import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: calc(100% - 323px);
    border-radius: 3px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AnimationDiv = styled.div`
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
