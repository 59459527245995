import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${(props) => (props.active ? "#CCDFFF" : "#f1f5f9")};
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 8px 24px 8px 16px;
`;

export const DataDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 45%;
`;
export const DarkText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000;
`;
export const GreyText = styled.div`
    font-weight: 400;
    font-size: ${(props) => props.fs && props.fs};
    line-height: 22px;
    color: ${(props) => (props.active ? "#263238" : "#475569")};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Content = styled.div`
    height: 56px;
    width: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Unit = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #94a3b8;
`;
export const Qty = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #475569;
`;
export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 56px;
    height: 56px;

    & img {
        width: 54px !important;
        height: 54px !important;
    }
`;
export const CheckboxContainer = styled.div`
    margin-right: 16px;

    & input {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
`;
export const QuantityContainer = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #334155;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #cbd5e1;
    border-radius: 4px;

    & span {
        font-weight: 600;
    }
`;
export const InputContainer = styled.div`
    height: 37px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    background-color: #fff;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    resize: both;
    text-align: center;
    padding-left: 9px;
`;
export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e2e8f0;
    width: 50%;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
export const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 0 24px;
    height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
`;
