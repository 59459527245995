import React from "react";
import * as S from "./style";

const CustomButton = ({ label, fill, color, icon, iconRight = false, onClick, size = "md", noBorder = false, disable }) => {
    return (
        <S.CustomBtn color={color} fill={fill} onClick={!disable ? onClick : undefined} size={size} noBorder={noBorder} disable={disable}>
            {icon && !iconRight && <img src={icon} alt={label} />}
            {label}
            {icon && iconRight && <img src={icon} alt={label} />}
        </S.CustomBtn>
    );
};

export default CustomButton;
