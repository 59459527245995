import React, { useEffect, useState } from "react";
import * as S from "./style";
import {
  ChequeIcon,
  CreditCardIcon,
  LoyaltyPointIcon,
  PayLaterIcon,
  RupeesIcon,
  UpiIcon,
} from "../../icons";
import { PAYMENT, PAYMENT_MODE_SUMMARY } from "../../constants/appConstants";
import { toDecimalPlace } from "../../utils/global-fn";
import { formatDate } from "../../utils/format-date";
import { getPayment } from "../../utils/object-parser";

const PaymentMode = ({ bill }) => {
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    let p = getPayment(bill.payment, bill.payment);
    setPayment(p);
  }, []);
  return (
    <div>
      <S.Payment>
        Payment Mode{" "}
        {payment?.is_paid === false && (
          <span>(Pending ₱{toDecimalPlace(payment?.amount_due)})</span>
        )}
      </S.Payment>
      <S.FlexContainer>
        {payment?.payment_modes?.map(
          ({ payment_mode, payment_amount, account }, idx) => (
            <>
              <S.ParentModeContainer>
                <S.ModeContainer key={idx}>
                  {payment_mode === PAYMENT.CASH && (
                    <S.PaymentImg src={RupeesIcon} alt="rupees" />
                  )}
                  {payment_mode === PAYMENT.QR && (
                    <S.PaymentImg src={UpiIcon} alt="QR" />
                  )}
                  {payment_mode === PAYMENT.CHEQUE && (
                    <S.PaymentImg src={ChequeIcon} alt="cheque" />
                  )}
                  {payment_mode === PAYMENT.CARD && (
                    <S.PaymentImg src={CreditCardIcon} alt="card" />
                  )}
                  {payment_mode === PAYMENT.PAY_LATER && (
                    <S.PaymentImg src={PayLaterIcon} alt="pay later" />
                  )}
                  {payment_mode === PAYMENT.LOYALTY_POINT && (
                    <S.PaymentImg
                      src={LoyaltyPointIcon}
                      alt="loyalty point"
                      size="sm"
                      padding
                    />
                  )}
                  <S.Mode>
                    {payment_mode && PAYMENT_MODE_SUMMARY[payment_mode]}{" "}
                    <span>(₱{toDecimalPlace(payment_amount)})</span>
                  </S.Mode>
                  {idx != payment?.payment_modes?.length - 1 && (
                    <S.Mode>,</S.Mode>
                  )}
                </S.ModeContainer>
                {payment_mode === PAYMENT.PAY_LATER &&
                  payment?.is_paid === true && <S.Strike></S.Strike>}
              </S.ParentModeContainer>
              {payment_mode === PAYMENT.CHEQUE && account?.cheque_number && (
                <>
                  <S.Mode>
                    <span>Cheque Number: {account?.cheque_number}</span>
                  </S.Mode>
                  <S.Mode>
                    <span>Due Date: {formatDate(account?.cheque_date)}</span>
                  </S.Mode>
                </>
              )}
            </>
          )
        )}
      </S.FlexContainer>
    </div>
  );
};

export default PaymentMode;
