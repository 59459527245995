import styled from "@emotion/styled/macro";

export const SelectedChipContainer = styled.div`
    height: 23px;
    display: flex;
    background: #dbeafe;
    border-radius: 2px;
    padding: 9px 17.5px;
    align-items: center;

    img {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        cursor: pointer;
        margin-top: 2px;
    }
`;
export const ChipText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3b82f6;
`;
