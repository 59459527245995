import { useState } from "react";
import * as S from "./style";
import { useSelector } from "react-redux";
import { PRICE_TYPE } from "../../../constants/appConstants";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { useEffect } from "react";

const Taxable = ({ item, onChange, type }) => {
    const [selectedType, setSelectedType] = useState("");
    const { saleProducts } = useSelector((store) => store.sale);

    useEffect(() => {
        if (item[type] === true || item[type] === false) {
            const idx = PRICE_TYPE.findIndex((option) => option.value === item[type]);
            if (idx >= 0) {
                setSelectedType(PRICE_TYPE[idx]?.name);
            }
        } else {
            setSelectedType(`With Tax`);
        }
    }, [item]);

    const handleSelectChange = (key, item) => {
        setSelectedType(item?.name);
        onChange(item?.value, key);
    };
    return (
        <S.Wrapper>
            <CustomSelect type={type} disableSearch data={PRICE_TYPE} onSelectChange={handleSelectChange} selectedOption={selectedType} size="sm" />
        </S.Wrapper>
    );
};

export default Taxable;
