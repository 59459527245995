import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllExpenses } from "../api/local/expenses-api";
import { captureException } from "../crash-reporting";

const initialState = {
    expenses: [],
    loading: false,
    error: "",
    searchParams: {},
    selectedExpense: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchAllExpenses = createAsyncThunk("expense/fetchAllExpenses", (queryParams) =>
    getAllExpenses(queryParams)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const expenseSlice = createSlice({
    name: "expense",
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },

        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = {};
        },
        setSelectedExpense: (state, action) => {
            state.selectedExpense = action.payload;
        },
        removeSelectedExpense: (state, action) => {
            state.selectedExpense = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllExpenses.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllExpenses.fulfilled, (state, action) => {
            state.loading = false;
            state.expenses = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllExpenses.rejected, (state, action) => {
            state.loading = false;
            state.expenses = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default expenseSlice.reducer;
export const {
    setSearchParams,
    removeSearchParams,
    removeAllSearchParams,
    setSelectedExpense,
    removeSelectedExpense,
} = expenseSlice.actions;
