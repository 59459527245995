import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import * as S from "./auto-login-modal.styles";
import { closeAutoLoginModal } from "../../store/app-slice";
import { PasswordEyeIcon, PasswordNoEyeIcon } from "../../icons";
import Avatar from "../avatar/Avatar";
import { employeeLogin } from "../../api/local/auth-api";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useNavigate } from "react-router-dom";
import { removeEmployeeSession, setEmployeeSession } from "../../store/session-slice";
import { captureException } from "../../crash-reporting";
import { useEffect } from "react";
import reduxStore from "../../store/store";
import { useRef } from "react";

const AutoLoginModal = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, _setPassword] = useState("");
    const passwordRef = useRef(password);
    const setPassword = (data) => {
        passwordRef.current = data;
        _setPassword(data);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const modalStatus = useSelector((state) => state.app.autoLoginModalStatus);

    const store = useSelector((state) => state.session.store);
    const employee = useSelector((state) => state.session.employee);

    useEffect(() => {
        if (modalStatus && store && employee) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [modalStatus]);

    const handleDetectKeyDown = (e) => {
        let open = reduxStore.getState().app.autoLoginModalStatus;
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleLogin(e);
            }
        }
    };
    const handleLogin = async (event) => {
        event.preventDefault();
        if (passwordRef.current) {
            try {
                const res = await employeeLogin({
                    username: employee.username,
                    password: passwordRef.current,
                });
                if (res.status === 200) {
                    await dispatch(setEmployeeSession(res.data));
                    navigate("/dashboard/new-sale");
                    dispatch(closeAutoLoginModal());
                } else {
                    handleSnackbarDetails({
                        show: true,
                        title: res.message,
                        type: "error",
                        subtitle: "Please retry",
                    });
                    throw new Error(res.data?.message);
                }
            } catch (error) {
                captureException(error);
                handleSnackbarDetails({
                    show: true,
                    title: "Username or password is incorrect",
                    type: "error",
                    subtitle: "Please retry",
                });
            }
        }
    };

    const handleLogout = async () => {
        dispatch(closeAutoLoginModal());
        dispatch(removeEmployeeSession());
        navigate("/employee-login");
    };

    return (
        <Modal open={modalStatus && store && employee} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.StoreDetail>
                    <Avatar name={store?.store_name} color={"#000000"} size={"48px"} />
                    <S.StoreName>{store?.store_name}</S.StoreName>
                </S.StoreDetail>
                <S.LoginText>{`Login again, ${employee?.name}`}</S.LoginText>
                <S.LoginHelperText>Please re-enter your password to continue</S.LoginHelperText>

                <S.LoginContainer>
                    <S.InputContainer>
                        <S.Password>Password</S.Password>
                        <S.PasswordInputContainer>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                autoFocus
                            />
                            <img src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon} alt="" onClick={() => setShowPassword(!showPassword)} />
                        </S.PasswordInputContainer>
                    </S.InputContainer>
                    <S.LoginBtn onClick={(e) => handleLogin(e)}>Log in</S.LoginBtn>
                </S.LoginContainer>

                <S.LoginBtnSecondary onClick={handleLogout}>Log in with different account</S.LoginBtnSecondary>
            </S.Container>
        </Modal>
    );
};

export default AutoLoginModal;
