import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 510px;
`;

export const CropperDiv = styled.div`
    height: 390px;
    width: 460px;
    margin: 20px;
    position: absolute;
    border-radius: 4px;
`;

export const Control = styled.div`
    position: absolute;
    bottom: -44px;
    left: 32%;
    // width: 100%;
    height: 40px;
    display: flex;
    gap: 6px;
    align-items: center;
`;
export const Icon = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-top: -8px;
`;
export const BottomBar = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    background: #f7f7f9;
    padding: 10px 16px;
    border-radius: 0 0 12px 12px;
`;

export const ConfirmBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #2d68fe;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 23px;
    cursor: pointer;
`;

export const CancelBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 23px;
    cursor: pointer;
`;
