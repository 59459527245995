import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 127px 30px 24px;
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Heading = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #263238;
`;

export const Points = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const Point = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #263238;

    & span {
        font-weight: 600;
    }
`;
export const Dot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #263238;
    margin-top: 2px;
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const QRContainer = styled.div`
    box-sizing: border-box;
    padding: 10px;
`;

export const ServerIp = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #263238;
    margin-top: 12px;
`;
