import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: #f1f5f9;
    padding: 62px 40px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
`;
export const LeftContainer = styled.div`
    height: 100%;
    & > img {
        width: 643px;
        height: 643px;
        border-radius: 24px;
    }
`;
export const MiddleContainer = styled.div`
    margin: 0px 26px 0 16px;
    height: 100%;
`;

export const InnerMiddleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 132px;
`;
export const RightContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 27px;
    margin-bottom: 7px;

    & > img {
        width: 36px;
        height: 36px;
    }
    & > div {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #0f172a;
    }
`;
export const SubHeading = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #004463;
    margin: 40px 0 25px;
`;

export const GreyHeading = styled.div`
    width: 534px;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #94a3b8;
    margin-top: 22px;
`;

export const CheckBtn = styled.div`
    width: ${(props) => (props.long ? "188px" : "81px")};
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.fill ? "#FFDE2C" : "#F1F5F9")};
    border: ${(props) =>
        props.fill ? "1px solid #FFDE2C" : "1px solid #94A3B8"};
    color: ${(props) => (props.fill ? "#263238" : "#94A3B8")};
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    cursor: pointer;
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 19px;
`;
export const NeedHelp = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #455a64;
`;
export const OuterCircle = styled.div`
    width: 32px;
    height: 32px;
    border: ${(props) =>
        props.active ? "1px solid #94A3B8" : "1px solid #EBEBEB"};
    border-radius: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: ${(props) => (props.active ? "#22FE53" : "#94A3B8")};
    }
`;
export const DottedLine = styled.div`
    width: 0px;
    height: 160px;
    border-right: 1px dashed #94a3b8;
`;
