import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addProductToDamageEntry,
    decrementQuantityDamageEntry,
    removeProductFromDamageEntry,
    updateProductFromDamageEntry,
} from "../../../../store/damageentry-slice";
import {
    removeProductFromPurchaseReturn,
    updateProductFromPurchaseReturn,
    addProductToPurchaseReturn,
    decrementQuantityPurchaseReturn,
    SCREENS,
    removeReturnSelectedSupplier,
} from "../../../../store/purchasereturn-slice";
import store from "../../../../store/store";
import CreateReturnItem from "./create-return-item/create-return-item";

import * as S from "./create-return-table.styles";
import { setOpenProductDrawer, setProductDrawerData, setProductDrawerType } from "../../../../store/productdrawer-slice";
import { PRODUCT_DRAWER_TYPE, ScreenType } from "../../../../constants/appConstants";
import { getProductsBySKU } from "../../../../api/local/productApi";
import { captureException } from "../../../../crash-reporting";
import { toDecimalPlace } from "../../../../utils/global-fn";
import CartBottomBar from "../../../cart-bottom-bar/CartBottomBar";

const CreateReturnTable = ({ products, setProductsList, type, createdAt, handleBtnClick }) => {
    const { currentScreen, returnSelectedSupplier } = useSelector((state) => state.purchaseReturn);
    const dispatch = useDispatch();
    const rowsRefs = useRef([]);
    const [activeRow, _setActiveRow] = useState(null);
    const activeRowRef = React.useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        if (products.length) {
            getProductsData();
        }
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const getCurrentIdx = () => {
        return rowsRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const handleDetectKeyDown = (e) => {
        if (type === ScreenType.NewPurchaseReturn) {
            if (e.keyCode === 46) {
                //DELETE Key
                let product = store.getState().purchaseReturn.purchaseReturnProducts[activeRowRef.current];
                if (activeRowRef.current >= 0 && product) {
                    e.preventDefault();
                    dispatch(removeProductFromPurchaseReturn(product));
                }
            } else if (e.keyCode === 38) {
                //UP Arrow key
                let idx = getCurrentIdx();
                let product = store.getState().purchaseReturn.purchaseReturnProducts[idx];
                if (idx >= 0 && product) {
                    e.preventDefault();
                    dispatch(addProductToPurchaseReturn(product));
                }
            } else if (e.keyCode === 40) {
                //DOWN Arrow key
                let idx = getCurrentIdx();
                let product = store.getState().purchaseReturn.purchaseReturnProducts[idx];
                if (idx >= 0 && product) {
                    e.preventDefault();
                    dispatch(decrementQuantityPurchaseReturn(product));
                }
            } else if (e.keyCode === 33) {
                //Pg Up key
                if (activeRowRef.current > 0) {
                    e.preventDefault();
                    setActiveRow(activeRowRef.current - 1);
                    rowsRefs.current[activeRowRef.current].focus();
                }
            } else if (e.keyCode === 34) {
                //Pg Down key
                let products = store.getState().purchaseReturn.purchaseReturnProducts;
                if (activeRowRef.current < products.length - 1) {
                    e.preventDefault();
                    setActiveRow(activeRowRef.current + 1);
                    rowsRefs.current[activeRowRef.current].focus();
                }
            }
        } else {
            if (e.keyCode === 46) {
                //DELETE Key
                let product = store.getState().damageEntry.damageEntryProducts[activeRowRef.current];
                if (activeRowRef.current >= 0 && product) {
                    e.preventDefault();
                    dispatch(removeProductFromDamageEntry(product));
                }
            } else if (e.keyCode === 38) {
                //UP Arrow key
                let idx = getCurrentIdx();
                let product = store.getState().damageEntry.damageEntryProducts[idx];
                if (idx >= 0 && product) {
                    e.preventDefault();
                    dispatch(addProductToDamageEntry(product));
                }
            } else if (e.keyCode === 40) {
                //DOWN Arrow key
                let idx = getCurrentIdx();
                let product = store.getState().damageEntry.damageEntryProducts[idx];
                if (idx >= 0 && product) {
                    e.preventDefault();
                    dispatch(decrementQuantityDamageEntry(product));
                }
            } else if (e.keyCode === 33) {
                //Pg Up key
                if (activeRowRef.current > 0) {
                    e.preventDefault();
                    setActiveRow(activeRowRef.current - 1);
                    rowsRefs.current[activeRowRef.current].focus();
                }
            } else if (e.keyCode === 34) {
                //Pg Down key
                let products = store.getState().damageEntry.damageEntryProducts;
                if (activeRowRef.current < products.length - 1) {
                    e.preventDefault();
                    setActiveRow(activeRowRef.current + 1);
                    rowsRefs.current[activeRowRef.current].focus();
                }
            }
        }
    };

    const updateProducts = (product) => {
        const productsCopy = cloneDeep(products);
        const productIdx = productsCopy.findIndex((item) => product.sku === item.sku);
        if (productIdx >= 0) {
            productsCopy[productIdx] = product;
            setProductsList(productsCopy);
        }
        if (type === ScreenType.NewPurchaseReturn) {
            dispatch(
                updateProductFromPurchaseReturn({
                    ...product,
                    purchase_price: product.purchase_price ? toDecimalPlace(product.purchase_price) : 0,
                    sale_price: toDecimalPlace(product.sale_price),
                })
            );
        } else if (type === ScreenType.NewDamageEntry) {
            dispatch(
                updateProductFromDamageEntry({
                    ...product,
                    purchase_price: product.purchase_price ? toDecimalPlace(product.purchase_price) : 0,
                    sale_price: toDecimalPlace(product.sale_price),
                })
            );
        }
    };

    const removeProduct = (product) => {
        if (type === ScreenType.NewPurchaseReturn) {
            dispatch(removeProductFromPurchaseReturn(product));
        } else if (type === ScreenType.NewDamageEntry) {
            dispatch(removeProductFromDamageEntry(product));
        }
    };

    const handleEditProduct = (product) => {
        dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.EDIT));
        dispatch(setProductDrawerData(product));
        dispatch(setOpenProductDrawer());
    };

    const getProductsData = async () => {
        const payload = {
            ids: products.map((p) => p.sku),
        };
        try {
            let res = await getProductsBySKU(payload);
            if (res.status === 200) {
                let resProducts = res.data?.entity;
                resProducts.forEach((product) => {
                    updateProducts(product);
                });
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleSupplierRemove = () => {
        dispatch(removeReturnSelectedSupplier());
    };
    return (
        <S.Wrapper isFull={currentScreen === SCREENS.PURCHASE_TABLE}>
            <S.Table hasBottom={products?.length}>
                <S.TableHeader>
                    <S.TableHeaderData flexValue="0.2">SN.</S.TableHeaderData>
                    <S.TableHeaderData flexValue="1.5">Item Description</S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.65" center>
                        MRP
                    </S.TableHeaderData>
                    {currentScreen === SCREENS.PURCHASE_TABLE && (
                        <S.TableHeaderData flexValue="0.65" center>
                            Unit
                        </S.TableHeaderData>
                    )}
                    <S.TableHeaderData flexValue="1" center>
                        Purchase Price
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.65" center>
                        {type === ScreenType.NewPurchaseReturn ? "Return" : "Damage"} Quantity
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.75" center>
                        Tax
                    </S.TableHeaderData>
                    {currentScreen === SCREENS.PURCHASE_TABLE && (
                        <S.TableHeaderData flexValue="0.35" center>
                            More Info
                        </S.TableHeaderData>
                    )}
                    <S.TableHeaderData flexValue="0.65" center>
                        Subtotal
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.35"></S.TableHeaderData>
                </S.TableHeader>
                <S.TableBody>
                    {products.map((product, index) => (
                        <CreateReturnItem
                            key={index}
                            idx={index}
                            product={product}
                            updateProducts={updateProducts}
                            removeProduct={removeProduct}
                            type={type}
                            createdAt={createdAt}
                            focusRef={rowsRefs}
                            activeRow={activeRow}
                            setActiveRow={setActiveRow}
                            handleEditProduct={handleEditProduct}
                        />
                    ))}
                </S.TableBody>
            </S.Table>
            {products?.length ? (
                <CartBottomBar
                    data={type === ScreenType.NewPurchaseReturn && returnSelectedSupplier}
                    expand={currentScreen === SCREENS.PURCHASE_TABLE}
                    onExpand={() => handleBtnClick("back")}
                    onProceed={() => handleBtnClick("proceed")}
                    onDataRemove={handleSupplierRemove}
                />
            ) : (
                <></>
            )}
        </S.Wrapper>
    );
};

export default CreateReturnTable;
