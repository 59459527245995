import styled from "@emotion/styled/macro";

export const Container = styled.div`
    min-width: 339px;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
`;
export const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    padding: 14px 12px;
    border-bottom: 1px solid #cbd5e1;
`;

export const Body = styled.div`
    padding: 5px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: calc(100% - 47px);
`;

export const SupplierDetails = styled.div`
    background: #f1f5f9;
    padding: 8px 12px;
    margin-bottom: 5px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }
`;

export const SupplierContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 90%;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    margin-bottom: 4px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Mobile = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;
export const ClearContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
    & > div {
        margin-bottom: 2px;
    }
`;
export const PriceDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    margin-bottom: ${(props) => (props.lastItem ? "16px" : "4px")};
`;
export const Dotted = styled.div`
    height: 1px;
    border-bottom: 2px dashed #e2e8f0;
    margin: 4px 0;
`;
export const TotalDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const NewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 8px;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;
export const FieldContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 138px;

    & > input {
        border: none;
        background: #fff;
        width: 100%;

        :focus {
            border: none;
            outline: none;
        }
    }
`;

export const Line = styled.div`
    height: 18px;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;

export const DeliveryDetails = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 8px;
`;

export const DiscountChoosers = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & input {
        width: 72px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        padding: 10px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 13px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;

export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: 72px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 2px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 2px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 2px;
`;

export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;

export const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-bottom: 4px;
`;
