import MuiDrawer from "@mui/material/Drawer";

import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { DrawerContext } from "../../DrawerContextProvider/DrawerProvider";
import NavItems from "./NavItems/NavItems";
import PlanBox from "../plan-box/PlanBox";
import VersionBox from "../version-box/VersionBox";

const drawerWidth = 204;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#0F172A",
    overflowX: "hidden",
    overflowY: "auto",
    height: "calc(100vh - 56px)",
    // paddingRight: "12px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#0F172A",
    //   padding: "48px 24px 0",
    //   boxSizing: "border-box",
    height: "calc(100vh - 56px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "auto",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const LeftDrawer = () => {
    const drawerDetails = useContext(DrawerContext);
    return (
        <Drawer className="left-drawer" variant="permanent" open={drawerDetails.leftDrawerOpen}>
            <NavItems />
            {/* <PlanBox /> */}
            <VersionBox />
        </Drawer>
    );
};

export default LeftDrawer;
