import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import * as S from "./CashDrawer.style";
import { AdjustCashIcon, CalendarIcon } from "../../icons";
import CashTable from "../../components/cash-drawer/cash-table/CashTable";
import AddNewCashItem from "../../components/cash-drawer/add-new-cash/AddNewCashItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCashDrawerItems, removeAllItems, removeAllSearchParams, setSearchParams } from "../../store/cashdrawer-slice";
import CustomSelect from "../../components/product-drawer/custom-select/custom-select";
import { CASH_DRAWER_REFERENCE_TYPES, CASH_DRAWER_TYPES } from "../../constants/appConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate, formatDate } from "../../utils/format-date";
import "./CashDrawer.css";
import SelectedChips from "../../components/selected-chips/selected-chips";
import moment from "moment";
import { isEmpty } from "lodash";
import { getAllCashDrawerItems, getCashDrawerCounts } from "../../api/local/cashDrawer-api";
import { captureException } from "../../crash-reporting";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { toDecimalPlace } from "../../utils/global-fn";

const initalDisplayData = {
    cash_type: {},
    transaction_type: {},
    date: "",
};
const start_day = moment().startOf("day").unix() * 1000;
const CashDrawer = (props, ref) => {
    const [openCashModal, _setOpenCashModal] = useState({
        show: false,
        cashId: null,
    });
    const openCashModalref = useRef(openCashModal);
    const setOpenCashModal = (data) => {
        openCashModalref.current = data;
        _setOpenCashModal(data);
    };
    const selectRef = useRef(false);
    const trackRef = useRef(null);
    const [displayData, setDisplayData] = useState(initalDisplayData);
    const { searchParams } = useSelector((state) => state.cashDrawer);
    const [createdAt, _setCreatedAt] = useState(new Date());
    const createdAtRef = useRef(createdAt);
    const setCreatedAt = (data) => {
        createdAtRef.current = data;
        _setCreatedAt(data);
    };
    const typeRef = useRef();
    const referenseTypeRef = useRef();
    const [cashData, setCashData] = useState({
        current_cash: 0,
        opening_cash: 0,
    });

    const dispatch = useDispatch();
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        if (!isEmpty(searchParams)) {
            dispatch(fetchAllCashDrawerItems(searchParams));
        }
    }, [searchParams]);

    useEffect(() => {
        dispatch(setSearchParams({ date: start_day }));
        getCashDetails(start_day);
        return () => {
            dispatch(removeAllSearchParams());
            dispatch(removeAllItems());
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            addNewCashItem: () => handleOpenAdjustCash(),
            openReferenceType: () => onOpenReference(),
            openType: () => onOpenType(),
        };
    });

    const onOpenType = () => {
        if (trackRef.current === "transaction-type") {
            referenseTypeRef.current?.click();
        }
        typeRef.current?.click();
    };

    const onOpenReference = () => {
        if (trackRef.current === "cash-type") {
            typeRef.current?.click();
        }
        referenseTypeRef.current?.click();
    };

    const handleSelectChange = (key, item) => {
        if (key === "cash-type") {
            setDisplayData({ ...displayData, cash_type: item });
            dispatch(setSearchParams({ type: item.value }));
        } else if (key === "transaction-type") {
            setDisplayData({ ...displayData, transaction_type: item });
            dispatch(setSearchParams({ reference_type: item.value }));
        }
    };

    const handleDateChange = (date) => {
        setCreatedAt(date);
        dispatch(setSearchParams({ date: date.getTime() }));
        getCashDetails(date.getTime());
    };

    const getCashDetails = async (date = null) => {
        try {
            let res;
            if (date) {
                res = await getCashDrawerCounts({ date: date });
            } else {
                res = await getCashDrawerCounts();
            }
            if (res.status === 200) {
                setCashData(res.data);
            } else {
                throw new Error(res?.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    };

    const handleOpenAdjustCash = () => {
        if (createdAtRef.current < start_day) {
            handleSnackbarDetails({
                show: true,
                title: "You can only adjust cash for today date",
                type: "error",
            });
            return;
        }
        setOpenCashModal({
            show: true,
            cashId: null,
        });
    };

    return (
        <>
            <AddNewCashItem
                open={openCashModal.show}
                cashId={openCashModal.cashId}
                onClose={() =>
                    setOpenCashModal({
                        show: false,
                        cashId: null,
                    })
                }
                cashData={cashData}
                getCashDetails={getCashDetails}
            />
            <S.Wrapper>
                <S.TopBar>
                    <S.Header>Cash Drawer</S.Header>
                    <S.FilterContainer>
                        <S.DateChooserSelect>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(createdAt))}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="cashdrawer-date-picker"
                                placeholderText="Select time"
                                maxDate={new Date()}
                            />
                            <img className="caret-down" src={CalendarIcon} alt="select" />
                        </S.DateChooserSelect>
                        <CustomSelect
                            type="transaction-type"
                            initalText="Select Transaction (F10)"
                            data={CASH_DRAWER_REFERENCE_TYPES}
                            localSearch
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.transaction_type?.name}
                            menuWidth="235px"
                            selectRef={selectRef}
                            trackRef={trackRef}
                            clickRef={referenseTypeRef}
                        />
                        <CustomSelect
                            type="cash-type"
                            initalText="Select Type (F11)"
                            data={CASH_DRAWER_TYPES}
                            localSearch
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.cash_type?.name}
                            menuWidth="235px"
                            selectRef={selectRef}
                            trackRef={trackRef}
                            clickRef={typeRef}
                        />
                        <S.BlueBtn onClick={() => handleOpenAdjustCash()}>
                            <img src={AdjustCashIcon} alt="adjust cash" />
                            Adjust Cash (F12)
                        </S.BlueBtn>
                    </S.FilterContainer>
                </S.TopBar>
                <S.ChipContainer>
                    <SelectedChips displayData={displayData} setDisplayData={setDisplayData} />
                </S.ChipContainer>
                <CashTable />
                <S.BottomBar>
                    <S.BlueTextBox>
                        <S.BoldText>Opening Cash:</S.BoldText>
                        <div>{toDecimalPlace(cashData.opening_cash)}/-</div>
                    </S.BlueTextBox>
                    {createdAtRef.current < start_day ? (
                        <S.BlueTextBox>
                            <S.BoldText>Closing Cash:</S.BoldText>
                            <div>{toDecimalPlace(cashData.closing_cash)}/-</div>
                        </S.BlueTextBox>
                    ) : (
                        <S.BlueTextBox>
                            <S.BoldText>Current Cash:</S.BoldText>
                            <div>{toDecimalPlace(cashData.current_cash)}/-</div>
                        </S.BlueTextBox>
                    )}

                    {/* <S.BlueTextBox>
                        <S.BoldText>Closing Cash:</S.BoldText>
                        <div>100000/-</div>
                    </S.BlueTextBox> */}
                </S.BottomBar>
            </S.Wrapper>
        </>
    );
};

export default React.forwardRef(CashDrawer);
