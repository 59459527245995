import React, { useImperativeHandle } from "react";
import { CopyIcon } from "../../../icons";
import * as S from "./style";
import Product from "./../Product/Product";
import { useDispatch, useSelector } from "react-redux";
import Timer from "./../timer/Timer";
import { useState } from "react";
import { useEffect } from "react";
import { completeDeliveryOnlineSale, completeOnlineSale, fulfillingOnlineSale, startDeliveryOnlineSale } from "../../../api/local/onlineSale-api";
import {
    addProductForPacking,
    fetchAllOnlineSales,
    removeAllPackingProducts,
    removeSingleOrder,
    setFulifillingOrder,
    removeFulifillingOrder,
    setScreenFocus,
    setSingleOrder,
} from "../../../store/onlinesale-slice";
import { ONLINE_ORDERS_FOCUS, ONLINE_ORDER_PARAMS_STATUS } from "../../../constants/appConstants";

import PackingProductList from "./../packing-product-list/PackingProductList";
import { useContext } from "react";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import ConfirmationDialog from "../confirmation-dialog/ConfirmationDialog";
import PartialOrderDialog from "./../partial-order-dialog/PartialOrderDialog";
import { useRef } from "react";
import Lottie from "lottie-react";
import ButtonLoader from "../../../animations/button-loader.json";
import PackingOrder from "../../../animations/packing-order.json";
import PickupOrder from "../../../animations/pickup-order.json";
import DeliveryOrder from "../../../animations/delivery-order.json";
import CancelledOrder from "../../../animations/cancelled-order.json";
import DeliveredOrder from "../../../animations/delivered-order.json";
import { captureException } from "../../../crash-reporting";
import store from "../../../store/store";
import { getCustomer } from "../../../api/local/customerApi";

const ProductSection = (
    {
        activeProductRow,
        activeProductRowRef,
        rowsRefs,
        setOpenWarning,
        productsRef,
        setLoading,
        setOpenConfirmationDialog,
        openConfirmationDialogRef,
        setActiveOrderRow,
        handleRowFocus,
    },
    ref
) => {
    const { order, packingProducts, params, fulifillingOrder } = useSelector((state) => state.onlineSale);
    const [timer, setTimer] = useState({
        hours: "00",
        minutes: "00",
        seconds: "00",
    });
    const dispatch = useDispatch();
    const [startFulfillment, _setStartFulfillment] = useState(false);
    const startFulfillmentRef = useRef(startFulfillment);
    const setStartFulfillment = (data) => {
        startFulfillmentRef.current = data;
        _setStartFulfillment(data);
    };

    const [btnLoader, setBtnLoader] = useState(false);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    // const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [leftProducts, setLeftProducts] = useState(null);
    const [openPartialOrderDialog, setOpenPartialOrderDialog] = useState(false);
    const [customerData, setCustomerData] = useState(null);

    useEffect(() => {
        checkOrder();
        setCustomerData(order?.online?.patient);
        return () => {};
    }, [order]);

    useImperativeHandle(ref, () => {
        return {
            onCancelOrder: () => setOpenConfirmationDialog(true),
            onSubmit: () => onHandleSubmit(),
        };
    });

    const onHandleSubmit = () => {
        let selectedOrder = store.getState().onlineSale.order;
        if (!startFulfillmentRef.current && selectedOrder?.online?.status === ONLINE_ORDER_PARAMS_STATUS.ORDERED) {
            handleStartFulfillment();
        } else {
            if (selectedOrder?.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING) {
                handleSubmit();
            } else if (selectedOrder?.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKED) {
                handleStartDelivery();
            } else if (selectedOrder?.online?.status === ONLINE_ORDER_PARAMS_STATUS.OUT_FOR_DELIVERY) {
                handleCompleteDelivery();
            }
        }
    };
    const checkOrder = () => {
        if (order?.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING) {
            if (!packingProducts.filter((p) => p.order_id === order.id)[0]?.products?.length) {
                order.products.forEach((item) => dispatch(addProductForPacking({ order_id: order.id, product: { ...item, isChecked: false } })));
            }
            dispatch(setFulifillingOrder(order));
            setStartFulfillment(true);
        }
    };

    const handleStartFulfillment = async () => {
        // if (fulifillingOrder) {
        //     setOpenWarning(true);
        //     return;
        // }
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
        };

        try {
            let res = await fulfillingOnlineSale(order.id, payload);
            if (res.status === 200) {
                order.products.forEach((item) => dispatch(addProductForPacking({ order_id: order.id, product: { ...item, isChecked: false } })));
                dispatch(setFulifillingOrder(order));
                dispatch(setSingleOrder(res.data));
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.PRODUCTS_SECTION));
                setStartFulfillment(true);
                setBtnLoader(false);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleCompleteOnlineSale = async () => {
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
            items: packingProducts
                .filter((p) => p.order_id === order.id)[0]
                .products.filter((item) => item.isChecked)
                .map((_item) => ({ sku: _item.sku, quantity: _item.packedQuantity })),
        };

        if (!payload.items.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a product",
                type: "error",
            });
            return;
        }

        try {
            let res = await completeOnlineSale(order.id, payload);
            if (res.status === 200) {
                dispatch(removeSingleOrder());
                dispatch(removeAllPackingProducts({ order_id: res.data.id }));
                dispatch(fetchAllOnlineSales(params));
                dispatch(removeFulifillingOrder());
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
                setActiveOrderRow(0);
                setBtnLoader(false);
                handleSnackbarDetails({
                    show: true,
                    title: "Order packed successfully",
                    type: "success",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleSubmit = () => {
        let products = packingProducts.filter((p) => p.order_id === order.id)[0].products.filter((item) => item.isChecked === false);
        if (products.length === packingProducts.filter((p) => p.order_id === order.id)[0].products.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a product",
                type: "error",
            });
            return;
        }
        if (products.length) {
            setLeftProducts(products);
            setOpenPartialOrderDialog(true);
        } else {
            handleCompleteOnlineSale();
        }
    };

    const disableButton = () => {
        let products = packingProducts.filter((p) => p.order_id === order.id)[0].products.filter((item) => item.isChecked === false);
        if (products.length === packingProducts.filter((p) => p.order_id === order.id)[0].products.length) {
            return true;
        }
        return false;
    };

    const handleCopyText = (order) => {
        navigator.clipboard.writeText(order.bill_no);
        handleSnackbarDetails({
            show: true,
            title: "Copied!",
            subtitle: "Order Id copied successfully",
            type: "success",
        });
    };

    const handleStartDelivery = async () => {
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
        };
        try {
            let res = await startDeliveryOnlineSale(order.id, payload);
            if (res.status === 200) {
                dispatch(removeSingleOrder());
                dispatch(fetchAllOnlineSales(params));
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
                setActiveOrderRow(0);
                setBtnLoader(false);
                handleSnackbarDetails({
                    show: true,
                    title: "Success!",
                    subtitle: "Order out for delivery",
                    type: "success",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleCompleteDelivery = async () => {
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
        };
        try {
            let res = await completeDeliveryOnlineSale(order.id, payload);
            if (res.status === 200) {
                dispatch(removeSingleOrder());
                dispatch(fetchAllOnlineSales(params));
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
                setActiveOrderRow(0);
                setBtnLoader(false);
                handleSnackbarDetails({
                    show: true,
                    title: "Success!",
                    subtitle: "Order delivered successfully",
                    type: "success",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    return (
        <>
            <ConfirmationDialog
                open={openConfirmationDialogRef.current}
                onClose={() => setOpenConfirmationDialog(false)}
                setActiveOrderRow={setActiveOrderRow}
            />
            <PartialOrderDialog
                open={openPartialOrderDialog}
                onClose={() => setOpenPartialOrderDialog(false)}
                data={leftProducts}
                setActiveOrderRow={setActiveOrderRow}
            />
            <S.ProductWrapper>
                <S.TopBar>
                    {customerData ? (
                        <S.CustomerContainer>
                            {/* <S.Label>Customer Details:</S.Label> */}
                            <S.DarkText>
                                {customerData?.name && `${customerData?.name}, `} {customerData?.phone && customerData?.phone}
                            </S.DarkText>
                            <S.GreyText>
                                {customerData?.address?.line_1}, {customerData?.address?.line_2},{customerData?.address?.city},
                                {customerData?.address?.state},{customerData?.address?.pincode}
                            </S.GreyText>
                            {/* <S.GreyText>
                                {customerData?.address?.city}, {customerData?.address?.state},{customerData?.address?.pincode}{" "}
                            </S.GreyText> */}
                        </S.CustomerContainer>
                    ) : (
                        <S.CustomerContainer></S.CustomerContainer>
                    )}
                    <S.OrderIdContainer>
                        <S.OrderId>#{order.bill_no}</S.OrderId>
                        <img src={CopyIcon} alt="copy" onClick={() => handleCopyText(order)} />
                    </S.OrderIdContainer>
                </S.TopBar>
                {startFulfillment ? (
                    <S.Body>
                        <PackingProductList
                            activeProductRow={activeProductRow}
                            activeProductRowRef={activeProductRowRef}
                            rowsRefs={rowsRefs}
                            handleRowFocus={handleRowFocus}
                            ref={productsRef}
                        />
                        <S.BtnContainer>
                            <S.AnimationStatusContainer>
                                <S.AnimationContainer>
                                    <Lottie animationData={PackingOrder} />
                                </S.AnimationContainer>
                                <S.StatusText>Your order is packing</S.StatusText>
                            </S.AnimationStatusContainer>
                            <S.Buttons>
                                <S.RedBtn onClick={() => setOpenConfirmationDialog(true)}>Cancel Order (F11)</S.RedBtn>
                                {btnLoader ? (
                                    <S.LoaderBtn>
                                        <div>
                                            <Lottie animationData={ButtonLoader} />
                                        </div>
                                    </S.LoaderBtn>
                                ) : (
                                    <S.BlueBtn disable={disableButton()} onClick={handleSubmit}>
                                        Complete (F12)
                                    </S.BlueBtn>
                                )}
                            </S.Buttons>
                        </S.BtnContainer>
                    </S.Body>
                ) : (
                    <S.Body>
                        <S.ProductList>
                            {order?.products.map((item, idx) => (
                                <Product item={item} key={idx} />
                            ))}
                        </S.ProductList>

                        {order.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKED ? (
                            <S.BtnContainer>
                                <S.AnimationStatusContainer>
                                    <S.AnimationContainer>
                                        <Lottie animationData={PickupOrder} />
                                    </S.AnimationContainer>
                                    <S.StatusText>Your order has been packed waiting for delivery partner.</S.StatusText>
                                </S.AnimationStatusContainer>
                                <S.Buttons>
                                    {btnLoader ? (
                                        <S.LoaderBtn>
                                            <div>
                                                <Lottie animationData={ButtonLoader} />
                                            </div>
                                        </S.LoaderBtn>
                                    ) : (
                                        <S.BlueBtn onClick={handleStartDelivery}>Out for delivery (F12)</S.BlueBtn>
                                    )}
                                </S.Buttons>
                            </S.BtnContainer>
                        ) : order.online?.status === ONLINE_ORDER_PARAMS_STATUS.OUT_FOR_DELIVERY ? (
                            <S.BtnContainer>
                                <S.AnimationStatusContainer>
                                    <S.AnimationContainer>
                                        <Lottie animationData={DeliveryOrder} />
                                    </S.AnimationContainer>
                                    <S.StatusText>Your order is out for delivery.</S.StatusText>
                                </S.AnimationStatusContainer>
                                <S.Buttons>
                                    {btnLoader ? (
                                        <S.LoaderBtn>
                                            <div>
                                                <Lottie animationData={ButtonLoader} />
                                            </div>
                                        </S.LoaderBtn>
                                    ) : (
                                        <S.BlueBtn onClick={handleCompleteDelivery}>Mark as Delivered (F12)</S.BlueBtn>
                                    )}
                                </S.Buttons>
                            </S.BtnContainer>
                        ) : order.online?.status === ONLINE_ORDER_PARAMS_STATUS.CANCELLED ? (
                            <S.BtnContainer>
                                <S.AnimationStatusContainer>
                                    <S.AnimationContainer>
                                        <Lottie animationData={CancelledOrder} />
                                    </S.AnimationContainer>
                                    <S.StatusText>Your order is Cancelled.</S.StatusText>
                                </S.AnimationStatusContainer>
                            </S.BtnContainer>
                        ) : order.online?.status === ONLINE_ORDER_PARAMS_STATUS.DELIVERED ? (
                            <S.BtnContainer>
                                <S.AnimationStatusContainer>
                                    <S.AnimationContainer>
                                        <Lottie animationData={DeliveredOrder} />
                                    </S.AnimationContainer>
                                    <S.StatusText>Your order is Delivered.</S.StatusText>
                                </S.AnimationStatusContainer>
                            </S.BtnContainer>
                        ) : (
                            <S.BtnContainer>
                                <S.RedBtn onClick={() => setOpenConfirmationDialog(true)}>Cancel Order (F11)</S.RedBtn>
                                {btnLoader ? (
                                    <S.LoaderBtn>
                                        <div>
                                            <Lottie animationData={ButtonLoader} />
                                        </div>
                                    </S.LoaderBtn>
                                ) : (
                                    <S.BlueBtn onClick={handleStartFulfillment}>Start Fulfilment (F12)</S.BlueBtn>
                                )}
                            </S.BtnContainer>
                        )}
                    </S.Body>
                )}
            </S.ProductWrapper>
        </>
    );
};

export default React.forwardRef(ProductSection);
