import React, { useContext, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CrossBlackIcon, EmptyDraftLogo } from "../../../icons";
import {
    fetchAllSales,
    removeAllProducts,
    removeSaleID,
    removeSelectedSale,
    resetAdditionalCharges,
    setBillno,
    setCreatedDate,
    setSelectDiscount,
} from "../../../store/sale-slice";
import * as S from "./style";
import Draft from "./Draft";
import { removeSelectedCustomer } from "../../../store/customer-slice";
import { addSale, updateSale } from "../../../api/local/sale-api";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import store from "./../../../store/store";
import { DiscountOption, SALE_BILL_TYPE } from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";
import ConfirmDialog from "../confirm-dialog/ConfirmDialog";
import { getSalePayloadProducts, handleSelectPiDraft, handleSelectSaleDraft } from "../../../utils/global-fn";
import {
    removeAllProductsPI,
    removeCustomerPI,
    removePI_ID,
    removeSelectedPI,
    resetAdditionalChargesPI,
    setBillNoPI,
    setCreatedDatePI,
    setSelectDiscountPI,
} from "../../../store/proformainvoice-slice";

const DraftDrawer = ({ onCloseDrawer, open, clearCartRef, type }) => {
    const dispatch = useDispatch();
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ show: false, data: null });
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };
    const scrollRef = useRef(null);
    const { searchSaleParams, sales, saleProducts, saleId, createdDate, selectDiscount, saleTotal, billno, globalDiscount } = useSelector(
        (state) => state.sale
    );

    const { inventorySelectedCustomer } = useSelector((state) => state.customer);
    const { PI_id, PI_products, PI_customer, PI_createdDate, PI_selectDiscount, PI_total, PI_billNo, PI_type, PI_globalDiscount } = useSelector(
        (state) => state.proformaInvoice
    );

    const { billTypePreference } = useSelector((state) => state.app);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);

    useEffect(() => {
        dispatch(fetchAllSales(searchSaleParams));
    }, []);

    const handleDetectKeyDown = (e) => {
        if (open) {
            try {
                if (e.keyCode === 13) {
                    //Enter key
                    handleDraftClick(store.getState().sale.sales[activeRowRef.current]);
                    e.preventDefault();
                } else if (e.keyCode === 38) {
                    //UP Arrow key
                    if (activeRowRef.current > 0) {
                        setActiveRow(activeRowRef.current - 1);
                        e.preventDefault();
                    }
                } else if (e.keyCode === 40) {
                    //DOWN Arrow key
                    if (activeRowRef.current < store.getState().sale.sales?.length - 1) {
                        setActiveRow(activeRowRef.current + 1);
                        e.preventDefault();
                    }
                } else if (e.keyCode === 46) {
                    //DELETE key
                    handleDraftDelete(e, store.getState().sale.sales[activeRowRef.current]);
                    e.preventDefault();
                }
            } catch (error) {
                //
            }
        }
    };

    async function handleDraftDelete(e, item) {
        e.stopPropagation();
        const payload = {
            status: "TRASH",
        };
        try {
            let res = await updateSale(item.id, payload);
            if (res.status === 200) {
                dispatch(fetchAllSales(searchSaleParams));
                if ((type === "sale" && saleId === item.id) || (type === "proforma-invoice" && PI_id === item.id)) {
                    handleClearCartPage();
                }
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Draft deleted successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }

    function handleDraftClick(item) {
        if (type === "sale") onSaleDraftClick(item);
        else if (type === "proforma-invoice") onPIClick(item);
    }

    const onSaleDraftClick = (item) => {
        if (saleId === item.id) {
            onCloseDrawer();
            return;
        }
        if (saleProducts?.length) {
            setOpenConfirmDialog({ show: true, data: item });
        } else {
            onSaleBillManage(item);
            onCloseDrawer();
        }
    };

    const onPIClick = (item) => {
        if (PI_id === item.id) {
            onCloseDrawer();
            return;
        }
        if (PI_products?.length) {
            setOpenConfirmDialog({ show: true, data: item });
        } else {
            onPIBillManage(item);
            onCloseDrawer();
        }
    };

    const handleClearCartPage = () => {
        if (type === "sale") onClearSaleCart();
        else if (type === "proforma-invoice") onClearPICart();

        if (clearCartRef) clearCartRef.current?.clearPaymentInfo();
    };

    const onClearSaleCart = () => {
        dispatch(setCreatedDate(null));
        dispatch(setBillno(""));
        dispatch(removeAllProducts());
        dispatch(removeSelectedCustomer());
        dispatch(removeSaleID());
        dispatch(removeSelectedSale());
        dispatch(resetAdditionalCharges());
        dispatch(
            setSelectDiscount({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
    };

    const onClearPICart = () => {
        dispatch(setCreatedDatePI(null));
        dispatch(setBillNoPI(""));
        dispatch(removeAllProductsPI());
        dispatch(removeCustomerPI());
        dispatch(removePI_ID());
        dispatch(removeSelectedPI());
        dispatch(resetAdditionalChargesPI());
        dispatch(
            setSelectDiscountPI({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
    };

    function handleBillManage(item) {
        // let item = openConfirmDialog.data;
        if (type === "sale") onSaleBillManage(item);
        else if (type === "proforma-invoice") onPIBillManage(item);
        onCloseDrawer();
    }

    const onSaleBillManage = async (item) => {
        handleSelectSaleDraft(item, dispatch);
    };
    const onPIBillManage = async (item) => {
        handleSelectPiDraft(item, dispatch);
    };

    const payloadSale = () => {
        const payload = {
            status: "DRAFT",
            bill_type: billTypePreference?.sale_bill_type,
            products: getSalePayloadProducts(saleProducts, billTypePreference?.sale_bill_type),
        };
        if (inventorySelectedCustomer) payload.patient_id = inventorySelectedCustomer.id;
        if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
        if (selectDiscount.cost) {
            let disc = {
                type: selectDiscount.type,
                value: selectDiscount.cost,
            };
            payload.cart_manual_discount = disc;
        }
        if (billno) payload.bill_no = billno;

        return payload;
    };

    const payloadPI = () => {
        const payload = {
            type: PI_type,
            status: "DRAFT",
            products: getSalePayloadProducts(PI_products, SALE_BILL_TYPE.INVOICE),
        };

        if (PI_customer) payload.patient_id = PI_customer.id;
        if (PI_createdDate) payload.created_at = Math.floor(PI_createdDate.getTime());
        if (PI_selectDiscount.cost) {
            let disc = {
                type: PI_selectDiscount.type,
                value: PI_globalDiscount,
            };
            payload.cart_manual_discount = disc;
        }
        payload.cart_manual_discount = +(
            PI_selectDiscount.type === DiscountOption.PERCENTAGE ? PI_total * (parseInt(PI_selectDiscount.cost) / 100) : PI_selectDiscount.cost || 0
        ).toFixed(2);
        if (PI_billNo) payload.bill_no = PI_billNo;

        return payload;
    };

    async function handleDraftBill() {
        let item = openConfirmDialog.data;

        let payload;

        if (type === "sale") payload = payloadSale();
        else if (type === "proforma-invoice") payload = payloadPI();

        try {
            let res;
            let title;
            let subtitle = "Draft saved successfully";
            if (type === "sale" && saleId) {
                //update the existing sale
                res = await updateSale(saleId, payload);
                title = "Draft Updated";
            } else if (type === "proforma-invoice" && PI_id) {
                //update the existing proforma invoice
                res = await updateSale(PI_id, payload);
                title = "Draft Updated";
            } else {
                //Add the new sale
                res = await addSale(payload);
                title = "Draft Added";
            }

            if (res.status === 200) {
                handleBillManage(item);
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title,
                    subtitle,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={onCloseDrawer}>
            <ConfirmDialog
                open={openConfirmDialog.show}
                onClose={(e) => {
                    e.stopPropagation();
                    setOpenConfirmDialog({ show: false, data: null });
                }}
                handleDraftBill={handleDraftBill}
                handleBillClear={() => handleBillManage(openConfirmDialog.data)}
            />
            <S.Wrapper>
                <S.TopBar>
                    <S.Heading>Counters ({sales?.length ? sales?.length : 0})</S.Heading>
                    <img src={CrossBlackIcon} alt="" onClick={onCloseDrawer} />
                </S.TopBar>
                {sales?.length ? (
                    <div>
                        {sales?.map((item, idx) => (
                            <React.Fragment key={idx}>
                                <Draft
                                    key={idx}
                                    item={item}
                                    activeRow={activeRow}
                                    idx={idx}
                                    handleDraftClick={handleDraftClick}
                                    handleDraftDelete={handleDraftDelete}
                                    scrollRef={scrollRef}
                                />
                                <S.Hr></S.Hr>
                            </React.Fragment>
                        ))}
                    </div>
                ) : (
                    <S.EmptyContainer>
                        <img src={EmptyDraftLogo} alt="empty draft" />
                    </S.EmptyContainer>
                )}
            </S.Wrapper>
        </OutsideClickHandler>
    );
};

export default DraftDrawer;
