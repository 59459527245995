import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getGlobalSearchData } from "../api/local/globalSearchApi";
import { captureException } from "../crash-reporting";

const initialState = {
  loading: false,
  global: {},
  error: "",
};

export const fetchGlobalData = createAsyncThunk(
  "global/fetchGlobalData",
  (search) =>
    getGlobalSearchData(search)
      .then((res) => res.data)
      .catch((err) => captureException(err))
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGlobalData.fulfilled, (state, action) => {
      state.loading = false;
      state.global = action.payload.entity;
      state.error = "";
    });
    builder.addCase(fetchGlobalData.rejected, (state, action) => {
      state.loading = false;
      state.global = {};
      state.error = action.error.message;
    });
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default globalSlice.reducer;
export const {} = globalSlice.actions;
