import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./style";
import { TimerSettingsIcon } from "../../../icons";
import { useState } from "react";
import PlanBuyModal from "../../plan-buy-modal/PlanBuyModal";
import { PLAN_TYPE } from "../../../constants/POS-plans";
import { setOpenBuyDialog } from "../../../store/app-slice";

const PlanChip = () => {
    const [openBuyPlan, setOpenBuyPlan] = useState(false);
    const { posPlan } = useSelector((state) => state.app);
    const [days, setDays] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (posPlan?.free_trial_upto > Date.now()) {
            let millis = posPlan.free_trial_upto - Date.now();
            let d = millis / (1000 * 60 * 60 * 24);
            setDays(Math.floor(d));
        } else {
            setDays(0);
        }
    }, [posPlan]);

    return (
        <>
            {!posPlan?.is_active && posPlan?.info?.type === PLAN_TYPE.PLAN_FREE_TRIAL && (
                <>
                    <S.Wrapper onClick={() => dispatch(setOpenBuyDialog(true))}>
                        <img src={TimerSettingsIcon} alt="dollar icon" />
                        <S.Status>{days} days left</S.Status>
                    </S.Wrapper>
                </>
            )}
        </>
    );
};

export default PlanChip;
