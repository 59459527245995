import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSuppliers } from "../../store/supplier-slice";
import Supplier from "./supplier";
import * as S from "./suppliers-table.styles";
import NewSupplierModal from "../../components/new-supplier-modal/new-supplier-modal";
import { removePurchases } from "../../store/purchase-slice";
import Lottie from "lottie-react";
import EmptyTable from "../../animations/empty_table.json";

const SuppliersTable = ({ openAddSupplierModal, tableRef }) => {
    const { suppliers, searchParams } = useSelector((state) => state.supplier);
    const dispatch = useDispatch();
    const [clear, setClear] = useState(false);
    const [txn, setTxn] = useState(false);

    useEffect(() => {
        if (searchParams.show_transactions) dispatch(fetchAllSuppliers(searchParams));
    }, [searchParams]);

    const [supplierData, setSupplierData] = useState(null);

    return (
        <>
            <NewSupplierModal
                open={supplierData !== null}
                onClose={() => {
                    setSupplierData(null);
                }}
                supplierId={supplierData?.id}
                onSave={() => {}}
                clear={clear}
                txn={txn}
            />
            <S.Table>
                <S.TableHeader>
                    <S.Header flexValue="1.5">Name</S.Header>
                    <S.Header flexValue="1.25">Contact</S.Header>
                    <S.Header flexValue="1.75">Brands associated</S.Header>
                    <S.Header flexValue="0.7" center>
                        Amount Due
                    </S.Header>
                    <S.Header flexValue="2"></S.Header>
                </S.TableHeader>
                {suppliers?.length ? (
                    <S.TableBody ref={tableRef}>
                        {suppliers.map((supplier) => (
                            <Supplier
                                key={supplier.id}
                                supplier={supplier}
                                openEditDialog={(_supplier) => {
                                    setSupplierData(_supplier);
                                }}
                                setClear={setClear}
                                setTxn={setTxn}
                            />
                        ))}
                    </S.TableBody>
                ) : (
                    <S.EmptyTable>
                        <div>
                            <Lottie animationData={EmptyTable} />
                        </div>
                    </S.EmptyTable>
                )}
            </S.Table>
        </>
    );
};

export default SuppliersTable;
