import React, { useContext, useEffect, useState } from "react";
import * as S from "./style";
import ToggleSwitch from "../../../switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { DiscountOption } from "../../../../constants/appConstants";
import { SnackbarContext } from "../../../../snackbar-context/snackbar-context";
import { setSalePreference } from "../../../../store/app-slice";
import { ArrowDrop, ArrowUp } from "../../../../icons";

const SalePreferences = () => {
    const { salePreference } = useSelector((state) => state.app);
    const [preference, setPreference] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    useEffect(() => {
        setPreference(salePreference);
    }, []);
    const handleSwitchChange = (event, type) => {
        let { checked } = event.target;
        if (type === "cart_percentage") {
            let pl = { ...preference.cart_discount_type, percentage_enabled: checked };
            if (checked === false && pl.first === DiscountOption.PERCENTAGE) {
                pl.first = DiscountOption.FLAT;
            }
            setPreference({ ...preference, cart_discount_type: pl });
        } else if (type === "cart_flat") {
            let pl = { ...preference.cart_discount_type, flat_enabled: checked };
            if (checked === false && pl.first === DiscountOption.FLAT) {
                pl.first = DiscountOption.PERCENTAGE;
            }
            setPreference({ ...preference, cart_discount_type: pl });
        } else if (type === "item_percentage") {
            let pl = { ...preference.item_discount_type, percentage_enabled: checked };
            if (checked === false && pl.first === DiscountOption.PERCENTAGE) {
                pl.first = DiscountOption.FLAT;
            }
            setPreference({ ...preference, item_discount_type: pl });
        } else if (type === "item_flat") {
            let pl = { ...preference.item_discount_type, flat_enabled: checked };
            if (checked === false && pl.first === DiscountOption.FLAT) {
                pl.first = DiscountOption.PERCENTAGE;
            }
            setPreference({ ...preference, item_discount_type: pl });
        }
    };

    const handleRadioChange = (event) => {
        const { name, value } = event.target;
        if (name === "cart_discount") {
            let pl = { ...preference.cart_discount_type, first: value };
            if (value === DiscountOption.PERCENTAGE && pl.percentage_enabled === false) {
                pl.percentage_enabled = true;
            } else if (value === DiscountOption.FLAT && pl.flat_enabled === false) {
                pl.flat_enabled = true;
            }
            setPreference({ ...preference, cart_discount_type: pl });
        } else if (name === "item_discount") {
            let pl = { ...preference.item_discount_type, first: value };
            if (value === DiscountOption.PERCENTAGE && pl.percentage_enabled === false) {
                pl.percentage_enabled = true;
            } else if (value === DiscountOption.FLAT && pl.flat_enabled === false) {
                pl.flat_enabled = true;
            }
            setPreference({ ...preference, item_discount_type: pl });
        }
    };

    const handleSavePreference = async () => {
        let value = {
            item_discount_type: preference.item_discount_type,
            cart_discount_type: preference.cart_discount_type,
        };

        let res = await window.electronAPI.saveAppPreference({
            key: "sale",
            value,
        });
        if (res.code === "success") {
            dispatch(setSalePreference(value));
            handleSnackbarDetails({
                show: true,
                title: "Saved",
                subtitle: "Sale preference saved successfully",
                type: "success",
            });
        } else {
            console.log(res);
        }
    };
    return (
        <S.Wrapper>
            <S.Body>
                <S.DiscountBody>
                    <S.Head>Cart Discount</S.Head>
                    <S.SubHead>
                        Choose the discount type that takes priority in your cart. You can customize the order in which "Flat Rupees" and "Percentage"
                        discounts are applied.
                    </S.SubHead>

                    {/* preview */}
                    <S.SubHead>Preview</S.SubHead>
                    {(preference?.cart_discount_type?.percentage_enabled || preference?.cart_discount_type?.flat_enabled) && (
                        <S.PreviewContainer width="80%">
                            <S.DarkText>
                                Cart discount <S.SubHead>(F8)</S.SubHead>
                            </S.DarkText>
                            <S.CartDiscountChoosers>
                                {preference?.cart_discount_type?.first === DiscountOption.FLAT ? (
                                    <S.SymbolDiv width="72px" height="32px">
                                        {preference?.cart_discount_type?.flat_enabled && (
                                            <S.Symbol active={preference?.cart_discount_type?.first === DiscountOption.FLAT}>₱</S.Symbol>
                                        )}
                                        {preference?.cart_discount_type?.percentage_enabled && preference?.cart_discount_type?.flat_enabled && (
                                            <S.MiddleBar></S.MiddleBar>
                                        )}

                                        {preference?.cart_discount_type?.percentage_enabled && (
                                            <S.Symbol active={preference?.cart_discount_type?.first === DiscountOption.PERCENTAGE}>%</S.Symbol>
                                        )}
                                    </S.SymbolDiv>
                                ) : (
                                    <S.SymbolDiv width="72px" height="32px">
                                        {preference?.cart_discount_type?.percentage_enabled && (
                                            <S.Symbol active={preference?.cart_discount_type?.first === DiscountOption.PERCENTAGE}>%</S.Symbol>
                                        )}
                                        {preference?.cart_discount_type?.percentage_enabled && preference?.cart_discount_type?.flat_enabled && (
                                            <S.MiddleBar></S.MiddleBar>
                                        )}

                                        {preference?.cart_discount_type?.flat_enabled && (
                                            <S.Symbol active={preference?.cart_discount_type?.first === DiscountOption.FLAT}>₱</S.Symbol>
                                        )}
                                    </S.SymbolDiv>
                                )}
                                <input type="text" value={5000} disabled />
                            </S.CartDiscountChoosers>
                        </S.PreviewContainer>
                    )}
                    <div>
                        <S.SubHead>Toggles</S.SubHead>
                        <S.FlexContainer>
                            <S.GreyText>Enable “%” Discount</S.GreyText>
                            <ToggleSwitch
                                type="cart_percentage"
                                checked={preference?.cart_discount_type?.percentage_enabled}
                                handleChange={(e, type) => handleSwitchChange(e, type)}
                            />
                        </S.FlexContainer>
                        <S.FlexContainer>
                            <S.GreyText>Enable “Flat” Discount</S.GreyText>
                            <ToggleSwitch
                                type="cart_flat"
                                checked={preference?.cart_discount_type?.flat_enabled}
                                handleChange={(e, type) => handleSwitchChange(e, type)}
                            />
                        </S.FlexContainer>
                    </div>
                    <S.PriorityContainer>
                        <S.SubHead>Set Discount Priority</S.SubHead>
                        <S.RadioContainer>
                            <input
                                type="radio"
                                id="cart_percentage"
                                name="cart_discount"
                                value={DiscountOption.PERCENTAGE}
                                checked={preference?.cart_discount_type?.first === DiscountOption.PERCENTAGE}
                                onChange={handleRadioChange}
                            />
                            <S.Label for="cart_percentage">% (Percentage)</S.Label>
                        </S.RadioContainer>
                        <S.RadioContainer>
                            <input
                                type="radio"
                                id="cart_flat"
                                name="cart_discount"
                                value={DiscountOption.FLAT}
                                checked={preference?.cart_discount_type?.first === DiscountOption.FLAT}
                                onChange={handleRadioChange}
                            />
                            <S.Label for="cart_flat">₱ (Flat Rupees)</S.Label>
                        </S.RadioContainer>
                    </S.PriorityContainer>
                </S.DiscountBody>
                <S.DiscountBody>
                    <S.Head>Item Discount</S.Head>
                    <S.SubHead>
                        Choose the discount type that takes priority in your items. You can customize the order in which "Flat Rupees" and
                        "Percentage" discounts are applied.
                    </S.SubHead>
                    {/* preview */}
                    <S.SubHead>Preview</S.SubHead>
                    <S.PreviewContainer width="95%">
                        <div>
                            <S.DarkText>₱130</S.DarkText>
                            <S.GreyText>
                                <s>₱140</s>
                            </S.GreyText>
                        </div>
                        <S.DarkText>1</S.DarkText>
                        <S.QuantityBox>
                            <div>
                                <input type="text" value={1} disabled />
                                <S.ArrowBox>
                                    <img src={ArrowUp} alt="arrow up" />
                                    <img src={ArrowDrop} alt="arrow drop" />
                                </S.ArrowBox>
                            </div>
                        </S.QuantityBox>
                        {(preference?.item_discount_type?.percentage_enabled || preference?.item_discount_type?.flat_enabled) && (
                            <S.ItemDiscount>
                                {preference?.item_discount_type?.first === DiscountOption.FLAT ? (
                                    <S.SymbolDiv width="42px" height="28px">
                                        {preference?.item_discount_type?.flat_enabled && (
                                            <S.Symbol active={preference?.item_discount_type?.first === DiscountOption.FLAT}>₱</S.Symbol>
                                        )}
                                        {preference?.item_discount_type?.percentage_enabled && preference?.item_discount_type?.flat_enabled && (
                                            <S.MiddleBar></S.MiddleBar>
                                        )}
                                        {preference?.item_discount_type?.percentage_enabled && (
                                            <S.Symbol active={preference?.item_discount_type?.first === DiscountOption.PERCENTAGE}>%</S.Symbol>
                                        )}
                                    </S.SymbolDiv>
                                ) : (
                                    <S.SymbolDiv width="42px" height="28px">
                                        {preference?.item_discount_type?.percentage_enabled && (
                                            <S.Symbol active={preference?.item_discount_type?.first === DiscountOption.PERCENTAGE}>%</S.Symbol>
                                        )}
                                        {preference?.item_discount_type?.percentage_enabled && preference?.item_discount_type?.flat_enabled && (
                                            <S.MiddleBar></S.MiddleBar>
                                        )}
                                        {preference?.item_discount_type?.flat_enabled && (
                                            <S.Symbol active={preference?.item_discount_type?.first === DiscountOption.FLAT}>₱</S.Symbol>
                                        )}
                                    </S.SymbolDiv>
                                )}
                                <S.InputDiv>
                                    <input type="text" value={10} disabled />
                                </S.InputDiv>
                            </S.ItemDiscount>
                        )}
                        <S.DarkText>₱130</S.DarkText>
                    </S.PreviewContainer>
                    <div>
                        <S.SubHead>Toggles</S.SubHead>
                        <S.FlexContainer>
                            <S.GreyText>Enable “%” Discount</S.GreyText>
                            <ToggleSwitch
                                type="item_percentage"
                                checked={preference?.item_discount_type?.percentage_enabled}
                                handleChange={(e, type) => handleSwitchChange(e, type)}
                            />
                        </S.FlexContainer>
                        <S.FlexContainer>
                            <S.GreyText>Enable “Flat” Discount</S.GreyText>
                            <ToggleSwitch
                                type="item_flat"
                                checked={preference?.item_discount_type?.flat_enabled}
                                handleChange={(e, type) => handleSwitchChange(e, type)}
                            />
                        </S.FlexContainer>
                    </div>
                    <S.PriorityContainer>
                        <S.SubHead>Set Discount Priority</S.SubHead>
                        <S.RadioContainer>
                            <input
                                type="radio"
                                id="item_percentage"
                                name="item_discount"
                                value={DiscountOption.PERCENTAGE}
                                checked={preference?.item_discount_type?.first === DiscountOption.PERCENTAGE}
                                onChange={handleRadioChange}
                            />
                            <S.Label for="item_percentage">% (Percentage)</S.Label>
                        </S.RadioContainer>
                        <S.RadioContainer>
                            <input
                                type="radio"
                                id="item_flat"
                                name="item_discount"
                                value={DiscountOption.FLAT}
                                checked={preference?.item_discount_type?.first === DiscountOption.FLAT}
                                onChange={handleRadioChange}
                            />
                            <S.Label for="item_flat">₱ (Flat Rupees)</S.Label>
                        </S.RadioContainer>
                    </S.PriorityContainer>
                </S.DiscountBody>
            </S.Body>
            <S.BottomBar>
                <S.SaveBtn onClick={handleSavePreference}>Save Changes</S.SaveBtn>
            </S.BottomBar>
        </S.Wrapper>
    );
};

export default SalePreferences;
