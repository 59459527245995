import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: ${(props) => (props.isFull ? "100%" : "calc(100% - 304px)")};
    height: 100%;
    transition: width 0.2s ease-in-out;
`;
export const Table = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    width: 100%;
    height: ${(props) => (props.hasBottom ? "calc(100% - 68px)" : "100%")};
`;

export const TableHeader = styled.div`
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid #e2e8f0;
    padding: 0 16px;
    color: #334155;
    margin: 2px;
`;

export const TableHeaderData = styled.div`
    flex: ${(props) => props.flexValue};
    text-align: ${(props) => props.center && "center"};
`;

export const TableBody = styled.div`
    overflow-y: auto;
    height: 100%;
`;
