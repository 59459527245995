import { EXPENSES_API } from "../../constants/apiRoutes";
import axios from "./axios";

const getAllExpenses = async (queryParams = {}) => {
    return await axios.get(EXPENSES_API, {
        params: queryParams,
    });
};

const getExpense = async (expenseId) => {
    return await axios.get(`${EXPENSES_API}/${expenseId}`);
};

const addExpense = async (payload) => {
    return await axios.post(EXPENSES_API, payload);
};

const deleteExpense = async (expenseId) => {
    return await axios.delete(`${EXPENSES_API}/${expenseId}`);
};

const updateExpense = async (expenseId, payload) => {
    return await axios.post(`${EXPENSES_API}/${expenseId}`, payload);
};

export { getAllExpenses, getExpense, addExpense, deleteExpense, updateExpense };
