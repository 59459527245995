import React, { useImperativeHandle } from "react";
import * as S from "./style";
import PackingProduct from "./PackingProduct";
import onInputChange from "../../../utils/handleInputChange";
import { useDispatch, useSelector } from "react-redux";
import { addProductForPacking, setScreenFocus, updateProductForPacking } from "../../../store/onlinesale-slice";
import { useState } from "react";
import { useEffect } from "react";
import { getProduct } from "../../../api/local/productApi";
import { useRef } from "react";
import SelectProductModal from "./../../select-product-modal/select-product-modal";
import store from "../../../store/store";
import { ONLINE_ORDERS_FOCUS } from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";

const PackingProductList = ({ activeProductRow, activeProductRowRef, rowsRefs, handleRowFocus }, ref) => {
    const [productList, _setProductList] = useState([]);
    const productListRef = useRef(productList);
    const setProductList = (data) => {
        productListRef.current = data;
        _setProductList(data);
    };
    const dispatch = useDispatch();
    const { packingProducts, order } = useSelector((state) => state.onlineSale);
    const [scannedProduct, setScannedProduct] = useState(null);
    const [openSelectProductModal, setOpenSelectProductModal] = useState(false);

    useEffect(() => {
        setProductList(packingProducts.filter((p) => p.order_id === order.id)[0].products);
    }, [packingProducts]);

    useEffect(() => {
        dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.PRODUCTS_SECTION));
        if (!process.env.REACT_APP_DEMO_SERVER) {
            const removeEventListener = window.electronAPI.onBarcodeScanned(async (event, value) => await handleGetProduct(value));
            return () => {
                removeEventListener();
            };
        }
    }, []);

    useImperativeHandle(ref, () => {
        return {
            onIncreaseQuantity: () => handleIncrementQuant(),
            onDecreaseQuantity: () => handleDecrementQuant(),
        };
    });

    const getCurrentIdx = () => {
        return rowsRefs.current?.findIndex((el) => el === document.activeElement);
    };
    const handleIncrementQuant = () => {
        let idx = getCurrentIdx();
        let product = store.getState().onlineSale.packingProducts.filter((p) => p.order_id === order.id)[0].products[idx];
        if (activeProductRowRef.current >= 0 && product) {
            handleChangeQuantity("increment", product);
        }
    };
    const handleDecrementQuant = () => {
        let idx = getCurrentIdx();
        let product = store.getState().onlineSale.packingProducts.filter((p) => p.order_id === order.id)[0].products[idx];
        if (activeProductRowRef.current >= 0 && product) {
            handleChangeQuantity("decrement", product);
        }
    };

    async function handleGetProduct(barcode) {
        try {
            let res = await getProduct(barcode, { status: "PUBLISH" });
            if (res.status === 200) {
                //Product is present
                if (res.data.length > 1) {
                    setScannedProduct(res.data);
                    setOpenSelectProductModal(true);
                } else {
                    onlineOrderDataAddHandler(res.data[0]);
                }
            } else if (res.status === 404) {
                //Product is not present
            } else {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    }

    const onlineOrderDataAddHandler = (item) => {
        let productIdx = productListRef.current?.findIndex((_item) => _item.sku === item.sku);
        let product = productListRef.current[productIdx];

        dispatch(updateProductForPacking({ order_id: order.id, updateProduct: { ...product, isChecked: true } }));
        if (!product.packedQuantity) {
            dispatch(updateProductForPacking({ order_id: order.id, updateProduct: { ...product, packedQuantity: 0, isChecked: true } }));
        }

        if (product.quantity > product.packedQuantity) {
            dispatch(addProductForPacking({ order_id: order.id, product: item }));
        }
    };

    const handleInputChange = (e, item) => {
        onInputChange(e, (data) => {
            let { value } = data;
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;

            if (!value || value === "0") {
                dispatch(
                    updateProductForPacking({
                        order_id: order.id,
                        updateProduct: { ...item, isChecked: false, packedQuantity: parseInt(value) },
                    })
                );
            } else if (value <= item.quantity) {
                dispatch(
                    updateProductForPacking({
                        order_id: order.id,
                        updateProduct: { ...item, isChecked: true, packedQuantity: parseInt(value) },
                    })
                );
            } else {
                dispatch(
                    updateProductForPacking({
                        order_id: order.id,
                        updateProduct: { ...item, isChecked: true, packedQuantity: parseInt(item.quantity) },
                    })
                );
            }
        });
    };

    const handleChangeQuantity = (key, item) => {
        if (key === "increment") {
            dispatch(updateProductForPacking({ order_id: order.id, updateProduct: { ...item, isChecked: true } }));
            if (!item.packedQuantity) {
                dispatch(updateProductForPacking({ order_id: order.id, updateProduct: { ...item, packedQuantity: 0, isChecked: true } }));
            }
            if (item.quantity > item.packedQuantity) {
                dispatch(
                    updateProductForPacking({
                        order_id: order.id,
                        updateProduct: { ...item, packedQuantity: item.packedQuantity + 1, isChecked: true },
                    })
                );
            }
        } else if (key === "decrement") {
            if (item.packedQuantity > 0) {
                dispatch(updateProductForPacking({ order_id: order.id, updateProduct: { ...item, isChecked: true } }));
                if (item.packedQuantity === 1) {
                    dispatch(
                        updateProductForPacking({
                            order_id: order.id,
                            updateProduct: { ...item, packedQuantity: item.packedQuantity - 1, isChecked: false },
                        })
                    );
                } else {
                    dispatch(
                        updateProductForPacking({
                            order_id: order.id,
                            updateProduct: { ...item, packedQuantity: item.packedQuantity - 1 },
                        })
                    );
                }
            }
        }
    };

    const checkHandler = (e, idx) => {
        const { name, checked } = e.target;
        productList.forEach(
            (item) =>
                item.sku === name &&
                dispatch(
                    updateProductForPacking({
                        order_id: order.id,
                        updateProduct: { ...item, isChecked: checked, packedQuantity: checked ? parseInt(item.quantity) : 0 },
                    })
                )
        );
        handleRowFocus(idx);
    };
    return (
        <>
            <SelectProductModal
                open={openSelectProductModal}
                onClose={() => setOpenSelectProductModal(false)}
                data={scannedProduct}
                onlineOrderDataAddHandler={onlineOrderDataAddHandler}
            />
            <S.ProductList>
                {productList.map((item, idx) => (
                    <PackingProduct
                        key={idx}
                        item={item}
                        handleInputChange={handleInputChange}
                        handleChangeQuantity={handleChangeQuantity}
                        checkHandler={checkHandler}
                        activeProductRow={activeProductRow}
                        idx={idx}
                        rowsRefs={rowsRefs}
                    />
                ))}
            </S.ProductList>
        </>
    );
};

export default React.forwardRef(PackingProductList);
