import React from "react";
import * as S from "./style";
import ShortcutKeyTab from "./../key-tab/ShortcutKeyTab";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { SHORTCUTS_MAP } from "./../../../constants/shortcutConstants";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));
const ShortcutKeys = () => {
    return (
        <S.Wrapper>
            <S.Body>
                <Masonry columns={2} spacing={2}>
                    {SHORTCUTS_MAP.map((item, index) => (
                        <ShortcutKeyTab title={item.title} data={item.data} />
                    ))}
                </Masonry>
            </S.Body>
        </S.Wrapper>
    );
};

export default ShortcutKeys;
