import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 186px);
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const SaveBtn = styled.div`
    background: #2d68fe;
    border-radius: 2px;
    padding: 10px 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
`;
export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Body = styled.div`
    display: flex;
    height: calc(100vh - 272px);
`;

export const DataContainer = styled.div`
    width: 427px;
    box-sizing: border-box;
    padding-right: 40px;
    border-right: 1px solid #e2e8f0;
    overflow: hidden;
    overflow-y: auto;
`;

export const DataRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 16px;
    margin-bottom: 16px;
`;

export const SerialNo = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #adcffa;
    border-radius: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    color: #2b456b;
`;

export const IconContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
    display: flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 16px;
`;

export const TimeContainerRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const TimeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 12;
    background: #ffffff;
    outline: none;
    width: ${(props) => (props.size === "lg" ? "95px" : "58px")};
    height: 34px;
    box-sizing: border-box;
    padding: 10px;
    font-weight: 400;
    font-size: 11.3052px;
    line-height: 14px;
    color: #334155;
    text-align: center;
`;
export const DeliveryChargeFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 54px;
`;
export const UnitText = styled.div`
    font-weight: 400;
    font-size: 12.7184px;
    line-height: 16px;
    color: #475569;
`;

export const AddBtn = styled.div`
    width: 119px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2d68fe;
    border-radius: 4px;
    color: #2d68fe;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    cursor: pointer;
    margin-top: 16px;
`;
