import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
    getDecimalPlacesCount,
    getPurchaseObjectHash,
    getPurchasePrice,
    getPurchaseProductSubTotal,
    getTax,
    getTaxValue,
    getUnit,
    hasDecimal,
    toDecimalPlace,
} from "../utils/global-fn";

const initialState = {
    damageEntryProducts: [],
    damageEntryQuantity: 0,
    damageEntryTotal: 0,
    damageEntryTaxTotal: 0,
    damageCreatedDate: null,
    selectedDraftDamage: null,
    currentFocus: 0,
    damageBillNo: "",
};

const damageEntrySlice = createSlice({
    name: "damageEntry",
    initialState,
    reducers: {
        addProductsToDamageEntry: (state, action) => {
            state.damageEntryProducts = action.payload;
            state.currentFocus = 0;
        },
        addProductToDamageEntry: (state, action) => {
            const itemIndex = state.damageEntryProducts.findIndex((item) => item.sku === action.payload.sku);
            if (itemIndex >= 0) {
                state.damageEntryProducts[itemIndex].quantity = parseFloat(state.damageEntryProducts[itemIndex].quantity) + 1;
                state.currentFocus = itemIndex;
            } else {
                state.damageEntryProducts.push({
                    ...action.payload,
                    quantity: action.payload.quantity ? action.payload.quantity : 1,
                    purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                    unit: getUnit(action.payload),
                    manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                    expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                    checkProduct: {
                        ...action.payload,
                        purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                        hashKey: getPurchaseObjectHash(action.payload),
                    },
                });
                state.currentFocus = state.damageEntryProducts.length - 1;
            }
        },
        updateProductFromDamageEntry: (state, action) => {
            const newDamageEntryProducts = state.damageEntryProducts.map((item) => {
                if (item.sku === action.payload.sku) {
                    if (action.payload.checkProduct) {
                        // for general slice product update
                        return { ...item, ...action.payload };
                    } else {
                        // update slice product after fetched from db
                        let newHash = getPurchaseObjectHash(action.payload);
                        if (item.checkProduct?.hashKey === newHash) {
                            let updatedCheckProduct = { ...item.checkProduct, stock: action.payload.stock };
                            return { ...item, name: action.payload.name, checkProduct: updatedCheckProduct };
                        } else {
                            let updateCheckProduct = {
                                ...action.payload,
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                hashKey: newHash,
                            };
                            return {
                                ...item,
                                ...action.payload,
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                unit: getUnit(action.payload),
                                manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                                expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                                checkProduct: updateCheckProduct,
                            };
                        }
                    }
                } else {
                    return item;
                }
            });
            state.damageEntryProducts = newDamageEntryProducts;
        },
        removeProductFromDamageEntry: (state, action) => {
            const idx = state.damageEntryProducts.findIndex((item) => item.sku === action.payload.sku);
            if (idx >= 0) {
                const newDamageEntryProducts = state.damageEntryProducts.filter((item) => item.sku !== action.payload.sku);
                state.damageEntryProducts = newDamageEntryProducts;
                if (idx >= newDamageEntryProducts.length - 1) {
                    state.currentFocus = newDamageEntryProducts.length - 1;
                }
            }
        },
        decrementQuantityDamageEntry: (state, action) => {
            const itemIndex = state.damageEntryProducts.findIndex((item) => item.sku === action.payload.sku);
            if (state.damageEntryProducts[itemIndex].quantity > 1) {
                let product = state.damageEntryProducts[itemIndex];
                let qty = parseFloat(product.quantity) - 1;

                if (hasDecimal(qty)) {
                    let ct = getDecimalPlacesCount(qty);
                    if (ct > 1) {
                        ct = 2;
                    }
                    qty = qty.toFixed(ct);
                }
                state.damageEntryProducts[itemIndex].quantity = parseFloat(qty);
            } else if (state.damageEntryProducts[itemIndex].quantity === 1) {
                toast.error("Minimum quantity reached", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        },
        getDamageEntryTotals: (state, action) => {
            let { total, quantity, tax } = state.damageEntryProducts.reduce(
                (purchaseTotal, purchaseItem) => {
                    const { purchase_price, quantity, purchase_price_with_tax } = purchaseItem;
                    let tax = getTax(purchaseItem);
                    let qty = parseFloat(quantity);
                    let purchasePrice = parseFloat(purchase_price);
                    let { itemTotal, taxTotal } = getPurchaseProductSubTotal(purchaseItem);

                    // const taxTotal = ((purchasePrice * tax) / 100) * (qty || 0);
                    let itemTaxTotal = 0;
                    if (qty > 0) {
                        itemTaxTotal = taxTotal;
                    }
                    purchaseTotal.total += itemTotal;
                    purchaseTotal.quantity += qty;
                    purchaseTotal.tax += itemTaxTotal;

                    return purchaseTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                    tax: 0,
                }
            );
            total = parseFloat(total.toFixed(2));
            state.damageEntryQuantity = quantity;
            state.damageEntryTotal = total;
            state.damageEntryTaxTotal = tax;
        },
        removeAllDamageEntryProducts: (state) => {
            state.damageEntryProducts = [];
        },
        setSelectedDraftDamage: (state, action) => {
            state.selectedDraftDamage = action.payload;
        },

        removeSelectedDraftDamage: (state) => {
            state.selectedDraftDamage = null;
        },
        setDamageCreatedDate: (state, action) => {
            state.damageCreatedDate = action.payload;
        },
        setDamageBillNo: (state, action) => {
            state.damageBillNo = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase("LOGOUT", (state) => {
            Object.assign(state, initialState);
        });
    },
});

export default damageEntrySlice.reducer;
export const {
    addProductToDamageEntry,
    updateProductFromDamageEntry,
    removeProductFromDamageEntry,
    decrementQuantityDamageEntry,
    getDamageEntryTotals,
    removeAllDamageEntryProducts,
    setSelectedDraftDamage,
    removeSelectedDraftDamage,
    setDamageCreatedDate,
    setDamageBillNo,
    addProductsToDamageEntry,
} = damageEntrySlice.actions;
