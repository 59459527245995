import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* Pages */
import Settings from "./pages/Settings/Index";
import Employees from "./pages/Employees/Index";
import Suppliers from "./pages/Suppliers/Index";
import Inventory from "./pages/inventory/inventory";
import ServiceUnavailable from "./pages/ServiceUnavailable/ServiceUnavailable";

// styles
import "./App.scss";

import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Layout components
import DashboardLayout from "./layout/DashboardLayout";
import { useContext, useEffect } from "react";
import Sales from "./pages/Sales";
import CustomSnackbar from "./components/snackbar/snackbar";
import { SnackbarContext } from "./snackbar-context/snackbar-context";
import Customers from "./pages/Customers/Customers";
import EmployeeLogin from "./pages/EmployeeLogin/EmployeeLogin";
import { useSelector, useDispatch } from "react-redux";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import LandingScreen from "./pages/LandingScreen/LandingScreen";
import LoginScreen from "./pages/LoginScreen/LoginScreen";
import RegistrationScreen from "./pages/RegistrationScreen/RegistrationScreen";
import Analytics from "./pages/Analytics/Analytics";
import AllSales from "./pages/AllSales/AllSales";
import ViewSale from "./pages/ViewSale/ViewSale";
import ReturnSale from "./pages/ReturnSale/ReturnSale";
import Loader from "./components/loader/Loader";
import PurchaseDetails from "./components/purchase/purchase-details/purchase-details";
import ReturnDetails from "./components/purchase/return-details/return-details";
import { useRef } from "react";
import { useState } from "react";
import AddNewCustomerModal from "./components/add-new-customer-modal/add-new-customer-modal";
import NewSupplierModal from "./components/new-supplier-modal/new-supplier-modal";
import AddNewEmp from "./components/EmployeesComponents/add-new-employee/AddNewEmp";
import history from "./utils/history";
import PurchaseView from "./components/purchase/purchase-view/purchase-view";
import { removeEmployeeSession, setStore } from "./store/session-slice";
import { detectKeyDown } from "./utils/shortcut-fn";
import OnboardingScreen from "./pages/OnboardingScreen/OnboardingSCreen";
import OnlineOrders from "./pages/OnlineOrders/OnlineOrders";
import { OrderSnackbarContext } from "./order-snackbar-context/order-snackbar-context";
import OrderSnackbar from "./components/order-snackbar/OrderSnackbar";
import Expenses from "./pages/Expenses/Expenses";
import {
  fetchAllOnlineSales,
  setCronOrders,
  setOrdersCount,
} from "./store/onlinesale-slice";
import {
  ONLINE_ORDER_PARAMS_STATUS,
  ONLINE_SALE_CRON_TIME,
  PASSWORD_CHANGE_STATE,
  PRODUCT_DRAWER_TYPE,
  PURCHASE_ROUTES,
  SALES_ROUTES,
} from "./constants/appConstants";
import OnlineSettings from "./pages/OnlineSettings/OnlineSettings";
import PLReport from "./pages/pl-report/PLReport";
import CashDrawer from "./pages/CashDrawer/CashDrawer";
import DownloadReports from "./pages/DownloadReports/DownloadReports";
import {
  removeProductFromPurchase,
  resetCurrentPurchaseScreen,
  resetSelectedHeaderTab,
  resetSelectedTab,
  updateProductFromPurchase,
} from "./store/purchase-slice";
import {
  removeProductFromSale,
  resetActiveTab,
  resetActiveTableTab,
  updateProductFromSale,
} from "./store/sale-slice";
import OnboardingLoading from "./pages/onboarding-loading-page/OnboardingLoading";
import {
  setAppOnline,
  setBillTypePreference,
  setLoyaltyPreference,
  setPrintingPreference,
  setOpenBuyDialog,
  setPOSFeatures,
  setPOSPlan,
  setSalePreference,
} from "./store/app-slice";
import { setDeviceData, removeStore } from "./store/session-slice";
import { OfflineDialogContext } from "./offline-dialog-context/offline-dialog-context";
import { OfflineSnackbarContext } from "./offline-snackbar-context/offline-snackbar-context";
import OfflineSnackbar from "./components/offline-snackbar/OfflineSnackbar";
import OfflineWarningModal from "./components/plan-warning/OfflineWarningModal";
// import {
//     DEVICE_INTEGRITY_CHANGED,
//     TIME_INTEGRITY_CHANGED,
//     CLIENT_INTEGRITY_CHANGED,
//     VERSION_INTEGRITY_CHANGED,
//     DEVICE_NOT_ACTIVE,
// } from "../../../resources/const";
import PlanBuyModal from "./components/plan-buy-modal/PlanBuyModal";
import { getAvailableIPsByWifiInfo } from "./api/server/storeApi";
import { getStore } from "./api/local/storeApi";
import { captureException } from "./crash-reporting";
import { FEATURES } from "./constants/POS-plans";
import { getAllOnlineSales } from "./api/local/onlineSale-api";
import store from "./store/store";
import ProformaInvoice from "./pages/ProformaInvoice/ProformaInvoice";
import PurchaseProforma from "./pages/purchase-proforma/PurchaseProforma";
import { Drawer } from "@mui/material";
import ProductDrawer from "./components/product-drawer/product-drawer";
import {
  setCloseProductDrawer,
  setIsProductNewSKU,
  setOpenProductDrawer,
  setProductDrawerData,
  setProductDrawerType,
} from "./store/productdrawer-slice";
import { generateSKU } from "./utils/sku-upc-generator";
import {
  removeProductFromPurchaseReturn,
  updateProductFromPurchaseReturn,
} from "./store/purchasereturn-slice";
import {
  removeProductFromDamageEntry,
  updateProductFromDamageEntry,
} from "./store/damageentry-slice";
import {
  removeProductFromPI,
  updateProductFromPI,
} from "./store/proformainvoice-slice";
import {
  removeProductFromPP,
  updateProductFromPP,
} from "./store/purchaseproforma-slice";
import HelpCenter from "./pages/HelpCenter/HelpCenter";
import NewPurchase from "./pages/new-purchase/NewPurchase";
import { errorNotify } from "./utils/toast";

function App() {
  const loaderStatus = useSelector((state) => state.app.loader);
  const { snackbarDetails, handleSnackbarDetails } =
    useContext(SnackbarContext);
  const [openNewCustModal, setOpenNewCustModal] = useState(false);
  const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
  const [openDrawer, _setOpenDrawer] = useState(false);
  const openDrawerRef = useRef(openDrawer);
  const setOpenDrawer = (data) => {
    openDrawerRef.current = data;
    _setOpenDrawer(data);
  };

  const [openNewEmpModal, setOpenNewEmpModal] = useState(false);
  const { orderSnackbarDetails, handleOrderSnackbarDetails } =
    useContext(OrderSnackbarContext);
  const { offlineSnackbarDetails, handleOfflineSnackbarDetails } = useContext(
    OfflineSnackbarContext
  );
  const { offlineDialogDetails, handleOfflineDialogDetails } =
    useContext(OfflineDialogContext);
  const { params } = useSelector((state) => state.onlineSale);
  const { openBuyDialog } = useSelector((state) => state.app);
  const { employee } = useSelector((state) => state.session);
  const sessionStore = useSelector((state) => state.session).store;
  const {
    openProductDrawer,
    productDrawerType,
    productDrawerData,
    isProductNewSKU,
  } = useSelector((state) => state.productDrawer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalRef = useRef();
  const newPurchaseRef = useRef();
  const newPurchaseReturnRef = useRef();
  const newSaleRef = useRef();
  const viewSaleRef = useRef();
  const purchaseRef = useRef();
  const salesRef = useRef();
  const inventoryRef = useRef();
  const staffRef = useRef();
  const suppliersRef = useRef();
  const customerRef = useRef();
  const onlineOrderRef = useRef();
  const expenseRef = useRef();
  const cashDrawerRef = useRef();
  const proformaInvoiceRef = useRef();
  const purchaseProformaRef = useRef();
  let path = history.location.pathname.split("/")[2];

  const [loginState, _setLoginState] = useState(PASSWORD_CHANGE_STATE.LOGIN);
  const loginStateRef = useRef(loginState);
  const setLoginState = (data) => {
    loginStateRef.current = data;
    _setLoginState(data);
  };
  const { features } = useSelector((state) => state.app);

  window.electronAPI = {
    onBarcodeScanned: (event, value) => {
      return function () {};
    },
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    updateOnlineStatus();
    document.addEventListener("keydown", handleDetectKeyDown, true);
    if (!process.env.REACT_APP_DEMO_SERVER) {
      //Online sale
      // window.electronAPI.onOnlineSale(async (event, value) => {
      //     let newOrdersCount = store.getState().onlineSale.newOrdersCount;
      //     dispatch(fetchAllOnlineSales(params));
      //     dispatch(setOrdersCount(newOrdersCount + 1));
      //     handleOrderSnackbarDetails({
      //         show: true,
      //         title: "New online order",
      //         subtitle: `You received an online order. Click to view.`,
      //     });
      // });
      // //Reports
      // window.electronAPI.onReportSaved(async (event, value) => {
      //     handleSnackbarDetails({
      //         show: true,
      //         type: value.success ? "success" : "error",
      //         title: value.success ? "Successful" : "Failed",
      //         subtitle: value.message,
      //     });
      // });
      // //Device data
      // const removeOnDeviceDataListener = window.electronAPI.onDeviceData(
      //     async (event, value) => {
      //         dispatch(setDeviceData(value));
      //         removeOnDeviceDataListener();
      //     }
      // );
      // //Logged Out
      // // const removeOnStoreLoggedOutListener =
      // //     window.electronAPI.onStoreLoggedOut(async (event, data) => {
      // //         if (data.logged_out) {
      // //             if (data.code === DEVICE_INTEGRITY_CHANGED) {
      // //             } else if (data.code === TIME_INTEGRITY_CHANGED) {
      // //             } else if (data.code === CLIENT_INTEGRITY_CHANGED) {
      // //             } else if (data.code === VERSION_INTEGRITY_CHANGED) {
      // //             } else if (data.code === DEVICE_NOT_ACTIVE) {
      // //                 setLoginState(PASSWORD_CHANGE_STATE.LIMIT);
      // //             }
      // //             //Clear all slice
      // //             dispatch({ type: "LOGOUT" });
      // //             navigate("/login");
      // //         } else {
      // //             handleSnackbarDetails({
      // //                 show: true,
      // //                 type: "error",
      // //                 title: "Failed to logout!",
      // //                 subtitle: "Please try again",
      // //             });
      // //         }
      // //         removeOnStoreLoggedOutListener();
      // //     });
      // //Device offline
      // window.electronAPI.onDeviceOffline(async (event, data) => {
      //     if (!data || !data.type || !data.content) {
      //         return;
      //     }
      //     if (data.type === "DIALOG") {
      //         handleOfflineDialogDetails({
      //             show: true,
      //             title: data.content.title,
      //             subTitle: data.content.sub_title,
      //             supportText: data.content.support_text,
      //             stepsTitle: data.content.steps_title,
      //             steps: data.content.steps,
      //         });
      //     } else if (data.type === "SNACKBAR") {
      //         handleOfflineSnackbarDetails({
      //             show: true,
      //             title: data.content.title,
      //             subTitle: data.content.sub_title,
      //             cta: data.content.cta,
      //         });
      //     }
      // });
      // //Subscription plan
      // const removeOnCurrentPlanListener =
      //     window.electronAPI.onCurrentPlan(async (event, data) => {
      //         dispatch(setPOSFeatures(data.features));
      //         dispatch(setPOSPlan(data));
      //         removeOnCurrentPlanListener();
      //     });
      // //Show snackbar
      // window.electronAPI.onShowSnackbar((event, data) => {
      //     handleSnackbarDetails({
      //         ...data,
      //         show: true,
      //     });
      // });
    }

    handleGetStoreData();
    handleGetAppPreferences();

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    let onlineSalesId;
    if (sessionStore && employee) {
      if (sessionStore?.sale_online) {
        handleGetOnlineOrders();
        if (!onlineSalesId) {
          onlineSalesId = setInterval(
            handleGetOnlineOrders,
            ONLINE_SALE_CRON_TIME
          );
        }
      }
    }
    return () => {
      clearInterval(onlineSalesId);
    };
  }, [sessionStore, employee]);

  useEffect(() => {
    checkRoutes();
  }, [path]);

  useEffect(() => {
    if (features.includes(FEATURES.LOYALTY_POINT)) {
      handleGetLoyaltyPreference();
    }
    handleGetPrintingPreference();
  }, [features]);

  const checkRoutes = () => {
    if (!PURCHASE_ROUTES.includes(path)) {
      dispatch(resetSelectedTab());
      dispatch(resetSelectedHeaderTab());
      dispatch(resetCurrentPurchaseScreen());
    }
    if (!SALES_ROUTES.includes(path)) {
      dispatch(resetActiveTab());
      dispatch(resetActiveTableTab());
    }
  };

  const handleEmployeeLogout = () => {
    dispatch(removeEmployeeSession());
    navigate("/login");
  };

  function handleDetectKeyDown(e) {
    detectKeyDown(
      e,
      history,
      navigate,
      globalRef,
      newPurchaseRef,
      newPurchaseReturnRef,
      newSaleRef,
      viewSaleRef,
      purchaseRef,
      salesRef,
      inventoryRef,
      staffRef,
      suppliersRef,
      customerRef,
      setOpenNewCustModal,
      setOpenAddSupplierModal,
      setOpenDrawer,
      setOpenNewEmpModal,
      handleEmployeeLogout,
      openNewCustModal,
      openAddSupplierModal,
      openDrawer,
      openNewEmpModal,
      onlineOrderRef,
      expenseRef,
      cashDrawerRef,
      proformaInvoiceRef,
      purchaseProformaRef
    );
  }

  const handleOrderClick = async () => {
    navigate("/dashboard/online-orders");
  };

  const updateOnlineStatus = () => {
    const isOnline = navigator.onLine;
    dispatch(setAppOnline(isOnline));
    if (isOnline && offlineDialogDetails.show) {
      handleOfflineDialogDetails({
        show: false,
        title: "",
        subTitle: "",
        supportText: "",
        stepsTitle: "",
        steps: [],
      });
    } else if (isOnline && offlineSnackbarDetails.show) {
      handleOfflineSnackbarDetails({
        show: false,
        title: "",
        subTitle: "",
        cta: "",
      });
    }
  };

  const getStoreData = async () => {
    try {
      const response = await getStore();
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      captureException(e);
      return null;
    }
  };

  const handleGetStoreData = async () => {
    try {
      const token = localStorage.getItem("serviceToken");
      if (token) {
        let result = await getStore();
        if (result.status === 200) {
          const entity = result?.data?.entities?.find(
            (e) => e.auth_type === "AUTH_TYPE_CLINIC"
          );
          if (entity) {
            await dispatch(setStore(entity));
            navigate("/dashboard/new-sale");
          } else {
            errorNotify(result.data?.message ?? "Account doesn't exists");
            throw new Error(result.data?.message ?? "Account doesn't exists");
          }
        } else {
          errorNotify(result.data?.message ?? "Account doesn't exists");
          throw new Error(result.data?.message ?? "Account doesn't exists");
        }
      } else {
        navigate("/login");
      }
    } catch (e) {
      navigate("/login");
    }
  };

  const handleGetAppPreferences = () => {
    handleGetSalePreference();
    handleGetBillTypePreference();
  };
  const handleGetSalePreference = async () => {
    try {
      let saleConfig = await window.electronAPI.getAppPreference("sale");
      dispatch(setSalePreference(saleConfig));
    } catch (err) {
      captureException(err);
    }
  };
  const handleGetLoyaltyPreference = async () => {
    try {
      let loyaltyConfig = await window.electronAPI.getAppPreference("loyalty");
      dispatch(setLoyaltyPreference(loyaltyConfig));
    } catch (err) {
      captureException(err);
    }
  };
  const handleGetPrintingPreference = async () => {
    try {
      let printingConfig = await window.electronAPI.getAppPreference(
        "printing"
      );
      dispatch(setPrintingPreference(printingConfig));
    } catch (err) {
      captureException(err);
    }
  };

  const handleGetBillTypePreference = async () => {
    try {
      let saleBillType = localStorage.getItem("sale_bill_type");
      dispatch(
        setBillTypePreference({
          sale_bill_type: saleBillType,
        })
      );
    } catch (err) {
      captureException(err);
    }
  };

  const handleGetOnlineOrders = async () => {
    try {
      let res = await getAllOnlineSales({
        status: `${ONLINE_ORDER_PARAMS_STATUS.ORDERED}`,
      });
      if (res.status === 200) {
        let cronOrders = store.getState().onlineSale.cronOrders;

        let newOrderCount =
          res.data.length -
          cronOrders[ONLINE_ORDER_PARAMS_STATUS.ORDERED].length;
        if (newOrderCount > 0) {
          dispatch(setOrdersCount(res.data.length));
          let notification = {
            title: "New online order",
            body: `You received ${newOrderCount} online order. Click to view.`,
          };
          await window.electronAPI.showNotification({
            title: notification.title,
            body: notification.body,
          });
          handleOrderSnackbarDetails({
            show: true,
            title: notification.title,
            subtitle: notification.body,
          });
        }
        dispatch(
          setCronOrders({
            orders: res.data,
            status: ONLINE_ORDER_PARAMS_STATUS.ORDERED,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeProductDrawer = (product, type) => {
    dispatch(setIsProductNewSKU(false));
    dispatch(setCloseProductDrawer());
    dispatch(setProductDrawerData(null));
    dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.ADD));
    if (type === "Edited" && product.sku) {
      if (path === "sales" || path === "new-sale") {
        dispatch(updateProductFromSale(product));
      } else if (path === "new-purchase") {
        dispatch(updateProductFromPurchase(product));
      } else if (path === "new-purchase-return") {
        dispatch(updateProductFromPurchaseReturn(product));
      } else if (path === "new-damage-entry") {
        dispatch(updateProductFromDamageEntry(product));
      } else if (path === "proforma-invoice") {
        dispatch(updateProductFromPI(product));
      } else if (path === "purchase-proforma") {
        dispatch(updateProductFromPP(product));
      }
    } else if (type === "Deleted") {
      if (path === "sales" || path === "new-sale") {
        dispatch(removeProductFromSale(product));
      } else if (path === "new-purchase") {
        dispatch(removeProductFromPurchase(product));
      } else if (path === "new-purchase-return") {
        dispatch(removeProductFromPurchaseReturn(product));
      } else if (path === "new-damage-entry") {
        dispatch(removeProductFromDamageEntry(product));
      } else if (path === "proforma-invoice") {
        dispatch(removeProductFromPI(product));
      } else if (path === "purchase-proforma") {
        dispatch(removeProductFromPP(product));
      }
    }
  };
  const onAddNewSKU = (productData) => {
    //Close the existing drawer
    dispatch(setCloseProductDrawer());
    //Open the drawer with new data
    dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.ADD));
    dispatch(
      setProductDrawerData({
        ...productData,
        sku: generateSKU(),
        batch_number: "",
        manufacturing_date: null,
        expiry_date: null,
      })
    );

    dispatch(setIsProductNewSKU(true));
    dispatch(setOpenProductDrawer());
  };

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop="true"
        open={openProductDrawer}
        onClose={closeProductDrawer}
        className="right-drawer"
      >
        <ProductDrawer
          open={openProductDrawer}
          type={productDrawerType}
          productData={productDrawerData}
          onCloseDrawer={(product, type) => closeProductDrawer(product, type)}
          onAddNewSKU={onAddNewSKU}
          isNewSKU={isProductNewSKU}
        />
      </Drawer>
      <AddNewCustomerModal
        open={openNewCustModal}
        onClose={() => setOpenNewCustModal(false)}
      />
      <NewSupplierModal
        open={openAddSupplierModal}
        onClose={() => setOpenAddSupplierModal(false)}
        onSave={() => {}}
      />
      <AddNewEmp
        open={openNewEmpModal}
        onClose={() => setOpenNewEmpModal(false)}
      />
      <ToastContainer />
      <CustomSnackbar
        data={snackbarDetails}
        onClose={(data) => handleSnackbarDetails(data)}
      />
      <OfflineSnackbar
        data={offlineSnackbarDetails}
        onClose={(data) => handleOfflineSnackbarDetails(data)}
      />
      <PlanBuyModal
        open={openBuyDialog}
        onClose={() => dispatch(setOpenBuyDialog(false))}
      />
      <OfflineWarningModal
        data={offlineDialogDetails}
        onClose={(data) => handleOfflineDialogDetails(data)}
      />
      <OrderSnackbar
        data={orderSnackbarDetails}
        onClose={(data) => handleOrderSnackbarDetails(data)}
        onClick={handleOrderClick}
      />
      {loaderStatus && <Loader />}
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        {/* <Route path="/" element={<OnboardingLoading />} /> */}
        <Route path="/service-unavailable" element={<ServiceUnavailable />} />
        <Route path="/registration" element={<RegistrationScreen />} />
        <Route
          path="/login"
          element={
            <LoginScreen
              loginState={loginState}
              setLoginState={setLoginState}
              loginStateRef={loginStateRef}
            />
          }
        />
        <Route path="/employee-login" element={<EmployeeLogin />} />
        <Route path="/database-sync" element={<OnboardingLoading />} />
        <Route path="/onboarding" element={<OnboardingScreen />} />
        <Route
          path="/dashboard"
          element={
            <DashboardLayout
              globalRef={globalRef}
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
            />
          }
        >
          {/* Sale Management */}
          {/* <Route path="new-sale" element={<AddProduct />} />
                    <Route path="online-orders" element={<AddProduct />} /> */}
          <Route path="sales" element={<Sales ref={newSaleRef} />} />
          <Route path="new-sale" element={<Sales ref={newSaleRef} />} />
          <Route path="all-sales" element={<AllSales ref={salesRef} />} />
          <Route path="view-sale" element={<ViewSale ref={viewSaleRef} />} />
          <Route
            path="return-sale"
            element={<ReturnSale ref={viewSaleRef} />}
          />
          <Route path="customers" element={<Customers ref={customerRef} />} />
          <Route
            path="online-orders"
            element={
              <OnlineOrders
                ref={onlineOrderRef}
                globalRef={globalRef}
                openDrawerRef={openDrawerRef}
              />
            }
          />
          <Route path="online-settings" element={<OnlineSettings />} />
          <Route
            path="proforma-invoice"
            element={<ProformaInvoice ref={proformaInvoiceRef} />}
          />
          {/* <Route path="discounts" element={<AddProduct />} /> */}

          {/* Purchase Management */}
          <Route
            path="new-purchase"
            element={<NewPurchase ref={newPurchaseRef} />}
          />
          <Route
            path="view-purchase"
            element={<PurchaseDetails ref={newPurchaseRef} />}
          />
          <Route
            path="new-purchase-return"
            element={
              <ReturnDetails
                type="New Purchase Return"
                ref={newPurchaseReturnRef}
              />
            }
          />
          <Route
            path="new-damage-entry"
            element={
              <ReturnDetails
                type="New Damage Entry"
                ref={newPurchaseReturnRef}
              />
            }
          />
          <Route
            path="purchases"
            element={<PurchaseView ref={purchaseRef} />}
          />
          <Route path="suppliers" element={<Suppliers ref={suppliersRef} />} />
          <Route
            path="purchase-proforma"
            element={<PurchaseProforma ref={purchaseProformaRef} />}
          />

          {/* Inventory Management */}
          <Route path="inventory" element={<Inventory ref={inventoryRef} />} />

          {/* Marketing */}
          {/* <Route path="new-campaign" element={< AddProduct />} />
                    <Route path="campaigns" element={<Inventory />} /> */}

          {/* Analytics */}
          <Route path="analytics" element={<Analytics />} />
          <Route path="pl-report" element={<PLReport />} />
          <Route path="download-reports" element={<DownloadReports />} />

          {/* Staff Management */}
          <Route path="employees" element={<Employees ref={staffRef} />} />

          {/* Settings */}
          <Route path="settings" element={<Settings />} />

          {/* Cash Drawer */}
          <Route path="expenses" element={<Expenses ref={expenseRef} />} />
          <Route
            path="cash-drawer"
            element={<CashDrawer ref={cashDrawerRef} />}
          />

          {/* Help Center */}
          <Route path="help-center" element={<HelpCenter />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
