import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as S from "../style/style";
import { addDays } from "date-fns";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIconSolid, FilterList, TimeChevronRight } from "../../../icons";
import { TIME_PERIOD } from "../../../constants/appConstants";
import { Bar } from "react-chartjs-3";
import moment from "moment";
import { formatDate } from "../../../utils/format-date";
import { getCustomerReportData } from "../../../api/local/analytics-api";
import DownloadButton from "../../download-button/DownloadButton";
import { captureException } from "../../../crash-reporting";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};

const initialData = {
    labels: [],
    datasets: [
        {
            label: "Unknown Customers",
            data: [],
            backgroundColor: "#FFA5CB",
            borderColor: "#d6025d",
            borderWidth: 2,
            barPercentage: 0.4,
        },
        {
            label: "Existing Customers",
            data: [],
            backgroundColor: "#63ABFD",
            borderColor: "#165BAA",
            borderWidth: 2,
            barPercentage: 0.4,
        },
        {
            label: "New Customers",
            data: [],
            backgroundColor: "#E697FF",
            borderColor: "#A155B9",
            borderWidth: 2,
            barPercentage: 0.4,
        },
    ],
};

const initialParams = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
};
const CustomerReport = () => {
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [period, setPeriod] = useState(null);
    const [customerChartData, setCustomerChartData] = useState(initialData);
    const [params, setParams] = useState(initialParams);
    const [displayDate, setDisplayDate] = useState(`${formatDate(initialParams.start_date)} - ${formatDate(initialParams.end_date)}`);
    const [totalDatas, setTotalDatas] = useState(null);

    useEffect(() => {
        handleCustomerReportData();
    }, [params]);

    const handleDateChange = (item) => {
        let startDate = new Date(item.selection.startDate).getTime();
        let endDate = new Date(item.selection.endDate).getTime();
        if (endDate > Date.now()) {
            endDate = Date.now();
            setDateRange([{ ...item.selection, endDate: new Date(Date.now()) }]);
        } else {
            setDateRange([item.selection]);
        }
        setDisplayDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        setParams({ start_date: startDate, end_date: endDate });
        if (startDate !== endDate) {
            setOpenDatePicker(false);
        }
    };

    const handleCustomerReportData = async () => {
        try {
            let res = await getCustomerReportData(params);
            if (res.status === 200) {
                setTotalDatas(res.data);

                //for chart data
                let graphData = res.data.graph;
                const chartData = {
                    labels: graphData.new_customer.map((item) => item.t),
                    datasets: [
                        {
                            label: "Unknown Customers",
                            data: graphData.unknown_customer.map((item) => item.value),
                            backgroundColor: "#FFA5CB",
                            borderColor: "#d6025d",
                            borderWidth: 2,
                            barPercentage: 0.4,
                        },
                        {
                            label: "Existing Customers",
                            data: graphData.existing_customer.map((item) => item.value),
                            backgroundColor: "#63ABFD",
                            borderColor: "#165BAA",
                            borderWidth: 2,
                            barPercentage: 0.4,
                        },
                        {
                            label: "New Customers",
                            data: graphData.new_customer.map((item) => item.value),
                            backgroundColor: "#E697FF",
                            borderColor: "#A155B9",
                            borderWidth: 2,
                            barPercentage: 0.4,
                        },
                    ],
                };
                setCustomerChartData(chartData);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    return (
        <div>
            <S.Heading>Customer Report</S.Heading>
            <S.Container>
                <S.FilterBar>
                    <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                        <S.dateContainer>
                            <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                <img src={CalendarIconSolid} alt="calendar" />
                                <div>{displayDate}</div>
                            </S.DateChooserSelect>
                            {openDatePicker && (
                                <S.DatePickerContainer>
                                    <DateRangePicker
                                        editableDateInputs={true}
                                        onChange={handleDateChange}
                                        ranges={dateRange}
                                        maxDate={addDays(new Date(), 0)}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </S.dateContainer>
                    </OutsideClickHandler>
                    {/* <S.TimePeriodContainer>
                        <S.TimePeriod onClick={() => setOpenMenu(!openMenu)}>
                            <div>Time Period</div>
                            <img src={FilterList} alt="" />
                        </S.TimePeriod>
                        {openMenu && (
                            <OutsideClickHandler
                                onOutsideClick={() => setOpenMenu(false)}
                            >
                                <S.PeriodMenu>
                                    {TIME_PERIOD?.map((item) => (
                                        <S.PeriodRow
                                            onClick={() => {
                                                setPeriod(item.value);
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <div>{item.name}</div>
                                            <img
                                                src={TimeChevronRight}
                                                alt=""
                                            />
                                        </S.PeriodRow>
                                    ))}
                                </S.PeriodMenu>
                            </OutsideClickHandler>
                        )}
                    </S.TimePeriodContainer> */}
                </S.FilterBar>
                <S.DataContainer>
                    <div>
                        <S.DataLabel>Total Customers</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_customer}</S.DataDiv>
                        {/* <S.Date>October 2022</S.Date> */}
                    </div>
                    <div>
                        <S.DataLabel>Total New Customers</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_new_customer}</S.DataDiv>
                        {/* <S.Date>October 2022</S.Date> */}
                    </div>
                    <div>
                        <S.DataLabel>Total Existing Customers</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_existing_customer}</S.DataDiv>
                        {/* <S.Date>October 2022</S.Date> */}
                    </div>
                    <div>
                        <S.DataLabel>Total Unknown Customers</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_unknown_customer}</S.DataDiv>
                        {/* <S.Date>October 2022</S.Date> */}
                    </div>
                </S.DataContainer>
                <S.ChartContainer>
                    <Bar
                        data={customerChartData}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        stacked: true,
                                    },
                                ],
                                yAxes: [
                                    {
                                        beginAtZero: true,
                                        stacked: true,
                                    },
                                ],
                            },
                            legend: {
                                position: "bottom",
                                align: "start",
                                reverse: true,
                            },
                        }}
                    />
                </S.ChartContainer>
            </S.Container>
        </div>
    );
};

export default CustomerReport;
