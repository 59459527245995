import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 680px;
    // min-height: 522px;
`;

export const Header = styled.div`
    padding: 16px 16px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;

    img {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
`;

export const Body = styled.div`
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 6px;
`;

export const SupplierDetail = styled.div`
    background: #f1f5f9;
    padding: 12px;

    .supplier-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #334155;
        margin-bottom: 4px;
    }

    .supplier-mobile {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
    }
`;

export const PriceDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;

export const TotalDetails = styled.div`
    font-weight: 600;
    font-size: 14.214px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 6px;
    border-top: 1px dashed #e2e8f0;
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const DueHeadline = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #334155;
    text-align: center;
    margin-bottom: -12px;
    margin-top: -4px;
    span {
        color: #2d68fe;
    }
`;

export const TemplateContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const TemplateBox = styled.div`
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid ${(props) => (props.active ? "#2d68fe" : "#d9d9d9")};
    padding: 6px;
    cursor: pointer;
    ${(props) => props.active && "box-shadow: rgba(45, 104, 254, 0.16) 0px 5px 15px"};

    & > img {
        width: 62px;
        height: 62px;
    }
`;

export const TemplateText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
`;

export const PrintSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const HintText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`;
export const PrintText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;
`;
