import React from "react";
import Lottie from "lottie-react";
import CircularLoaderLottie from "../../animations/circular-loader.json";
import * as S from "./styles";

const CircularLoader = () => {
    return (
        <S.Wrapper>
            <S.AnimationDiv>
                <Lottie animationData={CircularLoaderLottie} />
            </S.AnimationDiv>
        </S.Wrapper>
    );
};

export default CircularLoader;
