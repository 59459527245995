import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ThreeDotsVerticalIcon } from "../../../icons";
import * as S from "./EmpTable.style";

const EmpRow = ({ item, openEditDialog }) => {
    const [open, setOpen] = useState(false);

    return (
        <S.TRow onClick={() => openEditDialog(item)}>
            <S.Name>
                <S.Avatar>
                    {item.name
                        .split(" ")
                        .map((e) => e[0])
                        .join("")
                        .toUpperCase()}
                </S.Avatar>
                <div>{item.name}</div>
            </S.Name>
            <S.DataBtn>POS Machine 1</S.DataBtn>
            <S.DataBtn>{item.phone}</S.DataBtn>
            <S.DataBtn>{item.permission_role ? item.permission_role.name : ""}</S.DataBtn>
            <S.StatusDiv color={item.is_activated ? "#10B981" : "#FF4343"}>
                {item.is_activated ? "Active" : "Not Active"}
                <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                    <img
                        src={ThreeDotsVerticalIcon}
                        alt=""
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                        }}
                    />
                    {open && <OptionsContainer onOpenDrawer={() => openEditDialog(item)} />}
                </OutsideClickHandler>
            </S.StatusDiv>
        </S.TRow>
    );
};

export default EmpRow;

const OptionsContainer = ({ onOpenDrawer, handleDeleteEmployee }) => {
    return (
        <S.OptionsContainer>
            <S.Option onClick={() => onOpenDrawer()}>Edit</S.Option>
            {/* <S.Option onClick={() => handleDeleteEmployee()}>Delete</S.Option> */}
        </S.OptionsContainer>
    );
};
