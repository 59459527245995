import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 65px;
`;

export const Text = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #334155;
    text-align: center;
`;
