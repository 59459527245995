import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 421px;
    height: 64px;
    background: #ffffff;
    border-left: 4px solid ${(props) => (props.success ? "#10b981" : props.error ? "#F43F5E" : "#fff")};
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px 16px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 33px;
    margin-right: -7px;

    img {
        height: 24px;
        width: 24px;
    }
`;

export const Message = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    margin-left: 16px;
    width: 80%;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #334155;
`;

export const Subtitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        color: #2d68fe;
        margin-left: 4px;
    }
`;
