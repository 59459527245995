import React, { useContext, useState } from "react";
import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { DrawerContext } from "../../DrawerContextProvider/DrawerProvider";
import { Drawer } from "@mui/material";
import history from "../../utils/history";
import AddNewEmp from "../EmployeesComponents/add-new-employee/AddNewEmp";
import * as S from "./SubMenu.style";
import ProductDrawer from "../product-drawer/product-drawer";
import { useSelector } from "react-redux";
import { PLANS } from "../../constants/POS-plans";

const SubMenu = ({ data, setOpen }) => {
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [openNewEmpModal, setOpenNewEmpModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const drawerDetails = useContext(DrawerContext);
    let path = history.location.pathname.split("/")[2];
    const { features } = useSelector((state) => state.app);

    useEffect(() => {
        handleDetectSubmenu();
    }, [path]);

    function handleDetectSubmenu() {
        for (let i = 0; i < data.length; i++) {
            if (path === data[i].navigate) {
                setActiveSubMenu(data[i].name);
                break;
            }
        }
    }
    function handleNavigation(item) {
        if (item.navigate) {
            if (item.navigate === "add-employee") {
                setOpenNewEmpModal(true);
            } else if (item.navigate === "add-product") {
                setOpenDrawer(true);
            } else {
                setActiveSubMenu(item.name);
                navigate(`/dashboard/${item.navigate}`);
            }
        }
    }
    return (
        // <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <>
            <AddNewEmp open={openNewEmpModal} onClose={() => setOpenNewEmpModal(false)} />
            <Drawer anchor="right" hideBackdrop="true" open={openDrawer} onClose={() => setOpenDrawer(false)} className="right-drawer">
                <ProductDrawer type="add" onCloseDrawer={() => setOpenDrawer(false)} open={openDrawer} />{" "}
            </Drawer>
            <S.Wrapper>
                {data?.map(
                    (item) =>
                        features.includes(item.key) && (
                            <S.Item
                                key={item.id}
                                open={drawerDetails.leftDrawerOpen}
                                onClick={() => {
                                    handleNavigation(item);
                                }}
                                active={activeSubMenu === item.name || path === item.navigate}
                            >
                                <S.ActiveMenu active={activeSubMenu === item.name || path === item.navigate}>{"a"}</S.ActiveMenu>
                                <div
                                    style={{
                                        marginRight: drawerDetails.leftDrawerOpen ? "13px" : "34px",
                                    }}
                                >
                                    <img
                                        src={activeSubMenu === item.name || path === item.navigate ? item.activeLogo : item.logo}
                                        alt="sub menu logo"
                                    />
                                </div>
                                <S.Name>{item.name}</S.Name>
                            </S.Item>
                        )
                )}
            </S.Wrapper>
        </>
        //  </OutsideClickHandler>
    );
};

export default SubMenu;
