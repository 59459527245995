import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { debounce } from "lodash";
import CustomSelect from "../../components/inventory/custom-select/custom-select";
import PaginationBar from "../../components/inventory/pagination-bar/pagination-bar";
import ProductsList from "../../components/inventory/products-list/products-list";
import SelectedFilterChips from "../../components/inventory/selected-filter-chips/selected-filter-chips";
import {
  ActiveListViewIcon,
  AddCustomerSearchIcon,
  InactiveGridViewIcon,
  InactiveListViewIcon,
  ActiveGridViewIcon,
  CloseModalIcon,
  DownloadBtnIcon,
} from "../../icons";

import * as S from "./inventory.styles";
import {
  removeAllSearchParams,
  removeSearchParams,
  removeSort,
  setProductsCountData,
  setSearchParams,
} from "../../store/product-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategories,
  removeSelectedCategory,
} from "../../store/category-slice";
import {
  fetchAllSuppliers,
  removeSelectedSupplier,
} from "../../store/supplier-slice";
import { fetchAllBrands, removeSelectedBrand } from "../../store/brand-slice";
import {
  INVENTORY_SELECT,
  INVENTORY_SORT_LIST,
  PRODUCT_STATUS,
  REPORTS_TYPE,
  STOCK_MAP_TYPES,
} from "../../constants/appConstants";
import ProductDrawer, {
  INITIAL_PRODUCT_DISPLAY_DATA,
} from "../../components/product-drawer/product-drawer";
import { DrawerContext } from "../../DrawerContextProvider/DrawerProvider";
import { Drawer } from "@mui/material";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { getProduct, getProductsCount } from "../../api/local/productApi";
import SelectProductModal from "../../components/select-product-modal/select-product-modal";
import { useRef } from "react";
import { generateSKU } from "../../utils/sku-upc-generator";
import ShortcutBtn from "../../components/shortcut-btn/ShortcutBtn";
import { removeSelectedChipStock } from "../../store/stock-slice";
import { generateInventoryReport } from "../../api/local/reports-api";
import moment from "moment";
import { captureException } from "../../crash-reporting";

const TABS = ["All", "My Items", "Items Library", "Draft"];
const initialRange = {
  start_date: moment().startOf("month").unix() * 1000,
  end_date: Date.now(),
};

const Inventory = (props, ref) => {
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state.category);
  const typesData = useSelector((state) => state.type);
  const brandsData = useSelector((state) => state.brand);
  const suppliersData = useSelector((state) => state.supplier);
  const searchParams = useSelector((state) => state.product.searchParams);
  const [selectedTab, setSelectedTab] = useState(null);
  const [listType, setListType] = useState("grid");
  const [productSearch, setProductSearch] = useState("");
  const [productDrawer, setProductDrawer] = useState(false);
  const [drawerDataChanged, setDrawerDataChanged] = useState(false);
  const drawerDetails = useContext(DrawerContext);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const { productsCountData, products } = useSelector((state) => state.product);
  const [openViewDrawer, _setOpenViewDrawer] = useState(false);
  const openViewDrawerRef = useRef(openViewDrawer);
  const setOpenViewDrawer = (data) => {
    openViewDrawerRef.current = data;
    _setOpenViewDrawer(data);
  };

  const [productData, setProductData] = useState(null);
  const [productDrawerType, setProductDrawerType] = useState("add");
  const [scannedProduct, setScannedProduct] = useState(null);
  const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
  const inputRef = useRef();
  const typeRef = useRef();
  const categoryRef = useRef();
  const brandRef = useRef();
  const stockRef = useRef();
  const tableRef = useRef();
  const sortRef = useRef();
  const pageRef = useRef();
  const selectRef = useRef(false);
  const trackRef = useRef(null);

  const [activeRow, _setActiveRow] = useState(0);
  const activeRowRef = React.useRef(activeRow);
  const setActiveRow = (data) => {
    activeRowRef.current = data;
    _setActiveRow(data);
  };

  const [openModal, _setOpenModal] = useState({
    show: false,
    type: null,
    data: null,
    product: null,
  });
  const openModalRef = useRef(openModal);
  const setOpenModal = (data) => {
    openModalRef.current = data;
    _setOpenModal(data);
  };

  const [isNewSKU, setIsNewSKU] = useState(false);

  const delayedFunction = (val, page) => {
    if (page) dispatch(setSearchParams({ search: val, page: 1 }));
    else dispatch(setSearchParams({ search: val }));
  };
  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

  useEffect(() => {
    dispatch(fetchAllCategories());
    dispatch(fetchAllSuppliers());
    dispatch(fetchAllBrands());
    handleTabSelect(PRODUCT_STATUS.MY_ITEMS, TABS[1]);

    return () => {
      dispatch(removeAllSearchParams());
      dispatch(removeSelectedCategory());
      dispatch(removeSelectedBrand());
      dispatch(removeSelectedSupplier());
    };
  }, []);

  useEffect(() => {
    if (!process.env.REACT_APP_DEMO_SERVER) {
      const removeEventListener = window.electronAPI.onBarcodeScanned(
        async (event, value) => await handleGetProduct(value)
      );
      return () => {
        removeEventListener();
      };
    }
  }, []);

  async function handleGetProduct(barcode) {
    try {
      let res = await getProduct(barcode, { status: "PUBLISH" });
      if (res.status === 200) {
        //Product is present
        if (res.data?.length > 1) {
          setScannedProduct(res.data);
          setOpenSelectProductModal(true);
        } else {
          setProductDrawerType("edit");
          setProductData(res.data[0]);
          setOpenViewDrawer(true);
        }
      } else if (res.status === 404) {
        //Product is not present, Show the product slider to add the product
        setProductDrawerType("add");
        setProductData({
          ...INITIAL_PRODUCT_DISPLAY_DATA,
          upc: barcode,
          sku: generateSKU(),
        });
        setOpenViewDrawer(true);
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      //console.log(e);
      captureException(err);
    }
  }

  useEffect(() => {
    handleGetProductsCount();
  }, [openViewDrawer, products]);

  useImperativeHandle(ref, () => {
    return {
      searchProduct: () => {
        if (openViewDrawer) return;
        inputRef.current?.focus();
      },
      searchType: () => {
        if (openViewDrawer) return;
        typeRef.current?.click();
      },
      searchCategory: () => {
        if (openViewDrawer) return;
        onCategory();
      },
      searchBrand: () => {
        if (openViewDrawer) return;
        onBrand();
      },
      searchStock: () => {
        if (openViewDrawer) return;
        onStock();
      },
      clearFilter: () => onClearFilter(),
      onSort: () => onSort(),
      addProduct: () => onAddProduct(),
      onClearSearch: () => {
        if (!openModalRef.current?.show) {
          clearSearch();
        }
      },
      onNextPage: () => pageRef.current?.onNextPageChange(),
      onPrevPage: () => pageRef.current?.onPrevPageChange(),
      changeTabs: () => {
        if (openViewDrawer || selectRef.current) {
          return;
        }
        selectedTab === TABS[0]
          ? handleTabSelect(PRODUCT_STATUS.MY_ITEMS, TABS[1])
          : selectedTab === TABS[1]
          ? handleTabSelect(PRODUCT_STATUS.ITEMS_LIBRARY, TABS[2])
          : selectedTab === TABS[2]
          ? handleTabSelect(PRODUCT_STATUS.DRAFT, TABS[3])
          : handleTabSelect(PRODUCT_STATUS.PUBLISH, TABS[0]);
      },
    };
  });

  const onBrand = () => {
    if (trackRef.current === "category") {
      categoryRef.current?.click();
    } else if (trackRef.current === "stock") {
      stockRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    }
    brandRef.current?.click();
  };

  const onCategory = () => {
    if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "stock") {
      stockRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    }
    categoryRef.current?.click();
  };
  const onStock = () => {
    if (trackRef.current === "category") {
      categoryRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    }
    stockRef.current?.click();
  };

  const onSort = () => {
    if (trackRef.current === "category") {
      categoryRef.current?.click();
    } else if (trackRef.current === "stock") {
      stockRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    }
    sortRef.current?.click();
  };

  const onAddProduct = () => {
    if (openViewDrawer) return;
    setProductDrawerType("add");
    setProductData({ ...INITIAL_PRODUCT_DISPLAY_DATA, sku: generateSKU() });
    setOpenViewDrawer(true);
  };
  const handleGetProductsCount = async () => {
    try {
      let res = await getProductsCount();
      if (res.status === 200) {
        dispatch(setProductsCountData(res.data));
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  };

  const onClearFilter = () => {
    dispatch(removeAllSearchParams());
    dispatch(removeSelectedCategory());
    dispatch(removeSelectedBrand());
    dispatch(removeSelectedSupplier());
    dispatch(removeSelectedChipStock());
    dispatch(removeSort());
    clearSearch();
  };
  const handleProductSearchChange = (e) => {
    setProductSearch(e.target.value);
    delayedQuery(e.target.value, searchParams.page);
  };

  const clearSearch = () => {
    setProductSearch("");
    dispatch(removeSearchParams("search"));
  };

  /**
   * My Items -->
   * is_global_product == false,
   * date_modified != null && date_modified > created_at
   */

  const handleTabSelect = (key, tab) => {
    setSelectedTab(tab);
    if (key === TABS[0]) {
      dispatch(removeSearchParams("status"));
      return;
    }
    if (key) dispatch(setSearchParams({ status: key }));
  };

  const onAddNewSKU = (productData) => {
    //Close the existing drawer
    setOpenViewDrawer(false);
    //Open the drawer with new data
    setProductDrawerType("add");
    setProductData({
      ...productData,
      sku: generateSKU(),
      batch_number: "",
      manufacturing_date: null,
      expiry_date: null,
    });
    setIsNewSKU(true);
    setOpenViewDrawer(true);
  };

  const handleDownloadSalesReport = async () => {
    handleSnackbarDetails({
      show: true,
      title: "Successful",
      type: "success",
      subtitle:
        "Your report is downloading, and we will notify you once it is downloaded",
    });
    let params = {
      start_date: searchParams.start_date ?? initialRange.start_date,
      end_date: searchParams.end_date ?? initialRange.end_date,
    };
    try {
      let res = await generateInventoryReport(params);
      if (res.status === 200) {
        window.electronAPI.saveReport({
          type: REPORTS_TYPE.INVENTORY_REPORT,
          data: res.data,
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  };
  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop="true"
        open={openViewDrawer}
        onClose={() => {
          setIsNewSKU(false);
          setOpenViewDrawer(false);
        }}
        className="right-drawer"
      >
        <ProductDrawer
          open={openViewDrawer}
          type={productDrawerType}
          productData={productData}
          onCloseDrawer={() => {
            setIsNewSKU(false);
            setOpenViewDrawer(false);
          }}
          setProductData={setProductData}
          onAddNewSKU={onAddNewSKU}
          isNewSKU={isNewSKU}
        />{" "}
      </Drawer>
      <SelectProductModal
        open={openSelectProductModal}
        onClose={() => setOpenSelectProductModal(false)}
        data={scannedProduct}
        openEditDrawer={(product) => {
          setProductDrawerType("edit");
          setProductData(product);
          setOpenViewDrawer(true);
        }}
      />
      <S.Container>
        <S.HeaderContainer>
          <S.Headline>Products</S.Headline>{" "}
          <S.RightHeaderContainer>
            {/* <S.TodayDataContainer>
                            <S.WhiteBox>
                                <div>Total Inventory</div>
                                <S.BlueBox>₱ 1,12,20,897</S.BlueBox>
                            </S.WhiteBox>
                        </S.TodayDataContainer> */}
            <S.AddProductBtn onClick={() => onAddProduct()}>
              Add Product (F12)
            </S.AddProductBtn>
          </S.RightHeaderContainer>
        </S.HeaderContainer>
        <S.Body>
          <S.TabsContainer>
            <S.Tabs>
              <S.Tab
                onClick={() => handleTabSelect(PRODUCT_STATUS.PUBLISH, TABS[0])}
                active={selectedTab === TABS[0]}
              >
                {TABS[0]}
                {productsCountData && (
                  <div className="inventory-count">
                    {productsCountData?.PUBLISHED}
                  </div>
                )}
              </S.Tab>
              <S.Tab
                onClick={() =>
                  handleTabSelect(PRODUCT_STATUS.MY_ITEMS, TABS[1])
                }
                active={selectedTab === TABS[1]}
              >
                {TABS[1]}
                {productsCountData && (
                  <div className="inventory-count">
                    {productsCountData?.MY_ITEMS}
                  </div>
                )}
              </S.Tab>
              <S.Tab
                onClick={() =>
                  handleTabSelect(PRODUCT_STATUS.ITEMS_LIBRARY, TABS[2])
                }
                active={selectedTab === TABS[2]}
              >
                {TABS[2]}
                {productsCountData && (
                  <div className="inventory-count">
                    {productsCountData?.ITEMS_LIBRARY}
                  </div>
                )}
              </S.Tab>
              <S.Tab
                onClick={() => handleTabSelect(PRODUCT_STATUS.DRAFT, TABS[3])}
                active={selectedTab === TABS[3]}
              >
                {TABS[3]}
                {productsCountData && (
                  <div className="inventory-count">
                    {productsCountData?.DRAFT}
                  </div>
                )}
              </S.Tab>
              <ShortcutBtn text="TAB" />
            </S.Tabs>
            <S.FiltersRight>
              Sort (F4) :{" "}
              <CustomSelect
                type={INVENTORY_SELECT.SORT_SELECT}
                clickRef={sortRef}
                selectRef={selectRef}
                data={INVENTORY_SORT_LIST || []}
                name="sort"
                trackRef={trackRef}
              />
              <S.ChangeView>
                <img
                  onClick={() => {
                    setListType("grid");
                    setActiveRow(0);
                  }}
                  src={
                    listType === "grid"
                      ? ActiveGridViewIcon
                      : InactiveGridViewIcon
                  }
                  alt="grid-view"
                />
                <img
                  onClick={() => {
                    setListType("table");
                    setActiveRow(0);
                  }}
                  src={
                    listType === "table"
                      ? ActiveListViewIcon
                      : InactiveListViewIcon
                  }
                  alt="list-view"
                />
              </S.ChangeView>
              <S.DownloadContainer onClick={handleDownloadSalesReport}>
                <img src={DownloadBtnIcon} alt="download icon" />
              </S.DownloadContainer>
            </S.FiltersRight>
          </S.TabsContainer>

          <S.Content open={drawerDetails.leftDrawerOpen}>
            <S.FiltersContainer>
              <S.FiltersLeft>
                <S.InputContainer>
                  <img src={AddCustomerSearchIcon} alt="search" />
                  <S.Input
                    value={productSearch}
                    onChange={handleProductSearchChange}
                    type="text"
                    placeholder="Search by product name, UPC or SKU"
                    ref={inputRef}
                  />
                  {productSearch && (
                    <img
                      onClick={clearSearch}
                      src={CloseModalIcon}
                      alt="clear"
                    />
                  )}
                  <ShortcutBtn text="F10" />
                </S.InputContainer>

                {/* <CustomSelect
                                    type={INVENTORY_SELECT.NORMAL_SELECT}
                                    data={typesData.filteredTypes || []}
                                    text="Type"
                                    clickRef={typeRef}
                                /> */}
                <CustomSelect
                  type={INVENTORY_SELECT.NORMAL_SELECT}
                  data={categoryData.categories || []}
                  text="Category"
                  clickRef={categoryRef}
                  scText="F9"
                  selectRef={selectRef}
                  name="category"
                  trackRef={trackRef}
                />
                <CustomSelect
                  type={INVENTORY_SELECT.NORMAL_SELECT}
                  data={brandsData.brands || []}
                  text="Brands"
                  clickRef={brandRef}
                  scText="F8"
                  selectRef={selectRef}
                  name="brand"
                  trackRef={trackRef}
                />
                <CustomSelect
                  type={INVENTORY_SELECT.STOCK_SELECT}
                  text="Stock"
                  clickRef={stockRef}
                  scText="F7"
                  selectRef={selectRef}
                  data={STOCK_MAP_TYPES || []}
                  name="stock"
                  trackRef={trackRef}
                />
                {/* <CustomSelect
                type={INVENTORY_SELECT.NORMAL_SELECT}
                data={suppliersData.suppliers || []}
                text="Supplier"
              /> */}
                {/* <CustomSelect data={tagsData || []} text="Tags" /> */}
                {/* <CustomSelect withoutCaretIcon={true} text="More Filters" /> */}
              </S.FiltersLeft>
            </S.FiltersContainer>

            <SelectedFilterChips clearSearch={clearSearch} />

            <ProductsList
              type={listType}
              tableRef={tableRef}
              activeRow={activeRow}
              activeRowRef={activeRowRef}
              setActiveRow={setActiveRow}
              openViewDrawerRef={openViewDrawerRef}
              openEditDrawer={(product) => {
                setProductDrawerType("edit");
                setProductData(product);
                setOpenViewDrawer(true);
              }}
              selectRef={selectRef}
              openModalRef={openModalRef}
              setOpenModal={setOpenModal}
              openModal={openModal}
            />
            <PaginationBar tableRef={tableRef} ref={pageRef} />
          </S.Content>
        </S.Body>
      </S.Container>
    </>
  );
};

export default React.forwardRef(Inventory);
