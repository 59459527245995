import React, { useContext } from "react";
import * as S from "./style";
import OnlineStore from "../../../assets/images/gif/online-store.gif";
import {
    BackArrowIcon,
    BlueSelectTickIcon,
    ChevronRightWhite,
    CustomPosIcon,
    NoIcon,
    YesIcon,
} from "../../../icons";
import Bar from "../page-bar/Bar";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingData } from "../../../store/app-slice";

const PosType = ({ setActivePage }) => {
    const { onboardingData } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const { handleSnackbarDetails } = useContext(SnackbarContext);

    const handleExistPos = (data) => {
        dispatch(setOnboardingData({ ...onboardingData, existing_pos: data }));
    };
    const handleSelectPos = (item) => {
        dispatch(setOnboardingData({ ...onboardingData, pos: item.name, custom_pos: false }));
    };
    const handleCustomePos = () => {
        dispatch(setOnboardingData({ ...onboardingData, pos: "", custom_pos: true }));
    };
    const changeCustomPos = (e) => {
        dispatch(setOnboardingData({ ...onboardingData, pos: e.target.value }));
    };

    const onNextChange = () => {
        if (onboardingData?.existing_pos && !onboardingData?.pos) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a POS",
                type: "error",
            });
            return;
        }
        setActivePage(2);
    };

    return (
        <S.Wrapper>
            <S.LeftImgContainer>
                <img src={OnlineStore} alt="online store" />
            </S.LeftImgContainer>
            <S.RightContainer>
                <div></div>
                <div>
                    <S.Container>
                        <S.Text>Are you already using a POS or ERP solution?</S.Text>
                        <S.ImageContainer>
                            <S.ImageBlock onClick={() => handleExistPos(true)}>
                                {onboardingData?.existing_pos && (
                                    <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                )}
                                <img src={YesIcon} alt="yes icon" />
                                <div>Yes</div>
                            </S.ImageBlock>
                            <S.ImageBlock onClick={() => handleExistPos(false)}>
                                {!onboardingData?.existing_pos && (
                                    <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                )}
                                <img src={NoIcon} alt="no icon" />
                                <div>No</div>
                            </S.ImageBlock>
                        </S.ImageContainer>
                    </S.Container>
                    {onboardingData?.existing_pos && (
                        <S.Container>
                            <S.Text>Please select the POS / ERP you are currently using</S.Text>
                            <S.ImageContainer>
                                {onboardingData?.existing_pos_list.map((item) => (
                                    <S.ImageBlock onClick={() => handleSelectPos(item)}>
                                        {onboardingData?.pos === item.name && (
                                            <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                        )}
                                        <img src={item.image} alt="pos icon" />
                                        <div>{item.name}</div>
                                    </S.ImageBlock>
                                ))}
                                <S.ImageBlock onClick={handleCustomePos}>
                                    {onboardingData?.custom_pos && (
                                        <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                    )}
                                    <img src={CustomPosIcon} alt="pos icon" />
                                    <div>Custom</div>
                                </S.ImageBlock>
                            </S.ImageContainer>
                            {onboardingData?.custom_pos && (
                                <S.InputContainer>
                                    <S.CustomInput
                                        type="text"
                                        value={onboardingData?.pos}
                                        onChange={changeCustomPos}
                                        placeholder="Enter your POS name"
                                        autoFocus
                                    />
                                    {/* <S.SaveBtn>Save</S.SaveBtn> */}
                                </S.InputContainer>
                            )}
                        </S.Container>
                    )}
                </div>
                <S.FooterDiv>
                    <S.WhiteButton onClick={() => setActivePage(0)}>
                        <img src={BackArrowIcon} alt="back" />
                    </S.WhiteButton>
                    <S.BarContainer>
                        <Bar />
                        <Bar size="lg" />
                        <Bar />
                        {/* <Bar /> */}
                    </S.BarContainer>
                    <S.DarkButton onClick={() => onNextChange()}>
                        Next
                        <img src={ChevronRightWhite} alt="" />
                    </S.DarkButton>
                </S.FooterDiv>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default PosType;
