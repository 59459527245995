import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import DrawerProvider from "./DrawerContextProvider/DrawerProvider";
import SnackbarProvider from "./snackbar-context/snackbar-context";
import store from "./store/store";
import history from "./utils/history";
import OrderSnackbarProvider from "./order-snackbar-context/order-snackbar-context";
import { initSentry } from "./crash-reporting";
import OfflineSnackbarProvider from "./offline-snackbar-context/offline-snackbar-context";
import OfflineDialogProvider from "./offline-dialog-context/offline-dialog-context";

initSentry();

ReactDOM.render(
    <React.StrictMode>
        <DrawerProvider>
            <HistoryRouter history={history}>
                <SnackbarProvider>
                    <OrderSnackbarProvider>
                        <OfflineDialogProvider>
                            <OfflineSnackbarProvider>
                                <Provider store={store}>
                                    <App />
                                </Provider>
                            </OfflineSnackbarProvider>
                        </OfflineDialogProvider>
                    </OrderSnackbarProvider>
                </SnackbarProvider>
            </HistoryRouter>
        </DrawerProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
