import React from "react";
import { WifiOffLogo } from "../../icons";
import * as S from "./ServiceUnavailable.style";
import Lottie from "lottie-react";
import LeftAnimation from "../../animations/service_unavailable.json";

const ServiceUnavailable = () => {
    return (
        <S.Wrapper>
            <S.LeftContainer>
                <Lottie animationData={LeftAnimation} />
            </S.LeftContainer>
            <S.MiddleContainer>
                <S.InnerMiddleContainer>
                    <S.OuterCircle active>
                        <div></div>
                    </S.OuterCircle>
                    <S.DottedLine></S.DottedLine>
                    <S.OuterCircle>
                        <div></div>
                    </S.OuterCircle>
                </S.InnerMiddleContainer>
            </S.MiddleContainer>
            <S.RightContainer>
                <div>
                    <S.Header>
                        <img src={WifiOffLogo} alt="" />
                        <div>Service unavailable</div>
                    </S.Header>
                    <S.SubHeading>
                        Make sure your main server is up and running
                    </S.SubHeading>
                    <S.CheckBtn>Check</S.CheckBtn>
                    <S.GreyHeading>
                        Please ensure that your admin computer is powered on and
                        is running the DigiDukandar software.
                    </S.GreyHeading>
                    <S.SubHeading>
                        Make sure your router is up and running
                    </S.SubHeading>
                    <S.CheckBtn fill>Check</S.CheckBtn>
                    <S.GreyHeading>
                        Please ensure that your admin computer is powered on and
                        is running the DigiDukandar software.
                    </S.GreyHeading>
                </div>
                <S.BottomContainer>
                    <S.NeedHelp>Need help ?</S.NeedHelp>
                    <S.CheckBtn fill long>
                        Contact support
                    </S.CheckBtn>
                </S.BottomContainer>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default ServiceUnavailable;
