import { Drawer } from "@mui/material";
import React, { useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  PlusBlueIcon,
  ProductPlaceholderIcon,
  WifiDisconnectedIcon,
} from "../../icons";
import { addProductToDamageEntry } from "../../store/damageentry-slice";
import {
  addProductToPurchase,
  SCREENS,
  setCurrentScreen,
} from "../../store/purchase-slice";
import { addProductToPurchaseReturn } from "../../store/purchasereturn-slice";
import { addProductToSale } from "../../store/sale-slice";
import history from "../../utils/history";
import ProductDrawer from "../product-drawer/product-drawer";
import SelectProductModal from "../select-product-modal/select-product-modal";
import * as S from "./DropDown.style";
import { generateMetrics } from "./../../utils/metrics";
import { hasDecimal } from "../../utils/global-fn";
import { addProductToPI } from "../../store/proformainvoice-slice";
import { addProductToPP } from "../../store/purchaseproforma-slice";

const ProductDropDown = (
  {
    products,
    setOpenDropDown,
    setSearchText,
    openDrawer,
    setOpenDrawer,
    activeRow,
    setSkuProducts,
    setOpenSelectProductModal,
    scrollRef,
    productData,
    setProductData,
  },
  ref
) => {
  // const [openDrawer, setOpenDrawer] = useState(false);
  // const [productData, setProductData] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  let pathroute = history.location.pathname.split("/")[2];
  // const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
  // const [skuProducts, setSkuProducts] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      refProdRowClick: () => handleProdRowClick(products[activeRow]),
    };
  });
  function handleAddProduct(item) {
    let path = location.pathname.split("/")[2];
    if (path === "sales" || path === "new-sale") {
      dispatch(addProductToSale(item));
    } else if (path === "new-purchase") {
      dispatch(addProductToPurchase(item));
    } else if (path === "new-purchase-return") {
      dispatch(addProductToPurchaseReturn(item));
    } else if (path === "new-damage-entry") {
      dispatch(addProductToDamageEntry(item));
    } else if (path === "proforma-invoice") {
      dispatch(addProductToPI(item));
    } else if (path === "purchase-proforma") {
      dispatch(addProductToPP(item));
    } else {
      setOpenDrawer(true);
      setProductData(item);
    }
    setOpenDropDown(false);
    setSearchText("");
  }

  function handleProdRowClick(item) {
    handleAddProduct(item);
  }

  return (
    <>
      {/* <SelectProductModal
                open={openSelectProductModal}
                onClose={() => setOpenSelectProductModal(false)}
                data={skuProducts}
            /> */}
      <S.Container>
        {Array.isArray(products) && products.length > 0 ? (
          products.map((item, idx) => (
            <React.Fragment key={idx}>
              <S.ProdRow
                key={idx}
                active={idx === activeRow}
                onClick={() => {
                  handleProdRowClick(item);
                }}
                ref={idx === activeRow ? scrollRef : null}
              >
                <S.Box>
                  <S.Content>
                    <S.ImgDiv>
                      <img
                        src={item?.gallery?.[0] || ProductPlaceholderIcon}
                        alt="product"
                        onError={(e) => {
                          e.target.src = WifiDisconnectedIcon;
                        }}
                      />
                    </S.ImgDiv>
                    <div>
                      <S.Name>{item.name}</S.Name>
                      <S.Brand>{item.brand?.name}</S.Brand>
                      <S.BottomContainer>
                        <S.Stock stock={item.stock}>{`${
                          hasDecimal(item.stock)
                            ? item.stock.toFixed(2)
                            : item.stock
                        } in Stock`}</S.Stock>
                        <S.Bar></S.Bar>
                        {/* {item.items[0].price !== item.items[0].sale_price && (
                                        <S.StrikedPrice>₱{item.items[0].price}</S.StrikedPrice>
                                    )} */}
                        {/* <S.SalePrice>₱{item.items[0].sale_price}</S.SalePrice> */}
                        <S.SalePrice>Total SKU’s ({item.stock})</S.SalePrice>
                      </S.BottomContainer>
                      <S.SalePrice>{generateMetrics(item)}</S.SalePrice>
                    </div>
                  </S.Content>
                  <S.LongBar></S.LongBar>
                  <S.PriceRange>{item.price}</S.PriceRange>
                </S.Box>
              </S.ProdRow>
              <S.Hr></S.Hr>
            </React.Fragment>
          ))
        ) : (
          <S.Empty>No results found</S.Empty>
        )}
      </S.Container>
    </>
  );
};

export default React.forwardRef(ProductDropDown);
