import React from "react";
import * as S from "./style";
import "./style.css";
import { COLOR, DiscountOption, PURCHASE_TYPE } from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { BillIcon, CalendarIcon, CloseModalIcon, ExpenseIcon } from "../../../icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../../utils/format-date";
import onInputChange from "../../../utils/handleInputChange";
import {
    getTotalsPP,
    removeSelectedSupplierPP,
    setBillNoPP,
    setDeliveryDatePP,
    setDeliveryExpensePP,
    setSelectDiscountPP,
} from "../../../store/purchaseproforma-slice";
import { hasDecimal, toDecimalPlace } from "../../../utils/global-fn";
import { useEffect } from "react";
import CustomButton from "../../custom-button/CustomButton";

const PurchaseProformaSummary = ({ billRef, delExpRef, delTimeRef, cartDiscountRef, timeRef, handleBtnClick }) => {
    const {
        PP_products,
        PP_type,
        PP_selectedSupplier,
        PP_quantity,
        PP_total,
        PP_taxTotal,
        PP_discountValue,
        PP_cartTotal,
        PP_deliveryExpense,
        PP_billNo,
        PP_deliveryDate,
        PP_createdDate,
        PP_selectDiscount,
        PP_globalDiscount,
    } = useSelector((state) => state.purchaseProforma);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTotalsPP());
    }, [PP_products, PP_selectDiscount, PP_deliveryExpense]);

    const handleInputChange = (e, key) => {
        onInputChange(e, (data) => {
            let { value } = data;
            if (key === "delivery_expense") {
                const regex = /^[0-9\b]+$/;
                if (!(value === "" || regex.test(value))) return;
                dispatch(setDeliveryExpensePP(value));
            } else if (key === "bill_no") {
                dispatch(setBillNoPP(value));
            } else if (key === "cart_discount") {
                const regex = /^\d+\.?(\d)?(\d)?$/;
                if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
                    value = value.substr(1);
                }
                if (!(value === "" || regex.test(value))) return;

                if (PP_selectDiscount.type === DiscountOption.PERCENTAGE) {
                    if (parseFloat(value) > 100) {
                        value = 100;
                    }
                }
                if (PP_selectDiscount.type === DiscountOption.FLAT) {
                    if (parseFloat(value) > PP_total) {
                        value = PP_total;
                    }
                }
                dispatch(setSelectDiscountPP({ ...PP_selectDiscount, cost: value }));
            }
        });
    };

    const handleDateChange = async (date) => {
        dispatch(setDeliveryDatePP(date));
    };

    const handleCartDiscountTypeChange = (type) => {
        dispatch(setSelectDiscountPP({ ...PP_selectDiscount, type: type }));
    };
    return (
        <S.Container>
            <S.Header>{PP_type === PURCHASE_TYPE.PURCHASE_PROFORMA ? "Purchase Proforma" : "Purchase Order"} Summary</S.Header>
            <S.Body>
                <div>
                    {PP_selectedSupplier && (
                        <S.SupplierDetails>
                            <S.SupplierContent>
                                <S.Name>{PP_selectedSupplier?.name}</S.Name>
                                <S.Mobile>{PP_selectedSupplier?.phone && `(${PP_selectedSupplier?.phone})`}</S.Mobile>
                            </S.SupplierContent>
                            <S.ClearContainer>
                                <img src={CloseModalIcon} alt="close" onClick={() => dispatch(removeSelectedSupplierPP())} />
                            </S.ClearContainer>
                        </S.SupplierDetails>
                    )}
                    <S.PriceDetails>
                        <div>Quantity:</div>
                        <div>{PP_quantity}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Subtotal:</div>
                        <div>₱{toDecimalPlace(PP_total - PP_taxTotal + PP_discountValue)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Tax:</div>
                        <div>₱{toDecimalPlace(PP_taxTotal)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Discount:</div>
                        <div>₱{toDecimalPlace(PP_discountValue)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Bill Total:</div>
                        <div>₱{toDecimalPlace(PP_total)}</div>
                    </S.PriceDetails>
                    <S.Dotted></S.Dotted>
                    <S.PriceDetails>
                        <div>Cart Discount:</div>
                        <div>₱{toDecimalPlace(PP_globalDiscount)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Delivery Expense:</div>
                        <div>₱{PP_deliveryExpense || 0}</div>
                    </S.PriceDetails>
                    {hasDecimal(PP_cartTotal) && (
                        <S.PriceDetails>
                            <div>Adjusted Cash:</div>
                            <div>₱{toDecimalPlace(Math.round(PP_cartTotal) - PP_cartTotal)}</div>
                        </S.PriceDetails>
                    )}
                    <S.TotalDetails>
                        <div>{PP_type === PURCHASE_TYPE.PURCHASE_PROFORMA ? "Purchase Proforma" : "Purchase Order"} Total:</div>
                        <div>₱{Math.round(PP_cartTotal)}</div>
                    </S.TotalDetails>
                    <S.Dotted></S.Dotted>

                    {/* delivery expense */}
                    <S.NewContainer>
                        <S.Label>
                            Add delivery expense<div>(F5 )</div>
                        </S.Label>
                        <S.FieldContainer onClick={() => delExpRef.current?.focus()}>
                            <input
                                type="text"
                                onChange={(e) => handleInputChange(e, "delivery_expense")}
                                value={PP_deliveryExpense}
                                ref={delExpRef}
                            />
                            <S.Line></S.Line>
                            <img src={ExpenseIcon} alt="expense icon" />
                        </S.FieldContainer>
                    </S.NewContainer>

                    {/* bill number */}
                    <S.NewContainer>
                        <S.Label>
                            Add bill number<div>(F7)</div>
                        </S.Label>
                        <S.FieldContainer onClick={() => billRef.current?.focus()}>
                            <input type="text" onChange={(e) => handleInputChange(e, "bill_no")} value={PP_billNo} ref={billRef} />
                            <S.Line></S.Line>
                            <img src={BillIcon} alt="bill icon" />
                        </S.FieldContainer>
                    </S.NewContainer>

                    {/* delivery date */}
                    <S.DeliveryDetails>
                        <S.Label>
                            Select delivery date<div>(F3)</div>
                        </S.Label>
                        <S.FieldContainer ref={timeRef} onClick={() => timeRef.current?.querySelector("input").click()}>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(PP_deliveryDate))}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="summary-calendar"
                                minDate={PP_createdDate || new Date()}
                            />
                            <S.Line></S.Line>
                            <img src={CalendarIcon} alt="calendar" />
                        </S.FieldContainer>
                    </S.DeliveryDetails>
                    {/* <S.Dotted></S.Dotted> */}
                    {/* cart discount */}
                    <S.NewContainer>
                        <S.Label>
                            Add Discount<div>(F8)</div>
                        </S.Label>

                        <S.DiscountChoosers>
                            <S.SymbolDiv>
                                <S.Symbol
                                    active={PP_selectDiscount.type === DiscountOption.PERCENTAGE}
                                    onClick={() => handleCartDiscountTypeChange(DiscountOption.PERCENTAGE)}
                                >
                                    %
                                </S.Symbol>
                                <S.MiddleBar></S.MiddleBar>

                                <S.Symbol
                                    active={PP_selectDiscount.type === DiscountOption.FLAT}
                                    onClick={() => handleCartDiscountTypeChange(DiscountOption.FLAT)}
                                >
                                    ₱
                                </S.Symbol>
                            </S.SymbolDiv>
                            <input
                                type="text"
                                value={PP_selectDiscount.cost ?? 0}
                                onChange={(e) => handleInputChange(e, "cart_discount")}
                                disabled={PP_products.length <= 0}
                                ref={cartDiscountRef}
                            />
                        </S.DiscountChoosers>
                    </S.NewContainer>
                </div>
                <S.BtnContainer>
                    <CustomButton
                        label="Purchase (F12)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("purchase")}
                        fill={true}
                        size="lg"
                        disable={!PP_products?.length}
                    />
                    <CustomButton
                        label="Save to Counter (F10)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("draft")}
                        size="lg"
                        noBorder={true}
                        disable={!PP_products?.length}
                    />
                </S.BtnContainer>
            </S.Body>
        </S.Container>
    );
};

export default PurchaseProformaSummary;
