import React from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import Loading from "../../../assets/images/gif/loading.gif";

const LoadingDialog = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Wrapper>
                <S.Head>Setting up your smart store</S.Head>
                <S.SubHead>Random Text...</S.SubHead>
                <img src={Loading} alt="loader" />
                <S.LoadingText>0%</S.LoadingText>
                <S.BottomText>Please wait...</S.BottomText>
            </S.Wrapper>
        </Modal>
    );
};

export default LoadingDialog;
