import React from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CloseModalIcon } from "../../../../icons";
import { STORE_OFFLINE_REASONS } from "../../../../constants/appConstants";

const OfflineStoreDialog = ({
    open,
    onClose,
    offlineReasons,
    setOfflineReasons,
    setOnlineStore,
}) => {
    const handleReasons = (index) => {
        let newReasons = offlineReasons.map((item, idx) =>
            index === idx ? { ...item, value: !item.value } : item
        );
        setOfflineReasons(newReasons);
    };

    const handleClose = () => {
        onClose();
        setOfflineReasons(STORE_OFFLINE_REASONS);
        setOnlineStore(true);
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Container>
                <S.Top>
                    <S.TopBar>
                        <S.Heading>Switch?</S.Heading>
                        <img src={CloseModalIcon} alt="close" onClick={handleClose} />
                    </S.TopBar>
                    <S.SubHeading>Are you sure you want to change the status?</S.SubHeading>
                </S.Top>
                <S.TextContainer>
                    {offlineReasons.map((item, index) => (
                        <div key={item.id}>
                            <input
                                type="checkbox"
                                id={item.name}
                                checked={item.value}
                                onClick={() => handleReasons(index)}
                            />
                            <label htmlFor={item.name}>{item.name}</label>
                        </div>
                    ))}
                </S.TextContainer>
                <S.Bottom>
                    <S.ConfirmBtn onClick={onClose}>Confirm</S.ConfirmBtn>
                </S.Bottom>
            </S.Container>
        </Modal>
    );
};

export default OfflineStoreDialog;
