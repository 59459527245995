import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getGlobalSearchData } from "../api/local/globalSearchApi";
import AutoLoginModal from "../components/auto-login-modal/auto-login-modal";
import Header from "../components/Header/Header";
import LeftDrawer from "../components/LeftDrawer/LeftDrawer";

import "./DashboardLayout.scss";
import { captureException } from "../crash-reporting";

const DashboardLayout = ({ globalRef, openDrawer, setOpenDrawer }) => {
    const [globalSearchData, setGlobalSearchData] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [openDropDown, setOpenDropDown] = useState(false);

    useEffect(() => {
        if (searchText === "") {
            setGlobalSearchData(null);
        }
    }, [searchText]);

    const delayedFunction = async (searchText) => {
        if (searchText) {
            try {
                let res = await getGlobalSearchData(searchText);
                if (res.status === 200) {
                    setGlobalSearchData(res.data.entity);
                    setOpenDropDown(true);
                } else {
                    setGlobalSearchData(null);
                }
            } catch (e) {
                setGlobalSearchData(null);
                captureException(e);
            }
        }
    };

    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    function handleGlobalSearch(e) {
        setSearchText(e.target.value);
        delayedQuery(e.target.value);
    }

    return (
        <div className="dashboard-layout">
            <AutoLoginModal />
            <Header
                handleGlobalSearch={handleGlobalSearch}
                setSearchText={setSearchText}
                searchText={searchText}
                globalSearchData={globalSearchData}
                setOpenDropDown={setOpenDropDown}
                openDropDown={openDropDown}
                globalRef={globalRef}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
            />

            <div
                style={{
                    display: "flex",
                    height: "calc(100vh - 56px)",
                    backgroundColor: "#f1f5f9",
                }}
            >
                <LeftDrawer />
                <Outlet />
            </div>
        </div>
    );
};

export default DashboardLayout;
