import React, { useState, useImperativeHandle } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { DELETE_MODAL, MODAL_TYPES } from "../../../../constants/appConstants";
import { ProductPlaceholderIcon, ThreeDotsVerticalIcon, WifiDisconnectedIcon } from "../../../../icons";
import BarcodeDialog from "../../../barcode-dialog/barcode-dialog";

import * as S from "./products-table.styles";
import { hasDecimal } from "../../../../utils/global-fn";

const OptionsContainer = ({ onOpenDrawer, openBarcodeDialog, openConfirmationModal }) => {
    return (
        <S.OptionsContainer>
            <S.Option onClick={onOpenDrawer}>Edit</S.Option>
            <S.Option onClick={openConfirmationModal}>Delete</S.Option>
            <S.Option onClick={openBarcodeDialog}>Print Barcode</S.Option>
        </S.OptionsContainer>
    );
};

const Product = ({ product, openEditDrawer, activeRow, idx, scrollRef, setOpenModal, setActiveRow, setOpenBarcodeModal }, ref) => {
    const [open, setOpen] = useState(false);
    const [imgError, setImgError] = useState(false);
    // const [openBarcodeModal, setOpenBarcodeModal] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            // refDeleteProduct: () => handleConfirmationModalSubmit(products[activeRow]),
        };
    });

    // const handleConfirmationModalSubmit = (product) => {
    //     setOpenModal({ show: false, type: null, data: null });
    //     const payload = cloneDeep(product);
    //     payload.status = PRODUCT_STATUS.TRASH;
    //     updateProduct(product?.sku, payload)
    //         .then((res) => {
    //             dispatch(fetchAllProducts(searchParams));
    //             handleSnackbarDetails({
    //                 show: true,
    //                 title: "Deleted succesfully",
    //                 type: "success",
    //                 subtitle: "The product has been deleted succesfully",
    //             });
    //         })
    //         .catch((err) => console.log(err));
    // };

    const handleRowFocus = () => {
        setActiveRow(idx);
    };

    return (
        <>
            {/* <BarcodeDialog
                open={openBarcodeModal}
                data={product}
                onClose={() => setOpenBarcodeModal(false)}
            /> */}
            {/* <Modal
                open={openModal.show}
                onClose={() => setOpenModal({ show: false, type: null, data: null })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConfirmationDialog
                    closeModal={() => setOpenModal({ show: false, type: null, data: null })}
                    data={openModal.data}
                    type={openModal.type}
                    handleSubmit={() => handleConfirmationModalSubmit(product)}
                    open={openModal.show}
                />
            </Modal> */}
            <S.TableRow active={idx === activeRow} ref={idx === activeRow ? scrollRef : null} onClick={handleRowFocus}>
                <S.ProductData onClick={() => openEditDrawer(product)}>
                    {imgError || !product?.gallery?.[0] ? (
                        <S.ImagePlaceholder>
                            <img src={ProductPlaceholderIcon} alt="product" />
                        </S.ImagePlaceholder>
                    ) : (
                        <S.ProductImage
                            src={product.gallery?.[0] + `?n=${product.date_modified}`}
                            onError={(e) => {
                                e.target.src = WifiDisconnectedIcon;
                                setImgError(true);
                            }}
                            alt="product"
                        />
                    )}
                    <div>
                        <S.ProductName>{product.name}</S.ProductName>
                        {product.attributes && <S.Attr>{
                            Object.keys(product.attributes).map(k => `${k} : ${product.attributes[k]}`).join(", ")
                        }</S.Attr>}
                    </div>
                </S.ProductData>
                <S.BrandData onClick={() => openEditDrawer(product)}>{product.brand?.name}</S.BrandData>
                <S.PriceData onClick={() => openEditDrawer(product)}>
                    {product.price !== product.sale_price && (
                        <S.TextStrike>
                            {" "}
                            <S.StrikedPrice>₱{product.price}</S.StrikedPrice>{" "}
                        </S.TextStrike>
                    )}
                    <S.Price>₱{product.sale_price.toFixed(2)}</S.Price>
                </S.PriceData>
                <S.StockData>
                    {product.stock <= 0 && (
                        <S.StockStatus onClick={() => openEditDrawer(product)} stockType="0">
                            Out of Stock
                        </S.StockStatus>
                    )}
                    {/* {product.stock === -1 && (
                        <S.StockStatus
                            onClick={() => openEditDrawer(product)}
                            stockType="-1"
                        >
                            Unlimited Stock
                        </S.StockStatus>
                    )} */}
                    {product.stock > 0 && (
                        <S.StockStatus onClick={() => openEditDrawer(product)} stockType="1">
                            In Stock: {hasDecimal(product.stock) ? product.stock.toFixed(2) : product.stock}
                        </S.StockStatus>
                    )}
                    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                        <img src={ThreeDotsVerticalIcon} alt="" onClick={() => setOpen(true)} />
                        {open && (
                            <OptionsContainer
                                openBarcodeDialog={() =>
                                    setOpenBarcodeModal({
                                        show: true,
                                        data: product,
                                    })
                                }
                                onOpenDrawer={() => openEditDrawer(product)}
                                openConfirmationModal={() =>
                                    setOpenModal({
                                        show: true,
                                        type: MODAL_TYPES.DELETE,
                                        data: DELETE_MODAL,
                                        product: product,
                                    })
                                }
                            />
                        )}
                    </OutsideClickHandler>
                </S.StockData>
            </S.TableRow>
        </>
    );
};

export default React.forwardRef(Product);
