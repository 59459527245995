import React, { useContext, useState } from "react";
import { CalendarIcon, PlusBlueIcon, SelectArrowDownIcon } from "../../../icons";
import * as S from "./style";
import * as locales from "react-date-range/dist/locale";
import { Calendar } from "react-date-range";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { createAdvanceTransaction, getAdvanceTransactions } from "../../../api/local/employee-api";
import { formatDate } from "../../../utils/format-date";
import OutsideClickHandler from "react-outside-click-handler";
import { captureException } from "../../../crash-reporting";

const AdvanceSalaryEntry = ({ setOpenAdvEntry, employeeId }) => {
    const [openAdvCalendar, setOpenAdvCalendar] = useState(false);
    const [txnDate, setTxnDate] = useState(null);
    const [reason, setReason] = useState("");
    const [amount, setAmount] = useState("");
    const [recordType, setRecordType] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    async function handlecreateAdvTxn() {
        if (!amount) {
            handleSnackbarDetails({
                show: true,
                title: "Amount is required",
                type: "error",
            });
            return;
        }
        if (amount && !recordType) {
            handleSnackbarDetails({
                show: true,
                title: "Record type is required",
                type: "error",
            });
            return;
        }
        const payload = {
            amount: recordType === "REPAY" ? +amount * -1 : +amount,
        };
        if (reason) payload.reason = reason;
        if (txnDate) payload.timestamp = Math.floor(new Date(txnDate).getTime());
        try {
            let res = await createAdvanceTransaction(employeeId, payload);
            if (res.status === 200) {
                getAdvanceTransactions(employeeId);
                setOpenAdvEntry(false);
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Success",
                    subtitle: `Transaction added successfully`,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
            handleSnackbarDetails({
                show: true,
                title: e.message,
                type: "error",
            });
        }
    }
    return (
        <S.Wrapper>
            <S.TopDiv>
                <S.RecordContainer>
                    <div onClick={() => setRecordType("DEBT")}>
                        <input
                            type="radio"
                            name="record-type"
                            id="debit"
                            value="DEBT"
                            checked={recordType === "DEBT"}
                        />
                        <label htmlFor="debit">Debit Record</label>
                    </div>
                    <div onClick={() => setRecordType("REPAY")}>
                        <input
                            type="radio"
                            name="record-type"
                            id="credit"
                            value="REPAY"
                            checked={recordType === "REPAY"}
                        />
                        <label htmlFor="credit">Credit Record</label>
                    </div>
                </S.RecordContainer>
                <S.AmountInput>
                    ₱
                    <input
                        type="text"
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!(e.target.value === "" || regex.test(e.target.value))) return;
                            setAmount(e.target.value);
                        }}
                    />
                </S.AmountInput>
            </S.TopDiv>
            {/* <S.TopDiv>
                <div onClick={() => setOpenAdvCalendar(openAdvCalendar ? false : true)}>
                    {txnDate ? formatDate(txnDate) : "Date"}
                    <img src={SelectArrowDownIcon} alt="" />
                </div>
                <select name="adv type" id="" onChange={(e) => setRecordType(e.target.value)}>
                    <option selected disabled>
                        Record Type
                    </option>
                    <option value="DEBT">DEBT</option>
                    <option value="REPAY">REPAY</option>
                </select>
            </S.TopDiv> */}
            <S.MiddleDiv>
                <div>
                    <span>Reason:</span>
                    <input
                        type="text"
                        placeholder="Type here..."
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>
            </S.MiddleDiv>

            <S.BottomDiv>
                <S.CalendarDiv onClick={() => setOpenAdvCalendar(openAdvCalendar ? false : true)}>
                    {txnDate ? formatDate(txnDate) : "Select time period"}
                    <img src={CalendarIcon} alt="" />
                </S.CalendarDiv>
                {openAdvCalendar && (
                    <OutsideClickHandler onOutsideClick={() => setOpenAdvCalendar(false)}>
                        <S.DatePickerContainer>
                            <Calendar
                                onChange={(item) => {
                                    setTxnDate(item);
                                    setOpenAdvCalendar(false);
                                }}
                                locale={locales["enUS"]}
                                date={txnDate}
                                maxDate={new Date()}
                            />
                        </S.DatePickerContainer>
                    </OutsideClickHandler>
                )}
                <S.BtnDiv>
                    <S.CancelBtn onClick={() => setOpenAdvEntry(false)}>Cancel</S.CancelBtn>
                    <S.AddBtn onClick={handlecreateAdvTxn}>
                        <img src={PlusBlueIcon} alt="" />
                        Add
                    </S.AddBtn>
                </S.BtnDiv>
            </S.BottomDiv>
        </S.Wrapper>
    );
};

export default AdvanceSalaryEntry;
