import { AUTH_API, QUICK_LOGIN_API } from "../../constants/apiRoutes";
import axios from "./axios";

const employeeLogin = async (data) => {
    return await axios.post(AUTH_API, data);
};

const getQuickLogins = async () => {
    return await axios.get(`${AUTH_API}/${QUICK_LOGIN_API}`);
}

export { employeeLogin, getQuickLogins };
