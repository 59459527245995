import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // width: 1177px;
    width: 830px;
    height: 620px;
    padding: 36px;
`;
export const HeaderContainer = styled.div`
    height: 56px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #334155;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;

    img {
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
`;
export const HeaderText = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 8px;
    }
`;

export const Tabs = styled.div`
    display: flex;
    gap: 25px;
    width: 456px;
    border-bottom: 1px solid #e2e8f0;
`;
export const Tab = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #64748b;
    padding: 15px 0;
    margin-bottom: 1px;
    color: ${(props) => (props.active ? "#2D68FE" : "#475569")};
    border-bottom: 2.5px solid ${(props) => (props.active ? "#2D68FE" : "#fff")};
    padding-bottom: 6px;
    cursor: pointer;
`;
