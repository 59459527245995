import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllOnlineSales } from "../api/local/onlineSale-api";
import { ONLINE_ORDERS_FOCUS, ONLINE_ORDER_PARAMS_STATUS } from "../constants/appConstants";
import { captureException } from "../crash-reporting";

const initialState = {
    orders: [],
    params: {
        status: `${ONLINE_ORDER_PARAMS_STATUS.ORDERED},${ONLINE_ORDER_PARAMS_STATUS.PACKING}`,
    },
    order: null,
    packingProducts: [], //[{order_id:string, products:[]},{order_id:string, products:[]}]
    fulifillingOrder: null,
    loading: false,
    screenFocus: ONLINE_ORDERS_FOCUS.ORDERS_SECTION,
    newOrdersCount: 0,
    cronOrders: {
        SALE_ONLINE_ORDERED: [],
    },
};

export const fetchAllOnlineSales = createAsyncThunk("onlineSale/fetchAllOnlineSales", (search) =>
    getAllOnlineSales(search)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const onlineSaleSlice = createSlice({
    name: "onlineSale",
    initialState,
    reducers: {
        setOnlineSaleParams: (state, action) => {
            state.params = {
                ...state.params,
                ...action.payload,
            };
        },
        removeOnlineSaleParams: (state, action) => {
            delete state.params[action.payload];
        },

        removeAllOnlineSaleParams: (state) => {
            state.params = {};
        },
        setSingleOrder: (state, action) => {
            state.order = action.payload;
        },
        removeSingleOrder: (state) => {
            state.order = null;
        },
        addProductForPacking: (state, action) => {
            //payload = {order_id:string, product:{}}
            const orderIndex = state.packingProducts.findIndex((item) => item.order_id === action.payload.order_id);
            if (orderIndex >= 0) {
                const itemIndex = state.packingProducts[orderIndex].products.findIndex((item) => item.sku === action.payload.product.sku);
                if (itemIndex >= 0) {
                    state.packingProducts[orderIndex].products[itemIndex].packedQuantity += 1;
                } else {
                    state.packingProducts[orderIndex].products.push({
                        ...action.payload.product,
                        packedQuantity: 0,
                    });
                }
            } else {
                state.packingProducts.push({
                    order_id: action.payload.order_id,
                    products: [
                        {
                            ...action.payload.product,
                            packedQuantity: 0,
                        },
                    ],
                });
            }
        },
        updateProductForPacking: (state, action) => {
            //payload = {order_id:string, updateProduct:{}}
            const newPackingProducts = state.packingProducts.map((item) =>
                item.order_id === action.payload.order_id
                    ? {
                          ...item,
                          products: item.products.map((_item) =>
                              _item.sku === action.payload.updateProduct.sku ? { ..._item, ...action.payload.updateProduct } : _item
                          ),
                      }
                    : item
            );
            // const newPackingProducts = state.packingProducts.map((item) => (item.sku === action.payload.sku ? { ...item, ...action.payload } : item));
            state.packingProducts = newPackingProducts;
        },
        removeAllPackingProducts: (state, action) => {
            const newPackingProducts = state.packingProducts.filter((item) => item.order_id !== action.payload.order_id);
            state.packingProducts = newPackingProducts;
        },
        setFulifillingOrder: (state, action) => {
            state.fulifillingOrder = action.payload;
        },
        removeFulifillingOrder: (state) => {
            state.fulifillingOrder = null;
        },
        setScreenFocus: (state, action) => {
            state.screenFocus = action.payload;
        },
        showLoading: (state) => {
            state.loading = true;
        },
        removeAllOrders: (state) => {
            state.orders = [];
        },
        setOrdersCount: (state, action) => {
            state.newOrdersCount = action.payload;
        },
        setCronOrders: (state, action) => {
            let { orders, status } = action.payload;
            let newOrders = orders.filter((order) => order.online?.status === status);
            state.cronOrders[status] = newOrders;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllOnlineSales.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllOnlineSales.fulfilled, (state, action) => {
            state.loading = false;
            state.orders = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllOnlineSales.rejected, (state, action) => {
            state.loading = false;
            state.orders = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default onlineSaleSlice.reducer;
export const {
    setOnlineSaleParams,
    removeOnlineSaleParams,
    removeAllOnlineSaleParams,
    setSingleOrder,
    removeSingleOrder,
    addProductForPacking,
    updateProductForPacking,
    removeAllPackingProducts,
    setFulifillingOrder,
    removeFulifillingOrder,
    setScreenFocus,
    showLoading,
    removeAllOrders,
    setOrdersCount,
    setCronOrders,
} = onlineSaleSlice.actions;
