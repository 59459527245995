import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 607px;
    height: 92%;
    height: calc(100vh - 56px);
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    padding: 12px 19px 9px;
    overflow: scroll;
`;

export const Header = styled.div`
    // position: relative;
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid red;

    img {
        position: absolute;
        top: 0;
        right: 0;
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #475569;
    margin-bottom: 9px;
`;

export const SubTitle = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #475569;
    // padding-bottom: 12px;
`;

export const ProductDetails = styled.div`
    display: flex;
    gap: 6px;
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 124px;
    height: 126px;
    border: 1px solid #e2e8f0;
    border-radius: 3px;
    margin-top: 4px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    img[alt*="product"] {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
`;

export const AddImage = styled.div`
    background: #e2e8f0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #94a3b8;
    img[alt*="add"] {
        width: 18px;
        height: 18px;
    }
`;

export const DetailsRight = styled.div`
    flex: 1;
`;

export const DetailsTop = styled.div`
    display: flex;
    margin-bottom: 8px;
    gap: 8px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    label {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #64748b;
        margin-bottom: 6px;
    }

    input {
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        padding: 13px 0;
        width: 100%;
        height: 100%;
        border: none;
        background-color: #fff;
    }
`;

export const ContainerBottom = styled.div`
    padding: 18px 0 48px;
`;

export const Tabs = styled.div`
    display: flex;
    // gap: 6px;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 4px;
`;

export const Tab = styled.div`
    font-weight: ${(props) => (props.active ? "600" : "600")};
    font-size: 13px;
    line-height: 16px;
    color: ${(props) => (props.active ? "#2D68FE" : "#475569")};
    border: 1px solid ${(props) => (props.active ? "#2D68FE" : "#fff")};
    padding: 10px 16px;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    // width: 184px;
`;

export const TabView = styled.div``;

export const BtnContainer = styled.div`
    width: 607px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    right: 0;
    padding: 11px 16px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    background: white;
    z-index: 10;
`;

export const BtnRight = styled.div`
    display: flex;
    gap: 16px;
`;
export const BtnLeft = styled.div`
    display: flex;
    gap: 16px;
`;

export const Btn = styled.div`
    padding: 9px 13px;
    cursor: pointer;
    ${(props) => props.hide && "visibility:hidden"}
`;

export const BtnDelete = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #ff4343;
    cursor: pointer;
`;
export const BtnSecond = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #475569;
    text-decoration: underline;
    cursor: pointer;
`;

export const BtnSave = styled(Btn)`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #2d68fe;
    border-radius: 4px;
    // margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StatusContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const StatusChip = styled.div`
    background: ${(props) => (props.active ? "#d1fae5" : "#E2E8F0")};
    border-radius: 100px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: ${(props) => (props.active ? "#10b981" : " #334155")};
    display: flex;
    align-items: center;
    padding: 4px 16px;

    span {
        display: inline-block;
        height: 6px;
        width: 6px;
        background: ${(props) => (props.active ? "#10b981" : "#334155")};
        border-radius: 50%;
        margin-right: 9px;
    }
`;
export const CloseBtn = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #d80027;
    border: 1px solid #d80027;
    border-radius: 4px;
    padding: 8px 10px;
    cursor: pointer;
`;
export const ClearBtn = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #d80027;
    cursor: pointer;
`;
export const HeaderRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const DraftBtn = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #2d68fe;
    cursor: pointer;
`;
export const NextBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #253238;
    border: 1px solid #253238;
    border-radius: 4px;
    padding: 8px 9px;
    cursor: pointer;
`;
