import * as S from "./SplashScreen.style";
import Loader from "../../components/loader/Loader";

const SplashScreen = () => {
    return (
        <S.Wrapper>
            <Loader />
        </S.Wrapper>
    );
};

export default SplashScreen;
