import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calendarFormatDate, formatDate } from "../../../../../utils/format-date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

import { ArrowDrop, ArrowUp, DeleteRedIcon, MoreDOttedInfoIcon, MoreInfoIcon, ProductPlaceholderIcon } from "../../../../../icons";

import * as S from "./create-return-item.styles";
import onInputChange from "../../../../../utils/handleInputChange";
import { SCREENS, addProductToPurchaseReturn, decrementQuantityPurchaseReturn } from "../../../../../store/purchasereturn-slice";
import { addProductToDamageEntry, decrementQuantityDamageEntry } from "../../../../../store/damageentry-slice";
import ProductImage from "../../../../product-image/ProductImage";
import {
    getAltUnit,
    getPurchaseCompareToPrice,
    getPurchasePrice,
    getPurchaseProductSubTotal,
    getTaxName,
    handleGetProductUnitQty,
} from "../../../../../utils/global-fn";
import AlternateUnit from "../../../../sales/alternate-unit/AlternateUnit";
import Taxable from "../../../../sales/taxable/Taxable";
import SelectTax from "../../../../sales/select-tax/SelectTax";
import Tippy from "@tippyjs/react";

const initailDisplayData = {
    price: "",
    quantity: "",
    purchase_price: "",
    sale_price: "",
    tax: "",
    batch_number: "",
    manufacturing_date: new Date(),
    expiry_date: new Date(),
    gallery: [],
};

const CreateReturnItem = ({ product, removeProduct, updateProducts, type, createdAt, idx, activeRow, setActiveRow, focusRef, handleEditProduct }) => {
    const [imgError, setImgError] = useState(null);
    const [displayData, setDisplayData] = useState(initailDisplayData);
    const { currentScreen } = useSelector((state) => state.purchaseReturn);
    const { currentFocus } = useSelector((state) => state.damageEntry);
    const { returnCurrentFocus } = useSelector((state) => state.purchaseReturn);
    const dispatch = useDispatch();
    const [unitHint, setUnitHint] = useState(null);

    useEffect(() => {
        if (type === "New Purchase Return") {
            if (idx === returnCurrentFocus) {
                focusRef.current[idx].focus();
                setActiveRow(returnCurrentFocus);
            }
        } else {
            if (idx === currentFocus) {
                focusRef.current[idx].focus();
                setActiveRow(currentFocus);
            }
        }
    }, [currentFocus, returnCurrentFocus]);

    useEffect(() => {
        setDisplayData(product || null);
    }, [product]);

    useEffect(() => {
        setUnitHint(getAltUnit(displayData));
    }, [displayData]);

    const handleInputChange = (key, e) => {
        onInputChange(e, (data) => {
            let { value } = data;
            if (key === "batch_number") {
                updateProducts({ ...displayData, [key]: value.toUpperCase() });
            } else {
                const regex = /^\d+\.?(\d)?(\d)?$/;
                if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
                    value = value.substr(1);
                }
                if (!(value === "" || regex.test(value))) return;
                if (key === "quantity") {
                    if (!value) value = 0;
                }
                if (key === "purchase_price") {
                    value = getPurchaseCompareToPrice(value, displayData);
                }
                updateProducts({ ...displayData, [key]: value });
            }
        });
    };

    const handleIncrementQuantity = () => {
        if (type === "New Purchase Return") {
            dispatch(addProductToPurchaseReturn(displayData));
        } else {
            dispatch(addProductToDamageEntry(displayData));
        }
    };
    const handleDecrementQuantity = () => {
        if (displayData.quantity <= 1) return;
        if (type === "New Purchase Return") {
            dispatch(decrementQuantityPurchaseReturn(displayData));
        } else {
            dispatch(decrementQuantityDamageEntry(displayData));
        }
    };

    const handleDateChange = (key, item) => {
        updateProducts({ ...displayData, [key]: item });
    };

    const handleRowFocus = () => {
        setActiveRow(idx);
        focusRef.current[idx].focus();
    };

    const onInputClick = (e) => {
        e.stopPropagation();
        setActiveRow(idx);
    };

    const handleUnitChange = (unit) => {
        let newProduct = { ...displayData, unit: unit };
        let qt = handleGetProductUnitQty(newProduct);

        let cp = product.checkProduct;
        updateProducts({
            ...displayData,
            unit: unit,
            price: cp?.price * qt,
            purchase_price: cp?.purchase_price * qt,
        });
    };

    const handleSelectChange = (selectedvalue, type) => {
        if (type === "purchase_price_with_tax") {
            let purchasePrice = getPurchaseCompareToPrice(displayData.purchase_price, { ...displayData, purchase_price_with_tax: selectedvalue });
            updateProducts({ ...displayData, [type]: selectedvalue, purchase_price: purchasePrice });
        } else {
            updateProducts({ ...displayData, [type]: selectedvalue });
        }
    };

    return (
        <S.TableRow key={idx} active={idx === activeRow} onClick={() => handleRowFocus()}>
            <S.TableDataSn>
                <div>{idx + 1}</div>
            </S.TableDataSn>
            <S.TableBodyData flexValue="1.5" onClick={() => handleEditProduct(displayData)}>
                <ProductImage icon={displayData.gallery ? displayData.gallery?.[0] + `?n=${displayData.date_modified}` : ProductPlaceholderIcon} />
                <S.ProductDetails>
                    <S.Name>{displayData?.name}</S.Name>
                    <S.Brand>{displayData?.brand?.name}</S.Brand>
                    <S.Metrics>{displayData?.stock} in stock</S.Metrics>
                </S.ProductDetails>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.65" center>
                <S.InputContainer>
                    <S.Rupee>₱</S.Rupee>
                    <S.Input center type="text" value={displayData?.price} onChange={(e) => handleInputChange("price", e)} onClick={onInputClick} />
                </S.InputContainer>
            </S.TableBodyData>
            {currentScreen === SCREENS.PURCHASE_TABLE && (
                <S.TableBodyData flexValue="0.65" center column>
                    <S.InputContainer center>
                        <AlternateUnit item={displayData} onChange={(unit) => handleUnitChange(unit)} />
                    </S.InputContainer>
                    {unitHint && <S.HintText>{unitHint}</S.HintText>}
                </S.TableBodyData>
            )}

            <S.TableBodyData flexValue="1" center column full="95%">
                <S.InputFlexContainer>
                    <S.InputContainer center>
                        <S.Rupee>₱</S.Rupee>
                        <S.Input
                            center
                            type="text"
                            value={displayData?.purchase_price}
                            onChange={(e) => handleInputChange("purchase_price", e)}
                            onClick={onInputClick}
                        />
                    </S.InputContainer>
                    {currentScreen === SCREENS.PURCHASE_TABLE && (
                        <Taxable item={displayData} onChange={(unit, type) => handleSelectChange(unit, type)} type="purchase_price_with_tax" />
                    )}
                </S.InputFlexContainer>
            </S.TableBodyData>

            <S.TableBodyData flexValue="0.65" center>
                <S.InputContainer>
                    <S.Input
                        align="center"
                        type="text"
                        value={displayData.quantity || 0}
                        onChange={(e) => handleInputChange("quantity", e)}
                        autoFocus
                        onFocus={(e) => e.target.select()}
                        ref={(el) => (focusRef.current[idx] = el)}
                    />
                    {
                        <S.ArrowBox>
                            <img src={ArrowUp} alt="arrow up" onClick={() => handleIncrementQuantity()} />
                            <img src={ArrowDrop} alt="arrow drop" onClick={() => handleDecrementQuantity()} />
                        </S.ArrowBox>
                    }
                </S.InputContainer>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.75" center full="90%">
                <SelectTax item={displayData} onChange={(unit, type) => handleSelectChange(unit, type)} type="tax" />
            </S.TableBodyData>
            {currentScreen === SCREENS.PURCHASE_TABLE && (
                <S.TableBodyData flexValue="0.35" center>
                    <Tippy
                        placement="bottom"
                        trigger="click"
                        interactive
                        content={
                            <MoreInfo
                                displayData={displayData}
                                handleDateChange={handleDateChange}
                                handleInputChange={(key, e) => handleInputChange(key, e)}
                                onInputClick={onInputClick}
                                createdAt={createdAt}
                            />
                        }
                    >
                        {displayData?.batch_number || displayData?.manufacturing_date || displayData?.expiry_date ? (
                            <img src={MoreDOttedInfoIcon} alt="info" />
                        ) : (
                            <img src={MoreInfoIcon} alt="info" />
                        )}
                    </Tippy>
                </S.TableBodyData>
            )}
            <S.TableBodyData flexValue="0.65" center>
                ₱ {getPurchaseProductSubTotal(displayData)?.itemTotal}
            </S.TableBodyData>
            <S.TableBodyData center flexValue="0.35">
                <img onClick={() => removeProduct(product)} src={DeleteRedIcon} alt="delete" />
            </S.TableBodyData>
        </S.TableRow>
    );
};

export default CreateReturnItem;

export const MoreInfo = ({ displayData, onInputClick, handleInputChange, handleDateChange, createdAt }) => {
    return (
        <S.MoreInfoWrapper>
            <S.OptionDiv>
                <S.OptionHeader>Batch Number</S.OptionHeader>
                <S.OptionInputContainer>
                    <S.Input
                        type="text"
                        value={displayData.batch_number}
                        onChange={(e) => handleInputChange("batch_number", e)}
                        onClick={onInputClick}
                    />
                </S.OptionInputContainer>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Mfg Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.manufacturing_date ?? null))}
                        onChange={(date) => handleDateChange("manufacturing_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        maxDate={createdAt || new Date()}
                        showMonthDropdown
                        isClearable={displayData.manufacturing_date !== null}
                    />
                </div>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Expiry Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.expiry_date ?? null))}
                        onChange={(date) => handleDateChange("expiry_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        minDate={displayData.manufacturing_date || new Date()}
                        showMonthDropdown
                        isClearable={displayData.expiry_date !== null}
                    />
                </div>
            </S.OptionDiv>
        </S.MoreInfoWrapper>
    );
};
