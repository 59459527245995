import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ArrowRight, CalendarIcon, RightArrowIcon } from "../../../icons";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import InputLabel from "../../product-drawer/input-label/input-label";
import * as S from "./style";
import * as locales from "react-date-range/dist/locale";
import { Calendar } from "react-date-range";
import { formatDate } from "./../../../utils/format-date";
import { useEffect } from "react";
import moment from "moment";

const PersonalInformation = ({ displayData, setDisplayData, setTabView, employeeId }) => {
    const [openBirthDatePicker, setOpenBirthDatePicker] = useState(false);
    const [openJoinDatePicker, setOpenJoinDatePicker] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [joinDate, setJoinDate] = useState(null);

    const handleInputChange = (type) => (val) => {
        setDisplayData({ ...displayData, [type]: val });
    };
    function convertDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("-");
    }
    return (
        <S.Wrapper>
            {/* upper */}
            <S.Body>
                <S.InputContainers>
                    <S.InputContainer>
                        <S.RequiredContainer>
                            <InputLabel text="Name" />
                            <span>*</span>
                        </S.RequiredContainer>
                        <CustomInput placeholder="Enter name" type="text" value={displayData.name} onChange={handleInputChange("name")} />
                    </S.InputContainer>
                    <S.InputContainer>
                        <S.RequiredContainer>
                            <InputLabel text="Phone Number" />
                            <span>*</span>
                        </S.RequiredContainer>
                        <S.PhoneInputContainer>
                            <CustomInput type="text" value={"+63"} />
                            <CustomInput
                                placeholder="Enter phone number"
                                type="number"
                                value={displayData.phone}
                                onChange={handleInputChange("phone")}
                                maxLength={10}
                            />
                        </S.PhoneInputContainer>
                    </S.InputContainer>
                    {/* <S.InputContainer>
                        <S.RequiredContainer>
                            <InputLabel text="Username" />
                            <span>*</span>
                        </S.RequiredContainer>
                        {employeeId ? (
                            <CustomInput
                                placeholder="Enter username"
                                type="text"
                                disabled
                                value={displayData.username}
                                onChange={handleInputChange("username")}
                            />
                        ) : (
                            <CustomInput
                                placeholder="Enter username"
                                type="text"
                                value={displayData.username}
                                onChange={handleInputChange("username")}
                            />
                        )}
                    </S.InputContainer> */}
                </S.InputContainers>
                <S.InputContainers>
                    <S.InputContainer>
                        <InputLabel text="Email" />
                        <CustomInput placeholder="Enter email id" type="text" value={displayData.email} onChange={handleInputChange("email")} />
                    </S.InputContainer>
                    <S.InputContainer></S.InputContainer>
                </S.InputContainers>
                <S.InputContainers>
                    <S.InputContainer>
                        <InputLabel text="Date of Birth" />
                        <OutsideClickHandler onOutsideClick={() => setOpenBirthDatePicker(false)}>
                            <S.DateChooserSelect onClick={() => setOpenBirthDatePicker(openBirthDatePicker ? false : true)}>
                                {displayData?.dob
                                    ? formatDate(new Date(moment(displayData.dob, "DDMMYYYY").valueOf()))
                                    : birthDate
                                    ? formatDate(birthDate)
                                    : "Select a date"}{" "}
                                <img src={CalendarIcon} alt="calendar" />
                            </S.DateChooserSelect>
                            {openBirthDatePicker && (
                                <S.DatePickerContainer>
                                    <Calendar
                                        onChange={(item) => {
                                            setBirthDate(item);
                                            setDisplayData({
                                                ...displayData,
                                                dob: convertDate(item),
                                            });
                                            setOpenBirthDatePicker(false);
                                        }}
                                        locale={locales["enUS"]}
                                        date={birthDate}
                                        maxDate={new Date()}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </OutsideClickHandler>
                    </S.InputContainer>
                    <S.InputContainer>
                        <InputLabel text="Joining Date" />
                        <OutsideClickHandler onOutsideClick={() => setOpenJoinDatePicker(false)}>
                            <S.DateChooserSelect onClick={() => setOpenJoinDatePicker(openJoinDatePicker ? false : true)}>
                                {employeeId
                                    ? formatDate(new Date(displayData.joining_date))
                                    : joinDate
                                    ? formatDate(joinDate)
                                    : "Select joining date"}{" "}
                                <img src={CalendarIcon} alt="calendar" />
                            </S.DateChooserSelect>
                            {openJoinDatePicker && (
                                <S.DatePickerContainer>
                                    <Calendar
                                        onChange={(item) => {
                                            setJoinDate(item);
                                            setDisplayData({
                                                ...displayData,
                                                joining_date: Math.floor(new Date(item).getTime()),
                                            });
                                            setOpenJoinDatePicker(false);
                                        }}
                                        locale={locales["enUS"]}
                                        date={joinDate}
                                        maxDate={new Date()}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </OutsideClickHandler>
                    </S.InputContainer>
                </S.InputContainers>
            </S.Body>

            {/* footer */}
            <S.Footer>
                <div onClick={() => setTabView(2)}>
                    <div>Next</div>
                    <img src={ArrowRight} alt="" />
                </div>
            </S.Footer>
        </S.Wrapper>
    );
};

export default PersonalInformation;
