import React from "react";
import * as S from "../PlanBuyModal.style";
import { DesktopPOS, GPayIcon, HomeDropLogo, MinusIcon, MobilePOS, PaytmIcon, PhonePeIcon, PlusIcon } from "../../../icons";
import YellowBox from "../yellow-box/YellowBox";
import { useSelector } from "react-redux";
import BrandingLogo from "../../branding/branding-logo/BrandingLogo";
import BrandingFullLogo from "../../branding/full-logo/BrandingFullLogo";

const CreatePlan = ({ posCounter, setPosCounter, cost }) => {
    const { posPlan } = useSelector((state) => state.app);

    const updateCounter = (pos, key) => {
        if (key === "plus") {
            setPosCounter({ ...posCounter, [pos]: posCounter[pos] + 1 });
        } else if (key === "minus") {
            if (posCounter[pos] === 0) return;
            setPosCounter({ ...posCounter, [pos]: posCounter[pos] - 1 });
        }
    };

    return (
        <div>
            <S.HeroContainer>
                <S.LogoContainer>
                    <BrandingFullLogo size="120px" />
                </S.LogoContainer>
                <S.HeadingContainer>
                    <S.Heading>{posPlan?.info?.content?.title}</S.Heading>
                    <S.SubHeading>{posPlan?.info?.content?.sub_title}</S.SubHeading>
                </S.HeadingContainer>
                <S.SpaceBox></S.SpaceBox>
            </S.HeroContainer>

            <S.PosContainer>
                <S.POS>
                    <S.PosLogo src={DesktopPOS} alt="desktop pos" />
                    {/* <S.CounterContainer>
                        <img src={MinusIcon} alt="minus" onClick={() => updateCounter("desktop", "minus")} />
                        <S.Counter>{posCounter.desktop}</S.Counter>
                        <img src={PlusIcon} alt="plus" onClick={() => updateCounter("desktop", "plus")} />
                    </S.CounterContainer> */}
                    <S.PosHeading>Desktop POS</S.PosHeading>
                </S.POS>
                <S.POS>
                    <S.PosLogo src={MobilePOS} alt="mobile pos" />
                    {/* <S.CounterContainer>
                        <img src={MinusIcon} alt="minus" onClick={() => updateCounter("mobile", "minus")} />
                        <S.Counter>{posCounter.mobile}</S.Counter>
                        <img src={PlusIcon} alt="plus" onClick={() => updateCounter("mobile", "plus")} />
                    </S.CounterContainer> */}
                    <S.PosHeading>Mobile POS</S.PosHeading>
                </S.POS>
            </S.PosContainer>
            <S.Gap size="171px"></S.Gap>
            <YellowBox label={posPlan?.info?.content?.support_text} curved />

            {/* <S.BottomBar>
                <S.AmountContainer>
                    <S.Amount>
                        <S.Cash>₱{cost}</S.Cash>
                        <S.CashWord>/annually (billed once)</S.CashWord>
                    </S.Amount>
                    <S.PaymentIcons>
                        <img src={PaytmIcon} alt="paytm" />
                        <img src={GPayIcon} alt="gpay" />
                        <img src={PhonePeIcon} alt="phonepe" />
                    </S.PaymentIcons>
                </S.AmountContainer>
                <S.GreenBtn>Proceed to Checkout</S.GreenBtn>
            </S.BottomBar> */}
        </div>
    );
};

export default CreatePlan;
