import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 909px;
    // height: 642px;
    width: ${(props) => (props.isFull ? "909px" : "620px")};
    transition: width 0.1s ease;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 13px 29px;
    border-bottom: 1px solid #e2e8f0;
`;

export const Header = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #334155;
`;

export const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const Body = styled.div`
    box-sizing: border-box;
    padding: 13px 29px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    height: 100%;
`;

export const PaymentContainer = styled.div`
    width: 558px;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

export const AddressContainer = styled.div`
    width: 257px;
    display: flex;
    flex-direction: column;
    gap: 22px;
`;

export const SearchContainer = styled.div`
    width: 100%;
    position: relative;
`;
export const CustomerSearchContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px;
    ${(props) => props.border && "border: 1px solid #e2e8f0"};
    ${(props) => props.border && "border-radius: 4px"};
    // border: 1px solid #e2e8f0;
    // border-radius: 4px;
    box-sizing: border-box;
    // padding: 0 14px;

    & > input {
        border-radius: 4px;
        width: 100%;
        padding: 9px 0px;
        color: #334155;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        border: none;
        outline: none;

        & ::placeholder {
            color: #94a3b8;
        }
    }
`;

export const ClearBtn = styled.img`
    cursor: pointer;
    height: 20px;
    width: 20px;
`;

export const Input = styled.input`
    width: 100%;
    padding: 7px;
    color: #334155;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    border: none;
    outline: none;
    border-radius: 2px;

    & ::placeholder {
        color: #94a3b8;
    }
`;

export const CustomerDetailsContainer = styled.div`
    width: 100%;
    background-color: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 14px;
    display: flex;
    align-items: flex-end;
    gap: 14px;
`;

export const Label = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #334155;
`;

export const ColumnFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const PointContainer = styled.div`
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 7px 13px;

    & > input {
        cursor: pointer;
    }
    & > label {
        color: #334155;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        cursor: pointer;
        // margin-top: -2px;
    }

    & span {
        font-size: 10px;
    }
`;

export const InputContainer = styled.div`
    border-radius: 2px;
    // border: 1px solid #e2e8f0;
    background: #fff;
    width: ${(props) => props.width && props.width};
`;

export const PaymentAmountContainer = styled.div`
    width: 100%;
    background-color: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px 14px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.gap && props.gap};
`;

export const LabelContainer = styled.div`
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    padding: 4px 6px;
`;

export const PaymentModeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
`;

export const PaymentMode = styled.div`
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 125px;
    box-sizing: border-box;
    padding: 7px;

    & > img {
        ${(props) => props.type === "png" && "width: 17px"};
    }
`;
export const PaymentInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #334155;
`;

export const TextArea = styled.textarea`
    border: 1px solid #e2e8f0;
    outline: none;
    width: 100%;
    height: 102px;
    resize: none;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #334155;
    box-sizing: border-box;
    padding: 7px;
    font-family: sans-serif;
    &::placeholder {
        color: #737373;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const BoldLabel = styled(Label)`
    font-weight: 500;
    width: 100%;
    text-align: center;
`;
export const AddressFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin: -8px 0 0;

    & > input {
        border: 1px solid #cbd5e1;
    }

    & > label {
        color: #334155;
        font-size: 11.424px;
        font-weight: 400;
        line-height: 16.32px;
    }
`;

export const BlueBtn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    background: ${(props) => (props.disable ? "#94A3B8" : "#2d68fe")};
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 15.949px;
    cursor: ${(props) => (props.disable ? "no-drop" : "pointer")};

    :hover {
        opacity: 0.9;
    }
`;

export const BottomBar = styled.div`
    box-sizing: border-box;
    padding: 0 29px 16px;
`;
