import React, { useContext, useState, useImperativeHandle } from "react";
import { Calendar } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import {
  CalendarIcon,
  PathArrowRight,
  SaleBillIcon,
  SaleReturnIcon,
  ViewOnlineOrderIcon,
} from "../../icons";
import * as S from "./ViewSale.style";
import * as locales from "react-date-range/dist/locale";
import ViewSaleTable from "../../components/sales/view-sale-table/ViewSaleTable";
import SaleSummary from "../../components/sales/sale-summary/SaleSummary";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSale } from "../../api/local/sale-api";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import DeleteModal from "./../../components/purchase/delete-modal/DeleteModal";
import { isEmpty } from "lodash";
import AddCustomerModal from "./../../components/add-customer-modal/add-customer-modal";
import { removeSelectedCustomer } from "../../store/customer-slice";
import { calendarFormatDate, formatDate } from "./../../utils/format-date";
import BackButton from "./../../components/back-button/BackButton";
import { useRef } from "react";
import { useEffect } from "react";
import {
  addProductToReturnSale,
  removeAllReturnProducts,
  setSelectedReturnSale,
} from "../../store/returnsale-slice";
import history from "../../utils/history";
import { removeSelectedSale } from "../../store/sale-slice";
import AddCustomerDialog from "../../components/sales/add-customer-dialog/AddCustomerDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { captureException } from "../../crash-reporting";
import { SALES_TYPE } from "../../constants/appConstants";
import InvoicePaymentModal from "../../components/invoice-payment-modal/InvoicePaymentModal";

const ViewSale = (props, ref) => {
  const [createdDate, setCreatedDate] = useState(null);
  const { selectedSale } = useSelector((state) => state.sale);
  const { inventorySelectedCustomer } = useSelector((state) => state.customer);
  const navigate = useNavigate();
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [billno, setBillno] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const dispatch = useDispatch();
  const billRef = useRef();
  const paymentRef = useRef();
  const summaryRef = useRef();
  const [openInvoicePaymentModal, setOpenInvoicePaymentModal] = useState(false);

  useEffect(() => {
    dispatch(removeAllReturnProducts());
    if (selectedSale.bill_no) {
      setBillno(selectedSale.bill_no);
    }
    return () => {
      dispatch(removeSelectedCustomer());
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openCustomer: () => setOpenCustomerModal(true),
      returnItem: () => handleReturnClick(),
      billFocus: () => billRef.current?.focus(),
      paymentFocus: () => paymentRef.current?.click(),
      saveSale: () => handleSaveSale(),
      printInvoice: () => sendPrintReceiptCommand(),
      deleteSale: () => setOpenDeleteModal(true),
      backAction: () => onBackAction(),
      onSendTax: () => summaryRef.current?.onSendTaxInvoice(),
      viewInvoice: () => handleViewInvoice(),
    };
  });

  const onBackAction = () => {
    if (openCustomerModal) {
      setOpenCustomerModal(false);
    } else if (openDeleteModal) {
      setOpenDeleteModal(false);
    } else {
      handleBackAction();
    }
  };
  async function handleDeleteSale() {
    const payload = {
      status: "TRASH",
    };
    try {
      let res = await updateSale(selectedSale.id, payload);
      if (res.status === 200) {
        navigate("/dashboard/all-sales");
        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale deleted successfully",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  async function handleSaveSale() {
    const payload = {};
    if (billno !== undefined) {
      if (selectedSale.bill_no !== billno) {
        payload.bill_no = billno;
      }
    }
    // if (inventorySelectedCustomer) {
    //     if (
    //         !selectedSale.patient ||
    //         selectedSale.patient.phone !== inventorySelectedCustomer.phone
    //     ) {
    //         payload.patient_id = inventorySelectedCustomer.phone;
    //     }
    // }
    if (paymentMode) {
      if (
        !selectedSale.payment ||
        selectedSale.payment.payment_mode !== paymentMode
      ) {
        let payment = {};
        payment.payment_mode = paymentMode;
        payment.is_paid = true;
        payload.payment = payment;
      }
    }
    if (createdDate) {
      if (selectedSale.created_at !== createdDate.getTime()) {
        payload.created_date = createdDate.getTime();
      }
    }
    if (!isEmpty(payload)) {
      try {
        let res = await updateSale(selectedSale.id, payload);
        if (res.status === 200) {
          dispatch(removeSelectedCustomer());
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Sale saved successfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      } catch (err) {
        console.log(err);
        captureException(err);
        handleSnackbarDetails({
          show: true,
          title: err.message,
          type: "error",
        });
      }
    }
    navigate("/dashboard/all-sales");
  }

  const handleBackAction = () => {
    // navigate("/dashboard/all-sales");
    history.back();
  };

  const sendPrintReceiptCommand = async () => {
    if (!process.env.REACT_APP_DEMO_SERVER) {
      try {
        if (selectedSale?.bill_type === "POS") {
          //TODO:
        } else {
          await handleViewInvoice();
        }
      } catch (err) {
        console.log(err);
        captureException(err);
      }
    }
  };

  const handleReturnClick = () => {
    dispatch(setSelectedReturnSale(selectedSale));
    selectedSale?.items?.forEach((item) => {
      console.log(item);
      dispatch(addProductToReturnSale({ ...item, isChecked: false }));
    });
    navigate("/dashboard/return-sale");
  };

  const handleViewInvoice = async () => {
    if (!selectedSale) {
      return;
    }
    if (!selectedSale.invoice_url) {
      handleSnackbarDetails({
        show: true,
        title: "Invoice is generating, Please refresh and try again",
        type: "error",
      });
      return;
    }

    window.open(selectedSale.invoice_url, "_blank")?.focus();
  };

  const handleOpenPayment = () => {
    setOpenInvoicePaymentModal(true);
  };
  return (
    <S.Wrapper>
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleDelete={handleDeleteSale}
      />
      <AddCustomerDialog
        open={openCustomerModal}
        onClose={() => setOpenCustomerModal(false)}
      />
      <InvoicePaymentModal
        open={openInvoicePaymentModal}
        onClose={() => setOpenInvoicePaymentModal(false)}
        type="convert-to-invoice"
        saleDiscount={0}
        handleClearData={() => {}}
      />
      <S.Top>
        <S.Topbar>
          <S.TopContent>
            <S.Path>
              <BackButton onClickAction={handleBackAction} />
            </S.Path>
          </S.TopContent>
          <S.TopContent>
            <S.DatePicker>
              <S.Created>Date</S.Created>
              <DatePicker
                selected={Date.parse(
                  calendarFormatDate(selectedSale.created_at)
                )}
                onChange={(date) => dispatch(setCreatedDate(date))}
                dateFormat="dd/MM/yyyy"
                className="date-picker"
                disabled
              />
            </S.DatePicker>
          </S.TopContent>
        </S.Topbar>
        <S.TopBarBtn>
          {selectedSale.type === "RETURN" ? (
            <S.ReturnSaleId>
              <img src={SaleReturnIcon} alt="return arrow" />
              <S.ReturnGreyText>Return against</S.ReturnGreyText>
              <S.SaleId>#{selectedSale.return_id}</S.SaleId>
            </S.ReturnSaleId>
          ) : (
            <S.SaleId>
              {selectedSale.is_online_sale && "Online"} Sale ID: #
              {selectedSale.is_online_sale
                ? selectedSale.invoice_id
                : selectedSale.invoice_id}
            </S.SaleId>
          )}
          {/* <S.SaleId>Sale ID: #{selectedSale.id}</S.SaleId> */}
          {selectedSale.is_online_sale ? (
            <></>
          ) : (
            <S.ButtonContainer>
              {selectedSale.type === SALES_TYPE.SALE &&
                selectedSale.status === "PUBLISH" &&
                !selectedSale.return_id && (
                  <S.BlueBtn onClick={() => handleViewInvoice()}>
                    View Invoice (F9)
                  </S.BlueBtn>
                )}
              {selectedSale.type === SALES_TYPE.SALE &&
                selectedSale.status === "PUBLISH" &&
                !selectedSale.return_id && (
                  <S.BlueBtn onClick={() => handleReturnClick()}>
                    Return item/s (F11)
                  </S.BlueBtn>
                )}
              {selectedSale.type === SALES_TYPE.SALE &&
                selectedSale.status === "PUBLISH" &&
                selectedSale.return_id && (
                  <S.ReturnedBtn>
                    Returned #{selectedSale.return_id}
                  </S.ReturnedBtn>
                )}
              {/* <S.BlueBtn onClick={() => sendPrintReceiptCommand()}>
                Print Invoice (F10)
              </S.BlueBtn> */}
              {/* <S.BlueBtn>Print Tax Receipt (F9)</S.BlueBtn> */}
              {[
                SALES_TYPE.SALE,
                SALES_TYPE.PROFORMA_INVOICE,
                SALES_TYPE.DELIVERY_CHALLAN,
              ].includes(selectedSale.type) && (
                <S.BlueBtn onClick={() => setOpenCustomerModal(true)}>
                  {inventorySelectedCustomer
                    ? "Change Customer (F4)"
                    : "Add Customer (F4)"}
                </S.BlueBtn>
              )}
            </S.ButtonContainer>
          )}
        </S.TopBarBtn>
      </S.Top>
      <S.Body>
        <ViewSaleTable />
        <SaleSummary
          type={selectedSale.type === SALES_TYPE.SALE ? "Sale" : "Return"}
          billno={billno}
          setBillno={setBillno}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          billRef={billRef}
          paymentRef={paymentRef}
          ref={summaryRef}
        />
      </S.Body>
      <S.Bottom>
        <S.BottomLeftContainer>
          {selectedSale.employee && (
            <S.EmployeeDetails>
              <S.Avatar>
                {selectedSale.employee.name
                  .split(" ")
                  .map((el) => el[0])
                  .join("")
                  .toUpperCase()}
              </S.Avatar>
              <S.EmployeeTag>Employer:</S.EmployeeTag>
              <S.EmployeeName>{selectedSale.employee.name}</S.EmployeeName>
            </S.EmployeeDetails>
          )}

          {selectedSale.is_online_sale ? (
            <S.BillType>
              <img src={ViewOnlineOrderIcon} alt="online icon" />
              Online order
            </S.BillType>
          ) : selectedSale.type === SALES_TYPE.PROFORMA_INVOICE ? (
            <S.BillType>
              <img src={SaleBillIcon} alt="sale bill" />
              Proforma Invoice
            </S.BillType>
          ) : selectedSale.type === SALES_TYPE.DELIVERY_CHALLAN ? (
            <S.BillType>
              <img src={SaleBillIcon} alt="sale bill" />
              Delivery Challan
            </S.BillType>
          ) : selectedSale.bill_type ? (
            <S.BillType>
              <img src={SaleBillIcon} alt="sale bill" />
              {selectedSale.bill_type} Bill
            </S.BillType>
          ) : (
            <S.BillType>
              <img src={SaleBillIcon} alt="sale bill" />
              POS Bill
            </S.BillType>
          )}
          {}

          {[SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
            selectedSale?.type
          ) && (
            <S.BillType fill clickable onClick={handleOpenPayment}>
              Convert To Invoice
            </S.BillType>
          )}
        </S.BottomLeftContainer>

        <S.BottomRightContainer>
          {/* <S.DeleteBtn onClick={() => setOpenDeleteModal(true)}>
                        Delete (Alt + Del)
                    </S.DeleteBtn> */}
          {!selectedSale.is_online_sale && (
            <S.SaveBtn onClick={handleSaveSale}>Save (F12)</S.SaveBtn>
          )}
        </S.BottomRightContainer>
      </S.Bottom>
    </S.Wrapper>
  );
};

export default React.forwardRef(ViewSale);
