import React from "react";
import Lottie from "lottie-react";
import * as S from "./style";
import { useSelector } from "react-redux";
import { ACTIVE_ONLINE_SALE_TEXTS, INACTIVE_ONLINE_SALE_TEXTS } from "../../../constants/appConstants";
import DeliveryTruck from "../../../animations/delivery-truck.json";
import NoInternet from "../../../animations/no-internet-connection.json";

const EmptyScreen = () => {
    const { appOnline } = useSelector((state) => state.app);

    return (
        <S.Wrapper>
            <S.Body>
                <S.LottieContainer>
                    {appOnline ? (
                        <S.OnlineLottie>
                            <Lottie animationData={DeliveryTruck} />
                        </S.OnlineLottie>
                    ) : (
                        <S.OfflineLottie>
                            <Lottie animationData={NoInternet} />
                        </S.OfflineLottie>
                    )}
                </S.LottieContainer>
                <S.Title>Note:</S.Title>
                {appOnline ? (
                    <S.Lists>
                        {ACTIVE_ONLINE_SALE_TEXTS.map((item) => (
                            <S.List key={item.id}>
                                <S.DotContainer>
                                    <S.Dot></S.Dot>
                                </S.DotContainer>
                                <div>{item.text}</div>
                            </S.List>
                        ))}
                    </S.Lists>
                ) : (
                    <S.Lists>
                        {INACTIVE_ONLINE_SALE_TEXTS.map((item) => (
                            <S.List key={item.id}>
                                <S.DotContainer>
                                    <S.Dot></S.Dot>
                                </S.DotContainer>
                                <div>{item.text}</div>
                            </S.List>
                        ))}
                    </S.Lists>
                )}
            </S.Body>
        </S.Wrapper>
    );
};

export default EmptyScreen;
