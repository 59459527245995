import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    margin-bottom: 30px;
`;

export const TextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1e293b;
    padding: 8px;
    box-sizing: border-box;
    margin: 0 5px;
    border-radius: 4px;
`;

export const Text = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffde2c;
`;
