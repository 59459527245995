import React, { useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CalendarIcon, CloseModalIcon } from "../../../icons";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { EXPENSES_TYPES, REPEAT_TYPES } from "../../../constants/appConstants";
import OutsideClickHandler from "react-outside-click-handler";
import { useState } from "react";
import { calendarFormatDate, formatDate } from "../../../utils/format-date";
import * as locales from "react-date-range/dist/locale";
import { Calendar } from "react-date-range";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { addExpense, getExpense, updateExpense } from "../../../api/local/expenses-api";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllExpenses } from "../../../store/expense-slice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { captureException } from "../../../crash-reporting";
import PaylaterPayment from "../../paylater-payment-dialog/PaylaterPayment";

const initialValue = {
    createdDate: null,
    description: "",
    repeat: REPEAT_TYPES[0].value,
    expenseType: null,
    amount: "",
};
const today = new Date();

const AddNewExpense = ({ open, onClose, expenseId }) => {
    const selectRef = useRef(false);
    const trackRef = useRef(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [formdata, _setFormdata] = useState(initialValue);
    const formdataRef = useRef(formdata);
    const setFormdata = (data) => {
        formdataRef.current = data;
        _setFormdata(data);
    };
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const { searchParams } = useSelector((state) => state.expense);

    useEffect(() => {
        if (expenseId) {
            handleGetExpense();
        } else {
            handleClear();
        }

        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 122) {
                //F11
                e.preventDefault();
                handleOpenPaymentModal();
            } else if (e.keyCode === 120) {
                //F9
                e.preventDefault();
                handleClear();
            }
        }
    };

    const handleOpenPaymentModal = () => {
        const { amount, expenseType } = formdataRef.current;
        if (!expenseType) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose expense type",
                type: "error",
            });
            return;
        }
        if (!amount) {
            handleSnackbarDetails({
                show: true,
                title: "Please add amount",
                type: "error",
            });
            return;
        }

        setOpenPaymentModal(true);
    };

    const handleSelectChange = (key, item) => {
        setFormdata({ ...formdata, expenseType: item.value });
    };
    const handleInputChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (!(e.target.value === "" || regex.test(e.target.value))) return;
        setFormdata({ ...formdata, amount: e.target.value });
    };

    const handleGetExpenses = () => {
        dispatch(fetchAllExpenses(searchParams));
    };

    const handleClear = () => {
        setFormdata({ ...initialValue, createdDate: new Date() });
    };

    const handleClose = () => {
        if (openPaymentModal) return;
        onClose();
    };

    const onSuccess = () => {
        handleGetExpenses();
        onClose();
        handleClear();
    };

    const handleGetExpense = async () => {
        try {
            let res = await getExpense(expenseId);
            if (res.status === 200) {
                setFormdata({
                    ...formdata,
                    expenseType: res.data.expense_type,
                    amount: res.data.amount,
                    createdDate: new Date(res.data.created_at),
                });
                if (res.data.description)
                    setFormdata({
                        ...formdata,
                        description: res.data.description,
                        expenseType: res.data.expense_type,
                        amount: res.data.amount,
                        createdDate: new Date(res.data.created_at),
                    });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleSubmit = async () => {
        const { amount, createdDate, description, expenseType, repeat } = formdataRef.current;
        if (!expenseType) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose expense type",
                type: "error",
            });
            return;
        }
        if (!amount) {
            handleSnackbarDetails({
                show: true,
                title: "Please add amount",
                type: "error",
            });
            return;
        }
        const payload = {
            expense_type: expenseType,
            amount: parseInt(amount),
        };
        if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
        if (description) payload.description = description;
        if (repeat) payload.repeat = repeat;
        if (expenseId) {
            try {
                let res = await updateExpense(expenseId, payload);
                if (res.status === 200) {
                    handleGetExpenses();
                    onClose();
                    handleClear();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Expense updated",
                        subtitle: "Expense updated successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (e) {
                captureException(e);
            }
        } else {
            try {
                let res = await addExpense(payload);
                if (res.status === 200) {
                    handleGetExpenses();
                    onClose();
                    handleClear();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Expense Added",
                        subtitle: "New expense added successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (e) {
                captureException(e);
            }
        }
    };
    return (
        <>
            <PaylaterPayment
                open={openPaymentModal}
                onClose={() => {
                    setOpenPaymentModal(false);
                    onSuccess();
                }}
                type="expense-pay"
                expensePayData={{ data: formdataRef.current, id: expenseId }}
            />
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <S.Wrapper>
                    <S.TopBar>
                        <S.Title onClick={() => console.log(formdata)}>{expenseId ? "Update" : "Add"} Expenses</S.Title>
                        <img src={CloseModalIcon} alt="close icon" onClick={handleClose} />
                    </S.TopBar>
                    <S.Body>
                        <S.SelectContainers>
                            <S.SelectContainer>
                                <S.Label>Expenses Type</S.Label>
                                <CustomSelect
                                    type="expense-type"
                                    initalText={formdata.expenseType}
                                    data={EXPENSES_TYPES}
                                    localSearch
                                    onSelectChange={handleSelectChange}
                                    menuWidth="235px"
                                    selectRef={selectRef}
                                    trackRef={trackRef}
                                    blue
                                />
                            </S.SelectContainer>
                            <S.SelectContainer>
                                <S.Label>Date</S.Label>
                                <DatePicker
                                    selected={Date.parse(calendarFormatDate(formdata.createdDate))}
                                    onChange={(date) => setFormdata({ ...formdata, createdDate: date })}
                                    dateFormat="dd/MM/yyyy"
                                    className="date-picker"
                                    highlightDates={[today]}
                                />
                                {/* <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                                <S.DateChooserSelect
                                    onClick={() => setOpenDatePicker(openDatePicker ? false : true)}
                                >
                                    {formatDate(formdata.createdDate || new Date())}
                                    <img src={CalendarIcon} alt="calendar" />
                                </S.DateChooserSelect>
                                {openDatePicker && (
                                    <S.DatePickerContainer>
                                        <Calendar
                                            onChange={(item) => {
                                                setFormdata({ ...formdata, createdDate: item });
                                                setOpenDatePicker(false);
                                            }}
                                            locale={locales["enUS"]}
                                            date={formdata.createdDate}
                                        />
                                    </S.DatePickerContainer>
                                )}
                            </OutsideClickHandler> */}
                            </S.SelectContainer>
                        </S.SelectContainers>
                        <S.InputContainer>
                            <S.Label>Amount</S.Label>
                            <S.AmountContainer>
                                ₱
                                <input type="text" value={formdata.amount} onChange={handleInputChange} />
                            </S.AmountContainer>
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Label>Description</S.Label>
                            <S.FormContainerTextarea
                                placeholder="Add description"
                                value={formdata.description}
                                maxLength={200}
                                onChange={(e) => setFormdata({ ...formdata, description: e.target.value })}
                            ></S.FormContainerTextarea>
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Label>Repeat</S.Label>
                            <S.RepeatChipContainer>
                                {REPEAT_TYPES.map((item, idx) => (
                                    <S.RepeatChip
                                        key={idx}
                                        onClick={() => {
                                            setFormdata({ ...formdata, repeat: item.value });
                                        }}
                                        active={formdata.repeat === item.value}
                                    >
                                        <input
                                            type="radio"
                                            id={item.name}
                                            name="repeat-option"
                                            value={item}
                                            checked={formdata.repeat === item.value}
                                        />
                                        <label htmlFor={item.name}>{item.name}</label>
                                    </S.RepeatChip>
                                ))}
                            </S.RepeatChipContainer>
                        </S.InputContainer>
                    </S.Body>
                    <S.BottomBar>
                        {expenseId ? <div></div> : <S.ClearBtn onClick={handleClear}>Clear fields (F9)</S.ClearBtn>}
                        <S.BlueBtn onClick={() => handleOpenPaymentModal()}>{expenseId ? "Update" : "Add"} Expense (F11)</S.BlueBtn>
                    </S.BottomBar>
                </S.Wrapper>
            </Modal>
        </>
    );
};

export default AddNewExpense;
