import styled from "@emotion/styled/macro";

export const Container = styled.div`
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.16));
    position: absolute;
    background: white;
    top: 60px;
    // width: 92%;
    width: 451px;
`;

export const AddNew = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2d68fe;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#CCDFFF" : "#fff")};
    border-radius: 4px;

    img {
        margin-right: 4px;
    }
`;

export const Details = styled.div`
    padding: 10px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#CCDFFF" : "#fff")};
    border-radius: 4px;

    :hover {
        background-color: #e6efff;
    }
`;

export const DetailsContainer = styled.div`
    max-height: 225px;
    overflow: auto;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #334155;
`;

export const Brands = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
`;
