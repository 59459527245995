import React, { useEffect, useState } from "react";
import CustomAutocomplete from "../../../custom-autocomplete/Autocomplete";
import { getProductAttributeValues } from "../../../../api/local/productApi";
import { captureException } from "../../../../crash-reporting";

const AttributeValues = ({ id, value, handleSelectAttribute, position }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (id) handleGetAttributeValues(id);
        else setData(null);
    }, [id]);

    const handleGetAttributeValues = async (id) => {
        try {
            let res = await getProductAttributeValues(id);
            if (res.status === 200) {
                setData(res.data);
            } else {
                setData(null);
            }
        } catch (error) {
            captureException(error);
        }
    };

    return (
        <CustomAutocomplete
            data={data}
            value={value}
            name="attribute-value"
            position={position}
            onSelectOption={(name, item) => handleSelectAttribute(name, item)}
            width="100%"
        />
    );
};

export default AttributeValues;
