import React, { useEffect, useRef } from "react";
import { useState } from "react";
import * as S from "../LoginScreen.style";
import { PasswordEyeIcon, PasswordNoEyeIcon } from "../../../icons";

const NewPassword = ({ loginData, handleInputChange, onSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current?.focus();
        }
    }, []);
    return (
        <div>
            <S.LoginHead>Create New Password</S.LoginHead>
            <S.LoginContainer>
                <div>
                    <S.Label>Enter Password</S.Label>
                    <S.InputFIelds>
                        <input
                            type="password"
                            name="new_password"
                            onChange={(e) => handleInputChange(e)}
                            value={loginData.new_password}
                            autoFocus
                            ref={inputElement}
                        />
                    </S.InputFIelds>
                </div>
                <div>
                    <S.Label>Enter Password</S.Label>
                    <S.InputFIelds>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="confirm_new_password"
                            onChange={(e) => handleInputChange(e)}
                            value={loginData.confirm_new_password}
                        />
                        <img src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon} alt="" onClick={() => setShowPassword(!showPassword)} />
                    </S.InputFIelds>
                </div>
                <S.ConfirmBtn onClick={onSubmit}>Save</S.ConfirmBtn>
            </S.LoginContainer>
        </div>
    );
};

export default NewPassword;
