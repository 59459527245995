import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 343px;
    // height: 486px;
    height: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: #ffffff;
    overflow: hidden;
    overflow-y: auto;
`;

export const Heading = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    box-sizing: border-box;
    border-bottom: 1px solid #cbd5e1;
    padding: 13px 14px;
`;
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    box-sizing: border-box;
    padding: 8px 14px 30px;
`;
export const EmployeeDiv = styled.div`
    background: #f1f5f9;
    display: flex;
    align-items: center;
    // flex-direction: column;
    gap: 8px;
    padding: 12px 9px;
    cursor: pointer;
`;
export const EmpName = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
`;
export const EmpPhone = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;
export const GreyText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    margin: 4px 0;
    ${(props) => props.$underline && "text-decoration: underline"};
`;
export const OrderTotal = styled.div`
    font-weight: 600;
    font-size: 14.214px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-top: 2px dashed #e2e8f0;
    margin: 0;
    box-sizing: border-box;
    // padding-top: 8px;
`;

export const Change = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;
export const Payment = styled.div`
    font-weight: 600;
    font-size: 14.214px;
    line-height: 16px;
    color: #334155;
    margin-bottom: 8px;
`;
export const Mode = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;

    & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
    }
`;
export const ChangeText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2d68fe;
    cursor: pointer;
`;
export const BillContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 12px 0;
`;
export const FieldContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 130px;

    & > input {
        border: none;
        background: #fff;
        width: 100%;

        :focus {
            border: none;
            outline: none;
        }
    }
`;
export const Line = styled.div`
    height: 18px;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;
export const Dotted = styled.div`
    height: 1px;
    border-bottom: 2px dashed #e2e8f0;
    margin-top: ${(props) => props.mt && props.mt};
`;

export const CenterFlex = styled.div`
    display: flex;
    align-items: center;
`;
export const FlexContainer = styled(CenterFlex)`
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
`;
export const ModeContainer = styled(CenterFlex)`
    gap: 4px;
`;

export const ParentModeContainer = styled.div`
    position: relative;
    height: 22px;
`;
export const Strike = styled.div`
    height: 1.5px;
    margin-top: -8px;
    background-color: #64748b;
`;
export const PaymentImg = styled.img`
    width: ${(props) => (props.size === "sm" ? "20px" : "20px")};
    height: ${(props) => (props.size === "sm" ? "20px" : "20px")};
    padding: ${(props) => props.padding && "2px"};
    margin-bottom: -2px;
`;
