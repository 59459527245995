import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import * as S from "./new-purchase.style";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackButton from "../../components/back-button/BackButton";
import history from "../../utils/history";
import { calendarFormatDate } from "../../utils/format-date";
import { useDispatch, useSelector } from "react-redux";
import {
    SCREENS,
    addProductToPurchase,
    fetchAllPurchases,
    removeAllPurchaseProducts,
    removeAllSearchParams,
    removeSelectedDraftPurchase,
    removeSelectedSupplier,
    setBillNo,
    setCreatedDate,
    setCurrentScreen,
    setDeliveryDate,
    setDeliveryExpense,
    setSelectDiscount,
} from "../../store/purchase-slice";
import CustomButton from "../../components/custom-button/CustomButton";
import {
    COLOR,
    STATUS,
    INITIAL_PRODUCT_DISPLAY_DATA,
    PURCHASE_TYPE,
    DiscountOption,
} from "../../constants/appConstants";
import { RedClearCartIcon } from "../../icons";
import EmptyCart from "../../components/empty-cart/EmptyCart";
import NewPurchaseTable from "../../components/new-purchase/new-purchase-table/NewPurchaseTable";
import { getProduct } from "../../api/local/productApi";
import { captureException } from "../../crash-reporting";
import { generateSKU } from "../../utils/sku-upc-generator";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { getPurchasePayloadProducts } from "../../utils/global-fn";
import { addPurchase, updatePurchase } from "../../api/local/purchase-api";
import NewPurchaseSummary from "../../components/new-purchase/new-purchase-summary/NewPurchaseSummary";
import { Drawer } from "@mui/material";
import PurchaseDraftDrawer from "../../components/purchase/purchase-draft-drawer/PurchaseDraftDrawer";
import ProductDrawer from "../../components/product-drawer/product-drawer";
import SelectProductModal from "../../components/select-product-modal/select-product-modal";
import AddSupplierModal from "../../components/purchase/add-supplier-modal/add-supplier-modal";
import NewSupplierModal from "../../components/new-supplier-modal/new-supplier-modal";
import ClearCart from "../../components/purchase/clear-cart-modal/ClearCart";
import PurchasePaymentModal from "../../components/purchase-payment-modal/PurchasePaymentModal";

const NewPurchase = (props, ref) => {
    const {
        createdDate,
        selectedSupplier,
        purchaseProducts,
        currentScreen,
        billNo,
        selectedDraftPurchase,
        deliveryDate,
        deliveryExpense,
        globalDiscount,
        selectDiscount,
    } = useSelector((state) => state.purchase);
    const dispatch = useDispatch();
    const [scannedProduct, setScannedProduct] = useState(null);
    const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
    const [openProductDrawer, _setOpenProductDrawer] = useState(false);
    const openProductDrawerRef = useRef(openProductDrawer);
    const setOpenProductDrawer = (data) => {
        openProductDrawerRef.current = data;
        _setOpenProductDrawer(data);
    };
    const [productDrawerData, setProductDrawerData] = useState({
        ...INITIAL_PRODUCT_DISPLAY_DATA,
        sku: generateSKU(),
    });
    const [openDraftDrawer, setOpenDraftDrawer] = useState(false);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const [openPurchasePaymentModal, _setOpenPurchasePaymentModal] =
        useState(false);
    const openPurchasePaymentModalRef = useRef(openPurchasePaymentModal);
    const setOpenPurchasePaymentModal = (data) => {
        openPurchasePaymentModalRef.current = data;
        _setOpenPurchasePaymentModal(data);
    };
    const [openClearModal, _setOpenClearModal] = useState(false);
    const openClearModalRef = useRef(openClearModal);
    const setOpenClearModal = (data) => {
        openClearModalRef.current = data;
        _setOpenClearModal(data);
    };
    const [openPurchaseSummaryModal, setOpenPurchaseSummaryModal] =
        useState(false);
    const billRef = useRef();
    const delExpRef = useRef();
    const timeRef = useRef();
    const discountRef = useRef();

    useEffect(() => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            const removeEventListener = window.electronAPI.onBarcodeScanned(
                async (event, value) => await handleGetProduct(value)
            );
            return () => {
                removeEventListener();
            };
        }
    }, []);

    useEffect(() => {
        if (purchaseProducts?.length) {
            dispatch(setCurrentScreen(SCREENS.PURCHASE_TABLE));
        } else {
            dispatch(setCurrentScreen(SCREENS.PURCHASE_SUMMARY));
        }
    }, [purchaseProducts]);

    useImperativeHandle(ref, () => {
        return {
            openSupplierModal: () => setOpenSupplierModal(true),
            saveDraftPurchase: () => {
                if (!openClearModalRef.current) {
                    handleDraftPurchase();
                }
            },
            focusBill: () => billRef.current?.focus(),
            focusDelExp: () => delExpRef.current?.focus(),
            clearAll: () => setOpenClearModal(true),
            draftOrders: () => openDraftOrders(),
            addNewProduct: () => setOpenProductDrawer(true),
            proceedReturn: () => onProceedPurchase(),
            openDeliveryDate: () =>
                timeRef.current?.querySelector("input").click(),
            openDiscount: () => discountRef.current?.focus(),
            onBackEdit: () => handleBtnClick("back"),
            onBackAction: () => backAction(),
            onSave: () => handleBtnClick("save"),
            clearSupplier: () => dispatch(removeSelectedSupplier()),
        };
    });

    const handleGetProduct = async (barcode) => {
        try {
            let res = await getProduct(barcode, { status: "PUBLISH" });
            if (res.status === 200) {
                //Product is present
                if (res.data.length > 1) {
                    setScannedProduct(res.data);
                    setOpenSelectProductModal(true);
                } else {
                    dispatch(addProductToPurchase(res.data[0]));
                }
                setOpenProductDrawer(false);
            } else if (res.status === 404) {
                //Product is not present, Show the product slider to add the product
                setProductDrawerData({
                    ...INITIAL_PRODUCT_DISPLAY_DATA,
                    upc: barcode,
                    sku: generateSKU(),
                });
                setOpenProductDrawer(true);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleBackAction = () => {
        history.back();
    };

    const handleDateChange = (item) => {
        dispatch(setCreatedDate(item));
    };

    const openDraftOrders = () => {
        dispatch(fetchAllPurchases({ type: PURCHASE_TYPE.PURCHASE }));
        setOpenDraftDrawer(true);
    };

    const closeDraftOrders = () => {
        setOpenDraftDrawer(false);
        dispatch(removeAllSearchParams());
    };

    const closeProductDrawer = (product, type) => {
        setOpenProductDrawer(false);
        setProductDrawerData({
            ...INITIAL_PRODUCT_DISPLAY_DATA,
            sku: generateSKU(),
        });
        if (type === "Added" && product?.sku) {
            handleGetProduct(product.sku);
        }
    };

    const onProceedPurchase = () => {
        if (currentScreen === SCREENS.PURCHASE_SUMMARY) {
            handleBtnClick("purchase");
        } else {
            handleBtnClick("proceed");
        }
    };
    const backAction = () => {
        if (openDraftDrawer) {
            setOpenDraftDrawer(false);
        } else if (openClearModal) {
            setOpenClearModal(false);
        } else if (openProductDrawer) {
            setOpenProductDrawer(false);
        } else if (openSupplierModal) {
            setOpenSupplierModal(false);
        } else if (openPurchasePaymentModal) {
            setOpenPurchasePaymentModal(false);
        } else if (openPurchaseSummaryModal) {
            setOpenPurchaseSummaryModal(false);
        } else {
            handleBackAction();
        }
    };

    const handleBtnClick = (type) => {
        if (type === "proceed") {
            dispatch(setCurrentScreen(SCREENS.PURCHASE_SUMMARY));
        } else if (type === "back") {
            dispatch(setCurrentScreen(SCREENS.PURCHASE_TABLE));
        } else if (type === "purchase") {
            for (let item of purchaseProducts) {
                if (Number(item.quantity) <= 0) {
                    handleSnackbarDetails({
                        show: true,
                        title: `Please choose a quantity for ${item.name}`,
                        type: "error",
                    });
                    return;
                }
            }
            if (purchaseProducts.length === 0) {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Please add a product",
                });
                return;
            } else if (!selectedSupplier) {
                setOpenSupplierModal(true);
                return;
            } else {
                setOpenPurchasePaymentModal(true);
            }
        } else if (type === "draft") {
            handleDraftPurchase();
        }
    };

    const handleClearCart = () => {
        dispatch(removeAllPurchaseProducts());
        dispatch(removeSelectedSupplier());
        dispatch(removeSelectedDraftPurchase());
        dispatch(setDeliveryDate(null));
        dispatch(setBillNo(""));
        dispatch(setDeliveryExpense(""));
        dispatch(setCreatedDate(null));
        dispatch(
            setSelectDiscount({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
    };

    const handleDraftPurchase = async () => {
        if (!purchaseProducts.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of purchaseProducts) {
            if (Number(item.quantity) <= 0) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please choose a quantity for ${item.name}`,
                    type: "error",
                });
                return;
            }
        }

        const payload = {
            type: PURCHASE_TYPE.PURCHASE,
            status: STATUS.DRAFT,
            products: getPurchasePayloadProducts(purchaseProducts),
        };
        if (selectedSupplier) payload.supplier_id = selectedSupplier.id;
        if (billNo) payload.bill_no = billNo;
        if (deliveryExpense) payload.delivery_price = +deliveryExpense;
        if (createdDate) payload.created_at = createdDate.getTime();
        if (deliveryDate) payload.delivery_date = deliveryDate.getTime();
        if (parseFloat(selectDiscount.cost)) {
            let disc = {
                type: selectDiscount.type,
                value: globalDiscount,
            };
            payload.cart_discount = disc;
        }

        let res;
        try {
            if (selectedDraftPurchase) {
                res = await updatePurchase(selectedDraftPurchase.id, payload);
            } else {
                res = await addPurchase(payload);
            }

            if (res?.status === 200) {
                handleClearCart();
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Counter Saved",
                    subtitle: "Counter added successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
            handleSnackbarDetails({
                show: true,
                title: error?.response?.data?.message ?? error.message,
                type: "error",
            });
        }
    };

    const handleDraftDelete = async () => {
        const payload = {
            status: STATUS.TRASH,
        };
        try {
            let res = await updatePurchase(selectedDraftPurchase.id, payload);
            if (res.status === 200) {
                // dispatch(fetchAllPurchases(searchParams));
                dispatch(fetchAllPurchases());
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Counter deleted successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
            handleSnackbarDetails({
                show: true,
                title: "Something went wrong...",
                type: "error",
            });
        }
    };

    return (
        <>
            <PurchasePaymentModal
                open={openPurchasePaymentModal}
                onClose={() => setOpenPurchasePaymentModal(false)}
                type="purchase"
                additional={{
                    billno: billNo,
                    deliveryExpense,
                    createdAt: createdDate,
                    deliveryDate,
                    discount: globalDiscount,
                }}
                openPurchaseSummaryModal={openPurchaseSummaryModal}
                setOpenPurchaseSummaryModal={setOpenPurchaseSummaryModal}
                handleClearCart={handleClearCart}
            />
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openDraftDrawer}
                onClose={() => setOpenDraftDrawer(false)}
                className="right-drawer"
            >
                <PurchaseDraftDrawer
                    type={PURCHASE_TYPE.PURCHASE}
                    onCloseDrawer={closeDraftOrders}
                    open={openDraftDrawer}
                />
            </Drawer>
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openProductDrawerRef.current}
                onClose={() => setOpenProductDrawer(false)}
                className="right-drawer"
            >
                <ProductDrawer
                    type="add"
                    onCloseDrawer={(product, type) =>
                        closeProductDrawer(product, type)
                    }
                    productData={productDrawerData}
                    open={openProductDrawer}
                />
            </Drawer>
            <SelectProductModal
                open={openSelectProductModal}
                onClose={() => setOpenSelectProductModal(false)}
                data={scannedProduct}
            />

            <AddSupplierModal
                open={openSupplierModal}
                onClose={() => setOpenSupplierModal(false)}
                openAddNewModal={() => setOpenAddSupplierModal(true)}
            />
            <NewSupplierModal
                open={openAddSupplierModal}
                onClose={() => setOpenAddSupplierModal(false)}
                closeModal={() => setOpenSupplierModal(false)}
                onSave={() => {}}
            />
            <ClearCart
                open={openClearModal}
                onClose={() => setOpenClearModal(false)}
                handleClearCart={handleClearCart}
                handleDraftDelete={handleDraftDelete}
                handleDraftPurchase={handleDraftPurchase}
            />
            <S.Container>
                <S.Header>
                    <S.TopBar>
                        <BackButton onClickAction={handleBackAction} />
                        <S.DateContainer>
                            <S.Date>Date</S.Date>
                            <DatePicker
                                selected={Date.parse(
                                    calendarFormatDate(
                                        createdDate ?? new Date()
                                    )
                                )}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="new-purchase-date-picker"
                            />
                        </S.DateContainer>
                    </S.TopBar>
                    <S.ButtonBar>
                        <S.HeaderText>New Purchase</S.HeaderText>
                        <S.BtnContainer>
                            <CustomButton
                                label="Counter Orders (F11)"
                                color={COLOR.BLUE}
                                onClick={() => openDraftOrders()}
                                fill={true}
                            />
                            <CustomButton
                                label="Add New Product (F6)"
                                color={COLOR.BLUE}
                                onClick={() => setOpenProductDrawer(true)}
                                fill={true}
                            />
                            <CustomButton
                                label={`${
                                    selectedSupplier ? "Change" : "Add"
                                } Supplier (F4)`}
                                color={COLOR.BLUE}
                                onClick={() => setOpenSupplierModal(true)}
                                fill={true}
                            />
                            <CustomButton
                                label="Clear Cart (F9)"
                                color={COLOR.RED}
                                icon={RedClearCartIcon}
                                onClick={() => setOpenClearModal(true)}
                            />
                        </S.BtnContainer>
                    </S.ButtonBar>
                </S.Header>
                <S.Body>
                    {purchaseProducts?.length ? (
                        <NewPurchaseTable handleBtnClick={handleBtnClick} />
                    ) : (
                        <EmptyCart />
                    )}
                    {currentScreen === SCREENS.PURCHASE_SUMMARY && (
                        <NewPurchaseSummary
                            billRef={billRef}
                            delExpRef={delExpRef}
                            discountRef={discountRef}
                            timeRef={timeRef}
                            handleBtnClick={handleBtnClick}
                        />
                    )}
                </S.Body>
            </S.Container>
        </>
    );
};

export default React.forwardRef(NewPurchase);
