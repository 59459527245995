import React from "react";
import * as S from "../EmployeeLogin.style";
import { useSelector } from "react-redux";
import { useState } from "react";
import { PasswordEyeIcon, PasswordNoEyeIcon } from "../../../icons";
import { useRef } from "react";
import LoginAvatars from "../LoginAvatars";
import ResetPasswordDialog from "../../../components/PasswordDialog/ResetPasswordDialog";

const Login = ({ loginData, openPasswordDialog, setOpenPasswordDialog, user, onSubmit, toSendOtpState, handleInputChange, setPhone }) => {
    const store = useSelector((state) => state.session.store);
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef();

    const onAvatarClick = (data) => {
        passwordRef.current?.focus();
        setPhone(data.phone);
    };

    return (
        <S.LoginContainer>
            <S.ContainerHead>
                Login to <div>{store?.store_name}</div>
            </S.ContainerHead>
            <div>
                <S.Label>Enter phone number</S.Label>
                <S.InputFIelds>
                    <input type="text" name="phone" onChange={(e) => handleInputChange(e)} value={loginData.phone} autoFocus />
                </S.InputFIelds>
            </div>
            <div>
                <S.Label>Enter password</S.Label>
                <S.InputFIelds>
                    <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={(e) => handleInputChange(e)}
                        value={loginData.password}
                        ref={passwordRef}
                    />
                    <img src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon} alt="" onClick={() => setShowPassword(!showPassword)} />
                </S.InputFIelds>
            </div>
            <S.ForgetText onClick={toSendOtpState}>Forget/Change Password</S.ForgetText>
            <S.BlueBtn onClick={onSubmit}>Login</S.BlueBtn>
            <ResetPasswordDialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)} user={user} />
            <LoginAvatars onClick={onAvatarClick} />
        </S.LoginContainer>
    );
};

export default Login;
