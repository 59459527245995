import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: relative;
    margin-bottom: 12px;
    height: calc(100vh - 380px);
    width: 100%;
`;

export const PinIcon = styled.img`
    position: absolute;
    top: 139px;
    left: 242px;
`;

export const ImgContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
        max-width: 100%;
        height: auto;
        margin-top: -50%;
    }
`;
