import React, { useEffect } from "react";
import { useState } from "react";
import * as S from "./style";
import OutsideClickHandler from "react-outside-click-handler";
import { DUE_TYPE } from "../../../constants/appConstants";
import { SelectArrowDownIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { removeSelectedChipDue, setSelectedChipDue } from "../../../store/due-slice";
import { setSearchSaleParams } from "../../../store/sale-slice";

const CustomDueSelect = ({ text, handleChange }) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedDue, setSelectedDue] = useState(null);
    const [minDue, setMinDue] = useState(null);
    const [maxDue, setMaxDue] = useState(null);
    const dispatch = useDispatch();
    const { selectedDue: customerSelectedDue } = useSelector((state) => state.due);
    useEffect(() => {
        if (!customerSelectedDue) {
            setSelectedDue(null);
        }
    }, [customerSelectedDue]);

    const handleSelectDue = (val) => {
        setSelectedDue(val);
        if (!val) {
            dispatch(removeSelectedChipDue());
            return;
        }
        handleChange(val);
        if (val === DUE_TYPE.CUSTOM_DUE) return;

        setOpenMenu(false);
    };

    const handleCustomStock = () => {
        dispatch(setSelectedChipDue(`Custom (${minDue} - ${maxDue})`));
        setOpenMenu(false);
    };

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setOpenMenu(false);
            }}
        >
            <S.SelectContainer onClick={() => setOpenMenu(!openMenu)}>
                <S.SelectText>{text}</S.SelectText>
                <img src={SelectArrowDownIcon} alt="select" />
                {openMenu && (
                    <S.MenuContainer>
                        <S.CheckboxContainer
                            onClick={() =>
                                handleSelectDue(
                                    selectedDue === DUE_TYPE.ALL_DUE ? null : DUE_TYPE.ALL_DUE
                                )
                            }
                        >
                            <S.CheckboxTop>
                                <input checked={selectedDue === DUE_TYPE.ALL_DUE} type="checkbox" />
                                <S.CheckboxText>Due (All)</S.CheckboxText>
                            </S.CheckboxTop>
                        </S.CheckboxContainer>{" "}
                        <S.CheckboxContainer
                            onClick={() =>
                                handleSelectDue(
                                    selectedDue === DUE_TYPE.NO_DUE ? null : DUE_TYPE.NO_DUE
                                )
                            }
                        >
                            <S.CheckboxTop>
                                <input checked={selectedDue === DUE_TYPE.NO_DUE} type="checkbox" />
                                <S.CheckboxText>No Due (All)</S.CheckboxText>
                            </S.CheckboxTop>
                        </S.CheckboxContainer>{" "}
                        {/* <S.CheckboxContainer
                            onClick={() =>
                                handleSelectDue(
                                    selectedDue === DUE_TYPE.CUSTOM_DUE
                                        ? null
                                        : DUE_TYPE.CUSTOM_DUE
                                )
                            }
                        >
                            <S.CheckboxTop>
                                <input
                                    checked={selectedDue === DUE_TYPE.CUSTOM_DUE}
                                    type="checkbox"
                                />
                                <S.CheckboxText>Due Range</S.CheckboxText>
                            </S.CheckboxTop>
                            {selectedDue === DUE_TYPE.CUSTOM_DUE && (
                                <>
                                    <S.Range onClick={(e) => e.stopPropagation()}>
                                        <S.RangeInput
                                            value={minDue}
                                            onChange={(e) => setMinDue(e.target.value)}
                                            type="number"
                                            placeholder="Min"
                                        />
                                        <S.RangeInput
                                            value={maxDue}
                                            onChange={(e) => setMaxDue(e.target.value)}
                                            type="number"
                                            placeholder="Max"
                                        />
                                    </S.Range>
                                    <S.CustomDueRangeSubmit
                                        id="custom-due-range-submit"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleCustomStock();
                                        }}
                                    >
                                        OK
                                    </S.CustomDueRangeSubmit>
                                </>
                            )}
                        </S.CheckboxContainer> */}
                    </S.MenuContainer>
                )}
            </S.SelectContainer>
        </OutsideClickHandler>
    );
};

export default CustomDueSelect;
