import React from "react";
import * as S from "../EmployeeLogin.style";
import LoginAvatars from "../LoginAvatars";

const SendOtp = ({ loginData, handleInputChange, setPhone, onSubmit }) => {
    const onAvatarClick = (data) => {
        setPhone(data.phone);
    };

    const onSendOtp = () => {
        onSubmit();
    };

    return (
        <S.LoginContainer>
            <S.ContainerHead>Forget Password</S.ContainerHead>
            <div>
                <S.Label>Enter phone number</S.Label>
                <S.InputFIelds>
                    <input type="text" name="phone" onChange={(e) => handleInputChange(e)} value={loginData.phone} autoFocus />
                </S.InputFIelds>
            </div>
            <S.BlueBtn onClick={onSendOtp}>Send OTP</S.BlueBtn>
            <LoginAvatars onClick={onAvatarClick} />
        </S.LoginContainer>
    );
};

export default SendOtp;
