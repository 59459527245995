import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
`;

export const Body = styled.div``;
export const Heading = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #475569;
    margin-bottom: 20px;
    text-transform: uppercase;
`;
export const InputField = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 34px;
    & > div {
        flex: 1;
    }
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    //   top: 39px;
    //   right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    max-height: 36px;
    cursor: pointer;
    justify-content: space-between;

    img {
        height: 20px;
        width: 20px;
        // margin-left: 8px;
    }
`;

export const LeftDiv = styled.div`
    // border-right: 1px solid #e2e8f0;
    // padding-right: 15px;
    // border: 1px solid red;
    width: 324px;
    & > div {
        margin-bottom: 10px;
    }
`;
export const MidDiv = styled.div`
    margin: 0 15px;

    & > div {
        margin-bottom: 10px;
    }
`;
export const RightDiv = styled.div`
    margin-left: 15px;

    & > div {
        margin-bottom: 10px;
    }
`;

export const NextBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #253238;
    border: 1px solid #253238;
    border-radius: 4px;
`;
export const SaveBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    cursor: pointer;
`;
export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FooterBtns = styled.div`
    display: flex;
    gap: 10px;
    height: 46px;
    margin-top: 15px;
`;
export const FooterBtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;
`;
export const NetSalary = styled.div`
    visibility: hidden;
`;

export const Hidden = styled.div`
    visibility: hidden;
`;
export const AddEntryDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 5px;
    border: 1px solid #e2e8f0;
    border-radius: 3px;
    cursor: pointer;
    width: 88px;
    height: 32px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
    & img {
        height: 14px;
        width: 14px;
    }
`;

export const AdvSalaryBtns = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-right: 5px;
`;
export const ViewEntryBtn = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    color: #004463;
    cursor: pointer;
`;
