import React, { useEffect, useState, useImperativeHandle } from "react";
import * as S from "./OnlineOrders.style";
import { ONLINE_ORDERS_FOCUS, ONLINE_ORDERS_STATUS, ONLINE_ORDER_PARAMS_STATUS } from "./../../constants/appConstants";
import Order from "./../../components/online-orders/Order/Order";
import EmptyScreen from "./../../components/online-orders/empty-placeholder/EmptyScreen";
import { useContext } from "react";
import { OrderSnackbarContext } from "./../../order-snackbar-context/order-snackbar-context";
import { useDispatch } from "react-redux";
import {
    fetchAllOnlineSales,
    removeSingleOrder,
    setOnlineSaleParams,
    setScreenFocus,
    setSingleOrder,
    removeAllOnlineSaleParams,
    showLoading,
    removeAllOrders,
    setCronOrders,
} from "../../store/onlinesale-slice";
import { useSelector } from "react-redux";
import ProductSection from "./../../components/online-orders/product-section/ProductSection";
import { useRef } from "react";
import WarningDialog from "../../components/online-orders/warning-dialog/WarningDialog";
import store from "../../store/store";
import { getOnlineSale } from "../../api/local/onlineSale-api";
import CircularLoader from "../../components/circular-loader/CircularLoader";
import ScrollLoader from "../../components/scroll-loader/ScrollLoader";
import Lottie from "lottie-react";
import EmptyOrderLottie from "../../animations/empty-order.json";
import ShortcutBtn from "../../components/shortcut-btn/ShortcutBtn";
import { debounce, isEmpty } from "lodash";
import { useCallback } from "react";
import { captureException } from "../../crash-reporting";

const OnlineOrders = ({ globalRef, openDrawerRef }, ref) => {
    const [activeTab, setActiveTab] = useState(ONLINE_ORDERS_STATUS.NEW_ORDER);
    const { handleOrderSnackbarDetails } = useContext(OrderSnackbarContext);
    const dispatch = useDispatch();
    const { orders, params, order, fulifillingOrder, loading } = useSelector((state) => state.onlineSale);
    const productSectionRef = useRef();
    const [openWarning, setOpenWarning] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [activeOrderRow, _setActiveOrderRow] = useState(0);
    const activeOrderRowRef = React.useRef(activeOrderRow);
    const setActiveOrderRow = (data) => {
        activeOrderRowRef.current = data;
        _setActiveOrderRow(data);
    };

    const [activeProductRow, _setActiveProductRow] = useState(0);
    const activeProductRowRef = React.useRef(activeProductRow);
    const setActiveProductRow = (data) => {
        activeProductRowRef.current = data;
        _setActiveProductRow(data);
    };
    const rowsRefs = useRef([]);
    const productsRef = useRef();
    const scrollRef = useRef(null);
    const [openConfirmationDialog, _setOpenConfirmationDialog] = useState(false);
    const openConfirmationDialogRef = useRef(openConfirmationDialog);

    const setOpenConfirmationDialog = (data) => {
        openConfirmationDialogRef.current = data;
        _setOpenConfirmationDialog(data);
    };
    const [keyboardBuffer, _setKeyboardBuffer] = useState(null);
    const keyboardBufferRef = useRef(keyboardBuffer);
    const setKeyboardBuffer = (data) => {
        keyboardBufferRef.current = data;
        _setKeyboardBuffer(data);
    };

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    }, [activeOrderRow]);

    const handleDetectKeyDown = (e) => {
        if (globalRef.current?.value || openDrawerRef.current) return;
        if (keyboardBufferRef.current && Date.now() - keyboardBufferRef.current?.t < 50) {
            return;
        }
        setKeyboardBuffer({
            k: e.keyCode,
            t: Date.now(),
        });
        if (e.keyCode === 33) {
            //Pg Up key
            let products = store.getState().onlineSale.packingProducts;
            let focus = store.getState().onlineSale.screenFocus;
            if (focus === ONLINE_ORDERS_FOCUS.PRODUCTS_SECTION) {
                if (activeProductRowRef.current > 0) {
                    setActiveProductRow(activeProductRowRef.current - 1);
                    rowsRefs.current[activeProductRowRef.current].focus();
                    e.preventDefault();
                }
            } else {
                if (activeOrderRowRef.current > 0) {
                    setActiveOrderRow(activeOrderRowRef.current - 1);
                    e.preventDefault();
                }
            }
        } else if (e.keyCode === 34) {
            //Pg Down key
            let order = store.getState().onlineSale.order;
            let products = store.getState().onlineSale.packingProducts.filter((p) => p.order_id === order.id)[0]?.products;
            let orders = store.getState().onlineSale.orders;
            let focus = store.getState().onlineSale.screenFocus;
            if (focus === ONLINE_ORDERS_FOCUS.PRODUCTS_SECTION) {
                if (activeProductRowRef.current < products?.length - 1) {
                    setActiveProductRow(activeProductRowRef.current + 1);
                    rowsRefs.current[activeProductRowRef.current].focus();
                    e.preventDefault();
                }
            } else {
                if (activeOrderRowRef.current < orders?.length - 1) {
                    setActiveOrderRow(activeOrderRowRef.current + 1);
                    e.preventDefault();
                }
            }
        } else if (e.keyCode === 38) {
            //Up Arrow key
            e.preventDefault();
            productsRef.current?.onIncreaseQuantity();
        } else if (e.keyCode === 40) {
            //Down Arrow key
            e.preventDefault();
            productsRef.current?.onDecreaseQuantity();
        } else if (e.keyCode === 13) {
            //Enter key
            let focus = store.getState().onlineSale.screenFocus;
            if (focus === ONLINE_ORDERS_FOCUS.ORDERS_SECTION && !openConfirmationDialogRef.current) {
                e.preventDefault();
                let order = store.getState().onlineSale.orders[activeOrderRowRef.current];
                handleOnlineOrderClick(order, activeOrderRowRef.current);
            }
        } else if (e.keyCode === 27) {
            //Esc key
            // orderElementRef.current[0].focus();
            dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
        }
    };

    const handleRowFocus = (row) => {
        setActiveProductRow(row);
        rowsRefs.current[row].focus();
    };

    const handleTabSelect = (item) => {
        // if (loading) return;
        dispatch(showLoading());
        setActiveTab(item);
        delayedQuery(item);
    };

    const delayedFunction = (item) => {
        // setActiveTab(item);
        if (item === ONLINE_ORDERS_STATUS.NEW_ORDER) {
            dispatch(
                setOnlineSaleParams({
                    status: `${ONLINE_ORDER_PARAMS_STATUS.ORDERED},${ONLINE_ORDER_PARAMS_STATUS.PACKING}`,
                })
            );
        } else if (item === ONLINE_ORDERS_STATUS.PACKED) {
            dispatch(setOnlineSaleParams({ status: ONLINE_ORDER_PARAMS_STATUS.PACKED }));
        } else if (item === ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY) {
            dispatch(setOnlineSaleParams({ status: ONLINE_ORDER_PARAMS_STATUS.OUT_FOR_DELIVERY }));
        } else if (item === ONLINE_ORDERS_STATUS.DELIVERED) {
            dispatch(
                setOnlineSaleParams({
                    status: `${ONLINE_ORDER_PARAMS_STATUS.DELIVERED},${ONLINE_ORDER_PARAMS_STATUS.CANCELLED}`,
                })
            );
        }
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 500), []);

    useEffect(() => {
        if (!isEmpty(params)) {
            dispatch(fetchAllOnlineSales(params));
        }
        return () => {
            dispatch(removeSingleOrder());
        };
    }, [params]);

    useEffect(() => {
        if (orders?.length) {
            dispatch(setCronOrders({ orders: orders, status: ONLINE_ORDER_PARAMS_STATUS.ORDERED }));
        }
    }, [orders]);

    useEffect(() => {
        dispatch(
            setOnlineSaleParams({
                status: `${ONLINE_ORDER_PARAMS_STATUS.ORDERED},${ONLINE_ORDER_PARAMS_STATUS.PACKING}`,
            })
        );
        if (loading === false && orders?.length > 0) {
            const orderIndex = orders.findIndex((item) => item.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING);
            if (orderIndex >= 0) {
                handleOnlineOrderClick(orders[orderIndex], orderIndex);
            }
        }
        return () => {
            // dispatch(removeAllOrders());
            dispatch(removeAllOnlineSaleParams());
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            changeTab: () => onChangeTab(),
            cancelOrder: () => {
                let focus = store.getState().onlineSale.screenFocus;
                let order = store.getState().onlineSale.order;
                if (order) {
                    productSectionRef.current?.onCancelOrder();
                }
            },
            onSubmit: () => {
                let order = store.getState().onlineSale.order;
                if (order) {
                    productSectionRef.current?.onSubmit();
                }
            },
        };
    });

    const onChangeTab = () => {
        if (activeTab === ONLINE_ORDERS_STATUS.NEW_ORDER) {
            handleTabSelect(ONLINE_ORDERS_STATUS.PACKED);
        } else if (activeTab === ONLINE_ORDERS_STATUS.PACKED) {
            handleTabSelect(ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY);
        } else if (activeTab === ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY) {
            handleTabSelect(ONLINE_ORDERS_STATUS.DELIVERED);
        } else if (activeTab === ONLINE_ORDERS_STATUS.DELIVERED) {
            handleTabSelect(ONLINE_ORDERS_STATUS.NEW_ORDER);
        }
    };

    const handleOnlineOrderClick = async (item, idx) => {
        // let fulfillingOrderData = store.getState().onlineSale.fulifillingOrder;
        // if (fulfillingOrderData && item.id !== fulfillingOrderData.id && item.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING) {
        //     setOpenWarning(true);
        //     return;
        // }
        setActiveOrderRow(idx);
        dispatch(removeSingleOrder());
        // dispatch(removeAllPackingProducts());
        setProductLoading(true);
        try {
            let res = await getOnlineSale(item.id);
            if (res.status === 200) {
                dispatch(setSingleOrder(res.data));
                setProductLoading(false);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
        }
    };

    return (
        <>
            <WarningDialog
                open={openWarning}
                onClose={() => setOpenWarning(false)}
                data={{
                    title: "You can’t start other fulfillment.",
                    subtitle: "Another fulfillment is going on.",
                }}
            />
            <S.Wrapper>
                <S.OrderWrapper>
                    <S.Header>
                        <S.Head>Online Orders</S.Head>
                        <ShortcutBtn text="TAB" />
                    </S.Header>
                    <S.Tabs>
                        <S.Tab onClick={() => handleTabSelect(ONLINE_ORDERS_STATUS.NEW_ORDER)} active={activeTab === ONLINE_ORDERS_STATUS.NEW_ORDER}>
                            {ONLINE_ORDERS_STATUS.NEW_ORDER}
                        </S.Tab>
                        <S.Tab onClick={() => handleTabSelect(ONLINE_ORDERS_STATUS.PACKED)} active={activeTab === ONLINE_ORDERS_STATUS.PACKED}>
                            {ONLINE_ORDERS_STATUS.PACKED}
                        </S.Tab>
                        <S.Tab
                            onClick={() => handleTabSelect(ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY)}
                            active={activeTab === ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY}
                        >
                            {ONLINE_ORDERS_STATUS.OUT_FOR_DELIVERY}
                        </S.Tab>
                        <S.Tab onClick={() => handleTabSelect(ONLINE_ORDERS_STATUS.DELIVERED)} active={activeTab === ONLINE_ORDERS_STATUS.DELIVERED}>
                            {ONLINE_ORDERS_STATUS.DELIVERED}
                        </S.Tab>
                    </S.Tabs>
                    <S.OrderList>
                        {loading ? (
                            <ScrollLoader />
                        ) : orders?.length ? (
                            orders?.map((item, idx) => (
                                <Order
                                    item={item}
                                    key={idx}
                                    setOpenWarning={setOpenWarning}
                                    activeOrderRow={activeOrderRow}
                                    setActiveOrderRow={setActiveOrderRow}
                                    idx={idx}
                                    handleOnlineOrderClick={handleOnlineOrderClick}
                                    scrollRef={scrollRef}
                                />
                            ))
                        ) : (
                            <S.EmptyOrderContainer>
                                <div>
                                    <Lottie animationData={EmptyOrderLottie} />
                                </div>
                            </S.EmptyOrderContainer>
                        )}
                    </S.OrderList>
                </S.OrderWrapper>
                {productLoading ? (
                    <CircularLoader />
                ) : order?.products?.length ? (
                    <ProductSection
                        activeProductRow={activeProductRow}
                        activeProductRowRef={activeProductRowRef}
                        rowsRefs={rowsRefs}
                        setOpenWarning={setOpenWarning}
                        productsRef={productsRef}
                        setLoading={setProductLoading}
                        setActiveOrderRow={setActiveOrderRow}
                        openConfirmationDialogRef={openConfirmationDialogRef}
                        setOpenConfirmationDialog={setOpenConfirmationDialog}
                        handleRowFocus={handleRowFocus}
                        ref={productSectionRef}
                    />
                ) : (
                    <EmptyScreen />
                )}
            </S.Wrapper>
        </>
    );
};

export default React.forwardRef(OnlineOrders);
