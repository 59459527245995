import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import {
  INVENTORY_SELECT,
  INVENTORY_STOCK_FILTER_TEXT,
  STOCK_MAP_TYPES,
  STOCK_TYPE,
} from "../../../constants/appConstants";
import {
  AddCustomerSearchIcon,
  AddNewTypeCheckIcon,
  AddNewTypeCloseIcon,
  PlusBlueIcon,
  SelectArrowDownIcon,
} from "../../../icons";
import { fetchAllBrands, setSelectedBrand } from "../../../store/brand-slice";
import {
  fetchAllCategories,
  setSelectedCategory,
} from "../../../store/category-slice";
import {
  removeSearchParams,
  removeSort,
  setSearchParams,
  setSort,
} from "../../../store/product-slice";
import {
  removeSelectedChipStock,
  setSelectedChipStock,
} from "../../../store/stock-slice";
import {
  fetchAllSuppliers,
  setSelectedSupplier,
} from "../../../store/supplier-slice";
import ShortcutBtn from "../../shortcut-btn/ShortcutBtn";

import * as S from "./custom-select.styles";
import SortMenu from "./sort-menu/sort-menu";

const CustomMenu = ({
  closeMenu,
  data,
  onChange,
  text,
  searchValue,
  setSearchValue,
  scrollRef,
  activeRow,
}) => {
  const [showNewTypeInput, setShowNewTypeInput] = useState(false);

  return (
    <>
      <S.SearchContainer>
        <S.SearchInput
          value={searchValue.current}
          onChange={(e) => setSearchValue(e.target.value)}
          type="text"
          placeholder="Search"
          autoFocus
        />
        <img src={AddCustomerSearchIcon} alt="search" />
      </S.SearchContainer>

      <S.SearchList onClick={closeMenu}>
        {Array.isArray(data) &&
          data?.length &&
          data.map((item, idx) => (
            <S.SearchData
              onClick={() => onChange(item)}
              key={idx}
              active={idx === activeRow}
              ref={idx === activeRow ? scrollRef : null}
            >
              {item.name}
            </S.SearchData>
          ))}
      </S.SearchList>
    </>
  );
};

const CustomCheckboxMenu = ({
  selectedStock,
  handleSelectStock,
  onCustomStock,
  minStock,
  maxStock,
  setMinStock,
  setMaxStock,
  activeRow,
  scrollRef,
  data,
}) => {
  return (
    <>
      {data.map((item, idx) => (
        <S.CheckboxContainer
          onClick={() => handleSelectStock(item.type)}
          key={idx}
          active={idx === activeRow}
          ref={idx === activeRow ? scrollRef : null}
        >
          <S.CheckboxTop>
            <S.CheckboxText>{item.name}</S.CheckboxText>
          </S.CheckboxTop>
        </S.CheckboxContainer>
      ))}

      {selectedStock === STOCK_TYPE.CUSTOM_STOCK && (
        <>
          <S.Range onClick={(e) => e.stopPropagation()}>
            <S.RangeInput
              value={minStock}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (!(e.target.value === "" || regex.test(e.target.value)))
                  return;
                setMinStock(e.target.value);
              }}
              type="text"
              placeholder="Min"
              autoFocus
            />
            <S.RangeInput
              value={maxStock}
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if (!(e.target.value === "" || regex.test(e.target.value)))
                  return;
                setMaxStock(e.target.value);
              }}
              type="text"
              placeholder="Max"
            />
          </S.Range>
          <S.CustomStockRangeSubmit
            id="custom-stock-range-submit"
            onClick={(e) => {
              e.stopPropagation();
              onCustomStock();
            }}
          >
            OK
          </S.CustomStockRangeSubmit>
        </>
      )}
    </>
  );
};

const Menu = ({
  type,
  onOptionSelect,
  setOpenMenu,
  data,
  onSortSelect,
  text,
  selectedStock,
  handleSelectStock,
  onCustomStock,
  minStock,
  maxStock,
  setMinStock,
  setMaxStock,
  searchValue,
  setSearchValue,
  openMenuRef,
  setActiveRow,
  activeRowRef,
  activeRow,
  selectedStockRef,
}) => {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openMenuRef.current) {
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
      setActiveRow(-1);
    };
  }, [data]);
  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }, [activeRow]);

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 38) {
      //Arrow up
      if (activeRowRef.current > 0) {
        setActiveRow(activeRowRef.current - 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 40) {
      //Arrow down
      if (activeRowRef.current < data.length - 1) {
        setActiveRow(activeRowRef.current + 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 13) {
      //Enter key
      if (
        activeRowRef.current < 0 &&
        selectedStockRef.current !== STOCK_TYPE.CUSTOM_STOCK
      ) {
        e.preventDefault();
        return;
      }
      if (type === INVENTORY_SELECT.NORMAL_SELECT || !type) {
        onOptionSelect(data[activeRowRef.current]);
      } else if (type === INVENTORY_SELECT.SORT_SELECT) {
        handleChange(data[activeRowRef.current]);
      } else if (type === INVENTORY_SELECT.STOCK_SELECT) {
        if (selectedStockRef.current === STOCK_TYPE.CUSTOM_STOCK) {
          onCustomStock();
        } else {
          handleSelectStock(data[activeRowRef.current].type);
        }
      }
      e.preventDefault();
    } else if (e.keyCode === 27) {
      //ESC key
      onESChandle();
      e.preventDefault();
    }
  };

  const handleChange = (item) => {
    dispatch(removeSearchParams("is_reversed"));
    onSortSelect(item.name);

    const payload = { sort_by: item.search_key };
    if (+item.id % 2 === 0) payload.is_reversed = true;
    if (+item.id % 2 === 1) payload.is_reversed = false;
    dispatch(setSearchParams(payload));
    dispatch(setSort(item));
    setOpenMenu(false);
  };

  const onESChandle = () => {
    if (searchValue.current) {
      setSearchValue("");
    } else {
      setOpenMenu(false);
    }
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpenMenu(false);
      }}
    >
      <S.MenuContainer
        checkboxMenu={type === INVENTORY_SELECT.STOCK_SELECT ? true : false}
        type={type}
      >
        {type === INVENTORY_SELECT.STOCK_SELECT && (
          <CustomCheckboxMenu
            selectedStock={selectedStock}
            handleSelectStock={handleSelectStock}
            onCustomStock={onCustomStock}
            minStock={minStock}
            maxStock={maxStock}
            setMinStock={setMinStock}
            setMaxStock={setMaxStock}
            activeRow={activeRow}
            scrollRef={scrollRef}
            data={data}
          />
        )}
        {type === INVENTORY_SELECT.SORT_SELECT && (
          <SortMenu
            onSortSelect={onSortSelect}
            closeMenu={() => setOpenMenu(false)}
            activeRow={activeRow}
            scrollRef={scrollRef}
            data={data}
          />
        )}
        {(type === INVENTORY_SELECT.NORMAL_SELECT || !type) && (
          <CustomMenu
            text={text}
            onChange={onOptionSelect}
            data={data}
            closeMenu={() => setOpenMenu(false)}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            activeRow={activeRow}
            scrollRef={scrollRef}
          />
        )}
      </S.MenuContainer>
    </OutsideClickHandler>
  );
};

const CustomSelect = ({
  text,
  data,
  type,
  clickRef,
  scText,
  selectRef,
  name,
  trackRef,
}) => {
  const [openMenu, _setOpenMenu] = useState(false);
  const [searchValue, _setSearchValue] = useState("");
  const searchValueRef = useRef(searchValue);
  const setSearchValue = (data) => {
    searchValueRef.current = data;
    _setSearchValue(data);
  };
  const [selectedStock, _setSelectedStock] = useState(null);
  const selectedStockRef = useRef(selectedStock);
  const setSelectedStock = (data) => {
    selectedStockRef.current = data;
    _setSelectedStock(data);
  };
  const [minStock, _setMinStock] = useState(null);
  const minStockRef = useRef(minStock);
  const setMinStock = (data) => {
    minStockRef.current = data;
    _setMinStock(data);
  };
  const [maxStock, _setMaxStock] = useState(null);
  const maxStockRef = useRef(minStock);
  const setMaxStock = (data) => {
    maxStockRef.current = data;
    _setMaxStock(data);
  };
  const [selectedSort, setSelectedSort] = useState("Name (A-Z)");
  const searchParams = useSelector((state) => state.product.searchParams);
  const selectedChipStock = useSelector(
    (state) => state.stock.inventorySelectedStock
  );
  const dispatch = useDispatch();

  const openMenuRef = useRef(openMenu);
  const setOpenMenu = (data) => {
    openMenuRef.current = data;
    _setOpenMenu(data);
    selectRef.current = data;
    if (openMenuRef.current) {
      trackRef.current = name;
    } else {
      trackRef.current = null;
    }
  };

  const [activeRow, _setActiveRow] = useState(-1);
  const activeRowRef = React.useRef(activeRow);
  const setActiveRow = (data) => {
    activeRowRef.current = data;
    _setActiveRow(data);
  };

  const delayedFunction = (val) => {
    if (text === "Category") dispatch(fetchAllCategories(val));
    if (text === "Brands") dispatch(fetchAllBrands(val));
    if (text === "Supplier") dispatch(fetchAllSuppliers(val));
  };
  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

  useEffect(() => {
    if (!selectedChipStock) {
      setMinStock(null);
      setMaxStock(null);
      setSelectedStock(null);
      if (searchParams.stock_type) dispatch(removeSearchParams("stock_type"));
      if (searchParams.stock_min) dispatch(removeSearchParams("stock_min"));
      if (searchParams.stock_max) dispatch(removeSearchParams("stock_max"));
    }
  }, [selectedChipStock]);

  const handleCustomStock = () => {
    if (!(minStockRef.current && maxStockRef.current)) return;
    dispatch(
      setSelectedChipStock(
        `Custom (${minStockRef.current} - ${maxStockRef.current})`
      )
    );
    dispatch(removeSearchParams("stock_type"));
    if (minStockRef.current) {
      if (searchParams.page)
        dispatch(setSearchParams({ stock_min: minStockRef.current, page: 1 }));
      else dispatch(setSearchParams({ stock_min: minStockRef.current }));
    } else dispatch(removeSearchParams("stock_min"));
    if (maxStockRef.current) {
      if (searchParams.page)
        dispatch(setSearchParams({ stock_max: maxStockRef.current, page: 1 }));
      else dispatch(setSearchParams({ stock_max: maxStockRef.current }));
    } else dispatch(removeSearchParams("stock_max"));

    setOpenMenu(false);
  };

  const handleSearch = (val) => {
    setSearchValue(val);
    delayedQuery(val);
  };

  const handleOptionSelect = (item) => {
    switch (text) {

      case "Category":
        dispatch(setSelectedCategory(item.name));
        if (searchParams.page)
          dispatch(setSearchParams({ category: item.id, page: 1 }));
        else dispatch(setSearchParams({ category: item.id }));
        break;

      case "Brands":
        dispatch(setSelectedBrand(item.name));
        if (searchParams.page)
          dispatch(setSearchParams({ brand: item.id, page: 1 }));
        else dispatch(setSearchParams({ brand: item.id }));
        break;

      case "Supplier":
        dispatch(setSelectedSupplier(item.name));
        if (searchParams.page)
          dispatch(setSearchParams({ supplier: item.id, page: 1 }));
        else dispatch(setSearchParams({ supplier: item.id }));
        break;

      default:
        break;
    }
    setOpenMenu(false);
  };

  const handleSelectStock = (val) => {
    setSelectedStock(val);
    if (!val) {
      dispatch(removeSelectedChipStock());
      return;
    }
    if (val === STOCK_TYPE.CUSTOM_STOCK) return;
    if (searchParams.page)
      dispatch(setSearchParams({ stock_type: val, page: 1 }));
    else dispatch(setSearchParams({ stock_type: val }));

    dispatch(
      setSelectedChipStock(
        val === STOCK_TYPE.OUT_OF_STOCK
          ? "Out of Stock"
          : val === STOCK_TYPE.LOW_STOCK
          ? "Low Stock"
          : "Unlimited Stock"
      )
    );
    setOpenMenu(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        // setOpenMenu(false);
      }}
    >
      <S.SelectContainer
        scText
        ref={clickRef}
        id="custom-select-container"
        onClick={(e) => {
          if (
            (e.target.attributes &&
              e.target.attributes?.id?.value === "custom-select-container") ||
            (e.target.parentElement?.attributes &&
              e.target.parentElement?.attributes?.id?.value ===
                "custom-select-container")
          )
            setOpenMenu(!openMenu);
        }}
      >
        <S.SelectText>
          {type === INVENTORY_SELECT.SORT_SELECT ? selectedSort : text}
        </S.SelectText>
        <img src={SelectArrowDownIcon} alt="select" />
        {scText && <ShortcutBtn text={scText} />}
        {openMenu && (
          <Menu
            type={type}
            onOptionSelect={handleOptionSelect}
            setOpenMenu={setOpenMenu}
            data={data}
            text={text}
            onSortSelect={(val) => setSelectedSort(val)}
            selectedStock={selectedStock}
            handleSelectStock={handleSelectStock}
            onCustomStock={handleCustomStock}
            minStock={minStock}
            maxStock={maxStock}
            setMinStock={setMinStock}
            setMaxStock={setMaxStock}
            searchValue={searchValueRef}
            setSearchValue={handleSearch}
            openMenuRef={openMenuRef}
            activeRow={activeRow}
            activeRowRef={activeRowRef}
            setActiveRow={setActiveRow}
            selectedStockRef={selectedStockRef}
          />
        )}
      </S.SelectContainer>
    </OutsideClickHandler>
  );
};

export default CustomSelect;
