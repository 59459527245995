import styled from "@emotion/styled/macro";

export const SalesContainer = styled.div`
    width: 100%;
    height: calc(100vh-56px);
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #f1f5f9;
    overflow-y: auto;
    box-sizing: border-box;
`;
export const Headbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const HeadContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const HeaderText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: ${(props) => (props.active ? "#1e293b" : "#94a3b8")};
    cursor: pointer;
`;
export const RightHeadBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`;
export const DatePicker = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 200px;
`;
export const Created = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
`;

export const CartBody = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
`;
