import React from "react";
import { Modal } from "@mui/material";
import * as S from "./PlanBuyModal.style";
import { CrossBlackIcon } from "../../icons";
import { useState } from "react";
import { BUY_PLAN_STATES } from "../../constants/appConstants";
import CreatePlan from "./create-plan/CreatePlan";
import PaymentMethods from "./payment-methods/PaymentMethods";
import PaymentSuccess from "./payment-success/PaymentSuccess";
import PaymentFail from "./payment-fail/PaymentFail";
import { useSelector } from "react-redux";
import { PLAN_TYPE } from "../../constants/POS-plans";

const initialCount = {
    desktop: 0,
    mobile: 0,
};

const PlanBuyModal = ({ open, onClose }) => {
    const [planState, setPlanState] = useState(BUY_PLAN_STATES.CREATE_PLAN);
    const [posCounter, setPosCounter] = useState(initialCount);
    const [cost, setCost] = useState(0);
    const { posPlan } = useSelector((state) => state.app);

    const closeModal = () => {
        if (posPlan?.info?.type !== PLAN_TYPE.PLAN_EXPIRED) {
            onClose();
        }
    };
    return (
        <Modal open={open} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                {posPlan?.info?.type !== PLAN_TYPE.PLAN_EXPIRED && <S.CloseBtn src={CrossBlackIcon} alt="close" onClick={closeModal} />}
                {planState === BUY_PLAN_STATES.CREATE_PLAN && <CreatePlan posCounter={posCounter} setPosCounter={setPosCounter} cost={cost} />}
                {planState === BUY_PLAN_STATES.PAYMENT_METHOD && <PaymentMethods posCounter={posCounter} cost={cost} />}
                {planState === BUY_PLAN_STATES.PAYMENT_SUCCESS && <PaymentSuccess />}
                {planState === BUY_PLAN_STATES.PAYMENT_FAIL && <PaymentFail />}
            </S.Wrapper>
        </Modal>
    );
};

export default PlanBuyModal;
