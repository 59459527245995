import { toDecimalPlace } from "./global-fn";

export const getPayment = (paymentV1, payment) => {
    if (!paymentV1 && !payment) return;
    if (paymentV1) return paymentV1;
    return {
        is_paid: payment.is_paid,
        amount_due: payment.amount_due,
        total_amount: payment.total_amount,
        remarks: payment.remarks,
        due_date: payment.due_date,
        payment_modes: [
            {
                created_at: Date.now(),
                payment_mode: payment.payment_mode,
                payment_amount: payment.total_amount,
                account: payment.account,
            },
        ],
    };
};

export const getPurchaseNetPrice = (purchase) => {
    return purchase?.net_price_round_off ? purchase?.net_price_round_off : toDecimalPlace(purchase?.net_price);
};

export const getSaleNetAmount = (sale) => {
    return sale?.net_amount_round_off
        ? sale?.net_amount_round_off
        : sale?.net_amount
        ? toDecimalPlace(sale?.net_amount)
        : toDecimalPlace(sale?.net_amount);
};
