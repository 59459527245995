import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPermissions } from "../api/local/permissionRoleApi";
import { captureException } from "../crash-reporting";

const initialState = {
    loading: false,
    roles: [],
    error: "",
    inventorySelectedPermission: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllPermissions = createAsyncThunk(
    "permissionRole/fetchAllPermissions",
    (search) =>
        getAllPermissions(search)
            .then((res) => res.data)
            .catch((err) => captureException(err))
);

const permissionRoleSlice = createSlice({
    name: "permissionRole",
    initialState,
    reducers: {
        setSelectedPermission: (state, action) => {
            state.inventorySelectedPermission = action.payload;
        },
        removeSelectedPermission: (state) => {
            state.inventorySelectedPermission = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllPermissions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllPermissions.fulfilled, (state, action) => {
            state.loading = false;
            state.roles = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllPermissions.rejected, (state, action) => {
            state.loading = false;
            state.roles = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default permissionRoleSlice.reducer;
export const { setSelectedPermission, removeSelectedPermission } = permissionRoleSlice.actions;
