import styled from "@emotion/styled/macro";

export const Table = styled.div`
    border: 1px solid #e2e8f0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0 16px;
`;

export const TableHeader = styled.div`
    display: flex;
    background: #f1f5f9;
    // height: 48px;
    align-items: center;
    padding: 10px 23px 10px 16px;
`;

export const Header = styled.div`
    flex: ${(props) => props.flexValue};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    // margin-right: ${(props) => (props.lastItem ? "0" : "67px")};
    text-align: ${(props) => props.center && "center"};
`;

export const TableBody = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const TableRow = styled.div`
    display: flex;
    padding: 12px 16px 12px 16px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
`;

export const ImagePlaceholder = styled.div`
    min-width: 87px;
    min-height: 87px;
    background: #e2e8f0;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        min-height: 60px;
        min-width: 60px;
    }
`;

export const NameData = styled.div`
    flex: 1.5;
    // margin-right: 67px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const ContactData = styled.div`
    flex: 1.25;
    // margin-right: 67px;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    cursor: pointer;
`;
export const AmountDue = styled.div`
    flex: 0.7;
    // margin-right: 67px;
    // display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    text-align: center;
`;

export const BrandsData = styled.div`
    flex: 1.75;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    img {
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
`;

export const BtnDiv = styled.div`
    flex: 2;
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    position: relative;

    align-items: center;
    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 100;
    }
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    // height: 136px;
    top: 0;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    // gap: 20px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 1;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: #333333;
    padding: 12px;

    &: hover {
        background-color: #f1f5f9;
    }
`;
export const ViewBtn = styled.div`
    color: #2d68fe;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

export const ClearDues = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
    text-decoration: underline;
    cursor: pointer;
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
