import { createContext, useState } from "react";

export const OfflineSnackbarContext = createContext();

const OfflineSnackbarProvider = ({ children }) => {
    const [offlineSnackbarDetails, setOfflineSnackbarDetails] = useState({
        show: false,
        title: "",
        subTitle: "",
        cta: "",
    });

    const handleOfflineSnackbarDetails = (value) => setOfflineSnackbarDetails(value);

    return (
        <OfflineSnackbarContext.Provider
            value={{
                offlineSnackbarDetails,
                handleOfflineSnackbarDetails,
            }}
        >
            {children}
        </OfflineSnackbarContext.Provider>
    );
};

export default OfflineSnackbarProvider;
