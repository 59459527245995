import React, { useContext, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { employeeLogin } from "../../api/local/auth-api";
import { EmployeeLoginScreenImage, YellowArrowleft } from "../../icons";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { fetchQuickLogins } from "../../store/quicklogin-slice";
import * as S from "./EmployeeLogin.style";
import { setEmployeeSession } from "../../store/session-slice";
import { useRef } from "react";
import Lottie from "lottie-react";
import EmpLoginAnimation from "../../animations/employee_login_screen.json";
import StoreLogo from "../../components/store-logo/StoreLogo";
import { captureException } from "../../crash-reporting";
import { PASSWORD_CHANGE_STATE } from "../../constants/appConstants";
import Login from "./login/Login";
import SendOtp from "./send-otp/SendOtp";
import VerifyOtp from "./verify-otp/VerifyOtp";
import NewPassword from "./new-password/NewPassword";
import { sendOTP, verifyOTP } from "../../api/server/otpApi";
import { errorNotify, successNotify } from "../../utils/toast";
import { employeeChangePassword } from "../../api/local/changePasswordApi";
import { hideLoader, showLoader } from "../../store/app-slice";

const data = {
    head: "Manage your sales and purchase on one platform",
    subHead: "Our sales module gives you real-time access to all the information you require and puts your sales information to work.",
    image: EmployeeLoginScreenImage,
};
const initialLoginData = {
    phone: "",
    password: "",
    otp: "",
    new_password: "",
    confirm_new_password: "",
};

const EmployeeLogin = () => {
    const [loginData, _setLoginData] = useState(initialLoginData);
    const loginDataRef = useRef(loginData);
    const setLoginData = (data) => {
        loginDataRef.current = data;
        _setLoginData(data);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const store = useSelector((state) => state.session.store);
    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [user, setUser] = useState(null);

    const [loginState, _setLoginState] = useState(PASSWORD_CHANGE_STATE.LOGIN);
    const loginStateRef = useRef(loginState);
    const setLoginState = (data) => {
        loginStateRef.current = data;
        _setLoginState(data);
    };

    useEffect(() => {
        dispatch(fetchQuickLogins());
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 13) {
            //Enter key
            onEnterSubmit();
            e.preventDefault();
        } else if (e.keyCode === 27) {
            //ESC key
            handleBackAction();
            e.preventDefault();
        }
    };

    const onEnterSubmit = () => {
        if (loginStateRef.current === PASSWORD_CHANGE_STATE.LOGIN) {
            handleLoginUser();
        } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.SEND_OTP) {
            handleSendOtp();
        } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.VERIFY_OTP) {
            handleVerifyOtp();
        } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.NEW_PASSWORD) {
            handleChangeEmployeePassword();
        }
    };

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    const setPhone = useCallback((value) => setLoginData({ ...loginData, phone: value }), []);

    async function handleLoginUser(e) {
        if (loginDataRef.current?.phone && loginDataRef.current?.password) {
            dispatch(showLoader());
            try {
                const res = await employeeLogin({ username: loginDataRef.current?.phone, password: loginDataRef.current?.password });
                if (res.status === 200) {
                    await dispatch(setEmployeeSession(res.data));
                    if (res.data.reset_password_on_login) {
                        setUser(res.data);
                        setOpenPasswordDialog(true);
                    }
                    window.electronAPI.onDashboardScreen();
                    navigate("/dashboard/new-sale");
                } else {
                    handleSnackbarDetails({
                        show: true,
                        title: res.message,
                        type: "error",
                        subtitle: "Please retry",
                    });
                    captureException(res.message);
                }
            } catch (error) {
                handleSnackbarDetails({
                    show: true,
                    title: "Phone number or password is incorrect",
                    type: "error",
                    subtitle: "Please retry",
                });
            } finally {
                dispatch(hideLoader());
            }
        } else {
            handleSnackbarDetails({
                show: true,
                title: "Please enter phone number or password correctly",
                type: "error",
            });
        }
    }

    const handleSendOtp = async () => {
        if (loginDataRef.current?.phone?.length !== 10) {
            errorNotify("Enter valid phone number");
            return;
        }
        dispatch(showLoader());
        try {
            let response = await sendOTP({
                context: "HOMEDROP_POS_CHANGE_EMPLOYEE_PASSWORD",
                country_code: "+63",
                phone_number: loginDataRef.current?.phone,
            });
            if (response.status === 200 && response.data === true) {
                successNotify(`An OTP is sent to ${loginDataRef.current?.phone}`);
                toVerifyOtpState();
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data?.message);
            }
        } catch (error) {
            captureException(error);
            errorNotify("Some error occurred");
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleVerifyOtp = async () => {
        if (loginDataRef.current?.otp?.length !== 6) {
            errorNotify("Enter valid OTP");
            return;
        }
        dispatch(showLoader());
        try {
            let response = await verifyOTP({
                context: "HOMEDROP_POS_CHANGE_EMPLOYEE_PASSWORD",
                country_code: "+63",
                phone_number: loginDataRef.current?.phone,
                otp: loginDataRef.current?.otp,
            });
            if (response.status === 200 && response.data === true) {
                successNotify("Congratulations. OTP verified");
                toNewPasswordState();
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data);
            }
        } catch (e) {
            captureException(e);
            errorNotify("Some error occurred. Please try again!");
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleChangeEmployeePassword = async () => {
        if (loginDataRef.current?.new_password !== loginDataRef.current?.confirm_new_password) {
            errorNotify("Passwords does not match");
            return;
        }
        dispatch(showLoader());
        try {
            let response = await employeeChangePassword({
                context: "HOMEDROP_POS_CHANGE_EMPLOYEE_PASSWORD",
                phone: loginDataRef.current?.phone,
                new_password: loginDataRef.current?.new_password,
            });
            if (response.status === 200) {
                successNotify("Congratulations. Password changed successfully");
                toLoginState();
                console.log(response.data);
            } else {
                errorNotify(response.data?.message);
                throw new Error(response.data?.message);
            }
        } catch (err) {
            captureException(err);
            errorNotify("Some error occurred. Please try again!");
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleBackAction = () => {
        if (loginStateRef.current === PASSWORD_CHANGE_STATE.SEND_OTP) {
            setLoginState(PASSWORD_CHANGE_STATE.LOGIN);
        } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.VERIFY_OTP) {
            setLoginState(PASSWORD_CHANGE_STATE.SEND_OTP);
        } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.NEW_PASSWORD) {
            setLoginState(PASSWORD_CHANGE_STATE.VERIFY_OTP);
        }
    };

    const toSendOtpState = () => {
        setLoginState(PASSWORD_CHANGE_STATE.SEND_OTP);
    };

    const toVerifyOtpState = () => {
        setLoginState(PASSWORD_CHANGE_STATE.VERIFY_OTP);
    };

    const toNewPasswordState = () => {
        setLoginState(PASSWORD_CHANGE_STATE.NEW_PASSWORD);
    };

    const toLoginState = () => {
        setLoginState(PASSWORD_CHANGE_STATE.LOGIN);
    };

    return (
        <S.Wrapper>
            <S.LeftImageContainer>
                <S.Head>{data.head}</S.Head>
                {/* <img src={data.image} alt="" /> */}
                <S.AnimationDiv>
                    <Lottie animationData={EmpLoginAnimation} />
                </S.AnimationDiv>
                <S.SubHead>{data.subHead}</S.SubHead>
            </S.LeftImageContainer>
            <S.RightLoginContainer>
                {loginState !== PASSWORD_CHANGE_STATE.LOGIN && (
                    <S.BackBtnContainer>
                        <S.BackBtn onClick={handleBackAction}>
                            <img src={YellowArrowleft} alt="back" />
                        </S.BackBtn>
                    </S.BackBtnContainer>
                )}
                <S.StoreHead>
                    {/* <img src={StoreLogo} alt="store logo" /> */}
                    <StoreLogo store={store} size={"48px"} color={"#0f172a"} />
                    <div>{store?.store_name}</div>
                </S.StoreHead>
                {loginState === PASSWORD_CHANGE_STATE.LOGIN && (
                    <Login
                        loginData={loginData}
                        toSendOtpState={toSendOtpState}
                        handleInputChange={handleInputChange}
                        onSubmit={handleLoginUser}
                        setPhone={setPhone}
                        openPasswordDialog={openPasswordDialog}
                        setOpenPasswordDialog={setOpenPasswordDialog}
                        user={user}
                    />
                )}
                {loginState === PASSWORD_CHANGE_STATE.SEND_OTP && (
                    <SendOtp loginData={loginData} handleInputChange={handleInputChange} onSubmit={handleSendOtp} setPhone={setPhone} />
                )}
                {loginState === PASSWORD_CHANGE_STATE.VERIFY_OTP && (
                    <VerifyOtp loginData={loginData} handleInputChange={handleInputChange} onSubmit={handleVerifyOtp} />
                )}
                {loginState === PASSWORD_CHANGE_STATE.NEW_PASSWORD && (
                    <NewPassword loginData={loginData} handleInputChange={handleInputChange} onSubmit={handleChangeEmployeePassword} />
                )}
            </S.RightLoginContainer>
        </S.Wrapper>
    );
};

export default EmployeeLogin;
