import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 600px;
    min-height: 454px;
    box-sizing: border-box;
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 15.9486px;
    line-height: 22px;
    color: #334155;
    border-bottom: 1px solid #e2e8f0;
    padding: 15px 19px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;
export const Body = styled.div`
    padding: 16px 16px 27px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const CustomerDetailsContainer = styled.div`
    height: 94px;
    width: 100%;
    background: #f1f5f9;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 24px;
    padding: 14px;
    border-radius: 4px;

    span {
        color: #ff4343;
    }
`;
export const InputContainers = styled.div`
    flex: ${(props) => (props.flexValue ? props.flexValue : "1")};
    box-sizing: border-box;
    // padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
`;
export const CustomerContainer = styled.div`
    flex: 1;
`;
export const InputContainer = styled.div`
    display: flex;
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;

    & input {
        width: 100%;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
        :focus {
            outline: none;
        }
    }
`;
export const Bar = styled.div`
    width: 1px;
    height: 100%;
    background-color: #e2e8f0;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
`;
export const AmountChip = styled.div`
    min-width: 78px;
    height: 36px;
    background: ${(props) => (props.active ? " #DBEAFE" : "#ffffff")};
    border: 1px solid ${(props) => (props.active ? "#93C5FD" : "#e2e8f0")};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    cursor: pointer;
    padding: 0 8px;

    input {
        margin: 2px 4px 0 0;
        width: 13px;
        height: 13px;

        :focus {
            outline: none;
        }
    }

    label {
        cursor: pointer;
    }
`;
export const AmountDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;
export const AmountContainer = styled.div`
    height: 142px;
    width: 100%;
    background: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
export const OtherAmountDiv = styled.div`
    width: 202px;
    background: #fff;
    box-sizing: border-box;
    padding: 6px;

    & input {
        width: 100%;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;
export const PaymentModeContainer = styled.div`
    height: 102px;
    width: 100%;
    background: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
export const PaymentModeDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const PaymentMode = styled.div`
    border: 1px solid #253238;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => (props.active ? "#2D68FE" : "#253238")};
    border-color: ${(props) => (props.active ? "#2D68FE" : "#253238")};
    :focus {
        outline: none;
    }
    & img {
        margin-top: 2px;
        // width: 20px;
        // height: 20px;
    }
`;

export const LoyaltyPointContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
    background: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px;
`;
export const PointContainer = styled.div`
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid ${(props) => (props.active ? "#2D68FE" : "#E2E8F0")};
    color: ${(props) => (props.active ? "#2D68FE" : "#334155")};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    ${(props) => props.cursor && "cursor:pointer"};

    & > input {
        cursor: pointer;
    }
    & > label {
        cursor: pointer;
        margin-top: -2px;
    }
`;

export const LoyaltyInput = styled.input`
    background-color: ${(props) => (props.disabled ? "#f9f9f9" : "#fff")};
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    color: #334155;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 6px;
    outline: none;
    height: 34px;
    width: 64px;
`;

export const LoyaltyHint = styled.div`
    color: #7f8f97;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    font-style: ${(props) => props.$italic && "italic"};
    margin-top: -2px;
    ${(props) => props.$bold && "color: #334155"};
`;

export const LoyaltyHintContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: -12px;
`;

export const DueBox = styled.div`
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    padding: 10px 12px;
`;
