import { Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateEmployee } from "../../api/local/employee-api";
import { PasswordInvisibleIcon } from "../../icons";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import StoreLogo from "../store-logo/StoreLogo";
import * as S from "./style";
import { captureException } from "../../crash-reporting";

const initialData = {
    newpassword: "",
    confirmpassword: "",
};
const ResetPasswordDialog = ({ open, onClose, user }) => {
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [loginData, setLoginData] = useState(initialData);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const store = useSelector((state) => state.session.store);

    const navigate = useNavigate();

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }

    async function employeePasswordResetLogin(e) {
        e.preventDefault();
        e.stopPropagation();
        if (loginData.newpassword.length < 6) {
            handleSnackbarDetails({
                show: true,
                title: "Password should be minimum 6 characters",
                type: "error",
            });
            return;
        }
        if (loginData.newpassword === loginData.confirmpassword) {
            try {
                let res = await updateEmployee(user.username, { password: loginData.newpassword });
                if (res.status === 200) {
                    onClose();
                    navigate("/login");
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Password changed successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (e) {
                handleSnackbarDetails({
                    show: true,
                    title: e.message,
                    type: "error",
                });
                captureException(e);
            }
        } else {
            handleSnackbarDetails({
                show: true,
                title: "Passwords do not match",
                type: "error",
            });
        }
    }
    return (
        <Modal
            open={open}
            // onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Wrapper>
                <S.StoreHead>
                    {/* <img src={StoreLogo} alt="" /> */}
                    <StoreLogo store={store} size={"48px"} color={"#0f172a"} />
                    <S.StoreHeading>{store?.store_name}</S.StoreHeading>
                </S.StoreHead>
                <S.UserDetail>
                    <S.UserName>Hi, {user?.name.split(" ")[0]}</S.UserName>
                    <S.PasswordText>Please change your password</S.PasswordText>
                </S.UserDetail>
                <form onSubmit={employeePasswordResetLogin}>
                    <div>
                        <S.Label>New Password</S.Label>
                        <S.InputFIeld>
                            <input type={showPasswordNew ? "text" : "password"} onChange={(e) => handleInputChange(e)} name="newpassword" autoFocus />
                            <img src={PasswordInvisibleIcon} alt="" onClick={() => setShowPasswordNew(!showPasswordNew)} />
                        </S.InputFIeld>
                    </div>
                    <div>
                        <S.Label>Confirm Password</S.Label>
                        <S.InputFIeld>
                            <input type={showPasswordConfirm ? "text" : "password"} onChange={(e) => handleInputChange(e)} name="confirmpassword" />
                            <img src={PasswordInvisibleIcon} alt="" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} />
                        </S.InputFIeld>
                    </div>
                    <S.Button type="submit">Log in</S.Button>
                </form>
            </S.Wrapper>
        </Modal>
    );
};

export default ResetPasswordDialog;
