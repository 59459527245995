import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 457px;
    // height: 93%;
    height: calc(100vh - 56px);
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Top = styled.div``;
export const Footer = styled.div`
    background: #e2e8f0;
    display: flex;
    align-items: center;
    gap: 14px;
    box-sizing: border-box;
    padding: 13px 0px 15px 25px;

    & img {
        height: 18px;
        cursor: pointer;
    }
`;

export const Header = styled.div`
    box-sizing: border-box;
    padding: 17px 23px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const Network = styled.div`
    display: flex;
    gap: 12px;

    & img {
        width: 17px;
        margin-top: 2px;
    }
`;

export const Headings = styled.div`
    color: #1e293b;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
`;
export const Heading = styled.div`
    color: #334155;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
`;
export const SubHead = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #94a3b8;
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
    & > img {
        margin-top: 2px;
    }
`;
export const ConnHead = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => (props.color ? props.color : "#94a3b8")};
`;
export const RowWrapper = styled.div`
    margin: 0 23px;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    align-items: center;
    padding: 17px 0 17px 14px;

    & > img {
        cursor: pointer;
        width: 24px;
    }
`;

export const ConnectionStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    & > img {
        width: 20px;
        height: 20px;
    }
`;

export const Content = styled.div`
    display: flex;
    gap: 25px;
`;

export const SubContent = styled.div`
    //   border: 1px solid red;
`;

export const LogoImg = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
        width: 100%;
        height: 100%;
    }
`;
export const IpAddress = styled.div`
    color: #64748b;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
`;
export const FooterHeading = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #334155;
`;
