import { Modal } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Barcode from "react-barcode";
import { CloseModalIcon, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../icons";
import CustomInput from "../product-drawer/custom-input/custom-input";
import InputLabel from "../product-drawer/input-label/input-label";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import * as S from "./barcode-dialog.styles";
import { calendarFormatDate } from "../../utils/format-date";
import { captureException } from "../../crash-reporting";

const initialData = {
    copies: 1,
    mfg_date: null,
    exp_date: null,
};
const BarcodeDialog = ({ open, onClose, data }) => {
    const [imgError, setImgError] = useState(false);
    const [quantity, setQuantity] = useState("");
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [printData, setPrintData] = useState(initialData);

    useEffect(() => {}, [data]);

    const handleInputChange = (val) => {
        const regex = /^[0-9\b]+$/;
        if (!(val === "" || regex.test(val))) return;
        setPrintData({ ...printData, copies: val });
    };

    const printBarcode = async () => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            let payload = {
                product: data,
            };
            if (printData.copies) payload.copies = parseInt(printData.copies);
            if (printData.mfg_date) payload.manufactured_date = printData.mfg_date.getTime();
            if (printData.exp_date) payload.expiry_date = printData.exp_date.getTime();
            try {
                let res = await window.electronAPI.printBarcode(payload);
                if (res?.code === "success") {
                    handleSnackbarDetails({
                        show: true,
                        title: "Barcode stickers are printing.",
                        type: "success",
                    });
                    setPrintData(initialData);
                    onClose();
                } else {
                    handleSnackbarDetails({
                        show: true,
                        title: res?.message,
                        type: "error",
                    });
                }
            } catch (error) {
                captureException(error);
            }
        }
    };

    const handleDateChange = (date, key) => {
        setPrintData({ ...printData, [key]: date });
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    <S.HeaderText>Print Barcode</S.HeaderText>
                    <img onClick={onClose} src={CloseModalIcon} alt="close" />
                </S.Header>

                <S.Body>
                    <S.Top>
                        <S.Left>
                            {imgError || !data?.gallery?.[0] ? (
                                <S.ImagePlaceholder>
                                    <img
                                        src={ProductPlaceholderIcon}
                                        alt="product"
                                        onError={(e) => {
                                            e.target.src = WifiDisconnectedIcon;
                                        }}
                                    />
                                </S.ImagePlaceholder>
                            ) : (
                                <S.ProductImage src={data?.gallery?.[0]} onError={() => setImgError(true)} alt="product" />
                            )}
                            <S.ProductDetails>
                                <S.Brand>{data?.brand?.name}</S.Brand>
                                <S.Name>{data?.name}</S.Name>
                            </S.ProductDetails>
                        </S.Left>
                        <S.Right>
                            <Barcode value={data?.upc} height={35} />
                        </S.Right>
                    </S.Top>
                    <S.InputContainer>
                        {/* <InputLabel text={quantity === -1 ? "Unlimited Quantity" : "Quantity"} />
                        {quantity !== -1 && <CustomInput value={quantity} onChange={(val) => setQuantity(val)} type="number" />} */}
                        <div>
                            <InputLabel text="Copies" />
                            <CustomInput value={printData.copies} onChange={(val) => handleInputChange(val)} />
                        </div>
                        <div>
                            <InputLabel text="Manufacture Date" />
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(printData.mfg_date))}
                                onChange={(date) => handleDateChange(date, "mfg_date")}
                                dateFormat="dd/MM/yyyy"
                                className="product-date"
                            />
                        </div>
                        <div>
                            <InputLabel text="Expiry Date" />
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(printData.exp_date))}
                                onChange={(date) => handleDateChange(date, "exp_date")}
                                dateFormat="dd/MM/yyyy"
                                className="product-date"
                            />
                        </div>
                    </S.InputContainer>

                    <S.BtnContainer>
                        <S.Btn onClick={() => printBarcode()}>Print</S.Btn>
                    </S.BtnContainer>
                </S.Body>
            </S.Container>
        </Modal>
    );
};

export default BarcodeDialog;
