import React, { useContext, useEffect } from "react";
import moment from "moment";

import * as S from "./purchase-table.styles";
import { PURCHASE_STATUS, PURCHASE_STATUS_MAPPING, PURCHASE_TYPE, PURCHASE_TYPE_MAPPING, STATUS } from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPurchases, setPurchaseCountData, setSelectedPurchase } from "../../../store/purchase-slice";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { CheckRight, ThreeDotsVerticalIcon } from "../../../icons";
import { useState } from "react";
import { updatePurchase } from "../../../api/local/purchase-api";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import DeleteModal from "./../delete-modal/DeleteModal";
import { getPurchasesCount } from "./../../../api/local/purchase-api";
import { useImperativeHandle } from "react";
import { captureException } from "../../../crash-reporting";
import {
    handleSelectPurchaseDamageDraft,
    handleSelectPurchaseDraft,
    handleSelectPurchaseProformaDraft,
    handleSelectPurchaseReturnDraft,
    toDecimalPlace,
} from "../../../utils/global-fn";

import { getPayment, getPurchaseNetPrice } from "../../../utils/object-parser";

const Purchase = ({ purchase = {}, header, idx, activeRow, scrollRef, setOpenDeleteModal }, ref) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const { searchParams, purchases } = useSelector((state) => state.purchase);
    const [payment, setpayment] = useState(null);
    const [netPrice, setNetPrice] = useState(0);

    useEffect(() => {
        if (purchase) {
            let p = getPayment(purchase?.payment, purchase?.payment);
            setpayment(p);

            let price = getPurchaseNetPrice(purchase);
            setNetPrice(price);
        }
    }, [purchase]);

    useImperativeHandle(ref, () => {
        return {
            refPurchaseRowClick: () => handleRowClick(purchases[activeRow]),
        };
    });
    async function handleRowClick(purchase) {
        if (purchase.status === "DRAFT") {
            if (purchase.type === PURCHASE_TYPE.PURCHASE) {
                handleSelectPurchaseDraft(purchase, dispatch);
                navigate("/dashboard/new-purchase");
            } else if (purchase.type === PURCHASE_TYPE.RETURN) {
                handleSelectPurchaseReturnDraft(purchase, dispatch);
                navigate("/dashboard/new-purchase-return");
            } else if (purchase.type === PURCHASE_TYPE.DAMAGE_ENTRY) {
                handleSelectPurchaseDamageDraft(purchase, dispatch);
                navigate("/dashboard/new-damage-entry");
            } else if ([PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(purchase.type)) {
                handleSelectPurchaseProformaDraft(purchase, dispatch);
                navigate("/dashboard/purchase-proforma");
            }
        } else {
            dispatch(setSelectedPurchase(purchase));
            navigate("/dashboard/view-purchase");
        }
    }

    const handleGetPurchaseCount = async () => {
        try {
            let res = await getPurchasesCount();
            if (res.status === 200) {
                dispatch(setPurchaseCountData(res.data));
            } else {
                throw new Error(res.dat);
            }
        } catch (err) {
            captureException(err);
        }
    };

    // function handleDeletePurchase() {
    //     updatePurchase(purchase.id, { status: PURCHASE_STATUS.TRASH })
    //         .then((res) => {
    //             handleGetPurchaseCount();
    //             dispatch(fetchAllPurchases(searchParams));
    //             handleSnackbarDetails({
    //                 show: true,
    //                 type: "success",
    //                 title: "Purchase deleted successfully",
    //             });
    //         })
    //         .catch((err) =>
    //             handleSnackbarDetails({
    //                 show: true,
    //                 title: "Something went wrong...",
    //                 type: "error",
    //             })
    //         );
    // }

    function handlePublishPurchase() {
        updatePurchase(purchase.id, { status: PURCHASE_STATUS.PUBLISHED })
            .then((res) => {
                if (res.status === 200) {
                    handleGetPurchaseCount();
                    dispatch(fetchAllPurchases(searchParams));
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Purchase published successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            })
            .catch((err) => {
                handleSnackbarDetails({
                    show: true,
                    title: "Something went wrong...",
                    type: "error",
                });
                captureException(err);
            });
    }

    return (
        <S.TableRow
            onClick={(e) => {
                e.stopPropagation();
                handleRowClick(purchase);
            }}
            key={idx}
            active={idx === activeRow}
            ref={idx === activeRow ? scrollRef : null}
        >
            {/* <DeleteModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                handleDelete={() => {
                    handleDeletePurchase();
                }}
            /> */}
            {purchase && (
                <>
                    {" "}
                    <S.TableData flexValue="0.95">
                        {/* {moment(purchase.created_at).format("DD MMM YYYY hh:mm a")} */}
                        {moment(purchase.created_at).format("lll")}
                    </S.TableData>
                    <S.TableData flexValue="0.5" center>
                        {purchase.id}
                    </S.TableData>
                    {header === PURCHASE_TYPE.RETURN && (
                        <S.TableData flexValue="1" center>
                            {purchase.return_id}
                        </S.TableData>
                    )}
                    {header === PURCHASE_TYPE.PURCHASE && (
                        <S.TableData flexValue="1" center>
                            {PURCHASE_TYPE_MAPPING[purchase.type]}
                        </S.TableData>
                    )}
                    <S.TableData flexValue="0.15" center></S.TableData>
                    {header !== PURCHASE_TYPE.DAMAGE_ENTRY && (
                        <S.TableData flexValue="1">
                            {purchase.supplier?.name} {purchase.supplier?.phone && `(${purchase.supplier?.phone})`}
                        </S.TableData>
                    )}
                    <S.TableData flexValue="0.45" center>
                        {purchase.products?.length}
                    </S.TableData>
                    <S.TableData flexValue="0.15" center></S.TableData>
                    <S.TableData flexValue="0.6" center>
                        ₱ {netPrice}
                    </S.TableData>
                    <S.TableData flexValue="0.15" center></S.TableData>
                    {header !== PURCHASE_TYPE.DAMAGE_ENTRY && (
                        // <S.TableData
                        //     completed={purchase.status === PURCHASE_STATUS.PUBLISHED}
                        //     flexValue="1"
                        // >
                        //     {PURCHASE_STATUS_MAPPING[purchase.status]}
                        // </S.TableData>
                        <S.TableData completed={payment?.is_paid} flexValue="1">
                            {purchase.status === "DRAFT"
                                ? "Draft"
                                : payment?.is_paid
                                ? "Completed"
                                : [PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(purchase.type)
                                ? `Pending(₱${netPrice})`
                                : `Pending(₱${toDecimalPlace(payment?.amount_due)})`}
                        </S.TableData>
                    )}
                    {header === PURCHASE_TYPE.DAMAGE_ENTRY && (
                        <S.TableData completed={purchase.status === STATUS.PUBLISHED} flexValue="1">
                            {purchase.status === STATUS.PUBLISHED ? "Completed" : "Draft"}
                        </S.TableData>
                    )}
                    <S.TableData flexValue="2">
                        {[...new Set(purchase.products?.filter((item) => item.brand).map((item) => item.brand?.name))].join(", ")}
                    </S.TableData>
                    {purchase.status === PURCHASE_STATUS.IN_REVIEW ? (
                        <S.TableData flexValue="0.15">
                            <img
                                src={CheckRight}
                                alt="tick mark"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePublishPurchase();
                                }}
                            />
                        </S.TableData>
                    ) : (
                        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                            <S.TableData flexValue="0.15">
                                <S.ThreeDot
                                    src={ThreeDotsVerticalIcon}
                                    alt=""
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(true);
                                    }}
                                />
                            </S.TableData>
                            {open && (
                                <OptionsContainer
                                    handleRowClick={handleRowClick}
                                    // handleDeletePurchase={handleDeletePurchase}
                                    onClose={() => setOpen(false)}
                                    setOpenDeleteModal={setOpenDeleteModal}
                                    item={purchase}
                                />
                            )}
                        </OutsideClickHandler>
                    )}
                </>
            )}
        </S.TableRow>
    );
};

export default React.forwardRef(Purchase);

const OptionsContainer = ({ handleRowClick, onClose, setOpenDeleteModal, item }) => {
    return (
        <S.OptionsContainer>
            <S.Option
                onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick(item);
                }}
            >
                Edit
            </S.Option>
            <S.Option
                onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeleteModal({ show: true, data: item });
                    onClose();
                }}
            >
                Delete
            </S.Option>
        </S.OptionsContainer>
    );
};
