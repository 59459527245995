import {
    AddProducts,
    AddProductsActive,
    AddStaff,
    AddStaffActive,
    AnalyticsActiveLogo,
    AnalyticsLogo,
    CashDrawer,
    CashDrawerActive,
    CashManagementActiveIcon,
    CashManagementIcon,
    Customers,
    CustomersActive,
    DamageEntryLogo,
    Discounts,
    DiscountsActive,
    DownloadReports,
    DownloadReportsActive,
    Expenses,
    ExpensesActive,
    HelpCenterActiveIcon,
    HelpCenterIcon,
    InventoryActiveLogo,
    InventoryLogo,
    LogoutIcon,
    ManageInventory,
    ManageInventoryActive,
    ManageStaff,
    ManageStaffActive,
    MarketingActiveLogo,
    MarketingLogo,
    NewCampaigns,
    NewCampaignsActive,
    NewDamageReturnActive,
    NewPurchase,
    NewPurchaseActive,
    NewPurchaseReturnActive,
    NewReturn,
    NewReturnDamage,
    NewSale,
    NewSaleActive,
    OnlineOrders,
    OnlineOrdersActive,
    OnlineSalesActiveIcon,
    OnlineSalesIcon,
    OverallReportActiveIcon,
    OverallReportIcon,
    PLReportActiveIcon,
    PLReportIcon,
    ProformaInvoiceActiveIcon,
    ProformaInvoiceIcon,
    PurchaseActiveIcon,
    PurchaseActiveLogo,
    PurchaseIcon,
    PurchaseLogo,
    SaleActiveIcon,
    SaleIcon,
    SalesActiveLogo,
    SalesLogo,
    SettingsActive,
    SettingsIcon,
    StaffActiveLogo,
    StaffLogo,
    Suppliers,
    SuppliersActive,
    ViewCampaigns,
    ViewCampaignsActive,
    ViewPurchases,
    ViewPurchasesActive,
    ViewSales,
    ViewSalesActive,
} from "../icons";
import { FEATURES } from "./POS-plans";

export const NAV_MENU_IDS = {
    SALES: 1,
    PURCHASE: 2,
    INVENTORY: 3,
    MARKETING: 4,
    ANALYTICS: 5,
    STAFF: 6,
    SETTINGS: 8,
    LOGOUT: 7,
    CASH_MANAGEMENT: 9,
    ONLINE_SALES: 10,
    HELP_CENTER: 11,
};

export const NAV_MENU_ITEMS = [
    {
        id: NAV_MENU_IDS.SALES,
        name: "Sales",
        logo: SaleIcon,
        activeLogo: SaleActiveIcon,
        key: FEATURES.SALES,
        route: "new-sale",
    },
    {
        id: NAV_MENU_IDS.ONLINE_SALES,
        name: "Online Sales",
        logo: OnlineSalesIcon,
        activeLogo: OnlineSalesActiveIcon,
        key: FEATURES.SALE_ONLINE,
        route: "online-orders",
    },
    {
        id: NAV_MENU_IDS.PURCHASE,
        name: "Purchase",
        logo: PurchaseIcon,
        activeLogo: PurchaseActiveIcon,
        key: FEATURES.PURCHASE,
        route: "purchases",
    },
    {
        id: NAV_MENU_IDS.INVENTORY,
        name: "Inventory",
        logo: InventoryLogo,
        activeLogo: InventoryActiveLogo,
        key: FEATURES.INVENTORY,
        route: "inventory",
    },
    {
        id: NAV_MENU_IDS.CASH_MANAGEMENT,
        name: "Cash Management",
        logo: CashManagementIcon,
        activeLogo: CashManagementActiveIcon,
        key: FEATURES.EXPENSES,
        route: "expenses",
    },
    // {
    //     id: NAV_MENU_IDS.MARKETING,
    //     name: "Marketing",
    //     logo: MarketingLogo,
    //     activeLogo: MarketingActiveLogo,
    // },
    {
        id: NAV_MENU_IDS.ANALYTICS,
        name: "Analytics",
        logo: AnalyticsLogo,
        activeLogo: AnalyticsActiveLogo,
        key: FEATURES.ANALYTICS,
        route: "analytics",
    },
    {
        id: NAV_MENU_IDS.STAFF,
        name: "Staff",
        logo: StaffLogo,
        activeLogo: StaffActiveLogo,
        key: FEATURES.STAFF,
        route: "employees",
    },
    {
        id: NAV_MENU_IDS.SETTINGS,
        name: "Settings",
        logo: SettingsIcon,
        activeLogo: SettingsActive,
        key: FEATURES.SETTINGS,
        route: "settings",
    },
    {
        id: NAV_MENU_IDS.HELP_CENTER,
        name: "Help Center",
        logo: HelpCenterIcon,
        activeLogo: HelpCenterActiveIcon,
        key: FEATURES.HELP_CENTER,
        route: "help-center",
    },
    {
        id: NAV_MENU_IDS.LOGOUT,
        name: "Logout",
        logo: LogoutIcon,
        activeLogo: LogoutIcon,
        key: FEATURES.LOGOUT,
        route: "logout",
    },
];

export const SALES_SUBMENU = [
    {
        id: 1,
        name: "New Sale",
        navigate: "new-sale",
        logo: NewSale,
        activeLogo: NewSaleActive,
        key: FEATURES.SALES,
    },
    {
        id: 2,
        name: "Proforma Invoice/" + "\n" + "Delivery Challan",
        navigate: "proforma-invoice",
        logo: ProformaInvoiceIcon,
        activeLogo: ProformaInvoiceActiveIcon,
        key: FEATURES.SALES,
    },
    {
        id: 4,
        name: "View Sales",
        navigate: "all-sales",
        logo: ViewSales,
        activeLogo: ViewSalesActive,
        key: FEATURES.SALES,
    },
    {
        id: 5,
        name: "Customers",
        navigate: "customers",
        logo: Customers,
        activeLogo: CustomersActive,
        key: FEATURES.CUSTOMERS,
    },
    // {
    //     id: 6,
    //     name: "Discounts",
    //     // navigate: "discounts",
    //     logo: Discounts,
    //     activeLogo: DiscountsActive,
    // },
];
export const PURCHASE_SUBMENU = [
    {
        id: 1,
        name: "View Purchases",
        navigate: "purchases",
        logo: ViewPurchases,
        activeLogo: ViewPurchasesActive,
        key: FEATURES.PURCHASE,
    },
    {
        id: 2,
        name: "New Purchase",
        navigate: "new-purchase",
        logo: NewPurchase,
        activeLogo: NewPurchaseActive,
        key: FEATURES.PURCHASE,
    },
    {
        id: 3,
        name: "Purchase Proforma/" + "\n" + "Order",
        navigate: "purchase-proforma",
        logo: NewPurchase,
        activeLogo: NewPurchaseActive,
        key: FEATURES.PURCHASE,
    },
    {
        id: 4,
        name: "New Purchase Return",
        navigate: "new-purchase-return",
        logo: NewReturnDamage,
        activeLogo: NewPurchaseReturnActive,
        key: FEATURES.PURCHASE,
    },
    {
        id: 5,
        name: "New Damage Entry",
        navigate: "new-damage-entry",
        logo: DamageEntryLogo,
        activeLogo: NewDamageReturnActive,
        key: FEATURES.DAMAGE,
    },

    {
        id: 6,
        name: "Suppliers",
        navigate: "suppliers",
        logo: Suppliers,
        activeLogo: SuppliersActive,
        key: FEATURES.SUPPLIERS,
    },
];
export const INVENTORY_SUBMENU = [
    {
        id: 2,
        name: "Manage Inventory",
        navigate: "inventory",
        logo: ManageInventory,
        activeLogo: ManageInventoryActive,
        key: FEATURES.INVENTORY,
    },
    {
        id: 1,
        name: "Add Product",
        navigate: "add-product",
        logo: AddProducts,
        activeLogo: AddProductsActive,
        key: FEATURES.INVENTORY,
    },
];
export const MARKETING_SUBMENU = [
    {
        id: 1,
        name: "New Campaign",
        navigate: "new-campaign",
        logo: NewCampaigns,
        // navigate: "new-campaign",
        activeLogo: NewCampaignsActive,
    },
    {
        id: 2,
        name: "View Campaigns",
        // navigate: "campaigns",
        logo: ViewCampaigns,
    },
];
export const STAFF_SUBMENU = [
    {
        id: 1,
        name: "Manage Staff",
        navigate: "employees",
        logo: ManageStaff,
        activeLogo: ManageStaffActive,
        key: FEATURES.SUPPLIERS,
    },
    {
        id: 2,
        name: "Add Staff",
        navigate: "add-employee",
        logo: AddStaff,
        activeLogo: AddStaffActive,
        key: FEATURES.SUPPLIERS,
    },
];

export const ONLINE_SALES_SUBMENU = [
    {
        id: 1,
        name: "Orders",
        navigate: "online-orders",
        logo: OnlineSalesIcon,
        activeLogo: OnlineSalesActiveIcon,
        key: FEATURES.SALE_ONLINE,
    },
    {
        id: 2,
        name: "Settings",
        navigate: "online-settings",
        logo: SettingsIcon,
        activeLogo: SettingsActive,
        key: FEATURES.SALE_ONLINE,
    },
];

export const ANALYTICS_SUBMENU = [
    {
        id: 1,
        name: "Overall Report",
        navigate: "analytics",
        logo: OverallReportIcon,
        activeLogo: OverallReportActiveIcon,
        key: FEATURES.ANALYTICS,
    },
    {
        id: 2,
        name: "P & L Report",
        navigate: "pl-report",
        logo: PLReportIcon,
        activeLogo: PLReportActiveIcon,
        key: FEATURES.ANALYTICS,
    },
    {
        id: 3,
        name: "Download Reports",
        navigate: "download-reports",
        logo: DownloadReports,
        activeLogo: DownloadReportsActive,
        key: FEATURES.DOWNLOAD_REPORTS,
    },
];

export const CASH_MANAGEMENT_SUBMENU = [
    {
        id: 1,
        name: "Expenses",
        navigate: "expenses",
        logo: Expenses,
        activeLogo: ExpensesActive,
        key: FEATURES.EXPENSES,
    },
    {
        id: 2,
        name: "Cash Drawer",
        navigate: "cash-drawer",
        logo: CashDrawer,
        activeLogo: CashDrawerActive,
        key: FEATURES.EXPENSES,
    },
];
