import { isEmpty, isEqual } from "lodash";
import hash from "object-hash";
import store from "../store/store";
import {
  DiscountOption,
  PAYMENT,
  PURCHASE_TYPE,
  SALE_BILL_TYPE,
  TAX_VALUES,
  UNITS_LIST,
} from "../constants/appConstants";
import { getProductsFromDB } from "./api-fns";
import {
  addProductsToSale,
  removeAllProducts,
  setAdditionalCharges,
  setBillno,
  setCreatedDate,
  setSaleID,
  setSelectDiscount,
  setSelectedSale,
} from "../store/sale-slice";
import { setSelectedCustomer } from "../store/customer-slice";
import {
  addProductsToPI,
  removeAllProductsPI,
  setAdditionalChargesPI,
  setBillNoPI,
  setCreatedDatePI,
  setCustomerPI,
  setPI_ID,
  setSelectDiscountPI,
  setSelectedPI,
} from "../store/proformainvoice-slice";
import moment from "moment";
import {
  addProductsToPurchase,
  removeAllPurchaseProducts,
  setSelectedDraftPurchase,
  setSelectedSupplier,
  setCreatedDate as setPurchaseCreatedDate,
  setDeliveryExpense,
  setBillNo,
  setDeliveryDate,
  setSelectDiscount as setPurchaseSelectDiscount,
} from "../store/purchase-slice";
import {
  addProductsToPurchaseReturn,
  removeAllPurchaseReturnProducts,
  setReturnSelectedSupplier,
  setSelectedDraftPurchaseReturn,
  setCreatedDate as setPurchaseReturnCreatedDate,
  setDeliveryDate as setPurchaseReturnDeliveryDate,
  setBillNo as setPurchaseReturnBillNo,
  setDeliveryExpense as setPurchaseReturnDeliveryExpense,
} from "../store/purchasereturn-slice";
import {
  addProductsToDamageEntry,
  removeAllDamageEntryProducts,
  setDamageBillNo,
  setDamageCreatedDate,
  setSelectedDraftDamage,
} from "../store/damageentry-slice";
import {
  addProductsToPP,
  removeAllProductsPP,
  setBillNoPP,
  setCreatedDatePP,
  setDeliveryDatePP,
  setDeliveryExpensePP,
  setSelectDiscountPP,
  setSelectedPP,
  setSelectedSupplierPP,
  setTypePP,
} from "../store/purchaseproforma-slice";
import { captureException } from "../crash-reporting";

export const getTax = (item) => {
  if (item?.tax) {
    let totaltax = Object.values(item?.tax).reduce((a, b) => a + b, 0);
    return totaltax;
  } else if (item?.tax) {
    let totaltax = Object.values(item?.tax).reduce((a, b) => a + b, 0);
    return totaltax;
  } else if (item.tax) {
    return item.tax;
  } else {
    return 0;
  }
};

export const getTaxValue = (value, tax, valueWithTax = true) => {
  if (valueWithTax === false) {
    return toDecimalPlace(value * (tax / 100));
  }
  return toDecimalPlace(value * (tax / (100 + tax)));
};

export const getUnit = (item) => {
  if (item.unit) {
    return item?.unit;
  } else if (item.enable_alternate_unit) {
    return item?.alternate_unit?.base_unit;
  } else {
    return null;
  }
};

export const getQuantity = (item, bill_type = SALE_BILL_TYPE.POS) => {
  const { quantity, enable_alternate_unit, alternate_unit, unit } = item;
  let qty = parseFloat(quantity);

  if (enable_alternate_unit === true && bill_type === SALE_BILL_TYPE.INVOICE) {
    if (unit) {
      if (unit === alternate_unit?.base_unit) {
        qty = qty;
      } else if (unit === alternate_unit?.secondary_unit) {
        qty = qty * (1 / parseFloat(alternate_unit?.conversion));
      }
    }
    return qty;
  } else {
    return qty;
  }
};

export const getSalePrice = (
  item,
  withoutDiscount = false,
  bill_type = SALE_BILL_TYPE.POS
) => {
  const {
    sale_price,
    quantity,
    discountValue,
    sale_price_with_tax,
    wholesale_price,
    wholesale_quantity,
  } = item;
  let tax = getTax(item);
  // let qty = getQuantity(item, bill_type);
  let qty = parseFloat(quantity);
  let salePrice = parseFloat(sale_price);
  let discount = parseFloat(discountValue);

  if (withoutDiscount === true) {
    discount = 0;
  }
  let itemTotal;

  let sale_sum = salePrice * qty - discount;
  if (sale_price_with_tax !== false) {
    itemTotal = sale_sum;
  } else {
    // let taxPrice = parseFloat((sale_sum * tax) / 100);
    let taxPrice = getTaxValue(sale_sum, tax, sale_price_with_tax);
    itemTotal = sale_sum + taxPrice;
  }

  return parseFloat(toDecimalPlace(itemTotal));
};

export const getSalePriceWithoutTax = (product) => {
  const { sale_price, sale_price_with_tax } = product;
  let tax = getTax(product);
  if (sale_price_with_tax === false) {
    return sale_price;
  } else {
    return sale_price / (1 + tax / 100);
  }
};
export const getPurchasePriceWithoutTax = (product) => {
  const { purchase_price, purchase_price_with_tax } = product;
  let tax = getTax(product);
  if (purchase_price_with_tax === false) {
    return purchase_price;
  } else {
    return purchase_price / (1 + tax / 100);
  }
};

export const changeString = (str, key) => {
  let newStr;
  if (key === "name") {
    newStr = str.split(" ");
    let pre = parseInt(newStr[1]) - 1;
    newStr.splice(1, 1, pre);
    newStr = newStr.join(" ");
  } else if (key === "sku") {
    newStr = str.split("#");
    let pre = parseInt(newStr[1]) - 1;
    newStr.splice(1, 1, pre);
    newStr = newStr.join("#");
  }
  return newStr;
};

export const hasDecimal = (number) => {
  let n = Number(number);
  let result = n - Math.floor(n) !== 0;

  if (result) return true;
  else return false;
};

export const toDecimalPlace = (value) => {
  if (isNaN(value) || value === null || value === undefined) {
    return;
  }
  let n = Number(value);
  return Number(n.toFixed(2));
};

export const toAbsoluteRoundOff = (value) => {
  if (isNaN(value) || value === null || value === undefined) {
    return;
  }
  let n = Number(value);
  return Math.round(Math.abs(n));
};

export const getPurchasePrice = (item, withoutDiscount = false) => {
  const { purchase_price, quantity, discountValue, purchase_price_with_tax } =
    item;
  let tax = getTax(item);
  let qty = parseFloat(quantity);
  let purchasePrice = parseFloat(purchase_price);
  let discount = parseFloat(discountValue ?? 0);
  if (withoutDiscount === true) {
    discount = 0;
  }
  let itemTotal;
  let purchase_sum = purchasePrice * qty - discount;
  if (purchase_price_with_tax !== false) {
    itemTotal = purchase_sum;
  } else {
    // let taxPrice = parseFloat((purchase_sum * tax) / 100);
    let taxPrice = getTaxValue(purchase_sum, tax, purchase_price_with_tax);
    itemTotal = purchase_sum + taxPrice;
  }
  return parseFloat(itemTotal.toFixed(2));
};

export const getPurchaseQuantity = (item) => {
  const { quantity, enable_alternate_unit, alternate_unit, unit } = item;
  let qty = parseFloat(quantity);

  if (enable_alternate_unit === true) {
    if (unit) {
      if (unit === alternate_unit?.base_unit) {
        qty = qty;
      } else if (unit === alternate_unit?.secondary_unit) {
        qty = qty * (1 / parseFloat(alternate_unit?.conversion));
      }
    }
    return qty;
  } else {
    return qty;
  }
};

export const convertTax = (product) => {
  if (product?.tax) {
    return product.tax;
  } else if (product.tax) {
    const taxIdx = TAX_VALUES.findIndex(
      (option) => calculateTax(option.value) === product.tax
    );
    if (taxIdx >= 0) {
      return TAX_VALUES[taxIdx].value;
    } else {
      return TAX_VALUES[0].value;
    }
  } else {
    return TAX_VALUES[0].value;
  }
};

const calculateTax = (tax) => {
  let totalTax = Object.values(tax ?? {}).reduce((a, b) => a + b, 0);
  return totalTax;
};

export const getAltUnit = (product) => {
  if (!product.enable_alternate_unit) return "";

  const base = UNITS_LIST.findIndex(
    (option) => option.value === product.alternate_unit?.base_unit
  );
  const secondary = UNITS_LIST.findIndex(
    (option) => option.value === product.alternate_unit?.secondary_unit
  );
  if (base >= 0 && secondary >= 0) {
    let altUnit = {
      base_unit: UNITS_LIST[base].name,
      secondary_unit: UNITS_LIST[secondary].name,
    };
    if (product.alternate_unit?.conversion < 1) {
      altUnit.base_conversion = toDecimalPlace(
        1 / product.alternate_unit?.conversion
      );
      altUnit.secondary_conversion = 1;
    } else {
      altUnit.secondary_conversion = product.alternate_unit?.conversion;
      altUnit.base_conversion = 1;
    }
    return `${altUnit.base_conversion} ${altUnit.base_unit} = ${altUnit.secondary_conversion} ${altUnit.secondary_unit}`;
  }
};

export const getTaxName = (item) => {
  if (item?.tax) {
    const taxIdx = TAX_VALUES.findIndex((option) =>
      isEqual(option.value, item?.tax.value)
    );
    if (taxIdx >= 0) {
      return TAX_VALUES[taxIdx]?.name;
    }
  } else if (item?.tax) {
    const taxIdx = TAX_VALUES.findIndex((option) =>
      isEqual(option.value, item?.tax.value)
    );
    if (taxIdx >= 0) {
      return TAX_VALUES[taxIdx]?.name;
    }
  } else if (item.tax) {
    const taxIdx = TAX_VALUES.findIndex(
      (option) => calculateTax(option.value) === item.tax.value
    );
    if (taxIdx >= 0) {
      return TAX_VALUES[taxIdx]?.name;
    }
  } else {
    return TAX_VALUES[0]?.name;
  }
};

export const getDecimalPlacesCount = (number) => {
  return number.toString().split(".")[1].length;
};

export const getDiscountRate = (item) => {
  if (!item.manual_discount) return;
  let salePriceWotTax = toDecimalPlace(getSalePriceWithoutTax(item));
  let sp = salePriceWotTax;
  let discountValue = item.manual_discount?.value;
  return toDecimalPlace((discountValue * 100) / (sp * item.quantity));
};

export const getDraftProductToSlice = (product) => {
  return {
    ...product,
    quantity: product.quantity ? product.quantity : 1,
    discount:
      product.manual_discount?.type === DiscountOption.PERCENTAGE
        ? getDiscountRate(product)
        : product.manual_discount?.value,
    activeDiscount: product.manual_discount?.type ?? DiscountOption.FLAT,
    discountValue: product.manual_discount?.value ?? 0,
    unit: getUnit(product),
    manufacturing_date: product.manufacturing_date
      ? new Date(product.manufacturing_date)
      : null,
    expiry_date: product.expiry_date ? new Date(product.expiry_date) : null,
    tax: product.tax_percentage,
  };
};

export const getSalePayloadProducts = (productList, type) => {
  let products = [];
  if (productList?.length === 0) return products;

  products = productList.map((item) => {
    let p = {
      id: item.id,
      sku: item.sku,
      upc: item.upc,
      item_type: item.is_custom_product ? "custom" : "item",
      name: item.name,
      quantity: parseFloat(item.quantity),
      sale_price: toDecimalPlace(item.sale_price),
      unit: item.unit,
      sale_price_with_tax: item.sale_price_with_tax,
      price: toDecimalPlace(item.price),
      tax: convertTax(item),
      hsn: item.hsn,
      batch_number: item.batch_number,
      manufacturing_date: item.manufacturing_date
        ? item.manufacturing_date.getTime()
        : null,
      expiry_date: item.expiry_date ? item.expiry_date.getTime() : null,
      enable_alternate_unit: item.enable_alternate_unit,
      alternate_unit: item.alternate_unit,
      is_custom_product: item.is_custom_product ? true : false,
    };
    let qty = getQuantity(item, SALE_BILL_TYPE.INVOICE);
    if (qty >= item.wholesale_quantity) {
      p.wholesale_price = item.wholesale_price;
      p.wholesale_quantity = item.wholesale_quantity;
    }
    let disc = {
      type: item.activeDiscount,
      value: Number(item.discount),
    };
    p.manual_discount = disc;
    return p;
  });

  return products;
};

export const getPurchaseDiscountRate = (item) => {
  if (!item.discount) return;
  let purchasePriceWotTax = toDecimalPlace(getPurchasePriceWithoutTax(item));
  let sp = purchasePriceWotTax;
  let discountValue = item.discount?.value;
  return toDecimalPlace((discountValue * 100) / (sp * item.quantity));
};

export const getPurchaseDraftProductToSlice = (product) => {
  return {
    ...product,
    sale_price: toDecimalPlace(product.sale_price),
    purchase_price: product.purchase_price
      ? toDecimalPlace(product.purchase_price)
      : 0,
    tax: product.tax_percentage,
    activeDiscount: product.discount?.type ?? DiscountOption.FLAT,
    discountValue: product.discount?.value ?? 0,
    discount:
      product.discount?.type === DiscountOption.PERCENTAGE
        ? getPurchaseDiscountRate(product)
        : product.discount?.value,
    unit: getUnit(product),
    manufacturing_date: product.manufacturing_date
      ? getDateObjectFromDateString(product.manufacturing_date)
      : null,
    expiry_date: product.expiry_date
      ? getDateObjectFromDateString(product.expiry_date)
      : null,
  };
};
export const getPurchaseReturnDraftProductToSlice = (product) => {
  return {
    ...product,
    purchase_price: product.purchase_price
      ? toDecimalPlace(product.purchase_price)
      : 0,
    unit: getUnit(product),
    manufacturing_date: product.manufacturing_date
      ? getDateObjectFromDateString(product.manufacturing_date)
      : null,
    expiry_date: product.expiry_date
      ? getDateObjectFromDateString(product.expiry_date)
      : null,
  };
};

export const getPurchasePayloadProducts = (productList) => {
  let products = [];
  if (productList?.length === 0) return products;

  products = productList.map((item) => {
    let p = {
      sku: item.sku,
      quantity: parseFloat(item.quantity),
      free_quantity: item.free_quantity ? parseFloat(item.free_quantity) : 0,
      batch_number: item.batch_number ? item.batch_number : null,
      manufacturing_date: item.manufacturing_date
        ? moment(item.manufacturing_date).format("DD-MM-YYYY")
        : null,
      expiry_date: item.expiry_date
        ? moment(item.expiry_date).format("DD-MM-YYYY")
        : null,
      price: parseFloat(item.price),
      purchase_price: parseFloat(item.purchase_price),
      sale_price: parseFloat(item.sale_price),
      tax: parseFloat(item.tax),
      unit: item.unit,
      enable_alternate_unit: item.enable_alternate_unit,
      alternate_unit: item.alternate_unit,
      tax: item.tax ? item.tax : convertTax(item),
      sale_price_with_tax: item.sale_price_with_tax,
      purchase_price_with_tax: item.purchase_price_with_tax,
    };
    let disc = {
      type: item.activeDiscount,
      value: item.discountValue,
    };
    p.manual_discount = disc;
    return p;
  });

  return products;
};
export const getPurchaseReturnPayloadProducts = (productList) => {
  let products = [];
  if (productList?.length === 0) return products;

  products = productList.map((item) => {
    let p = {
      sku: item.sku,
      quantity: parseFloat(item.quantity),
      batch_number: item.batch_number ? item.batch_number : null,
      manufacturing_date: item.manufacturing_date
        ? moment(item.manufacturing_date).format("DD-MM-YYYY")
        : null,
      expiry_date: item.expiry_date
        ? moment(item.expiry_date).format("DD-MM-YYYY")
        : null,
      price: parseFloat(item.price),
      purchase_price: parseFloat(item.purchase_price),
      purchase_price_with_tax: item.purchase_price_with_tax,
      tax: item.tax ? item.tax : convertTax(item),
      unit: item.unit,
      enable_alternate_unit: item.enable_alternate_unit,
      alternate_unit: item.alternate_unit,
    };
    return p;
  });

  return products;
};

export const createPurchasePaymentModelV1 = (
  total,
  selectedPaymentOption,
  selectedAmount,
  chequeNumber = "",
  chequeDate = null,
  type = PURCHASE_TYPE.PURCHASE
) => {
  let roundedTotal = Math.round(total); // round off later here
  let payment = {};
  payment.total_amount = total;
  if (selectedPaymentOption === PAYMENT.PAY_LATER) {
    payment.amount_due = roundedTotal;
  } else {
    payment.amount_due =
      selectedAmount - roundedTotal < 0 ? roundedTotal - selectedAmount : 0;
  }
  payment.is_paid = payment.amount_due <= 0;

  let payment_modes = [];

  if (selectedPaymentOption) {
    if (selectedPaymentOption === PAYMENT.PAY_LATER) {
      let payload = {
        created_at: Date.now(),
        payment_mode: PAYMENT.PAY_LATER,
        payment_amount: roundedTotal,
      };
      payment_modes.push(payload);
    } else {
      let payload = {
        created_at: Date.now(),
        payment_mode: selectedPaymentOption,
        payment_amount:
          parseFloat(selectedAmount) > roundedTotal
            ? roundedTotal
            : parseFloat(selectedAmount),
      };
      if (selectedPaymentOption === PAYMENT.CASH) {
        if (type === PURCHASE_TYPE.RETURN) {
          payload.cash_in = parseFloat(selectedAmount);
          payload.cash_out =
            parseFloat(selectedAmount) - payload.payment_amount;

          if (payload.cash_out < 0) {
            payload.cash_out = 0;
          }
        } else {
          payload.cash_out = parseFloat(selectedAmount);
          payload.cash_in = parseFloat(selectedAmount) - payload.payment_amount;

          if (payload.cash_in < 0) {
            payload.cash_in = 0;
          }
        }
      } else if (
        selectedPaymentOption === PAYMENT.CHEQUE &&
        chequeNumber &&
        chequeDate
      ) {
        let account = {
          cheque_number: chequeNumber,
          cheque_date: chequeDate.getTime(),
        };
        payload.account = account;
      }
      payment_modes.push(payload);

      if (parseFloat(selectedAmount) < roundedTotal) {
        let payload = {
          created_at: Date.now(),
          payment_mode: PAYMENT.PAY_LATER,
          payment_amount: roundedTotal - parseFloat(selectedAmount),
        };
        payment_modes.push(payload);
      }
    }
  }

  payment.payment_modes = payment_modes;

  return payment;
};

export const getSaleObjectHash = (data) => {
  let hashableObj = {
    price: data.price ?? 0,
    sale_price: data.sale_price ?? 0,
    sale_price_with_tax: data.sale_price_with_tax ?? true,
    tax: convertTax(data),
    enable_alternate_unit: data.enable_alternate_unit,
    alternate_unit: data.alternate_unit,
    wholesale_price: data.wholesale_price,
    wholesale_quantity: data.wholesale_quantity,
    hsn: data.hsn,
    batch_number: data.batch_number,
    manufacturing_date: data.manufacturing_date,
    expiry_date: data.expiry_date,
  };

  let hashRes = hash(hashableObj);
  return hashRes;
};
export const getPurchaseObjectHash = (data) => {
  let hashableObj = {
    price: data.price ?? 0,
    sale_price: data.sale_price ?? 0,
    sale_price_with_tax: data.sale_price_with_tax ?? true,
    purchase_price: data.purchase_price ?? 0,
    purchase_price_with_tax: data.purchase_price_with_tax ?? true,
    tax: data.tax ? data.tax : convertTax(data),
    enable_alternate_unit: data.enable_alternate_unit,
    alternate_unit: data.alternate_unit,
    hsn: data.hsn,
    batch_number: data.batch_number,
    manufacturing_date: data.manufacturing_date,
    expiry_date: data.expiry_date,
  };

  let hashRes = hash(hashableObj);
  return hashRes;
};

export const handleGetProductUnitQty = (product, cb, returnable = true) => {
  const { enable_alternate_unit, alternate_unit, unit } = product;
  let qt = 1;
  if (enable_alternate_unit === true) {
    if (unit) {
      if (unit === alternate_unit?.base_unit) {
        qt = 1;
      } else if (unit === alternate_unit?.secondary_unit) {
        qt = qt * (1 / parseFloat(alternate_unit?.conversion));
      }
    }
  }
  if (cb) {
    cb(qt);
  }
  if (returnable) {
    return qt;
  }
};

export const handleSelectSaleDraft = async (item, dispatch) => {
  dispatch(removeAllProducts());
  let ids = item.items?.filter((p) => !p.is_custom_product).map((_p) => _p.sku);
  let _data = await getProductsFromDB(ids, "sale");

  let d = item.items.map((p) => {
    let checkIdx = _data?.findIndex((x) => x.sku === p.sku);
    let checkProduct;
    if (checkIdx >= 0) {
      checkProduct = _data[checkIdx];
    } else {
      checkProduct = p;
    }
    let _p = { ...p, checkProduct: checkProduct };
    return getDraftProductToSlice(_p);
  });

  dispatch(addProductsToSale(d));

  dispatch(setSelectedCustomer(item.patient));
  dispatch(setSaleID(item.id));
  dispatch(setSelectedSale(item));
  if (
    Array.isArray(item.additional_charges) &&
    item.additional_charges?.length
  ) {
    dispatch(setAdditionalCharges(item.additional_charges));
  }
  dispatch(setCreatedDate(new Date(item.created_at)));
  dispatch(setBillno(item.bill_no ? item.bill_no : ""));
  if (item.cart_manual_discount) {
    let c = item.cart_manual_discount.value;

    if (item.cart_manual_discount?.type === DiscountOption.PERCENTAGE) {
      c = toDecimalPlace((c * 100) / item.total_sale_price);
    }
    dispatch(
      setSelectDiscount({ type: item.cart_manual_discount?.type, cost: c })
    );
  } else {
    dispatch(setSelectDiscount({ type: DiscountOption.PERCENTAGE, cost: 0 }));
  }
};

export const handleSelectPiDraft = async (item, dispatch) => {
  dispatch(removeAllProductsPI());

  let ids = item.items?.filter((p) => !p.is_custom_product).map((_p) => _p.sku);
  let _data = await getProductsFromDB(ids, "sale");

  let d = item.items.map((p) => {
    let checkIdx = _data?.findIndex((x) => x.sku === p.sku);
    let checkProduct;
    if (checkIdx >= 0) {
      checkProduct = _data[checkIdx];
    } else {
      checkProduct = p;
    }
    let _p = { ...p, checkProduct: checkProduct };
    return getDraftProductToSlice(_p);
  });

  dispatch(addProductsToPI(d));

  dispatch(setCustomerPI(item.patient));
  dispatch(setPI_ID(item.id));
  dispatch(setSelectedPI(item));
  if (
    Array.isArray(item.additional_charges) &&
    item.additional_charges?.length
  ) {
    dispatch(setAdditionalChargesPI(item.additional_charges));
  }
  dispatch(setCreatedDatePI(new Date(item.created_at)));
  dispatch(setBillNoPI(item.bill_no ? item.bill_no : ""));

  if (item.cart_manual_discount) {
    let c = item.cart_manual_discount.value;

    if (item.cart_manual_discount?.type === DiscountOption.PERCENTAGE) {
      c = toDecimalPlace((c * 100) / item.total_sale_price);
    }
    dispatch(
      setSelectDiscountPI({ type: item.cart_manual_discount?.type, cost: c })
    );
  } else {
    dispatch(setSelectDiscountPI({ type: DiscountOption.PERCENTAGE, cost: 0 }));
  }
};

export const getProductsForPurchaseDraftSelect = async (products, type) => {
  let ids = products?.filter((p) => !p.is_custom_product).map((_p) => _p.sku);
  let _data = await getProductsFromDB(ids, "purchase");

  let d = products.map((p) => {
    let checkIdx = _data?.findIndex((x) => x.sku === p.sku);
    let checkProduct;
    if (checkIdx >= 0) {
      checkProduct = _data[checkIdx];
    } else {
      checkProduct = p;
    }
    let _p = { ...p, checkProduct: checkProduct };
    if (type === PURCHASE_TYPE.RETURN) {
      return getPurchaseReturnDraftProductToSlice(_p);
    } else {
      return getPurchaseDraftProductToSlice(_p);
    }
  });

  return d;
};

export const getDateObjectFromDateString = (date) => {
  let dt = date.split("-").join("/");
  return moment(dt, "DD/MM/YYYY").toDate();
};

export const onInvoiceRequired = async (template_id, type, data) => {
  if (!process.env.REACT_APP_DEMO_SERVER) {
    try {
      let res = await window.electronAPI.printInvoice({
        template_id: template_id,
        type: type,
        data: data,
      });
      if (res?.code !== "success") {
      }
    } catch (err) {
      captureException(err);
    }
  }
};

export const handleSelectPurchaseDraft = async (purchase, dispatch) => {
  dispatch(removeAllPurchaseProducts());
  let data = await getProductsForPurchaseDraftSelect(purchase.products);
  dispatch(addProductsToPurchase(data));
  dispatch(setSelectedDraftPurchase(purchase));
  dispatch(setSelectedSupplier(purchase.supplier));
  dispatch(setPurchaseCreatedDate(new Date(purchase.created_at)));
  dispatch(setDeliveryExpense(purchase.delivery_price));
  dispatch(setBillNo(purchase.bill_no));
  dispatch(
    setDeliveryDate(
      purchase.delivery_date ? new Date(purchase.delivery_date) : null
    )
  );
  if (purchase?.cart_discount) {
    let c = purchase?.cart_discount.value;

    if (purchase?.cart_discount?.type === DiscountOption.PERCENTAGE) {
      c = toDecimalPlace((c * 100) / purchase?.total_purchase_price);
    }
    dispatch(
      setPurchaseSelectDiscount({
        type: purchase?.cart_discount?.type,
        cost: c,
      })
    );
  } else {
    dispatch(
      setPurchaseSelectDiscount({ type: DiscountOption.PERCENTAGE, cost: 0 })
    );
  }
};

export const handleSelectPurchaseReturnDraft = async (purchase, dispatch) => {
  dispatch(removeAllPurchaseReturnProducts());
  let data = await getProductsForPurchaseDraftSelect(
    purchase.products,
    PURCHASE_TYPE.RETURN
  );
  dispatch(addProductsToPurchaseReturn(data));
  dispatch(setSelectedDraftPurchaseReturn(purchase));
  dispatch(setReturnSelectedSupplier(purchase.supplier));
  dispatch(setPurchaseReturnCreatedDate(new Date(purchase.created_at)));
  dispatch(
    setPurchaseReturnDeliveryDate(
      purchase.delivery_date ? new Date(purchase.delivery_date) : null
    )
  );
  dispatch(setPurchaseReturnBillNo(purchase.bill_no));
  dispatch(setPurchaseReturnDeliveryExpense(purchase.delivery_price));
};
export const handleSelectPurchaseDamageDraft = async (purchase, dispatch) => {
  dispatch(removeAllDamageEntryProducts());
  let data = await getProductsForPurchaseDraftSelect(
    purchase.products,
    PURCHASE_TYPE.RETURN
  );
  dispatch(addProductsToDamageEntry(data));
  dispatch(setSelectedDraftDamage(purchase));
  dispatch(setDamageCreatedDate(new Date(purchase.created_at)));
  dispatch(setDamageBillNo(purchase.bill_no));
};

export const handleSelectPurchaseProformaDraft = async (purchase, dispatch) => {
  dispatch(removeAllProductsPP());
  let data = await getProductsForPurchaseDraftSelect(purchase.products);
  dispatch(addProductsToPP(data));
  dispatch(setSelectedPP(purchase));
  dispatch(setSelectedSupplierPP(purchase.supplier));
  dispatch(setCreatedDatePP(new Date(purchase.created_at)));
  dispatch(
    setDeliveryDatePP(
      purchase.delivery_date ? new Date(purchase.delivery_date) : null
    )
  );
  dispatch(setBillNoPP(purchase.bill_no));
  dispatch(setDeliveryExpensePP(purchase.delivery_price));
  dispatch(setTypePP(purchase.type));

  if (purchase?.cart_discount) {
    let c = purchase?.cart_discount.value;

    if (purchase?.cart_discount?.type === DiscountOption.PERCENTAGE) {
      c = toDecimalPlace((c * 100) / purchase?.total_purchase_price);
    }
    dispatch(
      setSelectDiscountPP({ type: purchase?.cart_discount?.type, cost: c })
    );
  } else {
    dispatch(setSelectDiscountPP({ type: DiscountOption.PERCENTAGE, cost: 0 }));
  }
};

export const getPurchaseCompareToPrice = (value, product) => {
  let purchasePrice = parseFloat(value);
  let tax = getTax(product);
  let { price, purchase_price_with_tax } = product;
  if (purchase_price_with_tax === false) {
    let purchasePriceWithTax = purchasePrice * (1 + tax / 100);
    if (purchasePriceWithTax > price) {
      return toDecimalPlace(price / (1 + tax / 100));
    } else {
      return value;
    }
  } else {
    if (purchasePrice > price) {
      return price;
    } else {
      return value;
    }
  }
};

export const getSaleProductSubTotal = (product) => {
  const { quantity, discountValue } = product;
  let qty = parseFloat(quantity);
  let discount = parseFloat(discountValue);
  let salePriceWotTax = toDecimalPlace(getSalePriceWithoutTax(product));
  let tax = getTax(product);
  let perItemDicount = discount / qty;
  let salePriceAfterDiscount = salePriceWotTax - perItemDicount;
  let taxPrice = getTaxValue(salePriceAfterDiscount, tax, false);
  return {
    itemTotal: toDecimalPlace((salePriceAfterDiscount + taxPrice) * qty),
    taxTotal: taxPrice * qty,
  };
};

export const getPurchaseProductSubTotal = (product) => {
  const { quantity, discountValue } = product;
  let qty = parseFloat(quantity);
  let discount = discountValue ? parseFloat(discountValue) : 0;
  let purchasePriceWotTax = toDecimalPlace(getPurchasePriceWithoutTax(product));
  let tax = getTax(product);
  let perItemDicount = discount / qty;
  let purchasePriceAfterDiscount = purchasePriceWotTax - perItemDicount;
  let taxPrice = getTaxValue(purchasePriceAfterDiscount, tax, false);
  return {
    itemTotal: toDecimalPlace((purchasePriceAfterDiscount + taxPrice) * qty),
    taxTotal: taxPrice * qty,
  };
};
