import styled from "@emotion/styled/macro";
import { COLOR } from "../../../constants/appConstants";

export const Container = styled.div`
    width: 290px;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.1s ease-in-out;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 12px;
    border-bottom: 1px solid #cbd5e1;
`;

export const HeaderLeft = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #334155;
`;

export const HeaderRight = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #2d68fe;
    cursor: pointer;
`;

export const Body = styled.div`
    padding: 8px 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const CustomerContainer = styled.div`
    background: #f1f5f9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 13px 12px;
    box-sizing: border-box;
    margin-bottom: 8px;

    img {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
`;

export const CustomerDetailsTop = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const CustomerName = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
`;

export const ReturnBadge = styled.div`
    height: 26px;
    background: #10b981;
    border-radius: 2px;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
`;

export const MobileNumber = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    margin-top: 4px;
`;

export const GreyText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
    margin-bottom: 4px;

    & > div:nth-of-type(1) {
        text-transform: capitalize;
    }
`;

export const Tax = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #64748b;
    margin-bottom: 10px;
`;

export const Discount = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #64748b;
    border-bottom: 1px dashed #e2e8f0;
    padding-bottom: 16px;
`;

export const Total = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
`;

export const AddDiscount = styled.div`
    height: 22px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    img {
        height: 15px;
        width: 15px;
    }
`;

export const AddDiscountDetails = styled.div`
    display: flex;
    align-items: center;
    img {
        height: 15px;
        width: 15px;
        margin-right: 10px;
    }
`;

export const AddDiscountText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #475569;
`;

export const CheckoutBtn = styled.button`
    height: 42px;
    font-weight: 500;
    font-size: 15px;
    line-height: 3px;
    color: #ffffff;
    background: ${(props) => (props.color ? COLOR.BLUE : "#94A3B8")};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.color ? "pointer" : "no-drop")};
    border: none;
    width: 100%;
`;

export const SavetoDraftBtn = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #3b82f6;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
`;
export const Dotted = styled.div`
    height: 1px;
    border-bottom: 2px dashed #e2e8f0;
    margin: 8px 0;
`;
export const ChangeText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2d68fe;
    cursor: pointer;
`;
export const BillContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 12px;
`;
export const FieldContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 150px;

    & > input {
        border: none;
        background: #fff;
        width: 100%;

        :focus {
            border: none;
            outline: none;
        }
    }
`;
export const Line = styled.div`
    height: 18px;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;
export const Label = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
`;

export const DiscountBtns = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;

    & > img {
        cursor: pointer;
    }
`;
export const DiscountCost = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #94a3b8;
    margin-left: 10px;
`;
export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: 72px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 2px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 2px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 2px;
`;
export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
export const CartDiscountChoosers = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & input {
        width: 72px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        padding: 10px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 13px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;

export const OutlineBtn = styled.div`
    padding: 8px 10px;
    color: #2d68fe;
    border: 1px solid #2d68fe;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background: #fff;
    cursor: pointer;
    text-align: center;
    margin-bottom: 12px;
`;

export const AdditionalChargeContainer = styled.div`
    margin-top: 12px;
    height: 120px;
`;
