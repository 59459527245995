import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;
export const NextBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #253238;
    border: 1px solid #253238;
    border-radius: 4px;
`;

export const Body = styled.div``;
export const InputFieldDiv = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
`;
export const ImageContainer = styled.div`
    position: relative;
    width: 151.8px;
    height: 36px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;

    input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    img {
        height: 16px;
        // width: 16px;
        object-fit: contain;
    }
`;

export const AddImage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //   font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #94a3b8;
    gap: 5px;
`;

export const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin-bottom: 10px;
`;

export const AddNew = styled.div`
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #2d68fe;
    display: flex;
    align-items: center;
    margin: 12px 0;
    margin-left: ${(props) => (props.shift ? "16px" : "0")};
    cursor: pointer;

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
        margin-right: 5px;
    }
`;

export const SaveBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78px;
    height: 46px;
    cursor: pointer;
    padding: 5px;
`;

export const ImageContainerDiv = styled.div`
    display: flex;
    gap: 10px;
`;

export const KycImage = styled.div`
    width: 84px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${(props) => props.src});
    opacity: ${(props) => (props.hover ? 0.3 : 1)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

export const HoverBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 100;
    //   margin-top: -32px;
    > img {
        cursor: pointer;
    }
`;
