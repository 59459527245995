import React from "react";
import * as S from "./PLReport.style";
import ProfitLoss from "../../components/analytics/profit-loss-report/ProfitLoss";

const PLReport = () => {
    return (
        <S.Wrapper>
            <ProfitLoss />
        </S.Wrapper>
    );
};

export default PLReport;
