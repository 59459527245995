import React from "react";
import * as S from "../LoginScreen.style";
import { useState } from "react";
import { PasswordEyeIcon, PasswordNoEyeIcon } from "../../../icons";

const Login = ({ loginData, handleInputChange, onSubmit, toSendOtpState }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <S.LoginHead>Login to continue</S.LoginHead>
            <S.LoginContainer>
                <div>
                    <S.Label>Enter phone number</S.Label>
                    <S.PhoneInputField>
                        <S.InputFIelds>+63</S.InputFIelds>
                        <S.InputFIelds>
                            <input type="text" name="username" onChange={(e) => handleInputChange(e)} autoFocus value={loginData.username} />
                        </S.InputFIelds>
                    </S.PhoneInputField>
                </div>
                <div>
                    <S.Label>Enter Password</S.Label>
                    <S.InputFIelds>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => handleInputChange(e)}
                            value={loginData.password}
                        />
                        <img src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon} alt="" onClick={() => setShowPassword(!showPassword)} />
                    </S.InputFIelds>
                </div>
                <S.ForgotPassword onClick={toSendOtpState}>Forget Password</S.ForgotPassword>
                <S.ConfirmBtn onClick={onSubmit}>Login</S.ConfirmBtn>
            </S.LoginContainer>
        </div>
    );
};

export default Login;
