import React, { useEffect, useState } from "react";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { captureException } from "../../../crash-reporting";
import {
    hideLoader,
    setPrintingPreference,
    showLoader,
} from "../../../store/app-slice";

const Printing = () => {
    const { printingPreference } = useSelector((state) => state.app);
    const [preference, setPreference] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    useEffect(() => {
        setPreference(printingPreference);
    }, []);

    const handleInputChange = (event) => {
        let { name, value } = event.target;

        if (name === "notes") {
            setPreference({ ...preference, notes: value });
        } else if (name === "terms") {
            setPreference({ ...preference, terms: value });
        } else if (name === "box1_header") {
            let b1 = { ...preference?.box1, header: value };
            setPreference({ ...preference, box1: b1 });
        } else if (name === "box1_content") {
            let b1 = { ...preference?.box1, content: value };
            setPreference({ ...preference, box1: b1 });
        } else if (name === "box2_header") {
            let b2 = { ...preference?.box2, header: value };
            setPreference({ ...preference, box2: b2 });
        } else if (name === "box2_content") {
            let b2 = { ...preference?.box2, content: value };
            setPreference({ ...preference, box2: b2 });
        } else if (name === "box3_header") {
            let b3 = { ...preference?.box3, header: value };
            setPreference({ ...preference, box3: b3 });
        } else if (name === "box3_content") {
            let b3 = { ...preference?.box3, content: value };
            setPreference({ ...preference, box3: b3 });
        } else if (name === "box4_header") {
            let b4 = { ...preference?.box4, header: value };
            setPreference({ ...preference, box4: b4 });
        } else if (name === "box4_content") {
            let b4 = { ...preference?.box4, content: value };
            setPreference({ ...preference, box4: b4 });
        }
    };

    const handleSavePrintingPreference = async () => {
        dispatch(showLoader());
        try {
            let res = await window.electronAPI.saveAppPreference({
                key: "printing",
                value: preference,
            });
            if (res.code === "success") {
                dispatch(setPrintingPreference(preference));
                handleSnackbarDetails({
                    show: true,
                    title: "Saved",
                    subtitle: "Preference saved successfully",
                    type: "success",
                });
            } else {
                console.log(res);
            }
        } catch (error) {
            captureException(error);
        } finally {
            dispatch(hideLoader());
        }
    };

    return (
        <S.Wrapper>
            <S.Body>
                <div>
                    <S.Head>Notes</S.Head>
                    <S.Container>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                type="textarea"
                                align="left"
                                name="notes"
                                value={preference?.notes}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Terms & Conditions</S.Head>
                    <S.Container>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                type="textarea"
                                align="left"
                                name="terms"
                                value={preference?.terms}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Box 1</S.Head>
                    <S.Container>
                        <S.GreyText>Header</S.GreyText>
                        <S.ConversionContainer>
                            <S.Input
                                type="text"
                                align="left"
                                name="box1_header"
                                value={preference?.box1?.header}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                    <S.Container>
                        <S.GreyText>Content</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                type="textarea"
                                align="left"
                                name="box1_content"
                                multiple
                                min={3}
                                value={preference?.box1?.content}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Box 2</S.Head>
                    <S.Container>
                        <S.GreyText>Header</S.GreyText>
                        <S.ConversionContainer>
                            <S.Input
                                type="text"
                                align="left"
                                name="box2_header"
                                value={preference?.box2?.header}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                    <S.Container>
                        <S.GreyText>Content</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                type="textarea"
                                align="left"
                                multiple
                                min={3}
                                name="box2_content"
                                value={preference?.box2?.content}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Box 3</S.Head>
                    <S.Container>
                        <S.GreyText>Header</S.GreyText>
                        <S.ConversionContainer>
                            <S.Input
                                type="text"
                                align="left"
                                name="box3_header"
                                value={preference?.box3?.header}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                    <S.Container>
                        <S.GreyText>Content</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                align="left"
                                name="box3_content"
                                value={preference?.box3?.content}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Box 4</S.Head>
                    <S.Container>
                        <S.GreyText>Header</S.GreyText>
                        <S.ConversionContainer>
                            <S.Input
                                type="text"
                                align="left"
                                name="box4_header"
                                value={preference?.box4?.header}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                    <S.Container>
                        <S.GreyText>Content</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputTextArea
                                align="left"
                                name="box4_content"
                                value={preference?.box4?.content}
                                onChange={handleInputChange}
                            />
                        </S.ConversionContainer>
                    </S.Container>
                </div>
            </S.Body>
            <S.BottomContainer>
                <S.SaveBtn onClick={handleSavePrintingPreference}>
                    Save Changes
                </S.SaveBtn>
            </S.BottomContainer>
        </S.Wrapper>
    );
};

export default Printing;
