import React from "react";
import { KeyFrame } from "../../../icons";
import * as S from "./style";

const ShortcutKeyTab = ({ title, data }) => {
    return (
        <S.Wrapper>
            <S.Header>{title}</S.Header>
            <S.Container>
                {data?.map((item) => (
                    <S.KeyRow key={item.id}>
                        <S.KeyDiv>
                            {item.key1 && (
                                <>
                                    <S.Key bg={KeyFrame}>{item.key1}</S.Key>
                                </>
                            )}
                            {item.key2 && (
                                <>
                                    <S.KeyPlus>+</S.KeyPlus>
                                    <S.Key bg={KeyFrame}>{item.key2}</S.Key>
                                </>
                            )}
                            {item.key3 && (
                                <>
                                    <S.KeyPlus>+</S.KeyPlus>
                                    <S.Key bg={KeyFrame}>{item.key3}</S.Key>
                                </>
                            )}
                        </S.KeyDiv>
                        <S.Activity>{item.activity}</S.Activity>
                    </S.KeyRow>
                ))}
            </S.Container>
        </S.Wrapper>
    );
};

export default ShortcutKeyTab;
