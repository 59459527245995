import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccdfff;
    border-radius: 4px;
    padding: 4px 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #004463;
`;
