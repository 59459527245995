import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProductChipClose } from "../../../icons";
import { removeSelectedBrand } from "../../../store/brand-slice";
import { removeSelectedCategory } from "../../../store/category-slice";
import {
  removeAllSearchParams,
  removeSearchParams,
  removeSort,
} from "../../../store/product-slice";
import { removeSelectedChipStock } from "../../../store/stock-slice";
import { removeSelectedSupplier } from "../../../store/supplier-slice";

import * as S from "./selected-filter-chips.styles";

const SelectedChip = ({ text, type, onRemoveChip }) => {
  return (
    <S.SelectedChipContainer>
      <S.ChipText>
        {/* <strong>{type}</strong> :  */}
        {text}
      </S.ChipText>
      <img onClick={onRemoveChip} src={SelectedProductChipClose} alt="close" />
    </S.SelectedChipContainer>
  );
};

const SelectedFilterChips = ({ clearSearch }) => {
  const selectedCategory = useSelector(
    (state) => state.category.inventorySelectedCategory
  );
  const selectedBrand = useSelector(
    (state) => state.brand.inventorySelectedBrand
  );
  const selectedSupplier = useSelector(
    (state) => state.supplier.inventorySelectedSupplier
  );
  const selectedStock = useSelector(
    (state) => state.stock.inventorySelectedStock
  );
  const { sort } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const handleRemoveChip = (type) => {
    if (type === "category") dispatch(removeSelectedCategory());
    else if (type === "brand") dispatch(removeSelectedBrand());
    else if (type === "supplier") dispatch(removeSelectedSupplier());
    else if (type === "sort_by") {
      dispatch(removeSort());
      dispatch(removeSearchParams("is_reversed"));
    }
    dispatch(removeSearchParams(type));
  };

  const handleRemoveStock = () => {
    dispatch(removeSelectedChipStock());
  };

  const handleClearFilters = () => {
    dispatch(removeAllSearchParams());
    dispatch(removeSelectedCategory());
    dispatch(removeSelectedBrand());
    dispatch(removeSelectedSupplier());
    dispatch(removeSelectedChipStock());
    dispatch(removeSort());
    clearSearch();
  };

  return (
    <S.Container>
      <S.LeftContainer>
        {selectedCategory && (
          <SelectedChip
            text={selectedCategory}
            type="Category"
            onRemoveChip={() => handleRemoveChip("category")}
          />
        )}
        {selectedBrand && (
          <SelectedChip
            text={selectedBrand}
            type="Brands"
            onRemoveChip={() => handleRemoveChip("brand")}
          />
        )}
        {selectedSupplier && (
          <SelectedChip
            text={selectedSupplier}
            type="Supplier"
            onRemoveChip={() => handleRemoveChip("supplier")}
          />
        )}
        {selectedStock && (
          <SelectedChip
            text={selectedStock}
            type="Stock Type"
            onRemoveChip={() => handleRemoveStock()}
          />
        )}
        {sort?.name && (
          <SelectedChip
            text={sort?.name}
            type="Stock Type"
            onRemoveChip={() => handleRemoveChip("sort_by")}
          />
        )}
      </S.LeftContainer>
      {(selectedCategory ||
        selectedBrand ||
        selectedSupplier ||
        selectedStock ||
        sort?.name) && (
        <S.ClearFilters onClick={handleClearFilters}>
          Clear all filters (Shift + Esc)
        </S.ClearFilters>
      )}
    </S.Container>
  );
};

export default SelectedFilterChips;
