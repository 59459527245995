import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ThreeDotsVerticalIcon } from "../../icons";
import * as S from "./suppliers-table.styles";
import { toDecimalPlace } from "../../utils/global-fn";

const Supplier = ({ supplier, openEditDialog, setClear, setTxn }) => {
    const [open, setOpen] = useState(false);
    const handleSupplierClick = (state) => {
        openEditDialog(supplier);
        // dispatch(setSearchParams({ supplier_id: supplier.id, status: "PUBLISH" }));
        setTxn(state);
        setClear(false);
    };
    const handleSupplierClearClick = () => {
        openEditDialog(supplier);
        // dispatch(setSearchParams({ supplier_id: supplier.id, status: "PUBLISH" }));
        setClear(true);
        setTxn(true);
    };
    return (
        <>
            <S.TableRow>
                <S.NameData onClick={() => handleSupplierClick(false)}>{supplier.name}</S.NameData>
                <S.ContactData>
                    {supplier.phone ? supplier.phone : supplier.phone_2 ? supplier.phone_2 : supplier.email ? supplier.email : ""}
                </S.ContactData>
                <S.BrandsData>{supplier.brands?.map((brand) => brand.name)?.join(", ")}</S.BrandsData>
                <S.AmountDue>₱{toDecimalPlace(supplier.transactions_count?.total_amount_due ?? 0)}</S.AmountDue>
                <S.BtnDiv>
                    <S.ViewBtn onClick={() => handleSupplierClick(true)}>View all purchases</S.ViewBtn>
                    <S.ClearDues onClick={handleSupplierClearClick}>Clear Dues</S.ClearDues>
                    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                        <img src={ThreeDotsVerticalIcon} alt="" onClick={() => setOpen(true)} />
                        {open && <OptionsContainer onOpenDrawer={() => handleSupplierClick(false)} />}
                    </OutsideClickHandler>
                </S.BtnDiv>
            </S.TableRow>
        </>
    );
};

export default Supplier;

const OptionsContainer = ({ onOpenDrawer, handleDeleteSupplier }) => {
    return (
        <S.OptionsContainer>
            <S.Option onClick={() => onOpenDrawer()}>Edit</S.Option>
            <S.Option onClick={() => handleDeleteSupplier()}>Delete</S.Option>
        </S.OptionsContainer>
    );
};
