import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore, updateStore } from "../../../api/local/storeApi";
import { STORE_OFFLINE_REASONS, WEEK_DAYS } from "../../../constants/appConstants";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { hideLoader, showLoader } from "../../../store/app-slice";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import * as S from "./style";
import OfflineStoreDialog from "./offline-store/OfflineStoreDialog";
import { setStore } from "../../../store/session-slice";
import { captureException } from "../../../crash-reporting";

const initialTimeValue = {
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
    sun: null,
};
const ToggleSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 68,
    height: 36,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(32px)",
            color: "#2D68FE",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#CCDFFF" : "#CCDFFF",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 29,
        height: 29,
    },
    "& .MuiSwitch-track": {
        borderRadius: 49 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#CCDFFF" : "#CCDFFF",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const Configuration = () => {
    const [time, setTime] = useState(initialTimeValue);
    const dispatch = useDispatch();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const store = useSelector((state) => state.session.store);
    const [onlineStore, setOnlineStore] = useState(true);
    const [offlineReasons, setOfflineReasons] = useState(STORE_OFFLINE_REASONS);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isAlways, setIsAlways] = useState(false);

    useEffect(() => {
        if (store) {
            if (store.timings) {
                if (store.timings.always_open) {
                    setIsAlways(true);
                } else {
                    let timeObj = {};
                    for (let key in store.timings) {
                        if (store.timings[key] === null) {
                            timeObj[key] = null;
                        } else {
                            timeObj[key] = [
                                onSetTime(store.timings[key][0]),
                                onSetTime(store.timings[key][1]),
                            ];
                        }
                    }
                    setTime(timeObj);
                }
            }

            if (store.online_settings) {
                setOnlineStore(store.online_settings.is_online);
            }
        }
    }, []);

    const getStoreData = async () => {
        try {
            let res = await getStore();
            if (res.status === 200) {
                dispatch(setStore(res.data));
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleStoreTime = (key, value, item) => {
        console.log(value);
        if (key === "start") {
            let tempTime = [];
            tempTime[0] = value;
            tempTime[1] = time[item.value][1] || null;
            setTime({ ...time, [item.value]: tempTime });
        } else if (key === "end") {
            let tempTime = [];
            tempTime[0] = time[item.value][0] || null;
            tempTime[1] = value;
            setTime({ ...time, [item.value]: tempTime });
        }
    };

    const handleChangeStoreStatus = (item) => {
        if (time[item.value] === null) {
            setTime({ ...time, [item.value]: [null, null] });
        } else {
            setTime({ ...time, [item.value]: null });
        }
    };

    const handleSubmit = async () => {
        const payload = {};
        const timings = {};

        if (isAlways) {
            timings.always_open = true;
        } else {
            for (let key in time) {
                if (time[key] === null) {
                    timings[key] = null;
                } else if (time[key].includes(null)) {
                    handleSnackbarDetails({
                        show: true,
                        type: "error",
                        title: "Select valid time",
                    });
                    return;
                } else {
                    timings[key] = [timeCalculate(time[key][0]), timeCalculate(time[key][1])];
                }
            }
        }

        payload.timings = timings;

        if (!isEmpty(payload)) {
            dispatch(showLoader());
            try {
                let res = await updateStore(payload);
                if (res.status === 200) {
                    getStoreData();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Store Saved",
                        subtitle: "Store saved successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            } finally {
                dispatch(hideLoader());
            }
        } else {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please change something",
            });
        }
    };

    const timeCalculate = (time) => {
        if (time === null) return null;
        let timeArr = time.split(":").map(Number);
        let final = timeArr[0] * 60 + timeArr[1];
        return final;
    };

    const onSetTime = (time) => {
        if (time === null) return null;
        let hour = Math.floor(time / 60);
        let min = time % 60;
        if (hour.toString().length === 1) hour = "0" + hour;
        if (min.toString().length === 1) min = "0" + min;

        return `${hour}:${min}`;
    };

    const handleToggle = () => {
        if (onlineStore) {
            setOpenConfirmModal(true);
        }
        setOnlineStore(!onlineStore);
    };
    const handleCheckBoxChange = () => {
        setIsAlways(!isAlways);
    };

    const handleStoreAlwaysOpen = (item) => {
        setTime({ ...time, [item.value]: [onSetTime(0), onSetTime(1439)] });
    };
    return (
        <>
            <OfflineStoreDialog
                open={openConfirmModal}
                onClose={() => setOpenConfirmModal(false)}
                offlineReasons={offlineReasons}
                setOfflineReasons={setOfflineReasons}
                setOnlineStore={setOnlineStore}
            />
            <S.Wrapper>
                <div>
                    <S.Heading>
                        Store Timing<span>*</span>
                    </S.Heading>
                    <S.AlwaysBox>
                        <S.CheckBox
                            type="checkbox"
                            id="always"
                            checked={isAlways}
                            onChange={handleCheckBoxChange}
                        />
                        <label for="always">Always Open 24/7</label>
                    </S.AlwaysBox>
                    {!isAlways && (
                        <S.WeekContainer>
                            {WEEK_DAYS.map((item) => (
                                <S.WeekRow>
                                    <S.Day>{item.name}:</S.Day>
                                    {time[item.value] ? (
                                        <S.SubContainer>
                                            <S.TimeInput
                                                type="time"
                                                placeholder="Select Time"
                                                onChange={(e) =>
                                                    handleStoreTime("start", e.target.value, item)
                                                }
                                                value={time[item.value][0]}
                                            />
                                            <S.Colon>:</S.Colon>
                                            <S.TimeInput
                                                type="time"
                                                placeholder="Select Time"
                                                onChange={(e) =>
                                                    handleStoreTime("end", e.target.value, item)
                                                }
                                                value={time[item.value][1]}
                                            />
                                            <S.StatusBtn
                                                color="#FF4343"
                                                onClick={() => handleChangeStoreStatus(item)}
                                            >
                                                Close
                                            </S.StatusBtn>
                                        </S.SubContainer>
                                    ) : (
                                        <S.SubContainer>
                                            <S.ClosedBtn>Closed</S.ClosedBtn>
                                            <S.StatusBtn
                                                color="#10B981"
                                                onClick={() => handleChangeStoreStatus(item)}
                                            >
                                                Open
                                            </S.StatusBtn>
                                        </S.SubContainer>
                                    )}
                                    <S.StatusBtn
                                        color="#2f7bfa"
                                        onClick={() => handleStoreAlwaysOpen(item)}
                                    >
                                        Always Open
                                    </S.StatusBtn>
                                </S.WeekRow>
                            ))}
                        </S.WeekContainer>
                    )}
                    {/* <S.LiveContainer>
                        <ToggleSwitch checked={onlineStore} onChange={handleToggle} />
                        <S.LiveTitle>Switch to live the store.</S.LiveTitle>
                    </S.LiveContainer> */}
                </div>
                <S.BottomContainer>
                    <S.Hint>
                        (Note: To select a time, look for a time period selector or try adjusting
                        the time slider.)
                    </S.Hint>
                    <S.SaveBtn onClick={handleSubmit}>Save</S.SaveBtn>
                </S.BottomContainer>
            </S.Wrapper>
        </>
    );
};

export default Configuration;
