import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./style";
import Lottie from "lottie-react";
import EmptyTable from "../../../animations/empty_table.json";
import Expense from "./Expense";
import store from "../../../store/store";
import { deleteExpense } from "../../../api/local/expenses-api";
import { fetchAllExpenses } from "../../../store/expense-slice";
import DeleteModal from "../../purchase/delete-modal/DeleteModal";
import { captureException } from "../../../crash-reporting";

const ExpensesTable = ({ setOpenExpenseModal, activeRow, activeRowRef, setActiveRow, openExpenseModalref, selectRef }) => {
    const { expenses, searchParams } = useSelector((state) => state.expense);
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const [openDeleteModal, _setOpenDeleteModal] = useState({ show: false, data: null });
    const openDeleteModalRef = useRef(openDeleteModal);

    const setOpenDeleteModal = (data) => {
        openDeleteModalRef.current = data;
        _setOpenDeleteModal(data);
    };

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (openExpenseModalref.current?.show || selectRef.current || openDeleteModalRef.current?.show) return;
        if (e.keyCode === 33) {
            //Pg Up key
            if (activeRowRef.current > 0) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 34) {
            //Pg down key
            let totalExpenses = store.getState().expense.expenses;
            if (activeRowRef.current < totalExpenses.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            let expense = store.getState().expense.expenses[activeRowRef.current];
            handleRowClick(expense);
            e.preventDefault();
        } else if (e.keyCode === 46) {
            //Delete key
            let totalExpenses = store.getState().expense.expenses;
            setOpenDeleteModal({ show: true, data: totalExpenses[activeRowRef.current] });
            e.preventDefault();
        }
    };

    const handleRowClick = (item) => {
        setOpenExpenseModal({
            show: true,
            expenseId: item?.id,
        });
    };

    const handleDeleteExpense = async (item) => {
        try {
            let res = await deleteExpense(item?.id);
            if (res.status === 200) {
                dispatch(fetchAllExpenses(searchParams));
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <>
            <DeleteModal
                open={openDeleteModal.show}
                onClose={() => setOpenDeleteModal({ show: false, data: null })}
                handleDelete={() => {
                    handleDeleteExpense(openDeleteModal.data);
                }}
            />
            <S.Wrapper>
                <S.HeadRow>
                    <S.Head flexValue="0.7">Date & Time</S.Head>
                    <S.Head flexValue="0.6">Expenses Type</S.Head>
                    <S.Head flexValue="0.5" center mr="80px">
                        Amount
                    </S.Head>
                    <S.Head flexValue="0.7">Payment Mode</S.Head>
                    <S.Head flexValue="0.7">Added By</S.Head>
                    <S.Head flexValue="1">Description</S.Head>
                </S.HeadRow>
                {expenses?.length ? (
                    <S.TableBody>
                        {expenses?.map((item, idx) => (
                            <Expense
                                item={item}
                                handleRowClick={handleRowClick}
                                key={idx}
                                idx={idx}
                                activeRow={activeRow}
                                scrollRef={scrollRef}
                                setOpenDeleteModal={setOpenDeleteModal}
                            />
                        ))}
                    </S.TableBody>
                ) : (
                    <S.EmptyTable>
                        <div>
                            <Lottie animationData={EmptyTable} />
                        </div>
                    </S.EmptyTable>
                )}
            </S.Wrapper>
        </>
    );
};

export default ExpensesTable;
