import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQuickLogins } from "../api/local/auth-api";
import { captureException } from "../crash-reporting";

const initialState = {
    loading: false,
    logins: [],
    error: "",
};

export const fetchQuickLogins = createAsyncThunk("quickLogins/fetchQuickLogins", () =>
    getQuickLogins()
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const quickLoginsSlice = createSlice({
    name: "quickLogins",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchQuickLogins.pending, (state) => {
            state.loading = true;
            state.logins = [];
            state.error = "";
        });
        builder.addCase(fetchQuickLogins.fulfilled, (state, action) => {
            state.loading = false;
            state.logins = action.payload;
            state.error = "";
        });
        builder.addCase(fetchQuickLogins.rejected, (state, action) => {
            state.loading = false;
            state.logins = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default quickLoginsSlice.reducer;
