import { Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CloseModalIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import Kyc from "../KYC/Kyc";
import Permission from "../permission/Permission";
import PersonalInformation from "../personal-information/PersonalInformation";
import Salary from "../salary/Salary";
import * as S from "./AddNewEmp.style";
import { validEmail } from "../../../utils/validity-fns";
import { isEmpty } from "lodash";
import { updateEmployee, addEmployee, getEmployee } from "./../../../api/local/employee-api";
import { fetchAllEmployees } from "../../../store/employee-slice";
import Account from "../Account/Account";
import { captureException } from "../../../crash-reporting";

const initialDisplayData = {
    name: "",
    username: "",
    permission_role: "",
    phone: "",
    email: "",
    joining_date: Date.now(),
    dob: "",
    kyc: [],
    salary: "",
    account_type: "",
    account_name: "",
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    upi_id: "",
    // kyc_id: "",
    // id_type: "",
    // id_img1: "",
    // id_img2: "",
};

const AddNewEmp = ({ open, onClose, employeeId }) => {
    const [tabView, setTabView] = useState(0);
    const [displayData, setDisplayData] = useState(initialDisplayData);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [accountType, setAccountType] = useState("BANK ACCOUNT");
    const dispatch = useDispatch();

    useEffect(() => {
        setTabView(0);
        if (employeeId) {
            getEmployee(employeeId)
                .then((res) => {
                    if (res.status === 200) {
                        setDisplayData({
                            ...res.data,
                            salary: res.data.salary_detail?.monthly_salary,
                            account_type: res.data.salary_account?.account_type,
                            account_name: res.data.salary_account?.account_name ?? "",
                            account_number: res.data.salary_account?.account_number ?? "",
                            bank_name: res.data.salary_account?.bank_name ?? "",
                            ifsc_code: res.data.salary_account?.ifsc_code ?? "",
                            upi_id: res.data.salary_account?.upi_id ?? "",
                        });
                        if (res.data.salary_account !== null) {
                            if (res.data.salary_account?.account_type === "BANK_ACCOUNT") {
                                setAccountType("BANK ACCOUNT");
                            } else {
                                setAccountType(res.data.salary_account?.account_type);
                            }
                        }
                    } else {
                        throw new Error(res.data?.message);
                    }
                })
                .catch((e) => captureException(e));
        }
    }, [open, employeeId]);

    function handleClose() {
        onClose();
        setDisplayData(initialDisplayData);
        setAccountType("BANK ACCOUNT");
    }
    async function handleSubmit(kycDataList) {
        const {
            name = "",
            username = "",
            permission_role = "",
            phone = "",
            email = "",
            joining_date = Date.now(),
            dob = "",
            kyc = [],
            salary = "",
            account_type = "",
            account_name = "",
            account_number = "",
            bank_name = "",
            ifsc_code = "",
            upi_id = "",
            kyc_id = "",
            id_type = "",
            id_img1 = "",
            id_img2 = "",
        } = displayData;

        if (!name) {
            handleSnackbarDetails({
                show: true,
                title: "Name is required",
                type: "error",
            });
            return;
        }
        if (!phone) {
            handleSnackbarDetails({
                show: true,
                title: "Phone number is required",
                type: "error",
            });
            return;
        }
        // if (!permission_role) {
        //     handleSnackbarDetails({
        //         show: true,
        //         title: "Permission role is required",
        //         type: "error",
        //     });
        //     return;
        // }
        if (email && !validEmail(email)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid email",
                type: "error",
            });
            return;
        }
        if (phone) {
            if (phone?.length !== 10) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter a valid phone number",
                    type: "error",
                });
                return;
            }
        }
        const payload = {
            name: name,
            username: phone,
            permission_role: "Co Admin",
            password: "password",
        };
        const salary_detail = {};
        const salary_account = {};
        const kyc_data = {};
        if (phone) payload.phone = phone;
        if (email) payload.email = email;
        if (joining_date) payload.joining_date = joining_date;
        if (dob) payload.dob = dob;
        if (salary) salary_detail.monthly_salary = +salary;
        if (!isEmpty(salary_detail)) {
            payload.salary_detail = salary_detail;
        }
        if (account_type) salary_account.account_type = account_type;
        if (account_name) salary_account.account_name = account_name;
        if (account_number) salary_account.account_number = account_number;
        if (bank_name) salary_account.bank_name = bank_name;
        if (ifsc_code) salary_account.ifsc_code = ifsc_code;
        if (upi_id) salary_account.upi_id = upi_id;
        if (!isEmpty(salary_account)) {
            payload.salary_account = salary_account;
        }
        // if (id_type) kyc_data.id_type = id_type;
        // if (kyc_id) kyc_data.id = kyc_id;
        // if (id_img1) kyc_data.id_image = [id_img1]; //TODO
        // if (id_img2) kyc_data.id_image.concat(id_img2);
        // if (!isEmpty(kyc_data)) {
        //   payload.kyc = [kyc_data];
        // }
        // console.log(payload)
        // payload.kyc = kycDataList
        if (employeeId) {
            try {
                let res = await updateEmployee(employeeId, payload);
                if (res.status === 200) {
                    dispatch(fetchAllEmployees());

                    handleClose();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Success",
                        subtitle: `${payload.name} updated successfully`,
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (e) {
                captureException(e);
                handleSnackbarDetails({
                    show: true,
                    title: e.response?.data?.message,
                    type: "error",
                });
            }
        } else {
            try {
                let res = await addEmployee(payload);
                if (res.status === 200) {
                    dispatch(fetchAllEmployees());
                    handleClose();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Success",
                        subtitle: `${payload.name} added successfully`,
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (e) {
                captureException(e);
                handleSnackbarDetails({
                    show: true,
                    title: e.response?.data?.message,
                    type: "error",
                });
            }
        }
    }

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.HeaderContainer>
                    <S.HeaderText>Staff Information</S.HeaderText>
                    <img src={CloseModalIcon} alt="" onClick={handleClose} />
                </S.HeaderContainer>
                <S.Tabs>
                    <S.Tab active={tabView === 0} onClick={() => setTabView(0)}>
                        Personal Information
                    </S.Tab>
                    {/* <S.Tab active={tabView === 1} onClick={() => setTabView(1)}>
                        Permission
                    </S.Tab> */}
                    <S.Tab active={tabView === 2} onClick={() => setTabView(2)}>
                        Account
                    </S.Tab>
                    <S.Tab active={tabView === 3} onClick={() => setTabView(3)}>
                        Salary
                    </S.Tab>
                    {/* <S.Tab active={tabView === 4} onClick={() => setTabView(4)}>
                        KYC
                    </S.Tab> */}
                </S.Tabs>
                <div>
                    {tabView === 0 && (
                        <PersonalInformation
                            displayData={displayData}
                            setDisplayData={setDisplayData}
                            setTabView={setTabView}
                            employeeId={employeeId}
                        />
                    )}
                    {tabView === 1 && (
                        <Permission displayData={displayData} setDisplayData={setDisplayData} setTabView={setTabView} handleSubmit={handleSubmit} />
                    )}
                    {tabView === 2 && (
                        <Account
                            displayData={displayData}
                            setDisplayData={setDisplayData}
                            setTabView={setTabView}
                            handleSubmit={handleSubmit}
                            accountType={accountType}
                            setAccountType={setAccountType}
                        />
                    )}
                    {tabView === 3 && (
                        <Salary
                            displayData={displayData}
                            setDisplayData={setDisplayData}
                            setTabView={setTabView}
                            handleSubmit={handleSubmit}
                            employeeId={employeeId}
                        />
                    )}
                    {tabView === 4 && (
                        <Kyc
                            kycList={displayData.kyc}
                            //setDisplayData={setDisplayData}
                            handleSubmit={(value) => handleSubmit(value)}
                        />
                    )}
                </div>
            </S.Wrapper>
        </Modal>
    );
};

export default AddNewEmp;
