import React, { useContext, useEffect, useState, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SaleReturnIcon, SelectedProductChipClose } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { fetchAllPurchases, removeSelectedPurchase } from "../../../store/purchase-slice";
import { calendarFormatDate } from "../../../utils/format-date";
import CreatePurchaseTable from "./create-purchase-table/create-purchase-table";
import * as S from "./purchase-details.styles";
import PurchaseSummary from "./purchase-summary/purchase-summary";
import AddSupplierModal from "../add-supplier-modal/add-supplier-modal";
import NewSupplierModal from "../../new-supplier-modal/new-supplier-modal";
import { updatePurchase } from "../../../api/local/purchase-api";
import { cloneDeep, isEmpty } from "lodash";
import { COLOR, INVOICE_TYPE, PURCHASE_PAYMENT_MODES, PURCHASE_STATUS, PURCHASE_TYPE, PURCHASE_TYPE_MAPPING } from "../../../constants/appConstants";
import { useNavigate } from "react-router-dom";
import BackButton from "./../../back-button/BackButton";
import DeleteModal from "./../delete-modal/DeleteModal";
import { useRef } from "react";
import history from "../../../utils/history";
import PurchasePaymentModal from "../../purchase-payment-modal/PurchasePaymentModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { captureException } from "../../../crash-reporting";
import CustomButton from "../../custom-button/CustomButton";
import { getDateObjectFromDateString, getPurchasePriceWithoutTax, toDecimalPlace } from "../../../utils/global-fn";

const initialSelectedPurchaseData = {
    supplier: null,
    delivery_price: "",
    delivery_date: null,
    bill_no: "",
    created_at: null,
    cart_discount: "",
};

const PurchaseDetails = (props, ref) => {
    const [productsList, _setProductsList] = useState([]);
    const productsListRef = useRef(productsList);
    const setProductsList = (data) => {
        productsListRef.current = data;
        _setProductsList(data);
    };
    const [selectedPurchaseData, _setSelectedPurchaseData] = useState(initialSelectedPurchaseData);
    const selectedPurchaseDataRef = useRef(selectedPurchaseData);
    const setSelectedPurchaseData = (data) => {
        selectedPurchaseDataRef.current = data;
        _setSelectedPurchaseData(data);
    };

    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const [openReturn, _setOpenReturn] = useState(false);
    const openReturnRef = useRef(openReturn);
    const setOpenReturn = (data) => {
        openReturnRef.current = data;
        _setOpenReturn(data);
    };
    const { selectedPurchase, searchParams } = useSelector((state) => state.purchase);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const billRef = useRef();
    const delExpRef = useRef();
    const timeRef = useRef();
    const discountRef = useRef();
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [returnQuantityCount, setReturnQuantityCount] = useState(0);
    const [openPurchasePaymentModal, _setOpenPurchasePaymentModal] = useState(false);
    const openPurchasePaymentModalRef = useRef(openPurchasePaymentModal);
    const setOpenPurchasePaymentModal = (data) => {
        openPurchasePaymentModalRef.current = data;
        _setOpenPurchasePaymentModal(data);
    };
    const [openPurchaseSummaryModal, setOpenPurchaseSummaryModal] = useState(false);
    const [openReturnSummaryModal, setOpenReturnSummaryModal] = useState(false);
    const [openConvertpaymentModal, setOpenConvertPaymentModal] = useState(false);
    const [freeQtyPresent, setFreeQtyPresent] = useState(false);

    useEffect(() => {
        if (selectedPurchase) {
            let products = [];
            selectedPurchase?.products.forEach((product) => {
                products.push({
                    ...product,
                    manufacturing_date: product.manufacturing_date ? getDateObjectFromDateString(product.manufacturing_date) : null,
                    expiry_date: product.expiry_date ? getDateObjectFromDateString(product.expiry_date) : null,
                    editable: false,
                    freeQuantity: "",
                });

                if (product.free_quantity) {
                    setFreeQtyPresent(true);
                }
            });

            setProductsList(products);
            setSelectedPurchaseData({
                ...selectedPurchaseData,
                supplier: selectedPurchase.supplier,
                delivery_price: selectedPurchase.delivery_price,
                delivery_date: selectedPurchase.delivery_date ? new Date(selectedPurchase.delivery_date) : null,
                bill_no: selectedPurchase.bill_no,
                created_at: new Date(selectedPurchase.created_at),
            });
        }
        return () => {
            dispatch(removeSelectedPurchase());
        };
    }, []);

    useEffect(() => {
        if (openReturn) {
            handleReturnPurchaseTotal();
        }
    }, [productsList]);

    useImperativeHandle(ref, () => {
        return {
            openSupplierModal: () => setOpenSupplierModal(true),
            focusBill: () => billRef.current?.focus(),
            focusDelExp: () => delExpRef.current?.focus(),
            openDeliveryDate: () => timeRef.current?.querySelector("input").click(),
            onBackAction: () => backAction(),
            onSave: () => {
                if (openReturnRef.current) {
                    handleBtnClick("return");
                } else {
                    handleBtnClick("save");
                }
            },
            viewInvoice: () => handleViewInvoice(),
            onReturn: () => handleReturn(true),
            onDelete: () => {
                if (!openReturnRef.current) {
                    setOpenDeleteModal(true);
                }
            },
        };
    });

    const backAction = () => {
        if (openSupplierModal) {
            setOpenSupplierModal(false);
        } else if (openPurchasePaymentModal) {
            setOpenPurchasePaymentModal(false);
        } else if (openPurchaseSummaryModal) {
            setOpenPurchaseSummaryModal(false);
        } else if (openDeleteModal) {
            setOpenDeleteModal(false);
        } else {
            handleBackAction();
        }
    };

    const removeSupplier = () => {
        setSelectedPurchaseData({ ...selectedPurchaseData, supplier: null });
    };

    const handleDateChange = (item) => {
        setSelectedPurchaseData({ ...selectedPurchaseData, created_at: item });
    };

    const handleBtnClick = (type) => {
        if (type === "delete") {
            const payload = cloneDeep(selectedPurchase);
            payload.status = PURCHASE_STATUS.TRASH;
            updatePurchase(selectedPurchase.id, payload)
                .then((res) => {
                    dispatch(fetchAllPurchases(searchParams));
                    navigate("/dashboard/purchases");
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Purchase deleted successfully",
                    });
                })
                .catch((err) => {
                    captureException(err);
                    handleSnackbarDetails({
                        show: true,
                        title: "Something went wrong...",
                        type: "error",
                    });
                });
        } else if (type === "save") {
            if (selectedPurchase.type === PURCHASE_TYPE.PURCHASE && !selectedPurchaseData.supplier) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please select a supplier",
                    type: "error",
                });
                return;
            }
            const payload = {};
            if (selectedPurchaseData.supplier) {
                if (!selectedPurchase.supplier || selectedPurchase.supplier.id !== selectedPurchaseData.supplier.id) {
                    payload.supplier_id = selectedPurchaseData.supplier.id;
                }
            }
            if (selectedPurchaseData.delivery_price) {
                if (selectedPurchase.delivery_price !== selectedPurchaseData.delivery_price) {
                    payload.delivery_price = +selectedPurchaseData.delivery_price;
                }
            }
            if (selectedPurchaseData.bill_no) {
                if (selectedPurchase.bill_no !== selectedPurchaseData.bill_no) {
                    payload.bill_no = selectedPurchaseData.bill_no;
                }
            }
            if (selectedPurchaseData.delivery_date) {
                if (selectedPurchase.delivery_date !== selectedPurchaseData.delivery_date.getTime()) {
                    if (selectedPurchaseData.delivery_date.getTime() !== new Date().getTime()) {
                        payload.delivery_date = selectedPurchaseData.delivery_date.getTime();
                    }
                }
            }
            if (selectedPurchaseData.created_at) {
                if (selectedPurchase.created_at !== selectedPurchaseData.created_at.getTime()) {
                    if (selectedPurchaseData.created_at.getTime() !== new Date().getTime()) {
                        payload.created_at = selectedPurchaseData.created_at.getTime();
                    }
                }
            }
            if (!isEmpty(payload)) {
                updatePurchase(selectedPurchase.id, payload)
                    .then((res) => {
                        if (res.status === 200) {
                            handleSnackbarDetails({
                                show: true,
                                type: "success",
                                title: "Purchase saved successfully",
                            });
                        } else {
                            throw new Error(res.data?.message);
                        }
                    })
                    .catch((err) => {
                        captureException(err);
                        handleSnackbarDetails({
                            show: true,
                            title: "Something went wrong...",
                            type: "error",
                        });
                    });
            }

            navigate("/dashboard/purchases");
        } else if (type === "return") {
            let checkedProducts = productsList.filter((item) => item.editable);
            if (!checkedProducts.length) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please choose a product",
                    type: "error",
                });
                return;
            }
            setOpenPurchasePaymentModal(true);
        }
    };

    function handleBackAction() {
        if (openReturn) {
            handleReturn(false);
        } else {
            history.back();
        }
    }
    function handleReturnPurchaseTotal() {
        let checkedProducts = productsList.filter((item) => item.editable);
        let price = 0;
        let taxPrice = 0;
        let quantityCount = 0;
        for (let i = 0; i < checkedProducts.length; i++) {
            let { purchase_price, returnQuantity, tax, discount, quantity, purchase_price_with_tax } = checkedProducts[i];
            if (returnQuantity > 0) {
                let purchasePrice = parseFloat(purchase_price);

                let purchasePriceWotTax = toDecimalPlace(getPurchasePriceWithoutTax(checkedProducts[i]));
                let itemDiscount = discount ? discount.value : discount;
                let perItemDiscount = itemDiscount/quantity
                let purchasePriceAfterDiscount = purchasePriceWotTax - perItemDiscount;
                let itemTax = tax;
    
                const itemTotal = (purchasePriceAfterDiscount + itemTax) * parseFloat(returnQuantity);
                const taxTotal = tax * returnQuantity;
    
                // if (purchase_price_with_tax === false) {
                //     purchasePrice += tax;
                // }
    
                price += itemTotal;
                taxPrice += taxTotal;
                quantityCount += returnQuantity;
            }
        }
        setTotalPrice(price);
        setTotalTax(taxPrice);
        setReturnQuantityCount(quantityCount);
    }

    const handleReturn = (data) => {
        setOpenReturn(data);
        if (data === true) {
            setSelectedPurchaseData({ ...selectedPurchaseData, delivery_price: "", bill_no: "", delivery_date: null, created_at: null });
        } else {
            setSelectedPurchaseData({
                ...selectedPurchaseData,
                delivery_price: selectedPurchase?.delivery_price,
                bill_no: selectedPurchase?.bill_no,
                delivery_date: selectedPurchase?.delivery_date ? new Date(selectedPurchase?.delivery_date) : null,
                created_at: selectedPurchase?.created_at ? new Date(selectedPurchase?.created_at) : null,
            });
        }
    };

    const handleAddSupplier = (item) => {
        setSelectedPurchaseData({ ...selectedPurchaseData, supplier: item });
    };

    const handleViewInvoice = async () => {
        if (!selectedPurchase) {
            return;
        }

        try {
            //Non TAX Invoice - 3
            let templateId = selectedPurchase.invoice_template_id ?? "3";
            let res = await window.electronAPI.printInvoice({
                template_id: templateId,
                type: INVOICE_TYPE.PURCHASE,
                data: selectedPurchase,
            });
            if (res?.code !== "success") {
                handleSnackbarDetails({
                    show: true,
                    title: res?.message,
                    type: "error",
                });
            }
        } catch (err) {
            captureException(err);
        }
    };
    return (
        <>
            <PurchasePaymentModal
                open={openPurchasePaymentModal}
                onClose={() => setOpenPurchasePaymentModal(false)}
                type="return-purchase"
                rpAdditional={{
                    productsList: productsList.filter((item) => item.editable),
                    totalTax,
                    totalPrice,
                    cartDiscount: selectedPurchaseData.cart_discount,
                    billno: selectedPurchaseData.bill_no,
                    deliveryExpense: selectedPurchaseData.delivery_price,
                    deliveryDate: selectedPurchaseData.delivery_date,
                    createdAt: selectedPurchaseData.created_at,
                    supplier: selectedPurchaseData.supplier,
                }}
                openReturnSummaryModal={openReturnSummaryModal}
                setOpenReturnSummaryModal={setOpenReturnSummaryModal}
            />

            {[PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(selectedPurchase?.type) && (
                <PurchasePaymentModal
                    open={openConvertpaymentModal}
                    onClose={() => setOpenConvertPaymentModal(false)}
                    type="convert-to-purchase"
                    openPurchaseSummaryModal={openPurchaseSummaryModal}
                    setOpenPurchaseSummaryModal={setOpenPurchaseSummaryModal}
                />
            )}
            <DeleteModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} handleDelete={() => handleBtnClick("delete")} />

            <AddSupplierModal
                open={openSupplierModal}
                onClose={() => setOpenSupplierModal(false)}
                openAddNewModal={() => setOpenAddSupplierModal(true)}
                onSelect={(item) => handleAddSupplier(item)}
            />
            <NewSupplierModal
                open={openAddSupplierModal}
                onClose={() => setOpenAddSupplierModal(false)}
                closeModal={() => setOpenSupplierModal(false)}
                onSave={(item) => handleAddSupplier(item)}
            />
            <S.Container>
                <S.Header>
                    <S.Topbar>
                        <S.TitleContainer>
                            <BackButton onClickAction={handleBackAction} />
                            <S.PurchaseType>
                                {openReturn ? "Select Items to Return" : `Edit ${PURCHASE_TYPE_MAPPING[selectedPurchase?.type]}`}
                            </S.PurchaseType>
                        </S.TitleContainer>
                        <S.DateContainer>
                            <S.Date>Date</S.Date>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(selectedPurchaseData.created_at ?? new Date()))}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="date-picker"
                                disabled={!openReturn}
                            />
                        </S.DateContainer>
                    </S.Topbar>
                    <S.HeaderDetails>
                        <S.HeaderContainer>
                            {!selectedPurchase?.return_id ? (
                                <S.HeaderText>{`ID: #${selectedPurchase?.id}`}</S.HeaderText>
                            ) : (
                                <S.ReturnHead>
                                    <img src={SaleReturnIcon} alt="return icon" />
                                    <S.ReturnGreyHead>Return Against</S.ReturnGreyHead>
                                    <S.HeaderText>#{selectedPurchase.return_id}</S.HeaderText>
                                </S.ReturnHead>
                            )}
                        </S.HeaderContainer>
                        <S.BtnContainer>
                            {!openReturn && (
                                <>
                                    <CustomButton label="View Invoice (F9)" onClick={handleViewInvoice} color={COLOR.BLUE} fill={true} />
                                    {selectedPurchase?.type === PURCHASE_TYPE.PURCHASE && !selectedPurchase?.return_id && (
                                        <CustomButton label="Return item/s (F11)" onClick={() => handleReturn(true)} color={COLOR.BLUE} fill={true} />
                                    )}
                                    {selectedPurchase?.type === PURCHASE_TYPE.PURCHASE && selectedPurchase?.return_id && (
                                        <S.ReturnedBtn>Returned #{selectedPurchase?.return_id}</S.ReturnedBtn>
                                    )}
                                    {selectedPurchase?.type !== PURCHASE_TYPE.DAMAGE_ENTRY && !selectedPurchase?.return_id && (
                                        <CustomButton
                                            label={`${selectedPurchaseData.supplier ? "Change" : "Add"} Supplier (F4)`}
                                            onClick={() => setOpenSupplierModal(true)}
                                            color={COLOR.BLUE}
                                            fill={true}
                                        />
                                    )}
                                </>
                            )}
                        </S.BtnContainer>
                    </S.HeaderDetails>
                </S.Header>

                <S.Body>
                    <CreatePurchaseTable
                        products={productsListRef.current}
                        setProductsList={setProductsList}
                        openReturn={openReturn}
                        openReturnRef={openReturnRef}
                        openPurchasePaymentModalRef={openPurchasePaymentModalRef}
                        freeQtyPresent={freeQtyPresent}
                    />

                    <PurchaseSummary
                        openReturn={openReturn}
                        billRef={billRef}
                        delExpRef={delExpRef}
                        timeRef={timeRef}
                        discountRef={discountRef}
                        totalTax={totalTax}
                        totalPrice={totalPrice}
                        returnQuantityCount={returnQuantityCount}
                        selectedPurchaseData={selectedPurchaseData}
                        setSelectedPurchaseData={setSelectedPurchaseData}
                        removeSupplier={removeSupplier}
                    />
                </S.Body>
                <S.BottomBar>
                    <S.BottomBarLeft>
                        {selectedPurchaseData.supplier && (
                            <S.SupplierName>
                                Supplier: {selectedPurchaseData.supplier?.name}
                                {selectedPurchaseData.supplier?.phone ? `(${selectedPurchaseData.supplier.phone})` : ""}{" "}
                                {!openReturn && selectedPurchase?.type === PURCHASE_TYPE.PURCHASE && (
                                    <img onClick={() => removeSupplier()} src={SelectedProductChipClose} alt="clear" />
                                )}
                            </S.SupplierName>
                        )}
                        {[PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(selectedPurchase?.type) && (
                            <CustomButton onClick={() => setOpenConvertPaymentModal(true)} label="Convert To Purchase" color={COLOR.BLUE} size="lg" />
                        )}
                    </S.BottomBarLeft>

                    <S.BtnContainer>
                        {openReturn ? (
                            <CustomButton onClick={() => handleBtnClick("return")} label="Return (F12)" color={COLOR.BLUE} fill={true} size="lg" />
                        ) : (
                            <>
                                <CustomButton
                                    onClick={() => setOpenDeleteModal(true)}
                                    label="Delete (Alt + Del)"
                                    noBorder={true}
                                    color={COLOR.RED}
                                    size="lg"
                                />
                                <CustomButton onClick={() => handleBtnClick("save")} label="Save (F12)" color={COLOR.BLUE} fill={true} size="lg" />
                            </>
                        )}
                    </S.BtnContainer>
                </S.BottomBar>
            </S.Container>
        </>
    );
};

export default React.forwardRef(PurchaseDetails);
