import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    ${(props) => props.active && "box-shadow: 0px 4px 4px rgba(45, 104, 254, 0.2)"}
`;
export const TopWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const TopContainer = styled.div`
    // border-bottom: 1px solid #f1f5f9;
    margin: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    width: 325px;
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #263238;
    margin-bottom: 8px;
`;
export const DelExName = styled.div`
    color: ${(props) => (props.active ? "#2D68FE" : "#455a64")};
`;
export const TimeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #455a64;
`;
export const Time = styled.div`
    font-weight: 500;
    color: ${(props) => (props.active ? "#2D68FE" : "#455a64")};
`;
export const MiddleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SkuDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #94a3b8;

    & span {
        font-weight: 600;
        color: ${(props) => (props.active ? "#2D68FE" : "#455a64")};
    }
`;
export const Price = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => (props.active ? "#2D68FE" : "#455a64")};
`;
export const OrderIdContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #94a3b8;
`;
export const SideBar = styled.div`
    height: 70px;
    width: 3px;
    border-radius: 0px 16px 16px 0px;
    background-color: ${(props) =>
        props.active
            ? "#2D68FE"
            : props.packing
            ? "#22FE53"
            : props.cancelled
            ? "#FF4343"
            : "#fff"};
`;
export const PackingStatus = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #263238;

    & span {
        font-weight: 600;
        color: ${(props) => props.cancelled && "#FF4343"};
    }
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const DeliveryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #94a3b8;
`;
