import React, { useRef, useImperativeHandle } from "react";
import ExpensesTable from "../../components/cash-management/expenses-table/ExpensesTable";
import CustomInput from "../../components/product-drawer/custom-input/custom-input";
import CustomSelect from "../../components/product-drawer/custom-select/custom-select";
import { EXPENSES_SORT_LIST, EXPENSES_TYPES, TIME_PERIOD } from "../../constants/appConstants";
import * as S from "./Expenses.style";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAllExpenses, removeAllSearchParams, removeSearchParams, setSearchParams } from "../../store/expense-slice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useCallback } from "react";
import { debounce } from "lodash";
import { fetchAllEmployees, removeSelectedEmployee } from "../../store/employee-slice";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIcon, SelectArrowDownIcon } from "../../icons";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { calendarFormatDate, formatDate } from "../../utils/format-date";
import AddNewExpense from "../../components/cash-management/add-new-expense/AddNewExpense";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Expense.css";
import SelectedChips from "../../components/selected-chips/selected-chips";

const initalDisplayData = {
    expense_type: {},
    sort: {},
    employee: {},
    date: "",
};

const Expenses = (props, ref) => {
    const [search, setSearch] = useState("");
    const [displayData, setDisplayData] = useState(initalDisplayData);
    const { searchParams } = useSelector((state) => state.expense);
    const { employees, searchEmpParams } = useSelector((state) => state.employee);
    const selectRef = useRef(false);
    const trackRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [openDatePicker, _setOpenDatePicker] = useState(false);
    const openDatePickerRef = useRef(openDatePicker);
    const setOpenDatePicker = (data) => {
        openDatePickerRef.current = data;
        _setOpenDatePicker(data);
    };

    const [openExpenseModal, _setOpenExpenseModal] = useState({
        show: false,
        expenseId: null,
    });

    const openExpenseModalref = useRef(openExpenseModal);

    const setOpenExpenseModal = (data) => {
        openExpenseModalref.current = data;
        _setOpenExpenseModal(data);
    };

    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllExpenses(searchParams));
    }, [searchParams]);

    useEffect(() => {
        if (!employees?.length) dispatch(fetchAllEmployees(searchEmpParams));
        return () => {
            dispatch(removeAllSearchParams());
            dispatch(removeSelectedEmployee());
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            addNewExpense: () =>
                setOpenExpenseModal({
                    show: true,
                    expenseId: null,
                }),
        };
    });

    const delayedFunction = (val, page) => {
        if (val) {
            dispatch(setSearchParams({ search: val }));
        } else dispatch(removeSearchParams("search"));
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);
    const handleExpenseSearchChange = (val) => {
        setSearch(val);
        delayedQuery(val);
    };

    const handleSelectChange = (key, item) => {
        if (key === "expense-type") {
            setDisplayData({ ...displayData, expense_type: item });
            dispatch(setSearchParams({ expense_type: item.value }));
        } else if (key === "added-by") {
            setDisplayData({ ...displayData, employee: item });
            dispatch(setSearchParams({ added_by: item.username }));
            // dispatch(fetchAllEmployees());
        } else if (key === "sort") {
            setDisplayData({ ...displayData, sort: item });
            const payload = { sort_by: item.value };
            if (+item.id % 2 === 0) payload.is_reversed = true;
            if (+item.id % 2 === 1) payload.is_reversed = false;
            dispatch(setSearchParams(payload));
        }
    };

    const handleSearchChange = (key, value) => {
        if (key === "added-by") dispatch(fetchAllEmployees({ search: value }));
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setOpenDatePicker(false);
            setDisplayData({
                ...displayData,
                date: `${formatDate(start)} - ${formatDate(end)}`,
            });
            dispatch(
                setSearchParams({
                    start_date: start,
                    end_date: end,
                })
            );
        }
    };

    return (
        <>
            <AddNewExpense
                open={openExpenseModal.show}
                expenseId={openExpenseModal.expenseId}
                onClose={() =>
                    setOpenExpenseModal({
                        show: false,
                        expenseId: null,
                    })
                }
            />
            <S.Wrapper>
                <S.TopBar>
                    <S.Heading>View Expenses</S.Heading>
                    <S.BlueBtn
                        onClick={() =>
                            setOpenExpenseModal({
                                show: true,
                                expenseId: null,
                            })
                        }
                    >
                        Add New (F12)
                    </S.BlueBtn>
                </S.TopBar>
                <S.Body>
                    <S.FilterContainer>
                        <S.Filters>
                            <S.InputBox>
                                <CustomInput
                                    value={search}
                                    onChange={handleExpenseSearchChange}
                                    clearTextIcon
                                    onClickClear={() => handleExpenseSearchChange("")}
                                    searchIcon
                                    type="text"
                                    placeholder="Search by description"
                                />
                            </S.InputBox>
                            <CustomSelect
                                type="expense-type"
                                initalText="Expenses Type"
                                data={EXPENSES_TYPES}
                                localSearch
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.expense_type?.name}
                                menuWidth="235px"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                            <S.DateChooserSelect>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={Date.parse(calendarFormatDate(startDate))}
                                    endDate={Date.parse(calendarFormatDate(endDate))}
                                    onChange={(update) => {
                                        handleDateChange(update);
                                    }}
                                    isClearable={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="expense-date-picker"
                                    placeholderText="Select time period"
                                />
                                <img className="caret-down" src={SelectArrowDownIcon} alt="select" />
                            </S.DateChooserSelect>
                            {/* <CustomSelect
                            type="amount"
                            initalText="Amount"
                            menuWidth="235px"
                        /> */}
                            <CustomSelect
                                type="added-by"
                                initalText="Added By"
                                onSearchChange={handleSearchChange}
                                data={employees}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.employee?.name}
                                menuWidth="235px"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                        </S.Filters>
                        <S.SortContainer>
                            Sort:{" "}
                            <CustomSelect
                                type="sort"
                                initalText="Select Sort"
                                disableSearch
                                data={EXPENSES_SORT_LIST}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.sort?.name}
                                menuWidth="235px"
                                position="left"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                        </S.SortContainer>
                    </S.FilterContainer>
                    <S.ChipContainer>
                        <SelectedChips
                            displayData={displayData}
                            setDisplayData={setDisplayData}
                            clearSearch={() => handleExpenseSearchChange("")}
                            resetRange={() => setDateRange([null, null])}
                        />
                    </S.ChipContainer>
                    <ExpensesTable
                        setOpenExpenseModal={setOpenExpenseModal}
                        activeRow={activeRow}
                        activeRowRef={activeRowRef}
                        setActiveRow={setActiveRow}
                        openExpenseModalref={openExpenseModalref}
                        selectRef={selectRef}
                    />
                </S.Body>
            </S.Wrapper>
        </>
    );
};

export default React.forwardRef(Expenses);
