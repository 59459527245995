import React, { useState, useEffect } from "react";
import * as S from "./style";
import { TAX_VALUES } from "../../../constants/appConstants";
import { isEqual } from "lodash";
import CustomSelect from "../../product-drawer/custom-select/custom-select";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#334155",
        color: "#FFFFFF",
        maxWidth: 220,
        border: "1px solid #dadde9",
        borderRadius: "2px",
        width: "184px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px",
        textAlign: "center",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#334155",
    },
}));

const SelectTax = ({ item, onChange, type, dropDown = true }) => {
    const [selectedTax, setSelectedTax] = useState("");

    useEffect(() => {
        if (item?.tax) {
            const taxIdx = TAX_VALUES.findIndex((option) => isEqual(option.value, item?.tax));
            if (taxIdx >= 0) {
                setSelectedTax(TAX_VALUES[taxIdx]?.name);
            }
        } else if (item?.tax) {
            const taxIdx = TAX_VALUES.findIndex((option) => isEqual(option.value, item?.tax));
            if (taxIdx >= 0) {
                setSelectedTax(TAX_VALUES[taxIdx]?.name);
            }
        } else if (item.tax) {
            const taxIdx = TAX_VALUES.findIndex((option) => calculateTax(option.value) === item.tax);
            if (taxIdx >= 0) {
                setSelectedTax(TAX_VALUES[taxIdx]?.name);
            }
        } else {
            setSelectedTax(TAX_VALUES[0]?.name);
        }
    }, [item]);

    const handleSelectChange = (key, item) => {
        setSelectedTax(item?.name);
        onChange(item?.value, key);
    };

    const calculateTax = (tax) => {
        let totalTax = Object.values(tax).reduce((a, b) => a + b, 0);
        return totalTax;
    };

    return (
        <HtmlTooltip
            arrow
            placement="top"
            title={
                <React.Fragment>
                    <div className="sku">{selectedTax ?? "0%"}</div>
                </React.Fragment>
            }
        >
            <S.Wrapper>
                {dropDown ? (
                    <CustomSelect
                        type={type}
                        disableSearch
                        data={TAX_VALUES}
                        onSelectChange={handleSelectChange}
                        selectedOption={selectedTax ?? "0%"}
                        size="sm"
                    />
                ) : (
                    <S.Text>{selectedTax ?? "0%"}</S.Text>
                )}
            </S.Wrapper>
        </HtmlTooltip>
    );
};

export default SelectTax;
