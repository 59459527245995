import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    // width: 49.5%;
    width: 100%;
`;
export const Header = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 14px;
    color: #64748b;
    background: #f1f5f9;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
`;

export const KeyRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    padding: 18px;
`;
export const Container = styled.div`
    border: 1px solid #e2e8f0;
    // height: 300px;
    // overflow: hidden;
    // overflow-y: auto;
`;
export const KeyDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
`;
export const Activity = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #1e293b;
`;
export const Key = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;
    width: 42px;
    height: 24px;
    text-transform: capitalize;
    background-image: url(${(props) => props.bg});
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const KeyPlus = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    color: #64748b;
`;
