import React, { useCallback } from "react";
import * as S from "./style";
import { PRINTERS } from "../../../constants/appConstants";
import { useState } from "react";
import Thermal from "./thermal/Thermal";
import Barcode from "./barcode/Barcode";
import { useEffect } from "react";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { captureException } from "../../../crash-reporting";
import { debounce, isEmpty } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { join } from "path-browserify";

const SAMPLE_SALE = {
    _id: "e1052054-1b6a-445b-864f-17855f595f11",
    store_id: "60d35fa9-4982-4009-8950-96483271edef",
    id: 30,
    created_via: "POS Electron",
    is_online_sale: false,
    bill_no: null,
    customer: {
        _id: "3e3e70b1-0a8c-401e-b4af-bfaabd5bf0bc",
        store_id: "60d35fa9-4982-4009-8950-96483271edef",
        phone: "7478870112",
        name: "Gaurav Bordoloi",
        address: null,
        email: null,
        tags: [],
        created_at: 1691469700470,
        date_modified: 1691675385715,
        remarks: null,
    },
    type: "SALE",
    created_at: 1691675389034,
    date_modified: null,
    status: "PUBLISH",
    employee: {
        name: "Gaurav",
    },
    products: [
        {
            sku: "XH2B4N13FD",
            upc: "8901030831690",
            name: "Kissan Mixed Fruit Jam",
            gallery: ["https://content.homedrop.in/products/8901030831690.png"],
            price: 75,
            sale_price: 75,
            tax: 9,
            stock: -2,
            stock: -2,
            quantity: 1,
            quantity: 1,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 75,
            net_amount: 75,
            remarks: null,
            purchase_price: 58.97999954223633,
            net_profit: 16.020000457763672,
            tax_percentage: 12,
            age_restricted: null,
            brand: {
                _id: "5d5ea27f-d122-42d9-bf46-ded4de74a3bd",
                store_id: "GLOBAL",
                name: "Kissan",
                slug: "kissan",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649959,
                date_modified: null,
            },
            category: {
                _id: "f1f25249-a9d2-4c93-a1d9-1f4cdd3c410e",
                store_id: "GLOBAL",
                name: "Jams & Honey",
                slug: "jams-honey",
                image: "https://content.homedrop.in/categories/jams-honey.png",
                count: null,
                parent_id: "e7554f6b-e24e-4299-b919-e355bab4b7f3",
                created_at: 1671265649959,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "gm",
                    value: "200",
                },
            ],
            type: {
                _id: 1,
                name: "Grocery",
                image: "https://media.homedrop.in/media/categories/type/banner-grocery.jpg",
                icon: "https://media.homedrop.in/media/categories/type/grocery.svg",
            },
        },
        {
            sku: "4STIY5V8CF",
            upc: "89009550",
            name: "Dabur Amla Hair Oil - For Stronger, Longer & Thicker Hair",
            gallery: ["https://content.homedrop.in/products/89009550.png"],
            price: 47,
            sale_price: 47,
            tax: 7.559999942779541,
            stock: -6,
            stock: -6,
            quantity: 2,
            quantity: 2,
            manual_discount: 10,
            manual_discount: null,
            auto_discount: null,
            total_discount: 10,
            discounted_sale_price: 42,
            net_amount: 84,
            remarks: null,
            purchase_price: 35.939998626708984,
            net_profit: 32.119998931884766,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "029cf7a6-22ad-42b8-9728-0eb84d6800a5",
                store_id: "GLOBAL",
                name: "Dabur",
                slug: "dabur",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649959,
                date_modified: null,
            },
            category: {
                _id: "2f717f52-1d56-4c82-8058-f639504ac5e6",
                store_id: "GLOBAL",
                name: "Hair Oil",
                slug: "hair-oil",
                image: "https://content.homedrop.in/categories/hair-oil.png",
                count: null,
                parent_id: "6eb9efe2-8ffc-4317-8c04-7ab986b94ba1",
                created_at: 1671265649957,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "ml",
                    value: "90",
                },
            ],
            type: {
                _id: 2,
                name: "Beauty & Hygiene",
                image: "https://media.homedrop.in/media/categories/type/banner-beauty.jpg",
                icon: "https://media.homedrop.in/media/categories/type/beauty-and-hygine.svg",
            },
        },
        {
            sku: "IS46H8QUD0",
            upc: "4902430919128",
            name: "Ambi Pur Air Effects – Room Air Freshener – Floral Escape",
            gallery: ["https://content.homedrop.in/products/4902430919128.png"],
            price: 279,
            sale_price: 279,
            tax: 50.220001220703125,
            stock: -1,
            stock: -1,
            quantity: 4,
            quantity: 4,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 279,
            net_amount: 1116,
            remarks: null,
            purchase_price: 157.6199951171875,
            net_profit: 485.5199890136719,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "91d43fa7-6978-48fc-ad30-bf2b8bf4a144",
                store_id: "GLOBAL",
                name: "Ambi Pur",
                slug: "ambi-pur",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649944,
                date_modified: null,
            },
            category: {
                _id: "20444f18-541c-47a0-95e4-fa3d07e73b03",
                store_id: "GLOBAL",
                name: "Air & Room Freshener",
                slug: "air-room-freshener",
                image: "https://media.homedrop.in/media/categories/new/air-room-freshener.webp",
                count: null,
                parent_id: "28187bd3-6266-48f6-852e-45676bf2da46",
                created_at: 1671265649944,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "gm",
                    value: "275",
                },
            ],
            type: {
                _id: 3,
                name: "Household & Cleaning",
                image: "https://media.homedrop.in/media/categories/type/banner-household-and-cleaning.jpg",
                icon: "https://media.homedrop.in/media/categories/type/household-and-cleaning.svg",
            },
        },
        {
            sku: "VH3GKHSDZ1",
            upc: "4902430836784",
            name: "Ariel Lau Matic Front Load Detergent Powder",
            gallery: ["https://content.homedrop.in/products/4902430836784.png"],
            price: 239,
            sale_price: 239,
            tax: 42.119998931884766,
            stock: -1,
            stock: -1,
            quantity: 1,
            quantity: 1,
            manual_discount: 5,
            manual_discount: null,
            auto_discount: null,
            total_discount: 5,
            discounted_sale_price: 234,
            net_amount: 234,
            remarks: null,
            purchase_price: 181.91000366210938,
            net_profit: 62.09000015258789,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "9d9687c8-f0b3-4ab6-9a43-0030d9472d76",
                store_id: "GLOBAL",
                name: "Ariel",
                slug: "ariel",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649945,
                date_modified: null,
            },
            category: null,
            metrics: [
                {
                    unit: "kg",
                    value: "1",
                },
            ],
            type: {
                _id: 3,
                name: "Household & Cleaning",
                image: "https://media.homedrop.in/media/categories/type/banner-household-and-cleaning.jpg",
                icon: "https://media.homedrop.in/media/categories/type/household-and-cleaning.svg",
            },
        },
        {
            sku: "TK5CYPIR26",
            upc: "8901138819712",
            name: "Himalaya Baby Diaper Rash Cream",
            gallery: ["https://content.homedrop.in/products/8901138819712.png"],
            price: 120,
            sale_price: 120,
            tax: 14.399999618530273,
            stock: -1,
            stock: -1,
            quantity: 1,
            quantity: 1,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 120,
            net_amount: 120,
            remarks: null,
            purchase_price: 87.0999984741211,
            net_profit: 32.900001525878906,
            tax_percentage: 12,
            age_restricted: null,
            brand: {
                _id: "79621be9-cdc3-4dae-b40a-40886a18b5ba",
                store_id: "GLOBAL",
                name: "Himalaya",
                slug: "himalaya",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649962,
                date_modified: null,
            },
            category: {
                _id: "05af9eae-428e-4f6a-9520-0e786cb3bd67",
                store_id: "GLOBAL",
                name: "Baby Cream & Lotion",
                slug: "baby-cream-lotion",
                image: "https://media.homedrop.in/media/categories/new/baby-cream-lotion.webp",
                count: null,
                parent_id: "25909757-8de8-4a2e-97c3-a13ccd0f6804",
                created_at: 1671265649970,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "gm",
                    value: "50",
                },
            ],
            type: {
                _id: 5,
                name: "Baby Products",
                image: "https://media.homedrop.in/media/categories/type/banner-toy.jpg",
                icon: "https://media.homedrop.in/media/categories/type/baby-products.svg",
            },
        },
        {
            sku: "PI6030IO5I",
            upc: "8901396209102",
            name: "Airwick Air Freshener Spray - Lemon & Orange Blossom",
            gallery: ["https://content.homedrop.in/products/8901396209102.png"],
            price: 161,
            sale_price: 161,
            tax: 28.979999542236328,
            stock: -1,
            stock: -1,
            quantity: 2,
            quantity: 2,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 161,
            net_amount: 322,
            remarks: null,
            purchase_price: 95.19999694824219,
            net_profit: 131.60000610351562,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "11f1ffcd-6b5e-48be-b4d3-d5fcaf28ec1b",
                store_id: "GLOBAL",
                name: "Airwick",
                slug: "airwick",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1678863101803,
                date_modified: null,
            },
            category: {
                _id: "20444f18-541c-47a0-95e4-fa3d07e73b03",
                store_id: "GLOBAL",
                name: "Air & Room Freshener",
                slug: "air-room-freshener",
                image: "https://media.homedrop.in/media/categories/new/air-room-freshener.webp",
                count: null,
                parent_id: "28187bd3-6266-48f6-852e-45676bf2da46",
                created_at: 1671265649944,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "ml",
                    value: "245",
                },
            ],
            type: {
                _id: 3,
                name: "Household & Cleaning",
                image: "https://media.homedrop.in/media/categories/type/banner-household-and-cleaning.jpg",
                icon: "https://media.homedrop.in/media/categories/type/household-and-cleaning.svg",
            },
        },
        {
            sku: "RJJVFABYIN",
            upc: "8901542004612",
            name: "Complan Nutrition and Health Drink Royale Chocolate 500g, Jar",
            gallery: ["https://content.homedrop.in/products/8901542004612.png"],
            price: 245,
            sale_price: 245,
            tax: 0,
            stock: -1,
            stock: -1,
            quantity: 2,
            quantity: 2,
            manual_discount: 24.5,
            manual_discount: null,
            auto_discount: null,
            total_discount: 24.5,
            discounted_sale_price: 232.75,
            net_amount: 465.5,
            remarks: null,
            purchase_price: 191.5399932861328,
            net_profit: 131.4199981689453,
            tax_percentage: 0,
            age_restricted: null,
            brand: {
                _id: "8f1f18d0-f494-4194-bd3e-5eba68a5a523",
                store_id: "GLOBAL",
                name: "Complan",
                slug: "complan",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265650011,
                date_modified: null,
            },
            category: {
                _id: "d714df7a-ce37-40a5-87f4-35b3beed8c09",
                store_id: "GLOBAL",
                name: "Health Drink & Supplements",
                slug: "health-drink-supplements",
                image: "https://content.homedrop.in/categories/health-drink-supplements.png",
                count: null,
                parent_id: "8d53fd35-4963-427b-9e02-1130be53a39c",
                created_at: 1671265649959,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "gm",
                    value: "500",
                },
            ],
            type: {
                _id: 1,
                name: "Grocery",
                image: "https://media.homedrop.in/media/categories/type/banner-grocery.jpg",
                icon: "https://media.homedrop.in/media/categories/type/grocery.svg",
            },
        },
        {
            sku: "6GGQW7CBBG",
            upc: "8904097530414",
            name: "Divya jal Water Bottle 900ml ",
            gallery: ["https://content.homedrop.in/products/8904097530414.png"],
            price: 125,
            sale_price: 125,
            tax: 22.5,
            stock: -1,
            stock: -1,
            quantity: 1,
            quantity: 1,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 125,
            net_amount: 125,
            remarks: null,
            purchase_price: 90,
            net_profit: 35,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "6aab0e79-7a38-4974-8467-1da7e79a7541",
                store_id: "GLOBAL",
                name: "Patanjali",
                slug: "patanjali",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649958,
                date_modified: null,
            },
            category: {
                _id: "dee20f18-3ddc-4709-a3f7-34deb309ecf3",
                store_id: "GLOBAL",
                name: "Filter & Water Bottle",
                slug: "filter-water-bottle",
                image: "https://media.homedrop.in/media/categories/new/filter-water-bottle.webp",
                count: null,
                parent_id: "28187bd3-6266-48f6-852e-45676bf2da46",
                created_at: 1672076848145,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "ml",
                    value: "900",
                },
            ],
            type: {
                _id: 1,
                name: "Grocery",
                image: "https://media.homedrop.in/media/categories/type/banner-grocery.jpg",
                icon: "https://media.homedrop.in/media/categories/type/grocery.svg",
            },
        },
        {
            sku: "ZBWZDNUNQP",
            upc: "8901030702051",
            name: "Tresemme Hair Fall Defence Shampoo, For Strong Hair, With Keratin Protein, Prevent Hair Fall Due To Breakage, 340 Ml",
            gallery: ["https://content.homedrop.in/products/8901030702051.png"],
            price: 225,
            sale_price: 225,
            tax: 40.5,
            stock: -1,
            stock: -1,
            quantity: 2,
            quantity: 2,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 225,
            net_amount: 450,
            remarks: null,
            purchase_price: 168.13999938964844,
            net_profit: 113.72000122070312,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "fcb619bf-7492-4a90-a590-b8b99f6f3e1f",
                store_id: "GLOBAL",
                name: "TRESemmé",
                slug: "tresemm",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649971,
                date_modified: null,
            },
            category: null,
            metrics: [
                {
                    unit: "ml",
                    value: "340",
                },
            ],
            type: {
                _id: 2,
                name: "Beauty & Hygiene",
                image: "https://media.homedrop.in/media/categories/type/banner-beauty.jpg",
                icon: "https://media.homedrop.in/media/categories/type/beauty-and-hygine.svg",
            },
        },
        {
            sku: "MIKD0J49VM",
            upc: "8901030707834",
            name: "Dove Intense Repair Conditioner",
            gallery: ["https://content.homedrop.in/products/8901030707834.png"],
            price: 210,
            sale_price: 210,
            tax: 36,
            stock: -1,
            stock: -1,
            quantity: 1,
            quantity: 1,
            manual_discount: 10,
            manual_discount: null,
            auto_discount: null,
            total_discount: 10,
            discounted_sale_price: 200,
            net_amount: 200,
            remarks: null,
            purchase_price: 148.02999877929688,
            net_profit: 71.97000122070312,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "ff443b4d-4a5a-423b-990e-3695ca6bea8b",
                store_id: "GLOBAL",
                name: "Dove",
                slug: "dove",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649958,
                date_modified: null,
            },
            category: null,
            metrics: [
                {
                    unit: "ml",
                    value: "175",
                },
            ],
            type: {
                _id: 2,
                name: "Beauty & Hygiene",
                image: "https://media.homedrop.in/media/categories/type/banner-beauty.jpg",
                icon: "https://media.homedrop.in/media/categories/type/beauty-and-hygine.svg",
            },
        },
        {
            sku: "40BDEK3SRG",
            upc: "42182740",
            name: "AXE SHOWER GEL EXCITE 250ML",
            gallery: ["https://content.homedrop.in/products/42182740.png"],
            price: 225,
            sale_price: 200,
            tax: 28.799999237060547,
            stock: -1,
            stock: -1,
            quantity: 12,
            quantity: 12,
            manual_discount: 480,
            manual_discount: null,
            auto_discount: null,
            total_discount: 480,
            discounted_sale_price: 160,
            net_amount: 1920,
            remarks: null,
            purchase_price: 152.5500030517578,
            net_profit: 1049.4000244140625,
            tax_percentage: 18,
            age_restricted: null,
            brand: {
                _id: "43487506-1a51-4463-b22b-a9b68ab16f47",
                store_id: "GLOBAL",
                name: "Axe",
                slug: "axe",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649958,
                date_modified: null,
            },
            category: {
                _id: "44dda6a4-87df-481d-b3e7-9208c9b93554",
                store_id: "GLOBAL",
                name: "Shower Gel & Body Wash",
                slug: "shower-gel-body-wash",
                image: "https://content.homedrop.in/categories/shower-gel-body-wash.png",
                count: null,
                parent_id: "7733e400-5558-4401-82c8-397f1b7b5f09",
                created_at: 1671265649944,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "ml",
                    value: "250",
                },
            ],
            type: {
                _id: 2,
                name: "Beauty & Hygiene",
                image: "https://media.homedrop.in/media/categories/type/banner-beauty.jpg",
                icon: "https://media.homedrop.in/media/categories/type/beauty-and-hygine.svg",
            },
        },
        {
            sku: "TPAEK2XBBJ",
            upc: "8901030611179",
            name: "Axe After Shave Lotion - Denim",
            gallery: ["https://content.homedrop.in/products/8901030611179.png"],
            price: 120,
            sale_price: 120,
            tax: 33.599998474121094,
            stock: -1,
            stock: -1,
            quantity: 4,
            quantity: 4,
            manual_discount: 0,
            manual_discount: null,
            auto_discount: null,
            total_discount: 0,
            discounted_sale_price: 120,
            net_amount: 480,
            remarks: null,
            purchase_price: 85.9800033569336,
            net_profit: 136.0800018310547,
            tax_percentage: 28,
            age_restricted: null,
            brand: {
                _id: "43487506-1a51-4463-b22b-a9b68ab16f47",
                store_id: "GLOBAL",
                name: "Axe",
                slug: "axe",
                image: null,
                count: null,
                parent_id: null,
                created_at: 1671265649958,
                date_modified: null,
            },
            category: {
                _id: "76cf002d-15c8-46ba-94d5-cdbfd338e82e",
                store_id: "GLOBAL",
                name: "Shaving Essentials",
                slug: "shaving-essentials",
                image: "https://content.homedrop.in/categories/shaving-essentials.png",
                count: null,
                parent_id: "654234c4-59f5-44dd-89fd-291b67ca62c2",
                created_at: 1676975684595,
                date_modified: null,
            },
            metrics: [
                {
                    unit: "ml",
                    value: "50",
                },
            ],
            type: {
                _id: 2,
                name: "Beauty & Hygiene",
                image: "https://media.homedrop.in/media/categories/type/banner-beauty.jpg",
                icon: "https://media.homedrop.in/media/categories/type/beauty-and-hygine.svg",
            },
        },
    ],
    total_quantity: 33,
    total_quantity: 33,
    total_price: 6421,
    total_tax: 958.97998046875,
    total_sale_price: 5591.5,
    tax_invoice: null,
    cart_manual_discount: 111.83000183105469,
    cart_auto_discount: null,
    cart_total_discount: 641.3300170898438,
    total_savings: 0,
    net_purchase_price: 4352.66015625,
    net_profit: 2186.010009765625,
    net_amount: 5479.669921875,
    return_id: null,
    remarks: null,
    payment: {
        is_paid: false,
        due_date: null,
        amount_due: 5479,
        total_amount: 5479,
        payment_mode: "PAY_LATER",
        account: null,
        remarks: null,
    },
    sub_total: 4632.52001953125,
    tax: 958.97998046875,
    total_profit: 2186.010009765625,
    cart_discount: 111.83000183105469,
    bill_total: 5591.5,
    cart_total: 5479.67,
    discount: 529.5,
};

const SAMPLE_PRODUCT = {
    _id: "46e1a49f-1f52-4449-b1a2-01b20b154ac7",
    store_id: "60d35fa9-4982-4009-8950-96483271edef",
    sku: "3JZK6K2YT6",
    upc: "8906060154023",
    is_global_product: true,
    name: " Agarbatti 55G",
    slug: "agarbatti-55g",
    created_at: 1671265650023,
    date_modified: 1678104220703,
    category: null,
    brand: null,
    type: {
        _id: 1,
        name: "Grocery",
        image: "https://media.homedrop.in/media/categories/type/banner-grocery.jpg",
        icon: "https://media.homedrop.in/media/categories/type/grocery.svg",
    },
    tags: [],
    gallery: ["https://content.homedrop.in/products/8906060154023.png"],
    status: "PUBLISH",
    tax: 5,
    short_description:
        "An incense stick made of a mixture of agarbatti and essential oils, this stick is a great way to add a unique scent to your home. It comes in a package of 10 and has a 55-gram weight.",
    min_quantity: 1,
    max_quantity: null,
    price: 100,
    sale_price: 80,
    purchase_price: 50,
    stock: -1,
    stock: -1,
    sale_online: true,
    metrics: [
        {
            unit: "gm",
            value: "55",
        },
    ],
    attributes: {
        "Color": "Red",
        "Size": "Large"
    },
    age_restricted: null,
    discount: null,
};

// pdfjs.GlobalWorkerOptions.workerSrc = join(await window.electronAPI.getExtraResourcesPath(), `pdf-worker-min-${pdfjs.version}.js`);

const PrinterSettings = () => {
    const [activeTab, setActiveTab] = useState(PRINTERS.THERMAL);
    const [printers, setPrinters] = useState(null);
    const [thermalConfig, setThermalConfig] = useState(null);
    const [barcodeConfig, setBarcodeConfig] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [liveUrl, setLiveUrl] = useState(null);

    useEffect(() => {
        getThermalPrinterConfig();
        getBarcodePrinterConfig();
        handleGetPrinters();
    }, []);

    useEffect(() => {
        if (activeTab === PRINTERS.THERMAL) {
            if (thermalConfig) {
                thermalDelayedQuery(thermalConfig);
            }
        }
    }, [activeTab, thermalConfig]);
    useEffect(() => {
        if (activeTab === PRINTERS.BARCODE) {
            if (barcodeConfig) {
                barcodeDelayedQuery(barcodeConfig);
            }
        }
    }, [activeTab, barcodeConfig]);

    const handleGenerateThermal = (config) => {
        setLiveUrl(null);
        window.electronAPI
            .generateThermalPdf({
                sale: SAMPLE_SALE,
                config: config,
                collect_latest: true,
            })
            .then((res) => {
                if (res) {
                    setLiveUrl(res);
                }
            })
            .catch((err) => {
                //    
            });
    };
    const thermalDelayedQuery = useCallback(debounce(handleGenerateThermal, 600), []);

    const handleGenerateBarcode = (config) => {
        setLiveUrl(null);
        window.electronAPI
            .generateBarcodePdf({
                product: SAMPLE_PRODUCT,
                config: config,
                manufactured_date: Date.now(),
                expiry_date: Date.now(),
                collect_latest: true,
            })
            .then((res) => {
                if (res) {
                    setLiveUrl(res);
                }
            })
            .catch((err) => {
                //    
            });
    };

    const barcodeDelayedQuery = useCallback(debounce(handleGenerateBarcode, 600), []);

    const getThermalPrinterConfig = async () => {
        try {
            let config = await window.electronAPI.getThermalPrinterConfig();
            if (config && !isEmpty(config)) {
                setThermalConfig(config);
            }
        } catch (error) {
            captureException(error);
        }
    };
    const getBarcodePrinterConfig = async () => {
        try {
            let config = await window.electronAPI.getBarcodePrinterConfig();
            if (config && !isEmpty(config)) {
                setBarcodeConfig(config);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleGetPrinters = async () => {
        try {
            let allPrinters = await window.electronAPI.getPrinters();
            if (Array.isArray(allPrinters) && allPrinters.length > 0) {
                setPrinters(allPrinters);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleSubmit = async () => {
        if (activeTab === PRINTERS.THERMAL) {
            await handleThermalSubmit();
        } else if (activeTab === PRINTERS.BARCODE) {
            await handleBarcodeSubmit();
        }
    };

    const handleThermalSubmit = async () => {
        if (!thermalConfig.printer_name) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a printer",
                type: "error",
            });
            return;
        }
        let response = await window.electronAPI.setThermalPrinterConfig(thermalConfig);
        if (response?.code === "success") {
            handleSnackbarDetails({
                show: true,
                title: "Thermal printer settings saved.",
                type: "success",
            });
        } else {
            handleSnackbarDetails({
                show: true,
                title: response?.message,
                type: "error",
            });
        }
    };
    const handleBarcodeSubmit = async () => {
        if (!barcodeConfig.printer_name) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a printer",
                type: "error",
            });
            return;
        }
        let response = await window.electronAPI.setBarcodePrinterConfig(barcodeConfig);
        if (response?.code === "success") {
            handleSnackbarDetails({
                show: true,
                title: "Barcode printer settings saved.",
                type: "success",
            });
        } else {
            handleSnackbarDetails({
                show: true,
                title: response?.message,
                type: "error",
            });
        }
    };
    return (
        <S.Wrapper>
            <S.Tabs>
                <S.Tab active={activeTab === PRINTERS.THERMAL} onClick={() => setActiveTab(PRINTERS.THERMAL)}>
                    {PRINTERS.THERMAL}
                </S.Tab>
                <S.Tab active={activeTab === PRINTERS.BARCODE} onClick={() => setActiveTab(PRINTERS.BARCODE)}>
                    {PRINTERS.BARCODE}
                </S.Tab>
            </S.Tabs>
            <S.Body>
                <S.ConfigurationContainer>
                    {activeTab === PRINTERS.THERMAL && <Thermal printers={printers} config={thermalConfig} setConfig={setThermalConfig} />}
                    {activeTab === PRINTERS.BARCODE && <Barcode printers={printers} config={barcodeConfig} setConfig={setBarcodeConfig} />}
                </S.ConfigurationContainer>
                <S.PdfContainer>
                    {/* <S.Headings>Live Preview</S.Headings> */}
                    {liveUrl && (
                        <S.PdfView>
                            <Document file={liveUrl} style={{ border: "1px solid red" }}>
                                <Page pageNumber={1} width={400} />
                            </Document>
                        </S.PdfView>
                    )}
                    <S.BottomBar>
                        <S.SaveBtn onClick={handleSubmit}>Save Changes</S.SaveBtn>
                    </S.BottomBar>
                </S.PdfContainer>
            </S.Body>
        </S.Wrapper>
    );
};

export default PrinterSettings;
