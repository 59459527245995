import React, { useContext, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import * as S from "./add-new-customer-modal.styles";
import { ArrowRight, CloseModalIcon } from "../../icons";
import {
  addCustomer,
  getCustomer,
  getCustomerTransactionCount,
  updateCustomer,
} from "../../api/local/customerApi";
import "./style.css";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCustomer } from "../../store/customer-slice";
import { setSearchSaleParams } from "../../store/sale-slice";
import {
  checkValidGstAfterChange,
  isValidGst,
  validAddress,
  validEmail,
  validGstNumber,
} from "../../utils/validity-fns";
import Transactions from "./../customers/customer-transactions/Transactions";
import { isEmpty } from "lodash";
import { fetchAllCustomers } from "./../../store/customer-slice";
import { captureException } from "../../crash-reporting";
import { FEATURES } from "../../constants/POS-plans";
import CustomerAddress from "../customers/customer-address/CustomerAddress";
import { SALE_TABS } from "../../constants/appConstants";
import InputField from "../input-field/InputField";
import { handleGetGstInfo } from "../../utils/api-fns";
import PartyPayments from "../party-payments/PartyPayments";

export const TABS = {
  INFORMATION: "Information",
  ADDRESS: "Address",
  TRANSACTION: "Transaction",
  PAYMENT: "Payment",
};

export const InitialAddress = {
  line_1: "",
  line_2: "",
  pin_code: "",
  city: "",
  state: "",
  country: "Philippines",
};

const InitialCustomerData = {
  name: "",
  phone: "",
  email: "",
  remarks: "",
  gst_number: "",
};

const initialErrorInput = {
  gst_number: false,
};

const AddNewCustomerModal = ({
  id,
  open,
  onClose,
  phoneNumber,
  clear,
  txn,
}) => {
  const [countryCode, setCountryCode] = useState("+63");
  const [customerData, setCustomerData] = useState(InitialCustomerData);
  const [activeTab, setActiveTab] = useState(TABS.INFORMATION);
  const { searchParams } = useSelector((state) => state.customer);
  const [txnCount, setTxnCount] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [shippingAddress, setShippingAddress] = useState(InitialAddress);
  const [billingAddress, setBillingAddress] = useState(InitialAddress);

  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const { features } = useSelector((state) => state.app);
  const [errorInput, setErrorInput] = useState(initialErrorInput);
  const { appOnline } = useSelector((state) => state.app);
  const { gstCache } = useSelector((state) => state.apiCache);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id != null) {
      getCustomerData();
      getTransactionCount();
      // dispatch(
      //     setSearchSaleParams({
      //         patient_id: phoneNumber,
      //         status: "PUBLISH",
      //         type: `${SALE_TABS.SALE.toUpperCase()},${SALE_TABS.RETURNS.toUpperCase()}`,
      //     })
      // );
      if (txn) {
        setActiveTab(TABS.TRANSACTION);
      } else {
        setActiveTab(TABS.INFORMATION);
      }
    } else {
      setActiveTab(TABS.INFORMATION);
    }

    return () => {
      // dispatch(removeAllSearchSaleParams());
    };
  }, [open]);

  async function getCustomerData() {
    try {
      let res = await getCustomer(id);
      if (res.status === 200) {
        appendData(res.data.entity);
      } else if (res.status !== 404) {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }
  async function getTransactionCount() {
    try {
      let res = await getCustomerTransactionCount(phoneNumber);
      if (res.status === 200) {
        setTxnCount(res.data);
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }

  function appendData(data) {
    setCustomerData({
      name: data.name ? data.name : "",
      phone: data.phone ? data.phone : "",
      email: data.email ? data.email : "",
      remarks: data.remarks ? data.remarks : "",
      gst_number: data.gst_number ? data.gst_number : "",
    });
    if (data.loyalty_point) setLoyaltyPoints(data.loyalty_point);
    if (data.address) {
      setShippingAddress(data.address);
      setBillingAddress(data.address);
    } else {
      if (data.shipping_address) setShippingAddress(data.shipping_address);
      if (data.billing_address) setBillingAddress(data.billing_address);
    }
  }
  const error = (msg) =>
    handleSnackbarDetails({
      show: true,
      type: "error",
      title: msg,
    });

  const handleInputChange = async (event) => {
    let { name, value } = event.target;
    let gstData = null;
    if (name === "phone") {
      const regex = /^[0-9\b]+$/;
      if (!(value === "" || regex.test(value))) return;
      if (value.length > 10) return;
    } else if (name === "gst_number") {
      if (value.length > 15) return;
      value = value.toUpperCase();
      let valid = checkValidGstAfterChange(value);
      setErrorInput({ ...errorInput, [name]: !valid });
      if (value.length === 15) {
        setCustomerData({ ...customerData, [name]: value });
        gstData = await handleGetGstInfo(value, appOnline, gstCache, dispatch);
      }
    }

    if (gstData && name === "gst_number") {
      let { company_name, address } = gstData;
      setCustomerData({ ...customerData, [name]: value, name: company_name });
      setBillingAddress(address);
      setShippingAddress(address);
    } else {
      setCustomerData({ ...customerData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    let { phone, name, email, remarks, gst_number } = customerData;
    if (phone?.length !== 10) {
      error("Invalid Phone Number");
      return;
    }
    if (name?.length === 0 || name?.length > 50) {
      error("Invalid Customer Name");
      return;
    }
    if (email?.length > 0 && !validEmail(email)) {
      error("Invalid email id");
      return;
    }
    if (gst_number?.length > 0 && !isValidGst(gst_number)) {
      error("Invalid TAX Number");
      return;
    }

    if (!validAddress(shippingAddress)) {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter full shipping address",
      });
      return;
    }
    if (!validAddress(billingAddress)) {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter full billing address",
      });
      return;
    }

    const payload = {
      name: name,
      phone: phone,
      country_code: "+63",
    };
    if (email) payload.email = email;
    if (gst_number) payload.gst_number = gst_number;
    if (remarks) payload.remarks = remarks;
    const shipping_address = {};
    if (shippingAddress.line_1) {
      let { line_1, line_2, pin_code, city, state, country } = shippingAddress;

      if (line_1) shipping_address.line_1 = line_1;
      if (line_2) shipping_address.line_2 = line_2;
      if (pin_code) shipping_address.pin_code = pin_code;
      if (city) shipping_address.city = city;
      if (state) shipping_address.state = state;
      if (country) shipping_address.country = country;
    }
    if (!isEmpty(shipping_address)) {
      payload.shipping_address = shipping_address;
    }

    const billing_address = {};
    if (billingAddress.line_1) {
      let { line_1, line_2, pin_code, city, state, country } = billingAddress;

      if (line_1) billing_address.line_1 = line_1;
      if (line_2) billing_address.line_2 = line_2;
      if (pin_code) billing_address.pin_code = pin_code;
      if (city) billing_address.city = city;
      if (state) billing_address.state = state;
      if (country) billing_address.country = country;
    }
    if (!isEmpty(billing_address)) {
      payload.billing_address = billing_address;
    }

    try {
      let res;
      if (id) {
        res = await updateCustomer(id, payload);
      } else {
        res = await addCustomer(payload);
      }

      if (res.status === 200) {
        if (!phoneNumber) {
          dispatch(setSelectedCustomer(res.data));
        }
        handleClose();
        handleSnackbarDetails({
          show: true,
          type: "success",
          title: `${phoneNumber ? "Updated" : "Added"} succesfully`,
          subtitle: `Customer has been ${
            phoneNumber ? "updated" : "added"
          } successfully.`,
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (e) {
      captureException(e);
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: e.response?.data?.message,
        subtitle: "Customer add failed.",
      });
    }
  };

  function handleClose() {
    setCustomerData(InitialCustomerData);
    setShippingAddress(InitialAddress);
    setBillingAddress(InitialAddress);
    setLoyaltyPoints(0);
    setErrorInput(initialErrorInput);
    onClose();
    dispatch(fetchAllCustomers(searchParams));
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.Container>
        <S.HeaderContainer>
          <S.HeaderText>
            {phoneNumber ? "Update Customer" : "Add new customer"}
          </S.HeaderText>
          <img onClick={handleClose} src={CloseModalIcon} alt="close" />
        </S.HeaderContainer>
        <S.TabContainer>
          <S.Tab
            active={activeTab === TABS.INFORMATION}
            onClick={() => setActiveTab(TABS.INFORMATION)}
          >
            Customer Information
          </S.Tab>
          <S.Tab
            active={activeTab === TABS.ADDRESS}
            onClick={() => setActiveTab(TABS.ADDRESS)}
          >
            Address
          </S.Tab>
          {phoneNumber && (
            <>
              <S.Tab
                active={activeTab === TABS.TRANSACTION}
                onClick={() => setActiveTab(TABS.TRANSACTION)}
              >
                Transactions
              </S.Tab>
              <S.Tab
                active={activeTab === TABS.PAYMENT}
                onClick={() => setActiveTab(TABS.PAYMENT)}
              >
                Payments
              </S.Tab>
            </>
          )}
        </S.TabContainer>
        {activeTab === TABS.INFORMATION && (
          <S.Body>
            <S.BodyTop>
              <S.FormContainer>
                <S.FormContainerHeader>
                  Contact Information
                </S.FormContainerHeader>
                <S.MultipleInputsContainer>
                  {/* <S.FormContainerInputCode
                                        type="text"
                                        value={countryCode}
                                        placeholder="Country Code"
                                        // onChange={(e) => setCountryCode(e.target.value)}
                                    /> */}

                  <InputField
                    label="Phone Number"
                    value={customerData.phone}
                    name="phone"
                    onChange={handleInputChange}
                    required={true}
                    readOnly={phoneNumber != null}
                    fullWidth={true}
                    prefix={<S.CountryCode>+63</S.CountryCode>}
                  />
                </S.MultipleInputsContainer>
                <InputField
                  label="Name"
                  value={customerData.name}
                  name="name"
                  onChange={handleInputChange}
                  required={true}
                />
                <InputField
                  label="Email Address"
                  value={customerData.email}
                  name="email"
                  onChange={handleInputChange}
                />
                <InputField
                  label="Add a Note"
                  value={customerData.remarks}
                  name="remarks"
                  onChange={handleInputChange}
                  multiline={true}
                  rows={4}
                />
              </S.FormContainer>
              <S.FormContainer>
                <S.FormContainerHeader>Other</S.FormContainerHeader>
                <InputField
                  label="TAX Number"
                  value={customerData.gst_number}
                  name="gst_number"
                  onChange={handleInputChange}
                  error={errorInput.gst_number}
                  helperText={errorInput.gst_number && "Invalid TAX Number"}
                />
                {phoneNumber !== null &&
                  features.includes(FEATURES.LOYALTY_POINT) && (
                    <InputField
                      label="Loyalty Points Redeemable"
                      value={loyaltyPoints}
                      readOnly={true}
                    />
                  )}
              </S.FormContainer>
            </S.BodyTop>

            <S.AddBtnContainer>
              <S.NextBtn onClick={() => setActiveTab(TABS.ADDRESS)}>
                Next
                <img src={ArrowRight} alt="right arrow" />
              </S.NextBtn>
              <S.AddBtn onClick={handleSubmit}>
                {phoneNumber ? "Update" : "Add"}
              </S.AddBtn>
            </S.AddBtnContainer>
          </S.Body>
        )}
        {activeTab === TABS.TRANSACTION && (
          <Transactions
            id={id}
            type="customer"
            txnCount={txnCount}
            phoneNumber={phoneNumber}
            onClose={onClose}
            clear={clear}
          />
        )}
        {activeTab === TABS.PAYMENT && (
          <PartyPayments type="customer" id={phoneNumber} txnCount={txnCount} />
        )}
        {activeTab === TABS.ADDRESS && (
          <CustomerAddress
            phoneNumber={phoneNumber}
            setActiveTab={setActiveTab}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            handleSubmit={handleSubmit}
          />
        )}
      </S.Container>
    </Modal>
  );
};

export default AddNewCustomerModal;
