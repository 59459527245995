import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;

export const Head = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #263238;
`;

export const SubHead = styled.div`
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
`;

export const GreyText = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #64748b;
`;
export const Label = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #64748b;
    cursor: pointer;
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const Body = styled.div`
    width: 460px;
    height: calc(100vh - 312px);
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 100px;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 256px;
`;

export const DiscountBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const PriorityContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const PreviewContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    width: ${(props) => (props.width ? props.width : "100%")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e2e8f0;
    margin-top: -10px;
`;

export const DarkText = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    color: #263238;
`;
export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 2px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 2px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 2px;
`;
export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
export const CartDiscountChoosers = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & input {
        width: 72px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        padding: 10px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 13px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;

export const QuantityBox = styled.div`
    text-align: center;
    height: 40px;
    margin-top: 14px;
    & > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000;
        border-radius: 3px;
        width: 52px;
        margin: auto;
        background-color: #fff;
    }

    & input {
        width: 100%;
        border: none;
        padding: 3px;
        text-align: center;

        :focus {
            outline: none;
            border: none;
        }
    }
`;

export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid #000;
    background-color: #fff;
    border-radius: 0 3px 3px 0;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;

export const ItemDiscount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;
export const InputDiv = styled.div`
    width: 40px;
    height: 28px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    & > input {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #334155;
        border: none;
        outline: none;
    }
`;
