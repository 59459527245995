import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "../../../icons";
import { fetchAllPermissions } from "../../../store/permissionrole-slice";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import CustomCheckBox from "../custom-checkbox/CustomCheckBox";
import * as S from "./style";

const values = ["CREATE", "READ", "UPDATE", "DELETE"];
const values2 = ["READ", "UPDATE"];
const values3 = ["CREATE", "READ"];
const Permission = ({ displayData, setDisplayData, setTabView, handleSubmit }) => {
    const dispatch = useDispatch();
    const { roles } = useSelector((state) => state.permissionRole);

    const [role, setRole] = useState(displayData.permission_role);
    useEffect(() => {
        dispatch(fetchAllPermissions());
    }, []);

    function handleSelectChange(key, item) {
        setDisplayData({ ...displayData, permission_role: item.name });
        setRole(item);
        // console.log(item)
    }

    return (
        <S.Wrapper>
            <div>
                <S.Header>
                    <S.Heading>
                        permission role <span>*</span>
                    </S.Heading>
                    <CustomSelect
                        type="role"
                        disableSearch
                        initalText="Permission Role"
                        data={roles || []}
                        onSelectChange={handleSelectChange}
                    />
                    {/* TODO */}
                    {/* <CustomInput placeholder="Enter a new role name" type="text" />   */}
                </S.Header>
                <S.Copywrite>
                    Current scope allows to do any change in sales as well as purchases. Current
                    scope allows to do any change in sales as well as purchases. Current scope
                    allows to do any change in sales as well as purchases.
                </S.Copywrite>
                <S.CheckBoxDiv>
                    <div>
                        <CustomCheckBox
                            heading={"Sale"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.sale}
                        />
                        <CustomCheckBox
                            heading={"Purchase"}
                            subHeading={"Purchase from a supplier"}
                            values={values}
                            role={role?.purchase}
                        />
                        <CustomCheckBox
                            heading={"Supplier"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.supplier}
                        />
                        <CustomCheckBox
                            heading={"Product"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.product}
                        />
                    </div>
                    <div>
                        <CustomCheckBox
                            heading={"Employee"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.employee}
                        />
                        <CustomCheckBox
                            heading={"Customer"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.patient}
                        />
                        <CustomCheckBox
                            heading={"Discount"}
                            subHeading={"Sale products to a customer"}
                            values={values}
                            role={role?.discount}
                        />
                        <CustomCheckBox
                            heading={"Settings"}
                            subHeading={"Sale products to a customer"}
                            values={values2}
                            role={role?.settings}
                        />
                    </div>
                    <div>
                        <CustomCheckBox
                            heading={"Marketing"}
                            subHeading={"Sale products to a customer"}
                            values={values3}
                            role={role?.marketing}
                        />
                    </div>
                </S.CheckBoxDiv>
            </div>

            <S.Footer>
                <S.SaveBtn onClick={handleSubmit}>Save</S.SaveBtn>
                <S.NextBtn onClick={() => setTabView(3)}>
                    <div>Next</div>
                    <img src={ArrowRight} alt="" />
                </S.NextBtn>
            </S.Footer>
        </S.Wrapper>
    );
};

export default Permission;
