import { Drawer } from "@mui/material";
import React, { useState } from "react";
import ConnectionOverLay from "../../ConnectionOverlay/ConnectionOverLay";
import * as S from "./style";
import "./ConnectedChip.scss";
import { useSelector } from "react-redux";
import { ConnectedIcon, NotConnectedIcon } from "../../../icons";

const ConnectedChip = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const { appOnline } = useSelector((state) => state.app);
    return (
        <>
            {appOnline ? (
                <S.Wrapper online={appOnline}>
                    <img src={ConnectedIcon} alt="connection icon" />
                    <S.Status online={appOnline}>Connected</S.Status>
                </S.Wrapper>
            ) : (
                <S.Wrapper online={appOnline}>
                    <img src={NotConnectedIcon} alt="connection icon" />
                    <S.Status online={appOnline}>Disconnected</S.Status>
                </S.Wrapper>
            )}
            <Drawer anchor="right" hideBackdrop="true" open={openDrawer} onClose={() => setOpenDrawer(false)} className="right-drawer">
                <ConnectionOverLay setOpenDrawer={setOpenDrawer} />
            </Drawer>
        </>
    );
};

export default ConnectedChip;
