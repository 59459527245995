import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 186px);
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Label = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 10px;
    color: #64748b;
`;

export const TimeContainerFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 17px 0 24px;
`;

export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 1.5px;
    outline: none;
    text-align: center;
    width: 58px;
    height: 34px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const InputLabel = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #475569;

    & span {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #94a3b8;
    }
`;
export const MapContainer = styled.div`
    margin: 16px 0 8px;
    width: 100%;
    height: calc(100vh - 316px);
`;

export const HintText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #94a3b8;
`;

export const SaveBtn = styled.div`
    background: #2d68fe;
    border-radius: 2px;
    padding: 10px 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TopContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;
