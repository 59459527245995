import { GST_API } from "../../constants/apiRoutes";
import { captureException } from "../../crash-reporting";
import axios from "./axios";

const getGstInfoServer = async (gst_id) => {
    try {
        let params = { gst_id: gst_id };
        let response = await axios.get(GST_API, {
            params: params,
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.data?.message ?? "Some error occurred");
        }
    } catch (error) {
        captureException(error);
        return error;
    }
};

export { getGstInfoServer };
