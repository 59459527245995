import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FEATURES_KEY } from "../constants/POS-plans";

const initialState = {
  autoLoginModalStatus: false,
  confirmationModal: false,
  loader: false,
  onboardingData: null,
  appOnline: false,
  posPlan: null, //TODO: REMOVE
  currentPlan: null,
  features: [
    "sales",
    "sales_return",
    "purchase",
    "purchase_return",
    "damage",
    "suppliers",
    "customers",
    "inventory",
    "expenses",
    "staff",
    "analytics",
    "download_reports",
    "sale_online",
    "loyalty_point",
    "settings",
    "logout",
    "help_center",
  ],
  openBuyDialog: false,
  salePreference: null,
  loyaltyPreference: null,
  billTypePreference: null,
  printingPreference: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    openAutoLoginModal: (state) => {
      state.autoLoginModalStatus = true;
    },
    closeAutoLoginModal: (state) => {
      state.autoLoginModalStatus = false;
    },
    openConfirmationModal: (state) => {
      state.confirmationModal = true;
    },
    closeConfirmationModal: (state) => {
      state.confirmationModal = false;
    },
    showLoader: (state) => {
      state.loader = true;
    },
    hideLoader: (state) => {
      state.loader = false;
    },
    setOnboardingData: (state, action) => {
      state.onboardingData = action.payload;
    },
    setAppOnline: (state, action) => {
      state.appOnline = action.payload;
    },
    setPOSPlan: (state, action) => {
      state.posPlan = action.payload;
    },
    setPOSFeatures: (state, action) => {
      state.features = [...action.payload, ...DEFAULT_FEATURES_KEY];
    },
    setOpenBuyDialog: (state, action) => {
      state.openBuyDialog = action.payload;
    },
    setSalePreference: (state, action) => {
      state.salePreference = action.payload;
    },
    setLoyaltyPreference: (state, action) => {
      state.loyaltyPreference = action.payload;
    },
    setBillTypePreference: (state, action) => {
      state.billTypePreference = action.payload;
    },
    setPrintingPreference: (state, action) => {
      state.printingPreference = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default appSlice.reducer;
export const {
  openAutoLoginModal,
  closeAutoLoginModal,
  openConfirmationModal,
  closeConfirmationModal,
  showLoader,
  hideLoader,
  setOnboardingData,
  setAppOnline,
  setPOSPlan,
  setPOSFeatures,
  setOpenBuyDialog,
  setSalePreference,
  setLoyaltyPreference,
  setPrintingPreference,
  setBillTypePreference,
} = appSlice.actions;
