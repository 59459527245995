import React, { useCallback, useState } from "react";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CloseModalIcon } from "../../../icons";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import SupplierList from "./supplier-list/supplier-list";
import { fetchAllSuppliers } from "../../../store/supplier-slice";

import * as S from "./add-supplier-modal.styles";
import { debounce } from "lodash";
import { useEffect } from "react";

const AddSupplierModal = ({ open, onClose, openAddNewModal, onSelect }) => {
    const [search, setSearch] = useState("");
    const [supplierList, setSupplierList] = useState(null);
    const { suppliers, loading } = useSelector((state) => state.supplier);
    const dispatch = useDispatch();
    useEffect(() => {
        if (search) {
            setSupplierList(suppliers);
        } else {
            setSupplierList(null);
        }
    }, [suppliers]);

    const delayedFunction = (val) => {
        dispatch(fetchAllSuppliers({ search: val }));
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    const handleInputChange = (val) => {
        setSearch(val);
        delayedQuery(val);
    };

    const handleAddNewSupplier = () => {
        setSearch("");
        openAddNewModal();
    };

    const handleClearInput = () => {
        setSearch("");
        dispatch(fetchAllSuppliers());
    };

    const handleModalClose = () => {
        handleClearInput();
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    <S.HeaderText>Add Supplier</S.HeaderText>
                    <img onClick={onClose} src={CloseModalIcon} alt="close" />
                </S.Header>

                <S.Body>
                    <CustomInput
                        value={search}
                        onChange={handleInputChange}
                        type="text"
                        searchIcon
                        clearTextIcon
                        onClickClear={handleClearInput}
                        placeholder="Search supplier by Phone number or Name"
                        autoFocus
                    />
                    {search && supplierList && (
                        <SupplierList
                            onClose={handleModalClose}
                            data={supplierList}
                            onAddNewSupplier={handleAddNewSupplier}
                            open={search && supplierList}
                            onSelect={onSelect}
                        />
                    )}
                    <div className="middle-text">OR</div>
                    <S.AddBtn onClick={handleAddNewSupplier}>Add new supplier</S.AddBtn>
                </S.Body>
            </S.Container>
        </Modal>
    );
};

export default AddSupplierModal;
