import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPurchases } from "../api/local/purchase-api";
import { toast } from "react-toastify";
import { PURCHASE_TYPE, SCREENS as PURCHASE_SCREEN, PAGE_LIMIT, DiscountOption } from "../constants/appConstants";
import { captureException } from "../crash-reporting";
import {
    getDecimalPlacesCount,
    getPurchaseObjectHash,
    getPurchasePrice,
    getPurchaseProductSubTotal,
    getPurchaseQuantity,
    getTax,
    getTaxValue,
    getUnit,
    hasDecimal,
    toDecimalPlace,
} from "../utils/global-fn";

export const SCREENS = {
    PURCHASE_TABLE: 0,
    PURCHASE_SUMMARY: 1,
};

const initialState = {
    purchaseProducts: [],
    purchaseQuantity: 0,
    purchaseTotal: 0,
    purchaseCartTotal: 0,
    purchaseTaxTotal: 0,
    discountValue: 0,
    selectedSupplier: null,
    billNo: "",
    createdDate: null,
    deliveryDate: null,
    deliveryExpense: "",
    selectDiscount: {
        type: DiscountOption.PERCENTAGE,
        cost: 0,
    },
    globalDiscount: 0,

    loading: false,
    purchases: [],
    error: "",
    searchParams: { limit: PAGE_LIMIT },
    currentScreen: SCREENS.PURCHASE_SUMMARY,
    newSelectedSupplier: null,
    purchaseCountData: null,
    selectedPurchase: null,
    selectedDraftPurchase: null,
    selectedHeaderTab: PURCHASE_TYPE.PURCHASE,
    selectedTab: "All",
    currentPurchaseScreen: PURCHASE_SCREEN.PURCHASE,
    currentFocus: 0,
};

// Generates pending, fulfilled and rejected action types
export const fetchAllPurchases = createAsyncThunk("purchase/fetchAllPurchases", (queryParams) =>
    getAllPurchases(queryParams)
        .then((res) => (res.status === 200 ? res.data : []))
        .catch((err) => captureException(err))
);

const purchaseSlice = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        addProductsToPurchase: (state, action) => {
            state.purchaseProducts = action.payload;
            state.currentFocus = 0;
        },
        addProductToPurchase: (state, action) => {
            const itemIndex = state.purchaseProducts.findIndex((item) => item.sku === action.payload.sku);
            if (itemIndex >= 0) {
                state.purchaseProducts[itemIndex].quantity = parseFloat(state.purchaseProducts[itemIndex].quantity) + 1;
                state.currentFocus = itemIndex;
            } else {
                state.purchaseProducts.push({
                    ...action.payload,
                    sale_price: toDecimalPlace(action.payload.sale_price),
                    purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                    quantity: action.payload.quantity ? action.payload.quantity : 1,
                    discount: action.payload.discount ? action.payload.discount : 0,
                    discountValue: action.payload.discountValue ? action.payload.discountValue : 0,
                    activeDiscount: action.payload.activeDiscount ? action.payload.activeDiscount : DiscountOption.FLAT,
                    unit: getUnit(action.payload),
                    manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                    expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                    checkProduct: {
                        ...action.payload,
                        sale_price: toDecimalPlace(action.payload.sale_price),
                        purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                        hashKey: getPurchaseObjectHash(action.payload),
                    },
                });
                state.currentFocus = state.purchaseProducts.length - 1;
            }
        },
        updateProductFromPurchase: (state, action) => {
            const newPurchaseProducts = state.purchaseProducts.map((item) => {
                if (item.sku === action.payload.sku) {
                    if (action.payload.checkProduct) {
                        // for general slice product update
                        return { ...item, ...action.payload };
                    } else {
                        // update slice product after fetched from db
                        let newHash = getPurchaseObjectHash(action.payload);
                        if (item.checkProduct?.hashKey === newHash) {
                            let updatedCheckProduct = { ...item.checkProduct, stock: action.payload.stock };
                            return { ...item, name: action.payload.name, checkProduct: updatedCheckProduct };
                        } else {
                            let updateCheckProduct = {
                                ...action.payload,
                                sale_price: toDecimalPlace(action.payload.sale_price),
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                hashKey: newHash,
                            };
                            return {
                                ...item,
                                ...action.payload,
                                sale_price: toDecimalPlace(action.payload.sale_price),
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                unit: getUnit(action.payload),
                                discountValue: 0,
                                discount: 0,
                                activeDiscount: DiscountOption.FLAT,
                                manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                                expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                                checkProduct: updateCheckProduct,
                            };
                        }
                    }
                } else {
                    return item;
                }
            });
            state.purchaseProducts = newPurchaseProducts;
        },
        replaceProductFromPurchase: (state, action) => {
            for (let i = 0; i < state.purchaseProducts.length; i++) {
                if (state.purchaseProducts[i].sku === action.payload.sku) {
                    state.purchaseProducts[i].sku = action.payload.newSku;
                    break;
                }
            }
        },
        removeProductFromPurchase: (state, action) => {
            const idx = state.purchaseProducts.findIndex((item) => item.sku === action.payload.sku);
            if (idx >= 0) {
                const newPurchaseProducts = state.purchaseProducts.filter((item) => item.sku !== action.payload.sku);
                state.purchaseProducts = newPurchaseProducts;
                if (idx >= newPurchaseProducts.length - 1) {
                    state.currentFocus = newPurchaseProducts.length - 1;
                }
            }

            state.selectDiscount = {
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            };
        },
        decrementQuantity: (state, action) => {
            const itemIndex = state.purchaseProducts.findIndex((item) => item.sku === action.payload.sku);
            if (state.purchaseProducts[itemIndex].quantity > 1) {
                let product = state.purchaseProducts[itemIndex];
                let qty = parseFloat(product.quantity) - 1;

                if (hasDecimal(qty)) {
                    let ct = getDecimalPlacesCount(qty);
                    if (ct > 1) {
                        ct = 2;
                    }
                    qty = qty.toFixed(ct);
                }
                state.purchaseProducts[itemIndex].quantity = parseFloat(qty);
            } else if (state.purchaseProducts[itemIndex].quantity === 1) {
                toast.error("Minimum quantity reached", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        },
        getPurchaseTotals: (state, action) => {
            let { total, quantity, tax, discountValue } = state.purchaseProducts.reduce(
                (purchaseTotal, purchaseItem) => {
                    const { purchase_price, quantity, discountValue, purchase_price_with_tax } = purchaseItem;

                    let tax = getTax(purchaseItem);
                    let qty = parseFloat(quantity);
                    let purchasePrice = parseFloat(purchase_price);
                    // let itemTotal = getPurchasePrice(purchaseItem, false);

                    let { itemTotal, taxTotal } = getPurchaseProductSubTotal(purchaseItem);
                    let itemTaxTotal = 0;
                    if (qty > 0) {
                        // taxTotal = (((purchasePrice - discountValue / qty) * tax) / 100) * (qty || 0);
                        // let value = purchasePrice * qty - discountValue;
                        // itemTaxTotal = getTaxValue(value, tax, purchase_price_with_tax);
                        itemTaxTotal = taxTotal;
                    }
                    purchaseTotal.total += itemTotal;
                    purchaseTotal.quantity += qty;
                    purchaseTotal.tax += itemTaxTotal;
                    purchaseTotal.discountValue += discountValue;// * qty;

                    return purchaseTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                    tax: 0,
                    discountValue: 0,
                }
            );
            total = parseFloat(total);
            state.purchaseQuantity = quantity;
            state.purchaseTotal = total;
            state.purchaseTaxTotal = tax;
            state.discountValue = discountValue;

            let globalDisc =
                state.selectDiscount.type === DiscountOption.PERCENTAGE
                    ? total * (parseFloat(state.selectDiscount.cost || 0) / 100)
                    : parseFloat(state.selectDiscount.cost || 0);

            state.globalDiscount = toDecimalPlace(globalDisc);
            state.purchaseCartTotal = toDecimalPlace(total - globalDisc + +state.deliveryExpense);
        },
        removeAllPurchaseProducts: (state) => {
            state.purchaseProducts = [];
        },

        setBillNo: (state, action) => {
            state.billNo = action.payload;
        },
        setCreatedDate: (state, action) => {
            state.createdDate = action.payload;
        },
        setDeliveryDate: (state, action) => {
            state.deliveryDate = action.payload;
        },
        setDeliveryExpense: (state, action) => {
            state.deliveryExpense = action.payload;
        },
        setSelectDiscount: (state, action) => {
            state.selectDiscount = action.payload;
        },

        //for api handles
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },

        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = { limit: PAGE_LIMIT };
        },

        setCurrentScreen: (state, action) => {
            state.currentScreen = action.payload;
        },

        setSelectedPurchase: (state, action) => {
            state.selectedPurchase = action.payload;
        },

        removeSelectedPurchase: (state) => {
            state.selectedPurchase = null;
        },

        setSelectedDraftPurchase: (state, action) => {
            state.selectedDraftPurchase = action.payload;
        },

        removeSelectedDraftPurchase: (state) => {
            state.selectedDraftPurchase = null;
        },

        setSelectedSupplier: (state, action) => {
            state.selectedSupplier = action.payload;
        },

        removeSelectedSupplier: (state) => {
            state.selectedSupplier = null;
        },
        setNewSelectedSupplier: (state, action) => {
            state.newSelectedSupplier = action.payload;
        },

        removeNewSelectedSupplier: (state) => {
            state.newSelectedSupplier = null;
        },

        setPurchaseCountData: (state, action) => {
            state.purchaseCountData = action.payload;
        },

        setSelectedHeaderTab: (state, action) => {
            state.selectedHeaderTab = action.payload;
        },

        resetSelectedHeaderTab: (state, action) => {
            state.selectedHeaderTab = PURCHASE_TYPE.PURCHASE;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },

        resetSelectedTab: (state, action) => {
            state.selectedTab = "All";
        },
        setCurrentPurchaseScreen: (state, action) => {
            state.currentPurchaseScreen = action.payload;
        },
        resetCurrentPurchaseScreen: (state, action) => {
            state.currentPurchaseScreen = PURCHASE_SCREEN.PURCHASE;
        },
        removePurchases: (state, action) => {
            state.purchases = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllPurchases.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllPurchases.fulfilled, (state, action) => {
            state.loading = false;
            state.purchases = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllPurchases.rejected, (state, action) => {
            state.loading = false;
            state.purchases = [];
            state.error = action.error.message;
        });
        builder.addCase("LOGOUT", (state) => {
            Object.assign(state, initialState);
        });
    },
});

export default purchaseSlice.reducer;
export const {
    setSearchParams,
    removeSearchParams,
    removeAllSearchParams,
    setCurrentScreen,
    setSelectedSupplier,
    removeSelectedSupplier,
    setPurchaseCountData,
    setSelectedPurchase,
    removeSelectedPurchase,
    addProductToPurchase,
    removeProductFromPurchase,
    decrementQuantity,
    getPurchaseTotals,
    removeAllPurchaseProducts,
    setNewSelectedSupplier,
    removeNewSelectedSupplier,
    updateProductFromPurchase,
    setSelectedDraftPurchase,
    removeSelectedDraftPurchase,
    replaceProductFromPurchase,
    setSelectedHeaderTab,
    resetSelectedHeaderTab,
    setSelectedTab,
    resetSelectedTab,
    setCurrentPurchaseScreen,
    removePurchases,
    resetCurrentPurchaseScreen,
    setBillNo,
    setCreatedDate,
    setDeliveryDate,
    setDeliveryExpense,
    setSelectDiscount,
    addProductsToPurchase,
} = purchaseSlice.actions;
