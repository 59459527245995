import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, InfoWindow, Marker, Autocomplete, LoadScript } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { MapPin } from "../../../icons";
import MapStyle from "../../../assets/json/map_day.json";
import * as S from "./style";
import { isEqual } from "lodash";
import { DEFAULT_MAP_POSITION } from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
Geocode.enableDebug();
Geocode.setRegion("in");

const containerStyle = {
    width: "100%",
    height: "100%",
    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
};

const Map = ({ displayData, setDisplayData }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const [mapData, setMapData] = React.useState(null);

    const onLoad = React.useCallback(function callback(mapData) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(displayData.center);
        // mapData.fitBounds(bounds);
        mapData.setZoom(16);
        setMapData(mapData);
    }, []);

    const onUnmount = React.useCallback(function callback(mapData) {
        setMapData(null);
    }, []);

    const onInfoWindowClose = (event) => {};

    const onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = getCity(addressArray),
                    area = getArea(addressArray),
                    state = getState(addressArray),
                    pin_code = getPincode(addressArray);
                setDisplayData({
                    address: address ? address : "",
                    line_2: area ? area : "",
                    pin_code: pin_code ? pin_code : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    markerPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const getCity = (addressArray) => {
        let city = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && "administrative_area_level_3" === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    const getArea = (addressArray) => {
        let area = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ("sublocality_level_1" === addressArray[i].types[j] || "locality" === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };

    const getState = (addressArray) => {
        let state = "";
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && "administrative_area_level_1" === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    const getPincode = (addressArray) => {
        let pin_code = "";
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
                pin_code = addressArray[i].long_name;
                return pin_code;
            }
        }
    };

    useEffect(() => {
        if (displayData?.mapPosition && !isEqual(displayData?.mapPosition, DEFAULT_MAP_POSITION)) {
            Geocode.fromLatLng(displayData?.mapPosition?.lat, displayData?.mapPosition?.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address,
                        addressArray = response.results[0].address_components,
                        city = getCity(addressArray),
                        area = getArea(addressArray),
                        pin_code = getPincode(addressArray),
                        state = getState(addressArray);

                    // console.log("city", city, area, state);

                    setDisplayData((prevState) => ({
                        ...prevState,
                        address: address ? address : "",
                        line_2: area ? area : "",
                        area: area ? area : "",
                        pin_code: pin_code ? pin_code : "",
                        city: city ? city : "",
                        state: state ? state : "",
                    }));
                },
                (error) => {
                    captureException(error);
                }
            );
        }
    }, []);

    const handleDragEnd = () => {
        if (!mapData) return;
        const newPos = mapData.getCenter().toJSON();

        let newLat = newPos.lat,
            newLng = newPos.lng;

        Geocode.fromLatLng(newLat, newLng).then(
            (response) => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = getCity(addressArray),
                    area = getArea(addressArray),
                    pin_code = getPincode(addressArray),
                    state = getState(addressArray);

                setDisplayData({
                    line_1: "",
                    address: address ? address : "",
                    line_2: area ? area : "",
                    city: city ? city : "",
                    pin_code: pin_code ? pin_code : "",
                    state: state ? state : "",
                    markerPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng,
                    },
                });
            },
            (error) => {
                captureException(error);
            }
        );
    };

    const handleDrag = () => {
        if (!mapData) return;
        const newPos = mapData.getCenter().toJSON();

        let newLat = newPos.lat,
            newLng = newPos.lng;

        setDisplayData({
            ...displayData,
            markerPosition: {
                lat: newLat,
                lng: newLng,
            },
        });
    };

    return isLoaded ? (
        <S.Wrapper>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={displayData.mapPosition}
                zoom={16}
                onLoad={onLoad}
                onUnmount={onUnmount}
                // onClick={(e) => onMarkerDragEnd(e)}
                onDrag={handleDrag}
                onDragEnd={handleDragEnd}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                }}
                // extraMapTypes={MapStyle}
            >
                {/* Child components, such as markers, info windows, etc. */}
                {/* <InfoWindow
                    onClose={onInfoWindowClose}
                    position={{
                        lat: displayData.markerPosition.lat + 0.0018,
                        lng: displayData.markerPosition.lng,
                    }}
                >
                    <div>
                        <span style={{ padding: 0, margin: 0 }}>{displayData.address}</span>
                    </div>
                </InfoWindow> */}
                {/* <Marker
                    // google={props.google}
                    name={"Dolores park"}
                    draggable={false}
                    // onDragEnd={onMarkerDragEnd}
                    position={{
                        lat: displayData?.markerPosition?.lat,
                        lng: displayData?.markerPosition?.lng,
                    }}
                    icon={MapPin}
                />
                <Marker /> */}
                <></>
            </GoogleMap>
            <S.ImgContainer>
                <img src={MapPin} alt="pin icon" />
            </S.ImgContainer>
        </S.Wrapper>
    ) : (
        <></>
    );
};

export default React.memo(Map);
