import React from "react";

import * as S from "./custom-button.styles";

const CustomButton = ({ type = "primary", size = "md", text, onClick, disable, outline = false }) => {
    return (
        <S.Container onClick={onClick} type={type} size={size} disable={disable} outline={outline}>
            {text}
        </S.Container>
    );
};

export default CustomButton;
