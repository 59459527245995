import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    background: #ffde2c;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    ${(props) => props.curved && "border-radius: 0 0 20px 20px"};
`;
