import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 24px;
`;

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 10px;
`;

export const Tab = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    padding-bottom: 12px;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    border-bottom: 1px solid ${(props) => (props.active ? "#3B82F6" : "#fff")};
`;

export const Body = styled.div`
    height: calc(100vh - 277px);
    display: flex;
`;

export const ConfigurationContainer = styled.div`
    width: 298px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 20px;
`;

export const Headings = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #263238;

    & > span {
        color: #ff0000;
    }
`;

export const Label = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #64748b;

    & > span {
        color: #ff0000;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
`;
export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 6px;
    border-radius: 2px;
    border: 1px solid #e2e8f0;

    & > div {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #b4b4b4;
    }
`;

export const Input = styled.input`
    width: 90%;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #334155;
    border: none;
    outline: none;
`;

export const Hint = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #c8c8c8;
`;

export const Textarea = styled.textarea`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    height: ${(props) => props.height};
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    box-sizing: border-box;
    padding: 9px 14px;
    font-family: sans-serif;
    resize: none;
    outline: none;
    ::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #c8c8c8;
    }
`;

export const SettingsBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const SubBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const GridBody = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
`;
export const PdfContainer = styled.div`
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-left: 23px;
`;

export const PdfView = styled.div`
    width: 500px;
    height: 100%;
    margin: auto;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    display: flex;
    justify-content: center;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;
