import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 520px;
    height: 198px;
`;

export const Top = styled.div`
    box-sizing: border-box;
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Bottom = styled.div`
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    background: #f7f7f9;
    padding: 16px 40px;
    border-radius: 0 0 12px 12px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Heading = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: #232325;
`;
export const ClosedBtn = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const SubHeading = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #232325;
`;

export const CancelBtn = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #707090;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const DeleteBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d80027;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
`;
