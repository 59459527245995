import React from "react";
import moment from "moment";
import * as S from "./style";
import { ThreeDotsVerticalIcon } from "../../../icons";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { PAYMENT_MODE_SUMMARY } from "../../../constants/appConstants";

const Expense = ({ item, handleRowClick, idx, activeRow, scrollRef, setOpenDeleteModal }) => {
    const [open, setOpen] = useState(false);

    return (
        <S.TableRow key={idx} onClick={() => handleRowClick(item)} active={idx === activeRow} ref={idx === activeRow ? scrollRef : null}>
            <S.Td flexValue="0.7">{moment(item.created_at).format("lll")}</S.Td>
            <S.Td flexValue="0.6">{item.expense_type}</S.Td>
            <S.Td flexValue="0.5" center mr="80px">
                ₱{item.amount}
            </S.Td>
            <S.Td flexValue="0.7">{PAYMENT_MODE_SUMMARY[item.payment?.payment_mode]}</S.Td>
            <S.Td flexValue="0.7">{item.added_by?.name}</S.Td>
            <S.LastTd>
                <div>{item.description}</div>
                <OutsideClickHandler
                    onOutsideClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                    }}
                >
                    <img
                        src={ThreeDotsVerticalIcon}
                        alt=""
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                        }}
                    />
                    {open && (
                        <OptionsContainer
                            onClose={() => setOpen(false)}
                            item={item}
                            setOpenDeleteModal={setOpenDeleteModal}
                            onEditExpense={() => handleRowClick(item)}
                        />
                    )}
                </OutsideClickHandler>
            </S.LastTd>
        </S.TableRow>
    );
};

export default Expense;

const OptionsContainer = ({ item, setOpenDeleteModal, onClose, onEditExpense }) => {
    return (
        <S.OptionsContainer>
            <S.Option
                onClick={(e) => {
                    e.stopPropagation();
                    onEditExpense();
                }}
            >
                Edit
            </S.Option>
            <S.Option
                onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeleteModal({ show: true, data: item });
                    onClose();
                }}
            >
                Delete
            </S.Option>
        </S.OptionsContainer>
    );
};
