import React, { useEffect, useRef, useState } from "react";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
    SCREENS,
    addProductToPurchase,
    decrementQuantity,
    removeProductFromPurchase,
    removeSelectedSupplier,
    updateProductFromPurchase,
} from "../../../store/purchase-slice";
import CartBottomBar from "../../cart-bottom-bar/CartBottomBar";
import NewPurchaseItem from "../new-purchase-item/NewPurchaseItem";
import { setOpenProductDrawer, setProductDrawerData, setProductDrawerType } from "../../../store/productdrawer-slice";
import { PRODUCT_DRAWER_TYPE } from "../../../constants/appConstants";
import store from "../../../store/store";
import { getProductsBySKU } from "../../../api/local/productApi";
import { captureException } from "../../../crash-reporting";

const NewPurchaseTable = ({ handleBtnClick }) => {
    const { currentScreen, purchaseProducts, selectedSupplier } = useSelector((state) => state.purchase);
    const dispatch = useDispatch();
    const rowsRefs = useRef([]);
    const [activeRow, _setActiveRow] = useState(null);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        getProductsData();
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 46) {
            //DELETE Key
            let product = store.getState().purchase.purchaseProducts[activeRowRef.current];
            if (activeRowRef.current >= 0 && product) {
                e.preventDefault();
                removeProduct(product);
            }
        } else if (e.keyCode === 38) {
            //UP Arrow key
            let idx = getCurrentIdx();
            let product = store.getState().purchase.purchaseProducts[idx];
            if (idx >= 0 && product) {
                e.preventDefault();
                dispatch(addProductToPurchase(product));
            }
        } else if (e.keyCode === 40) {
            //DOWN Arrow key
            let idx = getCurrentIdx();
            let product = store.getState().purchase.purchaseProducts[idx];
            if (idx >= 0 && product) {
                e.preventDefault();
                dispatch(decrementQuantity(product));
            }
        } else if (e.keyCode === 33) {
            //Pg Up key
            if (activeRowRef.current > 0) {
                e.preventDefault();
                setActiveRow(activeRowRef.current - 1);
                rowsRefs.current[activeRowRef.current].focus();
            }
        } else if (e.keyCode === 34) {
            //Pg Down key
            let products = store.getState().purchase.purchaseProducts;
            if (activeRowRef.current < products.length - 1) {
                e.preventDefault();
                setActiveRow(activeRowRef.current + 1);
                rowsRefs.current[activeRowRef.current].focus();
            }
        }
    };
    const getCurrentIdx = () => {
        return rowsRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const getProductsData = async () => {
        const payload = {
            ids: purchaseProducts.map((p) => p.sku),
        };
        try {
            let res = await getProductsBySKU(payload);
            if (res.status === 200) {
                let resProducts = res.data?.entity;
                resProducts.forEach((product) => {
                    updateProducts(product);
                });
            }
        } catch (error) {
            captureException(error);
        }
    };

    const updateProducts = (product) => {
        dispatch(updateProductFromPurchase(product));
    };
    const removeProduct = (product) => {
        dispatch(removeProductFromPurchase(product));
    };
    const handleEditProduct = (product) => {
        dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.EDIT));
        dispatch(setProductDrawerData(product));
        dispatch(setOpenProductDrawer());
    };
    const handleSupplierRemove = () => {
        dispatch(removeSelectedSupplier());
    };
    return (
        <S.Wrapper isFull={currentScreen === SCREENS.PURCHASE_TABLE}>
            <S.Table>
                <S.TableHeader>
                    <S.TableHeaderData flexValue="0.2">SN.</S.TableHeaderData>
                    <S.TableHeaderData flexValue="1">Item Description</S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.5" center>
                        MRP
                    </S.TableHeaderData>
                    {currentScreen === SCREENS.PURCHASE_TABLE && (
                        <S.TableHeaderData flexValue="0.6" center>
                            Unit
                        </S.TableHeaderData>
                    )}
                    <S.TableHeaderData flexValue="0.5" center>
                        Qty
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="1" center>
                        Purchase Price
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="1" center>
                        Sale Price
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.6" center>
                        Tax
                    </S.TableHeaderData>
                    {currentScreen === SCREENS.PURCHASE_TABLE && (
                        <>
                            <S.TableHeaderData flexValue="0.6" center>
                                Discount
                            </S.TableHeaderData>
                            <S.TableHeaderData flexValue="0.35" center>
                                More Info
                            </S.TableHeaderData>
                            <S.TableHeaderData flexValue="0.5" center>
                                Subtotal
                            </S.TableHeaderData>
                        </>
                    )}
                    <S.TableHeaderData flexValue="0.25"></S.TableHeaderData>
                </S.TableHeader>
                <S.TableBody>
                    {purchaseProducts.map((product, index) => (
                        <React.Fragment key={index}>
                            <NewPurchaseItem
                                key={index}
                                product={product}
                                updateProducts={updateProducts}
                                removeProduct={removeProduct}
                                idx={index}
                                focusRef={rowsRefs}
                                activeRow={activeRow}
                                setActiveRow={setActiveRow}
                                handleEditProduct={handleEditProduct}
                            />
                            <S.Bar></S.Bar>
                        </React.Fragment>
                    ))}
                </S.TableBody>
            </S.Table>
            {purchaseProducts?.length ? (
                <CartBottomBar
                    data={selectedSupplier}
                    expand={currentScreen === SCREENS.PURCHASE_TABLE}
                    onExpand={() => handleBtnClick("back")}
                    onProceed={() => handleBtnClick("proceed")}
                    onDataRemove={handleSupplierRemove}
                />
            ) : (
                <></>
            )}
        </S.Wrapper>
    );
};

export default NewPurchaseTable;
