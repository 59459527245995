import axios from "./axios";
import { STORE_API, STORE_BACKUP_API } from "../../constants/apiRoutes";

const updateStore = async (payload) => {
    return await axios.post(STORE_API, payload);
};

const getStore = async () => {
    return await axios.get(STORE_API);
};

const backupData = async () => {
    return await axios.post(STORE_BACKUP_API);
}

export { getStore, updateStore, backupData };
