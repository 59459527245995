import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDotsVerticalIcon } from "../../icons";
import { setSearchSaleParams } from "../../store/sale-slice";
import DeleteModal from "../purchase/delete-modal/DeleteModal";
import * as S from "./CustomerTable.styles";
import { FEATURES } from "../../constants/POS-plans";
import { toDecimalPlace } from "../../utils/global-fn";

const OptionsContainer = ({ onOpenDrawer, handleDeleteCustomer, onClose, setOpenDeleteModal }) => {
    return (
        <>
            <S.OptionsContainer>
                <S.Option
                    onClick={(e) => {
                        e.stopPropagation();
                        onOpenDrawer();
                    }}
                >
                    Edit
                </S.Option>
                <S.Option
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenDeleteModal(true);
                    }}
                >
                    Delete
                </S.Option>
            </S.OptionsContainer>
        </>
    );
};

const TableRow = ({ item, setOpenNewCustModal, handleDeleteCustomer }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { features } = useSelector((state) => state.app);

    return (
        <>
            <DeleteModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} handleDelete={() => handleDeleteCustomer(item.phone)} />
            <S.TRow
                onClick={() => {
                    // dispatch(setSearchSaleParams({ patient_id: item.phone, status: "PUBLISH" }));
                    setOpenNewCustModal({
                        id: item.id,
                        state: true,
                        phoneNumber: item.phone,
                        clear: false,
                        txn: false,
                    });
                }}
            >
                <S.DataBtn>
                    <div>{item.name}</div>
                </S.DataBtn>
                <S.DataBtn>{item.phone}</S.DataBtn>
                {features.includes(FEATURES.LOYALTY_POINT) && <S.SaleBtn>{item.loyalty_point ?? 0}</S.SaleBtn>}
                <S.SaleBtn>{item.transactions_count?.count}</S.SaleBtn>
                <S.SaleBtn>₱{toDecimalPlace(item.transactions_count?.total_amount_due)}</S.SaleBtn>
                <S.DialogDiv>
                    <S.ViewBtn
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenNewCustModal({
                                state: true,
                                phoneNumber: item.phone,
                                clear: false,
                                txn: true,
                            });
                        }}
                    >
                        View all orders
                    </S.ViewBtn>
                    <S.ClearDues
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenNewCustModal({
                                state: true,
                                phoneNumber: item.phone,
                                clear: true,
                                txn: true,
                            });
                        }}
                    >
                        Clear Dues
                    </S.ClearDues>
                    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                        <img
                            src={ThreeDotsVerticalIcon}
                            alt=""
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(true);
                            }}
                        />
                        {open && (
                            <OptionsContainer
                                onOpenDrawer={() =>
                                    setOpenNewCustModal({
                                        state: true,
                                        phoneNumber: item.phone,
                                        clear: false,
                                        txn: false,
                                    })
                                }
                                handleDeleteCustomer={() => handleDeleteCustomer(item.phone)}
                                onClose={() => setOpen(false)}
                                setOpenDeleteModal={setOpenDeleteModal}
                            />
                        )}
                    </OutsideClickHandler>
                </S.DialogDiv>
            </S.TRow>
        </>
    );
};

export default TableRow;
