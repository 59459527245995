import React from "react";
import * as S from "./style";

const Security = () => {
    return (
        <S.Wrapper>
            <div>
                <S.Label>Old Password</S.Label>
                <S.Input type="text" />
            </div>
            <div>
                <S.Label>New Password</S.Label>
                <S.Input type="text" />
            </div>
            <div>
                <S.Label>Confirm New Password</S.Label>
                <S.Input type="text" />
            </div>
            <S.ChangePassword>Change Password</S.ChangePassword>
            <S.ForgetPassword>Forget Password?</S.ForgetPassword>
        </S.Wrapper>
    );
};

export default Security;
