import React, { useEffect } from "react";
import * as S from "./style";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import Label from "../../product-drawer/label/Label";
import { SalesCartDeleteIcon } from "../../../icons";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import { setAdditionalCharges } from "../../../store/sale-slice";
import { useState } from "react";
import { setAdditionalChargesPI } from "../../../store/proformainvoice-slice";

const AdditionalCharges = ({ open, onClose, type }) => {
    const { additional_charges } = useSelector((state) => state.sale);
    const { PI_additional_charges } = useSelector((state) => state.proformaInvoice);
    const [additionalCharge, setAdditionalCharge] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (type === "sale") {
            setAdditionalCharge(additional_charges);
        } else if (type === "proforma-invoice") {
            setAdditionalCharge(PI_additional_charges);
        } else if (type === "delivery-challan") {
        }
    }, [open, type]);

    const handleInputChange = (index, type) => (val) => {
        if (type === "field") {
            const newField = additionalCharge.map((item, idx) => {
                if (idx !== index) return item;
                return { ...item, field: val };
            });
            setAdditionalCharge(newField);
        } else if (type === "value") {
            const regex = /^\d+\.?(\d)?(\d)?$/;
            if (val.length > 1 && val[0] === "0" && val[1] !== ".") {
                val = val.substr(1);
            }
            if (!(val === "" || regex.test(val))) return;
            const newValue = additionalCharge.map((item, idx) => {
                if (idx !== index) return item;
                return { ...item, value: val };
            });
            setAdditionalCharge(newValue);
        }
    };

    const handleAddCharge = () => {
        const newAdditionalCharge = additionalCharge ? additionalCharge.concat([{ field: "", value: "" }]) : [{ field: "", value: "" }];
        setAdditionalCharge(newAdditionalCharge);
    };

    const handleRemoveCharge = (index) => {
        const filteredCharges = Array.isArray(additionalCharge) ? additionalCharge.filter((item, idx) => index !== idx) : [];
        setAdditionalCharge(filteredCharges);
    };

    const handleDone = () => {
        if (type === "sale") {
            dispatch(setAdditionalCharges(additionalCharge));
        } else if (type === "proforma-invoice") {
            dispatch(setAdditionalChargesPI(additionalCharge));
        } else if (type === "delivery-challan") {
        }
        onClose();
    };
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Header>Additional Charges</S.Header>
                <S.Container>
                    {Array.isArray(additionalCharge) && additionalCharge.length ? (
                        additionalCharge.map((item, index) => (
                            <React.Fragment key={index}>
                                <S.InputContainer>
                                    <S.Input>
                                        <Label text="Field" />
                                        <CustomInput
                                            value={item.field}
                                            onChange={handleInputChange(index, "field")}
                                            type="text"
                                            placeholder="Enter Field"
                                            autoFocus
                                        />
                                    </S.Input>
                                    <S.Input>
                                        <Label text="Value" />
                                        <CustomInput
                                            value={item.value}
                                            onChange={handleInputChange(index, "value")}
                                            type="text"
                                            placeholder="Enter Value"
                                        />
                                    </S.Input>
                                    <S.ImgDiv>
                                        <img src={SalesCartDeleteIcon} alt="delete" onClick={() => handleRemoveCharge(index)} />
                                    </S.ImgDiv>
                                </S.InputContainer>
                            </React.Fragment>
                        ))
                    ) : (
                        <></>
                    )}
                </S.Container>
                <S.BottomBar>
                    <S.AddNew onClick={() => handleAddCharge()}>+ Add New Charge</S.AddNew>
                    <S.AddNew $fill onClick={handleDone}>
                        Done
                    </S.AddNew>
                </S.BottomBar>
            </S.Wrapper>
        </Modal>
    );
};

export default AdditionalCharges;
