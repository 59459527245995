import React from "react";
import * as S from "../PlanBuyModal.style";
import { DesktopPOS, GPayIcon, HomeDropLogo, MobilePOS, PaytmIcon, PhonePeIcon } from "../../../icons";
import QRCodeReact from "react-qr-code";
import YellowBox from "../yellow-box/YellowBox";
import BrandingLogo from "../../branding/branding-logo/BrandingLogo";
import BrandingFullLogo from "../../branding/full-logo/BrandingFullLogo";

const PaymentMethods = ({ posCounter, cost }) => {
    return (
        <div>
            <S.HeroContainer>
                <S.LogoContainer>
                    <BrandingFullLogo size="120px" />
                </S.LogoContainer>
                <S.HeadingContainer>
                    <S.Heading>Payment Methods</S.Heading>
                </S.HeadingContainer>
                <S.SpaceBox></S.SpaceBox>
            </S.HeroContainer>

            <S.QRContainer>
                <QRCodeReact value={"8280210127"} style={{ height: "auto", maxWidth: "121px", width: "121px" }} />
                <S.QRAmount>
                    <S.Cash>₱{cost}</S.Cash>
                    <S.CashWord>annually (billed once)</S.CashWord>
                </S.QRAmount>
            </S.QRContainer>

            <S.PaymentIconsLarge>
                <img src={PaytmIcon} alt="paytm" />
                <img src={GPayIcon} alt="gpay" />
                <img src={PhonePeIcon} alt="phonepe" />
            </S.PaymentIconsLarge>

            <S.PosCountContainer>
                <S.PosCount>
                    <S.PosLogoSmall src={DesktopPOS} alt="desktop pos" />
                    <S.YellowRoundedBox>{posCounter.desktop} Desktop POS</S.YellowRoundedBox>
                </S.PosCount>
                <S.PosCount>
                    <S.PosLogoSmall src={MobilePOS} alt="mobile pos" />
                    <S.YellowRoundedBox>{posCounter.mobile} Mobile POS</S.YellowRoundedBox>
                </S.PosCount>
            </S.PosCountContainer>

            <YellowBox label="or call us, Our friendly sales executive is just a phone call away! Dial +91 123456789." curved />
        </div>
    );
};

export default PaymentMethods;
