import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
    removeAllProducts,
    removeSaleID,
    removeSelectedSale,
    resetAdditionalCharges,
    setBillno,
    setCreatedDate,
    setCurrentScreen,
    setSelectDiscount,
} from "../../../store/sale-slice";
import { removeSelectedCustomer } from "../../../store/customer-slice";
import { updateSale } from "../../../api/local/sale-api";
import { useContext } from "react";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import { DiscountOption, SALE_BILL_TYPE, SALE_SCREENS } from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";
import {
    removeAllProductsPI,
    removeCustomerPI,
    removePI_ID,
    removeSelectedPI,
    resetAdditionalChargesPI,
    setBillNoPI,
    setCreatedDatePI,
    setCurrentScreenPI,
    setSelectDiscountPI,
} from "../../../store/proformainvoice-slice";
import { convertTax, getQuantity, getSalePayloadProducts } from "../../../utils/global-fn";
import { isEmpty } from "lodash";

const ClearCart = ({ open, onClose, clearCartRef, type }) => {
    const dispatch = useDispatch();
    const { saleId, selectedSale, saleProducts, discountValue, saleTotal, billno, createdDate, selectDiscount, globalDiscount } = useSelector(
        (state) => state.sale
    );
    const { PI_id, PI_products, PI_billNo, PI_selectDiscount, PI_discountValue, PI_createdDate, PI_customer, PI_globalDiscount } = useSelector(
        (state) => state.proformaInvoice
    );
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const { inventorySelectedCustomer } = useSelector((state) => state.customer);
    const { billTypePreference } = useSelector((state) => state.app);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleClearCart();
                onClose();
            } else if (e.keyCode === 121) {
                e.preventDefault();
                handleDraftSave();
            }
        }
    };

    function handleClearCart() {
        if (type === "sale" && saleId) {
            handleDeleteSale(saleId);
        } else if (type === "proforma-invoice" && PI_id) {
            handleDeleteSale(PI_id);
        }
        onClearCart();
    }

    const onClearCart = () => {
        if (type === "sale") onSaleClear();
        else if (type === "proforma-invoice") onProformaInvoiceClear();

        clearCartRef.current?.clearPaymentInfo();
    };

    const onSaleClear = () => {
        dispatch(setCreatedDate(null));
        dispatch(setBillno(""));
        dispatch(removeAllProducts());
        dispatch(removeSelectedCustomer());
        dispatch(removeSelectedSale());
        dispatch(removeSaleID());
        dispatch(
            setSelectDiscount({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
        dispatch(resetAdditionalCharges());
        dispatch(setCurrentScreen(SALE_SCREENS.SUMMARY));
    };

    const onProformaInvoiceClear = () => {
        dispatch(setCreatedDatePI(null));
        dispatch(setBillNoPI(""));
        dispatch(removeAllProductsPI());
        dispatch(removeCustomerPI());
        dispatch(removeSelectedPI());
        dispatch(removePI_ID());
        dispatch(
            setSelectDiscountPI({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
        dispatch(resetAdditionalChargesPI());
        dispatch(setCurrentScreenPI(SALE_SCREENS.SUMMARY));
    };

    async function handleDeleteSale(id) {
        const payload = {
            status: "TRASH",
        };
        try {
            let res = await updateSale(id, payload);
            if (res.status === 200) {
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Draft deleted successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }

    const createSalePayload = () => {
        if (!saleProducts.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of saleProducts) {
            if (!item.quantity) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please choose a quantity",
                    type: "error",
                });
                return;
            }
        }
        const payload = {
            status: "DRAFT",
            products: getSalePayloadProducts(saleProducts, billTypePreference?.sale_bill_type),
        };
        if (inventorySelectedCustomer) payload.patient_id = inventorySelectedCustomer.id;
        if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
        if (selectDiscount.cost) {
            let disc = {
                type: selectDiscount.type,
                value: selectDiscount.cost,
            };
            payload.cart_manual_discount = disc;
        }
        if (billno) payload.bill_no = billno;
        return payload;
    };

    const createPIPayload = () => {
        if (!PI_products.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of PI_products) {
            if (!item.quantity) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please choose a quantity",
                    type: "error",
                });
                return;
            }
        }
        const payload = {
            status: "DRAFT",
            products: getSalePayloadProducts(PI_products, SALE_BILL_TYPE.INVOICE),
        };
        if (PI_customer) payload.patient_id = PI_customer.id;
        if (PI_createdDate) payload.created_at = Math.floor(PI_createdDate.getTime());
        if (PI_selectDiscount.cost) {
            let disc = {
                type: PI_selectDiscount.type,
                value: PI_globalDiscount,
            };
            payload.cart_manual_discount = disc;
        }
        if (PI_billNo) payload.bill_no = PI_billNo;

        return payload;
    };

    async function handleDraftSave() {
        let payload = {};

        if (type === "sale") payload = createSalePayload();
        else if (type === "proforma-invoice") payload = createPIPayload();

        if (isEmpty(payload)) {
            return;
        }

        try {
            let id;
            if (type === "sale") id = saleId;
            else if (type === "proforma-invoice") id = PI_id;
            let res = await updateSale(id, payload);
            if (res.status === 200) {
                onClearCart();
                onClose();
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Draft Updated",
                    subtitle: "Draft updated successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Title>Clear Cart?</S.Title>
                <S.Heading>Are you sure want to {saleId ? "delete draft" : "clear cart"} ?</S.Heading>
                <S.BtnContainer>
                    <div>
                        {((type === "sale" && saleId) || (type === "proforma-invoice" && PI_id)) && (
                            <S.DraftBtn onClick={handleDraftSave}>Save to Daft (F10)</S.DraftBtn>
                        )}
                    </div>
                    <S.RightBtnContainer>
                        <S.CancelBtn onClick={onClose}>Cancel</S.CancelBtn>
                        <S.ConfirmBtn
                            onClick={() => {
                                handleClearCart();
                                onClose();
                            }}
                        >
                            {(type === "sale" && saleId) || (type === "proforma-invoice" && PI_id) ? "Delete (ENT)" : "Confirm (ENT)"}
                        </S.ConfirmBtn>
                    </S.RightBtnContainer>
                </S.BtnContainer>
            </S.Wrapper>
        </Modal>
    );
};

export default ClearCart;
