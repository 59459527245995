import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    position: relative;
    cursor: pointer;
    height: 38px;

    .caret-down {
        height: 16px;
        width: 16px;
        margin-left: 8px;
    }
`;

export const Heading = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #737373;
`;
