import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./app-slice";
import productReducer from "./product-slice";
import categoryReducer from "./category-slice";
import brandReducer from "./brand-slice";
import supplierReducer from "./supplier-slice";
import stockReducer from "./stock-slice";
import purchaseReducer from "./purchase-slice";
import employeeReducer from "./employee-slice";
import globalReducer from "./global-slice";
import customerReducer from "./customer-slice";
import permissionRoleReducer from "./permissionrole-slice";
import quickLoginsReducer from "./quicklogin-slice";
import sessionReducer from "./session-slice";
import saleReducer from "./sale-slice";
import purchaseReturnReducer from "./purchasereturn-slice";
import damageEntrynReducer from "./damageentry-slice";
import returnSaleReducer from "./returnsale-slice";
import dueReducer from "./due-slice";
import onlineSaleReducer from "./onlinesale-slice";
import expenseReducer from "./expense-slice";
import cashDrawerReducer from "./cashdrawer-slice";
import proformaInvoiceReducer from "./proformainvoice-slice";
import purchaseProformareducer from "./purchaseproforma-slice";
import productDrawerReducer from "./productdrawer-slice";
import apiCacheReducer from "./apicache-slice";

const store = configureStore({
    reducer: {
        app: appReducer,
        product: productReducer,
        category: categoryReducer,
        brand: brandReducer,
        supplier: supplierReducer,
        stock: stockReducer,
        purchase: purchaseReducer,
        employee: employeeReducer,
        global: globalReducer,
        customer: customerReducer,
        permissionRole: permissionRoleReducer,
        quickLogins: quickLoginsReducer,
        session: sessionReducer,
        sale: saleReducer,
        purchaseReturn: purchaseReturnReducer,
        damageEntry: damageEntrynReducer,
        returnSale: returnSaleReducer,
        due: dueReducer,
        onlineSale: onlineSaleReducer,
        expense: expenseReducer,
        cashDrawer: cashDrawerReducer,
        proformaInvoice: proformaInvoiceReducer,
        purchaseProforma: purchaseProformareducer,
        productDrawer: productDrawerReducer,
        apiCache: apiCacheReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
