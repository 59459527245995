import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    // width: 115px;
    // height: 28px;
    margin-right: 6px;
    background: ${(props) => (props.online ? "#6FF6B0" : "#FA7171")};
    border-radius: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    cursor: pointer;
    border: 1px solid ${(props) => (props.online ? "#00A36D" : "#B91010")};
    padding: 6px 11px;

    & > img {
        width: 22px;
        height: 18px;
    }
`;

export const Status = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${(props) => (props.online ? "#334155" : "#FEFFFF")};
`;
