import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllEmployees } from "../api/local/employee-api";
import { captureException } from "../crash-reporting";

const initialState = {
    loading: false,
    employees: [],
    error: "",
    searchEmpParams: {},
    purchaseSelectedEmployee: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllEmployees = createAsyncThunk("employee/fetchAllEmployees", (search) =>
    getAllEmployees(search)
        .then((res) => res.status === 200 && res.data)
        .catch((err) => captureException(err))
);

const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        setSearchEmpParams: (state, action) => {
            state.searchEmpParams = {
                ...state.searchEmpParams,
                ...action.payload,
            };
        },
        removeSearchEmpParams: (state, action) => {
            delete state.searchEmpParams[action.payload];
        },

        removeAllSearchEmpParams: (state) => {
            state.searchEmpParams = {};
        },
        setSelectedEmployee: (state, action) => {
            state.purchaseSelectedEmployee = action.payload;
        },
        removeSelectedEmployee: (state) => {
            state.purchaseSelectedEmployee = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllEmployees.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllEmployees.fulfilled, (state, action) => {
            state.loading = false;
            state.employees = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllEmployees.rejected, (state, action) => {
            state.loading = false;
            state.employees = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default employeeSlice.reducer;
export const {
    setSearchEmpParams,
    removeSearchEmpParams,
    removeAllSearchEmpParams,
    setSelectedEmployee,
    removeSelectedEmployee,
} = employeeSlice.actions;
