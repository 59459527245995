import React, { useState } from "react";
import { DeleteRedIcon, SelectCaretDown } from "../../../../icons";

import * as S from "./account-accordion.styles";

const AccountAccordion = ({ data, onDelete }) => {
  const [showSubDetail, setShowSubDetail] = useState(false);

  return (
    <>
      <S.AccountDetails detailOpened={showSubDetail}>
        <S.BankName>{data?.bank_name}</S.BankName>
        <S.AccountNumber>A/C Number: {data?.account_number}</S.AccountNumber>
        <img
          onClick={() => setShowSubDetail((prev) => !prev)}
          src={SelectCaretDown}
          alt="down-arrow"
        />
      </S.AccountDetails>
      {showSubDetail && (
        <S.AccountSubDetails>
          <div className="sub-details">
            <div className="sub-details--title">Account Name</div>
            <div className="sub-details--value">{data?.account_name}</div>
          </div>
          <div className="sub-details">
            <div className="sub-details--title">IFSC Code</div>
            <div className="sub-details--value">{data?.ifsc_code}</div>
          </div>
          <img
            src={DeleteRedIcon}
            alt="delete"
            onClick={() => onDelete(data)}
          />
        </S.AccountSubDetails>
      )}
    </>
  );
};

export default AccountAccordion;
