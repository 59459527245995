import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: #fff;
    // border: 2px solid red;
    overflow-y: auto;
`;
