import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 82px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 44px 24px;
`;
export const LeftImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 556px;
        height: 556px;
    }
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
`;
export const Container = styled.div``;
export const ImageBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 168px;
    height: 108px;
    border: 1px solid #e2e8f0;
    border-radius: 9px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1e293b;
    position: relative;

    & img {
        width: 62px;
        height: 62px;
    }
`;

export const BlueTick = styled.img`
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    top: 0;
    right: 0;
`;
export const Text = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
    margin-bottom: 24px;
`;
export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
`;
export const DarkButton = styled.div`
    background: #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 12px 14px;
    cursor: pointer;
`;
export const BarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const FooterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 31px;
`;
export const WhiteButton = styled.div`
    border: 1px solid #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    cursor: pointer;
`;
export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const CustomInput = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 9px;
    background-color: #fff;
    width: 100%;
    padding: 10px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1e293b;
    &: focus {
        outline: none;
    }
`;
export const SaveBtn = styled.div`
    width: 168px;
    height: 40px;
    border: 1px solid #1e293b;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
