import { createContext, useState } from "react";

export const OrderSnackbarContext = createContext();

const OrderSnackbarProvider = ({ children }) => {
    const [orderSnackbarDetails, setOrderSnackbarDetails] = useState({
        show: false,
        title: "",
        subtitle: "",
    });

    const handleOrderSnackbarDetails = (value) => setOrderSnackbarDetails(value);

    return (
        <OrderSnackbarContext.Provider
            value={{
                orderSnackbarDetails,
                handleOrderSnackbarDetails,
            }}
        >
            {children}
        </OrderSnackbarContext.Provider>
    );
};

export default OrderSnackbarProvider;
