import styled from "@emotion/styled/macro";

export const Heading = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #263238;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Container = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #fff;
    // height: 300px;
`;
export const FilterBar = styled.div`
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    gap: 16px;
`;
export const dateContainer = styled.div`
    position: relative;
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-top: 2px;
`;

export const DateChooserSelect = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #475569;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 40px;
    cursor: pointer;
    width: 280px;
    border-right: 1px solid #e2e8f0;
    position: relative;
    top: 0;

    img {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
`;
export const TimePeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 8px;
    gap: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    cursor: pointer;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
    img {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
`;
export const DataContainer = styled.div`
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 64px;
    padding: 14px 0 26px 16px;
    border-bottom: 1px solid #e2e8f0;
`;
export const DataLabel = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #94a3b8;
`;
export const DataDiv = styled.div`
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: #263238;
    letter-spacing: -0.0375em;
`;
export const Date = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #263238;
`;
export const PeriodMenu = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 1px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
`;
export const PeriodRow = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
`;
export const TimePeriodContainer = styled.div`
    position: relative;
`;
export const InputFIeld = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
    padding: 8px 12px;
    width: 350px;

    & img {
        cursor: pointer;
    }

    & input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #475569;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        &::placeholder {
            color: #94a3b8;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }
`;
export const ChartContainer = styled.div`
    // border: 1px solid red;
    box-sizing: border-box;
    width: 90%;
    height: 290px;
    margin: 47px 24px 25px;
`;
export const InputContainer = styled.div`
    width: 350px;
    position: relative;
`;

export const ProductDrawerWrapper = styled.div`
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 315px;
    box-sizing: border-box;
    margin-top: 1px;
    overflow: hidden;
    overflow-y: auto;
`;
export const ProductRow = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    padding: 12px 12px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;

    :hover {
        background-color: #f1f5f9;
    }
`;
export const ProductImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.759894px solid #e2e8f0;
    border-radius: 3.03958px;
    width: 54px;
    height: 54px;
    background-color: #ffffff;

    & img {
        max-width: 100%;
        max-height: 100%;
    }
`;
export const ProductName = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const ProductSku = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #64748b;
    & > div:nth-of-type(1) {
        font-weight: 500;
    }
`;
export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 80%;
`;

export const EmptyContainer = styled.div`
    width: 100%;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyText = styled.div`
    background: rgba(0, 0, 0, 0.64);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    padding: 8px;
`;
