import React from "react";
import { BackButtonIcon, BlueArrowLeft } from "../../icons";
import * as S from "./style";

const BackButton = ({ onClickAction }) => {
    return (
        <S.Wrapper onClick={onClickAction}>
            <img src={BackButtonIcon} alt="left arrow" />
            {/* <S.Text>Go Back</S.Text> */}
        </S.Wrapper>
    );
};

export default BackButton;
