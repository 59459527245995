import styled from "@emotion/styled/macro";

export const DiscountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 2;
    width: 52px;
    height: 38px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 4px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 3px 2px;
`;

export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 4px;
`;

export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
export const InputDiv = styled.div`
    width: 70px;
    height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    & > input {
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #334155;
        border: none;
        outline: none;
    }
`;
