import axios from "./axios";
import {
    ONLINE_SALE_API,
    START_FULFILLMENT,
    COMPLETE_FULFILLMENT,
    CANCEL_FULFILLMENT,
    START_DELIVERY,
    COMPLETE_DELIVERY,
} from "../../constants/apiRoutes";

const getAllOnlineSales = async (queryParams = {}) => {
    return await axios.get(ONLINE_SALE_API, {
        params: queryParams,
    });
};

const getOnlineSale = async (id) => {
    return await axios.get(`${ONLINE_SALE_API}/${id}`);
};

const fulfillingOnlineSale = async (id, payload) => {
    return await axios.post(`${ONLINE_SALE_API}/${id}/${START_FULFILLMENT}`, payload);
};

const completeOnlineSale = async (id, payload) => {
    return await axios.post(`${ONLINE_SALE_API}/${id}/${COMPLETE_FULFILLMENT}`, payload);
};

const cancelOnlineSale = async (id, payload) => {
    return await axios.post(`${ONLINE_SALE_API}/${id}/${CANCEL_FULFILLMENT}`, payload);
};

const startDeliveryOnlineSale = async (id, payload) => {
    return await axios.post(`${ONLINE_SALE_API}/${id}/${START_DELIVERY}`, payload);
};

const completeDeliveryOnlineSale = async (id, payload) => {
    return await axios.post(`${ONLINE_SALE_API}/${id}/${COMPLETE_DELIVERY}`, payload);
};

export {
    getAllOnlineSales,
    getOnlineSale,
    fulfillingOnlineSale,
    completeOnlineSale,
    cancelOnlineSale,
    startDeliveryOnlineSale,
    completeDeliveryOnlineSale,
};
