import { createSlice } from "@reduxjs/toolkit";
import { PRODUCT_DRAWER_TYPE } from "../constants/appConstants";

const initialState = {
    productDrawerType: PRODUCT_DRAWER_TYPE.ADD,
    productDrawerData: null,
    openProductDrawer: false,
    isProductNewSKU: false,
};

const productDrawerSlice = createSlice({
    name: "productDrawer",
    initialState,
    reducers: {
        setOpenProductDrawer: (state) => {
            state.openProductDrawer = true;
        },
        setCloseProductDrawer: (state) => {
            state.openProductDrawer = false;
        },
        setProductDrawerData: (state, action) => {
            state.productDrawerData = action.payload;
        },
        setProductDrawerType: (state, action) => {
            state.productDrawerType = action.payload;
        },
        setIsProductNewSKU: (state, action) => {
            state.isProductNewSKU = action.payload;
        },
    },
});

export default productDrawerSlice.reducer;
export const { setOpenProductDrawer, setCloseProductDrawer, setProductDrawerData, setProductDrawerType, setIsProductNewSKU } =
    productDrawerSlice.actions;
