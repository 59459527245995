import styled from "@emotion/styled/macro";

export const CustomBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: ${(props) => (props.size === "lg" ? "10px 16px" : props.size === "md" ? "8px 10px" : "12px")};
    color: ${(props) => (props.fill ? "#fff" : props.disable ? "#94A3B8" : props.color ? props.color : "#000")};
    border: 1px solid ${(props) => (props.disable ? "#94A3B8" : props.color ? props.color : "#000")};
    border-radius: 4px;
    font-weight: 500;
    font-size: ${(props) => (props.size === "lg" ? "16px" : props.size === "md" ? "14px" : "12px")};
    line-height: ${(props) => (props.size === "lg" ? "20px" : props.size === "md" ? "13px" : "10px")};
    background: ${(props) => (props.fill ? (props.disable ? "#94A3B8" : props.color) : "#fff")};
    cursor: ${(props) => (props.disable ? "no-drop" : "pointer")};
    ${(props) => props.noBorder && "border: none"};

    :hover {
        opacity: 0.9;
    }
`;
