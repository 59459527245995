export const validURL = (str) => {
  const regex =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return regex.test(str);
};

export const validEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validUPI = (upi) => {
  const regex = /^[\w.-]+@[\w.-]+$/;
  return regex.test(upi);
};

export const validPincode = (pincode) => {
  const regex = "^[1-9][0-9]{5}$";
  return pincode.match(regex);
};

export const validGstNumber = (gstNumber) => {
  var regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return regex.test(gstNumber);
};

export const validAddress = (address) => {
  if (!address) return true;
  let { line_1, line_2, pin_code, city, state, country } = address;
  if (
    line_1?.length ||
    line_2?.length ||
    pin_code?.length ||
    city?.length ||
    state?.length
  ) {
    if (!line_1?.length) {
      return false;
    }
    if (!line_2?.length) {
      return false;
    }
    if (pin_code?.length !== 6) {
      return false;
    }
    if (!city?.length) {
      return false;
    }
    if (!state?.length) {
      return false;
    }
    if (!country?.length) {
      return false;
    }
  }
  return true;
};

function checksum(g) {
  let a = 65,
    b = 55,
    c = 36;
  let p;
  return Array["from"](g).reduce((i, j, k, g) => {
    p =
      (p =
        (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) *
        ((k % 2) + 1)) > c
        ? 1 + (p - c)
        : p;
    return k < 14
      ? i + p
      : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
  }, 0);
}

export const isValidGst = (gstNo) => {
  const REGEX = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

  return gstNo && gstNo.length === 15 && REGEX.test(gstNo) && checksum(gstNo);
};

//0 1 2 3 4 5 6 7 8 9 0 1  2  3  4
//D D A A A A A D D D D A A/D A A/D
//1 2 3 4 5 6 7 8 9 0 1 2  3  4  5

export const checkValidGstAfterChange = (value) => {
  const REGEX_NUM = /^[0-9\b]+$/;
  const REGEX_ALPHA = /^[A-Za-z]+$/;
  const REGEX_ALPHA_NUM = /^[a-zA-Z0-9]*$/;

  let text = value.trim();
  if (!text) return true;
  let valid = true;

  if (text.length > 0) {
    //State code
    let testText = text.slice(0, 2);
    if (!REGEX_NUM.test(testText)) valid = false;
  }

  if (text.length > 2) {
    //PAN
    if (text.length > 2) {
      let testText = text.slice(2, 7);
      if (!REGEX_ALPHA.test(testText)) valid = false;
    }
    if (text.length > 7) {
      let testText = text.slice(7, 11);
      if (!REGEX_NUM.test(testText)) valid = false;
    }
    if (text.length > 11) {
      let testText = text.slice(11, 12);
      if (!REGEX_ALPHA.test(testText)) valid = false;
    }
  }
  if (text.length > 12) {
    let testText = text.slice(12, 13);
    if (!REGEX_ALPHA_NUM.test(testText)) valid = false;
  }
  if (text.length > 13) {
    let testText = text.slice(13, 14);
    if (testText !== "Z") valid = false;
  }
  if (text.length > 14) {
    let testText = text.slice(14, 15);
    if (!REGEX_ALPHA_NUM.test(testText)) valid = false;
  }

  return valid;
};
