import React from "react";
import Lottie from "lottie-react";
import ScrollLoaderLottie from "../../animations/infinite-scroll-loader.json";
import * as S from "./style";

const ScrollLoader = () => {
    return (
        <S.Wrapper>
            <S.AnimationDiv>
                <Lottie animationData={ScrollLoaderLottie} />
            </S.AnimationDiv>
        </S.Wrapper>
    );
};

export default ScrollLoader;
