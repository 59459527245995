import styled from "@emotion/styled/macro";

export const Container = styled.div`
    min-width: 339px;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    flex: 1;
    height: 100%;
`;

export const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    padding: 14px 12px;
    border-bottom: 0.710937px solid #e2e8f0;
`;

export const Body = styled.div`
    padding: 5px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
`;

export const SupplierDetails = styled.div`
    background: #f1f5f9;
    padding: 12px;
    position: relative;
    margin-bottom: 5px;
    height: 44px;

    img {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 13px;
        right: 9px;
        cursor: pointer;
    }
`;
export const SupplierContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    margin-bottom: 4px;
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Mobile = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;

export const PriceDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    margin-bottom: ${(props) => (props.lastItem ? "16px" : "4px")};
`;

export const AddDetail = styled.div`
    display: flex;
    margin-bottom: 27px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 10px 13.5px;
    justify-content: space-between;
    align-items: center;

    input {
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
        background-color: #fff;

        &::placeholder {
            color: #94a3b8;
        }
    }
`;

export const AddBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2d68fe;
    cursor: pointer;
`;

export const TotalDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    // border-top: 1px dashed #e2e8f0;
    // padding-top: 12px;
    // margin-bottom: 50px;
`;
export const DeliveryDetails = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 8px;
`;

export const DeliveryDetailsTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #334155;
    margin-bottom: 6px;
`;

export const DateContainer = styled.div`
    // border: 1px solid #e2e8f0;
    // border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
    // display: flex;
    // justify-content: space-between;
    // padding: 8px 16px;
    // cursor: pointer;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 130px;
`;

export const DatePickerContainer = styled.div`
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 1;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;
export const Dotted = styled.div`
    height: 1px;
    border-bottom: 2px dashed #e2e8f0;
    margin: 8px 0;
`;
export const NewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 8px;
`;
export const FieldContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 130px;

    & > input {
        border: none;
        background: #fff;
        width: 100%;

        :focus {
            border: none;
            outline: none;
        }
    }
`;
export const Line = styled.div`
    height: 18px;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;

export const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
