import styled from "@emotion/styled/macro";

export const AccountDetails = styled.div`
  border: 1px solid #e2e8f0;
  position: relative;
  padding: 11px 17px;

  img {
    position: absolute;
    top: 11px;
    right: 17px;
    ${(props) => props.detailOpened && "transform: rotate(180deg)"};
    cursor: pointer;
  }
`;

export const BankName = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
  margin-bottom: 8px;
`;

export const AccountNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #334155;
`;

export const AccountSubDetails = styled.div`
  background: #f1f5f9;
  border-bottom: 1px solid #f1f5f9;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;

  .sub-details--title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1e293b;
    margin-bottom: 4px;
  }

  .sub-details--value {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
  }

  img {
    cursor: pointer;
  }
`;
