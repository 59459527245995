import React, { useContext, useEffect, useState } from "react";
import { HomeDropLogo } from "../../icons";
import * as S from "./onboardingscreen.style";
import StoreType from "../../components/onboarding/store-type/StoreType";
import PosType from "../../components/onboarding/pos-type/PosType";
import Members from "../../components/onboarding/members/Members";
// import PermissionPage from "../../components/onboarding/permission-page/PermissionPage";
import Transfer from "../../components/onboarding/data-transfer-page/Transfer";
import UploadPage from "../../components/onboarding/upload-page/UploadPage";
import { getOnboarding, setOnboarding } from "../../api/server/onboardingApi";
import axios from "axios";
import { useNavigate } from "react-router";
import { addEmployee, getEmployee, updateEmployee } from "../../api/local/employee-api";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingData } from "../../store/app-slice";
import { captureException } from "../../crash-reporting";
import BrandingLogo from "../../components/branding/branding-logo/BrandingLogo";
import BrandingName from "../../components/branding/branding-name/BrandingName";

const INITIAL_EMPLOYEE_DATA = [{ name: "", phone: "" }];
const OnboardingScreen = () => {
    const [activePage, setActivePage] = useState(0);
    const [employeeData, setEmployeeData] = useState(INITIAL_EMPLOYEE_DATA);
    // const [onboardingData, setOnboardingData] = useState(null);
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const { store } = useSelector((state) => state.session);
    const { onboardingData } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleSetOnboarding = async () => {
        const payload = {
            store_types: onboardingData?.select_store_types,
            sale_online: onboardingData?.sale_online,
            existing_pos: {
                using_existing_pos: onboardingData?.existing_pos,
                name: onboardingData?.pos,
            },
        };

        if (!employeeData[0].phone) {
            payload.employee_add_count = 0;
        } else {
            payload.employee_add_count = employeeData.length;
        }

        try {
            let res = await setOnboarding(payload);
            if (res.status === 200) {
                // console.log(res.data);
                onAddEmployeeData();
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const checkEmployeeData = async (data) => {
        let res = await getEmployee(data.phone);
        if (res.status === 2000) {
            return true;
        } else {
            return false;
        }
    };

    const uploadEmployeeData = async (data) => {
        if (data.name && data.phone.length !== 10) {
            handleSnackbarDetails({
                show: true,
                title: `Please enter a valid phone number for ${data.name}`,
                type: "error",
            });
            return;
        }
        let payload = {
            name: data.name,
            username: data.phone,
            phone: data.phone,
            permission_role: "Co Admin",
            password: "password",
        };
        try {
            let res = await addEmployee(payload);
            if (res.status !== 200) {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
        // if (checkEmployeeData(data)) {
        //     try {
        //         let res = await updateEmployee(data.phone, payload);
        //         if (res.status !== 200) {
        //             throw new Error(res.data?.message);
        //         }
        //     } catch (e) {
        //         console.log(e);
        //     }
        // } else {
        //     try {
        //         let res = await addEmployee(payload);
        //         if (res.status !== 200) {
        //             throw new Error(res.data?.message);
        //         }
        //     } catch (e) {
        //         console.log(e);
        //     }
        // }
    };

    const onAddEmployeeData = async () => {
        for (let i = 0; i < employeeData.length; i++) {
            await uploadEmployeeData(employeeData[i]);
        }
        window.electronAPI.onDashboardScreen();
        navigate("/dashboard/new-sale");
    };

    return (
        <S.Wrapper>
            <S.Header>
                <S.TitleContainer>
                    <BrandingLogo size="50px" />
                    <BrandingName size="sm" />
                </S.TitleContainer>
                <S.StoreTitle>
                    Hi <span>{store?.store_name}</span>, Let’s get you on-boarded!
                </S.StoreTitle>
            </S.Header>
            <div>
                {activePage === 0 && <StoreType setActivePage={setActivePage} />}
                {activePage === 1 && <PosType setActivePage={setActivePage} />}
                {activePage === 2 && (
                    <Members
                        setActivePage={setActivePage}
                        employeeData={employeeData}
                        setEmployeeData={setEmployeeData}
                        handleSetOnboarding={handleSetOnboarding}
                    />
                )}
                {/* {activePage === 3 && <PermissionPage setActivePage={setActivePage} />} */}
                {activePage === 3 && <Transfer setActivePage={setActivePage} />}
                {activePage === 4 && <UploadPage setActivePage={setActivePage} />}
            </div>
        </S.Wrapper>
    );
};

export default OnboardingScreen;
