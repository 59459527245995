import styled from "@emotion/styled/macro";

export const Container = styled.div`
    padding-top: 8px;
`;

export const Section = styled.div`
    margin-bottom: 24px;
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: #475569;
    margin-bottom: 16px;
    text-decoration: underline;
`;

export const InputContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: flex-end;
    margin-bottom: 16px;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;

export const Input = styled.div`
    flex: 1;
`;

export const ImgDiv = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;

export const AddNew = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #2d68fe;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 128px;
    height: 27px;
    cursor: pointer;
    border: 1px solid #2d68fe;
    border-radius: 4px;
    & img {
        width: 18px;
        height: 18px;
    }
`;

export const StaticPosition = styled.div`
    position: relative;
`;
