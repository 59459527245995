import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gstCache: {},
};

const apiCacheSlice = createSlice({
    name: "apiCache",
    initialState,
    reducers: {
        setGstCache: (state, action) => {
            state.gstCache = { ...state.gstCache, ...action.payload };
        },
    },
});

export default apiCacheSlice.reducer;
export const { setGstCache } = apiCacheSlice.actions;
