import { css } from "@emotion/react";

export const multilineEllipsis = ({ fontSize, lineHeight, lines }) => css`
  display: block; // Fallback for non-webkit
  display: -webkit-box;
  height: ${fontSize || "1rem"}*${lineHeight || 1.2}*${lines || 1}; // Fallback for non-webkit
  font-size: ${fontSize || "1rem"};
  line-height: ${lineHeight || 1.2};
  -webkit-line-clamp: ${lines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
