import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    // width: 74%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #fff;
    flex: 3;
`;
export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 14px 16px;
    border-bottom: 1px solid #cbd5e1;
    margin: 4px;

    & > div {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
    }
`;
export const Body = styled.div`
    // height: 100%;
    height: calc(100vh - 312px);
    overflow: hidden;
    overflow-y: auto;
`;

export const Th = styled.div`
    flex: ${(props) => (props.flex ? props.flex : 1)};
    text-align: ${(props) => (props.$first ? "left" : "center")};
`;
export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 14px 16px;
    background-color: ${(props) => (props.active ? "#EEF5FF" : "#fff")};
    border-radius: 4px;
    margin: 4px;
`;
export const ProductData = styled.div`
    display: flex;
    align-items: center;
    flex: 2.5;
    gap: 6px;

    & > img {
        height: 50px;
        width: 50px;
        border: 1px solid #cbd5e1;
        border-radius: 4px;
        background-color: #fff;
    }
`;
export const ProductDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 160px;
`;
export const Brand = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #475569;
`;
export const Name = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2d68fe;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
`;
export const Metrics = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94a3b8;
    text-transform: uppercase;
`;
export const Data = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    flex: ${(props) => (props.flex ? props.flex : 1)};
    text-align: center;
`;
export const Check = styled.input`
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
`;
export const TableDataQuantity = styled.div`
    // width: 31px;
    flex: 1.2;

    text-align: center;
    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #cbd5e1;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 8px;
        width: 64px;
        height: 40px;
        margin: auto;
        background-color: #fff;
    }
`;
export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e2e8f0;
    width: 50%;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
export const Quantity = styled.div`
    text-align: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const ReturnInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
`;
export const Line = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
`;
