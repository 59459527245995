import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 23px 75px 54px 23px;
`;
export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 28px;
    margin-bottom: 12px;
`;
export const BackupBtn = styled.div`
    width: 97.21px;
    height: 34.21px;
    border: 0.710625px solid #0075f6;
    border-radius: 2.13188px;
    font-weight: 500;
    font-size: 14.2125px;
    line-height: 20px;
    color: #0075f6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const CancelBtn = styled.div`
    width: 52.21px;
    height: 34.11px;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    font-weight: 500;
    font-size: 11.37px;
    line-height: 17px;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const TimeStamp = styled.div`
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #263238;
`;
export const ProgressContainer = styled.div`
    flex-grow: 1;
`;
