import {
    STORE_LOGIN_API,
    STORE_REGISTER_API,
    STORE_API,
    ACCOUNT_CHECK_API,
    AUTH_API,
    CHANGE_PASSWORD_API,
    APP_CLIENT,
} from "../../constants/apiRoutes";
import axios from "./axios";

const registerStore = async (data) => {
    return await axios.post(STORE_REGISTER_API, data);
};

const checkAccountExists = async (data) => {
    return await axios.post(ACCOUNT_CHECK_API, data);
};

const loginStore = async (data) => {
    return await axios.post(STORE_LOGIN_API, data);
};

const getStore = async (uid, timeout) => {
    return await axios.get(`${STORE_API}?uid=${uid}`, {
        timeout: timeout,
    });
};

const getAvailableIPsByWifiInfo = async (wifiInfo, storeId) => {
    return await axios.post("store/get-ip-by-wifi-info", {
        wifi_info: wifiInfo,
        store_id: storeId,
    });
};

const storeChangePassword = async (data) => {
    return await axios.post(`${AUTH_API}/${CHANGE_PASSWORD_API}`, data);
};

export { registerStore, loginStore, getStore, checkAccountExists, storeChangePassword, getAvailableIPsByWifiInfo };
