import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployees } from "../../../store/employee-slice";
import AddNewEmp from "../add-new-employee/AddNewEmp";
import EmpRow from "./EmpRow";
import * as S from "./EmpTable.style";
import Lottie from "lottie-react";
import EmptyTable from "../../../animations/empty_table.json";

const EmployeeTable = () => {
    const dispatch = useDispatch();
    const { employees, searchEmpParams } = useSelector((state) => state.employee);
    const [employeeData, setEmployeeData] = useState(null);
    useEffect(() => {
        dispatch(fetchAllEmployees(searchEmpParams));
    }, [searchEmpParams]);
    return (
        <S.Table>
            <AddNewEmp open={employeeData !== null} onClose={() => setEmployeeData(null)} employeeId={employeeData?.username} />
            <S.THead>
                <S.Th>Name</S.Th>
                <S.Th>POS Terminal</S.Th>
                <S.Th>Contact</S.Th>
                <S.Th>Role</S.Th>
                <S.Th>Status</S.Th>
            </S.THead>
            {employees?.length ? (
                <S.TBody>
                    {employees?.map((e) => (
                        <EmpRow
                            item={e}
                            openEditDialog={(_e) => {
                                setEmployeeData(_e);
                            }}
                        />
                    ))}
                </S.TBody>
            ) : (
                <S.EmptyTable>
                    <div>
                        <Lottie animationData={EmptyTable} />
                    </div>
                </S.EmptyTable>
            )}
        </S.Table>
    );
};

export default EmployeeTable;
