import styled from "@emotion/styled/macro";

export const SelectContainer = styled.div`
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) =>
        props.paddingHead ? props.paddingHead : props.scText ? "6px 10px 6px 16px" : props.size === "sm" ? "10px 6px" : "10px 16px"};

    position: relative;
    cursor: pointer;
    background-color: ${(props) => (props.blue ? "#EEF5FF" : "#fff")};

    .caret-down {
        height: 16px;
        width: 16px;
        margin-left: px;
        margin-right: ${(props) => props.scText && "6px"};
    }
`;

export const SelectedOption = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => (props.paddingHead ? "#737373" : props.bigText ? "#334155" : "#64748b")};
    font-size: ${(props) => props.paddingHead && "16px"};
    font-size: ${(props) => props.bigText && "14px"};
    ${(props) => props.size === "sm" && "width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"};

    // width: 80px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
`;

export const MenuContainer = styled.div`
    width: ${(props) => (props.menuWidth ? props.menuWidth : "100%")};
    position: absolute;
    ${(props) => (props.position === "top" ? "bottom : 46px" : "top :36px")};
    ${(props) => props.position === "left" && "margin-left : -115px"};
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 2px 2px;
    left: 0;
    z-index: 10;
    margin-top: 10px;
    display: ${(props) => !props.data && "none"};
    // padding-bottom: 8px;
`;

export const AddNew = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2d68fe;
    margin: 12px 16px 12px;
    display: flex;
    align-items: center;

    img {
        width: 16px;
        height: 16x;
        margin: 0 4px 0 0;
    }
`;

export const AddNewTypeInputContainer = styled.div`
    margin: 12px 12px 12px;
    display: flex;
    align-items: center;
    img {
        margin-left: ${(props) => (props.firstItem ? "unset" : "14px")};
    }
`;

export const AddNewInput = styled.input`
    padding: 4px 12px;
    border: 1.5px solid #2d68fe;
    border-radius: 4px;
    width: 100%;
    outline: none;

    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #475569;
    &::placeholder {
        color: #a6aebc;
    }
`;

export const SearchContainer = styled.div`
    margin: ${(props) => (props.size === "sm" ? "6px 6px 0" : "12px 12px 0")};
    display: flex;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    /* padding: 12px 8px; */
    padding: 6px 8px;
    max-height: 36px;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
    }
`;

export const SearchInput = styled.input`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #334155;
    outline: none;
    border: none;
    padding: 0;
    flex: 1;
    width: 100%;
    &::placeholder {
        color: #94a3b8;
    }
`;

export const SearchList = styled.div`
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    // padding: 8px 0 0;
`;

export const SearchData = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: ${(props) => (props.size === "sm" ? "8px 6px" : "8px 16px")};
    background: ${(props) => (props.active ? "#f1f5f9" : "#fff")};

    &:hover {
        background: #f1f5f9;
    }
`;
