import React from "react";
import Avatar from "../avatar/Avatar";
import * as S from "./storelogo.style";

const StoreLogo = ({ store, size, color }) => {
    return (
        <>
            {store?.logo ? (
                <S.LogoImage size={size} src={store.logo + `?n=${store?.updated_at}`} alt="store logo" />
            ) : (
                <Avatar name={store?.store_name ?? ""} color={color} size={size} noTooltip />
            )}
        </>
    );
};

export default StoreLogo;
