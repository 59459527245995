import React from "react";
import * as S from "./style";

const CustomCheckBox = ({ heading, subHeading, values, role }) => {
  return (
    <S.Wrapper>
      <S.Heading>{heading}</S.Heading>
      <S.SubHead>{subHeading}</S.SubHead>
      <S.InputField>
        {values.map((e) => (
          <S.CheckBox>
            <input type="checkbox" checked={role?.includes(e)}/>
            <S.CheckHead>{e}</S.CheckHead>
          </S.CheckBox>
        ))}
      </S.InputField>
    </S.Wrapper>
  );
};

export default CustomCheckBox;
