import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 24px;
`;

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    padding-bottom: 2px;
`;

export const Tab = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    padding-bottom: 6px;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    border-bottom: 1px solid ${(props) => (props.active ? "#3B82F6" : "#fff")};
`;

export const Body = styled.div`
    height: calc(100vh - 277px);
`;
