import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px 16px 16px;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 34px;
`;
export const Heading = styled.div`
    font-weight: 600;
    font-size: 16.9578px;
    line-height: 20px;
    color: #1e293b;
`;
export const Body = styled.div`
    height: calc(100vh - 152px);
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: #ffffff;
`;
export const Tabs = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    padding: 12px 24px 3px;
`;

export const Tab = styled.div`
    font-weight: 400;
    font-size: 12.7184px;
    line-height: 16px;
    cursor: pointer;
    padding-bottom: 9px;
    color: ${(props) => (props.active ? "#3B82F6" : "#475569")};
    border-bottom: 1px solid ${(props) => (props.active ? "#3B82F6" : "#fff")};
`;
