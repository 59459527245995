import styled from "@emotion/styled/macro";
export const Wrapper = styled.div`
    height: calc(100vh - 82px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 44px 24px;
`;
export const LeftImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    & > img {
        width: 556px;
        height: 556px;
    }
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
`;
export const FooterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 31px;
`;
export const WhiteButton = styled.div`
    border: 1px solid #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    cursor: pointer;
`;
export const BarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const Container = styled.div``;
export const DarkButton = styled.div`
    background: #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 11px 14px;
    cursor: pointer;
`;

export const PointRow = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
`;
export const GreenDot = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: rgba(86, 255, 59, 0.5);
`;
export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export const DottedDiv = styled.div`
    border-left: 2px dashed #94a3b8;
    height: 47px;
    margin-left: 11px;
`;
export const UploadBtn = styled.div`
    background: #2d68fe;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 10px;
    width: 75px;
    margin-top: 40px;
    cursor: pointer;
`;
export const Title = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
`;
