import React, { useEffect, useState } from "react";
import "./style.css";
import * as S from "./new-purchase-item.style";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
    ArrowDrop,
    ArrowUp,
    DeleteRedIcon,
    GreenArrowDown,
    MoreDOttedInfoIcon,
    MoreInfoIcon,
    ProductPlaceholderIcon,
    RedArrowUp,
} from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { SCREENS, addProductToPurchase, decrementQuantity } from "../../../store/purchase-slice";
import { calendarFormatDate } from "../../../utils/format-date";
import { DiscountOption } from "../../../constants/appConstants";
import onInputChange from "./../../../utils/handleInputChange";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tippy from "@tippyjs/react";
import {
    getAltUnit,
    getPurchaseCompareToPrice,
    getPurchasePrice,
    getPurchasePriceWithoutTax,
    getPurchaseProductSubTotal,
    getTax,
    handleGetProductUnitQty,
    hasDecimal,
    toDecimalPlace,
} from "../../../utils/global-fn";
import AlternateUnit from "../../sales/alternate-unit/AlternateUnit";
import SelectTax from "../../sales/select-tax/SelectTax";
import Taxable from "../../sales/taxable/Taxable";
import ProductImage from "../../product-image/ProductImage";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#334155",
        color: "#FFFFFF",
        maxWidth: 220,
        border: "1px solid #dadde9",
        borderRadius: "2px",
        width: "184px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px",
        textAlign: "center",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#334155",
    },
}));

const NewPurchaseItem = ({ product, removeProduct, updateProducts, idx, activeRow, setActiveRow, focusRef, type, handleEditProduct }) => {
    const [displayData, setDisplayData] = useState(product);
    const currentScreen = useSelector((state) => state.purchase.currentScreen);
    const { currentFocus } = useSelector((state) => state.purchase);
    const [inc, setInc] = useState(0);
    const [qty, setQty] = useState(1);
    const [unitHint, setUnitHint] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        setDisplayData(product || null);
        let checkProduct = product?.checkProduct;
        if (checkProduct) {
            if (checkProduct?.purchase_price * qty !== product?.purchase_price && checkProduct?.purchase_price !== 0) {
                setInc(((((product?.purchase_price || 0) - checkProduct?.purchase_price * qty) * 100) / checkProduct?.purchase_price) * qty);
            } else {
                setInc(0);
            }
        }
    }, [product]);

    useEffect(() => {
        handleGetProductUnitQty(displayData, setQty, false);
    }, [displayData.unit]);

    useEffect(() => {
        setUnitHint(getAltUnit(displayData));
    }, [displayData]);

    useEffect(() => {
        onDiscountChange(displayData?.discount, displayData);
    }, [displayData.activeDiscount, displayData.quantity, displayData.purchase_price, displayData.purchase_price_with_tax, displayData.tax]);

    useEffect(() => {
        if (idx === currentFocus) {
            focusRef.current[idx].focus();
            setActiveRow(currentFocus);
        }
    }, [currentFocus]);

    const handleInputChange1 = (key, e) => {
        onInputChange(e, (data) => {
            let { value } = data;
            const regex = /^\d+\.?(\d)?(\d)?$/;
            if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
                value = value.substr(1);
            }
            if (!(value === "" || regex.test(value))) return;
            if (key === "batch_number") {
                updateProducts({ ...displayData, [key]: value });
            } else if (key === "price") {
                let tempProd = product.checkProduct;
                if (tempProd?.price != value) {
                    updateProducts({ ...displayData, [key]: value, sale_price: value });
                } else {
                    updateProducts({ ...displayData, [key]: value, sale_price: tempProd.sale_price });
                }
            } else {
                if (key === "purchase_price") {
                    value = getPurchaseCompareToPrice(value, displayData);
                }
                updateProducts({ ...displayData, [key]: value || 0 });
            }
        });
    };

    const handleSelectChange = (selectedvalue, type) => {
        if (type === "purchase_price_with_tax") {
            let purchasePrice = getPurchaseCompareToPrice(displayData.purchase_price, { ...displayData, purchase_price_with_tax: selectedvalue });
            updateProducts({ ...displayData, [type]: selectedvalue, purchase_price: purchasePrice });
        } else {
            updateProducts({ ...displayData, [type]: selectedvalue });
        }
    };

    const handleUnitChange = (unit) => {
        let newProduct = { ...displayData, unit: unit };
        let qt = handleGetProductUnitQty(newProduct, setQty);

        let cp = product.checkProduct;
        updateProducts({
            ...displayData,
            unit: unit,
            discountValue: 0,
            discount: 0,
            price: cp?.price * qt,
            purchase_price: cp?.purchase_price * qt,
            sale_price: cp?.sale_price * qt,
        });
    };

    const handleIncrementQuantity = () => {
        dispatch(addProductToPurchase(displayData));
    };
    const handleDecrementQuantity = () => {
        if (displayData.quantity <= 1) return;
        dispatch(decrementQuantity(displayData));
    };

    const handleDateChange = (key, item) => {
        updateProducts({ ...displayData, [key]: item });
    };

    function handleDiscountChange(e, item) {
        onInputChange(e, (data) => {
            let { value } = data;
            onDiscountChange(value, item);
        });
    }

    function onDiscountChange(val, item) {
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (val?.length > 1 && val[0] === "0" && val[1] !== ".") {
            val = val.substr(1);
        }
        if (!(val === "" || regex.test(val))) return;

        let purchasePriceWot = getPurchasePriceWithoutTax(item);
        let purchase_price_sum = purchasePriceWot * item.quantity;
        if (displayData.activeDiscount === DiscountOption.FLAT) {
            if (val > purchase_price_sum) {
                val = purchase_price_sum;
            }
        }
        if (displayData.activeDiscount === DiscountOption.PERCENTAGE) {
            if (val > 100) {
                val = 100;
            }
        }

        updateProducts({
            ...item,
            discount: val || 0,
            discountValue:
                displayData.activeDiscount === DiscountOption.FLAT ? parseFloat(val || 0) : purchase_price_sum * (parseFloat(val || 0) / 100),
        });
    }
    const handleRowFocus = () => {
        setActiveRow(idx);
        focusRef.current[idx].focus();
    };

    const onInputClick = (e) => {
        e.stopPropagation();
        setActiveRow(idx);
    };

    const setActiveDiscount = (value) => {
        updateProducts({ ...displayData, activeDiscount: value });
    };

    return (
        <S.TableRow key={idx} active={idx === activeRow} onClick={() => handleRowFocus()}>
            <S.TableDataSn>
                <div>{idx + 1}</div>
            </S.TableDataSn>
            <HtmlTooltip
                arrow
                placement="top"
                title={
                    <React.Fragment>
                        <div className="sku">SKU: {displayData.sku}</div>
                        <div className="upc">UPC: {displayData.upc}</div>
                        <div className="upc">{displayData.name}</div>
                    </React.Fragment>
                }
            >
                <S.TableBodyData flexValue="1" firstItem onClick={() => handleEditProduct(displayData)}>
                    <ProductImage icon={(displayData.gallery && displayData.gallery?.[0]) || ProductPlaceholderIcon} />
                    <S.ProductDetails>
                        <S.Brand>{displayData?.brand?.name}</S.Brand>
                        <S.Name>{displayData?.name}</S.Name>
                    </S.ProductDetails>
                </S.TableBodyData>
            </HtmlTooltip>

            <S.TableBodyData flexValue="0.5" center>
                <S.InputContainer>
                    <S.Rupee>₱</S.Rupee>
                    <S.Input type="text" value={displayData?.price || 0} onChange={(e) => handleInputChange1("price", e)} onClick={onInputClick} />
                </S.InputContainer>
            </S.TableBodyData>
            {currentScreen === SCREENS.PURCHASE_TABLE && (
                <S.TableBodyData flexValue="0.6" center column>
                    <S.InputContainer center>
                        <AlternateUnit item={displayData} onChange={(unit) => handleUnitChange(unit)} />
                    </S.InputContainer>
                    {unitHint && <S.HintText>{unitHint}</S.HintText>}
                </S.TableBodyData>
            )}
            <S.TableBodyData flexValue="0.5" center column>
                <S.InputContainer>
                    <S.Input
                        align="center"
                        paddingLeft="9px"
                        type="text"
                        value={displayData.quantity || 0}
                        onChange={(e) => handleInputChange1("quantity", e)}
                        onFocus={(e) => e.target.select()}
                        ref={(el) => (focusRef.current[idx] = el)}
                    />
                    {
                        <S.ArrowBox>
                            <img src={ArrowUp} alt="arrow up" onClick={() => handleIncrementQuantity()} />
                            <img src={ArrowDrop} alt="arrow drop" onClick={() => handleDecrementQuantity()} />
                        </S.ArrowBox>
                    }
                </S.InputContainer>
                <S.HintText>{toDecimalPlace(displayData?.checkProduct?.stock)} in stock</S.HintText>
            </S.TableBodyData>
            <S.TableBodyData flexValue="1" center column full="95%">
                <S.InputFlexContainer>
                    <S.InputContainer>
                        <S.Rupee>₱</S.Rupee>{" "}
                        <S.Input
                            type="text"
                            value={displayData.purchase_price || 0}
                            onChange={(e) => handleInputChange1("purchase_price", e)}
                            onClick={onInputClick}
                        />
                    </S.InputContainer>
                    <Taxable item={displayData} onChange={(unit, type) => handleSelectChange(unit, type)} type="purchase_price_with_tax" />
                </S.InputFlexContainer>
                {inc !== 0 && (
                    <S.RateDiv inc={inc < 0}>
                        {inc < 0 ? <img src={GreenArrowDown} alt="green arrow up" /> : <img src={RedArrowUp} alt="red arrow down" />}
                        {Math.abs(inc).toFixed(1)}% {inc < 0 ? "dec" : "inc"}
                    </S.RateDiv>
                )}
            </S.TableBodyData>

            <S.TableBodyData flexValue="1" center full="95%">
                <S.InputFlexContainer>
                    <S.InputContainer>
                        <S.Rupee>₱</S.Rupee>{" "}
                        <S.Input
                            type="text"
                            value={displayData.sale_price || 0}
                            onChange={(e) => handleInputChange1("sale_price", e)}
                            onClick={onInputClick}
                        />
                    </S.InputContainer>
                    <Taxable item={displayData} onChange={(unit, type) => handleSelectChange(unit, type)} type="sale_price_with_tax" />
                </S.InputFlexContainer>
            </S.TableBodyData>

            <S.TableBodyData flexValue="0.6" center full="90%">
                <SelectTax item={displayData} onChange={(unit, type) => handleSelectChange(unit, type)} type="tax" />
            </S.TableBodyData>
            {currentScreen === SCREENS.PURCHASE_TABLE && (
                <>
                    <S.TableBodyData flexValue="0.6" center>
                        <S.SymbolDiv>
                            <S.Symbol
                                active={displayData.activeDiscount === DiscountOption.FLAT}
                                onClick={() => setActiveDiscount(DiscountOption.FLAT)}
                            >
                                ₱
                            </S.Symbol>
                            <S.MiddleBar></S.MiddleBar>
                            <S.Symbol
                                active={displayData.activeDiscount === DiscountOption.PERCENTAGE}
                                onClick={() => setActiveDiscount(DiscountOption.PERCENTAGE)}
                            >
                                %
                            </S.Symbol>
                        </S.SymbolDiv>
                        <S.DiscountInputDiv>
                            <input
                                type="text"
                                value={displayData.discount}
                                onChange={(e) => handleDiscountChange(e, displayData)}
                                onClick={onInputClick}
                                onSelect={(e) => e.stopPropagation()}
                            />
                        </S.DiscountInputDiv>
                    </S.TableBodyData>

                    <S.TableBodyData flexValue="0.35">
                        {" "}
                        <Tippy
                            placement="bottom"
                            trigger="click"
                            interactive={true}
                            content={
                                <MoreInfo
                                    displayData={displayData}
                                    handleDateChange={handleDateChange}
                                    handleInputChange1={(key, e) => handleInputChange1(key, e)}
                                    onInputClick={onInputClick}
                                />
                            }
                        >
                            {displayData?.batch_number || displayData?.manufacturing_date || displayData?.expiry_date ? (
                                <img src={MoreDOttedInfoIcon} alt="info" />
                            ) : (
                                <img src={MoreInfoIcon} alt="info" />
                            )}
                        </Tippy>
                    </S.TableBodyData>
                    <S.TableBodyData flexValue="0.5" center>
                        <S.DataText>₱ {getPurchaseProductSubTotal(displayData)?.itemTotal}</S.DataText>
                    </S.TableBodyData>
                </>
            )}
            <S.TableBodyData lastItem flexValue="0.25">
                <img onClick={() => removeProduct(product)} src={DeleteRedIcon} alt="delete" />
            </S.TableBodyData>
        </S.TableRow>
    );
};

export default NewPurchaseItem;

export const MoreInfo = ({ displayData, handleInputChange1, handleDateChange, onInputClick }) => {
    const { createdDate } = useSelector((state) => state.purchase);
    return (
        <S.MoreInfoWrapper>
            <S.OptionDiv>
                <S.OptionHeader>Bill Scheme</S.OptionHeader>
                <S.OptionInputContainer>
                    <S.Input
                        type="text"
                        value={displayData.free_quantity}
                        onChange={(e) => handleInputChange1("free_quantity", e)}
                        onClick={onInputClick}
                    />
                </S.OptionInputContainer>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Batch Number</S.OptionHeader>
                <S.OptionInputContainer>
                    <S.Input
                        type="text"
                        value={displayData.batch_number}
                        onChange={(e) => handleInputChange1("batch_number", e)}
                        onClick={onInputClick}
                    />
                </S.OptionInputContainer>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Mfg Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.manufacturing_date ?? null))}
                        onChange={(date) => handleDateChange("manufacturing_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        maxDate={createdDate || new Date()}
                        showMonthDropdown
                    />
                </div>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Expiry Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.expiry_date ?? null))}
                        onChange={(date) => handleDateChange("expiry_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        minDate={displayData.manufacturing_date || new Date()}
                        showMonthDropdown
                    />
                </div>
            </S.OptionDiv>
        </S.MoreInfoWrapper>
    );
};
