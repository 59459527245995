import axios from "./axios";
import {
  CUSTOMERS_API,
  PAYMENTS,
  TRANSACTIONS_CLEAR,
  TRANSACTIONS_COUNT,
} from "../../constants/apiRoutes";

const addCustomer = async (payload) => {
  return await axios.post(CUSTOMERS_API, {
    ...payload,
    country_code: "+63",
  });
};

const getCustomer = async (customerId, queryParams = {}) => {
  return await axios.get(`${CUSTOMERS_API}/${customerId}`, {
    params: queryParams,
  });
};

const getAllCustomers = async (queryParams = {}) => {
  // const queryParams = { search: searchKey };
  return await axios.get(CUSTOMERS_API, {
    params: queryParams,
  });
};

const updateCustomer = async (customerId, payload) => {
  return await axios.post(`${CUSTOMERS_API}/${customerId}`, payload);
};

const deleteCustomer = async (customerId) => {
  return await axios.delete(`${CUSTOMERS_API}/${customerId}`);
};

const getCustomerTransactionCount = async (customerId) => {
  return await axios.get(
    `${CUSTOMERS_API}/${TRANSACTIONS_COUNT}/${customerId}`
  );
};

const clearCustomerTransactions = async (customerId, payload) => {
  return await axios.post(
    `${CUSTOMERS_API}/${TRANSACTIONS_CLEAR}/${customerId}`,
    payload
  );
};

const getCustomerPayments = async (customerId, queryParams = {}) => {
  return await axios.get(`${CUSTOMERS_API}/${PAYMENTS}/${customerId}`, {
    params: queryParams,
  });
};
export {
  addCustomer,
  getCustomer,
  getAllCustomers,
  updateCustomer,
  deleteCustomer,
  getCustomerTransactionCount,
  clearCustomerTransactions,
  getCustomerPayments,
};
