import axios from "./axios";
import { ADVANCE_TRANSACTIONS_API, EMPLOYEES_API, EMPLOYEES_CONFIG_API } from "../../constants/apiRoutes";

export const addEmployee = async (payload) => {
    return await axios.post(EMPLOYEES_API, payload);
};

export const getAllEmployees = async (queryParams = {}) => {
    return await axios.get(EMPLOYEES_API, {
        params: queryParams,
    });
};

export const updateEmployee = async (employeeId, payload) => {
    return await axios.post(`${EMPLOYEES_API}/${employeeId}`, payload);
};

export const getAllEmployeesConfig = () => {
    return axios.get(EMPLOYEES_CONFIG_API);
};

export const getEmployee = async (employeeId) => {
    return await axios.get(`${EMPLOYEES_API}/${employeeId}`);
};
export const getAdvanceTransactions = async (employeeId) => {
    return await axios.get(`${EMPLOYEES_API}/${employeeId}/${ADVANCE_TRANSACTIONS_API}`);
};
export const createAdvanceTransaction = async (employeeId, payload) => {
    return await axios.post(`${EMPLOYEES_API}/${employeeId}/${ADVANCE_TRANSACTIONS_API}`, payload);
};