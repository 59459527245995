import axios from "./axios";
import { PURCHASES_COUNT, PURCHASE_API } from "../../constants/apiRoutes";

export const getPurchase = (id) => {
  return axios.get(`${PURCHASE_API}/${id}`);
};

export const getAllPurchases = (queryParams = {}) => {
  return axios.get(PURCHASE_API, {
    params: queryParams,
  });
};

export const addPurchase = (payload) => {
  return axios.post(PURCHASE_API, payload);
};

export const updatePurchase = (id, payload) => {
  return axios.post(`${PURCHASE_API}/${id}`, payload);
};

export const getPurchasesCount = () => {
    return axios.get(PURCHASES_COUNT);
};