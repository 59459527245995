import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #fff;
`;
export const Header = styled.div`
    height: 82px;
    background: #253238;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 24px;
`;
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;
export const Img = styled.img`
    width: 50px;
    height: 50px;
`;
export const Title = styled.div`
    font-weight: 700;
    font-size: 20.6897px;
    line-height: 25px;
    color: #ffde2c;
`;
export const StoreTitle = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    & span {
        font-weight: 700;
    }
`;
