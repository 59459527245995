import React, { useContext, useEffect, useState } from "react";
import { ACCOUNT_TYPES } from "../../../constants/appConstants";
import { DeleteRedIcon, PlusBlueIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { validUPI } from "../../../utils/validity-fns";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import InputLabel from "../../product-drawer/input-label/input-label";
import AccountAccordion from "./account-accordion/account-accordion";

import * as S from "./payment-content.styles";

const PaymentContent = ({ displayData, setDisplayData }) => {
    const [addNewAccount, setAddNewAccount] = useState(false);
    const [addNewUPI, setAddNewUPI] = useState(false);
    const [bankName, setBankName] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [upiId, setUpiId] = useState("");
    const [bankAccounts, setBankAccounts] = useState([]);
    const [upiAccounts, setUpiAccounts] = useState([]);

    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        const bankAccounts = [];
        const UPIs = [];
        displayData.payment_accounts.forEach((item) => {
            if (item.account_type === ACCOUNT_TYPES.BANK_ACCOUNT) bankAccounts.push(item);
            else if (item.account_type === ACCOUNT_TYPES.QR) UPIs.push(item);
        });

        setBankAccounts(bankAccounts);
        setUpiAccounts(UPIs);
    }, [displayData]);

    const clearInputs = (type) => {
        if (type === "bank") {
            setBankName("");
            setAccountName("");
            setAccountNumber("");
            setIfscCode("");
        } else if (type === "upi") setUpiId("");
    };

    const handleAddNewAccount = (open, save) => {
        if (save) {
            if (!bankName || !accountName || !accountNumber || !ifscCode) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please fill all the account details",
                    type: "error",
                });
                return;
            }

            const accountObj = {
                account_type: ACCOUNT_TYPES.BANK_ACCOUNT,
                account_name: accountName,
                account_number: accountNumber,
                bank_name: bankName,
                ifsc_code: ifscCode,
            };

            setDisplayData({
                ...displayData,
                payment_accounts: displayData.payment_accounts.concat([accountObj]),
            });

            setAddNewAccount(open);
            clearInputs("bank");
            return;
        }
        if (!open) clearInputs("bank");
        setAddNewAccount(open);
    };

    const handleAddNewUPI = (open, save) => {
        if (save) {
            if (!upiId) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please fill the QR ID",
                    type: "error",
                });
                return;
            } else if (!validUPI(upiId)) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter a valid QR ID",
                    type: "error",
                });
                return;
            }

            const upiObj = {
                account_type: ACCOUNT_TYPES.QR,
                upi_id: upiId,
            };

            setDisplayData({
                ...displayData,
                payment_accounts: displayData.payment_accounts.concat([upiObj]),
            });
            setAddNewUPI(open);
            clearInputs("upi");
            return;
        }
        if (!open) clearInputs("upi");
        setAddNewUPI(open);
    };

    const handleRemoveBankAccount = (item) => {
        const filtered = bankAccounts.filter((acc) => acc.account_number !== item.account_number);
        setDisplayData({
            ...displayData,
            payment_accounts: [...filtered, ...upiAccounts],
        });
    };

    const handleRemoveUPIAccount = (item) => {
        const filtered = upiAccounts.filter((acc) => acc.upi_id !== item.upi_id);
        setDisplayData({
            ...displayData,
            payment_accounts: [...filtered, ...bankAccounts],
        });
    };

    const handleChangeBankName = (val) => {
        const regex = /^([a-zA-Z ]+)$/;
        if (!(val === "" || regex.test(val))) return;
        setBankName(val);
    };
    const handleChangeAccountName = (val) => {
        const regex = /^([a-zA-Z ]+)$/;
        if (!(val === "" || regex.test(val))) return;
        setAccountName(val);
    };
    const handleChangeIfscCode = (val) => {
        const regex = /^([a-zA-Z0-9]+)$/;
        if (!(val === "" || regex.test(val))) return;
        setIfscCode(val.toUpperCase());
    };

    return (
        <S.Container>
            <S.Section>
                <InputLabel text="Saved Bank Accounts" />
                {bankAccounts.map((account, index) => (
                    <S.AccountContainer>
                        {index + 1}.
                        <S.Right>
                            <AccountAccordion key={index} data={account} onDelete={(item) => handleRemoveBankAccount(item)} />
                        </S.Right>
                    </S.AccountContainer>
                ))}

                {!addNewAccount && (
                    <S.AddNew shift={bankAccounts.length} onClick={() => handleAddNewAccount(true)}>
                        {" "}
                        <img src={PlusBlueIcon} alt="add" /> Add new bank account{" "}
                    </S.AddNew>
                )}
                {addNewAccount && (
                    <S.AddNewContainer>
                        {bankAccounts.length + 1}.{" "}
                        <S.Right>
                            <S.Title>Add a new bank account</S.Title>
                            <div>
                                <S.InputContainer>
                                    <S.Input>
                                        <InputLabel text="Bank Name" />
                                        <CustomInput type="text" value={bankName} onChange={(val) => handleChangeBankName(val)} />
                                    </S.Input>
                                    <S.Input>
                                        <InputLabel text="Account Name" />
                                        <CustomInput type="text" value={accountName} onChange={(val) => handleChangeAccountName(val)} />
                                    </S.Input>
                                </S.InputContainer>
                                <S.InputContainer className="row">
                                    <S.Input>
                                        <InputLabel text="Account Number" />
                                        <CustomInput type="number" value={accountNumber} onChange={(val) => setAccountNumber(val)} />
                                    </S.Input>
                                    <S.Input>
                                        <InputLabel text="IFSC Code" />
                                        <CustomInput type="text" value={ifscCode} onChange={(val) => handleChangeIfscCode(val)} />
                                    </S.Input>
                                </S.InputContainer>
                            </div>
                            <S.BtnContainer>
                                <S.SubmitBtn onClick={() => handleAddNewAccount(false, true)} save>
                                    Save{" "}
                                </S.SubmitBtn>
                                <S.SubmitBtn onClick={() => handleAddNewAccount(false)} cancel>
                                    Cancel{" "}
                                </S.SubmitBtn>
                            </S.BtnContainer>
                        </S.Right>
                    </S.AddNewContainer>
                )}
            </S.Section>
            <S.Section>
                <InputLabel text="Saved QR" />
                {upiAccounts.map((upi, index) => (
                    <S.UPIContainer>
                        {index + 1}.
                        <S.Right>
                            <S.UPIDetails>
                                <div className="upi-id">QR Id: {upi.upi_id}</div>
                                <img src={DeleteRedIcon} alt="delete" onClick={() => handleRemoveUPIAccount(upi)} />
                            </S.UPIDetails>
                        </S.Right>
                    </S.UPIContainer>
                ))}
                {!addNewUPI && (
                    <S.AddNew onClick={() => handleAddNewUPI(true)}>
                        {" "}
                        <img src={PlusBlueIcon} alt="add" /> Add new QR
                    </S.AddNew>
                )}
                {addNewUPI && (
                    <S.AddNewContainer>
                        {upiAccounts.length + 1}.{" "}
                        <S.Right>
                            <S.Title>Add a new QR account</S.Title>
                            <S.InputContainer>
                                <S.InputWrapper>
                                    <CustomInput type="text" value={upiId} onChange={(val) => setUpiId(val)} />
                                </S.InputWrapper>
                                <S.SubmitBtn onClick={() => handleAddNewUPI(false, true)} save>
                                    Save{" "}
                                </S.SubmitBtn>
                                <S.SubmitBtn onClick={() => handleAddNewUPI(false)} cancel>
                                    Cancel{" "}
                                </S.SubmitBtn>
                            </S.InputContainer>
                        </S.Right>
                    </S.AddNewContainer>
                )}
            </S.Section>
        </S.Container>
    );
};

export default PaymentContent;
