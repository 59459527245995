import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import * as S from "./style";
import IndiaNeighborhood from "../../animations/indian-neighborhood.json";
import Loader from "../../animations/loader.json";
import { AddNewTypeCheckIcon, HomeDropLogo } from "../../icons";
import { useEffect } from "react";
import { employeeLogin } from "../../api/local/auth-api";
import { errorNotify, successNotify } from "../../utils/toast";
import { setEmployeeSession } from "../../store/session-slice";
import store from "../../store/store";
import { LINKS, ONBOARDING_TEXTS } from "../../constants/appConstants";
import { captureException } from "../../crash-reporting";
import BrandingLogo from "../../components/branding/branding-logo/BrandingLogo";
import BrandingName from "../../components/branding/branding-name/BrandingName";
import BrandingFullLogo from "../../components/branding/full-logo/BrandingFullLogo";

/**
 * Start - 4
 * Product call - 18
 * DB Synced - 32
 * Loggin in - 45 - employee login
 * End - 73 - Redirect
 */
const ANIMATION_STEP_EMPLOYEE_LOGIN = "ANIMATION_STEP_EMPLOYEE_LOGIN";
const ANIMATION_STEP_END = "ANIMATION_STEP_END";

const OnboardingLoading = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const animationRef = useRef();
    const [animationStep, setAnimationStep] = useState(null);

    const [dots, setDots] = useState(1);
    const [showText, setShowText] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            setDots((prevDots) => (prevDots % 3) + 1);
        }, 750);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        (async () => {
            await executeScripts();
        })();
    }, []);

    const executeScripts = async () => {
        const storeData = store.getState().session.store;
        if (storeData.is_first_login) {
            //Registration flow
            await registrationFlow();
        } else {
            //Login flow
            await loginFlow();
        }
    };

    /**
     * Login flow
     * 1. Sync DB
     * 2. Go to dashboard
     */
    const loginFlow = async () => {
        animationRef.current?.playSegments([4, 32], true);
        setShowText([ONBOARDING_TEXTS.LOGIN_FLOW.IMPORT_STORE]);
        await syncDb();
        setAnimationStep(ANIMATION_STEP_EMPLOYEE_LOGIN);
        animationRef.current?.playSegments([32, 45]);
        setShowText([{ ...ONBOARDING_TEXTS.REGISTRATION_FLOW.CREATE_INVENTORY, loading: false }, ONBOARDING_TEXTS.LOGGING_STORE]);
    };

    /**
     * Registration flow
     * 1. Create inventory
     * 2. Sync DB
     * 3. Go to dashboard
     */
    const registrationFlow = async () => {
        //Step 1
        animationRef.current?.playSegments([4, 18], true);
        setShowText([ONBOARDING_TEXTS.REGISTRATION_FLOW.CREATE_INVENTORY]);
        await createInventory();
        //Step 2
        animationRef.current?.playSegments([18, 32]);
        setShowText([{ ...ONBOARDING_TEXTS.REGISTRATION_FLOW.CREATE_INVENTORY, loading: false }, ONBOARDING_TEXTS.REGISTRATION_FLOW.IMPORT_PRODUCTS]);
        await syncDb();
        //Step 3
        setAnimationStep(ANIMATION_STEP_EMPLOYEE_LOGIN);
        animationRef.current?.playSegments([32, 45]);
        setShowText([
            { ...ONBOARDING_TEXTS.REGISTRATION_FLOW.CREATE_INVENTORY, loading: false },
            { ...ONBOARDING_TEXTS.REGISTRATION_FLOW.IMPORT_PRODUCTS, loading: false },
            ONBOARDING_TEXTS.LOGGING_STORE,
        ]);
    };

    const syncDb = async () => {
        return new Promise((resolve, reject) => {
            try {
                //Send command to start DB syncing
                window.electronAPI.syncDb();
                //Acknowledge result
                const removeOnDbSyncedListener = window.electronAPI.onDbScyned(async (event, value) => {
                    if (value) {
                        resolve();
                    } else {
                        reject(new Error("Failed to sync"));
                    }
                    removeOnDbSyncedListener();
                });
            } catch (e) {
                reject(e);
            }
        });
    };

    const createInventory = async () => {
        return new Promise((resolve, reject) => {
            try {
                //Create inventory
                window.electronAPI.createInventory();
                const removeOnInventoryCreatedListener = window.electronAPI.onInventoryCreated(async (event, value) => {
                    if (value) {
                        resolve();
                    } else {
                        reject(new Error("Failed to create inventory"));
                    }
                    removeOnInventoryCreatedListener();
                });
            } catch (e) {
                reject(e);
            }
        });
    };

    const employeeLoginStep = async () => {
        const storeData = store.getState().session.store;
        try {
            let employeeLoginResult = await employeeLogin({
                username: storeData.phone,
                password: storeData.password,
            });
            if (employeeLoginResult.status === 200) {
                dispatch(setEmployeeSession(employeeLoginResult.data));
                setAnimationStep(ANIMATION_STEP_END);
                animationRef.current?.playSegments([45, 73]);
            } else {
                errorNotify("Some error occurred. Please try again!");
                throw new Error(employeeLoginResult.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    };

    const contactSupport = () => {
        window.open(LINKS.CONTACT_US);
    };

    return (
        <S.Wrapper>
            <S.Topbar>
                <S.LogoContainer>
                    <BrandingFullLogo size="sm" />
                </S.LogoContainer>
                <S.ContactSupportBtn onClick={() => contactSupport()}>Contact Support</S.ContactSupportBtn>
            </S.Topbar>
            <S.HeaderContainer>
                Setting up your magic store <div>{".".repeat(dots)}</div>
            </S.HeaderContainer>
            <S.Body>
                <S.BodyContainer>
                    <S.LeftContainer>
                        {showText.length ? (
                            <S.TextContainer>
                                {showText.map((item) => (
                                    <S.TextRow>
                                        <S.TextLoader>
                                            {item.loading ? (
                                                <Lottie animationData={Loader} />
                                            ) : (
                                                <>
                                                    <S.Tick src={AddNewTypeCheckIcon} alt="tick" />
                                                </>
                                            )}
                                        </S.TextLoader>
                                        <S.Text>{item.text}</S.Text>
                                    </S.TextRow>
                                ))}
                            </S.TextContainer>
                        ) : (
                            <></>
                        )}
                    </S.LeftContainer>
                    <S.RightContainer>
                        <S.AnimationDiv>
                            <Lottie
                                lottieRef={animationRef}
                                autoPlay={false}
                                loop={false}
                                animationData={IndiaNeighborhood}
                                onComplete={async () => {
                                    if (animationStep === ANIMATION_STEP_EMPLOYEE_LOGIN) {
                                        //Login the employee
                                        await employeeLoginStep();
                                    } else if (animationStep === ANIMATION_STEP_END) {
                                        //Completed, navigate to dashhboard
                                        window.electronAPI.onDashboardScreen();
                                        navigate("/dashboard/new-sale");
                                    }
                                }}
                            />
                        </S.AnimationDiv>
                    </S.RightContainer>
                </S.BodyContainer>
                <S.Bottombar>
                    <div>© HomeDrop Online Services Private Limited </div>
                </S.Bottombar>
            </S.Body>
        </S.Wrapper>
    );
};

export default OnboardingLoading;
