import React from "react";
import CustomSelect from "../product-drawer/custom-select/custom-select";
import { useState } from "react";
import { captureException } from "../../crash-reporting";

const StoreTypeSelect = ({
  selectedOption,
  selectRef,
  typeRef,
  size = "sm",
  onChange,
  position = "bottom",
}) => {
  const storeTypes = [];
  const [filterStoreTypes, setFilterStoreTypes] = useState([
    {
      id: 2,
      name: "Medicine (Pharmacy)",
      tags: ["hospital", "hospital suppliers, health"],
    },
  ]);

  const handleSelectChange = (key, item) => {
    if (key === "store_type") {
      if (onChange) {
        onChange(item);
      }
      setFilterStoreTypes(storeTypes);
    }
  };

  const handleSearchChange = (key, value) => {
    if (!value) {
      setFilterStoreTypes(storeTypes);
    } else {
      let s1 = storeTypes?.filter(
        (a) => a.name.toLowerCase() === value.toLowerCase()
      );
      let s2 = storeTypes?.filter((a) =>
        a.name.toLowerCase().startsWith(value.toLowerCase())
      );
      let s3 = storeTypes?.filter((a) =>
        a.name.toLowerCase().includes(value.toLowerCase())
      );
      let s4 = [];
      for (let i = 0; i < storeTypes.length; i++) {
        let type = storeTypes[i];
        let tags = type.tags ?? [];
        if (Array.isArray(tags) && tags.length) {
          for (let j = 0; j < tags.length; j++) {
            if (
              tags[j].toLowerCase() === value.toLowerCase() ||
              tags[j].toLowerCase().startsWith(value.toLowerCase()) ||
              tags[j].toLowerCase().includes(value.toLowerCase())
            ) {
              s4.push(type);
              break;
            }
          }
        }
      }
      let final = [...new Set([...s1, ...s2, ...s3, ...s4])];
      setFilterStoreTypes(final);
    }
  };

  return (
    <CustomSelect
      type="store_type"
      selectedOption={selectedOption}
      data={filterStoreTypes?.length ? filterStoreTypes : storeTypes}
      onSelectChange={handleSelectChange}
      onSearchChange={handleSearchChange}
      paddingHead={size === "lg" && "14px 10px"}
      clickRef={typeRef && typeRef}
      selectRef={selectRef && selectRef}
      position={position}
      disableSearch={!storeTypes?.length && !filterStoreTypes?.length}
    />
  );
};

export default StoreTypeSelect;
