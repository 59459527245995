import React from "react";
import * as S from "./style";
import { DiscountOption } from "../../../constants/appConstants";

const ProductDiscount = ({ activeDiscount, setActiveDiscount, discount, handleInputChange }) => {
    return (
        <S.DiscountContainer>
            <S.SymbolDiv>
                <S.Symbol active={activeDiscount === DiscountOption.FLAT} onClick={() => setActiveDiscount(DiscountOption.FLAT)}>
                    ₱
                </S.Symbol>

                <S.MiddleBar></S.MiddleBar>

                <S.Symbol active={activeDiscount === DiscountOption.PERCENTAGE} onClick={() => setActiveDiscount(DiscountOption.PERCENTAGE)}>
                    %
                </S.Symbol>
            </S.SymbolDiv>
            <S.InputDiv>
                <input type="text" value={discount} onChange={(e) => handleInputChange(e)} />
            </S.InputDiv>
        </S.DiscountContainer>
    );
};

export default ProductDiscount;
