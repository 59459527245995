import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CloseModalIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { removeSelectedCustomer, setSelectedCustomer } from "../../../store/customer-slice";
import { addCustomer, getAllCustomers, getCustomer, updateCustomer } from "../../../api/local/customerApi";
import { debounce, isEmpty } from "lodash";
import { updateSale } from "../../../api/local/sale-api";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { captureException } from "../../../crash-reporting";
import CustomerSearchDropdown from "../../payment-modal-new/customer-search-dropdown/CustomerSearchDropdown";

const AddCustomerDialog = ({ open, onClose }) => {
    const [phoneNumber, _setPhoneNumber] = useState("");
    const phoneNumberRef = React.useRef(phoneNumber);
    const setPhoneNumber = (data) => {
        phoneNumberRef.current = data;
        _setPhoneNumber(data);
    };
    const [name, _setName] = useState("");
    const nameRef = React.useRef(name);
    const setName = (data) => {
        nameRef.current = data;
        _setName(data);
    };
    const [customer, _setCustomer] = useState(null);
    const customerRef = React.useRef(customer);
    const setCustomer = (data) => {
        customerRef.current = data;
        _setCustomer(data);
    };
    const { selectedSale } = useSelector((state) => state.sale);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [customersList, setCustomersList] = useState(null);
    const [openCustomerDrop, _setOpenCustomerDrop] = useState({ phone: true, name: false });
    const openCustomerDropRef = useRef(openCustomerDrop);
    const setOpenCustomerDrop = (data) => {
        openCustomerDropRef.current = data;
        _setOpenCustomerDrop(data);
    };
    const handleSelectCustomer = (data) => {
        getCustomerData(data.id);
        setOpenCustomerDrop({ phone: false, name: false });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
            if (phoneNumber && phoneNumber.length === 10) {
                getCustomerData(phoneNumber);
            }
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open && !openCustomerDropRef.current.name && !openCustomerDropRef.current.phone) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleSubmit();
            }
        }
    };

    const handleChangeCustomerInput = (e) => {
        let { value, name } = e.target;
        if (name === "phone") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value)) || value.length > 10) return;
            setPhoneNumber(value);
        } else if (name === "name") {
            setName(value);
        }
        if (value) {
            delayedQuery(value);
        } else {
            if (name === "phone") handleClearCustomerData();
        }
    };

    const delayedFunction = async (value) => {
        try {
            let res = await getAllCustomers({ search: value });
            if (res.status === 200) {
                setCustomersList(res.data.entity);
            } else if (res.status === 404) {
                setCustomersList(null);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    const handleClearCustomerData = () => {
        setPhoneNumber("");
        setName("");
        setCustomersList(null);
    };
    const selectDrop = (key) => {
        setCustomersList(null);
        if (key === "phone") {
            setOpenCustomerDrop({ phone: true, name: false });
        } else if (key === "name") {
            setOpenCustomerDrop({ phone: false, name: true });
        }
    };

    async function getCustomerData(val) {
        try {
            let res = await getCustomer(val);
            if (res.status === 200) {
                let customer = res.data.entity;
                setPhoneNumber(customer.phone);
                setName(customer.name);
                setCustomer(res.data);
            } else if (res.status !== 404) {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    }

    async function addCustomerData() {
        const payload = {
            phone: phoneNumberRef.current,
        };
        if (nameRef.current) {
            payload.name = nameRef.current;
        } else {
            payload.name = "NA";
        }
        try {
            let res = await addCustomer(payload);
            if (res.status === 200) {
                setCustomer(res.data);
                dispatch(setSelectedCustomer(res.data));
                handleUpdateSale();
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    }

    async function updateCustomerData() {
        try {
            const payload = {
                name: nameRef.current,
            };

            let res = await updateCustomer(phoneNumberRef.current, payload);
            // console.log(res);
            if (res.status === 200) {
                dispatch(setSelectedCustomer(res.data));
                handleUpdateSale();
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    }

    const handleUpdateSale = async () => {
        const payload = {};
        if (customerRef.current) {
            if (!selectedSale.patient || selectedSale.patient.phone !== customerRef.current?.phone) {
                payload.patient_id = customerRef.current?.id;
            }
        }

        if (!isEmpty(payload)) {
            try {
                let res = await updateSale(selectedSale.id, payload);
                if (res.status === 200) {
                    onClose();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Customer updated successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            }
        }
    };

    const handleSubmit = () => {
        if (customerRef.current) {
            if (customerRef.current?.name === nameRef.current) {
                dispatch(setSelectedCustomer(customerRef.current));
                handleUpdateSale();
            } else {
                updateCustomerData();
            }
        } else {
            console.log("add new customer");
            addCustomerData();
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.HeaderBar>
                    <div>Add Customer</div>
                    <img src={CloseModalIcon} alt="close modal icon" onClick={onClose} />
                </S.HeaderBar>
                <S.Body>
                    <S.CustomerContainer>
                        <S.InputContainers>
                            <S.Label>
                                Phone Number <span>*</span>
                            </S.Label>
                            <S.InputContainer>
                                <div>+63</div>
                                <S.Bar></S.Bar>
                                <input
                                    type="text"
                                    name="phone"
                                    autoFocus
                                    value={phoneNumber}
                                    onChange={handleChangeCustomerInput}
                                    onFocus={() => selectDrop("phone")}
                                />
                            </S.InputContainer>
                        </S.InputContainers>
                        {openCustomerDrop.phone && customersList && (
                            <CustomerSearchDropdown
                                data={customersList}
                                onSelectCustomer={handleSelectCustomer}
                                open={openCustomerDrop.phone && customersList}
                            />
                        )}
                    </S.CustomerContainer>
                    <S.CustomerContainer>
                        <S.InputContainers>
                            <S.Label>Name</S.Label>
                            <S.InputContainer>
                                <input type="text" name="name" value={name} onChange={handleChangeCustomerInput} onFocus={() => selectDrop("name")} />
                            </S.InputContainer>
                        </S.InputContainers>
                        {openCustomerDrop.name && customersList && (
                            <CustomerSearchDropdown
                                data={customersList}
                                onSelectCustomer={handleSelectCustomer}
                                open={openCustomerDrop.name && customersList}
                            />
                        )}
                    </S.CustomerContainer>
                </S.Body>
                <S.Bottom>
                    <S.SubmitBtn onClick={handleSubmit}>Submit (Enter)</S.SubmitBtn>
                </S.Bottom>
            </S.Wrapper>
        </Modal>
    );
};

export default AddCustomerDialog;
