import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    background: #fff;
    width: ${(props)=>props.size === 'sm'? "40px": props.size === 'md'? "60px": props.size === 'lg'? "100px":props.size ? props.size : "40px"};
    height: ${(props)=>props.size === 'sm'? "40px": props.size === 'md'? "60px": props.size === 'lg'? "100px":props.size ? props.size : "40px"};
    border-radius: 100%;

`;

export const Logo = styled.img`
    width: 100%;
    height: 100%;
  
`;
