import React from "react";
import * as S from "./avatar.style";

const Avatar = ({ name, color, size, onClick, noTooltip = false }) => {
    return (
        <S.Avatar color={color} size={size} onClick={onClick}>
            {name
                .split(" ")
                .map((e) => e[0])
                .slice(0, 2)
                .join("")}
            {!noTooltip && <span className="tooltip">{name}</span>}
        </S.Avatar>
    );
};

export default Avatar;
