import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DEMO_STORE, DEMO_STORE_EMPLOYEE } from "../constants/appConstants";
import { setSentryUser } from "../crash-reporting";

const initialState = {
  store: process.env.REACT_APP_DEMO_SERVER ? DEMO_STORE.store : null,
  store_config: null,
  employee: process.env.REACT_APP_DEMO_SERVER ? DEMO_STORE_EMPLOYEE : null,
  device: null,
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload.entity;
      if (action.payload.token) {
        localStorage.setItem("serviceToken", action.payload.token);
      }
      setSentryUser(action.payload.entity);
    },
    setDeviceData: (state, action) => {
      state.device = action.payload;
    },
    setEmployeeSession: (state, action) => {
      state.employee = action.payload;
    },
    removeEmployeeSession: (state, action) => {
      state.employee = null;
      localStorage.removeItem("serviceToken");
    },
    removeStore: (state, action) => {
      state.store = null;
      state.store_config = null;
      state.employee = null;
    },
    removeDeviceData: (state, action) => {
      state.device = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default storeSlice.reducer;
export const {
  setStore,
  setEmployeeSession,
  removeEmployeeSession,
  removeStore,
  setDeviceData,
  removeDeviceData,
} = storeSlice.actions;
