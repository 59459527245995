import {
  NewSale,
  ManageStaff,
  AddStaff,
  ViewCampaigns,
  NewCampaigns,
  ManageInventory,
  AddProducts,
  Suppliers,
  ViewPurchases,
  NewReturnDamage,
  NewPurchase,
  Discounts,
  Customers,
  ViewSales,
  OnlineOrders,
  NewReturn,
  LandingScreen1,
  LandingScreen2,
  LandingScreen3,
  Grocery,
  Clothes,
  BabyProducts,
  FoodOrders,
  Myntra,
  Amazon,
  Flipkart,
  CustomPosIcon,
  AdminIcon,
  EmployeeIcon,
  GlobalProductIcon,
  GlobalProductActiveIcon,
  GlobalOrdersActiveIcon,
  GlobalOrdersIcon,
  GlobalCustomersIcon,
  GlobalCustomersActiveIcon,
  CashIcon,
  UpiMoneyIcon,
  CardIcon,
  CashActiveIcon,
  UpiMoneyActiveIcon,
  CardActiveIcon,
  PayLaterDarkIcon,
  PayLaterActiveIcon,
  BasicIcon,
  BasicActiveIcon,
  ProductIcon,
  ProductActiveIcon,
  MetricIcon,
  MetricActiveIcon,
  ChequePayActiveIcon,
  ChequePayIcon,
  AnalyticsSalesIcon,
  AnalyticsSalesReturnIcon,
  AnalyticsPurchaseIcon,
  AnalyticsPurchaseReturnIcon,
  AnalyticsDamageIcon,
  AnalyticsExpensesIcon,
  InventoryReportIcon,
  ProfitLossReportIcon,
  OverallAnalyticReportIcon,
  CustomersReportIcon,
  InvoiceCashIcon,
  InvoiceUpiIcon,
  InvoiceCardIcon,
  InvoicePaylaterIcon,
} from "../icons";
import LandingBackground from "../assets/images/png/landing-screen-background.png";
import hsnData from "./hsn.json";
import { generateSKU } from "../utils/sku-upc-generator";

export const CRUD_CONSTANTS = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

export const PAGE_LIMIT = 20;

export const PRODUCT_STATUS = {
  PUBLISH: "PUBLISH",
  UN_PUBLISH: "UN_PUBLISH",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
  MY_ITEMS: "MY_ITEMS",
  ITEMS_LIBRARY: "ITEMS_LIBRARY",
};

export const PRODUCT_STATUS_MAPPING = {
  PUBLISH: "ACTIVE",
  UN_PUBLISH: "INACTIVE",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
};

export const STOCK_TYPE = {
  OUT_OF_STOCK: "out_of_stock",
  INFINITE: "infinite",
  LOW_STOCK: "low_stock",
  CUSTOM_STOCK: "custom_stock",
};

export const STOCK_MAP_TYPES = [
  {
    id: 1,
    name: "Low Stock",
    type: STOCK_TYPE.LOW_STOCK,
  },
  {
    id: 2,
    name: "Out of Stock",
    type: STOCK_TYPE.OUT_OF_STOCK,
  },
  {
    id: 3,
    name: "Custom Stock",
    type: STOCK_TYPE.CUSTOM_STOCK,
  },
];

export const STOCK_VALUES = {
  OUT_OF_STOCK: 0,
  INFINITE: -1,
};

export const INVENTORY_SORT_TEXT = "Date Created";
export const INVENTORY_STOCK_FILTER_TEXT = "Stock";

export const INVENTORY_SELECT = {
  SORT_SELECT: "sort-select",
  STOCK_SELECT: "stock-select",
  NORMAL_SELECT: "normal-select",
};

export const AGE_RESTRICTED_OPTIONS = [
  {
    id: 1,
    name: "18+",
    value: 18,
  },
  {
    id: 2,
    name: "21+",
    value: 21,
  },
  {
    id: 3,
    name: "22+",
    value: 22,
  },
  {
    id: 4,
    name: "23+",
    value: 23,
  },
  {
    id: 5,
    name: "24+",
    value: 24,
  },
  {
    id: 6,
    name: "25+",
    value: 25,
  },
  {
    id: 7,
    name: "None",
    value: null,
  },
];

export const METRIC_VALUES = [
  { id: 1, name: "g", value: "gm" },
  { id: 2, name: "kg", value: "kg" },
  { id: 3, name: "lb", value: "lb" },
  { id: 4, name: "oz", value: "oz" },
  { id: 5, name: "ton", value: "ton" },
  { id: 6, name: "l", value: "l" },
  { id: 7, name: "cups", value: "cups" },
  { id: 8, name: "pints", value: "pints" },
  { id: 9, name: "quarts", value: "quarts" },
  { id: 10, name: "gallons", value: "gallons" },
  { id: 11, name: "cm", value: "cm" },
  { id: 12, name: "m", value: "m" },
];

export const METRIC_MAPPING = {
  gm: "gm",
  kg: "kg",
  ml: "ml",
  l: "l",
  lb: "lb",
  oz: "oz",
  ton: "ton",
  cups: "cups",
  pints: "pints",
  quarts: "quarts",
  gallons: "gallons",
  cm: "cm",
  m: "m",
};

export const ATTRIBUTE_VALUES = [
  {
    id: 1,
    name: "Flavour",
    value: "flavour",
  },
  {
    id: 2,
    name: "Color",
    value: "color",
  },
  {
    id: 3,
    name: "Size",
    value: "size",
  },
];

export const ATTRIBUTE_MAPPING = {
  flavour: "Flavour",
  color: "Color",
  size: "Size",
};

export const MODAL_TYPES = {
  DELETE: "DELETE",
  DISCARD: "DISCARD",
};

export const DELETE_MODAL = {
  title: "Delete Product",
  bodyText:
    "Are you sure you want to delete this product? This cannot be undone.",
};

export const DISCARD_MODAL = {
  title: "Discard Changes",
  bodyText:
    "Are you sure you want to discard the changes. This cannot be undone.",
};

export const PURCHASE_STATUS = {
  PUBLISHED: "PUBLISH",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
  IN_REVIEW: "IN_REVIEW",
};

export const PURCHASE_STATUS_MAPPING = {
  PUBLISHED: "Completed",
  IN_REVIEW: "Pending",
};

export const PURCHASE_TYPE = {
  PURCHASE: "PURCHASE",
  RETURN: "RETURN",
  DAMAGE_ENTRY: "DAMAGE_ENTRY",
  PURCHASE_PROFORMA: "PURCHASE_PROFORMA",
  PURCHASE_ORDER: "PURCHASE_ORDER",
};

export const PURCHASE_TYPE_MAPPING = {
  PURCHASE: "Purchase",
  RETURN: "Return",
  DAMAGE_ENTRY: "Damage",
  PURCHASE_PROFORMA: "Purchase Proforma",
  PURCHASE_ORDER: "Purchase Order",
};

export const PURCHASE_SORT_LIST = [
  { id: 1, name: "Date Created (Low to High)", value: "created_at" },
  { id: 2, name: "Date Created (High to Low)", value: "created_at" },
  { id: 3, name: "Delivery Date (Low to High)", value: "delivery_date" },
  { id: 4, name: "Delivery Date (High to Low)", value: "delivery_date" },
  { id: 5, name: "Net Price (Low to High)", value: "net_price" },
  { id: 6, name: "Net Price (High to Low)", value: "net_price" },
  // { id: 7, name: "Stock (Low to High)", value: "stock" },
  // { id: 8, name: "Stock (High to Low)", value: "stock" },
  // { id: 9, name: "Date Modified (Low to High)", value: "date_modified" },
  // { id: 10, name: "Date Modified (High to Low)", value: "date_modified" },
];

export const PAYMENT_STATUS = [
  {
    id: 1,
    name: "Completed",
    value: true,
  },
  {
    id: 2,
    name: "Pending",
    value: false,
  },
];

export const ACCOUNT_TYPES = {
  CASH: "CASH",
  QR: "QR_CODE",
  BANK_ACCOUNT: "BANK_ACCOUNT",
};

export const STATES = [
  { id: 1, value: "Abra", name: "Abra" },
  { id: 2, value: "Agusan del Norte", name: "Agusan del Norte" },
  { id: 3, value: "Agusan del Sur", name: "Agusan del Sur" },
  { id: 4, value: "Aklan", name: "Aklan" },
  { id: 5, value: "Albay", name: "Albay" },
  { id: 6, value: "Antique", name: "Antique" },
  { id: 7, value: "Apayao", name: "Apayao" },
  { id: 8, value: "Aurora", name: "Aurora" },
  { id: 9, value: "Basilan", name: "Basilan" },
  { id: 10, value: "Bataan", name: "Bataan" },
  { id: 11, value: "Batanes", name: "Batanes" },
  { id: 12, value: "Batangas", name: "Batangas" },
  { id: 13, value: "Benguet", name: "Benguet" },
  { id: 14, value: "Biliran", name: "Biliran" },
  { id: 15, value: "Bohol", name: "Bohol" },
  { id: 16, value: "Bukidnon", name: "Bukidnon" },
  { id: 17, value: "Bulacan", name: "Bulacan" },
  { id: 18, value: "Cagayan", name: "Cagayan" },
  { id: 19, value: "Camarines Norte", name: "Camarines Norte" },
  { id: 20, value: "Camarines Sur", name: "Camarines Sur" },
  { id: 21, value: "Camiguin", name: "Camiguin" },
  { id: 22, value: "Capiz", name: "Capiz" },
  { id: 23, value: "Catanduanes", name: "Catanduanes" },
  { id: 24, value: "Cavite", name: "Cavite" },
  { id: 25, value: "Cebu", name: "Cebu" },
  { id: 26, value: "Cotabato", name: "Cotabato" },
  {
    id: 27,
    value: "Davao de Oro (Compostela Valley)",
    name: "Davao de Oro (Compostela Valley)",
  },
  { id: 28, value: "Davao del Norte", name: "Davao del Norte" },
  { id: 29, value: "Davao del Sur", name: "Davao del Sur" },
  { id: 30, value: "Davao Occidental", name: "Davao Occidental" },
  { id: 31, value: "Davao Oriental", name: "Davao Oriental" },
  { id: 32, value: "Dinagat Islands", name: "Dinagat Islands" },
  { id: 33, value: "Eastern Samar", name: "Eastern Samar" },
  { id: 34, value: "Guimaras", name: "Guimaras" },
  { id: 35, value: "Ifugao", name: "Ifugao" },
  { id: 36, value: "Ilocos Norte", name: "Ilocos Norte" },
  { id: 37, value: "Ilocos Sur", name: "Ilocos Sur" },
  { id: 38, value: "Iloilo", name: "Iloilo" },
  { id: 39, value: "Isabela", name: "Isabela" },
  { id: 40, value: "Kalinga", name: "Kalinga" },
  { id: 41, value: "La Union", name: "La Union" },
  { id: 42, value: "Laguna", name: "Laguna" },
  { id: 43, value: "Lanao del Norte", name: "Lanao del Norte" },
  { id: 44, value: "Lanao del Sur", name: "Lanao del Sur" },
  { id: 45, value: "Leyte", name: "Leyte" },
  { id: 46, value: "Maguindanao del Norte", name: "Maguindanao del Norte" },
  { id: 47, value: "Maguindanao del Sur", name: "Maguindanao del Sur" },
  { id: 48, value: "Marinduque", name: "Marinduque" },
  { id: 49, value: "Masbate", name: "Masbate" },
  { id: 50, value: "Metro Manila/NCR", name: "Metro Manila/NCR" },
  { id: 51, value: "Misamis Occidental", name: "Misamis Occidental" },
  { id: 52, value: "Misamis Oriental", name: "Misamis Oriental" },
  { id: 53, value: "Mountain Province", name: "Mountain Province" },
  { id: 54, value: "Negros Occidental", name: "Negros Occidental" },
  { id: 55, value: "Negros Oriental", name: "Negros Oriental" },
  { id: 56, value: "Northern Samar", name: "Northern Samar" },
  { id: 57, value: "Nueva Ecija", name: "Nueva Ecija" },
  { id: 58, value: "Nueva Vizcaya", name: "Nueva Vizcaya" },
  { id: 59, value: "Occidental Mindoro", name: "Occidental Mindoro" },
  { id: 60, value: "Oriental Mindoro", name: "Oriental Mindoro" },
  { id: 61, value: "Palawan", name: "Palawan" },
  { id: 62, value: "Pampanga", name: "Pampanga" },
  { id: 63, value: "Pangasinan", name: "Pangasinan" },
  { id: 64, value: "Quezon", name: "Quezon" },
  { id: 65, value: "Quirino", name: "Quirino" },
  { id: 66, value: "Rizal", name: "Rizal" },
  { id: 67, value: "Romblon", name: "Romblon" },
  { id: 68, value: "Samar", name: "Samar" },
  { id: 69, value: "Sarangani", name: "Sarangani" },
  { id: 70, value: "Siquijor", name: "Siquijor" },
  { id: 71, value: "Sorsogon", name: "Sorsogon" },
  { id: 72, value: "South Cotabato", name: "South Cotabato" },
  { id: 73, value: "Southern Leyte", name: "Southern Leyte" },
  { id: 74, value: "Sultan Kudarat", name: "Sultan Kudarat" },
  { id: 75, value: "Sulu", name: "Sulu" },
  { id: 76, value: "Surigao del Norte", name: "Surigao del Norte" },
  { id: 77, value: "Surigao del Sur", name: "Surigao del Sur" },
  { id: 78, value: "Tarlac", name: "Tarlac" },
  { id: 79, value: "Tawi-Tawi", name: "Tawi-Tawi" },
  { id: 80, value: "Zambales", name: "Zambales" },
  { id: 81, value: "Zamboanga del Norte", name: "Zamboanga del Norte" },
  { id: 82, value: "Zamboanga del Sur", name: "Zamboanga del Sur" },
  { id: 83, value: "Zamboanga Sibugay", name: "Zamboanga Sibugay" },
];
export const COUNTRY = [{ id: 1, name: "Philippines", value: "Philippines" }];
export const CUSTOMER_SORT = [
  { id: 1, name: "Name (A-Z)", value: "name" },
  { id: 2, name: "Name (Z-A)", value: "name" },
  { id: 3, name: "Date Created (Low to High)", value: "created_at" },
  { id: 4, name: "Date Created (High to Low)", value: "created_at" },
];
export const CUSTOMER_SORT_WITH_LP = [
  { id: 1, name: "Name (A-Z)", value: "name" },
  { id: 2, name: "Name (Z-A)", value: "name" },
  { id: 3, name: "Date Created (Low to High)", value: "created_at" },
  { id: 4, name: "Date Created (High to Low)", value: "created_at" },
  { id: 5, name: "Loyalty Point (Low to High)", value: "loyalty_point" },
  { id: 6, name: "Loyalty Point (High to Low)", value: "loyalty_point" },
];

export const SUPPLIER_SORT_LIST = [
  { id: 1, name: "Name (A-Z)", value: "name" },
  { id: 2, name: "Name (Z-A)", value: "name" },
  { id: 3, name: "Date Created (Low to High)", value: "created_at" },
  { id: 4, name: "Date Created (High to Low)", value: "created_at" },
  // { id: 5, name: "Date Modified (Low to High)", value: "date_modified" },
  // { id: 6, name: "Date Modified (High to Low)", value: "date_modified" },
];

export const SALES_SUBMENU = [
  { id: 1, name: "New Sale", navigate: "newsale", logo: NewSale },
  { id: 2, name: "New Return", navigate: "newreturn", logo: NewReturn },
  {
    id: 3,
    name: "Online Orders",
    navigate: "onlineorders",
    logo: OnlineOrders,
  },
  { id: 4, name: "View Sales", navigate: "viewsales", logo: ViewSales },
  { id: 5, name: "Customers", navigate: "customers", logo: Customers },
  { id: 6, name: "Discounts", navigate: "discounts", logo: Discounts },
];

export const EMPLOYEE_STATUS = [
  { id: 1, name: "Active", value: true },
  { id: 2, name: "Not Active", value: false },
];

export const EMPLOYEE_SORT_LIST = [
  { id: 1, name: "Name (A-Z)", value: "name" },
  { id: 2, name: "Name (Z-A)", value: "name" },
  { id: 3, name: "Date Created (Low to High)", value: "created_at" },
  { id: 4, name: "Date Created (High to Low)", value: "created_at" },
  // { id: 5, name: "Date Modified (Low to High)", value: "date_modified" },
  // { id: 6, name: "Date Modified (High to Low)", value: "date_modified" },
];

export const BANK_ACCOUNT_TYPES = [
  { id: 1, name: "CASH", value: "CASH" },
  { id: 2, name: "QR", value: "QR_CODE" },
  { id: 3, name: "BANK ACCOUNT", value: "BANK_ACCOUNT" },
];

export const KYC_TYPES = [
  { id: 1, name: "Aadhaar Card", value: "Aadhaar Card" },
  { id: 2, name: "Voter Card", value: "Voter Card" },
  { id: 3, name: "Driving Licence", value: "Driving Licence" },
  { id: 4, name: "PAN Card", value: "PAN Card" },
];

export const LANDING_PAGE_DATA = {
  HEADING: "Only partner you will ever need",
  SUB_HEADING:
    "Get a complete package from where you can manage sales, purchases, inventory, staff, customers, suppliers, and get analytical reports",
  BACKGROUND_IMAGE: LandingBackground,
};

export const LINKS = {
  TERMS_AND_CONDITIONS: "https://www.homedrop.in/terms-conditions",
  PRIVACY_POLICY: "https://www.homedrop.in/privacy-policy",
  CONTACT_US: "https://www.homedrop.in/contact-us",
};

export const TIME_PERIOD = [
  { id: 1, name: "Hourly", value: "Hourly" },
  { id: 2, name: "Daily", value: "Daily" },
  { id: 3, name: "Weekly", value: "Weekly" },
  { id: 4, name: "Monthly", value: "Monthly" },
  { id: 5, name: "Quarterly", value: "Quarterly" },
  { id: 6, name: "Yearly", value: "Yearly" },
];

export const SALE_TABS = {
  SALE: "Sale",
  RETURNS: "Return",
  PROFORMA_INVOICE: "PROFORMA_INVOICE",
  DELIVERY_CHALLAN: "DELIVERY_CHALLAN",
};

export const SALE_TABLE_TABS = {
  ALL: "All",
  PUBLISHED: "Publish",
  DRAFT: "Draft",
};

export const SALE_SORT_LIST = [
  { id: 1, name: "Date Created (Low to High)", value: "created_at" },
  { id: 2, name: "Date Created (High to Low)", value: "created_at" },
  // { id: 3, name: "Date Modified (Low to High)", value: "date_modified" },
  // { id: 4, name: "Date Modified (High to Low)", value: "date_modified" },
  { id: 5, name: "Total Quantity (Low to High)", value: "total_quantity" },
  { id: 6, name: "Total Quantity (High to Low)", value: "total_quantity" },
  { id: 7, name: "Net Amount (Low to High)", value: "net_amount" },
  { id: 8, name: "Net Amount (High to Low)", value: "net_amount" },
  { id: 9, name: "Net Profit (Low to High)", value: "net_profit" },
  { id: 10, name: "Net Profit (High to Low)", value: "net_profit" },
];
export const AUTH_SLIDER_DATA = [
  {
    id: 1,
    image: LandingScreen1,
    text: "Manage your sales and purchase on one platform",
  },
  {
    id: 2,
    image: LandingScreen2,
    text: "Analyse and Manage your inventory",
  },
  {
    id: 3,
    image: LandingScreen3,
    text: "Sell everything online",
  },
];

export const SETTINGS_TAB = {
  ACCOUNT: "Account",
  SHORTCUT_KEYS: "Shortcut Keys",
  BACKUP: "Backup",
  SECURITY: "Security",
  QR_CODE: "Mobile App",
  LOCATION: "Address",
  CONFIGURATION: "Store Timings",
  DELIVERY: "Delivery",
  LOYALTY: "Loyalty",
  PRINTER_SETTINGS: "Printer Settings",
  PREFERENCES: "Preferences",
};
export const POSSIBLE_CASH_VALUES = [
  5, 10, 20, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000,
  2500, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
];

export const DiscountOption = {
  PERCENTAGE: "PERCENTAGE",
  FLAT: "FLAT",
};

export const AVATAR_COLORS = [
  "#3591BA",
  "#FFB573",
  "#AE75BC",
  "#F35958",
  "#B7BA35",
  "#FF8473",
  "#BC7586",
  "#64D861",
  "#282C92",
  "#EE32B9",
];
export const DUE_TYPE = {
  ALL_DUE: "All due",
  NO_DUE: "No due",
  CUSTOM_DUE: "custom_due",
};
export const STORE_TYPES = [
  {
    id: 1,
    title: "Grocery",
    icon: Grocery,
  },
  {
    id: 2,
    title: "Clothes",
    icon: Clothes,
  },
  {
    id: 3,
    title: "Baby Products",
    icon: BabyProducts,
  },
  {
    id: 4,
    title: "Food Order",
    icon: FoodOrders,
  },
];

export const POS_TYPES = [
  {
    id: 1,
    title: "Myntra",
    icon: Myntra,
  },
  {
    id: 2,
    title: "Amazon",
    icon: Amazon,
  },
  {
    id: 3,
    title: "Flipkart",
    icon: Flipkart,
  },
  {
    id: 4,
    title: "Custom",
    icon: CustomPosIcon,
  },
];
export const MEMBER_TYPES = [
  {
    id: 1,
    title: "Co - Admin",
    icon: AdminIcon,
    info: true,
  },
  {
    id: 2,
    title: "Sales Manager",
    icon: EmployeeIcon,
    info: true,
  },
  {
    id: 3,
    title: "Purchase Manager",
    icon: EmployeeIcon,
    info: true,
  },
  {
    id: 4,
    title: "Custom",
    icon: EmployeeIcon,
    info: false,
  },
];

export const PERMISSIONS = [
  {
    id: 1,
    title: "Sales",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
  {
    id: 2,
    title: "Purchase",
    subTitle: "Were you already using any?",
    isChecked: true,
  },
  {
    id: 3,
    title: "Sales Return",
    subTitle: "Were you already using any?",
    isChecked: true,
  },
  {
    id: 4,
    title: "Purchase Return",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
  {
    id: 5,
    title: "Discount",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
  {
    id: 6,
    title: "Expenses",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
  {
    id: 7,
    title: "Staff Adding",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
  {
    id: 8,
    title: "Damage",
    subTitle: "Were you already using any?",
    isChecked: false,
  },
];
export const SCREENS = {
  PURCHASE: 0,
  RETURNS: 1,
  DAMAGE_ENTRY: 2,
};
export const TABS = [
  {
    id: 1,
    name: "Product",
    value: "Product",
    icon: GlobalProductIcon,
    activeIcon: GlobalProductActiveIcon,
  },
  // {
  //     id: 2,
  //     name: "Orders",
  //     value: "Orders",
  //     icon: GlobalOrdersIcon,
  //     activeIcon: GlobalOrdersActiveIcon,
  // },
  // {
  //     id: 3,
  //     name: "Customers",
  //     value: "Customers",
  //     icon: GlobalCustomersIcon,
  //     activeIcon: GlobalCustomersActiveIcon,
  // },
];
export const PAYMENT_MODES = [
  {
    id: 1,
    name: "CASH",
    value: "Cash",
    icon: CashIcon,
    activeIcon: CashActiveIcon,
  },
  {
    id: 2,
    name: "QR_CODE",
    value: "QR",
    icon: UpiMoneyIcon,
    activeIcon: UpiMoneyActiveIcon,
  },
  {
    id: 3,
    name: "CC_DC",
    value: "Debit or Credit Card",
    icon: CardIcon,
    activeIcon: CardActiveIcon,
  },
  {
    id: 4,
    name: "PAY_LATER",
    value: "Pay later",
    icon: PayLaterDarkIcon,
    activeIcon: PayLaterActiveIcon,
  },
];
export const PRODUCT_DRAWER_TABS = [
  {
    id: 0,
    name: "Basic Information",
    icon: BasicIcon,
    activeIcon: BasicActiveIcon,
  },
  {
    id: 1,
    name: "Product Information",
    icon: ProductIcon,
    activeIcon: ProductActiveIcon,
  },
  {
    id: 2,
    name: "Metrics",
    icon: MetricIcon,
    activeIcon: MetricActiveIcon,
  },
];

export const INVENTORY_SORT_LIST = [
  { id: 1, name: "Name (A-Z)", search_key: "name" },
  { id: 2, name: "Name (Z-A)", search_key: "name" },
  { id: 3, name: "Price (Low to High)", search_key: "price" },
  { id: 4, name: "Price (High to Low)", search_key: "price" },
  { id: 5, name: "Stock (Low to High)", search_key: "stock" },
  { id: 6, name: "Stock (High to Low)", search_key: "stock" },
  { id: 7, name: "Date Created (Low to High)", search_key: "created_at" },
  { id: 8, name: "Date Created (High to Low)", search_key: "created_at" },
];

export const PURCHASE_PAYMENT_MODES = [
  {
    id: 1,
    name: "CASH",
    value: "Cash",
    icon: CashIcon,
    activeIcon: CashActiveIcon,
  },
  {
    id: 2,
    name: "QR_CODE",
    value: "QR",
    icon: UpiMoneyIcon,
    activeIcon: UpiMoneyActiveIcon,
  },
  {
    id: 3,
    name: "CC_DC",
    value: "Debit or Credit Card",
    icon: CardIcon,
    activeIcon: CardActiveIcon,
  },
  {
    id: 4,
    name: "PAY_LATER",
    value: "Pay later",
    icon: PayLaterDarkIcon,
    activeIcon: PayLaterActiveIcon,
  },
  {
    id: 5,
    name: "CHEQUE",
    value: "Cheque",
    icon: ChequePayIcon,
    activeIcon: ChequePayActiveIcon,
  },
];

// export const ONLINE_ORDERS_STATUS = [
//     {
//         id: 1,
//         title: "New Order",
//     },
//     {
//         id: 2,
//         title: "Packed",
//     },
//     {
//         id: 3,
//         title: "Out for delivery",
//     },
// ];
export const ONLINE_ORDERS_STATUS = {
  NEW_ORDER: "New Order",
  PACKED: "Packed",
  OUT_FOR_DELIVERY: "Out For Delivery",
  DELIVERED: "Completed",
};

export const ONLINE_ORDER_PARAMS_STATUS = {
  ORDERED: "SALE_ONLINE_ORDERED",
  PACKING: "SALE_ONLINE_PACKING",
  PACKED: "SALE_ONLINE_PACKED",
  OUT_FOR_DELIVERY: "SALE_ONLINE_OUT_FOR_DELIVERY",
  DELIVERED: "SALE_ONLINE_DELIVERED",
  CANCELLED: "SALE_ONLINE_CANCELLED",
};

export const CANCEL_REASONS = [
  { id: 1, name: "Products are not available in stock.", value: false },
  { id: 2, name: "Products are either damaged/expired.", value: false },
  { id: 3, name: "Store is closed.", value: false },
  {
    id: 4,
    name: "Store will be closed by the time delivery executive will arrive.",
    value: false,
  },
  { id: 5, name: "Other (Please explain below)", value: false },
];

export const ONLINE_ORDERS_FOCUS = {
  ORDERS_SECTION: "ORDERS_SECTION",
  PRODUCTS_SECTION: "PRODUCTS_SECTION",
};

export const EXPENSES_TYPES = [
  { id: 1, name: "Electricity", value: "Electricity" },
  { id: 2, name: "Rent", value: "Rent" },
  { id: 3, name: "Food & Beverages", value: "Food & Beverages" },
  { id: 4, name: "Transportation", value: "Transportation" },
  { id: 5, name: "Salary", value: "Salary" },
  { id: 5, name: "Telephone & Internet", value: "Telephone & Internet" },
  { id: 5, name: "Repair & Maintenance", value: "Repair & Maintenance" },
  { id: 5, name: "Printing & Stationary", value: "Printing & Stationary" },
  { id: 5, name: "Bank Fee & Charges", value: "Bank Fee & Charges" },
  { id: 6, name: "Miscellaneous", value: "Miscellaneous" },
];

export const EXPENSES_SORT_LIST = [
  { id: 1, name: "Amount (Low to High)", value: "amount" },
  { id: 2, name: "Amount (High to Low)", value: "amount" },
  { id: 3, name: "Date Created (Low to High)", value: "created_at" },
  { id: 4, name: "Date Created (High to Low)", value: "created_at" },
];

export const REPEAT_TYPES = [
  { id: 1, name: "Once", value: "once" },
  { id: 2, name: "Daily", value: "daily" },
  { id: 3, name: "Weekly", value: "weekly" },
  { id: 4, name: "Monthly", value: "monthly" },
];
export const PAYMENT_MODE_SUMMARY = {
  CASH: "Cash",
  QR_CODE: "QR",
  CC_DC: "Debit or Credit Card",
  PAY_LATER: "Pay Later",
  CHEQUE: "Cheque",
  LOYALTY_POINT: "Loyalty Point",
};

export const PAYLATER_PAYMENT_MODES = [
  {
    id: 1,
    name: "CASH",
    value: "Cash",
    icon: CashIcon,
    activeIcon: CashActiveIcon,
  },
  {
    id: 2,
    name: "QR_CODE",
    value: "QR",
    icon: UpiMoneyIcon,
    activeIcon: UpiMoneyActiveIcon,
  },
  {
    id: 3,
    name: "CC_DC",
    value: "Debit or Credit Card",
    icon: CardIcon,
    activeIcon: CardActiveIcon,
  },
  {
    id: 4,
    name: "CHEQUE",
    value: "Cheque",
    icon: ChequePayIcon,
    activeIcon: ChequePayActiveIcon,
  },
];

export const WEEK_DAYS = [
  {
    id: 1,
    name: "Monday",
    value: "mon",
  },
  {
    id: 2,
    name: "Tuesday",
    value: "tue",
  },
  {
    id: 3,
    name: "Wednesday",
    value: "wed",
  },
  {
    id: 4,
    name: "Thursday",
    value: "thu",
  },
  {
    id: 5,
    name: "Friday",
    value: "fri",
  },
  {
    id: 6,
    name: "saturday",
    value: "sat",
  },
  {
    id: 7,
    name: "Sunday",
    value: "sun",
  },
];

export const STORE_OFFLINE_REASONS = [
  { id: 1, name: "aaa", value: false },
  { id: 2, name: "bbb", value: false },
  { id: 3, name: "ccc", value: false },
  { id: 4, name: "ddd", value: false },
];

export const ONLINE_SETTINGS_TAB = {
  DELIVERY_AREA: "Delivery Area",
  DELIVERY_RANGE: "Delivery Range",
};

export const DEMO_STORE_EMPLOYEE = {
  username: "9387391066",
  name: "Gaurav Bordoloi",
  email: null,
  phone: "9387391066",
  is_activated: true,
  permission_role: {
    sale: ["CREATE", "DELETE", "READ", "UPDATE"],
    purchase: ["CREATE", "DELETE", "READ", "UPDATE"],
    supplier: ["CREATE", "DELETE", "READ", "UPDATE"],
    product: ["CREATE", "DELETE", "READ", "UPDATE"],
    employee: ["CREATE", "DELETE", "READ", "UPDATE"],
    customer: ["CREATE", "DELETE", "READ", "UPDATE"],
    discount: ["CREATE", "DELETE", "READ", "UPDATE"],
    settings: ["READ", "UPDATE"],
    marketing: ["CREATE", "READ"],
    permission_role: ["CREATE", "DELETE", "READ", "UPDATE"],
  },
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IjkzODczOTEwNjYiLCJuYW1lIjoiR2F1cmF2IEJvcmRvbG9pIiwicGVybWlzc2lvbnMiOnsic2FsZSI6WyJDUkVBVEUiLCJERUxFVEUiLCJSRUFEIiwiVVBEQVRFIl0sInB1cmNoYXNlIjpbIkNSRUFURSIsIkRFTEVURSIsIlJFQUQiLCJVUERBVEUiXSwic3VwcGxpZXIiOlsiQ1JFQVRFIiwiREVMRVRFIiwiUkVBRCIsIlVQREFURSJdLCJwcm9kdWN0IjpbIkNSRUFURSIsIkRFTEVURSIsIlJFQUQiLCJVUERBVEUiXSwiZW1wbG95ZWUiOlsiQ1JFQVRFIiwiREVMRVRFIiwiUkVBRCIsIlVQREFURSJdLCJjdXN0b21lciI6WyJDUkVBVEUiLCJERUxFVEUiLCJSRUFEIiwiVVBEQVRFIl0sImRpc2NvdW50IjpbIkNSRUFURSIsIkRFTEVURSIsIlJFQUQiLCJVUERBVEUiXSwic2V0dGluZ3MiOlsiUkVBRCIsIlVQREFURSJdLCJtYXJrZXRpbmciOlsiQ1JFQVRFIiwiUkVBRCJdLCJwZXJtaXNzaW9uX3JvbGUiOlsiQ1JFQVRFIiwiREVMRVRFIiwiUkVBRCIsIlVQREFURSJdfSwiaWF0IjoxNjgyNzA2OTAxLCJleHAiOjE3MTQyNjQ1MDF9.zroDfB4jzmUkaQNzERN_JnIfrouMBImvNNWnyX821xc",
};

export const DEMO_STORE = {
  config: {
    is_server: true,
    installed_on: 0,
    sale_online: false,
  },
  temp: {},
  sync: {
    pull_last_synced: 1682701471648,
  },
  store: {
    created_at: 1682701239467,
    online_settings: {
      reason: [],
      is_online: true,
    },
    store_address: {
      line_1: "Jalukbari back street",
      country: "Philippines",
      line_2: "Jalukbari",
      lat_lng: [26.143316395126096, 91.66309827046483],
      city: "Kamrup Metropolitan",
      pin_code: "781013",
      state: "Assam",
    },
    available_online: true,
    server_config: {
      updated_at: 1682706556886,
      conn_info: {
        mode: "28:87:ba:ef:84:02",
        security_flags: "WPA2-Personal",
        iface: "Wi-Fi",
        signal_level: 192.5,
        security: "802.11ac",
        bssid: "Cloud Nine GF_5G",
        channel: null,
        ssid: "connected",
        mac: "Cloud Nine GF_5G",
        frequency: null,
        quality: 585,
      },
      ip: "192.168.0.161",
    },
    delivery: {
      amount: 50,
    },
    logo: "https://content.homedrop.in/homedrop-dark.png",
    updated_at: 1682706701053,
    contact_person: "Gaurav Bordoloi",
    timings: {
      thu: [540, 1260],
      tue: [540, 1260],
      sat: [540, 1260],
      wed: [540, 1260],
      fri: [540, 1260],
      mon: [540, 1260],
      sun: [540, 1260],
    },
    store_location: {
      coordinates: [91.68539214675714, 26.123297319378285],
      type: "point",
    },
    country_code: "+63",
    delivery_data: [
      {
        delivery_time: "30",
        delivery_charge: "15",
        end_in_kms: "3",
        free_delivery_above_cart_value: "1000",
        start_in_kms: 0,
      },
      {
        delivery_time: "45",
        delivery_charge: "35",
        end_in_kms: "6",
        free_delivery_above_cart_value: "2000",
        start_in_kms: "3",
      },
      {
        delivery_time: "60",
        delivery_charge: "50",
        end_in_kms: "10",
        free_delivery_above_cart_value: "3000",
        start_in_kms: "6",
      },
    ],
    delivery_area: {
      coordinates: [
        [
          [91.6342591591367, 26.150404757416243],
          [91.65176861958592, 26.168585978684337],
          [91.67923443989842, 26.17166726072549],
          [91.7001771278867, 26.172283507361847],
          [91.71905987935155, 26.171051010831754],
          [91.7382859535703, 26.175364691681477],
          [91.75030224995702, 26.15964980031856],
          [91.74892895894139, 26.124822989291268],
          [91.74240582661717, 26.106326655538048],
          [91.72523968892186, 26.097077391149625],
          [91.67339795308202, 26.10355195303263],
          [91.65451520161717, 26.110334442910485],
          [91.61159985737889, 26.086902355527336],
          [91.61159985737889, 26.11711653930017],
          [91.6342591591367, 26.150404757416243],
        ],
      ],
      type: "polygon",
    },
    store_name: "Demo Store",
    uid: "09639da3-fc8e-403d-b8a1-3481bb24a2ef",
    phone: "9387391066",
    email: "info@homedrop.in",
    address: {
      line_1: "Jalukbari back street",
      country: "Philippines",
      line_2: "Jalukbari",
      lat_lng: [26.143316395126096, 91.66309827046483],
      city: "Kamrup Metropolitan",
      pin_code: "781013",
      state: "Assam",
    },
    access_token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIwOTYzOWRhMy1mYzhlLTQwM2QtYjhhMS0zNDgxYmIyNGEyZWYiLCJzdG9yZV9uYW1lIjoiRGVtbyBTdG9yZSIsImNvbnRhY3RfcGVyc29uIjoiR2F1cmF2IEJvcmRvbG9pIiwiZW1haWwiOiJpbmZvQGhvbWVkcm9wLmluIiwicGhvbmUiOiI5Mzg3MzkxMDY2IiwiaWF0IjoxNjgyNzA2NzAxLCJleHAiOjE3MTQyNjQzMDF9.D4x-S6aSOZRgdrC7MTNaFYDCU405w09q6I2D4N0KcVk",
  },
};

export const PROFIT_LOSS_REPORT = [
  {
    id: 1,
    name: "Sales",
    key: "total_sale",
    icon: AnalyticsSalesIcon,
  },
  {
    id: 2,
    name: "Sales Return",
    key: "total_sale_return",
    icon: AnalyticsSalesReturnIcon,
  },
  {
    id: 3,
    name: "Purchase",
    key: "total_purchase",
    icon: AnalyticsPurchaseIcon,
  },
  {
    id: 4,
    name: "Purchase Return",
    key: "total_purchase_return",
    icon: AnalyticsPurchaseReturnIcon,
  },
  {
    id: 5,
    name: "Damage",
    key: "total_damage",
    icon: AnalyticsDamageIcon,
  },
  {
    id: 6,
    name: "Expenses",
    key: "total_expense",
    icon: AnalyticsExpensesIcon,
  },
];

export const DOWNLOAD_REPORT_KEYS = {
  ALL: "ALL",
  SALES: "SALES",
  SALES_RETURN: "SALES_RETURN",
  PURCHASE: "PURCHASE",
  PURCHASE_RETURN: "PURCHASE_RETURN",
  DAMAGE: "DAMAGE",
  EXPENSES: "EXPENSES",
  INVENTORY: "INVENTORY",
  CASH_DRAWER: "CASH_DRAWER",
  PROFIT_LOSS: "PROFIT_LOSS",
  OVERALL: "OVERALL",
  CUSTOMERS: "CUSTOMERS",
  GSTR1: "GSTR1",
  GSTR2: "GSTR2",
  GSTR3B: "GSTR3B",
  GSTR9: "GSTR9",
  EXCISE: "EXCISE",
};

export const REPORT_TYPES = {
  SALE: "SALE",
  RETURN: "RETURN",
  PURCHASE: "PURCHASE",
  DAMAGE_ENTRY: "DAMAGE_ENTRY",
};

export const DOWNLOAD_REPORTS = [
  {
    id: 1,
    name: "Sales Report",
    key: DOWNLOAD_REPORT_KEYS.SALES,
    icon: AnalyticsSalesIcon,
  },
  {
    id: 2,
    name: "Sales Return Report",
    key: DOWNLOAD_REPORT_KEYS.SALES_RETURN,
    icon: AnalyticsSalesReturnIcon,
  },
  {
    id: 3,
    name: "Purchase Report",
    key: DOWNLOAD_REPORT_KEYS.PURCHASE,
    icon: AnalyticsPurchaseIcon,
  },
  {
    id: 4,
    name: "Purchase Return Report",
    key: DOWNLOAD_REPORT_KEYS.PURCHASE_RETURN,
    icon: AnalyticsPurchaseReturnIcon,
  },
  {
    id: 5,
    name: "Damage Report",
    key: DOWNLOAD_REPORT_KEYS.DAMAGE,
    icon: AnalyticsDamageIcon,
  },
  {
    id: 6,
    name: "Expenses Report",
    key: DOWNLOAD_REPORT_KEYS.EXPENSES,
    icon: AnalyticsExpensesIcon,
  },
  {
    id: 7,
    name: "Inventory Report",
    key: DOWNLOAD_REPORT_KEYS.INVENTORY,
    icon: InventoryReportIcon,
  },
  // {
  //     id: 8,
  //     name: "Cash Drawer Report",
  //     key: DOWNLOAD_REPORT_KEYS.CASH_DRAWER,
  //     icon: AnalyticsPurchaseReturnIcon,
  // },
  // {
  //     id: 9,
  //     name: "Profit Loss Report",
  //     key: DOWNLOAD_REPORT_KEYS.PROFIT_LOSS,
  //     icon: ProfitLossReportIcon,
  // },
  // {
  //     id: 10,
  //     name: "Overall Report",
  //     key: DOWNLOAD_REPORT_KEYS.OVERALL,
  //     icon: OverallAnalyticReportIcon,
  // },
  // {
  //     id: 11,
  //     name: "Customers Report",
  //     key: DOWNLOAD_REPORT_KEYS.CUSTOMERS,
  //     icon: CustomersReportIcon,
  // },
  {
    id: 12,
    name: "GSTR 1",
    key: DOWNLOAD_REPORT_KEYS.GSTR1,
    icon: ProfitLossReportIcon,
  },
  {
    id: 13,
    name: "GSTR 2",
    key: DOWNLOAD_REPORT_KEYS.GSTR2,
    icon: ProfitLossReportIcon,
  },
  {
    id: 14,
    name: "GSTR 3B",
    key: DOWNLOAD_REPORT_KEYS.GSTR3B,
    icon: ProfitLossReportIcon,
  },
  {
    id: 14,
    name: "GSTR 9",
    key: DOWNLOAD_REPORT_KEYS.GSTR9,
    icon: ProfitLossReportIcon,
  },
];

export const DOWNLOAD_REPORTS_LEFT = [
  {
    id: 1,
    name: "Sales Report",
    key: DOWNLOAD_REPORT_KEYS.SALES,
    icon: AnalyticsSalesIcon,
  },
  {
    id: 2,
    name: "Sales Return Report",
    key: DOWNLOAD_REPORT_KEYS.SALES_RETURN,
    icon: AnalyticsSalesReturnIcon,
  },
  {
    id: 3,
    name: "Purchase Report",
    key: DOWNLOAD_REPORT_KEYS.PURCHASE,
    icon: AnalyticsPurchaseIcon,
  },
  {
    id: 4,
    name: "Purchase Return Report",
    key: DOWNLOAD_REPORT_KEYS.PURCHASE_RETURN,
    icon: AnalyticsPurchaseReturnIcon,
  },
  {
    id: 5,
    name: "Damage Report",
    key: DOWNLOAD_REPORT_KEYS.DAMAGE,
    icon: AnalyticsDamageIcon,
  },
  {
    id: 6,
    name: "Expenses Report",
    key: DOWNLOAD_REPORT_KEYS.EXPENSES,
    icon: AnalyticsExpensesIcon,
  },
];

export const DOWNLOAD_REPORTS_RIGHT = [
  {
    id: 7,
    name: "Inventory Report",
    key: DOWNLOAD_REPORT_KEYS.INVENTORY,
    icon: InventoryReportIcon,
  },
  {
    id: 12,
    name: "GSTR 1",
    key: DOWNLOAD_REPORT_KEYS.GSTR1,
    icon: ProfitLossReportIcon,
  },
  {
    id: 13,
    name: "GSTR 2",
    key: DOWNLOAD_REPORT_KEYS.GSTR2,
    icon: ProfitLossReportIcon,
  },
  {
    id: 14,
    name: "GSTR 3B",
    key: DOWNLOAD_REPORT_KEYS.GSTR3B,
    icon: ProfitLossReportIcon,
  },
  {
    id: 14,
    name: "GSTR 9",
    key: DOWNLOAD_REPORT_KEYS.GSTR9,
    icon: ProfitLossReportIcon,
  },
];

export const REPORTS_TYPE = {
  ALL_REPORT: "ALL_REPORT",
  SALE_REPORT: "SALE_REPORT",
  PURCHASE_REPORT: "PURCHASE_REPORT",
  EXPENSE_REPORT: "EXPENSE_REPORT",
  INVENTORY_REPORT: "INVENTORY_REPORT",
  GSTR1_REPORT: "GSTR1_REPORT",
  GSTR2_REPORT: "GSTR2_REPORT",
  GSTR3B_REPORT: "GSTR3B_REPORT",
  GSTR9_REPORT: "GSTR9_REPORT",
};

export const PURCHASE_ROUTES = [
  "purchases",
  "new-purchase",
  "view-purchase",
  "new-purchase-return",
  "new-damage-entry",
  "suppliers",
];
export const SALES_ROUTES = [
  "sales",
  "new-sale",
  "all-sales",
  "customers",
  "view-sale",
  "return-sale",
  "proforma-invoice",
];
export const ONBOARDING_TEXTS = {
  LOGIN_FLOW: {
    IMPORT_STORE: {
      text: "Importing store data",
      loading: true,
    },
  },
  REGISTRATION_FLOW: {
    CREATE_INVENTORY: {
      text: "Creating your inventory",
      loading: true,
    },
    IMPORT_PRODUCTS: {
      text: "Importing products",
      loading: true,
    },
  },
  LOGGING_STORE: {
    text: "Logging in to your store...",
    loading: true,
  },
};

export const DEFAULT_MAP_POSITION = {
  lat: 28.620624152268903,
  lng: 77.20624682781705,
};

export const PASSWORD_CHANGE_STATE = {
  LOGIN: "LOGIN",
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  NEW_PASSWORD: "NEW_PASSWORD",
  LIMIT: "LIMIT",
};

export const ACTIVE_ONLINE_SALE_TEXTS = [
  {
    id: 1,
    text: "Press ‘Start Fulfilment’ when you start packing an order for delivery.",
  },
  {
    id: 2,
    text: "Scan products to add (like New Sales), view the ordered quantity and increase/decrease the quantities packed.",
  },
  {
    id: 3,
    text: "Press ‘Complete’ to complete the packing.",
  },
  {
    id: 4,
    text: "Press ‘Out For Delivery’ when you have sent the order for delivery to customer’s doorstep.",
  },
  {
    id: 5,
    text: "Press ‘Mark as Delivered’ when the order is successfully delivered to customer.",
  },
];
export const INACTIVE_ONLINE_SALE_TEXTS = [
  {
    id: 1,
    text: "Please check your internet connection to view and receive online orders.",
  },
  {
    id: 2,
    text: "Restore internet fast, as your store is currently shown offline/closed to your customers.",
  },
  {
    id: 3,
    text: "If internet is working, check if you have switched off your online store in “Online Sales > Settings“.",
  },
];

export const DELETE_SKU_MODAL_DATA = {
  head: "Delete SKU?",
  subHead: "Are you sure you want to delete this SKU?",
};

export const ADJUSTMENT_TYPE = {
  REDUCE_CASH: "DEBIT",
  INCREASE_CASH: "CREDIT",
};

export const CASH_DRAWER_TYPES = [
  { id: 1, name: "DEBIT", value: ADJUSTMENT_TYPE.REDUCE_CASH },
  { id: 2, name: "CREDIT", value: ADJUSTMENT_TYPE.INCREASE_CASH },
];

export const CashDrawerReferenceType = {
  SALE: "SALE",
  SALE_RETURN: "SALE_RETURN",
  PURCHASE: "PURCHASE",
  PURCHASE_RETURN: "PURCHASE_RETURN",
  EXPENSE: "EXPENSE",
};

export const CASH_DRAWER_REFERENCE_TYPES = [
  { id: 1, name: "SALE", value: CashDrawerReferenceType.SALE },
  { id: 2, name: "SALE RETURN", value: CashDrawerReferenceType.SALE_RETURN },
  { id: 3, name: "PURCHASE", value: CashDrawerReferenceType.PURCHASE },
  {
    id: 4,
    name: "PURCHASE RETURN",
    value: CashDrawerReferenceType.PURCHASE_RETURN,
  },
  { id: 5, name: "EXPENSE", value: CashDrawerReferenceType.EXPENSE },
];

export const BUY_PLAN_STATES = {
  CREATE_PLAN: "CREATE_PLAN",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAIL: "PAYMENT_FAIL",
};

export const PRINTERS = {
  THERMAL: "Thermal Printer",
  BARCODE: "Barcode Printer",
};

export const PREFERENCES_TYPES = {
  SALE_PREFERENCES: "Sale Preferences",
  LOYALTY: "Loyalty",
  PRINTING: "Printing",
  MORE: "More Coming Soon",
};

export const SALES_TYPE = {
  SALE: "SALE",
  RETURN: "RETURN",
  PROFORMA_INVOICE: "PROFORMA_INVOICE",
  DELIVERY_CHALLAN: "DELIVERY_CHALLAN",
};

export const SALE_STATUS = {
  PUBLISHED: "PUBLISH",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
};

export const ONLINE_SALE_CRON_TIME = 5 * 60 * 1000;

export const PRICE_TYPE = [
  { id: 1, name: "With Tax", value: true },
  { id: 2, name: "Without Tax", value: false },
];

export const HSN_DATA = hsnData;

export const UNITS_LIST = [
  { id: 1, name: "Mg", value: "mg" },
  { id: 2, name: "Gram", value: "gm" },
  { id: 3, name: "Kg", value: "kg" },
  { id: 4, name: "Ml", value: "ml" },
  { id: 5, name: "Litre", value: "l" },
  { id: 6, name: "Piece", value: "pcs" },
  { id: 7, name: "Cm", value: "cm" },
  { id: 8, name: "Meter", value: "m" },
  { id: 9, name: "Box", value: "box" },
  { id: 10, name: "Dozen", value: "doz" },
  { id: 11, name: "Carton", value: "ctn" },
  { id: 12, name: "Bag", value: "bag" },
  { id: 13, name: "Bottle", value: "bottle" },
  { id: 14, name: "Packet", value: "pkt" },
  { id: 15, name: "Roll", value: "roll" },
  { id: 16, name: "Feet", value: "feet" },
  { id: 17, name: "Inch", value: "inch" },
  { id: 18, name: "Numbers", value: "nos" },
  { id: 19, name: "Sq Ft", value: "sq_ft" },
  { id: 20, name: "Sq Mt", value: "sq_mt" },
  { id: 21, name: "Pound", value: "lbs" },
  { id: 22, name: "Ounce", value: "oz" },
  { id: 23, name: "Ton", value: "ton" },
  { id: 24, name: "Cup", value: "cup" },
  { id: 25, name: "Pint", value: "pint" },
  { id: 26, name: "Quart", value: "quart" },
  { id: 27, name: "Gallon", value: "gln" },
  { id: 28, name: "Pack", value: "pack" },
  { id: 29, name: "Pair", value: "pair" },
  { id: 30, name: "Cans", value: "cans" },
  { id: 31, name: "Strip", value: "strip" },
  { id: 32, name: "Tbs", value: "tbs" },
];

export const TAX_VALUES = [
  {
    id: 23,
    name: "None",
    value: {},
  },
  {
    id: 1,
    name: "0% VAT",
    value: { vat: 0 },
  },
  {
    id: 2,
    name: "12% VAT",
    value: { vat: 12 },
  },
];

export const SALE_BILL_TYPE = {
  POS: "POS",
  INVOICE: "INVOICE",
};

export const SALE_SCREENS = {
  TABLE: "TABLE",
  SUMMARY: "SUMMARY",
};

export const INVOICE_PAYMENT_MODES = [
  {
    id: 1,
    label: "Cash",
    name: "CASH",
    icon: InvoiceCashIcon,
  },
  {
    id: 2,
    label: "QR",
    name: "QR_CODE",
    icon: InvoiceUpiIcon,
  },
  {
    id: 3,
    label: "Card",
    name: "CC_DC",
    icon: InvoiceCardIcon,
  },
  {
    id: 4,
    label: "Pay Later",
    name: "PAY_LATER",
    icon: InvoicePaylaterIcon,
  },
];

export const PAYMENT = {
  CASH: "CASH",
  QR_CODE: "QR",
  CC_DC: "CARD",
  PAY_LATER: "PAY_LATER",
  CHEQUE: "CHEQUE",
  LOYALTY_POINT: "LOYALTY_POINT",
};

export const PAYMENT_MODE_LIST = [
  {
    id: 1,
    name: "Cash",
    value: PAYMENT.CASH,
  },
  {
    id: 2,
    name: "QR",
    value: PAYMENT.QR_CODE,
  },
  {
    id: 3,
    name: "Debit or Credit Card",
    value: PAYMENT.CC_DC,
  },
  {
    id: 4,
    name: "Cheque",
    value: PAYMENT.CHEQUE,
  },
  {
    id: 5,
    name: "Loyalty Point",
    value: PAYMENT.LOYALTY_POINT,
  },
  {
    id: 6,
    name: "Pay Later",
    value: PAYMENT.PAY_LATER,
  },
];

export const COLOR = {
  BLUE: "#2D68FE",
  RED: "#FF4343",
  ORANGE: "#e86c00",
};

export const FILTER_BILL_TYPES = [
  {
    id: 1,
    name: "POS Bill",
    value: SALE_BILL_TYPE.POS, //bill_type = posbill
  },
  {
    id: 2,
    name: "INVOICE Bill",
    value: SALE_BILL_TYPE.INVOICE, //billtype = invoice bill
  },
  {
    id: 3,
    name: "Proforma Invoice",
    value: SALES_TYPE.PROFORMA_INVOICE, // type = proforma
  },
];
export const FILTER_PURCHASE_BILL_TYPES = [
  {
    id: 1,
    name: "Purchase",
    value: PURCHASE_TYPE.PURCHASE,
  },
  {
    id: 2,
    name: "Purchase Proforma",
    value: PURCHASE_TYPE.PURCHASE_PROFORMA,
  },
  {
    id: 3,
    name: "Purchase Order",
    value: PURCHASE_TYPE.PURCHASE_ORDER,
  },
];

export const PRODUCT_DRAWER_TYPE = {
  ADD: "add",
  EDIT: "edit",
};

export const MONTHS = [
  {
    id: 1,
    name: "January",
    value: "January",
  },
  {
    id: 2,
    name: "February",
    value: "February",
  },
  {
    id: 3,
    name: "March",
    value: "March",
  },
  {
    id: 4,
    name: "April",
    value: "April",
  },
  {
    id: 5,
    name: "May",
    value: "May",
  },
  {
    id: 6,
    name: "June",
    value: "June",
  },
  {
    id: 7,
    name: "July",
    value: "July",
  },
  {
    id: 8,
    name: "August",
    value: "August",
  },
  {
    id: 9,
    name: "September",
    value: "September",
  },
  {
    id: 10,
    name: "October",
    value: "October",
  },
  {
    id: 11,
    name: "November",
    value: "November",
  },
  {
    id: 12,
    name: "December",
    value: "December",
  },
];

export const INVOICE_TYPE = {
  SALE: "SALE",
  PURCHASE: "PURCHASE",
};

export const ModalType = {
  Sale: "sale",
  SaleReturn: "return-sale",
  ConvertToInvoice: "convert-to-invoice",
  ProformaInvoice: "proforma-invoice",
  InvoiceSale: "invoice-sale",
  Purchase: "purchase",
  PurchaseReturn: "return-purchase",
  NewPurchaseReturn: "new-purchase-return",
  DamageEntry: "damage-entry",
  ConvertToPurchase: "convert-to-purchase",
};

export const ScreenType = {
  NewPurchase: "New Purchase",
  NewPurchaseReturn: "New Purchase Return",
  NewDamageEntry: "New Damage Entry",
};

export const STATUS = {
  PUBLISHED: "PUBLISH",
  DRAFT: "DRAFT",
  TRASH: "TRASH",
  IN_REVIEW: "IN_REVIEW",
};

export const INITIAL_PRODUCT_DISPLAY_DATA = {
  sku: generateSKU(),
  upc: "",
  brand: {},
  category: {},
  type: {},
  age_restricted: {},
  short_description: "",
  name: "",
  purchase_price: "",
  price: "",
  sale_price: "",
  tax: {},
  tax: {},
  stock: null,
  min_quantity: null,
  max_quantity: null,
  metrics: [{ unit: "", value: "" }],
  attributes: [["", ""]],
  low_stock_warning: "",
  sale_price_with_tax: { name: "With Tax", value: true },
  purchase_price_with_tax: { name: "With Tax", value: true },
  wholesale_price: "",
  wholesale_quantity: "",
  enable_alternate_unit: false,
  alternate_unit: {
    base_unit: "",
    secondary_unit: "",
    base_conversion: "",
    secondary_conversion: "",
  },
  batch_number: "",
  manufacturing_date: null,
  expiry_date: null,
  hsn: "",
  discount: 0,
  activeDiscount: DiscountOption.FLAT,
};
