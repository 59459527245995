import React from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { useEffect } from "react";

const DeleteModal = ({ open, onClose, handleDelete }) => {
    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                onDelete(e);
            }
        }
    };

    const onDelete = (e) => {
        e.stopPropagation();
        handleDelete();
        onClose();
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Wrapper>
                <S.Heading>Are you sure want to delete ?</S.Heading>
                <S.BtnContainer>
                    <S.CancelBtn
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        Cancel
                    </S.CancelBtn>
                    <S.ConfirmBtn
                        onClick={(e) => {
                            onDelete(e);
                        }}
                    >
                        Delete
                    </S.ConfirmBtn>
                </S.BtnContainer>
            </S.Wrapper>
        </Modal>
    );
};

export default DeleteModal;
