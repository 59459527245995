import React, { useCallback, useContext, useEffect, useState, useImperativeHandle } from "react";
import { debounce } from "lodash";
import CustomSelect from "../../components/product-drawer/custom-select/custom-select";
import PaginationBar from "../../components/inventory/pagination-bar/pagination-bar";
import SuppliersTable from "../../components/suppliers-table/suppliers-table";
import SelectedChips from "../../components/selected-chips/selected-chips";
import { AddCustomerSearchIcon, CloseModalIcon } from "../../icons";
import * as S from "./styles";
import { removeAllSearchParams, removeSearchParams, setSearchParams } from "../../store/supplier-slice";
import { removeAllSearchParams as removeAllPurchaseSearchParams } from "../../store/purchase-slice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBrands, removeSelectedBrand } from "../../store/brand-slice";
import NewSupplierModal from "../../components/new-supplier-modal/new-supplier-modal";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { COLOR, SUPPLIER_SORT_LIST } from "./../../constants/appConstants";
import { useRef } from "react";
import CustomButton from "../../components/custom-button/CustomButton";

const initalFilterData = {
    brand: {},
    sort: {},
    supplier: {},
    employee: {},
    payment_status: {},
    date: "",
};

const Suppliers = (props, ref) => {
    const dispatch = useDispatch();
    const [filterData, setFilterData] = useState(initalFilterData);
    const brandsData = useSelector((state) => state.brand);
    const searchParams = useSelector((state) => state.supplier.searchParams);
    const [supplierSearch, setSupplierSearch] = useState("");
    const [sortText, setSortText] = useState("Name (A-Z)");
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const inputRef = useRef();
    const brandRef = useRef();
    const pageRef = useRef();
    const tableRef = useRef();
    const [check, setCheck] = useState(false);

    const delayedFunction = (val, page) => {
        if (val) {
            if (page) {
                dispatch(setSearchParams({ search: val, page: 1 }));
            } else {
                dispatch(setSearchParams({ search: val }));
            }
        } else {
            dispatch(removeSearchParams("search"));
        }
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    useEffect(() => {
        dispatch(fetchAllBrands());
        dispatch(setSearchParams({ show_transactions: true }));
        return () => {
            dispatch(removeAllSearchParams());
            dispatch(removeSelectedBrand());
            dispatch(removeAllPurchaseSearchParams());
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            searchName: () => inputRef.current?.focus(),
            searchBrand: () => brandRef.current?.click(),
            addSupplier: () => setOpenAddSupplierModal(true),
            clearFilter: () => handleClearFilter(),
        };
    });
    const handleClearFilter = () => {
        setFilterData(initalFilterData);
        dispatch(removeSearchParams("search"));
        dispatch(removeSearchParams("sort_by"));
        dispatch(removeSearchParams("is_reversed"));
        dispatch(removeSearchParams("brand_id"));
        dispatch(removeSearchParams("page"));
    };
    const handleSupplierSearchChange = (e) => {
        setSupplierSearch(e.target.value);
        delayedQuery(e.target.value, searchParams.page);
    };

    const handleSearchChange = (key, value) => {
        if (key === "brand") dispatch(fetchAllBrands(value));
    };

    const handleSelectChange = (key, item) => {
        if (key === "brand") {
            setFilterData({ ...filterData, brand: item });
            dispatch(setSearchParams({ brand_id: item.id }));
            dispatch(fetchAllBrands());
        } else if (key === "sort") {
            setFilterData({ ...filterData, sort: item });
            // dispatch(removeSearchParams("is_reversed"));
            const payload = { sort_by: item.value };
            if (+item.id % 2 === 0) payload.is_reversed = true;
            if (+item.id % 2 === 1) payload.is_reversed = false;
            dispatch(setSearchParams(payload));
        }
    };

    const clearSearch = () => {
        setSupplierSearch("");
        dispatch(removeSearchParams("search"));
    };

    const onClickCheckChange = (e) => {
        e.stopPropagation();
        if (check) {
            handleCheckBoxChange(false);
        } else {
            handleCheckBoxChange(true);
        }
    };
    const handleCheckBoxChange = (checked) => {
        dispatch(setSearchParams({ pending_payment: checked }));
        setCheck(checked);
    };

    return (
        <>
            <NewSupplierModal open={openAddSupplierModal} onClose={() => setOpenAddSupplierModal(false)} onSave={() => {}} />
            <S.Container>
                <S.HeaderContainer>
                    <S.Headline>Suppliers</S.Headline>
                    <CustomButton label="Add Supplier" color={COLOR.BLUE} fill={true} onClick={() => setOpenAddSupplierModal(true)} size="lg" />
                </S.HeaderContainer>
                <S.Body>
                    <S.Content>
                        <S.FiltersContainer>
                            <S.FiltersLeft>
                                <S.InputContainer>
                                    <img src={AddCustomerSearchIcon} alt="search" />
                                    <S.Input
                                        value={supplierSearch}
                                        onChange={handleSupplierSearchChange}
                                        type="text"
                                        placeholder="Search by name, phone or email"
                                        ref={inputRef}
                                    />
                                    {supplierSearch && <img onClick={clearSearch} src={CloseModalIcon} alt="clear" />}
                                </S.InputContainer>
                                <CustomSelect
                                    type="brand"
                                    initalText="Brand"
                                    onSearchChange={handleSearchChange}
                                    data={brandsData.brands || []}
                                    onSelectChange={handleSelectChange}
                                    selectedOption={filterData?.brand?.name}
                                    menuWidth="235px"
                                    clickRef={brandRef}
                                    // paddingHead="15.5px 10px"
                                />

                                <S.CheckBoxContainer onClick={onClickCheckChange}>
                                    <input
                                        type="checkbox"
                                        checked={check}
                                        id="pending_payment"
                                        onChange={(e) => handleCheckBoxChange(e.target.checked)}
                                    />
                                    <label>Show Pending Payments</label>
                                </S.CheckBoxContainer>
                            </S.FiltersLeft>
                            <S.FiltersRight>
                                Sort:
                                <CustomSelect
                                    type="sort"
                                    initalText={sortText}
                                    disableSearch
                                    data={SUPPLIER_SORT_LIST}
                                    selectedOption={filterData?.sort}
                                    onSelectChange={handleSelectChange}
                                    menuWidth="235px"
                                    // paddingHead="14px 10px"
                                    position="left"
                                />
                            </S.FiltersRight>
                        </S.FiltersContainer>
                        <S.ChipsContainer>
                            <SelectedChips displayData={filterData} setDisplayData={setFilterData} clearSearch={clearSearch} />
                        </S.ChipsContainer>
                        <SuppliersTable openAddSupplierModal={openAddSupplierModal} tableRef={tableRef} />
                        <S.PageContainer>
                            <PaginationBar type="supplier" ref={pageRef} tableRef={tableRef} />
                        </S.PageContainer>
                    </S.Content>
                </S.Body>
            </S.Container>
        </>
    );
};

export default React.forwardRef(Suppliers);
