import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
`;

export const OrderWrapper = styled.div`
    width: 404px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 16px 27px 0 0;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;
`;
export const Head = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1e293b;
`;

export const HintBox = styled.div`
    background: #dce9ff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    color: #253238;
    padding: 2px 4px;

    & span {
        font-weight: 700;
    }
`;

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(226, 226, 226, 0.25);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 12px;
    margin-bottom: 16px;
`;
export const Tab = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => (props.active ? "#2d68fe" : "#64748b")};
    padding: 12px 0;
    cursor: pointer;
    border-bottom: 2px solid ${(props) => (props.active ? "#2d68fe" : "#fff")};
`;
export const OrderList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100vh - 196px);
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
`;
export const ProductWrapper = styled.div`
    width: calc(100% - 404px);
    background-color: #fff;
    border-radius: 3px;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 11px 24px;
`;
export const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 42px;
`;
export const OrderId = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #263238;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #263238;
    margin-bottom: 6px;
`;
export const PickupDateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    color: #263238;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
`;
export const DeliveryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & img {
        width: 24px;
        height: 24px;
    }
`;
export const DeliveryName = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #263238;
`;

export const DeliveryNumber = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #263238;
`;
export const Deliverydetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
export const Body = styled.div`
    height: calc(100vh - 162px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const BtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 68px;
    gap: 26px;
    box-sizing: border-box;
    padding: 0 34px;
`;
export const RedBtn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #f91919;
    cursor: pointer;
`;

export const BlueBtn = styled.div`
    background: #2d68fe;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
`;
export const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 0 24px;
    height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
`;
export const EmptyOrderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 196px);
    border-radius: 8px;
    background: #fff;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 236px;
        height: 236px;
    }
`;
