import React, { useContext, useEffect, useState, useImperativeHandle } from "react";
import { Calendar } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIcon } from "../../icons";
import * as S from "./ReturnSale.style";
import * as locales from "react-date-range/dist/locale";
import { useDispatch, useSelector } from "react-redux";
import ReturnSaleTable from "../../components/sales/return-sale/ReturnSaleTable";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useNavigate } from "react-router-dom";
import { addSale } from "../../api/local/sale-api";
import BackButton from "./../../components/back-button/BackButton";
import { formatDate } from "./../../utils/format-date";
import { useRef } from "react";
import { addDays } from "date-fns";
import { getReturnCartTotal, getReturnSaleTotals, resetReturnAdditionalCharges, setReturnCartDiscount } from "../../store/returnsale-slice";
import ReturnSummary from "../../components/sales/return-summary/ReturnSummary";
import history from "../../utils/history";
import PurchasePaymentModal from "../../components/purchase-payment-modal/PurchasePaymentModal";
import { captureException } from "../../crash-reporting";
import { isEmpty } from "lodash";
import { PAYMENT, PAYMENT_MODES, SALE_BILL_TYPE } from "../../constants/appConstants";
import { convertTax, getQuantity } from "../../utils/global-fn";

const ReturnSale = (props, ref) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [createdDate, setCreatedDate] = useState(new Date());
    const { selectedSale } = useSelector((state) => state.sale);
    const {
        returnSaleProducts,
        returnSaleQuantity,
        selectedReturnSale,
        returnSaleTotal,
        returnCartDiscount,
        returnAdditionalCharges,
        totalReturnAdditionalCharges,
        returnCartTotal,
    } = useSelector((state) => state.returnSale);
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [sale, _setSale] = useState(null);
    const [billno, setBillno] = useState("");
    const [paymentMode, setPaymentMode] = useState(null);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const billRef = useRef();
    const paymentRef = useRef();
    const dispatch = useDispatch();
    const returnSaleRef = useRef(returnSaleProducts);

    const setSale = (data) => {
        returnSaleRef.current = data;
        _setSale(data);
    };
    const [openSaleReturnSummaryModal, setOpenSaleReturnSummaryModal] = useState(false);

    useEffect(() => {
        // setSale(selectedSale.products.map((item) => ({ ...item, isChecked: false })));
        setSale(returnSaleProducts);
        dispatch(getReturnSaleTotals());
        return () => {
            dispatch(setReturnCartDiscount(0));
            dispatch(resetReturnAdditionalCharges());
        };
    }, [returnSaleProducts]);

    useEffect(() => {
        dispatch(getReturnCartTotal());
    }, [returnSaleProducts, returnCartDiscount, returnAdditionalCharges]);

    useImperativeHandle(ref, () => {
        return {
            billFocus: () => billRef.current?.focus(),
            paymentFocus: () => paymentRef.current?.click(),
            clearCheck: () => handleClearCheck(),
            onRefund: () => handleRefund(),
            backAction: () => onBackAction(),
        };
    });

    const onBackAction = () => {
        if (openDatePicker) {
            setOpenDatePicker(false);
        } else if (openPaymentModal) {
            setOpenPaymentModal(false);
        } else {
            handleBackAction();
        }
    };

    function handleClearCheck() {
        let tempSale = sale.map((item) => {
            return { ...item, isChecked: false };
        });
        setSale(tempSale);
    }

    const handleBackAction = () => {
        // navigate("/dashboard/view-sale");
        history.back();
    };

    const handleRefund = () => {
        if (!returnSaleQuantity) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a product",
                type: "error",
            });
            return;
        }
        if (selectedReturnSale.payment?.payment_mode === PAYMENT.PAY_LATER) {
            handleReturnSale();
        } else if (selectedReturnSale.payment) {
            let hasPayLater = false;
            for (let m of selectedReturnSale.payment.payment_modes) {
                if (m.payment_mode === PAYMENT.PAY_LATER) {
                    hasPayLater = true;
                    break;
                }
            }
            if (hasPayLater) handleReturnSale();
            else setOpenPaymentModal(true);
        } else {
            setOpenPaymentModal(true);
        }
    };

    async function handleReturnSale() {
        const payload = {
            type: "RETURN",
            return_id: selectedReturnSale.id,
            bill_type: selectedReturnSale.bill_type ?? null,
        };

        payload.items = returnSaleProducts
            .filter((item) => item.isChecked)
            .map((item) => {
                let manualDiscountV1 = null;
                if (item.manual_discount) {
                    manualDiscountV1 = { 
                        type: item.manual_discount.type,
                        value: (item.manual_discount.value / item.quantity) * item.returnQuantity
                    }
                }
                let p = {
                    sku: item.sku,
                    name: item.name,
                    quantity: parseFloat(item.returnQuantity),
                    manual_discount: manualDiscountV1,
                    sale_price: Number((+item.sale_price).toFixed(2)),
                    price: Number(item.price.toFixed(2)),
                    unit: item.unit,
                    sale_price_with_tax: item.sale_price_with_tax,
                    tax: convertTax(item),
                    hsn: item.hsn,
                    batch_number: item.batch_number,
                    manufacturing_date: item.manufacturing_date ? item.manufacturing_date : null,
                    expiry_date: item.expiry_date ? item.expiry_date : null,
                    enable_alternate_unit: item.enable_alternate_unit,
                    alternate_unit: item.alternate_unit,
                    is_custom_product: item.is_custom_product ? true : false,
                };
                let qty = getQuantity(item, selectedReturnSale?.bill_type);
                if (qty >= item.wholesale_quantity) {
                    p.wholesale_price = item.wholesale_price;
                    p.wholesale_quantity = item.wholesale_quantity;
                }
                return p;
            });

        if (!payload.items.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a product",
                type: "error",
            });
            return;
        }
        if (selectedReturnSale.patient) {
            payload.patient_id = selectedReturnSale.patient.id;
        }

        if (createdDate) {
            payload.created_at = createdDate.getTime();
        }

        if (billno) {
            payload.bill_no = billno;
        }
        if (returnCartDiscount) payload.cart_manual_discount = parseFloat(returnCartDiscount);
        if (!isEmpty(returnAdditionalCharges)) {
            let addCharges = [];
            for (let key in returnAdditionalCharges) {
                if (returnAdditionalCharges[key]) {
                    let pl = {
                        field: key,
                        value: parseFloat(returnAdditionalCharges[key]),
                    };
                    addCharges.push(pl);
                }
            }

            payload.additional_charges = addCharges;
        }

        const payment = createPaymentModelV1();
        if (!isEmpty(payment)) {
            payload.payment = payment;
        }

        try {
            let res = await addSale(payload);
            if (res.status === 200) {
                navigate("/dashboard/all-sales");
                dispatch(setReturnCartDiscount(0));
                dispatch(resetReturnAdditionalCharges());
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Sale returned",
                    subtitle: "Your sale has been returned successfully.",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }

    const createPaymentModelV1 = () => {
        let payment = {};
        let finalReturnSaleTotal = returnCartTotal;
        payment.total_amount = finalReturnSaleTotal;
        payment.amount_due = Math.round(finalReturnSaleTotal);
        payment.is_paid = false;

        let payment_modes = [];

        let payment_payload = {
            created_at: Date.now(),
            payment_mode: PAYMENT.PAY_LATER,
            payment_amount: Math.round(finalReturnSaleTotal),
        };
        payment_modes.push(payment_payload);

        payment.payment_modes = payment_modes;

        return payment;
    };

    return (
        <S.Wrapper>
            <PurchasePaymentModal
                open={openPaymentModal}
                onClose={() => setOpenPaymentModal(false)}
                type="return-sale"
                returnSaleBill={billno}
                rsCreatedDate={createdDate}
                openSaleReturnSummaryModal={openSaleReturnSummaryModal}
                setOpenSaleReturnSummaryModal={setOpenSaleReturnSummaryModal}
            />
            <S.Top>
                <S.Topbar>
                    <S.Path>
                        <BackButton onClickAction={handleBackAction} />
                        <S.Heading>Select items to return</S.Heading>
                    </S.Path>
                    <S.DatePicker>
                        <S.Created>Date</S.Created>
                        <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                            <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                {formatDate(createdDate)}
                                <img src={CalendarIcon} alt="calendar" />
                            </S.DateChooserSelect>
                            {openDatePicker && (
                                <S.DatePickerContainer>
                                    <Calendar
                                        onChange={(item) => {
                                            setCreatedDate(item);
                                            setOpenDatePicker(false);
                                        }}
                                        locale={locales["enUS"]}
                                        date={createdDate}
                                        minDate={addDays(new Date(selectedSale.created_at), 0)}
                                        maxDate={new Date()}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </OutsideClickHandler>
                    </S.DatePicker>
                </S.Topbar>

                <S.Body>
                    <ReturnSaleTable sale={returnSaleRef.current} setSale={setSale} />
                    <ReturnSummary
                        type={"Return"}
                        billno={billno}
                        setBillno={setBillno}
                        paymentMode={paymentMode}
                        setPaymentMode={setPaymentMode}
                        billRef={billRef}
                        paymentRef={paymentRef}
                    />
                </S.Body>
            </S.Top>
            <S.Bottom>
                <S.BottomLeftContainer>
                    {selectedSale.employee && (
                        <S.EmployeeDetails>
                            <S.Avatar>
                                {selectedSale.employee.name
                                    .split(" ")
                                    .map((el) => el[0])
                                    .join("")
                                    .toUpperCase()}
                            </S.Avatar>
                            <S.EmployeeTag>Employer:</S.EmployeeTag>
                            <S.EmployeeName>{selectedSale.employee.name}</S.EmployeeName>
                        </S.EmployeeDetails>
                    )}

                    {/* <S.SaleId>Sale ID: #{selectedSale.id}</S.SaleId> */}
                </S.BottomLeftContainer>

                <S.BottomRightContainer>
                    {/* <S.DeleteBtn onClick={handleDeleteSale}>Delete</S.DeleteBtn> */}
                    <S.SaveBtn onClick={handleRefund}>Refund (F12)</S.SaveBtn>
                </S.BottomRightContainer>
            </S.Bottom>
        </S.Wrapper>
    );
};

export default React.forwardRef(ReturnSale);
