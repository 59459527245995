import axios from "./axios";
import { PERMISSION_ROLE_API } from "../../constants/apiRoutes";

const addPermission = async (payload) => {
  return await axios.post(PERMISSION_ROLE_API, payload);
};

const getPermission = async (roleId) => {
  return await axios.get(`${PERMISSION_ROLE_API}/${roleId}`);
};

const getAllPermissions = (searchKey) => {
  const queryParams = {};
  if (searchKey) queryParams.search = searchKey;
  return axios.get(PERMISSION_ROLE_API, {
    params: queryParams,
  });
};

export { addPermission, getPermission, getAllPermissions };
