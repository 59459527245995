import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { COLOR } from "../../constants/appConstants";

const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#d9d9d9",
                        "--TextField-brandBorderHoverColor": "#B2BAC2",
                        "--TextField-brandBorderFocusedColor": COLOR.ORANGE,
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                        // height: "38px",
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        "--Adornment-textColor": "#334155",
                        "& .Mui-focused": {
                            color: "var(----Adornment-textColor)",
                        },
                        fontSize: 12,
                    },
                },
            },
        },
    });

// type="search" for clear icon

const InputField = ({
    value,
    onChange,
    label,
    name,
    placeholder,
    helperText,
    error,
    required,
    disabled,
    type,
    readOnly,
    autoFocus,
    multiline,
    rows,
    fullWidth,
    prefix,
}) => {
    const outerTheme = useTheme();

    const handleInputChange = (event) => {
        onChange(event);
    };

    return (
        <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
                id="outlined-controlled"
                type={type}
                name={name}
                label={label}
                placeholder={placeholder}
                value={value}
                onChange={(event) => handleInputChange(event)}
                size="small"
                InputProps={{
                    style: { fontSize: 12, color: "#334155" },
                    readOnly: readOnly,
                    startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
                }}
                InputLabelProps={{
                    sx: {
                        color: "#737373",
                        fontSize: 12,
                        "&.MuiOutlinedInput-notchedOutline": { fontSize: 12 },
                    },
                }}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                autoFocus={autoFocus}
                multiline={multiline}
                rows={rows}
                fullWidth={fullWidth}
            />
        </ThemeProvider>
    );
};

export default InputField;
