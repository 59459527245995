import { cloneDeep } from "lodash";
import React, { useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AGE_RESTRICTED_OPTIONS, TAX_VALUES } from "../../../constants/appConstants";
import { SearchIcon } from "../../../icons";
import CustomInput from "../custom-input/custom-input";
import CustomSelect from "../custom-select/custom-select";
import Label from "../label/Label";
import SelectedChip from "../selected-chip/selected-chip";

import * as S from "./product-information.styles";
import { fetchAllBrands } from "../../../store/brand-slice";
import { fetchAllCategories } from "../../../store/category-slice";
import { captureException } from "../../../crash-reporting";
import { addBrand } from "../../../api/local/brandApi";
import { addCategory } from "../../../api/local/categoryApi";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { getTaxName } from "../../../utils/global-fn";

const ProductInformation = ({ formData, setFormData, displayData, setDisplayData, type, selectRef }) => {
    const brands = useSelector((state) => state.brand.brands);
    const categories = useSelector((state) => state.category.categories);
    const dispatch = useDispatch();
    const [chipValue, setChipValue] = useState("");
    const [closeMenu, setCloseMenu] = useState(false);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    const handleSelectChange = (key, item) => {
        if (key === "tax") {
            setFormData({ ...formData, tax: item.value });
            setDisplayData({ ...displayData, tax: item });
        } else if (key === "tax") {
            setFormData({ ...formData, tax: item.value });
            setDisplayData({ ...displayData, tax: item });
        } else if (key === "age_restricted") {
            setFormData({ ...formData, age_restricted: item.value });
            setDisplayData({ ...displayData, age_restricted: item });
        } else if (key === "brand") {
            setFormData({ ...formData, brand: item.id });
            setDisplayData({ ...displayData, brand: item });
        } else if (key === "category") {
            setFormData({ ...formData, category: item.id });
            setDisplayData({ ...displayData, category: item });
        }
    };

    const updateData = (key, value) => {
        if (["min_quantity", "max_quantity"].includes(key) && +value < 0) return;
        if (key === "max_quantity" && displayData.max_quantity > displayData.min_quantity && +value <= +displayData.min_quantity) return;
        setFormData({ ...formData, [key]: value });
        setDisplayData({ ...displayData, [key]: value });
    };

    const handleSearchChange = (key, value) => {
        if (key === "brand") dispatch(fetchAllBrands(value));
        else if (key === "category") dispatch(fetchAllCategories(value));
    };

    const handleChipChange = (e) => {
        if (e.key === "Enter") {
            const formDataCopy = cloneDeep(formData);
            formDataCopy.tags = formDataCopy.tags ? [...formDataCopy.tags, e.target.value] : [e.target.value];
            setFormData(formDataCopy);
            setDisplayData({ ...displayData, tags: formDataCopy.tags });
            setChipValue("");
        } else setChipValue(e.target.value);
    };
    const handleRemovechip = (val) => {
        if (type === "edit") {
            const filteredTags = displayData.tags.filter((tag) => tag !== val);
            setFormData({ ...formData, tags: filteredTags });
            setDisplayData({ ...displayData, tags: filteredTags });
        } else {
            const filteredTags = formData.tags.filter((tag) => tag !== val);
            setFormData({ ...formData, tags: filteredTags });
            setDisplayData({ ...displayData, tags: filteredTags });
        }
    };

    const handleDescriptionChange = (e) => {
        if (e.target.value?.length <= 500) {
            setFormData({ ...formData, short_description: e.target.value });
            setDisplayData({
                ...displayData,
                short_description: e.target.value,
            });
        }
    };
    const handleAddOption = async (val, type) => {
        if (type === "brand") {
            try {
                const result = await addBrand({ name: val });
                setFormData({ ...formData, brand: result?.data?.id });
                setDisplayData({ ...displayData, brand: result?.data });
                setCloseMenu(true);
                dispatch(fetchAllBrands());
            } catch (error) {
                setCloseMenu(true);
                captureException(error);
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Something went wrong",
                });
            }
        } else if (type === "category") {
            try {
                const result = await addCategory({ name: val });
                setFormData({ ...formData, category: result?.data?.id });
                setDisplayData({ ...displayData, category: result?.data });
                setCloseMenu(true);
                dispatch(fetchAllCategories());
            } catch (error) {
                setCloseMenu(true);
                captureException(error);
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Something went wrong",
                });
            }
        }
    };

    const handleHSNChange = (value) => {
        const regex = /^[0-9\b]+$/;
        if (!(value === "" || regex.test(value))) return;
        setFormData({ ...formData, hsn: value });
        setDisplayData({
            ...displayData,
            hsn: value,
        });
    };

    return (
        <S.Container>
            <S.Section>
                <S.PriceContainer>
                    {/* <S.InputContainer>
                        <Label text="Type" />
                        <CustomSelect
                            type="type"
                            onSearchChange={handleSearchChange}
                            data={types || null}
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.type?.name}
                            selectRef={selectRef}
                        />
                    </S.InputContainer> */}
                    <S.InputContainer>
                        <Label text="Brand Name" />
                        <CustomSelect
                            type="brand"
                            onSearchChange={handleSearchChange}
                            data={brands}
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.brand?.name}
                            onAddOption={handleAddOption}
                            closeMenu={closeMenu}
                            setCloseMenu={setCloseMenu}
                            showAddNew
                            selectRef={selectRef}
                        />
                    </S.InputContainer>
                    <S.InputContainer>
                        <Label text="Category" />
                        <CustomSelect
                            type="category"
                            onSearchChange={handleSearchChange}
                            data={categories}
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.category?.name}
                            onAddOption={handleAddOption}
                            closeMenu={closeMenu}
                            setCloseMenu={setCloseMenu}
                            showAddNew
                            selectRef={selectRef}
                        />
                    </S.InputContainer>
                </S.PriceContainer>
            </S.Section>

            <S.Section>
                <S.PriceContainer>
                    <S.InputContainer>
                        <Label text="Age Restricted" />
                        <CustomSelect
                            type="age_restricted"
                            disableSearch
                            data={AGE_RESTRICTED_OPTIONS}
                            onSelectChange={handleSelectChange}
                            selectedOption={displayData?.age_restricted?.name}
                            selectRef={selectRef}
                        />
                    </S.InputContainer>
                    <S.InputContainer>
                        <Label text="Min Quantity" />
                        <CustomInput
                            value={displayData.min_quantity}
                            onChange={(val) => updateData("min_quantity", val)}
                            type="number"
                            counters
                            counterUp={() => updateData("min_quantity", +displayData.min_quantity + 1)}
                            counterDown={() => updateData("min_quantity", +displayData.min_quantity - 1)}
                            // onBlur={handleStockCorrection}
                        />
                    </S.InputContainer>
                    <S.InputContainer>
                        <Label text="Max Quantity" />
                        <CustomInput
                            value={displayData.max_quantity}
                            onChange={(val) => updateData("max_quantity", val)}
                            type="number"
                            counters
                            counterUp={() => updateData("max_quantity", +displayData.max_quantity + 1)}
                            counterDown={() => updateData("max_quantity", +displayData.max_quantity - 1)}
                            // onBlur={handleStockCorrection}
                        />
                    </S.InputContainer>
                </S.PriceContainer>
            </S.Section>
            <S.Section>
                <S.PriceContainer>
                    <S.InputContainer>
                        <Label text="Tax" />
                        <CustomSelect
                            data={TAX_VALUES}
                            onSelectChange={handleSelectChange}
                            type="tax"
                            disableSearch
                            selectedOption={getTaxName(displayData)}
                            selectRef={selectRef}
                        />
                    </S.InputContainer>

                    <S.InputContainer>
                        <Label text="HSN" />
                        <CustomInput value={displayData.hsn} name="hsn" onChange={handleHSNChange} type="text" placeholder="HSN" />
                    </S.InputContainer>

                    {/* TODO: implement later */}
                    {/* <S.InputContainer>
                        <Label text="HSN" />

                        <CustomSelect
                            data={HSN_DATA}
                            onSelectChange={handleSelectChange}
                            type="hsn"
                            localSearch
                            selectedOption={displayData.hsn.value}
                            selectRef={selectRef}
                        />
                    </S.InputContainer> */}
                </S.PriceContainer>
            </S.Section>

            <S.Section>
                <Label text="Tags" />
                <S.TagsInput>
                    <img src={SearchIcon} alt="search" />
                    <S.Tags>
                        {displayData.tags?.map((tag, index) => (
                            <SelectedChip key={index} text={tag} onRemove={handleRemovechip} />
                        ))}
                        <input value={chipValue} onChange={handleChipChange} onKeyDown={handleChipChange} type="text" />
                    </S.Tags>
                </S.TagsInput>
            </S.Section>
            <S.Section>
                <Label text="Description" />
                {/* <CustomInput value={displayData.short_description} onChange={handleDescriptionChange} type="text" placeholder="Description" /> */}
                <S.Description
                    value={displayData.short_description}
                    onChange={handleDescriptionChange}
                    type="text"
                    placeholder="Description"
                ></S.Description>
            </S.Section>
        </S.Container>
    );
};

export default ProductInformation;
