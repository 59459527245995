import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px;
`;

export const DataBox = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
    background: rgba(59, 130, 246, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 12px;

    img {
        height: 20px;
        width: 20px;
        margin-left: 4px;
        cursor: pointer;
    }
`;
