import React, { useEffect, useState } from "react";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { captureException } from "../../../crash-reporting";
import { hideLoader, setLoyaltyPreference, showLoader } from "../../../store/app-slice";
import ToggleSwitch from "../../switch/Switch";

const Loyalty = () => {
    const { loyaltyPreference } = useSelector((state) => state.app);
    const [preference, setPreference] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    useEffect(() => {
        setPreference(loyaltyPreference);
    }, []);

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
            value = value.substr(1);
        }
        if (!(value === "" || regex.test(value))) return;

        if (value === "" || value === "0") {
            value = "1";
        }

        if (name === "credit_rupees") {
            let pl = { ...preference?.credit_conversion, rupees: value };
            setPreference({ ...preference, credit_conversion: pl });
        } else if (name === "credit_loyalty_points") {
            let pl = { ...preference?.credit_conversion, loyalty_points: value };
            setPreference({ ...preference, credit_conversion: pl });
        } else if (name === "debit_rupees") {
            let pl = { ...preference?.debit_conversion, rupees: value };
            setPreference({ ...preference, debit_conversion: pl });
        } else if (name === "debit_loyalty_points") {
            let pl = { ...preference?.debit_conversion, loyalty_points: value };
            setPreference({ ...preference, debit_conversion: pl });
        } else if (name === "maximum_points") {
            let pl = { ...preference?.maximum_redeemable, loyalty_points: value };
            setPreference({ ...preference, maximum_redeemable: pl });
        }
    };

    const handleSwitchChange = (event, type) => {
        let { checked } = event.target;
        if (type === "loyalty_point_enabled") {
            setPreference({ ...preference, loyalty_point_enabled: checked });
        }
    };
    const handleSaveLoyaltyPreference = async () => {
        if (preference?.credit_conversion?.rupees === "") {
            handleSnackbarDetails({
                show: true,
                title: "Please enter credit conversion rupees",
                type: "error",
            });
            return;
        }
        if (preference?.credit_conversion?.loyalty_points === "") {
            handleSnackbarDetails({
                show: true,
                title: "Please enter credit conversion point",
                type: "error",
            });
            return;
        }
        if (preference?.debit_conversion?.rupees === "") {
            handleSnackbarDetails({
                show: true,
                title: "Please enter debit conversion rupees",
                type: "error",
            });
            return;
        }
        if (preference?.debit_conversion?.loyalty_points === "") {
            handleSnackbarDetails({
                show: true,
                title: "Please enter debit conversion point",
                type: "error",
            });
            return;
        }
        if (preference?.maximum_redeemable?.loyalty_points === "") {
            handleSnackbarDetails({
                show: true,
                title: "Please enter maximum redeemable point",
                type: "error",
            });
            return;
        }
        let payload = {
            loyalty_point_enabled: preference?.loyalty_point_enabled,
            credit_conversion: {
                rupees: parseFloat(preference?.credit_conversion.rupees),
                loyalty_points: parseFloat(preference?.credit_conversion.loyalty_points),
            },
            debit_conversion: {
                rupees: parseFloat(preference?.debit_conversion.rupees),
                loyalty_points: parseFloat(preference?.debit_conversion.loyalty_points),
            },
            maximum_redeemable: {
                loyalty_points: parseFloat(preference?.maximum_redeemable.loyalty_points),
            },
        };
        console.log(payload);
        dispatch(showLoader());
        try {
            let res = await window.electronAPI.saveAppPreference({
                key: "loyalty",
                value: payload,
            });
            if (res.code === "success") {
                dispatch(setLoyaltyPreference(payload));
                handleSnackbarDetails({
                    show: true,
                    title: "Saved",
                    subtitle: "Loyalty preference saved successfully",
                    type: "success",
                });
            } else {
                console.log(res);
            }
        } catch (error) {
            captureException(error);
        } finally {
            dispatch(hideLoader());
        }
    };

    return (
        <S.Wrapper>
            <S.Body>
                <S.FlexContainer>
                    <S.GreyText>Enable Loyalty Point</S.GreyText>
                    <ToggleSwitch
                        type="loyalty_point_enabled"
                        checked={preference?.loyalty_point_enabled}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <div>
                    <S.Head>Conversion Rate</S.Head>
                    <S.Container>
                        <S.GreyText>At Time Of Credit</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputContainer>
                                <S.Text>₱:</S.Text>
                                <S.Input
                                    type="text"
                                    align="left"
                                    name="credit_rupees"
                                    value={preference?.credit_conversion?.rupees}
                                    onChange={handleInputChange}
                                />
                            </S.InputContainer>
                            <div>=</div>
                            <S.InputContainer>
                                <S.Text>Points:</S.Text>
                                <S.Input
                                    type="text"
                                    align="left"
                                    name="credit_loyalty_points"
                                    value={preference?.credit_conversion?.loyalty_points}
                                    onChange={handleInputChange}
                                />
                            </S.InputContainer>
                        </S.ConversionContainer>
                    </S.Container>
                    <S.Container>
                        <S.GreyText>At Time Of Debit</S.GreyText>
                        <S.ConversionContainer>
                            <S.InputContainer>
                                <S.Text>Points:</S.Text>
                                <S.Input
                                    type="text"
                                    align="left"
                                    name="debit_loyalty_points"
                                    value={preference?.debit_conversion?.loyalty_points}
                                    onChange={handleInputChange}
                                />
                            </S.InputContainer>
                            <div>=</div>
                            <S.InputContainer>
                                <S.Text>₱:</S.Text>
                                <S.Input
                                    type="text"
                                    align="left"
                                    name="debit_rupees"
                                    value={preference?.debit_conversion?.rupees}
                                    onChange={handleInputChange}
                                />
                            </S.InputContainer>
                        </S.ConversionContainer>
                    </S.Container>
                </div>
                <div>
                    <S.Head>Maximum Redeemable Points</S.Head>
                    <S.ConversionContainer>
                        <S.InputContainer>
                            <S.Text>Points</S.Text>
                            <S.Input
                                type="text"
                                align="left"
                                name="maximum_points"
                                value={preference?.maximum_redeemable?.loyalty_points}
                                onChange={handleInputChange}
                            />
                        </S.InputContainer>
                    </S.ConversionContainer>
                </div>
            </S.Body>
            <S.BottomContainer>
                <S.SaveBtn onClick={handleSaveLoyaltyPreference}>Save Changes</S.SaveBtn>
            </S.BottomContainer>
        </S.Wrapper>
    );
};

export default Loyalty;
