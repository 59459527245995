import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 1.53687px 12.295px rgba(72, 79, 103, 0.24);
    border-radius: 4px;
    width: 709px;
    height: 503px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 35px;
    align-items: center;

    & img {
        width: 501px;
        height: 229px;
    }
`;
export const Head = styled.div`
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #1e293b;
`;
export const SubHead = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #1e293b;
`;
export const LoadingText = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #1e293b;
`;
export const BottomText = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
`;
