import styled from "@emotion/styled/macro";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
  display: flex;
  flex-direction: column;

  min-width: 400px;
  min-height: 388px;

  padding: 40px;
`;

export const LoginContainer = styled.form`
    background: #ffffff;
    border-radius: 12px;
    width: 419px;
`;

export const StoreDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const StoreName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2d68fe;
`;

export const LoginText = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #334155;
  margin-top: 24px;
`;

export const LoginHelperText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #64748b;
`;

export const InputContainer = styled.div`
  margin-top: 24px;
`;

export const Password = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #64748b;
  margin-bottom: 8px;
`;

export const PasswordInputContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  input {
    border: none;
    outline: none;
    flex: 1;
    margin-right: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  img {
    cursor: pointer;
  }
`;

export const LoginBtn = styled.div`
  background: #3b82f6;
  border-radius: 2px;
  margin-top: 24px;
  padding: 8px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
`;

export const LoginBtnSecondary = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3b82f6;
  margin-top: 24px;
  cursor: pointer;
`;
