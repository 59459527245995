import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 24px 40px 27px 40px;
    width: 520px;
`;
export const BtnContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: space-between;
`;
export const ConfirmBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
    background: #d80027;
    border-radius: 8px;
`;
export const CancelBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #707091;
    cursor: pointer;
    border-radius: 4px;
`;
export const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #455a64;
    margin-bottom: 36px;
`;
export const DraftBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: #fff;
    color: #2d68fe;
    cursor: pointer;
    border: 1px solid #2d68fe;
    border-radius: 8px;
`;
export const RightBtnContainer = styled.div`
    display: flex;
    gap: 16px;
`;
export const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #232325;
    margin-bottom: 16px;
`;
