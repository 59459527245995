import React from "react";
import { useSelector } from "react-redux";
import * as S from "./style";
import ViewSaleRow from "./ViewSaleRow";
import { SALE_BILL_TYPE } from "../../../constants/appConstants";

const ViewSaleTable = () => {
  const { selectedSale } = useSelector((state) => state.sale);

  return (
    <S.Wrapper>
      <S.HeadRow>
        <S.Th $first>Item Description</S.Th>
        <S.Th>Sale Price</S.Th>
        <S.Th>Stock</S.Th>
        <S.Th>Quantity</S.Th>
        <S.Th>Discount</S.Th>
        <S.Th>Tax</S.Th>
        <S.Th>Subtotal</S.Th>
        {selectedSale?.type === "SALE" && <S.Th>Profit</S.Th>}
        {selectedSale?.bill_type === SALE_BILL_TYPE.INVOICE && (
          <S.Th>More</S.Th>
        )}
      </S.HeadRow>
      <S.Body>
        {selectedSale.items?.length &&
          selectedSale.items.map((item, idx) => (
            <ViewSaleRow key={idx} item={item} idx={idx} />
          ))}
      </S.Body>
    </S.Wrapper>
  );
};

export default ViewSaleTable;
