import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    background-color: #253238;
    height: 100vh;
    box-sizing: border-box;
    padding: 26px 36px 36px;
`;
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #ffde2c;
    gap: 15px;
    height: 50px;
`;
export const SliderContainer = styled.div`
    // border: 1px solid aqua;
    margin-top: 83px;
`;
