import styled from "@emotion/styled/macro";
import { multilineEllipsis } from "../../assets/css/mixin";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;

    min-width: 619px;
    padding: 29px 29px 16px;
    box-sizing: border-box;
`;

export const Header = styled.div`
    display: flex;
    padding-bottom: 12px;
    border-bottom: 1px solid #e2e8f0;
    justify-content: space-between;
    margin-bottom: 27px;

    img {
        cursor: pointer;
        width: 19px;
        height: 19px;
    }
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 16.0155px;
    line-height: 22px;
    color: #334155;
`;

export const Body = styled.div``;
export const Left = styled.div`
    display: flex;
    flex: 1;
`;
export const Top = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const Bottom = styled.div``;
export const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.16));
    width: 240px;
    height: 80px;
`;

export const Brand = styled.div`
    font-weight: 500;
    font-size: 14.414px;
    line-height: 17px;
    color: #64748b;
    margin-bottom: 4px;
`;
export const Name = styled.div`
    font-weight: 400;
    font-size: 16.0155px;
    line-height: 19px;
    color: #334155;
    width: 100%;
    padding-bottom: 6px;
    ${multilineEllipsis({ fontSize: "20px", lineHeight: "24px", lines: 2 })}
`;

export const ProductDetails = styled.div``;

export const ProductImage = styled.img`
    //   min-width: 88px;
    //   max-width: 88px;
    //   min-height: 92px;
    //   max-height: 92px;
    width: 70px;
    height: 74px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;
`;

export const ImagePlaceholder = styled.div`
    //   min-width: 88px;
    //   max-width: 88px;
    //   min-height: 92px;
    //   max-height: 92px;
    width: 70px;
    height: 74px;
    background: #e2e8f0;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        min-height: 60px;
        min-width: 60px;
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 30px;

    & input {
        width: 100%;
    }
`;

export const BtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Btn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    margin-top: 12px;
    font-weight: 500;
    font-size: 14.414px;
    line-height: 18px;
    color: #ffffff;
    // padding: 13px 35px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 48px;
`;
