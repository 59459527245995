import React, { useEffect, useRef, useState } from "react";
import CustDropDown from "./CustDropDown";
import * as S from "./DropDown.style";
import OrderDropDown from "./OrderDropDown";
import ProductDropDown from "./ProductDropDown";
import { TABS } from "../../constants/appConstants";

const DropDown = ({
    globalSearchData,
    setOpenDropDown,
    setSearchText,
    open,
    setSkuProducts,
    setOpenSelectProductModal,
    openDrawer,
    setOpenDrawer,
    productData,
    setProductData,
    openNewCustModal,
    setOpenNewCustModal,
}) => {
    const [tab, setTab] = useState(1);
    const [orders, setOrders] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [products, _setProducts] = useState([]);
    const productsRef = useRef(products);
    const setProducts = (data) => {
        productsRef.current = data;
        _setProducts(data);
    };

    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    const productRef = useRef(null);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [globalSearchData, open]);

    useEffect(() => {
        if (globalSearchData) {
            setProducts(globalSearchData);
        } else {
            setProducts([]);
        }
    }, [globalSearchData]);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        try {
            if (e.keyCode === 13) {
                //Enter key
                productRef.current?.refProdRowClick();
                e.preventDefault();
            } else if (e.keyCode === 38) {
                //UP Arrow key
                if (activeRowRef.current > 0) {
                    setActiveRow(activeRowRef.current - 1);
                    e.preventDefault();
                }
            } else if (e.keyCode === 40) {
                //DOWN Arrow key
                if (activeRowRef.current < productsRef.current?.length - 1) {
                    setActiveRow(activeRowRef.current + 1);
                    e.preventDefault();
                }
            }
        } catch (err) {
            //
        }
    };
    return (
        <S.Wrapper>
            <S.Tabs tab={tab}>
                {TABS.map((item, idx) => (
                    <S.Tab onClick={() => setTab(item.id)} key={idx}>
                        <img src={tab === item.id ? item.activeIcon : item.icon} alt="icon" />
                        {item.name}
                    </S.Tab>
                ))}
            </S.Tabs>
            {tab === 1 ? (
                <ProductDropDown
                    products={products}
                    setOpenDropDown={setOpenDropDown}
                    setSearchText={setSearchText}
                    setOpenDrawer={setOpenDrawer}
                    openDrawer={openDrawer}
                    activeRow={activeRow}
                    ref={productRef}
                    setOpenSelectProductModal={setOpenSelectProductModal}
                    setSkuProducts={setSkuProducts}
                    scrollRef={scrollRef}
                    productData={productData}
                    setProductData={setProductData}
                />
            ) : tab === 2 ? (
                <OrderDropDown orders={orders} setOpenDropDown={setOpenDropDown} setSearchText={setSearchText} />
            ) : (
                <CustDropDown customers={customers} setOpenNewCustModal={setOpenNewCustModal} openNewCustModal={openNewCustModal} />
            )}
        </S.Wrapper>
    );
};

export default DropDown;
