import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarouselContainer from "../../components/carousel/Carousel";
import {
  HomeDropLogo,
  PasswordEyeIcon,
  PasswordNoEyeIcon,
  YellowArrowleft,
} from "../../icons";
import * as S from "./LoginScreen.style";
import {
  AUTH_SLIDER_DATA,
  PASSWORD_CHANGE_STATE,
} from "../../constants/appConstants";
import { setStore } from "../../store/session-slice";
import { showLoader, hideLoader, setPOSFeatures } from "../../store/app-slice";
import { errorNotify, successNotify } from "../../utils/toast";
import { loginStore } from "../../api/server/storeApi";
import { captureException } from "../../crash-reporting";
import { useRef } from "react";
import Login from "./login/Login";
import SendOtp from "./send-otp/SendOtp";
import VerifyOtp from "./verify-otp/VerifyOtp";
import NewPassword from "./new-password/NewPassword";
import { sendOTP, verifyOTP } from "../../api/server/otpApi";
import { storeChangePassword } from "../../api/server/storeApi";
import LimitWarn from "./limit-warn/LimitWarn";

const initialLoginData = {
  username: "",
  password: "",
  otp: "",
  new_password: "",
  confirm_new_password: "",
};
const LoginScreen = ({ loginState, setLoginState, loginStateRef }) => {
  const dispatch = useDispatch();
  const [loginData, _setLoginData] = useState(initialLoginData);
  const loginDataRef = useRef(loginData);
  const setLoginData = (data) => {
    loginDataRef.current = data;
    _setLoginData(data);
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleDetectKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, []);

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 13) {
      //Enter key
      onEnterSubmit();
      e.preventDefault();
    } else if (e.keyCode === 27) {
      //ESC key
      handleBackAction();
      e.preventDefault();
    }
  };

  const onEnterSubmit = () => {
    if (loginStateRef.current === PASSWORD_CHANGE_STATE.LOGIN) {
      doLogin();
    } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.SEND_OTP) {
      handleSendOtp();
    } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.VERIFY_OTP) {
      handleVerifyOtp();
    } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.NEW_PASSWORD) {
      handleChangeStorePassword();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username" || name === "otp") {
      if (name === "username" && value.length > 10) return;
      const regex = /^[0-9\b]+$/;
      if (!(value === "" || regex.test(value))) return;
    }
    setLoginData({ ...loginData, [name]: value });
  };

  const doLogin = async () => {
    if (!loginDataRef.current?.username) {
      errorNotify("Phone number is invalid");
      return;
    }
    if (
      !loginDataRef.current?.password ||
      loginDataRef.current?.password.length < 8
    ) {
      errorNotify("Password must be atleast 8 characters");
      return;
    }

    dispatch(showLoader());
    try {
      let result = await loginStore({
        username: "+63" + loginDataRef.current?.username,
        password: loginDataRef.current?.password,
      });
      if (result.status === 200) {
        const entity = result?.data?.entities?.find(
          (e) => e.auth_type === "AUTH_TYPE_CLINIC"
        );
        if (entity) {
          await dispatch(setStore(entity));
          navigate("/dashboard/new-sale");
        } else {
          errorNotify(result.data?.message ?? "Account doesn't exists");
          throw new Error(result.data?.message ?? "Account doesn't exists");
        }
      } else {
        errorNotify(result.data?.message ?? "Account doesn't exists");
        throw new Error(result.data?.message ?? "Account doesn't exists");
      }
    } catch (e) {
      captureException(e);
      errorNotify(
        e.response?.data?.message ?? "Some error occurred. Please try again!"
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleSendOtp = async () => {
    if (loginDataRef.current?.username?.length !== 10) {
      errorNotify("Enter valid phone number");
      return;
    }
    dispatch(showLoader());
    try {
      let response = await sendOTP({
        context: "HOMEDROP_POS_CHANGE_STORE_PASSWORD",
        country_code: "+63",
        phone_number: loginDataRef.current?.username,
      });
      if (response.status === 200 && response.data === true) {
        successNotify(`An OTP is sent to ${loginDataRef.current?.username}`);
        toVerifyOtpState();
      } else {
        errorNotify(response.data.message);
        throw new Error(response.data?.message);
      }
    } catch (error) {
      captureException(error);
      errorNotify("Some error occurred");
    } finally {
      dispatch(hideLoader());
    }
  };
  const handleVerifyOtp = async () => {
    if (loginDataRef.current?.otp?.length !== 6) {
      errorNotify("Enter valid OTP");
      return;
    }
    dispatch(showLoader());
    try {
      let response = await verifyOTP({
        context: "HOMEDROP_POS_CHANGE_STORE_PASSWORD",
        country_code: "+63",
        phone_number: loginDataRef.current?.username,
        otp: loginDataRef.current?.otp,
      });
      if (response.status === 200 && response.data === true) {
        successNotify("Congratulations. OTP verified");
        toNewPasswordState();
      } else {
        errorNotify(response.data.message);
        throw new Error(response.data);
      }
    } catch (e) {
      captureException(e);
      errorNotify("Some error occurred. Please try again!");
    } finally {
      dispatch(hideLoader());
    }
  };
  const handleChangeStorePassword = async () => {
    if (
      loginDataRef.current?.new_password !==
      loginDataRef.current?.confirm_new_password
    ) {
      errorNotify("Passwords does not match");
      return;
    }
    dispatch(showLoader());
    try {
      let response = await storeChangePassword({
        context: "HOMEDROP_POS_CHANGE_STORE_PASSWORD",
        username: loginDataRef.current?.username,
        new_password: loginDataRef.current?.new_password,
      });
      if (response.status === 200) {
        successNotify("Congratulations. Password changed successfully");
        toLoginState();
        console.log(response.data);
      } else {
        errorNotify(response.data?.message);
        throw new Error(response.data?.message);
      }
    } catch (err) {
      captureException(err);
      errorNotify("Some error occurred. Please try again!");
    } finally {
      dispatch(hideLoader());
    }
  };

  const toSendOtpState = () => {
    setLoginState(PASSWORD_CHANGE_STATE.SEND_OTP);
  };

  const toVerifyOtpState = () => {
    setLoginState(PASSWORD_CHANGE_STATE.VERIFY_OTP);
  };

  const toNewPasswordState = () => {
    setLoginState(PASSWORD_CHANGE_STATE.NEW_PASSWORD);
  };

  const toLoginState = () => {
    setLoginState(PASSWORD_CHANGE_STATE.LOGIN);
  };

  const handleBackAction = () => {
    if (loginStateRef.current === PASSWORD_CHANGE_STATE.SEND_OTP) {
      setLoginState(PASSWORD_CHANGE_STATE.LOGIN);
    } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.VERIFY_OTP) {
      setLoginState(PASSWORD_CHANGE_STATE.SEND_OTP);
    } else if (loginStateRef.current === PASSWORD_CHANGE_STATE.NEW_PASSWORD) {
      setLoginState(PASSWORD_CHANGE_STATE.VERIFY_OTP);
    }
  };

  return (
    <S.Wrapper>
      <S.LeftContainer>
        <CarouselContainer carouselData={AUTH_SLIDER_DATA} />
      </S.LeftContainer>
      <S.RightContainer>
        <div>
          <S.LogoContainer>
            <S.BackBtn onClick={handleBackAction}>
              <img src={YellowArrowleft} alt="yellow arrow" />
            </S.BackBtn>
          </S.LogoContainer>

          {loginState === PASSWORD_CHANGE_STATE.LOGIN && (
            <Login
              loginData={loginData}
              toSendOtpState={toSendOtpState}
              handleInputChange={handleInputChange}
              onSubmit={doLogin}
            />
          )}
          {loginState === PASSWORD_CHANGE_STATE.SEND_OTP && (
            <SendOtp
              loginData={loginData}
              handleInputChange={handleInputChange}
              onSubmit={handleSendOtp}
            />
          )}
          {loginState === PASSWORD_CHANGE_STATE.VERIFY_OTP && (
            <VerifyOtp
              loginData={loginData}
              handleInputChange={handleInputChange}
              onSubmit={handleVerifyOtp}
            />
          )}
          {loginState === PASSWORD_CHANGE_STATE.NEW_PASSWORD && (
            <NewPassword
              loginData={loginData}
              handleInputChange={handleInputChange}
              onSubmit={handleChangeStorePassword}
            />
          )}
          {loginState === PASSWORD_CHANGE_STATE.LIMIT && (
            <LimitWarn toLoginState={toLoginState} />
          )}
        </div>
      </S.RightContainer>
    </S.Wrapper>
  );
};

export default LoginScreen;
