import styled from "@emotion/styled/macro";

export const FilterBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cbd5e1;
    // margin-bottom: 12px;
    padding: 9px 16px;
`;

export const LeftFilterBox = styled.div`
    display: flex;
    gap: 8px;
    height: 38px;
`;

export const RightFilterBox = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;

export const InputBox = styled.div`
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0 12px;
    display: flex;
    width: 291px;
    align-items: center;

    img {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
`;
export const Input = styled.input`
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
    padding-left: 10px;
    border-radius: 4px;

    &::placeholder {
        color: #cbd5e1;
    }
`;

export const CheckBoxContainer = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
    padding: 4px 6px;
    cursor: pointer;

    & > input {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
    & > label {
        cursor: pointer;
    }
`;
