import React, { useState, useEffect, useRef } from "react";
import { DarkArrowLeft, WhiteArrowRight } from "../../icons";
import { OtpInput } from "../OtpInput/OtpInput";
import * as S from "./style";
import { sendOTP, verifyOTP } from "../../api/server/otpApi";
import { errorNotify, successNotify } from "../../utils/toast";
import { showLoader, hideLoader } from "../../store/app-slice";
import { useDispatch } from "react-redux";
import { captureException } from "../../crash-reporting";

const OtpPage = ({ setOtpState, otpState, setActiveTab, onSubmit }) => {
    const RETRY_SECONDS = 30;
    const dispatch = useDispatch();
    const [otpData, setOtpData] = useState("");
    const [seconds, setSeconds] = useState(otpState.tick_left ?? RETRY_SECONDS);
    const tick = useRef();
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current?.focus();
        }
    }, []);

    useEffect(() => {
        if (seconds > 0) {
            tick.current = setTimeout(() => {
                updateResendUI();
                setSeconds(seconds - 1);
            }, 1000);
        } else {
            updateResendUI();
            clearInterval(tick.current);
        }
        return () => {
            updateResendUI();
            clearInterval(tick.current);
        };
    }, [seconds]);

    const updateResendUI = () => {
        //TODO: @Aditya update the UI based on seconds
    };

    const checkOTP = async (e) => {
        e.preventDefault();
        if (otpData?.length !== 6) {
            return;
        }
        dispatch(showLoader());
        try {
            let response = await verifyOTP({
                context: "HOMEDROP_POS",
                country_code: "+63",
                phone_number: otpState.phone,
                otp: otpData,
            });
            if (response.status === 200 && response.data === true) {
                successNotify("Congratulations. OTP verified");
                onSubmit();
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data);
            }
        } catch (e) {
            captureException(e);
            errorNotify("OTP verification failed!");
        } finally {
            dispatch(hideLoader());
        }
    };

    const resendOTP = async () => {
        setSeconds(RETRY_SECONDS);

        dispatch(showLoader());
        try {
            let response = await sendOTP({
                context: "HOMEDROP_POS",
                country_code: "+63",
                phone_number: otpState.phone,
            });
            if (response.status === 200 && response.data === true) {
                successNotify(`An OTP is sent to ${otpState.phone}`);
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data);
            }
        } catch (error) {
            captureException(error);
            errorNotify("Some error occurred");
        } finally {
            dispatch(hideLoader());
        }
    };
    const handleInputChange = (e) => {
        let val = e.target.value;
        const regex = /^[0-9\b]+$/;
        if (!(val === "" || regex.test(val))) return;

        setOtpData(val);
    };

    return (
        <S.Wrapper onSubmit={(e) => checkOTP(e)}>
            <div>
                <S.TabHead>Verify OTP</S.TabHead>
                <S.DualColorTexts>
                    <div>Enter OTP sent to you at</div>
                    <div>+63-{otpState.phone}</div>
                </S.DualColorTexts>
                {/* <OtpInput totalInputs={6} onChange={(e) => setOtpData(e)} /> */}
                <S.OtpContainer>
                    <input type="text" maxLength={6} onChange={handleInputChange} value={otpData} ref={inputElement} />
                </S.OtpContainer>
                {seconds === 0 ? (
                    <S.DualColorTexts>
                        <div>Didn’t get the code?</div>
                        <S.Otp onClick={() => resendOTP()}>Resend OTP</S.Otp>
                    </S.DualColorTexts>
                ) : (
                    <S.Resend>Resend OTP after {seconds} seconds</S.Resend>
                )}
            </div>
            <S.DoubleButtonFooter>
                <S.WhiteButton
                    onClick={() => {
                        setOtpState({ ...otpState, tick_left: seconds });
                        setActiveTab((p) => p - 1);
                    }}
                >
                    <img src={DarkArrowLeft} alt="" />
                    <div>Go Back</div>
                </S.WhiteButton>
                <S.DarkButton type="submit">
                    <div>Verify</div>
                    <img src={WhiteArrowRight} alt="" />
                </S.DarkButton>
            </S.DoubleButtonFooter>
        </S.Wrapper>
    );
};

export default OtpPage;
