import axios from "./axios";
import { CATEGORIES_API } from "../../constants/apiRoutes";

const addCategory = async (payload) => {
  return await axios.post(CATEGORIES_API, payload);
};

const getCategory = async (categoryId) => {
  return await axios.get(`${CATEGORIES_API}/${categoryId}`);
};

const getAllCategories = async (searchKey) => {
  const queryParams = {};
  if (searchKey) queryParams.search = searchKey;
  return await axios.get(CATEGORIES_API, {
    params: queryParams,
  });
};

export { addCategory, getCategory, getAllCategories };
