import axios from "axios";
import store from "../../store/store";

const remoteAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Connection: "keep-alive",
    Accept: "application/json",
    "app-client": process.env.APP_CLIENT,
    "app-version": process.env.APP_VERSION,
  },
});

// Request interceptor
remoteAxios.interceptors.request.use((config) => {
  config.headers["client-ts"] = Date.now();
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "serviceToken"
  )}`;
  //Device ID
  try {
    if (!config.headers["device-id"]) {
      let device = store.getState().session.device;
      if (device) {
        config.headers["device-id"] = device.id;
      }
    }
  } catch (e) {}
  return config;
});

export default remoteAxios;
