import React from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CrossBlackIcon } from "../../icons";
import { useEffect } from "react";

const DeleteConfirmationModal = ({ open, onClose, data, handleSubmit }) => {
    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            try {
                if (e.keyCode === 13) {
                    //Enter key
                    handleSubmit();
                    e.preventDefault();
                }
            } catch (e) {
                //ignore
            }
        }
    };
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Top>
                    <S.HeadingContainer>
                        <S.Heading>{data?.head}</S.Heading>
                        <S.ClosedBtn src={CrossBlackIcon} alt="cross" onClick={onClose} />
                    </S.HeadingContainer>
                    <S.SubHeading>{data?.subHead}</S.SubHeading>
                </S.Top>
                <S.Bottom>
                    <S.CancelBtn onClick={onClose}>Cancel (ESC)</S.CancelBtn>
                    <S.DeleteBtn onClick={handleSubmit}>Delete (ENT)</S.DeleteBtn>
                </S.Bottom>
            </S.Wrapper>
        </Modal>
    );
};

export default DeleteConfirmationModal;
