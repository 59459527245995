import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 30px 16px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 24px;
    height: 90%;
`;

export const FormContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Header = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333;
`;

export const Label = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333;
`;
export const Input = styled.input`
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-weight: 400;
    font-size: 11.1533px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
    padding: 12.5px 11px;
    outline: none;
    margin-bottom: 14px;
    flex: 1;
    max-height: 36px;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;

    &::placeholder {
        color: #737373;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
export const SingleContainer = styled.div`
    flex: 1;
`;
export const FlexContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const FlexSelectContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
        flex: 1;
    }
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;

    & > label {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #333;
        cursor: pointer;
    }

    & > input {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;

export const AddBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    height: 40px;
    width: 200px;
    font-weight: 500;
    font-size: 14.3163px;
    line-height: 17px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`;

export const NextBtn = styled.div`
    background: #fff;
    border-radius: 4px;
    height: 40px;
    width: 120px;
    font-weight: 500;
    font-size: 14;
    line-height: 17px;
    color: #253238;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    border: 1px solid #253238;

    & > img {
        ${(props) => props.$rotate && "transform: rotate(180deg)"};
    }
`;

export const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
