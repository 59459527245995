import { createContext, useState } from "react";

export const DrawerContext = createContext();

const DrawerProvider = ({ children }) => {
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);

    const setLeftDrawerStatus = (value) => setLeftDrawerOpen(value);

    const setRightDrawerStatus = (value) => setRightDrawerOpen(value);

    return (
        <DrawerContext.Provider
            value={{
                leftDrawerOpen,
                rightDrawerOpen,
                setLeftDrawerStatus,
                setRightDrawerStatus,
            }}
        >
            {children}
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;
