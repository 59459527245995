import React from "react";
import * as S from "./style";
import { useState } from "react";
import { SelectArrowDownIcon } from "../../../icons";
import { calendarFormatDate, formatDate } from "./../../../utils/format-date";
import SelectedChips from "./../../selected-chips/selected-chips";
import CustomDueSelect from "./../custom-due-select/CustomDueSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DUE_TYPE,
  PURCHASE_TYPE,
  SALES_TYPE,
  STATUS,
} from "../../../constants/appConstants";
import { setSelectedChipDue } from "../../../store/due-slice";
import TransactionTable from "./transaction-table/TransactionTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import PaylaterPayment from "./../../paylater-payment-dialog/PaylaterPayment";
import { toAbsoluteRoundOff, toDecimalPlace } from "../../../utils/global-fn";
import { getPayment } from "../../../utils/object-parser";
import PartyTransactionCount from "../../party-transaction-count/PartyTransactionCount";
import { captureException } from "../../../crash-reporting";
import { getAllSales } from "../../../api/local/sale-api";
import { getAllPurchases } from "../../../api/local/purchase-api";

const initalDisplayData = {
  date: "",
};

const Transactions = ({
  id,
  type,
  txnCount,
  phoneNumber,
  onClose,
  clear,
  supplierId,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [displayData, setDisplayData] = useState(initalDisplayData);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const dispatch = useDispatch();
  const [displayTxnData, setDisplayTxnData] = useState(null);
  const [saleIDs, setsaleIDs] = useState(null);
  const [purchaseIDs, setPurchaseIDs] = useState(null);
  const [totalPay, setTotalPay] = useState(0);
  const [salesList, setSalesList] = useState(null);
  const [purchasesList, setPurchasesList] = useState(null);
  const [queryParams, setQueryParams] = useState({});

  // useEffect(() => {
  //     if (type === "customer") {
  //         dispatch(fetchAllSales({ ...searchSaleParams, limit: 1000 }));
  //     }
  //     if (type === "supplier") {
  //         dispatch(fetchAllPurchases(searchParams));
  //     }
  // }, [searchSaleParams, searchParams]);

  useEffect(() => {
    if (type === "customer") {
      handleGetSales(id);
    }
  }, [id, queryParams]);

  useEffect(() => {
    if (type === "supplier") {
      handleGetPurchases(supplierId);
    }
  }, [supplierId, queryParams]);

  const handleGetSales = async (id) => {
    let params = {
      ...queryParams,
      patient_id: id,
      status: STATUS.PUBLISHED,
      type: `${SALES_TYPE.SALE},${SALES_TYPE.RETURN}`,
      limit: 10000,
    };
    try {
      let res = await getAllSales(params);
      if (res.status === 200 && res.data.entity) {
        setSalesList(res.data.entity);
      } else {
        throw new Error(res.data.message);
      }
    } catch (error) {
      captureException(error);
    }
  };
  const handleGetPurchases = async (id) => {
    let params = {
      ...queryParams,
      supplier_id: id,
      status: STATUS.PUBLISHED,
      type: `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.RETURN},${PURCHASE_TYPE.DAMAGE_ENTRY}`,
      limit: 10000,
    };
    try {
      let res = await getAllPurchases(params);
      if (res.status === 200) {
        setPurchasesList(res.data);
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const handleDateChange = (item) => {
    setDateRange(item);
    const start = new Date(item[0]).getTime();
    const end = new Date(item[1]).getTime();
    if (start && end) {
      setDisplayData({
        ...displayData,
        date: `${formatDate(start)} - ${formatDate(end)}`,
      });
      setQueryParams({ ...queryParams, start_date: start, end_date: end });
      // if (type === "customer") {
      //     dispatch(setSearchSaleParams({ start_date: start, end_date: end }));
      // }
      // if (type === "supplier") {
      //     dispatch(setSearchParams({ start_date: start, end_date: end }));
      // }
    }
  };
  const handleChange = (val) => {
    if (val === DUE_TYPE.ALL_DUE) {
      setQueryParams({ ...queryParams, all_due: true });
      // if (type === "customer") {
      //     dispatch(setSearchSaleParams({ all_due: true }));
      // }
      // if (type === "supplier") {
      //     dispatch(setSearchParams({ all_due: true }));
      // }
      setDisplayData({ ...displayData, due: DUE_TYPE.ALL_DUE });
    } else {
      setQueryParams({ ...queryParams, all_due: false });
      // if (type === "customer") {
      //     dispatch(setSearchSaleParams({ all_due: false }));
      // }
      // if (type === "supplier") {
      //     dispatch(setSearchParams({ all_due: false }));
      // }
      setDisplayData({ ...displayData, due: DUE_TYPE.NO_DUE });
    }
    dispatch(setSelectedChipDue(val));
  };
  const handlePay = () => {
    if (type === "customer") {
      handleCustomerTransaction();
    }
    if (type === "supplier") {
      handleSupplierTransaction();
    }
  };
  const handleCustomerTransaction = () => {
    setsaleIDs(
      displayTxnData?.filter((item) => item.isChecked).map((item) => item.id)
    );
    setOpenPaymentModal(true);
  };

  const handleSupplierTransaction = () => {
    setPurchaseIDs(
      displayTxnData?.filter((item) => item.isChecked).map((item) => item.id)
    );
    setOpenPaymentModal(true);
  };

  const handleTotalPay = (data) => {
    let checkedOrders = data?.filter((item) => item.isChecked);
    let sum = 0;
    if (checkedOrders?.length) {
      for (let i = 0; i < checkedOrders.length; i++) {
        let payment = getPayment(
          checkedOrders[i].payment,
          checkedOrders[i].payment
        );
        if (checkedOrders[i].type === "RETURN") {
          sum -= payment?.amount_due;
        } else {
          sum += payment?.amount_due;
        }
      }
    }
    setTotalPay(toDecimalPlace(sum));
  };
  return (
    <>
      {type === "customer" && (
        <PaylaterPayment
          open={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          type="customer-pay"
          customerPayData={{
            saleIDs: saleIDs,
            customerId: phoneNumber,
            payModalClose: onClose,
            total_amount: toAbsoluteRoundOff(
              totalPay ? totalPay : txnCount?.total_amount_due
            ),
          }}
        />
      )}
      {type === "supplier" && (
        <PaylaterPayment
          open={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          type="supplier-pay"
          supplierPayData={{
            purchaseIDs: purchaseIDs,
            supplierId: supplierId,
            payModalClose: onClose,
            total_amount: toAbsoluteRoundOff(
              totalPay ? totalPay : txnCount?.total_amount_due
            ),
          }}
        />
      )}
      <S.Wrapper>
        <S.TopBar>
          <S.BtnDiv>
            <S.DateChooserSelect>
              <DatePicker
                selectsRange={true}
                startDate={Date.parse(calendarFormatDate(startDate))}
                endDate={Date.parse(calendarFormatDate(endDate))}
                onChange={(update) => {
                  handleDateChange(update);
                }}
                isClearable={false}
                dateFormat="dd/MM/yyyy"
                className="sales-date-range-picker"
                placeholderText="Select time period"
              />
              <img src={SelectArrowDownIcon} alt="calendar" />
            </S.DateChooserSelect>
            <CustomDueSelect text="Due" handleChange={handleChange} />
            <SelectedChips
              displayData={displayData}
              setDisplayData={setDisplayData}
              type="txn"
              resetRange={() => setDateRange([null, null])}
              noMargin
            />
          </S.BtnDiv>
          <S.BtnDiv>
            {totalPay ? <S.AmountDiv>₱{totalPay}</S.AmountDiv> : <></>}
            <S.PayBtn
              disabled={txnCount?.total_amount_due === 0}
              onClick={() => handlePay()}
            >
              Clear Pay Later
            </S.PayBtn>
          </S.BtnDiv>
        </S.TopBar>
        <S.Body>
          <TransactionTable
            type={type}
            displayData={displayTxnData}
            setDisplayData={setDisplayTxnData}
            clear={clear}
            handleTotalPay={handleTotalPay}
            sales={salesList}
            purchases={purchasesList}
          />
          <PartyTransactionCount type={type} txnCount={txnCount} />
        </S.Body>
      </S.Wrapper>
    </>
  );
};

export default Transactions;
