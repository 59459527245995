import React, { useEffect, useRef, useState } from "react";
import * as S from "./style";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIconSolid, DownloadIcon } from "../../../icons";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { PROFIT_LOSS_REPORT } from "../../../constants/appConstants";
import { Pie } from "react-chartjs-3";
import { getProfitLossReport } from "../../../api/local/analytics-api";
import moment from "moment";
import { formatDate } from "../../../utils/format-date";
import DownloadButton from "../../download-button/DownloadButton";
import { captureException } from "../../../crash-reporting";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};

const backgroundColor = ["#4318FF", "#ABCEFF", "#FFDE2C", "#57AEFE", "#9D1FFF", "#FB1FFF"];
const initialData = {
    labels: [],
    datasets: [],
};

const initialParams = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
};

const ProfitLoss = () => {
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [displayDate, setDisplayDate] = useState(`${formatDate(initialParams.start_date)} - ${formatDate(initialParams.end_date)}`);
    const [expensesData, setExpensesData] = useState(initialData);
    const [params, setParams] = useState(initialParams);
    const [profitLossData, setProfitLossData] = useState(null);
    const chartRef = useRef(null);
    const [hiddenIndex, setHiddenIndex] = useState(null);

    useEffect(() => {
        handleGetProfitLossData();
    }, [params]);

    const handleGetProfitLossData = async () => {
        try {
            let res = await getProfitLossReport(params);
            if (res.status === 200) {
                setProfitLossData(res.data);

                //for pie chart data
                let pieData = res.data.expense_breakdown;
                const chartData = {
                    labels: pieData.map((item) => item.type),
                    datasets: [
                        {
                            data: pieData.map((item) => item.value),
                            backgroundColor: backgroundColor,
                        },
                    ],
                };

                // res.data.expense_breakdown.forEach((item) => {
                //     const [label, value] = Object.entries(item)[0];
                //     chartData.labels.push(label);
                //     chartData.datasets[0].data.push(value);
                // });

                if (chartData.datasets[0].data.length > 6) {
                    let other = chartData.datasets[0].data.slice(5).reduce((a, b) => a + b);

                    let newLabels = chartData.labels.slice(0, 5);
                    let newdata = chartData.datasets[0].data.slice(0, 5);

                    chartData.labels = newLabels.concat(["Others"]);
                    chartData.datasets[0].data = newdata.concat([other]);
                }
                setExpensesData(chartData);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleDateChange = (item) => {
        setDateRange([item.selection]);
        const startDate = new Date(item.selection.startDate).getTime();
        const endDate = new Date(item.selection.endDate).getTime();
        setDisplayDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        setParams({ start_date: startDate, end_date: endDate });
        if (startDate !== endDate) {
            setOpenDatePicker(false);
        }
    };

    const handleToggle = (segmentIndex) => {
        const chart = chartRef.current.chartInstance;
        const meta = chart.getDatasetMeta(0);
        const segment = meta.data[segmentIndex];
        const hiddenSegments = meta.data.filter((d) => d.hidden).length;

        if (hiddenSegments === meta.data.length - 1 && !segment.hidden) {
            return; // Prevent hiding all segments
        }
        if (hiddenIndex === segment._index) {
            setHiddenIndex(null);
        } else {
            setHiddenIndex(segment._index);
        }
        segment.hidden = !segment.hidden;
        chart.update();
    };

    const getLegendHtml = () => {
        return expensesData.labels.map((item, index) => {
            return (
                <S.LegendRow key={index} onClick={() => handleToggle(index)}>
                    <S.LegendBox color={expensesData.datasets[0].backgroundColor[index]}></S.LegendBox>
                    <S.LegendTitle strike={index === hiddenIndex}>
                        {item}: ₱{expensesData.datasets[0].data[index]}
                    </S.LegendTitle>
                </S.LegendRow>
            );
        });
    };

    return (
        <S.Wrapper>
            <div>
                <S.TopBar>
                    <S.Heading>Profit & Loss report</S.Heading>
                    <S.FilterContainer>
                        <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                            <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                <img src={CalendarIconSolid} alt="calendar" />
                                <div>{displayDate}</div>
                            </S.DateChooserSelect>
                            {openDatePicker && (
                                <S.DatePickerContainer>
                                    <DateRangePicker
                                        editableDateInputs={true}
                                        onChange={handleDateChange}
                                        ranges={dateRange}
                                        maxDate={addDays(new Date(), 0)}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </OutsideClickHandler>
                        {/* <DownloadButton text="Download" icon={DownloadIcon} onClick={() => {}} /> */}
                    </S.FilterContainer>
                </S.TopBar>
                <S.Body>
                    <S.ReportBody>
                        {PROFIT_LOSS_REPORT.map((item, idx) => (
                            <S.ReportRow key={idx}>
                                <S.NameContainer>
                                    <img src={item.icon} alt="" />
                                    {item.name}
                                </S.NameContainer>
                                <S.Price>₱{profitLossData?.[item.key]}</S.Price>
                            </S.ReportRow>
                        ))}
                    </S.ReportBody>
                    {expensesData.labels.length ? (
                        <S.ChartBody>
                            <S.Heading>Expenses Breakdown</S.Heading>

                            <S.ChartContainer>
                                <Pie
                                    ref={chartRef}
                                    data={expensesData}
                                    options={{
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        legend: {
                                            display: false,
                                            position: "bottom",
                                            align: "center",
                                            labels: {
                                                boxWidth: 24,
                                                boxHeight: 24,
                                            },
                                            onClick: function (event, legendItem, legend) {
                                                console.log("event: ", event);
                                                console.log("legendItem: ", legendItem);
                                                console.log("legend: ", legend);
                                            },
                                        },
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: function (tooltipItem, data) {
                                                        var label = data.datasets[tooltipItem.datasetIndex].labels[tooltipItem.index];
                                                        var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                                        return label + ": " + value;
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />

                                <S.LegendContainer>{getLegendHtml()}</S.LegendContainer>
                            </S.ChartContainer>
                        </S.ChartBody>
                    ) : (
                        <></>
                    )}
                </S.Body>
            </div>
            <S.BottomBar>
                <S.BlueText>Revenue: ₱{profitLossData?.result?.revenue}</S.BlueText>
                <S.ProfitContainer>
                    <S.ProfitText>{profitLossData?.result?.type === "PROFIT" ? "Profit" : "Loss"}:</S.ProfitText>
                    <S.ProfitValue profit={profitLossData?.result?.profit}>₱{Math.abs(profitLossData?.result?.profit)}</S.ProfitValue>
                </S.ProfitContainer>
            </S.BottomBar>
        </S.Wrapper>
    );
};

export default ProfitLoss;
