import React from "react";
import * as S from "./style";
import { STATES } from "../../../constants/appConstants";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import InputField from "../../input-field/InputField";

export const InitialAddress = {
    line_1: "",
    line_2: "",
    pin_code: "",
    city: "",
    state: "",
    country: "",
};

const address_type = {
    billing: "billing_address",
    shipping: "shipping_address",
};

const Address = ({ address, setAddress, addressType, type }) => {
    const [allState, setAllState] = useState(STATES);
    const { inventorySelectedCustomer } = useSelector((state) => state.customer);
    const { PI_customer } = useSelector((state) => state.proformaInvoice);

    useEffect(() => {
        if ((type === "sale" || type === "convert-to-invoice") && inventorySelectedCustomer) {
            // if (addressType === address_type.billing) {
            //     setAddress(
            //         inventorySelectedCustomer?.billing_address
            //             ? inventorySelectedCustomer?.billing_address
            //             : inventorySelectedCustomer?.address
            //             ? inventorySelectedCustomer?.address
            //             : InitialAddress
            //     );
            // } else if (addressType === address_type.shipping) {
            //     setAddress(inventorySelectedCustomer?.shipping_address ? inventorySelectedCustomer?.shipping_address : InitialAddress);
            // }
        } else if (type === "proforma-invoice" && PI_customer) {
            // if (addressType === address_type.billing) {
            //     setAddress(
            //         PI_customer?.billing_address ? PI_customer?.billing_address : PI_customer?.address ? PI_customer?.address : InitialAddress
            //     );
            // } else if (addressType === address_type.shipping) {
            //     setAddress(PI_customer?.shipping_address ? PI_customer?.shipping_address : InitialAddress);
            // }
        } else {
            setAddress(InitialAddress);
        }
        return () => {
            setAddress(InitialAddress);
        };
    }, [addressType]);

    const handleInputChange = (event) => {
        let { name, value } = event.target;

        if (name === "pin_code") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            if (value.length > 6) return;
        }
        setAddress({ ...address, [name]: value });
    };

    const handleSearchChange = (key, value) => {
        if (key === "state") {
            setAllState(STATES.filter((s) => s.value.toLowerCase().includes(value.toLowerCase())));
        }
    };

    const handleSelectChange = (key, item) => {
        if (key === "state") {
            setAddress({ ...address, [key]: item.value, country: "Philippines" });
            setAllState(STATES);
        }
    };
    return (
        <S.Wrapper>
            <div>
                <InputField label="Address Line 1" value={address?.line_1} name="line_1" onChange={handleInputChange} fullWidth={true} />
            </div>
            <div>
                <InputField label="Address Line 2" value={address?.line_2} name="line_2" onChange={handleInputChange} fullWidth={true} />
            </div>
            <S.FlexContainer>
                <div>
                    <InputField label="Pin Code" value={address?.pin_code} name="pin_code" onChange={handleInputChange} fullWidth={true} />
                </div>
                <div>
                    <InputField label="City" value={address?.city} name="city" onChange={handleInputChange} fullWidth={true} />
                </div>
            </S.FlexContainer>
            <S.FlexContainer>
                <CustomSelect
                    type="state"
                    selectedOption={address?.state || "State"}
                    onSearchChange={handleSearchChange}
                    data={allState}
                    onSelectChange={handleSelectChange}
                    position={addressType === address_type.shipping ? "top" : "bottom"}
                    menuWidth={"215px"}
                />
                <CustomSelect
                    type="country"
                    initalText={address?.country || "Philippines"}
                    data={[]}
                    disableSearch
                    // onSelectChange={handleSelectCountry}
                />
            </S.FlexContainer>
        </S.Wrapper>
    );
};

export default Address;
