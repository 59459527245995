import { debounce, isEmpty } from "lodash";
import React, { useCallback, useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore, updateStore } from "../../../api/local/storeApi";
import { DEFAULT_MAP_POSITION, STATES } from "../../../constants/appConstants";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { hideLoader, showLoader } from "../../../store/app-slice";
import { setStore } from "../../../store/session-slice";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import Map from "../google-map/Map";
import * as S from "./style";
import { HeaderSearchIcon, PinAddressIcon, RightArrowIcon } from "../../../icons";
import axios from "axios";
import { captureException } from "../../../crash-reporting";

const initialDisplayData = {
    address: "",
    line_1: "",
    line_2: "",
    city: "",
    pin_code: "",
    area: "",
    state: "",
    mapPosition: DEFAULT_MAP_POSITION,
    markerPosition: DEFAULT_MAP_POSITION,
    // mapPosition: {
    //     lat: 26.1158,
    //     lng: 91.7086,
    // },
    // markerPosition: {
    //     lat: 26.1158,
    //     lng: 91.7086,
    // },
};

const Location = (props) => {
    const [displayData, setDisplayData] = useState(initialDisplayData);
    const [allState, setAllState] = useState(STATES);
    const store = useSelector((state) => state.session.store);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "in" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"],
    };
    useEffect(() => {
        try {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
            autoCompleteRef.current?.addListener("place_changed", async function () {
                try {
                    const place = await autoCompleteRef.current.getPlace();
                    handlePlaceSelected({ place }.place);
                } catch (error) {
                    throw new Error(error);
                }
            });
        } catch (err) {
            captureException(err);
        }
    }, []);

    // useEffect(() => {
    //     getStoreData();
    // }, []);

    useEffect(() => {
        if (store) {
            if (store.store_address?.lat_lng) {
                setDisplayData({
                    ...store,
                    ...initialDisplayData,
                    line_1: store.store_address?.line_1,
                    line_2: store.store_address?.line_2,
                    city: store.store_address?.city,
                    pin_code: +store.store_address?.pin_code,
                    state: store.store_address?.state,
                    mapPosition: {
                        lat: store.store_address.lat_lng[0],
                        lng: store.store_address.lat_lng[1],
                    },
                    markerPosition: {
                        lat: store.store_address.lat_lng[0],
                        lng: store.store_address.lat_lng[1],
                    },
                });
            } else {
                setDisplayData({
                    ...store,
                    ...initialDisplayData,
                    line_1: store.store_address?.line_1,
                    line_2: store.store_address?.line_2,
                    city: store.store_address?.city,
                    pin_code: +store.store_address?.pin_code,
                    state: store.store_address?.state,
                });
            }
        }
        // if (displayData?.store_address?.state) setSelectState(displayData?.store_address?.state);
    }, [store]);

    // const getStoreData = async () => {
    //     let res = await getStore();
    //     if (res.status === 200) {
    //         if (res.data.store_address?.lat_lng) {
    //             setDisplayData({
    //                 ...res.data,
    //                 ...initialDisplayData,
    //                 line_1: res.data.store_address?.line_1,
    //                 line_2: res.data.store_address?.line_2,
    //                 city: res.data.store_address?.city,
    //                 pin_code: +res.data.store_address?.pin_code,
    //                 state: res.data.store_address?.state,
    //                 mapPosition: {
    //                     lat: res.data.store_address.lat_lng[0],
    //                     lng: res.data.store_address.lat_lng[1],
    //                 },
    //                 markerPosition: {
    //                     lat: res.data.store_address.lat_lng[0],
    //                     lng: res.data.store_address.lat_lng[1],
    //                 },
    //             });
    //         } else {
    //             setDisplayData({
    //                 ...res.data,
    //                 ...initialDisplayData,
    //                 line_1: res.data.store_address?.line_1,
    //                 line_2: res.data.store_address?.line_2,
    //                 city: res.data.store_address?.city,
    //                 pin_code: +res.data.store_address?.pin_code,
    //                 state: res.data.store_address?.state,
    //             });
    //         }
    //     }
    // };

    const handleInputChange = (key, value) => {
        if (key === "pin_code") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value });
        } else if (key === "gst_number") {
            const regex = /^([a-zA-Z0-9]+)$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value.toUpperCase() });
        } else if (key === "city") {
            // const regex = /^([a-zA-Z]+)$/;
            // if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value });
        } else {
            setDisplayData({ ...displayData, [key]: value });
        }
    };
    function handleSearchChange(key, value) {
        setAllState(STATES.filter((e) => e.value.toLowerCase().includes(value.toLowerCase())));
    }

    function handleSelectState(key, item) {
        // setSelectState(item.value);
        setDisplayData({ ...displayData, state: item.value });
        setAllState(STATES);
    }

    const handleSaveAddress = async () => {
        const payload = {};
        const address = {};

        if (!displayData.line_1) {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please enter address line 1",
            });
            return;
        }
        if (!displayData.line_2) {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please enter address line 2",
            });
            return;
        }
        if (!displayData.pin_code) {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please enter pincode",
            });
            return;
        }
        if (!displayData.city) {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please enter city",
            });
            return;
        }
        if (!displayData.state) {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please select state",
            });
            return;
        }
        address.line_1 = displayData.line_1;
        address.line_2 = displayData.line_2;
        address.pin_code = displayData.pin_code;
        address.city = displayData.city;
        address.state = displayData.state;
        address.country = "Philippines";
        address.lat_lng = [displayData.markerPosition.lat, displayData.markerPosition.lng];

        if (!isEmpty(address)) {
            address.country = "Philippines";
            payload.store_address = address;
        }
        if (!isEmpty(payload)) {
            dispatch(showLoader());
            try {
                let res = await updateStore(payload);
                if (res.status === 200) {
                    // setDisplayData(res.data);
                    await dispatch(setStore(res.data));
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Store Saved",
                        subtitle: "Store saved successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            } finally {
                dispatch(hideLoader());
            }
        } else {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please change something",
            });
        }
    };

    const getCity = (addressArray) => {
        let city = "";
        if (!addressArray || !addressArray.length) {
            return city;
        }
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && "administrative_area_level_3" === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    const getArea = (addressArray) => {
        let area = "";
        if (!addressArray || !addressArray.length) {
            return area;
        }
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ("sublocality_level_1" === addressArray[i].types[j] || "locality" === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };

    const getState = (addressArray) => {
        let state = "";
        if (!addressArray || !addressArray.length) {
            return state;
        }
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && "administrative_area_level_1" === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    const getPincode = (addressArray) => {
        let pin_code = "";
        if (!addressArray || !addressArray.length) {
            return pin_code;
        }
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
                pin_code = addressArray[i].long_name;
                return pin_code;
            }
        }
    };

    const handlePlaceSelected = (place) => {
        const address = place.name,
            addressArray = place.address_components,
            city = getCity(addressArray),
            area = getArea(addressArray),
            state = getState(addressArray),
            pin_code = getPincode(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
        // Set these values in the state.
        setDisplayData({
            line_1: "",
            address: address ? address : "",
            line_2: area ? area : "",
            area: area ? area : "",
            pin_code: pin_code ? pin_code : "",
            city: city ? city : "",
            state: state ? state : "",
            markerPosition: {
                lat: latValue,
                lng: lngValue,
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue,
            },
        });
    };

    return (
        <S.Wrapper>
            <S.InputBody>
                <S.InputContainer>
                    <div>
                        <S.Label>
                            Address Line 1 <span>*</span>
                        </S.Label>
                        <S.Input type="text" value={displayData?.line_1} onChange={(e) => handleInputChange("line_1", e.target.value)} />
                    </div>
                    <div>
                        <S.Label>
                            Address Line 2 <span>*</span>
                        </S.Label>
                        <S.Input type="text" value={displayData?.line_2} onChange={(e) => handleInputChange("line_2", e.target.value)} />
                    </div>
                    <S.MultipleInputs>
                        <div>
                            <S.Label>
                                Pin Code <span>*</span>
                            </S.Label>
                            <S.Input
                                type="text"
                                value={displayData?.pin_code || ""}
                                onChange={(e) => handleInputChange("pin_code", e.target.value)}
                                maxLength="6"
                            />
                        </div>
                        <div>
                            <S.Label>
                                City <span>*</span>
                            </S.Label>
                            <S.Input type="text" value={displayData?.city} onChange={(e) => handleInputChange("city", e.target.value)} />
                        </div>
                    </S.MultipleInputs>
                    <S.MultipleInputs>
                        <div>
                            <S.Label>
                                State <span>*</span>
                            </S.Label>
                            <CustomSelect
                                type="state"
                                initalText={displayData?.state ?? "State"}
                                onSearchChange={handleSearchChange}
                                data={allState}
                                onSelectChange={handleSelectState}
                                position="top"
                            />
                        </div>
                        <div>
                            <S.Label>
                                Country <span>*</span>
                            </S.Label>
                            <CustomSelect type="country" initalText={"Philippines"} position="top" disabled />
                        </div>
                    </S.MultipleInputs>
                </S.InputContainer>
                <S.InputContainer>
                    <S.MapContainer>
                        <S.LabelContainer>
                            <S.Label>Store Location</S.Label>
                        </S.LabelContainer>
                        <S.AutocompleteContainer>
                            {/* <img src={HeaderSearchIcon} alt="search icon" /> */}
                            <input
                                type="text"
                                placeholder="Search a place"
                                ref={inputRef}
                                // value={address}
                                // onChange={handleAutocompleteInputChange}
                            />
                        </S.AutocompleteContainer>
                        <Map displayData={displayData} setDisplayData={setDisplayData} />
                    </S.MapContainer>
                </S.InputContainer>
            </S.InputBody>
            <S.BottomContainer>
                <S.Hint>(Note: "Online delivery depends on this location. Please don't change it unnecessarily")</S.Hint>
                <S.SaveBtn onClick={handleSaveAddress}>Save</S.SaveBtn>
            </S.BottomContainer>
        </S.Wrapper>
    );
};

export default Location;
