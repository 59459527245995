import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    width: 950px;
    height: 637px;
    box-sizing: border-box;
`;

export const CloseBtn = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    right: 28px;
    top: 28px;
`;

export const Logo = styled.img`
    width: 62px;
    height: 62px;
`;

export const LogoContainer = styled.div`
    margin-top: -22px;
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
`;

export const Heading = styled.div`
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: #334155;
`;
export const SubHeading = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #334155;
`;
export const SubBodyText = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
`;

export const HeroContainer = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 27px 30px;
    height: 115px;
    // border: 1px solid red;
`;

export const SpaceBox = styled.div`
    width: 62px;
`;

export const PosLogo = styled.img`
    width: 161px;
    height: 161px;
    border-radius: 20px;
`;

export const PosHeading = styled.div`
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    color: #334155;
`;

export const CounterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-radius: 3px;
    border: 1px solid #a2a3b1;
    width: 100%;

    & > img {
        width: 17px;
        height: 17px;
        cursor: pointer;
    }
`;

export const Counter = styled.div`
    color: #17183b;
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
`;

export const POS = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const PosContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 73px;
    margin-bottom: 46px;
    margin-top: 74px;
`;

export const GreenBtn = styled.div`
    background: #009e23;
    padding: 12px 13px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 29px 22px 73px;
`;

export const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 22px;
`;

export const Amount = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const CashWord = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #334155;
    margin-bottom: 3px;
`;
export const Cash = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #000;
`;

export const PaymentIcons = styled.div`
    display: flex;
    item-align: center;
    gap: 12px;
`;
export const QRContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    margin-top: 31px;
`;

export const QRAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
`;
export const PaymentIconsLarge = styled.div`
    display: flex;
    item-align: center;
    justify-content: center;
    gap: 56px;
    margin-top: 27px;

    & > img {
        // width: ${(props) => props.size && props.size};
        width: 122px;
    }
    & > img[alt="paytm"] {
        width: 180px;
    }
`;

export const PosLogoSmall = styled.img`
    width: 62px;
    height: 62px;
    border-radius: 8px;
`;
export const YellowRoundedBox = styled.div`
    border: 1px solid #ffde2c;
    padding: 6px 16px;
    border-radius: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
`;

export const PosCount = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const PosCountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
    margin-top: 57px;
    margin-bottom: 50px;
`;

export const CenterText = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    & > div {
        width: ${(props) => props.size && props.size};
    }
`;

export const PaymentSuccessLogo = styled.div`
    display: flex;
    justify-content: center;
    margin: 51px 0;

    & > img {
        width: 138px;
        height: 138px;
    }
`;
export const Gap = styled.div`
    height: ${(props) => props.size && props.size};
`;

export const FailedPaymentText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    margin: 6px 0 105px;
`;
