import React, {
  useContext,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThreeDotsVerticalIcon } from "../../../icons";
import {
  removeSelectedCustomer,
  setSelectedCustomer,
} from "../../../store/customer-slice";
import {
  addProductToSale,
  removeAllProducts,
  setAdditionalCharges,
  setBillno,
  setCreatedDate,
  setSaleCount,
  setSaleID,
  setSelectDiscount,
  setSelectedSale,
} from "../../../store/sale-slice";
import * as S from "./SalesTable.style";
import DeleteModal from "./../../purchase/delete-modal/DeleteModal";
import { addSale, updateSale } from "../../../api/local/sale-api";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import { fetchAllSales } from "./../../../store/sale-slice";
import moment from "moment";
import ConfirmDialog from "../confirm-dialog/ConfirmDialog";
import {
  DiscountOption,
  SALES_TYPE,
  SALE_BILL_TYPE,
  SALE_STATUS,
} from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";
import {
  convertTax,
  getDraftProductToSlice,
  getQuantity,
  getSalePayloadProducts,
  handleSelectPiDraft,
  handleSelectSaleDraft,
  toDecimalPlace,
} from "../../../utils/global-fn";
import {
  addProductToPI,
  removeAllProductsPI,
  setAdditionalChargesPI,
  setBillNoPI,
  setCreatedDatePI,
  setCustomerPI,
  setPI_ID,
  setSelectDiscountPI,
  setSelectedPI,
} from "../../../store/proformainvoice-slice";
import { getPayment, getSaleNetAmount } from "../../../utils/object-parser";

const Sale = ({ item, idx, activeRow, scrollRef, setOpenDeleteModal }, ref) => {
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    searchSaleParams,
    saleProducts,
    saleId,
    createdDate,
    selectDiscount,
    saleTotal,
    billno,
    sales,
    globalDiscount,
  } = useSelector((state) => state.sale);
  const {
    PI_id,
    PI_products,
    PI_customer,
    PI_createdDate,
    PI_selectDiscount,
    PI_total,
    PI_billNo,
    PI_type,
    PI_globalDiscount,
  } = useSelector((state) => state.proformaInvoice);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const { inventorySelectedCustomer } = useSelector((state) => state.customer);
  const { billTypePreference } = useSelector((state) => state.app);
  const [payment, setPayment] = useState(null);
  const [netAmount, setNetAmount] = useState(0);

  useEffect(() => {
    if (item) {
      let p = getPayment(item?.payment, item?.payment);
      setPayment(p);

      let amt = getSaleNetAmount(item);
      setNetAmount(amt);
    }
  }, [item]);

  useImperativeHandle(ref, () => {
    return {
      refSaleRowClick: () => handleSaleSelect(sales[activeRow]),
    };
  });

  function handleSaleSelect(sale) {
    if (sale.status === SALE_STATUS.PUBLISHED) {
      dispatch(setSelectedSale(sale));
      dispatch(setSelectedCustomer(sale.patient));
      navigate("/dashboard/view-sale");
    } else if (sale.status === SALE_STATUS.DRAFT) {
      if (sale.type === SALES_TYPE.SALE) {
        if (saleId === sale.id) {
          navigate("/dashboard/new-sale");
          return;
        }
        if (saleProducts.length) {
          setOpenConfirmDialog(true);
        } else {
          manageSaleCart(sale);
        }
      } else if (
        [SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
          sale.type
        )
      ) {
        if (PI_id === sale.id) {
          navigate("/dashboard/proforma-invoice");
          return;
        }
        if (PI_products.length) {
          setOpenConfirmDialog(true);
        } else {
          managePICart(sale);
        }
      }
    }
  }

  const manageSaleCart = (item) => {
    handleSelectSaleDraft(item, dispatch);
    navigate("/dashboard/new-sale");
  };
  const managePICart = (item) => {
    handleSelectPiDraft(item, dispatch);
    navigate("/dashboard/proforma-invoice");
  };

  const payloadSale = () => {
    const payload = {
      status: "DRAFT",
      bill_type: billTypePreference?.sale_bill_type,
      products: getSalePayloadProducts(
        saleProducts,
        billTypePreference?.sale_bill_type
      ),
    };
    if (inventorySelectedCustomer)
      payload.patient_id = inventorySelectedCustomer.id;
    if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
    if (selectDiscount.cost) {
      let disc = {
        type: selectDiscount.type,
        value: selectDiscount.cost,
      };
      payload.cart_manual_discount = disc;
    }
    if (billno) payload.bill_no = billno;

    return payload;
  };
  const payloadPI = () => {
    const payload = {
      type: PI_type,
      status: "DRAFT",
      products: getSalePayloadProducts(PI_products, SALE_BILL_TYPE.INVOICE),
    };

    if (PI_customer) payload.patient_id = PI_customer.id;
    if (PI_createdDate)
      payload.created_at = Math.floor(PI_createdDate.getTime());
    if (PI_selectDiscount.cost) {
      let disc = {
        type: PI_selectDiscount.type,
        value: PI_globalDiscount,
      };
      payload.cart_manual_discount = disc;
    }
    if (PI_billNo) payload.bill_no = PI_billNo;

    return payload;
  };
  async function handleDraftBill() {
    let payload;

    if (item.type === SALES_TYPE.SALE) payload = payloadSale();
    else if (
      [SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
        item.type
      )
    )
      payload = payloadPI();

    try {
      let res;
      let title;
      let subtitle = "Draft saved successfully";
      if (item.type === SALES_TYPE.SALE && saleId) {
        //update the existing sale
        res = await updateSale(saleId, payload);
        title = "Draft Updated";
      } else if (
        [SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
          item.type
        ) &&
        PI_id
      ) {
        //update the existing proforma invoice
        res = await updateSale(PI_id, payload);
        title = "Draft Updated";
      } else {
        //Add the new sale
        res = await addSale(payload);
        title = "Draft Added";
      }

      if (res.status === 200) {
        handleBillClear(item);
        handleSnackbarDetails({
          show: true,
          type: "success",
          title,
          subtitle,
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  function handleBillClear() {
    if (item.type === SALES_TYPE.SALE) manageSaleCart(item);
    else if (
      [SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
        item.type
      )
    )
      managePICart(item);
  }
  return (
    <S.TableRow
      key={idx}
      active={idx === activeRow}
      onClick={(e) => {
        e.stopPropagation();
        handleSaleSelect(item);
      }}
      ref={idx === activeRow ? scrollRef : null}
    >
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={(e) => {
          e.stopPropagation();
          setOpenConfirmDialog(false);
        }}
        handleDraftBill={handleDraftBill}
        handleBillClear={handleBillClear}
      />

      <S.Td flexValue="0.75">{moment(item.created_at).format("lll")}</S.Td>
      <S.Td flexValue="0.7" center>
        {item.is_online_sale ? "(Online)" : item.invoice_id}
      </S.Td>
      <S.Td flexValue="0.7" mr="60px" center>
        {item.is_online_sale
          ? "Online Order"
          : item.type === SALES_TYPE.PROFORMA_INVOICE
          ? "Proforma Invoice"
          : item.type === SALES_TYPE.DELIVERY_CHALLAN
          ? "Delivery Challan"
          : item.bill_type
          ? `${item.bill_type} Bill`
          : "POS Bill"}
      </S.Td>
      <S.Td flexValue="0.7" $customer>
        <S.CustomerName>{item.patient?.name}</S.CustomerName>
        {item.patient?.phone && `(${item.patient?.phone})`}
      </S.Td>
      <S.Td flexValue="0.7" center>
        {item.items.length}
      </S.Td>
      <S.Td flexValue="0.7" center mr="40px">
        ₱{netAmount}
      </S.Td>
      <S.Status
        flexValue="0.75"
        status={payment?.is_paid}
        online={item.is_online_sale}
      >
        {item.status === "DRAFT"
          ? "Draft"
          : payment?.is_paid && !item?.is_online_sale
          ? "Completed"
          : payment?.is_paid && item?.is_online_sale
          ? `Completed (Online)`
          : [SALES_TYPE.PROFORMA_INVOICE, SALES_TYPE.DELIVERY_CHALLAN].includes(
              item?.type
            )
          ? `Pending(₱${toDecimalPlace(netAmount)})`
          : `Pending(₱${toDecimalPlace(payment?.amount_due)})`}
        <OutsideClickHandler
          onOutsideClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          <img
            src={ThreeDotsVerticalIcon}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          />
          {open && (
            <OptionsContainer
              handleRowClick={() => handleSaleSelect(item)}
              onClose={() => setOpen(false)}
              setOpenDeleteModal={setOpenDeleteModal}
              item={item}
            />
          )}
        </OutsideClickHandler>
      </S.Status>
    </S.TableRow>
  );
};

export default React.forwardRef(Sale);

const OptionsContainer = ({
  handleRowClick,
  onClose,
  setOpenDeleteModal,
  item,
}) => {
  return (
    <S.OptionsContainer>
      <S.Option
        onClick={(e) => {
          e.stopPropagation();
          handleRowClick();
        }}
      >
        Edit
      </S.Option>
      {!item.is_online_sale && (
        <S.Option
          onClick={(e) => {
            e.stopPropagation();
            setOpenDeleteModal({ show: true, data: item });
            onClose();
          }}
        >
          Delete
        </S.Option>
      )}
    </S.OptionsContainer>
  );
};
