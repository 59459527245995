import { isEmpty, isEqual } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../api/local/globalSearchApi";
import { updateStore } from "../../../api/local/storeApi";
import { ImageEditIconDark, PlusGrayIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { validEmail, validGstNumber, validPincode } from "../../../utils/validity-fns";
import LogoutModal from "../../logout-modal/LogoutModal";
import * as S from "./style";
import StoreLogo from "./../../store-logo/StoreLogo";
import { useRef } from "react";
import { showLoader, hideLoader } from "../../../store/app-slice";
import { setStore } from "../../../store/session-slice";
import { captureException } from "../../../crash-reporting";
import StoreTypeSelect from "../../store-type-select/StoreTypeSelect";
import CropperModal from "../../product-drawer/cropper-modal/CropperModal";
import { uploadImageToS3 } from "../../../api/aws/s3Client";

const Account = () => {
    const [openLogout, setOpenLogout] = useState(false);
    const [image, setImage] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [displayData, setDisplayData] = useState(null);
    const store = useSelector((state) => state.session.store);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const imgRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cropperImg, setCropperImg] = useState({ open: false, image: null });

    useEffect(() => {
        if (store) {
            setDisplayData({
                ...store,
                fssai: store.licence ? store.licence.value : "",
                store_type: store.store_type ? store.store_type : null,
            });
            if (store.logo) {
                setImage(store.logo);
            }
        }
    }, [store]);

    const handleLogout = async (backupDatabase) => {
        dispatch(showLoader());
        try {
            let res = await window.electronAPI.logoutStore({
                backup_database: backupDatabase,
            });
            if (res.status !== "success") {
                throw Error(res?.message ?? "Unable to logout!");
            }
        } catch (e) {
            // handleSnackbarDetails({
            //     show: true,
            //     title: e.message ?? "Unable to logout!",
            //     type: "error",
            // });
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleInputChange = (key, value) => {
        if (key === "pin_code") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value });
        } else if (key === "fssai") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value });
        } else if (key === "gst_number") {
            const regex = /^([a-zA-Z0-9]+)$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value.toUpperCase() });
        } else if (key === "city") {
            const regex = /^([a-zA-Z]+)$/;
            if (!(value === "" || regex.test(value))) return;
            setDisplayData({ ...displayData, [key]: value });
        } else {
            setDisplayData({ ...displayData, [key]: value });
        }
    };

    const handleuploadImage = async (file) => {
        let res = await uploadFile(file);
        if (res.status === 200) {
            return res.data.path;
        }
    };

    const handleImageChange = async (event) => {
        let image = event.target.files[0];
        if (image) setCropperImg({ open: true, image: image });
    };

    const handleClearLogo = () => {
        handleUpdateStoreImage("");
    };

    async function handleSaveStore() {
        if (displayData.email && !validEmail(displayData.email)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid email",
                type: "error",
            });
            return;
        }
        if (!displayData.store_name) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid store name",
                type: "error",
            });
            return;
        }
        if (displayData.gst_number && !validGstNumber(displayData.gst_number)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid TAX number",
                type: "error",
            });
            return;
        }

        if (!displayData.store_type) {
            handleSnackbarDetails({
                show: true,
                title: "Please select a business type",
                type: "error",
            });
            return;
        }

        const payload = {};
        if (store.store_name !== displayData.store_name) {
            payload.store_name = displayData.store_name;
        }
        if (store.contact_person !== displayData.contact_person) {
            if (displayData.contact_person) {
                payload.contact_person = displayData.contact_person;
            } else {
                payload.contact_person = "Admin";
            }
        }
        if (store.email !== displayData.email) {
            payload.email = displayData.email;
        }
        if (store.gst_number !== displayData.gst_number) {
            payload.gst_number = displayData.gst_number;
        }

        const licence = {};
        if (store?.licence?.value != displayData.fssai) {
            licence.type = "FSSAI";
            licence.value = displayData.fssai;
            licence.updated_at = Date.now();
        }
        if (!isEmpty(licence)) {
            payload.licence = licence;
        }

        if (!isEqual(store.store_type, displayData.store_type)) {
            payload.store_type = displayData.store_type;
        }

        if (!isEmpty(payload)) {
            dispatch(showLoader());
            try {
                let res = await updateStore(payload);
                if (res.status === 200) {
                    setDisplayData(res.data);
                    await dispatch(setStore(res.data));
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Store Saved",
                        subtitle: "Store saved successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            } finally {
                dispatch(hideLoader());
            }
        } else {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please change something to update",
            });
        }
    }

    const handleSelectChange = (item) => {
        setDisplayData({ ...displayData, store_type: item });
    };

    const onSubmitCropImage = async (image) => {
        let imgKey = `stores/${store?.uid}.png`;
        try {
            let s3Res = await uploadImageToS3(image, imgKey);
            if (s3Res.status === "success") {
                let image = s3Res.img_url;
                handleUpdateStoreImage(image);
            } else {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Unable to upload image.",
                });
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleUpdateStoreImage = async (image) => {
        try {
            let res = await updateStore({ logo: image });
            if (res.status === 200) {
                dispatch(setStore(res.data));
                setImage(image);
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Succesful",
                    subtitle: "Store logo has been updated successfully.",
                });
            } else {
                throw new Error("Unable to update store");
            }
        } catch (error) {
            captureException(error);
        }
    };

    return (
        <S.Wrapper>
            <CropperModal
                open={cropperImg.open}
                onClose={() => setCropperImg({ open: false, image: null })}
                image={cropperImg.image}
                onSubmit={(image) => onSubmitCropImage(image)}
            />
            <LogoutModal type="admin" open={openLogout} onClose={() => setOpenLogout(false)} onClick={handleLogout} />
            <div>
                <S.Header>
                    {/* <img src={image || PlusGrayIcon} alt="store logo" /> */}
                    {image ? (
                        <S.Logo src={image + `?n=${store?.updated_at}`} alt="store logo" />
                    ) : (
                        <StoreLogo store={store} size={"49px"} color={"#0f172a"} />
                    )}
                    <S.EditIcon onClick={() => imgRef.current?.click()}>
                        <img src={ImageEditIconDark} alt="" />
                    </S.EditIcon>
                    <S.ImageContainer>
                        <div>Upload Logo</div>
                        <input type="file" accept="image/*" onChange={handleImageChange} ref={imgRef} />
                    </S.ImageContainer>
                    <S.RemoveText onClick={handleClearLogo}>Remove Logo</S.RemoveText>
                </S.Header>

                <S.InputBody>
                    <S.InputContainer>
                        <div>
                            <S.Label>
                                Business Name <span>*</span>
                            </S.Label>
                            <S.Input type="text" value={displayData?.store_name} onChange={(e) => handleInputChange("store_name", e.target.value)} />
                        </div>
                        <div>
                            <S.Label>Business Owner Name</S.Label>
                            <S.Input
                                type="text"
                                value={displayData?.contact_person}
                                onChange={(e) => handleInputChange("contact_person", e.target.value)}
                            />
                        </div>
                        <div>
                            <S.Label>
                                Phone number <span>*</span>
                            </S.Label>
                            <S.PhoneInput>
                                <S.Code>+63</S.Code>
                                <S.Input type="text" width="114px" value={displayData?.phone} disabled />
                            </S.PhoneInput>
                        </div>
                        <div>
                            <S.Label>Email ID</S.Label>
                            <S.Input type="text" value={displayData?.email} onChange={(e) => handleInputChange("email", e.target.value)} />
                        </div>
                    </S.InputContainer>
                    <S.InputContainer>
                        <div>
                            <S.Label>FSSAI</S.Label>
                            <S.Input type="text" value={displayData?.fssai} onChange={(e) => handleInputChange("fssai", e.target.value)} />
                        </div>
                        <div>
                            <S.Label>GSTIN</S.Label>
                            <S.Input type="text" value={displayData?.gst_number} onChange={(e) => handleInputChange("gst_number", e.target.value)} />
                        </div>
                        <div>
                            <S.Label>Business Type</S.Label>
                            <StoreTypeSelect
                                selectedOption={displayData?.store_type?.name ?? "Select Business Type"}
                                onChange={handleSelectChange}
                                position={"top"}
                            />
                        </div>
                    </S.InputContainer>
                </S.InputBody>
            </div>
            <S.BottomContainer>
                <S.Logout onClick={() => setOpenLogout(true)}>Logout</S.Logout>
                <S.RightBottomContainer>
                    {/* <S.CancelBtn>Cancel</S.CancelBtn> */}
                    <S.SaveBtn onClick={handleSaveStore}>Save Changes</S.SaveBtn>
                </S.RightBottomContainer>
            </S.BottomContainer>
        </S.Wrapper>
    );
};

export default Account;
