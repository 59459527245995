import React, { useState } from "react";
import { DarkArrowLeft } from "../../icons";
import * as S from "./style";

const OtherDetails = ({ setActiveTab, onSubmit }) => {

    const [otherDetail, setOtherDetail] = useState({
        existing_pos: null,
        sale_online: null
    })

    return (
        <S.Wrapper onSubmit={(e) => onSubmit(e,otherDetail)} >
            <div>
                <S.TabHead>Other details</S.TabHead>
                <S.InputsBodyContainer>
                    <S.InputContainer>
                        <S.Labels>
                            Are you already using a POS or billing system? If
                            yes, please enter the name below
                        </S.Labels>
                        <S.InputFIelds>
                            <input type="text" onChange={(e) => setOtherDetail({ ...otherDetail, existing_pos: e.target.value })} />
                        </S.InputFIelds>
                    </S.InputContainer>
                    <S.InputContainer>
                        <S.Labels>
                            Do you wish to sell your products through our online
                            store?
                        </S.Labels>
                        <S.RadioButtonContainer>
                            <div>
                                <input type="radio" name="wish_to_sell" value={true} onChange={(e) => setOtherDetail({ ...otherDetail, sale_online: true })} checked={otherDetail.sale_online === true} />
                                <S.RadioLabel for="Yes" onClick={() => setOtherDetail({ ...otherDetail, sale_online: true })}>Yes</S.RadioLabel>
                            </div>
                            <div>
                                <input type="radio" name="wish_to_sell" value={false} onChange={(e) => setOtherDetail({ ...otherDetail, sale_online: false })} checked={otherDetail.sale_online === false} />
                                <S.RadioLabel for="No" onClick={() => setOtherDetail({ ...otherDetail, sale_online: false })}>No</S.RadioLabel>
                            </div>
                        </S.RadioButtonContainer>
                    </S.InputContainer>
                </S.InputsBodyContainer>
            </div>
            <S.DoubleButtonFooter>
                <S.WhiteButton onClick={() => setActiveTab((p) => p - 1)}>
                    <img src={DarkArrowLeft} alt="" />
                    <div>Go Back</div>
                </S.WhiteButton>
                <S.DarkButton type="submit">
                    <div>Proceed</div>
                </S.DarkButton>
            </S.DoubleButtonFooter>
        </S.Wrapper>
    );
};

export default OtherDetails;
