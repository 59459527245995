import React from "react";
import * as S from './style'
import { DigiDukandarLogo } from "../../../icons";

const BrandingLogo = ({size = "sm"}) => {
    return (
        <S.Wrapper size={size}>
            <S.Logo src={DigiDukandarLogo} alt="digidukandar logo" />
        </S.Wrapper>
    );
};

export default BrandingLogo;
