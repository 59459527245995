import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;

export const Headline = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;

    &:nth-child(3) {
        display: none;
    }
`;

export const AddSupplierBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    height: 40px;
    width: 132px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Body = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Content = styled.div`
    flex: 1;
    // padding: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const FiltersLeft = styled.div`
    display: flex;
    gap: 8px;
`;

export const FiltersRight = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    // margin-right: 15px;
`;

export const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 10px;
    padding: 10px 16px;
    border-bottom: 1px solid #e2e8f0;
`;

export const InputContainer = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 0px 11px;
    display: flex;
    align-items: center;
    width: 291px;
    // height: 36px;

    img {
        height: 24px;
        width: 24px;
    }

    img[alt*="search"] {
        margin-right: 12px;
    }

    img[alt*="clear"] {
        cursor: pointer;
        margin-left: 10px;
    }
`;

export const Input = styled.input`
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475569;

    &::placeholder {
        color: #cbd5e1;
    }
`;

export const ChangeView = styled.div`
    height: 48px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    gap: 16px;
    padding: 8px 16px;

    img {
        width: 32px;
        height: 32px;
    }
`;
export const PageContainer = styled.div`
    padding: 0px 6px;
    box-sizing: border-box;
`;
export const ChipsContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
`;

export const CheckBoxContainer = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
    padding: 4px 6px;
    cursor: pointer;

    & > input {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
    & > label {
        cursor: pointer;
    }
`;
