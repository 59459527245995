import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
`;

export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const Heading = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #263238;
`;

export const Body = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    width: 100%;
    height: calc(100vh - 144px);
`;
export const FilterBar = styled.div`
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    gap: 16px;
    width: 100%;
`;
export const dateContainer = styled.div`
    position: relative;
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-top: 2px;
`;

export const DateChooserSelect = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #475569;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 40px;
    cursor: pointer;
    width: 280px;

    position: relative;
    top: 0;

    img {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
`;

export const FilterDiv = styled.div`
    width: 50%;
    border-right: 1px solid #e2e8f0;
`;

export const ReportContainer = styled.div`
    width: 100%;
    height: calc(100vh - 185px);
    display: flex;
`;

export const ReportRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;
`;
export const NameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #455a64;

    & img {
        width: 24px;
        height: 24px;
    }
`;

export const SubContainer = styled.div`
    width: 50%;
    height: 100%;
    ${(props) => props.border && "border-right: 1px solid #e2e8f0"};
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;
    overflow-y: auto;
`;
