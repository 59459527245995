import styled from "@emotion/styled/macro";

export const Wrapper = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 56px);
`;

export const TabHead = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    width: auto;
    color: #1e293b;
    display: inline-block;
    margin: 12px 0 39px;
`;
export const InputsBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
export const InputContainer = styled.div`
    flex: 1;
`;
export const MultipleInputsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 23px;
`;
export const InputFIelds = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 48px;
    & img {
        cursor: pointer;
    }

    & input {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #334155;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        width: 100%;
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::placeholder {
            color: #94a3b8;
        }
    }
`;
export const PhoneInputField = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    & > div:nth-of-type(1) {
        width: 60px;
    }
`;
export const Labels = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin-bottom: 12px;

    & span {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #e54630;
    }
`;
export const SingleButtonFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const DoubleButtonFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const DarkButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    height: 44px;
    background: #253238;
    border-radius: 4px;
    cursor: pointer;
    border: none;

    & div {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
    }
    & img {
        width: 24px;
        height: 24px;
    }
`;

export const WhiteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    height: 44px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #253238;

    & div {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #253238;
    }
    & img {
        width: 24px;
        height: 24px;
    }
`;
export const DualColorTexts = styled.div`
    display: flex;
    gap: 5px;
    ont-weight: 400;
    font-size: 16px;
    line-height: 20px;

    & > div:nth-of-type(1) {
        color: #64748b;
    }
    & > div:nth-of-type(2) {
        color: #1e293b;
    }
`;
export const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 96px;

    & input {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }
    & div {
        display: flex;
        align-items: center;
    }
`;
export const RadioLabel = styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #334155;
    cursor: pointer;
`;
export const Otp = styled.div`
    cursor: pointer;
`;
export const Resend = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
`;

export const BottomText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &>div: nth-of-type(1) {
        color: #64748b;
    }
    &>div: nth-of-type(2) {
        color: #253238;
        cursor: pointer;
    }
`;
export const CreateAccount = styled.div`
    cursor: pointer;
`;

export const OtpContainer = styled.div`
    margin: 36px 0 24px;

    & input {
        width: 288px;
        height: 48px;
        text-align: center;
        background: #f5f6f7;
        border: 1px solid #cdced1;
        border-radius: 6px;
        outline: none;
        font-weight: 400;
        font-size: 22px;
        line-height: 21px;
        letter-spacing: 20px;
        color: #1e293b;
    }
`;

export const Error = styled.div`
    font-size: 12px;
    color: red;
    position: absolute;
`;
