import moment from "moment";
import React from "react";
import { ProductPlaceholderIcon, WhiteArrowRight, WifiDisconnectedIcon } from "../../../icons";
import * as S from "./style";

const Draft = ({ item, activeRow, idx, handleDraftClick, handleDraftDelete, scrollRef }) => {
    return (
        <S.SaleRow key={item.id} onClick={() => handleDraftClick(item)} active={idx === activeRow} ref={idx === activeRow ? scrollRef : null}>
            <S.FlexConteiner>
                <div>
                    <S.SaleTopbar>
                        <S.SaleId>#{item.id}</S.SaleId>
                    </S.SaleTopbar>
                    <S.FlexContainer>
                        <S.CustomerName>{item.patient?.name}</S.CustomerName>
                        <S.CustomerPhone>{item.patient ? `(${item.patient.phone})` : ""}</S.CustomerPhone>
                    </S.FlexContainer>
                    <S.SaleQuantity>{item.items.length} SKUs</S.SaleQuantity>
                    <S.FlexContainer>
                        {item.total_price !== item.net_amount && <S.Price>₱{item.total_price}</S.Price>}
                        <S.SalePrice>₱{item.net_amount.toFixed(2)}</S.SalePrice>
                    </S.FlexContainer>
                </div>
                <S.DateContainer>
                    <S.SaleDate>{moment(item.created_at).format("ll")}</S.SaleDate>
                    <S.Time>{moment(item.created_at).format("LT")}</S.Time>
                </S.DateContainer>
            </S.FlexConteiner>
            <S.BottomContainer>
                <S.ImageContainer>
                    {item.items.map((p, i) => (
                        <S.ImageDiv key={i}>
                            <img
                                src={p.gallery?.length ? p.gallery?.[0] : ProductPlaceholderIcon}
                                alt="sale product avatar"
                                onError={(e) => {
                                    e.target.src = WifiDisconnectedIcon;
                                }}
                            />
                        </S.ImageDiv>
                    ))}
                </S.ImageContainer>
                <S.BtnContainer>
                    <S.DeleteBtn onClick={(e) => handleDraftDelete(e, item)}>Delete</S.DeleteBtn>
                    <S.ViewOrder>
                        <div>View Order</div>
                        <img src={WhiteArrowRight} alt="blue arrow" />
                    </S.ViewOrder>
                </S.BtnContainer>
            </S.BottomContainer>
        </S.SaleRow>
    );
};

export default Draft;
