import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
`;

export const Body = styled.div`
    box-sizing: border-box;
`;

export const LeftInputContainer = styled.div`
    flex: 1;
    border: 1px solid red;
`;
export const RightInputContainer = styled.div`
    flex: 1;
    border: 1px solid red;
`;

export const InputContainer = styled.div`
    margin-bottom: 12px;
    flex: 1;
`;

export const PhoneInputContainer = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    & div:nth-of-type(1) {
        width: 10%;
        & > input {
            width: 100%;
        }
    }
    & div:nth-of-type(2) {
        width: 87%;
    }
`;

export const DatePickerContainer = styled.div`
    position: absolute;
    //   top: 39px;
    //   right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 13000;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    max-height: 36px;
    cursor: pointer;
    justify-content: space-between;

    img {
        height: 20px;
        width: 20px;
        // margin-left: 8px;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;

    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 14px;
        gap: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        color: #253238;
        border: 1px solid #253238;
        border-radius: 4px;
    }
`;
export const RequiredContainer = styled.div`
    display: flex;
    & span {
        color: red;
        margin: -3px 0 0 2px;
    }
`;

export const InputContainers = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
`;
