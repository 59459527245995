import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCategories } from "../api/local/categoryApi";
import { captureException } from "../crash-reporting";

const initialState = {
  loading: false,
  categories: [],
  error: "",
  inventorySelectedCategory: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllCategories = createAsyncThunk(
  "category/fetchAllCategories",
  (search) =>
    getAllCategories(search)
      .then((res) => res.data?.entity)
      .catch((err) => captureException(err))
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.inventorySelectedCategory = action.payload;
    },
    removeSelectedCategory: (state) => {
      state.inventorySelectedCategory = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAllCategories.rejected, (state, action) => {
      state.loading = false;
      state.categories = [];
      state.error = action.error.message;
    });
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default categorySlice.reducer;
export const { setSelectedCategory, removeSelectedCategory } =
  categorySlice.actions;
