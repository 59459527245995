import styled from "@emotion/styled/macro";
import { multilineEllipsis } from "../../../../assets/css/mixin";

export const Table = styled.div`
    border: 1px solid #e2e8f0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const TableHeader = styled.div`
    display: flex;
    background: #f1f5f9;
    height: 34px;
    align-items: center;
    padding: 0 23px 0 36px;
    border-bottom: 1px solid #e2e8f0;
`;

export const Header = styled.div`
    flex: ${(props) => props.flexValue};
    font-weight: 500;
    font-size: 11.24px;
    line-height: 14px;
    color: #64748b;
    margin-right: ${(props) => (props.lastItem ? "0" : "67px")};
`;

export const TableBody = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const TableRow = styled.div`
    display: flex;
    padding: 12px 16px 12px 36px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    background: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    cursor: pointer;

    &:hover {
        background: #f1f5f9;
    }
`;

export const ImagePlaceholder = styled.div`
    min-width: 61px;
    min-height: 61px;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        min-height: 60px;
        min-width: 60px;
    }
`;

export const ProductData = styled.div`
    flex: 2.75;
    display: flex;
    align-items: center;
    margin-right: 67px;
    cursor: pointer;
`;

export const ProductImage = styled.img`
    min-width: 61px;
    max-width: 61px;
    min-height: 61px;
    max-height: 61px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;
`;

export const ProductName = styled.div`
    font-weight: 400;
    font-size: 14.05px;
    line-height: 17px;
    color: #334155;
    //   ${multilineEllipsis({
        fontSize: "20px",
        lineHeight: "24px",
        lines: 2,
    })}
`;

export const Attr = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
    width: 185px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 6px;
    // ${multilineEllipsis({ fontSize: "20px", lineHeight: "24px", lines: 2 })}
`;

export const BrandData = styled.div`
    flex: 1.5;
    margin-right: 67px;
    font-weight: 500;
    font-size: 12.645px;
    line-height: 14px;
    color: #64748b;
    cursor: pointer;
`;

export const PriceData = styled.div`
    flex: 1.25;
    margin-right: 67px;
    display: flex;
    align-items: center;
    color: #334155;
    cursor: pointer;
`;

export const TextStrike = styled.span`
    text-decoration: line-through;
    color: #334155;
    margin-right: 8px;
    margin-bottom: 2px;
`;

export const StrikedPrice = styled.span`
    font-weight: 400;
    font-size: 12.645px;
    line-height: 15px;
    color: #94a3b8;
`;

export const Price = styled.div`
    font-weight: 400;
    font-size: 14.05px;
    line-height: 17px;
`;

export const StockData = styled.div`
    flex: 1.75;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    img {
        width: 23px;
        height: 23px;
        cursor: pointer;
    }
`;

export const StockStatus = styled.div`
    font-weight: 400;
    font-size: 12.645px;
    line-height: 15px;
    color: ${(props) => (+props.stockType === 0 ? "#EE1111" : +props.stockType === -1 ? "#17b288" : "#17b288")};
    cursor: pointer;
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 122px;
    height: 99px;
    top: 0;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    // gap: 15px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 1;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 11.25px;
    line-height: 15px;
    letter-spacing: -0.24px;
    color: #333333;
    padding: 8px 16px;

    :hover {
        background-color: #f1f5f9;
    }
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
