import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 1.81047px 14.4837px rgba(72, 79, 103, 0.24);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 457px;
    height: 443px;
    box-sizing: border-box;
    padding: 16px;
`;

export const BlueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2d68fe;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > img {
        cursor: pointer;
    }
`;
export const Head = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #334155;
`;
export const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;

    & input {
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
        width: 100%;
    }
`;
export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ShowCash = styled.div`
    background-color: #e7e7e7;
    border: 1px solid #e2e8f0;
    padding: 10px 12px;
    box-sizing: border-box;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
`;

export const ShowCashContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 46px;

    & > div {
        flex: 1;
    }
`;
