import React, { useEffect, useState } from "react";
import * as S from "./HelpCenter.style";
import { isEmpty } from "lodash";
import { captureException } from "../../crash-reporting";
import { AboutUs, Facebook, Instagram, Messenger, Phone, PrivacyPolicy, ReturnPolicy, TermsConditions, WhatsApp } from "../../icons";

const HelpCenter = () => {
    const [appConfig, setAppConfig] = useState(null);

    useEffect(() => {
        handleGetAppConfig();
    }, []);

    const handleGetAppConfig = async () => {
        try {
            let config = await window.electronAPI.getAppConfig();
            if (config && !isEmpty(config)) {
                setAppConfig(config);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleLinkClick = (key) => {
        let url;
        if (key === "whatsapp") {
            url = `https://wa.me/${appConfig?.whatsapp_phone}`;
        } else if (key === "facebook") {
            url = `https://www.facebook.com/profile.php?id=${appConfig?.facebook_id}`;
        } else if (key === "messenger") {
            url = `https://www.facebook.com/messages/t/${appConfig?.facebook_id}`;
        } else if (key === "instagram") {
            url = `https://www.instagram.com/${appConfig?.instagram_id}`;
        } else if (key === "about") {
            url = appConfig?.link_about_us;
        } else if (key === "return_refund_policy") {
            url = appConfig?.link_return_refund_cancellation_policy;
        } else if (key === "privacy_policy") {
            url = appConfig?.link_privacy_policy;
        } else if (key === "terms") {
            url = appConfig?.link_terms_conditions;
        } else if (key === "phone") {
            url = `tel:${appConfig?.customer_care_phone}`;
        }
        if (url) {
            window.open(url, "_blank");
        }
    };
    return (
        <S.Wrapper>
            <S.Header>
                <S.Heading>Help Center</S.Heading>
            </S.Header>
            <S.Body>
                <S.Container>
                    <S.LabelHead>Contact Us</S.LabelHead>
                    <S.ContainerBody>
                        <S.Box onClick={() => handleLinkClick("phone")}>
                            <img src={Phone} alt="phone" />
                            <S.Label>
                                Customer Service <span>({appConfig?.customer_care_phone})</span>
                            </S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("whatsapp")}>
                            <img src={WhatsApp} alt="whatsapp" />
                            <S.Label>WhatsApp</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("messenger")}>
                            <img src={Messenger} alt="messenger" />
                            <S.Label>Facebook Messenger</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("facebook")}>
                            <img src={Facebook} alt="facebook" />
                            <S.Label>Facebook</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("instagram")}>
                            <img src={Instagram} alt="instagram" />
                            <S.Label>Instagram</S.Label>
                        </S.Box>
                    </S.ContainerBody>
                </S.Container>
                <S.Container>
                    <S.LabelHead>Legal</S.LabelHead>
                    <S.ContainerBody>
                        <S.Box onClick={() => handleLinkClick("about")}>
                            <img src={AboutUs} alt="" />
                            <S.Label>About Us</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("return_refund_policy")}>
                            <img src={ReturnPolicy} alt="" />
                            <S.Label>Return, Refund & Cancellation Policy</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("privacy_policy")}>
                            <img src={PrivacyPolicy} alt="" />
                            <S.Label>Privacy Policy</S.Label>
                        </S.Box>
                        <S.Box onClick={() => handleLinkClick("terms")}>
                            <img src={TermsConditions} alt="" />
                            <S.Label>Terms & Conditions</S.Label>
                        </S.Box>
                    </S.ContainerBody>
                </S.Container>
            </S.Body>
        </S.Wrapper>
    );
};

export default HelpCenter;
