import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 1.81047px 14.4837px rgba(72, 79, 103, 0.24);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 508px;
    height: 462px;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    padding: 12px 16px;

    & img {
        cursor: pointer;
    }
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #1e293b;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 6px 12px;
    background: #f1f1f1;
    border-radius: 0px 0px 6px 6px;
`;
export const ClearBtn = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #d80027;
    cursor: pointer;
    border-bottom: 1px solid #d80027;
`;
export const BlueBtn = styled.div`
    background: #2d68fe;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 8px 10px;
    cursor: pointer;
`;
export const DateChooserSelect = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    // width: 241px;
    max-height: 36px;
    cursor: pointer;
    justify-content: space-between;
    background-color: #eef5ff;
    // position: relative;

    img {
        height: 20px;
        width: 20px;
    }
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    //   top: 39px;
    // right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 13000;
`;
export const Body = styled.div`
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SelectContainers = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
`;

export const SelectContainer = styled.div`
    flex: 1;
    height: 63px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const FormContainerTextarea = styled.textarea`
    width: 100%;
    resize: none;
    height: 80px;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #64748b;
    padding: 10px 12px;
    box-sizing: border-box;
    outline: none;
    font-family: sans-serif;

    &::placeholder {
        color: #64748b;
    }
`;
export const AmountContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 10px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;

    & input {
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
        width: 100%;
    }
`;

export const RepeatChipContainer = styled.div`
    display: flex;
    gap: 16px;
`;
export const RepeatChip = styled.div`
    width: 88px;
    height: 36px;
    background: ${(props) => (props.active ? " #DBEAFE" : "#ffffff")};
    border: 1px solid ${(props) => (props.active ? "#93C5FD" : "#e2e8f0")};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    cursor: pointer;

    input {
        margin: 2px 4px 0 0;
        width: 13px;
        height: 13px;
    }

    label {
        cursor: pointer;
    }
`;
