import React, { useEffect, useState, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationArrowIcon } from "../../../icons";
import { setSearchParams } from "../../../store/product-slice";
import { setSearchParams as setSupplierSearchParams } from "../../../store/supplier-slice";
import { setSearchParams as setCustomerSearchParams } from "../../../store/customer-slice";

import * as S from "./pagination-bar.styles";

const PAGE_LIMIT = 20;

const PaginationBar = ({ tableRef, type }, ref) => {
    const [activePage, setActivePage] = useState(1);
    const productsData = useSelector((state) => state.product);
    const dispatch = useDispatch();
    const suppliersData = useSelector((state) => state.supplier);
    const customersData = useSelector((state) => state.customer);

    useEffect(() => {
        if (type === "supplier") {
            setActivePage(suppliersData.searchParams.page || 1);
        } else if (type === "customer") {
            setActivePage(customersData.searchParams.page || 1);
        } else {
            setActivePage(productsData.searchParams.page || 1);
        }
    }, [productsData.searchParams.page, suppliersData.searchParams.page, customersData.searchParams.page]);

    useImperativeHandle(ref, () => {
        return {
            onNextPageChange: () => handlePageChange("next"),
            onPrevPageChange: () => handlePageChange("prev"),
        };
    });

    const handlePageChange = (key) => {
        if (key === "prev") {
            if (activePage === 1) return;
            if (type === "supplier") {
                dispatch(setSupplierSearchParams({ page: activePage - 1 }));
            } else if (type === "customer") {
                dispatch(setCustomerSearchParams({ page: activePage - 1 }));
            } else {
                dispatch(setSearchParams({ page: activePage - 1 }));
            }
            setActivePage(activePage - 1);
        } else {
            if (type === "supplier") {
                if (suppliersData.suppliers.length < PAGE_LIMIT) return;
                dispatch(setSupplierSearchParams({ page: activePage + 1 }));
                setActivePage(activePage + 1);
            } else if (type === "customer") {
                if (customersData.customers.length < PAGE_LIMIT) return;
                dispatch(setCustomerSearchParams({ page: activePage + 1 }));
                setActivePage(activePage + 1);
            } else {
                if (productsData.products.length < PAGE_LIMIT) return;
                dispatch(setSearchParams({ page: activePage + 1 }));
                setActivePage(activePage + 1);
            }
        }
        tableRef.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <S.Container>
            <S.ArrowContainer disable={activePage === 1} prev onClick={() => handlePageChange("prev")}>
                <img src={PaginationArrowIcon} alt="prev" />
                Previous (F2)
            </S.ArrowContainer>
            <S.Page active>{activePage}</S.Page>
            <S.ArrowContainer
                disable={
                    type === "supplier"
                        ? suppliersData.suppliers?.length < PAGE_LIMIT
                        : type === "customer"
                        ? customersData.customers?.length < PAGE_LIMIT
                        : productsData.products?.length < PAGE_LIMIT
                }
                next
                onClick={() => handlePageChange("next")}
            >
                Next (F3)
                <img src={PaginationArrowIcon} alt="next" />
            </S.ArrowContainer>
        </S.Container>
    );
};

export default React.forwardRef(PaginationBar);
