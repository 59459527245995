import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 24px 40px 27px 40px;
    width: 520px;
`;
export const BtnContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;
export const ConfirmBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background: #f91919;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
`;
export const CancelBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #707091;
    cursor: pointer;
`;
export const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #232325;
`;
