import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as S from "./style";
import Transaction from "./Transaction";
import { getPayment } from "../../../../utils/object-parser";

const TransactionTable = ({ type, displayData, setDisplayData, clear, handleTotalPay, sales, purchases }) => {
    // const { sales } = useSelector((state) => state.sale);
    // const { purchases } = useSelector((state) => state.purchase);

    useEffect(() => {
        if (type === "customer") {
            if (clear) {
                let list = sales?.map((item) => {
                    let payment = getPayment(item?.payment, item?.payment);
                    return payment?.amount_due !== 0 ? { ...item, isChecked: true } : item;
                });
                setDisplayData(list);
                handleTotalPay(list);
            } else {
                setDisplayData(sales);
            }
        }
        if (type === "supplier") {
            if (clear) {
                let list = purchases?.map((item) => {
                    let payment = getPayment(item?.payment, item?.payment);
                    return !payment?.is_paid ? { ...item, isChecked: true } : item;
                });
                setDisplayData(list);
                handleTotalPay(list);
            } else {
                setDisplayData(purchases);
            }
        }
    }, [sales, purchases]);

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        if (name === "allSelect") {
            let tempData = displayData.map((item) => {
                return { ...item, isChecked: checked };
            });
            setDisplayData(tempData);
            handleTotalPay(tempData);
        } else {
            let tempData = displayData.map((item) => (item.id === +name ? { ...item, isChecked: checked } : item));
            setDisplayData(tempData);
            handleTotalPay(tempData);
        }
    };
    return (
        <S.Table>
            <S.TableHead data={displayData?.length}>
                <S.Th flex="0.3">
                    <input
                        type="checkbox"
                        name="allSelect"
                        checked={displayData?.length && !displayData?.some((item) => item?.isChecked !== true)}
                        onChange={handleCheck}
                    />
                </S.Th>
                <S.Th align="left" flex="0.8">
                    Date
                </S.Th>
                <S.Th flex="1.2">{type === "customer" ? "Sale" : "Purchase"}/Return ID</S.Th>
                <S.Th>Total Amount</S.Th>
                <S.Th>Amount Due</S.Th>
                <S.Th>Status</S.Th>
                <S.Th></S.Th>
            </S.TableHead>
            <S.TableBody>
                {displayData?.length ? displayData?.map((item) => <Transaction item={item} handleCheck={handleCheck} type={type} />) : <></>}
            </S.TableBody>
        </S.Table>
    );
};

export default TransactionTable;
