import styled from "@emotion/styled/macro";
import { INVENTORY_SELECT } from "../../../constants/appConstants";

export const SelectContainer = styled.div`
    // padding: 14px 16px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 6px;
    height: 36px;
    position: relative;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        margin-right: ${(props) => props.scText && "6px"};
    }
`;
export const SelectText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
`;

export const MenuContainer = styled.div`
    position: absolute;
    top: 48px;
    // width: ${(props) => (props.checkboxMenu ? "278px" : "268px")};
    width: ${(props) => (props.type === INVENTORY_SELECT.STOCK_SELECT ? "278px" : props.type === INVENTORY_SELECT.SORT_SELECT ? "228px" : "268px")};
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 2px 2px;
    left: 0;
    z-index: 1;

    // padding: ${(props) => (props.checkboxMenu ? "22px 0 0 16px" : "unset")};
`;

export const CustomStockRangeSubmit = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    padding: 4px 9px;
    width: fit-content;
    margin: 12px 0 0 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 12px;
`;

export const SearchContainer = styled.div`
    margin: 12px;
    display: flex;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 12px 8px;
    align-items: center;
    justify-content: space-between;

    img {
        width: 20px;
        height: 20px;
    }
`;

export const SearchInput = styled.input`
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    flex: 1;

    color: #475569;

    &::placeholder {
        color: #cbd5e1;
    }
`;

export const AddNew = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2d68fe;
    margin: 0 12px 12px;
    display: flex;
    align-items: center;

    img {
        width: 20px;
        height: 20px;
        margin: 0 4px 0 0;
    }
`;

export const AddNewTypeInputContainer = styled.div`
    margin: 0 12px 12px;
    display: flex;
    align-items: center;
    img {
        margin-left: ${(props) => (props.firstItem ? "unset" : "14px")};
    }
`;

export const AddNewInput = styled.input`
    padding: 7px 12px;
    border: 1.5px solid #2d68fe;
    border-radius: 4px;
    max-width: 190px;

    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #475569;
    &::placeholder {
        color: #a6aebc;
    }
`;

export const SearchList = styled.div`
    max-height: 287px;
    overflow: scroll;
`;

export const SearchData = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.24px;
    color: #1e293b;
    padding: 12px;
    background: ${(props) => (props.active ? "#f1f5f9" : "#fff")};
    &:hover {
        background: #f1f5f9;
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: ${(props) => (props.active ? "#f1f5f9" : "#fff")};
    &:hover {
        background: #f1f5f9;
    }

    input[type="checkbox"] {
        width: 12px;
        height: 12px;
        margin: 0 8px 0 0;
    }
`;
export const CheckboxTop = styled.div`
    display: flex;
    align-items: center;
`;
export const CheckboxText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;
export const Range = styled.div`
    margin: 14px 8px 0 18px;
    display: flex;
    gap: 16px;
`;
export const RangeInput = styled.input`
    flex: 1;
    height: 32px;
    width: 92px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    outline: none;
    color: #475569;

    &::placeholder {
        color: #cbd5e1;
    }
`;
