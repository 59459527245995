import styled from "@emotion/styled/macro";
import { COLOR } from "../../../constants/appConstants";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: ${(props) => (props.isFull ? "100%" : "calc(100% - 304px)")};
    height: 100%;
    transition: width 0.2s ease-in-out;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    height: ${(props) => (props.hasBottom ? "calc(100% - 68px)" : "100%")};
    background-color: #fff;
`;

export const TBody = styled.div`
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    overflow-y: auto;
`;
export const TableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 12px 8px 12px;
    background-color: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    border-bottom: 1px solid #e6ebf6;
    margin: 2px;
    border-radius: 4px;

    & > div {
        // border: 1px solid red;
    }
`;
export const TableHeadRow = styled.div`
    font-size: 12px;
    // height: 59px;
    text-align: left;
    color: #475569;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 12px 8px 12px;
    margin: 0 2px;
`;

export const TableHead = styled.div`
    box-sizing: border-box;
    text-align: ${(props) => (props.$firstItem || props.$serialNo ? "left" : "center")};
    flex: ${(props) => (props.flexValue ? props.flexValue : 1)};
    font-weight: 500;
    line-height: 22px;
    // border: 1px solid red;
`;

export const RowData = styled.div`
    flex: ${(props) => (props.flexValue ? props.flexValue : 1)};
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: ${(props) => (props.$left ? "flex-start" : "center")};
    ${(props) => props.column && "flex-direction: column"};

    & > div {
        ${(props) => !props.$full && "width:80%"};
    }
    & > img {
        cursor: pointer;
    }
    // border: 1px solid red;
`;

export const TableDataDetails = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    // img {
    //     height: 50px;
    //     width: 50px;
    //     margin-right: 8px;
    //     border: 1px solid #cbd5e1;
    //     border-radius: 4px;
    //     background: #fff;
    // }
`;

export const ItemDetailsBrand = styled.div`
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    color: #000;
    // margin-bottom: 2px;
    // text-transform: uppercase;
`;

export const ItemDetailsName = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: ${(props) => (props.wide ? "110px" : "140px")};
    padding-bottom: 2px;
`;

export const ItemDetailsQuantity = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #94a3b8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
`;

export const TableDataPrice = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #334155;

    div {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #94a3b8;
        text-decoration: line-through;
        margin-top: 4px;
    }
`;

export const SalePrice = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #334155;

    & > div {
        display: flex;
        align-items: center;

        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 10px 6px;

        & > input {
            border: none;
            outline: none;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: #334155;
            width: 100%;
            text-align: center;
        }
    }
`;

export const TableDataStock = styled.div`
    // width: 111px;
    flex: 0.5;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
`;

export const TableAltUnit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TableDataQuantity = styled.div`
    text-align: center;
    height: 40px;
    margin-top: 6px;
    & > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000;
        border-radius: 3px;
        width: 52px;
        height: 34px;
        margin: auto;
        background-color: #fff;
    }

    & input {
        width: 100%;
        border: none;
        padding: 3px;
        text-align: center;

        :focus {
            outline: none;
            border: none;
        }
    }
`;
export const WarningText = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #94a3b8;
    margin-top: 2px;
    text-align: center;
`;

export const UnitHintText = styled(WarningText)`
    margin-top: -3px;
    margin-bottom: -10px;
`;

export const TableDataSubtotal = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000;
`;

export const TableDataDiscount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;
export const InputDiv = styled.div`
    width: 46px;
    height: 38px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    & > input {
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #334155;
        border: none;
        outline: none;
    }
`;
export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: 52px;
    height: 38px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 4px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 3px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 4px;
`;
export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
export const TableDataDelete = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;

export const StrikeTextPrice = styled.span`
    color: #0f172a;
    text-decoration: line-through;
`;

export const StrikedPrice = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #94a3b8;
`;
export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-left: 1px solid #000;
    background-color: #fff;
    border-radius: 0 3px 3px 0;
    height: 100%;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #334155;
    height: 100%;
`;
export const TableDataSn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & div {
        text-align: center;
        border: 1px solid #000000;
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        color: #000000;
        width: 24px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const CustomBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 10px;
    color: ${(props) => (props.fill ? "#fff" : props.color ? props.color : "#000")};
    border: 1px solid ${(props) => (props.color ? props.color : "#000")};
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    background: ${(props) => (props.fill ? props.color : "#fff")};
    margin-right: ${(props) => props.mr && props.mr};
    cursor: pointer;

    & > img {
        width: 20px;
        height: 20px;
    }
`;

export const BottomBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px;
`;

export const MoreInfoWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 790px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 16px;
    box-sizing: border-box;
    padding: 7px 16px;
    border: 1px solid #cbd5e1;
`;

export const OptionHeader = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;
export const OptionDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const OptionInputContainer = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    // padding: 8px 9px;
    // cursor: pointer;
    width: 93px;
    // width: 30px;
    // width: auto;
`;
export const Input = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    resize: both;
    text-align: ${(props) => props.align};
`;

export const CustomProductContainer = styled.div`
    width: 80%;
`;
export const MoreIcon = styled.div`
    margin: auto;
    position: relative;
    cursor: pointer;
    display: flex;
    alignitems: center;
    justify-content: center;
`;
export const Dot = styled.div`
    position: absolute;
    left: 75%;
    top: 80%;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${COLOR.RED};
`;
