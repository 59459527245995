import React, { useEffect, useState } from "react";
import * as S from "./style";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedSale } from "../../../../store/sale-slice";
import { setSelectedCustomer } from "../../../../store/customer-slice";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { setSelectedPurchase } from "../../../../store/purchase-slice";
import { formatDate } from "../../../../utils/format-date";
import { toDecimalPlace } from "../../../../utils/global-fn";
import { getPayment, getPurchaseNetPrice, getSaleNetAmount } from "../../../../utils/object-parser";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#455A64",
        color: "#FFFFFF",
        // maxWidth: 220,
        border: "1px solid #455A64",
        borderRadius: "4px",
        // width: "184px",
        // height: "99px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        marginBottom: "40px",
        fontWeight: "400",
        fontSize: "8px",
        lineHeight: "16px",
        textAlign: "center",
        padding: "2px 8px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#455A64",
    },
}));

const Transaction = ({ item, handleCheck, type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [payment, setPayment] = useState(null);
    const [netAmount, setNetAmount] = useState(0);
    const [netPrice, setNetPrice] = useState(0);

    useEffect(() => {
        if (item) {
            let p = getPayment(item.payment, item.payment);
            setPayment(p);

            if (type === "customer") {
                let amt = getSaleNetAmount(item);
                setNetAmount(amt);
            } else {
                let price = getPurchaseNetPrice(item);
                setNetPrice(price);
            }
        }
    }, [item]);

    function handleViewDetail(item) {
        if (type === "customer") {
            dispatch(setSelectedSale(item));
            dispatch(setSelectedCustomer(item.patient));
            navigate("/dashboard/view-sale");
        }
        if (type === "supplier") {
            dispatch(setSelectedPurchase(item));
            navigate("/dashboard/view-purchase");
        }
    }
    return (
        <S.TableRow key={item.id}>
            <S.Rowdata flex="0.3">
                <input type="checkbox" name={item.id} checked={item?.isChecked || false} onChange={(e) => handleCheck(e)} />
            </S.Rowdata>
            <S.Rowdata align="left" flex="0.8">
                {formatDate(item.created_at)}
            </S.Rowdata>
            <HtmlTooltip
                arrow
                placement="top"
                title={
                    <React.Fragment>
                        <div>{item.type}</div>
                    </React.Fragment>
                }
            >
                <S.Rowdata flex="1.2">#{item.invoice_id}</S.Rowdata>
            </HtmlTooltip>
            <S.Rowdata>₱{type === "customer" ? netAmount : netPrice}</S.Rowdata>
            <S.Rowdata>
                {item.type === "RETURN" && payment?.amount_due !== 0 && "-"}₱{toDecimalPlace(Math.abs(payment?.amount_due))}
            </S.Rowdata>
            <HtmlTooltip
                arrow
                placement="top"
                title={
                    <React.Fragment>
                        <div>{payment?.payment_modes?.map((m) => m.payment_mode).join(", ")}</div>
                    </React.Fragment>
                }
            >
                <S.Rowdata>{payment?.is_paid === true ? "Completed" : "Pending"}</S.Rowdata>
            </HtmlTooltip>
            <S.Rowdata detail align="right" onClick={() => handleViewDetail(item)}>
                View Detail
            </S.Rowdata>
        </S.TableRow>
    );
};

export default Transaction;
