import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import * as S from "./styles";
import AddNewCustomerModal from "./../../components/add-new-customer-modal/add-new-customer-modal";
import CustomerFilter from "./../../components/CustomerFilter/CustomerFilter";
import CustomerTable from "../../components/CustomerTable/CustomerTable";
import { deleteCustomer } from "../../api/local/customerApi";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCustomers,
  removeAllSearchParams,
  setSearchParams,
  removeSearchParams,
} from "../../store/customer-slice";
import { removeAllSearchSaleParams, removeSales } from "../../store/sale-slice";
import { useRef } from "react";
import PaginationBar from "../../components/inventory/pagination-bar/pagination-bar";
import SelectedChips from "../../components/selected-chips/selected-chips";
import CustomButton from "../../components/custom-button/CustomButton";
import { COLOR } from "../../constants/appConstants";

const initalDisplayData = {
  sort: {},
  pending_payment: {},
};
const Customers = (props, ref) => {
  const [openNewCustModal, setOpenNewCustModal] = useState({
    id: null,
    state: false,
    phoneNumber: null,
    clear: false,
    txn: false,
  });

  const [displayData, setDisplayData] = useState(initalDisplayData);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { searchParams } = useSelector((state) => state.customer);
  const inputRef = useRef();
  const pageRef = useRef();
  const tableRef = useRef();
  const [check, setCheck] = useState(false);

  useEffect(() => {
    dispatch(setSearchParams({ show_transactions: true }));
    return () => {
      dispatch(removeAllSearchSaleParams());
      dispatch(removeAllSearchParams());
    };
  }, []);
  useEffect(() => {
    // handleGetCustomersData();
    if (!openNewCustModal.state) {
      dispatch(removeSales());
    }
  }, [searchParams]);

  useImperativeHandle(ref, () => {
    return {
      searchName: () => inputRef.current?.focus(),
      addCustomer: () =>
        setOpenNewCustModal({
          phoneNumber: null,
          state: true,
          clear: false,
          txn: false,
        }),
      clearFilter: () => handleClearFilter(),
    };
  });
  const handleClearFilter = () => {
    handleSearchChange("");
    dispatch(removeSearchParams("pending_payment"));
    dispatch(removeSearchParams("sort_by"));
    dispatch(removeSearchParams("is_reversed"));
    setDisplayData(initalDisplayData);
  };

  function handleGetCustomersData() {
    dispatch(fetchAllCustomers(searchParams));
    // console.log(res.data);
  }
  function delayedFunction(val) {
    dispatch(setSearchParams({ search: val }));
  }

  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

  function handleSearchCustomers(value) {
    delayedQuery(value);
  }

  function handleSearchChange(value) {
    handleSearchCustomers(value);
    setSearchText(value);
  }

  function handleSelectChange(key, item) {
    if (key === "sort") {
      setDisplayData({ ...displayData, sort: item });
      dispatch(removeSearchParams("is_reversed"));
      const payload = { sort_by: item.value };
      if (+item.id % 2 === 0) payload.is_reversed = true;
      dispatch(setSearchParams(payload));
    } else if (key === "pending_payment") {
      setDisplayData({ ...displayData, pending_payment: item });
      dispatch(setSearchParams({ pending_payment: item.value }));
    }
  }

  function handleDeleteCustomer(id) {
    deleteCustomer(id);
    handleGetCustomersData();
  }

  const handleCheckBoxChange = (checked) => {
    dispatch(setSearchParams({ pending_payment: checked }));
    setCheck(checked);
  };
  return (
    <S.CustomersContainer>
      <S.HeadingBox>
        <AddNewCustomerModal
          id={openNewCustModal.id}
          open={openNewCustModal.state}
          onClose={() =>
            setOpenNewCustModal({
              id: null,
              state: false,
              phoneNumber: null,
              clear: false,
              txn: false,
            })
          }
          phoneNumber={openNewCustModal.phoneNumber}
          clear={openNewCustModal.clear}
          txn={openNewCustModal.txn}
        />
        <S.CustomerHeading>Customers</S.CustomerHeading>
        <CustomButton
          label="Add Customer"
          color={COLOR.BLUE}
          fill={true}
          size="lg"
          onClick={() =>
            setOpenNewCustModal({
              phoneNumber: null,
              state: true,
              clear: false,
              txn: false,
            })
          }
        />
      </S.HeadingBox>
      <S.CustomerBody>
        <CustomerFilter
          handleSelectChange={handleSelectChange}
          handleSearchChange={handleSearchChange}
          inputRef={inputRef}
          displayData={displayData}
          searchText={searchText}
          setSearchText={setSearchText}
          handleCheckBoxChange={handleCheckBoxChange}
          check={check}
        />
        <S.ChipContainer>
          <SelectedChips
            displayData={displayData}
            setDisplayData={setDisplayData}
            clearSearch={() => handleSearchChange("")}
          />
        </S.ChipContainer>
        <CustomerTable
          setOpenNewCustModal={setOpenNewCustModal}
          handleDeleteCustomer={handleDeleteCustomer}
          tableRef={tableRef}
        />
        <S.PageContainer>
          <PaginationBar type="customer" ref={pageRef} tableRef={tableRef} />
        </S.PageContainer>
      </S.CustomerBody>
    </S.CustomersContainer>
  );
};

export default React.forwardRef(Customers);
