import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
`;
export const LeftContainer = styled.div`
    width: 43%;
    // border: 2px solid red;
`;
export const RightContainer = styled.div`
    width: 57%;
    background-color: #fff;
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
`;
export const LoginContainer = styled.div`
    background: #ffffff;
    border-radius: 12px;
    width: 419px;
`;
export const InputFIelds = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 6px 8px;
    width: 419px;
    height: 48px;
    & img {
        cursor: pointer;
    }

    & input {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #334155;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &::placeholder {
            color: #94a3b8;
        }
    }
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin: 12px 0 20px;
`;
export const ConfirmBtn = styled.button`
    width: 419px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 14px 188px;
    gap: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background: #253238;
    border-radius: 4px;
    margin: 18px 0;
    cursor: pointer;
    border: none;
`;
export const ForgotPassword = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #253238;
    margin: 12px 0 20px;
    cursor: pointer;
`;
export const LoginHead = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
    margin-bottom: 32px;
`;
export const LogoContainer = styled.div`
    margin: 82px 0 40px;
`;
export const BottomText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &>div: nth-of-type(1) {
        color: #64748b;
    }
    &>div: nth-of-type(2) {
        color: #253238;
        cursor: pointer;
    }
`;
export const CreateAccount = styled.div`
    cursor: pointer;
`;

export const PhoneInputField = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    & > div:nth-of-type(1) {
        width: 60px;
    }
`;

export const BackBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #263238;
    border-radius: 4px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    margin-bottom: 48px;
`;
