import styled from "@emotion/styled/macro";

export const EmployeesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 34px 25px 30px;
`;

export const HeadingBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const EmpHeading = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #1e293b;
`;

export const AddEmpBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #3b82f6;
    border-radius: 4px;
    height: 48px;
    width: 146px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
`;

export const Body = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const FilterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cbd5e1;
    // margin-bottom: 12px;
    padding: 15px 16px;
`;

export const LeftFilter = styled.div`
    display: flex;
    gap: 8px;
`;
export const RightFilter = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #334155;
    // margin-right: 30px;
`;

export const InputDiv = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    width: 350px;
    align-items: center;
    height: 38px;

    img {
        height: 24px;
        width: 24px;
    }
`;
export const Input = styled.input`
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #475569;
    margin-left: 10px;

    &::placeholder {
        color: #cbd5e1;
    }
`;

export const ChipWrapper = styled.div`
    padding: 0 25px;
`;
