import React from "react";
import * as S from "./style";
import moment from "moment";
import { BlueArrowRight, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";
import { PURCHASE_TYPE } from "../../../constants/appConstants";

const Draft = ({ item, type, handleDraftClick, handleDraftDelete, activeRow, scrollRef, idx }) => {
    return (
        <S.SaleRow key={item.id} onClick={() => handleDraftClick(item)} ref={idx === activeRow ? scrollRef : null} active={idx === activeRow}>
            <S.TopFlexContainer>
                <div>
                    <S.SaleTopbar>
                        <S.SaleId>#{item.id}</S.SaleId>
                    </S.SaleTopbar>
                    <S.FlexContainer>
                        <S.CustomerName>{item.supplier?.name}</S.CustomerName>
                    </S.FlexContainer>
                    <S.SaleQuantity>{item.items.length} items</S.SaleQuantity>
                    <S.FlexContainer>
                        {/* {item.total_purchase_price !== item.net_price && (
                                                <S.Price>₱{item.total_purchase_price}</S.Price>
                                            )} */}
                        <S.SalePrice>₱{item.net_price.toFixed(2)}</S.SalePrice>
                    </S.FlexContainer>
                </div>
                <S.DateContainer>
                    <S.SaleDate>{moment(item.created_at).format("ll")}</S.SaleDate>
                    <S.Time>{moment(item.created_at).format("LT")}</S.Time>
                </S.DateContainer>
            </S.TopFlexContainer>
            <S.BottomContainer>
                <S.ImageContainer>
                    {item.items.map((p) => (
                        <S.ImageDiv>
                            <img
                                src={p.gallery?.[0] || ProductPlaceholderIcon}
                                alt="purchase product avatar"
                                onError={(e) => {
                                    e.target.src = WifiDisconnectedIcon;
                                }}
                            />
                        </S.ImageDiv>
                    ))}
                </S.ImageContainer>
                <S.BtnContainer>
                    <S.DeleteBtn onClick={(e) => handleDraftDelete(e, item)}>Delete</S.DeleteBtn>
                    <S.ViewOrder>
                        <div>
                            View{" "}
                            {type === PURCHASE_TYPE.RETURN
                                ? "Returns"
                                : type === PURCHASE_TYPE.DAMAGE_ENTRY
                                ? "Damages"
                                : type === PURCHASE_TYPE.PURCHASE_PROFORMA
                                ? "Purchase Proforma"
                                : type === PURCHASE_TYPE.PURCHASE_ORDER
                                ? "Purchase Order"
                                : "Purchases"}
                        </div>
                        <img src={BlueArrowRight} alt="blue arrow" />
                    </S.ViewOrder>
                </S.BtnContainer>
            </S.BottomContainer>
        </S.SaleRow>
    );
};

export default Draft;
