import React from "react";
import { Snackbar } from "@mui/material";
import * as S from "./snackbar.style";
import { DarkChevronRight, NewOrderIcon } from "../../icons";

const OrderSnackbar = ({ onClose, data, onClick }) => {
    const handleCloseSnackbar = () => {
        onClose({ show: false, title: "", subtitle: "" });
    };

    const onClickSnackbar = () => {
        handleCloseSnackbar();
        onClick();
    };
    return (
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={data.show} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <S.Container onClick={onClickSnackbar}>
                <img src={NewOrderIcon} alt="new order icon" />
                <S.Message>
                    <S.Title>{data?.title}</S.Title>
                    <S.Subtitle>{data?.subtitle}</S.Subtitle>
                </S.Message>
                <img src={DarkChevronRight} alt="chevron right" />
            </S.Container>
        </Snackbar>
    );
};

export default OrderSnackbar;
