import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedDue: "",
};

const dueSlice = createSlice({
    name: "due",
    initialState,
    reducers: {
        setSelectedChipDue: (state, action) => {
            state.selectedDue = action.payload;
        },
        removeSelectedChipDue: (state) => {
            state.selectedDue = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    }
});

export default dueSlice.reducer;
export const { setSelectedChipDue, removeSelectedChipDue } = dueSlice.actions;
