import React from "react";
import * as S from "../EmployeeLogin.style";

const VerifyOtp = ({ loginData, handleInputChange, onSubmit }) => {
    const onVeifyOtp = () => {
        onSubmit();
    };
    return (
        <S.LoginContainer>
            <S.ContainerHead>Forget Password</S.ContainerHead>
            <div>
                <S.Label>Enter OTP</S.Label>
                <S.InputFIelds>
                    <input type="text" name="otp" onChange={(e) => handleInputChange(e)} value={loginData.otp} autoFocus />
                </S.InputFIelds>
            </div>
            <S.BlueBtn onClick={onVeifyOtp}>Confirm</S.BlueBtn>
        </S.LoginContainer>
    );
};

export default VerifyOtp;
