import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    padding: 24px;
    width: 400px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e8f0;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #334155;
`;

export const Body = styled.div`
    margin-top: 16px;
`;

export const BodyText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #475569;
    margin-bottom: 36px;
`;
export const BtnContainer = styled.div`
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
`;
export const RightBtnDiv = styled.div`
    display: flex;
`;
export const Cancel = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #f91919;
    padding: 13px 32px;
    cursor: pointer;
`;

export const Submit = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    padding: 12px 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
