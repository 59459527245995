import React, { useEffect, useRef } from "react";
import * as S from "../EmployeeLogin.style";
import { useState } from "react";
import { PasswordEyeIcon, PasswordNoEyeIcon } from "../../../icons";

const NewPassword = ({ loginData, handleInputChange, onSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current?.focus();
        }
    }, []);

    const onSavePassword = () => {
        onSubmit();
    };
    return (
        <S.LoginContainer>
            <S.ContainerHead>Create New Password</S.ContainerHead>
            <div>
                <S.Label>Enter New Password</S.Label>
                <S.InputFIelds>
                    <input
                        type="password"
                        name="new_password"
                        onChange={(e) => handleInputChange(e)}
                        value={loginData.new_password}
                        autoFocus
                        ref={inputElement}
                    />
                </S.InputFIelds>
            </div>
            <div>
                <S.Label>Confirn New Password</S.Label>
                <S.InputFIelds>
                    <input
                        type={showPassword ? "text" : "password"}
                        name="confirm_new_password"
                        onChange={(e) => handleInputChange(e)}
                        value={loginData.confirm_new_password}
                    />
                    <img src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon} alt="eye" onClick={() => setShowPassword(!showPassword)} />
                </S.InputFIelds>
            </div>
            <S.BlueBtn onClick={onSavePassword}>Save</S.BlueBtn>
        </S.LoginContainer>
    );
};

export default NewPassword;
