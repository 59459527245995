import React, { useState, useRef, useContext } from "react";
import * as S from "./style";
import { Modal } from "@mui/material";
import { CloseModalIcon } from "../../../icons";
import PurchaseSummaryModal from "../../purchase/purchase-summary-modal/purchase-summary-modal";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import moment from "moment";
import { convertTax, getPurchasePayloadProducts } from "../../../utils/global-fn";
import { DiscountOption, INVOICE_TYPE, PURCHASE_TYPE } from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";
import { addPurchase, updatePurchase } from "../../../api/local/purchase-api";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProformaCheckoutModal = ({ open, onClose, handleClearCart }) => {
    const {
        PP_products,
        PP_selectedSupplier,
        PP_type,
        PP_billNo,
        PP_createdDate,
        PP_deliveryExpense,
        PP_deliveryDate,
        selectedPP,
        PP_globalDiscount,
        PP_selectDiscount,
    } = useSelector((state) => state.purchaseProforma);
    const [openPurchaseSummaryModal, setOpenPurchaseSummaryModal] = useState(false);
    const [notes, _setNotes] = useState({ remarks: "", terms: "" });
    const notesRef = useRef(notes);
    const setNotes = (notes) => {
        notesRef.current = notes;
        _setNotes(notes);
    };

    const [selectedTemplate, _setSelectedTemplate] = useState(null);
    const selectedTemplateRef = useRef(selectedTemplate);
    const setSelectedTemplate = (data) => {
        selectedTemplateRef.current = data;
        _setSelectedTemplate(data);
    };

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        setNotes({ ...notes, [name]: value });
    };
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        if (selectedPP) {
            setNotes({
                remarks: selectedPP?.remarks ? selectedPP?.remarks : "",
                terms: selectedPP?.terms ? selectedPP?.terms : "",
            });
        }
    }, [open]);

    const closeCheckoutModal = () => {
        setNotes({ remarks: "", terms: "" });
        onClose();
    };

    const handleAddPurchaseProforma = async (invoiceRequired) => {
        if (!PP_products.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        if (!PP_selectedSupplier) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a supplier",
                type: "error",
            });
            return;
        }

        const payload = {
            type: PP_type,
            status: "PUBLISH",
            products: getPurchasePayloadProducts(PP_products),
        };

        if (PP_selectedSupplier) payload.supplier_id = PP_selectedSupplier.id;
        if (PP_createdDate) payload.created_at = PP_createdDate.getTime();
        if (PP_billNo) payload.bill_no = PP_billNo;
        if (PP_deliveryExpense) payload.delivery_price = +PP_deliveryExpense;
        if (PP_deliveryDate) payload.delivery_date = PP_deliveryDate.getTime();
        if (PP_globalDiscount) {
            let disc = {
                type: PP_selectDiscount.type,
                value: PP_globalDiscount,
            };
            payload.cart_discount = disc;
        }
        let { remarks, terms } = notesRef.current;
        if (remarks) payload.remarks = remarks;
        if (terms) payload.terms = terms;

        if (selectedTemplateRef.current) {
            payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
        }

        try {
            let res;
            if (selectedPP) {
                res = await updatePurchase(selectedPP.id, payload);
            } else {
                res = await addPurchase(payload);
            }
            if (res.status === 200) {
                navigate("/dashboard/purchases");
                handleClearCart();
                closeCheckoutModal();

                const proforma = res.data?.entity;
                //Print the invoice
                if (invoiceRequired) {
                    onInvoiceRequired(proforma, payload.invoice_template_id);
                }

                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Purchase completed",
                    subtitle: `Your ${
                        PP_type === PURCHASE_TYPE.PURCHASE_PROFORMA
                            ? "purchase proforma"
                            : PP_type === PURCHASE_TYPE.PURCHASE_ORDER
                            ? "purchase order"
                            : "purchase"
                    } has been completed successfully.`,
                });
            }
        } catch (error) {
            captureException(error);
            handleSnackbarDetails({
                show: true,
                title: error.message,
                type: "error",
            });
        }
    };

    const onInvoiceRequired = async (purchase, template_id) => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            try {
                let res = await window.electronAPI.printInvoice({
                    template_id: template_id,
                    type: INVOICE_TYPE.PURCHASE,
                    data: purchase,
                });
                if (res?.code !== "success") {
                    handleSnackbarDetails({
                        show: true,
                        title: res?.message,
                        type: "error",
                    });
                }
            } catch (err) {
                captureException(err);
            }
        }
    };

    const handleComplete = () => {
        setOpenPurchaseSummaryModal(true);
    };

    return (
        <>
            <PurchaseSummaryModal
                open={openPurchaseSummaryModal}
                onClose={() => setOpenPurchaseSummaryModal(false)}
                handleAddPurchaseProforma={handleAddPurchaseProforma}
                type={"purchase-proforma"}
                selectedPaymentOption={""}
                selectedAmount={""}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
            />
            <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <S.Wrapper>
                    <S.Topbar>
                        <div>Checkout</div>
                        <img src={CloseModalIcon} alt="close" onClick={onClose} />
                    </S.Topbar>
                    <S.Body>
                        <S.TextContainer>
                            <S.Label>Notes</S.Label>
                            <S.TextArea value={notes.remarks} name="remarks" maxLength={200} onChange={handleInputChange}></S.TextArea>
                        </S.TextContainer>
                        <S.TextContainer>
                            <S.Label>Terms & Conditions</S.Label>
                            <S.TextArea value={notes.terms} name="terms" maxLength={200} onChange={handleInputChange}></S.TextArea>
                        </S.TextContainer>
                        <S.CompleteBtn onClick={handleComplete}>Complete</S.CompleteBtn>
                    </S.Body>
                </S.Wrapper>
            </Modal>
        </>
    );
};

export default ProformaCheckoutModal;
