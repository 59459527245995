import styled from "@emotion/styled/macro";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 32px 16px 16px;
    // border: 2px solid red;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
`;

export const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const ArrowBox = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 6px;
`;

export const HeaderBtn = styled.div`
    display: flex;
    background: ${(props) => (props.active ? "#DBEAFE" : "none")};
    ${(props) => props.active && "border-radius: 2px"};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    padding: 10px 8px;
    align-items: center;
    cursor: pointer;

    .header-btn-count {
        background: ${(props) => (props.active ? "#3B82F6" : "#E2E8F0")};
        ${(props) => props.active && "border-radius: 2px"};
        margin-left: 6px;
        padding: 0 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => (props.active ? "#FFF" : "#334155")};
        display: flex;
        align-items: center;
        max-height: 16px;
    }
`;

export const Body = styled.div`
    background: #ffffff;
    border: 0.704375px solid #e2e8f0;
    border-radius: 2.8175px;
    // height: 610px;
    // max-height: calc(100vh - 206px);
    height: calc(100vh - 158px);
    padding: 0 24px;
    display: flex;
    flex-direction: column;
`;

export const BtnContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    margin: 0 -24px;
    max-height: 55px;
    height: 55px;
    border-bottom: 1px solid #e2e8f0;
`;

export const Tabs = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 32px;
`;

export const Tab = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    border-bottom: 2.5px solid ${(props) => (props.active ? "#3B82F6" : "#fff")};
    padding-bottom: 6px;
    cursor: pointer;

    .tab-btn-count {
        background: ${(props) => (props.active ? "#3B82F6" : "#E2E8F0")};
        ${(props) => props.active && "border-radius: 2px"};
        margin-left: 6px;
        padding: 0 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => (props.active ? "#FFF" : "#334155")};
        display: flex;
        align-items: center;
        max-height: 16px;
    }
`;

export const SortSelect = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FiltersContainer = styled.div`
    padding-top: 12px;
    display: flex;
`;

export const InputContainer = styled.div`
    width: 291px;
`;

export const Filters = styled.div`
    display: flex;
    gap: 12px;
    margin-left: 12px;
    align-items: center;
    position: relative;
`;

export const DatePickerContainer = styled.div`
    position: absolute;
    top: 39px;
    right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 100;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 9px 8px 9px 8px;
    display: flex;
    align-items: center;
    max-height: 38px;
    cursor: pointer;
    gap: 8px;
    img {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
`;
export const DraftBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2d68fe;
    border: 1px solid #2d68fe;
    cursor: pointer;
    background: #fff;
`;
export const TodayDataContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const WhiteBox = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    padding: 6px 8px;
    color: #253238;
`;
export const BlueBox = styled.div`
    background: #ccdfff;
    border-radius: 4px;
    padding: 6px;
`;
export const Today = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const RangeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 6px 8px 6px 8px;
`;
export const TableTopRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const DownloadContainer = styled.div`
    width: 36px;
    height: 36px;
    cursor: pointer;

    & > img {
        width: 100%;
        height: 100%;
    }
`;
