import React from "react";
import * as S from "../style";
import CustomSelect from "../../../product-drawer/custom-select/custom-select";
import ToggleSwitch from "../../../switch/Switch";
import { currencySymbol, thermalDateFormat, discountIn, pageSize } from "../../../../constants/printerConfigConstants";

const Thermal = ({ printers, config, setConfig }) => {
    const handleSwitchChange = (event, type) => {
        if (type === "thank_you_note") {
            let pl = { ...config.thank_you_note, show: event.target.checked };
            setConfig({ ...config, thank_you_note: pl });
        } else if (type === "notes") {
            let pl = { ...config.notes, show: event.target.checked };
            setConfig({ ...config, notes: pl });
        } else {
            setConfig({ ...config, [type]: event.target.checked });
        }
    };

    const handleSelectChange = (key, item) => {
        if (key === "printer") {
            setConfig({ ...config, printer_name: item.name });
        } else if (key === "page-size") {
            setConfig({ ...config, page_size: item.value });
        } else if (key === "date-format") {
            setConfig({ ...config, date_format: item.value });
        } else if (key === "currency-symbol") {
            setConfig({ ...config, currency_symbol: item.value });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "value") {
            let pl = { ...config.thank_you_note, value: value };
            setConfig({ ...config, thank_you_note: pl });
        } else if (name === "title") {
            let pl = { ...config.notes, title: value };
            setConfig({ ...config, notes: pl });
        } else if (name === "content") {
            let pl = { ...config.notes, content: value };
            setConfig({ ...config, notes: pl });
        } else {
            let newColumns = {
                ...config.columns,
                [name]: value,
            };
            setConfig({ ...config, columns: newColumns });
        }
    };
    return (
        <S.SettingsBody>
            <S.SubBody>
                <S.Headings>
                    Select Printer <span>*</span>
                </S.Headings>
                <CustomSelect
                    type="printer"
                    initalText={config?.printer_name ?? "Printer"}
                    data={printers}
                    disableSearch
                    onSelectChange={handleSelectChange}
                />
            </S.SubBody>
            <S.SubBody>
                <S.Headings>Page Size</S.Headings>
                <CustomSelect
                    type="page-size"
                    initalText={`${config?.page_size} inch` ?? "Page size"}
                    data={pageSize}
                    disableSearch
                    onSelectChange={handleSelectChange}
                />
            </S.SubBody>
            <S.SubBody>
                <S.Headings>Preferences</S.Headings>
                <S.SubBody>
                    <S.Label>Date Format</S.Label>
                    <CustomSelect
                        type="date-format"
                        initalText={config?.date_format ?? "Date Format"}
                        data={thermalDateFormat}
                        disableSearch
                        onSelectChange={handleSelectChange}
                    />
                </S.SubBody>
                <S.SubBody>
                    <S.Label>Currency Symbol</S.Label>
                    <CustomSelect
                        type="currency-symbol"
                        initalText={config?.currency_symbol ?? "Currency Symbol"}
                        data={currencySymbol}
                        disableSearch
                        onSelectChange={handleSelectChange}
                    />
                </S.SubBody>
                <S.SubBody>
                    <S.FlexContainer>
                        <S.Label>Show Order Number</S.Label>
                        <ToggleSwitch
                            type="show_order_number"
                            checked={config?.show_order_number}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show FSSAI Number</S.Label>
                        <ToggleSwitch
                            type="show_fssai_number"
                            checked={config?.show_fssai_number}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Phone Number</S.Label>
                        <ToggleSwitch
                            type="show_phone_number"
                            checked={config?.show_phone_number}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Logo</S.Label>
                        <ToggleSwitch type="show_logo" checked={config?.show_logo} handleChange={(e, type) => handleSwitchChange(e, type)} />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Address</S.Label>
                        <ToggleSwitch type="show_address" checked={config?.show_address} handleChange={(e, type) => handleSwitchChange(e, type)} />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Staff</S.Label>
                        <ToggleSwitch type="show_staff" checked={config?.show_staff} handleChange={(e, type) => handleSwitchChange(e, type)} />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Customer</S.Label>
                        <ToggleSwitch type="show_customer" checked={config?.show_customer} handleChange={(e, type) => handleSwitchChange(e, type)} />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Customer Savings</S.Label>
                        <ToggleSwitch
                            type="show_customer_savings"
                            checked={config?.show_customer_savings}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Payment Mode</S.Label>
                        <ToggleSwitch
                            type="show_payment_mode"
                            checked={config?.show_payment_mode}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Amount Due</S.Label>
                        <ToggleSwitch
                            type="show_amount_due"
                            checked={config?.show_amount_due}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Show Discount As Percentage</S.Label>
                        <ToggleSwitch
                            type="show_discount_as_percentage"
                            checked={config?.show_discount_as_percentage}
                            handleChange={(e, type) => handleSwitchChange(e, type)}
                        />
                    </S.FlexContainer>
                </S.SubBody>
            </S.SubBody>
            <S.SubBody>
                <S.Headings>Abbreviation</S.Headings>
                <S.SubBody>
                    <S.FlexContainer>
                        <S.Label>Serial Number</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="serial_number" value={config?.columns?.serial_number} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Product Name</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="item" value={config?.columns?.item} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Quantity</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="quantity" value={config?.columns?.quantity} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Price</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="price" value={config?.columns?.price} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Discount</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="discount" value={config?.columns?.discount} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                    <S.FlexContainer>
                        <S.Label>Amount</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="amount" value={config?.columns?.amount} onChange={handleInputChange} />
                        </S.InputContainer>
                    </S.FlexContainer>
                </S.SubBody>
            </S.SubBody>

            {/* TODO: didn't find key */}
            {/* <S.SubBody>
                <S.Label>Show discount in </S.Label>
                <CustomSelect
                    type="show-discount-in"
                    initalText={"Show discount in"}
                    data={discountIn}
                    disableSearch
                    // onSelectChange={handleSelectChange}
                />
            </S.SubBody> */}

            <div>
                <S.FlexContainer>
                    <S.Label>Show Total Amount in words?</S.Label>
                    <ToggleSwitch
                        type="show_amount_in_words"
                        checked={config?.show_amount_in_words}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.Hint>Eg. Two thousand & fourty three</S.Hint>
            </div>

            <S.SubBody>
                <S.FlexContainer>
                    <S.Label>Thank you note</S.Label>
                    <ToggleSwitch
                        type="thank_you_note"
                        checked={config?.thank_you_note?.show}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.Textarea
                    height="92px"
                    placeholder="Eg. Thank you. Visit Again"
                    name="value"
                    value={config?.thank_you_note?.value}
                    onChange={handleInputChange}
                ></S.Textarea>
            </S.SubBody>

            <S.SubBody>
                <S.FlexContainer>
                    <S.Label>Additional Notes?</S.Label>
                    <ToggleSwitch type="notes" checked={config?.notes?.show} handleChange={(e, type) => handleSwitchChange(e, type)} />
                </S.FlexContainer>

                <S.SubBody>
                    <S.Label>Title</S.Label>
                    <S.Textarea
                        height="39px"
                        placeholder="Eg. Thank you. Visit Again"
                        name="title"
                        value={config?.notes?.title}
                        onChange={handleInputChange}
                    ></S.Textarea>
                </S.SubBody>
                <S.SubBody>
                    <S.Label>Body</S.Label>
                    <S.Textarea
                        height="76px"
                        placeholder="Eg. Thank you. Visit Again"
                        name="content"
                        value={config?.notes?.content}
                        onChange={handleInputChange}
                    ></S.Textarea>
                </S.SubBody>
            </S.SubBody>
        </S.SettingsBody>
    );
};

export default Thermal;
