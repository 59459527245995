import React, { useEffect, useState, createRef } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import imageCompression from "browser-image-compression";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropperModal = ({ open, onClose, image, onSubmit }) => {
    const [zoom, setZoom] = useState(1);
    const [img, setImg] = useState(null);

    const cropperRef = createRef();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImg(reader.result);
            });
            reader.readAsDataURL(image);
        }

        return () => {
            setImg(null);
        };
    }, [open]);

    const handleDone = async () => {
        try {
            if (typeof cropperRef.current?.cropper !== "undefined") {
                let imgUrl = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();

                let fileObject = dataURLtoFile(imgUrl, "product.png");

                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 512,
                    useWebWorker: true,
                };

                let compressedFile = await imageCompression(fileObject, options);

                onSubmit(compressedFile);
                handleClose();

                // const reader = new FileReader();
                // reader.addEventListener("load", () => {
                //     console.log("src", reader.result);
                // });
                // reader.readAsDataURL(compressedFile);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleClose = () => {
        onClose();
        setImg(null);
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.CropperDiv>
                    {img && (
                        <>
                            <Cropper
                                ref={cropperRef}
                                style={{ height: "100%", width: "100%" }}
                                aspectRatio={1 / 1}
                                preview=".img-preview"
                                src={img}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={false}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                                modal={false}
                            />

                            {/* <S.Control>
                                <S.Icon>-</S.Icon>
                                <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e) => {
                                        setZoom(e.target.value);
                                    }}
                                    className="zoom-range"
                                />
                                <S.Icon>+</S.Icon>
                            </S.Control> */}
                        </>
                    )}
                </S.CropperDiv>
                <S.BottomBar>
                    <S.CancelBtn onClick={handleClose}>Cancel</S.CancelBtn>
                    <S.ConfirmBtn onClick={handleDone}>Done</S.ConfirmBtn>
                </S.BottomBar>
            </S.Container>
        </Modal>
    );
};

export default CropperModal;
