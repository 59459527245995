import React, { useCallback, useEffect, useState, useImperativeHandle } from "react";
import {
    FILTER_PURCHASE_BILL_TYPES,
    PAGE_LIMIT,
    PAYMENT_STATUS,
    PURCHASE_SORT_LIST,
    PURCHASE_STATUS,
    PURCHASE_TYPE,
    REPORTS_TYPE,
    SCREENS,
} from "../../../constants/appConstants";
import { Drawer } from "@mui/material";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import PurchaseTable from "../purchase-table/purchase-table";
import CustomButton from "../../product-drawer/custom-button/custom-button";

import CustomInput from "../../product-drawer/custom-input/custom-input";
import PaginationBar from "../pagination-bar/pagination-bar";

import * as S from "./purchase-view.styles";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllPurchases,
    removeAllSearchParams,
    removeSearchParams,
    resetSelectedHeaderTab,
    resetSelectedTab,
    setCurrentPurchaseScreen,
    setPurchaseCountData,
    setSearchParams,
    setSelectedHeaderTab,
    setSelectedTab,
} from "../../../store/purchase-slice";
import { fetchAllBrands, removeSelectedBrand } from "../../../store/brand-slice";
import { fetchAllSuppliers, removeSelectedSupplier } from "../../../store/supplier-slice";
import { setSearchEmpParams, fetchAllEmployees, removeSelectedEmployee } from "../../../store/employee-slice";
import { debounce } from "lodash";
import SelectedChips from "../../selected-chips/selected-chips";
import AddSupplierModal from "../add-supplier-modal/add-supplier-modal";
import NewSupplierModal from "../../new-supplier-modal/new-supplier-modal";
import { DateRange } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIcon, DownloadBtnIcon, ScArrowLeft, ScArrowRight } from "../../../icons";
import { calendarFormatDate, formatDate } from "../../../utils/format-date";
import { addDays } from "date-fns";
import { getPurchasesCount } from "../../../api/local/purchase-api";
import { useNavigate } from "react-router-dom";
import PurchaseDraftDrawer from "./../purchase-draft-drawer/PurchaseDraftDrawer";
import { useRef } from "react";
import ShortcutBtn from "../../shortcut-btn/ShortcutBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import moment from "moment";
import { generatePurchaseReport } from "../../../api/local/reports-api";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { captureException } from "../../../crash-reporting";
import { FEATURES, PLANS } from "../../../constants/POS-plans";

const TABS = ["All", "Published", "Draft", "In-Review"];
const initalDisplayData = {
    brand: {},
    sort: {},
    supplier: {},
    employee: {},
    payment_status: {},
    date: "",
};
const initalTabsDataCount = {
    [PURCHASE_TYPE.PURCHASE]: 0,
    [PURCHASE_TYPE.RETURN]: 0,
    ALL: 0,
    [PURCHASE_STATUS.PUBLISHED]: 0,
    [PURCHASE_STATUS.DRAFT]: 0,
    [PURCHASE_STATUS.IN_REVIEW]: 0,
};

const initialRange = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
};

const PurchaseView = (props, ref) => {
    const [pageNo, setPageNo] = useState(1);
    const [productSearch, setProductSearch] = useState("");
    const [displayData, setDisplayData] = useState(initalDisplayData);
    const [tabsDataCount, setTabsDataCount] = useState(initalTabsDataCount);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const [openDatePicker, _setOpenDatePicker] = useState(false);
    const openDatePickerRef = useRef(openDatePicker);
    const setOpenDatePicker = (data) => {
        openDatePickerRef.current = data;
        _setOpenDatePicker(data);
    };
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [purchaseCount, setPurchaseCount] = useState(null);
    const [openDraftDrawer, setOpenDraftDrawer] = useState(false);
    const [draftType, setDraftType] = useState(null);
    const inputRef = useRef();
    const supplierRef = useRef();
    const brandRef = useRef();
    const employeeRef = useRef();
    const paymentStatusRef = useRef();
    const timeRef = useRef();
    const sortRef = useRef();
    const purchaseBillTypeRef = useRef();

    const brandsData = useSelector((state) => state.brand.brands);
    const suppliersData = useSelector((state) => state.supplier.suppliers);
    const employeesData = useSelector((state) => state.employee.employees);
    const searchEmpParams = useSelector((state) => state.employee.searchEmpParams);
    const { features } = useSelector((state) => state.app);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    const { purchases, searchParams, purchaseCountData, selectedHeaderTab, selectedTab, currentPurchaseScreen } = useSelector(
        (state) => state.purchase
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectRef = useRef(false);
    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = React.useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };
    const trackRef = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            searchProduct: () => inputRef.current?.focus(),
            searchSupplier: () => onSupplier(),
            searchBrand: () => onBrand(),
            searchEmployee: () => onEmployee(),
            searchStatus: () => onStatus(),
            searchBillType: () => onBill(),
            filterSort: () => onSort(),
            newPurchaseFun: () => navigate("/dashboard/new-purchase"),
            returnTab: () =>
                selectedHeaderTab === PURCHASE_TYPE.RETURN ? navigate("/dashboard/new-purchase-return") : handleHeaderTabChange(PURCHASE_TYPE.RETURN),
            clearFilter: () => handleClearFilter(),
            damageReturn: () => navigate("/dashboard/new-damage-entry"),
            purchaseTab: () => handleHeaderTabChange(PURCHASE_TYPE.PURCHASE),
            changeTab: () => onHeaderTabChange(),
            onNextTab: () => handleNextTabChange(),
            onPrevTab: () => handlePrevTabChange(),
            onNewScreen: () => handleNewScreen(),
            onDraftScreen: () => handleDraftScreen(),
            clearSearch: () => onClearSearch(),
            onNextPage: () => handlePageChange(pageNo + 1),
            onPrevPage: () => handlePageChange(pageNo - 1),
        };
    });

    const onSupplier = () => {
        if (trackRef.current === "brand") {
            brandRef.current?.click();
        } else if (trackRef.current === "employee") {
            employeeRef.current?.click();
        } else if (trackRef.current === "payment_status") {
            paymentStatusRef.current?.click();
        } else if (trackRef.current === "sort") {
            sortRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        } else if (trackRef.current === "purchase_bill_type") {
            purchaseBillTypeRef.current?.click();
        }
        supplierRef.current?.click();
    };
    const onBrand = () => {
        if (trackRef.current === "supplier") {
            supplierRef.current?.click();
        } else if (trackRef.current === "employee") {
            employeeRef.current?.click();
        } else if (trackRef.current === "payment_status") {
            paymentStatusRef.current?.click();
        } else if (trackRef.current === "sort") {
            sortRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        } else if (trackRef.current === "purchase_bill_type") {
            purchaseBillTypeRef.current?.click();
        }
        brandRef.current?.click();
    };

    const onEmployee = () => {
        if (trackRef.current === "supplier") {
            supplierRef.current?.click();
        } else if (trackRef.current === "brand") {
            brandRef.current?.click();
        } else if (trackRef.current === "payment_status") {
            paymentStatusRef.current?.click();
        } else if (trackRef.current === "sort") {
            sortRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        } else if (trackRef.current === "purchase_bill_type") {
            purchaseBillTypeRef.current?.click();
        }
        employeeRef.current?.click();
    };
    const onStatus = () => {
        if (trackRef.current === "supplier") {
            supplierRef.current?.click();
        } else if (trackRef.current === "brand") {
            brandRef.current?.click();
        } else if (trackRef.current === "employee") {
            employeeRef.current?.click();
        } else if (trackRef.current === "sort") {
            sortRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        } else if (trackRef.current === "purchase_bill_type") {
            purchaseBillTypeRef.current?.click();
        }
        paymentStatusRef.current?.click();
    };
    const onSort = () => {
        if (trackRef.current === "supplier") {
            supplierRef.current?.click();
        } else if (trackRef.current === "brand") {
            brandRef.current?.click();
        } else if (trackRef.current === "payment_status") {
            paymentStatusRef.current?.click();
        } else if (trackRef.current === "employee") {
            employeeRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        } else if (trackRef.current === "purchase_bill_type") {
            purchaseBillTypeRef.current?.click();
        }
        sortRef.current?.click();
    };

    const onBill = () => {
        if (trackRef.current === "supplier") {
            supplierRef.current?.click();
        } else if (trackRef.current === "brand") {
            brandRef.current?.click();
        } else if (trackRef.current === "payment_status") {
            paymentStatusRef.current?.click();
        } else if (trackRef.current === "sort") {
            sortRef.current?.click();
        } else if (trackRef.current === "employee") {
            employeeRef.current?.click();
        } else if (openDatePickerRef.current) {
            setOpenDatePicker(false);
        }
        purchaseBillTypeRef.current?.click();
    };

    const handleNewScreen = () => {
        if (selectedHeaderTab === PURCHASE_TYPE.PURCHASE) {
            navigate("/dashboard/new-purchase");
        } else if (selectedHeaderTab === PURCHASE_TYPE.RETURN) {
            navigate("/dashboard/new-purchase-return");
        } else {
            navigate("/dashboard/new-damage-entry");
        }
    };

    const handleDraftScreen = () => {
        if (selectedHeaderTab === PURCHASE_TYPE.PURCHASE) {
            handleOpenPurchaseDraftDrawer("PURCHASE");
        } else if (selectedHeaderTab === PURCHASE_TYPE.RETURN) {
            handleOpenPurchaseDraftDrawer("RETURN");
        } else {
            handleOpenPurchaseDraftDrawer("DAMAGE_ENTRY");
        }
    };

    const onHeaderTabChange = () => {
        selectedHeaderTab === PURCHASE_TYPE.PURCHASE
            ? handleHeaderTabChange(PURCHASE_TYPE.RETURN)
            : selectedHeaderTab === PURCHASE_TYPE.RETURN && features.includes(FEATURES.DAMAGE)
            ? handleHeaderTabChange(PURCHASE_TYPE.DAMAGE_ENTRY)
            : handleHeaderTabChange(PURCHASE_TYPE.PURCHASE);
    };

    const handleNextTabChange = () => {
        if (inputRef.current !== document.activeElement) {
            if (selectedTab === TABS[0]) {
                handleTabSelect(PURCHASE_STATUS.PUBLISHED, TABS[1]);
            } else if (selectedTab === TABS[1]) {
                handleTabSelect(PURCHASE_STATUS.DRAFT, TABS[2]);
            }
        }
    };
    const handlePrevTabChange = () => {
        if (inputRef.current !== document.activeElement) {
            if (selectedTab === TABS[2]) {
                handleTabSelect(PURCHASE_STATUS.PUBLISHED, TABS[1]);
            } else if (selectedTab === TABS[1]) {
                handleTabSelect(null, TABS[0]);
            }
        }
    };

    const onClearSearch = () => {
        handleProductSearchChange("");
    };

    const handleClearFilter = () => {
        setDisplayData(initalDisplayData);
        dispatch(removeSearchParams("supplier_id"));
        dispatch(removeSearchParams("brand_id"));
        dispatch(removeSearchParams("employee_id"));
        dispatch(removeSearchParams("payment_status"));
        dispatch(removeSearchParams("is_reversed"));
        dispatch(removeSearchParams("sort_by"));
        dispatch(removeSelectedBrand());
        dispatch(removeSelectedSupplier());
        dispatch(removeSelectedEmployee());
    };
    const delayedFunction = (val, page) => {
        if (val.trim()) {
            if (page) dispatch(setSearchParams({ search: val.trim(), page: 1 }));
            else dispatch(setSearchParams({ search: val.trim() }));
        } else dispatch(removeSearchParams("search"));
        dispatch(setSearchParams({ page: 1 }));
        setPageNo(1);
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    useEffect(() => {
        if (!brandsData?.length) dispatch(fetchAllBrands());
        if (!suppliersData?.length) dispatch(fetchAllSuppliers());
        if (!employeesData?.length) dispatch(fetchAllEmployees(searchEmpParams));

        return () => {
            dispatch(removeAllSearchParams());
            dispatch(removeSelectedBrand());
            dispatch(removeSelectedSupplier());
            dispatch(removeSelectedEmployee());
        };
    }, []);

    useEffect(() => {
        handleGetPurchaseCount();
        // dispatch(setSelectedHeaderTab(PURCHASE_TYPE.PURCHASE));
        dispatch(
            setSearchParams({
                type:
                    selectedHeaderTab === PURCHASE_TYPE.PURCHASE
                        ? `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.PURCHASE_PROFORMA},${PURCHASE_TYPE.PURCHASE_ORDER}`
                        : selectedHeaderTab,
                status: selectedTab !== "All" ? selectedTab.toUpperCase() : null,
            })
        );
    }, []);

    useEffect(() => {
        if (searchParams.type) dispatch(fetchAllPurchases(searchParams));
    }, [searchParams]);

    const handleGetPurchaseCount = async () => {
        try {
            let res = await getPurchasesCount();
            if (res.status === 200) {
                dispatch(setPurchaseCountData(res.data));
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleTabSelect = (key, tab) => {
        dispatch(setSelectedTab(tab));
        if (tab === TABS[0]) {
            dispatch(removeSearchParams("status"));
            return;
        }
        if (key) dispatch(setSearchParams({ status: key }));
        setActiveRow(0);
    };

    const handleSelectChange = (key, item) => {
        if (key === "supplier") {
            setDisplayData({ ...displayData, supplier: item });
            dispatch(setSearchParams({ supplier_id: item.id }));
            dispatch(fetchAllSuppliers());
        } else if (key === "brand") {
            setDisplayData({ ...displayData, brand: item });
            dispatch(setSearchParams({ brand_id: item.id }));
            dispatch(fetchAllBrands());
        } else if (key === "employee") {
            setDisplayData({ ...displayData, employee: item });
            dispatch(setSearchParams({ employee_id: item.username }));
        } else if (key === "payment_status") {
            setDisplayData({ ...displayData, payment_status: item });
            dispatch(setSearchParams({ is_paid: item.value }));
        } else if (key === "sort") {
            setDisplayData({ ...displayData, sort: item });
            // dispatch(removeSearchParams("is_reversed"));

            const payload = { sort_by: item.value };
            if (+item.id % 2 === 0) payload.is_reversed = true;
            if (+item.id % 2 === 1) payload.is_reversed = false;
            dispatch(setSearchParams(payload));
        } else if (key === "purchase_bill_type") {
            setDisplayData({ ...displayData, purchase_bill_type: item });
            dispatch(setSearchParams({ type: item.value }));
        }
        dispatch(setSearchParams({ page: 1 }));
        setPageNo(1);
    };

    const handleProductSearchChange = (val) => {
        setProductSearch(val);
        delayedQuery(val, searchParams.page);
    };

    const handleSearchChange = (key, value) => {
        if (key === "supplier") dispatch(fetchAllSuppliers({ search: value }));
        else if (key === "brand") dispatch(fetchAllBrands(value));
    };

    const handleHeaderTabChange = (key) => {
        dispatch(setSelectedHeaderTab(key));
        handleTabSelect(null, TABS[0]);
        if (key === PURCHASE_TYPE.PURCHASE) {
            dispatch(setSearchParams({ type: `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.PURCHASE_PROFORMA},${PURCHASE_TYPE.PURCHASE_ORDER}` }));
        } else {
            dispatch(setSearchParams({ type: key }));
        }
        setActiveRow(0);
        if (key === PURCHASE_TYPE.PURCHASE) dispatch(setCurrentPurchaseScreen(SCREENS.PURCHASE));
        else if (key === PURCHASE_TYPE.RETURN) dispatch(setCurrentPurchaseScreen(SCREENS.RETURNS));
        else if (key === PURCHASE_TYPE.DAMAGE_ENTRY) dispatch(setCurrentPurchaseScreen(SCREENS.DAMAGE_ENTRY));
    };

    const handlePageChange = (page) => {
        if (page === 0) return;
        if (purchases.length < PAGE_LIMIT && page > pageNo) return;
        setPageNo(page);
        dispatch(setSearchParams({ page }));
        setActiveRow(0);
    };

    const handleAddNewModal = () => {
        setOpenSupplierModal(false);
        setOpenAddSupplierModal(true);
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setOpenDatePicker(false);
            setDisplayData({
                ...displayData,
                date: `${formatDate(start)} - ${formatDate(end)}`,
            });
            // dispatch(removeSearchParams("created_at"));
            dispatch(setSearchParams({ start_date: start, end_date: end }));
            dispatch(setSearchParams({ page: 1 }));
            setPageNo(1);
        }
    };

    function handleOpenPurchaseDraftDrawer(type) {
        // dispatch(setSearchParams({ status: "DRAFT" }));
        setDraftType(type);
        setOpenDraftDrawer(true);
    }

    const handleDownloadSalesReport = async () => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your report is downloading, and we will notify you once it is downloaded",
        });
        let params = {
            start_date: searchParams.start_date ?? initialRange.start_date,
            end_date: searchParams.end_date ?? initialRange.end_date,
            type: selectedHeaderTab.toUpperCase(),
        };
        try {
            let res = await generatePurchaseReport(params);
            if (res.status === 200) {
                window.electronAPI.saveReport({
                    type: REPORTS_TYPE.PURCHASE_REPORT,
                    data: res.data,
                    return: params.type === PURCHASE_TYPE.RETURN ? true : false,
                    damage: params.type === PURCHASE_TYPE.DAMAGE_ENTRY ? true : false,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <>
            <Drawer anchor="right" hideBackdrop="true" open={openDraftDrawer} onClose={() => setOpenDraftDrawer(false)} className="right-drawer">
                {/* <OutsideClickHandler onOutsideClick={() => setOpenDraftDrawer(false)}> */}
                <PurchaseDraftDrawer
                    type={draftType}
                    onCloseDrawer={() => {
                        setOpenDraftDrawer(false);
                        // dispatch(removeAllSearchParams());
                        if (selectedTab !== "All") dispatch(setSearchParams({ status: selectedTab.toUpperCase() }));
                    }}
                    open={openDraftDrawer}
                />
                {/* </OutsideClickHandler> */}
            </Drawer>
            <AddSupplierModal
                open={openSupplierModal}
                onClose={() => setOpenSupplierModal(false)}
                openAddNewModal={() => setOpenAddSupplierModal(true)}
            />
            <NewSupplierModal open={openAddSupplierModal} onClose={() => setOpenAddSupplierModal(false)} />
            <S.Container>
                <S.Header>
                    <S.HeaderLeft>
                        <S.HeaderBtn
                            onClick={() => handleHeaderTabChange(PURCHASE_TYPE.PURCHASE)}
                            active={selectedHeaderTab === PURCHASE_TYPE.PURCHASE}
                        >
                            Purchase <div className="header-btn-count">{purchaseCountData?.PURCHASE.TOTAL}</div>
                        </S.HeaderBtn>
                        <S.HeaderBtn onClick={() => handleHeaderTabChange(PURCHASE_TYPE.RETURN)} active={selectedHeaderTab === PURCHASE_TYPE.RETURN}>
                            Returns <div className="header-btn-count">{purchaseCountData?.RETURN.TOTAL}</div>
                        </S.HeaderBtn>
                        {features.includes(FEATURES.DAMAGE) && (
                            <S.HeaderBtn
                                onClick={() => handleHeaderTabChange(PURCHASE_TYPE.DAMAGE_ENTRY)}
                                active={selectedHeaderTab === PURCHASE_TYPE.DAMAGE_ENTRY}
                            >
                                Damage Entry <div className="header-btn-count">{purchaseCountData?.DAMAGE_ENTRY.TOTAL}</div>
                            </S.HeaderBtn>
                        )}
                        <ShortcutBtn text="TAB" />
                    </S.HeaderLeft>
                    <S.BtnContainer>
                        {/* <S.TodayDataContainer>
                            <S.Today>Today</S.Today>
                            <S.WhiteBox>
                                <div>Total Purchase</div>
                                <S.BlueBox>₱ 12,700</S.BlueBox>
                            </S.WhiteBox>
                            <S.WhiteBox>
                                <div>Total Orders</div>
                                <S.BlueBox>40</S.BlueBox>
                            </S.WhiteBox>
                        </S.TodayDataContainer> */}
                        {currentPurchaseScreen === SCREENS.RETURNS ? (
                            <>
                                {selectedTab === TABS[0] && (
                                    <CustomButton text="Counter Returns (F11)" onClick={() => handleOpenPurchaseDraftDrawer("RETURN")} />
                                )}
                                <CustomButton onClick={() => navigate("/dashboard/new-purchase-return")} text="New Purchase Return (F12)" />
                            </>
                        ) : currentPurchaseScreen === SCREENS.DAMAGE_ENTRY ? (
                            <>
                                {selectedTab === TABS[0] && (
                                    <CustomButton text="Counter Damage (F11)" onClick={() => handleOpenPurchaseDraftDrawer("DAMAGE_ENTRY")} />
                                )}
                                <CustomButton onClick={() => navigate("/dashboard/new-damage-entry")} text="New Damage Entry (F12)" />
                            </>
                        ) : (
                            <>
                                {selectedTab === TABS[0] && (
                                    <CustomButton onClick={() => handleOpenPurchaseDraftDrawer("PURCHASE")} text="Counter Purchases (F11)" />
                                )}
                                <CustomButton onClick={() => navigate("/dashboard/new-purchase")} text="New Purchase (F12)" />
                            </>
                        )}
                    </S.BtnContainer>
                </S.Header>

                <S.Body>
                    <S.TabsContainer>
                        <S.Tabs>
                            <S.Tab onClick={() => handleTabSelect(null, TABS[0])} active={selectedTab === TABS[0]}>
                                {TABS[0]} <div className="tab-btn-count">{purchaseCountData?.[selectedHeaderTab].TOTAL}</div>
                            </S.Tab>
                            <S.Tab onClick={() => handleTabSelect(PURCHASE_STATUS.PUBLISHED, TABS[1])} active={selectedTab === TABS[1]}>
                                {TABS[1]} <div className="tab-btn-count">{purchaseCountData?.[selectedHeaderTab].PUBLISHED}</div>
                            </S.Tab>
                            <S.Tab onClick={() => handleTabSelect(PURCHASE_STATUS.DRAFT, TABS[2])} active={selectedTab === TABS[2]}>
                                {TABS[2]} <div className="tab-btn-count">{purchaseCountData?.[selectedHeaderTab].DRAFT}</div>
                            </S.Tab>
                            {/* <S.Tab
                                onClick={() => handleTabSelect(PURCHASE_STATUS.IN_REVIEW, TABS[3])}
                                active={selectedTab === TABS[3]}
                            >
                                {TABS[3]}{" "}
                                <div className="tab-btn-count">
                                    {purchaseCountData?.[selectedHeaderTab].IN_REVIEW}
                                </div>
                            </S.Tab> */}
                            <S.ArrowBox>
                                <ShortcutBtn icon={ScArrowLeft} />
                                <ShortcutBtn icon={ScArrowRight} />
                            </S.ArrowBox>
                        </S.Tabs>
                        <S.TableTopRightContainer>
                            <S.SortSelect>
                                Sort (F4) :{" "}
                                <CustomSelect
                                    type="sort"
                                    initalText="Select Sort"
                                    disableSearch
                                    data={PURCHASE_SORT_LIST}
                                    onSelectChange={handleSelectChange}
                                    selectedOption={displayData?.sort?.name}
                                    menuWidth="235px"
                                    position="left"
                                    clickRef={sortRef}
                                    selectRef={selectRef}
                                    trackRef={trackRef}
                                />
                            </S.SortSelect>
                            <S.DownloadContainer onClick={handleDownloadSalesReport}>
                                <img src={DownloadBtnIcon} alt="download icon" />
                            </S.DownloadContainer>
                        </S.TableTopRightContainer>
                    </S.TabsContainer>
                    <S.FiltersContainer>
                        <S.InputContainer>
                            <CustomInput
                                value={productSearch}
                                onChange={handleProductSearchChange}
                                clearTextIcon
                                onClickClear={() => handleProductSearchChange("")}
                                inputRef={inputRef}
                                searchIcon
                                type="text"
                                placeholder="Search by product or bill"
                                scText="F10"
                            />
                        </S.InputContainer>
                        <S.Filters>
                            <CustomSelect
                                type="supplier"
                                initalText="Supplier"
                                onSearchChange={handleSearchChange}
                                data={suppliersData}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.supplier?.name}
                                menuWidth="235px"
                                clickRef={supplierRef}
                                scText="F9"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                            <CustomSelect
                                type="brand"
                                initalText="Brand"
                                onSearchChange={handleSearchChange}
                                data={brandsData}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.brand?.name}
                                menuWidth="235px"
                                clickRef={brandRef}
                                scText="F8"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                            <CustomSelect
                                type="employee"
                                initalText="Employee"
                                localSearch
                                data={employeesData}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.employee?.name}
                                menuWidth="235px"
                                clickRef={employeeRef}
                                scText="F7"
                                selectRef={selectRef}
                                trackRef={trackRef}
                            />
                            {selectedHeaderTab === PURCHASE_TYPE.PURCHASE && (
                                <CustomSelect
                                    type="payment_status"
                                    initalText="Payment Status"
                                    disableSearch
                                    data={PAYMENT_STATUS}
                                    onSelectChange={handleSelectChange}
                                    selectedOption={displayData?.payment_status?.name}
                                    menuWidth="235px"
                                    clickRef={paymentStatusRef}
                                    scText="F6"
                                    selectRef={selectRef}
                                    trackRef={trackRef}
                                />
                            )}
                            {selectedHeaderTab === PURCHASE_TYPE.PURCHASE && (
                                <CustomSelect
                                    type="purchase_bill_type"
                                    initalText="Bill Type"
                                    disableSearch
                                    data={FILTER_PURCHASE_BILL_TYPES}
                                    onSelectChange={handleSelectChange}
                                    selectedOption={displayData?.purchase_bill_type?.name}
                                    menuWidth="235px"
                                    clickRef={purchaseBillTypeRef}
                                    scText="F5"
                                    selectRef={selectRef}
                                    trackRef={trackRef}
                                />
                            )}
                            {selectedHeaderTab === PURCHASE_TYPE.PURCHASE && (
                                <S.RangeContainer>
                                    <div ref={timeRef}>
                                        <DatePicker
                                            selectsRange={true}
                                            startDate={Date.parse(calendarFormatDate(startDate))}
                                            endDate={Date.parse(calendarFormatDate(endDate))}
                                            onChange={(update) => {
                                                handleDateChange(update);
                                            }}
                                            isClearable={false}
                                            dateFormat="dd/MM/yyyy"
                                            className="sales-date-range-picker"
                                            placeholderText="Select time period"
                                        />
                                    </div>
                                    {/* <ShortcutBtn text="F5" /> */}
                                </S.RangeContainer>
                            )}
                        </S.Filters>
                    </S.FiltersContainer>
                    <SelectedChips
                        displayData={displayData}
                        setDisplayData={setDisplayData}
                        resetRange={() => setDateRange([null, null])}
                        clearSearch={() => handleProductSearchChange("")}
                    />
                    <PurchaseTable
                        header={selectedHeaderTab}
                        selectRef={selectRef}
                        activeRow={activeRow}
                        activeRowRef={activeRowRef}
                        setActiveRow={setActiveRow}
                    />
                    <PaginationBar value={pageNo} handlePageChange={handlePageChange} limit={PAGE_LIMIT} type="purchase" />
                </S.Body>
            </S.Container>
        </>
    );
};

export default React.forwardRef(PurchaseView);
