import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 16px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const RightBottomContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const Logout = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border: 1px solid #263238;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #263238;
    cursor: pointer;
`;
export const CancelBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 34px;
    border: 1px solid #0075f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #0075f6;
    cursor: pointer;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    // gap: 18px;
    margin-bottom: 12px;
`;
export const Logo = styled.img`
    width: 49px;
    height: 49px;
    border-radius: 100%;
`;
export const EditIcon = styled.div`
    background-color: #fff;
    margin-left: -13px;
    margin-bottom: -25px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
    & > img {
        width: 10px;
        height: 10px;
    }
`;
export const ImageContainer = styled.div`
    position: relative;
    width: 82px;
    height: 28px;
    border: 1px solid #64748b;
    border-radius: 4px;
    font-weight: 400;
    font-size: 11.3052px;
    line-height: 14px;
    color: #64748b;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 18px;

    div {
        cursor: pointer;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
`;
export const RemoveText = styled.div`
    font-weight: 400;
    font-size: 11.3052px;
    line-height: 14px;
    color: #64748b;
    border-bottom: 1px solid #64748b;
    cursor: pointer;
`;
export const InputBody = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 85px;
`;

export const InputContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > div {
        // flex: 1;
        // height: 62px;
    }
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;
    margin-bottom: 13px;
    span {
        color: #e54630;
    }
`;
export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    width: ${(props) => (props.width ? props.width : "100%")};
    padding: 9px 11px;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
    background-color: #fff;
`;
export const MultipleInputs = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 17px;

    & > div {
        flex: 1;
    }
`;
export const PhoneInput = styled.div`
    display: flex;
    gap: 6px;
`;

export const Code = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 9px 11px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;
