import axios from "./axios";
import { PAYMENTS, SUPPLIERS_API, TRANSACTIONS_CLEAR } from "../../constants/apiRoutes";
import { PAGE_LIMIT } from "../../constants/appConstants";
import { TRANSACTIONS_COUNT } from "../../constants/apiRoutes";

const addSupplier = async (payload) => {
    return await axios.post(SUPPLIERS_API, payload);
};

const getSupplier = async (supplierId) => {
    return await axios.get(`${SUPPLIERS_API}/${supplierId}`);
};

const updateSupplier = async (supplierId, payload) => {
    return await axios.post(`${SUPPLIERS_API}/${supplierId}`, payload);
};

const getAllSuppliers = async (queryParams = {}) => {
    return await axios.get(SUPPLIERS_API, {
        params: queryParams,
    });
};
const getSupplierTransactionCount = async (supplierId) => {
    return await axios.get(`${SUPPLIERS_API}/${TRANSACTIONS_COUNT}/${supplierId}`);
};
const clearSupplierTransactions = async (supplierId, payload) => {
    return await axios.post(`${SUPPLIERS_API}/${TRANSACTIONS_CLEAR}/${supplierId}`, payload);
};

const getSupplierPayments = async (supplierId, queryParams) => {
    return await axios.get(`${SUPPLIERS_API}/${PAYMENTS}/${supplierId}`, {
        params: queryParams,
    });
};

export { addSupplier, getSupplier, updateSupplier, getAllSuppliers, getSupplierTransactionCount, clearSupplierTransactions, getSupplierPayments };
