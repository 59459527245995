import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllSales } from "../api/local/sale-api";
import {
  DiscountOption,
  PAGE_LIMIT,
  SALE_BILL_TYPE,
  SALE_SCREENS,
  SALE_TABLE_TABS,
  SALE_TABS,
} from "../constants/appConstants";
import { captureException } from "../crash-reporting";
import {
  changeString,
  getSaleObjectHash,
  getSaleProductSubTotal,
  getTax,
  getTaxValue,
  getUnit,
  toDecimalPlace,
} from "../utils/global-fn";

const initialState = {
  currentFocus: 0,
  saleProducts: [],
  saleQuantity: 0,
  saleTotal: 0,
  cartTotal: 0,
  taxTotal: 0,
  globalDiscount: 0,
  discountValue: 0,
  searchSaleParams: { limit: PAGE_LIMIT },
  loading: false,
  sales: [],
  error: "",
  selectedSale: "",
  saleId: "",
  saleCount: null,
  saleCustomer: "",
  activeTab: SALE_TABS.SALE,
  activeTableTab: SALE_TABLE_TABS.ALL,
  billno: "",
  createdDate: null,
  selectDiscount: {
    type: DiscountOption.PERCENTAGE,
    cost: 0,
  },
  discountType: {
    First: DiscountOption.FLAT,
    Percentage: false,
    Flat: true,
  },
  currentScreen: SALE_SCREENS.SUMMARY,
  additional_charges: [{ field: "", value: "" }],
  totalAdditionalCharges: 0,
  cp_count: 0,
  cp_name_count: 0,
};
// Generates pending, fulfilled and rejected action types
export const fetchAllSales = createAsyncThunk("sale/fetchAllSales", (search) =>
  getAllSales(search)
    .then((res) => (res.status === 200 ? res.data.entity : []))
    .catch((err) => captureException(err))
);

const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    addProductsToSale: (state, action) => {
      state.saleProducts = action.payload;
      state.currentFocus = 0;
    },
    addProductToSale: (state, action) => {
      const itemIndex = state.saleProducts.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex >= 0) {
        state.saleProducts[itemIndex].quantity =
          parseFloat(state.saleProducts[itemIndex].quantity) + 1;
        state.currentFocus = itemIndex;
      } else {
        state.saleProducts.push({
          ...action.payload,
          sale_price: toDecimalPlace(action.payload.sale_price),
          quantity: action.payload.quantity ? action.payload.quantity : 1,
          discount: action.payload.discount ? action.payload.discount : 0,
          discountValue: action.payload.discountValue
            ? action.payload.discountValue
            : 0,
          activeDiscount: action.payload.activeDiscount
            ? action.payload.activeDiscount
            : DiscountOption.FLAT,
          unit: getUnit(action.payload),
          manufacturing_date: action.payload.manufacturing_date
            ? new Date(action.payload.manufacturing_date)
            : null,
          expiry_date: action.payload.expiry_date
            ? new Date(action.payload.expiry_date)
            : null,
          checkProduct: {
            ...action.payload,
            sale_price: toDecimalPlace(action.payload.sale_price),
            hashKey: getSaleObjectHash(action.payload),
          },
        });
        state.currentFocus = state.saleProducts.length - 1;
      }
    },
    removeProductFromSale: (state, action) => {
      const idx = state.saleProducts.findIndex(
        (item) => item.id === action.payload.id
      );
      if (idx >= 0) {
        let newSaleProducts = state.saleProducts.filter(
          (item) => item.id !== action.payload.id
        );
        if (action.payload.sku?.includes("cp#") && !action.payload.upc) {
          if (
            newSaleProducts.some(
              (item) => item.sku?.includes("cp#") && !item.upc
            )
          ) {
            newSaleProducts = newSaleProducts.map((item, index) => {
              if (
                item.sku?.includes("cp#") &&
                item.name.includes("Item ") &&
                !item.upc &&
                index >= idx
              ) {
                state.cp_count = state.cp_count - 1;
                state.cp_name_count = state.cp_name_count - 1;
                return {
                  ...item,
                  sku: changeString(item.sku, "sku"),
                  name: changeString(item.name, "name"),
                };
              } else if (
                item.sku?.includes("cp#") &&
                !item.upc &&
                index >= idx
              ) {
                state.cp_count = state.cp_count - 1;
                return { ...item, sku: changeString(item.sku, "sku") };
              } else {
                return item;
              }
            });
          }
        }
        state.saleProducts = newSaleProducts;

        // for current focus row
        if (idx >= newSaleProducts.length - 1) {
          state.currentFocus = newSaleProducts.length - 1;
        }

        // for discount clear
        state.selectDiscount = {
          type: DiscountOption.PERCENTAGE,
          cost: 0,
        };
      }
    },
    decrementQuantity: (state, action) => {
      const itemIndex = state.saleProducts.findIndex(
        (item) => item.id === action.payload.id
      );
      if (
        state.saleProducts[itemIndex].quantity >
        state.saleProducts[itemIndex].min_quantity
      ) {
        state.saleProducts[itemIndex].quantity =
          parseFloat(state.saleProducts[itemIndex].quantity) - 1;
      } else if (
        state.saleProducts[itemIndex].quantity > 1 &&
        state.saleProducts[itemIndex].quantity ===
          state.saleProducts[itemIndex].min_quantity
      ) {
        // const newSaleProducts = state.saleProducts.filter(
        //     (item) => item.sku !== action.payload.sku
        // );
        // state.saleProducts = newSaleProducts;
        toast.error("Minimum quantity reached", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
    updateProductFromSale: (state, action) => {
      // const newSaleProducts = state.saleProducts.map((item) => (item.sku === action.payload.sku ? { ...item, ...action.payload } : item));
      const newSaleProducts = state.saleProducts.map((item) => {
        if (item.id === action.payload.id) {
          if (action.payload.checkProduct) {
            // for general slice product update
            return { ...item, ...action.payload };
          } else {
            // update slice product after fetched from db
            let newHash = getSaleObjectHash(action.payload);
            if (item.checkProduct?.hashKey === newHash) {
              let updatedCheckProduct = {
                ...item.checkProduct,
                stock: action.payload.stock,
              };
              return {
                ...item,
                name: action.payload.name,
                checkProduct: updatedCheckProduct,
              };
            } else {
              let updateCheckProduct = {
                ...action.payload,
                sale_price: toDecimalPlace(action.payload.sale_price),
                hashKey: newHash,
              };
              return {
                ...item,
                ...action.payload,
                sale_price: toDecimalPlace(action.payload.sale_price),
                unit: getUnit(action.payload),
                discountValue: 0,
                discount: 0,
                activeDiscount: DiscountOption.FLAT,
                manufacturing_date: action.payload.manufacturing_date
                  ? new Date(action.payload.manufacturing_date)
                  : null,
                expiry_date: action.payload.expiry_date
                  ? new Date(action.payload.expiry_date)
                  : null,
                checkProduct: updateCheckProduct,
              };
            }
          }
        } else {
          return item;
        }
      });
      state.saleProducts = newSaleProducts;
    },
    getSaleTotals: (state, action) => {
      let { total, quantity, tax, discountValue } = state.saleProducts.reduce(
        (saleTotal, saleItem) => {
          const { sale_price, quantity, discountValue, sale_price_with_tax } =
            saleItem;
          let tax = getTax(saleItem);
          let qty = parseFloat(quantity);
          let salePrice = parseFloat(sale_price);
          // let itemTotal = getSalePrice(saleItem, false, action.payload?.bill_type);
          let { itemTotal, taxTotal } = getSaleProductSubTotal(saleItem);
          let itemTaxTotal = 0;
          if (qty > 0) {
            // let value = salePrice * qty - discountValue;
            // itemTaxTotal = getTaxValue(value, tax, sale_price_with_tax);
            // taxTotal = ((salePrice * qty - discountValue) * tax) / 100;
            itemTaxTotal = taxTotal;
          }
          saleTotal.total += itemTotal;
          saleTotal.quantity += parseFloat(quantity);
          saleTotal.tax += itemTaxTotal;
          saleTotal.discountValue += discountValue; // * qty;

          return saleTotal;
        },
        {
          total: 0,
          quantity: 0,
          tax: 0,
          discountValue: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.saleQuantity = quantity;
      state.saleTotal = total;
      state.taxTotal = tax;
      state.discountValue = discountValue;

      let totalCharges = 0;
      if (action.payload?.bill_type === SALE_BILL_TYPE.INVOICE) {
        if (state.additional_charges.length) {
          let ac = JSON.parse(JSON.stringify(state.additional_charges));
          totalCharges = ac
            .filter((item) => item.value)
            .reduce((a, b) => parseFloat(a) + parseFloat(b.value), 0);
          state.totalAdditionalCharges = parseFloat(totalCharges);
        } else {
          state.totalAdditionalCharges = 0;
        }
      }

      let globalDisc =
        state.selectDiscount.type === DiscountOption.PERCENTAGE
          ? total * (parseFloat(state.selectDiscount.cost || 0) / 100)
          : parseFloat(state.selectDiscount.cost || 0);

      state.globalDiscount = toDecimalPlace(globalDisc);
      state.cartTotal = toDecimalPlace(total - globalDisc + totalCharges);
    },
    removeAllProducts: (state) => {
      state.saleProducts = [];
      state.cp_count = 0;
      state.cp_name_count = 0;
    },

    //For api sales
    setSearchSaleParams: (state, action) => {
      state.searchSaleParams = {
        ...state.searchSaleParams,
        ...action.payload,
      };
    },
    removeSearchSaleParams: (state, action) => {
      delete state.searchSaleParams[action.payload];
    },

    removeAllSearchSaleParams: (state) => {
      state.searchSaleParams = { limit: PAGE_LIMIT };
    },
    setSelectedSale: (state, action) => {
      state.selectedSale = action.payload;
    },
    removeSelectedSale: (state) => {
      state.selectedSale = "";
    },
    setSaleID: (state, action) => {
      state.saleId = action.payload;
    },
    removeSaleID: (state) => {
      state.saleId = "";
    },
    setSaleCount: (state, action) => {
      state.saleCount = action.payload;
    },
    setSaleCustomer: (state, action) => {
      state.saleCustomer = action.payload;
    },
    removeSaleCustomer: (state) => {
      state.saleCustomer = "";
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetActiveTab: (state, action) => {
      state.activeTab = SALE_TABS.SALE;
    },
    setActiveTableTab: (state, action) => {
      state.activeTableTab = action.payload;
    },
    resetActiveTableTab: (state, action) => {
      state.activeTableTab = SALE_TABLE_TABS.ALL;
    },
    removeSales: (state, action) => {
      state.sales = [];
    },
    setBillno: (state, action) => {
      state.billno = action.payload;
    },
    setCreatedDate: (state, action) => {
      state.createdDate = action.payload;
    },
    setSelectDiscount: (state, action) => {
      state.selectDiscount = action.payload;
    },
    setCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    setAdditionalCharges: (state, action) => {
      state.additional_charges = action.payload;
    },
    resetAdditionalCharges: (state) => {
      state.additional_charges = [{ field: "", value: "" }];
    },
    setCpCount: (state, action) => {
      state.cp_count = action.payload.cp;
      if (action.payload.cp_name) state.cp_name_count = action.payload.cp_name;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllSales.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSales.fulfilled, (state, action) => {
      state.loading = false;
      state.sales = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAllSales.rejected, (state, action) => {
      state.loading = false;
      state.sales = [];
      state.error = action.error.message;
    });
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default saleSlice.reducer;
export const {
  addProductsToSale,
  addProductToSale,
  removeProductFromSale,
  decrementQuantity,
  getSaleTotals,
  removeAllProducts,
  setSearchSaleParams,
  removeSearchSaleParams,
  removeAllSearchSaleParams,
  setSelectedSale,
  removeSelectedSale,
  setSaleID,
  removeSaleID,
  setSaleCount,
  updateProductFromSale,
  setSaleCustomer,
  removeSaleCustomer,
  setActiveTab,
  resetActiveTab,
  setActiveTableTab,
  resetActiveTableTab,
  removeSales,
  setBillno,
  setCreatedDate,
  setSelectDiscount,
  setCurrentScreen,
  setAdditionalCharges,
  resetAdditionalCharges,
  setCpCount,
} = saleSlice.actions;
