import React, { useContext } from "react";
import * as S from "./style";
import OnlineStore from "../../../assets/images/gif/online-store.gif";
import ShoppingCartLoader from "../../../assets/images/gif/shopping-cart-loader.gif";
import { STORE_TYPES } from "./../../../constants/appConstants";
import { BlueSelectTickIcon, ChevronRightWhite, NoIcon, YesIcon } from "../../../icons";
import Bar from "../page-bar/Bar";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingData } from "../../../store/app-slice";

const StoreType = ({ setActivePage }) => {
    const { onboardingData } = useSelector((state) => state.app);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    const handleOnlineSale = (data) => {
        dispatch(setOnboardingData({ ...onboardingData, sale_online: data }));
    };
    const handleSelectStoreTypes = (item) => {
        if (!onboardingData?.select_store_types.includes(item.name)) {
            dispatch(
                setOnboardingData({
                    ...onboardingData,
                    select_store_types: [...onboardingData?.select_store_types, item.name],
                })
            );
        } else {
            let newStoreTypes = onboardingData?.select_store_types.filter(
                (_item) => _item !== item.name
            );
            dispatch(setOnboardingData({ ...onboardingData, select_store_types: newStoreTypes }));
        }
    };

    const onNextChange = () => {
        if (!onboardingData?.select_store_types.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a store type",
                type: "error",
            });
            return;
        }
        setActivePage(1);
    };
    return (
        <S.Wrapper>
            <S.LeftImgContainer>
                <img src={OnlineStore} alt="online store" />
            </S.LeftImgContainer>
            <S.RightContainer>
                <div></div>
                <div>
                    <S.Container>
                        <S.Heading>
                            <S.Text>Which option best describes your store type?</S.Text>
                            <img src={ShoppingCartLoader} alt="" />
                        </S.Heading>
                        <S.ImageContainer>
                            {onboardingData?.store_types?.map((item) => (
                                <S.ImageBlock onClick={() => handleSelectStoreTypes(item)}>
                                    {onboardingData?.select_store_types.includes(item.name) && (
                                        <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                    )}
                                    <img src={item.image} alt="store icon" />
                                    <div>{item.name}</div>
                                </S.ImageBlock>
                            ))}
                        </S.ImageContainer>
                    </S.Container>

                    <S.Container>
                        <S.Text>Do you want to sell your products through our online store?</S.Text>
                        <S.ImageContainer>
                            <S.ImageBlock onClick={() => handleOnlineSale(true)}>
                                {onboardingData?.sale_online && (
                                    <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                )}
                                <img src={YesIcon} alt="yes icon" />
                                <div>Yes</div>
                            </S.ImageBlock>
                            <S.ImageBlock onClick={() => handleOnlineSale(false)}>
                                {!onboardingData?.sale_online && (
                                    <S.BlueTick src={BlueSelectTickIcon} alt="blue tick" />
                                )}
                                <img src={NoIcon} alt="no icon" />
                                <div>No</div>
                            </S.ImageBlock>
                        </S.ImageContainer>
                        <S.GreyText>({onboardingData?.sale_online_hint})</S.GreyText>
                    </S.Container>
                </div>
                <S.FooterDiv>
                    <S.InvisibleBtn></S.InvisibleBtn>
                    <S.BarContainer>
                        <Bar size="lg" />
                        <Bar />
                        <Bar />
                        {/* <Bar /> */}
                    </S.BarContainer>
                    <S.DarkButton onClick={() => onNextChange()}>
                        Next
                        <img src={ChevronRightWhite} alt="" />
                    </S.DarkButton>
                </S.FooterDiv>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default StoreType;
