import React from "react";
import "./style.css";
import { EditProductIcon, ImageEditIcon, WifiDisconnectedIcon } from "../../icons";

const ProductImage = ({ icon }) => {
    return (
        <div class="container">
            <img
                class="image"
                src={icon}
                alt="product avatar"
                onError={(e) => {
                    e.target.src = WifiDisconnectedIcon;
                }}
            />
            <div class="overlay">
                <img class="icon" src={EditProductIcon} alt="edit icon" />
            </div>
        </div>
    );
};

export default ProductImage;
