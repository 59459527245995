import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CrossBlackIcon, EmptyDraftLogo } from "../../../icons";
import { fetchAllPurchases } from "../../../store/purchase-slice";
import * as S from "./style";
import OutsideClickHandler from "react-outside-click-handler";
import { addProductsToPurchaseReturn, removeAllPurchaseReturnProducts, setSelectedDraftPurchaseReturn } from "../../../store/purchasereturn-slice";
import { addProductsToDamageEntry, removeAllDamageEntryProducts, setSelectedDraftDamage } from "../../../store/damageentry-slice";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { updatePurchase } from "../../../api/local/purchase-api";
import Draft from "./Draft";
import { useRef } from "react";
import store from "../../../store/store";
import { captureException } from "../../../crash-reporting";
import { PURCHASE_TYPE } from "../../../constants/appConstants";
import { addProductsToPP, removeAllProductsPP, setSelectedPP } from "../../../store/purchaseproforma-slice";
import {
    getProductsForPurchaseDraftSelect,
    handleSelectPurchaseDamageDraft,
    handleSelectPurchaseDraft,
    handleSelectPurchaseProformaDraft,
    handleSelectPurchaseReturnDraft,
} from "../../../utils/global-fn";

const PurchaseDraftDrawer = ({ onCloseDrawer, type, open }) => {
    const [drafts, setDrafts] = useState([]);
    const dispatch = useDispatch();
    const { purchases } = useSelector((state) => state.purchase);
    const navigate = useNavigate();
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };
    const scrollRef = useRef(null);

    useEffect(() => {
        if (purchases.length) {
            setDrafts(purchases.filter((item) => item.type === type && item.status === "DRAFT"));
        } else {
            setDrafts([]);
        }
    }, [purchases]);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);
    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            try {
                let draftList = store.getState().purchase.purchases.filter((item) => item.type === type && item.status === "DRAFT");
                if (e.keyCode === 13) {
                    //Enter key
                    handleDraftClick(draftList[activeRowRef.current]);
                    e.preventDefault();
                } else if (e.keyCode === 38) {
                    //UP Arrow key
                    if (activeRowRef.current > 0) {
                        setActiveRow(activeRowRef.current - 1);
                        e.preventDefault();
                    }
                } else if (e.keyCode === 40) {
                    //DOWN Arrow key
                    if (activeRowRef.current < draftList.length - 1) {
                        setActiveRow(activeRowRef.current + 1);
                        e.preventDefault();
                    }
                } else if (e.keyCode === 46) {
                    //DELETE key
                    handleDraftDelete(e, draftList[activeRowRef.current]);
                    e.preventDefault();
                }
            } catch (error) {
                //
            }
        }
    };

    async function handleDraftClick(purchase) {
        if (purchase.type === PURCHASE_TYPE.PURCHASE) {
            handleSelectPurchaseDraft(purchase, dispatch);
            navigate("/dashboard/new-purchase");
        } else if (purchase.type === PURCHASE_TYPE.RETURN) {
            handleSelectPurchaseReturnDraft(purchase, dispatch);
            navigate("/dashboard/new-purchase-return");
        } else if (purchase.type === PURCHASE_TYPE.DAMAGE_ENTRY) {
            handleSelectPurchaseDamageDraft(purchase, dispatch);
            navigate("/dashboard/new-damage-entry");
        } else if ([PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(purchase.type)) {
            handleSelectPurchaseProformaDraft(purchase, dispatch);
            navigate("/dashboard/purchase-proforma");
        }

        onCloseDrawer();
    }

    async function handleDraftDelete(e, item) {
        e.stopPropagation();
        const payload = {
            status: "TRASH",
        };
        try {
            let res = await updatePurchase(item.id, payload);
            if (res.status === 200) {
                dispatch(fetchAllPurchases({ type: type }));

                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: `${
                        type === PURCHASE_TYPE.RETURN
                            ? "Return"
                            : type === PURCHASE_TYPE.DAMAGE_ENTRY
                            ? "Damage"
                            : type === PURCHASE_TYPE.PURCHASE_PROFORMA
                            ? "Purchase proforma"
                            : type === PURCHASE_TYPE.PURCHASE_ORDER
                            ? "Purchase order"
                            : "Purchase"
                    } deleted successfully`,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        }
    }
    return (
        <S.Wrapper>
            <OutsideClickHandler onOutsideClick={onCloseDrawer}>
                <S.TopBar>
                    <S.Heading>
                        Counter{" "}
                        {type === PURCHASE_TYPE.RETURN
                            ? "Returns"
                            : type === PURCHASE_TYPE.DAMAGE_ENTRY
                            ? "Damages"
                            : type === PURCHASE_TYPE.PURCHASE_PROFORMA
                            ? "Purchase Proformas"
                            : type === PURCHASE_TYPE.PURCHASE_ORDER
                            ? "Purchase Orders"
                            : "Purchases"}{" "}
                        ({drafts.length ? drafts.length : 0})
                    </S.Heading>
                    <img src={CrossBlackIcon} alt="" onClick={onCloseDrawer} />
                </S.TopBar>
                {drafts.length ? (
                    <div>
                        {drafts?.map((item, idx) => (
                            <>
                                <Draft
                                    item={item}
                                    type={type}
                                    handleDraftDelete={handleDraftDelete}
                                    handleDraftClick={handleDraftClick}
                                    idx={idx}
                                    activeRow={activeRow}
                                    scrollRef={scrollRef}
                                />
                                <S.Hr></S.Hr>
                            </>
                        ))}
                    </div>
                ) : (
                    <S.EmptyContainer>
                        <img src={EmptyDraftLogo} alt="empty draft" />
                    </S.EmptyContainer>
                )}
            </OutsideClickHandler>
        </S.Wrapper>
    );
};

export default PurchaseDraftDrawer;
