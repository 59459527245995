import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 16px 0;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Header = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e293b;
`;
export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const BlueBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #2d68fe;
    border-radius: 4px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;

    & > img {
        width: 24px;
        height: 24px;
    }
`;
export const BottomBar = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    box-sizing: border-box;
    padding: 0 24px;
`;

export const BlueTextBox = styled.div`
    background: #ccdfff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 18px;
    line-height: 22px;
    color: #2d68fe;
`;
export const BoldText = styled.div`
    font-weight: 500;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding-right: 8px;
    display: flex;
    align-items: center;
    max-height: 38px;
    cursor: pointer;
    background-color: #fff;
    img {
        height: 16px;
        width: 16px;
    }
`;
export const ChipContainer = styled.div`
    box-sizing: border-box;
    // padding: 0 16px;
    // border: 1px solid red;
`;
