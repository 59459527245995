import React from "react";
import * as S from "./style";
import { ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";
import { generateMetrics } from "./../../../utils/metrics";

const Product = ({ item }) => {
    return (
        <S.Wrapper>
            <S.DataDetails>
                <S.ImageContainer>
                    <img
                        src={item.gallery ? item.gallery : ProductPlaceholderIcon}
                        alt="product"
                        onError={(e) => {
                            e.target.src = WifiDisconnectedIcon;
                        }}
                    />
                </S.ImageContainer>
                <S.Content>
                    <S.DarkText>{item.brand?.name}</S.DarkText>
                    <S.GreyText fs="14px">{item.name}</S.GreyText>
                    <S.GreyText fs="12px">{generateMetrics(item)}</S.GreyText>
                </S.Content>
            </S.DataDetails>
            <S.DarkText>
                ₱{item.sale_price}/<S.Unit>UNIT</S.Unit>
            </S.DarkText>
            <S.DarkText>
                {item.quantity} <S.Qty>Quantity</S.Qty>
            </S.DarkText>
            <S.DarkText>₱{item.net_amount}</S.DarkText>
        </S.Wrapper>
    );
};

export default Product;
