import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    // max-height: 315px;
    box-sizing: border-box;
    margin-top: 5px;
    width: 452px;
    max-width: 636px;
    border: 1px solid #fff;
    background-color: #fff;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 12px;
    box-sizing: border-box;
    border-radius: 2px;
    overflow: hidden;
    overflow-y: auto;
    // height: 693px;
    height: 90vh;
`;
export const Tabs = styled.div`
    display: flex;
    gap: 2%;
    padding: 12px;
    position: sticky;
    top: 0;
    font-size: 12px;
    background-color: #fff;
    border-bottom: 1px solid #e6ebf7;

    & > div:nth-of-type(${(props) => props.tab}) {
        border: 2px solid #2d68fe;
        color: #2d68fe;
    }
`;

export const Tab = styled.div`
    padding: 12px 32px;
    border: 2px solid #fff;
    color: #64748b;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 16px;
`;

export const Container = styled.div`
    box-sizing: border-box;
    padding: 0 0 12px 0;
`;

export const ProdRow = styled.div`
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 4px;
    background: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    margin: 4px;
    cursor: pointer;

    :hover {
        background: #e6efff;
    }
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Hr = styled.div`
    width: 95%;
    margin: auto;
    height: 1px;
    border-bottom: 1px solid #e6ebf6;
`;
export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: 70%;
`;

export const PriceRange = styled.div`
    width: 25%;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #253238;
    text-align: center;
`;
export const LongBar = styled.div`
    width: 1px;
    height: 50px;
    border-right: 1px solid #94a3b8;
    margin: 0 13px 0 23px;
`;
export const Add = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2d68fe;
    width: 15%;
    height: 100%;
    cursor: pointer;
    & > img {
        width: 17px;
    }
`;
export const ImgDiv = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
    background: #fff;
    width: 56px;
    height: 56px;

    & > img {
        width: 54px;
        height: 54px;
        border-radius: 4px;
    }
`;
export const Brand = styled.div`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000;
`;

export const Name = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    margin: 3px 0;
    width: 100%;
    overflow: hidden;
    padding-bottom: 2px;
`;

export const Stock = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => (props.stock <= 0 ? "#F91919" : "#17B288")};
`;
export const Empty = styled.div`
    color: #64748b;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    margin: 48px 0;
`;
export const CustRow = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    :hover {
        background: #f1f5f9;
    }
`;
export const CustName = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    margin: 3px 0;
    width: 100%;
`;

export const CustDet = styled.div`
    display: flex;
    color: #64748b;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    gap: 5px;
    //   align-items: center;
`;
export const Divider = styled.div`
    width: 1px;
    background-color: #64748b;
    //   height: 10px;
`;

export const OrderRow = styled.div`
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    :hover {
        background: #f1f5f9;
    }
`;
export const ContentDiv = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
export const TimeDiv = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
`;

export const Quant = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
`;

export const Bar = styled.div`
    width: 1px;
    height: 12px;
    background: #94a3b8;
`;

export const StrikedPrice = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #94a3b8;
    text-decoration: line-through;
`;
export const SalePrice = styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #455a64;
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
