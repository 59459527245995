import React from "react";
import * as S from "./style";
import PaymentsRow from "./PaymentsRow";

let data = [
    {
        created_at: Date.now(),
        id: 23,
        mode: "Cash",
        amount: 147,
        extra_data: 23,
    },
    {
        created_at: Date.now(),
        id: 23,
        mode: "Cash",
        amount: 147,
        extra_data: 23,
    },
    {
        created_at: Date.now(),
        id: 23,
        mode: "Cash",
        amount: 147,
        extra_data: 23,
    },
    {
        created_at: Date.now(),
        id: 23,
        mode: "Cash",
        amount: 147,
        extra_data: 23,
    },
];
const PaymentsTable = ({ list }) => {
    return (
        <S.Table>
            <S.TableHeads>
                <S.Head align="left">Date</S.Head>
                <S.Head>Sale Id</S.Head>
                <S.Head>Mode</S.Head>
                <S.Head>Amount</S.Head>
                <S.Head flex="1.3">Extra Data</S.Head>
            </S.TableHeads>
            <S.TableBody>
                {list?.map((item, idx) => (
                    <PaymentsRow key={idx} item={item} />
                ))}
            </S.TableBody>
        </S.Table>
    );
};

export default PaymentsTable;
