import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: ${(props) => (props.width ? props.width : "215px")};
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    position: relative;
    cursor: pointer;
    background-color: #fff;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    ${(props) => props.rotate && "transform: rotate(180deg)"};
    transition-duration: 0.3s;
`;

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-right: 4px;
`;

export const Input = styled.input`
    width: 100%;
    height: 34px;
    border-radius: 4px;
    border: none;
    outline: none;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #475569;
`;

export const ListContainer = styled.div`
    width: ${(props) => (props.listWidth ? props.listWidth : "100%")};
    position: absolute;
    ${(props) => (props.position === "top" ? "bottom : 40px" : "top :30px")};
    ${(props) => props.position === "left" && "margin-left : -115px"};
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 2px 2px;
    left: 0;
    z-index: 10;
    margin-top: 10px;
    display: ${(props) => !props.data && "none"};
`;
export const Lists = styled.div`
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    background: ${(props) => (props.active ? "#f1f5f9" : "#fff")};

    &:hover {
        background: #f1f5f9;
    }
`;
