import React, { memo } from "react";
import * as S from "./EmployeeLogin.style";
import { useSelector } from "react-redux";
import Avatar from "../../components/avatar/Avatar";
import { AVATAR_COLORS } from "../../constants/appConstants";

const LoginAvatars = ({ onClick }) => {
    const { logins } = useSelector((state) => state.quickLogins);

    const getHash = function (str) {
        var hash = 0,
            i,
            chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return Math.abs(hash) % 10;
    };

    return (
        <div>
            {logins?.length ? (
                <>
                    <S.ORDiv>
                        <S.GradientLineRight></S.GradientLineRight>
                        <div>OR</div>
                        <S.GradientLineLeft></S.GradientLineLeft>
                    </S.ORDiv>
                    <S.QuickAvatarHead>Saved Users</S.QuickAvatarHead>
                    <S.AvatarContainer>
                        {logins?.map((e, i) => (
                            <Avatar onClick={() => onClick(e)} key={i} name={e.name} color={AVATAR_COLORS[getHash(e.name)]} size="56px" />
                        ))}
                    </S.AvatarContainer>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default memo(LoginAvatars);
