import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 353px;
    height: 105px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AnimationDiv = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
`;
