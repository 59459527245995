import React, { useEffect, useState } from "react";
import * as S from "./style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { SelectArrowDownIcon } from "../../icons";
import { calendarFormatDate, formatDate } from "../../utils/format-date";
import PaymentsTable from "./payments-table/PaymentsTable";
import { getCustomerPayments } from "../../api/local/customerApi";
import { captureException } from "../../crash-reporting";
import { getSupplierPayments } from "../../api/local/supplierApi";
import CustomSelect from "../product-drawer/custom-select/custom-select";
import { PAYMENT_MODE_LIST, PAYMENT_MODE_SUMMARY } from "../../constants/appConstants";
import PartyTransactionCount from "../party-transaction-count/PartyTransactionCount";

const initialParams = {
    payment_mode: null,
    start_date: null,
    end_date: null,
};

const PartyPayments = ({ type, id, txnCount }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [list, setList] = useState(null);
    const [params, setParams] = useState(initialParams);
    useEffect(() => {
        if (type === "customer") {
            handleGetCustomerPayments(id);
        } else if (type === "supplier") {
            handleGetSupplierPayments(id);
        }
    }, [params]);

    const handleGetCustomerPayments = async (customerId) => {
        try {
            let res = await getCustomerPayments(customerId, params);
            if (res.status === 200) {
                setList(res.data);
            } else {
                throw new Error(res.data);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleGetSupplierPayments = async (supplierId) => {
        try {
            let res = await getSupplierPayments(supplierId, params);
            if (res.status === 200) {
                setList(res.data);
            } else {
                throw new Error(res.data);
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleDateChange = (item) => {
        setDateRange(item);
        const start = new Date(item[0]).getTime();
        const end = new Date(item[1]).getTime();
        if (start && end) {
            setParams({ ...params, start_date: start, end_date: end });
        }
    };
    const handleSelectChange = (key, item) => {
        if (key === "payment_mode") {
            setParams({ ...params, payment_mode: item.value });
        }
    };

    const onClearFilter = () => {
        setParams(initialParams);
    };
    return (
        <S.Wrapper>
            <S.FilterContainer>
                <S.FilterBox>
                    <S.DateChooserSelect>
                        <DatePicker
                            selectsRange={true}
                            startDate={Date.parse(calendarFormatDate(startDate))}
                            endDate={Date.parse(calendarFormatDate(endDate))}
                            onChange={(update) => {
                                handleDateChange(update);
                            }}
                            isClearable={false}
                            dateFormat="dd/MM/yyyy"
                            className="sales-date-range-picker"
                            placeholderText="Select time period"
                        />
                        <img src={SelectArrowDownIcon} alt="calendar" />
                    </S.DateChooserSelect>
                    <CustomSelect
                        type="payment_mode"
                        disableSearch
                        data={PAYMENT_MODE_LIST}
                        menuWidth={"180px"}
                        onSelectChange={handleSelectChange}
                        selectedOption={params.payment_mode ? PAYMENT_MODE_SUMMARY[params.payment_mode] : "Payment Mode"}
                    />
                </S.FilterBox>
                {(params?.start_date || params?.end_date || params?.payment_mode) && (
                    <S.ClearFilters onClick={onClearFilter}>Clear Filter</S.ClearFilters>
                )}
            </S.FilterContainer>

            <S.Body>
                <PaymentsTable list={list} />
                <PartyTransactionCount type={type} txnCount={txnCount} />
            </S.Body>
        </S.Wrapper>
    );
};

export default PartyPayments;
