import styled from "@emotion/styled/macro";

export const RightInputContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const SupplierContentContainer = styled.div`
    display: flex;
    gap: 16px;
    box-sizing: border-box;
    padding: 0 16px 16px;
`;

export const SupplierContentBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
`;

export const RightInput = styled.div`
    flex: 1;
`;
export const RightInputPhone = styled.div`
    display: flex;
    width: 100%;
    gap: 12px;
    box-sizing: border-box;
    justify-content: space-between;
    & div:nth-child(1) {
        // width: 20%;
    }
`;

export const CountryCode = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
`;

export const DescriptionContainer = styled.div`
    // margin-bottom: 12px;
`;

export const TextArea = styled.textarea`
    height: 81px;
    max-height: 81px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
    resize: none;
    outline: none;
    width: 100%;
    padding: 8px;
    font-family: sans-serif;

    &::placeholder {
        color: #94a3b8;
    }
`;
