import React from "react";
import * as S from "./style";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { STATES } from "../../../constants/appConstants";
import { useState } from "react";
import InputField from "../../input-field/InputField";

const Address = ({ address, setAddress }) => {
    const [allState, setAllState] = useState(STATES);

    const handleInputChange = (event) => {
        let { name, value } = event.target;

        if (name === "pin_code") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            if (value.length > 6) return;
        }

        setAddress({ ...address, [name]: value });
    };

    const handleSearchChange = (key, value) => {
        if (key === "state") {
            setAllState(STATES.filter((s) => s.value.toLowerCase().includes(value.toLowerCase())));
        }
    };

    const handleSelectChange = (key, item) => {
        if (key === "state") {
            setAddress({ ...address, [key]: item.value });
            setAllState(STATES);
        }
    };
    return (
        <S.AddressContainer>
            <S.SingleContainer>
                <InputField label="Address Line 1" value={address.line_1} name="line_1" onChange={handleInputChange} fullWidth={true} />
            </S.SingleContainer>
            <S.SingleContainer>
                <InputField label="Address Line 2" value={address.line_2} name="line_2" onChange={handleInputChange} fullWidth={true} />
            </S.SingleContainer>
            <S.FlexContainer>
                <S.SingleContainer>
                    <InputField label="Pin Code" value={address.pin_code} name="pin_code" onChange={handleInputChange} fullWidth={true} />
                </S.SingleContainer>
                <S.SingleContainer>
                    <InputField label="City" value={address.city} name="city" onChange={handleInputChange} fullWidth={true} />
                </S.SingleContainer>
            </S.FlexContainer>
            <S.FlexSelectContainer>
                <CustomSelect
                    type="state"
                    selectedOption={address.state || "State"}
                    onSearchChange={handleSearchChange}
                    data={allState}
                    onSelectChange={handleSelectChange}
                />
                <CustomSelect
                    type="country"
                    initalText={"Philippines"}
                    data={[]}
                    disableSearch
                    // onSelectChange={handleSelectCountry}
                />
            </S.FlexSelectContainer>
        </S.AddressContainer>
    );
};

export default Address;
