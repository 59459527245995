import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PlusBlueIcon } from "../../../../icons";
import { setNewSelectedSupplier, setSelectedSupplier } from "../../../../store/purchase-slice";
import { setReturnSelectedSupplier } from "../../../../store/purchasereturn-slice";

import * as S from "./supplier-list.styles";
import { setSelectedSupplierPP } from "../../../../store/purchaseproforma-slice";

const SupplierList = ({ data, onAddNewSupplier, onClose, open, onSelect }) => {
    const filterBrandName = (data) => data?.map((brand) => brand.name).join(", ");
    const dispatch = useDispatch();
    const location = useLocation();
    const scrollRef = React.useRef(null);

    const [activeRow, _setActiveRow] = useState(-1);
    const activeRowRef = React.useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
            setActiveRow(-1);
        };
    }, [open, data]);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 38) {
            //Arrow up
            if (activeRowRef.current >= -1) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 40) {
            //Arrow down
            if (activeRowRef.current < data.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            if (activeRowRef.current === -1) {
                onAddNewSupplier();
            } else {
                handleSupplierSelect(data[activeRowRef.current]);
            }
            e.preventDefault();
        }
    };

    const handleSupplierSelect = (item) => {
        let path = location.pathname.split("/")[2];
        if (path === "new-purchase-return") {
            dispatch(setReturnSelectedSupplier(item));
        } else if (path === "purchase-proforma") {
            dispatch(setSelectedSupplierPP(item));
        } else if (path === "view-purchase") {
            onSelect(item);
        } else {
            dispatch(setSelectedSupplier(item));
        }
        onClose();
    };

    return (
        <S.Container>
            <S.AddNew onClick={onAddNewSupplier} tabIndex={-1} active={activeRow === -1}>
                <img src={PlusBlueIcon} alt="add" />
                Add new supplier
            </S.AddNew>
            <S.DetailsContainer>
                {data.length &&
                    data?.map((item, idx) => (
                        <S.Details
                            onClick={() => handleSupplierSelect(item)}
                            key={idx}
                            active={idx === activeRow}
                            ref={idx === activeRow ? scrollRef : null}
                        >
                            <S.Name>{item.name}</S.Name>
                            <S.Brands>{filterBrandName(item.brands)}</S.Brands>
                        </S.Details>
                    ))}
            </S.DetailsContainer>
        </S.Container>
    );
};

export default SupplierList;
