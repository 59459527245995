import React, { useState, useEffect, useRef } from "react";
import { STATES } from "../../constants/appConstants";
import { DarkArrowLeft, WhiteArrowRight } from "../../icons";
import * as S from "./style";
import CustomSelect from "./../product-drawer/custom-select/custom-select";
import { errorNotify } from "../../utils/toast";

const StoreDetails = ({ initialData, setRegistrationData, setActiveTab, onSubmit }) => {
    const [allState, setAllState] = useState(STATES);
    const [selectState, setSelectState] = useState("State");
    const [selectCountry, setSelectCountry] = useState("Philippines");
    const [storeData, setStoreData] = useState({
        store_address: {
            line_1: null,
            line_2: null,
            city: null,
            state: null,
            pin_code: "",
            country: null,
        },
        gst_number: null,
    });

    const selectRef = useRef(false);
    const stateRef = useRef();
    const cityRef = useRef();
    const gstRef = useRef();

    useEffect(() => {
        setRegistrationData({
            ...initialData,
            store_address: {
                ...initialData.store_address,
                state: initialData?.store_address?.state,
                country: selectCountry,
            },
            ...initialData.gst_number,
        });
        if (initialData?.store_address?.state) {
            setSelectState(initialData.store_address.state);
        }
        if (initialData?.store_address?.country) {
            setSelectCountry(initialData.store_address.country);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 9) {
            if (cityRef.current === document.activeElement) {
                e.preventDefault();
                stateRef.current?.click();
            } else if (selectRef.current) {
                e.preventDefault();
                stateRef.current?.click();
                // gstRef.current?.focus();
            }
        }
    };

    const onNextClick = (e) => {
        e.preventDefault();
        if (!initialData.store_address.line_1) {
            errorNotify("Address Line 1 is invalid");
            return;
        }
        if (!initialData.store_address.line_2) {
            errorNotify("Address Line 2 is invalid");
            return;
        }
        if (!initialData.store_address.city) {
            errorNotify("City is invalid");
            return;
        }
        if (!initialData.store_address.pin_code || initialData.store_address.pin_code.length !== 6) {
            errorNotify("PIN code is invalid");
            return;
        }
        if (!initialData.store_address.state) {
            errorNotify("State is invalid");
            return;
        }
        if (!initialData.store_address.country) {
            errorNotify("Country is invalid");
            return;
        }
        onSubmit(initialData);
    };

    const handleSearchChange = (key, value) => {
        setAllState(STATES.filter((e) => e.value.toLowerCase().includes(value.toLowerCase())));
    };

    const handleSelectState = (key, item) => {
        setSelectState(item.value);
        setAllState(STATES);
        setRegistrationData({
            ...initialData,
            store_address: {
                ...initialData.store_address,
                state: item.value,
            },
            ...initialData.gst_number,
        });
    };

    const handleSelectCountry = (key, item) => {
        setSelectCountry(item.value);
        setRegistrationData({
            ...initialData,
            store_address: {
                ...initialData.store_address,
                country: item.value,
            },
            ...initialData.gst_number,
        });
    };

    const handlePincode = (e) => {
        const regex = /^[0-9\b]+$/;
        if (!(e.target.value === "" || regex.test(e.target.value))) return;
        if (e.target.value.length === 7) return;
        setRegistrationData({
            ...initialData,
            store_address: {
                ...initialData.store_address,
                pin_code: e.target.value,
            },
            ...initialData.gst_number,
        });
    };

    return (
        <S.Wrapper onSubmit={(e) => onNextClick(e)}>
            <div>
                <S.TabHead>Enter store details</S.TabHead>
                <S.InputsBodyContainer>
                    <S.InputContainer>
                        <S.Labels>
                            Address Line 1 <span>*</span>
                        </S.Labels>
                        <S.InputFIelds>
                            <input
                                type="text"
                                value={initialData?.store_address?.line_1}
                                autoFocus
                                onChange={(e) =>
                                    setRegistrationData({
                                        ...initialData,
                                        store_address: {
                                            ...initialData.store_address,
                                            line_1: e.target.value,
                                        },
                                        ...initialData.gst_number,
                                    })
                                }
                            />
                        </S.InputFIelds>
                    </S.InputContainer>
                    <S.InputContainer>
                        <S.Labels>
                            Address Line 2 <span>*</span>
                        </S.Labels>
                        <S.InputFIelds>
                            <input
                                type="text"
                                value={initialData?.store_address?.line_2}
                                onChange={(e) =>
                                    setRegistrationData({
                                        ...initialData,
                                        store_address: {
                                            ...initialData.store_address,
                                            line_2: e.target.value,
                                        },
                                        ...initialData.gst_number,
                                    })
                                }
                            />
                        </S.InputFIelds>
                    </S.InputContainer>
                    <S.MultipleInputsContainer>
                        <S.InputContainer>
                            <S.Labels>
                                Pin Code <span>*</span>
                            </S.Labels>
                            <S.InputFIelds>
                                <input type="text" maxLength="6" value={initialData?.store_address?.pin_code} onChange={(e) => handlePincode(e)} />
                            </S.InputFIelds>
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Labels>
                                City <span>*</span>
                            </S.Labels>
                            <S.InputFIelds>
                                <input
                                    type="text"
                                    value={initialData?.store_address?.city}
                                    ref={cityRef}
                                    onChange={(e) =>
                                        setRegistrationData({
                                            ...initialData,
                                            store_address: {
                                                ...initialData.store_address,
                                                city: e.target.value,
                                            },
                                            ...initialData.gst_number,
                                        })
                                    }
                                />
                            </S.InputFIelds>
                        </S.InputContainer>
                    </S.MultipleInputsContainer>
                    <S.MultipleInputsContainer>
                        <S.InputContainer>
                            <S.Labels>
                                State <span>*</span>
                            </S.Labels>
                            <CustomSelect
                                type="state"
                                initalText={selectState}
                                onSearchChange={handleSearchChange}
                                data={allState}
                                onSelectChange={handleSelectState}
                                paddingHead="14px 10px"
                                position="top"
                                clickRef={stateRef}
                                selectRef={selectRef}
                            />
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Labels>
                                Country <span>*</span>
                            </S.Labels>
                            <CustomSelect
                                type="country"
                                initalText={selectCountry}
                                disableSearch
                                onSelectChange={handleSelectCountry}
                                paddingHead="14px 16px"
                                disabled
                            />
                        </S.InputContainer>
                    </S.MultipleInputsContainer>
                    {/* <S.InputContainer>
                        <S.Labels>Enter TAX Number</S.Labels>
                        <S.InputFIelds>
                            <input
                                type="text"
                                ref={gstRef}
                                onChange={(e) =>
                                    setRegistrationData({
                                        ...initialData,
                                        gst_number: e.target.value,
                                    })
                                }
                            />
                        </S.InputFIelds>
                    </S.InputContainer> */}
                </S.InputsBodyContainer>
            </div>
            <S.DoubleButtonFooter>
                <S.WhiteButton onClick={() => setActiveTab((p) => p - 2)}>
                    <img src={DarkArrowLeft} alt="arrow left" />
                    <div>Go Back</div>
                </S.WhiteButton>
                <S.DarkButton type="submit">
                    <div>Next</div>
                    <img src={WhiteArrowRight} alt="arrow right" />
                </S.DarkButton>
            </S.DoubleButtonFooter>
        </S.Wrapper>
    );
};

export default StoreDetails;
