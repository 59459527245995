import styled from "@emotion/styled/macro";
import { multilineEllipsis } from "../../../assets/css/mixin";
import { COLOR } from "../../../constants/appConstants";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: ${(props) => (props.isFull ? "100%" : "calc(100% - 304px)")};
    height: 100%;
    transition: width 0.2s ease-in-out;
`;

export const Table = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    width: 100%;
    height: ${(props) => (props.hasBottom ? "calc(100% - 68px)" : "100%")};
`;

export const TableHeader = styled.div`
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid #e2e8f0;
    padding: 0 10px;
    color: #334155;
`;

export const TableHeaderData = styled.div`
    flex: ${(props) => props.flexValue};
    text-align: ${(props) => (props.center ? "center" : "left")};
`;

export const Bar = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
    margin: 2px 0;
`;
export const TableBody = styled.div`
    overflow-y: auto;
    height: 100%;
`;

export const TableRow = styled.div`
    display: flex;
    padding: 12px 10px;
    background-color: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    border-radius: 4px;
`;
export const TableDataSn = styled.div`
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & div {
        text-align: center;
        border: 1px solid #000000;
        background-color: #fff;
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        color: #000000;
        width: 24px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const TableBodyData = styled.div`
    display: flex;
    flex: ${(props) => props.flexValue};
    align-items: center;
    ${(props) => props.lastItem && "gap: 16px"};
    position: relative;
    ${(props) => props.center && "display: flex"};
    ${(props) => props.column && "flex-direction: column"};
    ${(props) => props.center && "align-items: center"};
    ${(props) => props.center && "justify-content: center"};
    box-sizing: border-box;

    & > div {
        width: ${(props) => props.full && props.full};
    }

    img[alt*="delete"] {
        height: 32px;
        width: 32px;
        cursor: pointer;
        margin: auto;
    }
    img[alt*="info"] {
        height: 32px;
        width: 32px;
        cursor: pointer;
        margin: auto;
    }
    img[alt*="save"] {
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
    img[alt*="edit"] {
        height: 32px;
        width: 32px;
        cursor: pointer;
    }
`;
export const ImagePlaceholder = styled.div`
    width: 56px;
    height: 56px;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProductImage = styled.img`
    width: 56px;
    height: 56px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: #fff;
`;
export const ProductDetails = styled.div`
    margin-left: 4px;
    cursor: pointer;
`;

export const Brand = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #475569;
    margin-bottom: 2px;
`;

export const Name = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2d68fe;
    margin-bottom: 2px;
    ${multilineEllipsis({ fontSize: "12px", lineHeight: "15px", lines: 2 })}
`;
export const InputContainer = styled.div`
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    width: 70%;
    background-color: #fff;
`;

export const Percent = styled.div`
    padding-right: 9px;
`;
export const Rupee = styled.div`
    padding-left: 9px;
`;
export const Input = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    resize: both;
    text-align: ${(props) => props.align};
    padding-left: ${(props) => props.paddingLeft};
`;
export const HintText = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #94a3b8;
    margin-top: 2px;
    margin-bottom: -12px;
    text-align: center;
`;

export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e2e8f0;
    width: 50%;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
export const InputFlexContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & > div:nth-of-type(1) {
        width: 45%;
    }
    & > div:nth-of-type(2) {
        width: 55%;
    }
`;
export const RateDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 2px;
    position: absolute;
    top: 85%;
    left: 2.5%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    background-color: ${(props) => (props.inc ? "#eefdec" : "#fdecec")};
    color: ${(props) => (props.inc ? "#4CE13F" : "#E13F3F")};
`;
export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #d9d9d9;
    width: 44px;
    height: 37px;
    box-sizing: border-box;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;
    border-radius: 4px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 4px 3px;
`;
export const Symbol = styled.div`
    width: 16px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 4px;
`;
export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
export const DiscountInputDiv = styled.div`
    width: 44px;
    height: 37px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    & > input {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #334155;
        border: none;
        outline: none;
    }
`;
export const DataText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
`;

export const MoreInfoWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 760px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -78px;
    box-sizing: border-box;
    padding: 7px 16px;
    border: 1px solid #cbd5e1;
`;
export const OptionHeader = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;
export const OptionDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const OptionInputContainer = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    width: 93px;
`;

export const MoreIcon = styled.div`
    margin: auto;
    position: relative;
    cursor: pointer;
`;
export const Dot = styled.div`
    position: absolute;
    left: 80%;
    top: 70%;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${COLOR.RED};
`;
