import React from "react";
import * as S from "../style";
import CustomSelect from "../../../product-drawer/custom-select/custom-select";
import ToggleSwitch from "../../../switch/Switch";
import { currencySymbol, barcodeDateFormat, stickerRow, stickerSize } from "../../../../constants/printerConfigConstants";

const Barcode = ({ printers, config, setConfig }) => {
    const handleSelectChange = (key, item) => {
        if (key === "printer") {
            setConfig({ ...config, printer_name: item.name });
        } else if (key === "sticker-per-row") {
            setConfig({ ...config, sticker_per_row: item.value });
        } else if (key === "sticker-size") {
            setConfig({ ...config, sticker_size: item.value });
        } else if (key === "currency-symbol") {
            setConfig({ ...config, currency_symbol: item.value });
        } else if (key === "date-format") {
            setConfig({ ...config, date_format: item.value });
        }
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        const regex = /^[0-9\b]+$/;
        if (!(value === "" || regex.test(value))) return;
        let max = 10;
        if (Number(value) > Number(max)) {
            value = max;
        }
        setConfig({ ...config, [name]: parseInt(value || 0) });
    };
    const handleSwitchChange = (event, type) => {
        setConfig({ ...config, [type]: event.target.checked });
    };
    return (
        <S.SettingsBody>
            <S.SubBody>
                <S.Headings>
                    Select Printer <span>*</span>
                </S.Headings>
                <CustomSelect
                    type="printer"
                    initalText={config?.printer_name ?? "Printer"}
                    data={printers}
                    disableSearch
                    onSelectChange={handleSelectChange}
                />
            </S.SubBody>

            <S.SubBody>
                <S.Headings>Sticker Sheet Information</S.Headings>
                <S.GridBody>
                    <S.SubBody>
                        <S.Label>Sticker Per Row</S.Label>
                        <CustomSelect
                            type="sticker-per-row"
                            initalText={config?.sticker_per_row ?? ""}
                            data={stickerRow}
                            disableSearch
                            onSelectChange={handleSelectChange}
                        />
                    </S.SubBody>
                    <S.SubBody>
                        <S.Label>Sticker Size</S.Label>
                        <CustomSelect
                            type="sticker-size"
                            initalText={config?.sticker_size ?? "sticker size"}
                            data={stickerSize}
                            disableSearch
                            onSelectChange={handleSelectChange}
                        />
                    </S.SubBody>
                </S.GridBody>
            </S.SubBody>
            <S.SubBody>
                <S.Headings>Gaps & Margins</S.Headings>
                <S.GridBody>
                    <S.SubBody>
                        <S.Label>Left Gap</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="sticker_gap_start" value={config?.sticker_gap_start} onChange={handleInputChange} />
                            <div>mm</div>
                        </S.InputContainer>
                    </S.SubBody>
                    <S.SubBody>
                        <S.Label>Right Gap</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="sticker_gap_end" value={config?.sticker_gap_end} onChange={handleInputChange} />
                            <div>mm</div>
                        </S.InputContainer>
                    </S.SubBody>
                </S.GridBody>
                <S.GridBody>
                    {config?.sticker_per_row != 1 && (
                        <S.SubBody>
                            <S.Label>Center Gap</S.Label>
                            <S.InputContainer>
                                <S.Input type="text" name="sticker_gap_center" value={config?.sticker_gap_center} onChange={handleInputChange} />
                                <div>mm</div>
                            </S.InputContainer>
                        </S.SubBody>
                    )}
                    <S.SubBody>
                        <S.Label>Vertical Gap</S.Label>
                        <S.InputContainer>
                            <S.Input type="text" name="sticker_gap_vertical" value={config?.sticker_gap_vertical} onChange={handleInputChange} />
                            <div>mm</div>
                        </S.InputContainer>
                    </S.SubBody>
                </S.GridBody>
                <S.GridBody>
                    <S.SubBody>
                        <S.Label>Horizontal Padding</S.Label>
                        <S.InputContainer>
                            <S.Input
                                type="text"
                                name="sticker_horizontal_padding"
                                value={config?.sticker_horizontal_padding}
                                onChange={handleInputChange}
                            />
                            <div>mm</div>
                        </S.InputContainer>
                    </S.SubBody>
                    <S.SubBody>
                        <S.Label>Vertical Padding</S.Label>
                        <S.InputContainer>
                            <S.Input
                                type="text"
                                name="sticker_vertical_padding"
                                value={config?.sticker_vertical_padding}
                                onChange={handleInputChange}
                            />
                            <div>mm</div>
                        </S.InputContainer>
                    </S.SubBody>
                </S.GridBody>
            </S.SubBody>
            <S.SubBody>
                <S.Headings>Sticker Preferences</S.Headings>
                <S.SubBody>
                    <S.Label>Currency Symbol</S.Label>
                    <CustomSelect
                        type="currency-symbol"
                        initalText={config?.currency_symbol ?? "Currency Symbol"}
                        data={currencySymbol}
                        disableSearch
                        onSelectChange={handleSelectChange}
                    />
                </S.SubBody>
                <S.SubBody>
                    <S.Label>Date Format</S.Label>
                    <CustomSelect
                        type="date-format"
                        initalText={config?.date_format ?? "Date Format"}
                        data={barcodeDateFormat}
                        disableSearch
                        onSelectChange={handleSelectChange}
                    />
                </S.SubBody>
                <S.FlexContainer>
                    <S.Label>Show Store Name</S.Label>
                    <ToggleSwitch type="show_store_name" checked={config?.show_store_name} handleChange={(e, type) => handleSwitchChange(e, type)} />
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Label>Show FSSAI Number</S.Label>
                    <ToggleSwitch
                        type="show_fssai_number"
                        checked={config?.show_fssai_number}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Label>Show Product Name</S.Label>
                    <ToggleSwitch
                        type="show_product_name"
                        checked={config?.show_product_name}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                {/* <S.FlexContainer>
                    <S.Label>Show Price</S.Label>
                    <ToggleSwitch type="show_price" checked={config?.show_price} handleChange={(e, type) => handleSwitchChange(e, type)} />
                </S.FlexContainer> */}
                <S.FlexContainer>
                    <S.Label>Show Discounted Price</S.Label>
                    <ToggleSwitch
                        type="show_discounted_price"
                        checked={config?.show_discounted_price}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Label>Show Mfg. Date</S.Label>
                    <ToggleSwitch
                        type="show_manufactured_date"
                        checked={config?.show_manufactured_date}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Label>Show Exp. Date</S.Label>
                    <ToggleSwitch
                        type="show_expiry_date"
                        checked={config?.show_expiry_date}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Label>Show Custom Fields</S.Label>
                    <ToggleSwitch
                        type="show_custom_fields"
                        checked={config?.show_custom_fields}
                        handleChange={(e, type) => handleSwitchChange(e, type)}
                    />
                </S.FlexContainer>
            </S.SubBody>
        </S.SettingsBody>
    );
};

export default Barcode;
