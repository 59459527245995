import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: ${(props) =>
        props.size === "sm" ? "160px" : props.size === "md" ? "200px" : props.size === "lg" ? "240px" : props.size ? props.size : "160px"};
    height: ${(props) =>
        props.size === "sm" ? "160px" : props.size === "md" ? "200px" : props.size === "lg" ? "240px" : props.size ? props.size : "160px"};
`;
export const Logo = styled.img`
    width: 100%;
    height: 100%;
`;
