import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    width: 600px;
    height: 227px;
`;
export const HeaderBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    padding: 14px 19px 12px;

    & img {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }
`;
export const Body = styled.div`
    height: 94px;
    width: 568px;
    box-sizing: border-box;
    margin: 16px;
    border-radius: 4px;
    background: #f1f5f9;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 24px;
    padding: 12px 14px;

    span {
        color: #ff4343;
    }
`;
export const CustomerContainer = styled.div`
    flex: 1;
`;
export const InputContainers = styled.div`
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    width: 258px;
`;
export const InputContainer = styled.div`
    display: flex;
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;
    border: 1px solid #e2e8f0;

    & input {
        width: 100%;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
        :focus {
            outline: none;
        }
    }
`;
export const Bar = styled.div`
    width: 1px;
    height: 100%;
    background-color: #e2e8f0;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
`;
export const Bottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export const SubmitBtn = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    background: #2d68fe;
    border-radius: 4px;
    padding: 10px;
    margin-right: 16px;
    cursor: pointer;
`;
