import React from "react";
import * as S from "../PlanBuyModal.style";
import { HomeDropLogo } from "../../../icons";
import YellowBox from "../yellow-box/YellowBox";
import BrandingLogo from "../../branding/branding-logo/BrandingLogo";
import BrandingFullLogo from "../../branding/full-logo/BrandingFullLogo";

const PaymentFail = () => {
    return (
        <div>
            <S.HeroContainer>
                <S.LogoContainer>
                    <BrandingFullLogo size="120px" />
                </S.LogoContainer>
            </S.HeroContainer>
            <S.FailedPaymentText>
                <S.CenterText size="470px">
                    <S.Heading>We regret to inform you that there was an issue processing your payment</S.Heading>
                </S.CenterText>
                <S.CenterText size="440px">
                    <S.SubBodyText>
                        We understand how important it is for you to access our exceptional Point of Sale (POS) system, and we're here to help resolve
                        this matter promptly.
                    </S.SubBodyText>
                </S.CenterText>
                <S.Gap size="40px"></S.Gap>
                <S.GreenBtn>Retry Payment</S.GreenBtn>
            </S.FailedPaymentText>
            <YellowBox label="or call us, Our friendly sales executive is just a phone call away! Dial +91 123456789." curved />
        </div>
    );
};

export default PaymentFail;
