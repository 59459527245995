import styled from "@emotion/styled/macro";

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    overflow: hidden;
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    height: 92px;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #333333;
`;
