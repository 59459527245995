import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { CloseModalIcon, DescriptionIcon } from "../../../icons";
import CustomButton from "../../product-drawer/custom-button/custom-button";

import * as S from "./purchase-summary-modal.styles";
import { useSelector, useDispatch } from "react-redux";
import { formatDate } from "../../../utils/format-date";
import { getPurchaseTotals } from "../../../store/purchase-slice";
import { PAYMENT, PURCHASE_PAYMENT_MODES, PURCHASE_TYPE } from "../../../constants/appConstants";
import { hasDecimal, toDecimalPlace } from "../../../utils/global-fn";
import { getTotalsPP } from "../../../store/purchaseproforma-slice";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { captureException } from "../../../crash-reporting";
import ToggleSwitch from "../../switch/Switch";

const PurchaseSummaryModal = ({
    open,
    onClose,
    onSubmit,
    handleAddPurchase,
    datas,
    type,
    selectedAmount,
    selectedPaymentOption,
    handleAddPurchaseProforma,
    handleConvertToPurchase,
    selectedTemplate,
    setSelectedTemplate,
}) => {
    const {
        selectedSupplier,
        purchaseQuantity,
        purchaseTotal,
        purchaseTaxTotal,
        purchaseProducts,
        discountValue,
        selectedPurchase,
        purchaseCartTotal,
    } = useSelector((state) => state.purchase);
    const {
        PP_selectedSupplier,
        PP_products,
        PP_createdDate,
        PP_deliveryDate,
        PP_taxTotal,
        PP_discountValue,
        PP_total,
        PP_globalDiscount,
        PP_deliveryExpense,
        PP_cartTotal,
        PP_type,
    } = useSelector((state) => state.purchaseProforma);
    const dispatch = useDispatch();

    const [changeDue, setChangeDue] = useState(null);
    const [invoiceRequired, _setInvoiceRequired] = useState(true);
    const invoiceRequiredRef = React.useRef(invoiceRequired);
    const setInvoiceRequired = (data) => {
        invoiceRequiredRef.current = data;
        _setInvoiceRequired(data);
    };
    const [printTemplates, setPrintTemplates] = useState(null);

    useEffect(() => {
        if (open) {
            if (type === "purchase") dispatch(getPurchaseTotals());
        }
    }, [purchaseProducts, open]);

    useEffect(() => {
        if (type === "purchase-proforma") dispatch(getTotalsPP());
        handleGetPrintTemplates();
    }, []);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
            if (type === "convert-to-purchase") {
                setChangeDue(selectedAmount - Math.round(selectedPurchase?.net_price));
            } else if (type === "purchase") {
                setChangeDue(selectedAmount - Math.round(purchaseCartTotal));
            }
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleGetPrintTemplates = async () => {
        try {
            const templates = await window.electronAPI.getPrintTemplates();
            if (templates) {
                setPrintTemplates(templates);
                if (templates && templates?.length) {
                    setSelectedTemplate(templates[0]);
                }
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleComplete();
            onClose();
        } else if (e.keyCode === 37) {
            //LEFT Arrow Key
            e.preventDefault();
            setInvoiceRequired(false);
        } else if (e.keyCode === 39) {
            //RIGHT Arrow key
            e.preventDefault();
            setInvoiceRequired(true);
        } else if (e.keyCode === 32) {
            //SPACE Key
            e.preventDefault();
            setInvoiceRequired(!invoiceRequiredRef.current);
        }
    };

    const handleComplete = () => {
        if (type === "purchase") {
            handleAddPurchase(invoiceRequiredRef.current);
        } else if (type === "purchase-proforma") {
            handleAddPurchaseProforma(invoiceRequiredRef.current);
        } else if (type === "convert-to-purchase") {
            handleConvertToPurchase(invoiceRequiredRef.current);
        }
    };

    const handlePrintSwitchChange = (e) => {
        const { checked } = e.target;
        setInvoiceRequired(checked);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    {type === "purchase-proforma" && PP_type === PURCHASE_TYPE.PURCHASE_PROFORMA
                        ? "Purchase Proforma"
                        : type === "purchase-proforma" && PP_type === PURCHASE_TYPE.PURCHASE_ORDER
                        ? "Purchase Order"
                        : "Purchase"}{" "}
                    Summary <img onClick={onClose} src={CloseModalIcon} alt="close" />
                </S.Header>
                <S.Body>
                    {selectedPaymentOption && changeDue > 0 && selectedPaymentOption !== PAYMENT.PAY_LATER && (
                        <S.DueHeadline>
                            Change due: <span>₱{toDecimalPlace(changeDue)}</span>
                        </S.DueHeadline>
                    )}
                    <S.Section>
                        {((["purchase", "convert-to-purchase"].includes(type) && selectedSupplier) ||
                            (type === "purchase-proforma" && PP_selectedSupplier)) && (
                            <>
                                <S.SectionTitle firstItem>Supplier Information</S.SectionTitle>
                                <S.SupplierDetail>
                                    <div className="supplier-name">
                                        {["purchase", "convert-to-purchase"].includes(type)
                                            ? selectedSupplier?.name
                                            : type === "purchase-proforma"
                                            ? PP_selectedSupplier?.name
                                            : ""}
                                    </div>
                                    <div className="supplier-mobile">
                                        {["purchase", "convert-to-purchase"].includes(type)
                                            ? selectedSupplier?.phone
                                            : type === "purchase-proforma"
                                            ? PP_selectedSupplier?.phone
                                            : ""}
                                    </div>
                                </S.SupplierDetail>
                            </>
                        )}
                    </S.Section>
                    {["purchase", "convert-to-purchase", "purchase-proforma"].includes(type) && (
                        <>
                            <S.Section>
                                <S.SectionTitle secondItem>Purchase Summary</S.SectionTitle>
                                <S.PriceDetails>
                                    <div>Total SKUs</div>
                                    <div>
                                        {type === "purchase"
                                            ? purchaseProducts?.length
                                            : type === "purchase-proforma"
                                            ? PP_products?.length
                                            : type === "convert-to-purchase"
                                            ? selectedPurchase?.products?.length
                                            : 0}
                                    </div>
                                </S.PriceDetails>
                                {type === "purchase" && datas.deliveryDate && (
                                    <S.PriceDetails>
                                        <div>Delivery Date</div>
                                        <div>{datas.deliveryDate && formatDate(datas.deliveryDate)}</div>
                                    </S.PriceDetails>
                                )}
                                {type === "purchase-proforma" && PP_deliveryDate && (
                                    <S.PriceDetails>
                                        <div>Delivery Date</div>
                                        <div>{PP_deliveryDate && formatDate(PP_deliveryDate)}</div>
                                    </S.PriceDetails>
                                )}
                                {type === "convert-to-purchase" && selectedPurchase?.delivery_date && (
                                    <S.PriceDetails>
                                        <div>Delivery Date</div>
                                        <div>{selectedPurchase?.delivery_date && formatDate(selectedPurchase?.delivery_date)}</div>
                                    </S.PriceDetails>
                                )}
                                <S.PriceDetails>
                                    <div>Purchase Date </div>
                                    {type === "purchase" && <div>{datas.createdAt ? formatDate(datas.createdAt) : formatDate(new Date())}</div>}
                                    {type === "purchase-proforma" && (
                                        <div>{PP_createdDate ? formatDate(PP_createdDate) : formatDate(new Date())}</div>
                                    )}
                                    {type === "convert-to-purchase" && <div>{formatDate(new Date())}</div>}
                                </S.PriceDetails>
                            </S.Section>
                            <S.Section>
                                <S.SectionTitle thirdItem>Pricing Summary</S.SectionTitle>
                                <S.PriceDetails>
                                    <div>Subtotal:</div>
                                    {type === "purchase" && <div>₱{toDecimalPlace(purchaseTotal - purchaseTaxTotal + discountValue)}</div>}
                                    {type === "purchase-proforma" && <div>₱{toDecimalPlace(PP_total - PP_taxTotal + PP_discountValue)}</div>}
                                    {type === "convert-to-purchase" && <div>₱{toDecimalPlace(selectedPurchase?.sub_total)}</div>}
                                </S.PriceDetails>{" "}
                                <S.PriceDetails>
                                    <div>Tax:</div>
                                    {type === "purchase" && <div>₱{toDecimalPlace(purchaseTaxTotal)}</div>}
                                    {type === "purchase-proforma" && <div>₱{toDecimalPlace(PP_taxTotal)}</div>}
                                    {type === "convert-to-purchase" && <div>₱{toDecimalPlace(selectedPurchase?.tax)}</div>}
                                </S.PriceDetails>{" "}
                                <S.PriceDetails>
                                    <div>Discount:</div>
                                    {type === "purchase" && <div>₱{toDecimalPlace(discountValue)}</div>}
                                    {type === "purchase-proforma" && <div>₱{toDecimalPlace(PP_discountValue)}</div>}
                                    {type === "convert-to-purchase" && <div>₱{toDecimalPlace(selectedPurchase?.discount)}</div>}
                                </S.PriceDetails>{" "}
                                <S.PriceDetails>
                                    <div>Cart Discount:</div>
                                    {type === "purchase" && <div>₱{datas.discount ? datas.discount : 0}</div>}
                                    {type === "purchase-proforma" && <div>₱{toDecimalPlace(PP_globalDiscount)}</div>}
                                    {type === "convert-to-purchase" && <div>₱{toDecimalPlace(selectedPurchase?.cart_discount)}</div>}
                                </S.PriceDetails>{" "}
                                <S.PriceDetails>
                                    <div>Delivery Expense:</div>
                                    {type === "purchase" && <div>₱{datas.deliveryExpense ? datas.deliveryExpense : 0}</div>}
                                    {type === "purchase-proforma" && <div>₱{toDecimalPlace(PP_deliveryExpense)}</div>}
                                    {type === "convert-to-purchase" && <div>₱{toDecimalPlace(selectedPurchase?.delivery_expense)}</div>}
                                </S.PriceDetails>
                                {type === "purchase" && hasDecimal(purchaseCartTotal) && (
                                    <S.PriceDetails>
                                        <div>Adjusted Cash:</div>
                                        {<div>₱{toDecimalPlace(Math.round(purchaseCartTotal) - purchaseCartTotal)}</div>}
                                    </S.PriceDetails>
                                )}
                                {type === "purchase-proforma" && hasDecimal(PP_cartTotal) && (
                                    <S.PriceDetails>
                                        <div>Adjusted Cash:</div>
                                        {<div>₱{toDecimalPlace(Math.round(PP_cartTotal) - PP_cartTotal)}</div>}
                                    </S.PriceDetails>
                                )}
                                {type === "convert-to-purchase" && hasDecimal(selectedPurchase?.net_price) && (
                                    <S.PriceDetails>
                                        <div>Adjusted Cash:</div>
                                        {<div>₱{toDecimalPlace(Math.round(selectedPurchase?.net_price) - selectedPurchase?.net_price)}</div>}
                                    </S.PriceDetails>
                                )}
                            </S.Section>

                            <S.TotalDetails>
                                <div>Purchase Total:</div>
                                {type === "purchase" && <div>₱{Math.round(purchaseCartTotal)}</div>}
                                {type === "purchase-proforma" && <div>₱{Math.round(PP_cartTotal)}</div>}
                                {type === "convert-to-purchase" && <div>₱{Math.round(selectedPurchase?.net_price)}</div>}
                            </S.TotalDetails>

                            <S.TemplateContainer>
                                {printTemplates?.map((t, idx) => (
                                    <S.TemplateBox key={idx} active={selectedTemplate?.name === t.name} onClick={() => setSelectedTemplate(t)}>
                                        <img src={t.preview ?? DescriptionIcon} alt="preview" />
                                        <S.TemplateText>{t.name}</S.TemplateText>
                                    </S.TemplateBox>
                                ))}
                            </S.TemplateContainer>
                        </>
                    )}

                    <S.BtnContainer>
                        <S.PrintSwitchContainer>
                            <S.PrintText>Print Invoice</S.PrintText>
                            <ToggleSwitch
                                type="invoice"
                                checked={invoiceRequired}
                                handleChange={(e, type) => handlePrintSwitchChange(e, type)}
                                antSwitch
                            />
                            <S.HintText>(Press space or arrow keys to change)</S.HintText>
                        </S.PrintSwitchContainer>

                        <CustomButton
                            type="primary"
                            size="md"
                            text="Complete Purchase"
                            onClick={() => {
                                handleComplete();
                                onClose();
                            }}
                        />
                    </S.BtnContainer>
                </S.Body>
            </S.Container>
        </Modal>
    );
};

export default PurchaseSummaryModal;
