import React from "react";
import { Snackbar } from "@mui/material";
import * as S from "./OfflineSnackbar.style";
import { CrossBlackIcon } from "../../icons";

const OfflineSnackbar = ({ onClose, data }) => {
    const handleCloseSnackbar = () => {
        onClose({
            show: false,
            title: "",
            subTitle: "",
            cta: "",
        });
    };
    return (
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={data.show} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <S.Container>
                <S.Body>
                    <S.HeadBar>
                        <S.Head>
                            {data.title}
                        </S.Head>
                        <img src={CrossBlackIcon} alt="close" onClick={handleCloseSnackbar} />
                    </S.HeadBar>
                    <S.SubHead>
                        {data.subTitle}
                    </S.SubHead>
                </S.Body>
                <S.BottomBar>
                    {/* <S.GreyBtn>Remind me later</S.GreyBtn> */}
                    <S.BlueBtn>{data.cta}</S.BlueBtn>
                </S.BottomBar>
            </S.Container>
        </Snackbar>
    );
};

export default OfflineSnackbar;
