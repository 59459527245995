import React from "react";
import { ONLINE_ORDER_PARAMS_STATUS } from "../../../constants/appConstants";
import { DocumentActiveIcon, DocumentIcon, TimeFillActiveIcon, TimeFillIcon } from "../../../icons";
import * as S from "./style";
import moment from "moment";

const Order = ({ item, activeOrderRow, idx, handleOnlineOrderClick, scrollRef }) => {
    return (
        <S.Wrapper onClick={() => handleOnlineOrderClick(item, idx)} active={idx === activeOrderRow} ref={idx === activeOrderRow ? scrollRef : null}>
            <S.TopWrapper>
                <S.SideBar
                    active={idx === activeOrderRow}
                    packing={item.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING}
                    cancelled={item.online?.status === ONLINE_ORDER_PARAMS_STATUS.CANCELLED}
                ></S.SideBar>
                <S.TopContainer>
                    <S.TopBar>
                        {/* {item.delivery_partner && item.time_to_pickup >= Date.now() ? (
                            <S.TimeContainer>
                                <img
                                    src={idx === activeOrderRow ? TimeFillActiveIcon : TimeFillIcon}
                                    alt="time"
                                />
                                <div>Pickup</div>
                                <S.Time active={idx === activeOrderRow}>
                                    {moment(item.time_to_pickup).fromNow()}
                                </S.Time>
                            </S.TimeContainer>
                        ) : item.delivery_partner && item.time_to_pickup < Date.now() ? (
                            <S.TimeContainer>
                                <img
                                    src={idx === activeOrderRow ? TimeFillActiveIcon : TimeFillIcon}
                                    alt="time"
                                />
                                <div>Pickup is delayed</div>
                            </S.TimeContainer>
                        ) : (
                            <S.TimeContainer>
                                <img
                                    src={idx === activeOrderRow ? TimeFillActiveIcon : TimeFillIcon}
                                    alt="time"
                                />
                                <div>Pickup not assigned</div>
                            </S.TimeContainer>
                        )} */}
                        <S.TimeContainer>
                            <img src={idx === activeOrderRow ? TimeFillActiveIcon : TimeFillIcon} alt="time" />
                            <div>Created at</div>
                            <S.Time active={idx === activeOrderRow}>{moment(item.created_at).format("lll")}</S.Time>
                        </S.TimeContainer>
                        <S.Price active={idx === activeOrderRow}>₱{item.net_amount}</S.Price>
                    </S.TopBar>
                    <S.MiddleContainer>
                        <S.SkuDiv active={idx === activeOrderRow}>
                            SKU: <span>{item.products?.length}</span>
                        </S.SkuDiv>
                    </S.MiddleContainer>
                    <S.TopBar>
                        {/* <S.OrderIdContainer>
                            <img
                                src={idx === activeOrderRow ? DocumentActiveIcon : DocumentIcon}
                                alt="order id"
                            />
                            #{item.id}
                        </S.OrderIdContainer> */}
                        {item.online?.status === ONLINE_ORDER_PARAMS_STATUS.PACKING ? (
                            <S.PackingStatus>
                                Status: <span>Packing</span>
                            </S.PackingStatus>
                        ) : item.online?.status === ONLINE_ORDER_PARAMS_STATUS.DELIVERED ? (
                            <S.PackingStatus>
                                Status: <span>Delivered</span>
                            </S.PackingStatus>
                        ) : item.online?.status === ONLINE_ORDER_PARAMS_STATUS.CANCELLED ? (
                            <S.PackingStatus cancelled={item.online?.status === ONLINE_ORDER_PARAMS_STATUS.CANCELLED}>
                                Status: <span>Cancelled</span>
                            </S.PackingStatus>
                        ) : (
                            <S.DeliveryContainer>
                                {item.delivery_partner ? (
                                    <>
                                        <div>Pickup by:</div>
                                        <S.DelExName active={idx === activeOrderRow}>{item.delivery_partner.name}</S.DelExName>
                                    </>
                                ) : (
                                    <div>Pickup not assigned</div>
                                )}
                            </S.DeliveryContainer>
                        )}
                    </S.TopBar>
                </S.TopContainer>
            </S.TopWrapper>

            {/* <S.BottomContainer>Terminal: 1</S.BottomContainer> */}
        </S.Wrapper>
    );
};

export default Order;
