import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;

    min-width: 502px;
`;

export const Header = styled.div`
    height: 48px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    padding: 0 16px;
    align-items: center;
    img {
        height: 21px;
        width: 21px;
        cursor: pointer;
    }
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 17.5178px;
    line-height: 25px;
    color: #334155;
`;

export const Body = styled.div`
    //   padding: 35px 22.5px 0;
    padding: 17px 26px 48px;
    position: relative;

    .middle-text {
        margin: 14px 0;
        text-align: center;
        font-weight: 400;
        font-size: 14.1176px;
        line-height: 18px;
        color: #475569;
    }
`;
export const AddBtn = styled.div`
    font-weight: 500;
    font-size: 15.8824px;
    line-height: 19px;
    color: #ffffff;
    background: #3b82f6;
    border-radius: 4px;
    // padding: 16px;
    height: 42px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    //   margin-bottom: 72px;
`;
