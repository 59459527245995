import React from "react";
import * as S from "./style";
import { LimitReachIcon } from "../../../icons";

const LimitWarn = ({ toLoginState }) => {
    return (
        <S.Wrapper>
            <S.Image src={LimitReachIcon} alt="limit reached" />
            <S.Heads>
                <S.Head>Oops, Device Limit Reached!</S.Head>
                <S.Head>Log out from other Devices to Continue</S.Head>
            </S.Heads>
            <S.SubHead>
                Homedrop is currently logged in on 2 other devices. Click 'Continue Here' to seamlessly log out from the oldest device and continue
                here on this computer
            </S.SubHead>
            <S.BlackBtn onClick={toLoginState}>Continue on this Device</S.BlackBtn>
        </S.Wrapper>
    );
};

export default LimitWarn;
