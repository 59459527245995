export const STORE_LOGIN_API = "/auth";
export const STORE_REGISTER_API = "/auth/register";
export const SEND_OTP_API = "/auth/send-otp";
export const VERIFY_OTP_API = "/auth/verify-otp ";
export const ACCOUNT_CHECK_API = "/auth/check-account-exists";
export const APP_CLIENT = "/app-client";

export const AUTH_API = "/auth";
export const EMPLOYEES_API = "/employees";
export const BRANDS_API = "/inventory-brands";
export const CATEGORIES_API = "/inventory-categories";
export const CUSTOMERS_API = "/patients";
export const PRODUCTS_API = "/inventory";
export const PRODUCTS_BY_SKU_API = "/products-by-sku";
export const STORE_API = "/auth/account";
export const STORE_BACKUP_API = "/store/backup";
export const SUPPLIERS_API = "/suppliers";
export const PURCHASE_API = "/purchases";
export const GLOBAL_SEARCH_API = "/search";
export const PERMISSION_ROLE_API = "/permissions";
export const EMPLOYEES_CONFIG_API = "/employees-config";
export const ADVANCE_TRANSACTIONS_API = "transactions";
export const FILE_UPLOAD_API = "upload";
export const QUICK_LOGIN_API = "quick-logins";
export const PURCHASES_COUNT = "/purchases-count";
export const SALE_API = "/invoices";
export const SALE_RECEIPT_API = "sales/send-receipt";
export const SALES_COUNT_API = "/invoices-count";
export const PRODUCTS_COUNT_API = "/products-count";
export const TRANSACTIONS_COUNT = "transactions-count";
export const TRANSACTIONS_CLEAR = "transactions-clear";
export const SALES_TOTALS_API = "/sales-totals";
export const ONLINE_SALE_API = "/online-sale";
export const START_FULFILLMENT = "start-fulfillment";
export const COMPLETE_FULFILLMENT = "complete-fulfillment";
export const CANCEL_FULFILLMENT = "cancel-fulfillment";
export const START_DELIVERY = "start-delivery";
export const COMPLETE_DELIVERY = "complete-delivery";
export const EXPENSES_API = "/expenses";
export const ONBOARDING_API = "/onboarding";
export const ANALYTICS_API = "/analytics";
export const PROFIT_LOSS_API = "profit-loss";
export const OVERALL_DATA_API = "overall";
export const CUSTOMER_DATA_API = "customer";
export const PRODUCT_DATA_API = "product";
export const REPORTS_API = "/reports";
export const SALE_REPORT_API = "sales";
export const PURCHASE_REPORT_API = "purchases";
export const EXPENSE_REPORT_API = "expenses";
export const INVENTORY_REPORT_API = "inventory";
export const ALL_REPORT_API = "all";
export const CHANGE_PASSWORD_API = "change-password";
export const EMPLOYEE = "/employee";
export const CASHDRAWER_API = "/cashdrawer";
export const CASHDRAWER_COUNTS_API = "/cashdrawer-counts";
export const GST_API = "/gst";
export const PRODUCT_ATTRIBUTES = "/product-attributes";
export const PAYMENTS = "payments";
