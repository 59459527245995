import React, { useState } from "react";
import { DateRange } from "react-date-range";
import * as S from "../style/style";
import { addDays } from "date-fns";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIconSolid, FilterList, TimeChevronRight } from "../../../icons";
import { TIME_PERIOD } from "../../../constants/appConstants";
import { Line } from "react-chartjs-3";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};
const initialData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
        {
            label: "Sale",
            data: [12, 8, 15, 2, 24, 19],
            borderColor: "#0564D0",
            fill: false,
            tension: 0,
        },
        {
            label: "Sale Return",
            data: [8, 19, 12, 16, 2, 15],
            borderColor: "#620E7C",
            fill: false,
            tension: 0,
        },
        {
            label: "Expenses",
            data: [6, 2, 19, 14, 26, 9],
            borderColor: "#DF2172",
            fill: false,
            tension: 0,
        },
    ],
};

const ViewTrends = () => {
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [period, setPeriod] = useState(null);
    const [trendsData, setTrendsData] = useState(initialData);

    return (
        <div>
            <S.Heading>View Trends</S.Heading>
            <S.Container>
                <S.FilterBar>
                    <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                        <S.DateChooserSelect
                            onClick={() => setOpenDatePicker(openDatePicker ? false : true)}
                        >
                            <img src={CalendarIconSolid} alt="calendar" />
                            <div>Select time period</div>
                        </S.DateChooserSelect>
                        {openDatePicker && (
                            <S.DatePickerContainer>
                                <DateRange
                                    editableDateInputs={true}
                                    // onChange={handleDateChange}
                                    ranges={dateRange}
                                    maxDate={addDays(new Date(), 0)}
                                />
                            </S.DatePickerContainer>
                        )}
                    </OutsideClickHandler>
                    <S.TimePeriodContainer>
                        <S.TimePeriod onClick={() => setOpenMenu(!openMenu)}>
                            <div>Time Period</div>
                            <img src={FilterList} alt="filter list" />
                        </S.TimePeriod>
                        {openMenu && (
                            <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                                <S.PeriodMenu>
                                    {TIME_PERIOD?.map((item) => (
                                        <S.PeriodRow
                                            onClick={() => {
                                                setPeriod(item.value);
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <div>{item.name}</div>
                                            <img src={TimeChevronRight} alt="right arrow" />
                                        </S.PeriodRow>
                                    ))}
                                </S.PeriodMenu>
                            </OutsideClickHandler>
                        )}
                    </S.TimePeriodContainer>
                    <S.TimePeriodContainer>
                        <S.TimePeriod onClick={() => setOpenMenu(!openMenu)}>
                            <div>Time Period</div>
                            <img src={FilterList} alt="filter list" />
                        </S.TimePeriod>
                        {openMenu && (
                            <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                                <S.PeriodMenu>
                                    {TIME_PERIOD?.map((item) => (
                                        <S.PeriodRow
                                            onClick={() => {
                                                setPeriod(item.value);
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <div>{item.name}</div>
                                            <img src={TimeChevronRight} alt="right arrow" />
                                        </S.PeriodRow>
                                    ))}
                                </S.PeriodMenu>
                            </OutsideClickHandler>
                        )}
                    </S.TimePeriodContainer>
                </S.FilterBar>
                <S.DataContainer>
                    <div>
                        <S.DataLabel>Total Sale</S.DataLabel>
                        <S.DataDiv>5.987,37</S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Sale Return</S.DataLabel>
                        <S.DataDiv>5.987,37</S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Expenses</S.DataLabel>
                        <S.DataDiv>5.987,37</S.DataDiv>
                    </div>
                </S.DataContainer>
                <S.ChartContainer>
                    <Line
                        data={trendsData}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                            legend: {
                                position: "bottom",
                                align: "start",
                            },
                        }}
                    />
                </S.ChartContainer>
            </S.Container>
        </div>
    );
};

export default ViewTrends;
