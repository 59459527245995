import React, { useContext, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as S from "../style/style";
import { addDays } from "date-fns";
import OutsideClickHandler from "react-outside-click-handler";
import { CalendarIconSolid, DownloadIcon, FilterList, TimeChevronRight } from "../../../icons";
import { TIME_PERIOD } from "../../../constants/appConstants";
import { Line, Bar } from "react-chartjs-3";
import moment from "moment";
import { formatDate } from "../../../utils/format-date";
import { getOverallData } from "../../../api/local/analytics-api";
import DownloadButton from "../../download-button/DownloadButton";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { captureException } from "../../../crash-reporting";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};
const initialChartData = {
    labels: [],
    datasets: [
        {
            label: "Sale",
            data: [],
            borderColor: "#63ABFD",
            fill: false,
            tension: 0,
        },
        {
            label: "Purchase",
            data: [],
            borderColor: "#E697FF",
            fill: false,
            tension: 0,
        },
        {
            label: "Profit",
            data: [],
            borderColor: "#FFA5CB",
            fill: false,
            tension: 0,
        },
    ],
};

const initialParams = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
};
const OverallReport = () => {
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [period, setPeriod] = useState(null);
    const [overallChartData, setOverallChartData] = useState(initialChartData);
    const [totalDatas, setTotalDatas] = useState(null);
    const [params, setParams] = useState(initialParams);
    const [displayDate, setDisplayDate] = useState(`${formatDate(initialParams.start_date)} - ${formatDate(initialParams.end_date)}`);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        handleGetOverallData();
    }, [params]);

    const handleDateChange = (item) => {
        let startDate = new Date(item.selection.startDate).getTime();
        let endDate = new Date(item.selection.endDate).getTime();
        if (endDate > Date.now()) {
            endDate = Date.now();
            setDateRange([{ ...item.selection, endDate: new Date(Date.now()) }]);
        } else {
            setDateRange([item.selection]);
        }
        setDisplayDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        setParams({ start_date: startDate, end_date: endDate });
        if (startDate !== endDate) {
            setOpenDatePicker(false);
        }
    };

    const handleGetOverallData = async () => {
        try {
            let res = await getOverallData(params);
            if (res.status === 200) {
                setTotalDatas(res.data);

                //for chart data
                let graphData = res.data.graph;
                const chartData = {
                    labels: graphData.sale.map((item) => item.t),
                    datasets: [
                        {
                            label: "Sale",
                            data: graphData.sale.map((item) => item.value),
                            borderColor: "#63ABFD",
                            extraData: graphData.sale.map((item) => ({ count: item.count })),
                            fill: false,
                            tension: 0,
                        },
                        {
                            label: "Purchase",
                            data: graphData.purchase.map((item) => item.value),
                            borderColor: "#E697FF",
                            extraData: graphData.purchase.map((item) => ({ count: item.count })),
                            fill: false,
                            tension: 0,
                        },
                        {
                            label: "Profit",
                            data: graphData.profit.map((item) => item.value),
                            borderColor: "#FFA5CB",
                            extraData: graphData.profit.map((item) => ({ count: item.count })),
                            fill: false,
                            tension: 0,
                        },
                    ],
                };
                setOverallChartData(chartData);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <div>
            <S.Heading>
                Overall Report
            </S.Heading>
            <S.Container>
                <S.FilterBar>
                    <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                        <S.dateContainer>
                            <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                <img src={CalendarIconSolid} alt="calendar" />
                                <div>{displayDate}</div>
                            </S.DateChooserSelect>
                            {openDatePicker && (
                                <S.DatePickerContainer>
                                    <DateRangePicker
                                        editableDateInputs={true}
                                        onChange={handleDateChange}
                                        ranges={dateRange}
                                        maxDate={addDays(new Date(), 0)}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </S.dateContainer>
                    </OutsideClickHandler>
                    {/* <S.TimePeriodContainer>
                        <S.TimePeriod onClick={() => setOpenMenu(!openMenu)}>
                            <div>Time Period</div>
                            <img src={FilterList} alt="filter list" />
                        </S.TimePeriod>
                        {openMenu && (
                            <OutsideClickHandler
                                onOutsideClick={() => setOpenMenu(false)}
                            >
                                <S.PeriodMenu>
                                    {TIME_PERIOD?.map((item) => (
                                        <S.PeriodRow
                                            onClick={() => {
                                                setPeriod(item.value);
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <div>{item.name}</div>
                                            <img
                                                src={TimeChevronRight}
                                                alt="right arrow"
                                            />
                                        </S.PeriodRow>
                                    ))}
                                </S.PeriodMenu>
                            </OutsideClickHandler>
                        )}
                    </S.TimePeriodContainer> */}
                </S.FilterBar>
                <S.DataContainer>
                    <div>
                        <S.DataLabel>Total Profit Amount</S.DataLabel>
                        <S.DataDiv>₱{totalDatas?.total_profit_amount.toLocaleString("en-IN")}</S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Sale Amount</S.DataLabel>
                        <S.DataDiv>₱{totalDatas?.total_sale_amount.toLocaleString("en-IN")}</S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Purchase Amount</S.DataLabel>
                        <S.DataDiv>₱{totalDatas?.total_purchase_amount.toLocaleString("en-IN")}</S.DataDiv>
                    </div>
                </S.DataContainer>
                {overallChartData.datasets.map((item) => item.data.length).reduce((a, b) => a + b) > 0 ? (
                    <S.ChartContainer>
                        <Line
                            data={overallChartData}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                    },
                                },
                                legend: {
                                    position: "bottom",
                                    align: "start",
                                },
                                tooltips: {
                                    intersect: true,
                                    callbacks: {
                                        label: (tooltipItem, data) => {
                                            const { datasetIndex, index } = tooltipItem;
                                            const label = data.datasets[datasetIndex].label || "";
                                            const value = data.datasets[datasetIndex].data[index];
                                            const extraData = data.datasets[datasetIndex].extraData[index];
                                            const count = extraData.count;
                                            return `${label}: ${value} ${count !== undefined ? `, Count: ${count}` : ""}`;
                                        },
                                    },
                                },
                            }}
                        />
                    </S.ChartContainer>
                ) : (
                    <S.EmptyContainer>
                        <S.EmptyText>This Data Is Not Available</S.EmptyText>
                    </S.EmptyContainer>
                )}
            </S.Container>
        </div>
    );
};

export default OverallReport;
