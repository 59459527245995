import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 421px;
    height: 64px;
    background: #ffffff;
    border-left: 4px solid #2d68fe;
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px 16px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 33px;
    margin-right: -7px;
`;
export const Message = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #334155;
`;
export const Subtitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
`;
