import React from "react";
import * as S from "./style";
import CustomButton from "../custom-button/CustomButton";
import { COLOR } from "../../constants/appConstants";
import { BlueArrowRight, SelectedProductChipClose, WhiteArrowRight } from "../../icons";

const CartBottomBar = ({ data, expand, onProceed, onExpand, onDataRemove }) => {
    return (
        <S.Wrapper>
            {data && expand ? (
                <S.DataBox>
                    <div>{data?.name}</div>
                    <div>{data?.phone ? `(${data?.phone})` : ""}</div>
                    <img src={SelectedProductChipClose} alt="clear" onClick={onDataRemove} />
                </S.DataBox>
            ) : (
                <div></div>
            )}
            {expand ? (
                <CustomButton label="Proceed (F12)" color={COLOR.BLUE} fill={true} icon={WhiteArrowRight} iconRight={true} onClick={onProceed} />
            ) : (
                <CustomButton label="Expand (F2)" color={COLOR.BLUE} icon={BlueArrowRight} iconRight={true} onClick={onExpand} />
            )}
        </S.Wrapper>
    );
};

export default CartBottomBar;
