import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 508px;
`;

export const Heading = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #1e293b;
`;

export const SubHeading = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;

export const Top = styled.div`
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 22px 19px;

    & > div {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    & label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #253238;
        cursor: pointer;
        margin-bottom: 2px;
        width: 100%;
    }

    & input {
        cursor: pointer;
    }
`;
export const ConfirmBtn = styled.div`
    background: #2d68fe;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
`;
export const Bottom = styled.div`
    box-sizing: border-box;
    padding: 18px;
    background: #f7f7f9;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
