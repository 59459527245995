import React from "react";
import * as S from "./style";
import { DiscountOption } from "../../../constants/appConstants";

const CartDiscount = ({ activeDiscount, setActiveDiscount, discount, onChange, ref, disabled }) => {
    return (
        <S.Wrapper>
            <S.SymbolDiv>
                <S.Symbol active={activeDiscount === DiscountOption.PERCENTAGE} onClick={() => setActiveDiscount(DiscountOption.PERCENTAGE)}>
                    %
                </S.Symbol>
                <S.MiddleBar></S.MiddleBar>
                <S.Symbol active={activeDiscount === DiscountOption.FLAT} onClick={() => setActiveDiscount(DiscountOption.FLAT)}>
                    ₱
                </S.Symbol>
            </S.SymbolDiv>
            <input type="text" value={discount} onChange={(e) => onChange(e)} disabled={disabled} ref={ref} />
        </S.Wrapper>
    );
};

export default CartDiscount;
