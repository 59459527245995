import React, { useContext, useState } from "react";
import * as S from "./DownloadReports.style";
import DownloadButton from "../../components/download-button/DownloadButton";
import OutsideClickHandler from "react-outside-click-handler";
import { AnalyticsExpensesIcon, CalendarIconSolid, DownloadIcon, XlsxIcon } from "../../icons";
import { formatDate } from "../../utils/format-date";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import {
    DOWNLOAD_REPORTS,
    DOWNLOAD_REPORTS_LEFT,
    DOWNLOAD_REPORTS_RIGHT,
    DOWNLOAD_REPORT_KEYS,
    REPORTS_TYPE,
    REPORT_TYPES,
} from "../../constants/appConstants";
import {
    generateAllReport,
    generateExpenseReport,
    generateInventoryReport,
    generatePurchaseReport,
    generateSaleReport,
    generateGSTR1Report,
    generateGSTR2Report,
    generateGSTR3BReport,
    generateGSTR9Report,
} from "../../api/local/reports-api";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { captureException } from "../../crash-reporting";
import { useSelector } from "react-redux";
import TimeModal from "./time-modal/TimeModal";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};

const initialParams = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
};

const initialTimeModalConfig = {
    open: false,
    type: "",
    key: "",
    submitFn: () => {},
};
const DownloadReports = () => {
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [displayDate, setDisplayDate] = useState(`${formatDate(initialParams.start_date)} - ${formatDate(initialParams.end_date)}`);
    const [params, setParams] = useState(initialParams);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const store = useSelector((state) => state.session.store);
    const [openTimeModal, setOpenTimeModal] = useState(initialTimeModalConfig);

    const handleDateChange = (item) => {
        let startDate = new Date(item.selection.startDate).getTime();
        let endDate = new Date(item.selection.endDate).getTime();
        if (endDate > Date.now()) {
            endDate = Date.now();
            setDateRange([{ ...item.selection, endDate: new Date(Date.now()) }]);
        } else {
            setDateRange([item.selection]);
        }
        setDisplayDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        setParams({ start_date: startDate, end_date: endDate });
        if (startDate !== endDate) {
            setOpenDatePicker(false);
        }
    };

    const handleDownloadReport = (key) => {
        // handleSnackbarDetails({
        //     show: true,
        //     title: "Successful",
        //     type: "success",
        //     subtitle: "Your report is downloading, and we will notify you once it is downloaded",
        // });
        if (key === DOWNLOAD_REPORT_KEYS.SALES) {
            handleDownloadSalesReport({ ...params, type: REPORT_TYPES.SALE }, REPORTS_TYPE.SALE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.SALES_RETURN) {
            handleDownloadSalesReport({ ...params, type: REPORT_TYPES.RETURN }, REPORTS_TYPE.SALE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.PURCHASE) {
            handleDownloadPurchaseReport({ ...params, type: REPORT_TYPES.PURCHASE }, REPORTS_TYPE.PURCHASE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.PURCHASE_RETURN) {
            handleDownloadPurchaseReport({ ...params, type: REPORT_TYPES.RETURN }, REPORTS_TYPE.PURCHASE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.DAMAGE) {
            handleDownloadPurchaseReport({ ...params, type: REPORT_TYPES.DAMAGE_ENTRY }, REPORTS_TYPE.PURCHASE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.EXPENSES) {
            handleDownloadExpenseReport({ ...params }, REPORTS_TYPE.EXPENSE_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.INVENTORY) {
            handleDownloadInventoryReport({ ...params }, REPORTS_TYPE.INVENTORY_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.GSTR1) {
            setOpenTimeModal({
                open: true,
                type: "month",
                key: key,
                submitFn: (param) => handleDownloadGSTR1Report({ ...param }, REPORTS_TYPE.GSTR1_REPORT),
            });
        } else if (key === DOWNLOAD_REPORT_KEYS.GSTR2) {
            setOpenTimeModal({
                open: true,
                type: "month",
                key: key,
                submitFn: (param) => handleDownloadGSTR2Report({ ...param }, REPORTS_TYPE.GSTR2_REPORT),
            });
        } else if (key === DOWNLOAD_REPORT_KEYS.GSTR3B) {
            setOpenTimeModal({
                open: true,
                type: "month",
                key: key,
                submitFn: (param) => handleDownloadGSTR3BReport({ ...param }, REPORTS_TYPE.GSTR3B_REPORT),
            });
        } else if (key === DOWNLOAD_REPORT_KEYS.GSTR9) {
            setOpenTimeModal({
                open: true,
                type: "year",
                key: key,
                submitFn: (param) => handleDownloadGSTR9Report({ ...param }, REPORTS_TYPE.GSTR9_REPORT),
            });
        } else if (key === DOWNLOAD_REPORT_KEYS.ALL) {
            handleDownloadAllReport({ ...params }, REPORTS_TYPE.ALL_REPORT);
        } else if (key === DOWNLOAD_REPORT_KEYS.EXCISE) {
            // excise report download function @gaurav
        }
    };

    const handleSaveReport = (key, data, isReturn = false, isDamage = false) => {
        window.electronAPI.saveReport({ type: key, data: data, return: isReturn, damage: isDamage });
    };

    const handleDownloadSalesReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your sale report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateSaleReport(params);
            if (res.status === 200) {
                if (params.type === REPORT_TYPES.RETURN) {
                    handleSaveReport(key, res.data, true);
                } else {
                    handleSaveReport(key, res.data);
                }
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadPurchaseReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your purchase report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generatePurchaseReport(params);
            if (res.status === 200) {
                if (params.type === REPORT_TYPES.RETURN) {
                    handleSaveReport(key, res.data, true);
                } else if (params.type === REPORT_TYPES.DAMAGE_ENTRY) {
                    handleSaveReport(key, res.data, false, true);
                } else {
                    handleSaveReport(key, res.data);
                }
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadExpenseReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your exepense report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateExpenseReport(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadInventoryReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your inventory report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateInventoryReport(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadGSTR1Report = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your GSTR1 report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateGSTR1Report(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadGSTR2Report = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your GSTR2 report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateGSTR2Report(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadGSTR3BReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your GSTR3 report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateGSTR3BReport(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadGSTR9Report = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your GSTR9 report is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateGSTR9Report(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleDownloadAllReport = async (params, key) => {
        handleSnackbarDetails({
            show: true,
            title: "Successful",
            type: "success",
            subtitle: "Your all reports is downloading, and we will notify you once it is downloaded",
        });
        try {
            let res = await generateAllReport(params);
            if (res.status === 200) {
                handleSaveReport(key, res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    return (
        <>
            <TimeModal
                open={openTimeModal.open}
                type={openTimeModal.type}
                name={openTimeModal.key}
                onSubmit={(param) => openTimeModal.submitFn(param)}
                onClose={() => setOpenTimeModal(initialTimeModalConfig)}
            />
            <S.Wrapper>
                <S.Topbar>
                    <S.Heading>Download Reports</S.Heading>
                    <DownloadButton text="Download All" icon={DownloadIcon} onClick={() => handleDownloadReport(DOWNLOAD_REPORT_KEYS.ALL)} />
                </S.Topbar>
                <S.Body>
                    <S.FilterBar>
                        <S.FilterDiv>
                            <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                                <S.dateContainer>
                                    <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                        <img src={CalendarIconSolid} alt="calendar" />
                                        <div>{displayDate}</div>
                                    </S.DateChooserSelect>
                                    {openDatePicker && (
                                        <S.DatePickerContainer>
                                            <DateRangePicker
                                                editableDateInputs={true}
                                                onChange={handleDateChange}
                                                ranges={dateRange}
                                                maxDate={addDays(new Date(), 0)}
                                            />
                                        </S.DatePickerContainer>
                                    )}
                                </S.dateContainer>
                            </OutsideClickHandler>
                        </S.FilterDiv>
                    </S.FilterBar>
                    <S.ReportContainer>
                        <S.SubContainer border>
                            {DOWNLOAD_REPORTS_LEFT.map((item, idx) => (
                                <S.ReportRow key={idx}>
                                    <S.NameContainer>
                                        <img src={item.icon} alt="icon" />
                                        <div>{item.name}</div>
                                    </S.NameContainer>
                                    <DownloadButton text="Download" icon={XlsxIcon} onClick={() => handleDownloadReport(item.key)} />
                                </S.ReportRow>
                            ))}
                            {/* {store?.store_type?.id === 4 && (
                            <S.ReportRow>
                                <S.NameContainer>
                                    <img src={AnalyticsExpensesIcon} alt="icon" />
                                    <div>Excise Report</div>
                                </S.NameContainer>
                                <DownloadButton text="Download" icon={XlsxIcon} onClick={() => handleDownloadReport(DOWNLOAD_REPORT_KEYS.EXCISE)} />
                            </S.ReportRow>
                        )} */}
                        </S.SubContainer>
                        <S.SubContainer>
                            {DOWNLOAD_REPORTS_RIGHT.map((item, idx) => (
                                <S.ReportRow key={idx}>
                                    <S.NameContainer>
                                        <img src={item.icon} alt="icon" />
                                        <div>{item.name}</div>
                                    </S.NameContainer>
                                    <DownloadButton text="Download" icon={XlsxIcon} onClick={() => handleDownloadReport(item.key)} />
                                </S.ReportRow>
                            ))}
                        </S.SubContainer>
                    </S.ReportContainer>
                </S.Body>
            </S.Wrapper>
        </>
    );
};

export default DownloadReports;
