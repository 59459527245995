import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    // height: 400px;
    box-sizing: border-box;
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 15.9486px;
    line-height: 22px;
    color: #334155;
    border-bottom: 1px solid #e2e8f0;
    padding: 15px 19px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 16px;
`;

export const Body = styled.div`
    height: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    justify-content: center;
    gap: 24px;
`;

export const BodyContent = styled.div`
    width: 40%;
`;
