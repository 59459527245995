import React from "react";
import * as S from "./style";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../utils/format-date";

const CustomIconInput = ({ onClick, onChange, value, inputRef, icon, className, datePicker, onDateChange, minDate }) => {
    return (
        <S.Wrapper onClick={onClick} className={className} ref={datePicker && inputRef}>
            {datePicker ? (
                <DatePicker
                    selected={Date.parse(calendarFormatDate(value))}
                    onChange={(date) => onDateChange(date)}
                    dateFormat="dd/MM/yyyy"
                    className="summary-calendar"
                    minDate={minDate || new Date()}
                />
            ) : (
                <input type="text" onChange={(event) => onChange(event)} value={value} ref={inputRef} />
            )}

            <S.Line></S.Line>
            <img src={icon} alt="input-icon" />
        </S.Wrapper>
    );
};

export default CustomIconInput;
