import styled from "@emotion/styled/macro";

export const Container = styled.div`
    height: calc(100vh-56px);
    width: 100%;
`;

export const Header = styled.div`
    margin: 16px 16px 0;
`;

export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderDetails = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0 12px;
`;

export const DateContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
`;
export const Date = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
`;

export const BtnContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const HeadContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Headline = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: ${(props) => (props.active ? "#1e293b" : "#94a3b8")};
    cursor: pointer;
`;

export const Body = styled.div`
    height: calc(100vh - 178px);
    display: flex;
    gap: 16px;
    padding: 0 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
`;

export const BottomBar = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    background: white;
    padding: 11px 16px;
    justify-content: space-between;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    z-index: 1;
    box-sizing: border-box;
`;
export const Back = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
    }
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #334155;
    height: 100%;
    width: 100%;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
`;
