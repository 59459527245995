import { debounce } from "lodash";
import React, { useCallback, useEffect, useState, useImperativeHandle } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeesConfig } from "../../api/local/employee-api";
import AddNewEmp from "../../components/EmployeesComponents/add-new-employee/AddNewEmp";
import CustomSlider from "../../components/EmployeesComponents/custom-range-slider/CustomSlider";
import EmployeeTable from "../../components/EmployeesComponents/EmployeeTable/EmployeeTable";
import CustomSelect from "../../components/product-drawer/custom-select/custom-select";
import SelectedChips from "../../components/selected-chips/selected-chips";
import { EMPLOYEE_SORT_LIST, EMPLOYEE_STATUS } from "../../constants/appConstants";
import { AddCustomerSearchIcon, CloseModalIcon } from "../../icons";
import { fetchAllEmployees, removeAllSearchEmpParams, removeSearchEmpParams, setSearchEmpParams } from "../../store/employee-slice";
import { fetchAllPermissions } from "../../store/permissionrole-slice";
import * as S from "./employee.style";
import { captureException } from "../../crash-reporting";

const initalFilterData = {
    role: {},
    sort: {},
    employee: {},
    status: {},
    monthly_salary: "",
    advance_salary: "",
};

const Index = (props, ref) => {
    // return <Employees />;
    const [openNewEmpModal, setOpenNewEmpModal] = useState(false);
    const dispatch = useDispatch();
    const { searchEmpParams } = useSelector((state) => state.employee);
    const { roles } = useSelector((state) => state.permissionRole);
    const [employeeSearch, setEmployeeSearch] = useState("");
    const [employeeConfigData, setEmployeeConfigData] = useState(null);
    const [sortText, setSortText] = useState("Name (A-Z)");
    const [filterData, setFilterData] = useState(initalFilterData);
    const nameRef = useRef();
    const roleRef = useRef();
    const statusRef = useRef();
    const advRef = useRef();
    const salaryRef = useRef();

    useEffect(() => {
        dispatch(fetchAllPermissions());
        getEmployeesConfigData();

        return () => {
            dispatch(removeAllSearchEmpParams());
        };
    }, []);

    useImperativeHandle(ref, () => {
        return {
            searchName: () => nameRef.current?.focus(),
            searchRole: () => roleRef.current?.click(),
            searchStatus: () => statusRef.current?.click(),
            searchAdvSalary: () => advRef.current?.click(),
            searchMonthlySalary: () => salaryRef.current?.click(),
            clearFilter: () => handleClearFilter(),
        };
    });

    const handleClearFilter = () => {
        setFilterData(initalFilterData);
        dispatch(removeSearchEmpParams("permission_role"));
        dispatch(removeSearchEmpParams("sort_by"));
        dispatch(removeSearchEmpParams("is_reversed"));
        dispatch(removeSearchEmpParams("is_activated"));
        dispatch(removeSearchEmpParams("monthly_salary_min"));
        dispatch(removeSearchEmpParams("monthly_salary_max"));
        dispatch(removeSearchEmpParams("advance_salary_min"));
        dispatch(removeSearchEmpParams("advance_salary_max"));
    };
    async function getEmployeesConfigData() {
        try {
            let res = await getAllEmployeesConfig();
            if (res.status === 200) {
                setEmployeeConfigData(res.data);
            } else {
                setEmployeeConfigData(null);
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    }

    // for salary advance slider
    const delayedSAFunc = (min, max) => {
        // console.log("salary advance", min, max);
        dispatch(
            setSearchEmpParams({
                advance_salary_min: min,
                advance_salary_max: max,
            })
        );
        setFilterData({ ...filterData, advance_salary: `${min}-${max}` });
    };
    const delayedSAQuery = useCallback(debounce(delayedSAFunc, 300), []);
    function handleSalaryAdvance(min, max) {
        delayedSAQuery(min, max);
    }

    //for monthly salary slider
    const delayedMSFunc = (min, max) => {
        // console.log("monthly salary", min, max);
        dispatch(
            setSearchEmpParams({
                monthly_salary_min: min,
                monthly_salary_max: max,
            })
        );
        setFilterData({ ...filterData, monthly_salary: `${min}-${max}` });
    };
    const delayedMSQuery = useCallback(debounce(delayedMSFunc, 100), []);
    function handleMonthlySalary(min, max) {
        delayedMSQuery(min, max);
    }

    const delayedFunction = (val) => {
        dispatch(setSearchEmpParams({ search: val }));
    };

    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    function handleEmployeeSearch(e) {
        setEmployeeSearch(e.target.value);
        delayedQuery(e.target.value);
    }

    function handleSelectChange(key, item) {
        if (key === "role") {
            setFilterData({ ...filterData, role: item });
            dispatch(setSearchEmpParams({ permission_role: item.name }));
        } else if (key === "sort") {
            setFilterData({ ...filterData, sort: item });
            setSortText(item.name);
            // dispatch(removeSearchEmpParams("is_reversed"));
            const payload = { sort_by: item.value };
            if (+item.id % 2 === 0) payload.is_reversed = true;
            if (+item.id % 2 === 1) payload.is_reversed = false;
            dispatch(setSearchEmpParams(payload));
        } else if (key === "status") {
            setFilterData({ ...filterData, status: item });
            dispatch(setSearchEmpParams({ is_activated: item.value }));
        }
    }
    const clearSearch = () => {
        setEmployeeSearch("");
        dispatch(removeSearchEmpParams("search"));
    };

    return (
        <S.EmployeesContainer>
            <AddNewEmp open={openNewEmpModal} onClose={() => setOpenNewEmpModal(false)} />
            <S.HeadingBox>
                <S.EmpHeading>Staff</S.EmpHeading>
                <S.AddEmpBtn onClick={() => setOpenNewEmpModal(true)}>Add Staff</S.AddEmpBtn>
            </S.HeadingBox>
            <S.Body>
                <S.FilterDiv>
                    <S.LeftFilter>
                        <S.InputDiv>
                            <img src={AddCustomerSearchIcon} alt="search" />
                            <S.Input
                                type="text"
                                placeholder="Search by name, phone or email"
                                value={employeeSearch}
                                onChange={handleEmployeeSearch}
                                ref={nameRef}
                            />
                            {employeeSearch && <img onClick={clearSearch} src={CloseModalIcon} alt="clear" />}
                        </S.InputDiv>
                        <CustomSelect
                            type="role"
                            initalText="Permission Role"
                            disableSearch
                            data={roles || []}
                            onSelectChange={handleSelectChange}
                            selectedOption={filterData?.role?.name}
                            //   paddingHead="13px 10px"
                            menuWidth="235px"
                            clickRef={roleRef}
                        />
                        <CustomSelect
                            type="status"
                            disableSearch
                            initalText="Status"
                            data={EMPLOYEE_STATUS}
                            onSelectChange={handleSelectChange}
                            selectedOption={filterData?.status}
                            menuWidth="150px"
                            clickRef={statusRef}
                            //   paddingHead="13px 10px"
                        />
                        <CustomSlider
                            initalText={"Salary Advance"}
                            min={employeeConfigData ? employeeConfigData.SALARY_ADVANCE.MINIMUM : 0}
                            max={employeeConfigData ? employeeConfigData.SALARY_ADVANCE.MAXIMUM : 10000}
                            handleSlider={handleSalaryAdvance}
                            clickRef={advRef}
                        />
                        <CustomSlider
                            initalText={"Monthly Salary"}
                            min={employeeConfigData ? employeeConfigData.SALARY.MINIMUM : 0}
                            max={employeeConfigData ? employeeConfigData.SALARY.MAXIMUM : 10000}
                            handleSlider={handleMonthlySalary}
                            clickRef={salaryRef}
                        />
                    </S.LeftFilter>
                    <S.RightFilter>
                        Sort:
                        <CustomSelect
                            type="sort"
                            initalText={sortText}
                            disableSearch
                            data={EMPLOYEE_SORT_LIST}
                            onSelectChange={handleSelectChange}
                            selectedOption={filterData?.sort}
                            menuWidth="235px"
                            //   paddingHead="13px 10px"
                            position="left"
                        />
                    </S.RightFilter>
                </S.FilterDiv>
                <S.ChipWrapper>
                    <SelectedChips displayData={filterData} setDisplayData={setFilterData} clearSearch={clearSearch} />
                </S.ChipWrapper>
                <EmployeeTable />
            </S.Body>
        </S.EmployeesContainer>
    );
};

export default React.forwardRef(Index);
