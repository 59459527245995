import axios from "./axios";
import {
  SALES_COUNT_API,
  SALES_TOTALS_API,
  SALE_API,
  SALE_RECEIPT_API,
} from "../../constants/apiRoutes";

const addSale = async (payload, immediateInvoice) => {
  return await axios.post(SALE_API, payload, {
    params: {
      immediate_invoice: immediateInvoice,
    },
  });
};

const getSale = async (saleId) => {
  return await axios.get(`${SALE_API}/${saleId}`);
};

const getAllSales = async (queryParams = {}) => {
  return await axios.get(SALE_API, {
    params: queryParams,
  });
};

const updateSale = async (saleId, payload) => {
  return await axios.post(`${SALE_API}/${saleId}`, payload);
};

const deleteSale = async (saleId, payload) => {
  return await axios.delete(`${SALE_API}/${saleId}`);
};

const getSalesCount = () => {
  return axios.get(SALES_COUNT_API);
};

const sendSaleReceipt = async (saleId) => {
  return await axios.get(`${SALE_RECEIPT_API}/${saleId}`);
};

const getSaleTotals = async (queryParams = {}) => {
  return await axios.get(SALES_TOTALS_API, {
    params: queryParams,
  });
};

export {
  addSale,
  getSale,
  getAllSales,
  updateSale,
  getSalesCount,
  sendSaleReceipt,
  getSaleTotals,
  deleteSale,
};
