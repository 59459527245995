export const FEATURES = {
    SALES: "sales",
    SALES_RETURN: "sales_return",
    PURCHASE: "purchase",
    PURCHASE_RETURN: "purchase_return",
    SUPPLIERS: "suppliers",
    CUSTOMERS: "customers",
    INVENTORY: "inventory",
    ANDROID_POS: "android_pos",
    EXPENSES: "expenses",
    DAMAGE: "damage",
    STAFF: "staff",
    ANALYTICS: "analytics",
    DOWNLOAD_REPORTS: "download_reports",
    SALE_ONLINE: "sale_online",
    CASH_DRAWER: "cash_drawer",
    SETTINGS: "settings",
    LOGOUT: "logout",
    LOYALTY_POINT: "loyalty_point",
    PROFORMA_INVOICE: "proforma_voice",
    HELP_CENTER: "help_center",
};

export const PLAN_TYPES = {
    BRONZE_PLAN: "bronze_plan",
    SILVER_PLAN: "silver_plan",
    GOLD_PLAN: "gold_plan",
    FREE_TRIAL: "free_trial",
};

export const BRONZE_PLAN = {
    sales: true,
    sales_return: true,
    purchase: true,
    purchase_return: true,
    suppliers: true,
    customers: true,
    inventory: true,
    android_pos: true,
    expenses: false,
    damage: false,
    staff: false,
    analytics: false,
    download_reports: false,
    sale_online: false,
    settings: true,
    logout: true,
};

export const SILVER_PLAN = {
    ...BRONZE_PLAN,
    expenses: true,
    damage: true,
    staff: true,
    analytics: true,
};

export const GOLD_PLAN = {
    ...SILVER_PLAN,
    download_reports: true,
    sale_online: true,
};

export const FREE_TRIAL = {
    ...GOLD_PLAN,
};

export const PLANS = {
    bronze_plan: {
        type: PLAN_TYPES.BRONZE_PLAN,
        name: "BRONZE Plan",
        color: "#CD7F32",
        plan: BRONZE_PLAN,
        validity: "Validity till 2024",
    },
    silver_plan: {
        type: PLAN_TYPES.SILVER_PLAN,
        name: "SILVER Plan",
        color: "#C0C0C0",
        plan: SILVER_PLAN,
        validity: "Validity till 2024",
    },
    gold_plan: {
        type: PLAN_TYPES.GOLD_PLAN,
        name: "GOLD Plan",
        color: "#FFD700",
        plan: GOLD_PLAN,
        validity: "Validity till 2024",
    },
    free_trial: {
        type: PLAN_TYPES.FREE_TRIAL,
        name: "FREE TRIAL",
        color: null,
        plan: FREE_TRIAL,
        validity: "Valid for 15 Days",
    },
};

export const PLAN_TYPE = {
    PLAN_EXPIRED: "PLAN_EXPIRED",
    PLAN_FREE_TRIAL: "PLAN_FREE_TRIAL",
    PLAN_ACTIVE: "PLAN_ACTIVE",
};

export const DEFAULT_FEATURES_KEY = ["settings", "logout", "help_center"];
