import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 82px);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 44px 24px;
`;

export const LeftImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 556px;
        height: 556px;
    }
`;
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
`;
export const DarkButton = styled.div`
    background: #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    // padding: 10px 14px;
    height: 44px;
    width: 116px;
    cursor: pointer;
`;
export const BarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const FooterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 31px;
`;
export const WhiteButton = styled.div`
    border: 1px solid #253238;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px 14px;
`;

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Header = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #1e293b;
    margin-bottom: 12px;
`;
export const SubHead = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
    margin-bottom: 24px;
`;
export const AddStaffContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #1e293b;
    border-radius: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
    padding: 8px 10px;
    cursor: pointer;
`;
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const Container = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 16px;
    padding: 14px 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: calc(100vh - 324px);
    overflow: hidden;
    overflow-y: auto;
`;
export const PermissionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 70px;
    margin-bottom: 25px;
`;
export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    & > div {
        flex: 1;
    }

    & img {
        margin-top: 25px;
        cursor: pointer;
    }
`;

export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    background: #ffffff;
    padding: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #334155;
    width: 100%;

    &: focus {
        outline: none;
    }
`;
export const PhoneNumberContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & > div {
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        padding: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #94a3b8;
    }
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin-bottom: 12px;
`;
export const ImageBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e293b;
    cursor: pointer;
`;
export const ImgDiv = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #edf4fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    position: relative;
    cursor: pointer;
`;
export const InfoBtn = styled.img`
    position: absolute;
    top: 0;
    right: 0;
`;
export const AddContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2d68fe;
    margin-top: 14px;
    cursor: pointer;
`;
export const Bar = styled.div`
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
    margin: 16px 0;
`;
export const SerialNo = styled.div`
    width: 28px;
    height: 28px;
    background: #adcffa;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2b456b;
    // margin-top: -50px;
`;
