import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { PURCHASE_TYPE, DiscountOption } from "../constants/appConstants";

import {
    getDecimalPlacesCount,
    getPurchaseObjectHash,
    getPurchasePrice,
    getPurchaseProductSubTotal,
    getTax,
    getTaxValue,
    getUnit,
    hasDecimal,
    toDecimalPlace,
} from "../utils/global-fn";

export const SCREENS = {
    PURCHASE_TABLE: 0,
    PURCHASE_SUMMARY: 1,
};

const initialState = {
    PP_type: PURCHASE_TYPE.PURCHASE_PROFORMA,
    PP_products: [],
    PP_quantity: 0,
    PP_total: 0,
    PP_cartTotal: 0,
    PP_taxTotal: 0,
    PP_discountValue: 0,
    PP_selectedSupplier: null,
    PP_billNo: "",
    PP_createdDate: null,
    PP_deliveryDate: null,
    PP_deliveryExpense: "",
    PP_selectDiscount: {
        type: DiscountOption.PERCENTAGE,
        cost: 0,
    },
    PP_globalDiscount: 0,
    PP_currentScreen: SCREENS.PURCHASE_SUMMARY,
    selectedPP: null,
    PP_currentFocus: 0,
};

const purchaseProformaSlice = createSlice({
    name: "purchaseProforma",
    initialState,
    reducers: {
        addProductsToPP: (state, action) => {
            state.PP_products = action.payload;
            state.PP_currentFocus = 0;
        },
        addProductToPP: (state, action) => {
            const itemIndex = state.PP_products.findIndex((item) => item.sku === action.payload.sku);
            if (itemIndex >= 0) {
                state.PP_products[itemIndex].quantity = parseFloat(state.PP_products[itemIndex].quantity) + 1;
                state.PP_currentFocus = itemIndex;
            } else {
                state.PP_products.push({
                    ...action.payload,
                    sale_price: toDecimalPlace(action.payload.sale_price),
                    purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                    quantity: action.payload.quantity ? action.payload.quantity : 1,
                    discount: action.payload.discount ? action.payload.discount : 0,
                    discountValue: action.payload.discountValue ? action.payload.discountValue : 0,
                    activeDiscount: action.payload.activeDiscount ? action.payload.activeDiscount : DiscountOption.FLAT,
                    unit: getUnit(action.payload),
                    manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                    expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                    checkProduct: {
                        ...action.payload,
                        sale_price: toDecimalPlace(action.payload.sale_price),
                        purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                        hashKey: getPurchaseObjectHash(action.payload),
                    },
                });
                state.PP_currentFocus = state.PP_products.length - 1;
            }
        },
        updateProductFromPP: (state, action) => {
            const newPurchaseProducts = state.PP_products.map((item) => {
                if (item.sku === action.payload.sku) {
                    if (action.payload.checkProduct) {
                        // for general slice product update
                        return { ...item, ...action.payload };
                    } else {
                        // update slice product after fetched from db
                        let newHash = getPurchaseObjectHash(action.payload);
                        if (item.checkProduct?.hashKey === newHash) {
                            let updatedCheckProduct = { ...item.checkProduct, stock: action.payload.stock };
                            return { ...item, name: action.payload.name, checkProduct: updatedCheckProduct };
                        } else {
                            let updateCheckProduct = {
                                ...action.payload,
                                sale_price: toDecimalPlace(action.payload.sale_price),
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                hashKey: newHash,
                            };
                            return {
                                ...item,
                                ...action.payload,
                                sale_price: toDecimalPlace(action.payload.sale_price),
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                unit: getUnit(action.payload),
                                discountValue: 0,
                                discount: 0,
                                activeDiscount: DiscountOption.FLAT,
                                manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                                expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                                checkProduct: updateCheckProduct,
                            };
                        }
                    }
                } else {
                    return item;
                }
            });
            state.PP_products = newPurchaseProducts;
        },
        replaceProductFromPP: (state, action) => {
            for (let i = 0; i < state.PP_products.length; i++) {
                if (state.PP_products[i].sku === action.payload.sku) {
                    state.PP_products[i].sku = action.payload.newSku;
                    break;
                }
            }
        },
        removeProductFromPP: (state, action) => {
            const idx = state.PP_products.findIndex((item) => item.sku === action.payload.sku);
            if (idx >= 0) {
                const newPurchaseProducts = state.PP_products.filter((item) => item.sku !== action.payload.sku);
                state.PP_products = newPurchaseProducts;
                if (idx >= newPurchaseProducts.length - 1) {
                    state.PP_currentFocus = newPurchaseProducts.length - 1;
                }
            }

            state.PP_selectDiscount = {
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            };
        },
        decrementQuantityInPP: (state, action) => {
            const itemIndex = state.PP_products.findIndex((item) => item.sku === action.payload.sku);
            if (state.PP_products[itemIndex].quantity > 1) {
                let product = state.PP_products[itemIndex];
                let qty = parseFloat(product.quantity) - 1;

                if (hasDecimal(qty)) {
                    let ct = getDecimalPlacesCount(qty);
                    if (ct > 1) {
                        ct = 2;
                    }
                    qty = qty.toFixed(ct);
                }
                state.PP_products[itemIndex].quantity = parseFloat(qty);
            } else if (state.PP_products[itemIndex].quantity === 1) {
                toast.error("Minimum quantity reached", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        },
        getTotalsPP: (state, action) => {
            let { total, quantity, tax, discountValue } = state.PP_products.reduce(
                (purchaseTotal, purchaseItem) => {
                    const { purchase_price, quantity, discountValue, purchase_price_with_tax } = purchaseItem;

                    let tax = getTax(purchaseItem);
                    let qty = parseFloat(quantity);
                    let purchasePrice = parseFloat(purchase_price);
                    // let itemTotal = getPurchasePrice(purchaseItem, false);

                    let { itemTotal, taxTotal } = getPurchaseProductSubTotal(purchaseItem);

                    let itemTaxTotal = 0;
                    if (qty > 0) {
                        // taxTotal = (((purchasePrice - discountValue / qty) * tax) / 100) * (qty || 0);
                        // let value = purchasePrice * qty - discountValue;
                        // taxTotal = getTaxValue(value, tax, purchase_price_with_tax);
                        itemTaxTotal = taxTotal;
                    }
                    purchaseTotal.total += itemTotal;
                    purchaseTotal.quantity += qty;
                    purchaseTotal.tax += itemTaxTotal;
                    purchaseTotal.discountValue += discountValue * qty;

                    return purchaseTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                    tax: 0,
                    discountValue: 0,
                }
            );
            total = parseFloat(total);
            state.PP_quantity = quantity;
            state.PP_total = total;
            state.PP_taxTotal = tax;
            state.PP_discountValue = discountValue;

            let globalDisc =
                state.PP_selectDiscount.type === DiscountOption.PERCENTAGE
                    ? total * (parseFloat(state.PP_selectDiscount.cost || 0) / 100)
                    : parseFloat(state.PP_selectDiscount.cost || 0);

            state.PP_globalDiscount = globalDisc;
            state.PP_cartTotal = toDecimalPlace(total - globalDisc + +state.PP_deliveryExpense);
        },
        removeAllProductsPP: (state) => {
            state.PP_products = [];
        },

        setSelectedPP: (state, action) => {
            state.selectedPP = action.payload;
        },

        removeSelectedPP: (state) => {
            state.selectedPP = null;
        },

        setSelectedSupplierPP: (state, action) => {
            state.PP_selectedSupplier = action.payload;
        },

        removeSelectedSupplierPP: (state) => {
            state.PP_selectedSupplier = null;
        },

        setCurrentScreenPP: (state, action) => {
            state.PP_currentScreen = action.payload;
        },
        setTypePP: (state, action) => {
            state.PP_type = action.payload;
        },
        setBillNoPP: (state, action) => {
            state.PP_billNo = action.payload;
        },
        setCreatedDatePP: (state, action) => {
            state.PP_createdDate = action.payload;
        },
        setDeliveryDatePP: (state, action) => {
            state.PP_deliveryDate = action.payload;
        },
        setSelectDiscountPP: (state, action) => {
            state.PP_selectDiscount = action.payload;
        },
        setDeliveryExpensePP: (state, action) => {
            state.PP_deliveryExpense = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase("LOGOUT", (state) => {
            Object.assign(state, initialState);
        });
    },
});

export default purchaseProformaSlice.reducer;
export const {
    addProductToPP,
    updateProductFromPP,
    replaceProductFromPP,
    removeProductFromPP,
    decrementQuantityInPP,
    getTotalsPP,
    removeAllProductsPP,
    setSelectedPP,
    removeSelectedPP,
    setSelectedSupplierPP,
    removeSelectedSupplierPP,
    setCurrentScreenPP,
    setTypePP,
    setBillNoPP,
    setCreatedDatePP,
    setDeliveryDatePP,
    setSelectDiscountPP,
    setDeliveryExpensePP,
    addProductsToPP,
} = purchaseProformaSlice.actions;
