import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & input {
        width: 72px;
        height: 32px;
        border: 1px solid #e2e8f0;
        border-radius: 2px;
        padding: 10px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 13px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;
export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: 72px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 2px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 2px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 2px;
`;
export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;
