import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 16px 24px;
    width: 499px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    & label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
    }

    & input {
        cursor: pointer;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    text-align: center;
    justify-content: flex-end;
`;
export const LoaderBtn = styled.div`
    width: 80px;
    height: 36px;
    background: #2d68fe;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;

    & > div {
        width: 40px;
        height: 40px;
    }
`;
export const BlueBtn = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding: 6px 12px;
    background: #2e65f3;
    border-radius: 8px;
    cursor: pointer;
`;

export const Textarea = styled.textarea`
    border: 1px solid #c2dee7;
    border-radius: 8px;
    height: 79px;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
    color: #000;
    padding: 10px 12px;
    font-family: sans-serif;
    resize: none;

    &: focus {
        outline: none;
    }
`;
export const DataDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 20px;
    border-right: 1px solid #e2e8f0;
`;

export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 56px;
    height: 56px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;

    & img {
        width: 100%;
    }
`;

export const DarkText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #475569;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
export const GreyText = styled.div`
    font-weight: 400;
    font-size: ${(props) => props.fs && props.fs};
    line-height: 15px;
    color: #94a3b8;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const ProductList = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 18px;
    border-bottom: 1px solid #e2e8f0;
`;
export const Bar = styled.div`
    height: 100%;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;
export const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #334155;
`;
