import React from "react";
import * as S from "../LoginScreen.style";

const SendOtp = ({ loginData, handleInputChange, onSubmit }) => {
    return (
        <div>
            <S.LoginHead>Enter phone number</S.LoginHead>
            <S.LoginContainer>
                <S.InputFIelds>
                    <input type="text" name="username" onChange={(e) => handleInputChange(e)} autoFocus value={loginData.username} />
                </S.InputFIelds>
                <S.ConfirmBtn onClick={onSubmit}>Send OTP</S.ConfirmBtn>
            </S.LoginContainer>
        </div>
    );
};

export default SendOtp;
