import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    margin-right: 16px;
    background: #68a0fc;
    border-radius: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    cursor: pointer;
    border: 1px solid #0349ff;
    padding: 6px 6px 6px 12px;
`;

export const Title = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
`;

export const CountBox = styled.div`
    padding: 2px 4px;
    display: flex;
    min-width: 20px;
    min-height: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 71px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    background-color: #e81123;
`;
