import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";

const WarningDialog = ({ open, onClose, data }) => {
    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);
    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                onClose();
            }
        }
    };
    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Container>
                <S.TextContainer>
                    <S.Title>{data.title}</S.Title>
                    <S.SubTitle>{data.subtitle}</S.SubTitle>
                </S.TextContainer>
                <S.BtnContainer>
                    <S.Button onClick={onClose}>Done</S.Button>
                </S.BtnContainer>
            </S.Container>
        </Modal>
    );
};

export default WarningDialog;
