import React, { useRef, useEffect, useState, useContext } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CloseModalIcon } from "../../../icons";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { ADJUSTMENT_TYPE, CASH_DRAWER_TYPES } from "../../../constants/appConstants";
import { addCashDrawerItem } from "../../../api/local/cashDrawer-api";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCashDrawerItems } from "../../../store/cashdrawer-slice";
import { captureException } from "../../../crash-reporting";

const initialValue = {
    type: null,
    description: "",
    amount: "",
    adjusted_cash: "",
};
const AddNewCashItem = ({ open, onClose, cashId, cashData, getCashDetails }) => {
    const selectRef = useRef(false);
    const trackRef = useRef(null);

    const [formdata, _setFormdata] = useState(initialValue);
    const formdataRef = useRef(formdata);
    const setFormdata = (data) => {
        formdataRef.current = data;
        _setFormdata(data);
    };

    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const { searchParams } = useSelector((state) => state.cashDrawer);

    useEffect(() => {
        if (!formdataRef.current?.adjusted_cash) {
            setFormdata({ ...formdata, adjusted_cash: cashData.current_cash });
        }
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                handleSubmit();
                e.preventDefault();
            }
        }
    };

    const handleSelectChange = (key, item) => {
        let newAdjustCash =
            item.value === ADJUSTMENT_TYPE.REDUCE_CASH
                ? cashData.current_cash - (+formdata.amount ?? 0)
                : cashData.current_cash + (+formdata.amount ?? 0);
        setFormdata({ ...formdata, type: item.value, adjusted_cash: newAdjustCash });
    };

    const handleInputChange = (e, key) => {
        let { value } = e.target;
        if (key === "description") {
            setFormdata({ ...formdata, [key]: value });
        } else {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            if (key === "amount") {
                let newAdjustCash =
                    formdata.type === ADJUSTMENT_TYPE.REDUCE_CASH ? cashData.current_cash - (+value ?? 0) : cashData.current_cash + (+value ?? 0);
                setFormdata({ ...formdata, amount: value, adjusted_cash: newAdjustCash });
            } else if (key === "adjusted_cash") {
                if (value > cashData.current_cash) {
                    let pl = {
                        adjusted_cash: value,
                        amount: +value - cashData.current_cash,
                        type: ADJUSTMENT_TYPE.INCREASE_CASH,
                    };
                    setFormdata({ ...formdata, ...pl });
                } else {
                    let pl = {
                        adjusted_cash: value,
                        amount: cashData.current_cash - +value,
                        type: ADJUSTMENT_TYPE.REDUCE_CASH,
                    };
                    setFormdata({ ...formdata, ...pl });
                }
            }
        }
    };

    const handleClear = () => {
        setFormdata({ ...initialValue });
    };

    const handleGetCashDrawerData = () => {
        dispatch(fetchAllCashDrawerItems(searchParams));
    };

    const handleSubmit = async () => {
        const { amount, description, type } = formdataRef.current;
        if (!type) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose an adjustment type",
                type: "error",
            });
            return;
        }
        if (!amount) {
            handleSnackbarDetails({
                show: true,
                title: "Please add amount",
                type: "error",
            });
            return;
        }

        const payload = {
            type: type,
            amount: parseInt(amount),
        };
        if (description) payload.description = description;

        try {
            let res = await addCashDrawerItem(payload);
            if (res.status === 200) {
                handleGetCashDrawerData();
                getCashDetails();
                onClose();
                handleClear();
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Cash Adjustment Added",
                    subtitle: "New cash adjustment added successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.TopBar>
                    <S.Head>Adjust Cash</S.Head>
                    <img src={CloseModalIcon} alt="close icon" onClick={onClose} />
                </S.TopBar>
                <S.Body>
                    <S.InputContainer>
                        <S.Label>Adjustment</S.Label>
                        <CustomSelect
                            type="cash-adjustment"
                            initalText={formdata.type}
                            data={CASH_DRAWER_TYPES}
                            disableSearch
                            onSelectChange={handleSelectChange}
                            menuWidth="100%"
                            selectRef={selectRef}
                            trackRef={trackRef}
                            blue
                        />
                    </S.InputContainer>
                    <S.InputContainer>
                        <S.Label>Enter Amount</S.Label>
                        <S.AmountContainer>
                            ₱
                            <input type="text" value={formdata.amount} onChange={(e) => handleInputChange(e, "amount")} />
                        </S.AmountContainer>
                    </S.InputContainer>
                    <S.InputContainer>
                        <S.Label>Description</S.Label>
                        <S.AmountContainer>
                            <input
                                type="text"
                                placeholder="Enter Description"
                                value={formdata.description}
                                maxLength={200}
                                onChange={(e) => handleInputChange(e, "description")}
                            />
                        </S.AmountContainer>
                    </S.InputContainer>
                    <S.ShowCashContainer>
                        <S.InputContainer>
                            <S.Label>Current Cash</S.Label>
                            <S.ShowCash>₱{cashData.current_cash}</S.ShowCash>
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Label>Adjusted Cash</S.Label>
                            {/* <S.ShowCash>
                                ₱
                                {formdata.type === ADJUSTMENT_TYPE.INCREASE_CASH
                                    ? cashData.current_cash + (+formdata.amount ?? 0)
                                    : cashData.current_cash - (+formdata.amount ?? 0)}
                            </S.ShowCash> */}
                            <S.AmountContainer>
                                ₱
                                <input type="text" value={formdata.adjusted_cash} onChange={(e) => handleInputChange(e, "adjusted_cash")} />
                            </S.AmountContainer>
                        </S.InputContainer>
                    </S.ShowCashContainer>
                </S.Body>
                <S.BottomBar>
                    <S.BlueBtn onClick={handleSubmit}>Save Adjustment (ENT)</S.BlueBtn>
                </S.BottomBar>
            </S.Wrapper>
        </Modal>
    );
};

export default AddNewCashItem;
