import styled from "@emotion/styled/macro";

export const Container = styled.div`
    padding-top: 8px;
`;

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // column-gap: 24px;
    // row-gap: 16px;
    gap: 24px;
`;

export const FlexContainer = styled.div`
    display: flex;
    gap: 16px;
`;
export const SubFlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    & > div:nth-of-type(1) {
        width: 61%;
    }
    & > div:nth-of-type(2) {
        width: 39%;
    }
`;

export const Select = styled.div`
    flex: 1;
`;

export const Section = styled.div`
    width: 100%;
    margin-top: 15px;
`;

export const TagsInput = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    padding: 9px;
    flex: 1;
    gap: 12px;

    min-height: 36px;
    max-height: 36px;
    align-items: center;

    & > img {
        width: 18px;
        height: 18px;
    }
`;

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #334155;
        outline: none;
        border: none;
    }
`;

export const Description = styled.textarea`
    width: 100%;
    resize: none;
    height: 91px;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
    outline: none;
    font-family: sans-serif;
`;

export const Dashedline = styled.div`
    border-top: dashed 2px #e2e8f0;
`;
export const ToggleFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 35%;
`;

export const UnitFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    width: 60%;

    & > div:nth-of-type(1) {
        width: 20%;
    }
    & > div:nth-of-type(2) {
        width: 30%;
    }

    & > div:nth-of-type(3) {
        width: 20%;
    }
    & > div:nth-of-type(4) {
        width: 30%;
    }
`;

export const Hint = styled.div`
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 17px;
    color: #94a3b8;
`;

export const AdjustHeight = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 24px;
`;
