import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 600px;
    min-height: 335px;
    box-sizing: border-box;
`;
export const Header = styled.div`
    font-weight: 600;
    font-size: 15.9486px;
    line-height: 22px;
    color: #334155;
    border-bottom: 1px solid #e2e8f0;
    padding: 15px 19px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;
export const Body = styled.div`
    padding: 16px 16px 27px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const AmountContainer = styled.div`
    height: 142px;
    width: 100%;
    background: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
export const AmountDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
`;
export const AmountChip = styled.div`
    width: 88px;
    height: 36px;
    background: ${(props) => (props.active ? " #DBEAFE" : "#ffffff")};
    border: 1px solid ${(props) => (props.active ? "#93C5FD" : "#e2e8f0")};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    cursor: pointer;
    padding: 0 8px;

    input {
        margin: 2px 4px 0 0;
        width: 13px;
        height: 13px;

        :focus {
            outline: none;
        }
    }

    label {
        cursor: pointer;
    }
`;
export const OtherAmountDiv = styled.div`
    width: 202px;
    background: #fff;
    box-sizing: border-box;
    padding: 6px;

    & input {
        width: 100%;
        border: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;

        :focus {
            outline: none;
        }
    }
`;
export const PaymentModeContainer = styled.div`
    // height: 170px;
    width: 100%;
    background: #f1f5f9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
export const PaymentModeDiv = styled.div`
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    gap: 21px;
`;
export const PaymentMode = styled.div`
    border: 1px solid #253238;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => (props.active ? "#2D68FE" : "#253238")};
    border-color: ${(props) => (props.active ? "#2D68FE" : "#253238")};
    :focus {
        outline: none;
    }
    & img {
        margin-top: 2px;
        // width: 20px;
        // height: 20px;
    }
`;
export const ChequeDateInputContainer = styled.div`
    background: #ffffff;
    border: 1px solid #ccdfff;
    border-radius: 4px;
    padding: 8px 12px;
    position: relative;
    cursor: pointer;
    height: 40px;
`;
export const ChequeDateInput = styled.div`
    display: flex;
    justify-content: flex-end;
    // gap: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
    width: 114px;
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 40px;
`;
export const ChequeDatas = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const Bar = styled.div`
    width: 1px;
    height: 18px;
    background-color: #e2e8f0;
    margin: 0 10px 0 20px;
`;
export const ChequeNumberContainer = styled.div`
    background: #ffffff;
    border: 1px solid #ccdfff;
    border-radius: 4px;
    padding: 8px 12px;
    width: 165px;
    // height: 40px;

    & input {
        width: 100%;
        border: none;

        :focus {
            outline: none;
        }
    }
`;
export const SubmitBtn = styled.div`
    background: #2d68fe;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
`;
