import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 950px;
    height: 637px;
    box-sizing: border-box;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 46px;
`;

export const Head = styled.div`
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    color: #334155;
    text-align: center;
    width: 573px;
    margin: 27px 0 16px;
`;
export const SubHead = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;
    text-align: center;
    width: 474px;
`;
export const PointsHead = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
`;
export const Points = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #000000;
    display: flex;
    gap: 8px;
`;
export const DottedLine = styled.div`
    width: 687px;
    border: 1px dashed #ababab;
    margin: 34px 0;
`;

export const PointText = styled.div`
    width: 450px;
`;

export const PointsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
