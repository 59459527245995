import { SEND_OTP_API, VERIFY_OTP_API } from "../../constants/apiRoutes";
import axios from "./axios";

const sendOTP = async (data) => {
    return await axios.post(SEND_OTP_API, data);
};

const verifyOTP = async (data) => {
    return await axios.post(VERIFY_OTP_API, data);
};

export { sendOTP, verifyOTP };
