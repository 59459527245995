import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCustomers } from "../api/local/customerApi";
import { captureException } from "../crash-reporting";
import { PAGE_LIMIT } from "../constants/appConstants";

const initialState = {
    loading: false,
    customers: [],
    error: "",
    searchParams: { limit: PAGE_LIMIT },
    inventorySelectedCustomer: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllCustomers = createAsyncThunk("customer/fetchAllCustomers", (search) =>
    getAllCustomers(search)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },
        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = { limit: PAGE_LIMIT };
        },
        setSelectedCustomer: (state, action) => {
            state.inventorySelectedCustomer = action.payload;
        },
        removeSelectedCustomer: (state) => {
            state.inventorySelectedCustomer = "";
        },
        removeAllCustomers: (state) => {
            state.customers = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCustomers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllCustomers.fulfilled, (state, action) => {
            state.loading = false;
            state.customers = action.payload?.entity ?? [];
            state.error = "";
        });
        builder.addCase(fetchAllCustomers.rejected, (state, action) => {
            state.loading = false;
            state.customers = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default customerSlice.reducer;
export const { setSearchParams, removeSearchParams, removeAllSearchParams, setSelectedCustomer, removeSelectedCustomer, removeAllCustomers } =
    customerSlice.actions;
