import React from "react";
import * as S from "./style";
import { generateMetrics } from "../../../utils/metrics";
import { ArrowDrop, ArrowUp, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";

const PackingProduct = ({ item, handleInputChange, handleChangeQuantity, checkHandler, idx, activeProductRow, rowsRefs }) => {
    return (
        <S.Wrapper active={idx === activeProductRow}>
            <S.DataDetails>
                <S.CheckboxContainer>
                    <input type="checkbox" checked={item?.isChecked || false} name={item.sku} onChange={(e) => checkHandler(e, idx)} />
                </S.CheckboxContainer>
                <S.ImageContainer>
                    <img
                        src={item.gallery ? item.gallery : ProductPlaceholderIcon}
                        alt="product"
                        onError={(e) => {
                            e.target.src = WifiDisconnectedIcon;
                        }}
                    />
                </S.ImageContainer>
                <S.Content>
                    <S.DarkText>{item.brand?.name}</S.DarkText>
                    <S.GreyText fs="14px" active={idx === activeProductRow}>
                        {item.name}
                    </S.GreyText>
                    <S.GreyText fs="12px" active={idx === activeProductRow}>
                        {generateMetrics(item)}
                    </S.GreyText>
                </S.Content>
            </S.DataDetails>
            <S.DarkText>
                ₱{item.sale_price}/<S.Unit>UNIT</S.Unit>
            </S.DarkText>
            <S.QuantityContainer>
                QTY : <span>{item.quantity}</span>
            </S.QuantityContainer>

            <S.InputContainer>
                <S.Input
                    type="text"
                    value={item.packedQuantity || 0}
                    onChange={(e) => handleInputChange(e, item)}
                    ref={(el) => (rowsRefs.current[idx] = el)}
                    autoFocus={idx === activeProductRow}
                />
                {
                    <S.ArrowBox>
                        <img src={ArrowUp} alt="arrow up" onClick={() => handleChangeQuantity("increment", item)} />
                        <img src={ArrowDrop} alt="arrow drop" onClick={() => handleChangeQuantity("decrement", item)} />
                    </S.ArrowBox>
                }
            </S.InputContainer>

            <S.DarkText>₱{item.net_amount}</S.DarkText>
        </S.Wrapper>
    );
};

export default PackingProduct;
