import React, { useEffect, useRef, useState } from "react";
import { uploadFile } from "../../../api/local/globalSearchApi";
import { KYC_TYPES } from "../../../constants/appConstants";
import { ArrowRight, PlusBlueIcon, PlusGrayIcon, ImageDeleteIcon, ImageEditIcon } from "../../../icons";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import InputLabel from "../../product-drawer/input-label/input-label";
import * as S from "./style";
import { captureException } from "../../../crash-reporting";

const initialData = [
    {
        id: "",
        id_type: "",
        id_image: [],
    },
];

const Kyc = ({ kycList, handleSubmit }) => {
    const [dataList, setDataList] = useState(initialData);

    const [list, setList] = useState([]);

    useEffect(() => {
        handleAddComp();
    }, []);

    function handleAddComp() {
        setList(
            list.concat(
                <SubKyc
                    key={list.length}
                    id={list.length}
                    dataList={dataList}
                    displayData={dataList[list.length]}
                    setDisplayData={(value) => {
                        initialData[list.length] = value;
                        setDataList(initialData);
                    }}
                />
            )
        );
        // console.log(dataList)
    }
    return (
        <S.Wrapper>
            <S.Body>
                {list.map((e) => e)}

                {list.length < 3 && (
                    <S.AddNew onClick={handleAddComp}>
                        <img src={PlusBlueIcon} alt="" />
                        <div>Add new id</div>
                    </S.AddNew>
                )}
            </S.Body>
            <S.Footer>
                <S.SaveBtn onClick={() => handleSubmit(initialData)}>Save</S.SaveBtn>
            </S.Footer>
        </S.Wrapper>
    );
};

export default Kyc;

const SubKyc = ({ id, displayData, setDisplayData, dataList }) => {
    const [imgSrc1, setImgSrc1] = useState(null);
    const [imgSrc2, setImgSrc2] = useState(null);
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const inputRef = useRef(null);

    const handleInputChange = (type) => (val) => {
        setDisplayData({ ...displayData, id: val });
        // console.log(val)
        // console.log("dp", displayData);
        // console.log("datalist", dataList);
    };

    function handleSelectChange(key, item) {
        setDisplayData({ ...displayData, id_type: item.value });
        // console.log(item.value)
        // console.log("dp", dataList )
    }

    const handleuploadImage = async (file) => {
        try {
            let res = await uploadFile(file);
            if (res.status === 200) {
                return res.data.path;
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleImageChange = async (event) => {
        if (event.target.files[0]) {
            const reader = new FileReader();
            let res = await handleuploadImage(event.target.files[0]);
            setDisplayData({
                ...displayData,
                id_image: [...displayData.id_image, res],
            });

            reader.addEventListener("load", () => {
                if (!imgSrc1) {
                    setImgSrc1(reader.result);
                    //   setDisplayData({ ...displayData, id_img1: reader.result })
                } else {
                    setImgSrc2(reader.result);
                    //   setDisplayData({ ...displayData, id_img2: reader.result })
                }
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    function handleEditImage() {
        if (imgSrc2) {
            setImgSrc2(null);
            inputRef.current?.click();
        } else {
            setImgSrc1(null);
            inputRef.current?.click();
        }
    }
    return (
        <>
            <S.Heading>ID {id + 1}</S.Heading>
            <S.InputFieldDiv>
                <div>
                    <InputLabel text="ID Type" />
                    <CustomSelect type="kyc" initalText="Select ID type" disableSearch data={KYC_TYPES} onSelectChange={handleSelectChange} />
                </div>
                <div>
                    <InputLabel text="ID" />
                    <CustomInput
                        placeholder="Enter ID"
                        type="text"
                        // value={displayData.kyc_id}
                        onChange={handleInputChange("id")}
                    />
                </div>
                <div>
                    <InputLabel text="ID" />
                    <S.ImageContainerDiv>
                        {imgSrc1 && (
                            <S.KycImage hover={hover1} src={imgSrc1} onMouseEnter={() => setHover1(true)} onMouseLeave={() => setHover1(false)}>
                                {hover1 && (
                                    <S.HoverBtns>
                                        <img src={ImageEditIcon} alt="" onClick={handleEditImage} />
                                        <img src={ImageDeleteIcon} alt="" onClick={() => setImgSrc1(null)} />
                                    </S.HoverBtns>
                                )}
                            </S.KycImage>
                        )}
                        {imgSrc2 && (
                            <S.KycImage hover={hover2} src={imgSrc2} onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
                                {hover2 && (
                                    <S.HoverBtns>
                                        <img src={ImageEditIcon} alt="" onClick={handleEditImage} />
                                        <img src={ImageDeleteIcon} alt="" onClick={() => setImgSrc2(null)} />
                                    </S.HoverBtns>
                                )}
                            </S.KycImage>
                        )}
                        {(!imgSrc1 || !imgSrc2) && (
                            <S.ImageContainer>
                                <S.AddImage>
                                    <img src={PlusGrayIcon} alt="add" />
                                    Add image
                                </S.AddImage>
                                <input type="file" accept="image/*" onChange={handleImageChange} ref={inputRef} />
                            </S.ImageContainer>
                        )}
                    </S.ImageContainerDiv>
                </div>
            </S.InputFieldDiv>
        </>
    );
};
