import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #2d68fe;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2d68fe;
    padding: 8px 18px;
    cursor: pointer;

    & div {
        margin-top: -2px;
    }

    :hover {
        background-color: #f2f5fe;
    }
`;
