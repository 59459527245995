import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 199px;
    width: 499px;
    border-radius: 12px;
    box-sizing: border-box;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 27px 40px;
`;
export const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #232325;
`;
export const SubTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #232325;
`;
export const BtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 16px 19px;
    background: #f7f7f9;
    border-radius: 0 0 12px 12px;
`;
export const Button = styled.div`
    background: #2e65f3;
    border-radius: 8px;
    padding: 6px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
`;
