import React, { useEffect, useRef, useState } from "react";
import * as S from "./style";
import { CloseModalIcon, SelectArrowDownIcon } from "../../icons";
import OutsideClickHandler from "react-outside-click-handler";

const Autocomplete = ({ data, value = "", onSelectOption, onSearchChange, position = "bottom", trackRef, selectRef, name, listWidth, width }) => {
    const [showList, _setShowList] = useState(false);
    const showListRef = useRef(showList);
    const setShowList = (data) => {
        if (trackRef) {
            if (!showListRef.current) {
                trackRef.current = name;
            } else {
                trackRef.current = null;
            }
        }
        showListRef.current = data;
        _setShowList(data);
        if (selectRef) selectRef.current = data;
    };

    const [inputValue, _setInputValue] = useState(value);
    const inputValueRef = useRef(inputValue);
    const setInputValue = (value) => {
        inputValueRef.current = value;
        _setInputValue(value);
    };
    const [filteredData, _setFilteredData] = useState(data);
    const filteredDataRef = useRef(filteredData);
    const setFilteredData = (data) => {
        filteredDataRef.current = data;
        _setFilteredData(data);
    };

    const [activeRow, _setActiveRow] = useState(-1);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    const handleToggleList = () => {
        if (!showList) {
            handleSearch(inputValue);
        }
        setShowList(!showList);
    };

    const handleOutsideClick = () => {
        if (showListRef.current) {
            setShowList(false);
        }
    };

    const onInputFocus = () => {
        setShowList(true);
        handleSearch(inputValue);
    };

    const handleClearInput = () => {
        setInputValue("");
        setShowList(false);
    };

    const onLocalSearch = (value) => {
        if (value) {
            const filtered = data?.filter((item) => {
                let text;
                if (typeof item === "string") {
                    text = item;
                } else {
                    text = item.name;
                }
                return text.toLowerCase().includes(value.toLowerCase());
            });
            if (filtered?.length) {
                setFilteredData(filtered);
            } else {
                setFilteredData([]);
            }
        } else {
            setFilteredData(data);
        }
    };

    const handleSearch = (value) => {
        if (onSearchChange) {
            onSearchChange(value);
        } else {
            onLocalSearch(value);
        }
    };

    const handleInputChange = (e) => {
        let { value } = e.target;
        setInputValue(value);
        handleSearch(value);
        if (!showListRef.current) {
            setShowList(true);
        }
        if (onSelectOption) onSelectOption(name, value);
    };

    const handleOptionSelect = (item) => {
        setInputValue(typeof item === "string" ? item : item.name);
        if (onSelectOption) onSelectOption(name, item);
        setShowList(false);
    };
    return (
        <>
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                <S.Container width={width}>
                    <S.InputContainer>
                        <S.Input type="text" value={inputValue} onChange={handleInputChange} onFocus={onInputFocus} />
                        {/* {inputValue && <S.Icon src={CloseModalIcon} alt="clear" onClick={handleClearInput} />} */}
                        <S.Icon src={SelectArrowDownIcon} alt="arrow" rotate={showList && filteredData?.length} onClick={handleToggleList} />
                    </S.InputContainer>
                    {showList && (
                        <List
                            data={filteredData}
                            position={position}
                            listWidth={listWidth}
                            handleOptionSelect={handleOptionSelect}
                            showListRef={showListRef}
                            activeRow={activeRow}
                            activeRowRef={activeRowRef}
                            setActiveRow={setActiveRow}
                            handleOutsideClick={handleOutsideClick}
                        />
                    )}
                </S.Container>
            </OutsideClickHandler>
        </>
    );
};

export default Autocomplete;

const List = ({ data, position, listWidth, handleOptionSelect, showListRef, setActiveRow, activeRowRef, activeRow, handleOutsideClick }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        if (showListRef.current) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
            setActiveRow(-1);
        };
    }, [data]);
    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 38) {
            //Arrow up
            if (activeRowRef.current > 0) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 40) {
            //Arrow down
            if (activeRowRef.current < data.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            if (activeRowRef.current < 0) {
                e.preventDefault();
                return;
            }
            handleOptionSelect(data[activeRowRef.current]);
            e.preventDefault();
        } else if (e.keyCode === 27) {
            //ESC key
            handleOutsideClick();
            e.preventDefault();
        } else if (e.keyCode === 9) {
            //Tab key
            handleOutsideClick();
        }
    };
    return (
        <S.ListContainer position={position} data={data} listWidth={listWidth}>
            <S.Lists>
                {data?.length ? (
                    data?.map((item, idx) => (
                        <ListItem key={idx} idx={idx} item={item} handleOptionSelect={handleOptionSelect} activeRow={activeRow} />
                    ))
                ) : (
                    <></>
                )}
            </S.Lists>
        </S.ListContainer>
    );
};

const ListItem = ({ item, idx, handleOptionSelect, activeRow, scrollRef }) => {
    return (
        <S.Option key={idx} onClick={() => handleOptionSelect(item)} active={idx === activeRow} ref={idx === activeRow ? scrollRef : null}>
            {typeof item === "string" ? item : item.name}
        </S.Option>
    );
};
