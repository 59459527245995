import styled from "@emotion/styled/macro";

export const Container = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    /* padding: 14px 8px 10px; */
    padding: 8px 8px;
    max-height: 38px;
    height: 38px;
    display: flex;
    align-items: center;

    input {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #334155;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        background-color: #fff;
        width: 100%;

        &::placeholder {
            color: #94a3b8;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    img[alt*="search"] {
        width: 24.36px;
        height: 24px;
        cursor: pointer;
        margin-right: 4px;
    }

    img[alt*="refresh"] {
        width: 24.36px;
        height: 24px;
        cursor: pointer;
    }

    img[alt*="clear"] {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: ${(props) => props.scText && "6px"};
    }
`;
export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e2e8f0;
    // width: 20px;
    padding-left: 8px;
    margin-left: 4px;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
