import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    // width: 1177px;
    width: 843px;
    height: 630px;
    box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
    // height: 86px;
    font-weight: 600;
    font-size: 14.3246px;
    line-height: 20px;
    color: #334155;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 16px 14px;
    border-bottom: 1px solid #e2e8f0;

    img {
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
`;
export const HeaderText = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 24px !important;
        height: 24px !important;
        margin-right: 8px;
    }
`;

export const Body = styled.div`
    flex: 1;
    padding: 30px 16px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const BodyTop = styled.div`
    display: flex;
    gap: 30px;
`;

export const FormContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const FormContainerHeader = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333;
    margin-bottom: 14px;
`;

export const FormContainerInput = styled.input`
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
    padding: 12.5px 11px;
    outline: none;
    // margin-bottom: 14px;
    flex: 1;
    max-height: 36px;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;

    &::placeholder {
        color: #737373;
    }
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export const PinInputDiv = styled.div`
    flex: 1;
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    // padding-right: 5px;
    justify-content: space-between;
    padding: 12.5px 11px;
    max-height: 36px;
    box-sizing: border-box;

    & > input {
        border: none;
        font-weight: 400;
        font-size: 11.1533px;
        line-height: 150%;
        letter-spacing: -0.2px;
        color: #334155;
        outline: none;
        box-sizing: border-box;
        width: 80%;

        &::placeholder {
            color: #737373;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
`;
export const FormContainerInputCode = styled.input`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
    padding: 12px 11px;
    outline: none;
    width: 12%;
    max-height: 34px;
    box-sizing: border-box;

    &::placeholder {
        color: #737373;
    }
`;
export const FormContainerCheckbox = styled.div`
    font-weight: 400;
    font-size: 11.1533px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #545454;
    display: flex;
    align-items: center;
    margin-top: 10px;

    input {
        margin: 0 9px 0 0;
        width: 18px;
        height: 18px;
    }
`;

export const FormContainerTextarea = styled.textarea`
    width: 100%;
    resize: none;
    height: 107px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    font-weight: 400;
    font-size: 11.1533px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
    padding: 12.5px 11px;
    box-sizing: border-box;
    outline: none;
    font-family: sans-serif;

    &::placeholder {
        color: #737373;
    }
`;

export const MultipleInputsContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 14px;
`;

export const CountryStateContainer = styled.div`
    display: flex;
    gap: 14px;
    & > div {
        flex: 1;
    }
`;

export const PhoneContainer = styled.div`
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    justify-content: space-between;
    margin: 14px 0;

    input {
        border: none;
        border-radius: 5px;
        outline: none;
        padding: 0 11px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.2px;
        color: #334155;

        &::placeholder {
            color: #737373;
        }
    }

    img {
        width: 46px;
        height: 46px;
    }
`;

export const AddBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`;

export const AddBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    height: 40px;
    width: 200px;
    font-weight: 500;
    font-size: 14.3163px;
    line-height: 17px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Label = styled.label`
    cursor: pointer;
`;
export const CheckInput = styled.input`
    cursor: pointer;
    height: 14px;
`;
export const TabContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 36px;
    box-sizing: border-box;
    padding: 23px 16px 0px;
`;
export const Tab = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    padding-bottom: 12px;
    border-bottom: 2px solid ${(props) => (props.active ? "#2D68FE" : "#fff")};
    cursor: pointer;
    color: ${(props) => (props.active ? "#2D68FE" : "#64748B")};
`;

export const LoyaltyContainer = styled.div`
    width: 100%;
    margin-top: 18px;
`;

export const NextBtn = styled.div`
    background: #fff;
    border-radius: 4px;
    height: 40px;
    width: 120px;
    font-weight: 500;
    font-size: 14;
    line-height: 17px;
    color: #253238;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    border: 1px solid #253238;
`;

export const CountryCode = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #334155;
`;
