import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 890px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 12px 24px;

    & > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;
export const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000;
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 24px;
    box-sizing: border-box;
    padding: 12px 24px 24px;
`;

export const Text = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
`;
export const Label = styled(Text)`
    color: #475569;
`;
export const InputContainer = styled(Text)`
    display: flex;
    align-items: center;
    gap: 2px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    width: ${(props) => (props.size === "lg" ? "220px" : props.size === "md" ? "90px" : "50px")};
    box-sizing: border-box;
    padding-left: 4px;
`;

export const Input = styled.input`
    outline: none;
    border: none;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    color: #334155;
    border-radius: 4px;
    width: 100%;
    padding: 4px;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${({ align }) => (align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start")};
    gap: 4px;
    height: 100%;
`;

export const Boldtext = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    color: #334155;
    height: 26px;
`;

export const DiscountContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
`;

export const SymbolDiv = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 2;
    width: 52px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
    border-radius: 4px;
    background: #f1f5f9;
    box-sizing: border-box;
    padding: 3px 2px;
`;
export const Symbol = styled.div`
    height: 100%;
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#2D68FE" : "#f1f5f9")};
    color: ${(props) => (props.active ? "#fff" : "#334155")};
    border-radius: 4px;
    padding: 4px;
`;

export const MiddleBar = styled.div`
    height: 100%;
    width: 1px;
    background: #d9d9d9;
`;

export const InputDiv = styled.div`
    width: 45px;
    // height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    & > input {
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #334155;
        border: none;
        outline: none;
        padding: 4px;
    }
`;

export const AddBtn = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
    border-radius: 4px;
    background-color: #10b981;
    padding: 4px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 34px;

    :hover  {
        opacity: 0.9;
    }
`;
