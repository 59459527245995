import React, { useEffect, useState } from "react";
import { BillIcon, CalendarIcon, CloseModalIcon, ExpenseIcon, SummaryCartDiscountIcon } from "../../../../icons";
import * as S from "./purchase-summary.styles";
import { calendarFormatDate, formatDate } from "../../../../utils/format-date";
import { useSelector } from "react-redux";
import { PURCHASE_TYPE, PURCHASE_TYPE_MAPPING } from "../../../../constants/appConstants";
import onInputChange from "./../../../../utils/handleInputChange";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import PaymentMode from "../../../payment-mode/PaymentMode";
import { toDecimalPlace } from "../../../../utils/global-fn";
import { getPurchaseNetPrice } from "../../../../utils/object-parser";

const PurchaseSummary = ({
    openReturn,
    billRef,
    delExpRef,
    discountRef,
    timeRef,
    totalPrice,
    totalTax,
    returnQuantityCount,
    setSelectedPurchaseData,
    selectedPurchaseData,
    removeSupplier,
}) => {
    const { selectedPurchase } = useSelector((state) => state.purchase);
    const [adjustedCash, setAdjustedCash] = useState(0);

    useEffect(() => {
        if (selectedPurchase) {
            if (selectedPurchase?.net_price_round_off && toDecimalPlace(selectedPurchase?.net_price_round_off - selectedPurchase?.net_price) != 0) {
                setAdjustedCash(selectedPurchase?.net_price_round_off - selectedPurchase?.net_price);
            }
        }
    }, [selectedPurchase]);

    const handleDateChange = (date) => {
        setSelectedPurchaseData({ ...selectedPurchaseData, delivery_date: date });
    };

    const handleInputChange = (event, name) => {
        onInputChange(event, (data) => {
            let { value } = data;
            if (name === "delivery_price") {
                const regex = /^[0-9\b]+$/;
                if (!(value === "" || regex.test(value))) return;

                if (openReturn && value > selectedPurchase?.delivery_price) {
                    value = toDecimalPlace(selectedPurchase?.delivery_price);
                }
            } else if (name === "cart_discount") {
                const regex = /^\d+\.?(\d)?(\d)?$/;
                if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
                    value = value.substr(1);
                }
                if (!(value === "" || regex.test(value))) return;
                if (value > selectedPurchase?.cart_discount) {
                    value = toDecimalPlace(selectedPurchase?.cart_discount);
                }
            }
            setSelectedPurchaseData({ ...selectedPurchaseData, [name]: value });
        });
    };

    return (
        <S.Container>
            <S.Header>{openReturn ? "Return" : PURCHASE_TYPE_MAPPING[selectedPurchase?.type]} Summary</S.Header>
            <S.Body>
                {selectedPurchaseData.supplier && (
                    <S.SupplierDetails>
                        <S.SupplierContent>
                            <S.Name>{selectedPurchaseData.supplier?.name}</S.Name>
                            <S.Mobile>{selectedPurchaseData.supplier?.phone && `(${selectedPurchaseData.supplier?.phone})`}</S.Mobile>
                        </S.SupplierContent>
                        {!openReturn && selectedPurchase?.type === PURCHASE_TYPE.PURCHASE && (
                            <S.ClearContainer>
                                <img src={CloseModalIcon} alt="close" onClick={removeSupplier} />
                            </S.ClearContainer>
                        )}
                    </S.SupplierDetails>
                )}
                <S.PriceDetails>
                    <div>Quantity:</div>
                    {openReturn ? <div>{returnQuantityCount}</div> : <div>{selectedPurchase?.total_quantity}</div>}
                </S.PriceDetails>
                <S.PriceDetails>
                    <div>Subtotal:</div>
                    {openReturn ? <div>₱{toDecimalPlace(totalPrice - totalTax)}</div> : <div>₱{toDecimalPlace(selectedPurchase?.sub_total)}</div>}
                </S.PriceDetails>
                <S.PriceDetails>
                    <div>Tax:</div>
                    {openReturn ? <div>₱{toDecimalPlace(totalTax)}</div> : <div>₱{toDecimalPlace(selectedPurchase?.tax)}</div>}
                </S.PriceDetails>
                {!openReturn &&
                    [PURCHASE_TYPE.PURCHASE, PURCHASE_TYPE.RETURN, PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(
                        selectedPurchase?.type
                    ) && (
                        <S.PriceDetails>
                            <div>Discount:</div>
                            <div>₱{toDecimalPlace(selectedPurchase?.discount)}</div>
                        </S.PriceDetails>
                    )}
                <S.PriceDetails>
                    <div>Bill Total:</div>
                    {openReturn ? <div>₱{toDecimalPlace(totalPrice)}</div> : <div>₱{toDecimalPlace(selectedPurchase?.bill_total)}</div>}
                </S.PriceDetails>
                <S.Dotted></S.Dotted>
                {!openReturn &&
                    [PURCHASE_TYPE.PURCHASE, PURCHASE_TYPE.RETURN, PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(
                        selectedPurchase?.type
                    ) && (
                        <S.PriceDetails>
                            <div>Cart Discount:</div>
                            <div>₱{toDecimalPlace(selectedPurchase?.cart_discount)}</div>
                        </S.PriceDetails>
                    )}
                {!openReturn && selectedPurchase?.type !== PURCHASE_TYPE.DAMAGE_ENTRY && (
                    <S.PriceDetails>
                        <div>Delivery Expense:</div>
                        <div>₱{selectedPurchase?.delivery_expense}</div>
                    </S.PriceDetails>
                )}
                {!openReturn && adjustedCash ? (
                    <S.PriceDetails>
                        <div>Adjusted Cash:</div>
                        <div>₱{toDecimalPlace(adjustedCash)}</div>
                    </S.PriceDetails>
                ) : (
                    <></>
                )}
                {openReturn ? (
                    <S.TotalDetails>
                        <div>Return Total:</div>
                        <div>
                            ₱
                            {toDecimalPlace(
                                totalPrice + parseInt(selectedPurchaseData.delivery_price || 0) - parseFloat(selectedPurchaseData.cart_discount || 0)
                            )}
                        </div>
                    </S.TotalDetails>
                ) : (
                    <S.TotalDetails>
                        <div>{PURCHASE_TYPE_MAPPING[selectedPurchase?.type]} Total:</div>
                        <div>₱{getPurchaseNetPrice(selectedPurchase) - selectedPurchase?.delivery_price + +selectedPurchaseData.delivery_price}</div>
                    </S.TotalDetails>
                )}
                <S.Dotted></S.Dotted>
                {openReturn && (
                    <>
                        <S.BoldLabel>
                            <div>Earlier Cart Discount Added:</div>
                            <div>₱{toDecimalPlace(selectedPurchase?.cart_discount)}</div>
                        </S.BoldLabel>

                        <S.NewContainer>
                            <S.Label>
                                Subtract Cart Discount<div>(F8)</div>
                            </S.Label>
                            <S.FieldContainer onClick={() => discountRef.current?.focus()}>
                                <input
                                    type="text"
                                    onChange={(e) => handleInputChange(e, "cart_discount")}
                                    value={selectedPurchaseData.cart_discount}
                                    ref={discountRef}
                                />
                                <S.Line></S.Line>
                                <img src={SummaryCartDiscountIcon} alt="" />
                            </S.FieldContainer>
                        </S.NewContainer>

                        <S.BoldLabel>
                            <div>Earlier Delivery Expense Added:</div>
                            <div>₱{toDecimalPlace(selectedPurchase?.delivery_expense)}</div>
                        </S.BoldLabel>
                    </>
                )}

                {selectedPurchase?.type !== PURCHASE_TYPE.DAMAGE_ENTRY && (
                    <S.NewContainer>
                        <S.Label>
                            {openReturn ? "Subtract" : "Add"} Delivery Expense<div>(F5)</div>
                        </S.Label>
                        <S.FieldContainer onClick={() => delExpRef.current?.focus()}>
                            <input
                                type="text"
                                onChange={(e) => handleInputChange(e, "delivery_price")}
                                value={selectedPurchaseData.delivery_price}
                                ref={delExpRef}
                            />
                            <S.Line></S.Line>
                            <img src={ExpenseIcon} alt="" />
                        </S.FieldContainer>
                    </S.NewContainer>
                )}

                <S.NewContainer>
                    <S.Label>
                        Add Bill Number<div>(F7)</div>
                    </S.Label>
                    <S.FieldContainer onClick={() => billRef.current?.focus()}>
                        <input type="text" onChange={(e) => handleInputChange(e, "bill_no")} value={selectedPurchaseData.bill_no} ref={billRef} />
                        <S.Line></S.Line>
                        <img src={BillIcon} alt="" />
                    </S.FieldContainer>
                </S.NewContainer>

                {selectedPurchase?.type !== PURCHASE_TYPE.DAMAGE_ENTRY && (
                    <S.DeliveryDetails>
                        <S.Label>
                            Select Delivery Date<div>(F3)</div>
                        </S.Label>
                        <S.FieldContainer ref={timeRef} onClick={() => timeRef.current?.querySelector("input").click()}>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(selectedPurchaseData.delivery_date))}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="summary-calendar"
                                minDate={selectedPurchaseData.created_at || new Date()}
                            />
                            <S.Line></S.Line>
                            <img src={CalendarIcon} alt="calendar" />
                        </S.FieldContainer>
                    </S.DeliveryDetails>
                )}
                {!openReturn &&
                    selectedPurchase &&
                    ![PURCHASE_TYPE.DAMAGE_ENTRY, PURCHASE_TYPE.PURCHASE_PROFORMA, PURCHASE_TYPE.PURCHASE_ORDER].includes(selectedPurchase?.type) && (
                        <>
                            <S.Dotted></S.Dotted>
                            <PaymentMode bill={selectedPurchase} />
                        </>
                    )}
            </S.Body>
        </S.Container>
    );
};

export default PurchaseSummary;
