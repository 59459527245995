import React, { useRef, useState, useEffect } from "react";
import * as S from "./style";
import {
    SCREENS,
    addProductToPP,
    decrementQuantityInPP,
    removeProductFromPP,
    removeSelectedSupplierPP,
    updateProductFromPP,
} from "../../../store/purchaseproforma-slice";
import { useDispatch, useSelector } from "react-redux";
import PurchaseProformaItem from "./PurchaseProformaItem";
import store from "../../../store/store";
import { setOpenProductDrawer, setProductDrawerData, setProductDrawerType } from "../../../store/productdrawer-slice";
import { PRODUCT_DRAWER_TYPE } from "../../../constants/appConstants";
import { getProductsBySKU } from "../../../api/local/productApi";
import { toDecimalPlace } from "../../../utils/global-fn";
import CartBottomBar from "../../cart-bottom-bar/CartBottomBar";
import { captureException } from "../../../crash-reporting";

const PurchaseProformaTable = ({ handleBtnClick }) => {
    const { PP_currentScreen, PP_products, PP_selectedSupplier } = useSelector((state) => state.purchaseProforma);
    const dispatch = useDispatch();
    const rowsRefs = useRef([]);
    const [activeRow, _setActiveRow] = useState(null);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        if (PP_products.length > 0) {
            getProductsData();
        }
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const getProductsData = async () => {
        const payload = {
            ids: PP_products.map((p) => p.sku),
        };
        try {
            let res = await getProductsBySKU(payload);
            if (res.status === 200) {
                let resProducts = res.data?.entity;
                resProducts.forEach((product) => {
                    dispatch(updateProductFromPP(product));
                });
            }
        } catch (error) {
            captureException(error);
        }
    };

    const getCurrentIdx = () => {
        return rowsRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const handleDetectKeyDown = (e) => {
        let products = store.getState().purchaseProforma.PP_products;

        if (e.keyCode === 46) {
            //DELETE Key
            let product = products[activeRowRef.current];
            if (activeRowRef.current >= 0 && product) {
                e.preventDefault();
                dispatch(removeProductFromPP(product));
            }
        } else if (e.keyCode === 38) {
            //UP Arrow key
            let idx = getCurrentIdx();
            let product = products[idx];
            if (idx >= 0 && product) {
                e.preventDefault();
                dispatch(addProductToPP(product));
            }
        } else if (e.keyCode === 40) {
            //DOWN Arrow key
            let idx = getCurrentIdx();
            let product = products[idx];
            if (idx >= 0 && product) {
                e.preventDefault();
                dispatch(decrementQuantityInPP(product));
            }
        } else if (e.keyCode === 33) {
            //Pg Up key
            if (activeRowRef.current > 0) {
                e.preventDefault();
                setActiveRow(activeRowRef.current - 1);
                rowsRefs.current[activeRowRef.current].focus();
            }
        } else if (e.keyCode === 34) {
            //Pg Down key
            if (activeRowRef.current < products.length - 1) {
                e.preventDefault();
                setActiveRow(activeRowRef.current + 1);
                rowsRefs.current[activeRowRef.current].focus();
            }
        }
    };

    const handleEditProduct = (product) => {
        dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.EDIT));
        dispatch(setProductDrawerData(product));
        dispatch(setOpenProductDrawer());
    };

    const handleSupplierRemove = () => {
        dispatch(removeSelectedSupplierPP());
    };

    return (
        <S.Wrapper isFull={PP_currentScreen === SCREENS.PURCHASE_TABLE}>
            <S.Table hasBottom={PP_products?.length}>
                <S.TableHeader>
                    <S.TableHeaderData flexValue="0.2">SN.</S.TableHeaderData>
                    <S.TableHeaderData flexValue="1">Item Description</S.TableHeaderData>

                    <S.TableHeaderData flexValue="0.5" center>
                        MRP
                    </S.TableHeaderData>
                    {PP_currentScreen === SCREENS.PURCHASE_TABLE && (
                        <S.TableHeaderData flexValue="0.6" center>
                            Unit
                        </S.TableHeaderData>
                    )}

                    <S.TableHeaderData flexValue="0.5" center>
                        Qty
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="1" center>
                        Purchase Price
                    </S.TableHeaderData>

                    <S.TableHeaderData flexValue="1" center>
                        Sale Price
                    </S.TableHeaderData>
                    <S.TableHeaderData flexValue="0.6" center>
                        Tax
                    </S.TableHeaderData>
                    {PP_currentScreen === SCREENS.PURCHASE_TABLE && (
                        <>
                            <S.TableHeaderData flexValue="0.6" center>
                                Discount
                            </S.TableHeaderData>
                            <S.TableHeaderData flexValue="0.35" center>
                                More Info
                            </S.TableHeaderData>
                            <S.TableHeaderData flexValue="0.5" center>
                                Subtotal
                            </S.TableHeaderData>
                        </>
                    )}
                    {<S.TableHeaderData flexValue="0.25"></S.TableHeaderData>}
                </S.TableHeader>
                <S.TableBody>
                    {PP_products?.map((item, idx) => (
                        <React.Fragment key={idx}>
                            <PurchaseProformaItem
                                product={item}
                                idx={idx}
                                activeRow={activeRow}
                                setActiveRow={setActiveRow}
                                focusRef={rowsRefs}
                                checkProduct={item.checkProduct}
                                handleEditProduct={handleEditProduct}
                            />
                            <S.Bar></S.Bar>
                        </React.Fragment>
                    ))}
                </S.TableBody>
            </S.Table>
            {PP_products?.length ? (
                <CartBottomBar
                    data={PP_selectedSupplier}
                    expand={PP_currentScreen === SCREENS.PURCHASE_TABLE}
                    onExpand={() => handleBtnClick("back")}
                    onProceed={() => handleBtnClick("proceed")}
                    onDataRemove={handleSupplierRemove}
                />
            ) : (
                <></>
            )}
        </S.Wrapper>
    );
};

export default PurchaseProformaTable;
