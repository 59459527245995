import styled from "@emotion/styled/macro";

export const Avatar = styled.div`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    border-radius: 100%;

    & .tooltip {
        visibility: hidden;
        min-width: 100px !important;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        padding: 3px;
        position: absolute;
        z-index: 1;
        bottom: 110%;
        font-size: 12px;
        text-transform: none;

        & ::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }

    :hover .tooltip {
        visibility: visible;
    }
`;
