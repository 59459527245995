import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
`;

export const Heading = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #263238;
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const DatePickerContainer = styled.div`
    position: absolute;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    right: 0;
    margin-right: 16px;
`;

export const DateChooserSelect = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #475569;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 40px;
    cursor: pointer;
    width: 280px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;

    img {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
`;
export const TimePeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 8px;
    gap: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    cursor: pointer;
    border: 1px solid #e2e8f0;
    img {
        height: 16px;
        width: 16px;
        margin-right: 8px;
    }
`;
export const PeriodMenu = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 1px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
`;
export const PeriodRow = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
`;
export const TimePeriodContainer = styled.div`
    position: relative;
`;

export const Body = styled.div`
    width: 100%;
    height: calc(100vh - 213px);
    display: flex;
    justify-content: space-between;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
`;

export const BottomBar = styled.div`
    padding: 20px 16px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 50px;
`;
export const BlueText = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #1d71ff;
`;

export const ReportBody = styled.div`
    width: 50%;
    box-sizing: border-box;
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 44px;
`;

export const ChartBody = styled.div`
    width: 50%;
    box-sizing: border-box;
    padding: 21px 31px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ReportRow = styled.div`
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const NameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #455a64;

    & img {
        width: 24px;
        height: 24px;
    }
`;

export const Price = styled.div`
    background: #ccdfff;
    border-radius: 4px;
    padding: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #2d68fe;
`;
export const ChartContainer = styled.div`
    width: 370px;
    height: 370px;
    margin-top: 22px;
`;

export const ProfitContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
`;

export const ProfitText = styled.div`
    color: #263238;
`;
export const ProfitValue = styled.div`
    color: ${(props) => (props.profit < 0 ? "#EE1111" : "#17B288")};
`;

export const EmptyDataContainer = styled.div`
    width: 340px;
    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #455a64;
`;
export const LegendRow = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`;
export const LegendBox = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
`;
export const LegendTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #475569;
    text-decoration: ${(props) => props.strike && "line-through"};
`;
export const LegendContainer = styled.div`
    width: 450px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    margin-top: 12px;
`;
