import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBrands } from "../api/local/brandApi";
import { captureException } from "../crash-reporting";

const initialState = {
    loading: false,
    brands: [],
    error: "",
    inventorySelectedBrand: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllBrands = createAsyncThunk("brand/fetchAllBrands", (search) =>
    getAllBrands(search)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const brandSlice = createSlice({
    name: "brand",
    initialState,
    reducers: {
        setSelectedBrand: (state, action) => {
            state.inventorySelectedBrand = action.payload;
        },
        removeSelectedBrand: (state) => {
            state.inventorySelectedBrand = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllBrands.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllBrands.fulfilled, (state, action) => {
            state.loading = false;
            state.brands = action.payload ?? [];
            state.error = "";
        });
        builder.addCase(fetchAllBrands.rejected, (state, action) => {
            state.loading = false;
            state.brands = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default brandSlice.reducer;
export const { setSelectedBrand, removeSelectedBrand } = brandSlice.actions;
