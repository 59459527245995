import React, { useContext, useEffect, useState } from "react";
import * as S from "./style";
import { Modal } from "@mui/material";
import { CloseModalIcon } from "../../../icons";
import CustomButton from "../../../components/custom-button/CustomButton";
import { COLOR, MONTHS } from "../../../constants/appConstants";
import CustomSelect from "../../../components/product-drawer/custom-select/custom-select";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import moment from "moment";

//minimum
// april 2023
// 23-24
const Type = {
    month: "month",
    year: "year",
};

const startYearForMonthSelect = 2023;
const startYearForYearSelect = 2023;

const initialPeriod = {
    month: null,
    year: null,
};

const TimeModal = ({ open, onClose, type, onSubmit, name }) => {
    const [yearsList, setYearsList] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(initialPeriod);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        if (type === "month") {
            getYears(startYearForMonthSelect);
            let month = new Date().getMonth();
            let year = new Date().getFullYear();
            setSelectedPeriod({
                month: MONTHS[month],
                year: {
                    name: year,
                    value: year,
                },
            });
        } else if (type === "year") {
            getFinancialYears(startYearForYearSelect);
            let year = new Date().getFullYear();
            setSelectedPeriod({
                month: null,
                year: {
                    name: `${year}-${year + 1}`,
                    value: [year, year + 1],
                },
            });
        }
    }, [type]);

    const getYears = (startYear) => {
        var currentYear = new Date().getFullYear(),
            years = [],
            id = 1;
        while (startYear <= currentYear) {
            let year = {
                id: id,
                name: startYear,
                value: startYear,
            };
            years.push(year);

            id++;
            startYear++;
        }
        setYearsList(years);
    };

    const getFinancialYears = (startYear) => {
        var currentYear = new Date().getFullYear(),
            years = [],
            id = 1;
        while (startYear <= currentYear) {
            let year = {
                id: id,
                name: `${startYear}-${startYear + 1}`,
                value: [startYear, startYear + 1],
            };
            years.push(year);

            id++;
            startYear++;
        }
        setYearsList(years);
    };

    const getMonths = (year) => {
        if (year === 2023) {
            return MONTHS.slice(3);
        } else {
            return MONTHS;
        }
    };

    const handleSelectChange = (key, item) => {
        setSelectedPeriod({ ...selectedPeriod, [key]: item });
    };

    const handleSubmit = () => {
        let startDate;
        let endDate;
        if (type === "month") {
            if (!selectedPeriod.year) {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Please select a year",
                });
                return;
            }
            if (!selectedPeriod.month) {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Please select a month",
                });
                return;
            }

            startDate = new Date(`${selectedPeriod.month?.value}, ${selectedPeriod.year?.value}`).getTime();
            endDate = moment(startDate).endOf("month").unix() * 1000;
        } else if (type === "year") {
            if (!selectedPeriod.year) {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Please select a year",
                });
                return;
            }

            startDate = new Date(`April, ${selectedPeriod.year?.value[0]}`).getTime();
            endDate = new Date(`March, ${selectedPeriod.year?.value[1]}`).getTime();
            endDate = moment(endDate).endOf("month").unix() * 1000;
        }

        const params = {
            start_date: startDate,
            end_date: endDate,
        };

        onSubmit(params);
        setSelectedPeriod(initialPeriod);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Header>
                    <div>Select Period for {name}</div>
                    <img src={CloseModalIcon} alt="close" onClick={onClose} />
                </S.Header>
                <S.Body>
                    <S.BodyContent>
                        <CustomSelect
                            type="year"
                            disableSearch
                            data={yearsList}
                            onSelectChange={handleSelectChange}
                            selectedOption={selectedPeriod.year?.name ?? "Select Year"}
                        />
                    </S.BodyContent>
                    {type === "month" && (
                        <S.BodyContent>
                            <CustomSelect
                                type="month"
                                disableSearch
                                data={getMonths(selectedPeriod.year?.value)}
                                onSelectChange={handleSelectChange}
                                selectedOption={selectedPeriod.month?.name ?? "Select Month"}
                            />
                        </S.BodyContent>
                    )}
                </S.Body>
                <S.Footer>
                    <CustomButton label="Done" color={COLOR.BLUE} fill onClick={handleSubmit} />
                </S.Footer>
            </S.Wrapper>
        </Modal>
    );
};

export default TimeModal;
