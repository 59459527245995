import React, { useState } from "react";
import * as S from "./DropDown.style";
import AddNewCustomerModal from "./../add-new-customer-modal/add-new-customer-modal";

const CustDropDown = ({ customers, openNewCustModal, setOpenNewCustModal }) => {
    // const [openNewCustModal, setOpenNewCustModal] = useState({
    //     state: false,
    //     phoneNumber: null,
    // });
    return (
        <S.Container>
            {Array.isArray(customers) && customers.length > 0 ? (
                customers.map((e, i) => (
                    <S.CustRow
                        key={i}
                        onClick={() => setOpenNewCustModal({ state: true, phoneNumber: e.phone })}
                    >
                        <S.CustName>{e.name}</S.CustName>
                        <S.CustDet>
                            <div>Last sale : ₱ {e.last_sale?.total_sale_price || 0}</div>
                            <S.Divider></S.Divider>
                            <div>{e.last_sale?.products.length || 0} SKUs</div>
                        </S.CustDet>
                    </S.CustRow>
                ))
            ) : (
                <S.Empty>No results found</S.Empty>
            )}
            <AddNewCustomerModal
                open={openNewCustModal.state}
                onClose={() => setOpenNewCustModal({ state: false, phoneNumber: null })}
                phoneNumber={openNewCustModal.phoneNumber}
            />
        </S.Container>
    );
};

export default CustDropDown;
