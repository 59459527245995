import { Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BackArrowIcon, CloseModalIcon, PlusGrayIcon } from "../../icons";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import CustomInput from "../product-drawer/custom-input/custom-input";
import InputLabel from "../product-drawer/input-label/input-label";
import { validEmail, validPincode, validURL } from "../../utils/validity-fns";
import { addSupplier, getSupplier, getSupplierTransactionCount, updateSupplier } from "../../api/local/supplierApi";
import { fetchAllSuppliers } from "../../store/supplier-slice";
import { setSearchParams, setSelectedSupplier } from "../../store/purchase-slice";

import * as S from "./new-supplier-modal.styles";
import PaymentContent from "./payment-content/payment-content";
import SupplierContent from "./supplier-content/supplier-content";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Transactions from "./../customers/customer-transactions/Transactions";
import { captureException } from "../../crash-reporting";
import { useLocation } from "react-router-dom";
import { setReturnSelectedSupplier } from "../../store/purchasereturn-slice";
import { setSelectedSupplierPP } from "../../store/purchaseproforma-slice";
import { COLOR, PURCHASE_TYPE } from "../../constants/appConstants";
import CustomButton from "../custom-button/CustomButton";
import PartyPayments from "../party-payments/PartyPayments";

const initialDisplayData = {
    brands: [],
    name: "",
    email: "",
    phone: "",
    website: "",
    gst_number: "",
    description: "",
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    country: "Philippines",
    pin_code: "",
    payment_accounts: [],
};
const initialErrorInput = {
    gst_number: false,
};

const NewSupplierModal = ({ supplierId, open, onClose, onSave, closeModal, clear, txn }) => {
    const [imgSrc, setImgSrc] = useState(null);
    const [displayData, setDisplayData] = useState(initialDisplayData);
    const [activeTab, setActiveTab] = useState(0);
    const [txnCount, setTxnCount] = useState(null);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const location = useLocation();
    const [errorInput, setErrorInput] = useState(initialErrorInput);

    useEffect(() => {
        setActiveTab(0);
        if (supplierId) {
            // dispatch(
            //     setSearchParams({
            //         supplier_id: supplierId,
            //         status: "PUBLISH",
            //         type: `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.RETURN},${PURCHASE_TYPE.DAMAGE_ENTRY}`,
            //     })
            // );

            getTransactionCount();
            if (txn) {
                setActiveTab(2);
            } else {
                setActiveTab(0);
            }
            getSupplierData(supplierId);
        } else {
            setActiveTab(0);
            setDisplayData(initialDisplayData);
        }
    }, [open, supplierId]);

    const onChangeImage = (event) => {
        if (event.target.files[0]) {
            // setPicture(event.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgSrc(reader.result);
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        const {
            brands = [],
            name = "",
            email = "",
            phone = "",
            gst_number = "",
            website = "",
            description = "",
            line_1 = "",
            line_2 = "",
            city = "",
            state = "",
            country = "",
            pin_code = "",
            payment_accounts = [],
        } = displayData;
        if (!name) {
            handleSnackbarDetails({
                show: true,
                title: "Name is required",
                type: "error",
            });
            return;
        }

        // if (
        //     (address_1 || address_2 || city || state || pincode) &&
        //     (!address_1 || !address_2 || !city || !state || !pincode)
        // ) {
        //     handleSnackbarDetails({
        //         show: true,
        //         title: "Please fill all the address fields",
        //         type: "error",
        //     });
        //     return;
        // }

        if (pin_code && !validPincode(pin_code)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid pincode",
                type: "error",
            });
            return;
        }

        if (email && !validEmail(email)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid email",
                type: "error",
            });
            return;
        }

        if (website && !validURL(website)) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter a valid website",
                type: "error",
            });
            return;
        }

        if (phone) {
            if (phone?.length !== 10) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter a valid phone number",
                    type: "error",
                });
                return;
            }

            if (phone?.includes("+")) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter phone number without country code",
                    type: "error",
                });
                return;
            }
        }

        const payload = {
            name: name,
        };
        const address = {};
        if (phone) payload.phone = phone;
        if (email) payload.email = email;
        if (description) payload.description = description;
        payload.brands_id = brands.map((brand) => brand.id);
        if (line_1) address.line_1 = line_1;
        if (line_2) address.line_2 = line_2;
        if (city) address.city = city;
        if (state) address.state = state;
        if (pin_code) address.pin_code = pin_code;
        if (!isEmpty(address)) {
            address.country = country;
            payload.address = address;
        }

        if (gst_number) payload.gst_number = gst_number;
        if (website) payload.website = website;
        if (payment_accounts.length) payload.payment_accounts = payment_accounts;

        if (supplierId) {
            try {
                let res = await updateSupplier(supplierId, payload);
                if (res.status === 200) {
                    dispatch(setSelectedSupplier(res.data));
                    dispatch(fetchAllSuppliers());
                    onClose();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Success",
                        subtitle: `${payload.name} updated successfully`,
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (error) {
                captureException(error);
                handleSnackbarDetails({
                    show: true,
                    title: error.message,
                    type: "error",
                });
            }
        } else {
            try {
                let res = await addSupplier(payload);
                if (res.status === 200) {
                    handleSupplierSelect(res.data);
                    dispatch(fetchAllSuppliers());
                    if (closeModal instanceof Function) closeModal();
                    onClose();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Success",
                        subtitle: `${payload.name} added successfully`,
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (error) {
                captureException(error);
                handleSnackbarDetails({
                    show: true,
                    title: error.message,
                    type: "error",
                });
            }
        }
    };

    const handleSupplierSelect = (item) => {
        let path = location.pathname.split("/")[2];
        if (path === "new-purchase-return") {
            dispatch(setReturnSelectedSupplier(item));
        } else if (path === "purchase-proforma") {
            dispatch(setSelectedSupplierPP(item));
        } else if (path === "new-purchase") {
            dispatch(setSelectedSupplier(item));
        } else if (path === "view-purchase") {
            if (onSave) onSave(item);
        }
    };

    const handleInputChange = (key, val) => {
        setDisplayData({ ...displayData, [key]: val });
    };
    async function getTransactionCount() {
        try {
            let res = await getSupplierTransactionCount(supplierId);
            if (res.status === 200) {
                setTxnCount(res.data);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    }

    const getSupplierData = async (id) => {
        try {
            let res = await getSupplier(id);
            if (res.status === 200) {
                let data = res.data?.entity;
                let address = data.address;
                // let newdata = Object.assign(displayData, data, data.address && data.address);
                setDisplayData({
                    ...displayData,
                    ...data,
                    ...(address && {
                        line_1: address.line_1,
                        line_2: address.line_2,
                        pin_code: address.pin_code,
                        city: address.pin_code,
                        state: address.state,
                        country: address.country,
                    }),
                });
            } else {
                throw new Error(res.data);
            }
        } catch (error) {
            captureException(error);
        }
    };
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    <S.HeaderText>{supplierId ? "Update" : "Add new"} Supplier</S.HeaderText>
                    <img onClick={onClose} src={CloseModalIcon} alt="close" />
                </S.Header>
                <S.Body noPadding={activeTab === 2}>
                    <S.TabsContainer>
                        <S.Tab onClick={() => setActiveTab(0)} active={activeTab === 0}>
                            Supplier Information
                        </S.Tab>
                        <S.Tab onClick={() => setActiveTab(1)} active={activeTab === 1}>
                            Payment Information
                        </S.Tab>
                        {supplierId && (
                            <>
                                <S.Tab onClick={() => setActiveTab(2)} active={activeTab === 2}>
                                    Transactions
                                </S.Tab>
                                <S.Tab onClick={() => setActiveTab(3)} active={activeTab === 3}>
                                    Payments
                                </S.Tab>
                            </>
                        )}
                    </S.TabsContainer>

                    {activeTab === 0 && (
                        <SupplierContent
                            displayData={displayData}
                            setDisplayData={setDisplayData}
                            errorInput={errorInput}
                            setErrorInput={setErrorInput}
                        />
                    )}
                    {activeTab === 1 && <PaymentContent displayData={displayData} setDisplayData={setDisplayData} />}
                    {activeTab === 2 && <Transactions type="supplier" txnCount={txnCount} supplierId={supplierId} onClose={onClose} clear={clear} />}
                    {activeTab === 3 && <PartyPayments type="supplier" id={supplierId} txnCount={txnCount} />}
                </S.Body>
                {[0, 1].includes(activeTab) && (
                    <S.Footer>
                        <CustomButton label="Save and Continue" color={COLOR.BLUE} fill={true} onClick={handleSubmit} size="lg" />
                    </S.Footer>
                )}
            </S.Container>
        </Modal>
    );
};

export default NewSupplierModal;
