import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CANCEL_REASONS, ONLINE_ORDERS_FOCUS } from "./../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { cancelOnlineSale } from "../../../api/local/onlineSale-api";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { removeAllPackingProducts, removeFulifillingOrder, removeSingleOrder, setScreenFocus } from "../../../store/onlinesale-slice";
import { fetchAllOnlineSales } from "./../../../store/onlinesale-slice";
import { useEffect } from "react";
import { useRef } from "react";
import Lottie from "lottie-react";
import ButtonLoader from "../../../animations/button-loader.json";
import { captureException } from "../../../crash-reporting";

const ConfirmationDialog = ({ open, onClose, setActiveOrderRow }) => {
    const [cancels, _setCancels] = useState(CANCEL_REASONS);
    const cancelsRef = useRef(cancels);
    const setCancels = (data) => {
        cancelsRef.current = data;
        _setCancels(data);
    };
    const [btnLoader, setBtnLoader] = useState(false);
    const [otherReason, setOtherReason] = useState("");
    const { order, params } = useSelector((state) => state.onlineSale);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleCancelOrder();
            }
        }
    };

    const handleCancelReasons = (index) => {
        let newReasons = cancels.map((item, idx) => (index === idx ? { ...item, value: !item.value } : item));
        setCancels(newReasons);
    };

    const handleCancelOrder = async () => {
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
            reason: cancelsRef.current
                .filter((item) => item.value === true)
                .map((_item) => (_item.name === "Other (Please explain below)" ? otherReason : _item.name)),
        };
        if (!payload.reason.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a reason",
                type: "error",
            });
            return;
        }
        try {
            let res = await cancelOnlineSale(order.id, payload);
            if (res.status === 200) {
                dispatch(removeSingleOrder());
                dispatch(removeAllPackingProducts({ order_id: res.data.id }));
                dispatch(removeFulifillingOrder());
                dispatch(fetchAllOnlineSales(params));
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
                setActiveOrderRow(0);
                handleSnackbarDetails({
                    show: true,
                    title: "Order canceled successfully",
                    type: "success",
                });
                onClose();
                setBtnLoader(false);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Container>
                <S.Title>Why are you cancelling the order?</S.Title>
                <S.TextContainer>
                    {cancels.map((item, index) => (
                        <div key={item.id}>
                            <input type="checkbox" id={item.name} checked={item.value} onClick={() => handleCancelReasons(index)} />
                            <label htmlFor={item.name}>{item.name}</label>
                        </div>
                    ))}
                    <S.Textarea
                        placeholder="Add other reasons"
                        name="other"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                    ></S.Textarea>
                </S.TextContainer>
                <S.ButtonContainer>
                    {btnLoader ? (
                        <S.LoaderBtn>
                            <div>
                                <Lottie animationData={ButtonLoader} />
                            </div>
                        </S.LoaderBtn>
                    ) : (
                        <S.BlueBtn onClick={handleCancelOrder}>Submit</S.BlueBtn>
                    )}
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default ConfirmationDialog;
