import styled from "@emotion/styled/macro";

export const Container = styled.div`
    height: calc(100vh-56px);
    //   padding: 12px 24px 0;
    width: 100%;
`;

export const Header = styled.div`
    margin: 14px 16px 0;
`;
export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;
export const SubTopBar = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const DateRangeChooser = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    width: 240px;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5e6368;
`;

export const DateContainer = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;
export const Date = styled.div`
    // margin-right: 6px;
`;
export const HeaderDetails = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 12px;
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e293b;
`;

export const BtnContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const Body = styled.div`
    height: ${(props) => (props.returnBody ? "calc(100vh - 189px)" : "calc(100vh - 142px)")};
    display: flex;
    width: 100%;
    gap: 16px;
    padding: 0 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
`;

export const DatePickerContainer = styled.div`
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 1;
`;

export const BottomBar = styled.div`
    position: relative;
    width: 100%;
    // margin-top: -18px;
    display: flex;
    background: white;
    padding: 11px 16px;
    justify-content: space-between;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    z-index: 1;
    box-sizing: border-box;
`;

export const SupplierName = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
    background: rgba(59, 130, 246, 0.1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;

    img {
        height: 20px;
        width: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
`;

export const BottomBarLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const Back = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        height: 24px;
        width: 24px;
        margin-right: 6px;
    }
`;
export const ClearBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 10px;
    color: #ff4343;
    border: 1px solid #ff4343;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    background: #fff;
    cursor: pointer;
    // margin-right: 55px;
`;
export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #334155;
    height: 100%;
    width: 100%;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
`;
export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const NewReturnHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
