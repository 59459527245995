import React from "react";
import * as S from "../style/style";
import { ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";

const ProductDrawer = ({ data, onProductClick }) => {
    return (
        <S.ProductDrawerWrapper>
            {data?.map((item) => (
                <S.ProductRow onClick={() => onProductClick(item.upc, item.name)}>
                    <S.ProductImage>
                        <img
                            src={item.gallery?.[0] || ProductPlaceholderIcon}
                            alt="product icon"
                            onError={(e) => {
                                e.target.src = WifiDisconnectedIcon;
                            }}
                        />
                    </S.ProductImage>
                    <S.TextContainer>
                        <S.ProductSku>
                            <div>SKU-</div>
                            <div>{item.sku}</div>
                        </S.ProductSku>
                        <S.ProductName>{item.name}</S.ProductName>
                    </S.TextContainer>
                </S.ProductRow>
            ))}
        </S.ProductDrawerWrapper>
    );
};

export default ProductDrawer;
