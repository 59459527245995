import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
    getDecimalPlacesCount,
    getPurchaseObjectHash,
    getPurchasePrice,
    getPurchaseProductSubTotal,
    getTax,
    getTaxValue,
    getUnit,
    hasDecimal,
    toDecimalPlace,
} from "../utils/global-fn";

export const SCREENS = {
    PURCHASE_TABLE: 0,
    PURCHASE_SUMMARY: 1,
};

const initialState = {
    purchaseReturnProducts: [],
    purchaseReturnQuantity: 0,
    purchaseReturnTotal: 0,
    purchaseReturnTaxTotal: 0,
    purchaseReturnCartTotal: 0,
    billNo: "",
    createdDate: null,
    deliveryDate: null,
    deliveryExpense: "",
    returnSelectedSupplier: null,
    selectedDraftPurchaseReturn: null,
    returnCurrentFocus: 0,
    currentScreen: SCREENS.PURCHASE_SUMMARY,
};

const purchaseReturnSlice = createSlice({
    name: "purchaseReturn",
    initialState,
    reducers: {
        addProductsToPurchaseReturn: (state, action) => {
            state.purchaseReturnProducts = action.payload;
            state.currentFocus = 0;
        },
        addProductToPurchaseReturn: (state, action) => {
            const itemIndex = state.purchaseReturnProducts.findIndex((item) => item.sku === action.payload.sku);
            if (itemIndex >= 0) {
                state.purchaseReturnProducts[itemIndex].quantity = parseFloat(state.purchaseReturnProducts[itemIndex].quantity) + 1;
                state.returnCurrentFocus = itemIndex;
            } else {
                state.purchaseReturnProducts.push({
                    ...action.payload,
                    quantity: action.payload.quantity ? action.payload.quantity : 1,
                    purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                    unit: getUnit(action.payload),
                    manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                    expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                    checkProduct: {
                        ...action.payload,
                        purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                        hashKey: getPurchaseObjectHash(action.payload),
                    },
                });
                state.returnCurrentFocus = state.purchaseReturnProducts.length - 1;
            }
        },
        updateProductFromPurchaseReturn: (state, action) => {
            const newPurchaseReturnProducts = state.purchaseReturnProducts.map((item) => {
                if (item.sku === action.payload.sku) {
                    if (action.payload.checkProduct) {
                        // for general slice product update
                        return { ...item, ...action.payload };
                    } else {
                        // update slice product after fetched from db
                        let newHash = getPurchaseObjectHash(action.payload);
                        if (item.checkProduct?.hashKey === newHash) {
                            let updatedCheckProduct = { ...item.checkProduct, stock: action.payload.stock };
                            return { ...item, name: action.payload.name, checkProduct: updatedCheckProduct };
                        } else {
                            let updateCheckProduct = {
                                ...action.payload,
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                hashKey: newHash,
                            };
                            return {
                                ...item,
                                ...action.payload,
                                purchase_price: action.payload.purchase_price ? toDecimalPlace(action.payload.purchase_price) : 0,
                                unit: getUnit(action.payload),
                                manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                                expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                                checkProduct: updateCheckProduct,
                            };
                        }
                    }
                } else {
                    return item;
                }
            });
            state.purchaseReturnProducts = newPurchaseReturnProducts;
        },
        removeProductFromPurchaseReturn: (state, action) => {
            const idx = state.purchaseReturnProducts.findIndex((item) => item.sku === action.payload.sku);
            if (idx >= 0) {
                const newPurchaseReturnProducts = state.purchaseReturnProducts.filter((item) => item.sku !== action.payload.sku);
                state.purchaseReturnProducts = newPurchaseReturnProducts;
                if (idx >= newPurchaseReturnProducts.length - 1) {
                    state.returnCurrentFocus = newPurchaseReturnProducts.length - 1;
                }
            }
        },
        decrementQuantityPurchaseReturn: (state, action) => {
            const itemIndex = state.purchaseReturnProducts.findIndex((item) => item.sku === action.payload.sku);
            if (state.purchaseReturnProducts[itemIndex].quantity > 1) {
                let product = state.purchaseReturnProducts[itemIndex];
                let qty = parseFloat(product.quantity) - 1;

                if (hasDecimal(qty)) {
                    let ct = getDecimalPlacesCount(qty);
                    if (ct > 1) {
                        ct = 2;
                    }
                    qty = qty.toFixed(ct);
                }
                state.purchaseReturnProducts[itemIndex].quantity = parseFloat(qty);
            } else if (state.purchaseReturnProducts[itemIndex].quantity === 1) {
                toast.error("Minimum quantity reached", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        },
        getPurchaseReturnTotals: (state, action) => {
            let { total, quantity, tax } = state.purchaseReturnProducts.reduce(
                (purchaseTotal, purchaseItem) => {
                    const { purchase_price, quantity, purchase_price_with_tax } = purchaseItem;
                    let tax = getTax(purchaseItem);
                    let qty = parseFloat(quantity);
                    let purchasePrice = parseFloat(purchase_price);
                    let { itemTotal, taxTotal } = getPurchaseProductSubTotal(purchaseItem);

                    let itemTaxTotal = 0;
                    if (qty > 0) {
                        itemTaxTotal = taxTotal;
                    }
                    purchaseTotal.total += itemTotal;
                    purchaseTotal.quantity += qty;
                    purchaseTotal.tax += itemTaxTotal;

                    return purchaseTotal;
                },
                {
                    total: 0,
                    quantity: 0,
                    tax: 0,
                }
            );
            total = parseFloat(total);
            state.purchaseReturnQuantity = quantity;
            state.purchaseReturnTotal = total;
            state.purchaseReturnTaxTotal = tax;

            state.purchaseReturnCartTotal = toDecimalPlace(total + (+state.deliveryExpense || 0));
        },
        removeAllPurchaseReturnProducts: (state) => {
            state.purchaseReturnProducts = [];
        },

        setReturnSelectedSupplier: (state, action) => {
            state.returnSelectedSupplier = action.payload;
        },

        removeReturnSelectedSupplier: (state) => {
            state.returnSelectedSupplier = null;
        },
        setSelectedDraftPurchaseReturn: (state, action) => {
            state.selectedDraftPurchaseReturn = action.payload;
        },

        removeSelectedDraftPurchaseReturn: (state) => {
            state.selectedDraftPurchaseReturn = null;
        },
        setBillNo: (state, action) => {
            state.billNo = action.payload;
        },
        setCreatedDate: (state, action) => {
            state.createdDate = action.payload;
        },
        setDeliveryDate: (state, action) => {
            state.deliveryDate = action.payload;
        },
        setDeliveryExpense: (state, action) => {
            state.deliveryExpense = action.payload;
        },
        setPurchaseReturnCurrentScreen: (state, action) => {
            state.currentScreen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase("LOGOUT", (state) => {
            Object.assign(state, initialState);
        });
    },
});

export default purchaseReturnSlice.reducer;
export const {
    addProductToPurchaseReturn,
    updateProductFromPurchaseReturn,
    removeProductFromPurchaseReturn,
    decrementQuantityPurchaseReturn,
    getPurchaseReturnTotals,
    removeAllPurchaseReturnProducts,
    setReturnSelectedSupplier,
    removeReturnSelectedSupplier,
    setSelectedDraftPurchaseReturn,
    removeSelectedDraftPurchaseReturn,
    setBillNo,
    setCreatedDate,
    setDeliveryDate,
    setDeliveryExpense,
    addProductsToPurchaseReturn,
    setPurchaseReturnCurrentScreen,
} = purchaseReturnSlice.actions;
