import React from "react";
import * as S from "./style";
import { styled } from "@mui/material/styles";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useState } from "react";
import { useRef } from "react";
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { backupData } from "../../../api/local/storeApi";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 11,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 11,
        backgroundColor: theme.palette.mode === "light" ? "#2D68FE" : "#EEEEEE",
    },
}));

const Backup = () => {

    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const loading = useRef(null);
    const storeConfig = useSelector((state) => state.session.store_config);

    const startBackup = async () => {
        // try {
        //     let res = await backupData();
        //     if (res.status === 200 && res.data) {
        //         dispatch(fetchStoreConfig());
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    function startProgress() {
        loading.current = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 100;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);
    }

    function stopProgress() {
        clearInterval(loading.current);
        setProgress(0);
    }

    return (
        <S.Wrapper>
            <S.Topbar>
                <S.BackupBtn onClick={startBackup}>Backup now</S.BackupBtn>
                {/* <S.ProgressContainer>
                    <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                    />
                </S.ProgressContainer>
                <S.CancelBtn onClick={stopProgress}>Cancel</S.CancelBtn> */}
            </S.Topbar>
            <S.TimeStamp>{
                (storeConfig.last_backup_on ?? 0) === 0 ? "Not backed up yet" :
                    `Last backed up on ${moment(storeConfig.last_backup_on).format('h:mm a, DD-MMM-YYYY')}`
            }</S.TimeStamp>
        </S.Wrapper>
    );
};

export default Backup;
