import React from "react";
import * as S from "./style";
import OnlineStore from "../../../assets/images/gif/online-store.gif";
import { BackArrowIcon, ChevronRightWhite, NoIcon, YesIcon } from "../../../icons";
import Bar from "../page-bar/Bar";

const Transfer = ({ setActivePage }) => {
    return (
        <S.Wrapper>
            <S.LeftImgContainer>
                <img src={OnlineStore} alt="online store" />
            </S.LeftImgContainer>
            <S.RightContainer>
                <div></div>
                <div>
                    <S.Container>
                        <S.Text>Do you want to transfer data from Rancelabs?</S.Text>
                        <S.ImageContainer>
                            <S.ImageBlock>
                                <img src={YesIcon} alt="yes icon" />
                                <div>Yes</div>
                            </S.ImageBlock>
                            <S.ImageBlock>
                                <img src={NoIcon} alt="no icon" />
                                <div>No</div>
                            </S.ImageBlock>
                        </S.ImageContainer>
                    </S.Container>
                </div>
                <S.FooterDiv>
                    <S.WhiteButton onClick={() => setActivePage(2)}>
                        <img src={BackArrowIcon} alt="back" />
                    </S.WhiteButton>
                    <S.BarContainer>
                        <Bar />
                        <Bar />
                        <Bar />
                        <Bar size="lg" />
                    </S.BarContainer>
                    <S.ButtonContainer>
                        <S.WhiteButton>Contact us</S.WhiteButton>
                        <S.DarkButton onClick={() => setActivePage(4)}>
                            Next
                            <img src={ChevronRightWhite} alt="" />
                        </S.DarkButton>
                    </S.ButtonContainer>
                </S.FooterDiv>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default Transfer;
