import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px;
`;
export const BtnDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const PayBtn = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    background: ${(props) => (props.disabled ? "#94a3b8" : "#2d68fe")};
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
    border: none;
`;
export const AmountDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 2px 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #94a3b8;
    margin-right: 5px;
`;
export const BottomDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #455a64;
    padding: 10px 16px;
    background: #f1f5f9;
    border-radius: 0px 0px 6px 6px;

    & span {
        font-weight: 700;
        color: #000;
    }
`;
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const DatePickerContainer = styled.div`
    position: absolute;
    // top: 39px;
    // right: 0;
    margin-top: 5px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 100;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    max-height: 36px;
    cursor: pointer;
    img {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
`;
