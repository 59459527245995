import { createSelector } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProducts } from "../../../store/product-slice";
import ProductsGrid from "./products-grid/products-grid";

import * as S from "./products-list.styles";
import ProductsTable from "./products-table/products-table";
import BarcodeDialog from "../../barcode-dialog/barcode-dialog";

const ProductsList = ({
    type,
    tableRef,
    activeRow,
    activeRowRef,
    setActiveRow,
    openViewDrawerRef,
    openEditDrawer,
    selectRef,
    openModalRef,
    setOpenModal,
    openModal,
}) => {
    const { loading, products, error, searchParams } = useSelector((state) => state.product);
    const dispatch = useDispatch();
    const [openBarcodeModal, setOpenBarcodeModal] = useState({
        show: false,
        data: null,
    });

    useEffect(() => {
        dispatch(fetchAllProducts(searchParams));
        setActiveRow(0);
    }, [searchParams]);

    return (
        <>
            <BarcodeDialog
                open={openBarcodeModal.show}
                data={openBarcodeModal.data}
                onClose={() =>
                    setOpenBarcodeModal({
                        show: false,
                        data: null,
                    })
                }
            />

            <S.Container>
                {type === "table" ? (
                    <ProductsTable
                        products={products}
                        tableRef={tableRef}
                        activeRow={activeRow}
                        activeRowRef={activeRowRef}
                        setActiveRow={setActiveRow}
                        openViewDrawerRef={openViewDrawerRef}
                        openEditDrawer={openEditDrawer}
                        selectRef={selectRef}
                        openModalRef={openModalRef}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        setOpenBarcodeModal={setOpenBarcodeModal}
                    />
                ) : (
                    <ProductsGrid
                        products={products}
                        tableRef={tableRef}
                        activeRow={activeRow}
                        activeRowRef={activeRowRef}
                        setActiveRow={setActiveRow}
                        openViewDrawerRef={openViewDrawerRef}
                        openEditDrawer={openEditDrawer}
                        selectRef={selectRef}
                        openModalRef={openModalRef}
                        setOpenModal={setOpenModal}
                        openModal={openModal}
                        setOpenBarcodeModal={setOpenBarcodeModal}
                    />
                )}
            </S.Container>
        </>
    );
};

export default ProductsList;
