import styled from "@emotion/styled/macro";

export const Container = styled.div`
    ${(props) => props.type === "primary" && !props.disable && "background : #2D68FE"};
    ${(props) => props.type === "primary" && props.disable && "background : #94A3B8"};
    ${(props) => props.type === "no-bg" && "background : white"};
    ${(props) => props.type === "no-bg-red" && "background : white"};
    border-radius: 4px;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    ${(props) => props.type === "primary" && "color: #ffffff"};
    ${(props) => props.type === "no-bg" && "color: #3b82f6"};
    ${(props) => props.type === "no-bg-red" && "color: #F91919"};
    ${(props) => props.outline === true && "border: 1px solid #2D68FE"};

    ${(props) => props.size === "md" && "padding: 10px 15px"};
    ${(props) => props.size === "lg" && "padding: 10px 93.5px"};
    cursor: ${(props) => (props.disable ? "no-drop" : "pointer")};
`;
