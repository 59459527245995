import React from "react";
import { Modal } from "@mui/material";
import * as S from "./OfflineWarning.style";
import YellowBox from "../plan-buy-modal/yellow-box/YellowBox";
import { Warning } from "../../icons";

const OfflineWarningModal = ({ onClose, data }) => {
    const handleCloseDialog = () => {
        onClose({
            show: false,
            title: "",
            subTitle: "",
            supportText: "",
            stepsTitle: "",
            steps: [],
        });
    };
    return (
        <Modal open={data.show} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Body>
                    <img src={Warning} alt="warning" />
                    <S.Head>{data.title}</S.Head>
                    <S.SubHead>{data.subTitle}</S.SubHead>
                    <S.DottedLine></S.DottedLine>
                    <S.PointsHead>{data.stepsTitle}</S.PointsHead>
                    <S.PointsContainer>
                        {data.steps?.map((item, index) => (
                            <S.Points>
                                <div>{index + 1}.</div>
                                <S.PointText>{item}</S.PointText>
                            </S.Points>
                        ))}
                    </S.PointsContainer>
                </S.Body>
                <YellowBox label={data.supportText} curved />
            </S.Wrapper>
        </Modal>
    );
};

export default OfflineWarningModal;
