import React, { useContext, useRef, useState } from "react";
import * as S from "./style";
import { Modal } from "@mui/material";
import { AddCustomerSearchIcon, CloseModalIcon } from "../../icons";
import {
  INVOICE_PAYMENT_MODES,
  INVOICE_TYPE,
  PAYMENT,
  SALES_TYPE,
  SALE_BILL_TYPE,
} from "../../constants/appConstants";
import Address, { InitialAddress } from "./address/Address";
import CustomerSearchDropdown from "./customer-search-dropdown/CustomerSearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCustomers,
  removeAllCustomers,
  removeSelectedCustomer,
  setSelectedCustomer,
} from "../../store/customer-slice";
import { useCallback } from "react";
import { debounce, isEmpty } from "lodash";
import {
  checkValidGstAfterChange,
  validAddress,
  validGstNumber,
} from "../../utils/validity-fns";
import { captureException } from "../../crash-reporting";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import {
  addCustomer,
  getCustomer,
  updateCustomer,
} from "../../api/local/customerApi";
import OrderSummaryModal from "../order-summary-modal/order-summary-modal";
import { FEATURES } from "../../constants/POS-plans";
import { addSale, sendSaleReceipt, updateSale } from "../../api/local/sale-api";
import { useEffect } from "react";
import store from "../../store/store";
import {
  convertTax,
  getQuantity,
  getSalePayloadProducts,
  toDecimalPlace,
} from "../../utils/global-fn";
import {
  removeCustomerPI,
  setCustomerPI,
} from "../../store/proformainvoice-slice";
import { removeSelectedSale, setSelectedSale } from "../../store/sale-slice";
import { useNavigate } from "react-router-dom";
import InputField from "../input-field/InputField";
import { handleGetGstInfo } from "../../utils/api-fns";

const InitialCustomerInputData = {
  name: "",
  phone: "",
  gst_number: "",
  loyalty_point: "",
};

const initialLoyaltyPoint = {
  max_usable_points: 0,
  use_point: false,
  points: 0,
  loyalty_rupees: 0,
  remaining_points: 0,
};

const initialCollectedAmount = {
  CASH: "",
  QR: "",
  CARD: "",
  PAY_LATER: "",
};
const initialErrorInput = {
  gst_number: false,
};
const InvoicePaymentModal = ({
  open,
  onClose,
  type,
  saleDiscount,
  handleClearData,
}) => {
  const { customers, inventorySelectedCustomer } = useSelector(
    (state) => state.customer
  );
  const [customerData, _setCustomerData] = useState(InitialCustomerInputData);
  const customerDataRef = useRef(customerData);
  const setCustomerData = (data) => {
    customerDataRef.current = data;
    _setCustomerData(data);
  };
  const [customerSearch, _setCustomerSearch] = useState("");
  const customerSerachRef = useRef(customerSearch);
  const setCustomerSearch = (val) => {
    customerSerachRef.current = val;
    _setCustomerSearch(val);
  };
  const [openCustomerInput, setOpenCustomerInput] = useState(false);
  const [customerBillingAddress, _setCustomerBillingAddress] = useState(null);
  const billingAddressRef = useRef(customerBillingAddress);
  const setCustomerBillingAddress = (address) => {
    billingAddressRef.current = address;
    _setCustomerBillingAddress(address);
  };
  const [customerShippingAddress, _setCustomerShippingAddress] = useState(null);
  const shippingAddressRef = useRef(customerShippingAddress);
  const setCustomerShippingAddress = (address) => {
    shippingAddressRef.current = address;
    _setCustomerShippingAddress(address);
  };
  const [isSameAddress, setIsSameAddress] = useState(false);
  const dispatch = useDispatch();
  const [loyaltyPoints, _setLoyaltyPoints] = useState(initialLoyaltyPoint);
  const loyaltyPointsRef = useRef(loyaltyPoints);
  const setLoyaltyPoints = (data) => {
    loyaltyPointsRef.current = data;
    _setLoyaltyPoints(data);
  };
  const [collectedAmount, _setCollectedAmount] = useState({
    CASH: "",
    QR: "",
    CARD: "",
    PAY_LATER: "",
  });
  const collectedAmountRef = useRef(collectedAmount);
  const setCollectedAmount = (data) => {
    collectedAmountRef.current = data;
    _setCollectedAmount(data);
  };

  const [amount, _setAmount] = useState({
    total_amt: 0,
    amt_left: 0,
    total_collected: 0,
  });
  const amountRef = useRef(amount);
  const setAmount = (amt) => {
    amountRef.current = amt;
    _setAmount(amt);
  };

  const [notes, _setNotes] = useState({ remarks: "", terms: "" });
  const notesRef = useRef(notes);
  const setNotes = (notes) => {
    notesRef.current = notes;
    _setNotes(notes);
  };

  //invoice option
  const [invoiceRequired, _setInvoiceRequired] = useState(true);
  const invoiceRequiredRef = React.useRef(invoiceRequired);
  const setInvoiceRequired = (data) => {
    invoiceRequiredRef.current = data;
    _setInvoiceRequired(data);
  };
  const disableButtonRef = useRef(false);
  const setDisableButton = (data) => {
    disableButtonRef.current = data;
  };

  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const [openSummaryModal, _setOpenSummaryModal] = useState(false);
  const openSummaryModalRef = React.useRef(openSummaryModal);
  const setOpenSummaryModal = (data) => {
    openSummaryModalRef.current = data;
    _setOpenSummaryModal(data);
  };
  const { loyaltyPreference, billTypePreference } = useSelector(
    (state) => state.app
  );
  const {
    saleProducts,
    saleId,
    saleTotal,
    billno,
    createdDate,
    totalAdditionalCharges,
    additional_charges,
    selectedSale,
    cartTotal,
    selectDiscount,
  } = useSelector((state) => state.sale);
  const {
    PI_customer,
    PI_products,
    PI_additional_charges,
    PI_createdDate,
    PI_billNo,
    PI_id,
    PI_type,
    PI_selectDiscount,
  } = useSelector((state) => state.proformaInvoice);
  const { features } = useSelector((state) => state.app);
  const [selectedTemplate, _setSelectedTemplate] = useState(null);
  const selectedTemplateRef = useRef(selectedTemplate);
  const setSelectedTemplate = (data) => {
    selectedTemplateRef.current = data;
    _setSelectedTemplate(data);
  };
  const navigate = useNavigate();
  const [errorInput, setErrorInput] = useState(initialErrorInput);
  const { appOnline } = useSelector((state) => state.app);
  const { gstCache } = useSelector((state) => state.apiCache);

  useEffect(() => {
    if (open) {
      if (type === "sale" || type === "convert-to-invoice") {
        handleCalculateAmount();
      }
    }
  }, [
    open,
    loyaltyPoints,
    saleTotal,
    collectedAmount,
    totalAdditionalCharges,
    saleDiscount,
  ]);

  useEffect(() => {
    if (open) {
      if (type === "sale" || type === "convert-to-invoice") {
        if (inventorySelectedCustomer) {
          getCustomerData(inventorySelectedCustomer.id, true);
        }

        if (type === "convert-to-invoice") {
          if (selectedSale?.remarks || selectedSale?.terms)
            setNotes({
              remarks: selectedSale?.remarks ?? "",
              terms: selectedSale?.terms ?? "",
            });
        }
      } else if (type === "proforma-invoice") {
        if (PI_customer) {
          getCustomerData(PI_customer.id, true);
        }
      }
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open]);

  const handleCalculateAmount = () => {
    let totalCollectedAmt = 0;
    for (const key in collectedAmount) {
      if (collectedAmount[key]) {
        totalCollectedAmt += parseFloat(collectedAmount[key]);
      }
    }

    let finalSaleTotal;
    if (type === "convert-to-invoice" && selectedSale) {
      finalSaleTotal = selectedSale.cart_total;
    } else {
      finalSaleTotal = cartTotal;
    }

    if (
      features.includes(FEATURES.LOYALTY_POINT) &&
      loyaltyPreference?.loyalty_point_enabled
    ) {
      finalSaleTotal -= loyaltyPoints.loyalty_rupees;
    }

    let totalLeft = finalSaleTotal - totalCollectedAmt;
    if (totalLeft < 0) totalLeft = 0;

    setAmount({
      total_amt: toDecimalPlace(finalSaleTotal),
      amt_left: toDecimalPlace(totalLeft),
      total_collected: toDecimalPlace(totalCollectedAmt),
    });
  };

  const handleDetectKeyDown = (e) => {
    if (customerSerachRef.current || openSummaryModalRef.current) {
      return;
    }
    if (e.keyCode === 13) {
      //Enter key
      if (!customerSerachRef.current) {
        e.preventDefault();
        handleComplete();
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const delayedFunction = (val) => {
    dispatch(fetchAllCustomers({ search: val, show_transactions: true }));
  };
  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

  const handleCustomerInputChange = async (event) => {
    let { value, name } = event.target;
    let gstData = null;
    if (
      (["sale", "convert-to-invoice"].includes(type) &&
        inventorySelectedCustomer) ||
      (type === "proforma-invoice" && PI_customer)
    ) {
      if (name === "phone") return;
    }
    if (name === "phone") {
      const regex = /^[0-9\b]+$/;
      if (!(value === "" || regex.test(value))) return;
      if (value.length > 10) return;
      if (value.length === 10) {
        getCustomerData(value, true);
      }
    } else if (name === "gst_number") {
      if (value.length > 15) return;
      value = value.toUpperCase();
      let valid = checkValidGstAfterChange(value);
      setErrorInput({ ...errorInput, [name]: !valid });
      if (value.length === 15) {
        setCustomerData({ ...customerData, [name]: value });
        gstData = await handleGetGstInfo(value, appOnline, gstCache, dispatch);
      }
    }
    if (gstData && name === "gst_number") {
      let { company_name, address } = gstData;
      setCustomerData({ ...customerData, [name]: value, name: company_name });
      setCustomerBillingAddress(address);
      setCustomerShippingAddress(address);
    } else {
      setCustomerData({ ...customerData, [name]: value });
    }
  };

  async function getCustomerData(val, showTxn = false) {
    let params = {};
    if (showTxn) {
      params.show_transactions = showTxn;
    }
    try {
      let res = await getCustomer(val, params);
      if (res.status === 200) {
        handleSelectCustomer(res.data);
      } else if (res.status !== 404) {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }

  const handleCustomerSearch = (event) => {
    let { value } = event.target;
    setCustomerSearch(value);
    // handleRemoveCustomer();
    delayedQuery(value);
  };

  const handleClearCustomerSearch = () => {
    setCustomerData(InitialCustomerInputData);
    dispatch(removeAllCustomers());
    handleRemoveCustomer();
  };

  const handleSelectCustomer = (data) => {
    if (data === "new") {
      let searchValue = customerSerachRef.current;
      const regex = /^[0-9\b]+$/;
      if (regex.test(searchValue)) {
        setCustomerData({ ...InitialCustomerInputData, phone: searchValue });
      } else {
        setCustomerData({ ...InitialCustomerInputData, name: searchValue });
      }
      handleRemoveCustomer(true);
    } else {
      setCustomerData(data);
      setCustomerBillingAddress(
        data?.billing_address
          ? data?.billing_address
          : data?.address
          ? data?.address
          : InitialAddress
      );
      setCustomerShippingAddress(
        data?.shipping_address ? data?.shipping_address : InitialAddress
      );
      if (type === "sale" || type === "convert-to-invoice") {
        dispatch(setSelectedCustomer(data));

        if (
          features.includes(FEATURES.LOYALTY_POINT) &&
          loyaltyPreference?.loyalty_point_enabled
        ) {
          handleGetMaximumUsablePoints(data);
        }
      } else if (type === "proforma-invoice") {
        dispatch(setCustomerPI(data));
      }
    }
    setOpenCustomerInput(true);
    dispatch(removeAllCustomers());
    setCustomerSearch("");
  };

  const handleRemoveCustomer = (newCustomer = false) => {
    if (type === "sale" || type === "convert-to-invoice")
      dispatch(removeSelectedCustomer());
    else if (type === "proforma-invoice") dispatch(removeCustomerPI());
    setLoyaltyPoints(initialLoyaltyPoint);
    setOpenCustomerInput(false);
    if (!newCustomer) setCustomerData(InitialCustomerInputData);
  };

  const handleCheckChange = (event) => {
    let { checked } = event.target;
    setIsSameAddress(checked);
    if (checked === true) {
      console.log(customerBillingAddress);
      setCustomerShippingAddress(customerBillingAddress);
    } else {
      setCustomerShippingAddress(
        inventorySelectedCustomer?.shipping_address
          ? inventorySelectedCustomer?.shipping_address
          : InitialAddress
      );
    }
  };

  const handleAmountInputChange = (event) => {
    let { name, value } = event.target;
    // For decimal inputs
    // const regex = /^\d+\.?(\d)?(\d)?$/;
    // if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
    //     value = value.substr(1);
    // }

    // For non decimal inputs
    const regex = /^[0-9\b]+$/;
    if (!(value === "" || regex.test(value))) return;

    let { amt_left, total_amt, total_collected } = amountRef.current;

    let temp = collectedAmountRef.current;
    temp[name] = value;
    let amt_values = Object.values(temp).filter((el) => el && el);
    let amt_sum = amt_values.reduce((a, b) => Number(a) + Number(b), 0);

    // if (features.includes(FEATURES.LOYALTY_POINT) && loyaltyPreference?.loyalty_point_enabled) {
    //     let loyaltyRupees = loyaltyPointsRef.current?.loyalty_rupees;
    //     if (loyaltyRupees) {
    //         amt_values.push(loyaltyRupees);
    //         amt_sum += loyaltyRupees;
    //     }
    // }

    //TODO: later put conditions for partial payments

    if (amt_values.length > 1) {
      if (amt_sum > total_amt) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    } else {
      setDisableButton(false);
    }

    setCollectedAmount({ ...collectedAmount, [name]: value });
  };
  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setNotes({ ...notes, [name]: value });
  };

  const handleGetMaximumUsablePoints = (customer) => {
    if (customer?.loyalty_point) {
      let total;
      if (type === "convert-to-invoice" && selectedSale) {
        total = selectedSale.cart_total;
      } else {
        total = saleTotal;
        if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
          total = total + totalAdditionalCharges;
        }
      }
      let maxPoints = getRupeesToPoints(
        total - (parseFloat(saleDiscount) || 0)
      );
      let max_redeemable_points =
        loyaltyPreference?.maximum_redeemable?.loyalty_points;

      let minimum_points = Math.min(
        max_redeemable_points,
        customer?.loyalty_point
      );

      if (maxPoints > minimum_points) {
        maxPoints = minimum_points;
      }
      maxPoints = Math.round(maxPoints);
      let loyaltyrupees = getPointsToRupees(maxPoints);

      let { amt_left } = amountRef.current;
      setLoyaltyPoints({
        points: maxPoints,
        max_usable_points: maxPoints,
        use_point: true,
        loyalty_rupees: loyaltyrupees,
      });
      // if (amt_left) {
      // } else {
      //     setLoyaltyPoints({ points: 0, max_usable_points: maxPoints, use_point: false, loyalty_rupees: 0 });
      // }
      if (loyaltyrupees > amt_left) {
        adjustAmounts(loyaltyrupees);
      }
    }
  };

  const getRupeesToPoints = (rs) => {
    let cr = loyaltyPreference?.debit_conversion?.rupees;
    let cp = loyaltyPreference?.debit_conversion?.loyalty_points;
    if (!cr || !cp) return 0;
    let points = (cp / cr) * rs;
    return parseFloat(points.toFixed(2));
  };

  const getPointsToRupees = (pts) => {
    let cr = loyaltyPreference?.debit_conversion?.rupees;
    let cp = loyaltyPreference?.debit_conversion?.loyalty_points;
    if (!cr || !cp) return 0;
    let rupees = (cr / cp) * pts;
    return parseFloat(rupees.toFixed(2));
  };

  const handleCheckLoyaltyPoint = (event) => {
    let { checked } = event.target;
    let { amt_left } = amountRef.current;
    // if (!amt_left) {
    //     handleSnackbarDetails({
    //         show: true,
    //         type: "error",
    //         title: "No amount left to use loyalty point.",
    //     });
    //     return;
    // }
    if (checked) {
      let loyaltyrupees = getPointsToRupees(loyaltyPoints.max_usable_points);
      setLoyaltyPoints({
        ...loyaltyPoints,
        use_point: checked,
        points: loyaltyPoints.max_usable_points,
        loyalty_rupees: loyaltyrupees,
      });
      if (loyaltyrupees > amt_left) {
        adjustAmounts(loyaltyrupees);
      }
    } else {
      setLoyaltyPoints({
        ...loyaltyPoints,
        use_point: checked,
        points: 0,
        loyalty_rupees: 0,
      });
    }
  };

  const adjustAmounts = (loyaltyrupees) => {
    for (let key in collectedAmount) {
      if (collectedAmount[key]) {
        setCollectedAmount({
          ...collectedAmount,
          [key]: Math.ceil(collectedAmount[key] - loyaltyrupees),
        });
        break;
      }
    }
  };

  const handleChangeLoyaltyPoints = (event) => {
    let { value } = event.target;
    const regex = /^[0-9\b]+$/;
    if (!(value === "" || regex.test(value))) return;

    if (value > loyaltyPoints.max_usable_points) {
      value = loyaltyPoints.max_usable_points;
    }
    let loyaltyrupees = getPointsToRupees(value);
    let usePoint = loyaltyPoints.use_point;
    if (value == "" || value == 0) {
      usePoint = false;
    } else {
      usePoint = true;
    }
    setLoyaltyPoints({
      ...loyaltyPoints,
      points: value,
      loyalty_rupees: loyaltyrupees,
      use_point: usePoint,
    });
  };

  const handleAddCustomer = async () => {
    // if (!inventorySelectedCustomer) return;
    let { phone, name, gst_number } = customerDataRef.current;

    let payload = {
      phone: phone,
    };
    if (name) payload.name = name;
    payload.gst_number = gst_number;

    // if (!validAddress(billingAddressRef.current)) {
    //   handleSnackbarDetails({
    //     show: true,
    //     type: "error",
    //     title: "Please enter full billing address",
    //   });
    //   return false;
    // }
    // if (!validAddress(shippingAddressRef.current)) {
    //   handleSnackbarDetails({
    //     show: true,
    //     type: "error",
    //     title: "Please enter full shipping address",
    //   });
    //   return false;
    // }

    const billing_address = {};
    if (billingAddressRef.current?.line_1) {
      let { line_1, line_2, pin_code, city, state, country } =
        billingAddressRef.current;

      if (line_1) billing_address.line_1 = line_1;
      if (line_2) billing_address.line_2 = line_2;
      if (pin_code) billing_address.pin_code = pin_code;
      if (city) billing_address.city = city;
      if (state) billing_address.state = state;
      if (country) billing_address.country = country;
    }
    if (!isEmpty(billing_address)) {
      payload.billing_address = billing_address;
    }

    const shipping_address = {};
    if (shippingAddressRef.current?.line_1) {
      let { line_1, line_2, pin_code, city, state, country } =
        shippingAddressRef.current;

      if (line_1) shipping_address.line_1 = line_1;
      if (line_2) shipping_address.line_2 = line_2;
      if (pin_code) shipping_address.pin_code = pin_code;
      if (city) shipping_address.city = city;
      if (state) shipping_address.state = state;
      if (country) shipping_address.country = country;
    }
    if (!isEmpty(shipping_address)) {
      payload.shipping_address = shipping_address;
    }

    try {
      let customer = customerDataRef.current;
      if (!customer?.id) {
        const res = await addCustomer(payload);
        customer = res.data.entity;
        customerDataRef.current = customer;
      }
      if (customer) {
        if (type === "sale") {
          dispatch(setSelectedCustomer(customer));
        } else if (type === "proforma-invoice") {
          dispatch(setCustomerPI(customer));
        }
        return true;
      } else {
        throw new Error("Cannot add customer");
      }
    } catch (error) {
      captureException(error);
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Customer add failed.",
        subtitle: error.response?.data?.message,
      });
    }
  };

  const handleComplete = async () => {
    if (disableButtonRef.current) {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title:
          "Amounts can not be exceeded from total amount in partial payment",
      });
      return;
    }
    let selectedCustomer = store.getState().customer.inventorySelectedCustomer;
    let { name, phone, gst_number } = customerDataRef.current;

    if (name && !phone) {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter phone number to add a customer.",
      });
      return;
    }
    // if (phone && phone.length < 10) {
    //     handleSnackbarDetails({
    //         show: true,
    //         type: "error",
    //         title: "Please enter 10 digit phone number.",
    //     });
    //     return;
    // }
    if (gst_number?.length && !validGstNumber(gst_number)) {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter a valid TAX number.",
      });
      return;
    }
    if (phone) {
      let r = await handleAddCustomer();
      if (!r) return;
    }
    if (!phone) {
      let { amt_left } = amountRef.current;
      if (amt_left > 0) {
        handleSnackbarDetails({
          show: true,
          type: "error",
          title: "Please pay full amount or add a customer to pay later.",
          subtitle: "",
        });
        return;
      }
      let { PAY_LATER } = collectedAmountRef.current;
      if (!selectedCustomer && PAY_LATER) {
        handleSnackbarDetails({
          show: true,
          type: "error",
          title: "Please add a customer to pay later.",
          subtitle: "",
        });
        return;
      }
    }
    setOpenSummaryModal(true);
  };

  const handleAddSaleInvoice = async (invoiceRequired) => {
    const payload = {
      status: "PUBLISH",
      bill_type: billTypePreference?.sale_bill_type,
      items: getSalePayloadProducts(saleProducts, SALE_BILL_TYPE.INVOICE),
    };

    if (
      additional_charges.length &&
      billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE
    ) {
      payload.additional_charges = additional_charges
        .filter((a) => a.value !== "")
        .map((item) => ({ ...item, value: parseFloat(item.value) }));
    }

    if (saleDiscount) {
      let disc = {
        type: selectDiscount.type,
        value: selectDiscount.cost,
      };
      payload.cart_manual_discount = disc;
    }
    if (createdDate) payload.created_at = createdDate.getTime();
    payload.patient_id = customerDataRef.current?.id;
    if (billno !== undefined) payload.bill_no = billno;

    let { remarks, terms } = notesRef.current;
    if (remarks) payload.remarks = remarks;
    if (terms) payload.terms = terms;

    const payment = createPaymentModelV1();
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }

    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    try {
      let res;
      if (saleId) {
        //update the existing sale
        res = await updateSale(saleId, payload);
      } else {
        //Add the new sale
        res = await addSale(payload, invoiceRequired);
      }

      if (res?.status === 200) {
        handleClear();
        onClose();

        const sale = res.data?.entity;

        //Print the invoice
        if (invoiceRequired) {
          onInvoiceRequired(sale);
        }

        let subTitle = "Your sale has been completed successfully.";

        //TODO: Commented now. Need to send the same invoice bill that was printed.
        // if (navigator.onLine && sale.patient && sale.type === "SALE") {
        //     sendSaleReceipt(sale.id).catch((err) => captureException(err));
        //     subTitle = "Sale receipt sent to customer's phone via SMS.";
        // }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale completed",
          subtitle: subTitle,
        });
      } else {
        throw new Error(res?.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  };

  const onInvoiceRequired = async (sale) => {
    if (sale.invoice_url) {
      window.open(sale.invoice_url, "_blank")?.focus();
    }
  };

  const createPaymentModelV1 = () => {
    let payment = {};
    let finalSaleTotal;
    if (type === "convert-to-invoice" && selectedSale) {
      finalSaleTotal = selectedSale.cart_total;
    } else {
      finalSaleTotal =
        saleTotal + totalAdditionalCharges - (parseFloat(saleDiscount) || 0);
    }
    let { use_point, loyalty_rupees, points } = loyaltyPointsRef.current;
    payment.total_amount = finalSaleTotal;

    let payment_modes = [];
    let amounts = collectedAmountRef.current;
    for (const key in amounts) {
      if (amounts[key]) {
        let mode_pl = {
          date_created: Date.now(),
          payment_mode: key,
          payment_amount:
            parseFloat(amounts[key]) > parseFloat(finalSaleTotal)
              ? finalSaleTotal
              : amounts[key],
        };
        if (key === PAYMENT.CASH) {
          mode_pl.cash_in = parseFloat(amounts[key]);
          if (parseFloat(amounts[key]) > parseFloat(finalSaleTotal)) {
            mode_pl.cash_out =
              parseFloat(amounts[key]) - mode_pl.payment_amount;
          } else {
            mode_pl.cash_out = 0;
          }
        }
        payment_modes.push(mode_pl);
      }
    }

    if (
      features.includes(FEATURES.LOYALTY_POINT) &&
      loyaltyPreference?.loyalty_point_enabled
    ) {
      if (use_point) {
        let lp_payload = {
          date_created: Date.now(),
          payment_mode: PAYMENT.LOYALTY_POINT,
          payment_amount: parseFloat(loyalty_rupees),
          virtual_amount: parseFloat(points),
        };
        payment_modes.push(lp_payload);
      }
    }

    if (inventorySelectedCustomer) {
      let { amt_left } = amountRef.current;
      let { PAY_LATER } = collectedAmountRef.current;

      if (amt_left > 0 && amt_left != PAY_LATER) {
        if (PAY_LATER) {
          for (let item of payment_modes) {
            if (item.payment_mode === PAYMENT.PAY_LATER) {
              item.payment_amount =
                parseFloat(amt_left) + parseFloat(PAY_LATER);
              break;
            }
          }
        } else {
          let payload = {
            date_created: Date.now(),
            payment_mode: PAYMENT.PAY_LATER,
            payment_amount: amt_left,
          };
          payment_modes.push(payload);
        }
      }
    }

    payment.payment_modes = payment_modes;
    let havePayLater = false;
    for (let item of payment_modes) {
      if (item.payment_mode === PAYMENT.PAY_LATER) {
        havePayLater = true;
        payment.amount_due = item.payment_amount;
        payment.is_paid = false;
        break;
      }
    }
    if (havePayLater === false) {
      payment.amount_due = 0;
      payment.is_paid = true;
    }

    return payment;
  };

  const handleAddProformaInvoice = async (invoiceRequired) => {
    const payload = {
      type: PI_type,
      status: "PUBLISH",
      items: getSalePayloadProducts(PI_products, SALE_BILL_TYPE.INVOICE),
    };

    if (PI_additional_charges.length) {
      payload.additional_charges = PI_additional_charges.filter(
        (a) => a.value !== ""
      ).map((item) => ({
        ...item,
        value: parseFloat(item.value),
      }));
    }

    if (saleDiscount) {
      let disc = {
        type: PI_selectDiscount.type,
        value: PI_selectDiscount.cost,
      };
      payload.cart_manual_discount = disc;
    }
    if (PI_createdDate) payload.created_at = PI_createdDate.getTime();
    payload.patient_id = customerDataRef.current?.id;
    if (PI_billNo !== undefined) payload.bill_no = PI_billNo;

    let { remarks, terms } = notesRef.current;
    if (remarks) payload.remarks = remarks;
    if (terms) payload.terms = terms;

    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    try {
      let res;
      if (PI_id) {
        //update the existing sale
        res = await updateSale(PI_id, payload);
      } else {
        //Add the new sale
        res = await addSale(payload);
      }

      if (res?.status === 200) {
        handleClear();
        onClose();

        const sale = res.data?.entity;
        //Print the invoice
        if (invoiceRequired) {
          onInvoiceRequired(sale, payload.invoice_template_id);
        }

        let subTitle = "Your proforma invoice has been created successfully.";

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Proforma Invoice created",
          subtitle: subTitle,
        });
      } else {
        throw new Error(res?.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  };

  const handleConvertToInvoice = async (invoiceRequired) => {
    const payload = {
      type: SALES_TYPE.SALE,
      status: "PUBLISH",
      bill_type: SALE_BILL_TYPE.INVOICE,
      created_at: Date.now(),
    };
    payload.patient_id = customerDataRef.current?.id;

    let { remarks, terms } = notesRef.current;
    if (remarks) payload.remarks = remarks;
    if (terms) payload.terms = terms;

    const payment = createPaymentModelV1();
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }

    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    try {
      let res = await updateSale(selectedSale.id, payload);
      if (res?.status === 200) {
        const sale = res.data?.entity;
        dispatch(removeSelectedCustomer());
        dispatch(removeSelectedSale());
        navigate("/dashboard/all-sales");
        onSuccess();
        onClose();

        //Print the invoice
        if (invoiceRequired) {
          onInvoiceRequired(sale, payload.invoice_template_id);
        }

        let subTitle = "Your sale has been completed successfully.";
        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale completed",
          subtitle: subTitle,
        });
      } else {
        throw new Error(res?.data?.message);
      }
    } catch (error) {
      captureException(error);
      handleSnackbarDetails({
        show: true,
        title: error.message,
        type: "error",
      });
    }
  };

  const onSuccess = () => {
    setCustomerData(InitialCustomerInputData);
    setCustomerBillingAddress(null);
    setCustomerShippingAddress(null);
    setIsSameAddress(false);
    setLoyaltyPoints(initialLoyaltyPoint);
    setCollectedAmount({
      CASH: "",
      QR: "",
      CARD: "",
      PAY_LATER: "",
    });
  };
  const handleClear = () => {
    handleClearData();
    onSuccess();
  };

  return (
    <>
      <OrderSummaryModal
        open={openSummaryModal}
        onClose={() => setOpenSummaryModal(false)}
        handleAddSaleInvoice={handleAddSaleInvoice}
        handleAddProformaInvoice={handleAddProformaInvoice}
        handleConvertToInvoice={handleConvertToInvoice}
        selectedPaymentOption={""}
        selectedAmount={amountRef.current?.total_collected}
        saleDiscount={saleDiscount}
        type={
          billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
          type === "sale"
            ? "invoice-sale"
            : type
        }
        invoiceRequired={invoiceRequired}
        setInvoiceRequired={setInvoiceRequired}
        loyaltyPoints={loyaltyPoints}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.Wrapper isFull={openCustomerInput}>
          <S.TopBar>
            <S.Header>Checkout</S.Header>
            <S.CloseIcon
              src={CloseModalIcon}
              alt="close"
              onClick={handleClose}
            />
          </S.TopBar>
          <S.Body>
            <S.PaymentContainer>
              {/* customer search */}
              <S.SearchContainer>
                {openCustomerInput ? (
                  <S.FlexContainer gap="12px">
                    <S.CustomerSearchContainer>
                      <InputField
                        label="Customer Name"
                        value={customerData.name}
                        name="name"
                        onChange={handleCustomerInputChange}
                        fullWidth={true}
                        autoFocus={true}
                      />
                    </S.CustomerSearchContainer>
                    <S.CustomerSearchContainer>
                      <InputField
                        label="Customer Phone"
                        value={customerData.phone}
                        name="phone"
                        onChange={handleCustomerInputChange}
                        fullWidth={true}
                      />
                    </S.CustomerSearchContainer>
                    <S.ClearBtn
                      src={CloseModalIcon}
                      alt="clear"
                      onClick={handleRemoveCustomer}
                    />
                  </S.FlexContainer>
                ) : (
                  <S.CustomerSearchContainer>
                    <InputField
                      // label="Search"
                      placeholder="Search customer by phone or name"
                      value={customerSearch}
                      name="phone"
                      onChange={handleCustomerSearch}
                      fullWidth={true}
                      autoFocus={true}
                      type="search"
                      prefix={<img src={AddCustomerSearchIcon} alt="search" />}
                    />
                    {/* {customerSearch && <S.ClearBtn src={CloseModalIcon} alt="clear" onClick={handleClearCustomerSearch} />} */}
                  </S.CustomerSearchContainer>
                )}

                {customerSearch ? (
                  <CustomerSearchDropdown
                    data={customers}
                    onSelectCustomer={handleSelectCustomer}
                    open={customerSearch}
                  />
                ) : (
                  <></>
                )}
              </S.SearchContainer>
              {openCustomerInput ? (
                <S.LabelContainer>
                  <S.Label>
                    Pay Later Amount Due:{" "}
                    {type === "proforma-invoice"
                      ? PI_customer?.transactions_count?.total_amount_due ?? 0
                      : inventorySelectedCustomer?.transactions_count
                          ?.total_amount_due ?? 0}
                  </S.Label>
                </S.LabelContainer>
              ) : (
                <></>
              )}

              {/* gst & lp */}
              {openCustomerInput && (
                <div>
                  <S.CustomerDetailsContainer>
                    <S.ColumnFlexContainer>
                      <S.InputContainer width="215px">
                        <InputField
                          label="TAX Number"
                          value={customerData?.gst_number}
                          name="gst_number"
                          onChange={handleCustomerInputChange}
                          fullWidth={true}
                          error={errorInput.gst_number}
                          helperText={
                            errorInput.gst_number && "Invalid TAX Number"
                          }
                        />
                      </S.InputContainer>
                    </S.ColumnFlexContainer>
                    {features.includes(FEATURES.LOYALTY_POINT) &&
                    inventorySelectedCustomer?.loyalty_point &&
                    loyaltyPreference?.loyalty_point_enabled &&
                    (type === "sale" || type === "convert-to-invoice") ? (
                      <>
                        <S.PointContainer>
                          <input
                            type="checkbox"
                            id="loyalty_point"
                            checked={loyaltyPoints?.use_point}
                            onChange={handleCheckLoyaltyPoint}
                          />
                          <label htmlFor="loyalty_point">
                            Use Loyalty Points{" "}
                            <span>
                              (Total:{Math.round(customerData?.loyalty_point)})
                            </span>
                          </label>
                        </S.PointContainer>
                        {
                          <S.InputContainer width="80px">
                            <InputField
                              label="Points"
                              value={loyaltyPoints?.points}
                              name="loyalty_point"
                              onChange={handleChangeLoyaltyPoints}
                              fullWidth={true}
                            />
                          </S.InputContainer>
                        }
                      </>
                    ) : (
                      <></>
                    )}
                  </S.CustomerDetailsContainer>
                </div>
              )}

              {/* payment modes */}
              {(type === "sale" || type === "convert-to-invoice") && (
                <S.PaymentAmountContainer>
                  <S.FlexContainer gap="46px">
                    <S.Label>Select Payment Method</S.Label>
                    <S.FlexContainer gap="6px">
                      <S.LabelContainer>
                        <S.Label>Total Amount: {amount?.total_amt}</S.Label>
                      </S.LabelContainer>
                      <S.LabelContainer>
                        <S.Label>Amount Left: {amount?.amt_left}</S.Label>
                      </S.LabelContainer>
                    </S.FlexContainer>
                  </S.FlexContainer>
                  <S.PaymentModeContainer>
                    {INVOICE_PAYMENT_MODES.map((mode, idx) => (
                      <S.PaymentMode key={idx} type={idx === 2 && "png"}>
                        <img src={mode.icon} alt="payment icon" />
                        <S.PaymentInput
                          type="text"
                          name={mode.name}
                          placeholder={mode.label}
                          value={collectedAmount[mode.name]}
                          onChange={handleAmountInputChange}
                        />
                      </S.PaymentMode>
                    ))}
                  </S.PaymentModeContainer>
                </S.PaymentAmountContainer>
              )}

              {/* notes & terms */}

              <S.TextContainer>
                <InputField
                  label="Notes"
                  value={notes.remarks}
                  name="remarks"
                  onChange={handleInputChange}
                  multiline={true}
                  rows={5}
                />
              </S.TextContainer>
              <S.TextContainer>
                <InputField
                  label="Terms & Conditions"
                  value={notes.terms}
                  name="terms"
                  onChange={handleInputChange}
                  multiline={true}
                  rows={5}
                />
              </S.TextContainer>
            </S.PaymentContainer>

            {/* Address Section */}
            {openCustomerInput && (
              <S.AddressContainer>
                {/* billing address */}
                <S.AddressFormContainer>
                  <S.BoldLabel>Customer Billing Address</S.BoldLabel>
                  <Address
                    addressType="billing_address"
                    address={customerBillingAddress}
                    setAddress={setCustomerBillingAddress}
                    type={type}
                  />
                </S.AddressFormContainer>

                {/* shipping */}
                <S.AddressFormContainer>
                  <S.BoldLabel>Customer Shipping Address</S.BoldLabel>
                  <S.CheckBoxContainer>
                    <input
                      type="checkbox"
                      id="invoice_address"
                      checked={isSameAddress}
                      onChange={handleCheckChange}
                    />
                    <label htmlFor="invoice_address">
                      Same as Billing Address?
                    </label>
                  </S.CheckBoxContainer>
                  <Address
                    addressType="shipping_address"
                    address={customerShippingAddress}
                    setAddress={setCustomerShippingAddress}
                    type={type}
                  />
                </S.AddressFormContainer>
              </S.AddressContainer>
            )}
          </S.Body>
          <S.BottomBar>
            <S.BlueBtn
              disable={disableButtonRef.current}
              onClick={handleComplete}
            >
              Complete
            </S.BlueBtn>
          </S.BottomBar>
        </S.Wrapper>
      </Modal>
    </>
  );
};

export default InvoicePaymentModal;
