import React from "react";
import * as S from "./style";
import TransactionRow from "./TransactionRow";

const Transaction = ({ data, advTxn, displayData }) => {
  return (
    <S.Wrapper>
      <S.TopContainer>
        <S.Heading>View Transaction</S.Heading>
        <S.NetSalaryDiv>
          <div>Net Salary</div>
          <S.NetSalary>
            ₱{" "}
            {advTxn && displayData.salary_detail?.monthly_salary
              ? displayData.salary_detail?.monthly_salary - (advTxn.amount || 0)
              : displayData.salary_detail?.monthly_salary && !advTxn
              ? displayData.salary_detail?.monthly_salary
              : advTxn?.amount}
          </S.NetSalary>
        </S.NetSalaryDiv>
      </S.TopContainer>
      <S.TransactionList>
        {data?.map((item) => (
          <TransactionRow key={item.timestamp} item={item} />
        ))}
        {!data && <S.Empty>No Transaction history found</S.Empty>}
      </S.TransactionList>
    </S.Wrapper>
  );
};

export default Transaction;
