import styled from "@emotion/styled";

export const Wrapper = styled.div`
    height: 100%;
    box-sizing: border-box;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px;
`;
export const FilterBox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    max-height: 36px;
    cursor: pointer;
    img {
        height: 20px;
        width: 20px;
        margin-left: 8px;
    }
`;

export const Body = styled.div`
    height: calc(100% - 32px);
`;

export const ClearFilters = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3b82f6;
    cursor: pointer;
`;
