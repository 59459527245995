import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 24px 16px 16px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 34px;
`;
export const Heading = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1e293b;
`;

export const Body = styled.div`
    height: calc(100vh - 152px);
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
`;

export const Container = styled.div`
    flex: 1;
`;

export const LabelHead = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #1e293b;
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;

    & > span {
        font-weight: 400;
    }
`;

export const ContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    padding: 6px 8px;
    width: 50%;
    max-width: 300px;

    & > img {
        width: 22px;
        height: 22px;
    }
`;
