import styled from "@emotion/styled/macro";

export const Table = styled.div`
    border: 1px solid #e2e8f0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 15px 25px;
`;
export const THead = styled.div`
    display: flex;
    background: #f1f5f9;
    height: 48px;
    align-items: center;
    padding: 0 16px 0 26px;
`;

export const Th = styled.div`
    flex: 1;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #64748b;
    //   border: 1px solid red;
`;
export const TBody = styled.div`
    flex: 1;
    overflow-y: auto;
`;
export const TRow = styled.div`
    display: flex;
    padding: 12px 16px 12px 26px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    & > div {
        flex: 1;
        box-sizing: border-box;
    }
`;

export const Name = styled.div`
    display: flex;
    align-items: center;
    //   border: 1px solid red;
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    color: #fff;
    background-color: #334155;
`;
export const DataBtn = styled.div`
    color: #334155;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;

export const StatusDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: ${(props) => props.color};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        // z-index: 1;
    }
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    // height: 136px;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 1;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #333333;
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
