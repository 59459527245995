import React, { useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { AddCustomerSearchIcon, AddNewTypeCheckIcon, AddNewTypeCloseIcon, PlusBlueIcon, SelectArrowDownIcon, SelectCaretDown } from "../../../icons";
import ShortcutBtn from "../../shortcut-btn/ShortcutBtn";

import * as S from "./custom-select.styles";

const Menu = ({
    searchTerm,
    setSearchTerm,
    closeMenu,
    data,
    onOptionSelect,
    disableSearch,
    onAddOption,
    type,
    showAddNew,
    menuWidth,
    position,
    openMenuRef,
    setActiveRow,
    activeRowRef,
    activeRow,
    handleOutsideClick,
    size,
}) => {
    const [showNewTypeInput, setShowNewTypeInput] = useState(false);
    const [newInputValue, setNewInputValue] = useState("");

    const scrollRef = useRef(null);
    useEffect(() => {
        if (openMenuRef.current) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
            setActiveRow(-1);
        };
    }, [data]);
    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);
    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 38) {
            //Arrow up
            if (activeRowRef.current > 0) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 40) {
            //Arrow down
            if (activeRowRef.current < data.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            if (activeRowRef.current < 0) {
                e.preventDefault();
                return;
            }
            onOptionSelect(data[activeRowRef.current]);
            e.preventDefault();
        } else if (e.keyCode === 27) {
            //ESC key
            onESChandle();
            e.preventDefault();
        }
    };

    const onESChandle = () => {
        if (searchTerm.current) {
            setSearchTerm("");
        } else {
            handleOutsideClick();
        }
    };
    const handleSubmit = () => {
        if (newInputValue) {
            closeMenu();
            setNewInputValue("");
            setShowNewTypeInput(false);
            setSearchTerm("");
            if (onAddOption) onAddOption(newInputValue, type);
        }
    };

    const handleAddNew = () => {
        // if (!data?.length) setNewInputValue(searchTerm);
        setNewInputValue(searchTerm.current);
        setShowNewTypeInput(true);
    };

    return (
        <S.MenuContainer data={data} position={position} menuWidth={menuWidth} onClick={(e) => e.stopPropagation()}>
            {!disableSearch && (
                <S.SearchContainer size={size}>
                    <S.SearchInput
                        value={searchTerm.current}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        type="text"
                        placeholder="Search"
                        autoFocus
                    />
                    <img src={AddCustomerSearchIcon} alt="search" />
                </S.SearchContainer>
            )}
            {showAddNew &&
                (showNewTypeInput ? (
                    <S.AddNewTypeInputContainer>
                        <S.AddNewInput
                            value={newInputValue}
                            onChange={(e) => setNewInputValue(e.target.value)}
                            type="text"
                            placeholder="Add new"
                            autoFocus
                        />
                        <img onClick={handleSubmit} firstItem src={AddNewTypeCheckIcon} alt="check" />
                        <img onClick={() => setShowNewTypeInput(false)} src={AddNewTypeCloseIcon} alt="close" />
                    </S.AddNewTypeInputContainer>
                ) : (
                    <S.AddNew onClick={handleAddNew}>
                        {" "}
                        <img src={PlusBlueIcon} alt="add" /> Add new {type}
                    </S.AddNew>
                ))}
            {/* {showNewTypeInput ? (
        <S.AddNewTypeInputContainer>
          <S.AddNewInput
            value={newInputValue}
            onChange={(e) => setNewInputValue(e.target.value)}
            type="text"
            placeholder="Add new"
          />
          <img
            onClick={() => setShowNewTypeInput(false)}
            firstItem
            src={AddNewTypeCheckIcon}
            alt="check"
          />
          <img onClick={handleSubmit} src={AddNewTypeCloseIcon} alt="close" />
        </S.AddNewTypeInputContainer>
      ) : (
        <S.AddNew onClick={() => setShowNewTypeInput(true)}>
          {" "}
          <img src={PlusBlueIcon} alt="add" /> Add new type
        </S.AddNew>
      )} */}
            <S.SearchList onClick={closeMenu}>
                {data?.length ? (
                    data?.map((item, idx) => (
                        <S.SearchData
                            size={size}
                            onClick={() => onOptionSelect(item)}
                            key={idx}
                            active={idx === activeRow}
                            ref={idx === activeRow ? scrollRef : null}
                        >
                            {item.name}
                            {type === "customer" && ` (${item.phone})`}
                        </S.SearchData>
                    ))
                ) : (
                    <></>
                )}
            </S.SearchList>
        </S.MenuContainer>
    );
};

const CustomSelect = ({
    data,
    onSelectChange,
    disableSearch,
    onSearchChange,
    type,
    selectedOption,
    onAddOption,
    showAddNew,
    closeMenu,
    setCloseMenu,
    localSearch,
    initalText,
    menuWidth,
    position,
    paddingHead,
    clickRef,
    bigText,
    scText,
    selectRef,
    trackRef,
    blue,
    disabled,
    size,
}) => {
    const [openMenu, _setOpenMenu] = useState(false);
    const [searchTerm, _setSearchTerm] = useState("");
    const searchTermRef = useRef(searchTerm);
    const setSearchTerm = (data) => {
        searchTermRef.current = data;
        _setSearchTerm(data);
    };

    const [filteredData, _setFilteredData] = useState([]);
    const filteredDataRef = useRef(filteredData);
    const setFilteredData = (data) => {
        filteredDataRef.current = data;
        _setFilteredData(data);
    };
    const openMenuRef = useRef(openMenu);
    const setOpenMenu = (data) => {
        if (trackRef) {
            if (!openMenuRef.current) {
                trackRef.current = type;
            } else {
                trackRef.current = null;
            }
        }
        openMenuRef.current = data;
        _setOpenMenu(data);
        if (selectRef) selectRef.current = data;
    };
    const [activeRow, _setActiveRow] = useState(-1);
    const activeRowRef = React.useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        if (closeMenu) {
            setOpenMenu(false);
            setCloseMenu(false);
        }
    }, [closeMenu]);

    const handleOptionSelect = (item) => {
        setSearchTerm("");
        if (localSearch) setFilteredData([]);
        if (onSelectChange) onSelectChange(type, item);
        handleOutsideClick();
    };

    const handleInputChange = (val) => {
        setSearchTerm(val);
        setActiveRow(0);
        if (localSearch) {
            if (val) {
                const filtered = data?.filter((item) => item.name.toLowerCase().includes(val.toLowerCase()));
                setFilteredData(filtered);
            } else setFilteredData([]);
        } else if (onSearchChange) onSearchChange(type, val);
    };

    const handleOutsideClick = () => {
        if (openMenuRef.current) {
            setSearchTerm("");
            setOpenMenu(false);
            if (onSearchChange) onSearchChange(type, "");
        }
    };

    return (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <S.SelectContainer
                ref={clickRef}
                paddingHead={paddingHead}
                onClick={() => !disabled && setOpenMenu(!openMenuRef.current)}
                scText={scText}
                blue={blue}
                size={size}
            >
                <S.SelectedOption paddingHead={paddingHead} bigText={bigText} size={size}>
                    {initalText ? initalText : selectedOption}
                </S.SelectedOption>
                <img className="caret-down" src={SelectArrowDownIcon} alt="select" />
                {scText && <ShortcutBtn text={scText} />}
                {openMenuRef.current && (
                    <Menu
                        data={filteredData.length ? filteredData : data}
                        searchTerm={searchTermRef}
                        setSearchTerm={handleInputChange}
                        closeMenu={() => setOpenMenu(false)}
                        onOptionSelect={handleOptionSelect}
                        disableSearch={disableSearch}
                        onAddOption={onAddOption}
                        type={type}
                        showAddNew={showAddNew}
                        menuWidth={menuWidth}
                        position={position}
                        openMenuRef={openMenuRef}
                        activeRow={activeRow}
                        activeRowRef={activeRowRef}
                        setActiveRow={setActiveRow}
                        handleOutsideClick={handleOutsideClick}
                        size={size}
                    />
                )}
            </S.SelectContainer>
        </OutsideClickHandler>
    );
};

export default CustomSelect;
