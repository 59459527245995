import React from "react";
import { SelectedProductChipClose } from "../../../icons";

import * as S from "./selected-chip.styles";

const SelectedChip = ({ text, onRemove }) => {
    return (
        <S.SelectedChipContainer>
            <S.ChipText>{text}</S.ChipText>
            <img onClick={() => onRemove(text)} src={SelectedProductChipClose} alt="close" />
        </S.SelectedChipContainer>
    );
};

export default SelectedChip;
