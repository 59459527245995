import { Modal } from "@mui/material";
import React, {
  useContext,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import { CloseModalIcon } from "../../icons";
import * as S from "./PaymentModal.style";
import {
  PAYMENT,
  PAYMENT_MODES,
  POSSIBLE_CASH_VALUES,
  SALE_BILL_TYPE,
} from "./../../constants/appConstants";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { debounce, isEmpty } from "lodash";
import { addSale, updateSale, sendSaleReceipt } from "../../api/local/sale-api";
import {
  getSaleTotals,
  removeAllProducts,
  removeSaleID,
} from "../../store/sale-slice";
import { removeSelectedCustomer } from "../../store/customer-slice";
import {
  addCustomer,
  getAllCustomers,
  getCustomer,
  getCustomerTransactionCount,
  updateCustomer,
} from "../../api/local/customerApi";
import OrderSummaryModal from "./../order-summary-modal/order-summary-modal";
import { setSelectedCustomer } from "./../../store/customer-slice";
import { getReturnSaleTotals } from "../../store/returnsale-slice";
import { captureException } from "../../crash-reporting";
import { FEATURES } from "../../constants/POS-plans";
import {
  convertTax,
  getQuantity,
  getSalePayloadProducts,
  toDecimalPlace,
} from "../../utils/global-fn";
import CustomerSearchDropdown from "./customer-search-dropdown/CustomerSearchDropdown";

//Maintaining index for keyboard navigations
const CUSTOMER_ELEMENTS = [0, 1];

const initialLoyaltyPoint = {
  max_usable_points: 0,
  use_point: false,
  points: 0,
  loyalty_rupees: 0,
  remaining_points: 0,
};

const PaymentModal = (
  { open, onClose, saleDiscount, handleClearData, type },
  ref
) => {
  const [activeCash, setActiveCash] = useState(0);
  const [selectedPaymentOption, _setSelectedPaymentOption] = useState(null);
  const selectedPaymentOptionRef = useRef(selectedPaymentOption);
  const setSelectedPaymentOption = (data) => {
    selectedPaymentOptionRef.current = data;
    _setSelectedPaymentOption(data);
  };
  const [selectedAmount, _setSelectedAmount] = useState(null);
  const selectedAmountRef = useRef(selectedAmount);
  const setSelectedAmount = (data) => {
    selectedAmountRef.current = data;
    _setSelectedAmount(data);
  };

  const [otherAmount, setOtherAmount] = useState("");
  const [loyaltyPoints, _setLoyaltyPoints] = useState(initialLoyaltyPoint);
  const loyaltyPointsRef = useRef(loyaltyPoints);
  const setLoyaltyPoints = (data) => {
    loyaltyPointsRef.current = data;
    _setLoyaltyPoints(data);
  };
  const elementRefs = useRef([]);
  const dispatch = useDispatch();
  const {
    saleProducts,
    saleId,
    saleTotal,
    billno,
    createdDate,
    totalAdditionalCharges,
    additional_charges,
    cartTotal,
    selectDiscount,
  } = useSelector((state) => state.sale);
  const {
    returnSaleProducts,
    selectedReturnSale,
    returnSaleTotal,
    returnSaleQuantity,
  } = useSelector((state) => state.returnSale);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const [amounts, _setAmounts] = useState(null);
  const amountsRef = useRef(amounts);
  const setAmounts = (amt) => {
    amountsRef.current = amt;
    _setAmounts(amt);
  };
  const [phoneNumber, _setPhoneNumber] = useState("");
  const phoneNumberRef = React.useRef(phoneNumber);
  const setPhoneNumber = (data) => {
    phoneNumberRef.current = data;
    _setPhoneNumber(data);
  };
  const [name, _setName] = useState("");
  const nameRef = React.useRef(name);
  const setName = (data) => {
    nameRef.current = data;
    _setName(data);
  };
  const [openCustomerDrop, setOpenCustomerDrop] = useState({
    phone: true,
    name: false,
  });
  const [customersList, _setCustomersList] = useState(null);
  const customersListRef = React.useRef(customersList);
  const setCustomersList = (data) => {
    customersListRef.current = data;
    _setCustomersList(data);
  };
  const [customerPresent, _setCustomerPresent] = useState(false);
  const customerPresentRef = React.useRef(customerPresent);
  const setCustomerPresent = (data) => {
    customerPresentRef.current = data;
    _setCustomerPresent(data);
  };
  const [customerTxnData, setCustomerTxnData] = useState(null);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const { inventorySelectedCustomer } = useSelector((state) => state.customer);
  const { loyaltyPreference, billTypePreference } = useSelector(
    (state) => state.app
  );

  //Maintaining index for keyboard navigations
  const [amountsIndex, _setAmountsIndex] = useState([2, 3]);
  const amountsIndexRef = React.useRef(amountsIndex);
  const [paymentElementsIndex, _setPaymentElementsIndex] = useState([]);
  const paymentElementsIndexRef = React.useRef(paymentElementsIndex);
  const setAmountsIndex = (data) => {
    amountsIndexRef.current = data;
    _setAmountsIndex(data);
    const paymentElems = [
      data[data.length - 1] + 1,
      data[data.length - 1] + 2,
      data[data.length - 1] + 3,
      data[data.length - 1] + 4,
    ];
    paymentElementsIndexRef.current = paymentElems;
    _setPaymentElementsIndex(paymentElems);
  };

  //Options
  const [invoiceRequired, _setInvoiceRequired] = useState(true);
  const invoiceRequiredRef = React.useRef(invoiceRequired);
  const setInvoiceRequired = (data) => {
    invoiceRequiredRef.current = data;
    _setInvoiceRequired(data);
  };
  const { features } = useSelector((state) => state.app);

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      if (type === "sale") {
        dispatch(
          getSaleTotals({
            bill_type: billTypePreference?.sale_bill_type,
          })
        );
        let total = saleTotal;
        if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
          total = total + totalAdditionalCharges;
        }
        let finalSaleTotal;
        if (loyaltyPoints?.use_point) {
          finalSaleTotal = total - saleDiscount - loyaltyPoints?.loyalty_rupees;
        } else {
          finalSaleTotal = total - saleDiscount;
        }
        getCashValues(finalSaleTotal);
        if (otherAmount) {
          setSelectedAmount(otherAmount);
        } else {
          setSelectedAmount(amountsRef.current[0]);
        }
      } else if (type === "return-sale") {
        dispatch(getReturnSaleTotals());
        getCashValues(returnSaleTotal);
      }
    }
  }, [open, loyaltyPoints]);

  useImperativeHandle(ref, () => {
    return {
      clearPaymentInfo: () => handleClearPaymentInfo(),
    };
  });

  const handleClearPaymentInfo = () => {
    setPhoneNumber("");
    setName("");
    setActiveCash(0);
    setSelectedAmount(null);
  };
  const getCurrentIdx = () => {
    return elementRefs.current?.findIndex(
      (el) => el === document.activeElement
    );
  };

  const handleDetectKeyDown = (e) => {
    if (customersListRef.current) {
      return;
    }
    if (e.keyCode === 9) {
      //TAB key
      let idx = getCurrentIdx();
      if (idx >= 0 && elementRefs.current[idx + 1]) {
        e.preventDefault();
        elementRefs.current[idx + 1].focus();
      }
    } else if (e.keyCode === 13) {
      //Enter key
      if (!openSummaryModal) {
        let idx = getCurrentIdx();
        if (idx >= 0) {
          e.preventDefault();
          if (CUSTOMER_ELEMENTS.includes(idx)) {
            elementRefs.current[amountsIndexRef.current[0]].focus();
          } else if (amountsIndexRef.current?.includes(idx)) {
            elementRefs.current[paymentElementsIndexRef.current[0]].focus();
          } else if (paymentElementsIndexRef.current?.includes(idx)) {
            onSubmit();
          }
        }
      }
    } else if (e.keyCode === 37) {
      //LEFT Arrow Key
      if (!openSummaryModal) {
        let idx = getCurrentIdx();
        if (idx >= 0 && !CUSTOMER_ELEMENTS.includes(idx)) {
          e.preventDefault();
          if (amountsIndexRef.current?.includes(idx)) {
            elementRefs.current[idx - 1].focus();
          } else if (paymentElementsIndexRef.current?.includes(idx)) {
            elementRefs.current[idx - 1].focus();
          }
        }
      }
    } else if (e.keyCode === 38) {
      //UP Arrow key
      let idx = getCurrentIdx();
      if (idx >= 0) {
        e.preventDefault();
        if (
          amountsIndexRef.current
            ?.slice(0, amountsIndexRef.current?.length - 1)
            .includes(idx)
        ) {
          elementRefs.current[CUSTOMER_ELEMENTS[0]].focus();
        } else if (
          idx === amountsIndexRef.current[amountsIndexRef.current?.length - 1]
        ) {
          elementRefs.current[amountsIndexRef.current[0]].focus();
        } else if (paymentElementsIndexRef.current?.includes(idx)) {
          elementRefs.current[
            amountsIndexRef.current[amountsIndexRef.current?.length - 1]
          ].focus();
        }
      }
    } else if (e.keyCode === 39) {
      //RIGHT Arrow Key
      if (!openSummaryModal) {
        let idx = getCurrentIdx();
        if (idx >= 0 && !CUSTOMER_ELEMENTS.includes(idx)) {
          e.preventDefault();
          if (
            amountsIndexRef.current
              ?.slice(0, amountsIndexRef.current?.length - 1)
              .includes(idx)
          ) {
            elementRefs.current[idx + 1].focus();
          } else if (
            paymentElementsIndexRef.current
              ?.slice(0, paymentElementsIndexRef.current?.length - 1)
              .includes(idx)
          ) {
            elementRefs.current[idx + 1].focus();
          }
        }
      }
    } else if (e.keyCode === 40) {
      //DOWN Arrow key
      let idx = getCurrentIdx();
      if (idx >= 0) {
        e.preventDefault();
        if (CUSTOMER_ELEMENTS.includes(idx)) {
          elementRefs.current[amountsIndexRef.current[0]].focus();
        } else if (
          amountsIndexRef.current
            ?.slice(0, amountsIndexRef.current?.length - 1)
            .includes(idx)
        ) {
          elementRefs.current[
            amountsIndexRef.current[amountsIndexRef.current?.length - 1]
          ].focus();
        } else if (
          amountsIndexRef.current[amountsIndexRef.current?.length - 1] === idx
        ) {
          elementRefs.current[paymentElementsIndexRef.current[0]].focus();
        }
      }
    }
  };

  const onPaymentModeFocus = (index) => {
    setSelectedPaymentOption(PAYMENT_MODES[index].name);
  };

  const onAmountFocus = (index, item) => {
    // setActiveCash(index);
    setActiveCash(index);
    setSelectedAmount(item);
  };

  function closePaymentModal() {
    onClose();
    setSelectedAmount(null);
    setOtherAmount("");
    setActiveCash(null);
    setLoyaltyPoints(initialLoyaltyPoint);
    setSelectedPaymentOption(null);
  }

  const getCashValues = (amount) => {
    amount = Math.ceil(amount);
    if (amount <= 0) {
      setAmountsIndex([2, 3]);
      setAmounts([amount]);
      return;
    }
    amount = parseFloat(amount.toFixed(2));
    for (let i = 0; i < POSSIBLE_CASH_VALUES.length; i++) {
      if (amount < POSSIBLE_CASH_VALUES[i]) {
        const temp = POSSIBLE_CASH_VALUES.slice(i, i + 5);
        setAmountsIndex([2, 3, ...temp.map((val, i) => i + 4)]);
        setAmounts([amount, ...temp]);
        return;
      } else if (amount === POSSIBLE_CASH_VALUES[i]) {
        const temp = POSSIBLE_CASH_VALUES.slice(i, i + 5);
        setAmountsIndex([2, ...temp.map((val, i) => i + 3)]);
        setAmounts(temp);
        return;
      }
    }
    setAmountsIndex([2, 3]);
    setAmounts([amount]);
  };

  function handleOtherAmountChange(e) {
    let val = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (!(val === "" || regex.test(val))) return;
    setSelectedAmount(val);
    setOtherAmount(val);
  }
  const onSubmit = () => {
    if (phoneNumberRef.current && phoneNumberRef.current?.length !== 10) {
      handleSnackbarDetails({
        show: true,
        title: "Please enter 10 digit phone number",
        type: "error",
      });
      return;
    }

    console.log(selectedAmountRef.current);
    console.log(amountsRef?.current[0]);

    if (!customerPresentRef.current && phoneNumberRef.current?.length === 10) {
      addCustomerData();
    } else if (
      (!customerPresentRef.current &&
        selectedPaymentOptionRef.current === PAYMENT.PAY_LATER) ||
      selectedAmountRef.current < amountsRef?.current[0]
    ) {
      handleSnackbarDetails({
        show: true,
        title: "Please select a customer for Pay Later",
        type: "error",
      });
      return;
    }
    setOpenSummaryModal(true);
  };

  const handleSale = (invoiceRequired) => {
    // if (!customerPresent && phoneNumberRef.current?.length === 10) {
    //     addCustomerData();
    // }
    handleAddSale(invoiceRequired);
  };

  const handleClear = () => {
    handleClearData();
    handleClearCustomerData();
  };

  async function handleAddSale(invoiceRequired) {
    if (!saleProducts.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please add products",
        type: "error",
      });
      return;
    }
    if (!selectedPaymentOption) {
      handleSnackbarDetails({
        show: true,
        title: "Please choose a payment mode",
        type: "error",
      });
      return;
    }
    let { loyalty_rupees } = loyaltyPointsRef.current;

    const payload = {
      status: "PUBLISH",
      bill_type: billTypePreference?.sale_bill_type,
      items: getSalePayloadProducts(
        saleProducts,
        billTypePreference?.sale_bill_type
      ),
    };

    if (
      additional_charges.length &&
      billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE
    ) {
      payload.additional_charges = additional_charges
        .filter((a) => a.value !== "")
        .map((item) => ({ ...item, value: parseFloat(item.value) }));
    } else {
      payload.additional_charges = [];
    }
    if (saleDiscount) {
      let disc = {
        type: selectDiscount.type,
        value: selectDiscount.cost,
      };
      payload.cart_manual_discount = disc;
    }
    if (createdDate) payload.created_at = createdDate.getTime();
    payload.patient_id = customerPresentRef.current?.id;
    if (billno !== undefined) payload.bill_no = billno;

    const payment = createPaymentModelV1();
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }

    try {
      let res;
      if (saleId) {
        //update the existing sale
        res = await updateSale(saleId, payload);
      } else {
        //Add the new sale
        res = await addSale(payload, invoiceRequired);
      }

      if (res?.status === 200) {
        handleClear();
        closePaymentModal();

        const sale = res.data?.entity;
        //Print the receipt
        if (invoiceRequired) {
          onInvoiceRequired(sale);
        }

        let subTitle = "Your sale has been completed successfully.";
        if (navigator.onLine && sale.patient && sale.type === "SALE") {
          sendSaleReceipt(sale.id).catch((err) => captureException(err));
          subTitle = "Sale receipt sent to customer's phone via SMS.";
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale completed",
          subtitle: subTitle,
        });
      } else {
        throw new Error(res?.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  const onInvoiceRequired = async (sale) => {
    if (sale.invoice_url) {
      window.open(sale.invoice_url, "_blank")?.focus();
    }
  };

  const createPaymentModelV1 = () => {
    let payment = {};
    let finalSaleTotal = cartTotal;
    let selectedAmount = selectedAmountRef.current;
    let { use_point, loyalty_rupees, points } = loyaltyPointsRef.current;
    payment.total_amount = finalSaleTotal;
    if (selectedPaymentOption === PAYMENT.PAY_LATER) {
      payment.amount_due = finalSaleTotal;
    } else {
      payment.amount_due =
        selectedAmount - payment.total_amount + loyalty_rupees < 0
          ? payment.total_amount - loyalty_rupees - selectedAmount
          : 0;
    }
    payment.is_paid = payment.amount_due <= 0;

    let payment_modes = [];

    let saleTotalAfterLoyalty =
      parseFloat(finalSaleTotal) - parseFloat(loyalty_rupees);

    if (selectedPaymentOption) {
      if (selectedPaymentOption === PAYMENT.PAY_LATER) {
        let payload = {
          created_at: Date.now(),
          payment_mode: PAYMENT.PAY_LATER,
          payment_amount: finalSaleTotal,
        };
        payment_modes.push(payload);
      } else {
        let payload = {
          created_at: Date.now(),
          payment_mode: selectedPaymentOption,
          payment_amount:
            parseFloat(selectedAmount) > saleTotalAfterLoyalty
              ? saleTotalAfterLoyalty
              : parseFloat(selectedAmount),
        };
        if (selectedPaymentOption === PAYMENT.CASH) {
          payload.cash_in = parseFloat(selectedAmount);
          payload.cash_out =
            parseFloat(selectedAmount) - payload.payment_amount;

          if (payload.cash_out < 0) {
            payload.cash_out = 0;
          }
        }
        payment_modes.push(payload);
        if (parseFloat(selectedAmount) < saleTotalAfterLoyalty) {
          let payload = {
            created_at: Date.now(),
            payment_mode: PAYMENT.PAY_LATER,
            payment_amount: saleTotalAfterLoyalty - parseFloat(selectedAmount),
          };
          payment_modes.push(payload);
        }
      }
    }

    if (
      features.includes(FEATURES.LOYALTY_POINT) &&
      loyaltyPreference?.loyalty_point_enabled
    ) {
      if (use_point) {
        let lp_payload = {
          created_at: Date.now(),
          payment_mode: "LOYALTY_POINT",
          payment_amount: parseFloat(loyalty_rupees),
          virtual_amount: parseFloat(points),
        };
        payment_modes.push(lp_payload);
      }
    }

    payment.payment_modes = payment_modes;

    return payment;
  };

  const handleClearCustomerData = () => {
    setPhoneNumber("");
    setName("");
    setCustomerPresent(false);
    setCustomerTxnData(null);
    dispatch(removeSelectedCustomer());
    setLoyaltyPoints(initialLoyaltyPoint);
    setCustomersList(null);
  };

  const handleChangeCustomerInput = (e) => {
    let { value, name } = e.target;
    if (name === "phone") {
      const regex = /^[0-9\b]+$/;
      if (!(value === "" || regex.test(value)) || value.length > 10) return;
      setPhoneNumber(value);
    } else if (name === "name") {
      setName(value);
    }
    if (value) {
      delayedQuery(value);
    } else {
      if (name === "phone") handleClearCustomerData();
    }
  };

  const selectDrop = (key) => {
    setCustomersList(null);
    if (key === "phone") {
      setOpenCustomerDrop({ phone: true, name: false });
    } else if (key === "name") {
      setOpenCustomerDrop({ phone: false, name: true });
    }
  };

  const delayedFunction = async (value) => {
    try {
      let res = await getAllCustomers({ search: value });
      if (res.status === 200) {
        setCustomersList(res.data.entity);
      } else if (res.status === 404) {
        setCustomersList(null);
      } else {
        throw new Error(res.data?.message);
      }
    } catch (error) {
      captureException(error);
    }
  };
  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

  const handleSelectCustomer = (data) => {
    getCustomerData(data.id);
    setOpenCustomerDrop({ phone: false, name: false });
    setCustomersList(null);
  };
  async function getCustomerData(val) {
    try {
      let res = await getCustomer(val);
      if (res.status === 200) {
        let customer = res.data.entity;
        setPhoneNumber(customer.phone);
        setName(customer.name);
        setCustomerPresent(true);
        dispatch(setSelectedCustomer(customer));
        getCustomerTransactionData(val);
        if (
          features.includes(FEATURES.LOYALTY_POINT) &&
          loyaltyPreference?.loyalty_point_enabled
        ) {
          handleGetMaximumUsablePoints(customer);
        }
      } else if (res.status !== 404) {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }

  async function addCustomerData() {
    const payload = {
      phone: phoneNumberRef.current,
    };
    if (nameRef.current) {
      payload.name = nameRef.current;
    } else {
      payload.name = "NA";
    }
    try {
      let res = await addCustomer(payload);
      if (res.status === 200) {
        setCustomerPresent(true);
        await dispatch(setSelectedCustomer(res.data));
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }

  async function updateCustomerData() {
    const payload = {};
    if (
      nameRef.current &&
      inventorySelectedCustomer?.name !== nameRef.current
    ) {
      payload.name = nameRef.current;
    }

    try {
      let res = await updateCustomer(phoneNumberRef.current, payload);
      if (res.status === 200) {
        setCustomerPresent(true);
        await dispatch(setSelectedCustomer(res.data));
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  }

  const getCustomerTransactionData = async (value) => {
    try {
      let res = await getCustomerTransactionCount(value);
      if (res.status === 200) {
        setCustomerTxnData(res.data);
      }
    } catch (err) {
      captureException(err);
    }
  };

  const handleGetMaximumUsablePoints = (customer) => {
    if (customer?.loyalty_point) {
      let total = saleTotal;
      if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
        total = total + totalAdditionalCharges;
      }
      let maxPoints = getRupeesToPoints(total - parseFloat(saleDiscount));
      let max_redeemable_points =
        loyaltyPreference?.maximum_redeemable?.loyalty_points;

      let minimum_points = Math.min(
        max_redeemable_points,
        customer?.loyalty_point
      );

      if (maxPoints > minimum_points) {
        maxPoints = minimum_points;
      }

      let loyaltyrupees = getPointsToRupees(maxPoints);

      setLoyaltyPoints({
        points: maxPoints,
        max_usable_points: maxPoints,
        use_point: true,
        loyalty_rupees: loyaltyrupees,
      });
    }
  };
  const handleChangeLoyaltyPoints = (event) => {
    let { value } = event.target;
    const regex = /^\d+\.?(\d)?(\d)?$/;
    if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
      value = value.substr(1);
    }
    if (!(value === "" || regex.test(value))) return;

    if (value > loyaltyPoints.max_usable_points) {
      value = loyaltyPoints.max_usable_points;
    }
    let loyaltyrupees = getPointsToRupees(value);
    let usePoint = loyaltyPoints.use_point;
    if (value == "" || value == 0) {
      usePoint = false;
    } else {
      usePoint = true;
    }
    setLoyaltyPoints({
      ...loyaltyPoints,
      points: value,
      loyalty_rupees: loyaltyrupees,
      use_point: usePoint,
    });
  };

  const handleCheckLoyaltyPoint = (event) => {
    let { checked } = event.target;
    if (checked) {
      let loyaltyrupees = getPointsToRupees(loyaltyPoints.max_usable_points);
      setLoyaltyPoints({
        ...loyaltyPoints,
        use_point: checked,
        points: loyaltyPoints.max_usable_points,
        loyalty_rupees: loyaltyrupees,
      });
    } else {
      setLoyaltyPoints({
        ...loyaltyPoints,
        use_point: checked,
        points: 0,
        loyalty_rupees: 0,
      });
    }
  };
  const getRupeesToPoints = (rs) => {
    let cr = loyaltyPreference?.debit_conversion?.rupees;
    let cp = loyaltyPreference?.debit_conversion?.loyalty_points;
    if (!cr || !cp) return 0;
    let points = (cp / cr) * rs;
    return parseFloat(points.toFixed(2));
  };

  const getPointsToRupees = (pts) => {
    let cr = loyaltyPreference?.debit_conversion?.rupees;
    let cp = loyaltyPreference?.debit_conversion?.loyalty_points;
    if (!cr || !cp) return 0;
    let rupees = (cr / cp) * pts;
    return parseFloat(rupees.toFixed(2));
  };

  return (
    <>
      <OrderSummaryModal
        open={openSummaryModal}
        onClose={() => {
          setOpenSummaryModal(false);
        }}
        handleAddSale={handleSale}
        selectedPaymentOption={selectedPaymentOption}
        selectedAmount={selectedAmountRef.current}
        saleDiscount={saleDiscount}
        type={type}
        invoiceRequired={invoiceRequired}
        setInvoiceRequired={setInvoiceRequired}
        loyaltyPoints={loyaltyPoints}
      />
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.Container>
          <S.Header>
            <div>Checkout</div>
            <img
              src={CloseModalIcon}
              alt="close modal icon"
              onClick={onClose}
            />
          </S.Header>
          <S.Body>
            <S.CustomerDetailsContainer>
              <S.CustomerContainer>
                <S.InputContainers>
                  <S.Label>
                    Phone Number <span>*</span>
                  </S.Label>
                  <S.InputContainer>
                    <div>+63</div>
                    <S.Bar></S.Bar>
                    <input
                      ref={(el) => (elementRefs.current[0] = el)}
                      type="text"
                      name="phone"
                      autoFocus
                      value={phoneNumber}
                      onChange={handleChangeCustomerInput}
                      onFocus={() => selectDrop("phone")}
                    />
                  </S.InputContainer>
                </S.InputContainers>
                {openCustomerDrop.phone && customersList && (
                  <CustomerSearchDropdown
                    data={customersList}
                    onSelectCustomer={handleSelectCustomer}
                    open={openCustomerDrop.phone && customersList}
                  />
                )}
              </S.CustomerContainer>
              <S.CustomerContainer>
                <S.InputContainers>
                  <S.Label>Name</S.Label>
                  <S.InputContainer>
                    <input
                      type="text"
                      ref={(el) => (elementRefs.current[1] = el)}
                      value={name}
                      name="name"
                      onChange={handleChangeCustomerInput}
                      onFocus={() => selectDrop("name")}
                    />
                  </S.InputContainer>
                </S.InputContainers>
                {openCustomerDrop.name && customersList && (
                  <CustomerSearchDropdown
                    data={customersList}
                    onSelectCustomer={handleSelectCustomer}
                    open={openCustomerDrop.name && customersList}
                  />
                )}
              </S.CustomerContainer>
              {customerTxnData?.total_amount_due > 0 && (
                <S.InputContainers flexValue="0.5">
                  <S.Label>Pending Dues</S.Label>
                  <S.DueBox>
                    ₱ {toDecimalPlace(customerTxnData?.total_amount_due)}
                  </S.DueBox>
                </S.InputContainers>
              )}
            </S.CustomerDetailsContainer>
            {features.includes(FEATURES.LOYALTY_POINT) &&
            customerPresent &&
            inventorySelectedCustomer?.loyalty_point &&
            loyaltyPreference?.loyalty_point_enabled ? (
              <S.LoyaltyPointContainer>
                <S.PointContainer cursor>
                  <input
                    type="checkbox"
                    id="loyalty_point"
                    checked={loyaltyPoints?.use_point}
                    onChange={handleCheckLoyaltyPoint}
                  />
                  <label htmlFor="loyalty_point">Use Loyalty Points</label>
                </S.PointContainer>
                {
                  <>
                    <S.LoyaltyInput
                      type="text"
                      placeholder="Points"
                      value={loyaltyPoints?.points}
                      onChange={handleChangeLoyaltyPoints}
                    />
                    <S.LoyaltyHintContainer>
                      <S.LoyaltyHint>=</S.LoyaltyHint>
                      <S.LoyaltyInput
                        type="text"
                        placeholder="Points"
                        value={`₱ ${loyaltyPoints?.loyalty_rupees}`}
                        disabled
                      />
                      <S.LoyaltyHint $italic>
                        (Remaining:{" "}
                        {Math.round(
                          inventorySelectedCustomer?.loyalty_point -
                            loyaltyPoints?.points
                        )}
                        )
                      </S.LoyaltyHint>
                    </S.LoyaltyHintContainer>
                  </>
                }
              </S.LoyaltyPointContainer>
            ) : (
              <></>
            )}
            <S.AmountContainer>
              <S.Label>Amount Received</S.Label>
              <S.AmountDiv>
                {amounts?.map((item, ind) => (
                  <S.AmountChip
                    key={ind}
                    onClick={() => {
                      setActiveCash(ind);
                      setSelectedAmount(item);
                      setOtherAmount("");
                      elementRefs.current[2 + ind].focus();
                    }}
                    active={activeCash === ind}
                  >
                    <input
                      type="radio"
                      id={`amount${ind}`}
                      name="amount-option"
                      value={item}
                      onFocus={() => onAmountFocus(ind, item)}
                      ref={(el) => (elementRefs.current[2 + ind] = el)}
                      checked={activeCash === ind}
                      readOnly
                    />
                    <label htmlFor={`amount${ind}`}>₱{item}</label>
                  </S.AmountChip>
                ))}
              </S.AmountDiv>
              <S.OtherAmountDiv>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  ref={(el) => (elementRefs.current[2 + amounts?.length] = el)}
                  value={otherAmount}
                  onChange={(e) => handleOtherAmountChange(e)}
                  onFocus={() => {
                    setActiveCash(-1);
                    setSelectedAmount(null);
                  }}
                  onBlur={() => {
                    if (!otherAmount || parseFloat(otherAmount) <= 0) {
                      setActiveCash(0);
                      setSelectedAmount(amounts[0]);
                    }
                  }}
                />
              </S.OtherAmountDiv>
            </S.AmountContainer>
            <S.PaymentModeContainer>
              <S.Label>Select Payment Method</S.Label>
              <S.PaymentModeDiv>
                {PAYMENT_MODES.map((item, idx) => (
                  <S.PaymentMode
                    key={item.id}
                    active={selectedPaymentOption === PAYMENT_MODES[idx].name}
                    onClick={() => {
                      setSelectedPaymentOption(PAYMENT_MODES[idx].name);
                      onSubmit();
                    }}
                    onFocus={() => onPaymentModeFocus(idx)}
                    tabIndex={idx}
                    ref={(el) =>
                      (elementRefs.current[2 + amounts?.length + 1 + idx] = el)
                    }
                  >
                    <img
                      src={
                        selectedPaymentOption === PAYMENT_MODES[idx].name
                          ? item.activeIcon
                          : item.icon
                      }
                      alt=""
                    />

                    {item.value}
                  </S.PaymentMode>
                ))}
              </S.PaymentModeDiv>
            </S.PaymentModeContainer>
          </S.Body>
        </S.Container>
      </Modal>
    </>
  );
};

export default React.forwardRef(PaymentModal);
