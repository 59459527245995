import React from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { CrossBlackIcon } from "../../icons";
import { useState } from "react";

const LogoutModal = ({ open, onClose, onClick, type }) => {
    const [check, setCheck] = useState(true);
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Wrapper>
                <div>
                    <S.Topbar>
                        <div>Logout?</div>
                        <img src={CrossBlackIcon} alt="" onClick={onClose} />
                    </S.Topbar>
                    <S.Heading>Are you sure want to logout ?</S.Heading>
                    {type === "admin" && (
                        <S.CheckContainer onClick={() => setCheck(!check)}>
                            <input type="checkbox" checked={check} />
                            <div>Backup Database</div>
                        </S.CheckContainer>
                    )}
                </div>
                <S.BtnContainer>
                    <S.CancelBtn onClick={onClose}>Cancel</S.CancelBtn>
                    <S.ConfirmBtn
                        onClick={() => {
                            onClick(check);
                            onClose();
                        }}
                    >
                        Logout
                    </S.ConfirmBtn>
                </S.BtnContainer>
            </S.Wrapper>
        </Modal>
    );
};

export default LogoutModal;
