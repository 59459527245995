import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 605px;
    width: 701px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
`;

export const Header = styled.div`
    // height: 69px;
    justify-content: space-between;
    padding: 21px 30px 9px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    img {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #334155;
`;

export const Body = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 24px 24px;
`;

export const BodyTop = styled.div``;
export const DueHeadline = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #334155;
    text-align: center;
    margin-bottom: 13px;
    span {
        color: #2d68fe;
    }
`;

export const CustomerDetails = styled.div`
    margin-bottom: 16px;
`;

export const CustomerDetailsHeadline = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    margin-bottom: 6px;
`;

export const CustomerDetailsBody = styled.div`
    background: #f1f5f9;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 13px 16px;
    align-items: center;
`;

export const CustomerInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #fff;
    border-radius: 4px;
    padding: 11px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;

export const CustomerName = styled.div`
    // margin-bottom: 2px;
`;

export const CustomerNumber = styled.div``;

export const CustomerInfoEditBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3b82f6;
    cursor: pointer;
`;

export const ReceiptContainer = styled.div`
    margin-bottom: 30px;
`;

export const ReceiptHeadline = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 8px;
    color: #333333;
`;

export const ReceiptBody = styled.div`
    display: flex;
    align-items: center;
    margin-top: 13px;
    position: relative;
    .arrow {
        position: absolute;
        right: 0;
    }
    & img {
        width: 18px;
        height: 18px;
    }
`;

export const ReceiptText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 8px;
    color: #334155;
`;

export const Summary = styled.div``;

export const SummaryHeadline = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #333333;
    border-bottom: ${(props) => !props.underlined && "1px solid #e2e8f0"};
    padding-bottom: 8px;
    text-decoration: ${(props) => props.underlined && "underline"};
`;

export const SummaryBody = styled.div`
    margin-top: 14px;
`;

export const PriceDetail = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #64748b;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

export const Total = styled.div`
    margin-top: 12px;
    border-top: 1px dashed #e2e8f0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #334155;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
`;

export const BodyBottom = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DoneBtn = styled.div`
    background: #2d68fe;
    border-radius: 6px;
    height: 36px;
    width: 155px;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
export const PrintSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const PrintText = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;
`;
export const HintText = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`;

export const TemplateContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const TemplateBox = styled.div`
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid ${(props) => (props.active ? "#2d68fe" : "#d9d9d9")};
    padding: 6px;
    cursor: pointer;
    ${(props) => props.active && "box-shadow: rgba(45, 104, 254, 0.16) 0px 5px 15px"};

    & > img {
        width: 62px;
        height: 62px;
    }
`;

export const TemplateText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
`;
