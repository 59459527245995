import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    // border: 1px solid red;
    margin-top: -40px;
`;
export const Heading = styled.div`
    box-sizing: border-box;
    // padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
    margin-bottom: 16px;
`;
export const TransactionList = styled.div`
    box-sizing: border-box;
    // padding: 0 15px;
    overflow: hidden;
    overflow-y: auto;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const TransRow = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    // gap: 28px;
    box-sizing: border-box;
    padding: 8px 12px;
`;
export const RowTop = styled.div`
    height: 27px;
    background: #f7f7f7;
    box-shadow: 0px 0.464567px 0.929134px rgba(0, 0, 0, 0.12);
    border-radius: 5.5748px 5.5748px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 7px;
`;

export const Date = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #455a64;
`;
export const Record = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => (props.amount < 0 ? "#10B981" : "#FF4343")};
`;
export const RowBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 7px;

    background: #ffffff;
    border-width: 0px 0.232283px 0.232283px 0.232283px;
    border-style: solid;
    border-color: #eeeeee;
`;
export const Reason = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 161.02%;
    color: #94a3b8;
    width: 170px;
    height: 49px;
    overflow: hidden;
    text-overflow: ellipsis;
    & span {
        font-weight: 500;
        color: #64748b;
    }
`;
export const Amount = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000;
`;
export const Empty = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8a8a8a;
    text-align: center;
    margin-top: 30px;
`;
export const MiddleBar = styled.div`
    background-color: #cfdae9;
    height: 100%;
    width: 1px;
`;
export const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    text-align: right;
`;
export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const NetSalaryDiv = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #aab8cb;
    gap: 8px;
    margin-top: -100px;
`;
export const NetSalary = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    color: #6b7e98;
    padding: 10px 15px;
`;
