import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";

const ClearCart = ({ open, onClose, handleClearCart, handleDraftDelete, handleDraftPurchase }) => {
    const { selectedDraftPurchase } = useSelector((state) => state.purchase);
    const { selectedDraftDamage } = useSelector((state) => state.damageEntry);
    const { selectedDraftPurchaseReturn } = useSelector((state) => state.purchaseReturn);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                if (selectedDraftPurchase || selectedDraftDamage || selectedDraftPurchaseReturn) handleDraftDelete();
                handleClearCart();
                onClose();
            } else if (e.keyCode === 121) {
                handleDraftPurchase();
                onClose();
            }
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Title>Clear Cart ?</S.Title>
                <S.Heading>
                    Are you sure want to {selectedDraftPurchase || selectedDraftDamage || selectedDraftPurchaseReturn ? "delete draft" : "clear cart"}{" "}
                    ?
                </S.Heading>
                <S.BtnContainer>
                    <div>
                        {(selectedDraftPurchase || selectedDraftDamage || selectedDraftPurchaseReturn) && (
                            <S.DraftBtn
                                onClick={() => {
                                    handleDraftPurchase();
                                    onClose();
                                }}
                            >
                                Save to Daft (F10)
                            </S.DraftBtn>
                        )}
                    </div>
                    <S.RightBtnContainer>
                        <S.CancelBtn onClick={onClose}>Cancel</S.CancelBtn>
                        <S.ConfirmBtn
                            onClick={() => {
                                if (selectedDraftPurchase || selectedDraftDamage || selectedDraftPurchaseReturn) handleDraftDelete();
                                handleClearCart();
                                onClose();
                            }}
                        >
                            {selectedDraftPurchase || selectedDraftDamage || selectedDraftPurchaseReturn ? "Delete (ENT)" : "Confirm (ENT)"}
                        </S.ConfirmBtn>
                    </S.RightBtnContainer>
                </S.BtnContainer>
            </S.Wrapper>
        </Modal>
    );
};

export default ClearCart;
