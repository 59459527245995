import styled from "@emotion/styled/macro";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: ${(props) => !props.noMargin && "8px 0 10px"};
`;
export const ChipContainer = styled.div`
    display: flex;
    gap: 10px;
`;
export const ClearFilters = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3b82f6;
    cursor: pointer;
`;
