import React, { useEffect, useImperativeHandle } from "react";
import * as S from "./proforma-invoice.style";
import "./style.css";
import CustomButton from "../../components/custom-button/CustomButton";
import { BlueDraftIcon, RedClearCartIcon } from "../../icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../utils/format-date";
import { COLOR, DiscountOption, SALES_TYPE, SALE_SCREENS } from "../../constants/appConstants";
import Cart from "../../components/proforma-invoice/cart/Cart";
import Summary from "../../components/proforma-invoice/summary/Summary";
import { useState } from "react";
import { useRef } from "react";
import {
    addProductToPI,
    removeCustomerPI,
    setAdditionalChargesPI,
    setBillNoPI,
    setCpCountPI,
    setCreatedDatePI,
    setCurrentScreenPI,
    setSelectDiscountPI,
    setTypePI,
} from "../../store/proformainvoice-slice";
import { useDispatch, useSelector } from "react-redux";
import CustomProduct from "../../components/custom-product/CustomProduct";
import ClearCart from "../../components/sales/ClearDialog/ClearCart";
import { removeAllSearchParams } from "../../store/customer-slice";
import { removeAllSearchSaleParams, setSearchSaleParams } from "../../store/sale-slice";
import { Drawer } from "@mui/material";
import DraftDrawer from "../../components/sales/draft-drawer/DraftDrawer";
import ToggleSwitch from "../../components/switch/Switch";

const ProformaInvoice = (props, ref) => {
    const { PI_currentScreen, selectedPI, PI_cp_count, PI_cp_name_count, PI_createdDate, PI_products, PI_type } = useSelector(
        (state) => state.proformaInvoice
    );

    const [openClearModal, _setOpenClearModal] = useState(false);
    const openClearModalRef = useRef(openClearModal);
    const setOpenClearModal = (data) => {
        openClearModalRef.current = data;
        _setOpenClearModal(data);
    };
    const dispatch = useDispatch();

    const [openDrawer, _setOpenDrawer] = useState(false);
    const openDrawerRef = useRef(openDrawer);
    const setOpenDrawer = (data) => {
        openDrawerRef.current = data;
        _setOpenDrawer(data);
    };

    const [openCustomProduct, _setOpenCustomProduct] = useState(false);
    const openCustomProductRef = useRef(openCustomProduct);
    const setOpenCustomProduct = (data) => {
        openCustomProductRef.current = data;
        _setOpenCustomProduct(data);
    };
    const [openDraftDrawer, setOpenDraftDrawer] = useState(false);
    const [isPI, setIsPI] = useState();

    const clearCartRef = useRef();
    const billRef = useRef();
    const cartRef = useRef();
    const saleRef = useRef();

    useImperativeHandle(ref, () => {
        return {
            openDraftSale: () => handleOpenDrawer(),
            saveDraftSale: () => {
                if (!openClearModalRef.current) {
                    saleRef.current?.saveDraft();
                }
            },
            clearAll: () => setOpenClearModal(true),
            saveSale: () => onProceedCheckout(),
            billFocus: () => billRef.current?.focus(),
            cartFocus: () => cartRef.current?.focus(),
            openAddNewProduct: () => setOpenDrawer(true),
            openAddCustomProduct: () => setOpenCustomProduct(true),
            onExpand: () => onExpandScreen(),
        };
    });

    useEffect(() => {
        let type;
        if (selectedPI) {
            type = selectedPI.type;
            dispatch(setTypePI(type));
        } else {
            type = PI_type;
        }
        setIsPI(type === SALES_TYPE.DELIVERY_CHALLAN ? true : false);
    }, [selectedPI]);

    useEffect(() => {
        if (PI_products?.length) {
            dispatch(setCurrentScreenPI(SALE_SCREENS.TABLE));
        }
    }, [PI_products]);

    const onProceedCheckout = () => {
        if (PI_currentScreen === SALE_SCREENS.TABLE) {
            dispatch(setCurrentScreenPI(SALE_SCREENS.SUMMARY));
        } else {
            saleRef.current?.saveCheckoutSale();
        }
    };

    const onExpandScreen = () => {
        if (PI_currentScreen === SALE_SCREENS.SUMMARY) {
            dispatch(setCurrentScreenPI(SALE_SCREENS.TABLE));
        }
    };

    function handleClearCart() {
        dispatch(removeCustomerPI());
        clearCartRef.current?.clearPaymentInfo();
    }

    const handleAddCustomProduct = (product) => {
        let { name, sale_price, price, quantity, discount, discountValue, activeDiscount } = product;
        let payload = {
            name: name ? name : `Item ${PI_cp_name_count + 1}`,
            sale_price: parseFloat(sale_price),
            price: parseFloat(price),
            purchase_price: parseFloat(sale_price) * 0.92,
            quantity: parseFloat(quantity),
            discount: parseFloat(discount),
            discountValue: parseFloat(discountValue),
            sku: `cp#${PI_cp_count + 1}`,
            upc: "",
            is_custom_product: true,
            activeDiscount: activeDiscount ?? DiscountOption.FLAT,
        };

        dispatch(addProductToPI(payload));
        let cp_payload = {
            cp: PI_cp_count + 1,
        };
        if (!name) cp_payload.cp_name = PI_cp_name_count + 1;
        dispatch(setCpCountPI(cp_payload));
    };

    const handleOpenDrawer = () => {
        dispatch(setSearchSaleParams({ type: PI_type, status: "DRAFT" }));
        setOpenDraftDrawer(true);
    };

    const handleSwitchChange = (checked) => {
        if (checked === isPI) return;
        setIsPI(checked);

        dispatch(setTypePI(checked ? SALES_TYPE.DELIVERY_CHALLAN : SALES_TYPE.PROFORMA_INVOICE));
    };
    return (
        <>
            <CustomProduct
                open={openCustomProduct}
                onClose={() => setOpenCustomProduct(false)}
                onAddCustomProduct={(product) => handleAddCustomProduct(product)}
            />
            <ClearCart open={openClearModal} onClose={() => setOpenClearModal(false)} clearCartRef={clearCartRef} type="proforma-invoice" />
            <Drawer anchor="right" hideBackdrop="true" open={openDraftDrawer} onClose={() => setOpenDraftDrawer(false)} className="right-drawer">
                <DraftDrawer
                    open={openDraftDrawer}
                    onCloseDrawer={() => {
                        setOpenDraftDrawer(false);
                        dispatch(removeAllSearchSaleParams());
                    }}
                    clearCartRef={clearCartRef}
                    type="proforma-invoice"
                />
            </Drawer>
            <S.SalesContainer>
                <S.Headbar>
                    <S.HeadContainer>
                        <S.HeaderText active={isPI === false} onClick={() => handleSwitchChange(false)}>
                            Proforma
                        </S.HeaderText>
                        <ToggleSwitch
                            type="sale_type"
                            checked={isPI}
                            isTwoWay={true}
                            handleChange={(e, type) => handleSwitchChange(e.target.checked)}
                        />
                        <S.HeaderText active={isPI === true} onClick={() => handleSwitchChange(true)}>
                            Delivery Challan
                        </S.HeaderText>
                    </S.HeadContainer>
                    <S.RightHeadBar>
                        <CustomButton label="Add Custom Product (F5)" color={COLOR.BLUE} onClick={() => setOpenCustomProduct(true)} />
                        <CustomButton label="Add New Product (F6)" color={COLOR.BLUE} onClick={() => setOpenDrawer(true)} />
                        <CustomButton label="Counters (F11)" color={COLOR.BLUE} icon={BlueDraftIcon} onClick={() => handleOpenDrawer()} />
                        <CustomButton label="Clear Cart (F9)" color={COLOR.RED} icon={RedClearCartIcon} onClick={() => setOpenClearModal(true)} />
                        <S.DatePicker>
                            <S.Created>Date</S.Created>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(PI_createdDate ?? new Date()))}
                                onChange={(date) => dispatch(setCreatedDatePI(date))}
                                dateFormat="dd/MM/yyyy"
                                className="proforma-invoice-date-picker"
                            />
                        </S.DatePicker>
                    </S.RightHeadBar>
                </S.Headbar>
                <S.CartBody>
                    <Cart
                        openClearModal={openClearModal}
                        handleClearCart={() => handleClearCart()}
                        setOpenDrawer={setOpenDrawer}
                        openDrawer={openDrawerRef.current}
                        openCustomProduct={openCustomProductRef}
                    />
                    {PI_currentScreen === SALE_SCREENS.SUMMARY && (
                        <Summary ref={saleRef} billRef={billRef} cartRef={cartRef} clearCartRef={clearCartRef} openDrawer={openDrawerRef.current} />
                    )}
                </S.CartBody>
            </S.SalesContainer>
        </>
    );
};

export default React.forwardRef(ProformaInvoice);
