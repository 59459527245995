import React, { useRef, useState } from "react";
import { AddCustomerSearchIcon, CloseModalIcon } from "../../icons";
import * as S from "./styles";
import { CUSTOMER_SORT, CUSTOMER_SORT_WITH_LP, PAYMENT_STATUS } from "./../../constants/appConstants";
import CustomSelect from "./../product-drawer/custom-select/custom-select";
import { useDispatch, useSelector } from "react-redux";
import { removeSearchParams } from "../../store/customer-slice";
import { FEATURES } from "../../constants/POS-plans";

const CustomerFilter = ({
    handleSearchChange,
    handleSelectChange,
    inputRef,
    displayData,
    searchText,
    setSearchText,
    handleCheckBoxChange,
    check,
}) => {
    const selectRef = useRef(false);
    const trackRef = useRef(null);
    const dispatch = useDispatch();
    const { features } = useSelector((state) => state.app);

    const onClickCheckChange = (e) => {
        e.stopPropagation();
        if (check) {
            handleCheckBoxChange(false);
        } else {
            handleCheckBoxChange(true);
        }
    };

    return (
        <S.FilterBox>
            <S.LeftFilterBox>
                <S.InputBox>
                    <img src={AddCustomerSearchIcon} alt="search" />
                    <S.Input
                        type="text"
                        value={searchText}
                        placeholder="Search by name, phone or email"
                        onChange={(e) => handleSearchChange(e.target.value)}
                        ref={inputRef}
                    />
                    {searchText && (
                        <img
                            src={CloseModalIcon}
                            alt="clear"
                            onClick={() => {
                                setSearchText("");
                                dispatch(removeSearchParams("search"));
                            }}
                        />
                    )}
                </S.InputBox>
                <S.CheckBoxContainer onClick={onClickCheckChange}>
                    <input type="checkbox" checked={check} id="pending_payment" onChange={(e) => handleCheckBoxChange(e.target.checked)} />
                    <label>Show Pending Payments</label>
                </S.CheckBoxContainer>
                {/* <CustomSelect
                type={INVENTORY_SELECT.NORMAL_SELECT}
                data={categoryData.categories || []}
                text="Tags"
              /> */}
                {/* <CustomSelect
                    type="pending_payment"
                    initalText="Pending Payment"
                    disableSearch
                    data={PAYMENT_STATUS}
                    onSelectChange={handleSelectChange}
                    selectedOption={displayData?.pending_payment?.name}
                    menuWidth="235px"
                    selectRef={selectRef}
                    trackRef={trackRef}
                /> */}
            </S.LeftFilterBox>
            <S.RightFilterBox>
                Sort:
                <CustomSelect
                    type="sort"
                    initalText="Select Sort"
                    selectedOption={displayData?.sort?.name}
                    disableSearch
                    // text="States"
                    // onSearchChange={handleSearchChange}
                    data={features.includes(FEATURES.LOYALTY_POINT) ? CUSTOMER_SORT_WITH_LP : CUSTOMER_SORT}
                    onSelectChange={handleSelectChange}
                    menuWidth="235px"
                    position="left"
                    selectRef={selectRef}
                    trackRef={trackRef}
                />
            </S.RightFilterBox>
        </S.FilterBox>
    );
};

export default CustomerFilter;
