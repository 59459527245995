import styled from "@emotion/styled/macro";

export const SalesContainer = styled.div`
    width: 100%;
    height: calc(100vh-56px);
    //   padding: 0 24px 37px;
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    background-color: #f1f5f9;
    overflow-y: auto;
    box-sizing: border-box;
`;

export const Title = styled.div`
    width: 39px;
    height: 21px;
    // padding: 23px 0 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #1e293b;
`;
export const TitleFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const ClearCart = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ff4343;
    border-radius: 4px;
    padding: 7px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ff4343;
    cursor: pointer;
    height: 36px;
`;

export const SalesHeadline = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: ${(props) => (props.active ? "#1e293b" : "#94a3b8")};
    cursor: pointer;
`;

export const CartBody = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
`;

export const Headbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const RightHeadBar = styled.div`
    display: flex;
    align-items: center;
`;

export const DatePickerContainer = styled.div`
    position: absolute;
    //   top: 39px;
    right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 13000;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    width: 241px;
    max-height: 36px;
    cursor: pointer;
    justify-content: space-between;
    background-color: #fff;

    img {
        height: 20px;
        width: 20px;
    }
`;

export const DatePicker = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 200px;
`;
export const Created = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
`;
export const TopFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
export const CustomBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 10px;
    color: ${(props) => (props.fill ? "#fff" : props.color ? props.color : "#000")};
    border: 1px solid ${(props) => (props.color ? props.color : "#000")};
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    background: ${(props) => (props.fill ? props.color : "#fff")};
    margin-right: ${(props) => props.mr && props.mr};
    cursor: pointer;
`;

export const HeadContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
