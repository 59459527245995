import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    max-height: calc(100vh-56px);
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const Heading = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #1e293b;
`;

export const BlueBtn = styled.div`
    background: #2d68fe;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 8px;
    cursor: pointer;
`;
export const Body = styled.div`
    width: 100%;
    height: calc(100vh - 146px);
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;
export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 16px;
    border-bottom: 1px solid #e2e8f0;
`;
export const Filters = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SortContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const InputBox = styled.div`
    width: 291px;
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    // top: 39px;
    // right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 100;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding-right: 8px;
    display: flex;
    align-items: center;
    max-height: 38px;
    cursor: pointer;
    img {
        height: 16px;
        width: 16px;
    }
`;

export const ChipContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
    // border: 1px solid red;
`;
