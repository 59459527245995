import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 100%;
    // height: 100vh;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
`;

export const RightHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const Headline = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #1e293b;

    &:nth-child(3) {
        display: none;
    }
`;

export const AddProductBtn = styled.div`
    background: #2d68fe;
    border-radius: 3px;
    // height: 36px;
    // width: 99px;
    padding: 9px 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Body = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const TabsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // height: 63px;
    padding: 5px 16px;
    border-bottom: 1px solid #e2e8f0;
`;

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    // padding-bottom: 9px;
`;

export const Tab = styled.div`
    font-weight: 400;
    font-size: 13.5px;
    line-height: 16px;

    color: ${(props) => (props.active ? "#2D68FE" : "#475569")};
    border-bottom: ${(props) => (props.active ? "2px solid #2D68FE" : "unset")};
    padding-bottom: ${(props) => (props.active ? "7.5px" : "unset")};
    margin-bottom: ${(props) => (props.active ? "-9px" : "unset")};

    margin-right: 36px;
    cursor: pointer;

    display: flex;
    align-items: center;
    .inventory-count {
        background: ${(props) => (props.active ? "#3B82F6" : "#E2E8F0")};
        // ${(props) => props.active && "border-radius: 2px"};
        border-radius: 2px;
        margin-left: 6px;
        padding: 0 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => (props.active ? "#FFF" : "#334155")};
        display: flex;
        align-items: center;
        max-height: 16px;
    }
`;

export const Content = styled.div`
    flex: 1;
    // padding: ${(props) => (props.open ? "16px 12px 0" : "24px 24px 0")};
    padding: 16px 12px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.2s ease;
`;

export const FiltersLeft = styled.div`
    display: flex;
    gap: 8px;
`;

export const FiltersRight = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #334155;
`;

export const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 12px;
`;

export const InputContainer = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 9px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 320px;
    height: 36px;

    img {
        width: 18px;
        height: 18px;
    }

    img[alt*="search"] {
        margin-right: 9px;
    }

    img[alt*="clear"] {
        cursor: pointer;
        margin-left: 9px;
        margin-right: 6px;
    }
`;

export const Input = styled.input`
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #475569;

    &::placeholder {
        color: #94a3b8;
    }
`;

export const ChangeView = styled.div`
    height: 36px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    gap: 16px;
    padding: 6px 12px;

    img {
        width: 24px;
        height: 24px;
    }
`;
// export const ChangeView = styled.div``;
export const DownloadContainer = styled.div`
    width: 36px;
    height: 36px;
    cursor: pointer;

    & > img {
        width: 100%;
        height: 100%;
    }
`;
export const TodayDataContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const WhiteBox = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 8px;
    color: #253238;
`;
export const BlueBox = styled.div`
    background: #ccdfff;
    border-radius: 4px;
    padding: 6px;
`;
export const Today = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
