import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { captureException } from "../crash-reporting";
import { getAllCashDrawerItems } from "../api/local/cashDrawer-api";

const initialState = {
    cashDrawerItems: [],
    loading: false,
    error: "",
    searchParams: {},
    selectedCashDrawerItem: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchAllCashDrawerItems = createAsyncThunk("cashDrawer/fetchAllCashDrawerItems", (queryParams) =>
    getAllCashDrawerItems(queryParams)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const cashDrawerSlice = createSlice({
    name: "cashDrawer",
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },

        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = {};
        },
        setSelectedCashDrawerItem: (state, action) => {
            state.selectedCashDrawerItem = action.payload;
        },
        removeSelectedCashDrawerItem: (state, action) => {
            state.selectedCashDrawerItem = null;
        },
        removeAllItems: (state, action) => {
            state.cashDrawerItems = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCashDrawerItems.pending, (state) => {
            state.loading = true;
            state.cashDrawerItems = [];
            state.error = "";
        });
        builder.addCase(fetchAllCashDrawerItems.fulfilled, (state, action) => {
            state.loading = false;
            state.cashDrawerItems = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllCashDrawerItems.rejected, (state, action) => {
            state.loading = false;
            state.cashDrawerItems = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default cashDrawerSlice.reducer;
export const { setSearchParams, removeSearchParams, removeAllSearchParams, setSelectedCashDrawerItem, removeSelectedCashDrawerItem, removeAllItems } =
    cashDrawerSlice.actions;
