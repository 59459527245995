import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { SelectArrowDownIcon } from "../../../icons";
import * as S from "./style";
import MultiRangeSlider from "./../../multiRangeSlider/MultiRangeSlider";

const CustomSlider = ({ initalText, min, max, handleSlider, clickRef }) => {
    const [openSlider, setOpenSlider] = useState(false);

    const sliderChange = (min, max) => {
        handleSlider(min, max);
    };

    return (
        <S.Wrapper>
            <S.Heading onClick={() => setOpenSlider(!openSlider)} ref={clickRef}>
                {initalText ? initalText : "Range Slider"}
            </S.Heading>
            <img
                className="caret-down"
                src={SelectArrowDownIcon}
                alt="select"
                onClick={() => setOpenSlider(!openSlider)}
            />
            <OutsideClickHandler onOutsideClick={() => setOpenSlider(false)}>
                {openSlider && (
                    <MultiRangeSlider
                        min={min}
                        max={max}
                        onChange={({ min, max }) => handleSlider(min, max)}
                    />
                )}
            </OutsideClickHandler>
        </S.Wrapper>
    );
};

export default CustomSlider;
