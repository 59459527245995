import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPurchases, setPurchaseCountData } from "../../../store/purchase-slice";
import Purchase from "./purchase";
import * as S from "./purchase-table.styles";
import Lottie from "lottie-react";
import EmptyTable from "../../.././animations/empty_table.json";
import { PURCHASE_STATUS, PURCHASE_TYPE } from "../../../constants/appConstants";
import store from "../../../store/store";
import DeleteModal from "../delete-modal/DeleteModal";
import { getPurchasesCount, updatePurchase } from "../../../api/local/purchase-api";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { captureException } from "../../../crash-reporting";

const PurchaseTable = ({ header, activeRow, activeRowRef, setActiveRow, selectRef }) => {
    const { purchases, searchParams } = useSelector((state) => state.purchase);
    const dispatch = useDispatch();
    const scrollRef = useRef(null);
    const purchaseRowRef = useRef(null);
    const tableRef = useRef();

    const [openDeleteModal, _setOpenDeleteModal] = useState({ show: false, data: null });
    const openDeleteModalRef = useRef(openDeleteModal);

    const setOpenDeleteModal = (data) => {
        openDeleteModalRef.current = data;
        _setOpenDeleteModal(data);
    };
    const [keyboardBuffer, _setKeyboardBuffer] = useState(null);
    const keyboardBufferRef = useRef(keyboardBuffer);
    const setKeyboardBuffer = (data) => {
        keyboardBufferRef.current = data;
        _setKeyboardBuffer(data);
    };

    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        // if (!isEmpty(searchParams)) {
        // dispatch(fetchAllPurchases(searchParams));
        if (tableRef.current) {
            tableRef.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
        // }
    }, [searchParams]);

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);
    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (openDeleteModalRef.current?.show || selectRef.current) return;
        if (keyboardBufferRef.current && Date.now() - keyboardBufferRef.current?.t < 50) {
            setKeyboardBuffer({
                k: e.keyCode,
                t: Date.now(),
            });
            return;
        }
        setKeyboardBuffer({
            k: e.keyCode,
            t: Date.now(),
        });
        if (e.keyCode === 33) {
            //Pg Up key
            if (activeRowRef.current > 0) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 34) {
            //Pg down key
            let totalPurchases = store.getState().purchase.purchases;
            if (activeRowRef.current < totalPurchases.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            if (purchaseRowRef.current) {
                purchaseRowRef.current?.refPurchaseRowClick();
            }
            e.preventDefault();
        } else if (e.keyCode === 46) {
            //Delete key
            let totalPurchases = store.getState().purchase.purchases;
            setOpenDeleteModal({ show: true, data: totalPurchases[activeRowRef.current] });
            e.preventDefault();
        }
    };

    const handleGetPurchaseCount = async () => {
        try {
            let res = await getPurchasesCount();
            if (res.status === 200) {
                dispatch(setPurchaseCountData(res.data));
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    function handleDeletePurchase(purchase) {
        updatePurchase(purchase.id, { status: PURCHASE_STATUS.TRASH })
            .then((res) => {
                if (res.status === 200) {
                    handleGetPurchaseCount();
                    dispatch(fetchAllPurchases(searchParams));
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Purchase deleted successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            })
            .catch((err) => {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: "Something went wrong...",
                    type: "error",
                });
            });
    }

    return (
        <>
            <DeleteModal
                open={openDeleteModal.show}
                onClose={() => setOpenDeleteModal({ show: false, data: null })}
                handleDelete={() => {
                    handleDeletePurchase(openDeleteModal.data);
                }}
            />
            <S.Table>
                <S.TableHeaderContainer>
                    <S.TableHeader flexValue="0.95">Date created</S.TableHeader>
                    <S.TableHeader flexValue="0.5" center>
                        {header === PURCHASE_TYPE.PURCHASE ? "Purchase" : header === PURCHASE_TYPE.RETURN ? "Return" : "Damage"} Id
                    </S.TableHeader>
                    {header === PURCHASE_TYPE.RETURN && (
                        <S.TableHeader flexValue="1" center>
                            Return Against
                        </S.TableHeader>
                    )}
                    {header === PURCHASE_TYPE.PURCHASE && (
                        <S.TableHeader flexValue="1" center>
                            Bill Type
                        </S.TableHeader>
                    )}
                    <S.TableHeader flexValue="0.15" center></S.TableHeader>
                    {header !== PURCHASE_TYPE.DAMAGE_ENTRY && <S.TableHeader flexValue="1">Supplier Name</S.TableHeader>}
                    <S.TableHeader flexValue="0.45" center>
                        Total SKUs
                    </S.TableHeader>
                    <S.TableHeader flexValue="0.15" center></S.TableHeader>
                    <S.TableHeader flexValue="0.6" center>
                        Total Amount
                    </S.TableHeader>
                    <S.TableHeader flexValue="0.15" center></S.TableHeader>
                    {header !== PURCHASE_TYPE.DAMAGE_ENTRY && <S.TableHeader flexValue="1">Payment Status</S.TableHeader>}
                    {header === PURCHASE_TYPE.DAMAGE_ENTRY && <S.TableHeader flexValue="1">Status</S.TableHeader>}
                    <S.TableHeader flexValue="2">Brands</S.TableHeader>
                    <S.TableHeader flexValue="0.15" mr="12px"></S.TableHeader>
                </S.TableHeaderContainer>
                {purchases?.length ? (
                    <S.TableBody ref={tableRef}>
                        {purchases.map((purchase, idx) => (
                            <Purchase
                                purchase={purchase}
                                key={idx}
                                header={header}
                                idx={idx}
                                activeRow={activeRow}
                                scrollRef={scrollRef}
                                setOpenDeleteModal={setOpenDeleteModal}
                                ref={purchaseRowRef}
                            />
                        ))}
                    </S.TableBody>
                ) : (
                    <S.EmptyTable>
                        <div>
                            <Lottie animationData={EmptyTable} />
                        </div>
                    </S.EmptyTable>
                )}
            </S.Table>
        </>
    );
};

export default PurchaseTable;
