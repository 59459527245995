import React from "react";
import * as S from "./style";
import InputLabel from "./../../product-drawer/input-label/input-label";
import CustomSelect from "./../../product-drawer/custom-select/custom-select";
import { useState } from "react";
import { BANK_ACCOUNT_TYPES } from "../../../constants/appConstants";
import CustomInput from "./../../product-drawer/custom-input/custom-input";
import { ArrowRight, DarkArrowLeft } from "../../../icons";

const Account = ({
    displayData,
    setDisplayData,
    setTabView,
    handleSubmit,
    employeeId,
    accountType,
    setAccountType,
}) => {
    function handleSelectChange(key, item) {
        setAccountType(item.name);
        setDisplayData({ ...displayData, account_type: item.value });
    }
    const handleInputChange = (type) => (val) => {
        if (type === "salary" || type === "account_number") {
            const regex = /^[0-9\b]+$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val });
        } else if (type === "ifsc_code") {
            const regex = /^([a-zA-Z0-9]+)$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val.toUpperCase() });
        } else if (type === "bank_name" || type === "account_name") {
            const regex = /^([a-zA-Z]+)$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val });
        } else {
            setDisplayData({ ...displayData, [type]: val });
        }
        if (!displayData.account_type) {
            setDisplayData({ ...displayData, account_type: "BANK_ACCOUNT" });
        }
    };
    return (
        <S.Wrapper>
            <S.Body>
                <S.Heading>Account Detail</S.Heading>
                <S.InputContainer>
                    <div>
                        <InputLabel text="Account Type" />
                        <CustomSelect
                            type="account"
                            disableSearch
                            initalText={accountType}
                            data={BANK_ACCOUNT_TYPES}
                            onSelectChange={handleSelectChange}
                        />
                    </div>
                    {accountType === "BANK ACCOUNT" && (
                        <>
                            <div>
                                <InputLabel text="IFSC Code" />
                                <CustomInput
                                    type="text"
                                    value={displayData.ifsc_code}
                                    onChange={handleInputChange("ifsc_code")}
                                />
                            </div>
                            <div>
                                <InputLabel text="Account Name" />
                                <CustomInput
                                    type="text"
                                    value={displayData.account_name}
                                    onChange={handleInputChange("account_name")}
                                />
                            </div>
                            <div>
                                <InputLabel text="Bank Name" />
                                <CustomInput
                                    type="text"
                                    value={displayData.bank_name}
                                    onChange={handleInputChange("bank_name")}
                                />
                            </div>

                            <div>
                                <InputLabel text="Account Number" />
                                <CustomInput
                                    type="text"
                                    value={displayData.account_number}
                                    onChange={handleInputChange("account_number")}
                                />
                            </div>
                        </>
                    )}
                    {accountType === "QR" && (
                        <div>
                            <InputLabel text="QR Id" />
                            <CustomInput
                                type="text"
                                value={displayData.upi_id}
                                onChange={handleInputChange("upi_id")}
                            />
                        </div>
                    )}
                </S.InputContainer>
            </S.Body>
            <S.FooterBtns>
                <S.NextBtn onClick={() => setTabView(0)}>
                    <img src={DarkArrowLeft} alt="" />
                </S.NextBtn>
                <S.FooterRightBtns>
                    <S.SaveBtn onClick={handleSubmit}>Save</S.SaveBtn>
                    <S.NextBtn onClick={() => setTabView(3)}>
                        Next
                        <img src={ArrowRight} alt="" />
                    </S.NextBtn>
                </S.FooterRightBtns>
            </S.FooterBtns>
        </S.Wrapper>
    );
};

export default Account;
