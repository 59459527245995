import styled from "@emotion/styled/macro";

export const Container = styled.div`
    width: 386px;
    // height: 255px;
    background: #ffffff;
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const BottomBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
    box-sizing: border-box;
    padding: 12px 30px;
    background-color: #f7f7f9;
    border-radius: 9px;
`;
export const BlueBtn = styled.div`
    background: #2e65f3;
    padding: 6px 9px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
`;
export const GreyBtn = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #707090;
    cursor: pointer;
`;
export const Body = styled.div`
    box-sizing: border-box;
    padding: 34px 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const HeadBar = styled.div`
    display: flex;
    justify-content: space-between;

    & > img {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
`;

export const Head = styled.div`
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    color: #334155;
    width: 280px;
`;
export const SubHead = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #232325;
    width: 280px;
`;
