import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;
export const Hint = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #94a3b8;
    font-style: italic;
`;
export const Heading = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #64748b;
    margin-bottom: 16px;

    span {
        color: #e54630;
    }
`;

export const StatusBtn = styled.div`
    border-radius: 2px;
    padding: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    cursor: pointer;
    background: ${(props) => props.color};
`;

export const WeekRow = styled.div`
    display: flex;
    align-items: center;
`;

export const Day = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
    width: 66px;
    margin-right: 37px;
`;

export const WeekContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100vh - 350px);
    overflow: hidden;
    overflow-y: auto;
`;

export const Colon = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #334155;
`;
export const SubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    width: 448px;
    margin-right: 12px;
`;

export const TimeInput = styled.input`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    font-family: helvetica;
    border: 1px solid #e2e8f0;
    border-radius: 1.5px;
    outline: none;
    padding: 8px;
    height: 34px;
    width: 115px;
`;

export const ClosedBtn = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ff4343;
`;

export const LiveTitle = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;
export const LiveContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 35px;
    margin-top: 24px;
`;

export const AlwaysBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: -4px;

    label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #334155;
        margin-left: 4px;
        cursor: pointer;
    }
`;

export const CheckBox = styled.input`
    width: 16px;
    height: 16px;
`;
