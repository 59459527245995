import styled from "@emotion/styled/macro";

export const Table = styled.div`
    // height: calc(100% - 46px);
    height: 414px;
    border: 1px solid #e2e8f0;
    margin: 0 16px 8px;
`;

export const TableHeads = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f5f9;
    padding-right: 7px;
`;

export const Head = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    text-align: ${(props) => (props.align ? props.align : "center")};
    padding: 9px 13px;
    height: 36px;
`;
export const TableBody = styled.div`
    height: calc(100% - 36px);
    overflow: hidden;
    overflow-y: auto;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Rowdata = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    text-align: ${(props) => (props.align ? props.align : "center")};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    padding: 16px 13px;
    height: 48px;
`;

export const ExtraData = styled.div`
    margin-top: -9px;
`;
