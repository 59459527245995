import axios from "./axios";
import { GLOBAL_SEARCH_API, FILE_UPLOAD_API } from "../../constants/apiRoutes";

const getGlobalSearchData = (searchKey) => {
    const queryParams = { text: searchKey };
    return axios.get(GLOBAL_SEARCH_API, {
        params: queryParams,
    });
};

const uploadFile = async (file, queryParams = {}) => {
    const formData = new FormData();
    formData.append("file", file);
    return await axios.post(
        FILE_UPLOAD_API,
        formData,
        {
            params: queryParams,
        },
        {
            headers: {
                "content-type": "multipart/form-data",
            },
        }
    );
};

export { getGlobalSearchData, uploadFile };
