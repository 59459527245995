import React, { useContext, useRef, useState } from "react";
import { Modal } from "@mui/material";
import * as S from "./style";
import "./style.css";
import { CalendarIcon, CloseModalIcon } from "../../icons";
import { PAYLATER_PAYMENT_MODES, PAYMENT } from "../../constants/appConstants";
import { Calendar } from "react-date-range";
import { calendarFormatDate, formatDate } from "../../utils/format-date";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
import { clearSupplierTransactions } from "../../api/local/supplierApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSuppliers } from "./../../store/supplier-slice";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { clearCustomerTransactions } from "../../api/local/customerApi";
import { fetchAllCustomers } from "./../../store/customer-slice";
import { captureException } from "../../crash-reporting";
import { addExpense, updateExpense } from "../../api/local/expenses-api";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PaylaterPayment = ({ open, onClose, type, supplierPayData, customerPayData, expensePayData }) => {
    const elementRefs = useRef([]);
    const [selectedPaymentOption, _setSelectedPaymentOption] = useState(null);
    const selectedPaymentOptionRef = useRef(selectedPaymentOption);
    const setSelectedPaymentOption = (data) => {
        selectedPaymentOptionRef.current = data;
        _setSelectedPaymentOption(data);
    };
    const [chequeDate, _setChequeDate] = useState(null);
    const chequeDateRef = useRef(chequeDate);
    const setChequeDate = (data) => {
        chequeDateRef.current = data;
        _setChequeDate(data);
    };
    const [chequeNumber, _setChequeNumber] = useState("");
    const chequeNumberRef = useRef(chequeNumber);
    const setChequeNumber = (data) => {
        chequeNumberRef.current = data;
        _setChequeNumber(data);
    };
    const dispatch = useDispatch();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const supplierSearchParams = useSelector((state) => state.supplier.searchParams);
    const { searchParams } = useSelector((state) => state.customer);
    const [amount, _setAmount] = useState("");
    const amountRef = useRef(amount);
    const setAmount = (data) => {
        amountRef.current = data;
        _setAmount(data);
    };
    const [maxAmount, setMaxAmount] = useState(0);
    const [paymentElementsIndex, _setPaymentElementsIndex] = useState([0, 1, 2, 3]);
    const paymentElementsIndexRef = React.useRef(paymentElementsIndex);
    const amountsIndexRef = React.useRef([0, 1]);

    useEffect(() => {
        if (open) {
            if (type === "supplier-pay") {
                setMaxAmount(supplierPayData?.total_amount);
            } else if (type === "customer-pay") {
                setMaxAmount(customerPayData?.total_amount);
            }
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open, type]);

    const getCurrentIdx = () => {
        return elementRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const handleDetectKeyDown = (e) => {
        let idx = getCurrentIdx();
        if (e.keyCode === 9) {
            //TAB key
            if (idx >= 0 && elementRefs.current[idx + 1]) {
                e.preventDefault();
                elementRefs.current[idx + 1].focus();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            if (idx >= 0) {
                if (idx === 0) {
                    e.preventDefault();
                    elementRefs.current[1].focus();
                } else {
                    e.preventDefault();
                    onSubmit();
                }
            }
        } else if (e.keyCode === 37) {
            //LEFT Arrow Key
            if (idx >= 0 && elementRefs.current[idx - 1]) {
                e.preventDefault();
                elementRefs.current[idx - 1].focus();
            }
        } else if (e.keyCode === 39) {
            //RIGHT Arrow Key
            if (idx >= 0 && elementRefs.current[idx + 1]) {
                e.preventDefault();
                elementRefs.current[idx + 1].focus();
            }
        } else if (e.keyCode === 38) {
            //UP Arrow key
            if (idx >= 0) {
                if (idx === amountsIndexRef.current[amountsIndexRef.current?.length - 1]) {
                    e.preventDefault();
                    elementRefs.current[amountsIndexRef.current[0]].focus();
                } else if (paymentElementsIndexRef.current?.includes(idx)) {
                    e.preventDefault();
                    elementRefs.current[amountsIndexRef.current[amountsIndexRef.current?.length - 1]].focus();
                }
            }
        } else if (e.keyCode === 40) {
            //DOWN Arrow key
            if (idx >= 0) {
                if (amountsIndexRef.current?.slice(0, amountsIndexRef.current?.length - 1).includes(idx)) {
                    e.preventDefault();
                    elementRefs.current[amountsIndexRef.current[amountsIndexRef.current?.length - 1]].focus();
                } else if (amountsIndexRef.current[amountsIndexRef.current?.length - 1] === idx) {
                    e.preventDefault();
                    elementRefs.current[paymentElementsIndexRef.current[0]].focus();
                }
            }
        }
    };

    const onPaymentModeFocus = (index) => {
        setSelectedPaymentOption(PAYLATER_PAYMENT_MODES[index].name);
    };
    const handleInputChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (!(e.target.value === "" || regex.test(e.target.value))) return;
        setChequeNumber(e.target.value);
    };
    const handleDateChange = (item) => {
        setChequeDate(item);
    };

    const onCompleteTransaction = () => {
        onClose();
        setAmount("");
        setMaxAmount(0);
        setChequeNumber("");
        setChequeDate(null);
        setSelectedPaymentOption(null);
        elementRefs.current = [];
    };

    const handleSupplierPay = async () => {
        let payload = {
            total_amount: Number(amountRef.current),
            payment_mode: selectedPaymentOptionRef.current,
        };
        if (supplierPayData?.purchaseIDs?.length) payload.purchase_ids = supplierPayData.purchaseIDs;
        if (selectedPaymentOptionRef?.current === PAYMENT.CHEQUE) {
            let cheque = {
                number: chequeNumberRef?.current,
                date: chequeDateRef?.current?.getTime(),
            };
            payload.cheque = cheque;
        }
        try {
            let res = await clearSupplierTransactions(supplierPayData.supplierId, payload);
            if (res.status === 200) {
                onCompleteTransaction();
                dispatch(fetchAllSuppliers(supplierSearchParams));
                supplierPayData.payModalClose();

                handleSnackbarDetails({
                    show: true,
                    title: "Payment Successful",
                    type: "success",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const handleCustomerPay = async () => {
        let payload = {
            total_amount: Number(amountRef.current),
            payment_mode: selectedPaymentOptionRef.current,
        };
        if (customerPayData?.saleIDs?.length) payload.sale_ids = customerPayData.saleIDs;
        if (selectedPaymentOptionRef?.current === PAYMENT.CHEQUE) {
            let cheque = {
                number: chequeNumberRef?.current,
                date: chequeDateRef?.current?.getTime(),
            };
            payload.cheque = cheque;
        }
        try {
            let res = await clearCustomerTransactions(customerPayData.customerId, payload);
            if (res.status === 200) {
                onCompleteTransaction();
                dispatch(fetchAllCustomers(searchParams));
                customerPayData.payModalClose();
                handleSnackbarDetails({
                    show: true,
                    title: "Payment Successful",
                    type: "success",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const onSubmit = () => {
        if (!selectedPaymentOptionRef.current) {
            handleSnackbarDetails({
                show: true,
                title: "Please select a payment option",
                type: "error",
            });
            return;
        }
        if (type !== "expense-pay" && !amountRef.current) {
            handleSnackbarDetails({
                show: true,
                title: "Please enter amount",
                type: "error",
            });
            return;
        }
        if (selectedPaymentOptionRef.current === PAYMENT.CHEQUE) {
            if (!chequeNumberRef.current) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter a cheque number",
                    type: "error",
                });
                return;
            }
            if (!chequeDateRef.current) {
                handleSnackbarDetails({
                    show: true,
                    title: "Please enter a cheque date",
                    type: "error",
                });
                return;
            }
        }
        if (type === "supplier-pay") {
            handleSupplierPay();
        } else if (type === "customer-pay") {
            handleCustomerPay();
        } else if ("expense-pay") {
            handleExpensePay();
        }
    };

    const handleAmountChange = (e) => {
        let val = e.target.value;
        const regex = /^[0-9\b]+$/;
        if (!(val === "" || regex.test(val))) return;
        if (val > maxAmount) {
            val = maxAmount;
        }
        setAmount(val);
    };

    const handleExpensePay = async () => {
        const { amount, createdDate, description, expenseType, repeat } = expensePayData?.data;
        if (!expenseType) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose expense type",
                type: "error",
            });
            return;
        }
        if (!amount) {
            handleSnackbarDetails({
                show: true,
                title: "Please add amount",
                type: "error",
            });
            return;
        }
        const payload = {
            expense_type: expenseType,
            amount: parseInt(amount),
        };
        if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
        if (description) payload.description = description;
        if (repeat) payload.repeat = repeat;

        const payment = {
            payment_amount: parseInt(amount),
            payment_mode: selectedPaymentOptionRef.current,
        };

        if (selectedPaymentOptionRef.current === PAYMENT.CHEQUE && chequeNumberRef.current && chequeDateRef.current) {
            let account = {
                cheque_number: chequeNumberRef.current,
                cheque_date: chequeDateRef.current?.getTime(),
            };
            payment.account = account;
        }

        if (!isEmpty(payment)) {
            payload.payment = payment;
        }

        let expenseId = expensePayData?.id;

        try {
            let res;
            let title;
            let subtitle;
            if (expenseId) {
                res = await updateExpense(expenseId, payload);
                title = "Expense Updated";
                subtitle = "Expense updated successfully";
            } else {
                res = await addExpense(payload);
                title = "Expense Added";
                subtitle = "New expense added successfully";
            }

            if (res?.status === 200) {
                onCompleteTransaction();
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: title,
                    subtitle: subtitle,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    <div>Checkout</div>
                    <img src={CloseModalIcon} alt="close modal icon" onClick={onClose} />
                </S.Header>
                <S.Body>
                    {type !== "expense-pay" && (
                        <S.AmountContainer>
                            <S.Label>
                                Maximum Amount: ₱{type === "customer-pay" ? customerPayData?.total_amount : supplierPayData?.total_amount}
                            </S.Label>
                            <S.OtherAmountDiv>
                                <input
                                    type="text"
                                    placeholder="Enter Amount"
                                    autoFocus
                                    ref={(el) => (elementRefs.current[0] = el)}
                                    value={amount}
                                    onChange={(e) => handleAmountChange(e)}
                                />
                            </S.OtherAmountDiv>
                        </S.AmountContainer>
                    )}

                    <S.PaymentModeContainer>
                        <S.Label>Select Payment Method</S.Label>
                        <S.PaymentModeDiv>
                            {PAYLATER_PAYMENT_MODES.map((item, idx) => (
                                <S.PaymentMode
                                    key={item.id}
                                    active={selectedPaymentOption === PAYLATER_PAYMENT_MODES[idx].name}
                                    onClick={() => {
                                        setSelectedPaymentOption(item.name);
                                        if (selectedPaymentOption !== PAYLATER_PAYMENT_MODES[3].name) {
                                            onSubmit();
                                        }
                                    }}
                                    onFocus={() => onPaymentModeFocus(idx)}
                                    tabIndex={idx}
                                    ref={(el) => (elementRefs.current[1 + idx] = el)}
                                >
                                    <img src={selectedPaymentOption === PAYLATER_PAYMENT_MODES[idx].name ? item.activeIcon : item.icon} alt="" />

                                    {item.value}
                                </S.PaymentMode>
                            ))}
                            {selectedPaymentOption === PAYLATER_PAYMENT_MODES[3].name && (
                                <S.ChequeDatas>
                                    <S.ChequeNumberContainer>
                                        <input
                                            type="text"
                                            placeholder="Enter Cheque No"
                                            autoFocus
                                            ref={(el) => (elementRefs.current[PAYLATER_PAYMENT_MODES.length] = el)}
                                            value={chequeNumber}
                                            onChange={handleInputChange}
                                        />
                                    </S.ChequeNumberContainer>
                                    <S.ChequeDateInputContainer>
                                        <S.ChequeDateInput>
                                            <DatePicker
                                                selected={Date.parse(calendarFormatDate(chequeDate))}
                                                onChange={(date) => handleDateChange(date)}
                                                dateFormat="dd/MM/yyyy"
                                                className="paylater-payment-calendar"
                                                minDate={new Date()}
                                                popperPlacement="top-end"
                                            />
                                            <S.Bar></S.Bar>
                                            <img src={CalendarIcon} alt="calendar" />
                                        </S.ChequeDateInput>
                                    </S.ChequeDateInputContainer>
                                    <S.SubmitBtn onClick={onSubmit}>Submit</S.SubmitBtn>
                                </S.ChequeDatas>
                            )}
                        </S.PaymentModeDiv>
                    </S.PaymentModeContainer>
                </S.Body>
            </S.Container>
        </Modal>
    );
};

export default PaylaterPayment;
