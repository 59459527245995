import React, { useEffect, useState } from "react";
import * as S from "./style";
import { PREFERENCES_TYPES } from "../../../constants/appConstants";
import SalePreferences from "./sale-preferences/SalePreferences";
import Loyalty from "../loyalty/Loyalty";
import { useSelector } from "react-redux";
import { FEATURES } from "../../../constants/POS-plans";
import Printing from "../printing/Printing";

const Preferences = () => {
    const [activeTab, setActiveTab] = useState(null);
    const { features } = useSelector((state) => state.app);

    useEffect(() => {
        if (features.includes(FEATURES.LOYALTY_POINT)) {
            setActiveTab(PREFERENCES_TYPES.LOYALTY);
        } else {
            setActiveTab(PREFERENCES_TYPES.SALE_PREFERENCES);
        }
    }, []);
    return (
        <S.Wrapper>
            <S.Tabs>
                {features.includes(FEATURES.LOYALTY_POINT) && (
                    <S.Tab
                        active={activeTab === PREFERENCES_TYPES.LOYALTY}
                        onClick={() => setActiveTab(PREFERENCES_TYPES.LOYALTY)}
                    >
                        {PREFERENCES_TYPES.LOYALTY}
                    </S.Tab>
                )}
                <S.Tab
                    active={activeTab === PREFERENCES_TYPES.SALE_PREFERENCES}
                    onClick={() =>
                        setActiveTab(PREFERENCES_TYPES.SALE_PREFERENCES)
                    }
                >
                    {PREFERENCES_TYPES.SALE_PREFERENCES}
                </S.Tab>
                <S.Tab
                    active={activeTab === PREFERENCES_TYPES.PRINTING}
                    onClick={() => setActiveTab(PREFERENCES_TYPES.PRINTING)}
                >
                    {PREFERENCES_TYPES.PRINTING}
                </S.Tab>
                <S.Tab
                    active={activeTab === PREFERENCES_TYPES.MORE}
                    onClick={() => {}}
                >
                    {PREFERENCES_TYPES.MORE}
                </S.Tab>
            </S.Tabs>
            <S.Body>
                {activeTab === PREFERENCES_TYPES.SALE_PREFERENCES && (
                    <SalePreferences />
                )}
                {activeTab === PREFERENCES_TYPES.LOYALTY && <Loyalty />}
                {activeTab === PREFERENCES_TYPES.PRINTING && <Printing />}
            </S.Body>
        </S.Wrapper>
    );
};

export default Preferences;
