import { Modal } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useContext, useState, useImperativeHandle } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_MODAL, MODAL_TYPES, PRODUCT_STATUS } from "../../../../constants/appConstants";
import { ProductPlaceholderIcon, ThreeDotsVerticalIcon, WifiDisconnectedIcon } from "../../../../icons";
import { SnackbarContext } from "../../../../snackbar-context/snackbar-context";
import { fetchAllProducts } from "../../../../store/product-slice";
import BarcodeDialog from "../../../barcode-dialog/barcode-dialog";
import ConfirmationDialog from "../../../product-drawer/confirmation-dialog/confirmation-dialog";

import * as S from "./products-grid.styles";
import { hasDecimal } from "../../../../utils/global-fn";

const OptionsContainer = ({ onOpenDrawer, openBarcodeDialog, openConfirmationModal }) => {
    return (
        <S.OptionsContainer>
            <S.Option onClick={onOpenDrawer}>Edit</S.Option>
            <S.Option onClick={openConfirmationModal}>Delete</S.Option>
            <S.Option onClick={openBarcodeDialog}>Print Barcode</S.Option>
        </S.OptionsContainer>
    );
};

const Product = ({ product, openEditDrawer, activeRow, idx, scrollRef, setOpenModal, setActiveRow, setOpenBarcodeModal }, ref) => {
    const [open, setOpen] = useState(false);
    const [imgError, setImgError] = useState(false);
    // const [openBarcodeModal, setOpenBarcodeModal] = useState(false);
    // const [openModal, setOpenModal] = useState({
    //     show: false,
    //     type: null,
    //     data: null,
    // });
    const { searchParams, products } = useSelector((state) => state.product);
    const dispatch = useDispatch();
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useImperativeHandle(ref, () => {
        return {
            // refDeleteProduct: () => handleConfirmationModalSubmit(products[activeRow]),
        };
    });

    // const handleConfirmationModalSubmit = (product) => {
    //     setOpenModal({ show: false, type: null, data: null });
    //     const payload = cloneDeep(product);
    //     payload.status = PRODUCT_STATUS.TRASH;
    //     updateProduct(product?.sku, payload)
    //         .then((res) => {
    //             dispatch(fetchAllProducts(searchParams));
    //             handleSnackbarDetails({
    //                 show: true,
    //                 title: "Deleted succesfully",
    //                 type: "success",
    //                 subtitle: "The product has been deleted succesfully",
    //             });
    //         })
    //         .catch((err) => console.log(err));
    // };
    const handleRowFocus = () => {
        setActiveRow(idx);
    };

    return (
        <>
            {/* <BarcodeDialog open={openBarcodeModal} data={product} onClose={() => setOpenBarcodeModal(false)} /> */}
            {/* <Modal
                open={openModal.show}
                onClose={() => setOpenModal({ show: false, type: null, data: null })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ConfirmationDialog
                    closeModal={() => setOpenModal({ show: false, type: null, data: null })}
                    data={openModal.data}
                    type={openModal.type}
                    handleSubmit={() => handleConfirmationModalSubmit(product)}
                    open={openModal.show}
                />
            </Modal> */}
            <S.Grid active={idx === activeRow} ref={idx === activeRow ? scrollRef : null} onClick={handleRowFocus}>
                {imgError || !product?.gallery?.[0] ? (
                    <S.ImagePlaceholder onClick={() => openEditDrawer(product)}>
                        <img src={ProductPlaceholderIcon} alt="product" />
                    </S.ImagePlaceholder>
                ) : (
                    <S.ProductImage
                        onClick={() => openEditDrawer(product)}
                        src={product.gallery?.[0] + `?n=${product.date_modified}`}
                        onError={(e) => {
                            e.target.src = WifiDisconnectedIcon;
                            setImgError(true);
                        }}
                        alt="product"
                    />
                )}
                <S.ProductDetails>
                    <div onClick={() => openEditDrawer(product)}>
                        <S.Brand>{product.brand?.name}</S.Brand>
                        <S.Name>{product.name}</S.Name>
                        <S.PriceData>
                            {product.price !== product.sale_price && (
                                <S.TextStrike>
                                    {" "}
                                    <S.StrikedPrice>₱{product.price}</S.StrikedPrice>{" "}
                                </S.TextStrike>
                            )}
                            <S.Price>₱{product.sale_price?.toFixed(2)}</S.Price>
                        </S.PriceData>
                        <S.Stock stockType={product.stock <= 0 ? "0" : "1"}>
                            In Stock: {hasDecimal(product.stock) ? product.stock?.toFixed(2) : product.stock}
                        </S.Stock>
                        {product.attributes && <S.Attr>{
                            Object.keys(product.attributes).map(k => `${k} : ${product.attributes[k]}`).join(", ")
                        }</S.Attr>}
                    </div>
                    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                        <S.OptionIcon>
                            <img onClick={() => setOpen(true)} src={ThreeDotsVerticalIcon} alt="" />
                        </S.OptionIcon>
                        {open && (
                            <OptionsContainer
                                onOpenDrawer={() => openEditDrawer(product)}
                                openBarcodeDialog={() =>
                                    setOpenBarcodeModal({
                                        show: true,
                                        data: product,
                                    })
                                }
                                openConfirmationModal={() =>
                                    setOpenModal({
                                        show: true,
                                        type: MODAL_TYPES.DELETE,
                                        data: DELETE_MODAL,
                                        product: product,
                                    })
                                }
                            />
                        )}
                    </OutsideClickHandler>
                </S.ProductDetails>
            </S.Grid>
        </>
    );
};

export default React.forwardRef(Product);
