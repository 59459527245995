import React from "react";
import * as S from "./style";
import Modal from "@mui/material/Modal";
import { CANCEL_REASONS, ONLINE_ORDERS_FOCUS } from "./../../../constants/appConstants";
import { useState } from "react";
import { ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";
import { generateMetrics } from "./../../../utils/metrics";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { SnackbarContext } from "./../../../snackbar-context/snackbar-context";
import { completeOnlineSale } from "../../../api/local/onlineSale-api";
import { removeAllPackingProducts, removeFulifillingOrder, removeSingleOrder, setScreenFocus } from "../../../store/onlinesale-slice";
import { fetchAllOnlineSales } from "./../../../store/onlinesale-slice";
import { useEffect } from "react";
import { useRef } from "react";
import Lottie from "lottie-react";
import ButtonLoader from "../../../animations/button-loader.json";
import { captureException } from "../../../crash-reporting";

const PartialOrderDialog = ({ open, onClose, data, setActiveOrderRow }) => {
    const [cancels, _setCancels] = useState(CANCEL_REASONS);
    const cancelsRef = useRef(cancels);
    const setCancels = (data) => {
        cancelsRef.current = data;
        _setCancels(data);
    };
    const [btnLoader, setBtnLoader] = useState(false);
    const [otherReason, _setOtherReason] = useState("");
    const otherReasonRef = useRef(otherReason);
    const setOtherReason = (data) => {
        otherReasonRef.current = data;
        _setOtherReason(data);
    };

    const { order, params, packingProducts } = useSelector((state) => state.onlineSale);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleCompleteOnlineSale();
            }
        }
    };

    const handleCancelReasons = (index) => {
        let newReasons = cancels.map((item, idx) => (index === idx ? { ...item, value: !item.value } : item));
        setCancels(newReasons);
    };

    const handleCompleteOnlineSale = async () => {
        setBtnLoader(true);
        const payload = {
            timestamp: Date.now(),
            items: packingProducts
                .filter((p) => p.order_id === order.id)[0]
                .products.filter((item) => item.isChecked)
                .map((_item) => ({ sku: _item.sku, quantity: _item.packedQuantity })),
            reason: cancelsRef.current
                .filter((item) => item.value === true)
                .map((_item) => (_item.name === "Other (Please explain below)" ? otherReasonRef.current : _item.name)),
        };

        if (!payload.items.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a product",
                type: "error",
            });
            return;
        }
        if (!payload.reason.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please choose a reason",
                type: "error",
            });
            return;
        }

        try {
            let res = await completeOnlineSale(order.id, payload);
            if (res.status === 200) {
                dispatch(removeSingleOrder());
                dispatch(removeAllPackingProducts({ order_id: res.data.id }));
                dispatch(fetchAllOnlineSales(params));
                dispatch(removeFulifillingOrder());
                dispatch(setScreenFocus(ONLINE_ORDERS_FOCUS.ORDERS_SECTION));
                setActiveOrderRow(0);
                handleSnackbarDetails({
                    show: true,
                    title: "Order packed successfully",
                    type: "success",
                });
                setBtnLoader(false);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Container>
                <S.Title>Why are not you packing below items?</S.Title>
                <S.ProductList>
                    {data?.map((item) => (
                        <S.DataDetails>
                            <S.ImageContainer>
                                <img
                                    src={item.gallery ? item.gallery : ProductPlaceholderIcon}
                                    alt="product"
                                    onError={(e) => {
                                        e.target.src = WifiDisconnectedIcon;
                                    }}
                                />
                            </S.ImageContainer>
                            <div>
                                <S.DarkText>{item.brand?.name}</S.DarkText>
                                <S.DarkText fs="12px">
                                    {item.name}
                                    {item.name}
                                </S.DarkText>
                                <S.GreyText fs="10px">{generateMetrics(item)}</S.GreyText>
                            </div>
                        </S.DataDetails>
                    ))}
                </S.ProductList>
                <S.TextContainer>
                    {cancels.map((item, index) => (
                        <div key={item.id}>
                            <input type="checkbox" id={item.name} checked={item.value} onClick={() => handleCancelReasons(index)} />
                            <label htmlFor={item.name}>{item.name}</label>
                        </div>
                    ))}
                    <S.Textarea
                        placeholder="Add other reasons"
                        name="other"
                        value={otherReason}
                        onChange={(e) => setOtherReason(e.target.value)}
                    ></S.Textarea>
                </S.TextContainer>
                <S.ButtonContainer>
                    {btnLoader ? (
                        <S.LoaderBtn>
                            <div>
                                <Lottie animationData={ButtonLoader} />
                            </div>
                        </S.LoaderBtn>
                    ) : (
                        <S.BlueBtn onClick={handleCompleteOnlineSale}>Submit</S.BlueBtn>
                    )}
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default PartialOrderDialog;
