import { Modal } from "@mui/material";
import React from "react";
import * as S from "./style";
import { CloseModalIcon } from "../../../icons";

const ConfirmDialog = ({ open, onClose, handleDraftBill, handleBillClear }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Wrapper>
                <S.Header>
                    Want to save current bill?
                    <img src={CloseModalIcon} alt="close icon" onClick={onClose} />
                </S.Header>
                <S.BtnContainer>
                    <S.ClearBtn onClick={handleBillClear}>Clear</S.ClearBtn>
                    <S.DraftBtn onClick={handleDraftBill}>Save as Draft</S.DraftBtn>
                </S.BtnContainer>
            </S.Wrapper>
        </Modal>
    );
};

export default ConfirmDialog;
