import React, {
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BillIcon,
  ChequeIcon,
  CloseModalIcon,
  CreditCardIcon,
  LoyaltyPointIcon,
  PayLaterIcon,
  RupeesIcon,
  UpiIcon,
} from "../../../icons";
import { setSelectedCustomer } from "../../../store/customer-slice";
import { sendSaleReceipt } from "../../../api/local/sale-api";
import * as S from "./style";
import { errorNotify, successNotify } from "../../../utils/toast";
import {
  PAYMENT,
  PAYMENT_MODES,
  PAYMENT_MODE_SUMMARY,
} from "../../../constants/appConstants";
import { captureException } from "../../../crash-reporting";
import { SALES_TYPE } from "./../../../constants/appConstants";
import { hasDecimal, toDecimalPlace } from "../../../utils/global-fn";
import PaymentMode from "../../payment-mode/PaymentMode";
import { getSaleNetAmount } from "../../../utils/object-parser";

const SaleSummary = (
  { type, billno, setBillno, setPaymentMode, paymentMode, billRef, paymentRef },
  ref
) => {
  const { selectedSale } = useSelector((state) => state.sale);
  const [adjustedCash, setAdjustedCash] = useState(0);

  const { inventorySelectedCustomer } = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => {
    return {
      onSendTaxInvoice: () => {
        if (selectedSale?.type === "SALE") {
          sendTaxReceipt(true);
        }
      },
    };
  });
  useEffect(() => {
    if (
      selectedSale?.net_amount_round_off &&
      toDecimalPlace(
        selectedSale?.net_amount_round_off - selectedSale?.net_amount
      ) != 0
    ) {
      setAdjustedCash(
        selectedSale?.net_amount_round_off - selectedSale?.net_amount
      );
    }
  }, []);
  // async function handleGetSale() {
  //     try {
  //         let res = await getSale(selectedSale.id);
  //         if (res.status === 200) {
  //             setSale(res.data);
  //         } else {
  //             throw new Error(res.data?.message);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  const sendTaxReceipt = async () => {
    try {
      if (!navigator.onLine) {
        errorNotify("No internet connection");
        return;
      }
      if (selectedSale?.id && selectedSale.patient) {
        sendSaleReceipt(selectedSale.id).catch((err) => captureException(err));
        successNotify("Tax Receipt sent successfully.");
      } else if (!selectedSale.patient) {
        throw new Error("Please add a customer before sending a sale receipt!");
      } else {
        throw new Error("Unable to send receipt. Please try again!");
      }
    } catch (e) {
      errorNotify(e.message);
      captureException(e);
    }
  };

  return (
    <S.Wrapper>
      <S.Heading>
        {selectedSale?.is_online_sale && "Online"}{" "}
        {selectedSale?.type === SALES_TYPE.PROFORMA_INVOICE
          ? "Proforma Invoice"
          : selectedSale?.type === SALES_TYPE.DELIVERY_CHALLAN
          ? "Delivery Challan"
          : type}{" "}
        Summary
      </S.Heading>
      <S.Body>
        <div>
          {inventorySelectedCustomer ? (
            <S.EmployeeDiv>
              <S.EmpName>{inventorySelectedCustomer?.name}</S.EmpName>
              <S.EmpPhone>({inventorySelectedCustomer?.phone})</S.EmpPhone>
            </S.EmployeeDiv>
          ) : (
            <></>
          )}
          <S.GreyText>
            <div>Quantity:</div>
            <div>{selectedSale?.total_quantity}</div>
          </S.GreyText>
          <S.GreyText>
            <div>Subtotal:</div>
            <div>₱{selectedSale?.sub_total?.toFixed(2)}</div>
          </S.GreyText>
          <S.GreyText>
            <div>Tax:</div>
            <div>₱{selectedSale?.total_tax?.toFixed(2)}</div>
          </S.GreyText>
          <S.GreyText>
            <div>Discount:</div>
            <div>
              ₱
              {(
                (selectedSale?.total_discount ?? 0) -
                (selectedSale?.cart_discount ?? 0)
              )?.toFixed(2)}
            </div>
          </S.GreyText>
          <S.GreyText>
            <div>Bill Total:</div>
            <div>₱{selectedSale?.total_sale_price?.toFixed(2)}</div>
          </S.GreyText>
          <S.Dotted></S.Dotted>
          <S.GreyText>
            <div>Cart Discount:</div>
            <div>₱{selectedSale?.cart_discount?.toFixed(2)}</div>
          </S.GreyText>
          {Array.isArray(selectedSale?.additional_charges) &&
          selectedSale?.additional_charges?.length ? (
            <>
              <S.GreyText $underline>Additional Charges</S.GreyText>
              {selectedSale?.additional_charges?.map((item) => (
                <S.GreyText>
                  <div>{item.field}:</div>
                  <div>₱{item.value}</div>
                </S.GreyText>
              ))}
            </>
          ) : (
            <></>
          )}

          {adjustedCash ? (
            <S.GreyText>
              <div>Adjust Cash:</div>
              <div>₱{adjustedCash?.toFixed(2)}</div>
            </S.GreyText>
          ) : (
            <></>
          )}
          <S.OrderTotal>
            <div>Cart Total:</div>
            <div>₱{getSaleNetAmount(selectedSale)}</div>
          </S.OrderTotal>
          {selectedSale?.type === SALES_TYPE.SALE && (
            <S.GreyText>
              <div>Total Profit:</div>
              <div>
                ₱{(selectedSale?.net_profit + adjustedCash)?.toFixed(2)}
              </div>
            </S.GreyText>
          )}
          <S.Dotted mt="6px"></S.Dotted>

          {!selectedSale?.is_online_sale && (
            <S.BillContainer>
              <S.Label>
                Invoice number <div>(F7)</div>
              </S.Label>
              <S.FieldContainer>
                <input
                  type="text"
                  value={billno}
                  onChange={(e) => setBillno(e.target.value)}
                  disabled={selectedSale?.is_online_sale}
                  ref={billRef}
                />
                <S.Line></S.Line>
                <img src={BillIcon} alt="" />
              </S.FieldContainer>
            </S.BillContainer>
          )}
          <S.Dotted></S.Dotted>

          <S.Change>
            {(selectedSale?.payment || selectedSale?.payment) && (
              <PaymentMode bill={selectedSale} />
            )}
            {/* <S.ChangeText onClick={() => setOpenPaymentModal(true)} ref={paymentRef}>
                            Change
                        </S.ChangeText> */}
          </S.Change>
        </div>
        <div>
          {selectedSale?.type === SALES_TYPE.SALE ? (
            <S.Change>
              <div>
                <S.Mode>Send tax invoice (F8)</S.Mode>
              </div>
              <S.ChangeText onClick={() => sendTaxReceipt()}>Send</S.ChangeText>
            </S.Change>
          ) : (
            <></>
          )}
        </div>
      </S.Body>
    </S.Wrapper>
  );
};

export default React.forwardRef(SaleSummary);
