import React from "react";
import { Modal } from "@mui/material";
import { CloseModalIcon, DescriptionIcon } from "../../icons";

import * as S from "./order-summary-modal.styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSaleTotals } from "../../store/sale-slice";
import { useState } from "react";
import AddCustomerModal from "./../add-customer-modal/add-customer-modal";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
  PAYMENT,
  PAYMENT_MODES,
  SALE_BILL_TYPE,
} from "../../constants/appConstants";
import { captureException } from "../../crash-reporting";
import { isEmpty } from "lodash";
import { getTotalsFromPI } from "../../store/proformainvoice-slice";
import { hasDecimal, toDecimalPlace } from "../../utils/global-fn";
import ToggleSwitch from "../switch/Switch";

const OrderSummaryModal = ({
  open,
  onClose,
  selectedAmount,
  selectedPaymentOption,
  handleAddSale,
  saleDiscount,
  handleReturnSale,
  type,
  loyaltyPoints,
  handleAddSaleInvoice,
  selectedTemplate,
  setSelectedTemplate,
  handleAddProformaInvoice,
  handleConvertToInvoice,
}) => {
  const [invoiceRequired, _setInvoiceRequired] = useState(true);
  const invoiceRequiredRef = React.useRef(invoiceRequired);
  const setInvoiceRequired = (data) => {
    invoiceRequiredRef.current = data;
    _setInvoiceRequired(data);
  };
  const {
    saleProducts,
    saleQuantity,
    saleTotal,
    taxTotal,
    discountValue,
    totalAdditionalCharges,
    additional_charges,
    selectedSale,
    cartTotal,
  } = useSelector((state) => state.sale);
  const dispatch = useDispatch();
  const { inventorySelectedCustomer } = useSelector((state) => state.customer);
  const {
    selectedReturnSale,
    returnSaleTotal,
    returnSaleQuantity,
    taxTotal: returnSaleTaxTotal,
    returnCartDiscount,
    totalReturnAdditionalCharges,
    returnAdditionalCharges,
    returnCartTotal,
    returnDiscountValue,
  } = useSelector((state) => state.returnSale);
  const { billTypePreference } = useSelector((state) => state.app);
  const {
    PI_products,
    PI_customer,
    PI_quantity,
    PI_total,
    PI_taxTotal,
    PI_discountValue,
    PI_totalAdditionalCharges,
    PI_additional_charges,
    PI_cartTotal,
  } = useSelector((state) => state.proformaInvoice);

  const [changeDue, setChangeDue] = useState(null);
  const [printTemplates, setPrintTemplates] = useState(null);
  const [additionalCharges, setAdditionalCharges] = useState(null);

  useEffect(() => {
    if (open) {
      if (!["proforma-invoice"].includes(type)) {
        setChangeDue(
          selectedAmount -
            (type === "return-sale"
              ? returnCartTotal
              : type === "convert-to-invoice"
              ? selectedSale?.cart_total
              : billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE
              ? cartTotal - loyaltyPoints?.loyalty_rupees
              : cartTotal - loyaltyPoints?.loyalty_rupees)
        );
        if (type === "return-sale") {
          if (!isEmpty(returnAdditionalCharges)) {
            let addCharges = [];
            for (let key in returnAdditionalCharges) {
              if (returnAdditionalCharges[key]) {
                let pl = {
                  field: key,
                  value: parseFloat(returnAdditionalCharges[key]),
                };
                addCharges.push(pl);
              }
            }
            setAdditionalCharges(addCharges);
          }
        }
      }
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open]);

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 13) {
      //Enter key
      e.preventDefault();
      handleDone();
      onClose();
    } else if (e.keyCode === 37) {
      //LEFT Arrow Key
      e.preventDefault();
      setInvoiceRequired(false);
    } else if (e.keyCode === 39) {
      //RIGHT Arrow key
      e.preventDefault();
      setInvoiceRequired(true);
    } else if (e.keyCode === 32) {
      //SPACE Key
      e.preventDefault();
      setInvoiceRequired(!invoiceRequiredRef.current);
    }
  };

  useEffect(() => {
    if (["sale", "invoice-sale"].includes(type)) {
      dispatch(
        getSaleTotals({
          bill_type: billTypePreference?.sale_bill_type,
        })
      );
    }
  }, [saleProducts]);

  useEffect(() => {
    if (type === "proforma-invoice") {
      dispatch(getTotalsFromPI());
    }
  }, [PI_products]);

  useEffect(() => {
    if (
      ["invoice-sale", "convert-to-invoice", "proforma-invoice"].includes(type)
    ) {
      handleGetPrintTemplates();
    }
  }, []);

  const handleGetPrintTemplates = async () => {
    try {
      const templates = await window.electronAPI.getPrintTemplates();
      if (templates) {
        setPrintTemplates(templates);
        if (templates && templates?.length) {
          setSelectedTemplate(templates[0]);
        }
      }
    } catch (error) {
      captureException(error);
    }
  };

  const handleDone = () => {
    if (type === "return-sale") {
      handleReturnSale(invoiceRequiredRef.current);
    } else if (type === "invoice-sale") {
      handleAddSaleInvoice(invoiceRequiredRef.current);
    } else if (type === "proforma-invoice") {
      handleAddProformaInvoice(invoiceRequiredRef.current);
    } else if (type === "convert-to-invoice") {
      handleConvertToInvoice(invoiceRequiredRef.current);
    } else {
      handleAddSale(invoiceRequiredRef.current);
    }
  };

  const handlePrintSwitchChange = (e) => {
    const { checked } = e.target;
    setInvoiceRequired(checked);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.Container>
        <S.Header>
          <S.HeaderText>Order Summary</S.HeaderText>
          <img onClick={onClose} src={CloseModalIcon} alt="close" />
        </S.Header>
        <S.Body>
          <S.BodyTop>
            {changeDue <= 0 || selectedPaymentOption === PAYMENT.PAY_LATER ? (
              <></>
            ) : (
              <S.DueHeadline>
                Change due: <span>₱{toDecimalPlace(changeDue)}</span>{" "}
              </S.DueHeadline>
            )}

            {type === "return-sale" ? (
              <>
                {selectedReturnSale.patient && (
                  <S.CustomerDetails>
                    <S.CustomerDetailsBody>
                      <S.CustomerInfo>Customer Information</S.CustomerInfo>
                      <S.CustomerInfo>
                        <S.CustomerNumber>
                          {selectedReturnSale.patient?.phone}
                        </S.CustomerNumber>
                        <div>|</div>
                        <S.CustomerName>
                          {selectedReturnSale.patient?.name}
                        </S.CustomerName>
                      </S.CustomerInfo>
                    </S.CustomerDetailsBody>
                  </S.CustomerDetails>
                )}
              </>
            ) : type === "proforma-invoice" ? (
              <>
                {" "}
                {PI_customer && (
                  <S.CustomerDetails>
                    <S.CustomerDetailsBody>
                      <S.CustomerInfo>Customer Information</S.CustomerInfo>
                      <S.CustomerInfo>
                        <S.CustomerNumber>
                          {PI_customer?.phone}
                        </S.CustomerNumber>
                        <div>|</div>
                        <S.CustomerName>{PI_customer?.name}</S.CustomerName>
                      </S.CustomerInfo>
                    </S.CustomerDetailsBody>
                  </S.CustomerDetails>
                )}{" "}
              </>
            ) : (
              <>
                {inventorySelectedCustomer && (
                  <S.CustomerDetails>
                    <S.CustomerDetailsBody>
                      <S.CustomerInfo>Customer Information</S.CustomerInfo>
                      <S.CustomerInfo>
                        <S.CustomerNumber>
                          {inventorySelectedCustomer?.phone}
                        </S.CustomerNumber>
                        <div>|</div>
                        <S.CustomerName>
                          {inventorySelectedCustomer?.name}
                        </S.CustomerName>
                      </S.CustomerInfo>
                    </S.CustomerDetailsBody>
                  </S.CustomerDetails>
                )}
              </>
            )}

            {/* {type === "sale" && (
                            <S.ReceiptContainer>
                                <S.ReceiptHeadline>Receipt Options</S.ReceiptHeadline>
                                <S.ReceiptBody>
                                    <img src={ReceiptMobileIcon} alt="mobile" />
                                    <S.ReceiptText>Text Receipt</S.ReceiptText>
                                    <img className="arrow" src={RightArrowIcon} alt="right-arrow" />
                                </S.ReceiptBody>
                            </S.ReceiptContainer>
                        )} */}

            <S.Summary>
              <S.SummaryHeadline>
                {type === "return-sale"
                  ? "Return"
                  : type === "proforma-invoice"
                  ? "Proforma Invoice"
                  : "Sale"}{" "}
                Summary
              </S.SummaryHeadline>
              <S.SummaryBody>
                <S.PriceDetail>
                  <div>Total Quantity</div>
                  <div>
                    {type === "return-sale"
                      ? returnSaleQuantity
                      : type === "proforma-invoice"
                      ? PI_quantity
                      : type === "convert-to-invoice"
                      ? selectedSale?.total_quantity
                      : saleQuantity}
                  </div>
                </S.PriceDetail>

                <S.PriceDetail>
                  <div>Subtotal:</div>
                  <div>
                    ₱
                    {toDecimalPlace(
                      type === "return-sale"
                        ? returnSaleTotal - returnSaleTaxTotal
                        : type === "proforma-invoice"
                        ? PI_total - PI_taxTotal + PI_discountValue
                        : type === "convert-to-invoice"
                        ? selectedSale?.sub_total
                        : saleTotal - taxTotal + discountValue
                    )}
                  </div>
                </S.PriceDetail>

                <S.PriceDetail>
                  <div>Tax:</div>
                  <div>
                    ₱
                    {toDecimalPlace(
                      type === "return-sale"
                        ? returnSaleTaxTotal
                        : type === "proforma-invoice"
                        ? PI_taxTotal
                        : type === "convert-to-invoice"
                        ? selectedSale?.tax
                        : taxTotal
                    )}
                  </div>
                </S.PriceDetail>
                <S.PriceDetail>
                  <div>Discount:</div>
                  <div>
                    ₱
                    {type === "return-sale"
                      ? toDecimalPlace(returnDiscountValue)
                      : type === "proforma-invoice"
                      ? toDecimalPlace(PI_discountValue)
                      : type === "convert-to-invoice"
                      ? toDecimalPlace(selectedSale?.discount)
                      : toDecimalPlace(discountValue)}
                  </div>
                </S.PriceDetail>
                <S.PriceDetail>
                  <div>Order total:</div>
                  <div>
                    ₱
                    {type === "return-sale"
                      ? toDecimalPlace(returnSaleTotal - returnCartDiscount)
                      : type === "proforma-invoice"
                      ? toDecimalPlace(PI_total)
                      : type === "convert-to-invoice"
                      ? toDecimalPlace(selectedSale?.bill_total)
                      : toDecimalPlace(saleTotal)}
                  </div>
                </S.PriceDetail>

                {type !== "return-sale" && (
                  <S.PriceDetail>
                    <div> Cart Discount:</div>
                    <div>
                      ₱
                      {type === "convert-to-invoice"
                        ? toDecimalPlace(selectedSale?.cart_discount)
                        : toDecimalPlace(saleDiscount)}
                    </div>
                  </S.PriceDetail>
                )}
                {type === "return-sale" && (
                  <S.PriceDetail>
                    <div> Cart Discount:</div>
                    <div>₱{toDecimalPlace(returnCartDiscount)}</div>
                  </S.PriceDetail>
                )}
                {type !== "return-sale" && type !== "proforma-invoice" && (
                  <S.PriceDetail>
                    <div> Loyalty Point:</div>
                    <div>₱{loyaltyPoints?.loyalty_rupees}</div>
                  </S.PriceDetail>
                )}
                {type !== "return-sale" ? (
                  ["sale", "invoice-sale"].includes(type) &&
                  billTypePreference?.sale_bill_type ===
                    SALE_BILL_TYPE.INVOICE &&
                  totalAdditionalCharges &&
                  additional_charges?.length ? (
                    <>
                      <S.SummaryHeadline underlined>
                        Additional Charges
                      </S.SummaryHeadline>
                      {additional_charges?.map((item) => (
                        <S.PriceDetail>
                          <div>{item.field}:</div>
                          <div>₱{item.value}</div>
                        </S.PriceDetail>
                      ))}
                    </>
                  ) : type === "proforma-invoice" &&
                    PI_totalAdditionalCharges &&
                    PI_additional_charges?.length ? (
                    <>
                      <S.SummaryHeadline underlined>
                        Additional Charges
                      </S.SummaryHeadline>
                      {PI_additional_charges?.map((item) => (
                        <S.PriceDetail>
                          <div>{item.field}:</div>
                          <div>₱{item.value}</div>
                        </S.PriceDetail>
                      ))}
                    </>
                  ) : type === "convert-to-invoice" &&
                    selectedSale?.totalAdditionalCharges &&
                    selectedSale?.additional_charges?.length ? (
                    <>
                      <S.SummaryHeadline underlined>
                        Additional Charges
                      </S.SummaryHeadline>

                      {selectedSale?.additional_charges?.map((item) => (
                        <S.PriceDetail>
                          <div>{item.field}:</div>
                          <div>₱{item.value}</div>
                        </S.PriceDetail>
                      ))}
                    </>
                  ) : (
                    <></>
                  )
                ) : Array.isArray(additionalCharges) &&
                  additionalCharges?.length ? (
                  <>
                    <S.SummaryHeadline underlined>
                      Return Additional Charges
                    </S.SummaryHeadline>
                    {additionalCharges?.map((item, idx) => (
                      <S.PriceDetail key={idx}>
                        <div>{item.field}:</div>
                        <div>₱{item.value}</div>
                      </S.PriceDetail>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                <S.Total>
                  <div>Cart total:</div>
                  <div>
                    ₱
                    {type === "return-sale"
                      ? returnCartTotal
                      : type === "proforma-invoice"
                      ? PI_cartTotal
                      : type === "convert-to-invoice"
                      ? selectedSale?.cart_total
                      : cartTotal}
                  </div>
                </S.Total>
              </S.SummaryBody>
            </S.Summary>
          </S.BodyTop>
          {["invoice-sale", "convert-to-invoice", "proforma-invoice"].includes(
            type
          ) && printTemplates ? (
            <S.TemplateContainer>
              {printTemplates?.map((t, idx) => (
                <S.TemplateBox
                  key={idx}
                  active={selectedTemplate?.name === t.name}
                  onClick={() => setSelectedTemplate(t)}
                >
                  <img src={t.preview ?? DescriptionIcon} alt="preview" />
                  <S.TemplateText>{t.name}</S.TemplateText>
                </S.TemplateBox>
              ))}
            </S.TemplateContainer>
          ) : (
            <></>
          )}
          <S.BodyBottom>
            {type !== "invoice-sale" ||
            ["invoice-sale", "convert-to-invoice"].includes(type) ? (
              <S.PrintSwitchContainer>
                <S.PrintText>
                  Print{" "}
                  {["invoice-sale", "convert-to-invoice"].includes(type)
                    ? "Invoice"
                    : "Receipt"}
                </S.PrintText>
                <ToggleSwitch
                  type="invoice"
                  checked={invoiceRequired}
                  handleChange={(e, type) => handlePrintSwitchChange(e, type)}
                  antSwitch
                />
                <S.HintText>(Press space or arrow keys to change)</S.HintText>
              </S.PrintSwitchContainer>
            ) : (
              <S.PrintSwitchContainer></S.PrintSwitchContainer>
            )}
            <S.DoneBtn
              onClick={() => {
                handleDone();
                onClose();
              }}
            >
              Save
            </S.DoneBtn>
          </S.BodyBottom>
        </S.Body>
      </S.Container>
    </Modal>
  );
};

export default OrderSummaryModal;
