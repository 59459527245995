import { Modal } from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Calendar } from "react-date-range";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addPurchase, updatePurchase } from "../../api/local/purchase-api";
import {
  DiscountOption,
  INVOICE_TYPE,
  PAYMENT,
  POSSIBLE_CASH_VALUES,
  PURCHASE_PAYMENT_MODES,
  PURCHASE_TYPE,
  SALE_BILL_TYPE,
  STATUS,
} from "../../constants/appConstants";
import { CalendarIcon, CloseModalIcon } from "../../icons";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import {
  getPurchaseTotals,
  removeSelectedPurchase,
  removeSelectedSupplier,
} from "../../store/purchase-slice";
import { formatDate } from "../../utils/format-date";
import PurchaseSummaryModal from "../purchase/purchase-summary-modal/purchase-summary-modal";
import * as S from "./style";
import ReturnSummaryModal from "./../purchase/return-details/return-summary-modal/return-summary-modal";
import {
  getReturnCartTotal,
  resetReturnAdditionalCharges,
  setReturnCartDiscount,
} from "../../store/returnsale-slice";
import { addSale } from "../../api/local/sale-api";
import OrderSummaryModal from "./../order-summary-modal/order-summary-modal";
import { getPurchaseReturnTotals } from "../../store/purchasereturn-slice";
import { captureException } from "../../crash-reporting";
import {
  convertTax,
  createPurchasePaymentModelV1,
  getPurchasePayloadProducts,
  getPurchaseReturnPayloadProducts,
  getQuantity,
  onInvoiceRequired,
} from "../../utils/global-fn";

// const amounts = [100, 200, 300, 400, 500, 600];
const PurchasePaymentModal = ({
  open,
  onClose,
  type,
  additional,
  openPurchaseSummaryModal,
  setOpenPurchaseSummaryModal,
  openReturnSummaryModal,
  setOpenReturnSummaryModal,
  rpAdditional,
  returnSaleBill,
  rsCreatedDate,
  openSaleReturnSummaryModal,
  setOpenSaleReturnSummaryModal,
  returnAdditional,
  handleClearCart,
  setOpenSupplierModal,
}) => {
  const [activeCash, setActiveCash] = useState(0);
  const [selectedAmount, _setSelectedAmount] = useState(null);
  const selectedAmountRef = useRef(selectedAmount);
  const setSelectedAmount = (data) => {
    selectedAmountRef.current = data;
    _setSelectedAmount(data);
  };

  const [otherAmount, setOtherAmount] = useState("");
  const [selectedPaymentOption, _setSelectedPaymentOption] = useState(null);
  const selectedPaymentOptionRef = useRef(selectedPaymentOption);
  const setSelectedPaymentOption = (data) => {
    selectedPaymentOptionRef.current = data;
    _setSelectedPaymentOption(data);
  };

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [amounts, setAmounts] = useState(null);
  const [chequeDate, _setChequeDate] = useState(null);
  const chequeDateRef = useRef(chequeDate);
  const setChequeDate = (data) => {
    chequeDateRef.current = data;
    _setChequeDate(data);
  };
  const [chequeNumber, _setChequeNumber] = useState("");
  const chequeNumberRef = useRef(chequeNumber);
  const setChequeNumber = (data) => {
    chequeNumberRef.current = data;
    _setChequeNumber(data);
  };

  const {
    selectedPurchase,
    purchaseProducts,
    selectedSupplier,
    purchaseTotal,
    selectedDraftPurchase,
    selectDiscount,
    purchaseCartTotal,
  } = useSelector((state) => state.purchase);

  const {
    returnSaleProducts,
    selectedReturnSale,
    returnCartDiscount,
    returnAdditionalCharges,
    returnCartTotal,
  } = useSelector((state) => state.returnSale);

  const {
    purchaseReturnProducts,
    returnSelectedSupplier,
    selectedDraftPurchaseReturn,
    purchaseReturnCartTotal,
  } = useSelector((state) => state.purchaseReturn);

  const elementRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSnackbarDetails } = useContext(SnackbarContext);

  //Maintaining index for keyboard navigations
  const [amountsIndex, _setAmountsIndex] = useState([0, 1]);
  const amountsIndexRef = React.useRef(amountsIndex);
  const [paymentElementsIndex, _setPaymentElementsIndex] = useState([]);
  const paymentElementsIndexRef = React.useRef(paymentElementsIndex);
  const setAmountsIndex = (data) => {
    amountsIndexRef.current = data;
    _setAmountsIndex(data);
    const paymentElems = [
      data[data.length - 1] + 1,
      data[data.length - 1] + 2,
      data[data.length - 1] + 3,
      data[data.length - 1] + 4,
      data[data.length - 1] + 5,
    ];
    paymentElementsIndexRef.current = paymentElems;
    _setPaymentElementsIndex(paymentElems);
  };

  const [selectedTemplate, _setSelectedTemplate] = useState(null);
  const selectedTemplateRef = useRef(selectedTemplate);
  const setSelectedTemplate = (data) => {
    selectedTemplateRef.current = data;
    _setSelectedTemplate(data);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleDetectKeyDown, true);

      if (type === "purchase") {
        if (selectedPurchase) {
          getCashValues(selectedPurchase.total_purchase_price);
        } else {
          dispatch(getPurchaseTotals());
          let totalAmount = purchaseCartTotal;
          getCashValues(totalAmount);
          if (!selectedAmount) {
            setSelectedAmount(totalAmount);
          }
        }
      } else if (type === "return-purchase") {
        let returnPurchaseTotal =
          rpAdditional.totalPrice +
          parseFloat(rpAdditional.deliveryExpense || 0) -
          parseFloat(rpAdditional.cartDiscount || 0);
        getCashValues(returnPurchaseTotal);
        if (!selectedAmount) {
          setSelectedAmount(Math.round(returnPurchaseTotal));
        }
      } else if (type === "return-sale") {
        dispatch(getReturnCartTotal());
        getCashValues(Math.round(returnCartTotal));
        if (!selectedAmount) {
          setSelectedAmount(Math.round(returnCartTotal));
        }
      } else if (type === "new-purchase-return") {
        dispatch(getPurchaseReturnTotals());
        let totalAmount = purchaseReturnCartTotal;
        getCashValues(totalAmount);
        if (!selectedAmount) {
          setSelectedAmount(totalAmount);
        }
      } else if (type === "convert-to-purchase") {
        getCashValues(selectedPurchase.net_price);
      }
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open, type]);

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 9) {
      //TAB key
      let idx = getCurrentIdx();
      if (idx >= 0 && elementRefs.current[idx + 1]) {
        e.preventDefault();
        elementRefs.current[idx + 1].focus();
      }
    } else if (e.keyCode === 13) {
      //Enter key
      if (
        !openPurchaseSummaryModal &&
        !openReturnSummaryModal &&
        !openSaleReturnSummaryModal
      ) {
        let idx = getCurrentIdx();
        if (idx >= 0) {
          if (amountsIndexRef.current?.includes(idx)) {
            e.preventDefault();
            elementRefs.current[paymentElementsIndexRef.current[0]].focus();
          } else if (paymentElementsIndexRef.current?.includes(idx)) {
            e.preventDefault();
            onSubmit();
          }
        }
      }
    } else if (e.keyCode === 37) {
      //LEFT Arrow Key

      let idx = getCurrentIdx();
      if (amountsIndexRef.current?.includes(idx)) {
        e.preventDefault();
        elementRefs.current[idx - 1].focus();
      } else if (paymentElementsIndexRef.current?.includes(idx)) {
        e.preventDefault();
        elementRefs.current[idx - 1].focus();
      }
    } else if (e.keyCode === 38) {
      //UP Arrow key
      let idx = getCurrentIdx();
      if (idx >= 0) {
        if (
          idx === amountsIndexRef.current[amountsIndexRef.current?.length - 1]
        ) {
          e.preventDefault();
          elementRefs.current[amountsIndexRef.current[0]].focus();
        } else if (paymentElementsIndexRef.current?.includes(idx)) {
          e.preventDefault();
          elementRefs.current[
            amountsIndexRef.current[amountsIndexRef.current?.length - 1]
          ].focus();
        }
      }
    } else if (e.keyCode === 39) {
      //RIGHT Arrow Key

      let idx = getCurrentIdx();
      if (
        amountsIndexRef.current
          ?.slice(0, amountsIndexRef.current?.length - 1)
          .includes(idx)
      ) {
        e.preventDefault();
        elementRefs.current[idx + 1].focus();
      } else if (
        paymentElementsIndexRef.current
          ?.slice(0, paymentElementsIndexRef.current?.length - 1)
          .includes(idx)
      ) {
        e.preventDefault();
        elementRefs.current[idx + 1].focus();
      }
    } else if (e.keyCode === 40) {
      //DOWN Arrow key
      let idx = getCurrentIdx();
      if (idx >= 0) {
        if (
          amountsIndexRef.current
            ?.slice(0, amountsIndexRef.current?.length - 1)
            .includes(idx)
        ) {
          e.preventDefault();
          elementRefs.current[
            amountsIndexRef.current[amountsIndexRef.current?.length - 1]
          ].focus();
        } else if (
          amountsIndexRef.current[amountsIndexRef.current?.length - 1] === idx
        ) {
          e.preventDefault();
          elementRefs.current[paymentElementsIndexRef.current[0]].focus();
        }
      }
    }
  };

  const getCashValues = (amount) => {
    amount = Math.round(amount);
    if (amount <= 0) {
      setAmountsIndex([0, 1]);
      setAmounts([amount]);
      return;
    }
    for (let i = 0; i < POSSIBLE_CASH_VALUES.length; i++) {
      if (amount < POSSIBLE_CASH_VALUES[i]) {
        const temp = POSSIBLE_CASH_VALUES.slice(i, i + 5);
        setAmountsIndex([0, 1, ...temp.map((val, i) => i + 2)]);
        setAmounts([amount, ...temp]);
        return;
      } else if (amount === POSSIBLE_CASH_VALUES[i]) {
        const temp = POSSIBLE_CASH_VALUES.slice(i, i + 5);
        setAmountsIndex([0, ...temp.map((val, i) => i + 1)]);
        setAmounts(temp);
        return;
      }
    }
    setAmountsIndex([0, 1]);
    setAmounts([amount]);
  };

  const getCurrentIdx = () => {
    return elementRefs.current?.findIndex(
      (el) => el === document.activeElement
    );
  };

  const onAmountFocus = (index, item) => {
    setActiveCash(index);
    setSelectedAmount(item);
  };

  const onPaymentModeFocus = (index) => {
    setSelectedPaymentOption(PURCHASE_PAYMENT_MODES[index].name);
  };

  function closePaymentModal() {
    onClose();
    setSelectedAmount(null);
    setActiveCash(null);
    setAmounts(null);
  }

  function handleOtherAmountChange(e) {
    let val = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (!(val === "" || regex.test(val))) return;
    setSelectedAmount(val);
    setOtherAmount(val);
  }

  const handleInputChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (!(e.target.value === "" || regex.test(e.target.value))) return;
    setChequeNumber(e.target.value);
  };

  async function handleAddPurchase(invoiceRequired) {
    if (!purchaseProducts.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please add products",
        type: "error",
      });
      return;
    }
    if (!selectedSupplier) {
      handleSnackbarDetails({
        show: true,
        title: "Please choose a supplier",
        type: "error",
      });
      return;
    }

    const payload = {
      type: PURCHASE_TYPE.PURCHASE,
      status: "PUBLISH",
      products: getPurchasePayloadProducts(purchaseProducts),
    };

    if (selectedSupplier) payload.supplier_id = selectedSupplier.id;
    if (additional.billno) payload.bill_no = additional.billno;
    if (additional.deliveryExpense)
      payload.delivery_price = +additional.deliveryExpense;
    if (additional.createdAt)
      payload.created_at = additional.createdAt.getTime();
    if (additional.deliveryDate)
      payload.delivery_date = additional.deliveryDate.getTime();
    if (additional.discount) {
      let disc = {
        type: selectDiscount.type,
        value: additional.discount,
      };
      payload.cart_discount = disc;
    }
    const total = purchaseCartTotal;

    const payment = createPurchasePaymentModelV1(
      total,
      selectedPaymentOptionRef.current,
      selectedAmountRef.current,
      chequeNumberRef.current,
      chequeDateRef.current
    );
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }
    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    let res;
    try {
      if (selectedDraftPurchase) {
        res = await updatePurchase(selectedDraftPurchase.id, payload);
      } else {
        res = await addPurchase(payload);
      }

      if (res?.status === 200) {
        navigate("/dashboard/purchases");
        handleClearCart();
        closePaymentModal();

        //Print the invoice
        if (invoiceRequired) {
          onInvoiceRequired(
            payload.invoice_template_id,
            INVOICE_TYPE.PURCHASE,
            res.data
          );
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Purchase completed",
          subtitle: "Your purchase has been completed successfully.",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (error) {
      captureException(error);
      handleSnackbarDetails({
        show: true,
        title: error.message,
        type: "error",
      });
    }
  }

  const onSubmit = () => {
    if (type === "purchase") {
      if (!selectedPaymentOptionRef.current) {
        handleSnackbarDetails({
          show: true,
          title: "Please select payment mode",
          type: "error",
        });
        return;
      }
      if (selectedPaymentOptionRef.current === PURCHASE_PAYMENT_MODES[4].name) {
        if (!chequeNumberRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque number",
            type: "error",
          });
          return;
        }
        if (!chequeDateRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque date",
            type: "error",
          });
          return;
        }
      }
      if (selectedAmountRef.current === null) {
        handleSnackbarDetails({
          show: true,
          title: "Please choose amount",
          type: "error",
        });
        return;
      }
      setOpenPurchaseSummaryModal(true);
      // onClose();
    } else if (type === "return-purchase") {
      if (!selectedPaymentOptionRef.current) {
        handleSnackbarDetails({
          show: true,
          title: "Please select payment mode",
          type: "error",
        });
        return;
      }
      if (selectedPaymentOptionRef.current === PURCHASE_PAYMENT_MODES[4].name) {
        if (!chequeNumberRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque number",
            type: "error",
          });
          return;
        }
        if (!chequeDateRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque date",
            type: "error",
          });
          return;
        }
      }
      if (selectedAmountRef.current === null) {
        handleSnackbarDetails({
          show: true,
          title: "Please choose amount",
          type: "error",
        });
        return;
      }
      setOpenReturnSummaryModal(true);
      // onClose();
    } else if (type === "return-sale") {
      if (!selectedPaymentOptionRef.current) {
        handleSnackbarDetails({
          show: true,
          title: "Please select payment mode",
          type: "error",
        });
        return;
      }
      if (selectedAmountRef.curren === null) {
        handleSnackbarDetails({
          show: true,
          title: "Please choose amount",
          type: "error",
        });
        return;
      }
      setOpenSaleReturnSummaryModal(true);
    } else if (type === "new-purchase-return") {
      if (!selectedPaymentOptionRef.current) {
        handleSnackbarDetails({
          show: true,
          title: "Please select payment mode",
          type: "error",
        });
        return;
      }
      if (selectedPaymentOptionRef.current === PAYMENT.CHEQUE) {
        if (!chequeNumberRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque number",
            type: "error",
          });
          return;
        }
        if (!chequeDateRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque date",
            type: "error",
          });
          return;
        }
      } else if (selectedPaymentOptionRef.current === PAYMENT.PAY_LATER) {
        if (!returnSelectedSupplier) {
          setOpenSupplierModal(true);
          handleSnackbarDetails({
            show: true,
            title: "Please add a supplier for pay later",
            type: "error",
          });
          return;
        }
      }
      if (selectedAmountRef.current === null) {
        handleSnackbarDetails({
          show: true,
          title: "Please choose amount",
          type: "error",
        });
        return;
      }
      setOpenReturnSummaryModal(true);
    } else if (type === "convert-to-purchase") {
      if (!selectedPaymentOptionRef.current) {
        handleSnackbarDetails({
          show: true,
          title: "Please select payment mode",
          type: "error",
        });
        return;
      }
      if (selectedPaymentOptionRef.current === PURCHASE_PAYMENT_MODES[4].name) {
        if (!chequeNumberRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque number",
            type: "error",
          });
          return;
        }
        if (!chequeDateRef.current) {
          handleSnackbarDetails({
            show: true,
            title: "Please enter a cheque date",
            type: "error",
          });
          return;
        }
      }
      if (selectedAmountRef.current === null) {
        handleSnackbarDetails({
          show: true,
          title: "Please choose amount",
          type: "error",
        });
        return;
      }
      setOpenPurchaseSummaryModal(true);
    }
  };

  const handleDateChange = (item) => {
    setChequeDate(item);
    setOpenDatePicker(false);
  };

  async function handleAddReturnExistingPurchase(invoiceRequired) {
    const payload = {
      type: PURCHASE_TYPE.RETURN,
      return_id: selectedPurchase.id,
      products: rpAdditional.productsList.map((item) => {
        let manualDiscountV1 = null;
        if (item.discount) {
          manualDiscountV1 = {
            type: item.discount.type,
            value: (item.discount.value / item.quantity) * item.returnQuantity,
          };
        }
        return {
          sku: item.sku,
          quantity: parseFloat(item.returnQuantity),
          free_quantity: item.freeQuantity ? parseFloat(item.freeQuantity) : 0,
          batch_number: item.batch_number ? item.batch_number : null,
          manufacturing_date: item.manufacturing_date
            ? moment(item.manufacturing_date).format("DD-MM-YYYY")
            : null,
          expiry_date: item.expiry_date
            ? moment(item.expiry_date).format("DD-MM-YYYY")
            : null,
          purchase_price: item.purchase_price,
          purchase_price_with_tax: item.purchase_price_with_tax,
          tax_v2: item.tax_v2 ? item.tax_v2 : convertTax(item),
          manual_discount: manualDiscountV1,
        };
      }),
      // supplier_id: selectedPurchase.supplier.id,
    };

    if (rpAdditional.supplier) payload.supplier_id = rpAdditional.supplier.id;
    if (rpAdditional.billno) payload.bill_no = rpAdditional.billno;
    if (rpAdditional.deliveryExpense)
      payload.delivery_price = +rpAdditional.deliveryExpense;
    if (rpAdditional.createdAt)
      payload.created_at = rpAdditional.createdAt.getTime();
    if (rpAdditional.deliveryDate)
      payload.delivery_date = rpAdditional.deliveryDate.getTime();
    if (rpAdditional.cartDiscount) {
      let disc = {
        type: DiscountOption.FLAT,
        value: parseFloat(rpAdditional.cartDiscount),
      };
      payload.cart_discount = disc;
    }

    let total =
      rpAdditional.totalPrice +
      parseFloat(rpAdditional.deliveryExpense || 0) -
      parseFloat(rpAdditional.cartDiscount || 0);

    const payment = createPurchasePaymentModelV1(
      total,
      selectedPaymentOptionRef.current,
      selectedAmountRef.current,
      chequeNumberRef.current,
      chequeDateRef.current,
      PURCHASE_TYPE.RETURN
    );
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }
    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }
    try {
      let res = await addPurchase(payload);
      if (res.status === 200) {
        navigate("/dashboard/purchases");

        if (invoiceRequired) {
          onInvoiceRequired(
            payload.invoice_template_id,
            INVOICE_TYPE.PURCHASE,
            res.data
          );
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Purchase returned",
          subtitle: "Your purchase has been returned successfully.",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  async function handleReturnSale(invoiceRequired) {
    const payload = {
      type: "RETURN",
      return_id: selectedReturnSale.id,
      bill_type: selectedReturnSale.bill_type ?? null,
    };
    payload.items = returnSaleProducts
      .filter((item) => item.isChecked)
      .map((item) => {
        let manualDiscountV1 = null;
        if (item.manual_discount) {
          manualDiscountV1 = {
            type: item.manual_discount.type,
            value:
              (item.manual_discount.value / item.quantity) *
              item.returnQuantity,
          };
        }
        let p = {
          id: item.id,
          sku: item.sku,
          name: item.name,
          quantity: parseFloat(item.returnQuantity),
          manual_discount: manualDiscountV1,
          sale_price: Number((+item.sale_price).toFixed(2)),
          price: Number(item.price.toFixed(2)),
          unit: item.unit,
          sale_price_with_tax: item.sale_price_with_tax,
          tax: convertTax(item),
          upc: item.upc,
          item_type: item.item_type,
          hsn: item.hsn,
          batch_number: item.batch_number,
          manufacturing_date: item.manufacturing_date
            ? item.manufacturing_date
            : null,
          expiry_date: item.expiry_date ? item.expiry_date : null,
          enable_alternate_unit: item.enable_alternate_unit,
          alternate_unit: item.alternate_unit,
        };
        let qty = getQuantity(item, selectedReturnSale?.bill_type);
        if (qty >= item.wholesale_quantity) {
          p.wholesale_price = item.wholesale_price;
          p.wholesale_quantity = item.wholesale_quantity;
        }
        return p;
      });

    if (!payload.items.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please choose a product",
        type: "error",
      });
      return;
    }
    if (selectedReturnSale.patient) {
      payload.patient_id = selectedReturnSale.patient.id;
    }

    if (rsCreatedDate) {
      payload.created_at = rsCreatedDate.getTime();
    }

    if (returnSaleBill) {
      payload.bill_no = returnSaleBill;
    }
    if (returnCartDiscount) {
      let disc = {
        type: DiscountOption.FLAT,
        value: parseFloat(returnCartDiscount),
      };
      payload.cart_manual_discount = disc;
    }
    if (!isEmpty(returnAdditionalCharges)) {
      let addCharges = [];
      for (let key in returnAdditionalCharges) {
        if (returnAdditionalCharges[key]) {
          let pl = {
            field: key,
            value: parseFloat(returnAdditionalCharges[key]),
          };
          addCharges.push(pl);
        }
      }

      payload.additional_charges = addCharges;
    }

    const payment = createPaymentModelV1();
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }

    if (selectedReturnSale.bill_type === SALE_BILL_TYPE.INVOICE) {
      if (selectedReturnSale.invoice_template_id) {
        payload.invoice_template_id = selectedReturnSale.invoice_template_id;
      } else {
        payload.invoice_template_id = "3";
      }
    }

    try {
      let res = await addSale(payload);
      if (res.status === 200) {
        navigate("/dashboard/all-sales");
        dispatch(setReturnCartDiscount(0));
        dispatch(resetReturnAdditionalCharges());

        if (invoiceRequired) {
          if (selectedReturnSale.bill_type === SALE_BILL_TYPE.INVOICE) {
            onInvoiceRequired(
              payload.invoice_template_id,
              INVOICE_TYPE.SALE,
              res.data
            );
          } else {
            //TODO:
          }
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale returned",
          subtitle: "Your sale has been returned successfully.",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  const createPaymentModelV1 = () => {
    let payment = {};
    let finalReturnSaleTotal = returnCartTotal;
    payment.total_amount = finalReturnSaleTotal;
    if (selectedPaymentOption === PAYMENT.PAY_LATER) {
      payment.amount_due = Math.round(finalReturnSaleTotal);
    } else {
      payment.amount_due =
        selectedAmount - Math.round(finalReturnSaleTotal) < 0
          ? Math.round(finalReturnSaleTotal) - selectedAmount
          : 0;
    }
    payment.is_paid = payment.amount_due <= 0;

    let payment_modes = [];

    if (selectedPaymentOption) {
      let payload = {
        created_at: Date.now(),
        payment_mode: selectedPaymentOption,
        payment_amount: Math.round(finalReturnSaleTotal),
      };
      if (selectedPaymentOption === PAYMENT.CASH) {
        payload.cash_out = parseFloat(selectedAmount);
        payload.cash_in = parseFloat(selectedAmount) - payload.payment_amount;

        if (payload.cash_in < 0) {
          payload.cash_in = 0;
        }
      }
      payment_modes.push(payload);
    }

    payment.payment_modes = payment_modes;

    return payment;
  };
  async function handleAddPurchaseReturn(invoiceRequired) {
    if (!purchaseReturnProducts.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please add products",
        type: "error",
      });
      return;
    }
    if (!selectedPaymentOption) {
      handleSnackbarDetails({
        show: true,
        title: "Please choose a payment mode",
        type: "error",
      });
      return;
    }
    const payload = {
      type: PURCHASE_TYPE.RETURN,
      status: STATUS.PUBLISHED,
      products: getPurchaseReturnPayloadProducts(purchaseReturnProducts),
    };
    if (returnSelectedSupplier) payload.supplier_id = returnSelectedSupplier.id;
    if (returnAdditional.billno) payload.bill_no = returnAdditional.billno;
    if (returnAdditional.deliveryExpense)
      payload.delivery_price = returnAdditional.deliveryExpense;
    if (returnAdditional.createdAt)
      payload.created_at = returnAdditional.createdAt.getTime();
    if (returnAdditional.deliveryDate)
      payload.delivery_date = returnAdditional.deliveryDate.getTime();

    let total = purchaseReturnCartTotal;
    const payment = createPurchasePaymentModelV1(
      total,
      selectedPaymentOptionRef.current,
      selectedAmountRef.current,
      chequeNumberRef.current,
      chequeDateRef.current,
      PURCHASE_TYPE.RETURN
    );
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }

    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    let res;
    try {
      if (selectedDraftPurchaseReturn) {
        res = await updatePurchase(selectedDraftPurchaseReturn.id, payload);
      } else {
        res = await addPurchase(payload);
      }

      if (res?.status === 200) {
        navigate("/dashboard/purchases");
        handleClearCart();
        closePaymentModal();

        //Print the invoice
        if (invoiceRequired) {
          onInvoiceRequired(
            payload.invoice_template_id,
            INVOICE_TYPE.PURCHASE,
            res.data
          );
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Purchase return completed",
          subtitle: "Your purchase return has completed successfully.",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (error) {
      captureException(error);
      handleSnackbarDetails({
        show: true,
        title: error.message,
        type: "error",
      });
    }
  }

  const handleConvertToPurchase = async (invoiceRequired) => {
    const payload = {
      type: PURCHASE_TYPE.PURCHASE,
      status: STATUS.PUBLISHED,
      created_at: Date.now(),
    };

    const total = selectedPurchase?.net_price;

    const payment = createPurchasePaymentModelV1(
      total,
      selectedPaymentOptionRef.current,
      selectedAmountRef.current,
      chequeNumberRef.current,
      chequeDateRef.current
    );
    if (!isEmpty(payment)) {
      payload.payment = payment;
    }
    if (selectedTemplateRef.current) {
      payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
    }

    try {
      let res = await updatePurchase(selectedPurchase.id, payload);
      if (res.status === 200) {
        navigate("/dashboard/purchases");
        dispatch(removeSelectedSupplier());
        dispatch(removeSelectedPurchase());
        closePaymentModal();

        if (invoiceRequired) {
          onInvoiceRequired(
            payload.invoice_template_id,
            INVOICE_TYPE.PURCHASE,
            res.data
          );
        }

        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Purchase completed",
          subtitle: "Your purchase has been completed successfully.",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (error) {
      captureException(error);
      handleSnackbarDetails({
        show: true,
        title: error.message,
        type: "error",
      });
    }
  };

  return (
    <>
      {type === "return-sale" && (
        <OrderSummaryModal
          open={openSaleReturnSummaryModal}
          onClose={() => setOpenSaleReturnSummaryModal(false)}
          selectedPaymentOption={selectedPaymentOption}
          selectedAmount={selectedAmount}
          handleReturnSale={handleReturnSale}
          type={type}
        />
      )}
      {type === "purchase" && (
        <PurchaseSummaryModal
          open={openPurchaseSummaryModal}
          onClose={() => setOpenPurchaseSummaryModal(false)}
          handleAddPurchase={handleAddPurchase}
          datas={additional}
          type={type}
          selectedPaymentOption={selectedPaymentOption}
          selectedAmount={selectedAmount}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {type === "convert-to-purchase" && (
        <PurchaseSummaryModal
          open={openPurchaseSummaryModal}
          onClose={() => setOpenPurchaseSummaryModal(false)}
          handleConvertToPurchase={handleConvertToPurchase}
          type={type}
          selectedPaymentOption={selectedPaymentOption}
          selectedAmount={selectedAmount}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {(type === "new-purchase-return" || type === "return-purchase") && (
        <ReturnSummaryModal
          open={openReturnSummaryModal}
          onClose={() => setOpenReturnSummaryModal(false)}
          handleAddPurchaseReturn={
            type === "new-purchase-return"
              ? handleAddPurchaseReturn
              : handleAddReturnExistingPurchase
          }
          datas={
            type === "new-purchase-return" ? returnAdditional : rpAdditional
          }
          type={type}
          selectedPaymentOption={selectedPaymentOption}
          selectedAmount={selectedAmount}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      <Modal
        open={open}
        onClose={closePaymentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.Container>
          <S.Header>
            <div>{type === "return-sale" ? "Checkout" : "Payment Details"}</div>
            <img
              src={CloseModalIcon}
              alt="close modal icon"
              onClick={closePaymentModal}
            />
          </S.Header>
          <S.Body>
            <S.AmountContainer>
              <S.Label>
                {type === "return-sale" ? "Amount Return" : "Amount Paid"}
              </S.Label>
              <S.AmountDiv>
                {amounts?.map((item, ind) => (
                  <S.AmountChip
                    onClick={() => {
                      setActiveCash(ind);
                      setSelectedAmount(item);
                      elementRefs.current[ind].focus();
                    }}
                    active={activeCash === ind}
                  >
                    <input
                      type="radio"
                      id={`amount${ind}`}
                      name="amount-option"
                      value={item}
                      autoFocus={ind === 0}
                      onFocus={() => onAmountFocus(ind, item)}
                      ref={(el) => (elementRefs.current[ind] = el)}
                      checked={activeCash === ind}
                    />
                    <label htmlFor={`amount${ind}`}>
                      ₱
                      {item.toString().split("").includes(".")
                        ? item.toFixed(2)
                        : item}
                    </label>
                  </S.AmountChip>
                ))}
              </S.AmountDiv>
              <S.OtherAmountDiv>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  ref={(el) => (elementRefs.current[amounts?.length] = el)}
                  value={otherAmount}
                  onChange={(e) => handleOtherAmountChange(e)}
                  onFocus={() => {
                    setActiveCash(-1);
                    setSelectedAmount(null);
                  }}
                  onBlur={() => {
                    if (!otherAmount || parseFloat(otherAmount) <= 0) {
                      setActiveCash(0);
                      setSelectedAmount(amounts[0]);
                    }
                  }}
                />
              </S.OtherAmountDiv>
            </S.AmountContainer>
            <S.PaymentModeContainer>
              <S.Label>Select Payment Method</S.Label>
              <S.PaymentModeDiv>
                {PURCHASE_PAYMENT_MODES.map(
                  (item, idx) =>
                    (type === "return-sale" ? idx < 4 : idx < 5) && (
                      <S.PaymentMode
                        key={item.id}
                        active={
                          selectedPaymentOption ===
                          PURCHASE_PAYMENT_MODES[idx].name
                        }
                        onClick={() => {
                          setSelectedPaymentOption(
                            PURCHASE_PAYMENT_MODES[idx].name
                          );
                          if (
                            selectedPaymentOption !==
                            PURCHASE_PAYMENT_MODES[4].name
                          ) {
                            onSubmit();
                          }
                        }}
                        onFocus={() => onPaymentModeFocus(idx)}
                        tabIndex={idx}
                        ref={(el) =>
                          (elementRefs.current[amounts?.length + 1 + idx] = el)
                        }
                      >
                        <img
                          src={
                            selectedPaymentOption ===
                            PURCHASE_PAYMENT_MODES[idx].name
                              ? item.activeIcon
                              : item.icon
                          }
                          alt=""
                        />

                        {item.value}
                      </S.PaymentMode>
                    )
                )}
                {selectedPaymentOption === PURCHASE_PAYMENT_MODES[4].name && (
                  <S.ChequeDatas>
                    <S.ChequeNumberContainer>
                      <input
                        type="text"
                        placeholder="Enter Cheque number"
                        autoFocus
                        ref={(el) =>
                          (elementRefs.current[
                            amounts.length + PURCHASE_PAYMENT_MODES.length
                          ] = el)
                        }
                        value={chequeNumber}
                        onChange={handleInputChange}
                      />
                    </S.ChequeNumberContainer>
                    <S.ChequeDateInputContainer>
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenDatePicker(false)}
                      >
                        <S.ChequeDateInput
                          onClick={() => setOpenDatePicker((prev) => !prev)}
                        >
                          {formatDate(chequeDate)}
                          <S.Bar></S.Bar>
                          <img src={CalendarIcon} alt="calendar" />
                        </S.ChequeDateInput>
                        {openDatePicker && (
                          <S.DatePickerContainer>
                            <Calendar
                              onChange={(item) => {
                                handleDateChange(item);
                              }}
                              date={chequeDate}
                            />
                          </S.DatePickerContainer>
                        )}
                      </OutsideClickHandler>
                    </S.ChequeDateInputContainer>
                    <S.SubmitBtn onClick={onSubmit}>Submit</S.SubmitBtn>
                  </S.ChequeDatas>
                )}
              </S.PaymentModeDiv>
            </S.PaymentModeContainer>
          </S.Body>
        </S.Container>
      </Modal>
    </>
  );
};

export default PurchasePaymentModal;
