import React, { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import * as S from "../style/style";
import { addDays } from "date-fns";
import OutsideClickHandler from "react-outside-click-handler";
import { AddCustomerSearchIcon, CalendarIconSolid, CloseModalIcon, FilterList, TimeChevronRight } from "../../../icons";
import { TIME_PERIOD } from "../../../constants/appConstants";
import { Line, Bar } from "react-chartjs-3";
import ProductDrawer from "./ProductDrawer";
import moment from "moment";
import { formatDate } from "../../../utils/format-date";
import { getPerProductData } from "../../../api/local/analytics-api";
import { debounce } from "lodash";
import { getAllProducts } from "../../../api/local/productApi";
import DownloadButton from "../../download-button/DownloadButton";
import { captureException } from "../../../crash-reporting";

const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
};
const initialData = {
    labels: [],
    datasets: [
        {
            label: "Sale",
            data: [],
            borderColor: "#63ABFD",
            fill: false,
            tension: 0,
        },
        {
            label: "Purchase",
            data: [],
            borderColor: "#E697FF",
            fill: false,
            tension: 0,
        },
        {
            label: "Damage",
            data: [],
            borderColor: "#FFA5CB",
            fill: false,
            tension: 0,
        },
    ],
};

const data = [
    {
        id: 1,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
    {
        id: 2,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
    {
        id: 3,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
    {
        id: 4,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
    {
        id: 5,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
    {
        id: 6,
        sku: "8901058842722",
        name: "Cadbury Bournville Cranberry chocolate",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHcD4ILBe-tqg5euPnFNlw4y1TsAkJ_o54beV7TSBhXVoj92gMuWA-EXq-M1dmYVflB5A&usqp=CAU",
    },
];

const initialParams = {
    start_date: moment().startOf("month").unix() * 1000,
    end_date: Date.now(),
    upc: null,
    distinct_upc: true,
};
const ProductReport = () => {
    const [dateRange, setDateRange] = useState([selectionRange]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [period, setPeriod] = useState(null);
    const [productChartData, setProductChartData] = useState(initialData);
    const [totalDatas, setTotalDatas] = useState(null);
    const [searchedText, setSearchedText] = useState("");
    const [params, setParams] = useState(initialParams);
    const [displayDate, setDisplayDate] = useState(`${formatDate(initialParams.start_date)} - ${formatDate(initialParams.end_date)}`);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        if (params.upc) handleGetPerProductData();
    }, [params]);

    const handleDateChange = (item) => {
        let startDate = new Date(item.selection.startDate).getTime();
        let endDate = new Date(item.selection.endDate).getTime();
        if (endDate > Date.now()) {
            endDate = Date.now();
            setDateRange([{ ...item.selection, endDate: new Date(Date.now()) }]);
        } else {
            setDateRange([item.selection]);
        }
        setDisplayDate(`${formatDate(startDate)} - ${formatDate(endDate)}`);
        setParams({ ...params, start_date: startDate, end_date: endDate });
        if (startDate !== endDate) {
            setOpenDatePicker(false);
        }
    };

    const handleGetPerProductData = async () => {
        try {
            let res = await getPerProductData(params);
            if (res.status === 200) {
                setTotalDatas(res.data);

                //for chart data
                let graphData = res.data.graph;
                const chartData = {
                    labels: graphData.sale.map((item) => item.t),
                    datasets: [
                        {
                            label: "Sale",
                            data: graphData.sale.map((item) => item.value),
                            borderColor: "#63ABFD",
                            fill: false,
                            tension: 0,
                        },
                        {
                            label: "Purchase",
                            data: graphData.purchase.map((item) => item.value),
                            borderColor: "#E697FF",
                            fill: false,
                            tension: 0,
                        },
                        {
                            label: "Damage",
                            data: graphData.damage.map((item) => item.value),
                            borderColor: "#FFA5CB",
                            fill: false,
                            tension: 0,
                        },
                    ],
                };
                setProductChartData(chartData);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const delayedFunction = (val) => {
        if (val) {
            handleGetproducts(val);
        } else {
            setProducts(null);
            setParams({ ...params, upc: null });
        }
    };
    const delayedQuery = useCallback(debounce(delayedFunction, 300), []);

    const handleInputChange = (val, clearData = false) => {
        setSearchedText(val);
        delayedQuery(val);
        if (clearData) {
            setTotalDatas(null);
        }
    };

    const handleGetproducts = async (val) => {
        try {
            let res = await getAllProducts({ search: val });
            if (res.status === 200) {
                setProducts(res.data);
            } else {
                setProducts(null);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onProductClick = (upc, name) => {
        setParams({ ...params, upc: upc });
        setProducts(null);
        setSearchedText(name);
    };

    return (
        <div>
            <S.Heading>Per Product Report</S.Heading>
            <S.Container>
                <S.FilterBar>
                    <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                        <S.dateContainer>
                            <S.DateChooserSelect onClick={() => setOpenDatePicker(openDatePicker ? false : true)}>
                                <img src={CalendarIconSolid} alt="calendar" />
                                <div>{displayDate}</div>
                            </S.DateChooserSelect>
                            {openDatePicker && (
                                <S.DatePickerContainer>
                                    <DateRangePicker
                                        editableDateInputs={true}
                                        onChange={handleDateChange}
                                        ranges={dateRange}
                                        maxDate={addDays(new Date(), 0)}
                                    />
                                </S.DatePickerContainer>
                            )}
                        </S.dateContainer>
                    </OutsideClickHandler>
                    <S.InputContainer>
                        <S.InputFIeld>
                            <img src={AddCustomerSearchIcon} alt="search-icon" />
                            <input
                                type="text"
                                placeholder="Search by SKU,UPC or name"
                                value={searchedText}
                                onChange={(e) => handleInputChange(e.target.value)}
                            />
                            {searchedText && <img src={CloseModalIcon} alt="close-icon" onClick={() => handleInputChange("", true)} />}
                        </S.InputFIeld>
                        {searchedText && products && <ProductDrawer data={products} onProductClick={onProductClick} />}
                    </S.InputContainer>
                    {/* <S.TimePeriodContainer>
                        <S.TimePeriod onClick={() => setOpenMenu(!openMenu)}>
                            <div>Time Period</div>
                            <img src={FilterList} alt="" />
                        </S.TimePeriod>
                        {openMenu && (
                            <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
                                <S.PeriodMenu>
                                    {TIME_PERIOD?.map((item) => (
                                        <S.PeriodRow
                                            onClick={() => {
                                                setPeriod(item.value);
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <div>{item.name}</div>
                                            <img src={TimeChevronRight} alt="" />
                                        </S.PeriodRow>
                                    ))}
                                </S.PeriodMenu>
                            </OutsideClickHandler>
                        )}
                    </S.TimePeriodContainer> */}
                </S.FilterBar>
                <S.DataContainer>
                    <div>
                        <S.DataLabel>Total Sale</S.DataLabel>
                        <S.DataDiv>₱{totalDatas?.total_sale_amount.toLocaleString("en-IN") ?? 0}</S.DataDiv>
                        <S.Date>Qty: {totalDatas?.total_sale_count ?? 0}</S.Date>
                    </div>
                    <div>
                        <S.DataLabel>Total Profit</S.DataLabel>
                        <S.DataDiv>₱{totalDatas?.total_profit_amount.toLocaleString("en-IN") ?? 0}</S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Purchase</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_purchase_count ?? 0}</S.DataDiv>
                        <S.DataDiv></S.DataDiv>
                    </div>
                    <div>
                        <S.DataLabel>Total Damage</S.DataLabel>
                        <S.DataDiv>{totalDatas?.total_damage_count ?? 0}</S.DataDiv>
                        <S.DataDiv></S.DataDiv>
                    </div>
                </S.DataContainer>
                {params.upc === null ? (
                    <S.EmptyContainer>
                        <S.EmptyText>Select Product To See Data</S.EmptyText>
                    </S.EmptyContainer>
                ) : productChartData.datasets.map((item) => item.data.length).reduce((a, b) => a + b) > 0 ? (
                    <S.ChartContainer>
                        <Line
                            data={productChartData}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        beginAtZero: true,
                                    },
                                    y: {
                                        beginAtZero: true,
                                    },
                                },
                                legend: {
                                    position: "bottom",
                                    align: "start",
                                },
                            }}
                        />
                    </S.ChartContainer>
                ) : (
                    <S.EmptyContainer>
                        <S.EmptyText>This Data Is Not Available</S.EmptyText>
                    </S.EmptyContainer>
                )}
            </S.Container>
        </div>
    );
};

export default ProductReport;
