import styled from "@emotion/styled/macro";

export const Container = styled.div`
    min-width: 339px;
    flex: 1;
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    overflow: hidden;
    overflow-y: auto;
`;

export const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    padding: 14px 12px;
    border-bottom: 1px solid #cbd5e1;
`;

export const Body = styled.div`
    padding: 5px 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    height: calc(100% - 47px);
`;

export const BtnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;

export const SupplierDetails = styled.div`
    background: #f1f5f9;
    padding: 6px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: 20px;
        width: 20px;
        cursor: pointer;
    }
`;
export const SupplierContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 85%;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    margin-bottom: 4px;
    max-width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Mobile = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;
export const ClearContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
    & > div {
        margin-bottom: 2px;
    }
`;

export const PriceDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;
export const Dotted = styled.div`
    height: 1px;
    border-bottom: 2px dashed #e2e8f0;
    margin: 4px 0;
`;
export const TotalDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const NewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .icon-input {
        width: 138px;
    }
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;

export const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
