import styled from "@emotion/styled/macro";

export const SearchList = styled.div`
    max-height: 287px;
    overflow: scroll;
`;

export const SearchData = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.24px;
    color: #1e293b;
    padding: 12px;
    background: ${(props) => (props.active ? "#f1f5f9" : "#fff")};
    &:hover {
        background: #f1f5f9;
    }
`;
