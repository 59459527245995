import React from "react";
import * as S from "./style";
import {
  MoreInfoIcon,
  ProductPlaceholderIcon,
  WifiDisconnectedIcon,
} from "../../../icons";
import { generateMetrics } from "../../../utils/metrics";
import { formatDate } from "../../../utils/format-date";
import Tippy from "@tippyjs/react";
import { useSelector } from "react-redux";
import { SALE_BILL_TYPE } from "../../../constants/appConstants";
import { hasDecimal } from "../../../utils/global-fn";

const ViewSaleRow = ({ item, idx }) => {
  const { selectedSale } = useSelector((state) => state.sale);

  return (
    <S.Row key={idx}>
      <S.ProductData>
        <img
          src={item.gallery?.length ? item.gallery?.[0] : ProductPlaceholderIcon}
          alt="product img"
          onError={(e) => {
            e.target.src = WifiDisconnectedIcon;
          }}
        />
        <S.ProductDetail>
          <S.Brand>{item.brand?.name}</S.Brand>
          <S.Name>{item.name}</S.Name>
          {item.is_custom_product && <S.Metrics>Custom Product</S.Metrics>}
          <S.Metrics>{generateMetrics(item)}</S.Metrics>
        </S.ProductDetail>
      </S.ProductData>

      <S.Data>
        {item.price !== item.sale_price && <span>₱{item.price}</span>} ₱
        {item.sale_price}
        <S.SubText>
          ({item.sale_price_with_tax === false ? "Without Tax" : "With Tax"})
        </S.SubText>
      </S.Data>
      <S.Data>
        {hasDecimal(item.stock) ? item.stock?.toFixed(2) : item.stock}
      </S.Data>
      <S.Data flex>
        {item.quantity}
        {item.unit && <S.SubText>({item.unit})</S.SubText>}
      </S.Data>
      <S.Data>₱{item.total_discount?.toFixed(2) ?? 0}</S.Data>
      <S.Data>₱{item.tax_value?.toFixed(2) ?? 0}</S.Data>
      <S.Data>₱{item.net_amount?.toFixed(2) ?? 0}</S.Data>
      {selectedSale?.type === "SALE" && (
        <S.Data>₱ {item.net_profit?.toFixed(2) ?? 0}</S.Data>
      )}
      {selectedSale?.bill_type === SALE_BILL_TYPE.INVOICE && (
        <S.Data>
          <Tippy
            placement="bottom"
            trigger="click"
            interactive
            content={<MoreInfo displayData={item} />}
          >
            <img src={MoreInfoIcon} alt="info" />
          </Tippy>
        </S.Data>
      )}
    </S.Row>
  );
};

export default ViewSaleRow;

export const MoreInfo = ({ displayData }) => {
  return (
    <S.MoreInfoWrapper>
      <S.OptionDiv>
        <S.OptionHeader>HSN / SAC</S.OptionHeader>
        <S.OptionInputContainer>
          <S.Input type="text" value={displayData.hsn} disabled />
        </S.OptionInputContainer>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Batch Number</S.OptionHeader>
        <S.OptionInputContainer>
          <S.Input type="text" value={displayData.batch_number} disabled />
        </S.OptionInputContainer>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Mfg Date</S.OptionHeader>
        <S.OptionInputContainer>
          <S.DateDisplay>
            {displayData.manufacturing_date
              ? formatDate(displayData.manufacturing_date)
              : ""}
          </S.DateDisplay>
        </S.OptionInputContainer>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Expiry Date</S.OptionHeader>
        <S.OptionInputContainer>
          <S.DateDisplay>
            {displayData.expiry_date ? formatDate(displayData.expiry_date) : ""}
          </S.DateDisplay>
        </S.OptionInputContainer>
      </S.OptionDiv>
    </S.MoreInfoWrapper>
  );
};
