import React, { useEffect } from "react";
import { useState } from "react";
import * as S from "./style";
import QRCodeReact from "react-qr-code";

const QRCode = () => {
    const [serverIp, setServerIp] = useState("324.06.112.5");

    useEffect(() => {
        getServerIp();
    }, []);

    const getServerIp = async () => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            const store = await window.electronAPI.getStore();
            const ip = store?.device_config?.server_info?.ip
            if (ip) {
                setServerIp(ip);
            }
        } else {
            setServerIp("try-api.homedrop.in");
        }
    };

    return (
        <S.Wrapper>
            <S.LeftContainer>
                <S.Heading>To connect the server IP on your device:</S.Heading>
                <S.Points>
                    <S.Point>
                        <S.Dot></S.Dot>
                        <div>
                            Open <span>Inventory Management Application</span> on your device
                        </div>
                    </S.Point>

                    <S.Point>
                        <S.Dot></S.Dot>
                        <div>
                            On <span>login screen</span> there is an options
                        </div>
                    </S.Point>

                    <S.Point>
                        <S.Dot></S.Dot>
                        <div>
                            Tap on <span>Scan a QR Code</span>
                        </div>
                    </S.Point>

                    <S.Point>
                        <S.Dot></S.Dot>
                        <div>
                            Point your device to this screen to capture the <span>QR Code</span>
                        </div>
                    </S.Point>
                </S.Points>
            </S.LeftContainer>
            <S.RightContainer>
                <S.QRContainer>
                    <QRCodeReact
                        value={serverIp}
                        style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                    />
                </S.QRContainer>
                <S.ServerIp>Server IP: {serverIp}</S.ServerIp>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default QRCode;
