import React, { useState } from "react";
import * as S from "./OnlineSettings.style";
import { DEMO_STORE, ONLINE_SETTINGS_TAB } from "../../constants/appConstants";
import DeliveryArea from "../../components/online-settings/delivery-area/DeliveryArea";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getStore, updateStore } from "../../api/local/storeApi";
import { setStore } from "../../store/session-slice";
import { isEmpty } from "lodash";
import { hideLoader, showLoader } from "../../store/app-slice";
import { useContext } from "react";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import DeliveryRange from "../../components/online-settings/delivery-range/DeliveryRange";
import { captureException } from "../../crash-reporting";

const ToggleSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 68,
    height: 36,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(32px)",
            color: "#2D68FE",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#CCDFFF" : "#CCDFFF",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 29,
        height: 29,
    },
    "& .MuiSwitch-track": {
        borderRadius: 49 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#CCDFFF" : "#CCDFFF",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const OnlineSettings = () => {
    const [activeTab, setActiveTab] = useState(ONLINE_SETTINGS_TAB.DELIVERY_AREA);
    const dispatch = useDispatch();
    const [onlineStore, setOnlineStore] = useState(true);
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        getStoreData();
    }, []);

    const getStoreData = async () => {
        try {
            let res = await getStore();
            if (res.status === 200) {
                if (process.env.REACT_APP_DEMO_SERVER) {
                    dispatch(setStore(DEMO_STORE.store));
                } else {
                    dispatch(setStore(res.data));
                }
                if (res.data.online_settings) {
                    setOnlineStore(res.data.online_settings.is_online);
                } else {
                    setOnlineStore(false);
                }
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    const handleToggle = async () => {
        const payload = {};
        const online_settings = {};
        online_settings.is_online = !onlineStore;
        payload.online_settings = online_settings;

        if (!isEmpty(payload)) {
            dispatch(showLoader());
            try {
                let res = await updateStore(payload);
                if (res.status === 200) {
                    getStoreData();
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Settings Saved",
                        subtitle: "Online settings saved successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                handleSnackbarDetails({
                    show: true,
                    title: "Unable to change settings",
                    type: "error",
                });
                captureException(err);
            } finally {
                dispatch(hideLoader());
            }
        } else {
            handleSnackbarDetails({
                show: true,
                type: "error",
                title: "Please change something",
            });
        }
    };
    return (
        <S.Wrapper>
            <S.Header>
                <S.HeadContainer>
                    <S.Heading>Online Setting</S.Heading>
                    <S.HintText>(Your store is currently {onlineStore ? "Online" : "Offline"})</S.HintText>
                </S.HeadContainer>
                <S.SwitchContainer>
                    <S.Label>Store Online</S.Label>
                    <ToggleSwitch checked={onlineStore} onChange={handleToggle} />
                </S.SwitchContainer>
            </S.Header>
            <S.Body>
                <S.Tabs>
                    <S.Tab active={activeTab === ONLINE_SETTINGS_TAB.DELIVERY_AREA} onClick={() => setActiveTab(ONLINE_SETTINGS_TAB.DELIVERY_AREA)}>
                        {ONLINE_SETTINGS_TAB.DELIVERY_AREA}
                    </S.Tab>
                    <S.Tab active={activeTab === ONLINE_SETTINGS_TAB.DELIVERY_RANGE} onClick={() => setActiveTab(ONLINE_SETTINGS_TAB.DELIVERY_RANGE)}>
                        {ONLINE_SETTINGS_TAB.DELIVERY_RANGE}
                    </S.Tab>
                </S.Tabs>
                {activeTab === ONLINE_SETTINGS_TAB.DELIVERY_AREA && <DeliveryArea />}
                {activeTab === ONLINE_SETTINGS_TAB.DELIVERY_RANGE && <DeliveryRange />}
            </S.Body>
        </S.Wrapper>
    );
};

export default OnlineSettings;
