import React from "react";
import * as S from './style'

const BrandingName = ({size = "sm"}) => {
    return (
        <S.Wrapper size={size}>
            DigiDukandar
        </S.Wrapper>
    );
};

export default BrandingName;
