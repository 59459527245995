export const generateMetrics = (product) => {
    let showMetrics = [];
    if (product.metrics) {
        product.metrics.forEach((item) => showMetrics.push(`${item.value} ${item.unit}`));
    }
    if (product.attributes) {
        for (let key in product.attributes) {
            showMetrics.push(`${key}: ${product.attributes[key]}`);
        }
    }

    return showMetrics.join(", ");
};
