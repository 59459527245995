import { S3Client, PutObjectCommand, GetObjectCommand } from "@aws-sdk/client-s3";
import { captureException } from "../../crash-reporting";

export const REGION = process.env.REACT_APP_AWS_REGION;
export const BUCKET = process.env.REACT_APP_AWS_BUCKET;

export const s3Client = new S3Client({
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
    region: REGION,
});

export const uploadImageToS3 = async (file, key) => {
    let response;
    try {
        const uploadParams = {
            Bucket: BUCKET,
            Key: key, // Set the key (file name) for S3
            Body: file, // The file object itself
            ContentType: file.type, // Set the content type (e.g., image/jpeg, image/png)
        };

        const uploadResult = await s3Client.send(new PutObjectCommand(uploadParams));
        response = {
            status: "success",
            data: uploadResult,
            img_url: `https://s3.ap-south-1.amazonaws.com/${BUCKET}/${key}`,
        };
        return response;
    } catch (error) {
        captureException(error);
        response = {
            status: "error",
            data: error,
        };
        return response;
    }
};
