import React, { useEffect } from "react";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { BillIcon, CalendarIcon, CloseModalIcon, ExpenseIcon } from "../../../icons";
import {
    getPurchaseTotals,
    removeSelectedSupplier,
    setBillNo,
    setDeliveryDate,
    setDeliveryExpense,
    setSelectDiscount,
} from "../../../store/purchase-slice";
import { hasDecimal, toDecimalPlace } from "../../../utils/global-fn";
import CustomIconInput from "../../custom-icon-input/CustomIconInput";
import CartDiscount from "../../discount/cart-discount/CartDiscount";
import CustomButton from "../../custom-button/CustomButton";
import { COLOR, DiscountOption } from "../../../constants/appConstants";
import onInputChange from "../../../utils/handleInputChange";

const NewPurchaseSummary = ({ billRef, delExpRef, discountRef, timeRef, handleBtnClick }) => {
    const dispatch = useDispatch();
    const {
        selectedSupplier,
        purchaseQuantity,
        purchaseTotal,
        purchaseTaxTotal,
        discountValue,
        globalDiscount,
        deliveryExpense,
        purchaseCartTotal,
        billNo,
        deliveryDate,
        selectDiscount,
        purchaseProducts,
    } = useSelector((state) => state.purchase);

    useEffect(() => {
        dispatch(getPurchaseTotals());
    }, [purchaseProducts, selectDiscount, deliveryExpense]);

    const handleSupplierRemove = () => {
        dispatch(removeSelectedSupplier());
    };

    const handleInputChange = (event, name) => {
        onInputChange(event, (data) => {
            let { value } = data;

            if (name === "delivery_expense") {
                const regex = /^[0-9\b]+$/;
                if (!(value === "" || regex.test(value))) return;
                dispatch(setDeliveryExpense(value));
            } else if (name === "bill_no") {
                dispatch(setBillNo(value));
            } else if (name === "cart_discount") {
                const regex = /^\d+\.?(\d)?(\d)?$/;
                if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
                    value = value.substr(1);
                }
                if (!(value === "" || regex.test(value))) return;

                if (selectDiscount.type === DiscountOption.PERCENTAGE) {
                    if (parseFloat(value) > 100) {
                        value = 100;
                    }
                }
                if (selectDiscount.type === DiscountOption.FLAT) {
                    if (parseFloat(value) > purchaseTotal) {
                        value = purchaseTotal;
                    }
                }
                dispatch(setSelectDiscount({ ...selectDiscount, cost: value }));
            }
        });
    };

    const handleDateChange = (date) => {
        dispatch(setDeliveryDate(date));
    };

    const setActiveDiscount = (data) => {
        dispatch(setSelectDiscount({ ...selectDiscount, type: data }));
    };
    return (
        <S.Container>
            <S.Header>Purchase Summary</S.Header>
            <S.Body>
                <S.BodyContent>
                    {selectedSupplier && (
                        <S.SupplierDetails>
                            <S.SupplierContent>
                                <S.Name>{selectedSupplier?.name}</S.Name>
                                <S.Mobile>{selectedSupplier?.phone && `(${selectedSupplier?.phone})`}</S.Mobile>
                            </S.SupplierContent>
                            <S.ClearContainer>
                                <img src={CloseModalIcon} alt="close" onClick={handleSupplierRemove} />
                            </S.ClearContainer>
                        </S.SupplierDetails>
                    )}

                    <S.PriceDetails>
                        <div>Quantity:</div>
                        <div>{purchaseQuantity}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Subtotal:</div>
                        <div>₱{toDecimalPlace(purchaseTotal - purchaseTaxTotal + discountValue)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Tax:</div>
                        <div>₱{toDecimalPlace(purchaseTaxTotal)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Discount:</div>
                        <div>₱{toDecimalPlace(discountValue)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Bill Total:</div>
                        <div>₱{toDecimalPlace(purchaseTotal)}</div>
                    </S.PriceDetails>
                    <S.Dotted></S.Dotted>
                    <S.PriceDetails>
                        <div>Cart Discount:</div>
                        <div>₱{toDecimalPlace(globalDiscount)}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Delivery Expense:</div>
                        <div>₱{deliveryExpense || 0}</div>
                    </S.PriceDetails>
                    {hasDecimal(purchaseCartTotal) && (
                        <S.PriceDetails>
                            <div>Adjusted Cash:</div>
                            <div>₱{toDecimalPlace(Math.round(purchaseCartTotal) - purchaseCartTotal)}</div>
                        </S.PriceDetails>
                    )}
                    <S.TotalDetails>
                        <div>Purchase Total:</div>
                        <div>₱{Math.round(purchaseCartTotal)}</div>
                    </S.TotalDetails>
                    <S.Dotted></S.Dotted>
                    <S.NewContainer>
                        <S.Label>
                            Add Delivery Expense<div>(F5)</div>
                        </S.Label>
                        <CustomIconInput
                            onClick={() => delExpRef.current?.focus()}
                            onChange={(e) => handleInputChange(e, "delivery_expense")}
                            value={deliveryExpense}
                            inputRef={delExpRef}
                            icon={ExpenseIcon}
                            className="icon-input"
                        />
                    </S.NewContainer>
                    <S.NewContainer>
                        <S.Label>
                            Add Bill Number<div>(F7)</div>
                        </S.Label>
                        <CustomIconInput
                            onClick={() => billRef.current?.focus()}
                            onChange={(e) => handleInputChange(e, "bill_no")}
                            value={billNo}
                            inputRef={billRef}
                            icon={BillIcon}
                            className="icon-input"
                        />
                    </S.NewContainer>
                    <S.NewContainer>
                        <S.Label>
                            Select Delivery Date<div>(F3)</div>
                        </S.Label>
                        <CustomIconInput
                            onClick={() => timeRef.current?.querySelector("input").click()}
                            onDateChange={(data) => handleDateChange(data)}
                            value={deliveryDate}
                            inputRef={timeRef}
                            icon={CalendarIcon}
                            className="icon-input"
                            datePicker={true}
                        />
                    </S.NewContainer>
                    <S.NewContainer>
                        <S.Label>
                            Add Discount<div>(F8)</div>
                        </S.Label>
                        <CartDiscount
                            activeDiscount={selectDiscount.type}
                            setActiveDiscount={(data) => setActiveDiscount(data)}
                            discount={selectDiscount.cost ?? 0}
                            onChange={(event) => handleInputChange(event, "cart_discount")}
                            disabled={purchaseProducts.length <= 0}
                            inputRef={discountRef}
                        />
                    </S.NewContainer>
                </S.BodyContent>
                <S.BtnContainer>
                    <CustomButton
                        label="Purchase (F12)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("purchase")}
                        fill={true}
                        size="lg"
                        disable={!purchaseProducts?.length}
                    />
                    <CustomButton
                        label="Save to Counter (F10)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("draft")}
                        size="lg"
                        noBorder={true}
                        disable={!purchaseProducts?.length}
                    />
                </S.BtnContainer>
            </S.Body>
        </S.Container>
    );
};

export default NewPurchaseSummary;
