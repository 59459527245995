import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDrop, ArrowUp, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { updateProductFromReturnSale } from "../../../store/returnsale-slice";
import store from "../../../store/store";
import * as S from "./style";
import { generateMetrics } from "../../../utils/metrics";

const ReturnSaleTable = ({ sale, setSale }) => {
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const rowsRefs = useRef([]);
    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        rowsRefs.current[activeRowRef.current]?.focus();
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const getCurrentIdx = () => {
        return rowsRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 33) {
            //Pg Up key
            if (activeRowRef.current > 0) {
                e.preventDefault();
                setActiveRow(activeRowRef.current - 1);
                rowsRefs.current[activeRowRef.current]?.focus();
            }
        } else if (e.keyCode === 34) {
            //Pg Down key
            if (activeRowRef.current < sale?.length - 1) {
                e.preventDefault();
                setActiveRow(activeRowRef.current + 1);
                rowsRefs.current[activeRowRef.current]?.focus();
            }
        } else if (e.keyCode === 38) {
            //Up Arrow key
            let idx = getCurrentIdx();
            let product = store.getState().returnSale.returnSaleProducts[idx];
            if (activeRowRef.current >= 0 && product) {
                e.preventDefault();
                handleIncrementQuantity(product);
            }
        } else if (e.keyCode === 40) {
            //Down Arrow key
            let idx = getCurrentIdx();
            let product = store.getState().returnSale.returnSaleProducts[idx];
            if (activeRowRef.current >= 0 && product) {
                e.preventDefault();
                handleDecrementQuantity(product);
            }
        }
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        if (name === "allSelect") {
            // let tempSale = sale.map((item) => {
            //     return { ...item, isChecked: checked };
            // });
            // setSale(tempSale);
            sale.forEach((item) =>
                dispatch(
                    updateProductFromReturnSale({
                        ...item,
                        isChecked: checked,
                        returnQuantity: checked && item.quantity,
                    })
                )
            );
        } else {
            // let tempSale = sale.map((item) =>
            //     item.sku === name ? { ...item, isChecked: checked } : item
            // );
            // setSale(tempSale);
            sale.forEach(
                (item) =>
                    item.sku === name &&
                    dispatch(
                        updateProductFromReturnSale({
                            ...item,
                            isChecked: checked,
                            returnQuantity: checked && item.quantity,
                        })
                    )
            );
        }
    };
    const handleIncrementQuantity = (item) => {
        // if (item.isChecked) {
        // dispatch({ ...item, isChecked: true });
        // item.isChecked = true;
        dispatch(updateProductFromReturnSale({ ...item, isChecked: true }));
        if (!item.returnQuantity) {
            // item.returnQuantity = 0;
            dispatch(updateProductFromReturnSale({ ...item, returnQuantity: 0, isChecked: true }));
        }
        if (item.quantity > parseFloat(item.returnQuantity)) {
            // let tempSale = sale.map((el) =>
            //     el.sku === item.sku
            //         ? {
            //               ...el,
            //               returnQuantity: el.returnQuantity ? el.returnQuantity + 1 : 1,
            //           }
            //         : el
            // );
            // setSale(tempSale);
            dispatch(
                updateProductFromReturnSale({
                    ...item,
                    returnQuantity: parseFloat(item.returnQuantity) + 1 > item.quantity ? item.quantity : parseFloat(item.returnQuantity) + 1,
                    isChecked: true,
                })
            );
        }
        // }
    };
    const handleDecrementQuantity = (item) => {
        // if (item.isChecked) {
        if (parseFloat(item.returnQuantity) > 1) {
            // item.isChecked = true;
            dispatch(updateProductFromReturnSale({ ...item, isChecked: true }));
            if (parseFloat(item.returnQuantity) === 1) {
                // item.isChecked = false;
                dispatch(
                    updateProductFromReturnSale({
                        ...item,
                        returnQuantity: parseFloat(item.returnQuantity) - 1,
                        isChecked: false,
                    })
                );
            } else {
                dispatch(
                    updateProductFromReturnSale({
                        ...item,
                        returnQuantity: parseFloat(item.returnQuantity) - 1,
                    })
                );
            }
            // let tempSale = sale.map((el) =>
            //     el.sku === item.sku
            //         ? {
            //               ...el,
            //               returnQuantity: el.returnQuantity - 1,
            //           }
            //         : el
            // );
            // setSale(tempSale);
        }
        // }
    };
    const handleReturnInputChange = (val, item) => {
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (val.length > 1 && val[0] === "0" && val[1] !== ".") {
            val = val.substr(1);
        }
        if (!(val === "" || regex.test(val))) return;

        if (!val || parseFloat(val) === 0) {
            // item.isChecked = false;
            dispatch(
                updateProductFromReturnSale({
                    ...item,
                    isChecked: false,
                    returnQuantity: val,
                })
            );
        } else if (parseFloat(val) <= item.quantity) {
            console.log(parseFloat(val), item.quantity);
            dispatch(
                updateProductFromReturnSale({
                    ...item,
                    isChecked: true,
                    returnQuantity: val,
                })
            );
        } else {
            // item.isChecked = true;
            dispatch(
                updateProductFromReturnSale({
                    ...item,
                    isChecked: true,
                    returnQuantity: item.quantity,
                })
            );
        }
        // if (val > item.quantity) {
        //     val = item.quantity;
        // }
        // let tempSale = sale.map((el) =>
        //     el.sku === item.sku
        //         ? {
        //               ...el,
        //               returnQuantity: parseInt(val),
        //           }
        //         : el
        // );
        // setSale(tempSale);
        // dispatch(
        //     updateProductFromReturnSale({
        //         ...item,
        //         returnQuantity: parseInt(val),
        //     })
        // );
    };

    const handleRowFocus = (idx) => {
        setActiveRow(idx);
        rowsRefs.current[idx]?.focus();
    };
    return (
        <S.Wrapper>
            <S.HeadRow>
                <S.Th flex={0.5}>
                    <S.Check type="checkbox" name="allSelect" checked={!sale?.some((item) => item?.isChecked !== true)} onChange={handleCheck} />
                </S.Th>
                <S.Th flex={2.5} $first>
                    Item Description
                </S.Th>
                <S.Th>Unit Price</S.Th>
                <S.Th>Stock</S.Th>
                <S.Th>Quantity</S.Th>
                <S.Th>Discount</S.Th>
                <S.Th>Subtotal</S.Th>
                <S.Th>Profit</S.Th>
                <S.Th flex={1.2}>Return Quantity</S.Th>
            </S.HeadRow>
            <S.Body>
                {sale?.map((item, idx) => (
                    <>
                        <S.Row key={idx} active={idx === activeRow} onClick={() => handleRowFocus(idx)}>
                            <S.Data flex={0.5}>
                                <S.Check type="checkbox" name={item.sku} checked={item?.isChecked || false} onChange={handleCheck} />
                            </S.Data>
                            <S.ProductData>
                                <img
                                    src={item.gallery?.length ? item.gallery?.[0] : ProductPlaceholderIcon}
                                    alt="product img"
                                    onError={(e) => {
                                        e.target.src = WifiDisconnectedIcon;
                                    }}
                                />
                                <S.ProductDetail>
                                    <S.Brand>{item.brand?.name}</S.Brand>
                                    <S.Name>{item.name}</S.Name>
                                    <S.Metrics>{generateMetrics(item)}</S.Metrics>
                                </S.ProductDetail>
                            </S.ProductData>

                            <S.Data>₱ {item.sale_price}</S.Data>
                            <S.Data>{item.stock}</S.Data>
                            <S.Data>{item.quantity}</S.Data>
                            <S.Data>₱ {item.total_discount.toFixed(2)}</S.Data>
                            <S.Data>₱ {item.net_amount.toFixed(2)}</S.Data>
                            <S.Data>₱ {item.net_profit.toFixed(2)}</S.Data>
                            <S.TableDataQuantity>
                                <div>
                                    {/* <S.Quantity>
                                    {item.returnQuantity
                                        ? item.returnQuantity
                                        : 0}
                                </S.Quantity> */}
                                    <S.ReturnInput
                                        type="text"
                                        value={item.returnQuantity ? item.returnQuantity : 0}
                                        onChange={(e) => handleReturnInputChange(e.target.value, item)}
                                        ref={(el) => (rowsRefs.current[idx] = el)}
                                    />
                                    <S.ArrowBox>
                                        <img src={ArrowUp} alt="arrow up" onClick={() => handleIncrementQuantity(item)} />
                                        <img src={ArrowDrop} alt="arrow drop" onClick={() => handleDecrementQuantity(item)} />
                                    </S.ArrowBox>
                                </div>
                            </S.TableDataQuantity>
                        </S.Row>
                        <S.Line></S.Line>
                    </>
                ))}
            </S.Body>
        </S.Wrapper>
    );
};

export default ReturnSaleTable;
