import {
  REPORTS_API,
  SALE_REPORT_API,
  PURCHASE_REPORT_API,
  EXPENSE_REPORT_API,
  INVENTORY_REPORT_API,
  ALL_REPORT_API,
} from "../../constants/apiRoutes";
import axios from "./axios";

const generateGSTR1Report = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/gstr1`, {
    params: params,
    timeout: 60000,
  });
};

const generateGSTR2Report = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/gstr2`, {
    params: params,
    timeout: 60000,
  });
};

const generateGSTR3BReport = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/gstr3b`, {
    params: params,
    timeout: 60000,
  });
};

const generateGSTR9Report = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/gstr9`, {
    params: params,
    timeout: 60000,
  });
};

const generateSaleReport = async (params = {}) => {
  return await axios.post("/invoices-report", params, {
    responseType: "blob",
    timeout: 30000,
  });
};

const generatePurchaseReport = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/${PURCHASE_REPORT_API}`, {
    params: params,
    timeout: 60000,
  });
};

const generateExpenseReport = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/${EXPENSE_REPORT_API}`, {
    params: params,
    timeout: 60000,
  });
};

const generateInventoryReport = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/${INVENTORY_REPORT_API}`, {
    params: params,
    timeout: 60000,
  });
};

const generateAllReport = async (params = {}) => {
  return await axios.get(`${REPORTS_API}/${ALL_REPORT_API}`, {
    params: params,
    timeout: 30000,
  });
};

export {
  generateGSTR1Report,
  generateGSTR2Report,
  generateGSTR3BReport,
  generateGSTR9Report,
  generateSaleReport,
  generatePurchaseReport,
  generateExpenseReport,
  generateInventoryReport,
  generateAllReport,
};
