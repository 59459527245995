import { createContext, useState } from "react";

export const OfflineDialogContext = createContext();

const OfflineDialogProvider = ({ children }) => {
    const [offlineDialogDetails, setOfflineDialogDetails] = useState({
        show: false,
        title: "",
        subTitle: "",
        supportText: "",
        stepsTitle: "",
        steps: []
    });

    const handleOfflineDialogDetails = (value) => setOfflineDialogDetails(value);

    return (
        <OfflineDialogContext.Provider
            value={{
                offlineDialogDetails,
                handleOfflineDialogDetails,
            }}
        >
            {children}
        </OfflineDialogContext.Provider>
    );
};

export default OfflineDialogProvider;
