import React, { useState } from "react";
import { NAV_MENU_ITEMS } from "../../../constants/navItemsData";
import { PLANS } from "../../../constants/POS-plans";
import NavItem from "./NavItem/NavItem";
import OutsideClickHandler from "react-outside-click-handler";
import { useContext } from "react";
import { DrawerContext } from "../../../DrawerContextProvider/DrawerProvider";
import { useSelector } from "react-redux";

const NavItems = () => {
    const [selectedNavItemID, setSelectedNavItemID] = useState(null);
    const drawerDetails = useContext(DrawerContext);
    const { features } = useSelector((state) => state.app);

    const handleNavItemClick = (id) => {
        setSelectedNavItemID(+id);
    };

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => drawerDetails.setLeftDrawerStatus(false)}>
                {NAV_MENU_ITEMS.map(
                    (item) =>
                        features.includes(item.key) && (
                            <NavItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                logo={item.logo}
                                router={item.route}
                                activeLogo={item.activeLogo}
                                selectedNavItemID={selectedNavItemID}
                                onNavItemClick={handleNavItemClick}
                            />
                        )
                )}
            </OutsideClickHandler>
        </>
    );
};

export default NavItems;
