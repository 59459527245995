import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 419px;
`;

export const Image = styled.img`
    width: 118px;
    height: 97px;
`;

export const Head = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #263238;
    width: 405px;
`;

export const SubHead = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #625b71;
    width: 395px;
    margin-bottom: 30px;
`;
export const BlackBtn = styled.div`
    background-color: #000;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    padding: 9px 16px;
    cursor: pointer;
    width: 184px;
`;

export const Heads = styled.div`
    margin: 30px 0 13px;
`;
