import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    // padding: 24px 24px;
    width: 420px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Container = styled.div`
    height: 204px;
    box-sizing: border-box;
    padding: 12px 24px;
    overflow: hidden;
    overflow-y: auto;
`;

export const InputContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: flex-end;
    margin-bottom: 16px;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
`;

export const Input = styled.div`
    flex: 1;
`;

export const ImgDiv = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 10px;
`;

export const AddNew = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => (props.$fill ? "#fff" : "#2d68fe")};
    background: ${(props) => (props.$fill ? "#2d68fe" : "#fff")};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    padding: 6px 8px;
    cursor: pointer;
    border: 1px solid #2d68fe;
    border-radius: 4px;
`;
export const BottomBar = styled.div`
    padding: 12px 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    padding: 12px 24px;
`;
