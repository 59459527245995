import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 520px;
    height: 160px;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #232325;
    padding: 16px 40px;

    & img {
        cursor: pointer;
    }
`;
export const BtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #f7f7f9;
    border-radius: 12px;
    padding: 16px 40px;
    gap: 29px;
`;
export const DraftBtn = styled.div`
    background: #2e65f3;
    border-radius: 8px;
    width: 128px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
`;
export const ClearBtn = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707091;
    cursor: pointer;
`;
