import axios from "./axios";
import { BRANDS_API } from "../../constants/apiRoutes";
import { PAGE_LIMIT } from "../../constants/appConstants";

const addBrand = async (payload) => {
  return await axios.post(BRANDS_API, payload);
};

const getBrand = async (brandId) => {
  return await axios.get(`${BRANDS_API}/${brandId}`);
};

const getAllBrands = (searchKey) => {
  const queryParams = { limit: PAGE_LIMIT };
  if (searchKey) queryParams.search = searchKey;
  return axios.get(BRANDS_API, {
    params: queryParams,
  });
};

export { addBrand, getBrand, getAllBrands };
