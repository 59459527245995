export const SHORTCUT_TABS = {
    GENERAL_SHORTCUTS: "General Shortcuts",
    NEW_PURCHASE_SHORTCUTS: "New Purhase Shortcuts",
    NEW_PURCHASE_RETURN_DAMAGE_EXISTING_SHORTCUTS:
        "Purchase Return/Damage Shortcuts",
    NEW_SALES_SHORTCUTS: "New Sales Shortcuts",
    VIEW_SALES_SHORTCUTS: "View Sales Shortcuts",
    PURCHASE_MANAGEMENT_SHORTCUTS: "Purchase Management Shortcuts",
    SALES_MANAGEMENT_SHORTCUTS: "Sales Management Shortcuts",
    INVENTORY_MANAGEMENT_SHORTCUTS: "Inventory Management Shortcuts",
    STAFF_MANAGEMENT_SHORTCUTS: "Staff Management Shortcuts",
    SUPPLIERS_MANAGEMENT_SHORTCUTS: "Suppliers Management Shortcuts",
    CUSTOMERS_MANAGEMENT_SHORTCUTS: "Customers Management Shortcuts",
};

export const GENERAL_SHORTCUTS = [
    {
        id: 1,
        activity: "Sales List",
        key1: "Alt",
        key2: "s",
    },
    {
        id: 2,
        activity: "Purchase List",
        key1: "Alt",
        key2: "p",
    },
    {
        id: 3,
        activity: "Customers List",
        key1: "Alt",
        key2: "c",
    },
    {
        id: 4,
        activity: "Suppliers/Whole seller List ",
        key1: "Alt",
        key2: "w",
    },
    {
        id: 5,
        activity: "Manage Inventory",
        key1: "Alt",
        key2: "i",
    },
    {
        id: 6,
        activity: "Manage Staff / Employees",
        key1: "Alt",
        key2: "e",
    },
    {
        id: 7,
        activity: "View online orders",
        key1: "Alt",
        key2: "o",
    },
    {
        id: 8,
        activity: "View Discounts",
        key1: "Alt",
        key2: "d",
    },
    {
        id: 9,
        activity: "View campaigns",
        key1: "Alt",
        key2: "m",
    },
    {
        id: 10,
        activity: "View Analytics",
        key1: "Alt",
        key2: "a",
    },
    {
        id: 11,
        activity: "Global Search",
        key1: "Alt",
        key2: "g",
    },
    {
        id: 12,
        activity: "New Sales",
        key1: "Ctrl",
        key2: "s",
    },
    {
        id: 13,
        activity: "New Purchase",
        key1: "Ctrl",
        key2: "p",
    },
    {
        id: 14,
        activity: "New customer",
        key1: "Ctrl",
        key2: "c",
    },
    {
        id: 15,
        activity: "New  Supplier / Whole seller",
        key1: "Ctrl",
        key2: "w",
    },
    {
        id: 16,
        activity: "New Product / Inventory",
        key1: "Ctrl",
        key2: "i",
    },
    {
        id: 17,
        activity: "New staff / Employees",
        key1: "Ctrl",
        key2: "e",
    },
    {
        id: 18,
        activity: "New Sales Enter",
        key1: "Ctrl",
        key2: "s",
        key3: "r",
    },
    {
        id: 19,
        activity: "New Purchase Enter",
        key1: "Ctrl",
        key2: "p",
        key3: "r",
    },
    {
        id: 20,
        activity: "New campaign",
        key1: "Ctrl",
        key2: "m",
    },
    {
        id: 21,
        activity: "Close Window",
        key1: "Ctrl",
        key2: "w",
    },
    {
        id: 22,
        activity: "Logout",
        key1: "Ctrl",
        key2: "l",
    },
    {
        id: 23,
        activity: "Help",
        key1: "Ctrl",
        key2: "h",
    },
    {
        id: 24,
        activity: "Print",
        key1: "Ctrl",
        key2: "p",
    },
];

export const NEW_PURCHASE_SCREEN = [
    {
        id: 1,
        activity: "Add new product",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 2,
        activity: "Change / Add Supplier ",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 3,
        activity: "Save to Draft ",
        key1: "Shift",
        key2: "d",
    },
    {
        id: 4,
        activity: "View Drafts",
        key1: "Shift",
        key2: "o",
    },
    {
        id: 5,
        activity: "Add bill number",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 6,
        activity: "Add delivery expense",
        key1: "Shift",
        key2: "e",
    },
    {
        id: 7,
        activity: "Save",
        key1: "Shift",
        key2: "Enter",
    },
    {
        id: 8,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const NEW_PURCHASE_RETURN_DAMAGE_EXISTING_SCREEN = [
    {
        id: 1,
        activity: "Add new product",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 2,
        activity: "Add Supplier ",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 3,
        activity: "Save to Draft ",
        key1: "Shift",
        key2: "d",
    },
    {
        id: 4,
        activity: "Add bill number",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 5,
        activity: "Add delivery expense",
        key1: "Shift",
        key2: "e",
    },
    {
        id: 6,
        activity: "Select delivery date",
        key1: "Shift",
        key2: "y",
    },
    {
        id: 7,
        activity: "Proceed to Return",
        key1: "Shift",
        key2: "Enter",
    },
    {
        id: 8,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const NEW_SALES_SCREEN = [
    {
        id: 1,
        activity: "Add / Edit customer",
        key1: "Shift",
        key2: "c",
    },
    {
        id: 2,
        activity: "Add Discounts",
        key1: "Shift",
        key2: "m",
    },
    {
        id: 3,
        activity: "Save to Draft ",
        key1: "Shift",
        key2: "d",
    },
    {
        id: 4,
        activity: "View Draft Orders",
        key1: "Shift",
        key2: "o",
    },
    {
        id: 5,
        activity: "Save",
        key1: "Shift",
        key2: "Enter",
    },
    {
        id: 6,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const VIEW_SALE_SCREEN = [
    {
        id: 1,
        activity: "Add / Edit customer",
        key1: "Shift",
        key2: "c",
    },
    {
        id: 2,
        activity: "Return Items",
        key1: "Shift",
        key2: "r",
    },
    {
        id: 3,
        activity: "Print Invoice",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 4,
        activity: "Print Tax Invoice",
        key1: "Shift",
        key2: "t",
    },
    {
        id: 5,
        activity: "Add Bill number ",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 6,
        activity: "Change payment method",
        key1: "Shift",
        key2: "y",
    },
    {
        id: 7,
        activity: "Save",
        key1: "Shift",
        key2: "Enter",
    },
    {
        id: 8,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];
export const PURCHASE_MANAGEMENT_SCREEN = [
    {
        id: 1,
        activity: "Search by Product",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 2,
        activity: "Search by Supplier",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 3,
        activity: "Search by Brand",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 4,
        activity: "Search by Employees",
        key1: "Shift",
        key2: "e",
    },
    {
        id: 5,
        activity: "Search by Payment Status",
        key1: "Shift",
        key2: "y",
    },
    {
        id: 6,
        activity: "Search by Time",
        key1: "Shift",
        key2: "t",
    },
    {
        id: 7,
        activity: "Change Tabs",
        key1: "Shift",
        key2: "Tab",
    },
    {
        id: 8,
        activity: "New Purchase",
        key1: "Shift",
        key2: "n",
    },
    {
        id: 9,
        activity: "Return Tab",
        key1: "Shift",
        key2: "r",
    },
    {
        id: 10,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
    {
        id: 11,
        activity: "New Purchase Return",
        key1: "Shift",
        key2: "r",
    },
    {
        id: 12,
        activity: "New Damage Entry",
        key1: "Shift",
        key2: "d",
    },
    {
        id: 13,
        activity: "Purchase Tab",
        key1: "Shift",
        key2: "u",
    },
];

export const SALES_MANAGEMENT_SCREEN = [
    {
        id: 1,
        activity: "Search by Product",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 2,
        activity: "Search by Customers",
        key1: "Shift",
        key2: "c",
    },
    {
        id: 3,
        activity: "Search by Brand",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 4,
        activity: "Search by Employees",
        key1: "Shift",
        key2: "e",
    },
    {
        id: 5,
        activity: "Search by Payment Status",
        key1: "Shift",
        key2: "y",
    },
    {
        id: 6,
        activity: "Search by Time",
        key1: "Shift",
        key2: "t",
    },
    {
        id: 7,
        activity: "Change Tabs",
        key1: "Shift",
        key2: "Tab",
    },
    {
        id: 8,
        activity: "New Sales",
        key1: "Shift",
        key2: "n",
    },
    {
        id: 9,
        activity: "Return Tab",
        key1: "Shift",
        key2: "r",
    },
    {
        id: 10,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const INVENTORY_MANAGEMENT = [
    {
        id: 1,
        activity: "Search by Product",
        key1: "Shift",
        key2: "p",
    },
    {
        id: 2,
        activity: "Search by Type",
        key1: "Shift",
        key2: "t",
    },
    {
        id: 3,
        activity: "Search by Category",
        key1: "Shift",
        key2: "c",
    },
    {
        id: 4,
        activity: "Search by Brand",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 5,
        activity: "Search by Stock Type",
        key1: "Shift",
        key2: "y",
    },
    {
        id: 6,
        activity: "Search by Supplier",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 7,
        activity: "Search by Tags",
        key1: "Shift",
        key2: "g",
    },
    {
        id: 8,
        activity: "Search by Availability",
        key1: "Shift",
        key2: "a",
    },
    {
        id: 9,
        activity: "Change Tabs",
        key1: "Shift",
        key2: "Tab",
    },
    {
        id: 10,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const STAFF_MANAGEMENT = [
    {
        id: 1,
        activity: "Search by Name",
        key1: "Shift",
        key2: "n",
    },
    {
        id: 2,
        activity: "Search by Roles",
        key1: "Shift",
        key2: "r",
    },
    {
        id: 3,
        activity: "Search by Status",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 4,
        activity: "Search by Salary Advance",
        key1: "Shift",
        key2: "a",
    },
    {
        id: 5,
        activity: "Search by Monthly Salary",
        key1: "Shift",
        key2: "m",
    },
    {
        id: 6,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];
export const SUPPLIERS_MANAGEMENT = [
    {
        id: 1,
        activity: "Search by Name",
        key1: "Shift",
        key2: "n",
    },
    {
        id: 2,
        activity: "Search by Brand",
        key1: "Shift",
        key2: "b",
    },
    {
        id: 3,
        activity: "Add supplier",
        key1: "Shift",
        key2: "s",
    },
    {
        id: 4,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const CUSTOMERS_MANAGEMENT = [
    {
        id: 1,
        activity: "Search by Name",
        key1: "Shift",
        key2: "n",
    },
    {
        id: 2,
        activity: "Search by Tags",
        key1: "Shift",
        key2: "t",
    },
    {
        id: 3,
        activity: "Add Customer",
        key1: "Shift",
        key2: "c",
    },
    {
        id: 4,
        activity: "Clear All",
        key1: "Shift",
        key2: "x",
    },
];

export const SHORTCUTS_MAP = [
    {
        id: 1,
        title: SHORTCUT_TABS.GENERAL_SHORTCUTS,
        data: GENERAL_SHORTCUTS,
    },
    {
        id: 2,
        title: SHORTCUT_TABS.NEW_PURCHASE_SHORTCUTS,
        data: NEW_PURCHASE_SCREEN,
    },
    {
        id: 4,
        title: SHORTCUT_TABS.NEW_SALES_SHORTCUTS,
        data: NEW_SALES_SCREEN,
    },
    {
        id: 3,
        title: SHORTCUT_TABS.NEW_PURCHASE_RETURN_DAMAGE_EXISTING_SHORTCUTS,
        data: NEW_PURCHASE_RETURN_DAMAGE_EXISTING_SCREEN,
    },

    {
        id: 5,
        title: SHORTCUT_TABS.VIEW_SALES_SHORTCUTS,
        data: VIEW_SALE_SCREEN,
    },
    {
        id: 6,
        title: SHORTCUT_TABS.PURCHASE_MANAGEMENT_SHORTCUTS,
        data: PURCHASE_MANAGEMENT_SCREEN,
    },
    {
        id: 7,
        title: SHORTCUT_TABS.SALES_MANAGEMENT_SHORTCUTS,
        data: SALES_MANAGEMENT_SCREEN,
    },
    {
        id: 8,
        title: SHORTCUT_TABS.INVENTORY_MANAGEMENT_SHORTCUTS,
        data: INVENTORY_MANAGEMENT,
    },
    {
        id: 9,
        title: SHORTCUT_TABS.STAFF_MANAGEMENT_SHORTCUTS,
        data: STAFF_MANAGEMENT,
    },
    {
        id: 10,
        title: SHORTCUT_TABS.SUPPLIERS_MANAGEMENT_SHORTCUTS,
        data: SUPPLIERS_MANAGEMENT,
    },
    {
        id: 11,
        title: SHORTCUT_TABS.CUSTOMERS_MANAGEMENT_SHORTCUTS,
        data: CUSTOMERS_MANAGEMENT,
    },
];
