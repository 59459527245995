import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    // width: 74%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: #fff;
    // height: 100%;
    flex: 3;
`;
export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 14px 16px;
    border-bottom: 1px solid #cbd5e1;

    & > div {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
    }
`;

export const Body = styled.div`
    // height: 100%;
    height: calc(100vh - 300px);
    overflow: hidden;
    overflow-y: auto;
`;

export const Th = styled.div`
    flex: ${(props) => (props.$first ? 2.5 : 1)};
    text-align: ${(props) => (props.$first ? "left" : "center")};
`;
export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 14px 16px;
`;
export const ProductData = styled.div`
    display: flex;
    align-items: center;
    flex: 2.5;
    gap: 6px;

    & > img {
        height: 50px;
        width: 50px;
        border: 1px solid #cbd5e1;
        border-radius: 4px;
    }
`;
export const ProductDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 140px;
`;
export const Brand = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #475569;
    text-transform: uppercase;
`;
export const Name = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2d68fe;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
`;
export const Metrics = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94a3b8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const Data = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    flex: 1;
    text-align: center;

    & span {
        font-size: 12px;
        color: #64748b;
        text-decoration: line-through;
    }

    & > img {
        cursor: pointer;
    }
`;

export const SubText = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #94a3b8;
`;

export const MoreInfoWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 790px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f5f9;
    box-sizing: border-box;
    padding: 7px 16px;
    border: 1px solid #cbd5e1;
`;

export const OptionHeader = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;
export const OptionDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const OptionInputContainer = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #334155;
    width: 93px;
    background-color: #fff;
`;
export const DateDisplay = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #334155;
`;
export const Input = styled.input`
    width: 100%;
    border: none;
    outline: none;
    background: white;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #334155;
    text-align: center;
`;
