import { Modal } from "@mui/material";
import { cloneDeep, set } from "lodash";
import React, { useEffect, useState } from "react";
import { ThreeDotsVerticalIcon } from "../../../../icons";
import ProductDrawer from "../../../product-drawer/product-drawer";
import Product from "./product";
import Lottie from "lottie-react";
import EmptyTable from "../../../../animations/empty_table.json";

import * as S from "./products-table.styles";
import store from "../../../../store/store";
import { useRef } from "react";
import ConfirmationDialog from "../../../product-drawer/confirmation-dialog/confirmation-dialog";
import {
  DELETE_MODAL,
  MODAL_TYPES,
  PRODUCT_STATUS,
} from "../../../../constants/appConstants";
import { updateProduct } from "../../../../api/local/productApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts } from "../../../../store/product-slice";
import { useContext } from "react";
import { SnackbarContext } from "../../../../snackbar-context/snackbar-context";
import { captureException } from "../../../../crash-reporting";

const ProductsTable = ({
  products,
  tableRef,
  activeRow,
  activeRowRef,
  setActiveRow,
  openViewDrawerRef,
  openEditDrawer,
  selectRef,
  openModalRef,
  setOpenModal,
  openModal,
  setOpenBarcodeModal,
}) => {
  const scrollRef = useRef(null);
  const productRef = useRef(null);
  const [keyboardBuffer, _setKeyboardBuffer] = useState(null);
  const keyboardBufferRef = useRef(keyboardBuffer);
  const setKeyboardBuffer = (data) => {
    keyboardBufferRef.current = data;
    _setKeyboardBuffer(data);
  };

  const dispatch = useDispatch();
  const { searchParams } = useSelector((state) => state.product);
  const { handleSnackbarDetails } = useContext(SnackbarContext);

  useEffect(() => {
    document.addEventListener("keydown", handleDetectKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, []);

  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  }, [activeRow]);

  const handleDetectKeyDown = (e) => {
    if (openViewDrawerRef.current || selectRef.current) {
      return;
    }
    if (openModalRef.current?.show) return;
    if (
      keyboardBufferRef.current &&
      Date.now() - keyboardBufferRef.current?.t < 50
    ) {
      return;
    }
    setKeyboardBuffer({
      k: e.keyCode,
      t: Date.now(),
    });
    if (e.keyCode === 33) {
      //Pg Up key
      if (activeRowRef.current > 0) {
        setActiveRow(activeRowRef.current - 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 34) {
      //Pg down key
      let totalProducts = store.getState().product.products;
      if (activeRowRef.current < totalProducts.length - 1) {
        setActiveRow(activeRowRef.current + 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 13) {
      //Enter key
      let totalProducts = store.getState().product.products;
      // setOpenDrawer(true);
      if (totalProducts?.length) {
        openEditDrawer(totalProducts[activeRowRef.current]);
      }
      e.preventDefault();
    } else if (e.keyCode === 46) {
      //Delete key
      let totalProducts = store.getState().product.products;
      setOpenModal({
        show: true,
        type: MODAL_TYPES.DELETE,
        data: DELETE_MODAL,
        product: totalProducts[activeRowRef.current],
      });
      e.preventDefault();
    }
  };

  const handleConfirmationModalSubmit = (product) => {
    if (!product) return;
    setOpenModal({ show: false, type: null, data: null });
    const payload = cloneDeep(product);
    payload.status = PRODUCT_STATUS.TRASH;
    updateProduct(product.id, payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchAllProducts(searchParams));
          handleSnackbarDetails({
            show: true,
            title: "Deleted succesfully",
            type: "success",
            subtitle: "The product has been deleted succesfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      })
      .catch((err) => {
        captureException(err);
      });
  };
  return (
    <>
      <Modal
        open={openModal.show}
        onClose={() =>
          setOpenModal({ show: false, type: null, data: null, product: null })
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationDialog
          closeModal={() =>
            setOpenModal({ show: false, type: null, data: null, product: null })
          }
          data={openModal.data}
          type={openModal.type}
          handleSubmit={() => handleConfirmationModalSubmit(openModal.product)}
          open={openModal.show}
        />
      </Modal>
      <S.Table>
        <S.TableHeader>
          <S.Header flexValue="2.75">Product</S.Header>
          <S.Header flexValue="1.5">Brand</S.Header>
          <S.Header flexValue="1.25">Price</S.Header>
          <S.Header lastItem={true} flexValue="1.75">
            Stock
          </S.Header>
        </S.TableHeader>
        {products?.length ? (
          <S.TableBody ref={tableRef}>
            {products.map((product, idx) => (
              <Product
                key={product.sku}
                idx={idx}
                product={product}
                openEditDrawer={(product) => openEditDrawer(product)}
                activeRow={activeRow}
                scrollRef={scrollRef}
                ref={productRef}
                setOpenModal={setOpenModal}
                setActiveRow={setActiveRow}
                setOpenBarcodeModal={setOpenBarcodeModal}
              />
            ))}
          </S.TableBody>
        ) : (
          <S.EmptyTable>
            <div>
              <Lottie animationData={EmptyTable} />
            </div>
          </S.EmptyTable>
        )}
      </S.Table>
    </>
  );
};

export default ProductsTable;
