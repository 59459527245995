import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./CustomerTable.styles";
import TableRow from "./TableRow";
import Lottie from "lottie-react";
import EmptyTable from "../../animations/empty_table.json";
import { useEffect } from "react";
import { fetchAllCustomers } from "../../store/customer-slice";
import { FEATURES } from "../../constants/POS-plans";

const CustomerTable = ({ setOpenNewCustModal, handleDeleteCustomer, tableRef }) => {
    const { customers, searchParams } = useSelector((state) => state.customer);
    const dispatch = useDispatch();
    const { features } = useSelector((state) => state.app);

    useEffect(() => {
        if (searchParams.show_transactions) dispatch(fetchAllCustomers(searchParams));
    }, [searchParams]);
    return (
        <S.Table>
            <S.THead>
                <S.Th>Name</S.Th>
                <S.Th>Phone Number</S.Th>
                {features.includes(FEATURES.LOYALTY_POINT) && <S.Th>Loyalty Points</S.Th>}
                <S.Th>Total Orders</S.Th>
                <S.Th>Amount Due</S.Th>
                <S.Th></S.Th>
            </S.THead>
            {customers?.length ? (
                <S.TBody ref={tableRef}>
                    {customers.map((el, i) => (
                        <TableRow item={el} key={i} setOpenNewCustModal={setOpenNewCustModal} handleDeleteCustomer={handleDeleteCustomer} />
                    ))}
                </S.TBody>
            ) : (
                <S.EmptyTable>
                    <div>
                        <Lottie animationData={EmptyTable} />
                    </div>
                </S.EmptyTable>
            )}
        </S.Table>
    );
};

export default CustomerTable;
