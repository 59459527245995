import styled from "@emotion/styled/macro";

export const Container = styled.div`
    padding-top: 8px;
`;

export const Section = styled.div`
    margin-bottom: 12px;
`;

export const Header = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #475569;
    margin-bottom: 14px;
`;

export const Purchase = styled.div`
    width: 50%;
    margin-bottom: 12px;
`;

export const PriceContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const InputContainer = styled.div`
    flex: ${(props) => (props.flexValue ? props.flexValue : 1)};
`;

export const StockContainer = styled(Purchase)`
    width: 46%;
`;

export const StockCheckboxContainer = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #334155;
    margin-top: 6px;
    display: flex;
    align-items: center;

    label {
        display: flex;
        align-items: center;
    }

    input {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border: 1px solid #e2e8f0;
        border-radius: 2px;
    }
`;
export const TagsInput = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    padding: 9px;
    flex: 1;
    gap: 12px;

    min-height: 36px;
    max-height: 36px;
    align-items: center;

    & > img {
        width: 18px;
        height: 18px;
    }
`;

export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    input {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #334155;
        outline: none;
        border: none;
    }
`;
export const QuantityContainer = styled.div`
    display: flex;
    gap: 24px;
`;
export const Description = styled.textarea`
    width: 100%;
    resize: none;
    height: 91px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 6px 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
    outline: none;
    font-family: sans-serif;
`;
