import React from "react";
import { useState } from "react";
import { DeliveryChargeIcon, DeliveryRangeIcon, DeliveryTimeIcon, FreeCartIcon, StaffDelete } from "../../../icons";
import * as S from "./style";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../store/app-slice";
import { updateStore } from "../../../api/local/storeApi";
import { setStore } from "../../../store/session-slice";
import { useEffect } from "react";
import { captureException } from "../../../crash-reporting";

const initialData = [
    {
        start_in_kms: 0,
        end_in_kms: "",
        delivery_time: "",
        free_delivery_above_cart_value: "",
        delivery_charge: "",
    },
];
const DeliveryRange = () => {
    const [deliveryData, setDeliveryData] = useState(initialData);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const dispatch = useDispatch();
    const store = useSelector((state) => state.session.store);

    useEffect(() => {
        if (store) {
            if (store.delivery_data) {
                setDeliveryData(store.delivery_data);
            }
        }
    }, []);

    const handleAddDeliveryData = () => {
        let lastData = deliveryData[deliveryData.length - 1];
        if (lastData) {
            if (!lastData.end_in_kms) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please enter end in kms`,
                    type: "error",
                });
                return;
            }
            if (!lastData.delivery_charge) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please enter delivery charge`,
                    type: "error",
                });
                return;
            }

            if (!lastData.delivery_time) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please enter delivery time`,
                    type: "error",
                });
                return;
            }
            if (lastData.end_in_kms < lastData.start_in_kms) {
                handleSnackbarDetails({
                    show: true,
                    title: `End km should be greater than start km`,
                    type: "error",
                });
                return;
            }
        }
        const newDeliveryData = deliveryData
            ? deliveryData.concat([
                  {
                      start_in_kms: lastData.end_in_kms,
                      end_in_kms: "",
                      delivery_time: "",
                      free_delivery_above_cart_value: "",
                      delivery_charge: "",
                  },
              ])
            : [
                  {
                      start_in_kms: 0,
                      end_in_kms: "",
                      delivery_time: "",
                      free_delivery_above_cart_value: "",
                      delivery_charge: "",
                  },
              ];
        setDeliveryData(newDeliveryData);
    };

    const handleRemoveDeliveryData = (index) => {
        const newDeliveryData = deliveryData.filter((item, idx) => index !== idx);
        setDeliveryData(newDeliveryData);
    };

    const handleInputChange = (e, key, index) => {
        const regex = /^[0-9\b]+$/;
        if (!(e.target.value === "" || regex.test(e.target.value))) return;

        const newDeliveryData = deliveryData.map((item, idx) => {
            if (idx !== index) return item;
            return { ...item, [key]: e.target.value };
        });

        if (key === "end_in_kms" && index < newDeliveryData.length - 1) {
            newDeliveryData[index + 1].start_in_kms = e.target.value;
        }
        setDeliveryData(newDeliveryData);
    };

    const handleSubmit = async () => {
        let lastData = deliveryData[deliveryData.length - 1];

        if (!lastData.delivery_charge) {
            handleSnackbarDetails({
                show: true,
                title: `Please enter delivery charge`,
                type: "error",
            });
            return;
        }

        if (!lastData.delivery_time) {
            handleSnackbarDetails({
                show: true,
                title: `Please enter delivery time`,
                type: "error",
            });
            return;
        }
        if (lastData.end_in_kms !== "" && parseInt(lastData.end_in_kms) < parseInt(lastData.start_in_kms)) {
            handleSnackbarDetails({
                show: true,
                title: `End km should not be smaller than start km`,
                type: "error",
            });
            return;
        }

        let delivery_data = deliveryData.map((obj) => {
            let result = {};
            for (const key in obj) {
                if (obj[key] !== "") {
                    if (typeof obj[key] === "string") {
                        result[key] = parseInt(obj[key]);
                    } else {
                        result[key] = obj[key];
                    }
                }
            }
            return result;
        });

        const payload = {
            delivery_data,
        };

        dispatch(showLoader());
        try {
            let res = await updateStore(payload);
            if (res.status === 200) {
                // setDisplayData(res.data);
                await dispatch(setStore(res.data));
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Delivery data updated",
                    subtitle: "Delivery data updated successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        } finally {
            dispatch(hideLoader());
        }
    };

    return (
        <S.Wrapper>
            <S.Body>
                <S.DataContainer>
                    {deliveryData?.map((item, idx) => (
                        <S.DataRow key={idx}>
                            <S.IconContainer>
                                <S.SerialNo>{idx + 1}</S.SerialNo>
                                {deliveryData.length > 1 && idx === deliveryData.length - 1 && (
                                    <img src={StaffDelete} alt="delete icon" onClick={() => handleRemoveDeliveryData(idx)} />
                                )}
                            </S.IconContainer>
                            <div>
                                <S.Label>
                                    <img src={DeliveryRangeIcon} alt="" />
                                    Delivery Range
                                </S.Label>
                                <S.TimeContainerRow>
                                    <S.TimeContainer>
                                        <S.Input type="text" value={item.start_in_kms} disabled />
                                        <S.UnitText>Km</S.UnitText>
                                    </S.TimeContainer>
                                    <S.UnitText>-</S.UnitText>
                                    <S.TimeContainer>
                                        <S.Input type="text" value={item.end_in_kms} onChange={(e) => handleInputChange(e, "end_in_kms", idx)} />
                                        <S.UnitText>Km</S.UnitText>
                                    </S.TimeContainer>
                                </S.TimeContainerRow>
                            </div>
                            <S.DeliveryChargeFlexContainer>
                                <div>
                                    <S.Label>
                                        <img src={DeliveryChargeIcon} alt="" />
                                        Delivery Charge
                                    </S.Label>
                                    <S.TimeContainer>
                                        <S.UnitText>₱</S.UnitText>
                                        <S.Input
                                            type="text"
                                            size="lg"
                                            value={item.delivery_charge}
                                            onChange={(e) => handleInputChange(e, "delivery_charge", idx)}
                                        />
                                    </S.TimeContainer>
                                </div>
                                <div>
                                    <S.Label>
                                        <img src={FreeCartIcon} alt="" />
                                        Free Delivery above cart value
                                    </S.Label>
                                    <S.TimeContainer>
                                        <S.UnitText>₱</S.UnitText>
                                        <S.Input
                                            type="text"
                                            size="lg"
                                            value={item.free_delivery_above_cart_value}
                                            onChange={(e) => handleInputChange(e, "free_delivery_above_cart_value", idx)}
                                        />
                                    </S.TimeContainer>
                                </div>
                            </S.DeliveryChargeFlexContainer>
                            <div>
                                <S.Label>
                                    <img src={DeliveryTimeIcon} alt="" />
                                    Delivery Time
                                </S.Label>
                                <S.TimeContainer>
                                    <S.Input type="text" value={item.delivery_time} onChange={(e) => handleInputChange(e, "delivery_time", idx)} />
                                    <S.UnitText>Minutes</S.UnitText>
                                </S.TimeContainer>
                            </div>
                        </S.DataRow>
                    ))}
                    <S.AddBtn onClick={handleAddDeliveryData}>+ Add Distance Range</S.AddBtn>
                </S.DataContainer>
                <div></div>
            </S.Body>
            <S.BottomBar>
                <S.SaveBtn onClick={handleSubmit}>Save Changes</S.SaveBtn>
            </S.BottomBar>
        </S.Wrapper>
    );
};

export default DeliveryRange;
