import styled from "@emotion/styled/macro";
import { multilineEllipsis } from "../../../../assets/css/mixin";

export const Container = styled.div`
    display: flex;
    // gap: ${(props) => (props.open ? "1px" : "26px")};
    gap: 1px;
    flex-wrap: wrap;
    overflow: scroll;
    transition: all 0.2s ease;
    // border: 1px solid red;
`;
export const Grid = styled.div`
    flex: 1 0 352px;
    // width: 352px;
    height: 120px;
    // background: #ffffff;
    background: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    border: 0.724837px solid rgba(173, 173, 173, 0.25);
    box-shadow: 0px 0px 8.69804px rgba(0, 0, 0, 0.16);
    border-radius: 2.89935px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px;
    margin: 7px;
    cursor: pointer;

    &:hover {
        background: #f1f5f9;
    }
    // @media (max-width: 800px) {
    //     width: 450px;
    // }
`;
export const ProductDetails = styled.div`
    flex: 1;
`;
export const ProductImage = styled.img`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    width: 90px;
    height: 90px;
    margin-right: 12px;
`;
export const ImagePlaceholder = styled.div`
    min-width: 90px;
    min-height: 90px;
    background: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        min-height: 60px;
        min-width: 60px;
    }
`;
export const Brand = styled.div`
    font-weight: 500;
    font-size: 13.0471px;
    line-height: 16px;
    color: #64748b;
    margin-bottom: 3px;
    text-transform: uppercase;
`;
export const Name = styled.div`
    font-weight: 400;
    font-size: 14.4967px;
    line-height: 18px;
    color: #334155;
    width: 185px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 6px;
    // ${multilineEllipsis({ fontSize: "20px", lineHeight: "24px", lines: 2 })}
`;
export const Attr = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #334155;
    width: 185px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 6px;
    // ${multilineEllipsis({ fontSize: "20px", lineHeight: "24px", lines: 2 })}
`;
export const PriceData = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 9px;
`;
export const TextStrike = styled.span`
    text-decoration: line-through;
    color: #334155;
    margin-right: 4px;
`;
export const StrikedPrice = styled.span`
    font-weight: 400;
    font-size: 13.0471px;
    line-height: 16px;
    color: #94a3b8;
`;
export const Price = styled.div`
    font-weight: 400;
    font-size: 14.4967px;
    line-height: 17px;
    color: #334155;
`;
export const Stock = styled.div`
    font-weight: 400;
    font-size: 13.0471px;
    line-height: 16px;
    color: ${(props) => (+props.stockType === 0 ? "#EE1111" : +props.stockType === -1 ? "#17b288" : "#17b288")};
`;

export const OptionIcon = styled.div`
    position: absolute;
    // background: white;
    top: 12px;
    right: 12px;
    cursor: pointer;

    img {
        width: 24px;
        height: 24px;
    }
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 8px;
    width: 122px;
    height: 99px;
    top: 8px;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    // gap: 15px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    z-index: 1;
    cursor: pointer;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 11.25px;
    line-height: 15px;
    letter-spacing: -0.24px;
    color: #333333;
    padding: 8px 16px;

    :hover {
        background-color: #f1f5f9;
    }
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
