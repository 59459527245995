import styled from "@emotion/styled/macro";

export const Container = styled.div`
    border: 2px solid #e2e8f0;
    border-radius: 0px 0px 2px 2px;
    border-top: 0;
`;

export const AddNewContainer = styled.div`
    height: 58px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2d68fe;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;

    img {
        height: 24px;
        width: 24px;
        margin-right: 4px;
    }
`;

export const CustomerDetail = styled.div`
    height: 58px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
`;

export const CustomerDetailContainer = styled.div`
    overflow-y: auto;
    max-height: 530px;
`;

export const CustomerName = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #334155;
`;

export const CustomerNumber = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
`;
