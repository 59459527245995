import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import Cart from "../../components/sales/cart/cart";
import Checkout from "../../components/sales/checkout/checkout";
import { BlueDraftIcon, RedClearCartIcon } from "../../icons";
import * as S from "./styles";
import { calendarFormatDate } from "../../utils/format-date";
import ClearCart from "../../components/sales/ClearDialog/ClearCart";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllSearchParams,
  removeSelectedCustomer,
} from "../../store/customer-slice";
import { useRef } from "react";
import {
  addProductToSale,
  removeAllSearchSaleParams,
  setCpCount,
  setCreatedDate,
  setCurrentScreen,
  setSearchSaleParams,
  updateProductFromSale,
} from "../../store/sale-slice";
import { Drawer } from "@mui/material";
import DraftDrawer from "./../../components/sales/draft-drawer/DraftDrawer";
import {
  DiscountOption,
  SALES_TYPE,
  SALE_BILL_TYPE,
  SALE_SCREENS,
} from "../../constants/appConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { PLAN_TYPE } from "../../constants/POS-plans";
import {
  setBillTypePreference,
  setOpenBuyDialog,
  setPOSFeatures,
  setPOSPlan,
} from "../../store/app-slice";
import ToggleSwitch from "../../components/switch/Switch";
import { captureException } from "../../crash-reporting";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import CustomProduct from "../../components/custom-product/CustomProduct";

const Sales = (props, ref) => {
  const [openClearModal, _setOpenClearModal] = useState(false);
  const openClearModalRef = useRef(openClearModal);
  const setOpenClearModal = (data) => {
    openClearModalRef.current = data;
    _setOpenClearModal(data);
  };
  const [openDraftDrawer, setOpenDraftDrawer] = useState(false);
  const dispatch = useDispatch();
  const { createdDate, currentScreen, saleProducts, cp_count, cp_name_count } =
    useSelector((state) => state.sale);
  const saleRef = useRef();
  const tilesRef = useRef();
  const billRef = useRef();
  const cartRef = useRef();
  const clearCartRef = useRef();
  const [openDrawer, _setOpenDrawer] = useState(false);
  const openDrawerRef = useRef(openDrawer);
  const setOpenDrawer = (data) => {
    openDrawerRef.current = data;
    _setOpenDrawer(data);
  };
  const { billTypePreference } = useSelector((state) => state.app);
  const [isInvoice, setIsInvoice] = useState();
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const [openCustomProduct, _setOpenCustomProduct] = useState(false);
  const openCustomProductRef = useRef(openCustomProduct);
  const setOpenCustomProduct = (data) => {
    openCustomProductRef.current = data;
    _setOpenCustomProduct(data);
  };

  useEffect(() => {
    // if (selectedSale?.status === "DRAFT") {
    //     dispatch(setBillno(selectedSale.bill_no ? selectedSale.bill_no : ""));
    //     dispatch(setCreatedDate(new Date(selectedSale.created_at)));
    //     dispatch(
    //         setSelectDiscount({
    //             type: DiscountOption.PERCENTAGE,
    //             cost: 0,
    //         })
    //     );
    //     if (selectedSale.additional_charges) {
    //         dispatch(setAdditionalCharges(selectedSale.additional_charges));
    //     }
    // }
    return () => {
      dispatch(removeAllSearchParams());
    };
  }, []);

  useEffect(() => {
    if (billTypePreference) {
      let { sale_bill_type } = billTypePreference;
      if (sale_bill_type === SALE_BILL_TYPE.INVOICE) {
        setIsInvoice(true);
        if (saleProducts?.length) {
          dispatch(setCurrentScreen(SALE_SCREENS.TABLE));
        } else {
          dispatch(setCurrentScreen(SALE_SCREENS.SUMMARY));
        }
      } else if (sale_bill_type === SALE_BILL_TYPE.POS) {
        setIsInvoice(false);
        dispatch(setCurrentScreen(SALE_SCREENS.SUMMARY));
      }
    }
  }, [billTypePreference, saleProducts]);

  useImperativeHandle(ref, () => {
    return {
      openCustomerModalFunction: () => tilesRef.current?.openCustomer(),
      openDraftSale: () => handleOpenDrawer(),
      saveDraftSale: () => {
        if (!openClearModalRef.current) {
          saleRef.current?.saveDraft();
        }
      },
      clearAll: () => setOpenClearModal(true),
      saveSale: () => onProceedCheckout(),
      billFocus: () => billRef.current?.focus(),
      cartFocus: () => cartRef.current?.focus(),
      openAddNewProduct: () => setOpenDrawer(true),
      openAddCustomProduct: () => setOpenCustomProduct(true),
      onExpand: () => onExpandScreen(),
    };
  });

  const onProceedCheckout = () => {
    if (
      billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
      currentScreen === SALE_SCREENS.TABLE
    ) {
      dispatch(setCurrentScreen(SALE_SCREENS.SUMMARY));
    } else {
      saleRef.current?.saveCheckoutSale();
    }
  };

  const onExpandScreen = () => {
    if (
      billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
      currentScreen === SALE_SCREENS.SUMMARY
    ) {
      dispatch(setCurrentScreen(SALE_SCREENS.TABLE));
    }
  };

  function handleOpenDrawer() {
    dispatch(setSearchSaleParams({ type: SALES_TYPE.SALE, status: "DRAFT" }));
    setOpenDraftDrawer(true);
  }

  function handleClearCart() {
    dispatch(removeSelectedCustomer());
    clearCartRef.current?.clearPaymentInfo();
  }

  const handleSwitchChange = (checked) => {
    if (checked === isInvoice) return;
    setIsInvoice(checked);
    if (checked === false) {
      //handleChangeProductToPOS();
    }
    handleSaveBillTypePreference(
      checked ? SALE_BILL_TYPE.INVOICE : SALE_BILL_TYPE.POS
    );
  };

  const handleSaveBillTypePreference = async (value) => {
    localStorage.setItem("sale_bill_type", value);
    dispatch(
      setBillTypePreference({
        ...billTypePreference,
        sale_bill_type: value,
      })
    );
  };

  const handleChangeProductToPOS = () => {
    saleProducts.forEach((item, idx) => {
      dispatch(
        updateProductFromSale({
          ...item.checkProduct,
          checkProduct: item.checkProduct,
          quantity: item.quantity,
          discount: item.discount,
          discountValue: item.discountValue,
          unit: item?.alternate_unit?.base_unit
            ? item?.alternate_unit?.base_unit
            : null,
        })
      );
    });
  };

  const handleAddCustomProduct = (product) => {
    let {
      name,
      sale_price,
      price,
      quantity,
      discount,
      discountValue,
      activeDiscount,
    } = product;
    let payload = {
      name: name ? name : `Item ${cp_name_count + 1}`,
      sale_price: parseFloat(sale_price),
      price: parseFloat(price),
      purchase_price: parseFloat(sale_price) * 0.92,
      quantity: parseFloat(quantity),
      discount: parseFloat(discount),
      discountValue: parseFloat(discountValue),
      sku: `cp#${cp_count + 1}`,
      upc: "",
      is_custom_product: true,
      activeDiscount: activeDiscount ?? DiscountOption.FLAT,
    };

    dispatch(addProductToSale(payload));
    let cp_payload = {
      cp: cp_count + 1,
    };
    if (!name) cp_payload.cp_name = cp_name_count + 1;
    dispatch(setCpCount(cp_payload));
  };

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop={true}
        open={openDraftDrawer}
        onClose={() => setOpenDraftDrawer(false)}
        className="right-drawer"
      >
        <DraftDrawer
          open={openDraftDrawer}
          onCloseDrawer={() => {
            setOpenDraftDrawer(false);
            dispatch(removeAllSearchSaleParams());
          }}
          clearCartRef={clearCartRef}
          type="sale"
        />
      </Drawer>
      <CustomProduct
        open={openCustomProduct}
        onClose={() => setOpenCustomProduct(false)}
        onAddCustomProduct={(product) => handleAddCustomProduct(product)}
      />
      <S.SalesContainer>
        <ClearCart
          open={openClearModal}
          onClose={() => setOpenClearModal(false)}
          clearCartRef={clearCartRef}
          type="sale"
        />
        <S.Headbar>
          <S.HeadContainer>
            <S.SalesHeadline
              active={isInvoice === false}
              onClick={() => handleSwitchChange(false)}
            >
              POS
            </S.SalesHeadline>
            <ToggleSwitch
              type="sale_bill_type"
              checked={isInvoice}
              isTwoWay={true}
              handleChange={(e, type) => handleSwitchChange(e.target.checked)}
            />
            <S.SalesHeadline
              active={isInvoice === true}
              onClick={() => handleSwitchChange(true)}
            >
              Sale Invoice
            </S.SalesHeadline>
          </S.HeadContainer>
          <S.RightHeadBar>
            <S.CustomBtn
              color="#2D68FE"
              mr="16px"
              onClick={() => setOpenCustomProduct(true)}
            >
              Add Custom Product (F5)
            </S.CustomBtn>
            <S.CustomBtn
              color="#2D68FE"
              mr="16px"
              onClick={() => setOpenDrawer(true)}
            >
              Add New Product (F6)
            </S.CustomBtn>
            <S.CustomBtn
              color="#2D68FE"
              mr="16px"
              onClick={() => handleOpenDrawer()}
            >
              <img src={BlueDraftIcon} alt="draft" />
              Counters (F11)
            </S.CustomBtn>
            <S.CustomBtn
              color="#FF4343"
              mr="16px"
              onClick={() => setOpenClearModal(true)}
            >
              <img src={RedClearCartIcon} alt="cart" />
              Clear Cart (F9)
            </S.CustomBtn>
            <S.DatePicker>
              <S.Created>Date</S.Created>
              <DatePicker
                selected={Date.parse(
                  calendarFormatDate(createdDate ?? new Date())
                )}
                onChange={(date) => dispatch(setCreatedDate(date))}
                dateFormat="dd/MM/yyyy"
                className="sale-date-picker"
              />
            </S.DatePicker>
          </S.RightHeadBar>
        </S.Headbar>
        <S.TitleFlex>{/* <S.Title>Cart</S.Title> */}</S.TitleFlex>
        <S.CartBody>
          <Cart
            openClearModal={openClearModal}
            handleClearCart={() => handleClearCart()}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawerRef.current}
            openCustomProduct={openCustomProductRef}
          />
          {currentScreen === SALE_SCREENS.SUMMARY && (
            <Checkout
              ref={saleRef}
              billRef={billRef}
              cartRef={cartRef}
              clearCartRef={clearCartRef}
              openDrawer={openDrawerRef.current}
            />
          )}
        </S.CartBody>
      </S.SalesContainer>
    </>
  );
};

export default React.forwardRef(Sales);
