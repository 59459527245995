import React, { useEffect, useState } from "react";
import { ATTRIBUTE_MAPPING, ATTRIBUTE_VALUES, METRIC_MAPPING, METRIC_VALUES, UNITS_LIST } from "../../../constants/appConstants";
import { PlusBlueIcon, SalesCartDeleteIcon } from "../../../icons";
import CustomInput from "../custom-input/custom-input";
import CustomSelect from "../custom-select/custom-select";
import InputLabel from "../input-label/input-label";
import Label from "../label/Label";

import * as S from "./product-metrics.styles";
import CustomAutocomplete from "../../custom-autocomplete/Autocomplete";
import { getProductAttributeValues, getProductAttributes } from "../../../api/local/productApi";
import { captureException } from "../../../crash-reporting";
import AttributeValues from "./attribute-values/AttributeValues";

const ProductMetrics = ({ formData, setFormData, displayData, setDisplayData, type, selectRef }) => {
    const [attributes, setAttributes] = useState(null);

    useEffect(() => {
        handleGetAttributes();
    }, []);

    const handleGetAttributes = async () => {
        try {
            let res = await getProductAttributes();
            if (res.status === 200) {
                setAttributes(res.data);
            }
        } catch (error) {
            captureException(error);
        }
    };
    const handleGetAttributeValues = async (id) => {
        try {
            let res = await getProductAttributeValues(id);
            if (res.status === 200) {
                return res.data?.entity;
            }
        } catch (error) {
            captureException(error);
            return null;
        }
    };

    const handleSelectChange = (index, type) => (key, item) => {
        if (type === "metric") {
            const newMetricUnits = displayData.metrics.map((metric, idx) => {
                if (idx !== index) return metric;
                return { ...metric, unit: item.value };
            });

            setFormData({ ...formData, metrics: newMetricUnits });
            setDisplayData({ ...displayData, metrics: newMetricUnits });
        } else {
            const newAttributeUnits = displayData.attributes.map((attribute, idx) => {
                if (idx !== index) return attribute;
                return [item.value, attribute[1]];
            });
            // setFormData({
            //   ...formData,
            //   attributes: Object.fromEntries(newAttributeUnits),
            // });
            setDisplayData({ ...displayData, attributes: newAttributeUnits });
        }
    };

    const handleInputChange = (index, type) => (val) => {
        if (type === "metric") {
            const newMetricUnits = displayData.metrics.map((metric, idx) => {
                if (idx !== index) return metric;
                return { ...metric, value: val };
            });

            setFormData({ ...formData, metrics: newMetricUnits });
            setDisplayData({ ...displayData, metrics: newMetricUnits });
        } else if (type === "attribute-unit") {
            const newAttributeUnits = displayData.attributes.map((attribute, idx) => {
                if (idx !== index) return attribute;
                return [val, attribute[1]];
            });
            setDisplayData({ ...displayData, attributes: newAttributeUnits });
        } else if (type === "attribute-value") {
            const newAttributeValues = displayData.attributes.map((attribute, idx) => {
                if (idx !== index) return attribute;
                return [attribute[0], val];
            });
            setDisplayData({ ...displayData, attributes: newAttributeValues });
        }
    };

    const handleSelectAttribute = (index, type, value) => {
        if (type === "attribute-unit") {
            const newAttributeUnits = displayData.attributes.map((attribute, idx) => {
                if (idx !== index) return attribute;
                return [value, attribute[1]];
            });
            setDisplayData({ ...displayData, attributes: newAttributeUnits });
        } else if (type === "attribute-value") {
            const newAttributeValues = displayData.attributes.map((attribute, idx) => {
                if (idx !== index) return attribute;
                return [attribute[0], value];
            });
            setDisplayData({ ...displayData, attributes: newAttributeValues });
        }
    };

    const handleAddInput = (type) => {
        if (type === "metric") {
            const newMetricsFormData = formData.metrics ? formData.metrics.concat([{ unit: "", value: "" }]) : [{ unit: "", value: "" }];
            const newMetricsDisplayData = displayData.metrics ? displayData.metrics.concat([{ unit: "", value: "" }]) : [{ unit: "", value: "" }];
            setFormData({
                ...formData,
                metrics: newMetricsFormData,
            });
            setDisplayData({
                ...displayData,
                metrics: newMetricsDisplayData,
            });
        } else {
            const newAttributesDisplayData = Array.isArray(displayData.attributes) ? displayData.attributes.concat([["", ""]]) : [["", ""]];
            setDisplayData({
                ...displayData,
                attributes: newAttributesDisplayData,
            });
        }
    };

    const handleRemoveInput = (index, key) => {
        if (key === "metric") {
            // const filteredMetricsForm = formData.metrics.filter(
            //   (metric, idx) => index !== idx
            // );
            const filteredMetricsDisplay = displayData.metrics.filter((metric, idx) => index !== idx);
            // setFormData({
            //   ...formData,
            //   metrics: filteredMetricsForm,
            // });
            setDisplayData({
                ...displayData,
                metrics: filteredMetricsDisplay,
            });
        } else {
            const filteredAttributesDisplay = Array.isArray(displayData.attributes)
                ? displayData.attributes.filter((metric, idx) => index !== idx)
                : [];
            setDisplayData({
                ...displayData,
                attributes: filteredAttributesDisplay,
            });
        }
    };

    return (
        <S.Container>
            <S.Section>
                <S.Header>Metrics</S.Header>
                {displayData.metrics.map((metric, index) => (
                    <>
                        <S.InputContainer>
                            <S.Input>
                                <Label text={`Metric ${index + 1}`} />
                                <CustomSelect
                                    data={UNITS_LIST}
                                    type="metric"
                                    localSearch
                                    onSelectChange={handleSelectChange(index, "metric")}
                                    selectedOption={metric.unit || "Metric Unit"}
                                    selectRef={selectRef}
                                />
                            </S.Input>
                            <S.Input>
                                <Label text={`Metric Value`} />
                                <CustomInput
                                    value={metric.value}
                                    onChange={handleInputChange(index, "metric")}
                                    type="text"
                                    placeholder="Metric Value"
                                />
                            </S.Input>
                            <S.ImgDiv>
                                <img src={SalesCartDeleteIcon} alt="delete" onClick={() => handleRemoveInput(index, "metric")} />
                            </S.ImgDiv>
                        </S.InputContainer>{" "}
                    </>
                ))}
                <S.AddNew onClick={() => handleAddInput("metric")}>{/* <img src={PlusBlueIcon} alt="add" /> */}+ Add New Metric</S.AddNew>
            </S.Section>

            <S.Section>
                <S.Header>Custom Field</S.Header>
                {Array.isArray(displayData.attributes) && displayData.attributes.length ? (
                    displayData.attributes.map((attribute, index) => (
                        <React.Fragment key={index}>
                            <S.InputContainer>
                                <S.Input>
                                    <Label text={`Custom Field ${index + 1}`} />
                                    <CustomAutocomplete
                                        data={attributes}
                                        value={attribute[0]}
                                        name="attribute-unit"
                                        position={index === displayData.attributes.length - 1 ? "top" : "bottom"}
                                        onSelectOption={(name, item) => handleSelectAttribute(index, name, item)}
                                        width="100%"
                                    />
                                </S.Input>
                                <S.Input>
                                    <Label text={`Custom Field Value`} />
                                    <AttributeValues
                                        position={index === displayData.attributes.length - 1 ? "top" : "bottom"}
                                        id={attribute[0]}
                                        value={attribute[1]}
                                        handleSelectAttribute={(name, value) => handleSelectAttribute(index, name, value)}
                                    />
                                </S.Input>
                                <S.ImgDiv>
                                    <img src={SalesCartDeleteIcon} alt="delete" onClick={() => handleRemoveInput(index, "attribute")} />
                                </S.ImgDiv>
                            </S.InputContainer>
                        </React.Fragment>
                    ))
                ) : (
                    <></>
                )}
                <S.AddNew onClick={() => handleAddInput("attribute")}>{/* <img src={PlusBlueIcon} alt="add" /> */}+ Add New Custom Field</S.AddNew>
            </S.Section>
        </S.Container>
    );
};

export default ProductMetrics;
