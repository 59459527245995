import styled from "@emotion/styled/macro";

export const Container = styled.div`
    box-sizing: border-box;
    padding: 0 16px 16px;
`;
export const Section = styled.div``;

export const AccountContainer = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
`;

export const Right = styled.div`
    flex: 1;
    margin-left: 8px;
`;

export const AddNewContainer = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-top: 12px;
`;

export const AddNewAccount = styled.div``;
export const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #64748b;
    margin-bottom: 12px;
`;

export const InputContainer = styled.div`
    display: flex;
    gap: 21px;
    margin-bottom: 21px;
`;
export const Input = styled.div`
    flex: 1;
`;

export const AddNew = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #2d68fe;
    display: flex;
    align-items: center;
    margin: 12px 0;
    margin-left: ${(props) => (props.shift ? "16px" : "0")};
    cursor: pointer;

    img {
        height: 16px;
        width: 16px;
        cursor: pointer;
        margin-right: 5px;
    }
`;

export const UPIContainer = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
`;

export const UPIDetails = styled.div`
    border: 1px solid #e2e8f0;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        cursor: pointer;
    }

    .upi-id {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
    }
`;

export const BtnContainer = styled.div`
    display: flex;
    margin: 12px 0;
    gap: 12px;
`;

export const SubmitBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.save ? "#FFFFFF" : "#2D68FE")};
    background: ${(props) => (props.save ? "#3B82F6" : "#fff")};
    border-radius: 4px;
    ${(props) => props.save && "padding: 6px 22px"};
    align-items: center;
    display: flex;
    cursor: pointer;
`;
export const InputWrapper = styled.div`
    flex: 1;
`;
