import React from "react";
import { Snackbar } from "@mui/material";

import * as S from "./snackbar.styles";
import { CloseModalIcon, ErrorSnackbarIcon, SuccessSnackbarIcon } from "../../icons";

const CustomSnackbar = ({ onClose, data }) => {
    const handleCloseSnackbar = () => {
        onClose({ show: false, title: "", subtitle: "", ctaText: "" });
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={data.show}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
        >
            <S.Container error={data?.type === "error"} success={data?.type === "success"}>
                {data?.type === "success" ? (
                    <img src={SuccessSnackbarIcon} alt="green-tick" />
                ) : data?.type === "error" ? (
                    <img src={ErrorSnackbarIcon} alt="red-tick" />
                ) : (
                    <></>
                )}
                <S.Message>
                    <S.Title>{data?.title}</S.Title>
                    {data?.subtitle && (
                        <S.Subtitle>
                            {data?.subtitle}
                            <span>{data?.ctaText}</span>
                        </S.Subtitle>
                    )}
                </S.Message>
                <img onClick={handleCloseSnackbar} src={CloseModalIcon} alt="close" />
            </S.Container>
        </Snackbar>
    );
};

export default CustomSnackbar;
