import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import * as S from "./style";
import { GoogleMap, useJsApiLoader, Polygon, DrawingManager } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { getStore, updateStore } from "../../../api/local/storeApi";
import { setStore } from "../../../store/session-slice";
import { hideLoader, showLoader } from "../../../store/app-slice";
import { captureException } from "../../../crash-reporting";
import { DEFAULT_MAP_POSITION } from "../../../constants/appConstants";

const containerStyle = {
    width: "100%",
    height: "100%",
    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
};
const center = { lat: 24.886, lng: -70.268 };
const paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
];

const options = {
    fillColor: "#c3c4f2",
    fillOpacity: 0.5,
    strokeColor: "#39393c",
    strokeOpacity: 1,
    strokeWeight: 2,
    zIndex: 1,
};
const initialDisplayData = {
    paths: [],
    center: {},
};

const libraries = ["drawing"];

const lat_20km_bound = 0.144927;
const lng_20km_bound = 0.18018018;

const DeliveryArea = () => {
    const [displayData, setDisplayData] = useState(initialDisplayData);
    const [bounds, setBounds] = useState(null);
    const dispatch = useDispatch();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const store = useSelector((state) => state.session.store);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const [mapData, setMapData] = useState(null);
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    const onLoad = React.useCallback(function callback(mapData) {
        mapData.setZoom(11);
        setMapData(mapData);
    }, []);

    const onUnmount = React.useCallback(function callback(mapData) {
        setMapData(null);
    }, []);

    useEffect(() => {
        // getStoreData();
        if (store) {
            let centerCoord;
            if (store.store_address?.lat_lng) {
                centerCoord = store.store_address?.lat_lng;
            } else {
                centerCoord = Object.values(DEFAULT_MAP_POSITION);
            }
            if (centerCoord) {
                const northeast = {
                    lat: centerCoord[0] + lat_20km_bound,
                    lng: centerCoord[1] + lng_20km_bound,
                };

                const southwest = {
                    lat: centerCoord[0] - lat_20km_bound,
                    lng: centerCoord[1] - lng_20km_bound,
                };
                try {
                    setBounds(new window.google.maps.LatLngBounds(southwest, northeast));
                } catch (e) {
                    captureException(e);
                }

                if (store?.delivery_area) {
                    let polygonCoord = store.delivery_area.coordinates[0];
                    setDisplayData({
                        ...displayData,
                        paths: convertLatLng(polygonCoord),
                        center: { lat: centerCoord[0], lng: centerCoord[1] },
                    });
                } else {
                    setDisplayData({
                        ...displayData,

                        center: { lat: centerCoord[0], lng: centerCoord[1] },
                    });
                }
            }
        }
    }, []);

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            //   setPath(nextPath);
            setDisplayData({ ...displayData, paths: nextPath });
        }
    }, [setDisplayData]);

    const onPolygonLoad = useCallback(
        (polygon) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current?.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onPolygonUnmount = useCallback(() => {
        listenersRef.current?.forEach((lis) => lis.remove());
        polygonRef.current = null;
    }, []);

    const onDrawingManagerLoad = useCallback((dm) => {
        console.log("onDrawingManagerLoad");
    }, []);

    const getStoreData = async () => {
        try {
            let res = await getStore();
            if (res.status === 200) {
                let polygonCoord = res.data.delivery_area.coordinates[0];
                let centerCoord = res.data.store_address.lat_lng;

                setDisplayData({
                    ...displayData,
                    paths: convertLatLng(polygonCoord),
                    center: { lat: centerCoord[0], lng: centerCoord[1] },
                });
                console.log(res.data);
                // dispatch(setStore(res.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onMapClick = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();

        const newCoord = {
            lat: newLat,
            lng: newLng,
        };
        // console.log(displayData.paths);
        displayData.paths.splice(displayData.paths.length - 1, 0, newCoord);
        setDisplayData({ ...displayData, paths: [...displayData.paths] });
        // console.log(displayData.paths);
    };

    const convertLatLng = (arr) => {
        if (!arr || !arr.length) return;
        let newPath = arr.map((item) => {
            let lng = item[0];
            let lat = item[1];

            return { lat, lng };
        });

        return newPath;
    };

    const saveOnlineSettings = async () => {
        const payload = {};
        let newCoord;
        if (displayData.paths) {
            newCoord = displayData.paths.map((item) => {
                let newCoordArr = [item.lng, item.lat];
                return newCoordArr;
            });
        }
        if (newCoord.length > 0) {
            let deliveryArea = {};
            deliveryArea.coordinates = [newCoord];
            deliveryArea.type = "Polygon";
            payload.delivery_area = deliveryArea;
        }
        dispatch(showLoader());
        try {
            let res = await updateStore(payload);
            if (res.status === 200) {
                // setDisplayData(res.data);
                await dispatch(setStore(res.data));
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Store Saved",
                    subtitle: "Store saved successfully",
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
            handleSnackbarDetails({
                show: true,
                title: err.message,
                type: "error",
            });
        } finally {
            dispatch(hideLoader());
        }
    };

    const handlePolylineComplete = (polyline) => {
        let polylineData = polyline.getPath().getArray();
        let polygonData = polylineData.map((item) => {
            let lat = item.lat();
            let lng = item.lng();

            return { lat, lng };
        });
        setDisplayData({
            ...displayData,
            paths: polygonData,
        });
    };
    return (
        <S.Wrapper>
            <div>
                <S.TopContainer></S.TopContainer>

                <div>
                    <S.Label>Delivery Map</S.Label>
                    <S.MapContainer>
                        {isLoaded && bounds ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={displayData.center}
                                zoom={11}
                                // onLoad={onLoad}
                                // onUnmount={onUnmount}
                                options={{
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    clickableIcons: false,
                                    restriction: {
                                        latLngBounds: bounds,
                                        strictBounds: true,
                                    },
                                }}
                            >
                                {displayData.paths.length > 0 ? (
                                    <Polygon
                                        editable
                                        draggable
                                        onLoad={onPolygonLoad}
                                        paths={displayData.paths}
                                        options={options}
                                        onMouseUp={onEdit}
                                        onDragEnd={onEdit}
                                        onUnmount={onPolygonUnmount}
                                    />
                                ) : (
                                    <DrawingManager
                                        onLoad={onDrawingManagerLoad}
                                        onPolylineComplete={handlePolylineComplete}
                                        drawingMode={"polyline"}
                                        options={{
                                            drawingControl: false,
                                            polylineOptions: {
                                                strokeColor: "#000000",
                                                strokeWeight: 2,
                                                zIndex: 1,
                                            },
                                        }}
                                    />
                                )}
                                <></>
                            </GoogleMap>
                        ) : (
                            <></>
                        )}
                    </S.MapContainer>
                </div>
            </div>

            <S.BottomBar>
                <S.HintText>(Note: "Online delivery depends on this location. Please don't change it unnecessarily")</S.HintText>
                <S.SaveBtn onClick={saveOnlineSettings}>Save Changes</S.SaveBtn>
            </S.BottomBar>
        </S.Wrapper>
    );
};

export default DeliveryArea;
