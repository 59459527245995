import styled from "@emotion/styled/macro";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #455a64;
    padding: 10px 16px;
    background: #f1f5f9;
    border-radius: 0px 0px 6px 6px;

    & span {
        font-weight: 700;
        color: #000;
    }
`;
