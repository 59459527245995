import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { AddEntryPlus, ArrowRight, CalendarIcon, DarkArrowLeft } from "../../../icons";
import CustomInput from "../../product-drawer/custom-input/custom-input";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import InputLabel from "../../product-drawer/input-label/input-label";
import * as S from "./style";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { BANK_ACCOUNT_TYPES } from "../../../constants/appConstants";
import AdvanceSalaryEntry from "../AdvanceSalary/AdvanceSalaryEntry";
import Transaction from "../transactions/Transaction";
import { getAdvanceTransactions } from "../../../api/local/employee-api";

const dummy = [
    {
        amount: -5000,
        timestamp: 6548548594854880,
        reason: "refunded",
    },
    {
        amount: 10000,
        timestamp: 6548548594854874,
        reason: "hey there",
    },
    {
        amount: 100,
        timestamp: 100,
        reason: "asasasas",
    },
];

const Salary = ({ displayData, setDisplayData, setTabView, handleSubmit, employeeId }) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [transferDate, setTransferDate] = useState(null);
    const [accountType, setAccountType] = useState("BANK ACCOUNT");
    const [openAdvEntry, setOpenAdvEntry] = useState(false);
    const [openTransaction, setOpenTransaction] = useState(false);
    const [advTxn, setAdvTxn] = useState(null);

    useEffect(() => {
        if (employeeId) {
            getAdvanceTransactions(employeeId)
                .then((res) => {
                    if (res.status === 200) {
                        setAdvTxn(res.data);
                    } else {
                        console.log(res.data);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [openAdvEntry]);

    const handleInputChange = (type) => (val) => {
        if (type === "salary" || type === "account_number") {
            const regex = /^[0-9\b]+$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val });
        } else if (type === "ifsc_code") {
            const regex = /^([a-zA-Z0-9]+)$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val.toUpperCase() });
        } else if (type === "bank_name" || type === "account_name") {
            const regex = /^([a-zA-Z]+)$/;
            if (!(val === "" || regex.test(val))) return;
            setDisplayData({ ...displayData, [type]: val });
        } else {
            setDisplayData({ ...displayData, [type]: val });
        }
        if (!displayData.account_type) {
            setDisplayData({ ...displayData, account_type: "BANK_ACCOUNT" });
        }
    };

    function handleSelectChange(key, item) {
        setAccountType(item.name);
        setDisplayData({ ...displayData, account_type: item.value });
    }

    return (
        <S.Wrapper>
            <S.Body>
                <S.Heading>salary account info</S.Heading>
                <S.InputField>
                    <S.LeftDiv>
                        <div>
                            <InputLabel text="Salary" />
                            <CustomInput
                                placeholder="Enter Salary"
                                type="number"
                                value={displayData.salary}
                                onChange={handleInputChange("salary")}
                            />
                        </div>

                        {employeeId && (
                            <>
                                <div>
                                    <InputLabel text="Salary Advance Total" />
                                    <CustomInput
                                        disabled
                                        placeholder="Enter salary advance total"
                                        type="text"
                                        value={advTxn ? advTxn.amount : 0}
                                    />
                                </div>
                                <S.AdvSalaryBtns>
                                    <S.AddEntryDiv onClick={() => setOpenAdvEntry(!openAdvEntry)}>
                                        <img src={AddEntryPlus} alt="add entry" />
                                        Add Entry
                                    </S.AddEntryDiv>
                                    {/* <S.ViewEntryBtn
                                        onClick={() => {
                                            setOpenTransaction(!openTransaction);
                                        }}
                                    >
                                        {openTransaction ? "Close Entries" : "View Entries"}
                                    </S.ViewEntryBtn> */}
                                </S.AdvSalaryBtns>
                            </>
                        )}

                        {openAdvEntry && (
                            <AdvanceSalaryEntry
                                setOpenAdvEntry={setOpenAdvEntry}
                                employeeId={employeeId}
                            />
                        )}
                    </S.LeftDiv>
                    <Transaction
                        data={advTxn?.transactions}
                        displayData={displayData}
                        advTxn={advTxn}
                    />
                </S.InputField>
            </S.Body>
            <S.Footer>
                {employeeId ? (
                    <div>
                        <InputLabel text="Net Salary" />
                        <CustomInput
                            disabled
                            type="text"
                            value={
                                advTxn && displayData.salary_detail?.monthly_salary
                                    ? displayData.salary_detail?.monthly_salary -
                                      (advTxn.amount || 0)
                                    : displayData.salary_detail?.monthly_salary && !advTxn
                                    ? displayData.salary_detail?.monthly_salary
                                    : advTxn?.amount
                            }
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                <S.FooterBtnContainer>
                    {/* <S.NextBtn>
                        <img src={DarkArrowLeft} alt="" />
                    </S.NextBtn> */}
                    <S.FooterBtns>
                        <S.SaveBtn onClick={handleSubmit}>Save</S.SaveBtn>
                        {/* <S.NextBtn onClick={() => setTabView(4)}>
                        <div>Next</div>
                        <img src={ArrowRight} alt="" />
                    </S.NextBtn> */}
                    </S.FooterBtns>
                </S.FooterBtnContainer>
            </S.Footer>
        </S.Wrapper>
    );
};

export default Salary;
