import React from "react";
import { TailSpin } from "react-loader-spinner";
import * as S from "./style";
import { Modal } from "@mui/material";
import { DigiDukandarLogo } from "../../icons";

const Loader = () => {
    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <S.Wrapper>
                <TailSpin
                    height="80"
                    width="80"
                    color="#e86c00"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
                <S.Logo src={DigiDukandarLogo} alt="" />
            </S.Wrapper>
        </Modal>
    );
};

export default Loader;
