import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 8px 24px 8px 16px;
`;

export const DataDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 45%;
`;
export const DarkText = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #000;
`;
export const GreyText = styled.div`
    font-weight: 400;
    font-size: ${(props) => props.fs && props.fs};
    line-height: 22px;
    color: #475569;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Content = styled.div`
    height: 56px;
    width: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const Unit = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #94a3b8;
`;
export const Qty = styled.span`
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #475569;
`;
export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 56px;
    height: 56px;

    & img {
        width: 54px !important;
        height: 54px !important;
    }
`;
