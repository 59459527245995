import React, { useEffect, useState } from "react";
import * as S from "./create-purchase-item.styles";
import { ArrowDrop, ArrowUp, MoreInfoIcon, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../../../../icons";
import OutsideClickHandler from "react-outside-click-handler";
import { toDecimalPlace } from "../../../../../utils/global-fn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../../../../utils/format-date";

const CreatePurchaseItem = ({
    product,
    openReturn,
    handleCheck,
    handleIncrementQuantity,
    handleDecrementQuantity,
    handleReturnInputChange,
    handleUpdateProduct,
    idx,
    activePurchaseRow,
    setActivePurchaseRow,
    moreInfoRefs,
    quantityRefs,
    freeQtyPresent,
}) => {
    const [imgError, setImgError] = useState(null);
    const [displayData, setDisplayData] = useState(product);
    const [openInfo, setOpenInfo] = useState(false);

    useEffect(() => {
        setDisplayData(product || null);
    }, [product]);

    const handleRowFocus = (idx) => {
        if (openReturn) {
            setActivePurchaseRow(idx);
            quantityRefs.current[idx].focus();
        }
    };

    const onInputClick = (e) => {
        e.stopPropagation();
        setActivePurchaseRow(idx);
    };

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (name === "freeQuantity") {
            if (value > displayData.free_quantity) value = displayData.free_quantity;
        }
        handleUpdateProduct({ ...displayData, [name]: value });
    };

    const handleDateChange = (key, date) => {
        handleUpdateProduct({ ...displayData, [key]: date });
    };
    return (
        <S.TableRow key={idx} active={idx === activePurchaseRow && openReturn} onClick={() => handleRowFocus(idx)}>
            {openReturn && (
                <S.TableBodyData flexValue="0.3">
                    <S.Check type="checkbox" name={displayData?.sku} onChange={handleCheck} checked={displayData?.editable || false} />
                </S.TableBodyData>
            )}
            <S.TableBodyData flexValue="1">
                {imgError || !displayData?.gallery || !displayData?.gallery.length || !displayData?.gallery?.[0] ? (
                    <S.ImagePlaceholder>
                        <img
                            src={ProductPlaceholderIcon}
                            alt="product"
                            onError={(e) => {
                                e.target.src = WifiDisconnectedIcon;
                            }}
                        />
                    </S.ImagePlaceholder>
                ) : (
                    <S.ProductImage src={displayData?.gallery?.[0]} onError={() => setImgError(true)} alt="product" />
                )}
                <S.ProductDetails>
                    <S.Brand>{displayData?.brand?.name}</S.Brand>
                    <S.Name>{displayData?.name}</S.Name>
                    <S.Metrics>{toDecimalPlace(displayData.stock)} in stock</S.Metrics>
                </S.ProductDetails>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.5">
                <S.InputContainer>
                    <S.FlexBox>
                        {toDecimalPlace(displayData?.price) !== toDecimalPlace(displayData?.sale_price) && (
                            <S.Striked>₱{toDecimalPlace(displayData?.price)}</S.Striked>
                        )}
                        <S.InputDiv>₱{toDecimalPlace(displayData.sale_price)}</S.InputDiv>
                    </S.FlexBox>
                    <S.SubText>({displayData.sale_price_with_tax === false ? "Without Tax" : "With Tax"})</S.SubText>
                </S.InputContainer>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.5">
                <S.InputContainer>
                    <S.InputDiv>
                        <S.FlexBox>
                            {displayData.quantity} {displayData.free_quantity ? `+ ${displayData.free_quantity}` : ""}
                        </S.FlexBox>
                        {displayData.unit && <S.SubText>({displayData.unit})</S.SubText>}
                    </S.InputDiv>
                </S.InputContainer>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.6">
                <S.InputContainer>
                    <S.InputDiv>
                        ₱{toDecimalPlace(displayData.purchase_price)}
                        <S.SubText>({displayData.purchase_price_with_tax === false ? "Without Tax" : "With Tax"})</S.SubText>
                    </S.InputDiv>
                </S.InputContainer>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.6">
                <S.InputContainer>
                    <S.InputDiv>₱{toDecimalPlace(displayData.discount)}</S.InputDiv>
                </S.InputContainer>
            </S.TableBodyData>
            <S.TableBodyData flexValue="0.6">
                <S.InputContainer>
                    <S.InputDiv>₱{displayData.tax && toDecimalPlace(displayData.tax)}</S.InputDiv>
                </S.InputContainer>
            </S.TableBodyData>
            {!openReturn && (
                <S.TableBodyData flexValue="0.5">
                    <S.InputContainer>
                        <S.InputDiv>₱{toDecimalPlace(displayData.net_purchase_price)}</S.InputDiv>
                    </S.InputContainer>
                </S.TableBodyData>
            )}

            {openReturn && freeQtyPresent && (
                <S.TableBodyData flexValue="0.7">
                    <S.QuantityBox>
                        <S.ReturnInput
                            type="text"
                            name="freeQuantity"
                            value={parseFloat(displayData.freeQuantity || 0)}
                            onChange={handleInputChange}
                            onClick={onInputClick}
                        />
                    </S.QuantityBox>
                </S.TableBodyData>
            )}
            {openReturn && (
                <S.TableBodyData flexValue="0.7">
                    <S.QuantityBox>
                        <S.ReturnInput
                            type="text"
                            value={displayData.returnQuantity ? displayData.returnQuantity : 0}
                            onChange={(e) => handleReturnInputChange(e.target.value, displayData)}
                            ref={(el) => (quantityRefs.current[idx] = el)}
                        />
                        <S.ArrowBox>
                            <img src={ArrowUp} alt="arrow up" onClick={() => handleIncrementQuantity(displayData)} />
                            <img src={ArrowDrop} alt="arrow drop" onClick={() => handleDecrementQuantity(displayData)} />
                        </S.ArrowBox>
                    </S.QuantityBox>
                </S.TableBodyData>
            )}
            <S.TableBodyData flexValue="0.25">
                {" "}
                <img src={MoreInfoIcon} alt="info" onClick={() => setOpenInfo(!openInfo)} ref={(el) => (moreInfoRefs.current[idx] = el)} />
            </S.TableBodyData>
            <OutsideClickHandler onOutsideClick={() => setOpenInfo(false)}>
                {openInfo && (
                    <MoreInfo
                        displayData={displayData}
                        openReturn={openReturn}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        onInputClick={onInputClick}
                    />
                )}
            </OutsideClickHandler>
        </S.TableRow>
    );
};

export default CreatePurchaseItem;

export const MoreInfo = ({ displayData, openReturn, handleInputChange, handleDateChange, onInputClick }) => {
    return (
        <S.MoreInfoWrapper openReturn={openReturn}>
            {!openReturn && (
                <S.OptionDiv>
                    <S.OptionHeader>Bill Scheme</S.OptionHeader>
                    <S.OptionInputContainer>
                        <S.Input disabled type="text" value={displayData.free_quantity} />
                    </S.OptionInputContainer>
                </S.OptionDiv>
            )}
            <S.OptionDiv>
                <S.OptionHeader>Batch Number</S.OptionHeader>
                <S.OptionInputContainer>
                    <S.Input
                        disabled={!openReturn}
                        type="text"
                        value={displayData.batch_number}
                        name="batch_number"
                        onChange={handleInputChange}
                        onClick={onInputClick}
                    />
                </S.OptionInputContainer>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Mfg Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.manufacturing_date ?? null))}
                        onChange={(date) => handleDateChange("manufacturing_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        maxDate={displayData.created_at || new Date()}
                        showMonthDropdown
                        disabled={!openReturn}
                    />
                </div>
            </S.OptionDiv>
            <S.OptionDiv>
                <S.OptionHeader>Expiry Date</S.OptionHeader>
                <div>
                    <DatePicker
                        selected={Date.parse(calendarFormatDate(displayData.expiry_date ?? null))}
                        onChange={(date) => handleDateChange("expiry_date", date)}
                        dateFormat="dd/MM/yyyy"
                        className="date-picker"
                        minDate={displayData.manufacturing_date || new Date()}
                        showMonthDropdown
                        disabled={!openReturn}
                    />
                </div>
            </S.OptionDiv>
        </S.MoreInfoWrapper>
    );
};
