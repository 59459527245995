import React, { useEffect, useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "./Dots";
import "./slider.css";

function Slider({ carouselData }) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeIndex === (carouselData.length - 1) ? 0 : activeIndex + 1);
        }, 3000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    return (
        <div className="slider-container">
            <SliderContent
                activeIndex={activeIndex}
                sliderImage={carouselData}
            />
            <Dots
                activeIndex={activeIndex}
                sliderImage={carouselData}
                onclick={(activeIndex) => setActiveIndex(activeIndex)}
            />
        </div>
    );
}

export default Slider;
