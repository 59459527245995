import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: #253238;
`;
export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #253238;
    box-sizing: border-box;
    padding: 36px 48px 20px;
    height: 71px;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffde2c;
    gap: 9px;
    margin: 10px 0 0 -10px;
`;
export const ContactSupportBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 188px;
    height: 46px;
    border: 1px solid #ffde2c;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
`;
export const HeaderContainer = styled.div`
    font-weight: 700;
    font-size: 48px;
    line-height: 139%;
    color: #ffffff;
    background-color: #253238;
    box-sizing: border-box;
    padding: 16px 48px;
    display: flex;
    gap: 4px;
    margin-top: 16px;
`;

export const Body = styled.div`
    width: 100%;
    height: calc(100vh - 200px);
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #253238;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px 48px 20px;
`;

export const BodyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 280px);
`;
export const AnimationDiv = styled.div`
    width: 100%;
    border-radius: 48px;
`;

export const LeftContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
export const RightContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Bottombar = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const TextLoader = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Tick = styled.img`
    width: 32px;
    height: 32px;
`;
export const TextRow = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
export const Text = styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #ffffff;
    margin-top: -2px;
`;
