import React from "react";
import * as S from "./style";
import { useEffect } from "react";
import { useState } from "react";
import { UNITS_LIST } from "../../../constants/appConstants";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import { useSelector } from "react-redux";

const AlternateUnit = ({ item, onChange }) => {
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState("");

    useEffect(() => {
        if (item?.enable_alternate_unit) {
            const base = UNITS_LIST.findIndex((option) => option.value === item.alternate_unit?.base_unit);
            const secondary = UNITS_LIST.findIndex((option) => option.value === item.alternate_unit?.secondary_unit);
            if (base >= 0 && secondary >= 0) {
                let altUnits = [UNITS_LIST[base], UNITS_LIST[secondary]];
                setUnits(altUnits);
                if (item.unit) {
                    const unit = UNITS_LIST.findIndex((option) => option.value === item.unit);
                    if (unit >= 0) {
                        setSelectedUnit(UNITS_LIST[unit]?.name);
                    } else {
                        setSelectedUnit(UNITS_LIST[base]?.name);
                    }
                }
            }
        }
    }, [item]);

    const handleSelectChange = (key, item) => {
        if (key === "alternate_unit") {
            setSelectedUnit(item?.name);
            onChange(item?.value);
        }
    };

    return (
        <S.Wrapper>
            <CustomSelect
                type="alternate_unit"
                disableSearch
                data={units}
                onSelectChange={handleSelectChange}
                selectedOption={selectedUnit || "None"}
                size="sm"
            />
        </S.Wrapper>
    );
};

export default AlternateUnit;
