import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { DiscountOption, SALES_TYPE, SALE_SCREENS } from "../constants/appConstants";
import { changeString, getSaleObjectHash, getSaleProductSubTotal, getTax, getTaxValue, getUnit, toDecimalPlace } from "../utils/global-fn";

const initialState = {
    PI_type: SALES_TYPE.PROFORMA_INVOICE,
    PI_currentFocus: 0,
    PI_products: [],
    PI_quantity: 0,
    PI_total: 0,
    PI_cartTotal: 0,
    PI_taxTotal: 0,
    PI_globalDiscount: 0,
    PI_discountValue: 0,
    PI_customer: "",
    PI_billNo: "",
    PI_createdDate: null,
    PI_selectDiscount: {
        type: DiscountOption.PERCENTAGE,
        cost: 0,
    },
    PI_discountType: {
        First: DiscountOption.FLAT,
        Percentage: false,
        Flat: true,
    },
    PI_currentScreen: SALE_SCREENS.SUMMARY,
    PI_additional_charges: [{ field: "", value: "" }],
    PI_totalAdditionalCharges: 0,
    PI_cp_count: 0,
    PI_cp_name_count: 0,
    selectedPI: "",
    PI_id: "",
};

const proformaInvoiceSlice = createSlice({
    name: "proformaInvoice",
    initialState,
    reducers: {
        addProductsToPI: (state, action) => {
            state.PI_products = action.payload;
            state.PI_currentFocus = 0;
        },
        addProductToPI: (state, action) => {
            const itemIndex = state.PI_products.findIndex((item) => item.sku === action.payload.sku);
            if (itemIndex >= 0) {
                state.PI_products[itemIndex].quantity = parseFloat(state.PI_products[itemIndex].quantity) + 1;
                state.PI_currentFocus = itemIndex;
            } else {
                state.PI_products.push({
                    ...action.payload,
                    sale_price: toDecimalPlace(action.payload.sale_price),
                    quantity: action.payload.quantity ? action.payload.quantity : 1,
                    discount: action.payload.discount ? action.payload.discount : 0,
                    discountValue: action.payload.discountValue ? action.payload.discountValue : 0,
                    activeDiscount: action.payload.activeDiscount ? action.payload.activeDiscount : DiscountOption.FLAT,
                    unit: getUnit(action.payload),
                    manufacturing_date: action.payload.manufacturing_date ? new Date(action.payload.manufacturing_date) : null,
                    expiry_date: action.payload.expiry_date ? new Date(action.payload.expiry_date) : null,
                    checkProduct: {
                        ...action.payload,
                        sale_price: toDecimalPlace(action.payload.sale_price),
                        hashKey: getSaleObjectHash(action.payload),
                    },
                });
                state.PI_currentFocus = state.PI_products.length - 1;
            }
        },
        removeProductFromPI: (state, action) => {
            const idx = state.PI_products.findIndex((item) => item.sku === action.payload.sku);
            if (idx >= 0) {
                let PI_newProducts = state.PI_products.filter((item) => item.sku !== action.payload.sku);
                if (action.payload.sku.includes("cp#") && !action.payload.upc) {
                    if (PI_newProducts.some((item) => item.sku.includes("cp#") && !item.upc)) {
                        PI_newProducts = PI_newProducts.map((item, index) => {
                            if (item.sku.includes("cp#") && item.name.includes("Item ") && !item.upc && index >= idx) {
                                state.PI_cp_count = state.PI_cp_count - 1;
                                state.PI_cp_name_count = state.PI_cp_name_count - 1;
                                return { ...item, sku: changeString(item.sku, "sku"), name: changeString(item.name, "name") };
                            } else if (item.sku.includes("cp#") && !item.upc && index >= idx) {
                                state.PI_cp_count = state.PI_cp_count - 1;
                                return { ...item, sku: changeString(item.sku, "sku") };
                            } else {
                                return item;
                            }
                        });
                    }
                }
                state.PI_products = PI_newProducts;

                // for current focus row
                if (idx >= PI_newProducts.length - 1) {
                    state.PI_currentFocus = PI_newProducts.length - 1;
                }

                // for discount clear
                state.PI_selectDiscount = {
                    type: DiscountOption.PERCENTAGE,
                    cost: 0,
                };
            }
        },
        decrementQuantityInPI: (state, action) => {
            const itemIndex = state.PI_products.findIndex((item) => item.sku === action.payload.sku);
            if (state.PI_products[itemIndex].quantity > state.PI_products[itemIndex].min_quantity) {
                state.PI_products[itemIndex].quantity = parseFloat(state.PI_products[itemIndex].quantity) - 1;
            } else if (
                state.PI_products[itemIndex].quantity > 1 &&
                state.PI_products[itemIndex].quantity === state.PI_products[itemIndex].min_quantity
            ) {
                toast.error("Minimum quantity reached", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
        },
        updateProductFromPI: (state, action) => {
            const PI_newProducts = state.PI_products.map((item) => {
                if (item.sku === action.payload.sku) {
                    if (action.payload.checkProduct) {
                        // for general slice product update
                        return { ...item, ...action.payload };
                    } else {
                        // update slice product after fetched from db
                        let newHash = getSaleObjectHash(action.payload);
                        if (item.checkProduct?.hashKey === newHash) {
                            let updatedCheckProduct = { ...item.checkProduct, stock: action.payload.stock };
                            return { ...item, name: action.payload.name, checkProduct: updatedCheckProduct };
                        } else {
                            let updatedCheckProduct = { ...action.payload, sale_price: toDecimalPlace(action.payload.sale_price), hashKey: newHash };
                            return {
                                ...item,
                                ...action.payload,
                                sale_price: toDecimalPlace(action.payload.sale_price),
                                unit: getUnit(action.payload),
                                checkProduct: updatedCheckProduct,
                                discountValue: 0,
                                discount: 0,
                                activeDiscount: DiscountOption.FLAT,
                            };
                        }
                    }
                } else {
                    return item;
                }
            });
            state.PI_products = PI_newProducts;
        },
        getTotalsFromPI: (state, action) => {
            let { total, quantity, tax, discountValue } = state.PI_products.reduce(
                (PI_total, PI_item) => {
                    const { sale_price, quantity, discountValue, sale_price_with_tax } = PI_item;
                    let tax = getTax(PI_item);
                    let qty = parseFloat(quantity);
                    let salePrice = parseFloat(sale_price);
                    // let itemTotal = getSalePrice(PI_item, false);
                    let { itemTotal, taxTotal } = getSaleProductSubTotal(PI_item);
                    let PI_taxTotal = 0;
                    if (qty > 0) {
                        // PI_taxTotal = ((salePrice * qty - discountValue) * tax) / 100;
                        // let value = salePrice * qty - discountValue;
                        // PI_taxTotal = getTaxValue(value, tax, sale_price_with_tax);
                        PI_taxTotal = taxTotal;
                    }
                    PI_total.total += itemTotal;
                    PI_total.quantity += parseFloat(quantity);
                    PI_total.tax += PI_taxTotal;
                    PI_total.discountValue += discountValue;// * qty;

                    return PI_total;
                },
                {
                    total: 0,
                    quantity: 0,
                    tax: 0,
                    discountValue: 0,
                }
            );
            total = parseFloat(total.toFixed(2));
            state.PI_quantity = quantity;
            state.PI_total = total;
            state.PI_taxTotal = tax;
            state.PI_discountValue = discountValue;
            let totalCharges = 0;
            if (state.PI_additional_charges.length) {
                let ac = JSON.parse(JSON.stringify(state.PI_additional_charges));
                totalCharges = ac.filter((item) => item.value).reduce((a, b) => parseFloat(a) + parseFloat(b.value), 0);
                state.PI_totalAdditionalCharges = parseFloat(totalCharges);
            } else {
                state.PI_totalAdditionalCharges = 0;
            }

            let globalDisc =
                state.PI_selectDiscount.type === DiscountOption.PERCENTAGE
                    ? total * (parseFloat(state.PI_selectDiscount.cost || 0) / 100)
                    : parseFloat(state.PI_selectDiscount.cost || 0);

            state.PI_globalDiscount = toDecimalPlace(globalDisc);
            state.PI_cartTotal = toDecimalPlace(total - globalDisc + totalCharges);
        },
        removeAllProductsPI: (state) => {
            state.PI_products = [];
            state.PI_cp_count = 0;
            state.PI_cp_name_count = 0;
        },

        //For api sales
        setSelectedPI: (state, action) => {
            state.selectedPI = action.payload;
        },
        removeSelectedPI: (state) => {
            state.selectedPI = "";
        },
        setPI_ID: (state, action) => {
            state.PI_id = action.payload;
        },
        removePI_ID: (state) => {
            state.PI_id = "";
        },
        setCustomerPI: (state, action) => {
            state.PI_customer = action.payload;
        },
        removeCustomerPI: (state) => {
            state.PI_customer = "";
        },
        setBillNoPI: (state, action) => {
            state.PI_billNo = action.payload;
        },
        setCreatedDatePI: (state, action) => {
            state.PI_createdDate = action.payload;
        },
        setSelectDiscountPI: (state, action) => {
            state.PI_selectDiscount = action.payload;
        },
        setCurrentScreenPI: (state, action) => {
            state.PI_currentScreen = action.payload;
        },
        setAdditionalChargesPI: (state, action) => {
            state.PI_additional_charges = action.payload;
        },
        resetAdditionalChargesPI: (state) => {
            state.PI_additional_charges = [{ field: "", value: "" }];
        },
        setCpCountPI: (state, action) => {
            state.PI_cp_count = action.payload.cp;
            if (action.payload.cp_name) state.PI_cp_name_count = action.payload.cp_name;
        },
        setTypePI: (state, action) => {
            state.PI_type = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase("LOGOUT", (state) => {
            Object.assign(state, initialState);
        });
    },
});

export default proformaInvoiceSlice.reducer;
export const {
    addProductsToPI,
    addProductToPI,
    removeProductFromPI,
    decrementQuantityInPI,
    getTotalsFromPI,
    removeAllProductsPI,
    updateProductFromPI,
    setCustomerPI,
    removeCustomerPI,
    setBillNoPI,
    setCreatedDatePI,
    setSelectDiscountPI,
    setCurrentScreenPI,
    setAdditionalChargesPI,
    resetAdditionalChargesPI,
    setCpCountPI,
    setSelectedPI,
    removeSelectedPI,
    setPI_ID,
    removePI_ID,
    setTypePI,
} = proformaInvoiceSlice.actions;
