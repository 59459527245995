import styled from "@emotion/styled/macro";

export const Container = styled.div`
    height: calc(100vh-56px);
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
`;
export const Header = styled.div``;

export const DateContainer = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

export const Date = styled.div`
    margin-right: 6px;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1e293b;
`;

export const BtnContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const ButtonBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
`;

export const Body = styled.div`
    height: calc(100vh - 174px);
    display: flex;
    gap: 16px;
    box-sizing: border-box;
`;
