import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 706px;
    height: 404px;
`;

export const Header = styled.div`
    height: 52px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #334155;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 15px;
    border-bottom: 1px solid #e2e8f0;

    img {
        cursor: pointer;
        width: 16px;
        height: 16px;
    }
`;

export const Body = styled.div`
    flex: 1;
    padding: 16px 16px 0;
`;
export const TableBody = styled.div`
    height: 237px;
    overflow: hidden;
    overflow-y: auto;
`;
export const TableDataRow = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px 0px;
    border-radius: 4px;
    background-color: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    margin-bottom: 4px;
    :hover {
        background-color: #e6efff;
    }
`;

export const Hr = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e6ebf6;
`;

export const TableHeadRow = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;
    margin-right: 7px;
    padding-bottom: 14px;
`;

export const TableHead = styled.div`
    flex: ${(props) => props.flexValue};
    text-align: ${(props) => props.center && "center"};
`;

export const TableDataDetails = styled.div`
    display: flex;
    align-items: center;
    flex: 1.9;
    box-sizing: border-box;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    img {
        height: 54px;
        width: 54px;
        margin-right: 6px;
        border: 1px solid #e2e8f0;
        background-color: #fff;
        border-radius: 3px;
    }
`;

export const ItemDetailsBrand = styled.div`
    ont-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    margin-bottom: 2px;
`;

export const ItemDetailsName = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
`;

export const ItemDetailsQuantity = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94a3b8;
`;

export const TableDataSKU = styled.div`
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    text-align: center;
    padding: 8px 0;
`;

export const TableDataPrice = styled.div`
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    text-align: center;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    & > div {
        font-size: 12px;
        text-decoration: line-through;
        color: #64748b;
    }
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 40px 14px 22px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 8px 8px;
    // margin-top: 20px;
`;
export const BlueBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #2d68fe;
    border-radius: 4px;
    padding: 8px 9px;
    cursor: pointer;
`;
export const RedBtn = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    background: #d80027;
    border-radius: 4px;
    padding: 8px 9px;
    cursor: pointer;
`;
export const SkuCount = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #253238;
`;
export const DeleteBtn = styled.div`
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
`;
