import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 600px;
    height: 92.5%;
    height: calc(100vh - 56px);
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    overflow: scroll;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e8f0;
    box-sizing: border-box;
    margin: 21px 18px 0;
    padding-bottom: 10px;

    & img {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }
`;

export const Heading = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #475569;
`;
export const SaleRow = styled.div`
    box-sizing: border-box;
    padding: 11px 15px 9px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    border-radius: 8px;
    margin: 8px 4px;
    :hover {
        background-color: #f1f5f9;
    }
`;

export const Hr = styled.div`
    border-bottom: 1px solid #e2e8f0;
    margin: 0 16px;
`;
export const SaleTopbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SaleId = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #64748b;
`;
export const SaleDate = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #334155;
`;
export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
`;
export const CustomerName = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #334155;
`;
export const CustomerPhone = styled.div`
    font-weight: 400;
    font-size: 13.5px;
    line-height: 16px;
    color: #64748b;
`;
export const SaleQuantity = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2d68fe;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Price = styled.div`
    font-weight: 400;
    font-size: 13.5px;
    line-height: 16px;
    color: #94a3b8;
    text-decoration: line-through;
`;
export const SalePrice = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #334155;
`;
export const BottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
export const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 60%;
    overflow-x: scroll;
`;
export const ImageDiv = styled.div`
    width: 60px;
    height: 60px;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    & img {
        width: 60px;
        height: 60px;
    }
`;

export const ViewOrder = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2d68fe;
    border-radius: 4px;
    padding: 10px;

    & img {
        width: 18px;
        height: 18px;
    }
`;
export const Time = styled.div`
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #64748b;
    font-style: italic;
`;

export const FlexConteiner = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
`;
export const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92%;
`;

export const BtnContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const DeleteBtn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #d80027;
    cursor: pointer;
`;
