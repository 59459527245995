import React from "react";
import * as S from "./style";
import { EmptyCartLogo } from "../../icons";

const EmptyCart = () => {
    return (
        <S.EmptyContainer>
            <img src={EmptyCartLogo} alt="empty cart logo" />
            <div>Search or Scan Products to add</div>
        </S.EmptyContainer>
    );
};

export default EmptyCart;
