import React, { useEffect } from "react";
import { useState } from "react";
import Account from "../../components/Settings/account/Account";
import Backup from "../../components/Settings/backup/Backup";
import Configuration from "../../components/Settings/configuration/Configuration";
import Location from "../../components/Settings/location/Location";
import QRCode from "../../components/Settings/qr-code/QRCode";
import Security from "../../components/Settings/security/Security";
import ShortcutKeys from "../../components/Settings/shortcut-keys/ShortcutKeys";
import { DEMO_STORE, SETTINGS_TAB } from "../../constants/appConstants";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "../../api/local/storeApi";
import { setStore } from "../../store/session-slice";
import Delivery from "../../components/Settings/delivery/Delivery";
import { captureException } from "../../crash-reporting";
import Loyalty from "../../components/Settings/loyalty/Loyalty";
import PrinterSettings from "../../components/Settings/printer-settings/PrinterSettings";
import Preferences from "../../components/Settings/preferences/Preferences";

const Index = () => {
    const [activeTab, setActiveTab] = useState(SETTINGS_TAB.ACCOUNT);
    const dispatch = useDispatch();

    useEffect(() => {
        getStoreData();
    }, []);

    const getStoreData = async () => {
        try {
            let res = await getStore();
            if (res.status === 200) {
                if (process.env.REACT_APP_DEMO_SERVER) {
                    dispatch(setStore(DEMO_STORE.store));
                } else {
                    dispatch(setStore(res.data));
                }
            } else {
                throw new Error(res.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    return (
        <S.Wrapper>
            <S.Header>
                <S.Heading>Store Setting</S.Heading>
            </S.Header>
            <S.Body>
                <S.Tabs>
                    <S.Tab active={activeTab === SETTINGS_TAB.ACCOUNT} onClick={() => setActiveTab(SETTINGS_TAB.ACCOUNT)}>
                        {SETTINGS_TAB.ACCOUNT}
                    </S.Tab>
                    {/* <S.Tab
                        active={activeTab === SETTINGS_TAB.SHORTCUT_KEYS}
                        onClick={() => setActiveTab(SETTINGS_TAB.SHORTCUT_KEYS)}
                    >
                        {SETTINGS_TAB.SHORTCUT_KEYS}
                    </S.Tab> */}
                    {/* <S.Tab
                        active={activeTab === SETTINGS_TAB.BACKUP}
                        onClick={() => setActiveTab(SETTINGS_TAB.BACKUP)}
                    >
                        {SETTINGS_TAB.BACKUP}
                    </S.Tab> */}
                    {/* <S.Tab
                        active={activeTab === SETTINGS_TAB.SECURITY}
                        onClick={() => setActiveTab(SETTINGS_TAB.SECURITY)}
                    >
                        {SETTINGS_TAB.SECURITY}
                    </S.Tab> */}

                    <S.Tab active={activeTab === SETTINGS_TAB.LOCATION} onClick={() => setActiveTab(SETTINGS_TAB.LOCATION)}>
                        {SETTINGS_TAB.LOCATION}
                    </S.Tab>
                    <S.Tab active={activeTab === SETTINGS_TAB.CONFIGURATION} onClick={() => setActiveTab(SETTINGS_TAB.CONFIGURATION)}>
                        {SETTINGS_TAB.CONFIGURATION}
                    </S.Tab>
                    <S.Tab active={activeTab === SETTINGS_TAB.QR_CODE} onClick={() => setActiveTab(SETTINGS_TAB.QR_CODE)}>
                        {SETTINGS_TAB.QR_CODE}
                    </S.Tab>

                    <S.Tab active={activeTab === SETTINGS_TAB.PRINTER_SETTINGS} onClick={() => setActiveTab(SETTINGS_TAB.PRINTER_SETTINGS)}>
                        {SETTINGS_TAB.PRINTER_SETTINGS}
                    </S.Tab>
                    <S.Tab active={activeTab === SETTINGS_TAB.PREFERENCES} onClick={() => setActiveTab(SETTINGS_TAB.PREFERENCES)}>
                        {SETTINGS_TAB.PREFERENCES}
                    </S.Tab>

                    {/* <S.Tab
                        active={activeTab === SETTINGS_TAB.QR_CODE}
                        onClick={() => setActiveTab(SETTINGS_TAB.QR_CODE)}
                    >
                        {SETTINGS_TAB.QR_CODE}
                    </S.Tab> */}
                </S.Tabs>
                {activeTab === SETTINGS_TAB.ACCOUNT && <Account />}
                {activeTab === SETTINGS_TAB.SHORTCUT_KEYS && <ShortcutKeys />}
                {activeTab === SETTINGS_TAB.BACKUP && <Backup />}
                {activeTab === SETTINGS_TAB.SECURITY && <Security />}
                {activeTab === SETTINGS_TAB.QR_CODE && <QRCode />}
                {activeTab === SETTINGS_TAB.LOCATION && <Location />}
                {activeTab === SETTINGS_TAB.CONFIGURATION && <Configuration />}
                {activeTab === SETTINGS_TAB.PRINTER_SETTINGS && <PrinterSettings />}
                {activeTab === SETTINGS_TAB.PREFERENCES && <Preferences />}
            </S.Body>
        </S.Wrapper>
    );
};

export default Index;
