import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 9px 10px 9px 9px;
    background: #2d68fe;
    border-radius: 6px;
    cursor: pointer;

    & > img {
        width: 16px;
        height: 16px;
    }
`;
export const Text = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2d68fe;
    display: flex;
    align-items: center;
    justify-content: center;
`;
