import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 20px;
`;

export const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin-bottom: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 15px;
    & span {
        color: red;
        margin: -3px 0 0 2px;
    }
`;

export const Copywrite = styled.div`
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #a6a6a6;
    font-style: italic;
    width: 90%;
`;
export const CheckBoxDiv = styled.div`
    margin-top: 20px;
    & > div {
        display: flex;
        // justify-content: space-between;
        gap: 40px;
        margin-bottom: 10px;
    }
`;
export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;
export const NextBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #253238;
    border: 1px solid #253238;
    border-radius: 4px;
`;
export const SaveBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    cursor: pointer;
`;
