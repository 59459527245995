import React, { useState } from "react";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { DiscountOption } from "../../constants/appConstants";
import { CloseModalIcon, GreenCheckIcon } from "../../icons";
import { Modal } from "@mui/material";
import onInputChange from "../../utils/handleInputChange";
import { useEffect } from "react";
import { useContext } from "react";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useRef } from "react";
import { addProductToSale, setCpCount } from "../../store/sale-slice";

const initialProductData = {
  name: "",
  sale_price: "",
  price: "",
  quantity: "",
  discount: "",
  discountValue: "",
  activeDiscount: null,
};

const CustomProduct = ({ open, onClose, onAddCustomProduct }) => {
  const [product, _setProduct] = useState(initialProductData);
  const productRef = useRef(product);
  const setProduct = (data) => {
    productRef.current = data;
    _setProduct(data);
  };
  // const [itemTotal, setItemTotal] = useState(0);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const qtyRef = useRef(null);

  useEffect(() => {
    if (!product.activeDiscount) {
      setProduct({
        ...product,
        activeDiscount: DiscountOption.FLAT,
      });
    }
  }, [open]);

  // useEffect(() => {
  //     let total = parseFloat(product?.sale_price || 0) * parseFloat(product?.quantity || 0) - parseFloat(product?.discountValue || 0);
  //     setItemTotal(total);
  // }, [product]);

  useEffect(() => {
    handleDiscountChange(product.discount);
  }, [product.activeDiscount, product.sale_price, product.quantity]);

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open]);

  const handleDetectKeyDown = (e) => {
    if (open) {
      if (e.keyCode === 13) {
        //Enter key
        e.preventDefault();
        handleAddCustomProduct();
      } else if (e.keyCode === 38) {
        //UP Arrow key
        if (qtyRef.current === document.activeElement) {
          e.preventDefault();
          handleChangeQuantity("inc");
        }
      } else if (e.keyCode === 40) {
        //DOWN Arrow key
        if (qtyRef.current === document.activeElement) {
          e.preventDefault();
          handleChangeQuantity("dec");
        }
      }
    }
  };

  const handleInputChange = (e, key) => {
    if (key === "name") {
      let { value } = e.target;
      setProduct({ ...product, [key]: value });
    } else {
      handleManageInputchange(e, key);
    }
  };

  const handleManageInputchange = (e, key) => {
    onInputChange(e, (data) => {
      let { value } = data;
      if (key !== "name") {
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
          value = value.substr(1);
        }
        if (!(value === "" || regex.test(value))) return;
      }
      if (key === "discount") {
        handleDiscountChange(value);
      } else {
        if (key === "price") {
          if (!product.quantity) {
            setProduct({
              ...product,
              price: value,
              sale_price: value,
              quantity: 1,
            });
          } else {
            setProduct({ ...product, price: value, sale_price: value });
          }
        } else {
          setProduct({ ...product, [key]: value });
        }
      }
    });
  };

  const handleDiscountChange = (value) => {
    let itemTotal =
      parseFloat(product?.sale_price || 0) * parseFloat(product?.quantity || 0);
    if (product.activeDiscount === DiscountOption.FLAT) {
      if (value > itemTotal) {
        value = itemTotal;
      }
    }
    if (product.activeDiscount === DiscountOption.PERCENTAGE) {
      if (value > 100) {
        value = 100;
      }
    }
    setProduct({
      ...product,
      discount: value,
      discountValue:
        product.activeDiscount === DiscountOption.FLAT
          ? parseFloat(value || 0)
          : itemTotal * (parseFloat(value || 0) / 100),
    });
  };

  const handleClear = () => {
    setProduct(initialProductData);
  };
  const handleClose = () => {
    onClose();
    handleClear();
  };

  const handleChangeQuantity = (key) => {
    if (key === "inc") {
      setProduct({
        ...productRef.current,
        quantity: parseFloat(productRef.current.quantity || 0) + 1,
      });
    } else if (key === "dec") {
      if (!productRef.current.quantity) {
        return;
      }
      setProduct({
        ...productRef.current,
        quantity: parseFloat(productRef.current.quantity) - 1,
      });
    }
  };

  const setActiveDiscount = (value) => {
    setProduct({ ...product, activeDiscount: value });
  };

  const handleAddCustomProduct = () => {
    let { sale_price, price, quantity } = productRef.current;
    if (!price) {
      handleSnackbarDetails({
        show: true,
        title: "Please enter MRP.",
        type: "error",
      });
      return;
    }
    if (!sale_price) {
      handleSnackbarDetails({
        show: true,
        title: "Please enter sale price.",
        type: "error",
      });
      return;
    }
    if (!quantity) {
      handleSnackbarDetails({
        show: true,
        title: "Please enter quantity.",
        type: "error",
      });
      return;
    }
    onAddCustomProduct(productRef.current);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.Container>
        <S.TopBar>
          <S.Header>Add Custom Product</S.Header>
          <img src={CloseModalIcon} alt="close" onClick={handleClose} />
        </S.TopBar>
        <S.Wrapper>
          <S.FormContainer align="left">
            <S.Label>Name</S.Label>
            <S.InputContainer size="lg">
              <S.Input
                type="text"
                value={product.name}
                onChange={(e) => handleInputChange(e, "name")}
                autoFocus
              />
            </S.InputContainer>
          </S.FormContainer>

          <S.FormContainer align="center">
            <S.Label>MRP</S.Label>
            <S.InputContainer size="md">
              ₱{" "}
              <S.Input
                type="text"
                value={product.price}
                onChange={(e) => handleInputChange(e, "price")}
              />
            </S.InputContainer>
          </S.FormContainer>
          <S.FormContainer align="center">
            <S.Label>Sale Price</S.Label>
            <S.InputContainer size="md">
              ₱{" "}
              <S.Input
                type="text"
                value={product.sale_price}
                onChange={(e) => handleInputChange(e, "sale_price")}
              />
            </S.InputContainer>
          </S.FormContainer>

          <S.FormContainer align="center">
            <S.Label>Quantity</S.Label>
            <S.InputContainer>
              <S.Input
                type="text"
                value={product.quantity}
                onChange={(e) => handleInputChange(e, "quantity")}
                ref={qtyRef}
              />
            </S.InputContainer>
          </S.FormContainer>
          <S.FormContainer align="center">
            <S.Label>Discount</S.Label>
            <S.DiscountContainer>
              <S.SymbolDiv>
                <S.Symbol
                  active={product.activeDiscount === DiscountOption.FLAT}
                  onClick={() => setActiveDiscount(DiscountOption.FLAT)}
                >
                  ₱
                </S.Symbol>
                <S.MiddleBar></S.MiddleBar>

                <S.Symbol
                  active={product.activeDiscount === DiscountOption.PERCENTAGE}
                  onClick={() => setActiveDiscount(DiscountOption.PERCENTAGE)}
                >
                  %
                </S.Symbol>
              </S.SymbolDiv>
              <S.InputDiv>
                <input
                  type="text"
                  value={product.discount}
                  onChange={(e) => handleInputChange(e, "discount")}
                />
              </S.InputDiv>
            </S.DiscountContainer>
          </S.FormContainer>

          <S.FormContainer align="right">
            <S.Label>Item Total</S.Label>
            <S.Boldtext>
              ₱
              {parseFloat(product?.sale_price || 0) *
                parseFloat(product?.quantity || 0) -
                parseFloat(product?.discountValue || 0)}
            </S.Boldtext>
          </S.FormContainer>

          <S.AddBtn onClick={handleAddCustomProduct}>Add</S.AddBtn>
        </S.Wrapper>
      </S.Container>
    </Modal>
  );
};

export default CustomProduct;
