import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const Logo = styled.img`
    position: absolute;
    left: 0;
    height: 76px;
    width: 76px;
    margin-left: 1px;
    background: #fff;
    border-radius: 100%;
`;
