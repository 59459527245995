import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 23px 0px 0px 23px;
    width: 482px;
    display: flex;
    flex-direction: column;
    gap: 17px;
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;
    margin-bottom: 13px;
`;
export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    width: 100%;
    padding: 9px 11px;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;
export const ChangePassword = styled.div`
    width: 112.28px;
    height: 31.27px;
    background: #0075f6;
    border-radius: 2.8425px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11.37px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    margin: 5px 0;
`;
export const ForgetPassword = styled.div`
    font-weight: 500;
    font-size: 11.37px;
    line-height: 17px;
    color: #0075f6;
    cursor: pointer;
`;
