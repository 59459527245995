import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProducts } from "../api/local/productApi";
import { PAGE_LIMIT } from "../constants/appConstants";
import { captureException } from "../crash-reporting";

const initialState = {
    loading: false,
    products: [],
    error: "",
    searchParams: {
        limit: PAGE_LIMIT,
    },
    productsCountData: null,
    sort: null,
};

// Generates pending, fulfilled and rejected action types
export const fetchAllProducts = createAsyncThunk("product/fetchAllProducts", (queryParams) =>
    getAllProducts(queryParams)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },

        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = { limit: PAGE_LIMIT };
        },
        setProductsCountData: (state, action) => {
            state.productsCountData = action.payload;
        },
        setSort: (state, action) => {
            state.sort = action.payload;
        },
        removeSort: (state, action) => {
            state.sort = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllProducts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.products = action.payload.entity;
            state.error = "";
        });
        builder.addCase(fetchAllProducts.rejected, (state, action) => {
            state.loading = false;
            state.products = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default productSlice.reducer;
export const {
    setSearchParams,
    removeSearchParams,
    removeAllSearchParams,
    setProductsCountData,
    setSort,
    removeSort,
} = productSlice.actions;
