import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSuppliers } from "../api/local/supplierApi";
import { captureException } from "../crash-reporting";
import { PAGE_LIMIT } from "../constants/appConstants";

const initialState = {
    loading: false,
    suppliers: [],
    error: "",
    searchParams: { limit: PAGE_LIMIT },
    inventorySelectedSupplier: "",
};

// Generates pending, fulfilled and rejected action types
export const fetchAllSuppliers = createAsyncThunk("supplier/fetchAllSuppliers", (search) =>
    getAllSuppliers(search)
        .then((res) => res.data)
        .catch((err) => captureException(err))
);

const supplierSlice = createSlice({
    name: "supplier",
    initialState,
    reducers: {
        setSearchParams: (state, action) => {
            state.searchParams = {
                ...state.searchParams,
                ...action.payload,
            };
        },
        removeSearchParams: (state, action) => {
            delete state.searchParams[action.payload];
        },

        removeAllSearchParams: (state) => {
            state.searchParams = { limit: PAGE_LIMIT };
        },
        setSelectedSupplier: (state, action) => {
            state.inventorySelectedSupplier = action.payload;
        },
        removeSelectedSupplier: (state) => {
            state.inventorySelectedSupplier = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllSuppliers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllSuppliers.fulfilled, (state, action) => {
            state.loading = false;
            state.suppliers = action.payload;
            state.error = "";
        });
        builder.addCase(fetchAllSuppliers.rejected, (state, action) => {
            state.loading = false;
            state.suppliers = [];
            state.error = action.error.message;
        });
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default supplierSlice.reducer;
export const { setSearchParams, removeSearchParams, removeAllSearchParams, setSelectedSupplier, removeSelectedSupplier } = supplierSlice.actions;
