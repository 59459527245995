import React from "react";
import * as S from "./style";

const Nudge = ({ title, count, onClick }) => {
    return (
        <S.Wrapper onClick={onClick}>
            <S.Title>{title}</S.Title>
            <S.CountBox>{count}</S.CountBox>
        </S.Wrapper>
    );
};

export default Nudge;
