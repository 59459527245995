import React from "react";
import * as S from "./style";

const ShortcutBtn = ({ text, icon }) => {
    return (
        <S.Wrapper>
            {text && <div>{text}</div>}
            {icon && <img src={icon} alt="icon" />}
        </S.Wrapper>
    );
};

export default ShortcutBtn;
