import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeSearchParams, removeSort, setSearchParams, setSort } from "../../../../store/product-slice";

import * as S from "./sort-menu.styles";

const SortMenu = ({ closeMenu, onSortSelect, activeRow, scrollRef, data }) => {
    const dispatch = useDispatch();

    const handleChange = (item) => {
        dispatch(removeSearchParams("is_reversed"));
        onSortSelect(item.name);

        const payload = { sort_by: item.search_key };
        if (+item.id % 2 === 0) payload.is_reversed = true;
        if (+item.id % 2 === 1) payload.is_reversed = false;
        dispatch(setSearchParams(payload));
        dispatch(setSort(item));
    };

    return (
        <S.SearchList onClick={closeMenu}>
            {data.map((item, idx) => (
                <S.SearchData onClick={() => handleChange(item)} key={idx} active={idx === activeRow} ref={idx === activeRow ? scrollRef : null}>
                    {item.name}
                </S.SearchData>
            ))}
        </S.SearchList>
    );
};

export default SortMenu;
