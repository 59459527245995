import React from "react";
import * as S from "./cash-table.style";
import { AmountIcon, CashIncreaseIcon, CashReduceIcon, ClockIcon, ClosingCashIcon, DescriptionIcon, TypeIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Lottie from "lottie-react";
import EmptyTable from "../../../animations/empty_table.json";
import { ADJUSTMENT_TYPE, PURCHASE_TYPE, SALES_TYPE } from "../../../constants/appConstants";
import { getSale } from "../../../api/local/sale-api";
import { captureException } from "../../../crash-reporting";
import { setSelectedSale } from "../../../store/sale-slice";
import { setSelectedCustomer } from "../../../store/customer-slice";
import { useNavigate } from "react-router";
import { getPurchase } from "../../../api/local/purchase-api";
import { setSelectedPurchase } from "../../../store/purchase-slice";
import { toDecimalPlace } from "../../../utils/global-fn";

const data = [
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
    {
        type: "Cash Reduce",
        description: "wages payable",
        time: "11.30 AM",
        amount: 18000,
        closing_cash: 7000,
    },
];
const CashTable = () => {
    const { cashDrawerItems } = useSelector((state) => state.cashDrawer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleCashItemClick = (item) => {
        if (item.reference_type === SALES_TYPE.SALE || item.reference_type === SALES_TYPE.RETURN) {
            onSaleSelect(item.reference_id);
        } else if (item.reference_type === PURCHASE_TYPE.PURCHASE || item.reference_type === PURCHASE_TYPE.RETURN) {
            onPurchaseSelect(item.reference_id);
        }
    };

    const onSaleSelect = async (id) => {
        try {
            let res = await getSale(id);
            if (res.status === 200) {
                dispatch(setSelectedSale(res.data));
                dispatch(setSelectedCustomer(res.data.patient));
                navigate("/dashboard/view-sale");
            } else {
                throw new Error(res?.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };

    const onPurchaseSelect = async (id) => {
        try {
            let res = await getPurchase(id);
            if (res.status === 200) {
                dispatch(setSelectedPurchase(res.data));
                navigate("/dashboard/view-purchase");
            } else {
                throw new Error(res?.data?.message);
            }
        } catch (err) {
            captureException(err);
        }
    };
    return (
        <S.Wrapper>
            <S.HeadingRow>
                <S.Heading>
                    <img src={TypeIcon} alt="type" />
                    Type
                </S.Heading>
                <S.Heading>
                    <img src={DescriptionIcon} alt="description" />
                    Description
                </S.Heading>
                <S.Heading>
                    <img src={ClockIcon} alt="time" />
                    Time
                </S.Heading>
                <S.Heading>
                    <img src={AmountIcon} alt="amount" />
                    Amount
                </S.Heading>
                <S.Heading>
                    <img src={ClosingCashIcon} alt="closing cash" />
                    Closing Cash
                </S.Heading>
            </S.HeadingRow>
            {cashDrawerItems?.length ? (
                <S.TableBody>
                    {cashDrawerItems?.map((item, idx) => (
                        <S.TableRow key={idx} onClick={() => handleCashItemClick(item)}>
                            <S.TableData color="#263238">
                                {item.type === ADJUSTMENT_TYPE.INCREASE_CASH && <img src={CashIncreaseIcon} alt="cash increse" />}
                                {item.type === ADJUSTMENT_TYPE.REDUCE_CASH && <img src={CashReduceIcon} alt="cash reduce" />}
                                {item.type}
                            </S.TableData>
                            <S.TableData>{item.description}</S.TableData>
                            <S.TableData>{moment(item.created_at).format("lll")}</S.TableData>
                            <S.TableData color="#263238">{toDecimalPlace(item.amount)}</S.TableData>
                            <S.TableData>{toDecimalPlace(item.closing_amount)}</S.TableData>
                        </S.TableRow>
                    ))}
                </S.TableBody>
            ) : (
                <S.EmptyTable>
                    <div>
                        <Lottie animationData={EmptyTable} />
                    </div>
                </S.EmptyTable>
            )}
        </S.Wrapper>
    );
};

export default CashTable;
