import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BillIcon, CalendarIcon, CloseModalIcon, ExpenseIcon } from "../../../../icons";
import { calendarFormatDate, formatDate } from "../../../../utils/format-date";
import * as S from "./return-summary.styles";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
    getPurchaseReturnTotals,
    removeReturnSelectedSupplier,
    setBillNo,
    setDeliveryDate,
    setDeliveryExpense,
} from "../../../../store/purchasereturn-slice";
import { getDamageEntryTotals, setDamageBillNo } from "../../../../store/damageentry-slice";
import { hasDecimal, toDecimalPlace } from "../../../../utils/global-fn";
import { COLOR, ScreenType } from "../../../../constants/appConstants";
import CustomButton from "../../../custom-button/CustomButton";

const ReturnSummary = ({ billRef, delExpRef, type, timeRef, handleBtnClick, products }) => {
    const {
        purchaseReturnProducts,
        returnSelectedSupplier,
        purchaseReturnQuantity,
        purchaseReturnTotal,
        purchaseReturnTaxTotal,
        billNo,
        createdDate,
        deliveryDate,
        deliveryExpense,
        purchaseReturnCartTotal,
    } = useSelector((state) => state.purchaseReturn);
    const { damageEntryProducts, damageEntryTotal, damageEntryTaxTotal, damageEntryQuantity, damageBillNo } = useSelector(
        (state) => state.damageEntry
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPurchaseReturnTotals());
        dispatch(getDamageEntryTotals());
    }, [purchaseReturnProducts, damageEntryProducts, deliveryExpense]);

    const handleDateChange = (item) => {
        dispatch(setDeliveryDate(item));
    };

    const handleRemoveSupplier = () => {
        dispatch(removeReturnSelectedSupplier());
    };

    const handleChangeDelExp = (e) => {
        let val = e.target.value;
        const regex = /^[0-9\b]+$/;
        if (!(val === "" || regex.test(val))) return;
        dispatch(setDeliveryExpense(parseInt(val)));
    };

    const handleChangeBillNo = (event) => {
        let { value } = event.target;
        if (type === ScreenType.NewPurchaseReturn) {
            dispatch(setBillNo(value));
        } else if (type === ScreenType.NewDamageEntry) {
            dispatch(setDamageBillNo(value));
        }
    };

    return (
        <S.Container>
            <S.Header>{type === ScreenType.NewPurchaseReturn ? "Return" : "Damage"} Summary</S.Header>
            <S.Body>
                <div>
                    {type === ScreenType.NewPurchaseReturn && returnSelectedSupplier && (
                        <S.SupplierDetails>
                            <S.SupplierContent>
                                <S.Name>{returnSelectedSupplier?.name}</S.Name>
                                <S.Mobile>{returnSelectedSupplier?.phone && `(${returnSelectedSupplier?.phone})`}</S.Mobile>
                            </S.SupplierContent>
                            <img src={CloseModalIcon} alt="close" onClick={handleRemoveSupplier} />
                        </S.SupplierDetails>
                    )}
                    <S.PriceDetails>
                        <div>Quantity:</div>
                        <div>{type === ScreenType.NewPurchaseReturn ? purchaseReturnQuantity : damageEntryQuantity}</div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Subtotal:</div>
                        <div>
                            ₱
                            {type === ScreenType.NewPurchaseReturn
                                ? toDecimalPlace(purchaseReturnTotal - purchaseReturnTaxTotal)
                                : toDecimalPlace(damageEntryTotal - damageEntryTaxTotal)}
                        </div>
                    </S.PriceDetails>
                    <S.PriceDetails>
                        <div>Tax:</div>
                        <div>
                            ₱{type === ScreenType.NewPurchaseReturn ? toDecimalPlace(purchaseReturnTaxTotal) : toDecimalPlace(damageEntryTaxTotal)}
                        </div>
                    </S.PriceDetails>
                    {type === ScreenType.NewPurchaseReturn && (
                        <S.PriceDetails lastItem>
                            <div>Bill total:</div>
                            <div>
                                ₱{type === ScreenType.NewPurchaseReturn ? toDecimalPlace(purchaseReturnTotal) : toDecimalPlace(damageEntryTotal)}
                            </div>
                        </S.PriceDetails>
                    )}
                    <S.Dotted></S.Dotted>
                    {type === ScreenType.NewPurchaseReturn && (
                        <S.PriceDetails>
                            <div>Delivery expense:</div>
                            <div>₱{deliveryExpense || 0}</div>
                        </S.PriceDetails>
                    )}
                    {type === ScreenType.NewPurchaseReturn && hasDecimal(purchaseReturnCartTotal) && (
                        <S.PriceDetails>
                            <div>Adjusted Cash:</div>
                            <div>₱{toDecimalPlace(Math.round(purchaseReturnCartTotal) - purchaseReturnCartTotal)}</div>
                        </S.PriceDetails>
                    )}
                    {type === ScreenType.NewDamageEntry && hasDecimal(damageEntryTotal) && (
                        <S.PriceDetails>
                            <div>Adjusted Cash:</div>
                            <div>₱{toDecimalPlace(Math.round(damageEntryTotal) - damageEntryTotal)}</div>
                        </S.PriceDetails>
                    )}
                    <S.TotalDetails>
                        <div>{type === ScreenType.NewPurchaseReturn ? "Return" : "Damage"} Total:</div>
                        <div>₱{type === ScreenType.NewPurchaseReturn ? Math.round(purchaseReturnCartTotal) : Math.round(damageEntryTotal)}</div>
                    </S.TotalDetails>
                    {type === ScreenType.NewPurchaseReturn && (
                        <S.NewContainer>
                            <S.Label>
                                Add Delivery Expense
                                <div>(F5)</div>
                            </S.Label>
                            <S.FieldContainer>
                                <input type="text" onChange={handleChangeDelExp} value={deliveryExpense || 0} ref={delExpRef} />
                                <S.Line></S.Line>
                                <img src={ExpenseIcon} alt="" />
                            </S.FieldContainer>
                        </S.NewContainer>
                    )}

                    <S.NewContainer>
                        <S.Label>
                            Add Bill Number<div>(F7)</div>
                        </S.Label>
                        <S.FieldContainer>
                            <input
                                type="text"
                                onChange={handleChangeBillNo}
                                value={type === ScreenType.NewPurchaseReturn ? billNo : damageBillNo}
                                ref={billRef}
                            />
                            <S.Line></S.Line>
                            <img src={BillIcon} alt="" />
                        </S.FieldContainer>
                    </S.NewContainer>

                    {type === ScreenType.NewPurchaseReturn && (
                        <S.DeliveryDetails>
                            <S.Label>
                                Select delivery date<div>(F3)</div>
                            </S.Label>
                            <S.FieldContainer ref={timeRef} onClick={() => timeRef.current?.querySelector("input").click()}>
                                <DatePicker
                                    selected={Date.parse(calendarFormatDate(deliveryDate))}
                                    onChange={(date) => handleDateChange(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="summary-calendar"
                                    minDate={createdDate || new Date()}
                                />
                                <S.Line></S.Line>
                                <img src={CalendarIcon} alt="calendar" />
                            </S.FieldContainer>
                        </S.DeliveryDetails>
                    )}
                </div>
                <S.BtnContainer>
                    <CustomButton
                        label="Complete (F12)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("purchase")}
                        fill={true}
                        size="lg"
                        disable={!products?.length}
                    />
                    <CustomButton
                        label="Save to Counter (F10)"
                        color={COLOR.BLUE}
                        onClick={() => handleBtnClick("draft")}
                        size="lg"
                        noBorder={true}
                        disable={!products?.length}
                    />
                </S.BtnContainer>
            </S.Body>
        </S.Container>
    );
};

export default ReturnSummary;
