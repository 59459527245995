import styled from "@emotion/styled/macro";

export const Table = styled.div`
    border: 1px solid #e2e8f0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0px 16px 0;
`;
export const THead = styled.div`
    display: flex;
    background: #f1f5f9;
    // height: 48px;
    align-items: center;
    padding: 10px 16px;
    box-sizing: border-box;
`;
export const TBody = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const Th = styled.div`
    flex: 1;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
`;

export const TRow = styled.div`
    display: flex;
    padding: 12px 16px 12px 16px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    & > div {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        box-sizing: border-box;
    }
`;
export const DataBtn = styled.div`
    color: #334155;
    flex: 1;
    overflow: hidden;

    & > div {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const SaleBtn = styled.div`
    color: #334155;
    position: relative;
    left: 28px;
    flex: 1;
`;
export const DialogDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 100;
    }
`;

export const ViewBtn = styled.div`
    color: #2d68fe;
    cursor: pointer;
`;

export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    // height: 136px;
    top: 0;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    // gap: 16px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 1;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.24px;
    color: #333333;
    padding: 12px;

    :hover {
        background-color: #f1f5f9;
    }
`;

export const ClearDues = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
    text-decoration: underline;
    cursor: pointer;
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
