import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    //   width: 236px;
    height: 120px;
`;

export const TopDiv = styled.div`
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eef1f7;
    box-shadow: 0px 0.464567px 0.929134px rgba(0, 0, 0, 0.12);
    border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;
    padding: 12px;
    & > div {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #263238;
        cursor: pointer;
    }

    & > select {
        background: #fff;
        font-size: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        &:after {
            border: none;
            outline: none;
        }
    }
`;
export const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    height: 36px;
    padding: 12px;
    background: #fff;
    box-shadow: 0px 0.464567px 0.929134px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 8px;
`;
export const MiddleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    // align-items: center;
    height: 27px;
    border: 1px solid red;
    height: 51px;
    box-sizing: border-box;
    padding: 0px 12px;
    border-width: 0px 0.232283px 0.232283px 0.232283px;
    border-style: solid;
    border-color: #eeeeee;
    border-radius: 0px;

    & > div {
        display: flex;
        // align-items: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #8a8a8a;
        gap: 4px;

        & span {
            margin-top: 4px;
        }
    }
    & input {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #a6a6a6;
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        width: 310px;
        height: 25px;
        &::placeholder {
            color: #94a3b8;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }
`;
export const CancelBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    // border: 0.5px solid #94a3b8;
    border-radius: 4.1811px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #455a64;
    cursor: pointer;
    cursor: pointer;
`;
export const AddBtn = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border: 0.5px solid #2d68fe;
    border-radius: 4.1811px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #2d68fe;
    gap: 2.5px;
    text-transform: uppercase;
    & img {
        hieght: 8px;
        width: 8px;
    }
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    bottom: 170px;
    //   right: 0;
    left: 36px;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 13000;
`;
export const RecordContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    & div {
        display: flex;
        align-items: center;

        & input {
            cursor: pointer;
        }
        & label {
            cursor: pointer;
        }
    }
`;
export const AmountInput = styled.div`
    border-bottom: 1px solid #94a3b8;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #94a3b8;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 88px;
    & input {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #a6a6a6;
        outline: none;
        border: none;
        background-color: transparent;
        padding: 0;
        width: 100%;
        height: 15px;
        &::placeholder {
            color: #94a3b8;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }
`;
export const CalendarDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #94a3b8;
    cursor: pointer;

    & img {
        width: 16px;
        height: 16px;
    }
`;
export const BtnDiv = styled.div`
    display: flex;
    gap: 8px;
`;
