import styled from "@emotion/styled/macro";

export const Payment = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #334155;
    margin-bottom: 8px;

    & > span {
        font-weight: 400;
        font-size: 12px;
        color: red;
    }
`;
export const CenterFlex = styled.div`
    display: flex;
    align-items: center;
`;
export const FlexContainer = styled(CenterFlex)`
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
`;
export const ParentModeContainer = styled.div`
    position: relative;
    height: 22px;
`;
export const ModeContainer = styled(CenterFlex)`
    gap: 4px;
`;

export const PaymentImg = styled.img`
    width: ${(props) => (props.size === "sm" ? "20px" : "20px")};
    height: ${(props) => (props.size === "sm" ? "20px" : "20px")};
    padding: ${(props) => props.padding && "2px"};
    margin-bottom: -2px;
`;
export const Mode = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;

    & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
    }
`;

export const Strike = styled.div`
    height: 1.5px;
    margin-top: -8px;
    background-color: #64748b;
`;
