import React from "react";
import * as S from "./style";
import {
  getTotalsFromPI,
  removeAllProductsPI,
  removeCustomerPI,
  removePI_ID,
  removeSelectedPI,
  resetAdditionalChargesPI,
  setBillNoPI,
  setCreatedDatePI,
  setSelectDiscountPI,
} from "../../../store/proformainvoice-slice";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DiscountOption,
  SALES_TYPE,
  SALE_BILL_TYPE,
} from "../../../constants/appConstants";
import { useImperativeHandle } from "react";
import {
  convertTax,
  getQuantity,
  getSalePayloadProducts,
  hasDecimal,
  toDecimalPlace,
} from "../../../utils/global-fn";
import { addSale, updateSale } from "../../../api/local/sale-api";
import { captureException } from "../../../crash-reporting";
import onInputChange from "../../../utils/handleInputChange";
import AdditionalCharges from "../../sales/additional-charges/AdditionalCharges";
import { useEffect } from "react";
import { BillIcon } from "../../../icons";
import InvoicePaymentModal from "../../invoice-payment-modal/InvoicePaymentModal";

const Summary = ({ billRef, cartRef, clearCartRef, openDrawer }, ref) => {
  const [openAdditionalCharge, setOpenAdditionalCharge] = useState(false);
  const [openInvoicePaymentModal, setOpenInvoicePaymentModal] = useState(false);

  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const {
    PI_products,
    PI_quantity,
    PI_total,
    PI_taxTotal,
    PI_discountValue,
    PI_billNo,
    PI_createdDate,
    PI_selectDiscount,
    PI_additional_charges,
    PI_totalAdditionalCharges,
    PI_customer,
    PI_id,
    PI_cartTotal,
    PI_type,
    PI_globalDiscount,
  } = useSelector((state) => state.proformaInvoice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!PI_selectDiscount.cost) {
      dispatch(
        setSelectDiscountPI({
          ...PI_selectDiscount,
          type: DiscountOption.PERCENTAGE,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getTotalsFromPI());
  }, [
    PI_products,
    PI_selectDiscount.cost,
    PI_selectDiscount.type,
    PI_total,
    PI_additional_charges,
  ]);

  useImperativeHandle(ref, () => {
    return {
      saveDraft: () => onDraftSale(),
      saveCheckoutSale: () => onCheckOut(),
    };
  });

  const onCheckOut = () => {
    if (!openDrawer) {
      handleCheckout();
    }
  };

  const onDraftSale = () => {
    if (!openDrawer) {
      handleDraftPI();
    }
  };

  function handleClearData() {
    dispatch(removeAllProductsPI());
    dispatch(removeCustomerPI());
    dispatch(removePI_ID());
    dispatch(setBillNoPI(""));
    dispatch(setCreatedDatePI(null));
    dispatch(removeSelectedPI());
    dispatch(
      setSelectDiscountPI({
        type: DiscountOption.PERCENTAGE,
        cost: 0,
      })
    );
    dispatch(resetAdditionalChargesPI());
  }

  async function handleDraftPI() {
    if (!PI_products.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please add products",
        type: "error",
      });
      return;
    }
    for (let item of PI_products) {
      if (item.quantity <= 0) {
        handleSnackbarDetails({
          show: true,
          title: `Please choose a quantity for ${item.name}`,
          type: "error",
        });
        return;
      }
    }
    const payload = {
      type: PI_type,
      status: "DRAFT",
      products: getSalePayloadProducts(PI_products, SALE_BILL_TYPE.INVOICE),
    };

    if (PI_customer) payload.patient_id = PI_customer.id;
    if (PI_createdDate)
      payload.created_at = Math.floor(PI_createdDate.getTime());
    if (parseFloat(PI_selectDiscount.cost)) {
      let disc = {
        type: PI_selectDiscount.type,
        value: PI_globalDiscount,
      };
      payload.cart_manual_discount = disc;
    }
    if (PI_billNo !== undefined) payload.bill_no = PI_billNo;
    if (PI_additional_charges.length) {
      payload.additional_charges = PI_additional_charges.filter(
        (a) => a.value !== ""
      ).map((item) => ({
        ...item,
        value: parseFloat(item.value),
      }));
    }
    if (PI_id) {
      try {
        let res = await updateSale(PI_id, payload);
        if (res.status === 200) {
          handleClearData();
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Draft Updated",
            subtitle: "Draft updated successfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      } catch (err) {
        captureException(err);
        handleSnackbarDetails({
          show: true,
          title: err.message,
          type: "error",
        });
      }
    } else {
      try {
        let res = await addSale(payload);
        if (res.status === 200) {
          handleClearData();
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Draft Saved",
            subtitle: "Draft added successfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      } catch (err) {
        captureException(err);
        handleSnackbarDetails({
          show: true,
          title: err.message,
          type: "error",
        });
      }
    }
  }
  function handleCheckout() {
    if (!PI_products.length) {
      return;
    }
    for (let item of PI_products) {
      if (item.quantity == 0) {
        handleSnackbarDetails({
          show: true,
          title: `Please choose a quantity for ${item.name}`,
          type: "error",
        });
        return;
      }
    }
    setOpenInvoicePaymentModal(true);
  }

  function handleBillChange(e) {
    onInputChange(e, (data) => {
      const { value } = data;
      dispatch(setBillNoPI(value));
    });
  }

  function handleDiscountChange(e) {
    onInputChange(e, (data) => {
      let { value } = data;
      const regex = /^\d+\.?(\d)?(\d)?$/;
      if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
        value = value.substr(1);
      }
      if (!(value === "" || regex.test(value))) return;
      if (PI_selectDiscount.type === DiscountOption.PERCENTAGE) {
        if (parseFloat(value) > 100) {
          value = 100;
        }
      }
      if (PI_selectDiscount.type === DiscountOption.FLAT) {
        let total = PI_total + PI_totalAdditionalCharges;
        if (parseFloat(value) > total) {
          value = total;
        }
      }
      dispatch(
        setSelectDiscountPI({
          ...PI_selectDiscount,
          cost: value,
        })
      );
    });
  }
  return (
    <>
      <AdditionalCharges
        open={openAdditionalCharge}
        onClose={() => setOpenAdditionalCharge(false)}
        type="proforma-invoice"
      />
      <InvoicePaymentModal
        open={openInvoicePaymentModal}
        onClose={() => setOpenInvoicePaymentModal(false)}
        saleDiscount={PI_globalDiscount}
        handleClearData={handleClearData}
        type="proforma-invoice"
      />
      <S.Container>
        <S.Header>
          <S.HeaderLeft>
            {PI_type === SALES_TYPE.DELIVERY_CHALLAN
              ? "Delivery Challan"
              : "Proforma Invoice"}{" "}
            Summary
          </S.HeaderLeft>
        </S.Header>

        <S.Body>
          <div>
            <S.GreyText>
              <div>Quantity:</div>
              <div>{PI_quantity}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Subtotal:</div>
              <div>
                ₱
                {toDecimalPlace(
                  PI_total - PI_taxTotal.toFixed(2) + PI_discountValue
                )}
              </div>
            </S.GreyText>

            <S.GreyText>
              <div>Discount:</div>
              <div>₱{toDecimalPlace(PI_discountValue)}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Tax:</div>
              <div>₱{toDecimalPlace(PI_taxTotal)}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Bill total:</div>
              <div>₱{toDecimalPlace(PI_total)}</div>
            </S.GreyText>
            <S.Dotted></S.Dotted>

            <S.GreyText>
              <div>Cart discount:</div>
              <div>₱{toDecimalPlace(PI_globalDiscount)}</div>
            </S.GreyText>
            {hasDecimal(PI_cartTotal) && (
              <S.GreyText>
                <div>Adjusted cash:</div>
                <div>
                  ₱{toDecimalPlace(Math.round(PI_cartTotal) - PI_cartTotal)}
                </div>
              </S.GreyText>
            )}
            <S.Total>
              <div>Cart total:</div>
              <div>₱{Math.round(PI_cartTotal)}</div>
            </S.Total>
            <S.Dotted></S.Dotted>
            <S.BillContainer>
              <S.Label>
                Cart discount <div>(F8)</div>
              </S.Label>
              <S.CartDiscountChoosers>
                <S.SymbolDiv>
                  <S.Symbol
                    active={PI_selectDiscount.type === DiscountOption.FLAT}
                    onClick={() =>
                      dispatch(
                        setSelectDiscountPI({
                          ...PI_selectDiscount,
                          type: DiscountOption.FLAT,
                        })
                      )
                    }
                  >
                    ₱
                  </S.Symbol>
                  <S.MiddleBar></S.MiddleBar>

                  <S.Symbol
                    active={
                      PI_selectDiscount.type === DiscountOption.PERCENTAGE
                    }
                    onClick={() =>
                      dispatch(
                        setSelectDiscountPI({
                          ...PI_selectDiscount,
                          type: DiscountOption.PERCENTAGE,
                        })
                      )
                    }
                  >
                    %
                  </S.Symbol>
                </S.SymbolDiv>
                <input
                  type="text"
                  value={PI_selectDiscount.cost ?? 0}
                  onChange={(e) => handleDiscountChange(e)}
                  disabled={PI_products.length <= 0}
                  ref={cartRef}
                />
              </S.CartDiscountChoosers>
            </S.BillContainer>
            <S.BillContainer>
              <S.Label>
                Invoice number <div>(F7)</div>
              </S.Label>
              <S.FieldContainer>
                <input
                  type="text"
                  value={PI_billNo}
                  onChange={(e) => handleBillChange(e)}
                  ref={billRef}
                />
                <S.Line></S.Line>
                <img src={BillIcon} alt="bill icon" />
              </S.FieldContainer>
            </S.BillContainer>
            <S.AdditionalChargeContainer>
              {Array.isArray(PI_additional_charges) &&
              PI_additional_charges.length &&
              PI_totalAdditionalCharges ? (
                <>
                  <S.Label>Additional Charges:</S.Label>
                  {PI_additional_charges.map(
                    (item, index) =>
                      item.field &&
                      item.value && (
                        <S.GreyText key={index}>
                          <div>{item.field}:</div>
                          <div>₱{item.value}</div>
                        </S.GreyText>
                      )
                  )}
                </>
              ) : (
                <></>
              )}
            </S.AdditionalChargeContainer>
          </div>

          <div>
            <S.OutlineBtn onClick={() => setOpenAdditionalCharge(true)}>
              + Add Additional Charges
            </S.OutlineBtn>
            <S.CheckoutBtn
              color={PI_products.length}
              onClick={() => handleCheckout()}
            >
              Checkout (F12)
            </S.CheckoutBtn>

            <S.SavetoDraftBtn onClick={() => handleDraftPI()}>
              Add New Counter (F10)
            </S.SavetoDraftBtn>
          </div>
        </S.Body>
      </S.Container>
    </>
  );
};

export default React.forwardRef(Summary);
