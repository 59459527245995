import React from "react";
import * as S from "./style";
import { toDecimalPlace } from "../../utils/global-fn";

const PartyTransactionCount = ({ type, txnCount }) => {
    return (
        <S.Container>
            <div>
                Total {type === "customer" ? "Sale" : "Purchase"} Amount :<span> ₱{toDecimalPlace(txnCount?.total_amount)}</span>
            </div>
            <div>|</div>
            <div>
                Total Amount Due: <span> ₱{toDecimalPlace(txnCount?.total_amount_due)}</span>
            </div>
            <div>|</div>
            <div>
                Total {type === "customer" ? "Sales" : "Purchases"} Bills Count : <span> {toDecimalPlace(txnCount?.count)}</span>
            </div>
        </S.Container>
    );
};

export default PartyTransactionCount;
