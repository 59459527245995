import styled from "@emotion/styled/macro";
import { multilineEllipsis } from "../../../assets/css/mixin";

export const Table = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export const TableHeaderContainer = styled.div`
    display: flex;
    background: #f1f5f9;
`;

export const TableHeader = styled.div`
    flex: ${(props) => props.flexValue};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 10px 6px 10px 6px;
    // border: 1px solid red;
    text-align: ${(props) => (props.center ? "center" : "left")};
    margin-right: ${(props) => props.mr && props.mr};
    // border: 1px solid red;
`;

export const TableBody = styled.div`
    border: 0.706468px solid #e2e8f0;
    overflow-y: auto;
    height: 100%;
`;

export const TableRow = styled.div`
    height: 78px;
    display: flex;
    align-items: center;
    border-bottom: 0.706468px solid #e2e8f0;
    cursor: pointer;
    position: relative;
    background: ${(props) => (props.active ? "#E6EFFF" : "#fff")};

    &:hover {
        background: #f1f5f9;
    }
`;

export const TableData = styled.div`
    flex: ${(props) => props.flexValue};
    padding: 0 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => (props.completed ? "#17B288" : "#334155")};
    ${multilineEllipsis({ fontSize: "12px", lineHeight: "16px", lines: 2 })}
    text-align: ${(props) => (props.center ? "center" : "left")};
    box-sizing: border-box;
    position: relative;
    // border: 1px solid red;
`;
export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    // height: 136px;
    top: 0;
    display: flex;
    flex-direction: column;
    // padding: 16px;
    // gap: 20px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 1;
    margin-top: 10px;
    margin-right: 10px;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #333333;
    padding: 12px;
    &:hover {
        background: #f1f5f9;
    }
`;

export const ThreeDot = styled.img`
    width: 24px;
    height: 24px;
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
