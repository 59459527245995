import React, { useEffect, useState, useRef } from "react";
import {
  BlueArrowLeft,
  BlueArrowRight,
  EmptyCartLogo,
  WhiteArrowLeft,
  WhiteArrowRight,
} from "../../../icons";
import * as S from "./cart.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToSale,
  removeProductFromSale,
  setCurrentScreen,
  updateProductFromSale,
} from "../../../store/sale-slice";
import { getProduct, getProductsBySKU } from "../../../api/local/productApi";
import SelectProductModal from "./../../select-product-modal/select-product-modal";
import CartRow from "./CartRow";
import ProductDrawer, {
  INITIAL_PRODUCT_DISPLAY_DATA,
} from "./../../product-drawer/product-drawer";
import { Drawer } from "@mui/material";
import store from "../../../store/store";
import { generateSKU } from "../../../utils/sku-upc-generator";
import { captureException } from "../../../crash-reporting";
import {
  PRODUCT_DRAWER_TYPE,
  SALE_BILL_TYPE,
  SALE_SCREENS,
} from "../../../constants/appConstants";
import {
  setProductDrawerType,
  setProductDrawerData as setDrawerData,
  setOpenProductDrawer,
} from "../../../store/productdrawer-slice";
import {
  getQuantity,
  getSaleObjectHash,
  handleGetProductUnitQty,
} from "../../../utils/global-fn";

const Cart = ({
  openClearModal,
  handleClearCart,
  openDrawer,
  setOpenDrawer,
  openCustomProduct,
}) => {
  const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
  const [scannedProduct, setScannedProduct] = useState(null);
  const { saleProducts, currentScreen } = useSelector((state) => state.sale);
  const dispatch = useDispatch();
  const [productDrawerData, setProductDrawerData] = useState({
    ...INITIAL_PRODUCT_DISPLAY_DATA,
    sku: generateSKU(),
  });
  const rowsRefs = useRef([]);
  const [activeRow, _setActiveRow] = useState(null);
  const activeRowRef = React.useRef(activeRow);
  const setActiveRow = (data) => {
    activeRowRef.current = data;
    _setActiveRow(data);
  };
  const { billTypePreference } = useSelector((state) => state.app);

  useEffect(() => {
    if (!process.env.REACT_APP_DEMO_SERVER) {
      const removeEventListener = window.electronAPI.onBarcodeScanned(
        async (event, value) => await handleGetProduct(value)
      );
      return () => {
        removeEventListener();
      };
    }
  }, []);

  useEffect(() => {
    if (saleProducts.length > 0) {
      getProductsData();
    }
    document.addEventListener("keydown", handleDetectKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, []);

  useEffect(() => {
    if (saleProducts.length === 0) {
      handleClearCart();
    }
  }, [saleProducts]);

  const getProductsData = async () => {
    const payload = {
      ids: saleProducts.filter((p) => !p.is_custom_product).map((_p) => _p.sku),
    };
    try {
      let res = await getProductsBySKU(payload);
      if (res.status === 200) {
        let products = res.data?.entity;
        products.forEach((product) => {
          dispatch(updateProductFromSale(product));
        });
      }
    } catch (error) {
      captureException(error);
    }
  };

  const getCurrentIdx = () => {
    return rowsRefs.current?.findIndex((el) => el === document.activeElement);
  };

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 46) {
      //DELETE Key
      let product = store.getState().sale.saleProducts[activeRowRef.current];
      if (activeRowRef.current >= 0 && product) {
        e.preventDefault();
        dispatch(removeProductFromSale(product));
      }
    } else if (e.keyCode === 38) {
      //UP Arrow key
      let idx = getCurrentIdx();
      let product = store.getState().sale.saleProducts[idx];
      if (idx >= 0 && product) {
        e.preventDefault();
        handleUpdateQuantity(
          product,
          product.checkProduct,
          Number(product.quantity) + 1
        );
      }
    } else if (e.keyCode === 40) {
      //DOWN Arrow key
      let idx = getCurrentIdx();
      let product = store.getState().sale.saleProducts[idx];
      if (idx >= 0 && product) {
        e.preventDefault();
        if (product.quantity <= 1) return;
        handleUpdateQuantity(
          product,
          product.checkProduct,
          Number(product.quantity) - 1
        );
      }
    } else if (e.keyCode === 33) {
      //Pg Up key
      if (activeRowRef.current > 0) {
        e.preventDefault();
        setActiveRow(activeRowRef.current - 1);
        rowsRefs.current[activeRowRef.current].focus();
      }
    } else if (e.keyCode === 34) {
      //Pg Down key
      let products = store.getState().sale.saleProducts;
      if (activeRowRef.current < products.length - 1) {
        e.preventDefault();
        setActiveRow(activeRowRef.current + 1);
        rowsRefs.current[activeRowRef.current].focus();
      }
    }
  };

  const handleUpdateQuantity = (product, checkProduct, value) => {
    let { quantity, wholesale_quantity, wholesale_price, sale_price, price } =
      product;
    let newQuantity = value;
    let quantityBefore = getQuantity(
      product,
      billTypePreference?.sale_bill_type
    );
    let quantityAfter = getQuantity(
      { ...product, quantity: newQuantity },
      billTypePreference?.sale_bill_type
    );

    let qty = handleGetProductUnitQty(product);
    let salePrice = sale_price;
    let mrpPrice = price;
    // if (wholesale_quantity && wholesale_price) {
    if (
      quantityBefore < wholesale_quantity &&
      quantityAfter >= wholesale_quantity
    ) {
      salePrice = wholesale_price * qty;
      mrpPrice = checkProduct.price * qty;
    } else if (
      quantityBefore >= wholesale_quantity &&
      quantityAfter < wholesale_quantity
    ) {
      salePrice = checkProduct.sale_price * qty;
      mrpPrice = checkProduct.price * qty;
    }
    // } else {
    //     salePrice = checkProduct.sale_price * qty;
    //     mrpPrice = checkProduct.price * qty;
    // }

    dispatch(
      updateProductFromSale({
        ...product,
        quantity: newQuantity,
        sale_price: salePrice,
        price: mrpPrice,
      })
    );
  };

  async function handleGetProduct(barcode) {
    if (openCustomProduct.current) return;
    try {
      let res = await getProduct(barcode, { status: "PUBLISH" });
      if (res.status === 200) {
        //Product is present
        if (res.data.length > 1) {
          setScannedProduct(res.data);
          setOpenSelectProductModal(true);
        } else {
          dispatch(addProductToSale(res.data[0]));
        }
        setOpenDrawer(false);
      } else if (res.status === 404) {
        //Product is not present, Show the product slider to add the product
        setProductDrawerData({
          ...INITIAL_PRODUCT_DISPLAY_DATA,
          upc: barcode,
          sku: generateSKU(),
        });
        setOpenDrawer(true);
      } else {
        throw new Error(res.data?.message);
      }
    } catch (e) {
      //console.log(e);
      captureException(e);
    }
  }

  const handleClick = (type) => {
    if (type === "proceed") {
      dispatch(setCurrentScreen(SALE_SCREENS.SUMMARY));
    } else if (type === "edit") {
      dispatch(setCurrentScreen(SALE_SCREENS.TABLE));
    }
  };

  const handleEditProduct = (product) => {
    dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.EDIT));
    dispatch(setDrawerData(product));
    dispatch(setOpenProductDrawer());
  };

  return (
    <S.Wrapper isFull={currentScreen === SALE_SCREENS.TABLE}>
      <Drawer
        anchor="right"
        hideBackdrop="true"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        className="right-drawer"
      >
        <ProductDrawer
          type="add"
          onCloseDrawer={(product, type) => {
            setOpenDrawer(false);
            setProductDrawerData({
              ...INITIAL_PRODUCT_DISPLAY_DATA,
              sku: generateSKU(),
            });

            if (type === "Added" && product?.sku) {
              dispatch(addProductToSale(product));
            }
          }}
          productData={productDrawerData}
          open={openDrawer}
        />{" "}
      </Drawer>
      <S.Container
        hasBottom={
          billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
          saleProducts?.length
        }
      >
        <SelectProductModal
          open={openSelectProductModal}
          onClose={() => setOpenSelectProductModal(false)}
          data={scannedProduct}
        />
        {saleProducts.length ? (
          <>
            <S.TableHeadRow>
              <S.TableHead flexValue="0.3" $serialNo>
                SN.
              </S.TableHead>
              <S.TableHead flexValue="2" $firstItem>
                Item Description
              </S.TableHead>
              <S.TableHead>Quantity</S.TableHead>
              {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.POS && (
                <S.TableHead flexValue="0.5">Stock</S.TableHead>
              )}
              {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
                currentScreen === SALE_SCREENS.TABLE && (
                  <S.TableHead>Unit</S.TableHead>
                )}
              <S.TableHead>Price</S.TableHead>
              <S.TableHead flexValue="1.5">Sale Price</S.TableHead>
              {/* {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE && currentScreen === SALE_SCREENS.TABLE && (
                                <S.TableHead>Taxable ?</S.TableHead>
                            )} */}
              <S.TableHead flexValue="1.2">Discount</S.TableHead>
              {<S.TableHead flexValue="1.3">Tax</S.TableHead>}
              <S.TableHead>Subtotal</S.TableHead>
              {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
                currentScreen === SALE_SCREENS.TABLE && (
                  <S.TableHead flexValue="0.5">More</S.TableHead>
                )}
              <S.TableHead flexValue="0.3"></S.TableHead>
            </S.TableHeadRow>

            <S.TBody>
              {saleProducts.map((item, idx) => (
                <CartRow
                  key={idx}
                  item={item}
                  idx={idx}
                  focusRef={rowsRefs}
                  activeRow={activeRow}
                  setActiveRow={setActiveRow}
                  checkProduct={item?.checkProduct}
                  handleEditProduct={handleEditProduct}
                  handleUpdateQuantity={handleUpdateQuantity}
                />
              ))}
            </S.TBody>
          </>
        ) : (
          <S.EmptyContainer>
            <img src={EmptyCartLogo} alt="empty cart logo" />
            <div>Search or scan products to add to cart</div>
          </S.EmptyContainer>
        )}
      </S.Container>
      {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
      saleProducts?.length ? (
        <S.BottomBar>
          <div></div>

          {saleProducts?.length ? (
            <>
              {currentScreen === SALE_SCREENS.TABLE ? (
                <S.CustomBtn
                  fill
                  color="#2D68FE"
                  onClick={() => handleClick("proceed")}
                >
                  Proceed (F12)
                  <img src={WhiteArrowRight} alt="arrow" />
                </S.CustomBtn>
              ) : (
                <S.CustomBtn
                  color="#2D68FE"
                  onClick={() => handleClick("edit")}
                >
                  Expand (F2)
                  <img src={BlueArrowRight} alt="arrow" />
                </S.CustomBtn>
              )}
            </>
          ) : (
            <></>
          )}
        </S.BottomBar>
      ) : (
        <></>
      )}
    </S.Wrapper>
  );
};

export default Cart;
