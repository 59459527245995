import styled from "@emotion/styled/macro";
export const Wrapper = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    box-sizing: border-box;
`;
export const Body = styled.div``;
export const Heading = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
    margin-bottom: 20px;
`;
export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    & > div {
        width: 230px;
    }
`;

export const FooterBtns = styled.div`
    display: flex;
    justify-content: space-between;
    height: 46px;
`;
export const SaveBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    cursor: pointer;
`;
export const NextBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #253238;
    border: 1px solid #253238;
    border-radius: 4px;
`;
export const FooterRightBtns = styled.div`
    display: flex;
    gap: 10px;
    height: 46px;
`;
