import styled from "@emotion/styled/macro";
import { COLOR } from "../../../../../constants/appConstants";

export const TableRow = styled.div`
    display: flex;
    padding: 12px 16px;
    background-color: ${(props) => (props.active ? "#E6EFFF" : "#fff")};
    border-bottom: 1px solid #e6ebf6;
    margin: 2px;
    border-radius: 4px;
`;

export const TableBodyData = styled.div`
    display: flex;
    flex: ${(props) => props.flexValue};
    align-items: center;
    ${(props) => props.center && "justify-content: center"};
    ${(props) => props.center && "align-items: center"};
    ${(props) => props.center && "justify-content: center"};
    ${(props) => props.column && "flex-direction: column"};
    box-sizing: border-box;

    & > div {
        width: ${(props) => props.full && props.full};
    }

    img[alt*="delete"] {
        cursor: pointer;
        height: 32px;
        width: 32px;
    }
    img[alt*="info"] {
        height: 32px;
        width: 32px;
        cursor: pointer;
        margin: auto;
    }
`;

export const HintText = styled.div`
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #94a3b8;
    margin-top: 2px;
    margin-bottom: -12px;
    text-align: center;
`;

export const ProductImage = styled.img`
    width: 56px;
    height: 56px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: #fff;
`;

export const ImagePlaceholder = styled.div`
    width: 56px;
    height: 56px;
    background: #fff;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProductDetails = styled.div`
    margin-left: 4px;
    cursor: pointer;
`;

export const Brand = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #475569;
    margin-bottom: 2px;
    // text-transform: uppercase;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000;
    margin-bottom: 2px;
`;

export const InputContainer = styled.div`
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    width: 80%;
    background-color: #fff;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    resize: both;
    text-align: ${(props) => props.align};
    padding-left: ${(props) => props.paddingLeft};
`;

export const Text = styled.div`
    width: 100%;
    background-color: white;
    text-align: ${(props) => props.center && "center"};
`;

export const DateDisplay = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #334155;
    cursor: pointer;
    margin: 0 6px;
`;

export const DatePickerContainer = styled.div`
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 1;
`;

export const AddCommentButton = styled.div`
    position: relative;

    img {
        cursor: pointer;
    }
`;
export const ArrowBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid #e2e8f0;
    width: 50%;
    // border: 1px solid red;

    & img {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }
`;
export const Metrics = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #94a3b8;
`;
export const TableDataSn = styled.div`
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & div {
        text-align: center;
        border: 1px solid #000000;
        background-color: #fff;
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        color: #000000;
        width: 24px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const InputFlexContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    & > div:nth-of-type(1) {
        width: 45%;
    }
    & > div:nth-of-type(2) {
        width: 55%;
    }
`;

export const Rupee = styled.div`
    padding-left: 9px;
`;

export const MoreInfoWrapper = styled.div`
    position: absolute;
    right: 0;
    width: 600px;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -120px;
    box-sizing: border-box;
    padding: 7px 16px;
    border: 1px solid #cbd5e1;
`;

export const OptionHeader = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`;
export const OptionDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const OptionInputContainer = styled.div`
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #334155;
    width: 93px;
`;

export const MoreIcon = styled.div`
    margin: auto;
    position: relative;
    cursor: pointer;
`;
export const Dot = styled.div`
    position: absolute;
    left: 80%;
    top: 70%;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: ${COLOR.RED};
`;
