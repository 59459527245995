import axios from "axios";
import store from "../../store/store";
import { openAutoLoginModal } from "../../store/app-slice";
import history from "../../utils/history";

const EXCLUDE_AUTO_LOGIN = [
  "/",
  "/registration",
  "/login",
  "/employee-login",
  "/database-sync",
];

const localAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Connection: "keep-alive",
    Accept: "application/json",
  },
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status == 404;
  },
});

// Request interceptor
localAxios.interceptors.request.use(async (config) => {
  config.headers["client-ts"] = Date.now();
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "serviceToken"
  )}`;
  //Device ID
  try {
    if (!config.headers["device-id"]) {
      let device = store.getState().session.device;
      if (device) {
        config.headers["device-id"] = device.id;
      }
    }
  } catch (e) {}
  return config;
});

// Response inteceptor
localAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (
      error.response?.status === 401 &&
      EXCLUDE_AUTO_LOGIN.includes(history.location.pathname) === false
    ) {
      //If API returns Unauthorized, show Re-Login Dialog
      store.dispatch(openAutoLoginModal());
    }
    return Promise.reject(error);
  }
);

export default localAxios;
