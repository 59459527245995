import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSalesCount, getSaleTotals } from "../../api/local/sale-api";
import CustomInput from "../../components/product-drawer/custom-input/custom-input";
import CustomSelect from "../../components/product-drawer/custom-select/custom-select";
import SalesTable from "../../components/sales/sales-table/SalesTable";
import SelectedChips from "../../components/selected-chips/selected-chips";
import {
  FILTER_BILL_TYPES,
  PAGE_LIMIT,
  PAYMENT_STATUS,
  REPORTS_TYPE,
  SALES_TYPE,
  SALE_BILL_TYPE,
  SALE_SORT_LIST,
  SALE_TABLE_TABS,
  SALE_TABS,
} from "../../constants/appConstants";
import { DownloadBtnIcon, ScArrowLeft, ScArrowRight } from "../../icons";
import { fetchAllBrands, removeSelectedBrand } from "../../store/brand-slice";
import { fetchAllCustomers } from "../../store/customer-slice";
import {
  fetchAllEmployees,
  removeSelectedEmployee,
} from "../../store/employee-slice";
import {
  fetchAllSales,
  removeAllSearchSaleParams,
  removeSearchSaleParams,
  setActiveTab,
  setActiveTableTab,
  setSaleCount,
  setSearchSaleParams,
} from "../../store/sale-slice";
import { calendarFormatDate, formatDate } from "../../utils/format-date";
import * as S from "./AllSales.style";
import PaginationBar from "./../../components/purchase/pagination-bar/pagination-bar";
import { useRef } from "react";
import ShortcutBtn from "../../components/shortcut-btn/ShortcutBtn";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { generateSaleReport } from "../../api/local/reports-api";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { captureException } from "../../crash-reporting";
import { endOfDay, startOfDay } from "date-fns";

const initalDisplayData = {
  brand: {},
  sort: {},
  customer: {},
  employee: {},
  payment_status: {},
  bill_type: {},
  date: "",
};

const initialRange = {
  start_date: moment().startOf("month").unix() * 1000,
  end_date: Date.now(),
};

const AllSales = (props, ref) => {
  const [displayData, setDisplayData] = useState(initalDisplayData);
  // const [dateRange, setDateRange] = useState([selectionRange]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [openDatePicker, _setOpenDatePicker] = useState(false);
  const openDatePickerRef = useRef(openDatePicker);
  const setOpenDatePicker = (data) => {
    openDatePickerRef.current = data;
    _setOpenDatePicker(data);
  };
  const [productSearch, setProductSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const { customers } = useSelector((state) => state.customer);
  const { employees, searchEmpParams } = useSelector((state) => state.employee);
  const brandsData = useSelector((state) => state.brand.brands);
  const { sales, searchSaleParams, saleCount, activeTab, activeTableTab } =
    useSelector((state) => state.sale);
  const [saleTotals, setSaleTotals] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef();
  const customerRef = useRef();
  const brandRef = useRef();
  const employeeRef = useRef();
  const paymentStatusRef = useRef();
  const timeRef = useRef();
  const sortRef = useRef();
  const trackRef = useRef(null);
  const tableRef = useRef();
  const billTypeRef = useRef();
  const [activeRow, _setActiveRow] = useState(0);
  const activeRowRef = React.useRef(activeRow);
  const setActiveRow = (data) => {
    activeRowRef.current = data;
    _setActiveRow(data);
  };

  const selectRef = useRef(false);
  const { handleSnackbarDetails } = useContext(SnackbarContext);

  useEffect(() => {
    handleGetSaleCount();
    handleGetSaleTotals();
    // dispatch(removeSearchSaleParams("status"));
    dispatch(
      setSearchSaleParams({
        type:
          activeTab === SALE_TABS.SALE
            ? `${SALE_TABS.SALE.toUpperCase()},${SALE_TABS.PROFORMA_INVOICE},${
                SALE_TABS.DELIVERY_CHALLAN
              }`
            : activeTab.toUpperCase(),
        status:
          activeTableTab !== SALE_TABLE_TABS.ALL
            ? activeTableTab.toUpperCase()
            : null,
      })
    );

    if (!brandsData?.length) dispatch(fetchAllBrands());
    if (!customers?.length) dispatch(fetchAllCustomers());
    if (!employees?.length) dispatch(fetchAllEmployees(searchEmpParams));

    return () => {
      dispatch(removeAllSearchSaleParams());
      dispatch(removeSelectedBrand());
      dispatch(removeSelectedEmployee());
    };
  }, []);

  useEffect(() => {
    if (searchSaleParams.type) dispatch(fetchAllSales(searchSaleParams));
    if (tableRef.current) {
      tableRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [searchSaleParams]);

  useImperativeHandle(ref, () => {
    return {
      filterBillType: () => onBillType(),
      searchProduct: () => inputRef.current?.focus(),
      searchCustomer: () => onCustomer(),
      searchBrand: () => onBrand(),
      searchEmployee: () => onEmployee(),
      searchStatus: () => onStatus(),
      searchTime: () => onTime(),
      filterSort: () => onSort(),
      newSaleFun: () => navigate("/dashboard/new-sale"),
      clearSearch: () => onClearSearch(),
      returnTab: () => {
        handleSaleType(SALE_TABS.RETURNS);
        dispatch(setActiveTab(SALE_TABS.RETURNS));
      },
      clearFilter: () => handleClearFilter(),
      changeTabs: () => {
        if (activeTab === SALE_TABS.SALE) {
          handleHeaderClick(SALE_TABS.RETURNS, SALE_TABLE_TABS.ALL);
        } else {
          handleHeaderClick(SALE_TABS.SALE, SALE_TABLE_TABS.ALL);
        }
      },
      onNextPage: () => handlePageChange(pageNo + 1),
      onPrevPage: () => handlePageChange(pageNo - 1),
      onNextTab: () => handleNextTabChange(),
      onPrevTab: () => handlePrevTabChange(),
    };
  });

  const onBillType = () => {
    if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "customer") {
      customerRef.current?.click();
    }
    billTypeRef.current?.click();
  };

  const onCustomer = () => {
    if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    customerRef.current?.click();
  };
  const onBrand = () => {
    if (trackRef.current === "customer") {
      customerRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    brandRef.current?.click();
  };

  const onEmployee = () => {
    if (trackRef.current === "customer") {
      customerRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    employeeRef.current?.click();
  };
  const onStatus = () => {
    if (trackRef.current === "customer") {
      customerRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    paymentStatusRef.current?.click();
  };
  const onSort = () => {
    if (trackRef.current === "customer") {
      customerRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (openDatePickerRef.current) {
      setOpenDatePicker(false);
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    sortRef.current?.click();
  };

  const onTime = () => {
    if (trackRef.current === "customer") {
      customerRef.current?.click();
    } else if (trackRef.current === "brand") {
      brandRef.current?.click();
    } else if (trackRef.current === "payment_status") {
      paymentStatusRef.current?.click();
    } else if (trackRef.current === "sort") {
      sortRef.current?.click();
    } else if (trackRef.current === "employee") {
      employeeRef.current?.click();
    } else if (trackRef.current === "bill_type") {
      billTypeRef.current?.click();
    }
    timeRef.current?.querySelector("input").click();
  };

  const handleNextTabChange = () => {
    if (
      activeTab === SALE_TABS.SALE &&
      inputRef.current !== document.activeElement
    ) {
      if (activeTableTab === SALE_TABLE_TABS.ALL) {
        handleTabClick(SALE_TABLE_TABS.PUBLISHED);
      } else if (
        activeTableTab === SALE_TABLE_TABS.PUBLISHED &&
        inputRef.current !== document.activeElement
      ) {
        handleTabClick(SALE_TABLE_TABS.DRAFT);
      }
    }
  };

  const handlePrevTabChange = () => {
    if (
      activeTab === SALE_TABS.SALE &&
      inputRef.current !== document.activeElement
    ) {
      if (activeTableTab === SALE_TABLE_TABS.PUBLISHED) {
        handleTabClick(SALE_TABLE_TABS.ALL);
      } else if (
        activeTableTab === SALE_TABLE_TABS.DRAFT &&
        inputRef.current !== document.activeElement
      ) {
        handleTabClick(SALE_TABLE_TABS.PUBLISHED);
      }
    }
  };

  const onClearSearch = () => {
    if (selectRef.current) return;
    handleProductSearchChange("");
  };
  const handleClearFilter = () => {
    dispatch(removeSearchSaleParams("patient_id"));
    dispatch(removeSearchSaleParams("brand_id"));
    dispatch(removeSearchSaleParams("employee_id"));
    dispatch(removeSearchSaleParams("payment_status"));
    dispatch(removeSearchSaleParams("is_reversed"));
    dispatch(removeSearchSaleParams("sort_by"));
    dispatch(removeSearchSaleParams("is_paid"));
    dispatch(removeSelectedBrand());
    dispatch(removeSelectedEmployee());
    if (Object.hasOwn(SALE_BILL_TYPE, displayData.bill_type?.value)) {
      dispatch(removeSearchSaleParams("bill_type"));
    } else if (Object.hasOwn(SALES_TYPE, displayData.bill_type?.value)) {
      dispatch(
        setSearchSaleParams({
          type: `${SALE_TABS.SALE.toUpperCase()},${
            SALE_TABS.PROFORMA_INVOICE
          },${SALE_TABS.DELIVERY_CHALLAN}`,
        })
      );
    }
    setDisplayData(initalDisplayData);
  };
  const handleGetSaleCount = async () => {
    try {
      let res = await getSalesCount();
      if (res.status === 200) {
        dispatch(setSaleCount(res.data?.entity));
      } else {
        throw new Error("Unable to get sales count");
      }
    } catch (err) {
      captureException(err);
    }
  };
  const handleGetSaleTotals = async () => {
    let date = moment().utc().startOf("day").unix();
    try {
      let res = await getSaleTotals({ created_at: `${date}000` });
      if (res.status === 200) {
        setSaleTotals(res.data);
      } else if (res.status === 404) {
        setSaleTotals(null);
      } else if (!(res.status === 404 || res.status === 200)) {
        setSaleTotals(null);
        throw new Error("Couldn't get sale totals");
      }
    } catch (err) {
      captureException(err);
    }
  };
  const handleSelectChange = (key, item) => {
    if (key === "customer") {
      setDisplayData({ ...displayData, customer: item });
      dispatch(setSearchSaleParams({ patient_id: item.id }));
      dispatch(fetchAllCustomers());
    } else if (key === "payment_status") {
      setDisplayData({ ...displayData, payment_status: item });
      dispatch(
        setSearchSaleParams({
          payment_status: item.value ? "COMPLETED" : "PENDING",
        })
      );
    } else if (key === "sort") {
      setDisplayData({ ...displayData, sort: item });
      // dispatch(removeSearchSaleParams("is_reversed"));
      const payload = { sort_by: item.value };
      if (+item.id % 2 === 0) payload.is_reversed = true;
      if (+item.id % 2 === 1) payload.is_reversed = false;
      dispatch(setSearchSaleParams(payload));
    } else if (key === "bill_type") {
      setDisplayData({ ...displayData, bill_type: item });
      if (Object.hasOwn(SALE_BILL_TYPE, item.value)) {
        dispatch(
          setSearchSaleParams({
            type: "SALE",
            bill_type: item.value,
          })
        );
      } else {
        dispatch(setSearchSaleParams({ type: item, bill_type: null }));
      }
    }

    dispatch(setSearchSaleParams({ page: 1 }));
    setPageNo(1);
  };

  const handleSearchChange = (key, value) => {
    if (key === "customer") dispatch(fetchAllCustomers({ search: value }));
    else if (key === "brand") dispatch(fetchAllBrands(value));
    else if (key === "employee") dispatch(fetchAllEmployees(value));
  };

  const handleDateChange = (item) => {
    setDateRange(item);
    const start = new Date(item[0]).getTime();
    const end = new Date(item[1]).getTime();
    if (start && end) {
      setOpenDatePicker(false);
      setDisplayData({
        ...displayData,
        date: `${formatDate(start)} - ${formatDate(end)}`,
      });
      dispatch(
        setSearchSaleParams({
          start_date: startOfDay(start).getTime(),
          end_date: endOfDay(end).getTime(),
        })
      );
    }
  };
  const delayedFunction = (val, page) => {
    if (val) {
      dispatch(setSearchSaleParams({ search: val }));
    } else dispatch(removeSearchSaleParams("search"));

    dispatch(setSearchSaleParams({ page: 1 }));
    setPageNo(1);
  };
  const delayedQuery = useCallback(debounce(delayedFunction, 300), []);
  const handleProductSearchChange = (val) => {
    setProductSearch(val);
    delayedQuery(val);
  };

  function handleSaleStatus(type) {
    type = type.toUpperCase();
    if (type === "ALL") {
      // dispatch(removeAllSearchSaleParams());
      // dispatch(fetchAllSales(searchSaleParams));
      dispatch(removeSearchSaleParams("status"));
      setDisplayData(initalDisplayData);
    } else {
      // dispatch(removeAllSearchSaleParams());
      dispatch(setSearchSaleParams({ status: type }));
      // dispatch(fetchAllSales(searchSaleParams));
      setDisplayData(initalDisplayData);
    }
  }

  function handleSaleType(type) {
    let t = type.toUpperCase();
    // dispatch(removeAllSearchSaleParams());
    if (type === SALE_TABS.SALE) {
      t = `${SALE_TABS.SALE.toUpperCase()},${SALE_TABS.PROFORMA_INVOICE},${
        SALE_TABS.DELIVERY_CHALLAN
      }`;
    }
    dispatch(setSearchSaleParams({ type: t, page: 1 }));
    // dispatch(fetchAllSales(searchSaleParams));
    setDisplayData(initalDisplayData);
    setPageNo(1);
  }
  const handlePageChange = (page) => {
    if (page === 0) return;
    if (sales.length < PAGE_LIMIT && page > pageNo) return;
    setPageNo(page);
    dispatch(setSearchSaleParams({ page }));
    setActiveRow(0);
  };

  const handleHeaderClick = (head, subHead) => {
    handleSaleType(head);
    dispatch(setActiveTab(head));
    handleSaleStatus(subHead);
    dispatch(setActiveTableTab(subHead));
    setActiveRow(0);
  };

  const handleTabClick = (tab) => {
    handleSaleStatus(tab);
    dispatch(setActiveTableTab(tab));
    setActiveRow(0);
  };

  const handleDownloadSalesReport = async () => {
    handleSnackbarDetails({
      show: true,
      title: "Successful",
      type: "success",
      subtitle:
        "Your report is downloading, and we will notify you once it is downloaded",
    });
    let params = {
      start_date: searchSaleParams.start_date ?? initialRange.start_date,
      end_date: searchSaleParams.end_date ?? initialRange.end_date,
      // type: activeTab.toUpperCase(),
      type: "SALE", // TODO: Only sale allowed
    };
    try {
      let res = await generateSaleReport(params);
      const blob = new Blob([res.data], {
        type: res.headers["Content-Type"],
      });
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "Sale Report.xlsx";
      link.click();
      URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      captureException(err);
    }
  };
  return (
    <S.Wrapper>
      <S.Topbar>
        <S.Chips>
          <S.Chip
            active={activeTab === SALE_TABS.SALE}
            onClick={() => {
              handleHeaderClick(SALE_TABS.SALE, SALE_TABLE_TABS.ALL);
            }}
          >
            {SALE_TABS.SALE}
            <span>{saleCount?.sale?.total}</span>
          </S.Chip>
          <S.Chip
            active={activeTab === SALE_TABS.RETURNS}
            onClick={() => {
              handleHeaderClick(SALE_TABS.RETURNS, SALE_TABLE_TABS.ALL);
            }}
          >
            {SALE_TABS.RETURNS}
            <span>{saleCount?.return?.total}</span>
          </S.Chip>
          <ShortcutBtn text="TAB" />
        </S.Chips>

        <S.RightTopContainer>
          <S.TodayDataContainer>
            <S.Today>Today</S.Today>
            <S.WhiteBoxContainer>
              <S.WhiteBox>
                <div>Total Sales</div>
                <S.BlueBox>₱ {saleTotals?.net_amount ?? 0}</S.BlueBox>
              </S.WhiteBox>
              <S.Bar></S.Bar>
              <S.WhiteBox>
                <div>Total Orders</div>
                <S.BlueBox>{saleTotals?.count ?? 0}</S.BlueBox>
              </S.WhiteBox>
            </S.WhiteBoxContainer>
          </S.TodayDataContainer>
          {activeTab === SALE_TABS.SALE && (
            <S.NewSaleBtn onClick={() => navigate("/dashboard/new-sale")}>
              New Sale (F12)
            </S.NewSaleBtn>
          )}
        </S.RightTopContainer>
      </S.Topbar>
      <S.Body>
        <S.TableTop>
          <S.TableChips>
            <S.TableChip
              active={activeTableTab === SALE_TABLE_TABS.ALL}
              onClick={() => {
                handleTabClick(SALE_TABLE_TABS.ALL);
              }}
            >
              {SALE_TABLE_TABS.ALL}
              <span>{saleCount?.[activeTab?.toLowerCase()]?.total}</span>
            </S.TableChip>
            {activeTab === SALE_TABS.SALE && (
              <S.TableChip
                active={activeTableTab === SALE_TABLE_TABS.PUBLISHED}
                onClick={() => {
                  handleTabClick(SALE_TABLE_TABS.PUBLISHED);
                }}
              >
                {SALE_TABLE_TABS.PUBLISHED}
                <span>{saleCount?.sale?.publish}</span>
              </S.TableChip>
            )}
            {activeTab === SALE_TABS.SALE && (
              <S.TableChip
                active={activeTableTab === SALE_TABLE_TABS.DRAFT}
                onClick={() => {
                  handleTabClick(SALE_TABLE_TABS.DRAFT);
                }}
              >
                {SALE_TABLE_TABS.DRAFT}
                <span>{saleCount?.sale?.draft}</span>
              </S.TableChip>
            )}
            {activeTab === SALE_TABS.SALE && (
              <S.ArrowBox>
                <ShortcutBtn icon={ScArrowLeft} />
                <ShortcutBtn icon={ScArrowRight} />
              </S.ArrowBox>
            )}
          </S.TableChips>
          <S.TableTopRightContainer>
            <S.SortContainer>
              Sort (F4):
              <CustomSelect
                type="sort"
                initalText="Select Sort"
                disableSearch
                data={SALE_SORT_LIST}
                onSelectChange={handleSelectChange}
                selectedOption={displayData?.sort?.name}
                menuWidth="235px"
                position="left"
                clickRef={sortRef}
                selectRef={selectRef}
                trackRef={trackRef}
              />
            </S.SortContainer>
            <S.DownloadContainer onClick={handleDownloadSalesReport}>
              <img src={DownloadBtnIcon} alt="download icon" />
            </S.DownloadContainer>
          </S.TableTopRightContainer>
        </S.TableTop>
        <S.FilterContainer>
          <S.InputBox>
            <CustomInput
              value={productSearch}
              onChange={handleProductSearchChange}
              clearTextIcon
              onClickClear={() => handleProductSearchChange("")}
              searchIcon
              type="text"
              placeholder="Search by product or bill"
              inputRef={inputRef}
              scText="F11"
            />
          </S.InputBox>
          <CustomSelect
            type="bill_type"
            initalText="Bill Type"
            disableSearch
            data={FILTER_BILL_TYPES}
            onSelectChange={handleSelectChange}
            selectedOption={displayData?.bill_type?.name}
            menuWidth="235px"
            clickRef={billTypeRef}
            scText="F10"
            selectRef={selectRef}
            trackRef={trackRef}
          />
          <CustomSelect
            type="customer"
            initalText="Customer"
            onSearchChange={handleSearchChange}
            data={customers}
            onSelectChange={handleSelectChange}
            selectedOption={displayData?.patient?.name}
            menuWidth="235px"
            clickRef={customerRef}
            scText="F9"
            selectRef={selectRef}
            trackRef={trackRef}
          />
          <CustomSelect
            type="payment_status"
            initalText="Payment Status"
            disableSearch
            data={PAYMENT_STATUS}
            onSelectChange={handleSelectChange}
            selectedOption={displayData?.payment_status?.name}
            menuWidth="235px"
            clickRef={paymentStatusRef}
            scText="F6"
            selectRef={selectRef}
            trackRef={trackRef}
          />
          <S.RangeContainer>
            <div ref={timeRef}>
              <DatePicker
                selectsRange={true}
                startDate={Date.parse(calendarFormatDate(startDate))}
                endDate={Date.parse(calendarFormatDate(endDate))}
                onChange={(update) => {
                  handleDateChange(update);
                }}
                isClearable={false}
                dateFormat="dd/MM/yyyy"
                className="sales-date-range-picker"
                placeholderText="Select time period"
              />
            </div>
            <ShortcutBtn text="F5" />
          </S.RangeContainer>
          {/* <OutsideClickHandler onOutsideClick={() => setOpenDatePicker(false)}>
                        <S.DateChooserSelect
                            ref={timeRef}
                            onClick={() => setOpenDatePicker(openDatePicker ? false : true)}
                        >
                            Select time period <img src={CalendarIcon} alt="calendar" />{" "}
                            <ShortcutBtn text="F5" />
                        </S.DateChooserSelect>
                        {openDatePicker && (
                            <S.DatePickerContainer>
                                <DateRange
                                    editableDateInputs={true}
                                    onChange={handleDateChange}
                                    ranges={dateRange}
                                    maxDate={addDays(new Date(), 0)}
                                />
                            </S.DatePickerContainer>
                        )}
                    </OutsideClickHandler> */}
        </S.FilterContainer>
        <S.ChipContainer>
          <SelectedChips
            displayData={displayData}
            setDisplayData={setDisplayData}
            clearSearch={() => handleProductSearchChange("")}
            resetRange={() => setDateRange([null, null])}
          />
        </S.ChipContainer>
        <SalesTable
          activeRow={activeRow}
          activeRowRef={activeRowRef}
          setActiveRow={setActiveRow}
          handleGetSaleTotals={handleGetSaleTotals}
          selectRef={selectRef}
          timeRef={timeRef}
          tableRef={tableRef}
        />
        <S.PageContainer>
          <PaginationBar
            value={pageNo}
            handlePageChange={handlePageChange}
            limit={PAGE_LIMIT}
            type="sale"
          />
        </S.PageContainer>
      </S.Body>
    </S.Wrapper>
  );
};

export default React.forwardRef(AllSales);
