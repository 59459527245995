import React from "react";
import * as S from "../PlanBuyModal.style";
import { GreenConnected, HomeDropLogo } from "../../../icons";
import YellowBox from "../yellow-box/YellowBox";
import BrandingLogo from "../../branding/branding-logo/BrandingLogo";
import BrandingFullLogo from "../../branding/full-logo/BrandingFullLogo";

const PaymentSuccess = () => {
    return (
        <div>
            <S.HeroContainer>
                <S.SpaceBox></S.SpaceBox>
                <S.LogoContainer>
                    <BrandingFullLogo size="120px" />
                </S.LogoContainer>
                <S.SpaceBox></S.SpaceBox>
            </S.HeroContainer>
            <S.CenterText size="470px">
                <S.Heading>Payment Received! Enjoy the Best POS in the World!</S.Heading>
            </S.CenterText>

            <S.PaymentSuccessLogo>
                <img src={GreenConnected} alt="succes" />
            </S.PaymentSuccessLogo>

            <S.CenterText size="440px">
                <S.SubBodyText>Get ready to take your business to new heights with our feature-packed and user-friendly POS solution.</S.SubBodyText>
            </S.CenterText>
            <S.Gap size="103px"></S.Gap>
            <YellowBox label="or call us, Our friendly sales executive is just a phone call away! Dial +91 123456789." curved />
        </div>
    );
};

export default PaymentSuccess;
