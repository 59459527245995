import React from "react";
import { ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../icons";
import * as S from "./DropDown.style";
import { formatDate } from "./../../utils/format-date";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProductToSale, removeAllProducts, setSaleID, setSelectedSale } from "../../store/sale-slice";
import { setSelectedCustomer } from "../../store/customer-slice";

const OrderDropDown = ({ orders, setOpenDropDown, setSearchText }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOrderClick = (item) => {
        setOpenDropDown(false);
        setSearchText("");
        if (item.status === "PUBLISH") {
            dispatch(setSelectedSale(item));
            dispatch(setSelectedCustomer(item.patient));
            navigate("/dashboard/view-sale");
        } else {
            dispatch(removeAllProducts());
            item.products.forEach((item) =>
                dispatch(
                    addProductToSale({
                        ...item,
                        tax: item.tax_percentage,
                    })
                )
            );
            dispatch(setSelectedCustomer(item.patient));
            dispatch(setSaleID(item.id));
            dispatch(setSelectedSale(item));
            navigate("/dashboard/new-sale");
        }
    };
    return (
        <S.Container>
            {Array.isArray(orders) && orders.length > 0 ? (
                orders.map((e, i) => (
                    <S.OrderRow onClick={() => handleOrderClick(e)} key={i}>
                        <S.ContentDiv>
                            <S.ImgDiv>
                                <img
                                    src={e.products[0].gallery?.length ? e.products[0].gallery?.[0] : ProductPlaceholderIcon}
                                    alt="icon"
                                    onError={(e) => {
                                        e.target.src = WifiDisconnectedIcon;
                                    }}
                                />
                            </S.ImgDiv>
                            <div>
                                <S.Brand>
                                    {/* {new Date(e.created_at).toLocaleString(
                                    "en-GB",
                                    {
                                        hour12: true,
                                    }
                                )} */}
                                    {formatDate(e.created_at)}
                                </S.Brand>
                                <S.CustName>₱. {e.total_sale_price}</S.CustName>
                                <S.Quant>{e.products.length} items purchased</S.Quant>
                            </div>
                        </S.ContentDiv>
                        <S.TimeDiv>{moment(e.created_at).format("LT")}</S.TimeDiv>
                    </S.OrderRow>
                ))
            ) : (
                <S.Empty>No results found</S.Empty>
            )}
        </S.Container>
    );
};

export default OrderDropDown;
