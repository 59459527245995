import React from "react";
import { DiscountOption, PRICE_TYPE, UNITS_LIST } from "../../../constants/appConstants";
import CustomInput from "../custom-input/custom-input";
import CustomSelect from "../custom-select/custom-select";
import Label from "../label/Label";
import "./style.css";
import * as S from "./basic-information.styles";
import ToggleSwitch from "../../switch/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../../utils/format-date";
import ProductDiscount from "../../discount/product-discount/ProductDiscount";
import { getPurchaseCompareToPrice, toDecimalPlace } from "../../../utils/global-fn";

const BasicInformation = ({ formData, setFormData, displayData, setDisplayData, type, priceRef, selectRef }) => {
    const handleSelectChange = (key, item) => {
        if (key === "brand") {
            setFormData({ ...formData, brand: item.id });
            setDisplayData({ ...displayData, brand: item });
        } else if (key === "category") {
            setFormData({ ...formData, category: item.id });
            setDisplayData({ ...displayData, category: item });
        } else if (key === "age_restricted") {
            setFormData({ ...formData, age_restricted: item.value });
            setDisplayData({ ...displayData, age_restricted: item });
        } else if (key === "sale_price_with_tax") {
            setFormData({ ...formData, sale_price_with_tax: item.value });
            setDisplayData({ ...displayData, sale_price_with_tax: item });
        } else if (key === "purchase_price_with_tax") {
            let purchasePrice = getPurchaseCompareToPrice(displayData.purchase_price, {
                ...displayData,
                purchase_price_with_tax: item.value,
                tax: displayData.tax.value,
            });
            setFormData({ ...formData, purchase_price_with_tax: item.value, purchase_price: purchasePrice });
            setDisplayData({ ...displayData, purchase_price_with_tax: item, purchase_price: purchasePrice });
        } else if (key === "base_unit" || key === "secondary_unit") {
            let display_pl = { ...displayData?.alternate_unit, [key]: item };
            setDisplayData({ ...displayData, alternate_unit: display_pl });

            let form_pl = { ...formData?.alternate_unit, [key]: item.value };
            setFormData({ ...formData, alternate_unit: form_pl });
        }
    };

    const updateData = (key, value) => {
        if (key === "price") {
            let purchasePrice = parseFloat((value * 0.92).toFixed(2));
            let sp = updateSalePrice(displayData.discount, displayData.activeDiscount, value, value);
            setFormData({
                ...formData,
                [key]: value,
                sale_price: sp,
                purchase_price: purchasePrice,
            });
            setDisplayData({
                ...displayData,
                [key]: value,
                sale_price: sp,
                purchase_price: purchasePrice,
            });
        } else if (key === "base_conversion" || key === "secondary_conversion") {
            let display_pl = { ...displayData?.alternate_unit, [key]: value };
            setDisplayData({ ...displayData, alternate_unit: display_pl });

            let form_pl = { ...formData?.alternate_unit, [key]: value };
            setFormData({ ...formData, alternate_unit: form_pl });
        } else if (key === "batch_number") {
            setFormData({ ...formData, [key]: value.toUpperCase() });
            setDisplayData({ ...displayData, [key]: value.toUpperCase() });
        } else if (key === "sale_price") {
            let disc = updateDiscount(displayData.discount, displayData.activeDiscount, displayData.price, value);
            setFormData({ ...formData, [key]: value, discount: disc });
            setDisplayData({ ...displayData, [key]: value, discount: disc });
        } else if (key === "purchase_price") {
            value = getPurchaseCompareToPrice(value, {
                ...displayData,
                purchase_price_with_tax: displayData.purchase_price_with_tax.value,
                tax: displayData.tax.value,
            });
            setFormData({ ...formData, [key]: value });
            setDisplayData({ ...displayData, [key]: value });
        } else {
            setFormData({ ...formData, [key]: value });
            setDisplayData({ ...displayData, [key]: value });
        }
    };

    const handleDateChange = (key, item) => {
        setFormData({ ...formData, [key]: item });
        setDisplayData({ ...displayData, [key]: item });
    };

    const handleSwitchChange = (event, key) => {
        let { checked } = event.target;
        setFormData({ ...formData, [key]: checked });
        setDisplayData({ ...displayData, [key]: checked });
    };

    const setActiveDiscount = (value) => {
        let sp = updateSalePrice(displayData.discount, value, displayData.price, displayData.sale_price);

        setFormData({ ...formData, activeDiscount: value, sale_price: sp });
        setDisplayData({ ...displayData, activeDiscount: value, sale_price: sp });
    };
    const handleDiscountChange = (e) => {
        let { value } = e.target;
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
            value = value.substr(1);
        }
        if (!(value === "" || regex.test(value))) return;

        let mrp = displayData.price;
        if (displayData.activeDiscount === DiscountOption.FLAT) {
            if (Number(value) > Number(mrp)) {
                value = mrp;
            }
        }
        if (displayData.activeDiscount === DiscountOption.PERCENTAGE) {
            if (value > 100) {
                value = 100;
            }
        }

        let sp = updateSalePrice(value, displayData.activeDiscount, displayData.price, displayData.sale_price);

        setFormData({ ...formData, discount: value || 0, sale_price: sp });
        setDisplayData({ ...displayData, discount: value || 0, sale_price: sp });
    };

    const updateSalePrice = (discount, discountType, price, salePrice) => {
        let sp = salePrice;

        if (price && discount) {
            let discountVal = discountType === DiscountOption.FLAT ? parseFloat(discount) : Number(price) * (discount / 100);
            sp = Number(price) - Number(discountVal);
        }
        return sp;
    };

    const updateDiscount = (discount, discountType, price, salePrice) => {
        let disc = discount;
        if (price && salePrice) {
            let diff = Number(price) - Number(salePrice);
            if (discountType === DiscountOption.FLAT) {
                disc = diff;
            } else if (discountType === DiscountOption.PERCENTAGE) {
                let d = (diff / Number(price)) * 100;
                disc = toDecimalPlace(d);
            }
        }
        return disc;
    };

    return (
        <S.Container>
            <S.SelectContainer>
                <S.FlexContainer>
                    <S.Select>
                        <Label text="Price" />
                        <CustomInput
                            value={displayData.price}
                            onChange={(val) => updateData("price", val)}
                            type="number"
                            decimalAllow
                            inputRef={priceRef}
                        />
                    </S.Select>
                    <S.Select>
                        <Label text="Discount" />
                        <ProductDiscount
                            activeDiscount={displayData.activeDiscount}
                            setActiveDiscount={setActiveDiscount}
                            discount={displayData.discount}
                            handleInputChange={handleDiscountChange}
                        />
                    </S.Select>
                    <S.Select>
                        <Label text="Current Stock" />
                        <CustomInput
                            value={displayData.stock}
                            onChange={(val) => updateData("stock", val)}
                            type="number"
                            counters
                            counterUp={() => updateData("stock", (+displayData.stock + 1).toString())}
                            counterDown={() => updateData("stock", (+displayData.stock - 1).toString())}
                            negativeAllow
                            decimalAllow
                        />
                    </S.Select>
                    <S.Select>
                        <Label text="Low Stock Warning" />
                        <CustomInput
                            value={displayData.low_stock_warning}
                            onChange={(val) => updateData("low_stock_warning", val)}
                            type="number"
                            negativeAllow
                            decimalAllow
                            counters
                            counterUp={() => updateData("low_stock_warning", (+displayData.low_stock_warning + 1).toString())}
                            counterDown={() => updateData("low_stock_warning", (+displayData.low_stock_warning - 1).toString())}
                        />
                    </S.Select>
                </S.FlexContainer>
                <S.FlexContainer>
                    <S.Select>
                        <Label text="Sale Price" />
                        <S.SubFlexContainer>
                            <CustomInput
                                value={displayData.sale_price}
                                onChange={(val) => updateData("sale_price", val)}
                                type="number"
                                decimalAllow
                            />
                            <CustomSelect
                                type="sale_price_with_tax"
                                disableSearch
                                data={PRICE_TYPE}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.sale_price_with_tax?.name}
                                selectRef={selectRef}
                                size="sm"
                            />
                        </S.SubFlexContainer>
                    </S.Select>
                    <S.Select>
                        <Label text="Purchase Price" />
                        <S.SubFlexContainer>
                            <CustomInput
                                value={displayData.purchase_price}
                                onChange={(val) => updateData("purchase_price", val)}
                                type="number"
                                decimalAllow
                            />
                            <CustomSelect
                                type="purchase_price_with_tax"
                                disableSearch
                                data={PRICE_TYPE}
                                onSelectChange={handleSelectChange}
                                selectedOption={displayData?.purchase_price_with_tax?.name}
                                selectRef={selectRef}
                                size="sm"
                            />
                        </S.SubFlexContainer>
                    </S.Select>
                </S.FlexContainer>

                {/* <S.Dashedline></S.Dashedline> */}
                <div>
                    <S.FlexContainer>
                        <S.Select>
                            <Label text="Wholesale Price" />
                            <CustomInput
                                value={displayData.wholesale_price}
                                onChange={(val) => updateData("wholesale_price", val)}
                                type="number"
                                decimalAllow
                            />
                        </S.Select>
                        <S.Select>
                            <Label text="Wholesale Quantity" />
                            <CustomInput
                                value={displayData.wholesale_quantity}
                                onChange={(val) => updateData("wholesale_quantity", val)}
                                type="number"
                                decimalAllow
                                counters
                                counterUp={() => updateData("wholesale_quantity", (+displayData.wholesale_quantity + 1).toString())}
                                counterDown={() => updateData("wholesale_quantity", (+displayData.wholesale_quantity - 1).toString())}
                            />
                        </S.Select>
                    </S.FlexContainer>
                    <S.Hint>Wholesale price will be applied when the orders exceed wholesale quantity value.</S.Hint>
                </div>
                {/* <S.Dashedline></S.Dashedline> */}
                <div>
                    <S.FlexContainer>
                        <S.ToggleFlex>
                            <Label text="Enable Alternate Unit" />
                            <ToggleSwitch
                                type="enable_alternate_unit"
                                checked={displayData.enable_alternate_unit}
                                handleChange={(e, type) => handleSwitchChange(e, type)}
                            />
                        </S.ToggleFlex>
                        {displayData.enable_alternate_unit && (
                            <S.UnitFlex>
                                <S.AdjustHeight>
                                    <Label text="" />
                                    <CustomInput
                                        value={displayData?.alternate_unit?.base_conversion}
                                        onChange={(val) => updateData("base_conversion", val)}
                                        type="number"
                                        decimalAllow
                                    />
                                </S.AdjustHeight>
                                <S.Select>
                                    <Label text="Base Unit" />
                                    <CustomSelect
                                        type="base_unit"
                                        localSearch
                                        data={UNITS_LIST}
                                        onSelectChange={handleSelectChange}
                                        selectedOption={displayData?.alternate_unit?.base_unit?.name}
                                        selectRef={selectRef}
                                        size="sm"
                                        position={"top"}
                                    />
                                </S.Select>
                                <S.AdjustHeight>
                                    <Label text="=" />
                                    <CustomInput
                                        value={displayData?.alternate_unit?.secondary_conversion}
                                        onChange={(val) => updateData("secondary_conversion", val)}
                                        type="number"
                                        decimalAllow
                                    />
                                </S.AdjustHeight>
                                <S.Select>
                                    <Label text="Alt Unit" />
                                    <CustomSelect
                                        type="secondary_unit"
                                        localSearch
                                        data={UNITS_LIST}
                                        onSelectChange={handleSelectChange}
                                        selectedOption={displayData?.alternate_unit?.secondary_unit?.name}
                                        selectRef={selectRef}
                                        size="sm"
                                        position={"top"}
                                    />
                                </S.Select>
                            </S.UnitFlex>
                        )}
                    </S.FlexContainer>
                    <S.Hint>
                        Alternate unit helps you to sell a product in multiple units. If 1 cartoon contains 12 pieces, then select 'Cartoon' in the
                        Base Alternate Unit field, "12' in the Alt. quantity field and 'pieces in the Alt. Unit field and vice versa.
                    </S.Hint>
                </div>
                <S.FlexContainer>
                    <S.Select>
                        <Label text="Batch Number" />
                        <CustomInput value={displayData.batch_number} onChange={(val) => updateData("batch_number", val)} type="text" />
                    </S.Select>
                    <S.Select>
                        <Label text="Mfg. Date" />
                        <DatePicker
                            selected={Date.parse(calendarFormatDate(displayData.manufacturing_date ?? null))}
                            onChange={(date) => handleDateChange("manufacturing_date", date)}
                            dateFormat="dd/MM/yyyy"
                            className="inventory-date-picker"
                            showMonthDropdown
                            isClearable={displayData.manufacturing_date}
                        />
                    </S.Select>
                    <S.Select>
                        <Label text="Expiry Date" />
                        <DatePicker
                            selected={Date.parse(calendarFormatDate(displayData.expiry_date ?? null))}
                            onChange={(date) => handleDateChange("expiry_date", date)}
                            dateFormat="dd/MM/yyyy"
                            className="inventory-date-picker"
                            showMonthDropdown
                            isClearable={displayData.expiry_date}
                        />
                    </S.Select>
                </S.FlexContainer>
            </S.SelectContainer>
        </S.Container>
    );
};

export default BasicInformation;
