import React from "react";
import * as S from "./style";

const VersionBox = () => {
    return (
        <S.Wrapper>
            <S.TextBox>
                <S.Text>v{process.env.REACT_APP_VERSION}</S.Text>
            </S.TextBox>
        </S.Wrapper>
    );
};

export default VersionBox;
