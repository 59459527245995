import React from "react";
import { useEffect } from "react";
import { CloseModalIcon } from "../../../icons";

import * as S from "./confirmation-dialog.styles";

const ConfirmationDialog = ({ closeModal, data, handleSubmit, actionType, handleDraft, open }) => {
    const { title, bodyText } = data;

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    const handleDetectKeyDown = (e) => {
        if (open) {
            if (e.keyCode === 13) {
                //Enter key
                e.preventDefault();
                handleSubmit();
            } else if (e.keyCode === 27) {
                closeModal();
            }
        }
    };
    return (
        <S.Container>
            <S.Header>
                <S.HeaderText>{title}</S.HeaderText>
                <img onClick={closeModal} src={CloseModalIcon} alt="close" />
            </S.Header>
            <S.Body>
                <S.BodyText>{bodyText}</S.BodyText>
                <S.BtnContainer>
                    {actionType === "add" ? (
                        <S.Submit onClick={handleDraft}>Draft</S.Submit>
                    ) : (
                        <div></div>
                    )}
                    <S.RightBtnDiv>
                        <S.Cancel onClick={closeModal}>Cancel</S.Cancel>
                        <S.Submit onClick={handleSubmit}>OK</S.Submit>
                    </S.RightBtnDiv>
                </S.BtnContainer>
            </S.Body>
        </S.Container>
    );
};

export default ConfirmationDialog;
