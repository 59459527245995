import styled from "@emotion/styled/macro";

export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    border-radius: 8px;
    width: 840px;
    max-width: 960px;
    // max-height: 650px;
    height: 650px;
    // overflow: auto;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    // height: 68px;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;

    img {
        // margin-right: 7px;
        cursor: pointer;
    }
`;

export const Footer = styled.div`
    display: flex;
    padding: 0 16px;
    justify-content: flex-end;
    align-items: center;
`;

export const HeaderText = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #334155;

    img {
        cursor: pointer;
    }
`;

export const HeaderBtn = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 10px 16px;
    height: fit-content;
    cursor: pointer;
`;

export const Body = styled.div`
    height: calc(100% - 116px);
`;

export const BodyLeft = styled.div`
    max-width: 215px;
    width: 215px;
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #2d68fe;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 6px 0 16px;
        cursor: pointer;
    }
`;

export const ImageContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 1000px;
    height: 102px;
    width: 102px;
    overflow: hidden;

    img[alt*="product"] {
        width: 100%;
        height: 100%;
    }

    input {
        display: none !important;
    }
`;

export const AddImage = styled.div`
    background: #e2e8f0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #94a3b8;
`;

export const InputContainer = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

export const BodyRight = styled.div`
    flex: 1;
`;

export const TabsContainer = styled.div`
    display: flex;
    gap: 41px;
    box-sizing: border-box;
    padding: 16px;
`;
export const ActiveTabsContainer = styled.div`
    display: flex;
    gap: 41px;
    // margin-bottom: 22px;
    margin: 22px;
`;

export const Tab = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    padding-bottom: 8px;
    border-bottom: 2px solid ${(props) => (props.active ? "#3B82F6" : "#fff")};
    cursor: pointer;
`;
