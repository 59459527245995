import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { DrawerContext } from "../../DrawerContextProvider/DrawerProvider";
import { CloseModalIcon, HamburgerLogo, HeaderSearchIcon, NavbarHelpIcon, NavbarNotificationIcon, NavbarUserLogo } from "../../icons";
import DropDown from "../globalSearch/DropDown";
import SelectProductModal from "../select-product-modal/select-product-modal";
import StoreLogo from "../store-logo/StoreLogo";
import ConnectedChip from "./ConnectedChip/ConnectedChip";
import { Drawer } from "@mui/material";

import "./Header.scss";
import ProductDrawer from "../product-drawer/product-drawer";
import ShortcutBtn from "../shortcut-btn/ShortcutBtn";
import PlanChip from "./PlanChip/PlanChip";
import Nudge from "../nudge/Nudge";
import { useNavigate } from "react-router-dom";
import { generateSKU } from "../../utils/sku-upc-generator";
import OutsideClickHandler from "react-outside-click-handler";

const Header = ({
    handleGlobalSearch,
    setSearchText,
    searchText,
    globalSearchData,
    openDropDown,
    setOpenDropDown,
    globalRef,
    openDrawer,
    setOpenDrawer,
}) => {
    const drawerDetails = useContext(DrawerContext);
    const store = useSelector((state) => state.session.store);
    const { newOrdersCount, cronOrders } = useSelector((state) => state.onlineSale);
    const setDrawer = () => {
        drawerDetails.setLeftDrawerStatus(!drawerDetails.leftDrawerOpen);
    };
    const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
    const [skuProducts, setSkuProducts] = useState(null);
    const [productData, setProductData] = useState(null);
    const navigate = useNavigate();
    const [productDrawerType, setProductDrawerType] = useState("edit");
    const [isNewSKU, setIsNewSKU] = useState(false);
    const [openNewCustModal, setOpenNewCustModal] = useState({
        state: false,
        phoneNumber: null,
    });

    function handleSearch(e) {
        setSearchText(e.target.value);
        handleGlobalSearch(e);
    }
    const handleNavigateToOnlineOrders = () => {
        navigate("/dashboard/online-orders");
    };

    const onAddNewSKU = (productData) => {
        //Close the existing drawer
        setOpenDrawer(false);
        //Open the drawer with new data
        setProductDrawerType("add");
        setProductData({
            ...productData,
            sku: generateSKU(),
            batch_number: "",
            manufacturing_date: null,
            expiry_date: null,
        });
        setIsNewSKU(true);
        setOpenDrawer(true);
    };

    return (
        <>
            <SelectProductModal
                open={openSelectProductModal}
                onClose={() => setOpenSelectProductModal(false)}
                data={skuProducts}
                openEditDrawer={(product) => {
                    setProductData(product);
                    setOpenDrawer(true);
                }}
            />
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                    setIsNewSKU(false);
                    setProductDrawerType("edit");
                }}
                className="right-drawer"
            >
                <ProductDrawer
                    type={productDrawerType}
                    productData={productData}
                    onCloseDrawer={() => {
                        setOpenDrawer(false);
                        setIsNewSKU(false);
                        setProductDrawerType("edit");
                    }}
                    open={openDrawer}
                    onAddNewSKU={onAddNewSKU}
                    isNewSKU={isNewSKU}
                />{" "}
            </Drawer>
            <div className="app-header">
                <div className="app-header__main">
                    <div className="app-header__main__left">
                        <img onClick={setDrawer} className="hamburger-icon" src={HamburgerLogo} alt="hamburger" />
                        {/* <img className="store-icon" src={StoreLogo} alt="store" /> */}
                        <div className="store-icon">
                            <StoreLogo store={store} size={"34px"} color={"#0f172a"} />
                        </div>
                        <div className="app-header__main__left__name">{store?.store_name}</div>
                    </div>
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            if (!openDrawer && !openNewCustModal.state) {
                                setOpenDropDown(false);
                                setSearchText("");
                            }
                        }}
                    >
                        <div className="app-header__main__searchdiv">
                            <div className="app-header__main__searchdiv__search">
                                {searchText === "" && <img src={HeaderSearchIcon} alt="search" />}
                                <input type="text" placeholder="Search" onChange={handleSearch} value={searchText} ref={globalRef} />
                                {searchText !== "" && <img src={CloseModalIcon} alt="close" onClick={() => setSearchText("")} />}
                                <ShortcutBtn text="F1" />
                            </div>
                            {openDropDown && searchText && globalSearchData && (
                                <DropDown
                                    globalSearchData={globalSearchData}
                                    setOpenDropDown={setOpenDropDown}
                                    setSearchText={setSearchText}
                                    open={openDropDown && searchText && globalSearchData}
                                    setOpenSelectProductModal={setOpenSelectProductModal}
                                    setSkuProducts={setSkuProducts}
                                    openDrawer={openDrawer}
                                    setOpenDrawer={setOpenDrawer}
                                    productData={productData}
                                    setProductData={setProductData}
                                    openNewCustModal={openNewCustModal}
                                    setOpenNewCustModal={setOpenNewCustModal}
                                />
                            )}
                        </div>
                    </OutsideClickHandler>
                </div>
                <div className="app-header__right">
                    <PlanChip />
                    {newOrdersCount ? <Nudge title="Online Orders" count={newOrdersCount} onClick={handleNavigateToOnlineOrders} /> : <></>}
                    <ConnectedChip />
                </div>
                {/* <div className="app-header__right">
                <img className="app-header__right--help-icon" src={NavbarHelpIcon} alt="help" />
                <img
                    className="app-header__right--notification-icon"
                    src={NavbarNotificationIcon}
                    alt="notification"
                />
                <img
                    onClick={openRightDrawer}
                    className="app-header__right--user-logo"
                    src={NavbarUserLogo}
                    alt="user"
                />
                <img
                    className="app-header__right--arrow-down-icon"
                    src={ArrowDownIcon}
                    alt="arrow-down"
                />
            </div> */}
            </div>
        </>
    );
};

export default Header;
