import {
    ANALYTICS_API,
    CUSTOMER_DATA_API,
    OVERALL_DATA_API,
    PRODUCT_DATA_API,
    PROFIT_LOSS_API,
} from "../../constants/apiRoutes";
import axios from "./axios";

const getProfitLossReport = async (params = {}) => {
    return await axios.get(`${ANALYTICS_API}/${PROFIT_LOSS_API}`, {
        params: params,
    });
};

const getOverallData = async (params = {}) => {
    return await axios.get(`${ANALYTICS_API}/${OVERALL_DATA_API}`, {
        params: params,
    });
};

const getCustomerReportData = async (params = {}) => {
    return await axios.get(`${ANALYTICS_API}/${CUSTOMER_DATA_API}`, {
        params: params,
    });
};
const getPerProductData = async (params = {}) => {
    return await axios.get(`${ANALYTICS_API}/${PRODUCT_DATA_API}`, {
        params: params,
    });
};

export { getProfitLossReport, getOverallData, getCustomerReportData, getPerProductData };
