import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
`;
export const LeftContainer = styled.div`
    width: 43%;
`;
export const RightContainer = styled.div`
    width: 57%;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px 46px 40px 48px;
`;

export const TabContainer = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    &div: nth-of-type(1) {
        color: #475569;
    }
    &div: nth-of-type(2) {
        color: #94a3b8;
    }
`;
export const BackBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #263238;
    border-radius: 4px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    margin-bottom: 22px;
`;
