import styled from "@emotion/styled/macro";
export const Table = styled.div`
    height: 420px;
    padding: 0 16px 16px;

    & input {
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        // margin-left: -50px;
        cursor: pointer;
    }
`;
export const TableHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f5f9;
    padding-right: 7px;

    & input {
        ${(props) => !props.data && "display: none"}
    }
`;

export const Th = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    text-align: ${(props) => (props.align ? props.align : "center")};
    padding: 9px 13px;
    height: 36px;
`;
export const TableBody = styled.div`
    border: 1px solid #e2e8f0;
    height: 378px;
    overflow: hidden;
    overflow-y: auto;
`;
export const TableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Rowdata = styled.div`
    flex: ${(props) => (props.flex ? props.flex : "1")};
    text-align: ${(props) => (props.align ? props.align : "center")};
    font-weight: ${(props) => (props.detail ? "400" : "500")};
    font-size: 12px;
    line-height: 16px;
    padding: 16px 13px;
    color: ${(props) => (props.detail ? "#2D68FE" : "#334155")};
    cursor: ${(props) => props.detail && "pointer"};
    height: 48px;
`;
