import React, { useEffect, useRef } from "react";
import * as S from "../LoginScreen.style";

const VerifyOtp = ({ loginData, handleInputChange, onSubmit }) => {
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current?.focus();
        }
    }, []);

    return (
        <div>
            <S.LoginHead>Enter OTP</S.LoginHead>
            <S.LoginContainer>
                <S.InputFIelds>
                    <input type="text" name="otp" onChange={(e) => handleInputChange(e)} value={loginData.otp} autoFocus ref={inputElement} />
                </S.InputFIelds>
                <S.ConfirmBtn onClick={onSubmit}>Confirm</S.ConfirmBtn>
            </S.LoginContainer>
        </div>
    );
};

export default VerifyOtp;
