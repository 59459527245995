import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px 12px 16px;
    // height: calc(100vh - 211px);
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;
export const HeadRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 9px 8px 9px;
    background: #f1f5f9;
`;

export const Head = styled.div`
    flex: ${(props) => props.flexValue};
    text-align: ${(props) => (props.center ? "center" : "left")};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    margin-right: ${(props) => props.mr};
`;
export const TableBody = styled.div`
    border: 1px solid #e2e8f0;
    // height: calc(100vh - 255px);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
`;
export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;

export const TableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 9px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    background: ${(props) => (props.active ? "#EEF5FF" : "#fff")};

    &:hover {
        background: #eef5ff;
    }
    & > div {
        position: relative;
    }
`;
export const Td = styled.div`
    flex: ${(props) => props.flexValue};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    margin-right: ${(props) => props.mr};
    text-align: ${(props) => (props.center ? "center" : "left")};
`;
export const LastTd = styled.div`
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img {
        width: 24px;
        height: 24px;
    }
`;
export const OptionsContainer = styled.div`
    position: absolute;
    right: 0;
    width: 162px;
    top: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 1px;
    cursor: pointer;
    z-index: 100;
`;

export const Option = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.24px;
    color: #1c1b1f;
    padding: 12px 16px;
    &:hover {
        background: #eef2f8;
    }
`;
