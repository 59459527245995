import React, { useEffect, useRef, useState } from "react";
import { PasswordEyeIcon, PasswordNoEyeIcon, WhiteArrowRight } from "../../icons";
import * as S from "./style";
import { useDispatch, useSelector } from "react-redux";
import { checkValidGstAfterChange, validEmail } from "../../utils/validity-fns";
import { errorNotify, successNotify } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "../../api/server/otpApi";
import { showLoader, hideLoader } from "../../store/app-slice";
import { captureException } from "../../crash-reporting";
import { checkAccountExists } from "../../api/server/storeApi";
import StoreTypeSelect from "../store-type-select/StoreTypeSelect";
import { handleGetGstInfo } from "../../utils/api-fns";

const initialErrorInput = {
    gst_number: false,
};
const PersonalDetails = ({ onSubmit, initialData, otpState }) => {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [personalData, setPersonalData] = useState(initialData);
    const [errorInput, setErrorInput] = useState(initialErrorInput);
    const navigate = useNavigate();
    const { appOnline } = useSelector((state) => state.app);
    const { gstCache } = useSelector((state) => state.apiCache);

    const selectRef = useRef(false);
    const typeRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const gstRef = useRef();

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, []);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 9) {
            if (gstRef.current === document.activeElement) {
                e.preventDefault();
                typeRef.current?.click();
            } else if (selectRef.current) {
                e.preventDefault();
                typeRef.current?.click();
                nameRef.current?.focus();
            }
        }
    };

    async function handleInputChange(e) {
        let { name, value } = e.target;
        let gstData = null;
        if (name === "phone" && value.length > 10) {
            return;
        } else if (name === "gst_number") {
            if (value.length > 15) return;
            value = value.toUpperCase();
            let valid = checkValidGstAfterChange(value);
            setErrorInput({ ...errorInput, [name]: !valid });
            if (value.length === 15) {
                setPersonalData({ ...personalData, [name]: value });
                gstData = await handleGetGstInfo(value, appOnline, gstCache, dispatch, false);
            }
        }
        if (gstData && name === "gst_number") {
            let { company_name, address } = gstData;
            setPersonalData({ ...personalData, [name]: value, store_name: company_name, store_address: address });
        } else {
            setPersonalData({ ...personalData, [name]: value });
        }
    }

    const onNextClick = async (event) => {
        event.preventDefault();
        if (!personalData.store_name || personalData.store_name.length < 4) {
            errorNotify("Store name is invalid");
            return;
        }
        // if (!personalData.contact_person || personalData.contact_person.length < 4) {
        //     errorNotify("Contact person name is invalid");
        //     return;
        // }
        if (!personalData.phone || personalData.phone.length !== 10) {
            errorNotify("Phone number is invalid");
            return;
        }
        if (!personalData.password || personalData.password.length < 8) {
            errorNotify("Password must be atleast 8 characters");
            return;
        }
        if (personalData.email && validEmail(personalData.email) !== true) {
            errorNotify("Email is invalid");
            return;
        }
        personalData.country_code = "+63";

        if (otpState.phone === personalData.phone && otpState.otp_verified === true) {
            onSubmit(personalData);
            return;
        }
        dispatch(showLoader());
        try {
            let checkResponse = await checkAccountExists({
                phone: personalData.phone,
                email: personalData.email,
            });
            if (checkResponse.status === 200) {
                if (checkResponse.data.user_exist) {
                    errorNotify("User is already registered");
                    return;
                } else if (checkResponse.data.phone_exist) {
                    errorNotify("Phone number is already registered");
                    return;
                } else if (checkResponse.data.email_exist) {
                    errorNotify("Email is already registered");
                    return;
                } else {
                    handleOTPSend();
                }
            } else {
                throw new Error(checkResponse.data?.message);
            }
        } catch (err) {
            captureException(err);
            errorNotify("Some error occurred");
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleOTPSend = async () => {
        try {
            let response = await sendOTP({
                context: "HOMEDROP_POS",
                country_code: personalData.country_code,
                phone_number: personalData.phone,
            });
            if (response.status === 200 && response.data === true) {
                successNotify(`An OTP is sent to ${personalData.phone}`);
                onSubmit(personalData);
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data?.message);
            }
        } catch (error) {
            captureException(error);
            errorNotify("Some error occurred");
        } finally {
            dispatch(hideLoader());
        }
    };

    const handleSelectChange = (item) => {
        setPersonalData({ ...personalData, store_type: item });
    };

    return (
        <S.Wrapper onSubmit={(e) => onNextClick(e)}>
            <div>
                <S.TabHead>Let’s get started</S.TabHead>
                <S.InputsBodyContainer>
                    <S.MultipleInputsContainer>
                        <S.InputContainer>
                            <S.Labels>Enter TAX Number</S.Labels>
                            <S.InputFIelds>
                                <input
                                    type="text"
                                    value={personalData?.gst_number}
                                    name="gst_number"
                                    onChange={(e) => handleInputChange(e)}
                                    autoFocus
                                    ref={gstRef}
                                />
                            </S.InputFIelds>
                            {errorInput.gst_number && <S.Error>Invalid TAX Number</S.Error>}
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Labels>
                                Business Type <span>*</span>
                            </S.Labels>
                            <StoreTypeSelect
                                selectedOption={personalData?.store_type?.name}
                                selectRef={selectRef}
                                typeRef={typeRef}
                                onChange={handleSelectChange}
                                size="lg"
                            />
                        </S.InputContainer>
                    </S.MultipleInputsContainer>
                    <S.InputContainer>
                        <S.Labels>
                            What’s Your Business Name? <span>*</span>
                        </S.Labels>
                        <S.InputFIelds>
                            <input
                                type="text"
                                value={personalData?.store_name}
                                name="store_name"
                                onChange={(e) => handleInputChange(e)}
                                ref={nameRef}
                            />
                        </S.InputFIelds>
                    </S.InputContainer>
                    <S.MultipleInputsContainer>
                        {/* <S.InputContainer>
                            <S.Labels>
                                Owner's Name <span>*</span>
                            </S.Labels>
                            <S.InputFIelds>
                                <input
                                    type="text"
                                    value={personalData?.contact_person}
                                    name="contact_person"
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </S.InputFIelds>
                        </S.InputContainer> */}
                        <S.InputContainer>
                            <S.Labels>
                                Phone Number <span>*</span>
                            </S.Labels>
                            <S.PhoneInputField>
                                <S.InputFIelds>+63</S.InputFIelds>
                                <S.InputFIelds>
                                    <input
                                        type="number"
                                        value={personalData?.phone}
                                        name="phone"
                                        onChange={(e) => handleInputChange(e)}
                                        ref={phoneRef}
                                    />
                                </S.InputFIelds>
                            </S.PhoneInputField>
                        </S.InputContainer>
                        <S.InputContainer>
                            <S.Labels>
                                Create Password <span>*</span>
                            </S.Labels>
                            <S.InputFIelds>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={personalData?.password}
                                    name="password"
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <img
                                    src={showPassword ? PasswordNoEyeIcon : PasswordEyeIcon}
                                    alt="password eye"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </S.InputFIelds>
                        </S.InputContainer>
                    </S.MultipleInputsContainer>
                    {/* <S.MultipleInputsContainer>
                        
                        <S.InputContainer>
                            <S.Labels>Email</S.Labels>
                            <S.InputFIelds>
                                <input type="text" value={personalData?.email} name="email" onChange={(e) => handleInputChange(e)} />
                            </S.InputFIelds>
                        </S.InputContainer>
                    </S.MultipleInputsContainer> */}
                </S.InputsBodyContainer>
            </div>
            <S.DoubleButtonFooter>
                <S.BottomText>
                    <div>Already a registered user?</div>
                    <S.CreateAccount onClick={() => navigate("/login")}>Login</S.CreateAccount>
                </S.BottomText>
                <S.DarkButton type="submit">
                    <div>Next</div>
                    <img src={WhiteArrowRight} alt="" />
                </S.DarkButton>
            </S.DoubleButtonFooter>
        </S.Wrapper>
    );
};

export default PersonalDetails;
