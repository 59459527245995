import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";

import * as S from "./select-product-modal.styles";
import { CloseModalIcon, DeleteRedIcon, ProductPlaceholderIcon, WifiDisconnectedIcon } from "../../icons";
import { useDispatch } from "react-redux";
import { addProductToSale } from "../../store/sale-slice";
import { useLocation } from "react-router-dom";
import { addProductToPurchase } from "../../store/purchase-slice";
import { addProductToPurchaseReturn } from "../../store/purchasereturn-slice";
import { addProductToDamageEntry } from "../../store/damageentry-slice";
import { Drawer } from "@mui/material";
import ProductDrawer from "./../product-drawer/product-drawer";
import { generateSKU } from "../../utils/sku-upc-generator";
import DeleteConfirmationModal from "../delete-confirmation-modal/DeleteConfirmationModal";
import { DELETE_SKU_MODAL_DATA, PRODUCT_STATUS } from "../../constants/appConstants";
import { cloneDeep } from "lodash";
import { updateProduct } from "../../api/local/productApi";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { useContext } from "react";
import { captureException } from "../../crash-reporting";
import { generateMetrics } from "../../utils/metrics";
import { formatDate } from "../../utils/format-date";
import { hasDecimal } from "../../utils/global-fn";
import { addProductToPI } from "../../store/proformainvoice-slice";
import { addProductToPP } from "../../store/purchaseproforma-slice";

const SelectProductModal = ({ open, onClose, data, openEditDrawer, onlineOrderDataAddHandler }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [products, _setProducts] = useState(null);
    const productsRef = useRef(products);
    const setProducts = (p) => {
        productsRef.current = p;
        _setProducts(p);
    };
    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = React.useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };
    const [openDrawer, setOpenDrawer] = useState(false);
    const [productData, setProductData] = useState(null);
    const scrollRef = useRef(null);
    const [isNewSKU, setIsNewSKU] = useState(false);
    const [openDeleteModal, _setOpenDeleteModal] = useState({
        show: false,
        product: null,
    });
    const openDeleteModalRef = useRef(openDeleteModal);
    const setOpenDeleteModal = (data) => {
        openDeleteModalRef.current = data;
        _setOpenDeleteModal(data);
    };
    const { handleSnackbarDetails } = useContext(SnackbarContext);

    useEffect(() => {
        setProducts(data);
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (openDeleteModalRef.current?.show) return;
        if (open) {
            try {
                if (e.keyCode === 13) {
                    //Enter key
                    handleProductSelect(productsRef.current[activeRowRef.current]);
                    e.preventDefault();
                } else if (e.keyCode === 38) {
                    //UP Arrow key
                    if (activeRowRef.current > 0) {
                        setActiveRow(activeRowRef.current - 1);
                        e.preventDefault();
                    }
                } else if (e.keyCode === 40) {
                    //DOWN Arrow key
                    if (activeRowRef.current < productsRef.current?.length - 1) {
                        setActiveRow(activeRowRef.current + 1);
                        e.preventDefault();
                    }
                } else if (e.key.toLowerCase() === "d" && e.altKey) {
                    handleCreateNewSku(productsRef.current[activeRowRef.current]);
                    e.preventDefault();
                } else if (e.keyCode === 46) {
                    //Delete key
                    handleOpenDeleteModal(e, productsRef.current[activeRowRef.current]);
                    e.preventDefault();
                }
            } catch (error) {
                //
            }
        }
    };

    function handleProductSelect(item) {
        // console.log("clicked", item);
        onClose();
        let path = location.pathname.split("/")[2];
        if (path === "sales" || path === "new-sale") {
            dispatch(addProductToSale(item));
        } else if (path === "new-purchase") {
            dispatch(addProductToPurchase(item));
        } else if (path === "new-purchase-return") {
            dispatch(addProductToPurchaseReturn(item));
        } else if (path === "new-damage-entry") {
            dispatch(addProductToDamageEntry(item));
        } else if (path === "proforma-invoice") {
            dispatch(addProductToPI(item));
        } else if (path === "online-orders") {
            onlineOrderDataAddHandler(item);
        } else if (path === "purchase-proforma") {
            dispatch(addProductToPP(item));
        } else {
            if (openEditDrawer) {
                openEditDrawer(item);
            }
        }
    }

    const handleCreateNewSku = (item) => {
        setProductData({ ...item, sku: generateSKU(), batch_number: "", manufacturing_date: null, expiry_date: null });
        setIsNewSKU(true);
        setOpenDrawer(true);
        onClose();
    };

    const handleOpenDeleteModal = (e, item) => {
        e.stopPropagation();
        setOpenDeleteModal({ show: true, product: item });
    };

    const onDeleteModalClose = (item) => {
        setOpenDeleteModal({ show: false, product: null });
        const newProducts = productsRef.current?.filter((p) => p.sku !== item.sku);
        if (newProducts?.length > 0) {
            setProducts(newProducts);
            setActiveRow(0);
        } else {
            onClose();
        }
    };

    const handleDeleteSKU = (product) => {
        const payload = cloneDeep(product);
        payload.status = PRODUCT_STATUS.TRASH;
        updateProduct(product?.sku, payload)
            .then((res) => {
                if (res.status === 200) {
                    onDeleteModalClose(res.data);
                    handleSnackbarDetails({
                        show: true,
                        title: "Deleted succesfully",
                        type: "success",
                        subtitle: "The SKU has been deleted succesfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            })
            .catch((err) => {
                captureException(err);
            });
    };
    return (
        <>
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openDrawer}
                onClose={() => {
                    setIsNewSKU(false);
                    setOpenDrawer(false);
                }}
                className="right-drawer"
            >
                <ProductDrawer
                    type={"add"}
                    productData={productData}
                    onCloseDrawer={() => {
                        setIsNewSKU(false);
                        setOpenDrawer(false);
                    }}
                    open={openDrawer}
                />{" "}
            </Drawer>
            <DeleteConfirmationModal
                open={openDeleteModalRef.current?.show}
                onClose={() => setOpenDeleteModal({ show: false, product: null })}
                data={DELETE_SKU_MODAL_DATA}
                handleSubmit={() => handleDeleteSKU(openDeleteModalRef.current?.product)}
            />
            <Modal
                open={open}
                onClose={() => {
                    onClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <S.Container>
                    <S.Header>
                        <div>Select product</div>
                        <img
                            onClick={() => {
                                onClose();
                            }}
                            src={CloseModalIcon}
                            alt="close-modal"
                        />
                    </S.Header>
                    <S.Body>
                        <S.TableHeadRow>
                            <S.TableHead flexValue="2">Item Description</S.TableHead>
                            <S.TableHead flexValue="1" center>
                                Stock
                            </S.TableHead>
                            <S.TableHead flexValue="1" center>
                                Unit Price
                            </S.TableHead>
                            <S.TableHead flexValue="1" center>
                                Batch No
                            </S.TableHead>
                            <S.TableHead flexValue="1" center>
                                Mfg Date
                            </S.TableHead>
                            <S.TableHead flexValue="1" center>
                                Exp Date
                            </S.TableHead>
                            <S.TableHead flexValue="0.42" center></S.TableHead>
                        </S.TableHeadRow>

                        <S.TableBody>
                            {products?.map((item, idx) => (
                                <React.Fragment key={item.sku}>
                                    <S.TableDataRow
                                        key={item.sku}
                                        active={idx === activeRow}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleProductSelect(item);
                                        }}
                                        ref={idx === activeRow ? scrollRef : null}
                                    >
                                        <S.TableDataDetails>
                                            <img
                                                src={item.gallery?.[0] || ProductPlaceholderIcon}
                                                alt="product avatar"
                                                onError={(e) => {
                                                    e.target.src = WifiDisconnectedIcon;
                                                }}
                                            />
                                            <div>
                                                <S.ItemDetailsName>{item.name}</S.ItemDetailsName>
                                                <S.ItemDetailsBrand>{item.brand?.name}</S.ItemDetailsBrand>
                                                <S.ItemDetailsQuantity>{generateMetrics(item)}</S.ItemDetailsQuantity>
                                            </div>
                                        </S.TableDataDetails>

                                        <S.TableDataSKU>{hasDecimal(item.stock) ? item.stock.toFixed(2) : item.stock}</S.TableDataSKU>

                                        <S.TableDataPrice>
                                            {item.price !== item.sale_price && <div>₱{item.price.toFixed(2)}</div>} ₱{item.sale_price.toFixed(2)}
                                        </S.TableDataPrice>
                                        <S.TableDataPrice>{item.batch_number ?? "-"}</S.TableDataPrice>
                                        <S.TableDataPrice>{item.manufacturing_date ? formatDate(item.manufacturing_date) : "-"}</S.TableDataPrice>
                                        <S.TableDataPrice>{item.expiry_date ? formatDate(item.expiry_date) : "-"}</S.TableDataPrice>
                                        <S.DeleteBtn>
                                            <img src={DeleteRedIcon} alt="delete" onClick={(e) => handleOpenDeleteModal(e, item)} />
                                        </S.DeleteBtn>
                                    </S.TableDataRow>
                                    <S.Hr></S.Hr>
                                </React.Fragment>
                            ))}
                        </S.TableBody>
                    </S.Body>
                    <S.BottomContainer>
                        <S.BlueBtn onClick={() => handleCreateNewSku(productsRef.current[activeRowRef.current])}>Add New Batch (ALT+D)</S.BlueBtn>
                        <S.SkuCount>Total SKU’s: {productsRef.current?.length}</S.SkuCount>
                    </S.BottomContainer>
                </S.Container>
            </Modal>
        </>
    );
};

export default SelectProductModal;
