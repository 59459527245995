import React, { useEffect } from "react";
import * as S from "./style";
import { useState } from "react";
import Address from "./Address";
import { InitialAddress, TABS } from "../../add-new-customer-modal/add-new-customer-modal";
import { ArrowRight } from "../../../icons";
import { isEqual } from "lodash";

const CustomerAddress = ({ phoneNumber, setActiveTab, shippingAddress, setShippingAddress, billingAddress, setBillingAddress, handleSubmit }) => {
    const [isSameAddress, setIsSameAddress] = useState(false);
    useEffect(() => {
        if (shippingAddress.line_1) {
            if (isEqual(shippingAddress, billingAddress)) {
                setIsSameAddress(true);
            }
        }
    }, [billingAddress, shippingAddress]);

    const handleCheckInputChange = (event) => {
        let { checked } = event.target;
        setIsSameAddress(checked);
        if (checked === true) {
            setShippingAddress(billingAddress);
        } else {
            setShippingAddress(InitialAddress);
        }
    };
    return (
        <S.Wrapper>
            <S.Container>
                <S.FormContainer>
                    <S.Header>Billing Address</S.Header>
                    <Address address={billingAddress} setAddress={setBillingAddress} />
                    <S.CheckBoxContainer>
                        <input type="checkbox" id="address" onChange={handleCheckInputChange} checked={isSameAddress} />
                        <label htmlFor="address">Shipping address same as Billing address</label>
                    </S.CheckBoxContainer>
                </S.FormContainer>
                <S.FormContainer>
                    <S.Header>Shipping Address</S.Header>
                    <Address address={shippingAddress} setAddress={setShippingAddress} />
                </S.FormContainer>
            </S.Container>
            <S.BottomBar>
                <S.NextBtn $rotate onClick={() => setActiveTab(TABS.INFORMATION)}>
                    <img src={ArrowRight} alt="right arrow" />
                    Back
                </S.NextBtn>
                {phoneNumber && (
                    <S.NextBtn onClick={() => setActiveTab(TABS.TRANSACTION)}>
                        Next
                        <img src={ArrowRight} alt="right arrow" />
                    </S.NextBtn>
                )}
                <S.AddBtn onClick={handleSubmit}>{phoneNumber ? "Update" : "Add"}</S.AddBtn>
            </S.BottomBar>
        </S.Wrapper>
    );
};

export default CustomerAddress;
