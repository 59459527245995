import styled from "@emotion/styled/macro";

export const CustomersContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    // padding: 34px 25px 30px;
    padding: 16px;
`;

export const HeadingBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const CustomerHeading = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
`;

export const AddCustomerBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #3b82f6;
    border-radius: 4px;
    width: 131px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
`;

export const CustomerBody = styled.div`
    background: #ffffff;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
export const PageContainer = styled.div`
    padding: 0px 6px;
    box-sizing: border-box;
`;

export const ChipContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
`;
