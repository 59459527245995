import React, { useContext, useState, useImperativeHandle } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSale, updateSale } from "../../../api/local/sale-api";
import { BillIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { removeSelectedCustomer } from "../../../store/customer-slice";
import {
  getSaleTotals,
  removeAllProducts,
  removeSaleID,
  removeSelectedSale,
  resetAdditionalCharges,
  setBillno,
  setCreatedDate,
  setSelectDiscount,
} from "../../../store/sale-slice";
import PaymentModal from "../../payment-modal-new/PaymentModal";
import ClearCart from "../ClearDialog/ClearCart";
import * as S from "./checkout.styles";
import {
  DiscountOption,
  SALE_BILL_TYPE,
} from "../../../constants/appConstants";
import onInputChange from "./../../../utils/handleInputChange";
import { captureException } from "../../../crash-reporting";
import AdditionalCharges from "../additional-charges/AdditionalCharges";
import {
  getSalePayloadProducts,
  hasDecimal,
  toDecimalPlace,
} from "../../../utils/global-fn";
import InvoicePaymentModal from "../../invoice-payment-modal/InvoicePaymentModal";

const Checkout = ({ billRef, cartRef, clearCartRef, openDrawer }, ref) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openClearModal, setOpenClearModal] = useState(false);
  // const [globalDiscount, setGlobalDiscount] = useState(0);
  const [openAdditionalCharge, setOpenAdditionalCharge] = useState(false);
  const [openInvoicePaymentModal, setOpenInvoicePaymentModal] = useState(false);
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const {
    saleProducts,
    saleQuantity,
    saleTotal,
    taxTotal,
    saleId,
    discountValue,
    billno,
    createdDate,
    selectDiscount,
    additional_charges,
    totalAdditionalCharges,
    cartTotal,
    globalDiscount,
  } = useSelector((state) => state.sale);
  const { billTypePreference } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { inventorySelectedCustomer } = useSelector((state) => state.customer);

  useEffect(() => {
    if (!selectDiscount.cost) {
      dispatch(
        setSelectDiscount({
          ...selectDiscount,
          type: DiscountOption.PERCENTAGE,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      getSaleTotals({
        bill_type: billTypePreference?.sale_bill_type,
      })
    );
    // let total = saleTotal;
    // if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
    //     total = total + totalAdditionalCharges;
    // }
    // let globalDisc =
    //     selectDiscount.type === DiscountOption.PERCENTAGE
    //         ? total * (parseFloat(selectDiscount.cost || 0) / 100)
    //         : parseFloat(selectDiscount.cost || 0);

    // setGlobalDiscount(globalDisc);
    // if (selectDiscount.type === DiscountOption.PERCENTAGE) {
    //     if (parseFloat(selectDiscount.cost) > 100) {
    //         dispatch(setSelectDiscount({ ...selectDiscount, cost: 100 }));
    //     }
    // } else if (selectDiscount.type === DiscountOption.FLAT) {
    //     console.log(total);
    //     if (parseFloat(selectDiscount.cost) > total) {
    //         dispatch(setSelectDiscount({ ...selectDiscount, cost: total }));
    //     }
    // }
  }, [billTypePreference, selectDiscount]);

  useImperativeHandle(ref, () => {
    return {
      saveDraft: () => onDraftSale(),
      saveCheckoutSale: () => onCheckOut(),
    };
  });

  const onCheckOut = () => {
    if (!openDrawer) {
      handleCheckout();
    }
  };

  const onDraftSale = () => {
    if (!openDrawer) {
      handleDraftSale();
    }
  };

  function handleClearData() {
    dispatch(removeAllProducts());
    dispatch(removeSelectedCustomer());
    dispatch(removeSaleID());
    dispatch(setBillno(""));
    dispatch(setCreatedDate(null));
    dispatch(removeSelectedSale());
    dispatch(
      setSelectDiscount({
        type: DiscountOption.PERCENTAGE,
        cost: 0,
      })
    );
    dispatch(resetAdditionalCharges());
  }

  async function handleDraftSale() {
    if (!saleProducts.length) {
      handleSnackbarDetails({
        show: true,
        title: "Please add products",
        type: "error",
      });
      return;
    }
    for (let item of saleProducts) {
      if (item.quantity <= 0) {
        handleSnackbarDetails({
          show: true,
          title: `Please choose a quantity for ${item.name}`,
          type: "error",
        });
        return;
      }
    }
    const payload = {
      status: "DRAFT",
      bill_type: billTypePreference?.sale_bill_type,
      items: getSalePayloadProducts(
        saleProducts,
        billTypePreference?.sale_bill_type
      ),
    };

    if (inventorySelectedCustomer)
      payload.patient_id = inventorySelectedCustomer.id;
    if (createdDate) payload.created_at = Math.floor(createdDate.getTime());
    if (parseFloat(selectDiscount.cost)) {
      let disc = {
        type: selectDiscount.type,
        value: selectDiscount.cost,
      };
      payload.cart_manual_discount = disc;
    }
    // payload.cart_manual_discount = +(
    //     selectDiscount.type === DiscountOption.PERCENTAGE
    //         ? (saleTotal + billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE ? totalAdditionalCharges : 0) *
    //             (parseFloat(selectDiscount.cost) / 100)
    //         : parseFloat(selectDiscount.cost)
    // ).toFixed(2);
    if (billno !== undefined) payload.bill_no = billno;
    if (
      additional_charges.length &&
      billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE
    ) {
      payload.additional_charges = additional_charges
        .filter((a) => a.value !== "")
        .map((item) => ({ ...item, value: parseFloat(item.value) }));
    } else {
      payload.additional_charges = [];
    }
    if (saleId) {
      try {
        let res = await updateSale(saleId, payload);
        if (res.status === 200) {
          handleClearData();
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Draft Updated",
            subtitle: "Draft updated successfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      } catch (err) {
        captureException(err);
        handleSnackbarDetails({
          show: true,
          title: err?.response?.data?.message ?? err.message,
          type: "error",
        });
      }
    } else {
      try {
        let res = await addSale(payload);
        if (res.status === 200) {
          handleClearData();
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Draft Saved",
            subtitle: "Draft added successfully",
          });
        } else {
          throw new Error(res.data?.message);
        }
      } catch (err) {
        captureException(err);
        handleSnackbarDetails({
          show: true,
          title: err?.response?.data?.message ?? err.message,
          type: "error",
        });
      }
    }
  }
  function handleCheckout() {
    if (!saleProducts.length) {
      // handleSnackbarDetails({
      //     show: true,
      //     title: "Please add products",
      //     type: "error",
      // });
      return;
    }
    // if (!inventorySelectedCustomer) {
    //     handleSnackbarDetails({
    //         show: true,
    //         title: "Please choose a customer",
    //         type: "error",
    //     });
    //     return;
    // }
    for (let item of saleProducts) {
      if (item.quantity <= 0) {
        handleSnackbarDetails({
          show: true,
          title: `Please choose a quantity for ${item.name}`,
          type: "error",
        });
        return;
      }
    }
    if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
      setOpenInvoicePaymentModal(true);
    } else {
      setOpenPaymentModal(true);
    }
  }

  function handleBillChange(e) {
    onInputChange(e, (data) => {
      const { value } = data;
      dispatch(setBillno(value));
    });
  }

  function handleDiscountChange(e) {
    onInputChange(e, (data) => {
      let { value } = data;
      const regex = /^\d+\.?(\d)?(\d)?$/;
      if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
        value = value.substr(1);
      }
      if (!(value === "" || regex.test(value))) return;
      if (selectDiscount.type === DiscountOption.PERCENTAGE) {
        if (parseFloat(value) > 100) {
          value = 100;
        }
      }
      if (selectDiscount.type === DiscountOption.FLAT) {
        let total = saleTotal;
        if (billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE) {
          total = total + totalAdditionalCharges;
        }
        if (parseFloat(value) > total) {
          value = total;
        }
      }
      dispatch(
        setSelectDiscount({
          ...selectDiscount,
          cost: value,
        })
      );
    });
  }
  return (
    <>
      <ClearCart
        open={openClearModal}
        onClose={() => setOpenClearModal(false)}
      />
      <AdditionalCharges
        open={openAdditionalCharge}
        onClose={() => setOpenAdditionalCharge(false)}
        type="sale"
      />
      <PaymentModal
        open={openPaymentModal}
        onClose={() => setOpenPaymentModal(false)}
        saleDiscount={globalDiscount}
        handleClearData={handleClearData}
        type="sale"
        ref={clearCartRef}
      />
      <InvoicePaymentModal
        open={openInvoicePaymentModal}
        onClose={() => setOpenInvoicePaymentModal(false)}
        type="sale"
        saleDiscount={globalDiscount}
        handleClearData={handleClearData}
      />

      <S.Container>
        <S.Header>
          <S.HeaderLeft>Sale Summary</S.HeaderLeft>
          {/* <S.HeaderRight onClick={() => setOpenClearModal(true)}>
                        Clear Cart
                    </S.HeaderRight> */}
        </S.Header>

        <S.Body>
          <div>
            {/* {inventorySelectedCustomer && (
                            <S.CustomerContainer>
                                <S.CustomerDetailsTop>
                                    <S.CustomerName>
                                        {inventorySelectedCustomer.name}
                                    </S.CustomerName>{" "}
                                    <S.MobileNumber>
                                        ({inventorySelectedCustomer.phone})
                                    </S.MobileNumber>
                                </S.CustomerDetailsTop>
                                <img
                                    src={CloseModalIcon}
                                    alt="cross-btn"
                                    onClick={() => dispatch(removeSelectedCustomer())}
                                />
                            </S.CustomerContainer>
                        )} */}

            <S.GreyText>
              <div>Quantity:</div>
              <div>{saleQuantity}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Subtotal:</div>
              <div>
                ₱
                {toDecimalPlace(
                  saleTotal - taxTotal.toFixed(2) + discountValue
                )}
              </div>
            </S.GreyText>

            <S.GreyText>
              <div>Discount:</div>
              <div>₱{toDecimalPlace(discountValue)}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Tax:</div>
              <div>₱{toDecimalPlace(taxTotal)}</div>
            </S.GreyText>

            <S.GreyText>
              <div>Bill total:</div>
              <div>₱{toDecimalPlace(saleTotal)}</div>
            </S.GreyText>
            <S.Dotted></S.Dotted>

            <S.GreyText>
              <div>Cart discount:</div>
              <div>₱{toDecimalPlace(globalDiscount)}</div>
            </S.GreyText>
            <S.Total>
              <div>Cart Total:</div>
              <div>₱{cartTotal}</div>
            </S.Total>
            <S.Dotted></S.Dotted>
            <S.BillContainer>
              <S.Label>
                Cart Discount <div>(F8)</div>
              </S.Label>
              <S.CartDiscountChoosers>
                <S.SymbolDiv>
                  <S.Symbol
                    active={selectDiscount.type === DiscountOption.FLAT}
                    onClick={() =>
                      dispatch(
                        setSelectDiscount({
                          ...selectDiscount,
                          type: DiscountOption.FLAT,
                        })
                      )
                    }
                  >
                    ₱
                  </S.Symbol>
                  <S.MiddleBar></S.MiddleBar>

                  <S.Symbol
                    active={selectDiscount.type === DiscountOption.PERCENTAGE}
                    onClick={() =>
                      dispatch(
                        setSelectDiscount({
                          ...selectDiscount,
                          type: DiscountOption.PERCENTAGE,
                        })
                      )
                    }
                  >
                    %
                  </S.Symbol>
                </S.SymbolDiv>
                <input
                  type="text"
                  value={selectDiscount.cost ?? 0}
                  onChange={(e) => handleDiscountChange(e)}
                  disabled={saleProducts.length <= 0}
                  ref={cartRef}
                />
              </S.CartDiscountChoosers>
            </S.BillContainer>
            <S.BillContainer>
              <S.Label>
                Invoice Number <div>(F7)</div>
              </S.Label>
              <S.FieldContainer>
                <input
                  type="text"
                  value={billno}
                  onChange={(e) => handleBillChange(e)}
                  ref={billRef}
                />
                <S.Line></S.Line>
                <img src={BillIcon} alt="" />
              </S.FieldContainer>
            </S.BillContainer>
            {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE && (
              <S.AdditionalChargeContainer>
                {Array.isArray(additional_charges) &&
                additional_charges.length &&
                totalAdditionalCharges ? (
                  <>
                    <S.Label>Additional Charges:</S.Label>
                    {additional_charges.map(
                      (item, index) =>
                        item.field &&
                        item.value && (
                          <S.GreyText key={index}>
                            <div>{item.field}:</div>
                            <div>₱{item.value}</div>
                          </S.GreyText>
                        )
                    )}
                  </>
                ) : (
                  <></>
                )}
              </S.AdditionalChargeContainer>
            )}
          </div>

          <div>
            {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE && (
              <S.OutlineBtn onClick={() => setOpenAdditionalCharge(true)}>
                + Add Additional Charges
              </S.OutlineBtn>
            )}
            <S.CheckoutBtn
              color={saleProducts.length}
              // disabled={!saleProducts.length}
              onClick={() => handleCheckout()}
            >
              Checkout (F12)
            </S.CheckoutBtn>

            <S.SavetoDraftBtn onClick={() => handleDraftSale()}>
              {saleId ? "Save" : "Add New"} Counter (F10)
            </S.SavetoDraftBtn>
          </div>
        </S.Body>
      </S.Container>
    </>
  );
};

export default React.forwardRef(Checkout);
