import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const InputBody = styled.div`
    display: flex;
    // justify-content: space-between;
    gap: 185px;
`;

export const InputContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: calc(100vh - 290px);

    & > div {
        // flex: 1;
    }
`;
export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;
    margin-bottom: 13px;
    span {
        color: #e54630;
    }
`;
export const Input = styled.input`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    width: ${(props) => (props.width ? props.width : "100%")};
    padding: 9px 11px;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
    background-color: #fff;
`;
export const MultipleInputs = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 17px;

    & > div {
        flex: 1;
    }
`;
export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;
export const Hint = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #94a3b8;
    font-style: italic;
`;

export const MapContainer = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
`;
export const LabelContainer = styled.div`
    width: 533px;
`;

export const AutocompleteContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    background: #ffffff;
    border: 0.706576px solid #e2e8f0;
    border-radius: 1.41315px;
    width: 533px;
    height: 34px;
    margin-bottom: 27px;
    box-sizing: border-box;
    // padding-left: 12px;
    position: relative;

    & input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #334155;
        box-sizing: border-box;
        padding-left: 8px;

        ::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #334155;
        }
    }
`;
export const PlacesContainer = styled.div`
    position: absolute;
    background-color: #fff;
    border: 0.71px solid #e2e8f0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    left: 0;
    top: 0;
    z-index: 1;
    margin-top: 39px;
    width: 533px;
    height: 251px;
    overflow: hidden;
    overflow-y: auto;
`;

export const Place = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 24px 16px 16px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;

    :hover {
        background-color: #f1f5f9;
    }
`;
export const Head = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #334155;
`;
export const SubHead = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #7f8f97;
`;
export const SubPlace = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
