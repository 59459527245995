import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    margin-right: 12px;
    background: #ca6ff6;
    border-radius: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    cursor: pointer;
    border: 1px solid #00a36d;
    padding: 6px 11px 6px 9px;

    & > img {
        width: 22px;
        height: 18px;
    }
`;

export const Status = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #334155;
`;
