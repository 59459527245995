import styled from "@emotion/styled/macro";

export const Table = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    // padding-bottom: 55px;
    flex: 3;

    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const TableHeader = styled.div`
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid #e2e8f0;
    padding: 0 10px;
    color: #334155;
`;

export const TableHeaderData = styled.div`
    flex: ${(props) => props.flexValue};
    text-align: ${(props) => (props.center ? "center" : "left")};
`;

export const TableBody = styled.div`
    overflow-y: auto;
    height: 100%;
`;
export const Check = styled.input`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
export const Bar = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
    margin: 2px 0;
`;
