import styled from "@emotion/styled/macro";

export const Container = styled.div`
    display: flex;
    padding: 9px;
    justify-content: flex-end;
    box-sizing: border-box;
    align-items: center;
    gap: 10px;
    img {
        cursor: pointer;
    }

    img[alt*="prev"] {
        transform: rotate(180deg);
    }
`;

export const ArrowContainer = styled.div`
    padding: 6px 10px;
    background: ${(props) => (props.disable ? "#94A3B8" : "#3b82f6")};
    border-radius: 4px;

    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #ffffff;

    display: flex;
    align-items: center;
    cursor: ${(props) => (props.disable ? "no-drop" : "pointer")};

    img {
        width: 14px;
        height: 14px;
        ${(props) => props.prev && "transform: rotate(180deg)"};
        ${(props) => (props.prev ? "margin-right: 4px" : "margin-left: 4px")};
    }
`;

export const Page = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => (props.active ? "#FFFFFF" : "#374151")};
    height: 24px;
    width: 24px;
    background: ${(props) => (props.active ? "#2D68FE" : "unset")};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Ellipsis = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
`;
