import moment from "moment";

export const formatDate = (time) => {
    if (!time) return;
    return moment(time).format("DD/MM/YYYY");
};

export const calendarFormatDate = (time) => {
    return moment(time).format("MM/DD/YYYY");
};
