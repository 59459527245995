import styled from "@emotion/styled/macro";

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 24px solid #fff;

    input[type="checkbox"] {
        width: 12px;
        height: 12px;
        margin: 0 8px 0 0;
    }
`;
export const CheckboxTop = styled.div`
    display: flex;
    align-items: center;
`;
export const CheckboxText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #334155;
`;
export const Range = styled.div`
    margin: 14px 8px 0 18px;
    display: flex;
    gap: 16px;
`;
export const RangeInput = styled.input`
    flex: 1;
    height: 32px;
    width: 92px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    outline: none;
    color: #475569;

    &::placeholder {
        color: #cbd5e1;
    }
`;
export const CustomDueRangeSubmit = styled.div`
    background: #3b82f6;
    border-radius: 4px;
    padding: 4px 9px;
    width: fit-content;
    margin: 12px 0 0 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
`;
export const MenuContainer = styled.div`
    position: absolute;
    top: 38px;
    width: 278px;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 2px 2px;
    left: 0;
    z-index: 1;
    // border: 1px solid red;
    padding: 22px 16px 0 16px;
`;

export const SelectContainer = styled.div`
    // padding: 14px 16px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 6px;
    height: 36px;
    position: relative;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
    }
`;
export const SelectText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #64748b;
`;
