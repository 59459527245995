import { ONBOARDING_API } from "../../constants/apiRoutes";
import store from "../../store/store";
import axios from "./axios";

const getOnboarding = async () => {
    return await axios.get(ONBOARDING_API);
};

const setOnboarding = async (payload) => {
    let token = store.getState().session.store.access_token;
    return await axios.post(ONBOARDING_API, payload, {
        headers: {
            Authorization: token,
        },
    });
};

export { getOnboarding, setOnboarding };
