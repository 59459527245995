import React from "react";
import CustomerReport from "../../components/analytics/customerReport/CustomerReport";
import OverallReport from "../../components/analytics/overallReport/OverallReport";
import ProductReport from "../../components/analytics/productReport/ProductReport";
import * as S from "./Analytics.style";
import ViewTrends from "../../components/analytics/view-trends/ViewTrends";
import ProfitLoss from "../../components/analytics/profit-loss-report/ProfitLoss";

const Analytics = () => {
    return (
        <S.Wrapper>
            <OverallReport />
            <CustomerReport />
            <ProductReport />
            {/* <ViewTrends /> */}
        </S.Wrapper>
    );
};

export default Analytics;
