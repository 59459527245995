import styled from "@emotion/styled/macro";

export const ProductWrapper = styled.div`
    width: calc(100% - 323px);
    background-color: #fff;
    border-radius: 3px;
`;
export const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 11px 33px 11px 24px;
`;
export const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 42px;
`;
export const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #263238;
    text-decoration: underline;
`;

export const DarkText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
`;
export const GreyText = styled.div`
    color: #263238;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
export const PickupDateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    color: #263238;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
`;

export const OrderId = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #263238;
    height: 54px;
    display: flex;
    align-items: center;
`;
export const Body = styled.div`
    height: calc(100vh - 162px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 0 24px;
    height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
`;
export const BtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    box-sizing: border-box;
    padding: 0 34px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.07);
`;
export const AnimationContainer = styled.div`
    width: 36px;
    height: 36px;
`;

export const StatusText = styled.div`
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #455a64;
`;

export const AnimationStatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 26px;
`;
export const RedBtn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #f91919;
    cursor: pointer;
`;

export const BlueBtn = styled.div`
    background: ${(props) => (props.disable ? "#94A3B8" : "#2d68fe")};
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    padding: 10px;
    cursor: ${(props) => (props.disable ? "no-drop" : "pointer")};
`;

export const PackingBtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    box-sizing: border-box;
    padding: 0 34px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.07);
`;
export const OutlinedBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2d68fe;
    background: #ffffff;
    border: 1px solid #2d68fe;
    border-radius: 4px;

    & img {
        width: 16px;
        height: 16px;
    }
`;

export const RightSideBtns = styled.div`
    display: flex;
    align-items: center;
    gap: 26px;
`;
export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 68px;
    box-sizing: border-box;
    padding: 0 24px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.07);
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #455a64;
`;
export const LoaderBtn = styled.div`
    width: 181px;
    height: 36px;
    background: #2d68fe;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 10px;

    & > div {
        width: 40px;
        height: 40px;
    }
`;

export const TimeContainer = styled.div`
    width: 180px;
`;

export const OrderIdContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    img {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
`;

export const CustomerContainer = styled.div`
    width: 40%;
`;
