import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    inventorySelectedStock: "",
};

const stockSlice = createSlice({
    name: "stock",
    initialState,
    reducers: {
        setSelectedChipStock: (state, action) => {
            state.inventorySelectedStock = action.payload;
        },
        removeSelectedChipStock: (state) => {
            state.inventorySelectedStock = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase('LOGOUT', (state) => {
            Object.assign(state, initialState);
        })
    },
});

export default stockSlice.reducer;
export const { setSelectedChipStock, removeSelectedChipStock } =
    stockSlice.actions;
