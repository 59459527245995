import { createContext, useState } from "react";

export const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [snackbarDetails, setSnackbarDetails] = useState({
    show: false,
    title: "",
    subtitle: "",
    ctaText: "",
    type: "",
  });

  const handleSnackbarDetails = (value) => setSnackbarDetails(value);

  return (
    <SnackbarContext.Provider
      value={{
        snackbarDetails,
        handleSnackbarDetails,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
