import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const Bottom = styled.div`
    width: 100%;
    height: 66px;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 9px 16px;
`;
export const BottomLeftContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
`;
export const BottomRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
export const EmployeeDetails = styled.div`
    display: flex;
    align-items: center;
    background: #ccdfff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 6px 8px;
`;
export const Avatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: #334155;
    border-radius: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #ffffff;
    margin-right: 6px;
`;
export const EmployeeTag = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
    margin-right: 3px;
`;
export const EmployeeName = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2d68fe;
`;
export const SaleId = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #004463;
`;
export const SaveBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    // width: 200px;
    // height: 40px;
    background: #2d68fe;
    border-radius: 2.84375px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
`;
export const DeleteBtn = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    line-height: 18px;
    color: #f91919;
`;
export const Top = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 12px 16px 0px;
    // height: 100%;
`;
export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;
export const Path = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    // & > div:nth-of-type(1) {
    //     color: #2d68fe;
    // }
    // & > div:nth-of-type(2) {
    //     color: #2d68fe;
    // }
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    right: 0;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 13000;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    width: 241px;
    max-height: 36px;
    cursor: pointer;
    justify-content: space-between;
    background-color: #fff;

    img {
        height: 20px;
        width: 20px;
    }
`;

export const DatePicker = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;
`;
export const Created = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #475569;
`;

export const Body = styled.div`
    width: 100%;
    // height: 80%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    height: calc(100vh - 200px);
`;
export const Heading = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #1e293b;
    // margin: 28px 0 14px;
`;
