import React, { useContext, useState, useEffect, useRef, useImperativeHandle } from "react";
import * as S from "./PurchaseProfoma.style";
import "./calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../utils/format-date";
import BackButton from "../../components/back-button/BackButton";
import CustomButton from "../../components/custom-button/CustomButton";
import { COLOR, DiscountOption, PURCHASE_TYPE } from "../../constants/appConstants";
import { EmptyCartLogo, RedClearCartIcon } from "../../icons";
import ToggleSwitch from "../../components/switch/Switch";
import PurchaseProformaTable from "../../components/purchase-proforma/purchase-proforma-table/PurchaseProformaTable";
import { useDispatch, useSelector } from "react-redux";
import PurchaseProformaSummary from "../../components/purchase-proforma/purchase-proforma-summary/PurchaseProformaSummary";
import {
    SCREENS,
    addProductToPP,
    removeAllProductsPP,
    removeSelectedPP,
    removeSelectedSupplierPP,
    setBillNoPP,
    setCreatedDatePP,
    setCurrentScreenPP,
    setDeliveryDatePP,
    setDeliveryExpensePP,
    setSelectDiscountPP,
    setSelectedSupplierPP,
    setTypePP,
} from "../../store/purchaseproforma-slice";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import { generateSKU } from "../../utils/sku-upc-generator";
import ProductDrawer, { INITIAL_PRODUCT_DISPLAY_DATA } from "../../components/product-drawer/product-drawer";
import { Drawer } from "@mui/material";
import { convertTax, getPurchasePayloadProducts, toDecimalPlace } from "../../utils/global-fn";
import { addPurchase, updatePurchase } from "../../api/local/purchase-api";
import ClearCart from "../../components/purchase/clear-cart-modal/ClearCart";
import { fetchAllPurchases, removeAllSearchParams } from "../../store/purchase-slice";
import PurchaseDraftDrawer from "../../components/purchase/purchase-draft-drawer/PurchaseDraftDrawer";
import { getProduct } from "../../api/local/productApi";
import AddSupplierModal from "../../components/purchase/add-supplier-modal/add-supplier-modal";
import NewSupplierModal from "../../components/new-supplier-modal/new-supplier-modal";
import SelectProductModal from "../../components/select-product-modal/select-product-modal";
import ProformaCheckoutModal from "../../components/purchase-proforma/proforma-checkout-modal/ProformaCheckoutModal";
import history from "../../utils/history";
import { captureException } from "../../crash-reporting";

const PurchaseProforma = (props, ref) => {
    const [isOrder, setIsOrder] = useState(false);
    const {
        PP_products,
        PP_currentScreen,
        PP_selectedSupplier,
        PP_createdDate,
        selectedPP,
        PP_type,
        PP_billNo,
        PP_deliveryExpense,
        PP_deliveryDate,
        PP_globalDiscount,
        PP_selectDiscount,
    } = useSelector((state) => state.purchaseProforma);
    const { searchParams } = useSelector((state) => state.purchase);
    const dispatch = useDispatch();
    const billRef = useRef();
    const delExpRef = useRef();
    const delTimeRef = useRef();
    const cartDiscountRef = useRef();
    const timeRef = useRef();
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const [scannedProduct, setScannedProduct] = useState(null);
    const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
    const [openProductDrawer, _setOpenProductDrawer] = useState(false);
    const openProductDrawerRef = useRef(openProductDrawer);
    const setOpenProductDrawer = (data) => {
        openProductDrawerRef.current = data;
        _setOpenProductDrawer(data);
    };
    const [productDrawerData, setProductDrawerData] = useState({ ...INITIAL_PRODUCT_DISPLAY_DATA, sku: generateSKU() });
    const [openClearModal, _setOpenClearModal] = useState(false);
    const openClearModalRef = useRef(openClearModal);
    const setOpenClearModal = (data) => {
        openClearModalRef.current = data;
        _setOpenClearModal(data);
    };
    const [openDraftDrawer, setOpenDraftDrawer] = useState(false);

    const [openProformaCheckoutModal, _setOpenProformaCheckoutModal] = useState(false);
    const openProformaCheckoutModalRef = useRef(openProformaCheckoutModal);
    const setOpenProformaCheckoutModal = (data) => {
        openProformaCheckoutModalRef.current = data;
        _setOpenProformaCheckoutModal(data);
    };

    useEffect(() => {
        let type;
        if (selectedPP) {
            type = selectedPP.type;
        } else {
            type = PP_type;
        }
        setIsOrder(type === PURCHASE_TYPE.PURCHASE_ORDER ? true : false);

        return () => {
            dispatch(setCurrentScreenPP(SCREENS.PURCHASE_SUMMARY));
        };
    }, [selectedPP]);

    useEffect(() => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            const removeEventListener = window.electronAPI.onBarcodeScanned(async (event, value) => await handleGetProduct(value));
            return () => {
                removeEventListener();
            };
        }
    }, []);

    useEffect(() => {
        if (PP_products?.length) {
            dispatch(setCurrentScreenPP(SCREENS.PURCHASE_TABLE));
        } else {
            dispatch(setCurrentScreenPP(SCREENS.PURCHASE_SUMMARY));
        }
    }, [PP_products]);

    useImperativeHandle(ref, () => {
        return {
            onBackEdit: () => handleBtnClick("back"),
            openDeliveryDate: () => timeRef.current?.querySelector("input").click(),
            openSupplierModal: () => setOpenSupplierModal(true),
            focusDelExp: () => delExpRef.current?.focus(),
            addNewProduct: () => setOpenProductDrawer(true),
            focusBill: () => billRef.current?.focus(),
            focusDiscount: () => cartDiscountRef.current?.focus(),
            clearAll: () => setOpenClearModal(true),
            saveDraftPurchase: () => {
                if (!openClearModalRef.current) {
                    handlePurchaseProformaDraft();
                }
            },
            draftOrders: () => openDraftOrders(),
            onProceed: () => onProceedPurchase(),
            onBackAction: () => backAction(),
        };
    });

    const onProceedPurchase = () => {
        if (PP_currentScreen === SCREENS.PURCHASE_SUMMARY) {
            handleBtnClick("purchase");
        } else {
            handleBtnClick("proceed");
        }
    };

    const backAction = () => {
        if (openDraftDrawer) {
            setOpenDraftDrawer(false);
        } else if (openClearModal) {
            setOpenClearModal(false);
        } else if (openProductDrawer) {
            setOpenProductDrawer(false);
        } else if (openSupplierModal) {
            setOpenSupplierModal(false);
        } else if (openProformaCheckoutModal) {
            setOpenProformaCheckoutModal(false);
        } else {
            handleBackAction();
        }
    };

    async function handleGetProduct(barcode) {
        try {
            let res = await getProduct(barcode, { status: "PUBLISH" });
            if (res.status === 200) {
                //Product is present
                if (res.data.length > 1) {
                    setScannedProduct(res.data);
                    setOpenSelectProductModal(true);
                } else {
                    dispatch(addProductToPP(res.data[0]));
                }
                setOpenProductDrawer(false);
            } else if (res.status === 404) {
                //Product is not present, Show the product slider to add the product
                setProductDrawerData({
                    ...INITIAL_PRODUCT_DISPLAY_DATA,
                    upc: barcode,
                    sku: generateSKU(),
                });
                setOpenProductDrawer(true);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    }

    const handleSwitchChange = (checked) => {
        if (checked === isOrder) return;
        setIsOrder(checked);
        dispatch(setTypePP(checked ? PURCHASE_TYPE.PURCHASE_ORDER : PURCHASE_TYPE.PURCHASE_PROFORMA));
    };

    const handleDateChange = (date) => {
        dispatch(setCreatedDatePP(date));
    };

    const handleBtnClick = (type) => {
        if (type === "proceed") {
            dispatch(setCurrentScreenPP(SCREENS.PURCHASE_SUMMARY));
        } else if (type === "back") {
            dispatch(setCurrentScreenPP(SCREENS.PURCHASE_TABLE));
        } else if (type === "purchase") {
            for (let item of PP_products) {
                if (Number(item.quantity) <= 0) {
                    handleSnackbarDetails({
                        show: true,
                        title: `Please choose a quantity for ${item.name}`,
                        type: "error",
                    });
                    return;
                }
            }

            if (PP_products.length === 0) {
                handleSnackbarDetails({
                    show: true,
                    type: "error",
                    title: "Please add a product",
                });
                return;
            } else if (!PP_selectedSupplier) {
                setOpenSupplierModal(true);
                return;
            } else {
                setOpenProformaCheckoutModal(true);
            }
        } else if (type === "draft") {
            handlePurchaseProformaDraft();
        }
    };

    const handleClearCart = () => {
        dispatch(removeAllProductsPP());
        dispatch(removeSelectedSupplierPP());
        dispatch(removeSelectedPP());
        dispatch(setDeliveryDatePP(null));
        dispatch(setBillNoPP(""));
        dispatch(setDeliveryExpensePP(""));
        dispatch(setCreatedDatePP(null));
        dispatch(
            setSelectDiscountPP({
                type: DiscountOption.PERCENTAGE,
                cost: 0,
            })
        );
    };

    const handlePurchaseProformaDraft = async () => {
        if (!PP_products.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of PP_products) {
            if (Number(item.quantity) <= 0) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please choose a quantity for ${item.name}`,
                    type: "error",
                });
                return;
            }
        }

        const payload = {
            type: PP_type,
            status: "DRAFT",
            products: getPurchasePayloadProducts(PP_products),
        };

        if (PP_selectedSupplier) payload.supplier_id = PP_selectedSupplier.id;
        if (PP_billNo) payload.bill_no = PP_billNo;
        if (PP_deliveryExpense) payload.delivery_price = +PP_deliveryExpense;
        if (PP_createdDate) payload.created_at = PP_createdDate.getTime();
        if (PP_deliveryDate) payload.delivery_date = PP_deliveryDate.getTime();
        if (parseFloat(PP_selectDiscount.cost)) {
            let disc = {
                type: PP_selectDiscount.type,
                value: PP_globalDiscount,
            };
            payload.cart_discount = disc;
        }

        let res;
        try {
            if (selectedPP) {
                res = await updatePurchase(selectedPP.id, payload);
            } else {
                res = await addPurchase(payload);
            }

            if (res.status === 200) {
                handleClearCart();
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Draft Saved",
                    subtitle: `Draft ${selectedPP ? "updated" : "added"} successfully`,
                });
            }
        } catch (error) {
            captureException(error);
            handleSnackbarDetails({
                show: true,
                title: error?.response?.data?.message ?? error?.message,
                type: "error",
            });
        }
    };

    const handleDraftDelete = async () => {
        const payload = {
            status: "TRASH",
        };

        try {
            let res = await updatePurchase(selectedPP.id, payload);
            if (res.status === 200) {
                dispatch(fetchAllPurchases(searchParams));
                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: "Draft deleted successfully",
                });
            }
        } catch (error) {
            handleSnackbarDetails({
                show: true,
                title: error?.response?.data?.message ?? "Something went wrong...",
                type: "error",
            });
        }
    };

    const openDraftOrders = () => {
        dispatch(fetchAllPurchases({ type: `${PURCHASE_TYPE.PURCHASE_PROFORMA},${PURCHASE_TYPE.PURCHASE_ORDER}` }));
        setOpenDraftDrawer(true);
    };

    const handleBackAction = () => {
        history.back();
    };
    return (
        <>
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openProductDrawerRef.current}
                onClose={() => {
                    setOpenProductDrawer(false);
                }}
                className="right-drawer"
            >
                <ProductDrawer
                    type="add"
                    onCloseDrawer={(product, type) => {
                        setOpenProductDrawer(false);
                        setProductDrawerData({ ...INITIAL_PRODUCT_DISPLAY_DATA, sku: generateSKU() });
                        if (type === "Added" && product?.sku) {
                            handleGetProduct(product.sku);
                        }
                    }}
                    productData={productDrawerData}
                    open={openProductDrawer}
                />
            </Drawer>
            <ClearCart
                open={openClearModal}
                onClose={() => setOpenClearModal(false)}
                handleClearCart={handleClearCart}
                handleDraftDelete={handleDraftDelete}
                handleDraftPurchase={handlePurchaseProformaDraft}
            />
            <Drawer anchor="right" hideBackdrop="true" open={openDraftDrawer} onClose={() => setOpenDraftDrawer(false)} className="right-drawer">
                <PurchaseDraftDrawer
                    type={PP_type}
                    onCloseDrawer={() => {
                        setOpenDraftDrawer(false);
                        dispatch(removeAllSearchParams());
                    }}
                    open={openDraftDrawer}
                />
            </Drawer>
            <AddSupplierModal
                open={openSupplierModal}
                onClose={() => setOpenSupplierModal(false)}
                openAddNewModal={() => setOpenAddSupplierModal(true)}
            />
            <NewSupplierModal
                open={openAddSupplierModal}
                onClose={() => setOpenAddSupplierModal(false)}
                closeModal={() => setOpenSupplierModal(false)}
                onSave={() => {}}
            />
            <SelectProductModal open={openSelectProductModal} onClose={() => setOpenSelectProductModal(false)} data={scannedProduct} />
            <ProformaCheckoutModal
                open={openProformaCheckoutModal}
                onClose={() => setOpenProformaCheckoutModal(false)}
                handleClearCart={handleClearCart}
            />
            <S.Container>
                <S.Header>
                    <S.Topbar>
                        <BackButton onClickAction={handleBackAction} />
                        <S.DateContainer>
                            <S.Date>Date</S.Date>
                            <DatePicker
                                selected={Date.parse(calendarFormatDate(PP_createdDate || new Date()))}
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="dd/MM/yyyy"
                                className="pp-date-picker"
                            />
                        </S.DateContainer>
                    </S.Topbar>
                    <S.HeaderDetails>
                        <S.HeadContainer>
                            <S.Headline active={isOrder === false} onClick={() => handleSwitchChange(false)}>
                                Purchase Proforma
                            </S.Headline>
                            <ToggleSwitch
                                type="purchase_type"
                                checked={isOrder}
                                isTwoWay={true}
                                handleChange={(e, type) => handleSwitchChange(e.target.checked)}
                            />
                            <S.Headline active={isOrder === true} onClick={() => handleSwitchChange(true)}>
                                Purchase Order
                            </S.Headline>
                        </S.HeadContainer>
                        <S.BtnContainer>
                            <CustomButton label="Counter Orders (F11)" color={COLOR.BLUE} fill={true} onClick={() => openDraftOrders()} />
                            <CustomButton label="Add New product (F6)" color={COLOR.BLUE} fill={true} onClick={() => setOpenProductDrawer(true)} />
                            <CustomButton
                                label={`${PP_selectedSupplier ? "Change" : "Add"} Supplier (F4)`}
                                color={COLOR.BLUE}
                                fill={true}
                                onClick={() => setOpenSupplierModal(true)}
                            />
                            <CustomButton label="Clear Cart (F9)" color={COLOR.RED} icon={RedClearCartIcon} onClick={() => setOpenClearModal(true)} />
                        </S.BtnContainer>
                    </S.HeaderDetails>
                </S.Header>
                <S.Body>
                    {PP_products?.length ? (
                        <PurchaseProformaTable handleBtnClick={handleBtnClick} />
                    ) : (
                        <S.EmptyContainer>
                            <img src={EmptyCartLogo} alt="empty cart logo" />
                            <div>Search or Scan Products to Add</div>
                        </S.EmptyContainer>
                    )}

                    {PP_currentScreen === SCREENS.PURCHASE_SUMMARY && (
                        <PurchaseProformaSummary
                            billRef={billRef}
                            delExpRef={delExpRef}
                            delTimeRef={delTimeRef}
                            cartDiscountRef={cartDiscountRef}
                            timeRef={timeRef}
                            handleBtnClick={handleBtnClick}
                        />
                    )}
                </S.Body>
            </S.Container>
        </>
    );
};

export default React.forwardRef(PurchaseProforma);
