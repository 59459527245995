import React, { useEffect, useRef, useState } from "react";
import * as S from "./SalesTable.style";
import { useDispatch, useSelector } from "react-redux";

import Sale from "./Sale";
import Lottie from "lottie-react";
import EmptyTable from "../../../animations/empty_table.json";
import store from "../../../store/store";
import DeleteModal from "../../purchase/delete-modal/DeleteModal";
import { useNavigate } from "react-router";
import { fetchAllSales, setSaleCount } from "../../../store/sale-slice";
import {
  deleteSale,
  getSalesCount,
  updateSale,
} from "../../../api/local/sale-api";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { captureException } from "../../../crash-reporting";

const SalesTable = ({
  activeRow,
  activeRowRef,
  setActiveRow,
  handleGetSaleTotals,
  selectRef,
  timeRef,
  tableRef,
}) => {
  const { sales, searchSaleParams } = useSelector((state) => state.sale);
  const scrollRef = useRef(null);
  const salesRowRef = useRef(null);
  const [openDeleteModal, _setOpenDeleteModal] = useState({
    show: false,
    data: null,
  });
  const openDeleteModalRef = useRef(openDeleteModal);

  const setOpenDeleteModal = (data) => {
    openDeleteModalRef.current = data;
    _setOpenDeleteModal(data);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSnackbarDetails } = useContext(SnackbarContext);

  useEffect(() => {
    document.addEventListener("keydown", handleDetectKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, []);

  useEffect(() => {
    if (!scrollRef.current) return;

    scrollRef.current?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
    });
  }, [activeRow]);

  //TODO: @adityasekharbej
  const [keyboardBuffer, _setKeyboardBuffer] = useState(null);
  const keyboardBufferRef = useRef(keyboardBuffer);
  const setKeyboardBuffer = (data) => {
    keyboardBufferRef.current = data;
    _setKeyboardBuffer(data);
  };
  const handleDetectKeyDown = (e) => {
    if (openDeleteModalRef.current?.show || selectRef.current) return;
    if (
      keyboardBufferRef.current &&
      Date.now() - keyboardBufferRef.current?.t < 50
    ) {
      setKeyboardBuffer({
        k: e.keyCode,
        t: Date.now(),
      });
      return;
    }
    setKeyboardBuffer({
      k: e.keyCode,
      t: Date.now(),
    });
    if (e.keyCode === 33) {
      //Pg Up key
      if (activeRowRef.current > 0) {
        setActiveRow(activeRowRef.current - 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 34) {
      //Pg down key
      let totalSales = store.getState().sale.sales;
      if (activeRowRef.current < totalSales.length - 1) {
        setActiveRow(activeRowRef.current + 1);
        e.preventDefault();
      }
    } else if (e.keyCode === 13) {
      //Enter key
      if (salesRowRef.current) {
        salesRowRef.current?.refSaleRowClick();
      }
      e.preventDefault();
    }
    // else if (e.keyCode === 46) {
    //     //Delete key
    //     let totalSales = store.getState().sale.sales;
    //     setOpenDeleteModal({ show: true, data: totalSales[activeRowRef.current] });
    //     e.preventDefault();
    // }
  };

  async function handleDeleteSale(item) {
    try {
      let res = await deleteSale(item.id);
      if (res.status === 200) {
        dispatch(fetchAllSales(searchSaleParams));
        handleGetSaleCount();
        handleGetSaleTotals();
        navigate("/dashboard/all-sales");
        handleSnackbarDetails({
          show: true,
          type: "success",
          title: "Sale deleted successfully",
        });
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
      handleSnackbarDetails({
        show: true,
        title: err.message,
        type: "error",
      });
    }
  }

  const handleGetSaleCount = async () => {
    try {
      let res = await getSalesCount();
      if (res.status === 200) {
        dispatch(setSaleCount(res.data?.entity));
      } else {
        throw new Error(res.data?.message);
      }
    } catch (err) {
      captureException(err);
    }
  };

  return (
    <>
      <DeleteModal
        open={openDeleteModal.show}
        onClose={() => setOpenDeleteModal({ show: false, data: null })}
        handleDelete={() => {
          handleDeleteSale(openDeleteModal.data);
        }}
      />
      <S.Wrapper>
        <S.HeadRow>
          <S.Head flexValue="0.75">Date Created</S.Head>
          <S.Head flexValue="0.7" center>
            Order Id
          </S.Head>
          <S.Head flexValue="0.7" center mr="60px">
            Bill Type
          </S.Head>
          <S.Head flexValue="0.7">Customer Details</S.Head>
          <S.Head flexValue="0.7" center>
            Total SKUs
          </S.Head>
          <S.Head flexValue="0.7" center mr="40px">
            Total Amount
          </S.Head>
          <S.Head flexValue="0.75">Payment Status</S.Head>
        </S.HeadRow>
        {sales?.length ? (
          <S.TableBody ref={tableRef}>
            {sales?.map((item, idx) => (
              <Sale
                item={item}
                key={idx}
                idx={idx}
                activeRow={activeRow}
                scrollRef={scrollRef}
                ref={salesRowRef}
                setOpenDeleteModal={setOpenDeleteModal}
              />
            ))}
          </S.TableBody>
        ) : (
          <S.EmptyTable>
            <div>
              <Lottie animationData={EmptyTable} />
            </div>
          </S.EmptyTable>
        )}
      </S.Wrapper>
    </>
  );
};

export default SalesTable;
