import React from "react";
import * as S from "./create-purchase-table.styles";
import CreatePurchaseItem from "./create-purchase-item/create-purchase-item";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import store from "../../../../store/store";
import { cloneDeep } from "lodash";

const CreatePurchaseTable = ({ products, setProductsList, openReturn, openReturnRef, openPurchasePaymentModalRef, freeQtyPresent }) => {
    const { selectedPurchase } = useSelector((state) => state.purchase);
    const moreInfoRefs = useRef([]);
    const quantityRefs = useRef([]);
    const [activePurchaseRow, _setActivePurchaseRow] = useState(0);
    const activePurchaseRowRef = React.useRef(activePurchaseRow);
    const setActivePurchaseRow = (data) => {
        activePurchaseRowRef.current = data;
        _setActivePurchaseRow(data);
    };

    useEffect(() => {
        document.addEventListener("keydown", handleDetectKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [products]);

    useEffect(() => {
        if (openReturnRef.current) {
            quantityRefs.current[activePurchaseRowRef.current].focus();
        }
    }, [openReturn]);
    const getCurrentQtyIdx = () => {
        return quantityRefs.current?.findIndex((el) => el === document.activeElement);
    };

    const handleDetectKeyDown = (e) => {
        if (openPurchasePaymentModalRef.current) return;
        if (selectedPurchase) {
            if (e.keyCode === 33) {
                //Pg Up key
                if (activePurchaseRowRef.current > 0) {
                    e.preventDefault();
                    setActivePurchaseRow(activePurchaseRowRef.current - 1);
                    if (openReturnRef.current) {
                        quantityRefs.current[activePurchaseRowRef.current].focus();
                    }
                }
            } else if (e.keyCode === 34) {
                //Pg Down key
                let products = store.getState().purchase.selectedPurchase.products;
                if (activePurchaseRowRef.current < products.length - 1) {
                    e.preventDefault();
                    setActivePurchaseRow(activePurchaseRowRef.current + 1);
                    if (openReturnRef.current) {
                        quantityRefs.current[activePurchaseRowRef.current].focus();
                    }
                }
            } else if (e.keyCode === 9 || e.keyCode === 39) {
                //Tab key or Right arrow key
                e.preventDefault();
                moreInfoRefs.current[activePurchaseRowRef.current].click();
            } else if (e.keyCode === 38) {
                //Up Arrow key
                if (openReturnRef.current) {
                    let idx = getCurrentQtyIdx();
                    let product = products[idx];
                    if (activePurchaseRowRef.current >= 0 && product) {
                        e.preventDefault();
                        handleIncrementQuantity(product);
                    }
                }
            } else if (e.keyCode === 40) {
                //Down Arrow key
                if (openReturnRef.current) {
                    let idx = getCurrentQtyIdx();
                    let product = products[idx];
                    if (activePurchaseRowRef.current >= 0 && product) {
                        e.preventDefault();
                        handleDecrementQuantity(product);
                    }
                }
            }
        }
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        if (name === "allSelect") {
            let tempSale = products.map((item) => {
                return { ...item, editable: checked, returnQuantity: checked && item.quantity };
            });
            setProductsList(tempSale);
        } else {
            let tempSale = products.map((item) =>
                item.sku === name ? { ...item, editable: checked, returnQuantity: checked && item.quantity } : item
            );
            setProductsList(tempSale);
        }
    };
    const handleIncrementQuantity = (item) => {
        item.editable = true;
        if (!item.returnQuantity) item.returnQuantity = 0;
        if (item.quantity > parseFloat(item.returnQuantity)) {
            let tempProducts = products.map((el) =>
                el.sku === item.sku
                    ? {
                          ...el,
                          returnQuantity: el.returnQuantity
                              ? parseFloat(el.returnQuantity) + 1 > item.quantity
                                  ? item.quantity
                                  : parseFloat(el.returnQuantity) + 1
                              : 1,
                      }
                    : el
            );
            setProductsList(tempProducts);
        }
    };
    const handleDecrementQuantity = (item) => {
        if (parseFloat(item.returnQuantity) >= 1) {
            item.editable = true;
            if (parseFloat(item.returnQuantity) === 1) {
                item.editable = false;
            }
            let tempProducts = products.map((el) =>
                el.sku === item.sku
                    ? {
                          ...el,
                          returnQuantity: parseFloat(el.returnQuantity) - 1,
                      }
                    : el
            );
            setProductsList(tempProducts);
        }
    };

    const handleReturnInputChange = (val, item) => {
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (val.length > 1 && val[0] === "0" && val[1] !== ".") {
            val = val.substr(1);
        }
        if (!(val === "" || regex.test(val))) return;

        if (!val || parseFloat(val) === 0) {
            item.editable = false;
        } else {
            item.editable = true;
        }
        if (val > item.quantity) {
            val = item.quantity;
        }
        let tempProducts = products.map((el) =>
            el.sku === item.sku
                ? {
                      ...el,
                      returnQuantity: val,
                  }
                : el
        );
        setProductsList(tempProducts);
    };

    const handleUpdateProduct = (product) => {
        const productsCopy = cloneDeep(products);
        const productIdx = productsCopy.findIndex((item) => product.sku === item.sku);

        if (productIdx >= 0) {
            productsCopy[productIdx] = product;
            setProductsList(productsCopy);
        }
    };

    return (
        <S.Table>
            <S.TableHeader>
                {openReturn && (
                    <S.TableHeaderData flexValue="0.3">
                        <S.Check
                            type="checkbox"
                            name="allSelect"
                            onChange={handleCheck}
                            checked={!products?.some((item) => item?.editable !== true)}
                        />
                    </S.TableHeaderData>
                )}
                <S.TableHeaderData flexValue="1">Item Description</S.TableHeaderData>

                <S.TableHeaderData flexValue="0.5" center>
                    Price
                </S.TableHeaderData>
                <S.TableHeaderData flexValue="0.5" center>
                    Qty
                </S.TableHeaderData>
                <S.TableHeaderData flexValue="0.6" center>
                    Purchase Price
                </S.TableHeaderData>
                <S.TableHeaderData flexValue="0.6" center>
                    Discount
                </S.TableHeaderData>
                <S.TableHeaderData flexValue="0.6" center>
                    Tax
                </S.TableHeaderData>
                {!openReturn && (
                    <S.TableHeaderData flexValue="0.5" center>
                        SubTotal
                    </S.TableHeaderData>
                )}
                {openReturn && freeQtyPresent && (
                    <S.TableHeaderData flexValue="0.7" center>
                        Free Qty Return
                    </S.TableHeaderData>
                )}
                {openReturn && (
                    <S.TableHeaderData flexValue="0.7" center>
                        Quantity Return
                    </S.TableHeaderData>
                )}
                {<S.TableHeaderData flexValue="0.25"></S.TableHeaderData>}
            </S.TableHeader>
            <S.TableBody>
                {products?.map((product, index) => {
                    return (
                        <CreatePurchaseItem
                            key={index}
                            product={product}
                            openReturn={openReturn}
                            handleCheck={handleCheck}
                            handleIncrementQuantity={handleIncrementQuantity}
                            handleDecrementQuantity={handleDecrementQuantity}
                            handleReturnInputChange={handleReturnInputChange}
                            handleUpdateProduct={handleUpdateProduct}
                            idx={index}
                            activePurchaseRow={activePurchaseRow}
                            setActivePurchaseRow={setActivePurchaseRow}
                            moreInfoRefs={moreInfoRefs}
                            quantityRefs={quantityRefs}
                            freeQtyPresent={freeQtyPresent}
                        />
                    );
                })}
            </S.TableBody>
        </S.Table>
    );
};

export default CreatePurchaseTable;
