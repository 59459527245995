import React from "react";
import { useDispatch } from "react-redux";
import { removeSearchEmpParams } from "../../store/employee-slice";
import { removeSearchParams, setSearchParams } from "../../store/purchase-slice";
import { removeSearchParams as removeSupplierSearchParams } from "../../store/supplier-slice";
import { removeSearchSaleParams, setSearchSaleParams } from "../../store/sale-slice";
import { removeSearchParams as removeCustomerSearchParams } from "../../store/customer-slice";
import SelectedChip from "./selected-chip/selected-chip";

import * as S from "./selected-chips.styles";
import { removeSelectedChipDue } from "../../store/due-slice";
import { removeSearchParams as removeExpenseSearchParams } from "../../store/expense-slice";
import { removeSearchParams as removeCashSearchParams } from "../../store/cashdrawer-slice";
import { PURCHASE_TYPE, SALES_TYPE, SALE_BILL_TYPE, SALE_TABS } from "../../constants/appConstants";

const SelectedChips = ({ displayData, setDisplayData, type, resetRange, noMargin, clearSearch }) => {
    const dispatch = useDispatch();

    const handleRemoveChip = (key) => {
        if (key === "supplier") {
            setDisplayData({ ...displayData, supplier: {} });
            dispatch(removeSearchParams("supplier_id"));
        } else if (key === "customer") {
            setDisplayData({ ...displayData, customer: {} });
            dispatch(removeSearchSaleParams("patient_id"));
        } else if (key === "expense_type") {
            setDisplayData({ ...displayData, expense_type: {} });
            dispatch(removeExpenseSearchParams("expense_type"));
        } else if (key === "brand") {
            setDisplayData({ ...displayData, brand: {} });
            dispatch(removeSearchParams("brand_id"));
            dispatch(removeSearchSaleParams("brand_id"));
            dispatch(removeSupplierSearchParams("brand_id"));
        } else if (key === "employee") {
            setDisplayData({ ...displayData, employee: {} });
            dispatch(removeSearchParams("employee_id"));
            dispatch(removeSearchSaleParams("employee_id"));
            dispatch(removeSearchEmpParams("employee_id"));
            dispatch(removeExpenseSearchParams("added_by"));
        } else if (key === "payment_status") {
            setDisplayData({ ...displayData, payment_status: {} });
            dispatch(removeSearchParams("is_paid"));
            dispatch(removeSearchSaleParams("is_paid"));
        } else if (key === "date") {
            setDisplayData({ ...displayData, date: "" });
            dispatch(removeSearchParams("start_date"));
            dispatch(removeSearchParams("end_date"));
            dispatch(removeSearchParams("created_at"));
            dispatch(removeSearchSaleParams("start_date"));
            dispatch(removeSearchSaleParams("end_date"));
            dispatch(removeSearchSaleParams("created_at"));
            dispatch(removeExpenseSearchParams("start_date"));
            dispatch(removeExpenseSearchParams("end_date"));
            resetRange();
        } else if (key === "role") {
            setDisplayData({ ...displayData, role: {} });
            dispatch(removeSearchEmpParams("permission_role"));
        } else if (key === "sort") {
            setDisplayData({ ...displayData, sort: {} });
            dispatch(removeSearchParams("sort_by"));
            dispatch(removeSearchParams("is_reversed"));
            dispatch(removeSearchEmpParams("sort_by"));
            dispatch(removeSearchEmpParams("is_reversed"));
            dispatch(removeSearchSaleParams("sort_by"));
            dispatch(removeSearchSaleParams("is_reversed"));
            dispatch(removeSupplierSearchParams("sort_by"));
            dispatch(removeSupplierSearchParams("is_reversed"));
            dispatch(removeExpenseSearchParams("sort_by"));
            dispatch(removeExpenseSearchParams("is_reversed"));
            dispatch(removeCustomerSearchParams("sort_by"));
            dispatch(removeCustomerSearchParams("is_reversed"));
        } else if (key === "due") {
            setDisplayData({ ...displayData, due: "" });
            dispatch(removeSearchSaleParams("all_due"));
            dispatch(removeSearchParams("all_due"));
            dispatch(removeSelectedChipDue());
        } else if (key === "status") {
            setDisplayData({ ...displayData, status: {} });
            dispatch(removeSearchEmpParams("is_activated"));
        } else if (key === "advance_salary") {
            setDisplayData({ ...displayData, advance_salary: "" });
            dispatch(removeSearchEmpParams("advance_salary_min"));
            dispatch(removeSearchEmpParams("advance_salary_max"));
        } else if (key === "monthly_salary") {
            setDisplayData({ ...displayData, monthly_salary: "" });
            dispatch(removeSearchEmpParams("monthly_salary_min"));
            dispatch(removeSearchEmpParams("monthly_salary_max"));
        } else if (key === "cash_type") {
            setDisplayData({ ...displayData, cash_type: {} });
            dispatch(removeCashSearchParams("type"));
        } else if (key === "transaction_type") {
            setDisplayData({ ...displayData, transaction_type: {} });
            dispatch(removeCashSearchParams("reference_type"));
        } else if (key === "pending_payment") {
            setDisplayData({ ...displayData, pending_payment: {} });
            dispatch(removeCustomerSearchParams("pending_payment"));
        } else if (key === "bill_type") {
            setDisplayData({ ...displayData, bill_type: {} });
            if (Object.hasOwn(SALE_BILL_TYPE, displayData.bill_type?.value)) {
                dispatch(removeSearchSaleParams("bill_type"));
            } else {
                dispatch(
                    setSearchSaleParams({ type: `${SALE_TABS.SALE.toUpperCase()},${SALE_TABS.PROFORMA_INVOICE},${SALE_TABS.DELIVERY_CHALLAN}` })
                );
            }
        } else if (key === "purchase_bill_type") {
            dispatch(setSearchParams({ type: `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.PURCHASE_PROFORMA},${PURCHASE_TYPE.PURCHASE_ORDER}` }));
            setDisplayData({ ...displayData, purchase_bill_type: {} });
        }
    };

    const handleClearAllFilter = () => {
        dispatch(removeSearchParams("supplier_id"));
        dispatch(removeSearchSaleParams("patient_id"));
        dispatch(removeSearchParams("brand_id"));
        dispatch(removeSearchSaleParams("brand_id"));
        dispatch(removeSupplierSearchParams("brand_id"));
        dispatch(removeSearchParams("employee_id"));
        dispatch(removeSearchSaleParams("employee_id"));
        dispatch(removeSearchEmpParams("employee_id"));
        dispatch(removeSearchEmpParams("sort_by"));
        dispatch(removeSearchEmpParams("is_reversed"));
        dispatch(removeSearchParams("is_paid"));
        dispatch(removeSearchSaleParams("is_paid"));
        dispatch(removeSearchParams("start_date"));
        dispatch(removeSearchParams("end_date"));
        dispatch(removeSearchParams("created_at"));
        dispatch(removeSearchSaleParams("start_date"));
        dispatch(removeSearchSaleParams("end_date"));
        dispatch(removeSearchSaleParams("created_at"));
        dispatch(removeSearchSaleParams("sort_by"));
        dispatch(removeSearchSaleParams("is_reversed"));
        dispatch(removeSearchEmpParams("permission_role"));
        dispatch(removeSearchParams("sort_by"));
        dispatch(removeSearchParams("is_reversed"));
        dispatch(removeSearchSaleParams("all_due"));
        dispatch(removeSearchParams("all_due"));
        dispatch(removeSearchEmpParams("is_activated"));
        dispatch(removeSearchEmpParams("advance_salary_min"));
        dispatch(removeSearchEmpParams("advance_salary_max"));
        dispatch(removeSearchEmpParams("monthly_salary_min"));
        dispatch(removeSearchEmpParams("monthly_salary_max"));
        dispatch(removeSupplierSearchParams("sort_by"));
        dispatch(removeSupplierSearchParams("is_reversed"));
        dispatch(removeExpenseSearchParams("sort_by"));
        dispatch(removeExpenseSearchParams("is_reversed"));
        dispatch(removeExpenseSearchParams("start_date"));
        dispatch(removeExpenseSearchParams("end_date"));
        dispatch(removeExpenseSearchParams("expense_type"));
        dispatch(removeExpenseSearchParams("added_by"));
        dispatch(removeCashSearchParams("type"));
        dispatch(removeCashSearchParams("reference_type"));
        dispatch(removeCustomerSearchParams("pending_payment"));
        dispatch(removeCustomerSearchParams("sort_by"));
        dispatch(removeCustomerSearchParams("is_reversed"));
        if (Object.hasOwn(SALE_BILL_TYPE, displayData.bill_type?.value)) {
            dispatch(removeSearchSaleParams("bill_type"));
        } else {
            dispatch(setSearchSaleParams({ type: `${SALE_TABS.SALE.toUpperCase()},${SALE_TABS.PROFORMA_INVOICE},${SALE_TABS.DELIVERY_CHALLAN}` }));
        }
        dispatch(setSearchParams({ type: `${PURCHASE_TYPE.PURCHASE},${PURCHASE_TYPE.PURCHASE_PROFORMA},${PURCHASE_TYPE.PURCHASE_ORDER}` }));

        setDisplayData(null);
        if (resetRange) resetRange();
        if (clearSearch) clearSearch();
    };
    return (
        <S.Container noMargin={noMargin}>
            <S.ChipContainer>
                {displayData?.supplier?.name && <SelectedChip text={displayData?.supplier?.name} onRemove={() => handleRemoveChip("supplier")} />}
                {displayData?.expense_type?.name && (
                    <SelectedChip text={`Expense Type: ${displayData?.expense_type?.name}`} onRemove={() => handleRemoveChip("expense_type")} />
                )}
                {displayData?.patient?.name && <SelectedChip text={displayData?.patient?.name} onRemove={() => handleRemoveChip("customer")} />}
                {displayData?.role?.name && (
                    <SelectedChip text={`Permission Role: ${displayData?.role?.name}`} onRemove={() => handleRemoveChip("role")} />
                )}
                {displayData?.status?.name && <SelectedChip text={displayData?.status?.name} onRemove={() => handleRemoveChip("status")} />}
                {displayData?.brand?.name && <SelectedChip text={displayData?.brand?.name} onRemove={() => handleRemoveChip("brand")} />}
                {displayData?.employee?.name && <SelectedChip text={displayData?.employee?.name} onRemove={() => handleRemoveChip("employee")} />}
                {displayData?.sort?.name && <SelectedChip text={displayData?.sort?.name} onRemove={() => handleRemoveChip("sort")} />}
                {displayData?.payment_status?.name && (
                    <SelectedChip text={displayData?.payment_status?.name} onRemove={() => handleRemoveChip("payment_status")} />
                )}
                {displayData?.date && <SelectedChip text={displayData?.date} onRemove={() => handleRemoveChip("date")} />}
                {displayData?.advance_salary && (
                    <SelectedChip text={`Salary Advance: ${displayData?.advance_salary}`} onRemove={() => handleRemoveChip("advance_salary")} />
                )}
                {displayData?.monthly_salary && (
                    <SelectedChip text={`Monthly Salary: ${displayData?.monthly_salary}`} onRemove={() => handleRemoveChip("monthly_salary")} />
                )}
                {displayData?.due && <SelectedChip text={displayData?.due} onRemove={() => handleRemoveChip("due")} />}
                {displayData?.cash_type?.name && <SelectedChip text={displayData?.cash_type?.name} onRemove={() => handleRemoveChip("cash_type")} />}
                {displayData?.transaction_type?.name && (
                    <SelectedChip text={displayData?.transaction_type?.name} onRemove={() => handleRemoveChip("transaction_type")} />
                )}
                {displayData?.pending_payment?.name && (
                    <SelectedChip text={displayData?.pending_payment?.name} onRemove={() => handleRemoveChip("pending_payment")} />
                )}
                {displayData?.bill_type?.name && <SelectedChip text={displayData?.bill_type?.name} onRemove={() => handleRemoveChip("bill_type")} />}
                {displayData?.purchase_bill_type?.name && (
                    <SelectedChip text={displayData?.purchase_bill_type?.name} onRemove={() => handleRemoveChip("purchase_bill_type")} />
                )}
            </S.ChipContainer>
            {(displayData?.supplier?.name ||
                displayData?.patient?.name ||
                displayData?.role?.name ||
                displayData?.status?.name ||
                displayData?.brand?.name ||
                displayData?.employee?.name ||
                displayData?.sort?.name ||
                displayData?.payment_status?.name ||
                displayData?.date ||
                displayData?.advance_salary ||
                displayData?.monthly_salary ||
                displayData?.due ||
                displayData?.cash_type?.name ||
                displayData?.transaction_type?.name ||
                displayData?.pending_payment?.name ||
                displayData?.purchase_bill_type?.name ||
                displayData?.bill_type?.name) &&
                type !== "txn" && <S.ClearFilters onClick={handleClearAllFilter}>Clear all filters (Shift+ESC)</S.ClearFilters>}
        </S.Container>
    );
};

export default SelectedChips;
