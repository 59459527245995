import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: calc(100% - 323px);
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    img {
        margin-left: -10px;
        width: 475px;
    }
`;

export const Title = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #455a64;
`;

export const Dot = styled.div`
    width: 12px;
    height: 12px;
    background: #455a64;
    border-radius: 100%;
`;
export const DotContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4px 0 0 2px;
`;
export const Lists = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
`;
export const List = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #455a64;
    width: 444px;
`;

export const OnlineLottie = styled.div`
    width: 485px;
`;

export const OfflineLottie = styled.div`
    width: 260px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const LottieContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
