import { createSlice } from "@reduxjs/toolkit";
import { getSalePriceWithoutTax, toDecimalPlace } from "../utils/global-fn";

const initialState = {
  returnSaleProducts: [],
  returnSaleQuantity: 0,
  returnSaleTotal: 0,
  taxTotal: 0,
  returnDiscountValue: 0,
  selectedReturnSale: "",
  returnSaleCustomer: "",
  returnCartDiscount: 0,
  returnAdditionalCharges: {},
  totalReturnAdditionalCharges: 0,
  returnCartTotal: 0,
};

const returnSaleSlice = createSlice({
  name: "returnSale",
  initialState,
  reducers: {
    addProductToReturnSale: (state, action) => {
      state.returnSaleProducts.push({
        ...action.payload,
        returnQuantity: 0,
      });
    },
    updateProductFromReturnSale: (state, action) => {
      const newSaleProducts = state.returnSaleProducts.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item
      );
      console.log(newSaleProducts);
      state.returnSaleProducts = newSaleProducts;
    },
    getReturnSaleTotals: (state, action) => {
      let { total, returnQuantity, tax, returnDiscountValue } =
        state.returnSaleProducts.reduce(
          (saleTotal, saleItem) => {
            const {
              sale_price,
              returnQuantity,
              tax_value,
              total_discount,
              isChecked,
              quantity,
              sale_price_with_tax,
            } = saleItem;
            console.log(JSON.stringify(saleItem));
            if (returnQuantity > 0) {
              let salePrice = parseFloat(sale_price);
              let salePriceWotTax = toDecimalPlace(
                getSalePriceWithoutTax(saleItem)
              );
              let discount = total_discount ?? 0;
              let discountPerItem = discount / parseFloat(quantity);
              let salePriceAfterDiscount = salePriceWotTax - discountPerItem;
              let itemTax = tax_value;
              // if (sale_price_with_tax === false) {
              //     salePrice += tax;
              // }

              const itemTotal = isChecked
                ? (salePriceAfterDiscount + itemTax) *
                  parseFloat(returnQuantity)
                : 0;
              const taxTotal = isChecked ? tax_value * returnQuantity : 0;
              const discountTotal = isChecked ? discount : 0;
              saleTotal.total += parseFloat(itemTotal);
              saleTotal.returnQuantity =
                saleTotal.returnQuantity + (isChecked ? returnQuantity : 0);
              saleTotal.tax += taxTotal;
              saleTotal.returnDiscountValue += discountTotal;
            }
            return saleTotal;
          },
          {
            total: 0,
            returnQuantity: 0,
            tax: 0,
            returnDiscountValue: 0,
          }
        );
      total = parseFloat(total.toFixed(2));
      state.returnSaleQuantity = returnQuantity;
      state.returnSaleTotal = total;
      state.taxTotal = tax;
      state.returnDiscountValue = returnDiscountValue;
    },

    getReturnCartTotal: (state, action) => {
      state.returnCartTotal =
        state.returnSaleTotal -
        parseFloat(state.returnCartDiscount) +
        parseFloat(state.totalReturnAdditionalCharges);
    },

    removeAllReturnProducts: (state) => {
      state.returnSaleProducts = [];
    },

    setSelectedReturnSale: (state, action) => {
      state.selectedReturnSale = action.payload;
    },
    removeSelectedReturnSale: (state) => {
      state.selectedReturnSale = "";
    },

    setReturnSaleCustomer: (state, action) => {
      state.returnSaleCustomer = action.payload;
    },
    removeReturnSaleCustomer: (state) => {
      state.returnSaleCustomer = "";
    },
    setReturnCartDiscount: (state, action) => {
      state.returnCartDiscount = action.payload;
    },
    setReturnAdditionalCharges: (state, action) => {
      state.returnAdditionalCharges = action.payload;
      state.totalReturnAdditionalCharges =
        Object.values(action.payload).reduce((a, b) => Number(a) + Number(b)) ||
        0;
    },
    resetReturnAdditionalCharges: (state) => {
      state.returnAdditionalCharges = {};
      state.totalReturnAdditionalCharges = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export default returnSaleSlice.reducer;
export const {
  addProductToReturnSale,
  updateProductFromReturnSale,
  getReturnSaleTotals,
  setSelectedReturnSale,
  removeSelectedReturnSale,
  setReturnSaleCustomer,
  removeReturnSaleCustomer,
  removeAllReturnProducts,
  setReturnCartDiscount,
  setReturnAdditionalCharges,
  resetReturnAdditionalCharges,
  getReturnCartTotal,
} = returnSaleSlice.actions;
