import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    // padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 2px 16px rgba(72, 79, 103, 0.24);
    z-index: 10;
    margin-top: 6px;
`;

export const AddNewContainer = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #2d68fe;
    box-sizing: border-box;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid #e2e8f0;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#CCDFFF" : "#fff")};

    :hover {
        background-color: #f1f5f9;
    }

    & > img {
        width: 20px;
        height: 20px;
    }
`;

export const DataRow = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #334155;
    padding: 8px 14px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${(props) => (props.active ? "#CCDFFF" : "#fff")};
    :hover {
        background-color: #f1f5f9;
    }
`;

export const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
`;
