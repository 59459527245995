import React, { useState } from "react";
import * as S from "./style";
import OnlineStore from "../../../assets/images/gif/online-store.gif";
import Bar from "../page-bar/Bar";
import { BackArrowIcon } from "../../../icons";
import LoadingDialog from "../loading-dialog/LoadingDialog";

const UploadPage = ({ setActivePage }) => {
    const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
    return (
        <S.Wrapper>
            <LoadingDialog
                open={openLoadingDialog}
                onClose={() => {
                    setOpenLoadingDialog(false);
                }}
            />
            <S.LeftImgContainer>
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/7581seumraE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                <S.Title>Watch the video....</S.Title>
            </S.LeftImgContainer>
            <S.RightContainer>
                <div></div>
                <S.RowContainer>
                    <S.PointRow>
                        <S.GreenDot></S.GreenDot>
                        <div>Click on the left icon to start transferring</div>
                    </S.PointRow>
                    <S.DottedDiv></S.DottedDiv>
                    <S.PointRow>
                        <S.GreenDot></S.GreenDot>
                        <div>Find the Rancelab’s icon in the Desktop</div>
                    </S.PointRow>
                    <S.DottedDiv></S.DottedDiv>
                    <S.PointRow>
                        <S.GreenDot></S.GreenDot>
                        <div>Right click on the icon, go to ‘Open File Location’</div>
                    </S.PointRow>
                    <S.DottedDiv></S.DottedDiv>
                    <S.PointRow>
                        <S.GreenDot></S.GreenDot>
                        <div>Open the ‘Data’ folder</div>
                    </S.PointRow>
                    <S.DottedDiv></S.DottedDiv>
                    <S.PointRow>
                        <S.GreenDot></S.GreenDot>
                        <div>Select ‘x.db’ file</div>
                    </S.PointRow>
                    <S.UploadBtn>Upload</S.UploadBtn>
                </S.RowContainer>
                <S.FooterDiv>
                    <S.WhiteButton onClick={() => setActivePage(3)}>
                        <img src={BackArrowIcon} alt="back" />
                    </S.WhiteButton>
                    <S.BarContainer>
                        <Bar />
                        <Bar />
                        <Bar />
                        <Bar size="lg" />
                    </S.BarContainer>
                    <S.ButtonContainer>
                        <S.WhiteButton>Contact us</S.WhiteButton>
                        <S.DarkButton
                            onClick={() => {
                                setOpenLoadingDialog(true);
                            }}
                        >
                            Start Transfer
                        </S.DarkButton>
                    </S.ButtonContainer>
                </S.FooterDiv>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default UploadPage;
