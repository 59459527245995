import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    max-height: calc(100vh-56px);
    box-sizing: border-box;
    padding: 20px 16px 0px;
    background-color: #fff;
    overflow-y: auto;
`;
