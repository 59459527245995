import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import {
    BarcodePrinter,
    BarcodeScanner,
    BillingPOSMachine,
    GreenConnected,
    NetworkIcon,
    PrinterIcon,
    RedNotConnected,
    ThreeDotsVerticalIcon,
    ThreeLineOption,
} from "../../icons";
import * as S from "./connectionOverlay.style";

const ROWS = [
    { id: 1, title: "Barcode Scanner", logo: BarcodeScanner, connected: false },
    {
        id: 2,
        title: "Billing POS Machine",
        logo: BillingPOSMachine,
        connected: true,
    },
    { id: 3, title: "Barcode Printer", logo: BarcodePrinter, connected: true },
    { id: 4, title: "Printer", logo: PrinterIcon, connected: true },
];
const ConnectionOverLay = ({ setOpenDrawer }) => {
    return (
        <OutsideClickHandler onOutsideClick={() => setOpenDrawer(false)}>
            <S.Wrapper>
                <S.Top>
                    <S.Header>
                        <div>
                            <S.Network>
                                <S.Headings>Network</S.Headings>
                                <img src={NetworkIcon} alt="" />
                            </S.Network>
                            <S.SubHead>
                                <img src={GreenConnected} alt="" />
                                Server connected
                            </S.SubHead>
                        </div>
                        <S.IpAddress>Server IP : 192.168.1.1</S.IpAddress>
                    </S.Header>
                    <S.RowWrapper>
                        {ROWS.map((e) => (
                            <S.Row>
                                <S.Content>
                                    <S.LogoImg>
                                        <img src={e.logo} alt="logo" />
                                    </S.LogoImg>
                                    <S.SubContent>
                                        <S.Heading>{e.title}</S.Heading>
                                        <S.ConnectionStatus>
                                            <S.ConnHead>Status</S.ConnHead>
                                            <img
                                                src={
                                                    e.connected
                                                        ? GreenConnected
                                                        : RedNotConnected
                                                }
                                                alt="connection logo"
                                            />
                                            <S.ConnHead
                                                color={
                                                    e.connected
                                                        ? "#10B981"
                                                        : "#FF4343"
                                                }
                                            >
                                                {e.connected
                                                    ? "Connected"
                                                    : "Not connected"}
                                            </S.ConnHead>
                                        </S.ConnectionStatus>
                                    </S.SubContent>
                                </S.Content>
                                <img src={ThreeDotsVerticalIcon} alt="" />
                            </S.Row>
                        ))}
                    </S.RowWrapper>
                </S.Top>
                {/* <S.Footer>
                    <img src={ThreeLineOption} alt="" />
                    <S.FooterHeading>(4) Items</S.FooterHeading>
                </S.Footer> */}
            </S.Wrapper>
        </OutsideClickHandler>
    );
};

export default ConnectionOverLay;
