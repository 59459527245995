import React, { useEffect, useRef, useState } from "react";
import * as S from "./style";

const CustomerSearchDropdown = ({ data, onSelectCustomer, open }) => {
    const scrollRef = useRef(null);

    const [activeRow, _setActiveRow] = useState(0);
    const activeRowRef = useRef(activeRow);
    const setActiveRow = (data) => {
        activeRowRef.current = data;
        _setActiveRow(data);
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
            setActiveRow(0);
        };
    }, [open, data]);

    useEffect(() => {
        if (!scrollRef.current) return;

        scrollRef.current?.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
        });
    }, [activeRow]);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 38) {
            //Arrow up
            if (activeRowRef.current > 0) {
                setActiveRow(activeRowRef.current - 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 40) {
            //Arrow down
            if (activeRowRef.current < data.length - 1) {
                setActiveRow(activeRowRef.current + 1);
                e.preventDefault();
            }
        } else if (e.keyCode === 13) {
            //Enter key
            onSelectCustomer(data[activeRowRef.current]);
            e.preventDefault();
        }
    };

    return (
        <S.Wrapper>
            {Array.isArray(data) && data.length ? (
                data.map((item, idx) => (
                    <React.Fragment key={idx}>
                        <S.DataRow onClick={() => onSelectCustomer(item)} active={idx === activeRow} ref={idx === activeRow ? scrollRef : null}>
                            <S.Name>{item.name}</S.Name>
                            <S.Phone>{item.phone}</S.Phone>
                        </S.DataRow>
                        <S.Line></S.Line>
                    </React.Fragment>
                ))
            ) : (
                <></>
            )}
        </S.Wrapper>
    );
};

export default CustomerSearchDropdown;
