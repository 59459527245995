import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 460px;
    height: calc(100vh - 312px);
    overflow: hidden;
    overflow-y: auto;
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #64748b;
`;

export const ConversionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const InputContainer = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 6px 6px 6px 12px;
    width: 130px;
`;

export const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    color: #94a3b8;
`;
export const Input = styled.input`
    outline: none;
    border: none;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #64748b;
    text-align: ${(props) => props.align};
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
export const SaveBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 34px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
    background: #2c73eb;
`;

export const Head = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #263238;
    margin-bottom: 16px;
`;
export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
`;

export const GreyText = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #64748b;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
`;
