import React from "react";
import * as S from "./style";
import { formatDate } from "../../../utils/format-date";
import { PAYMENT, PAYMENT_MODE_SUMMARY } from "../../../constants/appConstants";
import { toDecimalPlace } from "../../../utils/global-fn";

const PaymentsRow = ({ item }) => {
    return (
        <S.Row>
            <S.Rowdata align="left">{formatDate(item?.created_at)}</S.Rowdata>
            <S.Rowdata>#{item?.id}</S.Rowdata>
            <S.Rowdata>{PAYMENT_MODE_SUMMARY[item?.payment_mode]}</S.Rowdata>
            <S.Rowdata>₱{toDecimalPlace(item?.payment_amount)}</S.Rowdata>
            <S.Rowdata flex="1.3">
                {item?.payment_mode === PAYMENT.LOYALTY_POINT && item?.virtual_amount && <div>{toDecimalPlace(item?.virtual_amount)} Pts</div>}
                {item?.payment_mode === PAYMENT.CHEQUE && item?.account && (
                    <S.ExtraData>
                        <div>Cheque No: {item?.account?.cheque_number}</div>
                        <div>Cheque Date: {formatDate(item?.account?.cheque_date)}</div>
                    </S.ExtraData>
                )}
            </S.Rowdata>
        </S.Row>
    );
};

export default PaymentsRow;
