import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarouselContainer from "../../components/carousel/Carousel";
import OtherDetails from "../../components/RegistationScreenTabs/OtherDetails";
import OtpPage from "../../components/RegistationScreenTabs/OtpPage";
import PersonalDetails from "../../components/RegistationScreenTabs/PersonalDetails";
import StoreDetails from "../../components/RegistationScreenTabs/StoreDetails";
import * as S from "./RegistrationScreen.style";
import { AUTH_SLIDER_DATA } from "../../constants/appConstants";
import { showLoader, hideLoader } from "../../store/app-slice";
import { errorNotify } from "../../utils/toast";
import { registerStore } from "../../api/server/storeApi";
import { setStore } from "../../store/session-slice";
import { YellowArrowleft } from "../../icons";
import { captureException } from "../../crash-reporting";

const RegistrationScreen = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [registrationData, setRegistrationData] = useState({
        store_address: {
            line_1: null,
            line_2: null,
            city: null,
            state: null,
            pin_code: "",
            country: null,
        },
        gst_number: null,
        contact_person: "Admin",
    });
    const [otpState, setOtpState] = useState({
        phone: null,
        tick_left: null,
        otp_verified: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const registerStoreOnline = async () => {
        dispatch(showLoader());
        try {
            const device = await window.electronAPI.getDevice();
            if (!device) {
                throw new Error("Device registering! Please try again!");
            }
            let response = await registerStore({
                ...registrationData,
                device,
            });
            if (response.status === 200) {
                handleSaveStore(response);
            } else {
                errorNotify(response.data.message);
                throw new Error(response.data.message);
            }
        } catch (error) {
            errorNotify(error.message ?? "Store already exists with the same phone number/email. Please log in");
            captureException(error);
        } finally {
            dispatch(hideLoader());
        }
    };

    const updatePreferences = (e) => {
        e.preventDefault();
        console.log("Preference update not yet implemented");
        //go to home
        navigate("/onboarding");
    };

    const handleSaveStore = async (response) => {
        window.electronAPI.addConfig({
            is_server: true,
        });
        //Save the store data
        await window.electronAPI.addStore(response.data);
        window.electronAPI.onStoreAdded(async (event, value) => {
            if (value) {
                await window.electronAPI.startLocalServer(true);
                window.electronAPI.onLocalServerStarted(async (event, value) => {
                    if (value) {
                        //Save store data
                        await dispatch(
                            setStore({
                                ...response.data,
                                password: registrationData.password,
                                is_first_login: true,
                            })
                        );
                        //Sync database
                        navigate("/database-sync");
                    } else {
                        errorNotify("Unable to save current store. Please contact support.");
                    }
                });
            } else {
                errorNotify("Unable to save current store. Please contact support.");
            }
        });
    };

    return (
        <S.Wrapper>
            <S.LeftContainer>
                <CarouselContainer carouselData={AUTH_SLIDER_DATA} />
            </S.LeftContainer>
            <S.RightContainer>
                <S.BackBtn onClick={() => navigate("/landing-screen")}>
                    <img src={YellowArrowleft} alt="HomeDrop" />
                </S.BackBtn>
                <S.TabContainer>
                    <div>Step 0{activeTab}/</div>
                    <div>04</div>
                </S.TabContainer>
                {activeTab === 1 && (
                    <PersonalDetails
                        initialData={registrationData}
                        otpState={otpState}
                        onSubmit={(personalData) => {
                            setRegistrationData({ ...registrationData, ...personalData });
                            setOtpState({
                                phone: personalData.phone,
                                tick_left: otpState.phone === personalData.phone ? otpState.tick_left : null,
                                otp_verified: otpState.phone === personalData.phone ? otpState.otp_verified : false,
                            });
                            const isVerified = otpState.phone === personalData.phone && otpState.otp_verified === true;
                            if (isVerified) {
                                setActiveTab(activeTab + 2);
                            } else {
                                setActiveTab(activeTab + 1);
                            }
                        }}
                    />
                )}
                {activeTab === 2 && (
                    <OtpPage
                        setOtpState={setOtpState}
                        otpState={otpState}
                        setActiveTab={setActiveTab}
                        onSubmit={() => {
                            setOtpState({
                                ...otpState,
                                otp_verified: true,
                            });
                            setActiveTab(activeTab + 1);
                        }}
                    />
                )}
                {activeTab === 3 && (
                    <StoreDetails
                        initialData={registrationData}
                        setRegistrationData={setRegistrationData}
                        setActiveTab={setActiveTab}
                        onSubmit={(storeData) => {
                            setRegistrationData({ ...registrationData, ...storeData });
                            registerStoreOnline();
                        }}
                    />
                )}
                {activeTab === 4 && <OtherDetails setActiveTab={setActiveTab} onSubmit={(e, otherDetail) => updatePreferences(e, otherDetail)} />}
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default RegistrationScreen;
