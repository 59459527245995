import React, { useState } from "react";
import * as S from "./style";
import {
  COLOR,
  PRODUCT_DRAWER_TYPE,
  SALE_BILL_TYPE,
  SALE_SCREENS,
} from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { BlueArrowRight, EmptyCartLogo, WhiteArrowRight } from "../../../icons";
import CartRow from "./CartRow";
import { Drawer } from "@mui/material";
import ProductDrawer, {
  INITIAL_PRODUCT_DISPLAY_DATA,
} from "../../product-drawer/product-drawer";
import { useEffect } from "react";
import store from "../../../store/store";
import {
  addProductToPI,
  removeProductFromPI,
  setCurrentScreenPI,
  updateProductFromPI,
} from "../../../store/proformainvoice-slice";
import { getProduct, getProductsBySKU } from "../../../api/local/productApi";
import { generateSKU } from "../../../utils/sku-upc-generator";
import { captureException } from "../../../crash-reporting";
import CustomButton from "../../custom-button/CustomButton";
import { useRef } from "react";
import SelectProductModal from "../../select-product-modal/select-product-modal";
import {
  setOpenProductDrawer,
  setProductDrawerType,
  setProductDrawerData as setDrawerData,
} from "../../../store/productdrawer-slice";
import { getQuantity, handleGetProductUnitQty } from "../../../utils/global-fn";

const Cart = ({
  handleClearCart,
  openDrawer,
  setOpenDrawer,
  openCustomProduct,
}) => {
  const { PI_currentScreen, PI_products } = useSelector(
    (state) => state.proformaInvoice
  );
  const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
  const [scannedProduct, setScannedProduct] = useState(null);
  const dispatch = useDispatch();
  const [productDrawerData, setProductDrawerData] = useState({
    ...INITIAL_PRODUCT_DISPLAY_DATA,
    sku: generateSKU(),
  });
  const rowsRefs = useRef([]);
  const [activeRow, _setActiveRow] = useState(null);
  const activeRowRef = React.useRef(activeRow);
  const setActiveRow = (data) => {
    activeRowRef.current = data;
    _setActiveRow(data);
  };

  useEffect(() => {
    if (!process.env.REACT_APP_DEMO_SERVER) {
      const removeEventListener = window.electronAPI.onBarcodeScanned(
        async (event, value) => await handleGetProduct(value)
      );
      return () => {
        removeEventListener();
      };
    }
  }, []);

  useEffect(() => {
    if (PI_products.length > 0) {
      getProductsData();
    }
    document.addEventListener("keydown", handleDetectKeyDown, true);
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, []);

  useEffect(() => {
    if (PI_products.length === 0) {
      handleClearCart();
    }
  }, [PI_products]);

  const getProductsData = async () => {
    const payload = {
      ids: PI_products.filter((p) => !p.is_custom_product).map((_p) => _p.sku),
    };
    try {
      let res = await getProductsBySKU(payload);
      if (res.status === 200) {
        let products = res.data?.entity;
        products.forEach((product) => {
          dispatch(updateProductFromPI(product));
        });
      }
    } catch (error) {
      captureException(error);
    }
  };

  const handleDetectKeyDown = (e) => {
    if (e.keyCode === 46) {
      //DELETE Key
      let product =
        store.getState().proformaInvoice.PI_products[activeRowRef.current];
      if (activeRowRef.current >= 0 && product) {
        e.preventDefault();
        dispatch(removeProductFromPI(product));
      }
    } else if (e.keyCode === 38) {
      //UP Arrow key
      let idx = getCurrentIdx();
      let product = store.getState().proformaInvoice.PI_products[idx];
      if (idx >= 0 && product) {
        e.preventDefault();
        handleUpdateQuantity(
          product,
          product.checkProduct,
          Number(product.quantity) + 1
        );
      }
    } else if (e.keyCode === 40) {
      //DOWN Arrow key
      let idx = getCurrentIdx();
      let product = store.getState().proformaInvoice.PI_products[idx];
      if (idx >= 0 && product) {
        e.preventDefault();
        if (product.quantity <= 1) return;
        handleUpdateQuantity(
          product,
          product.checkProduct,
          Number(product.quantity) - 1
        );
      }
    } else if (e.keyCode === 33) {
      //Pg Up key
      if (activeRowRef.current > 0) {
        e.preventDefault();
        setActiveRow(activeRowRef.current - 1);
        rowsRefs.current[activeRowRef.current].focus();
      }
    } else if (e.keyCode === 34) {
      //Pg Down key
      let products = store.getState().proformaInvoice.PI_products;
      if (activeRowRef.current < products.length - 1) {
        e.preventDefault();
        setActiveRow(activeRowRef.current + 1);
        rowsRefs.current[activeRowRef.current].focus();
      }
    }
  };

  const handleUpdateQuantity = (product, checkProduct, value) => {
    let { quantity, wholesale_quantity, wholesale_price, sale_price, price } =
      product;
    let newQuantity = value;
    let quantityBefore = getQuantity(product, SALE_BILL_TYPE.INVOICE);
    let quantityAfter = getQuantity(
      { ...product, quantity: newQuantity },
      SALE_BILL_TYPE.INVOICE
    );

    let qty = handleGetProductUnitQty(product);
    let salePrice = sale_price;
    let mrpPrice = price;

    if (
      quantityBefore < wholesale_quantity &&
      quantityAfter >= wholesale_quantity
    ) {
      salePrice = wholesale_price * qty;
      mrpPrice = checkProduct.price * qty;
    } else if (
      quantityBefore >= wholesale_quantity &&
      quantityAfter < wholesale_quantity
    ) {
      salePrice = checkProduct.sale_price * qty;
      mrpPrice = checkProduct.price * qty;
    }

    dispatch(
      updateProductFromPI({
        ...product,
        quantity: newQuantity,
        sale_price: salePrice,
        price: mrpPrice,
      })
    );
  };

  const getCurrentIdx = () => {
    return rowsRefs.current?.findIndex((el) => el === document.activeElement);
  };

  async function handleGetProduct(barcode) {
    if (openCustomProduct.current) return;
    try {
      let res = await getProduct(barcode, { status: "PUBLISH" });
      if (res.status === 200) {
        //Product is present
        if (res.data.length > 1) {
          setScannedProduct(res.data);
          setOpenSelectProductModal(true);
        } else {
          dispatch(addProductToPI(res.data[0]));
        }
        setOpenDrawer(false);
      } else if (res.status === 404) {
        //Product is not present, Show the product slider to add the product
        setProductDrawerData({
          ...INITIAL_PRODUCT_DISPLAY_DATA,
          upc: barcode,
          sku: generateSKU(),
        });
        setOpenDrawer(true);
      } else {
        throw new Error(res.data?.message);
      }
    } catch (e) {
      captureException(e);
    }
  }

  const handleClick = (type) => {
    if (type === "proceed") {
      dispatch(setCurrentScreenPI(SALE_SCREENS.SUMMARY));
    } else if (type === "edit") {
      dispatch(setCurrentScreenPI(SALE_SCREENS.TABLE));
    }
  };

  const handleEditProduct = (product) => {
    dispatch(setProductDrawerType(PRODUCT_DRAWER_TYPE.EDIT));
    dispatch(setDrawerData(product));
    dispatch(setOpenProductDrawer());
  };

  return (
    <>
      <Drawer
        anchor="right"
        hideBackdrop="true"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        className="right-drawer"
      >
        <ProductDrawer
          type="add"
          onCloseDrawer={(product, type) => {
            setOpenDrawer(false);
            setProductDrawerData({
              ...INITIAL_PRODUCT_DISPLAY_DATA,
              sku: generateSKU(),
            });

            if (type === "Added" && product?.sku) {
              handleGetProduct(product.sku);
            }
          }}
          productData={productDrawerData}
          open={openDrawer}
        />{" "}
      </Drawer>
      <SelectProductModal
        open={openSelectProductModal}
        onClose={() => setOpenSelectProductModal(false)}
        data={scannedProduct}
      />
      <S.Wrapper isFull={PI_currentScreen === SALE_SCREENS.TABLE}>
        <S.Container hasBottom={PI_products?.length}>
          {PI_products?.length ? (
            <>
              <S.TableHeadRow>
                <S.TableHead flexValue="0.3" $serialNo>
                  SN.
                </S.TableHead>
                <S.TableHead flexValue="2" $firstItem>
                  Item Description
                </S.TableHead>
                <S.TableHead>Quantity</S.TableHead>
                {PI_currentScreen === SALE_SCREENS.TABLE && (
                  <S.TableHead>Unit</S.TableHead>
                )}
                <S.TableHead>Price</S.TableHead>
                <S.TableHead flexValue="1.5">Sale Price</S.TableHead>

                <S.TableHead flexValue="1.2">Discount</S.TableHead>
                {<S.TableHead flexValue="1.3">Tax</S.TableHead>}
                <S.TableHead>Subtotal</S.TableHead>
                {PI_currentScreen === SALE_SCREENS.TABLE && (
                  <S.TableHead flexValue="0.5">More</S.TableHead>
                )}
                <S.TableHead flexValue="0.3"></S.TableHead>
              </S.TableHeadRow>

              <S.TBody>
                {PI_products.map((item, idx) => (
                  <CartRow
                    key={idx}
                    item={item}
                    idx={idx}
                    focusRef={rowsRefs}
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                    checkProduct={item?.checkProduct}
                    handleEditProduct={handleEditProduct}
                    handleUpdateQuantity={handleUpdateQuantity}
                  />
                ))}
              </S.TBody>
            </>
          ) : (
            <S.EmptyContainer>
              <img src={EmptyCartLogo} alt="empty cart logo" />
              <div>Search or scan products to add to cart</div>
            </S.EmptyContainer>
          )}
        </S.Container>
        {PI_products?.length ? (
          <S.BottomBar>
            <div></div>

            {PI_products?.length ? (
              <>
                {PI_currentScreen === SALE_SCREENS.TABLE ? (
                  <CustomButton
                    label="Proceed (F12)"
                    color={COLOR.BLUE}
                    fill={true}
                    icon={WhiteArrowRight}
                    iconRight={true}
                    onClick={() => handleClick("proceed")}
                  />
                ) : (
                  <CustomButton
                    label="Expand (F2)"
                    color={COLOR.BLUE}
                    icon={BlueArrowRight}
                    iconRight={true}
                    onClick={() => handleClick("edit")}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </S.BottomBar>
        ) : (
          <></>
        )}
      </S.Wrapper>
    </>
  );
};

export default Cart;
