import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { DrawerContext } from "../../../../DrawerContextProvider/DrawerProvider";

import "./NavItem.scss";
import SubMenu from "../../../SubMenu/SubMenu";
import { useDispatch } from "react-redux";
import {
    NAV_MENU_IDS,
    PURCHASE_SUBMENU,
    SALES_SUBMENU,
    INVENTORY_SUBMENU,
    MARKETING_SUBMENU,
    STAFF_SUBMENU,
    ONLINE_SALES_SUBMENU,
    ANALYTICS_SUBMENU,
    CASH_MANAGEMENT_SUBMENU,
} from "../../../../constants/navItemsData";
import { removeEmployeeSession } from "../../../../store/session-slice";
import LogoutModal from "../../../logout-modal/LogoutModal";

const NavItem = ({ id, name, logo, activeLogo, route, onNavItemClick, selectedNavItemID }) => {
    const navigate = useNavigate();
    const [subMenuData, setSubMenuData] = useState(null);
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    const drawerDetails = useContext(DrawerContext);
    const location = useLocation();
    const dispatch = useDispatch();
    const rippleRef = useRef(id);
    const onRippleStart = (e) => {
        rippleRef.current?.start(e);
    };
    const onRippleStop = (e) => {
        rippleRef.current?.stop(e);
    };

    const handleNavItemClick = () => {
        switch (id) {
            case NAV_MENU_IDS.SALES:
                setOpen(true);
                setSubMenuData(SALES_SUBMENU);
                navigate("/dashboard/new-sale");
                break;
            case NAV_MENU_IDS.PURCHASE:
                setOpen(true);
                setSubMenuData(PURCHASE_SUBMENU);
                navigate("/dashboard/purchases");
                break;
            case NAV_MENU_IDS.INVENTORY:
                setOpen(true);
                setSubMenuData(INVENTORY_SUBMENU);
                navigate("/dashboard/inventory");
                break;
            case NAV_MENU_IDS.MARKETING:
                setOpen(true);
                setSubMenuData(MARKETING_SUBMENU);
                //navigate("/dashboard/suppliers")
                break;
            case NAV_MENU_IDS.ANALYTICS:
                setOpen(true);
                setSubMenuData(ANALYTICS_SUBMENU);
                navigate("/dashboard/analytics");
                break;
            case NAV_MENU_IDS.STAFF:
                setOpen(true);
                setSubMenuData(STAFF_SUBMENU);
                navigate("/dashboard/employees");
                break;
            case NAV_MENU_IDS.LOGOUT:
                handleLogout();
                break;

            case NAV_MENU_IDS.SETTINGS:
                navigate("/dashboard/settings");
                break;
            case NAV_MENU_IDS.CASH_MANAGEMENT:
                setOpen(true);
                setSubMenuData(CASH_MANAGEMENT_SUBMENU);
                navigate("/dashboard/expenses");
                break;
            case NAV_MENU_IDS.ONLINE_SALES:
                setOpen(true);
                setSubMenuData(ONLINE_SALES_SUBMENU);
                navigate("/dashboard/online-orders");
                break;
            case NAV_MENU_IDS.HELP_CENTER:
                navigate("/dashboard/help-center");
                break;
            default:
                navigate("/login");
                break;
        }

        onNavItemClick(id);
    };

    const handleEmployeeLogout = () => {
        dispatch(removeEmployeeSession());
        navigate("/login");
    };
    const handleLogout = () => {
        let path = location.pathname.split("/")[2];
        if (path === "sales" || path === "new-sale" || path === "new-purchase" || path === "new-purchase-return" || path === "new-damage-entry") {
            setOpenLogout(true);
        } else {
            handleEmployeeLogout();
        }
    };

    return (
        <>
            <LogoutModal open={openLogout} onClose={() => setOpenLogout(false)} onClick={handleEmployeeLogout} />
            <div
                type="button"
                style={{
                    position: "relative",
                    backgroundColor: id === selectedNavItemID && "#1E293B",
                    // margin: !drawerDetails.leftDrawerOpen && "0 11px 0 18px",
                    padding: drawerDetails.leftDrawerOpen ? "12px 24px 12px 24px" : "12px 24px 12px 24px",
                }}
                onClick={handleNavItemClick}
                onMouseDown={onRippleStart}
                onMouseUp={onRippleStop}
                className="left-drawer-nav-item"
            >
                <img
                    src={id === selectedNavItemID ? activeLogo : logo}
                    alt="nav-item-logo"
                    style={{
                        marginRight: drawerDetails.leftDrawerOpen ? "13px" : "24px",
                    }}
                />
                <div style={{ color: id === selectedNavItemID && "#FFFFFF" }} className="left-drawer-nav-item__name">
                    {name}
                </div>
                <TouchRipple ref={rippleRef} center={false} />
            </div>
            {open && selectedNavItemID === id && <SubMenu data={subMenuData} setOpen={setOpen} />}
            {/* {subMenuData && openSubMenu && <SubMenu data={subMenuData}/>} */}
        </>
    );
};

export default NavItem;
