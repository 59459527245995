import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 30px 16px 16px 16px;
    width: 100%;
    max-height: calc(100vh-56px);
`;
export const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`;
export const RightTopContainer = styled.div`
    display: flex;
    align-items: center;
`;
// export const Today = styled.div`
//     font-weight: 400;
//     font-size: 12px;
//     line-height: 16px;
//     color: #334155;
//     margin-right: 12px;
// `;
export const TotalDataBox = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${(props) => props.mr && props.mr};
`;
export const NewSaleBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    // width: 83px;
    background: #2d68fe;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
`;
export const Chips = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Chip = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => (props.active ? "#3b82f6" : "#64748B")};
    background: ${(props) => (props.active ? "#dbeafe" : "#f1f5f9")};
    border-radius: 2px;
    cursor: pointer;

    & span {
        min-width: 30px;
        height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        background: ${(props) => (props.active ? "#3B82F6" : "#E2E8F0")};
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => (props.active ? "#fff" : "#334155")};
    }
`;
export const Body = styled.div`
    width: 100%;
    height: calc(100vh - 154px);
    background: #fff;
    border: 0.704375px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;
export const TableTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 11px 16px 4px;
    border-bottom: 1px solid #e2e8f0;
    max-height: 53px;
    height: 53px;
`;
export const TableChips = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
`;
export const TableChip = styled.div`
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: ${(props) => (props.active ? "#3B82F6" : "#64748B")};
    box-sizing: border-box;
    padding: 18px 0;
    border-bottom: ${(props) => (props.active ? "2px solid #3B82F6" : "2px solid #fff")};
    & span {
        min-width: 30px;
        height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        background: ${(props) => (props.active ? "#3B82F6" : "#E2E8F0")};
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: ${(props) => (props.active ? "#fff" : "#334155")};
    }
`;
export const SortContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #334155;
`;
export const InputBox = styled.div`
    width: 230px;
`;
export const Input = styled.input`
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    margin-left: 10px;

    &::placeholder {
        color: #cbd5e1;
    }
`;
export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    box-sizing: border-box;
    padding: 16px 16px 0;
`;
export const DatePickerContainer = styled.div`
    position: absolute;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    z-index: 10;
    margin-left: -173px;
`;

export const DateChooserSelect = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    padding: 9px 8px 9px 8px;
    display: flex;
    align-items: center;
    max-height: 38px;
    cursor: pointer;
    gap: 8px;

    img {
        height: 20px;
        width: 20px;
    }
`;

export const ChipContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
`;
export const PageContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
    // border: 1px solid red;
    // position: fixed;
    // bottom: 16px;
    // right: 16px;
`;

export const ArrowBox = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
export const TodayDataContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 16px;
`;
export const WhiteBox = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #253238;
`;
export const BlueBox = styled.div`
    background: #ccdfff;
    border-radius: 4px;
    padding: 6px;
`;
export const Today = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #334155;
`;
export const WhiteBoxContainer = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    gap: 12px;
    padding: 6px 8px;
`;
export const Bar = styled.div`
    width: 1px;
    height: 12px;
    border-right: 1px solid #94a3b8;
`;
export const RangeContainer = styled.div`
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 6px 8px 6px 8px;
`;

export const TableTopRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const DownloadContainer = styled.div`
    width: 36px;
    height: 36px;
    cursor: pointer;

    & > img {
        width: 100%;
        height: 100%;
    }
`;
