import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 214px);
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 19px 24px;
`;
export const HeadingRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f1f5f9;
    height: 36px;
    box-sizing: border-box;
    padding: 12px;
    margin-right: 6px;
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #64748b;
    flex: 1;
`;
export const TableBody = styled.div`
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 280px);
`;
export const TableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border: 1px solid #e2e8f0;
    cursor: pointer;
`;
export const TableData = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => (props.color ? props.color : "#94a3b8")};
    height: 70px;
    flex: 1;
    box-sizing: border-box;
    padding-left: 4px;
`;

export const EmptyTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    & > div {
        width: 228px;
        height: 228px;
    }
    & > img {
        width: 280px;
        height: 280px;
    }
`;
