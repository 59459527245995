import React from "react";
import * as S from "./supplier-content.styles";
import Address from "../../customers/customer-address/Address";
import InputField from "../../input-field/InputField";
import { checkValidGstAfterChange } from "../../../utils/validity-fns";
import { handleGetGstInfo } from "../../../utils/api-fns";
import { useDispatch, useSelector } from "react-redux";

const SupplierContent = ({ displayData, setDisplayData, errorInput, setErrorInput }) => {
    const dispatch = useDispatch();
    const { appOnline } = useSelector((state) => state.app);
    const { gstCache } = useSelector((state) => state.apiCache);

    const handleStateChange = (key, item) => {
        setDisplayData({ ...displayData, state: item.value });
    };

    const handleInputChange = async (event) => {
        let { name, value } = event.target;
        let gstData = null;
        if (name === "phone") {
            const regex = /^[0-9\b]+$/;
            if (!(value === "" || regex.test(value))) return;
            if (value.length > 10) return;
        } else if (name === "gst_number") {
            if (value.length > 15) return;
            value = value.toUpperCase();
            let valid = checkValidGstAfterChange(value);
            setErrorInput({ ...errorInput, [name]: !valid });
            if (value.length === 15) {
                setDisplayData({ ...displayData, [name]: value });
                gstData = await handleGetGstInfo(value, appOnline, gstCache, dispatch);
            }
        }

        if (gstData && name === "gst_number") {
            let { company_name, address } = gstData;
            setDisplayData({ ...displayData, [name]: value, name: company_name, ...(address ? address : {}) });
        } else {
            setDisplayData({ ...displayData, [name]: value });
        }
    };

    return (
        <S.SupplierContentContainer>
            <S.SupplierContentBody>
                <S.Label>Contact Information</S.Label>
                <InputField label="Name" value={displayData.name} name="name" onChange={handleInputChange} fullWidth={true} required={true} />
                <InputField
                    label="TAX Number"
                    value={displayData.gst_number}
                    name="gst_number"
                    onChange={handleInputChange}
                    fullWidth={true}
                    error={errorInput.gst_number}
                    helperText={errorInput.gst_number && "Invalid TAX Number"}
                />
                <S.RightInputPhone>
                    <InputField
                        label="Phone Number"
                        value={displayData.phone}
                        name="phone"
                        onChange={handleInputChange}
                        fullWidth={true}
                        prefix={<S.CountryCode>+63</S.CountryCode>}
                    />
                </S.RightInputPhone>
                <InputField label="Email" value={displayData.email} name="email" onChange={handleInputChange} fullWidth={true} />
            </S.SupplierContentBody>
            <S.SupplierContentBody>
                <S.Label>Billing Address</S.Label>
                <Address address={displayData} setAddress={setDisplayData} />
            </S.SupplierContentBody>
        </S.SupplierContentContainer>
    );
};

export default SupplierContent;
