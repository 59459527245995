import React, { useState } from "react";
import { PaginationArrowIcon } from "../../../icons";

import * as S from "./pagination-bar.styles";
import { useSelector } from "react-redux";

const PaginationBar = ({ value, handlePageChange, limit, type }) => {
    // const [activePage, setActivePage] = useState(1);

    // const handlePageChange = (type) => {
    // if (type === "prev") {
    //   if (activePage === 1) return;
    //   dispatch(setSearchParams({ page: activePage - 1 }));
    //   setActivePage(activePage - 1);
    // } else {
    //   if (productsData.products.length < PAGE_LIMIT) return;
    //   dispatch(setSearchParams({ page: activePage + 1 }));
    //   setActivePage(activePage + 1);
    // }
    // };
    const { purchases } = useSelector((state) => state.purchase);
    const { sales } = useSelector((state) => state.sale);

    return (
        <S.Container>
            <S.ArrowContainer disable={value === 1} prev onClick={() => handlePageChange(value - 1)}>
                <img src={PaginationArrowIcon} alt="prev" />
                Previous (F2)
            </S.ArrowContainer>
            <S.Page active>{value}</S.Page>
            {type === "purchase" && (
                <S.ArrowContainer disable={purchases?.length < limit} next onClick={() => handlePageChange(value + 1)}>
                    Next (F3)
                    <img src={PaginationArrowIcon} alt="next" />
                </S.ArrowContainer>
            )}
            {type === "sale" && (
                <S.ArrowContainer disable={sales?.length < limit} next onClick={() => handlePageChange(value + 1)}>
                    Next (F3)
                    <img src={PaginationArrowIcon} alt="next" />
                </S.ArrowContainer>
            )}
        </S.Container>
    );
};

export default PaginationBar;
