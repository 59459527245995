const BARCODE_MIN_LENGTH = 6
var buffer = [];
var timeout;

function onInputChange(e, onChange, ignore = false) {
    const { name, value } = e.target;
    const c = value.substr(value.length - 1);
    if (ignore) {
        onChange({ name, value })
        return;
    }
    if (/^[a-zA-Z0-9]+$/.test(c)) {
        buffer.push(c)
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            if (buffer.length < BARCODE_MIN_LENGTH) {
                onChange({ name, value: value.substr(0, value.length - buffer.length) + buffer.join("") })
            }
            buffer = []
        }, 60);
        return;
    }
    onChange({ name, value: value })
    buffer = []
}

export default onInputChange;