import { CASHDRAWER_API, CASHDRAWER_COUNTS_API } from "../../constants/apiRoutes";
import axios from "./axios";

const getAllCashDrawerItems = async (queryParams = {}) => {
    return await axios.get(CASHDRAWER_API, {
        params: queryParams,
    });
};

const getCashDrawerItem = async (cashId) => {
    return await axios.get(`${CASHDRAWER_API}/${cashId}`);
};

const addCashDrawerItem = async (payload) => {
    return await axios.post(CASHDRAWER_API, payload);
};

const getCashDrawerCounts = async (queryParams = {}) => {
    return await axios.get(CASHDRAWER_COUNTS_API, {
        params: queryParams,
    });
};

export { getAllCashDrawerItems, getCashDrawerItem, addCashDrawerItem, getCashDrawerCounts };
