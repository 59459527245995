import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    //   width: 772px;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    //   border: 2px solid red;
`;

export const LeftImageContainer = styled.div`
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    background-color: #253238;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

export const Head = styled.div`
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 370px;
    text-align: center;
    letter-spacing: 0.115em;
    color: #fff;
`;

export const SubHead = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #94a3b8;
    //
    background: rgba(0, 0, 0, 0.41);
    box-sizing: border-box;
    text-align: center;
    padding: 5px 19%;
    // width: 370px;
    text-align: center;
`;
export const RightLoginContainer = styled.div`
    width: 55%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    //   justify-content: center;
    padding-top: 30px;
`;
export const StoreHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2d68fe;
    margin: 18px 0 18px;

    & > div {
        white-space: nowrap;
        max-width: 58%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const LoginContainer = styled.div`
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    padding: 24px 40px;
    width: 499px;
`;
export const ContainerHead = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #64748b;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 6px;

    & > div {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #113546;
        white-space: nowrap;
        max-width: 330px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
export const InputFIelds = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 6px 8px;
    width: 419px;
    height: 48px;
    & img {
        cursor: pointer;
    }

    & input {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #334155;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        &::placeholder {
            color: #94a3b8;
        }
    }
`;

export const Label = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #64748b;
    margin: 20px 0;
`;
export const BlueBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 419px;
    height: 48px;
    gap: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background: #0f2feb;
    border-radius: 4px;
    margin: 18px 0;
    cursor: pointer;
    border: none;
`;
export const ORDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    box-sizing: border-box;
`;
export const GradientLineLeft = styled.div`
    height: 2px;
    width: 40%;
    background-image: linear-gradient(to left, rgba(226, 232, 240, 0), rgba(226, 232, 240, 1));
`;
export const GradientLineRight = styled.div`
    height: 2px;
    width: 40%;
    background-image: linear-gradient(to right, rgba(226, 232, 240, 0), rgba(226, 232, 240, 1));
`;
export const QuickAvatarHead = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #1e293b;
    margin: 8px 0;
`;
export const AvatarContainer = styled.div`
    display: flex;
    gap: 17px;
    flex-wrap: wrap;
    // max-height: 80px;
    // overflow-y: hidden;
    // overflow-y: scroll;
    // border: 1px solid red;
`;

export const Avatar = styled.div`
    width: 56px;
    height: 56px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.color};
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    border-radius: 100%;

    & .tooltip {
        visibility: hidden;
        min-width: 100px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        padding: 3px;
        position: absolute;
        z-index: 1;
        bottom: 110%;
        font-size: 12px;
        text-transform: none;

        & ::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }

    :hover .tooltip {
        visibility: visible;
    }
`;
export const AnimationDiv = styled.div`
    width: 298px;
    height: 314.37px;
`;

export const ForgetText = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #253238;
    margin-top: 12px;
    cursor: pointer;
`;

export const BackBtnContainer = styled.div`
    width: 499px;
    margin-top: 30px;
`;

export const BackBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #263238;
    border-radius: 4px;
    cursor: pointer;
    height: 28px;
    width: 28px;
`;

export const AvatarWrapper = styled.div`
    // border: 1px solid red;
`;
