import moment from "moment";
import React from "react";
import * as S from "./style";

const TransactionRow = ({ item }) => {
    return (
        <S.TransRow>
            <S.Reason>
                <span>Reason: </span>
                {item.reason}
            </S.Reason>
            <S.MiddleBar></S.MiddleBar>
            <S.RightDiv>
                <S.Date>{moment(item.timestamp).format("llll")}</S.Date>
                <S.Amount>₱{item.amount}</S.Amount>
                <S.Record amount={item.amount}>
                    {item.amount < 0 ? "Credit" : "Debit"} Record
                </S.Record>
            </S.RightDiv>
            {/* <S.RowTop>
                <S.Date>
                    {new Date(item.timestamp).toGMTString({ hour12: true }).split("GMT")[0]}
                </S.Date>
                <S.Record amount={item.amount}>
                    {item.amount < 0 ? "Repay" : "Debt"} Record
                </S.Record>
            </S.RowTop>
            <S.RowBottom>
                <S.Reason>Reason: {item.reason}</S.Reason>
                <S.Amount amount={item.amount}>₱{Math.abs(item.amount)}/-</S.Amount>
            </S.RowBottom> */}
        </S.TransRow>
    );
};

export default TransactionRow;
