import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
    width: 354px;
    height: 367px;
    box-sizing: border-box;
    padding: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
export const StoreHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    & > img {
        width: 48px;
        height: 48px;
    }
`;

export const StoreHeading = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2d68fe;
`;
export const UserDetail = styled.div`
    text-align: center;
`;
export const UserName = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #334155;
`;
export const PasswordText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #64748b;
`;
export const InputFIeld = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 8px;
    width: 320px;
    height: 36px;
    & img {
        cursor: pointer;
    }

    & input {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #334155;
        outline: none;
        border: none;
        padding: 0;
        flex: 1;
        &::placeholder {
            color: #94a3b8;
        }
    }
`;
export const Label = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #64748b;
    margin: 10px 0 8px;
`;
export const Button = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: #3b82f6;
    border-radius: 2px;
    width: 320px;
    height: 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 32px;
    border: none;
    outline: none;
`;
