import React, { useRef } from "react";
import * as S from "./cart.styles";
import "./style.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  ArrowUp,
  ArrowDrop,
  DeleteRedIcon,
  ProductPlaceholderIcon,
  WifiDisconnectedIcon,
  MoreInfoIcon,
  MoreDOttedInfoIcon,
} from "../../../icons";
import { generateMetrics } from "./../../../utils/metrics";
import { useState } from "react";
import {
  DiscountOption,
  PRICE_TYPE,
  SALE_BILL_TYPE,
  SALE_SCREENS,
  TAX_VALUES,
} from "../../../constants/appConstants";
import { useContext } from "react";
import { DrawerContext } from "../../../DrawerContextProvider/DrawerProvider";
import {
  removeProductFromSale,
  updateProductFromSale,
} from "../../../store/sale-slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import onInputChange from "./../../../utils/handleInputChange";
import AlternateUnit from "../alternate-unit/AlternateUnit";
import CustomSelect from "../../product-drawer/custom-select/custom-select";
import Tippy from "@tippyjs/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { calendarFormatDate } from "../../../utils/format-date";
import SelectTax from "../select-tax/SelectTax";
import Taxable from "../taxable/Taxable";
import {
  getAltUnit,
  getQuantity,
  getSalePriceWithoutTax,
  getSaleProductSubTotal,
  handleGetProductUnitQty,
  toDecimalPlace,
} from "../../../utils/global-fn";
import ProductImage from "../../product-image/ProductImage";
import { CollectionsBookmarkOutlined } from "@mui/icons-material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#334155",
    color: "#FFFFFF",
    maxWidth: 220,
    border: "1px solid #dadde9",
    borderRadius: "2px",
    width: "184px",
    // height: "99px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    fontEeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    padding: "10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#334155",
  },
}));

const CartRow = ({
  item,
  idx,
  activeRow,
  setActiveRow,
  focusRef,
  checkProduct,
  handleEditProduct,
  handleUpdateQuantity,
}) => {
  const { currentFocus, currentScreen } = useSelector((state) => state.sale);
  const { billTypePreference } = useSelector((state) => state.app);
  const [qty, setQty] = useState(1);
  const drawerDetails = useContext(DrawerContext);
  const dispatch = useDispatch();
  const [unitHint, setUnitHint] = useState(null);

  useEffect(() => {
    setUnitHint(getAltUnit(item));
  }, [item]);

  useEffect(() => {
    if (idx === currentFocus) {
      focusRef.current[idx].focus();
      setActiveRow(currentFocus);
    }
  }, [currentFocus]);
  useEffect(() => {
    if (item.discount) {
      onDiscountChange(item.discount);
    }
  }, [
    item.activeDiscount,
    item.quantity,
    item.sale_price,
    item.sale_price_with_tax,
    item.tax,
  ]);

  useEffect(() => {
    handleGetProductUnitQty(item, setQty, false);
  }, [item.unit]);

  function handleIncrementQuantity(item) {
    handleUpdateQuantity(item, checkProduct, Number(item.quantity) + 1);
  }
  function handleDecrementQuantity(item) {
    if (item.quantity <= 1) return;
    handleUpdateQuantity(item, checkProduct, Number(item.quantity) - 1);
  }
  function handleRemoveProduct(item) {
    dispatch(removeProductFromSale(item));
  }
  function handleQuantityChange(e, item) {
    onInputChange(e, (data) => {
      let { value } = data;
      onQuantityChange(value, item);
    });
  }

  const onQuantityChange = (value, item) => {
    const regex = /^\d+\.?(\d)?(\d)?$/;
    if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
      value = value.substr(1);
    }

    let newValue;
    if (value === "") newValue = "0";
    else if (!regex.test(value)) newValue = 1;
    else newValue = value;

    handleUpdateQuantity(item, checkProduct, newValue);
  };

  function handleOutFocusQuantity() {
    if (item.quantity === 0) {
      dispatch(
        updateProductFromSale({
          ...item,
          quantity: parseFloat(1),
        })
      );
    }
  }
  function handleDiscountChange(e) {
    onInputChange(e, (data) => {
      let { value } = data;
      onDiscountChange(value);
    });
  }

  function onDiscountChange(value) {
    const regex = /^\d+\.?(\d)?(\d)?$/;
    if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
      value = value.substr(1);
    }
    if (!(value === "" || regex.test(value))) return;

    let salePriceWot = getSalePriceWithoutTax(item);
    let sale_price_sum = salePriceWot * item.quantity;
    if (item.activeDiscount === DiscountOption.FLAT) {
      if (value > sale_price_sum) {
        value = sale_price_sum;
      }
    }
    if (item.activeDiscount === DiscountOption.PERCENTAGE) {
      if (value > 100) {
        value = 100;
      }
    }
    dispatch(
      updateProductFromSale({
        ...item,
        discount: value || 0,
        discountValue:
          item.activeDiscount === DiscountOption.FLAT
            ? parseFloat(value || 0)
            : sale_price_sum * (parseFloat(value || 0) / 100),
      })
    );
  }

  const handleRowFocus = () => {
    setActiveRow(idx);
    focusRef.current[idx].focus();
  };

  const handleInputChange = (e, type) => {
    onInputChange(e, (data) => {
      const { value } = data;
      if (type === "sale_price") {
        onSalePriceChange(value);
      } else {
        if (type === "hsn") {
          const regex = /^[0-9\b]+$/;
          if (!(value === "" || regex.test(value))) return;
        }
        dispatch(
          updateProductFromSale({
            ...item,
            [type]: value.toUpperCase(),
          })
        );
      }
    });
  };

  const onSalePriceChange = (value) => {
    const regex = /^\d+\.?(\d)?(\d)?$/;
    if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
      value = value.substr(1);
    }
    if (!(value === "" || regex.test(value))) return;

    dispatch(
      updateProductFromSale({
        ...item,
        sale_price: value || 0,
      })
    );
  };

  const handleUnitChange = (item, unit) => {
    let { sale_price, price, wholesale_quantity, wholesale_price } = item;
    let salePrice = sale_price;
    let mrpPrice = price;
    let newProduct = { ...item, unit: unit };
    let unitQty = handleGetProductUnitQty(newProduct);
    let quantity = getQuantity(newProduct, billTypePreference?.sale_bill_type);

    if (wholesale_quantity && wholesale_price) {
      if (quantity >= wholesale_quantity) {
        salePrice = wholesale_price * unitQty;
        mrpPrice = checkProduct.price * unitQty;
      } else if (quantity < wholesale_quantity) {
        salePrice = checkProduct.sale_price * unitQty;
        mrpPrice = checkProduct.price * unitQty;
      }
    } else {
      salePrice = checkProduct.sale_price * unitQty;
      mrpPrice = checkProduct.price * unitQty;
    }

    dispatch(
      updateProductFromSale({
        ...item,
        unit: unit,
        sale_price: salePrice,
        price: mrpPrice,
        discountValue: 0,
        discount: 0,
      })
    );
  };

  const onInputClick = (e) => {
    e.stopPropagation();
    setActiveRow(idx);
  };

  const handleDateChange = (key, date) => {
    dispatch(
      updateProductFromSale({
        ...item,
        [key]: date,
      })
    );
  };

  const handleSelectChange = (item, selectedvalue, type) => {
    dispatch(
      updateProductFromSale({
        ...item,
        [type]: selectedvalue,
      })
    );
  };

  const setActiveDiscount = (value) => {
    dispatch(updateProductFromSale({ ...item, activeDiscount: value }));
  };

  return (
    <S.TableRow
      key={idx}
      active={idx === activeRow}
      onClick={() => handleRowFocus()}
    >
      <S.RowData flexValue="0.3" $left $full>
        <S.TableDataSn>
          <div>{idx + 1}</div>
        </S.TableDataSn>
      </S.RowData>
      <S.RowData flexValue="2" $left $full>
        <S.TableDataDetails
          onClick={() => {
            if (!item.is_custom_product) handleEditProduct(item);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ProductImage
              icon={(item.gallery && item.gallery?.[0]) || ProductPlaceholderIcon}
            />
            <HtmlTooltip
              arrow
              placement="top"
              title={
                <React.Fragment>
                  {!item.is_custom_product && (
                    <>
                      <div className="sku">SKU: {item.sku}</div>
                      <div className="upc">UPC: {item.upc}</div>
                    </>
                  )}
                  <div className="upc">
                    Purchase Price: ₱{toDecimalPlace(item.purchase_price)}
                  </div>
                  <div className="upc">{item.name}</div>
                </React.Fragment>
              }
            >
              <div>
                <S.ItemDetailsName wide={drawerDetails.leftDrawerOpen}>
                  {item.name}
                </S.ItemDetailsName>
                {item.is_custom_product ? (
                  <S.ItemDetailsBrand>Custom Product</S.ItemDetailsBrand>
                ) : (
                  <>
                    <S.ItemDetailsBrand>{item.brand?.name}</S.ItemDetailsBrand>
                    <S.ItemDetailsQuantity wide={drawerDetails.leftDrawerOpen}>
                      {generateMetrics(item)}
                    </S.ItemDetailsQuantity>
                  </>
                )}
              </div>
            </HtmlTooltip>
          </div>
        </S.TableDataDetails>
      </S.RowData>
      <S.RowData $full>
        <S.TableDataQuantity onClick={() => setActiveRow(idx)}>
          <div>
            <input
              type="text"
              value={item.quantity || 0}
              onChange={(e) => handleQuantityChange(e, item)}
              onFocus={(e) => e.target.select()}
              ref={(el) => (focusRef.current[idx] = el)}
              onBlur={handleOutFocusQuantity}
            />
            <S.ArrowBox>
              <img
                src={ArrowUp}
                alt="arrow up"
                onClick={() => handleIncrementQuantity(item)}
              />
              <img
                src={ArrowDrop}
                alt="arrow drop"
                onClick={() => handleDecrementQuantity(item)}
              />
            </S.ArrowBox>
          </div>
          {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
            !item.is_custom_product && (
              <S.WarningText>
                {toDecimalPlace(checkProduct.stock)} in stock
              </S.WarningText>
            )}
          {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.POS && (
            <S.WarningText>
              {item.quantity > checkProduct.stock && "Greater than stock"}
            </S.WarningText>
          )}
        </S.TableDataQuantity>
      </S.RowData>
      {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.POS && (
        <S.RowData flexValue="0.5">
          <S.TableDataStock>
            {toDecimalPlace(checkProduct.stock)}
          </S.TableDataStock>
        </S.RowData>
      )}

      {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
        currentScreen === SALE_SCREENS.TABLE && (
          <S.RowData column>
            <S.TableAltUnit>
              <AlternateUnit
                item={item}
                onChange={(unit) => handleUnitChange(item, unit)}
              />
            </S.TableAltUnit>
            {unitHint && <S.UnitHintText>{unitHint}</S.UnitHintText>}
          </S.RowData>
        )}

      <S.RowData>
        <S.TableDataPrice>
          ₱
          {item.wholesale_quantity &&
          getQuantity(item, billTypePreference?.sale_bill_type) >=
            item.wholesale_quantity
            ? (checkProduct.wholesale_price * qty)?.toFixed(2)
            : (checkProduct.sale_price * qty)?.toFixed(2)}
          <br />
          {item.wholesale_quantity &&
          getQuantity(item, billTypePreference?.sale_bill_type) >=
            item.wholesale_quantity ? (
            <S.StrikeTextPrice>
              <S.StrikedPrice>
                ₱ {checkProduct.sale_price?.toFixed(2)}
              </S.StrikedPrice>
            </S.StrikeTextPrice>
          ) : checkProduct.price &&
            checkProduct.sale_price !== checkProduct.price ? (
            <S.StrikeTextPrice>
              <S.StrikedPrice>
                ₱ {(checkProduct.price * qty).toFixed(2)}
              </S.StrikedPrice>
            </S.StrikeTextPrice>
          ) : (
            <></>
          )}
        </S.TableDataPrice>
      </S.RowData>
      <S.RowData flexValue="1.5">
        <S.SalePrice
          onClick={(e) => {
            e.stopPropagation();
            setActiveRow(idx);
          }}
        >
          <div>
            ₱
            <input
              type="text"
              value={item.sale_price}
              onChange={(e) => handleInputChange(e, "sale_price")}
            />
          </div>
        </S.SalePrice>
        {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
          currentScreen === SALE_SCREENS.TABLE && (
            <Taxable
              item={item}
              onChange={(unit, type) => handleSelectChange(item, unit, type)}
              type="sale_price_with_tax"
            />
          )}
      </S.RowData>

      <S.RowData flexValue="1.2">
        <S.TableDataDiscount
          onClick={(e) => {
            e.stopPropagation();
            setActiveRow(idx);
          }}
        >
          <S.SymbolDiv>
            <S.Symbol
              active={item.activeDiscount === DiscountOption.FLAT}
              onClick={() => setActiveDiscount(DiscountOption.FLAT)}
            >
              ₱
            </S.Symbol>
            <S.MiddleBar></S.MiddleBar>

            <S.Symbol
              active={item.activeDiscount === DiscountOption.PERCENTAGE}
              onClick={() => setActiveDiscount(DiscountOption.PERCENTAGE)}
            >
              %
            </S.Symbol>
          </S.SymbolDiv>
          <S.InputDiv>
            <input
              type="text"
              value={item.discount}
              onChange={(e) => handleDiscountChange(e)}
            />
          </S.InputDiv>
        </S.TableDataDiscount>
      </S.RowData>
      {
        <S.RowData flexValue="1.3">
          <SelectTax
            item={item}
            onChange={(unit, type) => handleSelectChange(item, unit, type)}
            type="tax"
            dropDown={
              billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE
            }
          />
        </S.RowData>
      }

      <S.RowData>
        <S.TableDataSubtotal>
          <div>₱{getSaleProductSubTotal(item)?.itemTotal}</div>
          <S.WarningText>
            {item.wholesale_quantity &&
            getQuantity(item, billTypePreference?.sale_bill_type) >=
              item.wholesale_quantity
              ? "Wholesale price applied"
              : ""}
          </S.WarningText>
        </S.TableDataSubtotal>
      </S.RowData>
      {billTypePreference?.sale_bill_type === SALE_BILL_TYPE.INVOICE &&
        currentScreen === SALE_SCREENS.TABLE && (
          <S.RowData flexValue="0.5" center>
            <Tippy
              placement="bottom"
              trigger="click"
              interactive
              content={
                <MoreInfo
                  item={item}
                  onInputClick={onInputClick}
                  handleDateChange={handleDateChange}
                  handleInputChange={handleInputChange}
                />
              }
            >
              {item?.hsn ||
              item?.batch_number ||
              item?.manufacturing_date ||
              item?.expiry_date ? (
                <img src={MoreDOttedInfoIcon} alt="info" />
              ) : (
                <img src={MoreInfoIcon} alt="info" />
              )}
            </Tippy>
          </S.RowData>
        )}
      <S.RowData flexValue="0.3">
        <S.TableDataDelete>
          <img
            src={DeleteRedIcon}
            alt="delete"
            onClick={() => handleRemoveProduct(item)}
          />
        </S.TableDataDelete>
      </S.RowData>
    </S.TableRow>
  );
};

export default CartRow;

export const MoreInfo = ({
  item,
  onInputClick,
  handleDateChange,
  handleInputChange,
}) => {
  return (
    <S.MoreInfoWrapper>
      <S.OptionDiv>
        <S.OptionHeader>HSN / SAC</S.OptionHeader>
        <S.OptionInputContainer>
          <S.Input
            type="text"
            value={item.hsn}
            onChange={(e) => handleInputChange(e, "hsn")}
            onClick={onInputClick}
          />
        </S.OptionInputContainer>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Batch Number</S.OptionHeader>
        <S.OptionInputContainer>
          <S.Input
            type="text"
            value={item.batch_number}
            onChange={(e) => handleInputChange(e, "batch_number")}
            onClick={onInputClick}
          />
        </S.OptionInputContainer>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Mfg Date</S.OptionHeader>
        <div onClick={onInputClick}>
          <DatePicker
            selected={Date.parse(
              calendarFormatDate(item?.manufacturing_date ?? null)
            )}
            onChange={(date) => handleDateChange("manufacturing_date", date)}
            dateFormat="dd/MM/yyyy"
            className="sale-invoice-date-picker"
            // maxDate={createdAt || new Date()}
            showMonthDropdown
            isClearable={item?.manufacturing_date !== null}
          />
        </div>
      </S.OptionDiv>
      <S.OptionDiv>
        <S.OptionHeader>Expiry Date</S.OptionHeader>
        <div onClick={onInputClick}>
          <DatePicker
            selected={Date.parse(calendarFormatDate(item?.expiry_date ?? null))}
            onChange={(date) => handleDateChange("expiry_date", date)}
            dateFormat="dd/MM/yyyy"
            className="sale-invoice-date-picker"
            minDate={item.manufacturing_date || new Date()}
            showMonthDropdown
            isClearable={item?.expiry_date !== null}
          />
        </div>
      </S.OptionDiv>
    </S.MoreInfoWrapper>
  );
};
