import React, { useContext, useEffect, useRef, useState } from "react";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { DrawerContext } from "../../DrawerContextProvider/DrawerProvider";
import { PlusGrayIcon } from "../../icons";

import BasicInformation from "./basic-information/basic-information";
import ProductMetrics from "./product-metrics/product-metrics";
import ProductInformation from "./product-information/product-information";

import * as S from "./product-drawer.styles";
import CustomInput from "./custom-input/custom-input";
import {
  addProduct,
  getProduct,
  updateProduct,
} from "../../api/local/productApi";
import {
  AGE_RESTRICTED_OPTIONS,
  DELETE_MODAL,
  DISCARD_MODAL,
  DiscountOption,
  MODAL_TYPES,
  PRICE_TYPE,
  PRODUCT_DRAWER_TABS,
  PRODUCT_STATUS,
  PRODUCT_STATUS_MAPPING,
  TAX_VALUES,
  UNITS_LIST,
} from "../../constants/appConstants";
import { fetchAllProducts } from "../../store/product-slice";
import { useDispatch, useSelector } from "react-redux";
import { generateSKU, generateUPC } from "../../utils/sku-upc-generator";
import { Modal } from "@mui/material";
import ConfirmationDialog from "./confirmation-dialog/confirmation-dialog";
import { SnackbarContext } from "../../snackbar-context/snackbar-context";
import OutsideClickHandler from "react-outside-click-handler";
import Label from "./label/Label";
import { useLocation } from "react-router";
import { captureException } from "../../crash-reporting";
import { hasDecimal, toDecimalPlace } from "../../utils/global-fn";
import { uploadImageToS3 } from "../../api/aws/s3Client";
import CropperModal from "./cropper-modal/CropperModal";

const PRODUCT_TABS = {
  PRODUCT_INFO: "Product Information",
  PRDUCT_PRICING: "Pricing and Inventory",
  PRODUCT_METRICS: "Metrics & Attributes",
};

const INITIAL_PRODUCT_DISPLAY_DATA = {
  sku: generateSKU(),
  upc: "",
  brand: {},
  category: {},
  type: {},
  age_restricted: {},
  short_description: "",
  name: "",
  purchase_price: "",
  price: "",
  sale_price: "",
  tax: {},
  tax: {},
  stock: null,
  min_quantity: null,
  max_quantity: null,
  metrics: [{ unit: "", value: "" }],
  attributes: [["", ""]],
  low_stock_warning: "",
  sale_price_with_tax: { name: "With Tax", value: true },
  purchase_price_with_tax: { name: "With Tax", value: true },
  wholesale_price: "",
  wholesale_quantity: "",
  enable_alternate_unit: false,
  alternate_unit: {
    base_unit: "",
    secondary_unit: "",
    base_conversion: "",
    secondary_conversion: "",
  },
  batch_number: "",
  manufacturing_date: null,
  expiry_date: null,
  hsn: "",
  discount: 0,
  activeDiscount: DiscountOption.FLAT,
};

const initialError = {
  info: "",
  pricing: "",
  metrics: "",
};

/**
 *
 * onCloseDrawer - (product, type)
 */
const ProductDrawer = ({
  type = "add",
  onCloseDrawer,
  onAddNewSKU,
  productData = INITIAL_PRODUCT_DISPLAY_DATA,
  setProductData,
  open,
  isNewSKU = false,
}) => {
  const drawerDetails = useContext(DrawerContext);
  const [tabView, _setTabView] = useState(0);
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [displayData, _setDisplayData] = useState({
    ...INITIAL_PRODUCT_DISPLAY_DATA,
    sku: generateSKU(),
  });
  const [originalData, _setOriginalData] = useState({});
  const originalDataRef = useRef(originalData);
  const setOriginalData = (data) => {
    originalDataRef.current = data;
    _setOriginalData(data);
  };
  const [openModal, setOpenModal] = useState({
    show: false,
    type: null,
    data: null,
  });
  const { handleSnackbarDetails } = useContext(SnackbarContext);
  const [dataError, setDataError] = useState(initialError);
  // const [openBarcodeModal, setOpenBarcodeModal] = useState({
  //     show: false,
  //     data: null,
  // });
  const { searchParams } = useSelector((state) => state.product);
  const { store } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const tabViewRef = useRef(tabView);
  const setTabView = (data) => {
    tabViewRef.current = data;
    _setTabView(data);
  };

  const displayDataRef = useRef(displayData);

  const setDisplayData = (data) => {
    displayDataRef.current = data;
    _setDisplayData(data);
  };
  const priceRef = useRef();
  const selectRef = useRef(false);
  const location = useLocation();

  //for crop image
  const [cropperImg, setCropperImg] = useState({ open: false, image: null });

  useEffect(() => {
    if (type === "edit" && productData) {
      getProduct(productData.id)
        .then((res) => {
          const entity = res.data?.entity;
          if (res.status === 200 && entity) {
            frameDisplayData({
              ...entity,
              purchase_price: toDecimalPlace(entity.purchase_price),
              sale_price: toDecimalPlace(entity.sale_price),
              price: toDecimalPlace(entity.price),
              stock: toDecimalPlace(entity.stock),
            });
          } else if (res.status !== 404) {
            throw new Error(res.data?.message);
          }
        })
        .catch((err) => captureException(err));
    } else if (type === "add" && productData && isNewSKU) {
      frameDisplayData(productData);
    } else {
      setDisplayData({ ...productData });
    }
  }, [productData, type]);

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleDetectKeyDown, true);
    }
    return () => {
      document.removeEventListener("keydown", handleDetectKeyDown, true);
    };
  }, [open]);

  const handleDetectKeyDown = (e) => {
    if (selectRef.current) return;
    if (open) {
      try {
        if (e.keyCode === 120) {
          //F9 key
          if (type !== "add") {
            e.preventDefault();
            onAddNewSKU(productData);
          }
        } else if (e.keyCode === 33) {
          // Pg Up key
          e.preventDefault();
          handleFinalBtn();
        } else if (e.keyCode === 34) {
          // Pg Down key
          e.preventDefault();
          if (tabViewRef.current === 1) {
            setTabView(0);
            priceRef.current?.focus();
          }
          if (tabViewRef.current === 2) setTabView(1);
        } else if (e.keyCode === 123) {
          //F12 key
          e.preventDefault();
          handleSaveBtn();
        } else if (e.keyCode === 121) {
          //F10 key
          e.preventDefault();
          handleClearForm();
        } else if (e.keyCode === 122) {
          //F11 key
          e.preventDefault();
          handleSecondBtn(PRODUCT_STATUS.DRAFT);
        } else if (e.keyCode === 119) {
          //F8 key
          if (type !== "add") {
            e.preventDefault();
            handleDeleteProduct();
          }
        } else if (e.keyCode === 118) {
          //F7 key
          // e.preventDefault();
          // if (productData?.status === PRODUCT_STATUS.UN_PUBLISH) {
          //     handleSecondBtn(PRODUCT_STATUS.PUBLISH);
          // } else if (productData?.status === PRODUCT_STATUS.PUBLISH && type !== "add") {
          //     handleSecondBtn(PRODUCT_STATUS.UN_PUBLISH);
          // }
        } else if (e.keyCode === 27) {
          //ESC key
          e.preventDefault();
          handleDrawerClose();
        }
      } catch (error) {
        //
      }
    }
  };

  const frameDisplayData = (data) => {
    const mappingData = {
      sku: data.sku || generateSKU(),
      upc: data.upc || generateUPC(),
      brand: data.brand,
      category: data.category,
      type: data.type,
      short_description: data.short_description,
      name: data.name,
      purchase_price: data.purchase_price,
      price: data.price,
      sale_price: data.sale_price,
      stock: data.stock,
      min_quantity: data.min_quantity,
      max_quantity: data.max_quantity,
      metrics: data.metrics?.length ? data.metrics : [{ unit: "", value: "" }],
      age_restricted: {},
      tax: {},
      attributes: [["", ""]],
      tags: data.tags || [],
      gallery: data.gallery ? data.gallery : [],
      low_stock_warning: data.low_stock_warning ?? "",
      sale_price_with_tax:
        data.sale_price_with_tax || data.sale_price_with_tax === null
          ? PRICE_TYPE[0]
          : PRICE_TYPE[1],
      purchase_price_with_tax:
        data.purchase_price_with_tax || data.purchase_price_with_tax === null
          ? PRICE_TYPE[0]
          : PRICE_TYPE[1],
      wholesale_price: data.wholesale_price ?? "",
      wholesale_quantity: data.wholesale_quantity ?? "",
      enable_alternate_unit: data.enable_alternate_unit ?? false,
      batch_number: data.batch_number ?? "",
      manufacturing_date: data.manufacturing_date
        ? new Date(data.manufacturing_date)
        : null,
      expiry_date: data.expiry_date ? new Date(data.expiry_date) : null,
      store_id: data.store_id,
      id: data.id,
      hsn: data.hsn ?? "",
    };
    if (data.enable_alternate_unit) {
      const base = UNITS_LIST.findIndex(
        (option) => option.value === data.alternate_unit?.base_unit
      );
      const secondary = UNITS_LIST.findIndex(
        (option) => option.value === data.alternate_unit?.secondary_unit
      );
      if (base >= 0 && secondary >= 0) {
        let altUnit = {
          base_unit: UNITS_LIST[base],
          secondary_unit: UNITS_LIST[secondary],
        };
        if (data.alternate_unit?.conversion < 1) {
          altUnit.base_conversion = 1 / data.alternate_unit?.conversion;
          altUnit.secondary_conversion = 1;
        } else {
          altUnit.secondary_conversion = data.alternate_unit?.conversion;
          altUnit.base_conversion = 1;
        }
        mappingData.alternate_unit = altUnit;
      }
    }
    if (data.gallery?.length) {
      setImgSrc(data.gallery?.[0] + `?n=${data.date_modified}`);
    }
    if (typeof data.age_restricted === "number") {
      const result = AGE_RESTRICTED_OPTIONS.findIndex(
        (option) => +option.value === +data.age_restricted
      );
      if (result >= 0)
        mappingData.age_restricted = AGE_RESTRICTED_OPTIONS[result];
    }
    if (typeof data.tax === "number") {
      const result = TAX_VALUES.findIndex(
        (option) => +option.value === +data.tax
      );
      if (result >= 0) mappingData.tax = TAX_VALUES[result];
    }
    if (data.tax) {
      const result = TAX_VALUES.findIndex((option) =>
        isEqual(option.value, data.tax)
      );
      if (result >= 0) mappingData.tax = TAX_VALUES[result];
    }
    if (Object.keys(data.attributes ?? {})?.length) {
      mappingData.attributes = Object.entries(data.attributes);
    }

    if (data.price && data.sale_price && data.price > data.sale_price) {
      let diff = Number(data.price) - Number(data.sale_price);
      let p = toDecimalPlace((diff * 100) / Number(data.price));
      if (hasDecimal(p)) {
        mappingData.discount = toDecimalPlace(diff);
        mappingData.activeDiscount = DiscountOption.FLAT;
      } else {
        mappingData.discount = toDecimalPlace(p);
        mappingData.activeDiscount = DiscountOption.PERCENTAGE;
      }
    } else {
      mappingData.discount = 0;
      mappingData.activeDiscount = DiscountOption.FLAT;
    }
    setOriginalData(mappingData);
    setDisplayData(mappingData);
  };

  const updateFormDataAttribute = (key, value) => {
    const formDataCopy = cloneDeep(formData);
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const onChangeImage = (event) => {
    let image = event.target.files[0];
    if (image) setCropperImg({ open: true, image: image });
  };

  const onSubmitCropImage = async (image) => {
    if (type === "edit") {
      handleUpdateImageToProduct(image, displayData);
    } else if (type === "add") {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result);
      });
      reader.readAsDataURL(image);
      setSelectedFile(image);
    }
  };

  const handleInputChange = (key, val) => {
    updateFormDataAttribute(key, val);
    setDisplayData({ ...displayDataRef?.current, [key]: val });
  };

  const frameFormData = (data) => {
    if (!data.name) return;
    const frameData = {
      name: data.name,
    };
    if (!isEmpty(data.category)) frameData.category = data.category?.id;
    if (!isEmpty(data.brand)) frameData.brand = data.brand?.id;
    if (!isEmpty(data.tags)) frameData.tags = data.tags;
    if (!isEmpty(data.tax)) frameData.tax = data.tax.value;
    if (data.short_description)
      frameData.short_description = data.short_description;
    if (data.min_quantity >= 0) frameData.min_quantity = +data.min_quantity;
    if (data.max_quantity >= 0) frameData.max_quantity = +data.max_quantity;

    if (data.price >= 0) frameData.price = +data.price;
    else frameData.price = 0;

    if (data.sale_price >= 0) frameData.sale_price = +data.sale_price;
    else frameData.sale_price = 0;

    if (data.purchase_price >= 0)
      frameData.purchase_price = +data.purchase_price;
    if (data.stock) frameData.stock = +data.stock;

    const metrics = data.metrics.filter(
      (metric) => metric.unit || metric.value
    );
    if (isEmpty(metrics)) frameData.metrics = [];
    else frameData.metrics = metrics;

    var attributes = [];
    try {
      attributes = data.attributes?.filter(
        (attribute) => attribute[0] || attribute[1]
      );
    } catch (error) {}

    if (isEmpty(attributes)) frameData.attributes = {};
    else frameData.attributes = Object.fromEntries(attributes);

    if (!isEmpty(data.age_restricted))
      frameData.age_restricted = data.age_restricted.value;
    if (!isEmpty(data.discount))
      frameData.age_restricted = data.age_restricted.value;
    if (data.gallery && data.gallery?.length > 0) {
      frameData.gallery = data.gallery;
    }
    if (data.low_stock_warning)
      frameData.low_stock_warning = +data.low_stock_warning;
    if (!isEmpty(data.sale_price_with_tax))
      frameData.sale_price_with_tax = data.sale_price_with_tax?.value;
    if (!isEmpty(data.purchase_price_with_tax))
      frameData.purchase_price_with_tax = data.purchase_price_with_tax?.value;
    if (data.wholesale_price >= 0)
      frameData.wholesale_price = +data.wholesale_price;
    if (data.wholesale_quantity >= 0)
      frameData.wholesale_quantity = +data.wholesale_quantity;
    if (data.enable_alternate_unit !== null)
      frameData.enable_alternate_unit = data.enable_alternate_unit;
    if (data.enable_alternate_unit) {
      let altUnit = {
        base_unit: data.alternate_unit?.base_unit?.value,
        secondary_unit: data.alternate_unit?.secondary_unit?.value,
        conversion:
          data.alternate_unit.base_conversion == 1
            ? +data.alternate_unit.secondary_conversion
            : 1 / +data.alternate_unit.base_conversion,
      };
      frameData.alternate_unit = altUnit;
    }
    if (data.batch_number !== undefined)
      frameData.batch_number = data.batch_number;
    if (data.manufacturing_date !== undefined)
      frameData.manufacturing_date = data.manufacturing_date
        ? data.manufacturing_date.getTime()
        : null;
    if (data.expiry_date !== undefined)
      frameData.expiry_date = data.expiry_date
        ? data.expiry_date.getTime()
        : null;
    if (data.hsn !== undefined) frameData.hsn = data.hsn;
    if (!isEmpty(data.tax)) frameData.tax = data.tax.value;

    if (type === "add") {
      frameData.sku = data.sku;
      frameData.upc = data.upc;
    }

    if (type === "edit") {
      frameData.sku = data.sku ?? productData.sku;
      frameData.upc = data.upc ?? productData.upc;
      frameData.status = productData.status;
    }

    return frameData;
  };

  const validatePricingTab = () => {
    if (
      +displayDataRef?.current?.purchase_price < 0 ||
      +displayDataRef?.current?.price < 0 ||
      +displayDataRef?.current?.sale_price < 0
    ) {
      handleErrorSnackbar(
        "Purchase Price/MRP/Sale Price must be greater than equal to 0"
      );
      setDataError({
        ...dataError,
        pricing:
          "Purchase Price/MRP/Sale Price must be greater than equal to 0",
      });
      return false;
    }

    if (
      parseFloat(displayDataRef?.current?.purchase_price) >
      parseFloat(displayDataRef?.current?.price)
    ) {
      handleErrorSnackbar("Purchase price must be less or equal to MRP");
      setDataError({
        ...dataError,
        pricing: "Purchase price must be less or equal to MRP",
      });
      return false;
    }

    // if (+displayData.stock < -1 && +displayData.stock) {
    //     handleErrorSnackbar("Total available items must be greater than 0");
    //     setDataError({
    //         ...dataError,
    //         pricing: "Total available items must be greater than 0",
    //     });
    //     return false;
    // }

    if (
      parseInt(+displayDataRef?.current?.min_quantity) &&
      parseInt(+displayDataRef?.current?.max_quantity) &&
      +displayDataRef?.current?.min_quantity >=
        +displayDataRef?.current?.max_quantity
    ) {
      handleErrorSnackbar("Max quantity must be greater than min quantity");
      setDataError({
        ...dataError,
        pricing: "Max quantity must be greater than min quantity",
      });
      return false;
    }

    if (
      displayDataRef?.current?.enable_alternate_unit &&
      displayDataRef?.current?.alternate_unit
    ) {
      let { base_unit, secondary_unit, base_conversion, secondary_conversion } =
        displayDataRef?.current?.alternate_unit;
      if (
        !base_unit ||
        !secondary_unit ||
        !base_conversion ||
        !secondary_conversion
      ) {
        handleErrorSnackbar("Please enter complete alternate units");
        return false;
      }

      if (base_conversion != 1 && secondary_conversion != 1) {
        handleErrorSnackbar(
          "Either side of the alternate unit's value must be 1 "
        );
        return false;
      }
    }

    return true;
  };

  const handleErrorSnackbar = (msg) => {
    handleSnackbarDetails({
      show: true,
      type: "error",
      title: msg,
    });
  };

  const validateMetricsTab = () => {
    const metrics = displayDataRef?.current?.metrics.filter(
      (metric) => metric.unit || metric.value
    );
    const attributes = Array.isArray(displayDataRef?.current?.attributes)
      ? displayDataRef?.current?.attributes.filter(
          (attribute) => attribute[0] || attribute[1]
        )
      : [];
    let isValid = true;
    metrics.forEach((metric) => {
      if (metric.unit && !metric.value) {
        handleErrorSnackbar("Please fill all metric values");
        setDataError({
          ...dataError,
          metrics: "Please fill all metric values",
        });
        isValid = false;
        return;
      }
      if (!metric.unit && metric.value) {
        handleErrorSnackbar("Please fill all metric units");
        setDataError({
          ...dataError,
          metrics: "Please fill all metric units",
        });
        isValid = false;
        return;
      }
    });

    if (!isValid) return isValid;

    attributes.forEach((attribute) => {
      if (attribute[0] && !attribute[1]) {
        handleErrorSnackbar("Please fill all attribute values");
        setDataError({
          ...dataError,
          metrics: "Please fill all attribute values",
        });
        isValid = false;
        return;
      }
      if (!attribute[0] && attribute[1]) {
        handleErrorSnackbar("Please fill all attribute units");
        setDataError({
          ...dataError,
          metrics: "Please fill all attribute units",
        });
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  const handleSecondBtn = (status) => {
    if (validatePricingTab() && validateMetricsTab()) {
      const payload = frameFormData(displayDataRef?.current);
      if (!payload) {
        handleSnackbarDetails({
          show: true,
          type: "error",
          title: "Please enter Name/UPC/SKU",
        });
        return;
      }

      payload.status = status;
      handleApi(payload);
    }
  };

  const handleDelete = (status) => {
    const payload = frameFormData(displayDataRef?.current);
    payload.status = status;
    handleApi(payload);
  };

  const handleFinalBtn = () => {
    if (tabViewRef.current === 0) {
      if (validatePricingTab()) setTabView(1);
      return;
    } else if (tabViewRef.current === 1) {
      if (validatePricingTab()) setTabView(2);
      return;
    } else if (tabViewRef.current === 2) {
      // if (!validateMetricsTab()) return;
      return;
    }

    const payload = frameFormData(displayDataRef?.current);

    if (payload) handleApi({ ...payload, status: "PUBLISH" });
    else {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter Name",
      });
    }
  };

  const handleSaveBtn = () => {
    if (tabViewRef.current === 0) {
      if (!validatePricingTab()) return;
    } else if (tabViewRef.current === 1) {
      if (!validatePricingTab()) return;
    } else if (tabViewRef.current === 2) {
      if (!validateMetricsTab()) return;
    }

    const payload = frameFormData(displayDataRef?.current);
    if (payload) handleApi({ ...payload });
    else {
      handleSnackbarDetails({
        show: true,
        type: "error",
        title: "Please enter Name/UPC/SKU",
      });
    }
  };

  const handleApi = async (payload) => {
    if (type === "edit") {
      try {
        console.log(displayDataRef?.current);
        let res = await updateProduct(displayDataRef?.current?.id, payload);
        if (res.status === 200) {
          handleClearForm();
          dispatch(fetchAllProducts(searchParams));
          handleSnackbarDetails({
            show: true,
            title: `${
              payload.status === PRODUCT_STATUS.TRASH ? "Deleted" : "Edited"
            } succesfully`,
            type: "success",
            subtitle: `The product has been ${
              payload.status === PRODUCT_STATUS.TRASH ? "deleted" : "edited"
            } succesfully`,
          });
          onCloseDrawer(
            res.data,
            payload.status === PRODUCT_STATUS.TRASH ? "Deleted" : "Edited"
          );
        } else {
          throw new Error(res.data?.message);
        }
      } catch (error) {
        captureException(error);
      }
    } else if (type === "add") {
      try {
        let res = await addProduct(payload);
        if (res.status === 200) {
          if (selectedFile) {
            let product = res.data?.entity;
            handleUpdateImageToProduct(selectedFile, product);
          }
          handleClearForm();
          dispatch(fetchAllProducts(searchParams));
          handleSnackbarDetails({
            show: true,
            type: "success",
            title: "Added succesfully",
            subtitle: "The product has been added successfully.",
          });

          if (onCloseDrawer) {
            onCloseDrawer(res.data.entity, "Added");
          }
        } else {
          throw new Error(res.data?.message);
        }
      } catch (error) {
        captureException(error);
      }
    }
  };

  const handleUpdateImageToProduct = async (selectedFile, product) => {
    let imgKey = `store-products/${store?.uid}/${product?.id}.png`;
    try {
      let s3Res = await uploadImageToS3(selectedFile, imgKey);
      if (s3Res.status === "success") {
        let gallery = [s3Res.img_url];
        try {
          let res = await updateProduct(product.id, { gallery: gallery });
          if (res.status === 200) {
            setImgSrc(s3Res.img_url + `?n=${res.data?.date_modified}`);
            dispatch(fetchAllProducts(searchParams));
            handleSnackbarDetails({
              show: true,
              type: "success",
              title: "Succesful",
              subtitle: "Image has been updated successfully.",
            });
          } else {
            throw new Error("Unable to upload image");
          }
        } catch (error) {
          throw new Error(error);
        }
      } else {
        handleSnackbarDetails({
          show: true,
          type: "error",
          title: "Unable to upload image.",
        });
      }
    } catch (error) {
      captureException(error);
    }
  };

  const checkDataChange = () => {
    return isEqual(displayDataRef?.current, originalDataRef.current);
  };

  const handleDrawerClose = () => {
    if (cropperImg.open || openModal.show) return;
    if (type === "edit") {
      if (checkDataChange()) {
        onCloseDrawer(displayDataRef?.current);
      } else {
        setOpenModal({
          show: true,
          type: MODAL_TYPES.DISCARD,
          data: DISCARD_MODAL,
        });
      }
    } else {
      if (!displayDataRef?.current?.name) {
        onCloseDrawer(displayDataRef?.current);
      } else {
        setOpenModal({
          show: true,
          type: MODAL_TYPES.DISCARD,
          data: DISCARD_MODAL,
        });
      }
    }
  };

  const handleGenerateUPCSKU = (key) => {
    if (key === "sku")
      setDisplayData({ ...displayDataRef?.current, sku: generateSKU() });
    else if (key === "upc")
      setDisplayData({ ...displayDataRef?.current, upc: generateUPC() });
  };

  // const handleCloseBarcode = () => {
  //     setOpenBarcodeModal({ show: false, data: null });
  //     if (onCloseDrawer) {
  //         onCloseDrawer(displayData)
  //     };
  // };

  const handleDeleteProduct = () => {
    setOpenModal({
      show: true,
      type: MODAL_TYPES.DELETE,
      data: DELETE_MODAL,
    });
  };

  const handleConfirmationModalSubmit = () => {
    setOpenModal({ show: false, type: null, data: null });

    if (openModal.type === MODAL_TYPES.DELETE)
      handleDelete(PRODUCT_STATUS.TRASH);
    else if (openModal.type === MODAL_TYPES.DISCARD) {
      onCloseDrawer(displayDataRef?.current);
    }
  };

  const handleClearForm = () => {
    // console.log(displayData.stock);
    setTabView(0);
    setFormData({});
    setImgSrc(null);
    setDisplayData({
      ...INITIAL_PRODUCT_DISPLAY_DATA,
      sku: generateSKU(),
    });
    setOriginalData({});
    setDataError(initialError);
    setSelectedFile(null);
  };

  // const handleAddNewSKU = () => {
  //     console.log("Adding sku");
  //     setProductDrawerType("add");
  //     // console.log(typeof setProductData);
  //     // setProductData(displayDataRef?.current);
  // };

  return (
    <>
      <Modal
        open={openModal.show}
        onClose={() => setOpenModal({ show: false, type: null, data: null })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfirmationDialog
          closeModal={() =>
            setOpenModal({ show: false, type: null, data: null })
          }
          data={openModal.data}
          type={openModal.type}
          handleSubmit={handleConfirmationModalSubmit}
          actionType={type}
          handleDraft={() => handleSecondBtn(PRODUCT_STATUS.DRAFT)}
          open={openModal.show}
        />
      </Modal>
      <CropperModal
        open={cropperImg.open}
        onClose={() => setCropperImg({ open: false, image: null })}
        image={cropperImg.image}
        onSubmit={(image) => onSubmitCropImage(image)}
      />
      {/* <BarcodeDialog
                open={openBarcodeModal.show}
                data={openBarcodeModal.data}
                onClose={handleCloseBarcode}
            /> */}
      <OutsideClickHandler onOutsideClick={() => handleDrawerClose()}>
        <S.Container>
          <S.Header>
            <div>
              <S.Title>{type === "add" ? "Add new" : "Edit"} product</S.Title>
              {type === "add" ? (
                <S.SubTitle>Scan the product to auto-fill</S.SubTitle>
              ) : (
                <S.SubTitle>Edit Product Details</S.SubTitle>
              )}
            </div>
            <S.HeaderRightContainer>
              {type === "add" && (
                <S.ClearBtn onClick={handleClearForm}>
                  Clear all (F10)
                </S.ClearBtn>
              )}
              {type === "edit" && (
                <S.StatusContainer>
                  <S.StatusChip
                    active={productData?.status === PRODUCT_STATUS.PUBLISH}
                  >
                    <span></span>
                    {PRODUCT_STATUS_MAPPING[productData?.status]}
                  </S.StatusChip>
                </S.StatusContainer>
              )}
              {type === "edit" &&
                productData?.status === PRODUCT_STATUS.PUBLISH && (
                  <S.BtnSave onClick={() => onAddNewSKU(productData)}>
                    Add New Batch (F9)
                  </S.BtnSave>
                )}
              <S.CloseBtn onClick={handleDrawerClose}>Close (ESC)</S.CloseBtn>
            </S.HeaderRightContainer>
            {/* <img onClick={handleDrawerClose} src={CrossBlackIcon} alt="close" /> */}
          </S.Header>

          <S.ProductDetails>
            <S.ImageContainer>
              {imgSrc ? (
                <img src={imgSrc} alt="product" key={imgSrc} />
              ) : (
                <S.AddImage>
                  <img src={PlusGrayIcon} alt="add" /> Add
                </S.AddImage>
              )}
              <input
                onChange={onChangeImage}
                type="file"
                accept="image/*"
                className="file_upload"
              />
            </S.ImageContainer>

            <S.DetailsRight>
              <S.DetailsTop>
                <S.InputContainer>
                  <Label text="Barcode" />
                  <CustomInput
                    value={displayDataRef?.current?.upc}
                    onChange={(val) => handleInputChange("upc", val?.toUpperCase())}
                    name="upc-input"
                    placeholder="Barcode"
                    refreshIcon={type === "add" ? true : false}
                    onClickRefresh={() => handleGenerateUPCSKU("upc")}
                    autoFocus={!productData?.upc}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Label text="Item code" />
                  <CustomInput
                    value={displayDataRef?.current?.sku}
                    onChange={(val) => handleInputChange("sku", val)}
                    type="text"
                    name="sku-input"
                    placeholder="Item code"
                    refreshIcon={type === "add" ? true : false}
                    onClickRefresh={() => handleGenerateUPCSKU("sku")}
                  />
                </S.InputContainer>
              </S.DetailsTop>
              <S.InputContainer>
                <Label text="Product Name" />
                <CustomInput
                  type="text"
                  name="product-name-input"
                  placeholder="Product Name"
                  value={displayDataRef?.current?.name}
                  onChange={(val) => handleInputChange("name", val)}
                  autoFocus={productData?.upc}
                />
              </S.InputContainer>
            </S.DetailsRight>
          </S.ProductDetails>

          <S.ContainerBottom>
            <S.Tabs>
              {PRODUCT_DRAWER_TABS.map((tab) => (
                <S.Tab
                  active={tabView === tab.id}
                  onClick={() => {
                    if (tab.id === 0) {
                      priceRef.current?.focus();
                    }
                    setTabView(tab.id);
                  }}
                >
                  <img
                    src={tabView === tab.id ? tab.activeIcon : tab.icon}
                    alt="tab icon"
                  />
                  {tab.name}
                </S.Tab>
              ))}
            </S.Tabs>
            <S.TabView>
              {tabView === 0 && (
                <BasicInformation
                  formData={formData}
                  setFormData={setFormData}
                  displayData={displayDataRef?.current}
                  setDisplayData={setDisplayData}
                  type={type}
                  priceRef={priceRef}
                  selectRef={selectRef}
                />
              )}
              {tabView === 1 && (
                <ProductInformation
                  formData={formData}
                  setFormData={setFormData}
                  displayData={displayDataRef?.current}
                  setDisplayData={setDisplayData}
                  type={type}
                  selectRef={selectRef}
                />
              )}
              {tabView === 2 && (
                <ProductMetrics
                  formData={formData}
                  setFormData={setFormData}
                  displayData={displayDataRef?.current}
                  setDisplayData={setDisplayData}
                  type={type}
                  selectRef={selectRef}
                />
              )}
            </S.TabView>

            <S.BtnContainer>
              <S.BtnLeft>
                {type !== "add" && (
                  <S.BtnDelete onClick={handleDeleteProduct}>
                    Delete (F8)
                  </S.BtnDelete>
                )}
                {/* {productData?.status === PRODUCT_STATUS.PUBLISH && type !== "add" && (
                                    <S.BtnSecond onClick={() => handleSecondBtn(PRODUCT_STATUS.UN_PUBLISH)}>Mark Inactive (F7)</S.BtnSecond>
                                )}
                                {productData?.status === PRODUCT_STATUS.UN_PUBLISH && (
                                    <S.BtnSecond onClick={() => handleSecondBtn(PRODUCT_STATUS.PUBLISH)}>Mark Active (F7)</S.BtnSecond>
                                )} */}
                {(type === "add" ||
                  productData?.status === PRODUCT_STATUS.DRAFT) && (
                  <S.DraftBtn
                    onClick={() => handleSecondBtn(PRODUCT_STATUS.DRAFT)}
                  >
                    Save to Draft (F11)
                  </S.DraftBtn>
                )}
              </S.BtnLeft>

              <S.BtnRight>
                <S.BtnSave onClick={handleSaveBtn}>
                  {type === "add"
                    ? "Save (F12)"
                    : productData?.status === PRODUCT_STATUS.DRAFT
                    ? "Publish (F12)"
                    : "Save (F12)"}
                </S.BtnSave>
                {(tabView === 0 || tabView === 1) && (
                  <S.NextBtn onClick={handleFinalBtn}>Next (Pg UP)</S.NextBtn>
                )}
              </S.BtnRight>
            </S.BtnContainer>
          </S.ContainerBottom>
        </S.Container>
      </OutsideClickHandler>
    </>
  );
};

export default ProductDrawer;
export { INITIAL_PRODUCT_DISPLAY_DATA };
