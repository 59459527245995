import React from "react";
import * as S from "./style";
import { DigiDukandarLogo } from "../../../icons";

const BrandingFullLogo = ({ size = "md" }) => {
    return (
        <S.Wrapper size={size}>
            <S.Logo src={DigiDukandarLogo} alt="digidukandar logo" />
        </S.Wrapper>
    );
};

export default BrandingFullLogo;
