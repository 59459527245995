import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

export const Label = styled.div`
    color: #334155;
    font-size: 10px;
    font-weight: 400;
    line-height: 17.951px;
    margin-bottom: 2px;
`;

export const Input = styled.input`
    width: 100%;
    border-radius: 2px;
    border: 1px solid #e2e8f0;
    outline: none;
    background: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #334155;
    padding: 7px;
`;
export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    & > div {
        flex: 1;
    }
`;
