import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    transition-delay: 2s;
    box-sizing: border-box;
    transition: all 0.2s ease;
`;

export const Item = styled.div`
    color: ${(props) => (props.active ? "#fff" : "#64748b")};
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: ${(props) => (props.open ? "8px 0 8px 0px" : "8px 0 8px 0px")};
    cursor: pointer;
    display: flex;
    align-items: center;
    // border: 1px solid aqua;
    // gap: 20px;
    transition: all 0.2s ease;
    & div:nth-of-type(2) {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & img {
        width: 14px;
        height: 14px;
    }
`;

export const Name = styled.pre`
    margin: 0;
    width: 133px;
    min-height: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
`;

export const ActiveMenu = styled.div`
    background: ${(props) => (props.active ? "#fff" : "#0f172a")};
    border-radius: 0px 4px 4px 0px;
    width: 3px;
    margin-right: 27px;
    color: transparent;
`;
