import { getProductsBySKU } from "../api/local/productApi";
import { captureException } from "../crash-reporting";
import { getPurchaseObjectHash, getSaleObjectHash } from "./global-fn";
import { getGstInfo } from "../api/local/gstApi";
import { getGstInfoServer } from "../api/server/gstApi";
import { setGstCache } from "../store/apicache-slice";
import { isValidGst } from "./validity-fns";

export const getProductsFromDB = async (ids, type) => {
    let data = null;
    const payload = { ids: ids };
    try {
        let res = await getProductsBySKU(payload);
        if (res.status === 200) {
            let fetchedData = res.data?.entity;
            fetchedData?.sort((a, b) => ids.indexOf(a.sku) - ids.indexOf(b.sku));
            data = fetchedData?.map((item) => {
                let hash;
                if (type === "sale") {
                    hash = getSaleObjectHash(item);
                } else if (type === "purchase") {
                    hash = getPurchaseObjectHash(item);
                }
                return { ...item, hashKey: hash };
            });
        } else {
            throw new Error(res.data);
        }
    } catch (error) {
        captureException(error);
    } finally {
        return data;
    }
};
export const handleGetGstInfo = async (gst_id, appOnline, gstCache, dispatch, localApiCall = true) => {
    //checking internet present or not
    if (!appOnline) return;

    //gst number validation
    if (!isValidGst(gst_id)) return;

    // Check if data is already in the cache
    if (gstCache[gst_id]) {
        //retrieve from cache
        return gstCache[gst_id];
    } else {
        // if not present in the cache, make the API call
        let data;
        try {
            if (localApiCall) {
                let res = await getGstInfo(gst_id);
                if (res.status === 200) {
                    data = res.data?.entity;
                    // store the data in the cache
                    dispatch(setGstCache({ [gst_id]: data }));
                } else {
                    throw new Error(res.data);
                }
            } else {
                let res = await getGstInfoServer(gst_id);
                if (res) {
                    data = res;
                    // store the data in the cache
                    dispatch(setGstCache({ [gst_id]: data }));
                } else {
                    throw new Error(res);
                }
            }
        } catch (error) {
            captureException(error);
        } finally {
            return data;
        }
    }
};
