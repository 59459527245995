import styled from "@emotion/styled";

export const Wrapper = styled.div`
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px;
    width: 100%;

    & > input {
        border: none;
        background: #fff;
        width: 100%;

        :focus {
            border: none;
            outline: none;
        }
    }
`;

export const Line = styled.div`
    height: 18px;
    width: 1px;
    border-right: 1px solid #e2e8f0;
`;
