import React from "react";
import * as S from "./style";
import { DownloadIcon } from "../../icons";

const DownloadButton = ({ text = "Download", icon = DownloadIcon, onClick }) => {
    return (
        <S.Wrapper onClick={onClick}>
            <img src={icon} alt="download icon" />
            <div>{text}</div>
        </S.Wrapper>
    );
};

export default DownloadButton;
