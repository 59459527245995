import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseModalIcon, DescriptionIcon } from "../../../../icons";
import { getPurchaseReturnTotals } from "../../../../store/purchasereturn-slice";
import CustomButton from "../../../product-drawer/custom-button/custom-button";

import * as S from "./return-summary-modal.styles";
import { formatDate } from "./../../../../utils/format-date";
import { hasDecimal, toDecimalPlace } from "../../../../utils/global-fn";
import ToggleSwitch from "../../../switch/Switch";
import { ModalType } from "../../../../constants/appConstants";
import { captureException } from "../../../../crash-reporting";

const ReturnSummaryModal = ({
    open,
    onClose,
    onSubmit,
    handleAddPurchaseReturn,
    handleAddDamageEntry,
    datas,
    type,
    selectedAmount,
    selectedPaymentOption,
    selectedTemplate,
    setSelectedTemplate,
}) => {
    const {
        purchaseReturnProducts,
        returnSelectedSupplier,
        purchaseReturnQuantity,
        purchaseReturnTotal,
        purchaseReturnTaxTotal,
        purchaseReturnCartTotal,
    } = useSelector((state) => state.purchaseReturn);

    const { damageEntryProducts, damageEntryTotal, damageEntryTaxTotal } = useSelector((state) => state.damageEntry);
    const dispatch = useDispatch();
    const [changeDue, setChangeDue] = useState(null);
    const [printTemplates, setPrintTemplates] = useState(null);
    const [invoiceRequired, _setInvoiceRequired] = useState(true);
    const invoiceRequiredRef = React.useRef(invoiceRequired);
    const setInvoiceRequired = (data) => {
        invoiceRequiredRef.current = data;
        _setInvoiceRequired(data);
    };

    useEffect(() => {
        dispatch(getPurchaseReturnTotals());
    }, [purchaseReturnProducts]);

    useEffect(() => {
        if (open) {
            if (type === ModalType.NewPurchaseReturn) {
                setChangeDue(selectedAmount - Math.round(purchaseReturnCartTotal));
            } else if (type === ModalType.PurchaseReturn) {
                setChangeDue(selectedAmount - Math.round(datas.totalPrice + (+datas.deliveryExpense || 0) - (+datas.cartDiscount || 0)));
            }
            document.addEventListener("keydown", handleDetectKeyDown, true);
        }
        return () => {
            document.removeEventListener("keydown", handleDetectKeyDown, true);
        };
    }, [open]);

    useEffect(() => {
        handleGetPrintTemplates();
    }, []);

    const handleDetectKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleComplete();
            onClose();
        } else if (e.keyCode === 37) {
            //LEFT Arrow Key
            e.preventDefault();
            setInvoiceRequired(false);
        } else if (e.keyCode === 39) {
            //RIGHT Arrow key
            e.preventDefault();
            setInvoiceRequired(true);
        } else if (e.keyCode === 32) {
            //SPACE Key
            e.preventDefault();
            setInvoiceRequired(!invoiceRequiredRef.current);
        }
    };

    const handleGetPrintTemplates = async () => {
        try {
            const templates = await window.electronAPI.getPrintTemplates();
            if (templates) {
                setPrintTemplates(templates);
                if (templates && templates?.length) {
                    setSelectedTemplate(templates[0]);
                }
            }
        } catch (error) {
            captureException(error);
        }
    };

    const handlePrintSwitchChange = (e, type) => {
        const { checked } = e.target;
        setInvoiceRequired(checked);
    };

    const handleComplete = () => {
        if (type === ModalType.DamageEntry) {
            handleAddDamageEntry(invoiceRequiredRef.current);
        } else {
            handleAddPurchaseReturn(invoiceRequiredRef.current);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <S.Container>
                <S.Header>
                    {type === ModalType.DamageEntry ? "Damage" : "Return"} Summary <img onClick={onClose} src={CloseModalIcon} alt="close" />
                </S.Header>
                <S.Body>
                    {type !== ModalType.DamageEntry && selectedPaymentOption && changeDue > 0 && (
                        <S.DueHeadline>
                            Change due: <span>₱{toDecimalPlace(changeDue)}</span>
                        </S.DueHeadline>
                    )}
                    {type !== ModalType.DamageEntry &&
                        ((type === ModalType.NewPurchaseReturn && returnSelectedSupplier) ||
                            (type === ModalType.PurchaseReturn && datas.supplier)) && (
                            <S.Section>
                                <S.SectionTitle>Supplier Information</S.SectionTitle>
                                <S.SupplierDetail>
                                    <div className="supplier-name">
                                        {type === ModalType.NewPurchaseReturn ? returnSelectedSupplier?.name : datas?.supplier?.name}
                                    </div>
                                    <div className="supplier-mobile">
                                        {type === ModalType.NewPurchaseReturn ? returnSelectedSupplier?.phone : datas?.supplier?.phone}
                                    </div>
                                </S.SupplierDetail>
                            </S.Section>
                        )}
                    {[ModalType.DamageEntry, ModalType.NewPurchaseReturn, ModalType.PurchaseReturn].includes(type) && (
                        <>
                            <S.Section>
                                <S.SectionTitle>{type === ModalType.DamageEntry ? "Damage" : "Return"} Summary</S.SectionTitle>
                                <S.PriceDetails>
                                    <div>Total SKUs</div>
                                    <div>
                                        {type === ModalType.NewPurchaseReturn
                                            ? purchaseReturnProducts.length
                                            : type === ModalType.DamageEntry
                                            ? damageEntryProducts.length
                                            : datas.productsList.length}
                                    </div>
                                </S.PriceDetails>
                                {[ModalType.NewPurchaseReturn, ModalType.PurchaseReturn].includes(type) && (
                                    <>
                                        <S.PriceDetails>
                                            <div>Delivery Date</div>
                                            <div>{datas.deliveryDate && formatDate(datas.deliveryDate)}</div>
                                        </S.PriceDetails>
                                        <S.PriceDetails>
                                            <div>Purchase Date </div>
                                            <div>{datas.createdAt && formatDate(datas.createdAt)}</div>
                                        </S.PriceDetails>
                                    </>
                                )}
                                <S.PriceDetails></S.PriceDetails>
                            </S.Section>
                            <S.Section>
                                <S.SectionTitle>Pricing Summary</S.SectionTitle>
                                <S.PriceDetails>
                                    <div>Subtotal:</div>
                                    {type === ModalType.NewPurchaseReturn ? (
                                        <div>₱{toDecimalPlace(purchaseReturnTotal - purchaseReturnTaxTotal)}</div>
                                    ) : type === ModalType.DamageEntry ? (
                                        <div>₱{toDecimalPlace(damageEntryTotal - damageEntryTaxTotal)}</div>
                                    ) : (
                                        <div>₱{toDecimalPlace(datas.totalPrice - datas.totalTax)}</div>
                                    )}
                                </S.PriceDetails>{" "}
                                <S.PriceDetails>
                                    <div>Tax:</div>
                                    {type === ModalType.NewPurchaseReturn ? (
                                        <div>₱{toDecimalPlace(purchaseReturnTaxTotal)}</div>
                                    ) : type === ModalType.DamageEntry ? (
                                        <div>₱{toDecimalPlace(damageEntryTaxTotal)}</div>
                                    ) : (
                                        <div>₱{toDecimalPlace(datas.totalTax)}</div>
                                    )}
                                </S.PriceDetails>{" "}
                                {[ModalType.NewPurchaseReturn, ModalType.PurchaseReturn].includes(type) && (
                                    <S.PriceDetails>
                                        <div>Delivery Expense:</div>
                                        <div>₱{datas.deliveryExpense ? datas.deliveryExpense : 0}</div>
                                    </S.PriceDetails>
                                )}
                                {type === ModalType.NewPurchaseReturn && hasDecimal(purchaseReturnCartTotal) && (
                                    <S.PriceDetails>
                                        <div>Adjusted Cash:</div>
                                        <div>₱{toDecimalPlace(Math.round(purchaseReturnCartTotal) - purchaseReturnCartTotal)}</div>
                                    </S.PriceDetails>
                                )}
                                {type === ModalType.DamageEntry && hasDecimal(damageEntryTotal) && (
                                    <S.PriceDetails>
                                        <div>Adjusted Cash:</div>
                                        <div>₱{toDecimalPlace(Math.round(damageEntryTotal) - damageEntryTotal)}</div>
                                    </S.PriceDetails>
                                )}
                                {type === ModalType.PurchaseReturn &&
                                    hasDecimal(datas.totalPrice + parseFloat(datas.deliveryExpense || 0) - parseFloat(datas.cartDiscount || 0)) && (
                                        <S.PriceDetails>
                                            <div>Adjusted Cash:</div>
                                            <div>
                                                ₱
                                                {toDecimalPlace(
                                                    Math.round(
                                                        datas.totalPrice +
                                                            parseFloat(datas.deliveryExpense || 0) -
                                                            parseFloat(datas.cartDiscount || 0)
                                                    ) -
                                                        (datas.totalPrice +
                                                            parseFloat(datas.deliveryExpense || 0) -
                                                            parseFloat(datas.cartDiscount || 0))
                                                )}
                                            </div>
                                        </S.PriceDetails>
                                    )}
                            </S.Section>

                            <S.TotalDetails>
                                <div>{type === ModalType.DamageEntry ? "Damage" : "Return"} Total:</div>
                                {type === ModalType.NewPurchaseReturn ? (
                                    <div>₱{Math.round(purchaseReturnCartTotal)}</div>
                                ) : type === ModalType.DamageEntry ? (
                                    <div>₱{Math.round(damageEntryTotal)}</div>
                                ) : (
                                    <div>
                                        ₱{Math.round(datas.totalPrice + parseFloat(datas.deliveryExpense || 0) - parseFloat(datas.cartDiscount || 0))}
                                    </div>
                                )}
                            </S.TotalDetails>

                            <S.TemplateContainer>
                                {printTemplates?.map((t, idx) => (
                                    <S.TemplateBox key={idx} active={selectedTemplate?.name === t.name} onClick={() => setSelectedTemplate(t)}>
                                        <img src={t.preview ?? DescriptionIcon} alt="preview" />
                                        <S.TemplateText>{t.name}</S.TemplateText>
                                    </S.TemplateBox>
                                ))}
                            </S.TemplateContainer>
                        </>
                    )}

                    <S.BtnContainer>
                        <S.PrintSwitchContainer>
                            <S.PrintText>Print Invoice</S.PrintText>
                            <ToggleSwitch
                                type="invoice"
                                checked={invoiceRequired}
                                handleChange={(e, type) => handlePrintSwitchChange(e, type)}
                                antSwitch
                            />
                            <S.HintText>(Press space or arrow keys to change)</S.HintText>
                        </S.PrintSwitchContainer>
                        <CustomButton
                            type="primary"
                            size="md"
                            text={`Complete ${type === ModalType.DamageEntry ? "Damage Entry" : "Return"}`}
                            onClick={() => {
                                handleComplete();
                                onClose();
                            }}
                        />
                    </S.BtnContainer>
                </S.Body>
            </S.Container>
        </Modal>
    );
};

export default ReturnSummaryModal;
