import React from "react";
import * as S from "./style";
import TeamMembers from "../../../assets/images/gif/team.gif";
import {
    BackArrowIcon,
    ChevronRightWhite,
    InfoBtn,
    PlusCircleBlack,
    PlusCircleBlue,
    StaffDelete,
} from "../../../icons";
import Bar from "../page-bar/Bar";
import { getEmployee, updateEmployee, addEmployee } from "./../../../api/local/employee-api";
import { useContext } from "react";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";

const Members = ({ setActivePage, employeeData, setEmployeeData, handleSetOnboarding }) => {
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    // useEffect(() => {
    //     storeLogin();
    // }, []);

    // const storeLogin = async () => {
    //     console.log("Store login");
    //     let employeeLoginResult = await employeeLogin({
    //         username: "7478870112",
    //         password: "password",
    //     });
    //     if (employeeLoginResult.status === 200) {
    //         setEmployeeSession(employeeLoginResult.data);
    //         successNotify(`registered successfully`);
    //     } else {
    //         errorNotify("Some error occurred. Please try again!");
    //     }
    // };

    const handleAddEmployee = () => {
        let data = employeeData[employeeData.length - 1];
        if (data.phone.length !== 10) {
            handleSnackbarDetails({
                show: true,
                title: `Please enter a valid phone number for ${data.name}`,
                type: "error",
            });
            return;
        }
        const newEmployeeData = employeeData
            ? employeeData.concat([{ phone: "", name: "" }])
            : [{ phone: "", name: "" }];
        setEmployeeData(newEmployeeData);
    };

    const handleRemoveEmployee = (index) => {
        const newEmployeeData = employeeData.filter((metric, idx) => index !== idx);
        setEmployeeData(newEmployeeData);
    };

    const handleInputChange = (e, key, index) => {
        if (key === "phone") {
            const regex = /^[0-9\b]+$/;
            if (!(e.target.value === "" || regex.test(e.target.value))) return;
        }
        const newEmployeeData = employeeData.map((item, idx) => {
            if (idx !== index) return item;
            return { ...item, [key]: e.target.value };
        });
        setEmployeeData(newEmployeeData);
    };

    const handleNextBtn = () => {
        handleSetOnboarding();
        // onAddEmployeeData();
        // setActivePage(3);
    };

    return (
        <S.Wrapper>
            <S.LeftImgContainer>
                <img src={TeamMembers} alt="online store" />
            </S.LeftImgContainer>
            <S.RightContainer>
                <S.HeaderContainer>
                    <div>
                        <S.Header>Let’s add your team members</S.Header>
                        <S.SubHead>Employees/Staff</S.SubHead>
                    </div>
                    {/* <S.AddStaffContainer>
                        <img src={PlusCircleBlack} alt="plus icon" />
                        <div>Add More Staff</div>
                    </S.AddStaffContainer> */}
                </S.HeaderContainer>
                <S.Container>
                    <div>
                        {employeeData.map((item, idx) => (
                            <>
                                <S.PermissionContainer>
                                    {/* {MEMBER_TYPES.map((item) => (
                                        <S.ImageBlock>
                                            <S.ImgDiv>
                                                <img src={item.icon} alt="icon" />
                                                {item.info && <S.InfoBtn src={InfoBtn} alt="" />}
                                            </S.ImgDiv>
                                            <div>{item.title}</div>
                                        </S.ImageBlock>
                                    ))} */}
                                    <S.SerialNo>{idx + 1}</S.SerialNo>
                                </S.PermissionContainer>
                                <S.InputContainer>
                                    <div>
                                        <S.Label>Name</S.Label>
                                        <S.Input
                                            type="text"
                                            value={item.name}
                                            onChange={(e) => handleInputChange(e, "name", idx)}
                                        />
                                    </div>
                                    <div>
                                        <S.Label>Phone Number</S.Label>
                                        <S.PhoneNumberContainer>
                                            <div>+63</div>
                                            <S.Input
                                                type="text"
                                                value={item.phone}
                                                onChange={(e) => handleInputChange(e, "phone", idx)}
                                            />
                                        </S.PhoneNumberContainer>
                                    </div>

                                    <img
                                        src={StaffDelete}
                                        alt="delete"
                                        onClick={() => handleRemoveEmployee(idx)}
                                    />
                                </S.InputContainer>
                                <S.Bar></S.Bar>
                            </>
                        ))}
                        <S.AddContainer>
                            <img src={PlusCircleBlue} alt="plus icon" />
                            <div onClick={handleAddEmployee}>Add more Staff/Employee</div>
                        </S.AddContainer>
                    </div>
                </S.Container>
                <S.FooterDiv>
                    <S.WhiteButton onClick={() => setActivePage(1)}>
                        <img src={BackArrowIcon} alt="back" />
                    </S.WhiteButton>
                    <S.BarContainer>
                        <Bar />
                        <Bar />
                        <Bar size="lg" />
                        {/* <Bar /> */}
                    </S.BarContainer>
                    <S.ButtonContainer>
                        {/* <S.WhiteButton>Skip</S.WhiteButton> */}
                        <S.DarkButton onClick={handleNextBtn}>
                            Finish
                            {/* <img src={ChevronRightWhite} alt="" /> */}
                        </S.DarkButton>
                    </S.ButtonContainer>
                </S.FooterDiv>
            </S.RightContainer>
        </S.Wrapper>
    );
};

export default Members;
