import styled from "@emotion/styled/macro";

export const Wrapper = styled.div`
    height: calc(100vh - 194px);
    box-sizing: border-box;
    padding: 23px 23px;
    width: 100%;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    & > div {
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        color: #1e293b;
    }
`;
export const Body = styled.div`
    // margin-top: 24px;
    // display: flex;
    // flex-wrap: wrap;
    // column-gap: 10px;
    // row-gap: 32px;
    height: calc(100vh - 238px);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
`;
