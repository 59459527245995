import store from "../store/store";

export function detectKeyDown(
    e,
    history,
    navigate,
    globalRef,
    newPurchaseRef,
    newPurchaseReturnRef,
    newSaleRef,
    viewSaleRef,
    purchaseRef,
    salesRef,
    inventoryRef,
    staffRef,
    suppliersRef,
    customerRef,
    setOpenNewCustModal,
    setOpenAddSupplierModal,
    setOpenDrawer,
    setOpenNewEmpModal,
    handleEmployeeLogout,
    openNewCustModal,
    openAddSupplierModal,
    openDrawer,
    openNewEmpModal,
    onlineOrderRef,
    expenseRef,
    cashDrawerRef,
    proformaInvoiceRef,
    purchaseProformaRef
) {
    let employee = store.getState().session.employee;
    if (employee) {
        let path = history.location.pathname.split("/")[2];
        if (e.keyCode === 112) {
            //F1
            globalRef.current?.focus();
        }
        if (e.keyCode === 113) {
            //F2
            if (path === "inventory") {
                inventoryRef.current?.onPrevPage();
            }
            if (path === "all-sales") {
                salesRef.current?.onPrevPage();
            }
            if (path === "new-purchase-return" || path === "new-damage-entry") {
                newPurchaseReturnRef.current?.onBackEdit();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.onBackEdit();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.onBackEdit();
            }
            if (path === "purchases") {
                purchaseRef.current?.onPrevPage();
            }
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.onExpand();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.onExpand();
            }
        }
        if (e.keyCode === 114) {
            //F3
            if (path === "inventory") {
                inventoryRef.current?.onNextPage();
            }
            if (path === "all-sales") {
                salesRef.current?.onNextPage();
            }
            if (path === "new-purchase-return") {
                newPurchaseReturnRef.current?.focusDeliveryDate();
            }
            if (path === "new-purchase" || path === "view-purchase") {
                newPurchaseRef.current?.openDeliveryDate();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.openDeliveryDate();
            }
            if (path === "purchases") {
                purchaseRef.current?.onNextPage();
            }
        }
        if (e.keyCode === 115) {
            //F4
            if (path === "view-sale") {
                viewSaleRef.current?.openCustomer();
            }
            if (path === "inventory") {
                inventoryRef.current?.onSort();
            }
            if (path === "all-sales") {
                salesRef.current?.filterSort();
            }
            if (path === "new-purchase-return") {
                newPurchaseReturnRef.current?.openSupplierModal();
            }
            if (path === "new-purchase" || path === "view-purchase") {
                newPurchaseRef.current?.openSupplierModal();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.openSupplierModal();
            }
            if (path === "purchases") {
                purchaseRef.current?.filterSort();
            }
        }
        if (e.keyCode === 116) {
            //F5
            if (path === "all-sales") {
                salesRef.current?.searchTime();
            }
            if (path === "new-purchase-return") {
                newPurchaseReturnRef.current?.focusDelExp();
            }
            if (path === "new-purchase" || path === "view-purchase") {
                newPurchaseRef.current?.focusDelExp();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.focusDelExp();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchBillType();
            }
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.openAddCustomProduct();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.openAddCustomProduct();
            }
        }
        if (e.keyCode === 117) {
            //F6
            if (path === "all-sales") {
                salesRef.current?.searchStatus();
            }
            if (path === "new-purchase-return") {
                newPurchaseReturnRef.current?.onAddProduct();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.addNewProduct();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.addNewProduct();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchStatus();
            }
            if (path === "view-purchase") {
                newPurchaseRef.current?.clearSupplier();
            }
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.openAddNewProduct();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.openAddNewProduct();
            }
        }
        if (e.keyCode === 118) {
            //F7
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.billFocus();
            }
            if (path === "all-sales") {
                salesRef.current?.searchEmployee();
            }
            if (path === "view-sale" || path === "return-sale") {
                viewSaleRef.current?.billFocus();
            }
            if (path === "inventory") {
                inventoryRef.current?.searchStock();
            }
            if (path === "new-purchase-return" || path === "new-damage-entry") {
                newPurchaseReturnRef.current?.focusBill();
            }
            if (path === "new-purchase" || path === "view-purchase") {
                newPurchaseRef.current?.focusBill();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.focusBill();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchEmployee();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.billFocus();
            }
        }
        if (e.keyCode === 119) {
            //F8
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.cartFocus();
            }
            if (path === "all-sales") {
                salesRef.current?.searchBrand();
            }
            if (path === "inventory") {
                inventoryRef.current?.searchBrand();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.openDiscount();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.focusDiscount();
            }
            if (path === "view-sale") {
                viewSaleRef.current?.onSendTax();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchBrand();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.cartFocus();
            }
        }
        if (e.keyCode === 120) {
            //F9
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.clearAll();
            }
            if (path === "all-sales") {
                salesRef.current?.searchCustomer();
            }
            if (path === "view-sale") {
                viewSaleRef.current?.viewInvoice();
            }
            if (path === "view-purchase") {
                newPurchaseRef.current?.viewInvoice();
            }
            if (path === "inventory") {
                inventoryRef.current?.searchCategory();
            }
            if (path === "new-damage-entry" || path === "new-purchase-return") {
                newPurchaseReturnRef.current?.clearAll();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.clearAll();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.clearAll();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchSupplier();
            }
            if (path === "view-purchase") {
                newPurchaseRef.current?.onPaymentChange();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.clearAll();
            }
        }
        if (e.keyCode === 121) {
            //F10
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.saveDraftSale();
            }
            if (path === "all-sales") {
                salesRef.current?.filterBillType();
            }
            if (path === "view-sale") {
                viewSaleRef.current?.printInvoice();
            }
            if (path === "inventory") {
                inventoryRef.current?.searchProduct();
            }
            if (path === "new-damage-entry" || path === "new-purchase-return") {
                newPurchaseReturnRef.current?.onDamageDraft();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.saveDraftPurchase();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.saveDraftPurchase();
            }
            if (path === "purchases") {
                purchaseRef.current?.searchProduct();
            }
            if (path === "cash-drawer") {
                cashDrawerRef.current?.openReferenceType();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.saveDraftSale();
            }
        }
        if (e.keyCode === 122) {
            //F11
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.openDraftSale();
            }
            if (path === "all-sales") {
                salesRef.current?.searchProduct();
            }
            if (path === "view-sale") {
                viewSaleRef.current?.returnItem();
            }
            if (path === "new-damage-entry" || path === "new-purchase-return") {
                newPurchaseReturnRef.current?.openDraft();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.draftOrders();
            }
            if (path === "purchases") {
                purchaseRef.current?.onDraftScreen();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.draftOrders();
            }
            if (path === "view-purchase") {
                newPurchaseRef.current?.onReturn();
            }
            if (path === "online-orders") {
                onlineOrderRef.current?.cancelOrder();
            }
            if (path === "cash-drawer") {
                cashDrawerRef.current?.openType();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.openDraftSale();
            }
        }
        if (e.keyCode === 123) {
            //F12
            if (path === "sales" || path === "new-sale") {
                newSaleRef.current?.saveSale();
            }
            if (path === "all-sales") {
                salesRef.current?.newSaleFun();
            }
            if (path === "view-sale") {
                viewSaleRef.current?.saveSale();
            }
            if (path === "return-sale") {
                viewSaleRef.current?.onRefund();
            }
            if (path === "inventory") {
                inventoryRef.current?.addProduct();
            }
            if (path === "new-damage-entry" || path === "new-purchase-return") {
                newPurchaseReturnRef.current?.proceedReturn();
            }
            if (path === "new-purchase") {
                newPurchaseRef.current?.proceedReturn();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.onProceed();
            }
            if (path === "purchases") {
                purchaseRef.current?.onNewScreen();
            }
            if (path === "view-purchase") {
                newPurchaseRef.current?.onSave();
            }
            if (path === "online-orders") {
                e.preventDefault();
                onlineOrderRef.current?.onSubmit();
            }
            if (path === "expenses") {
                expenseRef.current?.addNewExpense();
            }
            if (path === "cash-drawer") {
                cashDrawerRef.current?.addNewCashItem();
            }
            if (path === "proforma-invoice") {
                proformaInvoiceRef.current?.saveSale();
            }
        }

        if (e.keyCode === 9) {
            //TAB
            if (path === "all-sales") {
                e.preventDefault();
                salesRef.current?.changeTabs();
            }
            if (path === "inventory") {
                // e.preventDefault();
                inventoryRef.current?.changeTabs();
            }
            if (path === "purchases") {
                purchaseRef.current?.changeTab();
            }
            if (path === "online-orders") {
                e.preventDefault();
                onlineOrderRef.current?.changeTab();
            }
        }

        if (e.altKey && e.keyCode === 46) {
            //alt + dlt
            // if (path === "view-sale") {
            //     viewSaleRef.current?.deleteSale();
            // }
            if (path === "view-purchase") {
                newPurchaseRef.current?.onDelete();
            }
        }

        if (e.keyCode === 27) {
            //ESC key
            if (path === "view-sale" || path === "return-sale") {
                viewSaleRef.current?.backAction();
            }
            if (path === "all-sales") {
                salesRef.current?.clearSearch();
            }
            if (path === "inventory") {
                inventoryRef.current?.onClearSearch();
            }
            if (path === "new-damage-entry" || path === "new-purchase-return") {
                newPurchaseReturnRef.current?.onBackAction();
            }
            if (path === "new-purchase" || path === "view-purchase") {
                newPurchaseRef.current?.onBackAction();
            }
            if (path === "purchase-proforma") {
                purchaseProformaRef.current?.onBackAction();
            }
            if (path === "purchases") {
                purchaseRef.current?.clearSearch();
            }
        }

        if (e.shiftKey && e.keyCode === 27) {
            //shift + ESC key
            if (path === "inventory") {
                inventoryRef.current?.clearFilter();
            }
            if (path === "all-sales") {
                e.preventDefault();
                salesRef.current?.clearFilter();
                // salesRef.current?.clearSearch();
            }
            if (path === "purchases") {
                purchaseRef.current?.clearFilter();
            }
            if (path === "customers") {
                customerRef.current?.clearFilter();
            }
        }
        if (e.keyCode === 37) {
            //arrow left
            if (path === "all-sales") {
                salesRef.current?.onPrevTab();
            }
            if (path === "purchases") {
                purchaseRef.current?.onPrevTab();
            }
        }
        if (e.keyCode === 39) {
            //arrow right
            if (path === "all-sales") {
                salesRef.current?.onNextTab();
            }
            if (path === "purchases") {
                purchaseRef.current?.onNextTab();
            }
        }
    }
}
