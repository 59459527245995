import React from "react";
import * as S from "./style";
import Slider from "./../slider/Slider";
import BrandingLogo from "../branding/branding-logo/BrandingLogo";
import BrandingName from "../branding/branding-name/BrandingName";
import BrandingFullLogo from "../branding/full-logo/BrandingFullLogo";

const CarouselContainer = ({ carouselData }) => {
    return (
        <S.Wrapper>
            <S.LogoContainer>
                <BrandingFullLogo />
            </S.LogoContainer>
            <S.SliderContainer>
                <Slider carouselData={carouselData} />
            </S.SliderContainer>
        </S.Wrapper>
    );
};

export default CarouselContainer;
