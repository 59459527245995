import axios from "./axios";
import { PRODUCTS_API, PRODUCTS_BY_SKU_API, PRODUCTS_COUNT_API, PRODUCT_ATTRIBUTES } from "../../constants/apiRoutes";

const addProduct = async (payload) => {
    return await axios.post(PRODUCTS_API, payload);
};

const updateProduct = async (productId, payload) => {
    return await axios.post(`${PRODUCTS_API}/${productId}`, payload);
};

const getProduct = async (productId, queryParams = {}) => {
    return await axios.get(`${PRODUCTS_API}/${productId}`, {
        params: queryParams,
    });
};

const getAllProducts = async (queryParams = {}) => {
    return await axios.get(PRODUCTS_API, {
        params: {
            ...queryParams,
            is_service: false
        },
    });
};

const getProductsBySKU = async (payload) => {
    return await axios.post(PRODUCTS_BY_SKU_API, payload);
};

const getProductsCount = () => {
    return axios.get(PRODUCTS_COUNT_API);
};

const getProductAttributes = () => {
    return axios.get(PRODUCT_ATTRIBUTES);
};

const getProductAttributeValues = async (id) => {
    return await axios.get(`${PRODUCT_ATTRIBUTES}/${id}`);
};
export { addProduct, getProduct, getAllProducts, updateProduct, getProductsCount, getProductsBySKU, getProductAttributes, getProductAttributeValues };
