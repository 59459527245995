import { Drawer } from "@mui/material";
import React, { useContext, useEffect, useState, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyCartLogo, RedClearCartIcon } from "../../../icons";
import { SnackbarContext } from "../../../snackbar-context/snackbar-context";
import { fetchAllPurchases, removeAllSearchParams } from "../../../store/purchase-slice";
import { calendarFormatDate } from "../../../utils/format-date";
import ProductDrawer, { INITIAL_PRODUCT_DISPLAY_DATA } from "../../product-drawer/product-drawer";
import AddSupplierModal from "../add-supplier-modal/add-supplier-modal";
import NewSupplierModal from "../../new-supplier-modal/new-supplier-modal";
import CreateReturnTable from "./create-return-table/create-return-table";
import * as S from "./return-details.styles";
import "./styles.css";
import ReturnSummary from "./return-summary/return-summary";
import { getProduct } from "../../../api/local/productApi";
import SelectProductModal from "../../select-product-modal/select-product-modal";
import {
    SCREENS,
    addProductToPurchaseReturn,
    removeAllPurchaseReturnProducts,
    removeReturnSelectedSupplier,
    removeSelectedDraftPurchaseReturn,
    setBillNo,
    setCreatedDate,
    setDeliveryDate,
    setDeliveryExpense,
    setPurchaseReturnCurrentScreen,
    setReturnSelectedSupplier,
} from "../../../store/purchasereturn-slice";
import {
    addProductToDamageEntry,
    removeAllDamageEntryProducts,
    removeSelectedDraftDamage,
    setDamageBillNo,
    setDamageCreatedDate,
} from "../../../store/damageentry-slice";
import { addPurchase, updatePurchase } from "./../../../api/local/purchase-api";
import ClearCart from "./../clear-cart-modal/ClearCart";
import BackButton from "./../../back-button/BackButton";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import PurchaseDraftDrawer from "../purchase-draft-drawer/PurchaseDraftDrawer";
import { generateSKU } from "./../../../utils/sku-upc-generator";
import PurchasePaymentModal from "../../purchase-payment-modal/PurchasePaymentModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { captureException } from "../../../crash-reporting";
import { COLOR, INVOICE_TYPE, ModalType, PURCHASE_TYPE, STATUS, ScreenType } from "../../../constants/appConstants";
import history from "../../../utils/history";
import ReturnSummaryModal from "./return-summary-modal/return-summary-modal";
import { getPurchaseReturnPayloadProducts, onInvoiceRequired } from "../../../utils/global-fn";
import CustomButton from "../../custom-button/CustomButton";

const ReturnDetails = ({ type }, ref) => {
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [productsList, setProductsList] = useState([]);
    const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const [scannedProduct, setScannedProduct] = useState(null);
    const [openSelectProductModal, setOpenSelectProductModal] = useState(false);
    const { currentScreen } = useSelector((state) => state.purchaseReturn);
    const { handleSnackbarDetails } = useContext(SnackbarContext);
    const { purchaseReturnProducts, returnSelectedSupplier, selectedDraftPurchaseReturn, billNo, createdDate, deliveryDate, deliveryExpense } =
        useSelector((state) => state.purchaseReturn);
    const [openClearModal, setOpenClearModal] = useState(false);
    const { damageEntryProducts, selectedDraftDamage, damageCreatedDate, damageBillNo } = useSelector((state) => state.damageEntry);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const billRef = useRef();
    const delExpRef = useRef();
    const timeRef = useRef();

    const [openDraftDrawer, setOpenDraftDrawer] = useState(false);

    const [openProductDrawer, _setOpenProductDrawer] = useState(false);
    const openProductDrawerRef = useRef(openProductDrawer);
    const setOpenProductDrawer = (data) => {
        openProductDrawerRef.current = data;
        _setOpenProductDrawer(data);
    };
    const [productDrawerData, setProductDrawerData] = useState({ ...INITIAL_PRODUCT_DISPLAY_DATA, sku: generateSKU() });
    const [openReturnSummaryModal, setOpenReturnSummaryModal] = useState(false);
    const [openDamageSummaryModal, setOpenDamageSummaryModal] = useState(false);
    const [selectedTemplate, _setSelectedTemplate] = useState(null);
    const selectedTemplateRef = useRef(selectedTemplate);
    const setSelectedTemplate = (data) => {
        selectedTemplateRef.current = data;
        _setSelectedTemplate(data);
    };
    let path = history.location.pathname.split("/")[2];

    useEffect(() => {
        if (type === ScreenType.NewPurchaseReturn) {
            setProductsList([]);
            if (purchaseReturnProducts.length) {
                setProductsList(
                    purchaseReturnProducts.map((item) => ({
                        ...item,
                    }))
                );
                dispatch(setPurchaseReturnCurrentScreen(SCREENS.PURCHASE_TABLE));
            }
        }
        if (type === ScreenType.NewDamageEntry) {
            setProductsList([]);
            if (damageEntryProducts.length) {
                setProductsList(
                    damageEntryProducts.map((item) => ({
                        ...item,
                    }))
                );
                dispatch(setPurchaseReturnCurrentScreen(SCREENS.PURCHASE_TABLE));
            }
        }

        // setProductsList(products.map((item) => ({ ...item, editable: false })));
        return () => {
            dispatch(setPurchaseReturnCurrentScreen(SCREENS.PURCHASE_SUMMARY));
            // dispatch(removeReturnSelectedSupplier());
        };
    }, [type, purchaseReturnProducts, damageEntryProducts]);

    useEffect(() => {
        if (!process.env.REACT_APP_DEMO_SERVER) {
            const removeEventListener = window.electronAPI.onBarcodeScanned(async (event, value) => await handleGetProduct(value));
            return () => {
                removeEventListener();
            };
        }
    }, [path]);

    async function handleGetProduct(barcode) {
        // let path = location.pathname.split("/")[2];
        try {
            let res = await getProduct(barcode, { status: "PUBLISH" });
            if (res.status === 200) {
                //Product is present
                if (res.data.length > 1) {
                    setScannedProduct(res.data);
                    setOpenSelectProductModal(true);
                } else {
                    if (path === "new-purchase-return") {
                        dispatch(addProductToPurchaseReturn(res.data[0]));
                    } else if (path === "new-damage-entry") {
                        dispatch(addProductToDamageEntry(res.data[0]));
                    }
                }
                setOpenProductDrawer(false);
            } else if (res.status === 404) {
                //Product is not present, ignore
                setProductDrawerData({
                    ...INITIAL_PRODUCT_DISPLAY_DATA,
                    upc: barcode,
                    sku: generateSKU(),
                });
                setOpenProductDrawer(true);
            } else {
                throw new Error(res.data?.message);
            }
        } catch (e) {
            captureException(e);
        }
    }

    useImperativeHandle(ref, () => {
        return {
            openSupplierModal: () => setOpenSupplierModal(true),
            saveDraftPurchaseReturn: () => handleReturnPurchaseDraft(),
            focusBill: () => billRef.current?.focus(),
            focusDelExp: () => delExpRef.current?.focus(),
            focusDeliveryDate: () => timeRef.current?.querySelector("input").click(),
            proceedReturn: () => onProceedReturn(),
            clearAll: () => setOpenClearModal(true),
            onDamageDraft: () => handleBtnClick("draft"),
            onBackAction: () => backAction(),
            openDraft: () => handleOpenDraftDrawer(),
            onAddProduct: () => setOpenProductDrawer(true),
            onBackEdit: () => handleBtnClick("back"),
        };
    });

    const handleOpenDraftDrawer = () => {
        if (type === ScreenType.NewDamageEntry) {
            dispatch(fetchAllPurchases({ type: PURCHASE_TYPE.DAMAGE_ENTRY }));
            setOpenDraftDrawer(true);
        } else {
            dispatch(fetchAllPurchases({ type: PURCHASE_TYPE.RETURN }));
            setOpenDraftDrawer(true);
        }
    };

    const backAction = () => {
        if (openDraftDrawer) {
            setOpenDraftDrawer(false);
        } else if (openClearModal) {
            setOpenClearModal(false);
        } else if (openProductDrawerRef.current) {
            setOpenProductDrawer(false);
        } else if (openSupplierModal) {
            setOpenSupplierModal(false);
        } else {
            handleBackAction();
        }
    };

    const handleDateChange = (key, item) => {
        if (type === ScreenType.NewDamageEntry) {
            dispatch(setDamageCreatedDate(item));
        } else {
            dispatch(setCreatedDate(item));
        }
    };

    const onProceedReturn = () => {
        if (currentScreen === SCREENS.PURCHASE_SUMMARY) {
            handleBtnClick("purchase");
        } else {
            handleBtnClick("proceed");
        }
    };

    const handleBtnClick = (btntype) => {
        if (btntype === "proceed") {
            for (let item of productsList) {
                item.editable = false;
            }

            dispatch(setPurchaseReturnCurrentScreen(SCREENS.PURCHASE_SUMMARY));
        } else if (btntype === "back") dispatch(setPurchaseReturnCurrentScreen(SCREENS.PURCHASE_TABLE));
        else if (btntype === "purchase") {
            if (type === ScreenType.NewPurchaseReturn) {
                for (let item of productsList) {
                    if (Number(item.quantity) <= 0) {
                        handleSnackbarDetails({
                            show: true,
                            title: `Please choose a quantity for ${item.name}`,
                            type: "error",
                        });
                        return;
                    }
                }
                if (productsList.length === 0) {
                    handleSnackbarDetails({
                        show: true,
                        type: "error",
                        title: "Please add a product",
                    });
                    return;
                } else {
                    setOpenPaymentModal(true);
                }
            } else {
                setOpenDamageSummaryModal(true);
                // handleDamageEntry("PUBLISH");
            }
        } else if (btntype === "draft") {
            if (type === ScreenType.NewPurchaseReturn) {
                handleReturnPurchaseDraft();
            } else {
                handleDamageEntry("DRAFT");
            }
        }
    };

    function handleClearCart() {
        if (type === ScreenType.NewPurchaseReturn) {
            dispatch(removeAllPurchaseReturnProducts());
            dispatch(removeReturnSelectedSupplier());
            dispatch(removeSelectedDraftPurchaseReturn());
            dispatch(setBillNo(""));
            dispatch(setCreatedDate(null));
            dispatch(setDeliveryDate(null));
            dispatch(setDeliveryExpense(""));
        } else {
            dispatch(removeAllDamageEntryProducts());
            dispatch(removeSelectedDraftDamage());
            dispatch(setDamageCreatedDate(null));
            dispatch(setDamageBillNo(""));
        }
    }

    async function handleReturnPurchaseDraft() {
        if (!purchaseReturnProducts.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of purchaseReturnProducts) {
            if (Number(item.quantity) <= 0) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please choose a quantity for ${item.name}`,
                    type: "error",
                });
                return;
            }
        }
        const payload = {
            type: PURCHASE_TYPE.RETURN,
            status: "DRAFT",
            products: getPurchaseReturnPayloadProducts(purchaseReturnProducts),
        };
        if (returnSelectedSupplier) payload.supplier_id = returnSelectedSupplier.id;
        if (billNo) payload.bill_no = billNo;
        if (deliveryExpense) payload.delivery_price = deliveryExpense;
        if (createdDate) payload.created_at = createdDate.getTime();
        if (deliveryDate) payload.delivery_date = deliveryDate.getTime();

        if (selectedDraftPurchaseReturn) {
            try {
                let res = await updatePurchase(selectedDraftPurchaseReturn.id, payload);
                if (res.status === 200) {
                    handleClearCart();
                    navigate("/dashboard/purchases");
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Counter Saved",
                        subtitle: "Counter added successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            }
        } else {
            try {
                let res = await addPurchase(payload);
                if (res.status === 200) {
                    handleClearCart();
                    navigate("/dashboard/purchases");
                    handleSnackbarDetails({
                        show: true,
                        type: "success",
                        title: "Counter Saved",
                        subtitle: "Counter added successfully",
                    });
                } else {
                    throw new Error(res.data?.message);
                }
            } catch (err) {
                captureException(err);
                handleSnackbarDetails({
                    show: true,
                    title: err.message,
                    type: "error",
                });
            }
        }
    }

    async function handleDamageEntry(status, invoiceRequired) {
        if (!damageEntryProducts.length) {
            handleSnackbarDetails({
                show: true,
                title: "Please add products",
                type: "error",
            });
            return;
        }
        for (let item of damageEntryProducts) {
            if (Number(item.quantity) <= 0) {
                handleSnackbarDetails({
                    show: true,
                    title: `Please choose a quantity for ${item.name}`,
                    type: "error",
                });
                return;
            }
        }

        const payload = {
            type: PURCHASE_TYPE.DAMAGE_ENTRY,
            status: status,
            products: getPurchaseReturnPayloadProducts(damageEntryProducts),
        };
        if (damageCreatedDate) payload.created_at = damageCreatedDate.getTime();
        if (damageBillNo) payload.bill_no = damageBillNo;

        if (status === STATUS.PUBLISHED && selectedTemplateRef.current) {
            payload.invoice_template_id = `${selectedTemplateRef.current?.id}`;
        }

        let res;
        try {
            if (selectedDraftDamage) {
                res = await updatePurchase(selectedDraftDamage.id, payload);
            } else {
                res = await addPurchase(payload);
            }
            if (res?.status === 200) {
                handleClearCart();
                navigate("/dashboard/purchases");

                if (status === STATUS.PUBLISHED && invoiceRequired) {
                    onInvoiceRequired(payload.invoice_template_id, INVOICE_TYPE.PURCHASE, res.data);
                }

                handleSnackbarDetails({
                    show: true,
                    type: "success",
                    title: `${status === STATUS.PUBLISHED ? "Damage entry" : "Counter"} ${selectedDraftDamage ? "saved" : "completed"}`,
                    subtitle: `Your ${status === STATUS.PUBLISHED ? "damage entry" : "counter"} has ${
                        selectedDraftDamage ? "saved" : "completed"
                    } successfully.`,
                });
            } else {
                throw new Error(res.data?.message);
            }
        } catch (error) {
            captureException(error);
            handleSnackbarDetails({
                show: true,
                title: error.message,
                type: "error",
            });
        }
    }
    async function handleDraftDelete() {
        if (type === ScreenType.NewPurchaseReturn) {
            const payload = {
                status: "TRASH",
            };
            updatePurchase(selectedDraftPurchaseReturn.id, payload)
                .then((res) => {
                    if (res.status === 200) {
                        handleClearCart();
                        // fetchAllPurchases();
                        // navigate("/dashboard/purchases");
                        handleSnackbarDetails({
                            show: true,
                            type: "success",
                            title: "Purchase deleted successfully",
                        });
                    } else {
                        throw new Error(res.data?.message);
                    }
                })
                .catch((err) => {
                    handleSnackbarDetails({
                        show: true,
                        title: "Something went wrong...",
                        type: "error",
                    });
                    captureException(err);
                });
        } else if (type === ScreenType.NewDamageEntry) {
            const payload = {
                status: "TRASH",
            };
            updatePurchase(selectedDraftDamage.id, payload)
                .then((res) => {
                    if (res.status === 200) {
                        handleClearCart();
                        // fetchAllPurchases();
                        // navigate("/dashboard/purchases");
                        handleSnackbarDetails({
                            show: true,
                            type: "success",
                            title: "Purchase deleted successfully",
                        });
                    } else {
                        throw new Error(res.data?.message);
                    }
                })
                .catch((err) => {
                    handleSnackbarDetails({
                        show: true,
                        title: "Something went wrong...",
                        type: "error",
                    });
                    captureException(err);
                });
        }
    }

    function handleBackAction() {
        navigate("/dashboard/purchases");
    }

    function clearCartDraft() {
        if (type === ScreenType.NewPurchaseReturn) {
            handleReturnPurchaseDraft();
        } else {
            handleDamageEntry("DRAFT");
        }
    }

    return (
        <>
            <Drawer
                anchor="right"
                hideBackdrop="true"
                open={openProductDrawerRef.current}
                onClose={() => {
                    setOpenProductDrawer(false);
                }}
                className="right-drawer"
            >
                <ProductDrawer
                    type="add"
                    onCloseDrawer={(product, type) => {
                        setOpenProductDrawer(false);
                        setProductDrawerData({ ...INITIAL_PRODUCT_DISPLAY_DATA, sku: generateSKU() });
                        if (type === "Added" && product?.sku) {
                            handleGetProduct(product.sku);
                        }
                    }}
                    productData={productDrawerData}
                    open={openProductDrawerRef.current}
                />{" "}
            </Drawer>
            <Drawer anchor="right" hideBackdrop="true" open={openDraftDrawer} onClose={() => setOpenDraftDrawer(false)} className="right-drawer">
                <PurchaseDraftDrawer
                    type={type === ScreenType.NewDamageEntry ? PURCHASE_TYPE.DAMAGE_ENTRY : PURCHASE_TYPE.RETURN}
                    onCloseDrawer={() => {
                        setOpenDraftDrawer(false);
                        dispatch(removeAllSearchParams());
                    }}
                    open={openDraftDrawer}
                />
            </Drawer>
            <ClearCart
                open={openClearModal}
                onClose={() => setOpenClearModal(false)}
                handleClearCart={handleClearCart}
                handleDraftDelete={handleDraftDelete}
                handleDraftPurchase={clearCartDraft}
            />
            <SelectProductModal open={openSelectProductModal} onClose={() => setOpenSelectProductModal(false)} data={scannedProduct} />

            <PurchasePaymentModal
                open={openPaymentModal}
                onClose={() => setOpenPaymentModal(false)}
                type="new-purchase-return"
                returnAdditional={{
                    billno: billNo,
                    deliveryExpense: deliveryExpense || 0,
                    createdAt: createdDate,
                    deliveryDate,
                }}
                openReturnSummaryModal={openReturnSummaryModal}
                setOpenReturnSummaryModal={setOpenReturnSummaryModal}
                handleClearCart={handleClearCart}
                setOpenSupplierModal={setOpenSupplierModal}
            />
            <AddSupplierModal
                open={openSupplierModal}
                onClose={() => setOpenSupplierModal(false)}
                openAddNewModal={() => setOpenAddSupplierModal(true)}
            />
            <NewSupplierModal
                open={openAddSupplierModal}
                onClose={() => setOpenAddSupplierModal(false)}
                closeModal={() => setOpenSupplierModal(false)}
            />
            {type === ScreenType.NewDamageEntry && (
                <ReturnSummaryModal
                    open={openDamageSummaryModal}
                    onClose={() => setOpenDamageSummaryModal(false)}
                    handleAddDamageEntry={(invoiceRequire) => handleDamageEntry("PUBLISH", invoiceRequire)}
                    datas={{
                        createdAt: damageCreatedDate,
                    }}
                    type={ModalType.DamageEntry}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                />
            )}
            <S.Container>
                <S.Header>
                    <S.Topbar>
                        <S.SubTopBar>
                            <BackButton onClickAction={handleBackAction} />
                            {type === ScreenType.NewDamageEntry && (
                                <S.HeaderContainer>
                                    <S.HeaderText>{type}</S.HeaderText>
                                </S.HeaderContainer>
                            )}
                        </S.SubTopBar>
                        <S.SubTopBar>
                            <S.BtnContainer>
                                {type === ScreenType.NewDamageEntry && (
                                    <>
                                        <CustomButton
                                            label="Counter Damage (F11)"
                                            color={COLOR.BLUE}
                                            onClick={() => handleOpenDraftDrawer()}
                                            fill={true}
                                        />
                                        <CustomButton
                                            label="Clear Cart (F9)"
                                            color={COLOR.RED}
                                            icon={RedClearCartIcon}
                                            onClick={() => setOpenClearModal(true)}
                                        />
                                    </>
                                )}
                            </S.BtnContainer>
                            <S.DateContainer>
                                <S.Date>Date</S.Date>
                                <DatePicker
                                    selected={Date.parse(
                                        calendarFormatDate(
                                            type === ScreenType.NewDamageEntry ? damageCreatedDate ?? new Date() : createdDate ?? new Date()
                                        )
                                    )}
                                    onChange={(date) => handleDateChange("created_at", date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="purchase-return-date-picker"
                                />
                            </S.DateContainer>
                        </S.SubTopBar>
                    </S.Topbar>
                    {type === ScreenType.NewPurchaseReturn && (
                        <S.NewReturnHeader>
                            <S.HeaderContainer>
                                <S.HeaderText>{type}</S.HeaderText>
                            </S.HeaderContainer>
                            <S.HeaderDetails>
                                <CustomButton label="Counter Returns (F11)" color={COLOR.BLUE} onClick={() => handleOpenDraftDrawer()} fill={true} />
                                <CustomButton
                                    label="Add new product (F6)"
                                    color={COLOR.BLUE}
                                    onClick={() => setOpenProductDrawer(true)}
                                    fill={true}
                                />
                                <CustomButton
                                    label={`${returnSelectedSupplier ? "Change" : "Add"} Supplier (F4)`}
                                    color={COLOR.BLUE}
                                    onClick={() => setOpenSupplierModal(true)}
                                    fill={true}
                                />

                                <CustomButton
                                    label="Clear Cart (F9)"
                                    color={COLOR.RED}
                                    icon={RedClearCartIcon}
                                    onClick={() => setOpenClearModal(true)}
                                />
                            </S.HeaderDetails>
                        </S.NewReturnHeader>
                    )}
                </S.Header>

                <S.Body returnBody={type === ScreenType.NewPurchaseReturn}>
                    {productsList.length ? (
                        <CreateReturnTable
                            products={productsList}
                            setProductsList={setProductsList}
                            type={type}
                            createdAt={type === ScreenType.NewDamageEntry ? damageCreatedDate : createdDate}
                            handleBtnClick={handleBtnClick}
                        />
                    ) : (
                        <S.EmptyContainer>
                            <img src={EmptyCartLogo} alt="empty cart logo" />
                            <div>Search or Scan Products to add</div>
                        </S.EmptyContainer>
                    )}
                    {currentScreen === SCREENS.PURCHASE_SUMMARY && (
                        <ReturnSummary
                            billRef={billRef}
                            delExpRef={delExpRef}
                            type={type}
                            timeRef={timeRef}
                            handleBtnClick={handleBtnClick}
                            products={productsList}
                        />
                    )}
                </S.Body>
            </S.Container>
        </>
    );
};

export default React.forwardRef(ReturnDetails);
