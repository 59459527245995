export const thermalDateFormat = [
    {
        id: 1,
        name: "hh:mm a, DD-MMM-YYYY",
        value: "hh:mm a, DD-MMM-YYYY",
    },
    {
        id: 2,
        name: "hh:mm a, DD-MMM-YY",
        value: "hh:mm a, DD-MMM-YY",
    },
    {
        id: 3,
        name: "hh:mm a, DD-MM-YYYY",
        value: "hh:mm a, DD-MM-YYYY",
    },
    {
        id: 4,
        name: "hh:mm a, DD-MM-YY",
        value: "hh:mm a, DD-MM-YY",
    },
    {
        id: 5,
        name: "hh:mm a, MMM-DD-YYYY",
        value: "hh:mm a, MMM-DD-YYYY",
    },
    {
        id: 6,
        name: "hh:mm a, MMM-DD-YY",
        value: "hh:mm a, MMM-DD-YY",
    },
    {
        id: 7,
        name: "hh:mm a, MM-DD-YYYY",
        value: "hh:mm a, MM-DD-YYYY",
    },
    {
        id: 8,
        name: "hh:mm a, MM-DD-YY",
        value: "hh:mm a, MM-DD-YY",
    },
    {
        id: 9,
        name: "hh:mm a, DD/MMM/YYYY",
        value: "hh:mm a, DD/MMM/YYYY",
    },
    {
        id: 10,
        name: "hh:mm a, DD/MMM/YY",
        value: "hh:mm a, DD/MMM/YY",
    },
    {
        id: 11,
        name: "hh:mm a, DD/MM/YYYY",
        value: "hh:mm a, DD/MM/YYYY",
    },
    {
        id: 12,
        name: "hh:mm a, DD/MM/YY",
        value: "hh:mm a, DD/MM/YY",
    },
    {
        id: 13,
        name: "hh:mm a, MMM/DD/YYYY",
        value: "hh:mm a, MMM/DD/YYYY",
    },
    {
        id: 14,
        name: "hh:mm a, MMM/DD/YY",
        value: "hh:mm a, MMM/DD/YY",
    },
    {
        id: 15,
        name: "hh:mm a, MM/DD/YYYY",
        value: "hh:mm a, MM/DD/YYYY",
    },
    {
        id: 16,
        name: "hh:mm a, MM/DD/YY",
        value: "hh:mm a, MM/DD/YY",
    },
];

export const barcodeDateFormat = [
    {
        id: 1,
        name: "DD-MMM-YYYY",
        value: "DD-MMM-YYYY",
    },
    {
        id: 2,
        name: "DD-MMM-YY",
        value: "DD-MMM-YY",
    },
    {
        id: 3,
        name: "DD-MM-YYYY",
        value: "DD-MM-YYYY",
    },
    {
        id: 4,
        name: "DD-MM-YY",
        value: "DD-MM-YY",
    },
    {
        id: 5,
        name: "MMM-DD-YYYY",
        value: "MMM-DD-YYYY",
    },
    {
        id: 6,
        name: "MMM-DD-YY",
        value: "MMM-DD-YY",
    },
    {
        id: 7,
        name: "MM-DD-YYYY",
        value: "MM-DD-YYYY",
    },
    {
        id: 8,
        name: "MM-DD-YY",
        value: "MM-DD-YY",
    },
    {
        id: 9,
        name: "DD/MMM/YYYY",
        value: "DD/MMM/YYYY",
    },
    {
        id: 10,
        name: "DD/MMM/YY",
        value: "DD/MMM/YY",
    },
    {
        id: 11,
        name: "DD/MM/YYYY",
        value: "DD/MM/YYYY",
    },
    {
        id: 12,
        name: "DD/MM/YY",
        value: "DD/MM/YY",
    },
    {
        id: 13,
        name: "MMM/DD/YYYY",
        value: "MMM/DD/YYYY",
    },
    {
        id: 14,
        name: "MMM/DD/YY",
        value: "MMM/DD/YY",
    },
    {
        id: 15,
        name: "MM/DD/YYYY",
        value: "MM/DD/YYYY",
    },
    {
        id: 16,
        name: "MM/DD/YY",
        value: "MM/DD/YY",
    },
];

export const currencySymbol = [
    {
        id: 1,
        name: "₱",
        value: "₱",
    },
    {
        id: 2,
        name: "₱",
        value: "₱",
    },
];

export const discountIn = [
    {
        id: 1,
        name: "Rupees",
        value: "Rupees",
    },
];

export const pageSize = [
    {
        id: 1,
        name: "2 inch",
        value: 2,
    },
    {
        id: 2,
        name: "3 inch",
        value: 3,
    },
    {
        id: 3,
        name: "4 inch",
        value: 4,
    },
];

export const stickerRow = [
    {
        id: 1,
        name: 1,
        value: 1,
    },
    {
        id: 2,
        name: 2,
        value: 2,
    },
];

export const stickerSize = [
    {
        id: 1,
        name: "50 x 25",
        value: "50 x 25",
    },
    {
        id: 2,
        name: "38 x 25",
        value: "38 x 25",
    },
    {
        id: 3,
        name: "38 x 50",
        value: "38 x 50",
    },
];
