import React, { useState } from "react";
import { AddCustomerSearchIcon, ArrowDrop, ArrowUp, CloseModalIcon, RefreshIcon } from "../../../icons";
import ShortcutBtn from "../../shortcut-btn/ShortcutBtn";

import * as S from "./custom-input.styles";

const CustomInput = ({
    type,
    placeholder = "",
    value,
    onChange,
    refreshIcon,
    searchIcon,
    onClickRefresh,
    disabled,
    pattern,
    onClickClear,
    clearTextIcon,
    counters,
    counterUp,
    counterDown,
    inputRef,
    onBlur,
    autoFocus,
    decimalAllow,
    negativeAllow,
    scText,
    maxLength,
}) => {
    const [inputType] = useState(type);
    const [inputValue, setInputValue] = useState(value ?? "");

    const handleChange = (e) => {
        if (type === "number") {
            if (decimalAllow && negativeAllow) {
                const regex = /^(-)?\d+\.?(\d)?(\d)?$/;
                if (!(e.target.value === "" || regex.test(e.target.value))) return;
            } else if (decimalAllow) {
                const regex = /^\d+\.?(\d)?(\d)?$/;
                if (e.target.value.length > 1 && e.target.value[0] === "0" && e.target.value[1] !== ".") {
                    e.target.value = e.target.value.substr(1);
                }
                if (!(e.target.value === "" || regex.test(e.target.value))) return;
            } else if (negativeAllow) {
                // const regex = /^(-)?\d+$/;
                // if (!(e.target.value === "" || regex.test(e.target.value))) return;
            } else {
                const regex = /^[0-9\b]+$/;
                if (!(e.target.value === "" || regex.test(e.target.value))) return;
            }
        }

        if (onChange) onChange(e.target.value);
    };

    return (
        <S.Container scText>
            {searchIcon && <img src={AddCustomerSearchIcon} alt="search" />}
            <input
                type={negativeAllow ? "number" : "text"}
                name="custom-input"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                pattern={pattern}
                ref={inputRef}
                onBlur={onBlur}
                autoFocus={autoFocus}
                maxLength={maxLength}
            />
            {refreshIcon && <img onClick={onClickRefresh} src={RefreshIcon} alt="refresh" />}
            {clearTextIcon && value && <img onClick={onClickClear} src={CloseModalIcon} alt="clear" />}
            {scText && <ShortcutBtn text={scText} />}
            {counters && (
                <S.ArrowBox>
                    <img src={ArrowUp} alt="arrow up" onClick={counterUp} />
                    <img src={ArrowDrop} alt="arrow drop" onClick={counterDown} />
                </S.ArrowBox>
            )}
        </S.Container>
    );
};

export default CustomInput;
