import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BillIcon, SummaryCartDiscountIcon } from "../../../icons";
import * as S from "./style";
import { setReturnAdditionalCharges, setReturnCartDiscount } from "../../../store/returnsale-slice";
import { hasDecimal, toDecimalPlace } from "../../../utils/global-fn";

const ReturnSummary = ({ type, billno, setBillno, setPaymentMode, paymentMode, billRef, paymentRef }) => {
    const { selectedSale } = useSelector((state) => state.sale);
    const {
        selectedReturnSale,
        returnSaleTotal,
        returnSaleQuantity,
        taxTotal,
        returnCartDiscount,
        returnAdditionalCharges,
        totalReturnAdditionalCharges,
        returnCartTotal,
    } = useSelector((state) => state.returnSale);

    const dispatch = useDispatch();

    const handleDiscountChange = (e) => {
        let { value } = e.target;
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
            value = value.substr(1);
        }
        if (!(value === "" || regex.test(value))) return;

        if (parseFloat(value) > selectedReturnSale?.cart_discount) {
            value = toDecimalPlace(selectedReturnSale?.cart_discount);
        }
        dispatch(setReturnCartDiscount(value || 0));
    };

    const handleAdditionalCherges = (e, charge) => {
        let { value } = e.target;
        const regex = /^\d+\.?(\d)?(\d)?$/;
        if (value.length > 1 && value[0] === "0" && value[1] !== ".") {
            value = value.substr(1);
        }
        if (!(value === "" || regex.test(value))) return;

        if (parseFloat(value) > charge.value) {
            value = charge.value;
        }

        let key = charge.field;
        dispatch(setReturnAdditionalCharges({ ...returnAdditionalCharges, [key]: value }));
    };
    return (
        <S.Wrapper>
            <S.Heading>{type} Summary</S.Heading>
            <S.Body>
                <div>
                    {selectedReturnSale.patient !== null && (
                        <S.EmployeeDiv>
                            <S.EmpName>{selectedReturnSale.patient?.name}</S.EmpName>
                            <S.EmpPhone>({selectedReturnSale.patient?.phone})</S.EmpPhone>
                        </S.EmployeeDiv>
                    )}
                    <S.GreyText>
                        <div>Quantity:</div>
                        <div>{returnSaleQuantity}</div>
                    </S.GreyText>
                    <S.GreyText>
                        <div>Subtotal:</div>
                        <div>₱{(returnSaleTotal - taxTotal).toFixed(2)}</div>
                    </S.GreyText>
                    <S.GreyText>
                        <div>Tax:</div>
                        <div>₱{taxTotal.toFixed(2)}</div>
                    </S.GreyText>
                    <S.GreyText>
                        <div>Bill total:</div>
                        <div>₱{returnSaleTotal.toFixed(2)}</div>
                    </S.GreyText>
                    {/* <S.GreyText>
                        <div>Total Profit:</div>
                        <div>₱{selectedSale?.net_profit}</div>
                    </S.GreyText> */}
                    {/* <S.GreyText>
                        <div>Cart Discount:</div>
                        <div>{selectedSale?.cart_total_discount}</div>
                    </S.GreyText> */}
                    <S.DottedLine></S.DottedLine>

                    {selectedReturnSale?.cart_discount ? (
                        <>
                            <S.ShowDiscount>
                                <div>Earlier Cart Discount Added:</div>
                                <div>₱{selectedReturnSale?.cart_discount.toFixed(2)}</div>
                            </S.ShowDiscount>
                            <S.BillContainer>
                                <S.Label>Subtract Cart Discount</S.Label>
                                <S.FieldContainer>
                                    <input
                                        type="text"
                                        value={returnCartDiscount}
                                        onChange={(e) => handleDiscountChange(e)}
                                        disabled={!returnSaleQuantity}
                                    />
                                    <S.Line></S.Line>

                                    <img src={SummaryCartDiscountIcon} alt="" />
                                </S.FieldContainer>
                            </S.BillContainer>
                            <S.DottedLine></S.DottedLine>
                        </>
                    ) : (
                        <></>
                    )}
                    {selectedReturnSale?.total_additional_charges ? (
                        <>
                            <S.ShowDiscount>
                                <div>Earlier Additional Charges Added:</div>
                                <div>₱{selectedReturnSale?.total_additional_charges.toFixed(2)}</div>
                            </S.ShowDiscount>
                            {Array.isArray(selectedReturnSale?.additional_charges) && selectedReturnSale?.additional_charges?.length ? (
                                selectedReturnSale?.additional_charges?.map((item, idx) => (
                                    <S.BillContainer>
                                        <S.Label>
                                            Return {item.field} Charge(₱{item.value})
                                        </S.Label>
                                        <S.FieldContainer>
                                            <input
                                                type="text"
                                                value={returnAdditionalCharges[item.field]}
                                                onChange={(e) => handleAdditionalCherges(e, item)}
                                                disabled={!returnSaleQuantity}
                                            />
                                            <S.Line></S.Line>

                                            <img src={SummaryCartDiscountIcon} alt="" />
                                        </S.FieldContainer>
                                    </S.BillContainer>
                                ))
                            ) : (
                                <></>
                            )}

                            <S.ShowDiscount>
                                <div>Total Return Additional Charges:</div>
                                <div>₱{totalReturnAdditionalCharges}</div>
                            </S.ShowDiscount>

                            <S.DottedLine></S.DottedLine>
                        </>
                    ) : (
                        <></>
                    )}

                    {hasDecimal(returnCartTotal) && (
                        <S.GreyText>
                            <div>Adjusted cash:</div>
                            <div>₱{(Math.round(returnCartTotal) - returnCartTotal).toFixed(2)}</div>
                        </S.GreyText>
                    )}
                    <S.OrderTotal>
                        <div>Return total:</div>
                        <div>₱{Math.round(returnCartTotal)}</div>
                    </S.OrderTotal>
                    <S.DottedLine></S.DottedLine>
                    <S.BillContainer>
                        <S.Label>
                            Add bill number
                            <div>(F7)</div>
                        </S.Label>
                        <S.FieldContainer>
                            <input type="text" value={billno} onChange={(e) => setBillno(e.target.value)} ref={billRef} />
                            <S.Line></S.Line>

                            <img src={BillIcon} alt="" />
                        </S.FieldContainer>
                    </S.BillContainer>
                </div>
                {/* <div>
                    <S.Change>
                        <div>
                            <S.Mode>Send tax invoice</S.Mode>
                        </div>
                        <S.ChangeText>Send</S.ChangeText>
                    </S.Change>
                </div> */}
            </S.Body>
        </S.Wrapper>
    );
};

export default ReturnSummary;
