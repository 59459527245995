import styled from "@emotion/styled/macro";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const LeftContainer = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`;

export const ClearFilters = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2d68fe;
    cursor: pointer;
`;
export const SelectedChipContainer = styled.div`
    height: 23px;
    display: flex;
    background: #dbeafe;
    border-radius: 2px;
    padding: 3px 13px;
    align-items: center;
    margin-top: 12px;

    img {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        cursor: pointer;
    }
`;
export const ChipText = styled.div`
    font-weight: 400;
    font-size: 12.241px;
    line-height: 15px;
    color: #2d68fe;
`;
